import { useState } from 'react';
import { injectIntl } from 'react-intl';

import OFDSearchFilters from '../OFDSearch/components/OFDSearchFilters/OFDSearchFilters';
import OFDSearchTable from '../OFDSearch/components/OFDSearchTable/OFDSearchTable';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';

const OFDSearchTab = ({ intl }) => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [getSuccessRate, setGetSuccessRate] = useState(false);

  return (
    <div className="br-ofd-search-tab__container">
      <OFDSearchFilters
        setAppliedFilters={setAppliedFilters}
        setGetSuccessRate={setGetSuccessRate}
      />
      <OFDSearchTable
        appliedFilters={appliedFilters}
        getSuccessRate={getSuccessRate}
        setGetSuccessRate={setGetSuccessRate}
      />
    </div>
  );
};

export default injectIntl(OFDSearchTab);
