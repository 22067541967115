import { PhoneNumberUtil } from 'google-libphonenumber';

import { FIXED_LINE } from 'constants/form';
import { PASSWORD_REGEX } from 'constants/Security';
import {
  currentUserCountry,
  getPhoneNumberRegex
} from 'common/countries/countries-mapping';

const emailValidator = (value, message) => {
  const emailExp = /^\S+@\S+\.\S+$/;

  if (!value || emailExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const emailRule = (message) => ({
  validator: (_, value) => emailValidator(value, message)
});

const urlValidator = (value, message) => {
  const urlExp =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  if (!value || urlExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const urlRule = (message) => ({
  validator: (_, value) => urlValidator(value, message)
});

export const requiredRule = (message) => ({
  required: true,
  message
});

export const notRequiredRule = () => ({
  required: false
});

const numberValidator = (value, message) => {
  const numberRegExp = /^\d+$/;
  if (!value || numberRegExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const numberRule = (message) => ({
  validator: (_, value) => numberValidator(value, message)
});

const lengthRuleValidator = (value, length, message) => {
  if (value?.length === length) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const lengthRule = ({ length, message }) => ({
  validator: (_, value) => lengthRuleValidator(value, length, message)
});

const samePasswordValidator = (value, message, passwordValue) => {
  if (!value || passwordValue === value) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const samePassword = (message, passwordValue) => ({
  validator: (_, value) => samePasswordValidator(value, message, passwordValue)
});

const phoneNumberValidator = ({
  value,
  message,
  internationlNumbers,
  landlineNumbers,
  country,
  withoutCountryCode
}) => {
  const phoneRegex = getPhoneNumberRegex({
    countryCode: country.codeName,
    withoutCountryCode
  });
  const phoneUtil = PhoneNumberUtil.getInstance();
  let number;

  if (!value || phoneRegex.test(value)) {
    return Promise.resolve();
  }

  try {
    number = phoneUtil.parseAndKeepRawInput(value, country.codeName);
  } catch (e) {
    if (!value) {
      return Promise.resolve();
    }
    return Promise.reject(message);
  }

  if (
    internationlNumbers &&
    phoneUtil.isValidNumber(number) &&
    phoneUtil.getNumberType(number) !== FIXED_LINE
  ) {
    return Promise.resolve();
  }

  if (landlineNumbers && phoneUtil.isValidNumber(number)) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const phoneNumberRule = ({
  message,
  internationlNumbers,
  landlineNumbers,
  country = currentUserCountry,
  withoutCountryCode
}) => ({
  validator: (_, value) =>
    phoneNumberValidator({
      value,
      message,
      internationlNumbers,
      landlineNumbers,
      country,
      withoutCountryCode
    })
});

const passwordValidator = (value, message) => {
  if (!value || PASSWORD_REGEX.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const passwordRule = (message) => ({
  validator: (_, value) => passwordValidator(value, message)
});

const codValidator = (
  value,
  message,
  isCashCollection,
  limit = 30000,
  isAllowedToRefund
) => {
  if (isCashCollection) {
    if (value) {
      if (value > 0 || isAllowedToRefund) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          'Cash collection COD cannot be 0 or smaller than 0'
        );
      }
    }
    return Promise.reject('Cash collection COD cannot be 0 or smaller than 0');
  }
  if (
    (value && parseInt(value) <= limit && parseInt(value) >= -1 * limit) ||
    !value ||
    !Number.isInteger(parseInt(value))
  ) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const codRule = (
  message,
  isCashCollection = false,
  limit,
  isAllowedToRefund
) => ({
  validator: (_, value) =>
    codValidator(value, message, isCashCollection, limit, isAllowedToRefund)
});

export const codCashCollectionRule = (message) => ({
  validator: (_, value) => codCashCollectionValidator(value, message)
});

const codCashCollectionValidator = (value, message) => {
  if (value && value > 0) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const noOfItemsRule = (message) => ({
  validator: (_, value) => noOfItemsRuleValidator(value, message)
});

const noOfItemsRuleValidator = (value, message) => {
  if (value && value > 0) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

const bonusAndDeductionValidator = (value, message) => {
  if (
    (value && parseInt(value) <= 10000) ||
    !value ||
    !Number.isInteger(parseInt(value))
  ) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const bonusAndDeductionAmountRule = (message) => ({
  validator: (_, value) => bonusAndDeductionValidator(value, message)
});

const planNameValidator = (value, message, planName) => {
  if (value !== planName) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const pricePlanRule = (message, planName) => ({
  validator: (_, value) => planNameValidator(value, message, planName)
});

const postiveNumberValidator = (value, message) => {
  if (value === null || parseFloat(value) > 0) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const positiveNumberRule = (message) => ({
  validator: (_, value) => postiveNumberValidator(value, message)
});

const isEqualValidator = ({ inputValue, value, message }) => {
  if (inputValue === value) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const isEqualRule = ({ message, value }) => ({
  validator: (_, inputValue) => isEqualValidator({ inputValue, value, message })
});

export const whiteSpaceRule = (message) => ({
  whitespace: true,
  message
});

export const rangeValidator = ({ message, min, max, value }) => {
  if (!value || (min && value < min) || (max && value > max)) {
    return Promise.reject(message);
  }

  return Promise.resolve();
};

export const rangeRule = ({ message, min, max }) => ({
  validator: (_, value) => rangeValidator({ message, min, max, value })
});

const attachmentsCountValidator = ({ message, count, value }) => {
  if (!value || value.attachments.length !== count) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const attachmentsCountRule = ({ count, message }) => ({
  validator: (_, value) => attachmentsCountValidator({ message, count, value })
});
