import './LocationEmptyState.less';

const LocationEmptyState = ({ imgSrc, title }) => {
  return (
    <div className="br-location-empty-state">
      <div>
        <img src={imgSrc} alt="" />
        <span className="br-location-empty-state__title">{title}</span>
      </div>
    </div>
  );
};

export default LocationEmptyState;
