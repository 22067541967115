import {
  getAisleRacks,
  getLocationZones,
  getRackShelves,
  getShelveTotes,
  getZoneAisles
} from 'services/fulfillment-locations';

import { fmt } from 'IntlWrapper/IntlWrapper';

import LocationsList from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/LocationsList';
import { notify } from 'components/Notify/Notify';

import ZoneEmptyState from 'assets/imgRevamp/ZoneEmptyState.png';
import AisleEmptyState from 'assets/imgRevamp/AisleEmptyState.png';
import RackEmptyState from 'assets/imgRevamp/RackEmptyState.png';
import ShelfEmptyState from 'assets/imgRevamp/ShelfEmptyState.png';
import ToteEmptyState from 'assets/imgRevamp/ToteEmptyState.png';

export const FULFILLMENT_LOCATIONS_MAIN_PATH = '/fulfillment/locations';
export const FULFILLMENT_LOCATIONS_ROUTES = [
  {
    path: `${FULFILLMENT_LOCATIONS_MAIN_PATH}/:zone?/:aisle?/:rack?/:shelf?`,
    name: 'Locations List',
    component: LocationsList,
    exact: true
  },
  {
    path: FULFILLMENT_LOCATIONS_MAIN_PATH,
    name: 'Locations List',
    component: LocationsList,
    exact: true
  }
];

export const FULFILLMENT_LOCATION_LEVELS_KEYS = {
  LOCATION: 'location',
  ZONE: 'zone',
  AISLE: 'aisle',
  RACK: 'rack',
  SHELF: 'shelf',
  TOTE: 'tote'
};

export const FULFILLMENT_LOCATION_LEVELS_OPTIONS = [
  {
    label: fmt({
      id: 'fulfillment_locations.location_levels.zone'
    }),
    value: FULFILLMENT_LOCATION_LEVELS_KEYS.ZONE
  },
  {
    label: fmt({
      id: 'fulfillment_locations.location_levels.aisle'
    }),
    value: FULFILLMENT_LOCATION_LEVELS_KEYS.AISLE
  },
  {
    label: fmt({
      id: 'fulfillment_locations.location_levels.rack'
    }),
    value: FULFILLMENT_LOCATION_LEVELS_KEYS.RACK
  },
  {
    label: fmt({
      id: 'fulfillment_locations.location_levels.shelf'
    }),
    value: FULFILLMENT_LOCATION_LEVELS_KEYS.SHELF
  },
  {
    label: fmt({
      id: 'fulfillment_locations.location_levels.tote'
    }),
    value: FULFILLMENT_LOCATION_LEVELS_KEYS.TOTE
  }
];

export const FULFILLMENT_LOCATION_LEVELS_EMPTY_STATE = {
  [FULFILLMENT_LOCATION_LEVELS_KEYS.LOCATION]: {
    imgSrc: ZoneEmptyState,
    title: fmt({
      id: 'fulfillment_locations.zone.empty_state.title'
    })
  },
  [FULFILLMENT_LOCATION_LEVELS_KEYS.ZONE]: {
    imgSrc: AisleEmptyState,
    title: fmt({
      id: 'fulfillment_locations.aisle.empty_state.title'
    })
  },
  [FULFILLMENT_LOCATION_LEVELS_KEYS.AISLE]: {
    imgSrc: RackEmptyState,
    title: fmt({
      id: 'fulfillment_locations.rack.empty_state.title'
    })
  },
  [FULFILLMENT_LOCATION_LEVELS_KEYS.RACK]: {
    imgSrc: ShelfEmptyState,
    title: fmt({
      id: 'fulfillment_locations.shelf.empty_state.title'
    })
  },
  [FULFILLMENT_LOCATION_LEVELS_KEYS.SHELF]: {
    imgSrc: ToteEmptyState,
    title: fmt({
      id: 'fulfillment_locations.tote.empty_state.title'
    })
  }
};

export const getCurrentLevel = ({ zone, aisle, rack, shelf, tote }) => {
  const { TOTE, SHELF, RACK, AISLE, ZONE, LOCATION } =
    FULFILLMENT_LOCATION_LEVELS_KEYS;

  if (tote)
    return {
      id: tote,
      level: TOTE
    };
  if (shelf)
    return {
      id: shelf,
      level: SHELF
    };
  if (rack)
    return {
      id: rack,
      level: RACK
    };
  if (aisle)
    return {
      id: aisle,
      level: AISLE
    };
  if (zone)
    return {
      id: zone,
      level: ZONE
    };

  return {
    id: null,
    level: LOCATION
  };
};

const FULFILLMENT_LOCATION_LEVELS_GET_API = {
  [FULFILLMENT_LOCATION_LEVELS_KEYS.LOCATION]: getLocationZones,
  [FULFILLMENT_LOCATION_LEVELS_KEYS.ZONE]: getZoneAisles,
  [FULFILLMENT_LOCATION_LEVELS_KEYS.AISLE]: getAisleRacks,
  [FULFILLMENT_LOCATION_LEVELS_KEYS.RACK]: getRackShelves,
  [FULFILLMENT_LOCATION_LEVELS_KEYS.SHELF]: getShelveTotes
};

export const getLocationByLevel = ({ level, id }) =>
  FULFILLMENT_LOCATION_LEVELS_GET_API[level](id);

export const getLevelParentKey = (level) => {
  const { LOCATION, ZONE, AISLE, RACK, SHELF, TOTE } =
    FULFILLMENT_LOCATION_LEVELS_KEYS;

  const parents = {
    [ZONE]: LOCATION,
    [AISLE]: ZONE,
    [RACK]: AISLE,
    [SHELF]: RACK,
    [TOTE]: SHELF
  };

  return parents[level];
};

export const getLevelChildKey = (level) => {
  const { LOCATION, ZONE, AISLE, RACK, SHELF, TOTE } =
    FULFILLMENT_LOCATION_LEVELS_KEYS;

  const parents = {
    [LOCATION]: ZONE,
    [ZONE]: AISLE,
    [AISLE]: RACK,
    [RACK]: SHELF,
    [SHELF]: TOTE,
    [TOTE]: null
  };

  return parents[level];
};

export const getSelectedLevelsList = (level) => {
  const { LOCATION, ZONE, AISLE, RACK, SHELF, TOTE } =
    FULFILLMENT_LOCATION_LEVELS_KEYS;

  const levels = {
    [ZONE]: [],
    [AISLE]: [ZONE],
    [RACK]: [ZONE, AISLE],
    [SHELF]: [ZONE, AISLE, RACK],
    [TOTE]: [ZONE, AISLE, RACK, SHELF]
  };

  return levels[level] || [];
};

export const getConcatenatedLocationName = ({ aisle, rack, shelf, tote }) => {
  return [aisle, rack, shelf, tote].filter(Boolean).join('-');
};

const getNextStringId = (str) => {
  let index = str.length - 1;
  let baseCode = str.charCodeAt(index);

  do {
    baseCode = str.charCodeAt(index);
    const strArr = str.split('');

    if (strArr[index] === 'Z') {
      strArr[index] = 'A';
      if (index === 0) {
        strArr.unshift('A');
      }
    } else {
      strArr[index] = String.fromCharCode(baseCode + 1);
    }

    str = strArr.join('');
    index--;
  } while (baseCode === 90);

  return str;
};

export const getNextLevelValue = ({ level, previousValue }) => {
  if (previousValue) {
    return !isNaN(previousValue)
      ? String(Number(previousValue) + 1).padStart(2, '0') // ex: if '02' return '03', if '11' return '12'
      : getNextStringId(previousValue); // ex: if 'AB' return 'AC', if 'AZ' return 'BA'
  }

  const { AISLE, RACK, SHELF, TOTE } = FULFILLMENT_LOCATION_LEVELS_KEYS;

  switch (level) {
    case AISLE:
    case SHELF:
      return 'A';

    case RACK:
    case TOTE:
      return '01';

    default:
      break;
  }
};

export const fetchFulfillmentLocations = async ({
  setIsLoading,
  level,
  id,
  setList,
  callback = () => {},
  withSplit = false
}) => {
  setIsLoading(true);

  try {
    const response = await getLocationByLevel({
      level,
      id
    });
    const currentLevelResponseKey = Object.keys(response)[0];
    let newList = [];
    let newLocationLabelChain = {};

    if (Array.isArray(response[currentLevelResponseKey])) {
      newList = response[currentLevelResponseKey];
    } else {
      const { [currentLevelResponseKey]: list, ...rest } =
        response[currentLevelResponseKey];
      newList = list;
      newLocationLabelChain = rest;
    }

    if (!withSplit) {
      newList = newList.map(({ label, ...rest }) => ({
        label: label.slice(label.lastIndexOf('-') + 1),
        ...rest
      }));
    }

    setList(newList);
    callback({ newList, newLocationLabelChain });
  } catch (error) {
    notify(error.message);
  }

  setIsLoading(false);
};

export const getLocationURL = ({
  zoneId,
  aisleId,
  rackId,
  shelfId,
  lastLevelId
}) =>
  `${FULFILLMENT_LOCATIONS_MAIN_PATH}/${[
    zoneId,
    aisleId,
    rackId,
    shelfId,
    lastLevelId
  ]
    .filter(Boolean)
    .join('/')}`;
