import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import CreateEditStar from 'components/Stars/components/CreateEditStar/CreateEditStar';
import StarDetails from 'components/Stars/components/StarDetailsContainer/StarDetails/StarDetails';

const DEFAULT_TAB_PATH = 'cash-records';

const Stars = (props) => {
  const {
    match: { path }
  } = props;

  return (
    <Switch>
      <Route
        path={['/stars/all/:id/cash-records', '/stars/all/:id/data-log']}
        exact
        component={StarDetails}
      />
      <Route path={['/stars/all/:id/edit']} exact component={CreateEditStar} />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default withRouter(Stars);
