import http from 'utils/http.js';

export const getActions = ({ payload }) => {
  return http.post(`/action-center/items/search`, payload);
};

export const getAction = (actionId) => {
  return http.get(`action-center/items/${actionId}`);
};

export const actionSubmit = (id, payload) => {
  return http.put(`action-center/items/${id}`, payload);
};

export const exportActionItems = (payload) => {
  return http.post('/action-center/items/exportXlsx', payload);
};

export const getActionsSummary = () => {
  return http.get('action-center/actions-summary');
};

export const exportActionsSummary = (payload) => {
  return http.post('/action-center/actions-summary/exportXlsx', payload);
};

export const getRequests = (payload) => {
  return http.post('/requests/search', payload);
};

export const requestAction = ({ requestId, payload }) => {
  return http.put(`/requests/${requestId}`, payload);
};
