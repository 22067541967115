import { Tag } from 'antd';

import { getFulfillmentReturnToVendorLineSerialNumbers } from 'services/fulfillment-return-to-vendor';
import {
  SERIAL_NUMBERS_TYPES,
  renderSerialsView
} from 'constants/fulfillment-return-orders';
import { fmt } from 'IntlWrapper/IntlWrapper';

import CreateReturnToVendor from 'components/NewFulfillment/FulfillmentReturnToVendor/components/CreateReturnToVendor/CreateReturnToVendor';
import ReturnToVendorList from 'components/NewFulfillment/FulfillmentReturnToVendor/components/ReturnToVendorList/ReturnToVendorList';
import ReturnToVendor from 'components/NewFulfillment/FulfillmentReturnToVendor/components/ReturnToVendor/ReturnToVendor';

import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Chevron-left.svg';

export const FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH =
  '/fulfillment/return-to-vendor';

export const FULFILLMENT_RETURN_TO_VENDOR_PATH = `${FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH}/rtv/:id`;
export const CREATE_FULFILLMENT_RETURN_TO_VENDOR_PATH = `${FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH}/create`;

export const FULFILLMENT_RETURN_TO_VENDOR_ROUTES = [
  {
    path: FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH,
    name: 'Return To Vendor List',
    component: ReturnToVendorList,
    exact: true
  },
  {
    path: FULFILLMENT_RETURN_TO_VENDOR_PATH,
    name: 'Return To Vendor',
    component: ReturnToVendor,
    exact: true
  },
  {
    path: CREATE_FULFILLMENT_RETURN_TO_VENDOR_PATH,
    name: 'Create RTV',
    component: CreateReturnToVendor,
    exact: true
  }
];

export const RETURN_TO_VENDOR_STATE_IDS = {
  COMPLETED: 2
};

export const RETURN_TO_VENDOR_STATE_NAMES = {
  [RETURN_TO_VENDOR_STATE_IDS.COMPLETED]: 'completed'
};

export const RETURN_TO_VENDOR_OPTIONS_IDS = {
  RTV: {
    value: 'RTV',
    apiKey: 'rtvId',
    label: fmt({
      id: 'fulfillment_common.rtv'
    })
  },
  SERIAL: {
    value: 'SERIAL',
    apiKey: 'serialId',
    label: fmt({
      id: 'fulfillment_common.serial'
    })
  },
  SKU: {
    value: 'SKU',
    apiKey: 'sku',
    label: fmt({
      id: 'fulfillment_common.sku'
    })
  }
};

export const RETURN_TO_VENDOR_SEARCH_OPTIONS = [
  RETURN_TO_VENDOR_OPTIONS_IDS.RTV,
  RETURN_TO_VENDOR_OPTIONS_IDS.SERIAL,
  RETURN_TO_VENDOR_OPTIONS_IDS.SKU
];

export const RTV_KEY = 'RTV-';

export const RTV_LINE_TYPES = {
  PARTIAL: 'partial',
  DAMAGED: 'damaged',
  ALL: 'all'
};

export const RTV_LINE_TYPES_NAMES = {
  [RTV_LINE_TYPES.PARTIAL]: fmt({
    id: 'fulfillment_return_to_vendor.line_types.partial'
  }),
  [RTV_LINE_TYPES.DAMAGED]: fmt({
    id: 'fulfillment_return_to_vendor.line_types.damaged'
  }),
  [RTV_LINE_TYPES.ALL]: fmt({
    id: 'fulfillment_return_to_vendor.line_types.all'
  })
};

export const getRTVPathById = (id) =>
  `${FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH}/rtv/${id}`;

export const getRTVLineTypesOptions = (props = {}) => {
  const { disabledDamaged = false } = props;

  return [
    {
      label: RTV_LINE_TYPES_NAMES[RTV_LINE_TYPES.PARTIAL],
      value: RTV_LINE_TYPES.PARTIAL
    },
    {
      label: RTV_LINE_TYPES_NAMES[RTV_LINE_TYPES.DAMAGED],
      value: RTV_LINE_TYPES.DAMAGED,
      disabled: disabledDamaged
    },
    {
      label: RTV_LINE_TYPES_NAMES[RTV_LINE_TYPES.ALL],
      value: RTV_LINE_TYPES.ALL
    }
  ];
};

export const goToReturnToVendorList = () =>
  window.open(FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH, '_self');

export const renderReturnToVendorTag = ({ stateId }) => {
  const stateName = RETURN_TO_VENDOR_STATE_NAMES[stateId];
  if (!stateName) {
    return null;
  }

  const stateInsideIcon = {
    [RETURN_TO_VENDOR_STATE_IDS.COMPLETED]: <DoubleCheckIcon />
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_return_to_vendor.state_name.${stateName}`
        })}
        {stateInsideIcon[stateId]}
      </Tag>
    </div>
  );
};

export const renderSerialsViewForRTV = ({
  line,
  clickedLineData,
  setClickedLineData,
  fetchData
}) => {
  const { ON_SHELF, DAMAGED } = SERIAL_NUMBERS_TYPES;

  return renderSerialsView({
    clickedLineId: clickedLineData?.id,
    line,
    setClickedLineData,
    fetchData,
    formatTypedSerialsList: ({ damaged, onShelf }) => {
      return [
        {
          type: ON_SHELF,
          title: fmt({
            id: 'fulfillment_returns.serials_view.on_shelf.title'
          }),
          serials: onShelf
        },
        {
          type: DAMAGED,
          title: fmt({
            id: 'fulfillment_returns.serials_view.damaged.title'
          }),
          serials: damaged
        }
      ];
    },
    getSerialType: ({ damagedAt }) => (damagedAt ? DAMAGED : ON_SHELF)
  });
};

export const getViewReturnToVendorContentForRendering = ({
  returnToVendor,
  clickedLineData,
  setClickedLineData
}) => {
  const { state } = returnToVendor;
  const { COMPLETED } = RETURN_TO_VENDOR_STATE_IDS;

  const sharedHeaderProps = {
    icon: LeftArrowIcon,
    iconOnClick: goToReturnToVendorList
  };

  const sharedProductsTableColumns = [
    {
      dataIndex: 'type',
      title: fmt({
        id: 'fulfillment_common.return'
      }),
      render: (type) => RTV_LINE_TYPES_NAMES[type]
    },
    {
      dataIndex: 'quantity',
      title: fmt({
        id: 'fulfillment_common.qty'
      })
    },
    {
      render: (line = {}) =>
        renderSerialsViewForRTV({
          line,
          clickedLineData,
          setClickedLineData,
          fetchData: () =>
            getFulfillmentReturnToVendorLineSerialNumbers(line.id)
        })
    }
  ];

  const RETURN_TO_VENDOR_VIEW = {
    [COMPLETED]: {
      headerProps: sharedHeaderProps,
      productsTableColumns: sharedProductsTableColumns
    }
  };

  return RETURN_TO_VENDOR_VIEW[state?.stateId] || {};
};
