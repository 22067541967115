import React, { useEffect, useState } from 'react';
import { Timeline } from 'antd';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { useMaterialLogs } from 'utils/packaging';

import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as DownArrow } from 'assets/bosta-icons/Chevron-down.svg';
import { ReactComponent as UpArrow } from 'assets/bosta-icons/Chevron-up.svg';

import './MaterialLogs.less';

const MaterialLogs = ({
  callRefreshLogs,
  setRefreshLogs,
  tierName = false,
  isPackagingPage = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    logs,
    showLoadMore,
    isLoading,
    loadMoreLogs,
    haveManyLogs,
    refreshLogs
  } = useMaterialLogs(!!isPackagingPage);
  const intl = useIntl();
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (callRefreshLogs) {
      refreshLogs();
      setRefreshLogs(false);
    }
  }, [callRefreshLogs]);

  return (
    <LoadingWrapper loading={isLoading}>
      <div
        className={classNames(
          'br-shipment-details__tracking',
          {
            'br-shipment-details__tracking-expanded':
              isExpanded || !haveManyLogs
          },
          { 'br-shipment-tracking__many-logs': !haveManyLogs }
        )}
      >
        <span className="br-shipment-details__tracking-title subheading">
          {intl.formatMessage({ id: 'order_details.activity_log' })}
        </span>
        <Timeline className="br-shipment-details__tracking-timeline">
          {logs.map((log, index) => (
            <Timeline.Item key={index}>
              <p className="br-shipment-details_tracking-status body-medium">
                {log.logTitle}
              </p>
              <p className="br-shipment-details_tracking-taken-by caption">
                {log.takenBy}
              </p>

              <div className="br-details-status">
                <div className="br-shipment-details-logs">
                  <span className="br-shipment-details_tracking-details">
                    {log.logInfo} {!!log?.tierName && `in tier ${log.tierName}`}
                  </span>
                  {log.description && (
                    <span className="br-shipment-details_tracking-details-date caption">
                      {intl.formatMessage({
                        id: 'packaging.manage_materials.description'
                      })}
                      {': '}
                      {log.description}
                    </span>
                  )}

                  <span className="br-shipment-details_tracking-details-date">
                    {log.logDate}
                  </span>
                </div>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div className="br-material-logs__actions-container">
        {showLoadMore && isExpanded && (
          <BRButton
            type="link-color"
            className="button br-shipment-details__expand-collapse"
            label={intl.formatMessage({
              id: 'packaging.manage_materials.logs.load_more'
            })}
            onClick={loadMoreLogs}
          />
        )}
        {haveManyLogs && (
          <BRButton
            type="link-color"
            className="button br-shipment-details__expand-collapse"
            label={intl.formatMessage({
              id: `order_details.${isExpanded ? 'collapse' : 'expand'}`
            })}
            suffixIcon={
              <Icon
                component={isExpanded ? UpArrow : DownArrow}
                className="ant-icon-md"
              />
            }
            onClick={handleExpandClick}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default MaterialLogs;
