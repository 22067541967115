import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Tag } from 'antd';

import { SEAL_STATE, SEAL_STATE_COLOR } from 'constants/hubs';

import SealLogs from './components/SealLogs/SealLogs';
import SealsList from './components/SealsList/SealsList';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './SealsLogs.less';

const SealsLogs = ({ intl }) => {
  const [isSealLogsViewing, setIsSealLogsViewing] = useState(false);
  const [selectedSealData, setSelectedSealData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const viewSeal = (seal) => {
    setIsSealLogsViewing(true);
    setSelectedSealData(seal);
  };

  const hideSeal = () => {
    setIsSealLogsViewing(false);
    setSelectedSealData(null);
  };

  const renderState = (state) =>
    state ? (
      <Tag className={`br-seals-logs__tag ${SEAL_STATE_COLOR[state]}`}>
        {SEAL_STATE[state]}
      </Tag>
    ) : (
      '-'
    );

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-seals-logs">
        <SealLogs
          open={isSealLogsViewing}
          onClose={hideSeal}
          seal={selectedSealData}
          renderState={renderState}
        />
        <SealsList
          intl={intl}
          viewSeal={viewSeal}
          renderState={renderState}
          setIsLoading={setIsLoading}
        />
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(SealsLogs);
