import { injectIntl } from 'react-intl';

import { getFulfillmentBusinessTabsPath } from 'constants/fulfillment';

import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';
import { ReactComponent as ShopIcon } from 'assets/bosta-icons/Shop.svg';
import { ReactComponent as HashIcon } from 'assets/bosta-icons/HashIcon.svg';

import './BusinessInfoCard.less';

const BusinessInfoCard = ({ intl, businessInfo = {} }) => {
  const businessContent = [
    {
      icon: <ShopIcon />,
      content: businessInfo.businessName
    },
    {
      icon: <HashIcon />,
      content: businessInfo.businessId
    }
  ];

  return (
    <div className="br-info-card br-po-section">
      <div className="br-po-section__title">
        {intl.formatMessage({
          id: 'fulfillment_outbound.business_info.title'
        })}
      </div>
      <div className="br-po-section__content">
        <div>
          {businessContent.map(({ icon, content }, index) => (
            <div key={index} className="br-info-card__item">
              {icon}
              <span>{content}</span>
            </div>
          ))}
        </div>
        <a
          className="br-po-business-details__view-info"
          href={`${getFulfillmentBusinessTabsPath(
            businessInfo.businessId
          )}/info`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {intl.formatMessage({
            id: 'fulfillment_inbound.create_po.business_details.view_business'
          })}
          <ExternalLink />
        </a>
      </div>
    </div>
  );
};

export default injectIntl(BusinessInfoCard);
