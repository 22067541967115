import React, { useCallback, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Collapse, Dropdown, Menu } from 'antd';

import MultiSelectLookup from 'components/BRFilter/components/MultiSelectLookup/MultiSelectLookup';
import SingleSelectLookup from 'components/BRFilter/components/SingleSelectLookup/SingleSelectLookup';

import { ReactComponent as Filter } from 'assets/imgRevamp/filter.svg';

import './BRFilter.less';

export const filterTypes = {
  MULTI: 'MULTI',
  SINGLE: 'SINGLE',
  LOOKUP_SINGLE: 'LOOKUP_SINGLE'
};

const BRFilter = ({
  filterItems,
  onSubmitFilter = () => {},
  onResetFilter = () => {},
  intl,
  className,
  notCollapsable,
  title,
  isDisabled = false,
  placement = 'bottomRight'
}) => {
  const [filterState, setFilterState] = useState({});
  const [activeFilter, setActiveFilter] = useState([]);
  const filtersRef = useRef({});
  const filterItemChanged = (key, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const resetFilter = () => {
    setFilterState({});
    Object.values(filtersRef.current).forEach((item) => {
      item.clear();
    });
    onResetFilter();
  };

  const submitFilter = () => {
    onSubmitFilter(filterState);
  };

  const renderExpandIcon = useCallback(
    (item) => (
      <>
        {item.type === filterTypes.MULTI && (
          <Checkbox
            indeterminate={
              filterState[item.key]?.length !== item.lookup.length &&
              !!filterState[item.key]?.length
            }
            checked={!!filterState[item.key]?.length}
            onClick={(e) => {
              e.stopPropagation();
              const { clear, selectAll } = filtersRef?.current?.[item.key];
              if (
                !filterState[item.key] ||
                filterState[item.key].length < item.lookup.length
              ) {
                selectAll();
                setFilterState((prevState) => ({
                  ...prevState,
                  [item.key]: item.lookup.map((val) => val.value)
                }));
              } else {
                clear();
                setFilterState((prevState) => ({
                  ...prevState,
                  [item.key]: undefined
                }));
              }
            }}
          />
        )}
        <span className="br-filter__checkbox__text">{item.header}</span>
      </>
    ),
    [filterState, filtersRef]
  );

  const getFilterComponent = (type) => {
    switch (type) {
      case filterTypes.LOOKUP_SINGLE:
        return SingleSelectLookup;
      case filterTypes.MULTI:
      default:
        return MultiSelectLookup;
    }
  };

  const renderLookupItem = useCallback(
    (key) => {
      const item = filterItems.find((val) => val.key === key);
      const Filter = getFilterComponent(item.type);
      return (
        <Filter
          ref={(el) => (filtersRef.current[item.key] = el)}
          lookup={item.lookup}
          onChange={(value) => filterItemChanged(item.key, value)}
          name={item.key}
        />
      );
    },
    [filterItems]
  );
  const notCollapsableProps = () => {
    return notCollapsable
      ? { defaultActiveKey: filterItems[0].key, collapsible: 'disabled' }
      : { activeKey: activeFilter };
  };
  const menu = () => {
    return (
      <Menu className="br-filter__menu">
        <div className="br-filter__container">
          <div className="display-flex justify-space-between p-2 br-filter__container__header">
            <Button size="small" onClick={resetFilter}>
              {' '}
              Reset
            </Button>
            <Button type="primary" size="small" onClick={submitFilter}>
              {intl.formatMessage({ id: 'common.done' })}
            </Button>
          </div>
          <Collapse
            onChange={(active) => {
              setActiveFilter(active);
            }}
            {...notCollapsableProps()}
            accordion
          >
            {filterItems.map((item) => (
              <Collapse.Panel
                key={item.key}
                header={renderExpandIcon(item)}
                showArrow={false}
                forceRender
              >
                {renderLookupItem(item.key)}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </Menu>
    );
  };

  return (
    <div>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={isDisabled}
        placement={placement}
      >
        <Button className="br-filter__trigger">
          <Filter /> {title || intl.formatMessage({ id: 'common.filter' })}
        </Button>
      </Dropdown>
    </div>
  );
};

export default injectIntl(BRFilter);
