import { Link, useLocation } from 'react-router-dom';
import Icon from '@ant-design/icons';
import classNames from 'classnames';

import { ReactComponent as ArrowRightIcon } from 'assets/bosta-icons/Arrow-right.svg';

import './LocationCard.less';

const LocationCard = ({
  title,
  subtitle,
  currentLevelId,
  hasChild,
  hasParent
}) => {
  const { pathname } = useLocation();

  const renderTitle = () => {
    const lastDashIndex = title.lastIndexOf('-') + 1;
    const parents = title.substring(0, lastDashIndex);
    const child = title.substring(lastDashIndex);

    return (
      <div className="br-fulfillment-location-card__title">
        {parents}
        <span className="display-lg">{child}</span>
      </div>
    );
  };

  return (
    <Link
      to={hasChild ? `${pathname}/${currentLevelId}` : ''}
      className={classNames('br-fulfillment-location-card', {
        'not-have-child': !hasChild,
        'has-parent': hasParent
      })}
    >
      <div>
        {renderTitle()}
        <div className="br-fulfillment-location-card__subtitle">{subtitle}</div>
      </div>
      {hasChild && (
        <Icon
          className="br-fulfillment-location-card__icon"
          component={ArrowRightIcon}
        />
      )}
    </Link>
  );
};

export default LocationCard;
