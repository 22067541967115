import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { CAPITAL_ROUTES_URL } from 'constants/borrow';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';

import BostaCapitalSettings from './BostaCapitalSettings/BostaCapitalSettings';
import BostaCapital from './BostaCapital';

const CapitalRoutes = ({ match: { path } }) => {
  const CAPITAL_ROUTES = [
    {
      path: CAPITAL_ROUTES_URL.CAPITAL,
      name: 'Bosta Capital',
      component: BostaCapital,
      access: aclMatrix.DELIVERIES,
      permission: [ACL_MATRIX.FUNDING_PROFILES_GET]
    },
    {
      path: CAPITAL_ROUTES_URL.SETTINGS,
      name: 'Capital Routes',
      exact: true,
      component: BostaCapitalSettings,
      access: aclMatrix.DELIVERIES,
      permission: [ACL_MATRIX.CAPITAL_CONFIGURATIONS_GET]
    }
  ];

  const getFirstAcceessibleRoute = () => {
    return CAPITAL_ROUTES.find((route) =>
      isUserAuthorized(route.access, route.permission)
    ).path;
  };

  return (
    <Switch>
      {CAPITAL_ROUTES.map(
        (route, index) =>
          isUserAuthorized(route.access, route.permission) && (
            <Route key={index} {...route} />
          )
      )}
      <Redirect from={path} to={getFirstAcceessibleRoute()} />
    </Switch>
  );
};

export default withRouter(CapitalRoutes);
