import Icon from '@ant-design/icons';

import { ReactComponent as BostaBoxIcon } from 'assets/bosta-icons/BostaBox.svg';

import './InternationalOrdersTag.less';

const InternationalOrdersTag = () => {
  return (
    <div className="br-international-order__tag">
      <Icon component={BostaBoxIcon} className="ant-icon-sm" />
    </div>
  );
};

export default InternationalOrdersTag;
