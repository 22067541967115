import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import Container from 'components/Container';
import { Accordion, Button, Col, Form } from 'react-bootstrap';
import { get as getValue, omitBy } from 'lodash';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Select from 'react-select';
import {
  exportHubTransfersReportAsCSV,
  getHubs,
  getHubTransfersReport,
  getVerifiedStars,
  clearHubTransfersReport
} from '../../actions';
import { FORMAT_DATE_TO_UTC } from 'utils/helpers';
import Calendar from '../../components/Calendar';
import { notify } from 'components/Notify/Notify';
import HubManagmentContainer from './HubManagmentContainer';
import { hubTransferReportColumns, transferStates } from './constants';
import styles from './styles.module.scss';
import BRHeader from 'components/BRHeader/BRHeader';
// import 'antd/es/pagination/style/index.css';
// import 'antd/es/table/style/index.css';

/**
 * format options to be bound in select controls
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue };
    });
  }
  return data.map((item) => ({ value: item, label: item }));
};

/**
 * format data read for binding in table
 * @param data
 * @param stars
 * @returns {*}
 */
const formatDatasource = (data, { stars }) => {
  return data.map(
    ({
      _id,
      starId,
      numberOfPackagesTransferred,
      numberOfPackagesReceived,
      transferDate,
      transferState,
      originWarehouse,
      destinationWarehouse
    }) => {
      const star = stars.find((el) => el._id === starId);
      return {
        containerID: _id,
        key: _id,
        starId: star
          ? `${star.profile.firstName} ${star.profile.lastName} (${starId})`
          : '',
        originHub: originWarehouse.name,
        destinationHub: destinationWarehouse.name,
        numberOfPackagesTransferred,
        numberOfPackagesReceived,
        transferDate: dayjs(new Date(transferDate)).tz().format('LLL'),
        transferState
      };
    }
  );
};

/**
 * Date range picker used in search form
 * @param name
 * @param label
 * @param handleChange
 * @param value
 * @returns {*}
 * @constructor
 */
const DateRangePicker = ({ name, label, handleChange, value }) => {
  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates && dates.length > 0) handleChange(`${name}`, dateStrings, true);
    else {
      handleChange(`${name}`, '', true);
    }
  };
  return (
    <>
      <Form.Group as={Col} sm={3} controlId={`${name}-control`}>
        <Form.Label className={styles.formLabel}>{label}</Form.Label>
        <Calendar
          handleChange={handleDateRangeChange}
          placeholder={['', '']}
          value={value[0] !== null ? [dayjs(value[0]), dayjs(value[1])] : null}
        />
      </Form.Group>
    </>
  );
};

/**
 * Search form used to apply filter and search deliveries
 * @param businesses
 * @param stars
 * @param hubs
 * @param handleFormChange
 * @param handleFormSubmit
 * @param handleResetForm
 * @param formLoading
 * @returns {*}
 * @constructor
 */
const SearchForm = ({
  hubs,
  stars,
  handleFormChange,
  handleFormSubmit,
  handleResetForm,
  formLoading
}) => {
  const [openAccordion, setOpenAccordion] = useState(true);

  return (
    <>
      <Accordion>
        <div>
          <Accordion.Toggle
            as="a"
            eventKey="0"
            variant="button"
            onClick={() => setOpenAccordion(!openAccordion)}
            className={
              openAccordion
                ? styles.SearchToggleOpen
                : styles.SearchToggleClosed
            }
          >
            Search
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="mt-2">
              <Formik
                className="formContainer"
                enableReinitialize
                validationSchema={{}}
                onChange={handleFormChange}
                onSubmit={handleFormSubmit}
                initialValues={{
                  containerIds: '',
                  starId: [],
                  states: '',
                  destinationHubs: '',
                  originHubs: null,
                  startDate: null,
                  endDate: null
                }}
                render={({
                  // handleSubmit,
                  // handleChange,
                  handleBlur,
                  values,
                  // touched,
                  initialValues,
                  setFieldValue,
                  // errors
                  resetForm
                }) => (
                  <Form
                    className={styles.formWidth}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleFormSubmit();
                    }}
                  >
                    {/* Containers Ids */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={6}
                        controlId="containerIds-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Container ID(s):
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.containerIds}
                          onBlur={handleBlur}
                          onChange={(evt) => {
                            handleFormChange(
                              'containerIds',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'containerIds',
                              evt.currentTarget.value
                            );
                          }}
                          name="containerIds"
                        />
                      </Form.Group>
                    </Form.Row>

                    {/* Origin Hub, Destination Hubs */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={6}
                        controlId="originHubs-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Origin Hub(s)
                        </Form.Label>
                        <Select
                          name="originHubs"
                          options={formatOptions('_id', 'name', hubs)}
                          value={values.originHubs || ''}
                          isClearable
                          isMulti
                          onChange={(evt) => {
                            handleFormChange(
                              'originHubs',
                              evt ? evt.map((el) => el.value) : null
                            );
                            setFieldValue('originHubs', evt);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={6}
                        controlId="destinationHubs-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Destination Hub(s)
                        </Form.Label>
                        <Select
                          name="destinationHubs"
                          options={formatOptions('_id', 'name', hubs)}
                          value={values.destinationHubs || ''}
                          isClearable
                          isMulti
                          onChange={(evt) => {
                            handleFormChange(
                              'destinationHubs',
                              evt ? evt.map((el) => el.value) : null
                            );
                            setFieldValue('destinationHubs', evt);
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    {/* Stars, States */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} sm={6} controlId="stars-control">
                        <Form.Label className={styles.formLabel}>
                          Star(s)
                        </Form.Label>
                        <Select
                          name="starId"
                          options={formatOptions(
                            '_id',
                            'profile.firstName,profile.lastName',
                            stars
                          )}
                          value={values.starId}
                          isMulti
                          isClearable
                          onChange={(evt) => {
                            handleFormChange(
                              'starId',
                              evt ? evt.map((el) => el.value) : null
                            );
                            setFieldValue('starId', evt);
                          }}
                        />
                      </Form.Group>
                      {/* <Form.Group as={Col} sm={6} controlId='states-control'>
                      <Form.Label className={styles.formLabel}>State(s)</Form.Label>
                      <Select
                        name="states"
                        options={formatOptions(null, null, transferStates)}
                        value={values.states}
                        isMulti
                        isClearable
                        onChange={evt => {
                          handleFormChange("states", evt ? evt.map(el => el.value) : null);
                          setFieldValue("states", evt);
                        }}
                      />
                    </Form.Group> */}
                    </Form.Row>
                    {/* Dates section */}
                    <Form.Row className={styles.formRow}>
                      <DateRangePicker
                        label="Transfer Date:"
                        handleChange={(name, value, isDateRange) => {
                          handleFormChange(name, value, isDateRange);
                          setFieldValue(`startDate`, value[0]);
                          setFieldValue(`endDate`, value[1]);
                        }}
                        name="transferDate"
                        value={[values.startDate, values.endDate]}
                      />
                    </Form.Row>
                    {/* submit area */}
                    <div>
                      <Button
                        type="submit"
                        className="primaryButton"
                        disabled={formLoading}
                      >
                        Filter
                      </Button>
                      <Button
                        type="button"
                        className="secondaryButton"
                        onClick={() => {
                          handleResetForm();
                          resetForm(initialValues);
                        }}
                        disabled={formLoading}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

function HubTransfer({
  hubs,
  stars,
  hubTransfers,
  fetchHubs,
  fetchStars,
  fetchHubTransfersReport,
  getHubTransfersReportCSV,
  clearHubTransferReport,
  loading
}) {
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchHubs();
  }, [fetchHubs]);
  useEffect(() => {
    fetchStars();
    return function cleanup() {
      clearHubTransferReport();
    };
  }, [fetchStars]);
  const [formValues, setFormValues] = useState({
    containerIds: '',
    starId: [],
    states: '',
    destinationHubs: '',
    originHubs: null,
    transferDateFrom: null,
    transferDateTo: null
  });
  const getCurrentFilter = () => {
    return omitBy({ ...formValues /* state */ }, (value) => {
      if ((typeof value === 'string' && value === '') || value === null) {
        return true;
      }
      return Array.isArray(value) && value.length === 0;
    });
  };
  const checkRequiredFilters = (fn) => () => {
    const currentFilter = getCurrentFilter();
    if (Object.entries(currentFilter).length === 0) {
      notify('Please apply at least one filter!');
      return;
    }
    /* if (!currentFilter.hub) {
      notify("Container ID is mandatory filter!");
      return;
    } */
    fn(currentFilter);
  };
  const handleSubmit = checkRequiredFilters((filter) => {
    for (const key in filter) {
      if (dayjs(filter[key]).isValid() && key.includes('Date')) {
        filter[key] = FORMAT_DATE_TO_UTC(filter[key]);
      }
    }
    fetchHubTransfersReport({
      ...filter,
      pageId: hubTransfers.pagination.pageId || 0
      // limit: (moneyDebriefReport.pagination.pageSize || 25)
    });
  });
  const handleFormReset = () => {
    setFormValues({
      containerIds: null,
      starId: null,
      states: null,
      destinationHubs: null,
      originHubs: null,
      transferDateFrom: null,
      transferDateTo: null
    });
    // clearReportData();
  };
  const handleFormChange = (name, value, isDateRange) => {
    if (!isDateRange) {
      setFormValues({
        ...formValues,
        [name]: value
      });
    } else {
      setFormValues({
        ...formValues,
        [`startDate`]: value[0],
        [`endDate`]: value[1]
      });
    }
  };

  const handleExportCSV = checkRequiredFilters((filter) => {
    getHubTransfersReportCSV({
      ...filter,
      pageId: undefined
    });
  });

  return (
    <Container
      className="br-tabs-container-content"
      hideWarningBanner
      header={<BRHeader title="Hub Transfer" />}
      content={
        <>
          <HubManagmentContainer
            children={
              <>
                <SearchForm
                  hubs={hubs}
                  stars={stars.data}
                  handleResetForm={handleFormReset}
                  handleFormChange={handleFormChange}
                  handleFormSubmit={handleSubmit}
                />
                <div className={styles.divHub}>
                  <Button
                    disabled={loading}
                    className="primaryButton"
                    onClick={handleExportCSV}
                  >
                    Export CSV
                  </Button>
                </div>
                {hubTransfers.data.length > 0 && (
                  <div className="mb-4 mt-4">
                    <b>Total Result:</b>
                    <span className="ml-2 ">{hubTransfers.count}</span>
                  </div>
                )}
                <Table
                  className="my-3"
                  style={{ overflow: 'auto' }}
                  columns={hubTransferReportColumns}
                  dataSource={formatDatasource(hubTransfers.data, {
                    stars: stars.data
                  })}
                  loading={loading}
                  pagination={{
                    current: hubTransfers.pagination.pageId
                      ? hubTransfers.pagination.pageId + 1
                      : 1,
                    total: hubTransfers.count,
                    pageSize: hubTransfers.pagination.limit || 50,
                    showSizeChanger: false
                  }}
                />
              </>
            }
          />
        </>
      }
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs()),
  fetchStars: () => dispatch(getVerifiedStars()),
  fetchHubTransfersReport: (data) => dispatch(getHubTransfersReport(data)),
  getHubTransfersReportCSV: (data) =>
    dispatch(exportHubTransfersReportAsCSV(data)),
  clearHubTransferReport: (data) => dispatch(clearHubTransfersReport(data))
});
const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubTransfer);
