import { useEffect, useState } from 'react';

import { getFulfillmentReturnOrder } from 'services/fulfillment-return-orders';
import {
  getReturnOrderRenderedTag,
  getViewReturnOrderContentForRendering,
  isFulfillmentCRP,
  isFulfillmentExchangeReturn
} from 'constants/fulfillment-return-orders';

import BusinessInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/BusinessInfoCard/BusinessInfoCard';
import CustomerInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/CustomerInfoCard/CustomerInfoCard';
import OrderLogsCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/OrderLogsCard/OrderLogsCard';
import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import Products from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/Products';
import TotalOrdersPrice from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/TotalOrdersPrice/TotalOrdersPrice';
import LinkedOrdersCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/LinkedOrdersCard/LinkedOrdersCard';

const ReturnOrder = ({
  match: {
    params: { id }
  }
}) => {
  const [returnOrder, setReturnOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [clickedLineData, setClickedLineData] = useState(null);

  const fetchReturnOrder = async () => {
    setIsLoading(true);

    const payload = {
      linesType: 'all'
    };

    try {
      const { data = {} } = await getFulfillmentReturnOrder(id, payload);
      setReturnOrder(data.returnOrder);
      setProducts(data.returnOrder?.lines || []);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const { productsTableColumns, headerProps = {} } =
    getViewReturnOrderContentForRendering({
      isLoading,
      setIsLoading,
      returnOrder,
      products,
      clickedLineData,
      setClickedLineData
    });

  useEffect(() => {
    fetchReturnOrder();
  }, []);

  return (
    <div className="br-sales-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader
            title={returnOrder.trackingNumber}
            secondaryTitle={`(#${returnOrder.id || ''})`}
            renderTag={() =>
              getReturnOrderRenderedTag({
                stateId: returnOrder.state?.stateId,
                typeId: returnOrder.type?.typeId
              })
            }
            {...headerProps}
          />
          <div className="br-po-content">
            <div className="start-side">
              <BusinessInfoCard
                businessInfo={{
                  businessName: returnOrder.businessName,
                  businessId: returnOrder.businessId
                }}
              />
              <CustomerInfoCard customerInfo={returnOrder.customer} />
              <LinkedOrdersCard
                soId={returnOrder.originalSalesOrderId || returnOrder.soId}
                pjIds={returnOrder.pjIds}
                exchangeSoId={
                  isFulfillmentExchangeReturn(returnOrder.type) &&
                  returnOrder.soId
                }
              />
              <OrderLogsCard orderLogs={returnOrder.history?.reverse()} />
            </div>
            <div className="end-side">
              <Products
                products={products}
                columns={productsTableColumns}
                hideSelectProductBtn
                hideOverlay
              />
              {!isFulfillmentCRP(returnOrder.type) && (
                <TotalOrdersPrice
                  cod={returnOrder.cod}
                  totalPrice={returnOrder.totalPrice}
                />
              )}
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default ReturnOrder;
