import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Input, Form, Select } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import { mediaHook } from 'customHooks';

import { getPurchaseDetails } from 'services/fulfillment';
import { checkDate, dates } from 'utils/helpers';
import { INVENTORY_PAGE_SIZE_OPTIONS } from 'constants/fulfillment';

import { notify } from 'components/Notify/Notify';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import Header from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import BRTable from 'components/BRTable/BRTable';
import BRFormInputCounter from 'components/BRFormInputCounter/BRFormInputCounter';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Product } from 'assets/bosta-icons/Product.svg';
import { ReactComponent as Dropdown } from 'assets/bosta-icons/Dropdown.svg';
import { ReactComponent as Calendar } from 'assets/imgRevamp/calender-icon.svg';
import { ReactComponent as Clock } from 'assets/bosta-icons/Clock.svg';

import './RequestDetails.less';

const PAGE_SIZE = 50;
const PRODCUTS_COLUMNS = [
  {
    dataIndex: 'product_image',
    render: (productImage) => (
      <span className="br-inventory-request-details__product-image">
        {productImage ? (
          <img src={productImage} alt="product details image" />
        ) : (
          <Product />
        )}
      </span>
    ),
    width: 65
  },
  {
    title: fmt({ id: 'fulfillment.inventory_request_details.products' }),
    dataIndex: 'product_id',
    render: (product_id, { product_sku }) => (
      <span>
        {product_id?.length && (
          <div className="br-inventory-request-details__product-name">
            {product_id[1]}
          </div>
        )}
        <div className="br-inventory-request-details__product-sku caption">
          {fmt({ id: 'fulfillment.inventory_request_details.sku' })}:
          {product_sku}
        </div>
      </span>
    )
  },
  {
    title: fmt({ id: 'fulfillment.inventory_request_details.sku' }),
    dataIndex: 'product_sku'
  },
  {
    title: fmt({ id: 'fulfillment.inventory_request_details.quantity' }),
    dataIndex: 'product_qty',
    render: (quantity) => (
      <Input
        value={quantity}
        disabled
        addonAfter={
          <div
            className={classnames(
              'br-inventory-request-details__quantity-column',
              {
                'br-inventory-request-details__quantity-column__disabled': true
              }
            )}
          >
            <Icon component={Dropdown} className="ant-icon-sm" />
            <Icon component={Dropdown} className="ant-icon-sm" />
          </div>
        }
      />
    )
  },
  {
    title: fmt({ id: 'fulfillment.inventory_request_details.recieved' }),
    dataIndex: 'qty_received',
    render: (recieved, request) => (
      <span
        className={classnames({
          'br-inventory-request-details__differnet-quantity':
            request?.product_qty !== recieved
        })}
      >
        {recieved}
      </span>
    )
  }
];

const RequestDetails = ({
  intl,
  match: {
    params: { id: requestId, bussinessId }
  },
  history,
  mobileScreenSizes: { isMediumMobileScreen }
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [PODetails, setPODetails] = useState({});
  const [count, setCount] = useState(0);

  const handleCloseDetails = () => {
    history.push({ pathname: '/inventory/requests', state: { bussinessId } });
  };

  const getProductsList = async ({ page, limit }) => {
    try {
      setIsLoading(true);
      const {
        data: { data, count }
      } = await getPurchaseDetails(
        {
          id: requestId
        },
        { bussinessId, page, limit }
      );
      setCount(count);
      if (data[0]?.date_order_str) {
        const formattedDate = new Date(data[0].date_order_str);
        formattedDate.setHours(formattedDate.getHours() + 2);
        const date = checkDate(formattedDate);
        const newFormattedDate = dates(formattedDate, 'D MMM YYYY');
        setPODetails({
          ...data[0],
          date: date ? `${date}, ${newFormattedDate}` : newFormattedDate,
          time: dates(formattedDate, 'h:mm A')
        });
      } else {
        setPODetails({
          ...data[0],
          date: intl.formatMessage({ id: 'common.empty_field' })
        });
      }
      setIsLoading(false);
      return {
        total: count,
        list: data
      };
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  const pageTitle = () => (
    <span className="display-sm">
      <span className="br-inventory-request-details__header-close-icon">
        <Close className="ant-icon-md" onClick={handleCloseDetails} />
      </span>
      {intl.formatMessage(
        { id: 'fulfillment.inventory_request_details.page_title' },
        {
          requestId: history?.location?.state?.name || requestId
        }
      )}
    </span>
  );

  return (
    <Container
      className="br-inventory-request-details"
      header={
        <Header
          title={pageTitle()}
          className={classnames({
            'br-inventory-request-details__header': !isMediumMobileScreen
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-inventory-request-details__content">
            <div className="br-inventory-request-details__content__left-section">
              <span className="heading">
                {intl.formatMessage({
                  id: 'fulfillment.inventory_request_details.products'
                })}
              </span>
              <span>
                <BRTable
                  showFilter={false}
                  showSearch={false}
                  listFunction={getProductsList}
                  columns={PRODCUTS_COLUMNS}
                  // pagination={count > 50}
                  pageSizeOptions={INVENTORY_PAGE_SIZE_OPTIONS}
                  scroll={false}
                />
              </span>
            </div>
            <div className="br-inventory-request-details__content__right-section">
              <span className="heading">
                {intl.formatMessage({
                  id: 'fulfillment.inventory_request_details.shipment_details'
                })}
              </span>
              <Form>
                <Form.Item
                  name="bosta_warehouse"
                  label={intl.formatMessage({
                    id: 'fulfillment.inventory_request_details.sending_to'
                  })}
                >
                  <Select
                    defaultValue={
                      PODetails?.picking_type_id?.length
                        ? PODetails?.picking_type_id[1]
                        : history?.location?.state?.warehouse ||
                          intl.formatMessage({ id: 'common.empty_field' })
                    }
                    disabled
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="bosta_warehouse"
                  label={intl.formatMessage({
                    id: 'fulfillment.inventory_request_details.date'
                  })}
                >
                  <Select defaultValue="1" disabled>
                    <Select.Option key="1" value="1">
                      <span className="display-flex align-center">
                        <Calendar className="br-inventory-request-details__select-icon" />
                        {PODetails?.date}
                      </span>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="bosta_warehouse"
                  label={intl.formatMessage({
                    id: 'fulfillment.inventory_request_details.time'
                  })}
                >
                  <Select defaultValue="1" disabled>
                    <Select.Option key="1" value="1">
                      <span className="display-flex align-center">
                        <Clock className="br-inventory-request-details__select-icon" />
                        {PODetails?.time}
                      </span>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <BRFormInputCounter
                  label={intl.formatMessage({
                    id: 'fulfillment.inventory_request_details.notes'
                  })}
                  value={PODetails?.additional_notes}
                  optional
                >
                  <Input.TextArea
                    disabled
                    placeholder={intl.formatMessage({
                      id: 'fulfillment.inventory_request_details.notes_placeholder'
                    })}
                    autoSize={{ minRows: 3.1, maxRows: 3.1 }}
                    autoComplete={'' + Math.random()}
                    allowClear
                  />
                </BRFormInputCounter>
              </Form>
            </div>
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default withRouter(injectIntl(mediaHook(RequestDetails)));
