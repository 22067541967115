export const PICKUP_CUTOFF_TIME = {
  EG: 16,
  SA: 22
};

export const FULFILLMENT_INBOUND_CUTOFF_TIME = {
  DEFAULT: 19
};

export const WORKING_DAYS = {
  DEFAULT: [0, 1, 2, 3, 4, 6]
};
