import actionTypes from '../actions/ActionTypes';

const initialState = {
  hubs: [],
  stars: { count: 0, data: [] },
  hub: undefined,
  loading: false,
  moneyDebriefReport: { count: 0, data: [], pagination: {} },
  hubTransfers: { count: 0, data: [], pagination: {} },
  moneyDebriefReportFiltered: undefined,
  hubStars: { count: 0, data: [], pagination: {} },
  starDayReport: {
    routesIds: [],
    pickupRequestIds: 0,
    pickedupDeliveries: { count: 0, data: [] },
    outForDelivery: { count: 0, data: [] },
    deliveredDeliveries: { count: 0, data: [] },
    exceptionDeliveries: { count: 0, data: [] },
    totalStarCod: 0,
    loaded: false,
  },
  starLiabilities: { error: true, totalLiability: 0 },
  currentUserHubInfo: null,
  currentTransferItems: undefined,
  currentUserHubId: null,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_HUBS_PAGED_RECEIVED:
      return { ...state, hubs: action.payload };
    case actionTypes.GET_HUB_RECEIVED:
      return { ...state, hub: action.payload };
    case actionTypes.GET_STARS_RECEIVED:
      return { ...state, stars: action.payload };
    case actionTypes.GET_MONEY_DEBRIEF_REPORT_RECEIVED:
      return { ...state, moneyDebriefReport: action.payload };
    case actionTypes.CLEAR_MONEY_DEBRIEF_REPORT:
      return {
        ...state,
        moneyDebriefReport: { count: 0, data: [], pagination: {} },
      };
    case actionTypes.GET_MONEY_DEBRIEF_REPORT_FILTERED_RECEIVED:
      return { ...state, moneyDebriefReportFiltered: action.payload };
    case actionTypes.GET_HUB_TRANSFER_REPORT_RECEIVED:
      return { ...state, hubTransfers: action.payload };
    case actionTypes.CLEAR_HUB_TRANSFER_REPORT:
      return { ...state, hubTransfers: { count: 0, data: [], pagination: {} } };
    case actionTypes.GET_STARS_BY_HUB_RECEIVED:
      return { ...state, hubStars: action.payload };
    case actionTypes.GET_STAR_DAY_REPORT_RECEIVED:
      return { ...state, starDayReport: action.payload };
    case actionTypes.GET_STAR_LIABILITIES_RECEIVED:
      return { ...state, starLiabilities: action.payload };
    case actionTypes.GET_CURRENT_HUB_INFO_RECEIVED:
      return { ...state, currentUserHubInfo: action.payload };

    case actionTypes.GET_CURRENT_HUB_ID_RECIEVED:
      return { ...state, currentUserHubId: action.payload };
    case actionTypes.GET_INCOMING_TRANSFER_ITEMS_RECEIVED:
      return { ...state, currentTransferItems: action.payload };
    case actionTypes.CLEAR_STAR_DEBRIEF_REPORT:
      return {
        ...state,
        starLiabilities: { error: true, totalLiability: 0 },
        starDayReport: {
          routesIds: [],
          pickupRequestIds: 0,
          pickedupDeliveries: { count: 0, data: [] },
          outForDelivery: { count: 0, data: [] },
          deliveredDeliveries: { count: 0, data: [] },
          exceptionDeliveries: { count: 0, data: [] },
          totalStarCod: 0,
          loaded: false,
        },
        hubStars: { count: 0, data: [], pagination: {} },
      };
    case actionTypes.GET_HUB_TRANSFER_REPORT_CSV_RECEIVED:
      return { ...state, loading: false };

    case actionTypes.GET_HUB_TRANSFER_REPORT_CSV:
      return { ...state, loading: true };

    default:
      return state;
  }
}
