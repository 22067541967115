import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';

import { printAirwaybill } from 'services/shipments';
import { downloadAsPdf } from 'utils/download';

import { notify } from 'components/Notify/Notify'
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';

import './OrderSummary.less';

const OrderSummary = ({ intl, history, location, match }) => {
  const { successResponse } = location.state ? location.state : false;
  const handlePrintAirwaybillClick = useCallback(async () => {
    try {
      const { data: pdfData } = await printAirwaybill(successResponse?._id);
      downloadAsPdf(
        pdfData,
        `shipment_${successResponse?.trackingNumber}.pdf`,
        intl.formatMessage({
          id: `shipments.new_order.order_summary.download_airwaybill_successfully`,
        }),
      );
    } catch (e) {
      notify(e.message, 'info');
    }
  }, [successResponse]);

  useEffect(() => {
    if (successResponse) {
      notify(successResponse?.message, 'success');
    } else if (!match.params.id) {
      history.push('/deliveries/create');
    }
  },[successResponse]);

  return (
    <Container
      header={<BRHeader title="" />}
      content={
        <div
          className={classnames('br-order-summary', {
            'br-order-summary_vertical-alignment': !successResponse?.awb,
          })}
        >
          <CheckCircleFilled />
          <div className="br-order-summary__header">
            <span className="br-order-summary__title">
              {intl.formatMessage({
                id: `shipments.new_order.order_summary.order_created_successfully`,
              })}
            </span>
            {successResponse?.awb && (
              <p className="br-order-summary__sub-title">
                {intl.formatMessage({
                  id: `shipments.new_order.order_summary.air_waybills_note`,
                })}
              </p>
            )}
          </div>
          {successResponse?.awb && (
            <div className="br-order-summary__card">
              <div>
                <img
                  src={`data:image/jpeg;base64,${successResponse?.awb}`}
                  className="br-order-summary__card__img"
                />
              </div>
              <div className="br-order-summary__card__footer">
                <Button
                  className="br-order-summary__card__print-button"
                  type="primary"
                  onClick={handlePrintAirwaybillClick}
                >
                  {intl.formatMessage({
                    id: `shipments.new_order.actions.print_awb`,
                  })}
                </Button>
                <p className="br-order-summary__card__print-note">
                  {intl.formatMessage({
                    id: `shipments.new_order.order_summary.skip_printing_note`,
                  })}
                </p>
              </div>
            </div>
          )}
          <div className="br-order-summary__footer">
            <Button
              className="br-order-summary__footer_create-more-orders-button"
              onClick={() => {
                history.replace('/deliveries/create');
              }}
            >
              {intl.formatMessage({
                id: `shipments.new_order.actions.create_more_orders`,
              })}
            </Button>
            <Button
              onClick={() => {
                history.replace('/deliveries');
              }}
              type="primary"
            >
              {intl.formatMessage({
                id: `shipments.new_order.actions.view_orders`,
              })}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default injectIntl(withRouter(OrderSummary));
