import { useState } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';

const ConfirmUploadSheetModal = ({
  onSuccess,
  close,
  onSumbit,
  businessCount,
  intl,
  bodyText,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleOnOk = async () => {
    setIsLoading(true);
    try {
      await onSuccess();
    } catch (error) {}
    close();
    setIsLoading(false);
  };
  return (
    <Modal
      onCancel={close}
      {...props}
      title={null}
      width={null}
      onOk={handleOnOk}
      okButtonProps={{
        loading: isLoading
      }}
    >
      <p>
        {bodyText ||
          intl.formatMessage(
            {
              id: 'wallet.business_configuration.confirm_modal.title'
            },
            {
              businessCount: businessCount
            }
          )}
      </p>
      <p>
        {intl.formatMessage({
          id: 'wallet.business_configuration.confirm_modal.sub_title'
        })}
      </p>
    </Modal>
  );
};

export default injectIntl(ConfirmUploadSheetModal);
