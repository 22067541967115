import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Select, Button } from 'antd';

import { getHubs } from 'services/hubs';
import { SORTING_HUB } from 'constants/hubs';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './AssignHubModal.less';

const AssignHubModal = ({ visible, close, intl, onConfirm, fetchedHubs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allHubs, setAllHubs] = useState(fetchedHubs || []);
  const [selectedHubId, setSelectedHubId] = useState(null);

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(result);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!fetchedHubs) {
      getAllHubs();
    }
  }, []);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(selectedHubId);
    setIsLoading(false);
    close();
  };

  const renderHubsDropdown = () =>
    allHubs.map(
      (hub, index) =>
        hub.type !== SORTING_HUB && (
          <Select.Option key={index} value={hub._id}>
            {hub.name}
          </Select.Option>
        )
    );

  return (
    <LoadingWrapper loading={isLoading}>
      <Modal
        visible={visible}
        title={intl.formatMessage({
          id: 'action_center.select_hub_placeholder'
        })}
        footer={null}
        centered
        onCancel={close}
      >
        <div></div>
        <Select
          showSearch
          size="large"
          placeholder={intl.formatMessage({
            id: 'action_center.select_hub_placeholder'
          })}
          value={selectedHubId}
          onSelect={setSelectedHubId}
          optionFilterProp="children"
          loading={isLoading}
          disabled={isLoading}
          className="br-assign-hub-modal__select"
        >
          {renderHubsDropdown()}
        </Select>
        <div className="br-assign-hub-modal__footer">
          <Button onClick={close} disabled={isLoading}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </Button>
          <Button
            type="primary"
            disabled={!selectedHubId || isLoading}
            onClick={handleConfirm}
          >
            {intl.formatMessage({ id: 'common.submit' })}
          </Button>
        </div>
      </Modal>
    </LoadingWrapper>
  );
};

export default injectIntl(AssignHubModal);
