import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'antd';
import TransactionsTable from './TransactionsTable';
import UploadForm from './UploadForm';
import styles from './style.module.scss';
import { deliverySchema, amazonDeliverySchema } from './common';
import { TABS_TYPES } from 'constants/cod-reports';
import {
  uploadTransactionReportXLSX,
  uploadSouqTransactionReportXLSX
} from '../../../actions/ARReportsAction';

class UploadTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      data: [],
      step: 1,
      hasErrors: false
    };
  }

  showUploadTransactionsPopup = () => {
    this.setState({ showPopup: true });
  };

  handleOk = async () => {
    const { data } = this.state;
    const { transactionType, handleUploadAmazonTransaction } = this.props;
    if (transactionType === 'souq') {
      const res = await uploadSouqTransactionReportXLSX(
        data.map((item) => ({
          ...item,
          paymentType: item.paymentTypeMapped,
          paymentTypeMapped: undefined
        }))
      );
      if (res) {
        this.setState({
          showPopup: false,
          step: 1,
          data: [],
          hasErrors: false
        });
      }
    } else if (transactionType === TABS_TYPES.AMAZON) {
      handleUploadAmazonTransaction(data);
      this.setState({
        showPopup: false,
        step: 1,
        data: [],
        hasErrors: false
      });
    } else {
      const res = await uploadTransactionReportXLSX(
        data.map((item) => ({
          ...item,
          paymentType: item.paymentTypeMapped,
          paymentTypeMapped: undefined
        }))
      );
      if (res) {
        this.setState({
          showPopup: false,
          step: 1,
          data: [],
          hasErrors: false
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({ showPopup: false, step: 1, data: [], hasErrors: false });
  };

  fileParsed = async (data) => {
    const { transactionType } = this.props;
    let hasErrors = false;
    try {
      if (transactionType === TABS_TYPES.AMAZON) {
        await amazonDeliverySchema.validate(data);
      } else {
        await deliverySchema.validate(data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.dir(e);
      hasErrors = true;
    }
    this.setState({ data, step: 2, hasErrors });
  };

  back = () => {
    this.setState({ step: 1, data: [], hasErrors: false });
  };

  render() {
    const { showPopup, data, step, hasErrors } = this.state;
    const { transactionType } = this.props;
    return (
      <>
        <Button
          className="primaryButton"
          onClick={this.showUploadTransactionsPopup}
        >
          Upload Transactions
        </Button>
        <Modal
          title="Upload Transactions"
          visible={showPopup}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          destroyOnClose
          maskClosable={false}
          width="80vw"
          footer={null}
        >
          <div className={styles.transactionContent}>
            {step === 1 && (
              <UploadForm
                fileParsed={this.fileParsed}
                transactionType={transactionType}
              />
            )}
            {step === 2 && (
              <>
                <TransactionsTable
                  data={data}
                  transactionType={transactionType}
                />
                <div className={styles.footer}>
                  <Button className="secondaryButton" onClick={this.back}>
                    Back
                  </Button>
                  <Button
                    disabled={hasErrors}
                    className="primaryButton"
                    onClick={this.handleOk}
                  >
                    Upload
                  </Button>
                </div>
                {hasErrors && (
                  <p>
                    * There are some error in excel sheet, please review and fix
                    then reupload
                  </p>
                )}
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

export default UploadTransactions;
