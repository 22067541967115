import { Tag } from 'antd';
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl';

import {
  HUB_TRANSFER_DISPATCHING_TYPES,
  IN_TRANSIT,
  SEALS,
  SEAL_STATE,
  SEAL_STATE_COLOR
} from 'constants/hubs';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import './SealsTable.less';

const DEFAULT_PAGE_SIZE = 50;

const SealsTable = ({ intl, getSeals, hubId }) => {
  const columns = [
    {
      dataIndex: 'sealNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.seal_code'
      })
    },
    {
      dataIndex: 'noOfPackages',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.no_of_packages'
      })
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.state'
      })
    },
    {
      dataIndex: 'masterSeal',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.master_seal'
      })
    },
    {
      dataIndex: 'lastUpdate',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.last_update'
      })
    }
  ];

  const formatSealsData = (data) =>
    data.map((seal) => {
      const {
        _id,
        sealNumber,
        masterSealNumber,
        state,
        trackingNumbers = [],
        transferredDeliveries = [],
        updatedAt,
        binCode
      } = seal;

      return {
        _id,
        sealNumber: binCode || sealNumber,
        noOfPackages: intl.formatMessage(
          {
            id: 'hubs.receive_from_hub.receive_seals.count_from_total'
          },
          {
            count: trackingNumbers.length - transferredDeliveries.length,
            total: trackingNumbers.length
          }
        ),
        state: state ? (
          <Tag className={SEAL_STATE_COLOR[state]}>{SEAL_STATE[state]}</Tag>
        ) : (
          '-'
        ),
        masterSeal: masterSealNumber || '-',
        lastUpdate: (
          <div className="br-seals-table__time">
            {dayjs(updatedAt).format('DD MMM YYYY')}{' '}
            <span>{dayjs(updatedAt).format('HH:MM A')} </span>
          </div>
        )
      };
    });

  const getTableData = async ({ page, limit, searchInputText }) => {
    try {
      const payload = {
        type: HUB_TRANSFER_DISPATCHING_TYPES.join(','),
        state: IN_TRANSIT,
        destinationHubId: hubId,
        searchType: SEALS,
        pageId: page,
        searchString: searchInputText,
        pageLimit: limit
      };

      const { results = [] } = (await getSeals(payload)) || {};

      const totalSeals =
        results.length === DEFAULT_PAGE_SIZE
          ? page * DEFAULT_PAGE_SIZE + 1
          : page * DEFAULT_PAGE_SIZE;

      return {
        list: formatSealsData(results),
        total: totalSeals
      };
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <BRTable
      className="br-receive-seals__table"
      columns={columns}
      listFunction={getTableData}
      pageLimit={DEFAULT_PAGE_SIZE}
      showSearch
      showExport={false}
      showFilter={false}
      searchPlaceholder={intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.search_placeholder'
      })}
      keepDataBetweenTabs
    />
  );
};

export default injectIntl(SealsTable);
