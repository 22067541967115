import { injectIntl } from 'react-intl';

import { ReactComponent as EmptyOrderDetailsImage } from 'assets/imgRevamp/ActionCenterEmptyOrderDetails.svg';

import './EmptyOrderDetails.less';

const EmptyOrderDetails = ({ intl }) => {
  return (
    <div className="br-order-details__container">
      <div className="br-order-details__empty-content">
        <EmptyOrderDetailsImage />
        <div className="br-order-details__empty-content-title">
          {intl.formatMessage({
            id: 'action_center.empty_orders.no_orders_selected'
          })}
        </div>
        <div className="br-order-details__empty-content-subtitle">
          {intl.formatMessage({
            id: 'action_center.empty_orders.select_order_to_view'
          })}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EmptyOrderDetails);
