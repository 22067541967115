import { put, takeLatest } from "redux-saga/effects";
import actionTypes from "../actions/ActionTypes";
import { fetchCities, fetchZones } from "../actions/CitiesActions";

/**
 * fetch all cities
 * @returns {?}
 */
function* getCities() {
  let results = [];
  const cities = yield fetchCities();
  if (cities) {
    results = cities;
  }
  yield put({ type: actionTypes.FETCH_CITIES_RECEIVED, payload: results });
}

/**
 * fetch all zones
 * @returns {?}
 */
function* getZones({ data }) {
  let results = [];
  const zones = yield fetchZones(data);
  if (zones) {
    results = zones;
  }
  yield put({ type: actionTypes.FETCH_ZONES_RECEIVED, payload: results });
}


export default function* citiesWatcher() {
  yield takeLatest(actionTypes.FETCH_CITIES, getCities);
  yield takeLatest(actionTypes.FETCH_ZONES, getZones);
}