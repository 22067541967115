import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import './NewConfirmationModal.less';

const NewConfirmationModal = ({
  close,
  confirmButtonType = 'primary',
  cancelButtonLabel,
  confirmButtonLabel,
  title,
  subtitle,
  onConfirm = async () => {},
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    close();
  };

  return (
    <Modal
      title={null}
      footer={null}
      onCancel={close}
      wrapClassName="br-new-confirmation__modal"
      {...props}
    >
      <div className="br-new-confirmation-modal__content">
        <span className="display-xs">{title}</span>
        <span className="br-new-confirmation-modal__subtitle">{subtitle}</span>
      </div>
      <div className="br-new-confirmation-modal__footer">
        <BRButton
          onClick={close}
          label={
            cancelButtonLabel || intl.formatMessage({ id: 'common.cancel' })
          }
          disabled={isLoading}
          block
        />
        <BRButton
          type={confirmButtonType}
          onClick={handleConfirm}
          label={
            confirmButtonLabel ||
            intl.formatMessage({ id: 'common.yes_confirm' })
          }
          loading={isLoading}
          block
        />
      </div>
    </Modal>
  );
};

export default NewConfirmationModal;
