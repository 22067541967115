import { put, takeLatest } from 'redux-saga/effects';
import actionTypes from '../actions/ActionTypes';
import {
  exportHubTransfersReportAsCSV,
  getAllHubs,
  getAllStars,
  getCurrentUserHubInfo,
  getHubById,
  getHubIncomingTransfers,
  getHubTransfersReport,
  getMoneyDebriefingReport,
  getStarLiabilities,
  getStarsByHubId,
  getTransferContainers,
} from '../actions/HubsActions';
import { endLoading, startLoading } from '../actions';
import { downloadAsCSV } from '../utils';
import { debriefDeliveries } from '../actions/DeliveriesActions';

/**
 * fetch all hubs
 * @returns {?}
 */
function* fetchHubs() {
  let results = [];
  const data = yield getAllHubs();
  if (data) {
    results = data.result;
  }
  yield put({
    type: actionTypes.GET_ALL_HUBS_PAGED_RECEIVED,
    payload: results,
  });
}

/**
 * fetch single hub
 * @param data
 * @returns {?}
 */
function* fetchHub({ data }) {
  let results = null;
  const hub = yield getHubById(data);
  if (hub) {
    results = hub;
  }
  yield put({ type: actionTypes.GET_HUB_RECEIVED, payload: results });
}

/**
 * get all verified stars
 * @returns {?}
 */
function* fetchStars() {
  yield put(startLoading());
  let res = yield getAllStars();
  if (!res) res = { count: 0, data: [] };
  yield put({ type: actionTypes.GET_STARS_RECEIVED, payload: res });
  yield put(endLoading());
}

/**
 * get all stars assigned to a hub
 * @returns {?}
 */
function* fetchStarsByHub({ data }) {
  yield put(startLoading());
  let res = yield getStarsByHubId(data);
  if (!res) res = { count: 0, data: [] };
  yield put({ type: actionTypes.GET_STARS_BY_HUB_RECEIVED, payload: res });
  yield put(endLoading());
}

/**
 * fetch money debrief report according to criteria
 * @returns {?}
 * @param payload
 */
function* fetchMoneyDebriefReport(payload) {
  let results = { count: 0, data: [], pagination: {} };
  const res = yield getMoneyDebriefingReport(payload.data);
  if (!res) results = { count: 0, data: [], pagination: {} };
  // if (data.pageSize) {
  const { pageSize, pageNumber, data, count } = res;
  results.pagination = { pageNumber: Number(pageNumber), pageSize };
  results.count = count;
  results.data = data;
  // }
  yield put({
    type: actionTypes.GET_MONEY_DEBRIEF_REPORT_RECEIVED,
    payload: results,
  });
  yield put(endLoading());
}

/**
 * filter current money report
 * @param data
 * @returns {?}
 */
function* filterCurrentMoneyReport({ data }) {
  const { reportItems, searchText } = data;
  const searchBy = searchText.trim();
  const view = { ...reportItems };
  if (searchBy) {
    const rows = reportItems.data.filter(el => {
      return (
        JSON.stringify(el)
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
      );
    });
    view.count = rows.length;
    view.data = [...rows];
  }
  yield put({
    type: actionTypes.GET_MONEY_DEBRIEF_REPORT_FILTERED_RECEIVED,
    payload: view,
  });
}

/**
 * fetch hubs transfers report
 * @param data
 * @returns {?}
 */
function* fetchHubTransfersReport({ data }) {
  let results = { count: 0, data: [], pagination: {} };
  const res = yield getHubTransfersReport(data);
  if (!res) results = { count: 0, data: [], pagination: {} };
  // if (data.pageSize) {
  // const { pageId, limit } = data;
  results.pagination = { pageId: Number(res.pageNumber), limit: res.pageSize };
  results.count = res.count;
  results.data = res.data;
  // }
  yield put({
    type: actionTypes.GET_HUB_TRANSFER_REPORT_RECEIVED,
    payload: results,
  });
  yield put(endLoading());
}

/**
 * fetch hubs transfers report
 * @param data
 * @returns {?}
 */
function* fetchHubTransfersReportCSV({ data }) {
  const res = yield exportHubTransfersReportAsCSV(data);
  if (res) {
    downloadAsCSV(res, 'hub-transfers.csv');
  }
  yield put({
    type: actionTypes.GET_HUB_TRANSFER_REPORT_CSV_RECEIVED,
    payload: res,
  });
  yield put(endLoading());
}

/**
 * fetch daily star report
 * @param data
 * @returns {?}
 */
function* fetchStarDailyDebrief({ data }) {
  let payload = {
    routesIds: [],
    pickupRequestIds: 0,
    pickedupDeliveries: { count: 0, data: [] },
    outForDelivery: { count: 0, data: [] },
    deliveredDeliveries: { count: 0, data: [] },
    exceptionDeliveries: { count: 0, data: [] },
    totalStarCod: 0,
  };
  const res = yield debriefDeliveries(data);
  if (!res) {
    payload = {
      ...payload,
      loaded: true,
    };
  } else {
    payload = { ...payload, ...res?.starDayReport, loaded: true };
  }
  yield put({ type: actionTypes.GET_STAR_DAY_REPORT_RECEIVED, payload });
}

/**
 * calculate star liabilities
 * @param data
 * @returns {?}
 */
function* calculateStarLiabilities({ data }) {
  let payload = { error: true };
  const res = yield getStarLiabilities(data);
  if (res) {
    payload = { ...res };
  }

  yield put({ type: actionTypes.GET_STAR_LIABILITIES_RECEIVED, payload });
}

/**
 * get current warehouse info
 * @returns {?}
 */
function* getCurrentHubId() {
  const { warehouseInfo } = yield getCurrentUserHubInfo();

  yield put({
    type: actionTypes.GET_CURRENT_HUB_ID_RECIEVED,
    payload: warehouseInfo,
  });
}

function* getCurrentHubInfo({ data: { hubId, pageId, pageLimit, sealNumbers } }) {
  try {
    const res = yield getHubIncomingTransfers({
      hubId,
      pageId,
      pageLimit,
      sealNumbers
    });
    const warehouseInfo = {
      data: res?.result || [],
      count: res?.count || 0,
      pageId: res?.pageId || 0,
      pageLimit: res?.pageLimit || 0,
    };
    yield put({
      type: actionTypes.GET_CURRENT_HUB_INFO_RECEIVED,
      payload: warehouseInfo,
    });
  } catch (err) {}
}

/**
 * get current warehouse info
 * @returns {?}
 */
function* getIncomingTransferItems({ data }) {
  try {
    const res = yield getTransferContainers(data);
    res.originWarehouse = yield getHubById(res.originWarehouseId);
    yield put({
      type: actionTypes.GET_INCOMING_TRANSFER_ITEMS_RECEIVED,
      payload: res,
    });
  } catch (err) {}
}

export default function* hubsWatcher() {
  yield takeLatest(actionTypes.GET_ALL_HUBS_PAGED, fetchHubs);
  yield takeLatest(actionTypes.GET_HUB, fetchHub);
  yield takeLatest(actionTypes.GET_STARS_, fetchStars);
  yield takeLatest(actionTypes.GET_STARS_BY_HUB, fetchStarsByHub);
  yield takeLatest(
    actionTypes.GET_MONEY_DEBRIEF_REPORT,
    fetchMoneyDebriefReport,
  );
  yield takeLatest(
    actionTypes.GET_MONEY_DEBRIEF_REPORT_FILTERED,
    filterCurrentMoneyReport,
  );
  yield takeLatest(
    actionTypes.GET_HUB_TRANSFER_REPORT,
    fetchHubTransfersReport,
  );
  yield takeLatest(
    actionTypes.GET_HUB_TRANSFER_REPORT_CSV,
    fetchHubTransfersReportCSV,
  );
  yield takeLatest(actionTypes.GET_STAR_DAY_REPORT, fetchStarDailyDebrief);
  yield takeLatest(actionTypes.GET_STAR_LIABILITIES, calculateStarLiabilities);
  yield takeLatest(actionTypes.GET_CURRENT_HUB_INFO, getCurrentHubInfo);
  yield takeLatest(actionTypes.GET_CURRENT_HUB_ID, getCurrentHubId);
  yield takeLatest(
    actionTypes.GET_INCOMING_TRANSFER_ITEMS,
    getIncomingTransferItems,
  );
}
