import { fmt } from 'IntlWrapper/IntlWrapper';

export const WITHOUT_COUNTRY_CODE = 'WITHOUT_COUNTRY_CODE';
export const DEFAULT = 'DEFAULT';

export const PHONE_NUMBER_MAX_LENGTH = {
  EG: 11,
  SA: 20
};

export const PHONE_NUMBER_REGEX = {
  EG: {
    WITHOUT_COUNTRY_CODE: /^1[0-2|5]{1}[0-9]{8}$/,
    DEFAULT: /^(\+201|01)[0-2|5]{1}[0-9]{8}$/
  },
  SA: {
    WITHOUT_COUNTRY_CODE: /^(05|5)([013-9][0-9]{7})$/,
    DEFAULT: /^(\+9665|05|5)([013-9][0-9]{7})$/
  }
};

export const PHONE_INPUT_PLACEHOLDER = {
  EG: fmt({ id: 'form.egypt_phone_placeholder' }),
  SA: fmt({ id: 'form.saudi_arabia_phone_placeholder' })
};

export const PHONE_VALIDATION_ERROR_MSG = {
  EG: {
    FIRST_NUMBER: fmt({
      id: 'orders.mass_upload.error_table.errors.phone_not_valid'
    }),
    SECOND_NUMBER: fmt({
      id: 'orders.mass_upload.error_table.errors.second_phone_not_valid'
    })
  },
  SA: {
    FIRST_NUMBER: fmt({
      id: 'orders.mass_upload.error_table.errors.sadui_phone_not_valid'
    }),
    SECOND_NUMBER: fmt({
      id: 'orders.mass_upload.error_table.errors.sadui_second_phone_not_valid'
    })
  }
};

export const CREDIT_LIMIT = {
  DEFAULT: {
    MIN: 0,
    MAX: 10000000
  }
};
