import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Tabs, Button, Table, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { confirmReceivingDeliveries, createTicket } from 'services/hubs';
import { exceptionsMapping } from 'constants/hubs';
import { getDeliveryType } from 'utils/deliveries';

import { notify } from 'components/Notify/Notify';

const { TabPane } = Tabs;

const ViewDetailsTabs = ({
  intl,
  pendingOrders,
  returnedOrders,
  rejectedOrders,
  routesIds,
  hubName,
  getUpdatedData
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const renderDate = (date) => dayjs(date).tz().format('ddd,  DD MMM hh:mm A');

  const confirmReceivingOrders = async (deliveryIds) => {
    setIsLoading(true);

    try {
      const res = await confirmReceivingDeliveries({
        deliveryIds,
        routeIds: routesIds
      });

      if (res) {
        getUpdatedData();
        setSelectedOrders([]);
        setSelectedRowKeys([]);
      }
    } catch ({ message }) {
      notify(message);
    }

    setIsLoading(false);
  };

  const handleConfirmAll = () => {
    const selectedOrdersIds = selectedOrders.map(({ _id }) => _id);
    confirmReceivingOrders(selectedOrdersIds);
  };

  const sharedColumns = {
    trackingNumber: {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.common_columns.tracking_number'
      }),
      dataIndex: '',
      key: 'trackingNumber',
      render: (delivery) => (
        <Link
          className="br-packages-debrief__rto-tab__view-details__tabs__link"
          to={`/deliveries/${delivery?._id}/details`}
          target="_blank"
        >
          {delivery?.trackingNumber}
        </Link>
      )
    },
    originalType: {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.common_columns.order_type'
      }),
      dataIndex: '',
      key: 'originalType',
      render: (delivery) => {
        const type = delivery?.oldType || delivery?.type;
        const collectedFromConsignee = delivery?.type
          ? delivery?.collectedFromConsignee
          : null;

        return getDeliveryType({ type, collectedFromConsignee });
      }
    },
    updatedAt: {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.common_columns.last_update'
      }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: renderDate
    }
  };

  const pendingOrdersColumns = [
    sharedColumns.trackingNumber,
    sharedColumns.originalType,
    sharedColumns.updatedAt
  ];

  const returnedOrdersColumns = [
    sharedColumns.trackingNumber,
    sharedColumns.originalType,
    sharedColumns.updatedAt,
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.columns.actions'
      }),
      dataIndex: '',
      key: 'actions',
      align: 'center',
      render: (delivery) => (
        <Dropdown overlay={renderActionsMenu(delivery)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      )
    }
  ];

  const rejectedOrdersColumns = [
    sharedColumns.trackingNumber,
    sharedColumns.originalType,
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.rejected_orders.columns.exception_reason'
      }),
      dataIndex: 'state',
      key: 'exceptionReason',
      render: (state, record) =>
        state.lastExceptionCode &&
        exceptionsMapping(record)[state.lastExceptionCode]
    },
    sharedColumns.updatedAt
  ];

  const tabs = [
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.pending_orders.title'
      }),
      table: {
        data: pendingOrders,
        columns: pendingOrdersColumns
      }
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.title'
      }),
      table: {
        data: returnedOrders?.map((order) => ({
          key: order?.trackingNumber,
          ...order
        })),
        columns: returnedOrdersColumns,
        rowSelection: {
          selectedRowKeys,
          onChange: (_selectedRowKeys, selectedRows) => {
            setSelectedOrders(selectedRows);
            setSelectedRowKeys(_selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            disabled: record?.isConfirmedDelivery
          })
        }
      },
      extraContent: selectedOrders.length && (
        <div className="br-packages-debrief__rto-tab__view-details__tabs__extra-content">
          <span>
            {intl.formatMessage(
              {
                id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.extra_content.selected'
              },
              {
                count: selectedOrders.length
              }
            )}
          </span>
          <Button onClick={handleConfirmAll}>
            {intl.formatMessage({
              id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.extra_content.confirm_all_btn'
            })}
          </Button>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.rejected_orders.title'
      }),
      table: {
        data: rejectedOrders,
        columns: rejectedOrdersColumns
      }
    }
  ];

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const handleConfirmOrder = (deliveryId) => {
    confirmReceivingOrders([deliveryId]);
  };

  const handleCreateTicketForOrder = async (trackingNumber) => {
    const ticketCreationDate = dayjs().format('D MMM');
    setIsLoading(true);

    try {
      const ticketData = {
        trackingNumber,
        category: 'Delivery Issue',
        subCategory: 'Delivered by Mistake',
        reason: `Order picked up by Mistake || ${ticketCreationDate} || ${hubName}`,
        description: `Order delivered by mistake. Please restart order of tracking number:${trackingNumber}`,
        rank: 'Low'
      };
      const { externalId } = await createTicket({ ...ticketData });
      notify(`Ticket Id: ${externalId} is created successfully`, 'success');
      getUpdatedData();
    } catch ({ message }) {
      notify(message);
    }

    setIsLoading(false);
  };

  const renderActionsMenu = ({ _id, trackingNumber, isConfirmedDelivery }) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => handleConfirmOrder(_id)}
        disabled={isConfirmedDelivery}
      >
        {intl.formatMessage({
          id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.actions.confirm'
        })}
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => handleCreateTicketForOrder(trackingNumber)}
      >
        {intl.formatMessage({
          id: 'hubs.packages_debrief.rto_tab.view_details.tabs.returned_orders.actions.create_ticket'
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <Tabs
      defaultActiveKey={selectedTab}
      tabBarExtraContent={tabs[selectedTab]?.extraContent}
      onChange={handleTabChange}
      className="br-packages-debrief__rto-tab__view-details__tabs"
    >
      {tabs.map(({ title, table }, index) => (
        <TabPane tab={title} key={index}>
          <Table
            columns={table.columns}
            dataSource={table.data}
            rowSelection={table?.rowSelection}
            pagination={false}
            loading={isLoading}
          />
        </TabPane>
      ))}
    </Tabs>
  );
};

export default injectIntl(ViewDetailsTabs);
