import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Select, Input } from 'antd';

import { fetchCities, getAreasInfo } from 'services/cities';
import { formatOptions } from 'utils';
import { FIRST_LINE_MIN_LENGTH } from 'constants/shipments';

import BRArea, { AVAILABILITY } from 'components/BRArea/BRArea';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

const EditAddressModal = ({ deliveryDetails, onConfirm, close, ...props }) => {
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [isAreasLoading, setIsAreasLoading] = useState(false);

  const intl = useIntl();

  const [form] = Form.useForm();
  const cityId = Form.useWatch('cityId', form);

  useEffect(() => {
    if (cityId) {
      handleCityChange(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (deliveryDetails?.dropOffAddress) {
      const {
        city,
        district,
        firstLine,
        country,
        secondLine,
        apartment,
        floor,
        buildingNumber
      } = deliveryDetails.dropOffAddress;

      getCities(country._id);

      form.setFieldsValue({
        cityId: city._id,
        districtId: district._id,
        firstLine,
        secondLine,
        apartment,
        floor,
        buildingNumber
      });
    }
  }, [deliveryDetails]);

  const getCities = async (countryId) => {
    try {
      const cities = await fetchCities(countryId);
      setCities(cities);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleCityChange = async () => {
    try {
      setIsAreasLoading(true);
      const areas = await getAreasInfo(cityId);
      setAreas(areas);
    } catch (error) {
      notify(error.message);
    }
    setIsAreasLoading(false);
  };

  const onFinish = (values) => {
    const { cityId, districtId, ...rest } = values;
    const payload = {
      dropOffAddress: {
        districtId,
        ...rest
      }
    };

    onConfirm(payload);
    close();
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.edit_customer_address'
      })}
      onCancel={close}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({ id: 'common.confirm' })}
            htmlType="submit"
            form="editAddressForm"
          />
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={onFinish} name="editAddressForm">
        <Form.Item
          name="cityId"
          label={intl.formatMessage({
            id: 'location_form_labels.city'
          })}
        >
          <Select
            options={formatOptions('_id', 'name', cities)}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={() => form.resetFields(['districtId'])}
          />
        </Form.Item>
        <BRArea
          name="districtId"
          availability={AVAILABILITY.DROPOFF}
          areas={areas}
          loading={isAreasLoading}
          disabled={!cityId}
        />
        <Form.Item
          name={'firstLine'}
          label={intl.formatMessage({
            id: 'location_form_labels.street'
          })}
          rules={[
            {
              required: true,
              label: intl.formatMessage({
                id: 'location_form_labels.street'
              })
            },
            {
              min: FIRST_LINE_MIN_LENGTH,
              message: intl.formatMessage(
                {
                  id: 'form.field_min_length'
                },
                {
                  label: intl.formatMessage({
                    id: 'location_form_labels.street'
                  }),
                  count: FIRST_LINE_MIN_LENGTH
                }
              )
            }
          ]}
        >
          <Input
            autoComplete={`${Math.random()}`}
            placeholder={intl.formatMessage({
              id: 'shipments.new_order.form_placeholder.street_placeholder'
            })}
            maxLength={250}
          />
        </Form.Item>
        <div className="br-filters-search__row">
          <Form.Item
            name="buildingNumber"
            label={intl.formatMessage({
              id: 'location_form_labels.building'
            })}
            rules={[{ max: 25 }]}
          >
            <Input
              autoComplete={`${Math.random()}`}
              placeholder={intl.formatMessage({
                id: 'shipments.new_order.form_placeholder.building_placeholder'
              })}
              maxLength={25}
            />
          </Form.Item>
          <Form.Item
            name="floor"
            label={intl.formatMessage({
              id: 'location_form_labels.floor'
            })}
            rules={[
              {
                max: 25,
                label: intl.formatMessage({
                  id: 'location_form_labels.floor'
                })
              }
            ]}
          >
            <Input
              maxLength={25}
              placeholder={intl.formatMessage({
                id: 'shipments.new_order.form_placeholder.floor_placeholder'
              })}
              autoComplete={`${Math.random()}`}
            />
          </Form.Item>

          <Form.Item
            name="apartment"
            label={intl.formatMessage({
              id: 'location_form_labels.apartment'
            })}
            rules={[
              {
                max: 25,
                label: intl.formatMessage({
                  id: 'location_form_labels.apartment'
                })
              }
            ]}
          >
            <Input
              maxLength={25}
              placeholder={intl.formatMessage({
                id: 'shipments.new_order.form_placeholder.apartment_placeholder'
              })}
              autoComplete={`${Math.random()}`}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="secondLine"
          label={intl.formatMessage({
            id: 'location_form_labels.landmarks'
          })}
        >
          <Input
            autoComplete={`${Math.random()}`}
            placeholder={intl.formatMessage({
              id: 'shipments.new_order.form_placeholder.street_placeholder'
            })}
            maxLength={250}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAddressModal;
