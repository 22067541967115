import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';

import './ProductImageName.less';

const ProductImageName = ({ imgSrc, productName }) => {
  return (
    <div className="br-product-image-name">
      <ProductImage src={imgSrc} />
      <span>{productName}</span>
    </div>
  );
};

export default ProductImageName;
