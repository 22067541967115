export const UPLOADED_FILE_TYPES = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv'
};

export const FILE_SHEET_UPLOAD_ERROR_TYPES = {
  INVALID_FILE: 'INVALID_FILE',
  EMPTY_FILE: 'EMPTY_FILE',
  EXCEEDED_MAX_ROWS: 'EXCEEDED_MAX_ROWS'
};

export const MULTIPLE_IMPORT_STEPS_KEYS = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  PREVIEW_FILE: 'PREVIEW_FILE',
  VALIDATING_AND_UPLOADING: 'VALIDATING_AND_UPLOADING',
  PREVIEW_ERRORS: 'PREVIEW_ERRORS'
};
