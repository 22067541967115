import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import {
  INTERNATIONAL_CONTACT_DOCUMENT_NAMES,
  INTERNATIONAL_SERVICE_DOCUMENT_KEYS
} from 'constants/Businesses';
import { getLegalDocUploadLink } from 'services/business';
import { imageUpload } from 'services/upload';

import Banner from 'components/Banner/Banner';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as AlertIcon } from 'assets/bosta-icons/alert-circle.svg';

import './ActivateInternationalShippingModal.less';

const ActivateInternationalShippingModal = ({
  close,
  businessId,
  onConfirm,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const handleOnConfirm = (values) => {
    onConfirm(values);
    close();
  };

  const renderDocumentsList = () => {
    return (
      <ul>
        {INTERNATIONAL_SERVICE_DOCUMENT_KEYS.map((key) => (
          <li key={key}>
            {intl.formatMessage({
              id: `activate_international_shipping.${key}`
            })}
          </li>
        ))}
      </ul>
    );
  };

  const formatImage = (image, index) => {
    return {
      image: image.attachments[index],
      type: image.attachmentsTypes[index]
    };
  };

  const getUploadLink = async (index) => {
    try {
      const payload = {
        documentName: INTERNATIONAL_CONTACT_DOCUMENT_NAMES[index]
      };
      return await getLegalDocUploadLink({ businessId, payload });
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const numberOfFiles = values.contractImage.attachments.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const formattedImage = formatImage(values.contractImage, i);
        const { data } = await getUploadLink(i);
        imageUpload(
          data[INTERNATIONAL_CONTACT_DOCUMENT_NAMES[i]],
          formattedImage
        );
      }
      handleOnConfirm(numberOfFiles);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      title={null}
      footer={null}
      onCancel={close}
      wrapClassName="br-international-shipping__modal"
      {...props}
    >
      <div className="br-international-shipping-modal__title display-xs">
        {intl.formatMessage({ id: 'activate_international_shipping.title' })}
      </div>
      <div className="br-international-shipping-modal__content">
        <Banner
          icon={<AlertIcon />}
          isAntIcon
          title={intl.formatMessage({
            id: 'activate_international_shipping.banner_title'
          })}
          subText={renderDocumentsList()}
        />
        <div className="br-international-shipping-modal__upload-container">
          <Form onFinish={onFinish} name="uploadContactForm">
            <Form.Item
              label={intl.formatMessage({
                id: 'activate_international_shipping.label'
              })}
              name="contractImage"
              rules={[{ required: true }]}
            >
              <UploadAttachment
                customSubtitle={intl.formatMessage({
                  id: 'activate_international_shipping.upload_subtitle'
                })}
                maxCount={4}
                maxSize={15}
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                disableEncoding
                noMaxTotalSize
                hideUploadButtonAfterMaxCount
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="br-international-shipping-modal__footer">
        <BRButton
          block
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={close}
          disabled={isLoading}
        />
        <BRButton
          block
          type="primary"
          htmlType="submit"
          form="uploadContactForm"
          loading={isLoading}
          label={intl.formatMessage({ id: 'international_shipping.activate' })}
        />
      </div>
    </Modal>
  );
};

export default ActivateInternationalShippingModal;
