import { useEffect, useRef, useState } from 'react';
import { Form, Collapse, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import Icon from '@ant-design/icons';

import { fetchCities } from 'services/cities';
import {
  BUSINESS_DATE_RANGE_FILTERS_KEYS,
  statusTypes
} from 'constants/Businesses';
import {
  FORMAT_DATE_TO_UTC,
  cleanEmptyString,
  isUserAuthorized,
  trimObjectStrings
} from 'utils/helpers';
import { ACCOUNTANT, SUPER_ADMIN } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { DATE_FORMAT } from 'constants/date-picker';

import BRButton from 'components/BRButton/BRButton';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './BusinessesSearch.less';

const BusinessesSearch = ({
  handleExportBusinessBankDetailsClick,
  handleExportAllBusinessConfig,
  handleOnSubmit,
  isLoading
}) => {
  const [cities, setCities] = useState([]);

  const formRef = useRef();

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      const data = await fetchCities();
      setCities(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleBulkPromotionClick = () => {
    history.push('/businesses/bulk/promotions');
  };

  const handleBulkUploadClick = () => {
    history.push('/businesses/bulk');
  };

  const handleBulkUpdatePricingTierClick = () => {
    history.push('/businesses/bulk/pricing/update');
  };

  const onFinish = (values) => {
    const cleanedValues = cleanEmptyString(values);

    for (const key of Object.keys(cleanedValues)) {
      if (BUSINESS_DATE_RANGE_FILTERS_KEYS.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        cleanedValues[dateStartKey] = FORMAT_DATE_TO_UTC(
          values[key][0].format(DATE_FORMAT)
        );
        cleanedValues[dateEndKey] = FORMAT_DATE_TO_UTC(
          values[key][1].format(DATE_FORMAT)
        );

        delete cleanedValues[key];
      }
    }

    handleOnSubmit(trimObjectStrings(cleanedValues));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleOnSubmit({});
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <Icon
          component={DropDownIcon}
          rotate={isActive ? 0 : -90}
          className="ant-icon-xl"
        />
      )}
    >
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'businesses.search_filters.title'
        })}
      >
        <Form onFinish={onFinish} ref={formRef}>
          <div className="br-filters-search__row">
            <Form.Item
              name="name"
              label={intl.formatMessage({
                id: 'businesses.search_filters.name'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.name'
                })}
              />
            </Form.Item>
            <Form.Item
              name="businessId"
              label={intl.formatMessage({
                id: 'businesses.search_filters.business_id'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.business_id'
                })}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={intl.formatMessage({
                id: 'businesses.search_filters.email'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.email'
                })}
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label={intl.formatMessage({
                id: 'businesses.search_filters.phone'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.phone'
                })}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="cityId"
              label={intl.formatMessage({
                id: 'businesses.search_filters.city'
              })}
            >
              <Select
                allowClear
                showSearch
                options={cities}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.city'
                })}
              />
            </Form.Item>
            <Form.Item
              name="status"
              label={intl.formatMessage({
                id: 'businesses.search_filters.status'
              })}
            >
              <Select
                allowClear
                options={statusTypes}
                optionFilterProp="name"
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.status'
                })}
              />
            </Form.Item>
            <SearchDateRangePicker
              name="registeredAt"
              label={intl.formatMessage({
                id: 'businesses.search_filters.registered_at'
              })}
            />
            <SearchDateRangePicker
              name="activeAt"
              label={intl.formatMessage({
                id: 'businesses.search_filters.active_at'
              })}
            />
          </div>
          <div className="br-businesses-search__actions">
            <div>
              <BRButton
                type="primary"
                htmlType="submit"
                label={intl.formatMessage({ id: 'common.search' })}
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleResetFilters}
              />
            </div>
            <div className="br-businesses-search-actions__right-side">
              {isUserAuthorized(
                [ACCOUNTANT, SUPER_ADMIN],
                [ACL_MATRIX.BUSINESS_EXPORT_BANK_DETAILS]
              ) && (
                <div>
                  <BRButton
                    type="primary"
                    label={intl.formatMessage({
                      id: 'businesses.search_filters.promotion_upload'
                    })}
                    onClick={handleBulkPromotionClick}
                  />
                  <BRButton
                    type="primary"
                    label={intl.formatMessage({
                      id: 'businesses.search_filters.export_bank_details'
                    })}
                    onClick={handleExportBusinessBankDetailsClick}
                    disabled={isLoading}
                  />
                </div>
              )}
              {isUserAuthorized(
                [ACCOUNTANT, SUPER_ADMIN],
                [ACL_MATRIX.BUSINESS_EXPORT, ACL_MATRIX.BUSINESS_UPDATE_WALLET]
              ) && (
                <div>
                  {isUserAuthorized(
                    [ACCOUNTANT, SUPER_ADMIN],
                    [ACL_MATRIX.BUSINESS_UPDATE_WALLET]
                  ) && (
                    <BRButton
                      type="primary"
                      onClick={handleBulkUpdatePricingTierClick}
                      label={intl.formatMessage({
                        id: 'businesses.search_filters.bulk_update_pricing_tier'
                      })}
                    />
                  )}
                  <BRButton
                    type="primary"
                    label={intl.formatMessage({
                      id: 'businesses.search_filters.bulk_upload'
                    })}
                    onClick={handleBulkUploadClick}
                  />
                  <BRButton
                    type="primary"
                    label={intl.formatMessage({
                      id: 'businesses.search_filters.export_all_businesses'
                    })}
                    onClick={handleExportAllBusinessConfig}
                    disabled={isLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default BusinessesSearch;
