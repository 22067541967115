import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Select, Form } from 'antd';

import { ROUTES_STATE } from 'constants/route';
import { getRoutes } from 'services/hubs';
import { formatOptions } from 'utils';
import { ROUTE_ASSIGNING_TYPES } from 'constants/pickups';

import BRTabs from 'components/BRTabs/BRTabs';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './AssignToRouteModal.less';

const { ASSIGNED_ROUTES, UNASSIGNED_ROUTES } = ROUTE_ASSIGNING_TYPES;

const AssignToRouteModal = ({ close, onConfirm, ...props }) => {
  const [isLoading, setIsLoading] = useState([]);
  const [assignedRoutes, setAssignedRoutes] = useState([]);
  const [unassignedRoutes, setUnassignedRoutes] = useState([]);
  const [activeTab, setActiveTab] = useState(ASSIGNED_ROUTES);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const intl = useIntl();

  const stateUpdateKey = {
    [ASSIGNED_ROUTES]: setAssignedRoutes,
    [UNASSIGNED_ROUTES]: setUnassignedRoutes
  };

  const stateKey = {
    [ASSIGNED_ROUTES]: assignedRoutes,
    [UNASSIGNED_ROUTES]: unassignedRoutes
  };

  useEffect(() => {
    if (!stateKey[activeTab].length) {
      fetchRoutes();
    }

    setSelectedRoute(null);
  }, [activeTab]);

  const fetchRoutes = async () => {
    setIsLoading(true);

    const states = {
      [ASSIGNED_ROUTES]: [ROUTES_STATE.DISPATCHED, ROUTES_STATE.ASSIGNED],
      [UNASSIGNED_ROUTES]: [ROUTES_STATE.UNASSIGNED]
    };

    try {
      const payload = {
        pageId: -1,
        active: true,
        field: 'name',
        states: states[activeTab]
      };

      const { result } = await getRoutes(payload);
      stateUpdateKey[activeTab](formatOptions('_id', 'name', result || []));
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const handleAssignToRoute = () => {
    onConfirm(selectedRoute);
    close();
  };

  const renderRoutesSelect = () => (
    <Select
      showSearch
      labelInValue
      options={stateKey[activeTab]}
      onChange={setSelectedRoute}
      value={selectedRoute}
      placeholder={intl.formatMessage({ id: 'common.select' })}
      optionFilterProp="label"
    />
  );

  const TABS = {
    [ASSIGNED_ROUTES]: {
      label: intl.formatMessage({
        id: 'pickups.pickup_request.assign_to_route_modal.assigned_routes'
      }),
      component: renderRoutesSelect
    },
    [UNASSIGNED_ROUTES]: {
      label: intl.formatMessage({
        id: 'pickups.pickup_request.assign_to_route_modal.unassigned_routes'
      }),
      component: renderRoutesSelect
    }
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'pickups.pickup_request.assign_to_route_modal.title'
      })}
      onCancel={close}
      wrapClassName="br-assign-pickup__modal"
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'pickups.pickup_request.assign_to_route_modal.title'
            })}
            disabled={!selectedRoute}
            onClick={handleAssignToRoute}
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <BRTabs tabs={TABS} sameRouteTabs onChangeTabs={setActiveTab} />
      </LoadingWrapper>
    </Modal>
  );
};

export default AssignToRouteModal;
