import React from 'react';

import './DepartmentsAndApprovalToolTip.less';

const DepartmentsAndApprovalToolTip = ({ type = 'department', list }) => {
  return (
    <div className="br-department-tooltip-container">
      {list.map((item, key) => (
        <div className="br-department-tooltip-content" key={key}>
          <span className="br-department-tooltip__title">
            {type === 'department' ? item?.departmentName : item?.assigneeName}
          </span>
          {type !== 'department' && (
            <span className="br-department-tooltip__sub_title">
              {item?.departmentName}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default DepartmentsAndApprovalToolTip;
