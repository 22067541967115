import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

import {
  YESTERDAY,
  LAST_SEVEN_DAYS,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
  DATE_RANGES,
  DATE_FORMAT,
  TODAY,
  THIS_MONTH_TO_DATE,
  THIS_QUARTER_TO_DATE,
  THIS_YEAR_TO_DATE,
  THIS_WEEK,
  LAST_WEEK,
  THIS_WEEK_TO_DATE
} from 'constants/date-picker';
import { LOCALE } from 'constants/intl-wrapper';

import { ReactComponent as DatePickerIcon } from 'assets/imgRevamp/DatePickerIcon.svg';

import './BRDatePicker.less';

const BRDatePicker = ({
  intl,
  isActive,
  filterData,
  setFilterData,
  disabledDate,
  datePickerValue,
  header,
  initialFilterIndex,
  getFilterData,
  withOutRanges,
  fixedLabel,
  label,
  dropdownClassName,
  checkVisbilty,
  dateRangeToDate = false,
  ...props
}) => {
  const [datePresetLabel, setDatePresetLabel] = useState(
    initialFilterIndex
      ? DATE_RANGES(dateRangeToDate)[initialFilterIndex].label
      : intl.formatMessage({ id: 'date_picker.select_date' })
  );
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const filterElements = document.getElementsByClassName('ant-tag-blue');
  const activeFilterClassName = 'br-date-picker__active-tag';

  useEffect(() => {
    if (!withOutRanges) {
      const element = initialFilterIndex
        ? filterElements[initialFilterIndex]
        : '';
      let selectedFilter = false;
      DATE_RANGES(dateRangeToDate).map((value, index) => {
        const activeElement = document.getElementsByClassName(
          activeFilterClassName
        )[index];
        if (activeElement) {
          selectedFilter = true;
          return;
        }
      });
      if (!selectedFilter) element?.classList?.add(activeFilterClassName);
    }
  }, [isPanelVisible]);

  const datePickerHeader = () => {
    return <div className="br-date-picker__date-header">{header}</div>;
  };

  const checkTagColor = (formattedDateValues, filterOptionIndex) => {
    setDatePresetLabel('');
    let startDate = filterData?.pickedUpAtStart;
    let endDate = filterData?.pickedUpAtEnd;
    if (formattedDateValues) {
      startDate = formattedDateValues[0];
      endDate = formattedDateValues[1];
    }
    DATE_RANGES(dateRangeToDate).forEach(({ value, label }, newIndex) => {
      const element = filterElements[newIndex];
      if (
        value[0].format(DATE_FORMAT) === startDate &&
        value[1].format(DATE_FORMAT) === endDate &&
        !filterOptionIndex
      ) {
        element.classList.add(activeFilterClassName);
        setDatePresetLabel(label);
      } else {
        element.classList.remove(activeFilterClassName);
      }
    });
    if (filterOptionIndex) {
      filterElements[filterOptionIndex].classList.add(activeFilterClassName);
      setDatePresetLabel(DATE_RANGES(dateRangeToDate)[filterOptionIndex].label);
    }
  };

  const onDateChanged = async (dateObjects, dateValues) => {
    const formattedDateValues = dateObjects.map((value) =>
      dayjs(value).format(DATE_FORMAT)
    );
    if (!withOutRanges) {
      checkTagColor(formattedDateValues, dateObjects[2]);
    }
    const date = {
      pickedUpAtStart: formattedDateValues[0],
      pickedUpAtEnd: formattedDateValues[1]
    };
    setIsPanelVisible(false);
    setFilterData && setFilterData({ ...filterData, ...date });
    getFilterData({ ...filterData, ...date });
  };

  return (
    <div className="br-date-picker">
      <DatePicker.RangePicker
        disabled={!isActive}
        inputReadOnly
        className={classNames('br-date-picker__range-picker', {
          'br-date-picker__range-picker-preset':
            datePresetLabel.length || fixedLabel
        })}
        dropdownClassName={classNames(
          'br-date-picker__date-filter',
          dropdownClassName
        )}
        panelRender={(node) => (
          <>
            {<div>{datePickerHeader()}</div>}
            {node}
          </>
        )}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement.parentElement
            .parentElement
        }
        allowClear={false}
        ranges={
          !withOutRanges && intl.locale === LOCALE.AR
            ? {
                اليوم: TODAY,
                أمس: YESTERDAY,
                'هذا الاسبوع': dateRangeToDate ? THIS_WEEK_TO_DATE : THIS_WEEK,
                'الاسبوع الماضي': LAST_WEEK,
                'آخر 7 أيام': LAST_SEVEN_DAYS,
                'هذا الشهر': dateRangeToDate ? THIS_MONTH_TO_DATE : THIS_MONTH,
                'هذا الربع': dateRangeToDate
                  ? THIS_QUARTER_TO_DATE
                  : THIS_QUARTER,
                'هذا العام': dateRangeToDate ? THIS_YEAR_TO_DATE : THIS_YEAR,
                'الشهر الماضي': LAST_MONTH,
                'الربع الأخير': LAST_QUARTER,
                'العام الماضي': LAST_YEAR
              }
            : !withOutRanges && {
                Today: TODAY,
                Yesterday: YESTERDAY,
                'This week': dateRangeToDate ? THIS_WEEK_TO_DATE : THIS_WEEK,
                'Last week': LAST_WEEK,
                'Last 7 days': LAST_SEVEN_DAYS,
                'This month': dateRangeToDate ? THIS_MONTH_TO_DATE : THIS_MONTH,
                'This quarter': dateRangeToDate
                  ? THIS_QUARTER_TO_DATE
                  : THIS_QUARTER,
                'This year': dateRangeToDate ? THIS_YEAR_TO_DATE : THIS_YEAR,
                'Last month': LAST_MONTH,
                'Last quarter': LAST_QUARTER,
                'Last year': LAST_YEAR
              }
        }
        disabledDate={disabledDate}
        onChange={onDateChanged}
        onOpenChange={(visible) => {
          setIsPanelVisible(visible);
          if (checkVisbilty) {
            checkVisbilty(visible);
          }
        }}
        value={datePickerValue}
        format={!datePresetLabel.length && 'D MMM, YYYY'}
        suffixIcon={
          <div className="br-date-picker__range-picker-preset__suffix-container">
            <DatePickerIcon />
            <span className="br-date-picker__date-filter__text">
              {fixedLabel ? label : datePresetLabel}
            </span>
          </div>
        }
        separator={'-'}
        {...props}
      />
    </div>
  );
};

export default injectIntl(BRDatePicker);
