import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import dayjs from 'dayjs';

import { fetchCities } from 'services/cities';
import { INTERNATIONAL_ORDERS_PICKUPS_DATE_FILTERS } from 'constants/international-pickups';
import { DATE_FORMAT } from 'constants/date-picker';
import { cleanEmptyString } from 'utils/helpers';
import { disabledDate } from 'utils/international-order';
import { InternationalShippingContext } from 'contexts/international-shipping.context';

import BRButton from 'components/BRButton/BRButton';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import { notify } from 'components/Notify/Notify';

const InternationalPickupsSearch = ({ stars, handleFilterPickups }) => {
  const [cities, setCities] = useState([]);

  const { businesses } = useContext(InternationalShippingContext);

  const formRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      const data = await fetchCities();
      if (data?.length) {
        setCities(data);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = (values) => {
    Object.keys(cleanEmptyString(values)).forEach((key) => {
      if (INTERNATIONAL_ORDERS_PICKUPS_DATE_FILTERS.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        values[dateStartKey] = dayjs(values[key][0]).format(DATE_FORMAT);
        values[dateEndKey] = dayjs(values[key][1]).format(DATE_FORMAT);

        delete values[key];
      }
    });

    if (values.pickupRequestId) {
      values.pickupRequestId = values.pickupRequestId
        .trim()
        .replace(/,+\s*$/, '')
        .split(/[, ]+/)
        .join(',');
    }

    handleFilterPickups(cleanEmptyString(values));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleFilterPickups({});
  };

  return (
    <div className="br-international-shipping-search__container">
      <div className="br-international-shipping-search__title display-xs">
        {intl.formatMessage({ id: 'common.search' })}
      </div>
      <div className="br-international-shipping-search__content">
        <Form onFinish={onFinish} ref={formRef}>
          <div className="br-international-shipping-search__row ">
            <Form.Item
              name="pickupRequestId"
              label={intl.formatMessage({
                id: 'international_orders.pickups.search.pickup_id_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'international_orders.pickups.search.pickup_id_placeholder'
                })}
              />
            </Form.Item>
            <Form.Item
              name="businessId"
              label={intl.formatMessage({
                id: 'business_details.business_info.business_name'
              })}
            >
              <Select
                showSearch
                optionFilterProp="name"
                placeholder={intl.formatMessage({
                  id: 'international_orders.pickups.search.business_placeholder'
                })}
                options={businesses}
                fieldNames={{ label: 'name', value: '_id' }}
              />
            </Form.Item>
            <Form.Item
              name="cityId"
              label={intl.formatMessage({
                id: 'pickups.pickup_details.pickup_info.city'
              })}
            >
              <Select
                showSearch
                optionFilterProp="name"
                placeholder={intl.formatMessage({ id: 'common.select' })}
                options={cities}
                fieldNames={{ label: 'name', value: '_id' }}
              />
            </Form.Item>
            <Form.Item
              name="starId"
              label={intl.formatMessage({
                id: 'international_orders.pickups.search.courier_label'
              })}
            >
              <Select
                showSearch
                optionFilterProp="label"
                placeholder={intl.formatMessage({
                  id: 'international_orders.pickups.search.courier_placeholder'
                })}
                options={stars}
              />
            </Form.Item>
          </div>
          <div className="br-international-shipping-search__row ">
            {INTERNATIONAL_ORDERS_PICKUPS_DATE_FILTERS.map((key) => (
              <SearchDateRangePicker
                key={key}
                name={key}
                label={intl.formatMessage({
                  id: `international_orders.pickups.search.${key}`
                })}
                disabledDate={disabledDate}
              />
            ))}
          </div>
          <div className="br-international-shipping-search__actions">
            <BRButton
              onClick={handleResetFilters}
              label={intl.formatMessage({ id: 'common.clear_filters' })}
            />
            <BRButton
              type="primary"
              htmlType="submit"
              label={intl.formatMessage({
                id: 'international_orders.pickups.search.apply_filters'
              })}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InternationalPickupsSearch;
