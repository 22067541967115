import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';

import { ACL_MATRIX } from 'common/permissions';
import { SUPER_ADMIN } from 'common/aclMatrix';
import { isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';

import BRButton from 'components/BRButton/BRButton';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import GenerateBarcodeModal from 'components/HubOperations/BarcodeToAWB/components/GenerateBarcodeModal/GenerateBarcodeModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import EmptyStateIcon from 'assets/bosta-icons/Empty-Orders-View.svg';

import './BarcodesList.less';

const BarcodesList = ({
  intl,
  history,
  hasMore,
  loadMore,
  barcodes,
  isLoading
}) => {
  const handleStartScanning = () => {
    history.push('/barcode-scanning');
  };

  const handleGenerateBarcodeSheetsClick = () => {
    openModal(GenerateBarcodeModal, {
      reopenModal: handleGenerateBarcodeSheetsClick
    });
  };

  return (
    <div className="br-barcodes-list__container">
      <div className="br-barcodes-list__header">
        <div className="br-barcodes-list__title display-xs">
          {intl.formatMessage({ id: 'barcode_to_awb.barcodes_list.title' })}
        </div>
        <div className="br-barcodes-list__action">
          {isUserAuthorized([SUPER_ADMIN], [ACL_MATRIX.BARCODES_CREATE]) && (
            <BRButton
              type="primary"
              onClick={handleGenerateBarcodeSheetsClick}
              label={intl.formatMessage({
                id: 'barcode_to_awb.barcodes_list.actions.generate_barcodes'
              })}
            />
          )}
          <BRButton
            type="primary"
            onClick={handleStartScanning}
            label={intl.formatMessage({
              id: 'barcode_to_awb.barcodes_list.actions.start_scanning'
            })}
          />
        </div>
      </div>
      <div className="br-barcodes-list__body">
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
          threshold={1}
        >
          <LoadingWrapper loading={isLoading}>
            {!!barcodes.length ? (
              barcodes.map(({ barcode }) => (
                <div className="br-barcodes-list__item body-medium">
                  {barcode}
                </div>
              ))
            ) : (
              <BREmptyState
                emptyViewImage={EmptyStateIcon}
                title={intl.formatMessage({
                  id: 'barcode_to_awb.barcodes_list.empty_state.title'
                })}
              />
            )}
          </LoadingWrapper>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default withRouter(injectIntl(BarcodesList));
