import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Drawer, Timeline } from 'antd';

import DateTime from '../DateTime/DateTime';

import './SealLogs.less';

const SealLogs = ({ intl, open, onClose, seal, renderState }) => {
  const {
    sealNumber,
    closeTime,
    trackingNumbers = [],
    state,
    log
  } = seal || {};

  const [sortedLogsByDate, setSortedLogsByDate] = useState([]);

  useEffect(() => {
    if (seal?.log) {
      setSortedLogsByDate(
        log.sort((a, b) => new Date(b.time) - new Date(a.time))
      );
    }
  }, [seal]);

  return (
    <Drawer
      className="br-seal-logs__drawer"
      title={
        <>
          {intl.formatMessage({
            id: 'hubs.seals_logs.headers.seal_code'
          })}
          : <span className="br-seal-number">{sealNumber}</span>
        </>
      }
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <div className="br-seal-logs__seal-data">
        <div>
          <span>
            {intl.formatMessage({
              id: 'hubs.seals_logs.headers.closed_at'
            })}
            :{' '}
            <div className="text-bold">
              <DateTime dateTime={closeTime} />
            </div>
          </span>
        </div>
        <div>
          {intl.formatMessage({
            id: 'hubs.seals_logs.headers.no_of_packages'
          })}
          : <div className="text-bold">{trackingNumbers.length}</div>
        </div>
        <div>
          {intl.formatMessage({
            id: 'hubs.seals_logs.headers.seal_state'
          })}
          : <div>{renderState(state)}</div>
        </div>
      </div>
      <hr className="br-seal-logs__divider" />
      {!!sortedLogsByDate?.length && (
        <Timeline>
          <div className="br-seal-logs__timeline__title">
            {intl.formatMessage({
              id: 'hubs.seals_logs.log_timeline'
            })}
            :
          </div>
          {sortedLogsByDate.map(({ action, time, takenBy }, index) => (
            <Timeline.Item color="gray" key={index}>
              <div className="br-seal-logs__log">
                <div>
                  <div className="text-bold">{action}</div>
                  <div className="text-gray">
                    <span className="text-bold">{takenBy?.userName}</span> [
                    {takenBy?.userRole}]
                  </div>
                </div>
                <DateTime dateTime={time} />
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </Drawer>
  );
};

export default injectIntl(SealLogs);
