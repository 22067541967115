import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { formatDatesForPayload } from 'constants/fulfillment-inventory-serials';
import { seperateNumberDigits } from 'utils/helpers';

import { notify } from 'components/Notify/Notify';

import './POStatistics.less';

const POStatistics = ({
  updateFilters,
  poFilters = {},
  fetchData,
  getStatisticsListToRender,
  statisticsFilters
}) => {
  const [statisticsObj, setStatisticsObj] = useState({});

  const fetchPOStatistics = async () => {
    const { datesFilter, ...restFilters } = statisticsFilters || {};
    
    const payload = {
      ...formatDatesForPayload(datesFilter),
      ...restFilters
    };

    try {
      const { statistics = {} } = await fetchData(payload);
      setStatisticsObj(statistics);
    } catch (error) {
      notify(error.message);
    }
  };

  const renderStatistic = ({
    key,
    title,
    content = 0,
    icon = null,
    stateIdFilterValue,
    typeFilterValue
  }) => (
    <div
      key={key}
      className={classNames('br-po-statistics__statistic', key, {
        'has-icon': !!icon,
        selected: poFilters.type
          ? poFilters.type === typeFilterValue
          : poFilters.state === stateIdFilterValue
      })}
      onClick={() =>
        updateFilters({ state: stateIdFilterValue, type: typeFilterValue })
      }
    >
      <div>
        <span className="br-po-statistics__statistic__title">{title}</span>
        <span className="br-po-statistics__statistic__content">
          {seperateNumberDigits(content)}
        </span>
      </div>
      {icon && <div className="br-po-statistics__statistic__icon">{icon}</div>}
    </div>
  );

  useEffect(() => {
    fetchPOStatistics();
  }, [poFilters?.state, statisticsFilters]);

  return (
    <div className="br-po-statistics">
      {getStatisticsListToRender(statisticsObj).map(renderStatistic)}
    </div>
  );
};

export default POStatistics;
