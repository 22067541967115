import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';

import { getFormattedNumber } from 'utils/number';
import { COUNTRY_CURRENCY } from 'constants/helper';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import './BRHeaderCardContent.less';

/**
 *
 * @param {*} inline true/false
 * @param {*} dataSource
 * [{
 *    label: 'Bosta Fees',
 *    tooltip: 'Collection, Shipping, Bulky and Vat fees',
 *    value: '30',
 *    isVisible: () => true,
 *    children: [{
 *      label: 'Shipping Fees',
 *      tooltip: 'Collection, Shipping, Bulky and Vat fees',
 *      value: '50'
 *      isVisible: () => true,
 *    }]
 * }]
 */

const BRHeaderCardContent = ({ inline, dataSource, currencyId }) => (
  <div className={classnames({ 'br-header-card-content': !inline })}>
    {dataSource.map((item) => {
      return (
        <>
          {((item.isVisible && item.isVisible()) || !item.isVisible) && (
            <p key={item} className="br-header-card-content__row">
              <span className="display-flex align-center">
                <span className="br-header-card-content__row__label">
                  {item.label}
                </span>
                {item.tooltip && (
                  <Tooltip title={item.tooltip} trigger={['click']}>
                    <InfoCircleFilled />
                  </Tooltip>
                )}
              </span>
              <span className="br-header-card-content__row__value">
                {item.value
                  ? fmt(
                      {
                        id: currencyId || COUNTRY_CURRENCY
                      },
                      {
                        cod: getFormattedNumber(item.value)
                      }
                    )
                  : 0}
              </span>
            </p>
          )}
          {item?.children?.map((child) => {
            return (
              ((child.isVisible && child.isVisible()) || !child.isVisible) && (
                <p
                  key={child.label}
                  className="br-header-card-content__sub-row"
                >
                  <span className="display-flex align-center">
                    <span className="br-header-card-content__sub-row__label">
                      {child.label}
                    </span>
                    {child.tooltip && (
                      <Tooltip title={child.tooltip} trigger={['click']}>
                        <InfoCircleFilled />
                      </Tooltip>
                    )}
                  </span>
                  <span className="br-header-card-content__sub-row__value">
                    {child.value
                      ? fmt(
                          {
                            id: currencyId || COUNTRY_CURRENCY
                          },
                          {
                            cod: getFormattedNumber(child.value)
                          }
                        )
                      : 0}
                  </span>
                </p>
              )
            );
          })}
        </>
      );
    })}
  </div>
);

export default BRHeaderCardContent;
