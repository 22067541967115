import React from 'react';
import { injectIntl } from 'react-intl';

const CollectionFees = ({ intl }) => {
  return (
    <div className="br-pricing-plan__14-percent">
      <p>
        {intl.formatMessage({
          id: 'settings.pricing_plan.vat.collection_fees'
        })}
      </p>
      <span>
        {intl.formatMessage({
          id: 'settings.pricing_plan.vat.collection_fees_sub_title'
        })}
      </span>
    </div>
  );
};

export default injectIntl(CollectionFees);
