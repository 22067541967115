import React, { useState } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Formik } from 'formik';
import { Accordion, Button, Col, Form } from 'react-bootstrap';
import { DatePicker } from 'antd';
import { formatOptions, initialFormValues, routesState } from '../../constant';
import styles from '../../styles.module.scss';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

const SearchForm = ({
  stars,
  hubs,
  handleFormChange,
  handleSubmit,
  handleResetForm,
  formLoading,
  removeStateOption = false,
  handleExport
}) => {
  const [openAccordion, setOpenAccordion] = useState(true);

  return (
    <>
      <Accordion defaultActiveKey="0">
        <div>
          <Accordion.Toggle
            as="a"
            variant="button"
            eventKey="0"
            onClick={() => setOpenAccordion(!openAccordion)}
            className={
              openAccordion
                ? styles.SearchToggleOpen
                : styles.SearchToggleClosed
            }
          >
            Search
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div>
              <Formik
                className="formContainer"
                enableReinitialize
                onChange={handleFormChange}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                initialValues={initialFormValues}
                render={({
                  // handleSubmit,
                  // handleChange,
                  handleBlur,
                  values,
                  // touched,
                  initialValues,
                  setFieldValue,
                  // errors
                  resetForm
                }) => (
                  <Form
                    className={styles.formWidth}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}
                  >
                    {/* hub,state, star */}
                    <Form.Row className={styles.formRow}>
                      {!removeStateOption && (
                        <Form.Group as={Col} sm={4} controlId="state-control">
                          <Form.Label className={styles.formLabel}>
                            Route State
                          </Form.Label>
                          <Select
                            name="states"
                            options={routesState}
                            onChange={(evt) => {
                              setFieldValue('states', evt);
                              handleFormChange('states', evt);
                            }}
                            defaultValue={initialValues.states}
                            value={values.states}
                            onBlur={handleBlur}
                            isClearable
                            isMulti
                          />
                        </Form.Group>
                      )}

                      <Form.Group as={Col} sm={4} controlId="star-control">
                        <Form.Label className={styles.formLabel}>
                          Star
                        </Form.Label>

                        <Select
                          name="starId"
                          options={formatOptions(
                            '_id',
                            'profile.firstName,profile.lastName',
                            stars?.data || []
                          )}
                          onChange={(evt) => {
                            handleFormChange('starId', evt?.value || null);
                            setFieldValue('starId', evt);
                          }}
                          defaultValue={initialValues.starId}
                          onBlur={handleBlur}
                          value={values.starId}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4} controlId="date-control">
                        <Form.Label className={styles.formLabel}>
                          Created At
                        </Form.Label>
                        <DatePicker
                          size="large"
                          name="createdAt"
                          allowClear
                          onChange={(evt) => {
                            if (evt !== null) {
                              setFieldValue(
                                'createdAt',
                                dayjs(evt, 'YYYY-MM-DD')
                              );
                              handleFormChange(
                                'createdAt',
                                dayjs(evt, 'YYYY-MM-DD')
                              );
                            } else {
                              setFieldValue('createdAt', null);
                              handleFormChange('createdAt', null);
                            }
                          }}
                          className="d-block"
                        />
                      </Form.Group>
                    </Form.Row>
                    {/*   business,create at , date */}

                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="warehouses-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Hubs
                        </Form.Label>

                        <Select
                          name="warehouses"
                          options={
                            isDefaultBostaCourier([...aclMatrix.THREE_PL]) &&
                            formatOptions('_id', 'name', hubs?.data || [])
                          }
                          onChange={(evt) => {
                            handleFormChange('warehouses', evt);
                            setFieldValue('warehouses', evt);
                          }}
                          defaultValue={initialValues.warehouses}
                          onBlur={handleBlur}
                          value={values.warehouses}
                          isMulti={isDefaultBostaCourier([
                            ...aclMatrix.THREE_PL
                          ])}
                          isClearable={isDefaultBostaCourier([
                            ...aclMatrix.THREE_PL
                          ])}
                          isDisabled={
                            !isDefaultBostaCourier([...aclMatrix.THREE_PL])
                          }
                        />
                      </Form.Group>
                      <Col>
                        <Form.Row className="justify-content-end">
                          <Form.Group
                            as={Col}
                            sm={3}
                            controlId="filter-control"
                          >
                            <Button
                              type="submit"
                              className="primaryButton position-absolute btn btn-primary col-11"
                              disabled={formLoading}
                            >
                              Filter
                            </Button>
                          </Form.Group>
                          <Form.Group as={Col} sm={3} controlId="clear-control">
                            <Button
                              type="button"
                              className="secondaryButton position-absolute btn btn-primary col-11"
                              onClick={() => {
                                handleResetForm();
                                resetForm({ ...initialValues, states: null });
                              }}
                              disabled={formLoading}
                            >
                              Clear
                            </Button>
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-end">
                      <Form.Group as={Col} sm={2}>
                        <Button
                          className="primaryButton position-absolute btn btn-primary col-11"
                          disabled={formLoading}
                          onClick={handleExport}
                        >
                          Export
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                )}
              />
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

export default SearchForm;
