import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { sumBy } from 'lodash';

import { getFulfillmentSalesOrder } from 'services/sales-orders';
import { getSalesOrderRenderedTag } from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import { getViewSalesOrderContentForRendering } from 'constants/fulfillment-sales-orders';

import BusinessInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/BusinessInfoCard/BusinessInfoCard';
import CustomerInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/CustomerInfoCard/CustomerInfoCard';
import OrderLogsCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/OrderLogsCard/OrderLogsCard';
import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import Products from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/Products';
import TotalOrdersPrice from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/TotalOrdersPrice/TotalOrdersPrice';
import ScanSerialsCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/ScanSerialsCard/ScanSerialsCard';
import LinkedOrdersCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/LinkedOrdersCard/LinkedOrdersCard';

import './SalesOrder.less';

const SalesOrder = ({
  match: {
    params: { id }
  }
}) => {
  const [salesOrder, setSalesOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [scannedSerials, setScannedSerials] = useState([]);
  const [isAllSerialsScanned, setIsAllSerialsScanned] = useState(false);
  const [allSerialsCount, setAllSerialsCount] = useState(0);
  const [clickedLineData, setClickedLineData] = useState(null);

  const fetchSalesOrder = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await getFulfillmentSalesOrder(id);
      setSalesOrder(data.saleOrder);
      setAllSerialsCount(
        sumBy(data.saleOrder.lines, ({ serialsCount }) => Number(serialsCount))
      );
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const {
    productsTableColumns,
    headerProps = {},
    hideTotalOrderSection = false,
    showScanSerialsSection = false
  } = getViewSalesOrderContentForRendering({
    salesOrder,
    isLoading,
    setIsLoading,
    scannedSerials,
    isAllSerialsScanned,
    allSerialsCount,
    clickedLineData,
    setClickedLineData
  });

  useEffect(() => {
    fetchSalesOrder();
  }, []);

  useEffect(() => {
    if (allSerialsCount && allSerialsCount === scannedSerials.length) {
      setIsAllSerialsScanned(true);
    }
  }, [scannedSerials]);

  return (
    <div className="br-sales-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader
            title={salesOrder.trackingNumber}
            secondaryTitle={`(#${salesOrder.id || ''})`}
            renderTag={() =>
              getSalesOrderRenderedTag({
                stateId: salesOrder.state?.stateId,
                typeId: salesOrder.type?.typeId
              })
            }
            {...headerProps}
          />
          <div className="br-po-content">
            <div className="start-side">
              <BusinessInfoCard
                businessInfo={{
                  businessName: salesOrder.businessName,
                  businessId: salesOrder.businessId
                }}
              />
              <CustomerInfoCard customerInfo={salesOrder.customer} />
              <LinkedOrdersCard
                pjIds={salesOrder.pjIds}
                roId={salesOrder.roId}
                soId={salesOrder.originalSalesOrderId}
              />
              <OrderLogsCard orderLogs={salesOrder.history?.reverse()} />
            </div>
            <div className="end-side">
              {showScanSerialsSection && (
                <ScanSerialsCard
                  scannedSerials={scannedSerials}
                  setScannedSerials={setScannedSerials}
                  isAllSerialsScanned={isAllSerialsScanned}
                  soId={id}
                />
              )}
              <Products
                products={salesOrder.lines || []}
                columns={productsTableColumns}
                hideSelectProductBtn
                hideOverlay
              />
              {!hideTotalOrderSection && (
                <TotalOrdersPrice
                  cod={salesOrder.cod}
                  totalPrice={salesOrder.totalPrice}
                />
              )}
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default withRouter(SalesOrder);
