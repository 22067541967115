import { useIntl } from 'react-intl';
import { Modal, Form, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { requiredRule } from 'utils/forms';
import {
  FLIGHT_DETAILS_DATE_FORMAT,
  FLIGHT_DETAILS_PAYLOAD_DATE_FORMAT
} from 'constants/international-orders';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as BostaBoxIcon } from 'assets/bosta-icons/BostaBox.svg';
import { ReactComponent as HashIcon } from 'assets/bosta-icons/HashIcon.svg';
import { ReactComponent as BarcodeIcon } from 'assets/bosta-icons/BarcodeIcon.svg';

import './AddEditFlightDetailsModal.less';
import { numberRegex } from 'utils/helpers';

const AddEditFlightDetailsModal = ({ close, count, onConfirm, ...props }) => {
  const intl = useIntl();

  const onFinish = (values) => {
    values.flightDetails.flightDate = dayjs(values.flightDetails.flightDate)
      .utc()
      .format(FLIGHT_DETAILS_PAYLOAD_DATE_FORMAT);

    onConfirm(values);
    close();
  };

  return (
    <Modal
      title={null}
      footer={null}
      wrapClassName="br-flight-details__modal"
      {...props}
    >
      <div className="br-flight-details-modal__header">
        <span className="display-xs">
          {intl.formatMessage(
            {
              id: 'international_orders.create_edit_flight_modal.title'
            },
            {
              count
            }
          )}
        </span>
        <span className="br-flight-details-modal-header__subtitle">
          {intl.formatMessage(
            {
              id: 'international_orders.create_edit_flight_modal.subtitle'
            },
            { count }
          )}
        </span>
      </div>
      <div className="br-flight-details-modal__content">
        <div className="br-flight-details-modal-content__title">
          <BostaBoxIcon />
          <span className="heading">
            {intl.formatMessage({
              id: 'international_orders.create_edit_flight_modal.content_title'
            })}
          </span>
        </div>
        <Form onFinish={onFinish} name="flightDetailsModal">
          <Form.Item
            name={['flightDetails', 'flightNumber']}
            label={intl.formatMessage({
              id: 'international_orders.create_edit_flight_modal.form_labels.flight_no'
            })}
            rules={[
              requiredRule(
                intl.formatMessage({
                  id: 'international_orders.create_edit_flight_modal.required_message'
                })
              )
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'international_orders.create_edit_flight_modal.placeholder.flight_no'
              })}
              prefix={<HashIcon />}
            />
          </Form.Item>
          <Form.Item
            name={['flightDetails', 'flightDate']}
            label={intl.formatMessage({
              id: 'international_orders.create_edit_flight_modal.form_labels.flight_date'
            })}
            rules={[
              requiredRule(
                intl.formatMessage({
                  id: 'international_orders.create_edit_flight_modal.required_message'
                })
              )
            ]}
          >
            <DatePicker
              showTime
              use12Hours
              showNow={false}
              format={FLIGHT_DETAILS_DATE_FORMAT}
            />
          </Form.Item>
          <Form.Item
            name={['flightDetails', 'masterAWB']}
            label={intl.formatMessage({
              id: 'international_orders.create_edit_flight_modal.form_labels.master_awb'
            })}
            rules={[
              {
                pattern: numberRegex,
                message: intl.formatMessage({
                  id: 'international_orders.create_edit_flight_modal.master_awb_validation'
                })
              },
              requiredRule(
                intl.formatMessage({
                  id: 'international_orders.create_edit_flight_modal.required_message'
                })
              )
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'international_orders.create_edit_flight_modal.placeholder.master_awb'
              })}
              prefix={<BarcodeIcon />}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="br-flight-details-modal__footer">
        <BRButton
          block
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={close}
        />
        <BRButton
          block
          type="primary"
          label={intl.formatMessage({ id: 'common.confirm' })}
          htmlType="submit"
          form="flightDetailsModal"
        />
      </div>
    </Modal>
  );
};

export default AddEditFlightDetailsModal;
