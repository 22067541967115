import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import BRButton from 'components/BRButton/BRButton';

import './BREditInputField.less';

const BREditInputField = ({
  close,
  weight,
  initialValues,
  name,
  handleUpdateInputValue = () => {},
  label,
  title,
  prefix,
  suffix,
  ...props
}) => {
  const formRef = useRef();

  const intl = useIntl();

  const onFinish = (values) => {
    handleUpdateInputValue({ ...values });
    close();
  };

  const handleOnChangeForm = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
  };

  return (
    <Modal
      title={title}
      onCancel={close}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            form="updateInput"
            htmlType="submit"
            type="primary"
            label={intl.formatMessage({ id: 'common.confirm' })}
          />
        </>
      }
      okText={intl.formatMessage({ id: 'common.confirm' })}
      {...props}
    >
      <Form
        ref={formRef}
        onFinish={onFinish}
        initialValues={initialValues}
        name="updateInput"
      >
        <Form.Item
          name={name}
          label={label}
          className="br-edit-input-field__form-item"
        >
          <BRFormInputNumber
            filedName={name}
            formRef={formRef}
            handleOnChangeForm={handleOnChangeForm}
            prefix={prefix}
            suffix={suffix}
            acceptFloat
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BREditInputField;
