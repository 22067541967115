import { Select } from 'antd';

const SearchSelect = ({ ...props }) => {
  return (
    <Select
      allowClear
      showSearch
      showArrow
      getPopupContainer={(trigger) => trigger.parentElement}
      filterOption={(input, option) =>
        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option.value.toString().toLowerCase().includes(input.toLowerCase())
      }
      disabled={props?.disabled || !props?.options?.length}
      {...props}
    />
  );
};

export default SearchSelect;
