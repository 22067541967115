export const IMPERSONATION_REQUEST_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING'
};

export const IMPERSONATION_REQUEST_STATUS_ICONS = {
  [IMPERSONATION_REQUEST_STATUS.APPROVED]:
    'https://storage.googleapis.com/bosta-fe/Admin-Dashboard/impersonation-approved-icon.png',
  [IMPERSONATION_REQUEST_STATUS.PENDING]:
    'https://storage.googleapis.com/bosta-fe/Admin-Dashboard/impersonation-pending-icon.png'
};

export const SLLR_DASHBOARD_URL =
  window.env && window.env.SLLR_DASHBOARD_URL
    ? window.env.SLLR_DASHBOARD_URL
    : 'https://dashboard.sllr.co';
