import { BASE } from 'apis';
import { notify } from 'components/Notify/Notify';
import { downloadAsXlsx } from '../utils';

export const getStars = async (page) => {
  const { data } = await BASE.get(`/users/stars?pageId=${page - 1}`);
  return data;
};
export const settlementBodyAction = async (id, data) => {
  try {
    return await BASE.post(`/users/stars/${id}/settlement`, data);
  } catch (err) { 
    return err;
  }
};
// stars/9JyR2itrdehb5Z97w/collect-all-cash-money
export const allCollectCashMoney = async (id) => {
  try {
    const data = await BASE.put(`/users/stars/${id}/collect-all-cash-money`);
    notify('all cash collected successfully', 'success');
    return data;
  } catch (err) {
    return err;
  }
};
// stars/9JyR2itrdehb5Z97w/collect-cash-money
export const collectCashMoney = async (id, MoneyId) => {
  try {
    const payload = {
      cashMoneyId: MoneyId
    };
    const data = await BASE.put(
      `/users/stars/${id}/collect-cash-money`,
      payload
    );
    notify('cash collected successfully', 'success');
    return data;
  } catch (err) {
    return err;
  }
};
// /stars/${starId}`, { verified: false }
export const changeStatus = async (id, status) => {
  try {
    const data = await BASE.put(`/users/stars/${id}`, { verified: status });
    notify('status changed successfully', 'success');
    return data;
  } catch (err) {
    return err;
  }
};

export const changeState = async (id, status) => {
  try {
    const data = await BASE.put(`/users/stars/${id}`, { state: status });
    notify('state changed successfully', 'success');
    return data;
  } catch (err) {
    return err;
  }
};

export const getCashRecords = async (allData) => {
  try {
    // const pageInfo = { page: allData.pageId, limit: allData.limit };

    const data = await BASE.get(
      `/users/stars/${allData.id}/wallet?page=${allData.pageId}&limit=${allData.limit}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const editStars = async (id, data) => {
  try {
    // const response = await BASE.put(`/users/stars/${id}`, data);
    // notify('stars Edit successfully', 'success');
    return await BASE.put(`/users/stars/${id}`, data);
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((e) => notify(e.message));
    } else {
      notify(err.message);
    }
    return null;
  }
};

export const searchStars = async (values) => {
  let url = `/businesses/search?pageId=${values.page}`;
  if (values.id) url += `&id=${values.id}`;
  if (values.name) url += `&name=${encodeURIComponent(values.name)}`;
  if (values.phone) url += `&id=${values.phone}`;
  if (values.city) url += `&id=${values.city}`;
  if (values.status) url += `&id=${values.status}`;
  const { data } = await BASE.get(url);
  return data;
};

export const getStarsByAvailabilityState = async ({ availabilityState }) => {
  try {
    const { data } = await BASE.get('/users/stars/all');
    const filter = Array.isArray(availabilityState)
      ? availabilityState.join(',')
      : availabilityState;
    return data?.message?.stars.filter((el) =>
      filter.includes(el.starInfo.availabilityState)
    );
  } catch (e) {
    return null;
  }
};

export const getAllAvailableStars = async () => {
  try {
    const { data } = await BASE.get('/users/stars/all?available=true');
    return data.message.stars;
  } catch (e) {
    return null;
  }
};

export const exportXlsx = async (search) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json'
      },
      params: search
    };

    const res = await BASE.get(`/users/stars/exportXlsx`, header);
    if (res.headers['content-type'].includes('application/json')) {
      notify('Stars exported to your mail successfully.', 'success');
    } else {
      notify('Stars loaded successfully', 'success');
      downloadAsXlsx(res.data, `Stars.xlsx`);
      return res.data;
    }
  } catch (error) {
    notify(error.response.data.message || error.message);
    return null;
  }
};

export const exportStarRecord = async (data) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };

    const { starId, dateExportFrom, dateExportTo } = data;
    const res = await BASE.get(
      `/cash/star-transactions/${starId}/${dateExportFrom}/${dateExportTo}`,
      header
    );
    notify('Stars transactions loaded successfully', 'success');
    if (res) downloadAsXlsx(res.data, `StarsTransactions.xlsx`);
    return res.data;
  } catch (error) {
    notify(error.response.data.message || error.message);
    return null;
  }
};

export const getStarLiabilities = async (starId) => {
  try {
    const { data } = await BASE.get(
      `/cash/calculate-star-liabilities/${starId}`
    );
    return data;
  } catch (e) {
    return null;
  }
};
