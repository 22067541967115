import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import Icon from '@ant-design/icons';

import Sounds from 'common/Sounds';
import { scanInternationalOrder } from 'services/international-orders';
import { formatInternationalDeliveryData } from 'utils/international-order';
import {
  INTERNATIONAL_DELIVERY_ACTIONABLE_CODES,
  INTERNATIONAL_DELIVERY_ACTION_ERROR
} from 'constants/international-orders';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './InternationalOrdersActionScan.less';

const { beep, warning } = Sounds;

const InternationalOrdersActionScan = ({
  isScanning,
  scannedDeliveries,
  setScannedDeliveries,
  updateType
}) => {
  const intl = useIntl();

  const scanInputRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (isScanning) {
      scanInputRef.current.focus();
    }
  }, [isScanning]);

  const formatDeliveryData = (delivery) => ({
    ...formatInternationalDeliveryData({ delivery }),
    delete: (
      <Icon
        component={Close}
        className="ant-icon-lg"
        onClick={() => handleRemoveItem(delivery._id)}
      />
    )
  });

  const handleRemoveItem = (deliveryId) => {
    setScannedDeliveries((prev) =>
      prev.filter((delivery) => delivery._id !== deliveryId)
    );
  };

  const validateDelivery = (scannedDelivery) => {
    if (!scannedDelivery) {
      warning();
      return notify(
        intl.formatMessage({
          id: 'international_orders.transfer.scan.incorrect_tracking'
        })
      );
    }

    const alreadyScanned = scannedDeliveries.find(
      (delivery) => delivery._id === scannedDelivery._id
    );

    if (alreadyScanned) {
      warning();
      notify(
        intl.formatMessage({
          id: 'international_orders.transfer.scan.already_scanned'
        })
      );
    } else if (
      !INTERNATIONAL_DELIVERY_ACTIONABLE_CODES[updateType].includes(
        scannedDelivery.state.code
      )
    ) {
      warning();
      notify(INTERNATIONAL_DELIVERY_ACTION_ERROR[updateType]);
    } else {
      setScannedDeliveries((prev) => [
        formatDeliveryData(scannedDelivery),
        ...prev
      ]);
      beep();
    }
  };

  const scanTrackingNumber = async (trackingNumber) => {
    try {
      const { data } = await scanInternationalOrder({ trackingNumber });

      validateDelivery(data);
      resetTrackingNumberField();
    } catch (error) {
      warning();
      resetTrackingNumberField();
      notify(error.message);
    }
  };

  const handleScanTrackingNumber = debounce((e) => {
    const value = e.target.value?.trim();
    scanTrackingNumber(value);
  }, 300);

  const resetTrackingNumberField = () => {
    formRef.current?.resetFields(['trackingNumber']);
    scanInputRef.current?.focus();
  };

  return (
    <div className="br-international-shipping-search__container">
      <div className="br-international-shipping-search__title display-xs">
        {intl.formatMessage({
          id: 'international_orders.transfer.scan.title'
        })}
      </div>
      <div className="br-international-shipping-scan__content">
        <Form ref={formRef}>
          <Form.Item
            label={intl.formatMessage({
              id: 'international_orders.transfer.scan.scan_tracking_number'
            })}
            name="trackingNumber"
          >
            <Input
              ref={scanInputRef}
              placeholder={intl.formatMessage({
                id: 'international_orders.transfer.scan.scan_or_enter_tracking_number'
              })}
              disabled={!isScanning}
              onChange={handleScanTrackingNumber}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default InternationalOrdersActionScan;
