import classnames from 'classnames';

import { MAX_SEVERITY_LEVEL } from 'constants/escalation';

import './EscalationSeverity.less';

const EscalationSeverity = ({ severityLevel }) => {
  const levels = Array.from({ length: MAX_SEVERITY_LEVEL }, (_, i) => i + 1);

  return (
    <div className="br-escalation-severity__container">
      {levels.map((level) => (
        <>
          <div
            className={classnames('br-escalation-severity__item', {
              [`br-escalation-severity__item-${level}`]: severityLevel >= level
            })}
          />
          {level === MAX_SEVERITY_LEVEL &&
            severityLevel == MAX_SEVERITY_LEVEL &&
            ' 🚨'}
        </>
      ))}
    </div>
  );
};
export default EscalationSeverity;
