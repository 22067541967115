import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH,
  FULFILLMENT_RETURN_TO_VENDOR_ROUTES
} from 'constants/fulfillment-return-to-vendor';

const FulfillmentReturnToVendor = () => {
  return (
    <Switch>
      {FULFILLMENT_RETURN_TO_VENDOR_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentReturnToVendor);
