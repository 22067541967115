import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import BusinessesTable from './components/BusinessesTable/BusinessesTable';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';

const BusinessesList = ({ intl }) => {
  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_businesses.title'
          })}
        />
      }
      content={<BusinessesTable />}
    />
  );
};

export default injectIntl(withRouter(BusinessesList));
