import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Checkbox, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date-picker';
import { BUSINESS_TIERS } from 'constants/Businesses';
import { currentUserCountry } from 'common/countries/countries-mapping';
import {
  addHoliday,
  getOfficialHolidays,
  deleteHoliday
} from 'services/system';
import { openModal } from 'utils/modal';
import { HOLIDAY_SETTINGS_OPTIONS } from 'constants/System';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Delete } from 'assets/bosta-icons/Delete.svg';

import './HolidaySettings.less';

const HolidaySettings = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [count, setCount] = useState(0);

  const { RangePicker } = DatePicker;

  const refreshTable = useRef();
  const formRef = useRef();

  const fetchHolidays = async ({ page, limit, filterValues }) => {
    setIsLoading(true);
    try {
      const payload = {
        pageNumber: page - 1,
        pageLimit: limit,
        ...filterValues
      };

      const { data, total } = await getOfficialHolidays({ payload });

      setCount(total);
      setIsLoading(false);

      return {
        list: data,
        total
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onFinish = (values) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'settings.holiday_settings.modal_title'
      }),
      content: intl.formatMessage({
        id: 'settings.holiday_settings.modal_content'
      }),
      confirmButtonLabel: intl.formatMessage({
        id: 'settings.holiday_settings.modal_confirm'
      }),
      confirmButtonType: 'primary',
      modalAction: () => handleCreateHoliday(values)
    });
  };

  const handleCreateHoliday = async (values) => {
    setIsLoading(true);
    try {
      const { holidaySettings } = values;

      const options = HOLIDAY_SETTINGS_OPTIONS.reduce(
        (previousValue, { value }) => {
          return { ...previousValue, [value]: holidaySettings.includes(value) };
        },
        {}
      );
      delete values.holidaySettings;

      values.holidayDateStart = values.holidayDate[0];
      values.holidayDateEnd = values.holidayDate[1];
      delete values.holidayDate;

      const payload = {
        ...values,
        ...options,
        holidayDateStart: dayjs(values.holidayDateStart).format(DATE_FORMAT),
        holidayDateEnd: dayjs(values.holidayDateEnd).format(DATE_FORMAT),
        countryCode: currentUserCountry.codeName
      };

      await addHoliday(payload);
      formRef.current.resetFields();
      notify(
        intl.formatMessage({
          id: 'settings.holiday_settings.success_message'
        }),
        'success'
      );
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDeleteHoliday = async (id) => {
    setIsLoading(true);
    try {
      await deleteHoliday(id);
      notify(
        intl.formatMessage({
          id: 'settings.holiday_settings.delete_message'
        }),
        'success'
      );
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const disabledDate = (current) => {
    return current && current <= dayjs().startOf('day');
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleRowSelection = (selectedRowKeys) => {
    setSelectedRowsIds(selectedRowKeys);
  };

  const holidaysColumns = [
    {
      key: 'holidayDate',
      dataIndex: 'holidayDate',
      title: intl.formatMessage({
        id: 'settings.holiday_settings.table_columns.date'
      }),
      fixed: 'left',
      render: (date) => {
        return dayjs(date).format(DATE_FORMAT);
      }
    },
    {
      key: 'businessTiers',
      dataIndex: 'businessTiers',
      title: intl.formatMessage({
        id: 'settings.holiday_settings.table_columns.business_tiers'
      }),
      render: (businessTiers) => {
        return businessTiers.join(', ');
      }
    },
    {
      key: 'options',
      dataIndex: '',
      width: 500,
      title: intl.formatMessage({
        id: 'settings.holiday_settings.table_columns.options'
      }),
      render: ({
        stopSchedulingPickups,
        stopSchedulingOrders,
        skipWenshActions
      }) => {
        return (
          <div>
            <div>
              {intl.formatMessage({
                id: 'settings.holiday_settings.table_columns.prevent_pickups'
              })}

              <div
                className={`status-dot ${
                  stopSchedulingPickups ? 'green' : 'red'
                }`}
              />
            </div>

            <div>
              {intl.formatMessage({
                id: 'settings.holiday_settings.table_columns.prevent_orders'
              })}

              <div
                className={`status-dot ${
                  stopSchedulingOrders ? 'green' : 'red'
                }`}
              />
            </div>

            <div>
              {intl.formatMessage({
                id: 'settings.holiday_settings.table_columns.skip_wensh'
              })}

              <div
                className={`status-dot ${skipWenshActions ? 'green' : 'red'}`}
              />
            </div>
          </div>
        );
      }
    },
    {
      key: 'delete',
      title: '',
      fixed: 'right',
      render: (row) => (
        <Delete
          className="br-hub-transfer-delete__action"
          onClick={() => handleDeleteHoliday(row._id)}
        />
      )
    }
  ];

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-holiday-settings__container">
        <BRContentHeader
          title={intl.formatMessage({
            id: 'settings.holiday_settings.title'
          })}
          isInternalComponent
        />
        <div className="br-holiday-settings__form-container">
          <Form
            ref={formRef}
            onFinish={onFinish}
            initialValues={{
              businessTiers: BUSINESS_TIERS.map(({ value }) => value)
            }}
          >
            <div className="br-holiday-settings__form-row">
              <Form.Item name="holidayDate" rules={[{ required: true }]}>
                <RangePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item name="businessTiers" rules={[{ required: true }]}>
                <Select showArrow options={BUSINESS_TIERS} mode="multiple" />
              </Form.Item>
            </div>
            <Form.Item name="holidaySettings" rules={[{ required: true }]}>
              <Checkbox.Group options={HOLIDAY_SETTINGS_OPTIONS} />
            </Form.Item>
            <div className="br-holiday-settings__actions">
              <BRButton
                type="primary"
                htmlType="submit"
                label={intl.formatMessage({ id: 'common.save' })}
              />
            </div>
          </Form>
        </div>

        <BRTable
          title={intl.formatMessage(
            {
              id: 'settings.holiday_settings.table_title'
            },
            { count }
          )}
          scroll={{ y: 400 }}
          columns={holidaysColumns}
          listFunction={fetchHolidays}
          showFilter={false}
          handleSelectedRows={handleRowSelection}
          shareMethods={acceptMethods}
          allowSelection={false}
        />
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(HolidaySettings);
