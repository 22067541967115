import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Select, Form, Input } from 'antd';

import { escalateDelivery } from 'services/deliveries';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Flag } from 'assets/bosta-icons/escalation-modal.svg';

import './escalateModal.less';

const EscalateModal = ({
  intl,
  visible,
  close,
  onSuccess,
  deliveryId,
  deliveryTickets
}) => {
  const [severityLevel, setSeverityLevel] = useState('1');

  const handleOnFinish = async ({ ticketNumber }) => {
    try {
      const payload = {
        ticketNumber
      };

      await escalateDelivery({ deliveryId, payload });
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.escalation_modal.success_msg'
        }),
        'success'
      );
      close();
      onSuccess();
    } catch (e) {
      notify(e.message);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={close}
      className="br-Escalation-modal__container"
      okText="Confirm"
      okType=""
      okButtonProps={{
        className: 'br-escalation-modal__primary-btn',
        htmlType: 'submit',
        form: 'escalationform'
      }}
    >
      <div className="br-escalation-modal__header">
        <Flag />
        <h3>
          {intl.formatMessage({
            id: 'deliveries.delivery_details.escalation_modal.title'
          })}
        </h3>
      </div>
      <div className="br-escalation-modal__dropdown">
        <Form
          onFinish={handleOnFinish}
          name="escalationform"
          initialValues={{
            ticketNumber: deliveryTickets?.[0]?.externalId
          }}
        >
          <Form.Item
            name="ticketNumber"
            rules={[{ required: true }]}
            label={intl.formatMessage({
              id: 'deliveries.delivery_details.escalation_modal.ticket_number'
            })}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'deliveries.delivery_details.escalation_modal.header'
            })}
          >
            <Select
              showSearch
              size="large"
              className="br-escalation-modal__dropdown-select"
              placeholder={intl.formatMessage({
                id: 'deliveries.delivery_details.escalation_modal.select_placeholder'
              })}
              value={severityLevel}
              onSelect={setSeverityLevel}
              optionFilterProp="children"
              disabled={false}
              options={[
                {
                  value: '1',
                  label: intl.formatMessage({
                    id: 'deliveries.delivery_details.escalation_modal.severy_option_1'
                  })
                }
              ]}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default injectIntl(EscalateModal);
