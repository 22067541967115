import React from 'react';
import { Modal } from 'antd';

import { notify } from 'components/Notify/Notify';

const DeleteTemplateModal = ({
  close,
  message,
  title,
  editTemplate,
  template,
  ...props
}) => {
  const handleOnDeleteClick = async () => {
    if (template.hubs.length) {
      notify(
        'Assigned hubs on the template, please remove the assigned hubs first.'
      );
      close();
    } else {
      await editTemplate({
        values: null,
        id: template.id,
        callHubEndpoint: true,
        deleteAction: true,
        notifyMessage: 'Template deleted successfully'
      });
      close();
    }
  };

  return (
    <Modal
      onCancel={close}
      {...props}
      title={null}
      width={null}
      cancelText={'Cancel'}
      okText={'Yes'}
      onOk={handleOnDeleteClick}
    >
      <div className="">
        <div>
          <span className="br-add-factors-modal__performance-factor-warning">
            Are you sure you want to remove this template?
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTemplateModal;
