import { memo, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GoogleMap, useLoadScript, InfoBox } from '@react-google-maps/api';

import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';
import { mapDefaultLocation } from 'common/countries/countries-mapping';
import { HubsContext } from 'contexts/hub.context';
import {
  checkPolygonsIntersection,
  generateInitialPolygon,
  getPolygonCentroid,
  getZoomLevelForPolygon
} from 'utils/bosta-coding';
import {
  HUB_POLYGON_OPTIONS,
  ZONE_POLYGON_OPTIONS
} from 'constants/bosta-coding';

import BostaCodingPolygon from 'components/BostaCoding/components/BostaCodingPolygon/BostaCodingPolygon';

import './ZonesGeofencingMap.less';

const containerStyle = {
  width: '100%',
  height: '70vh'
};

const ZonesGeofencingMap = ({
  selectedHubId,
  selectedZoneId,
  selectedZoneGeometryPath,
  setSelectedZoneGeometryPath,
  zonePolygonRef,
  otherZonesGeometry,
  setSelectedZoneId,
  hubZones,
  setZoneGeofencingErrors
}) => {
  const [hubGeometryPath, setHubGeometryPath] = useState([]);

  const intl = useIntl();

  const { scopedHubs } = useContext(HubsContext);

  const isSelectedZoneWithNoGeometryPath =
    selectedZoneId && !selectedZoneGeometryPath.length;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  useEffect(() => {
    if (selectedHubId) {
      const selectedHub = scopedHubs.find((hub) => hub._id === selectedHubId);
      const geometryPath = selectedHub.geometry
        ? selectedHub.geometry.coordinates[0].map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0]
          }))
        : [];

      setHubGeometryPath(geometryPath);
    }
  }, [selectedHubId]);

  useEffect(() => {
    if (selectedZoneGeometryPath?.length && hubZones?.length) {
      validateZoneGeofencing();
    }
  }, [selectedZoneGeometryPath, hubZones]);

  const validateZoneGeofencing = () => {
    const zoneCoordinates =
      zonePolygonRef?.current?.state?.polygon
        ?.getPath()
        ?.getArray()
        ?.map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() })) || [];

    const allPolygons = hubZones.map((zone) => {
      if (zone.geometry && zone._id !== selectedZoneId) {
        return zone.geometry.coordinates[0].map((coordinates) => ({
          lat: coordinates[1],
          lng: coordinates[0]
        }));
      }
    });

    setZoneGeofencingErrors({
      polygonsIntersecting: checkPolygonsIntersection([
        ...allPolygons.filter(Boolean),
        ...(zoneCoordinates.length ? [zoneCoordinates] : [])
      ])
    });
  };

  const handleMapClick = ({ latLng: { lat: getLat, lng: getLng } }) => {
    if (!isSelectedZoneWithNoGeometryPath) {
      return;
    }

    const lat = getLat();
    const lng = getLng();

    setSelectedZoneGeometryPath(generateInitialPolygon({ lat, lng }));
  };

  if (!isLoaded) {
    return intl.formatMessage({ id: 'common.map_failed_to_load' });
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={
        selectedZoneId
          ? getZoomLevelForPolygon(selectedZoneGeometryPath)
          : selectedHubId
          ? getZoomLevelForPolygon(hubGeometryPath)
          : 10
      }
      streetView={false}
      options={{
        streetViewControl: false,
        clickableIcons: false,
        mapTypeControl: false
      }}
      mapTypeControl={false}
      center={
        selectedZoneId
          ? getPolygonCentroid(selectedZoneGeometryPath)
          : selectedHubId
          ? getPolygonCentroid(hubGeometryPath)
          : mapDefaultLocation
      }
      onClick={handleMapClick}
    >
      <InfoBox
        position={
          hubGeometryPath.length
            ? getPolygonCentroid(hubGeometryPath)
            : mapDefaultLocation
        }
        options={{
          isHidden: !isSelectedZoneWithNoGeometryPath
        }}
      >
        <h5 className="br-bosta-coding-info-box__container">
          {intl.formatMessage({
            id: 'bosta_coding.zones_geofencing.add_zone_geofence'
          })}
        </h5>
      </InfoBox>
      <BostaCodingPolygon
        geometryPath={hubGeometryPath}
        onClick={handleMapClick}
        options={HUB_POLYGON_OPTIONS}
        editable={false}
      />
      <BostaCodingPolygon
        geometryPath={selectedZoneGeometryPath}
        polygonRef={zonePolygonRef}
        onClick={handleMapClick}
        onMouseUp={validateZoneGeofencing}
        options={ZONE_POLYGON_OPTIONS(true)}
      />
      {otherZonesGeometry.map((zone, index) => (
        <div key={index}>
          <BostaCodingPolygon
            geometryPath={zone.coordinates}
            editable={false}
            onClick={() => setSelectedZoneId(zone._id)}
            options={ZONE_POLYGON_OPTIONS(false)}
          />
        </div>
      ))}
    </GoogleMap>
  );
};

export default memo(ZonesGeofencingMap);
