import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import './BRFormInputCount.less';

const BRFormInputCounter = ({
  maxCount,
  label,
  optional = false,
  intl,
  value,
  onChange,
  children
}) => {
  const [count, setCount] = useState(value ? value.length : 0);
  const [itemValue, setItemValue] = useState('');

  const onItemChange = (evt) => {
    const {
      target: { value }
    } = evt;

    if (onChange) {
      const validRegex = onChange(evt);
      if (validRegex) {
        setItemValue(value);
        setCount(value.length);
      } else if (validRegex === undefined) {
        setItemValue(value);
        setCount(value.length);
      }
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onChange: onItemChange,
        value: value || itemValue
      });
    }
    return child;
  });

  useEffect(() => {
    setCount(value?.length || 0);
  }, [value]);

  return (
    <>
      <div className="br-form-count__label-container">
        <div className="br-form-count__optional-label-container">
          <span className="br-form-count__label">{label}</span>
          {optional && (
            <span className="br-form-count__optional-label">
              {intl.formatMessage({
                id: 'form.new_optional_label'
              })}
            </span>
          )}
        </div>
        <span className="br-form-count__count-label">
          {count}/{maxCount}
        </span>
      </div>
      {childrenWithProps}
    </>
  );
};

export default injectIntl(BRFormInputCounter);
