import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

const FailedDeliveriesModal = ({
  intl,
  onSuccess,
  close,
  failedDeliveries,
  ...props
}) => {
  return (
    <Modal
      onCancel={close}
      {...props}
      title={null}
      footer={null}
      wrapClassName="br-rejection-reasons-modal"
    >
      <BRContentHeader
        title={
          <>
            <span>Failed Deliveries</span>
          </>
        }
      />
      <div>
        These orders IDs are not eligible for reset:{' '}
        {failedDeliveries?.map((delivery, index) => {
          return index === failedDeliveries.length - 1
            ? `#${delivery}`
            : index === failedDeliveries.length - 2
            ? `#${delivery} and `
            : `#${delivery}, `;
        })}
      </div>
    </Modal>
  );
};

export default injectIntl(FailedDeliveriesModal);
