import React, { useState, useRef } from 'react';
import {
  Modal,
  InputNumber,
  DatePicker,
  Form,
  Button,
  // Checkbox,
  Select
} from 'antd';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  // TARGET_TYPES,
  PROMOTION_SUBTYPES,
  VALUE_TYPES
} from 'constants/promotions';

import '../Promotions.less';

const EditPromotionModal = ({
  intl,
  onSuccess,
  close,
  promotion,
  isTierPromotion = false,
  ...restProps
}) => {
  const [selectedValueType, setSelectedValueType] = useState(
    promotion?.valueType
  );
  const [volumeCap, setVolumeCap] = useState({
    min: promotion?.minVolumeCap,
    max: promotion?.maxVolumeCap
  });

  const formRef = useRef();

  const handleSubmit = ({
    activeAt,
    inactiveAt,
    minVolumeCap,
    maxVolumeCap,
    volumeCapIntervalInDays,
    durationIntervalInDays,
    value,
    percentage
    //  target
  }) => {
    const payload = {
      promotionId: promotion._id,
      // ...(promotion?.subType === PROMOTION_SUBTYPES[0].value
      //   ? isTierPromotion
      //     ? {
      //         durationIntervalInDays
      //       }
      //     : {
      //         activeAt: moment(activeAt).startOf('day').format(),
      //         inactiveAt: moment(inactiveAt).endOf('day').format()
      //       }
      //   : {
      //       minVolumeCap,
      //       maxVolumeCap,
      //       volumeCapIntervalInDays
      //     }),
      // value:
      //   selectedValueType === VALUE_TYPES[0].value
      //     ? value / 100
      //     : parseInt(value),
      // valueType: selectedValueType
      // target,
      activeAt: dayjs(activeAt).startOf('day').format(),
      inactiveAt: dayjs(inactiveAt).endOf('day').format(),
      percentage: percentage / 100
    };
    onSuccess(payload);
    close();
  };

  const hanldeValueTypeChange = (value) => {
    setSelectedValueType(value);
    formRef.current.setFieldsValue({ value: '' });
  };

  const hanldeMinVolumeCapChange = (value) => {
    setVolumeCap({ ...volumeCap, min: value });
    if (value > volumeCap.max) {
      setVolumeCap({ ...volumeCap, max: value });
      formRef.current.setFieldsValue({
        maxVolumeCap: value
      });
    }
  };

  const disabledDate = (current) => {
    const inactiveAt = formRef.current.getFieldValue('inactiveAt');
    if (inactiveAt) {
      return current > dayjs(inactiveAt).startOf('day');
    }
    return current && current < dayjs().endOf('day');
  };

  const disabledExpiryDate = (current) => {
    const activeAt = formRef.current.getFieldValue('activeAt');
    return current < dayjs(activeAt).endOf('day');
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'promotions.modal.edit_title'
      })}
      footer={null}
      {...restProps}
    >
      <Form
        onFinish={handleSubmit}
        initialValues={{
          activeAt: dayjs(promotion?.activeAt),
          inactiveAt: dayjs(promotion?.inactiveAt),
          value:
            promotion?.valueType === VALUE_TYPES[0].value
              ? (promotion?.value * 100).toFixed(2)
              : promotion?.value,
          valueType: promotion?.valueType,
          minVolumeCap: promotion?.minVolumeCap,
          maxVolumeCap: promotion?.maxVolumeCap,
          volumeCapIntervalInDays: promotion?.volumeCapIntervalInDays,
          durationIntervalInDays: promotion?.durationIntervalInDays,
          percentage: (promotion?.percentage * 100).toFixed(2)
          // target: promotion?.target
        }}
        ref={formRef}
      >
        {/* {promotion?.subType === PROMOTION_SUBTYPES[0].value ? (
          isTierPromotion ? (
            <Form.Item
              label={intl.formatMessage({
                id: 'promotions.modal.duration'
              })}
              name="durationIntervalInDays"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                className="full-width"
                placeholder={intl.formatMessage({
                  id: 'promotions.modal.placeholder.duration'
                })}
                min={1}
                step={1}
                prefix={intl.formatMessage({
                  id: 'pickups.pickup_details.repetition_info.days'
                })}
              />
            </Form.Item>
          ) : ( */}
        <>
          <Form.Item
            label={intl.formatMessage({
              id: 'promotions.modal.activation_date'
            })}
            name="activeAt"
            rules={[
              {
                required: true
              }
            ]}
          >
            <DatePicker
              placeholder={intl.formatMessage({
                id: 'promotions.modal.placeholder.enter_date'
              })}
              disabledDate={disabledDate}
              showToday={false}
              format="ll"
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'promotions.modal.expiry_date'
            })}
            name="inactiveAt"
            rules={[
              {
                required: true
              }
            ]}
          >
            <DatePicker
              placeholder={intl.formatMessage({
                id: 'promotions.modal.placeholder.enter_date'
              })}
              disabledDate={disabledExpiryDate}
              showToday={false}
              format="ll"
            />
          </Form.Item>
        </>
        {/* )
        ) : (
          <>
            <div className="display-flex">
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.min_volume_cap'
                })}
                name="minVolumeCap"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber
                  className="full-width"
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.volume_cap'
                  })}
                  min={1}
                  step={1}
                  onChange={hanldeMinVolumeCapChange}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.max_volume_cap'
                })}
                name="maxVolumeCap"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber
                  className="full-width"
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.volume_cap'
                  })}
                  min={volumeCap?.min || 1}
                  step={1}
                  onChange={(value) => {
                    setVolumeCap({ ...volumeCap, max: value });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label={intl.formatMessage({
                id: 'promotions.modal.interval'
              })}
              name="volumeCapIntervalInDays"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                className="full-width"
                placeholder={intl.formatMessage({
                  id: 'promotions.modal.placeholder.interval'
                })}
                min={1}
                step={1}
                prefix={intl.formatMessage({
                  id: 'pickups.pickup_details.repetition_info.days'
                })}
              />
            </Form.Item>
          </>
        )} */}
        {/* <Form.Item
          label={intl.formatMessage({
            id: 'promotions.modal.promotion_value_type'
          })}
          name="valueType"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select onChange={hanldeValueTypeChange}>
            {VALUE_TYPES.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        {/* {selectedValueType === VALUE_TYPES[0].value ? ( */}
        <Form.Item
          label={intl.formatMessage({
            id: 'promotions.modal.percentage'
          })}
          name="percentage"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber
            className="full-width"
            placeholder={intl.formatMessage({
              id: 'promotions.modal.placeholder.percentage'
            })}
            min={1}
            max={100}
            step={1}
            formatter={(value) => value && `${value}%`}
          />
        </Form.Item>
        {/* ) : (
          <Form.Item
            label={intl.formatMessage({
              id: 'pricing.amount'
            })}
            name="value"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber
              className="full-width"
              placeholder={intl.formatMessage({
                id: 'pricing.amount'
              })}
              min={1}
              step={1}
            />
          </Form.Item>
        )} */}
        {/* <Form.Item
          name="target"
          label={intl.formatMessage({
            id: 'promotions.modal.target'
          })}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Checkbox.Group options={TARGET_TYPES} />
        </Form.Item> */}
        <div className="display-flex justify-flex-end">
          <Button className="br-promotions-modal__button" onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'promotions.modal.button.update'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(EditPromotionModal);
