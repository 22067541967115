import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Select } from 'antd';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { lockUnlockDeliveryItem, callDeliveryItem } from 'services/live-ops';
import {
  VALIDATE_EXCEPTION,
  VALIDATE_MESSAGE,
  VALIDATE_CALL,
  UNASSIGN_LIVE_OPS,
  EXCEPTION_REASONS
} from 'constants/live-ops';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './ValidateModal.less';

const ValidateModal = (props) => {
  const { intl, close, id, refTableList, refCard, onSuccess } = props;
  const [step, setStep] = useState(0);
  const [textArea, setTextArea] = useState('');
  const [selectedReasonCode, setSelectedReasonCode] = useState('');
  const [isLoading, setLoading] = useState();

  useEffect(() => {
    setStep(0);
  }, []);

  const unlockService = async () => {
    const payload = {
      updateType: UNASSIGN_LIVE_OPS
    };
    try {
      await lockUnlockDeliveryItem(id, payload);
    } catch (err) {
      notify(err.message);
    }
  };

  const handleOnOk = async (isValid) => {
    const payload = {
      actionType: step === 1 ? VALIDATE_MESSAGE : VALIDATE_CALL,
      isValid
    };
    try {
      const res = await callDeliveryItem(id, payload);
      notify(res.message, 'success');
      if (refTableList) {
        refTableList.current();
        refCard();
      }
      if (onSuccess) {
        onSuccess();
      }
      close();
    } catch (err) {
      notify(err.message);
    }
  };

  const handleOnException = async (isValid) => {
    const payload = {
      actionType: VALIDATE_EXCEPTION,
      note: textArea.length !== 0 ? textArea : undefined,
      isValid,
      ...(isValid ? {} : { exceptionReasonCode: selectedReasonCode })
    };
    try {
      setLoading(true);
      const res = await callDeliveryItem(id, payload);
      notify(res.message, 'success');
      if (refTableList) {
        refTableList.current();
        refCard();
      }
      if (onSuccess) {
        onSuccess();
      }
      close();
    } catch (err) {
      notify(err.message);
    }
    setLoading(false);
  };

  const handleOnCancel = () => {
    unlockService();
    close();
  };

  const handleSelectChange = (value) => {
    setSelectedReasonCode(value);
  };

  const selectionError = () => {
    if (selectedReasonCode !== '' && selectedReasonCode !== undefined) {
      handleOnException(false);
    } else
      notify(
        intl.formatMessage({
          id: 'live_ops.validate_modal_call_sms.select_exception_error'
        })
      );
  };

  const intialState = () => (
    <>
      <>
        <div className="br-validate-modal__heading">
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.title'
          })}
        </div>
        <div className="br-validate-modal__sub-heading">
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.sub_title'
          })}
        </div>
      </>
      <div className="display-flex br-validate-modal__form__footer">
        <Button type="primary" onClick={() => setStep(1)}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.sms'
          })}
        </Button>
        <Button type="primary" onClick={() => setStep(2)}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.call'
          })}
        </Button>
        <Button type="primary" onClick={() => setStep(3)}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.exception'
          })}
        </Button>
      </div>
    </>
  );

  const smsCallState = () => (
    <>
      <>
        <div className="br-validate-modal__heading">
          {intl.formatMessage({
            id:
              step === 1
                ? 'live_ops.validate_modal_call_sms.validate_SMS_title'
                : 'live_ops.validate_modal_call_sms.validate_call_title'
          })}
        </div>
        <div className="br-validate-modal__sub-heading">
          {intl.formatMessage({
            id:
              step === 1
                ? 'live_ops.validate_modal_call_sms.validate_SMS_sub_title'
                : 'live_ops.validate_modal_call_sms.validate_call_sub_title'
          })}
        </div>
      </>
      <div className="display-flex br-validate-modal__form__footer">
        <Button type="error" onClick={() => handleOnOk(false)}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.invalid'
          })}
        </Button>
        <Button type="primary" onClick={() => handleOnOk(true)}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.valid'
          })}
        </Button>
      </div>
    </>
  );

  const exceptionState = () => (
    <>
      <>
        <div className="br-validate-modal__heading">
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.validate_exception_title'
          })}
        </div>
        <div className="br-validate-modal__sub-heading">
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.sub_title'
          })}
        </div>
      </>
      <Select
        placeholder={intl.formatMessage({
          id: 'live_ops.validate_modal_call_sms.select_placeholder'
        })}
        className="br-validate-model__select"
        onChange={handleSelectChange}
        allowClear
      >
        {EXCEPTION_REASONS.map((value, index) => {
          return (
            <Select.Option value={index + 1}>
              {intl.formatMessage({
                id: `live_ops.exceptionReasonsStrings.${value.label}`
              })}
            </Select.Option>
          );
        })}
      </Select>
      <Input.TextArea
        className="br-validate-modal__textarea"
        onChange={(e) => setTextArea(e.target.value)}
        placeholder={intl.formatMessage({
          id: 'live_ops.validate_modal_call_sms.placeholder'
        })}
        autoSize={{ minRows: 3.1, maxRows: 3.1 }}
        maxLength={500}
      />
      <div className="display-flex br-validate-modal__form__footer">
        <Button type="error" onClick={selectionError} disabled={isLoading}>
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.fake'
          })}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            handleOnException(true);
          }}
          disabled={isLoading}
        >
          {intl.formatMessage({
            id: 'live_ops.validate_modal_call_sms.valid'
          })}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      {...props}
      wrapClassName="br-validate-modal"
      title={null}
      footer={null}
      width={null}
      closeIcon={<Icon component={CloseIcon} onClick={handleOnCancel} />}
    >
      {step === 0 && intialState()}
      {(step === 1 || step === 2) && smsCallState()}
      {step === 3 && exceptionState()}
    </Modal>
  );
};
export default injectIntl(ValidateModal);
