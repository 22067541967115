import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get as getValue } from 'lodash';
import { Table, Modal, Switch } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Col, Form, Button } from 'react-bootstrap';
import { notify } from 'components/Notify/Notify';
// import 'antd/es/table/style/index.css';
// import 'antd/es/pagination/style/index.css';
import { getAllDeliveries, getCurrentUserHubId } from '../../actions';
import { getCurrentUserHubInfo } from '../../actions/HubsActions';
import {
  DELIVERY_NEEDED_ACTIONS_TYPES,
  WHATSAPP_ACTION_LEVEL,
  WHATSAPP_NEEDED_ACTIONS_TYPES
} from 'constants/hubs';
import { COUNTRY_CURRENCY } from 'constants/helper';

import styles from '../Deliveries/styles.module.scss';

const deliveriesColumnsMapped = [
  { dataIndex: 'business', title: 'Business' },
  { dataIndex: 'trackingNumber', title: 'Tracking Number' },
  { dataIndex: 'type', title: 'Type' },
  { dataIndex: 'status', title: 'Status' },
  { dataIndex: 'locationInHub', title: 'Location in hub' },
  { dataIndex: 'actionsNeeded', title: 'Actions Needed' },
  { dataIndex: 'action', title: 'Actions' }
];

// const reasons = {
//   'Return to shipper' : 'Return to shipper',
//   'Redispatch tomorrow':'Redispatch tomorrow',
//   'Schedule delivery': 'Schedule delivery',
//   'Change delivery address':'Change delivery address'
// }

const filterOptions = [
  { name: 'All', value: '' },
  { name: 'WhatsApp', value: WHATSAPP_ACTION_LEVEL }
];

class HubsNotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentHubInfo: null,
      trackingNumbers: '',
      neededActionFilterValue: '',
      neededActionLevel: ''
    };
  }

  componentDidMount() {
    this.initLoad();
  }

  initLoad = async () => {
    const { fetchDeliveries } = this.props;
    let hub;
    try {
      const { warehouseInfo } = await getCurrentUserHubInfo();
      hub = warehouseInfo;
    } catch (e) {
      hub = null;
    }
    this.setState({ currentHubInfo: hub });
    fetchDeliveries({
      notifiedHub: hub?._id || undefined,
      neededAction: true,
      pageNumber: 1,
      pageLimit: 50
    });
  };

  markAsDone = async ({ _id }) => {
    try {
      const { deliveries } = this.props;
      this.handleTableChange({
        current: deliveries.pagination.pageNumber || 1,
        pageSize: deliveries.pagination.pageLimit || 50
      });
    } catch (e) {
      notify(e.response?.data?.message || e.message);
    }
  };

  confirmMarkAsDone = ({ _id }) => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure you want to confirm this action?',
      okButtonProps: { className: 'primaryButton' },
      cancelButtonProps: { className: 'secondaryButton' },
      onOk: async () => {
        this.markAsDone({ _id });
      }
    });
  };

  formatData = (data) => {
    const { intl } = this.props;

    return data.map((item) => {
      return {
        key: item._id,
        id: item._id,
        business: `${getValue(item, 'sender.name', '')}`,
        trackingNumber: (
          <Link
            to={`/deliveries/${item._id}/details`}
            style={{
              textDecoration: 'underline'
            }}
          >
            {item.trackingNumber}
          </Link>
        ),
        locationInHub: item.state?.receivedAtWarehouse?.location || 'N/A',
        type: getValue(item, 'type', ''),
        status: item?.state?.value || 'N/A',
        cod:
          item.cod && item.cod.amount
            ? ` ${intl.formatMessage(
                {
                  id: COUNTRY_CURRENCY
                },
                { cod: item.cod.amount }
              )}`
            : 'N/A',
        actionsNeeded: this.formatActionNeeded(
          item.onHold?.actionNeeded,
          item.scheduledAt
        ),
        action: (
          <FileDoneOutlined
            type="file-done"
            title="Mark as done"
            onClick={() => this.confirmMarkAsDone(item)}
          />
        )
      };
    });
  };

  handleTableChange = (pagingOptions) => {
    const { fetchDeliveries } = this.props;
    const {
      currentHubInfo,
      trackingNumbers,
      neededActionFilterValue,
      neededActionLevel
    } = this.state;
    fetchDeliveries({
      trackingNumbers: trackingNumbers || undefined,
      notifiedHub: neededActionLevel.length
        ? undefined
        : currentHubInfo?._id || undefined,
      neededAction: true,
      neededActionType: neededActionLevel
        ? undefined
        : neededActionFilterValue || undefined,
      neededActionLevel: neededActionLevel || undefined,
      neededActionCode: neededActionLevel ? neededActionFilterValue : undefined,
      pageNumber: pagingOptions?.current || 1,
      pageLimit: pagingOptions?.pageSize || 50
    });
  };

  formatActionNeeded = (action, scheduledAt) => {
    if (!action) return 'N/A';
    let takenAction = '';
    switch (action.action) {
      case 'Schedule delivery':
        // scheduledAt
        takenAction = `${action.action} at ${dayjs(scheduledAt)
          .tz()
          .format('L')}`;
        break;
      case 'Change delivery address':
        takenAction = `${action.action} to ${
          action.address?.firstLine || ''
        }, ${action.address?.secondLine || ''}, ${
          action.address?.zone?.name || ''
        }, ${action.address?.city?.name || ''}`;
        break;
      case 'Return to shipper':
      case 'Redispatch tomorrow':
      default:
        takenAction = action.action;
        break;
    }

    return takenAction;
  };

  handleFilterOptionChange = ({ target: { value } }) => {
    if (value) {
      this.setState({
        neededActionLevel: WHATSAPP_ACTION_LEVEL,
        neededActionFilterValue: ''
      });
    } else {
      this.setState({ neededActionLevel: '', neededActionFilterValue: '' });
    }
  };

  render() {
    const { trackingNumbers, neededActionFilterValue, neededActionLevel } =
      this.state;
    const { deliveries, loading } = this.props;
    return (
      <div className="br-hub-notification__container">
        <h3>Notifications</h3>
        <p className="ml-1">
          list of deliveries that need action from your hub.
        </p>
        <Form.Row className="align-items-center">
          <Form.Group as={Col} sm={4} controlId="trackingNumber-control">
            <Form.Label className={styles.formLabel}>
              Search By Tracking Number(s)
            </Form.Label>
            <Form.Control
              type="text"
              value={trackingNumbers || ''}
              onChange={(evt) => {
                this.setState({ trackingNumbers: evt.currentTarget.value });
              }}
              name="trackingNumbers"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>
              Filter By Action Needed
            </Form.Label>
            <Form.Control
              as="select"
              name="actionNeeded"
              placeholder="Action needed"
              value={neededActionFilterValue || ''}
              onChange={(evt) => {
                this.setState({
                  neededActionFilterValue: evt.currentTarget.value
                });
              }}
            >
              <option selected disabled></option>
              {DELIVERY_NEEDED_ACTIONS_TYPES.map((action) => (
                <option value={action.value}>{action.label}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Col>
            <Button
              className="primaryButton"
              onClick={() => {
                this.handleTableChange();
              }}
            >
              Search
            </Button>
            <Button
              className="secondaryButton"
              onClick={() => {
                this.setState(
                  {
                    trackingNumbers: null,
                    neededActionFilterValue: null
                  },
                  () => {
                    this.handleTableChange();
                  }
                );
              }}
            >
              Clear
            </Button>
          </Col>
        </Form.Row>
        <div className="mt-2">
          {deliveries.data.length > 0 && (
            <div className="mb-4">
              <b>Total Deliveries:</b>
              <span className="ml-2">{deliveries.count}</span>
            </div>
          )}
          <Table
            style={{ overflow: 'auto' }}
            columns={deliveriesColumnsMapped}
            dataSource={this.formatData(deliveries.data || [], {})}
            pagination={{
              current: deliveries?.pagination?.pageNumber,
              total: deliveries?.count,
              pageSize: deliveries?.pagination?.pageLimit,
              showSizeChanger: false
              // pageSizeOptions: [50],
            }}
            loading={loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveries: (data) => dispatch(getAllDeliveries(data)),
  fetchGetCurrentHubId: () => dispatch(getCurrentUserHubId())
});

const mapStateToProps = ({ deliveries, hubs }) => {
  return {
    ...deliveries,
    currentUserHubId: hubs.currentUserHubId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HubsNotificationPage));
