import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Button, Menu, Dropdown, Tag, Modal, Checkbox, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classnames from 'classnames';

import {
  getBusiness,
  updateBusiness,
  updateFulfillmentActivation,
  editTeamMemeberInfoValidation,
  confirmTeamMemeberInfoValidation,
  getTeamMemebrsStatus,
  addExtraFlyersQuota,
  editSendAutomatedCalls,
  getSllrData,
  downloadLegalDocuments,
  getBasicllrInfo
} from 'services/business';
import { getActivePromotions } from 'services/promotions';
import {
  deleteAPIKey,
  getAllApiKeysInfo,
  updateAPIKey
} from 'services/api-keys';
import { openModal } from 'utils/modal';
import {
  STATE_COLOR,
  MAP_PERMISSIONS_CODES,
  LOG_TYPE_MAPPING,
  PAYMENT_FREQUENCY_API,
  PAYMENT_FREQUENCY,
  PAYMENT_SCHEDULE,
  JOB_TITLES,
  OTP,
  INTEGRATION_STATES_COLOR,
  API_KEYS_SCOPES,
  API_SUPER_ADMIN_ACCESS,
  BUSINESS_LEGAL_DOCUMENTS_FLAGS,
  MAX_NUMBER_OF_UPLOADABLE_FILES,
  REG_SOURCE
} from 'constants/Businesses';
import { PROMOTION_SUBTYPES_MAPPING, VALUE_TYPES } from 'constants/promotions';
import { isEqual } from 'lodash';
import {
  SLLR_ACTIVATION_QUICK_FILTERS,
  SLLR_URL
} from 'constants/sllr-analytics';
import {
  cleanEmptyString,
  isAdminGroupUser,
  isUserAuthorized
} from 'utils/helpers';
import { downloadAsZip } from 'utils/download';
import aclMatrix, { EXPERIENCE, SUPER_ADMIN } from 'common/aclMatrix';
import { ACL_MATRIX, PAGES_ACCESS } from 'common/permissions';
import { getFulfillmentTierList } from 'services/fulfillment-pricing';
import { RECURRING_PICKUP_BUSINESS_TIERS } from 'constants/pickups';
import { isInternationalShippingAvailable } from 'common/countries/countries-mapping';
import { INTERNATIONAL_WALLET_CELLS } from 'constants/pricing';

import InvalidatedBusinessModal from 'components/Businesses/InvalidatedBusinessModal/InvalidatedBusinessModal';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';
import EditPhoneEmailModal from 'components/Businesses/EditPhoneEmailModal/EditPhoneEmailModal';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import { notify } from 'components/Notify/Notify';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import AddBostaShopQuotaModal from '../AddBostaShopQuotaModal/AddBostaShopQuotaModal';
import AddReturnContactModal from 'components/Businesses/AddReturnContactModal/AddReturnContactModal';
import CreateEditApiKeyModal from '../CreateEditApiKeyModal/CreateEditApiKeyModal';
import BRButton from 'components/BRButton/BRButton';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import ActivateInternationalShippingModal from 'components/Businesses/ActivateInternationalShippingModal/ActivateInternationalShippingModal';
import NewConfirmationModal from 'components/BRModals/NewConfirmationModal/NewConfirmationModal';

import { ReactComponent as Lock } from 'assets/bosta-icons/lock.svg';

import './ShowBusiness.less';

class ShowBusiness extends Component {
  state = {
    businessInfo: {},
    teamMemebersInfo: null,
    isLoading: false,
    isHaveRoleToEdit: false,
    logTableInfo: [],
    defaultPickupsContacts: [],
    hasPromotions: true,
    flyersInfo: {},
    apiIntegrationInfo: [],
    isSllrDataLoading: false,
    sllrData: [],
    isBotCallActive: null,
    fulfillmentTiers: [],
    basicSllrData: { storeLink: false },
    isSllrBasicInfoLoading: false
  };
  refreshTable = createRef();

  acceptMethods = (refreshMethod) => {
    this.refreshTable.current = refreshMethod;
  };
  getBusinessInfo = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const businessInfo = await getBusiness(businessId);
      const {
        data: { fulfillmentTiers }
      } = await getFulfillmentTierList();
      this.setState({
        isLoading: false,
        businessInfo,
        logTableInfo: this.formatLogTableInfo(businessInfo?.logs || []),
        defaultPickupsContacts: this.formatDefaultPickupsContacts(
          businessInfo?.defaultPickupLocation
        ),
        isBotCallActive: businessInfo.isBotCallActive,
        fulfillmentTiers
      });
    } catch (error) {
      notify(error.message);
      this.setState({ isLoading: false });
    }
  };

  formatDefaultPickupsContacts = (pickupLocation) => {
    if (!pickupLocation) {
      return [];
    }

    const { contacts, contactPerson, returnContactPerson } = pickupLocation;

    return contacts.map((contact) => {
      if (contact?._id === contactPerson?._id) {
        contact.pickupDefaultContact = true;
      }
      if (contact?._id === returnContactPerson?._id) {
        contact.returnDefaultContact = true;
      }

      return contact;
    });
  };

  getPromotions = async ({ pageId: pageNumber, pageLimit: pageSize }) => {
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const {
        data: { promotions, total }
      } = await getActivePromotions({
        businessId,
        pageNumber: pageNumber - 1,
        pageSize
      });
      this.setState({ hasPromotions: total > 0 });
      return {
        list: promotions,
        total: total
      };
    } catch (error) {
      notify(error.message);
    }
  };

  formatLogTableInfo = (logs) => {
    const formattedLog = [];
    logs.forEach((log) => {
      formattedLog.push({
        _id: `${log.time}+${log.value}+${log.type}`,
        type: LOG_TYPE_MAPPING[log.type],
        value: log.value,
        date: this.formatDate(log.time),
        takenBy: log.takenBy ? log.takenBy.email : 'N/A'
      });
    });
    return formattedLog;
  };

  formatDate = (date) => {
    const newDate = dayjs(date).tz().format('DD MMM YYYY  h:mm a');
    return newDate;
  };

  getTeamMembersInfo = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const {
        data: { list }
      } = await getTeamMemebrsStatus(businessId);
      this.setState({ isLoading: false, teamMemebersInfo: list });
    } catch (error) {
      notify(error.message);
      this.setState({ isLoading: false });
    }
  };

  getApiIntegrationInfo = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const { data } = await getAllApiKeysInfo(businessId);

      this.setState({ isLoading: false, apiIntegrationInfo: data });
      return {
        list: data,
        total: data.length || 0
      };
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  changeStatusBusiness = async (updateParams) => {
    const { id, ...restParams } = updateParams;
    try {
      const res = await updateBusiness(id, cleanEmptyString(restParams));
      notify(res.message, 'success');
      if (res) {
        this.getBusinessInfo();
      }
    } catch (error) {
      notify(error.message);
    }
  };

  changeFulfillmentActivation = async (updateParams) => {
    const { id, ...restParams } = updateParams;
    try {
      this.setState({ isLoading: true });
      const res = await updateFulfillmentActivation(id, restParams);
      notify(res.message, 'success');
      if (res) {
        this.getBusinessInfo();
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  changeInternationalShippingActivation = async (updateParams) => {
    const { id, ...restParams } = updateParams;
    const { intl } = this.props;

    this.setState({ isLoading: true });

    try {
      const res = await updateBusiness(id, restParams);
      notify(
        intl.formatMessage({
          id: `activate_international_shipping.${
            restParams?.internationalShippingActivated
              ? 'success_activated_message'
              : 'success_deactivated_message'
          }`
        }),
        'success'
      );
      if (res) {
        this.getBusinessInfo();
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleValidClick = () => {
    this.setState({ isLoading: true });
    const {
      match: {
        params: { id: businessId }
      }
    } = this.props;
    this.changeStatusBusiness({ id: businessId, status: 'Valid' });
  };

  handleInvalidateClick = () => {
    const {
      match: {
        params: { id: businessId }
      }
    } = this.props;

    openModal(InvalidatedBusinessModal, {
      businessId,
      handleChangeBusinessStatus: ({ businessId, status, restPayload }) =>
        this.changeStatusBusiness({
          id: businessId,
          status,
          ...restPayload
        })
    });
  };

  handleDownloadLegalDocuments = async () => {
    this.setState({ isLoading: true });
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const data = await downloadLegalDocuments({ businessId });
      downloadAsZip(data, `${businessId}-Legal-Documents`);
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleDownloadPaymentDocuments = async () => {
    this.setState({ isLoading: true });
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const data = await downloadLegalDocuments({
        businessId,
        payload: { type: 'payments' }
      });
      downloadAsZip(data, `${businessId}-Payment-Documents`);
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleActiveClick = () => {
    this.setState({ isLoading: true });
    const {
      match: {
        params: { id: businessId }
      }
    } = this.props;
    const { businessInfo } = this.state;
    const isActive = businessInfo?.isBostaFulfillment;
    this.changeFulfillmentActivation({ id: businessId, isActive: !isActive });
  };

  activateInternationalShipping = (extraParams) => {
    this.setState({ isLoading: true });
    const {
      match: {
        params: { id: businessId }
      }
    } = this.props;
    const { businessInfo } = this.state;
    this.changeInternationalShippingActivation({
      id: businessId,
      internationalShippingActivated:
        !businessInfo?.internationalShippingActivated,
      ...extraParams
    });
  };

  handleActivateInternationalShipping = () => {
    const {
      match: {
        params: { id: businessId }
      },
      intl
    } = this.props;
    const { businessInfo } = this.state;

    if (!businessInfo?.internationalShippingActivated) {
      openModal(ActivateInternationalShippingModal, {
        businessId,
        onConfirm: (numberOfUploadedFiles) => {
          const businessLegalInfo = {
            isInternationalContractUploaded: true
          };

          for (let i = 2; i <= MAX_NUMBER_OF_UPLOADABLE_FILES; i++) {
            businessLegalInfo[`isInternationalActivationDoc${i}Uploaded`] =
              i <= numberOfUploadedFiles;
          }

          this.activateInternationalShipping({
            businessLegalInfo
          });
        }
      });
    } else {
      openModal(NewConfirmationModal, {
        title: intl.formatMessage({
          id: 'international_shipping.modal_deactivate_title'
        }),
        subtitle: intl.formatMessage(
          { id: 'international_shipping.international_shipping_activation' },
          {
            value: intl.formatMessage({
              id: 'international_shipping.modal_deactivate'
            })
          }
        ),
        confirmButtonLabel: intl.formatMessage({
          id: 'international_shipping.deactivate'
        }),
        confirmButtonType: 'destructive-primary',
        onConfirm: this.activateInternationalShipping
      });
    }
  };

  handleEnableInternationalShipping = async () => {
    const {
      match: {
        params: { id: businessId }
      },
      intl
    } = this.props;
    const { businessInfo } = this.state;

    try {
      const payload = {
        internationalShippingEnabled:
          !businessInfo?.internationalShippingEnabled
      };

      const res = await updateBusiness(businessId, payload);
      notify(
        intl.formatMessage({
          id: `activate_international_shipping.${
            payload?.internationalShippingEnabled
              ? 'success_activated_message'
              : 'success_deactivated_message'
          }`
        }),
        'success'
      );
      if (res) {
        this.getBusinessInfo();
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleActivateRecurringPickupsClick = (isActivating) => {
    const { intl } = this.props;
    const { businessInfo } = this.state;

    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: `business_details.activating_recurring_pickups.${
          isActivating ? 'activate_title' : 'deactivate_title'
        }`
      }),
      content: intl.formatMessage(
        {
          id: `business_details.activating_recurring_pickups.${
            isActivating ? 'activate_content' : 'deactivate_content'
          }`
        },
        {
          businessName: businessInfo?.name,
          businessId: businessInfo?._id,
          span: (children) => <span className="body-medium">{children}</span>
        }
      ),
      confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
      confirmButtonType: 'primary',
      modalAction: () =>
        this.changeStatusBusiness({
          id: businessInfo?._id,
          allowRecurringPickups: isActivating
        })
    });
  };

  activateServive = ({ close, onSuccess, ...props }) => {
    const { businessInfo } = this.state;
    const { intl } = this.props;
    const handleConfirm = () => {
      onSuccess();
      close();
    };
    return (
      <Modal
        {...props}
        title={null}
        footer={null}
        onCancel={close}
        className="br-request-pickup-modal"
      >
        <div className="br-request-pickup-modal__title">
          {businessInfo.isBostaFulfillment
            ? 'Deactivate Fulfillment Service'
            : 'Activate Fulfillment Service'}
        </div>
        <div className="br-request-pickup-modal__body">
          {intl.formatMessage(
            { id: 'fulfillment.fulfillment_activation' },
            {
              value: `${
                businessInfo.isBostaFulfillment ? ' deactivate' : ' activate'
              }`
            }
          )}
        </div>
        <div className="br-request-pickup-modal__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" onClick={handleConfirm}>
            {intl.formatMessage({
              id: 'common.confirm'
            })}
          </Button>
        </div>
      </Modal>
    );
  };

  businessInfoCells = () => {
    const { intl } = this.props;
    const { businessInfo } = this.state;
    return [
      {
        key: 'name',
        label: intl.formatMessage({
          id: `business_details.business_info.business_name`
        }),
        render: (name, { createdAt }) => (
          <>
            <div>{name}</div>
            <div>{this.formatDate(createdAt)}</div>
          </>
        )
      },
      ...(businessInfo.arabicName
        ? [
            {
              key: 'arabicName',
              label: intl.formatMessage({
                id: `business_details.business_info.business_arabic_name`
              })
            }
          ]
        : []),
      {
        key: 'status',
        label: intl.formatMessage({
          id: `business_details.business_info.status`
        }),
        render: (status) => {
          return (
            <div className="display-flex align-center">
              {status?.value}
              <Button
                type="primary"
                className="no-min-width ml-5"
                disabled={
                  !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                    ACL_MATRIX.BUSINESS_VALIDATE_STATUS
                  ])
                }
                onClick={() =>
                  businessInfo?.status?.value === 'Invalid'
                    ? this.handleValidClick()
                    : this.handleInvalidateClick()
                }
              >
                {businessInfo?.status?.value === 'Invalid'
                  ? intl.formatMessage({
                      id: 'business_details.business_info.valid'
                    })
                  : intl.formatMessage({
                      id: 'business_details.business_info.invalidate'
                    })}
              </Button>
            </div>
          );
        }
      },
      ...(businessInfo?.address
        ? [
            {
              key: 'deliveryLocationPresets',
              label: intl.formatMessage({
                id: 'business_details.business_info.no_of_pickup_location'
              }),
              render: (deliveryLocationPresets) =>
                deliveryLocationPresets.length + 1
            }
          ]
        : []),
      ...(businessInfo?.type
        ? [
            {
              key: 'type',
              label: intl.formatMessage({
                id: 'business_details.business_info.type'
              })
            }
          ]
        : []),
      ...(businessInfo?.industry
        ? [
            {
              key: 'industry',
              label: intl.formatMessage({
                id: 'business_details.business_info.industry'
              })
            }
          ]
        : []),
      ...(businessInfo?.salesChannel
        ? [
            {
              key: 'salesChannel',
              label: intl.formatMessage({
                id: 'business_details.business_info.sales_channel'
              })
            }
          ]
        : []),
      ...(businessInfo?.website
        ? [
            {
              key: 'website',
              label: intl.formatMessage({
                id: 'business_details.business_info.store_url'
              })
            }
          ]
        : []),
      ...(businessInfo?.status?.invalidationReason
        ? [
            {
              key: ['status', 'invalidationReason'],
              label: intl.formatMessage({
                id: 'business_details.business_info.invalidation_reason'
              })
            }
          ]
        : []),
      ...(businessInfo?.status?.duplicateBusinessAccount
        ? [
            {
              key: ['status', 'duplicateBusinessAccount'],
              label: intl.formatMessage({
                id: 'business_details.business_info.invalidation_reason'
              })
            }
          ]
        : []),
      ...(businessInfo?.status?.notSupportedService
        ? [
            {
              key: ['status', 'notSupportedService'],
              label: intl.formatMessage({
                id: 'business_details.business_info.service_not_available'
              })
            }
          ]
        : []),
      ...(businessInfo?.status?.invalidationNote
        ? [
            {
              key: ['status', 'invalidationNote'],
              label: intl.formatMessage({
                id: 'business_details.business_info.invalidation_note'
              })
            }
          ]
        : []),
      ...(businessInfo?.desc
        ? [
            {
              key: 'desc',
              label: intl.formatMessage({
                id: 'business_details.business_info.description'
              })
            }
          ]
        : []),
      {
        key: 'isBostaFulfillment',
        label: intl.formatMessage({
          id: `fulfillment.fulfillment_service`
        }),
        render: (isBostaFulfillment) => {
          return (
            <div>
              {intl.formatMessage({
                id: `fulfillment.${isBostaFulfillment ? 'active' : 'inactive'}`
              })}
              <Button
                type="primary"
                className="no-min-width"
                disabled={
                  !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                    ACL_MATRIX.BUSINESS_CONFIGURE_FULFILLMENT
                  ])
                }
                onClick={() =>
                  openModal(this.activateServive, {
                    onSuccess: () => {
                      this.handleActiveClick();
                    }
                  })
                }
              >
                {intl.formatMessage({
                  id: `fulfillment.${
                    isBostaFulfillment ? 'deactivate' : 'activate'
                  }`
                })}
              </Button>
            </div>
          );
        }
      },
      {
        key: ['businessLegalInfo'],
        label: intl.formatMessage({
          id: 'business_details.business_info.account_type'
        }),
        render: (businessLegalInfo, { regSrc }) => (
          <>
            <div>
              {intl.formatMessage({
                id: `business_details.business_info.${
                  businessLegalInfo.legalBusinessName ? 'company' : 'individual'
                }`
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: `sllr_analytics.sllr_activation_filters.${
                  regSrc === SLLR_ACTIVATION_QUICK_FILTERS[2].value
                    ? 'sllr'
                    : 'bosta'
                }`
              })}
            </div>
          </>
        )
      },
      {
        key: ['businessLegalInfo'],
        label: intl.formatMessage({
          id: 'business_details.business_info.legal_documents'
        }),
        render: (businessLegalInfo) => {
          const hasBusinessLegalInfoUploaded = Object.keys(
            businessLegalInfo || {}
          ).some((key) => key && BUSINESS_LEGAL_DOCUMENTS_FLAGS.includes(key));

          return hasBusinessLegalInfoUploaded ? (
            <BRButton
              type="link-color"
              onClick={this.handleDownloadLegalDocuments}
              label={intl.formatMessage({
                id: 'business_details.business_info.download_document'
              })}
            />
          ) : (
            <span className="br-business_details__no-document">
              {intl.formatMessage({
                id: 'business_details.business_info.no_document_upload'
              })}
            </span>
          );
        }
      },
      {
        key: ['businessLegalInfo'],
        label: intl.formatMessage({
          id: 'business_details.business_info.payment_documents'
        }),
        render: (businessLegalInfo) => {
          const {
            isInternationalBankCertificateIdUploaded,
            isInternationalSecondBankCertificateIdUploaded
          } = businessLegalInfo;
          return isInternationalSecondBankCertificateIdUploaded ||
            isInternationalBankCertificateIdUploaded ? (
            <BRButton
              type="link-color"
              onClick={this.handleDownloadPaymentDocuments}
              label={intl.formatMessage({
                id: 'business_details.business_info.download_document'
              })}
            />
          ) : (
            <span className="br-business_details__no-document">
              {intl.formatMessage({
                id: 'business_details.business_info.no_document_upload'
              })}
            </span>
          );
        }
      },
      {
        key: 'allowRecurringPickups',
        label: intl.formatMessage({
          id: 'business_details.business_info.recurring_pickup'
        }),
        render: () => {
          const isBusinessTierActivated =
            RECURRING_PICKUP_BUSINESS_TIERS.includes(
              businessInfo?.businessTier
            );
          const isRecurringPickupsActivated =
            businessInfo?.allowRecurringPickups || isBusinessTierActivated;

          return (
            <div>
              {intl.formatMessage({
                id: `fulfillment.${
                  isRecurringPickupsActivated ? 'active' : 'inactive'
                }`
              })}
              {isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                ACL_MATRIX.BUSINESS_UPDATE_RECURRING_PICKUPS_OPTION
              ]) &&
                !isBusinessTierActivated && (
                  <BRButton
                    type="primary"
                    onClick={() =>
                      this.handleActivateRecurringPickupsClick(
                        !isRecurringPickupsActivated
                      )
                    }
                    label={intl.formatMessage({
                      id: `fulfillment.${
                        isRecurringPickupsActivated ? 'deactivate' : 'activate'
                      }`
                    })}
                  />
                )}
            </div>
          );
        }
      },
      ...(isInternationalShippingAvailable()
        ? [
            {
              key: 'internationalShippingEnabled',
              label: intl.formatMessage({
                id: `international_shipping.international_shipping_service`
              }),
              render: () => {
                const {
                  internationalShippingEnabled,
                  internationalShippingActivated
                } = businessInfo || {};

                return (
                  <div>
                    <span
                      className={classnames(
                        'br-business-details__international-shipping-indicator',
                        {
                          'br-business-details__international-shipping-enabled':
                            internationalShippingEnabled,
                          'br-business-details__international-shipping-activated':
                            internationalShippingActivated
                        }
                      )}
                    />
                    <span>
                      {intl.formatMessage({
                        id: `business_details.api_integration.states.${
                          internationalShippingEnabled ? 'enabled' : 'disabled'
                        }`
                      })}
                      (
                      {intl.formatMessage({
                        id: `international_shipping.${
                          internationalShippingActivated ? 'active' : 'inactive'
                        }`
                      })}
                      )
                    </span>

                    {isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                      ACL_MATRIX.BUSINESS_ACTIVATE_INTERNATIONAL_SHIPPING
                    ]) && (
                      <div className="br-business-details__international-shipping-actions">
                        <BRButton
                          type={
                            internationalShippingEnabled ? 'basic' : 'primary'
                          }
                          onClick={this.handleEnableInternationalShipping}
                          label={intl.formatMessage({
                            id: `international_shipping.${
                              internationalShippingEnabled
                                ? 'disable'
                                : 'enable'
                            }`
                          })}
                        />
                        {internationalShippingEnabled && (
                          <BRButton
                            type="primary"
                            onClick={this.handleActivateInternationalShipping}
                            label={intl.formatMessage({
                              id: `international_shipping.${
                                internationalShippingActivated
                                  ? 'deactivate'
                                  : 'activate'
                              }`
                            })}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            }
          ]
        : []),
      {
        key: 'internationalBusinessNetsuiteId',
        label: intl.formatMessage({
          id: 'business_details.business_info.netsuit_id'
        }),
        render: (internationalBusinessNetsuiteId) =>
          internationalBusinessNetsuiteId ||
          intl.formatMessage({ id: 'common.empty_field' })
      },
      {
        key: 'regSrc',
        label: intl.formatMessage({
          id: 'business_details.business_info.reg_src'
        }),
        render: (regSrc) =>
          REG_SOURCE[regSrc] ||
          regSrc ||
          intl.formatMessage({ id: 'common.empty_field' })
      }
    ];
  };

  businessLocationCells = () => {
    const { intl } = this.props;
    return [
      {
        key: 'city',
        label: intl.formatMessage({
          id: `location_form_labels.city`
        }),
        render: (city) => {
          return city?.name;
        }
      },
      {
        key: 'district',
        label: intl.formatMessage({
          id: `location_form_labels.area`
        }),
        render: (district) => {
          return district?.name;
        }
      },
      {
        key: 'firstLine',
        label: intl.formatMessage({
          id: `location_form_labels.street`
        })
      },
      {
        key: 'buildingNumber',
        label: intl.formatMessage({
          id: `location_form_labels.building_number`
        })
      },
      {
        key: 'floor',
        label: intl.formatMessage({
          id: `location_form_labels.floor`
        })
      },
      {
        key: 'apartment',
        label: intl.formatMessage({
          id: `location_form_labels.apartment`
        })
      },
      {
        key: 'secondLine',
        label: intl.formatMessage({
          id: `location_form_labels.landmarks`
        })
      }
    ];
  };

  bankInfo = () => {
    const { intl } = this.props;
    return [
      {
        key: 'bankName',
        label: intl.formatMessage({
          id: `business_details.business_bank_info.bank_name`
        })
      },
      {
        key: 'beneficiaryName',
        label: intl.formatMessage({
          id: `business_details.business_bank_info.account_owner_name`
        })
      },
      {
        key: 'accountNumber',
        label: intl.formatMessage({
          id: `business_details.business_bank_info.account_number`
        })
      },
      {
        key: 'ibanNumber',
        label: intl.formatMessage({
          id: `business_details.business_bank_info.iban`
        })
      }
    ];
  };

  pricingInfo = () => {
    const { intl } = this.props;
    return [
      {
        key: ['pricingFlags', 'isExtraCodFeesApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.extra_cod_fees`
        }),
        render: (isExtraCodFeesApplied) => {
          return isExtraCodFeesApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      },
      {
        key: ['pricingFlags', 'isZeroCodDiscountApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.zero_cod_discount`
        }),
        render: (isZeroCodDiscountApplied) => {
          return isZeroCodDiscountApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      },
      {
        key: ['pricingFlags', 'isInsuranceFeesApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.insurance_fees`
        }),
        render: (isInsuranceFeesApplied) => {
          return isInsuranceFeesApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      },
      {
        key: ['pricingFlags', 'isExpediteFeesApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.next_day_cod_fees`
        }),
        render: (isExpediteFeesApplied) => {
          return isExpediteFeesApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      },
      {
        key: ['pricingFlags', 'isCodFeesApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.cod_fees`
        }),
        render: (isCodFeesApplied) => {
          return isCodFeesApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      },
      {
        key: ['pricingFlags', 'isPosFeesApplied'],
        label: intl.formatMessage({
          id: `business_details.pricing_info.pos_fees`
        }),
        render: (isPosFeesApplied) => {
          return isPosFeesApplied
            ? intl.formatMessage({
                id: `business_details.pricing_info.applied`
              })
            : intl.formatMessage({
                id: `business_details.pricing_info.not_applied`
              });
        }
      }
    ];
  };

  fulfillmentPricingInfo = () => {
    const { intl } = this.props;
    const { fulfillmentTiers } = this.state;
    return [
      {
        key: 'fulfillmentPricingTierId',
        label: 'Pricing Plan',
        render: (fulfillmentPricingTierId) =>
          fulfillmentTiers.find(
            (tier) => tier?._id === fulfillmentPricingTierId
          )?.name
      }
    ];
  };

  walletPaymentInfoCells = () => {
    const { intl } = this.props;

    return [
      {
        key: 'businessType',
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.business_type'
        })
      },
      {
        key: 'businessCategory',
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.business_category'
        })
      },
      {
        key: 'businessTier',
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.business_tier'
        })
      },
      {
        key: ['paymentInfo', 'paymentTransferMethod'],
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.payment_gateway'
        }),
        render: (paymentTransferMethod) => paymentTransferMethod.join(', ')
      },
      {
        key: ['pricingTier', 'name'],
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.pricing_plan'
        })
      },
      {
        key: ['paymentInfo', 'paymentType'],
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.payment_type'
        })
      },
      {
        key: ['paymentInfo', 'creditLimit'],
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.credit_limit'
        }),
        render: (creditLimit) =>
          creditLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      {
        label: intl.formatMessage({
          id: 'business_details.wallet_payment_info.cashout_frequency'
        }),
        render: (businessInfo) =>
          businessInfo.paymentInfo.paymentFrequency ===
          PAYMENT_FREQUENCY_API.DAILY
            ? PAYMENT_FREQUENCY.DAILY
            : businessInfo.paymentInfo.paymentFrequency ===
              PAYMENT_FREQUENCY_API.NEXT_DAY
            ? PAYMENT_FREQUENCY.NEXT_DAY
            : businessInfo.paymentInfo.paymentSchedule.length === 3
            ? PAYMENT_FREQUENCY.THREE_DAYS
            : businessInfo.paymentInfo.paymentSchedule.length === 2
            ? PAYMENT_FREQUENCY.TWO_DAYS
            : isEqual(
                businessInfo.paymentInfo.paymentSchedule,
                PAYMENT_SCHEDULE.WEEKLY_SUN
              )
            ? PAYMENT_FREQUENCY.WEEKLY_SUN
            : isEqual(
                businessInfo.paymentInfo.paymentSchedule,
                PAYMENT_SCHEDULE.WEEKLY_MON
              )
            ? PAYMENT_FREQUENCY.WEEKLY_MON
            : isEqual(
                businessInfo.paymentInfo.paymentSchedule,
                PAYMENT_SCHEDULE.WEEKLY_WED
              )
            ? PAYMENT_FREQUENCY.WEEKLY_WED
            : ''
      }
    ];
  };

  operationsSettingsCells = () => {
    const { intl } = this.props;

    return [
      {
        key: 'numberOfAttempts',
        label: intl.formatMessage({
          id: 'business_details.operations_settings.number_of_attempts'
        })
      },
      {
        key: 'isRtoOtp',
        label: intl.formatMessage({
          id: 'business_details.operations_settings.proof_of_return'
        }),
        render: (isRtoOtp) =>
          isRtoOtp === true
            ? intl.formatMessage({
                id: 'business_details.operations_settings.otp'
              })
            : intl.formatMessage({
                id: 'business_details.operations_settings.signature'
              })
      },
      {
        key: 'pickupProofType',
        label: intl.formatMessage({
          id: 'business_details.operations_settings.pickup_proof'
        }),
        render: (pickupProofType) =>
          pickupProofType === OTP
            ? intl.formatMessage({
                id: 'business_details.operations_settings.otp'
              })
            : intl.formatMessage({
                id: 'business_details.operations_settings.signature'
              })
      }
    ];
  };

  defaultPickupLocation = () => {
    const { intl } = this.props;
    return [
      {
        key: 'locationName',
        label: intl.formatMessage({
          id: 'location_form_labels.location_name'
        })
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.city'
        }),
        render: (address) => {
          return address?.city?.name;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.area'
        }),
        render: (address) => {
          return `${address?.zone?.name ? address.zone.name : ''}${
            address?.district?.name ? ` - ${address?.district?.name}` : ''
          }`;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.street'
        }),
        render: (address) => {
          return address?.firstLine;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.building_number'
        }),
        render: (address) => {
          return address?.buildingNumber;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.floor'
        }),
        render: (address) => {
          return address?.floor;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.apartment'
        }),
        render: (address) => {
          return address?.apartment;
        }
      },
      {
        key: 'address',
        label: intl.formatMessage({
          id: 'location_form_labels.landmarks'
        }),
        render: (address) => {
          return address?.secondLine;
        }
      }
    ];
  };

  basicSllrInfoCells = () => {
    const { intl } = this.props;
    return [
      {
        key: 'storeLink',
        label: intl.formatMessage({
          id: 'business_details.sllr.table_columns.store_link'
        }),
        render: (storeLink) =>
          storeLink && storeLink !== 'N/A' ? (
            <a href={`${SLLR_URL}${storeLink}`} target="_blank">
              {storeLink}
            </a>
          ) : (
            storeLink
          )
      },
      {
        key: 'storeName',
        label: intl.formatMessage({
          id: 'business_details.sllr.table_columns.store_name'
        })
      },

      {
        key: 'storeState',
        label: intl.formatMessage({
          id: 'business_details.sllr.table_columns.sllr_store'
        })
      },
      {
        key: 'businessStatus',
        label: intl.formatMessage({
          id: 'business_details.sllr.table_columns.business_status'
        })
      }
    ];
  };

  TeamMembersColumns = () => {
    const { intl } = this.props;
    const { teamMemebersInfo } = this.state;
    return [
      {
        key: 'profile',
        dataIndex: '',
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.name'
        }),
        render: (user) => {
          const memeber = teamMemebersInfo.find(
            (member) => member.email === user.profile?.email
          );
          return (
            <div className="br-teams-table__username">
              {user.profile ? (
                <div>
                  {user.profile?.firstName || ''} {user.profile?.lastName || ''}
                </div>
              ) : (
                <div>{memeber.emails[0]?.address.split('@')[0]}</div>
              )}

              {user.organizationalAccount && (
                <Tooltip
                  getPopupContainer={(trigger) =>
                    trigger.parentElement.parentElement.parentElement
                      .parentElement
                  }
                  title={intl.formatMessage({
                    id: 'business_details.team_members.organizational_account'
                  })}
                >
                  <Lock />
                </Tooltip>
              )}
            </div>
          );
        }
      },
      {
        key: 'profile',
        dataIndex: ['profile', 'phone'],
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.phone'
        })
      },
      {
        key: 'emails',
        dataIndex: 'emails',
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.email'
        }),
        render: (emails) => {
          return emails[0]?.address;
        }
      },
      {
        key: 'jobTitle',
        dataIndex: ['profile', 'jobTitle'],
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.job_title'
        }),
        render: (jobTitle) => {
          return (jobTitle && JOB_TITLES[jobTitle]) || 'N/A';
        }
      },
      {
        key: 'permission',
        dataIndex: ['group', 'code'],
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.permissions'
        }),
        render: (itemCode) => {
          return MAP_PERMISSIONS_CODES[itemCode]?.value || '-';
        }
      },
      {
        key: 'state',
        dataIndex: ['state', 'code'],
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.status'
        }),
        render: (code) => {
          return (
            code && (
              <Tag color={STATE_COLOR[code]}>
                {intl.formatMessage({
                  id: `business_details.team_members.state.${code}`
                })}
              </Tag>
            )
          );
        }
      },
      {
        key: 'actions',
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.actions'
        }),
        render: (item) => (
          <Dropdown
            trigger={['click']}
            overlay={this.actionsMenu(item)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button>
              {intl.formatMessage({
                id: 'business_details.team_members.table_columns.actions'
              })}
              <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    ];
  };

  logTableColumn = () => {
    const { intl } = this.props;
    return [
      {
        title: intl.formatMessage({
          id: 'business_details.logs.table_columns.type'
        }),
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.logs.table_columns.value'
        }),
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.logs.table_columns.date'
        }),
        dataIndex: 'date',
        key: 'date'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.logs.table_columns.taken_by'
        }),
        dataIndex: 'takenBy',
        key: 'takenBy'
      }
    ];
  };

  IntegrationTableColumn = () => {
    const { intl } = this.props;
    return [
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.name'
        }),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.key'
        }),
        dataIndex: 'key',
        key: 'key'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.created_at'
        }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return this.formatDate(createdAt);
        }
      },
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.permission'
        }),
        dataIndex: 'apiGroup',
        key: 'apiGroup',
        render: (apiGroup = {}) =>
          API_KEYS_SCOPES[apiGroup.name || API_SUPER_ADMIN_ACCESS]
      },
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.state'
        }),
        dataIndex: 'isActive',
        key: 'isActive',
        render: (isActive) => (
          <Tag
            color={
              isActive
                ? INTEGRATION_STATES_COLOR.ENABLED
                : INTEGRATION_STATES_COLOR.DISABLED
            }
          >
            {intl.formatMessage({
              id: `business_details.api_integration.states.${
                isActive ? 'enabled' : 'disabled'
              }`
            })}
          </Tag>
        )
      },
      {
        title: intl.formatMessage({
          id: 'business_details.api_integration.table_columns.actions.title'
        }),
        key: 'actions',
        render: (item) => (
          <Dropdown
            trigger={['click']}
            overlay={this.actionsIntegrationMenu(item)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button>
              {intl.formatMessage({
                id: 'business_details.api_integration.table_columns.actions.title'
              })}
              <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    ];
  };

  sllrTableColumn = () => {
    const { intl } = this.props;
    return [
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.sllr_store'
        }),
        dataIndex: 'storeState',
        key: 'storeState'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.store_name'
        }),
        dataIndex: 'storeName',
        key: 'storeName'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.store_link'
        }),
        dataIndex: 'storeLink',
        key: 'storeLink',
        render: (storeLink) =>
          storeLink ? (
            <a href={`${SLLR_URL}${storeLink}`} target="_blank">
              {storeLink}
            </a>
          ) : (
            'N/A'
          )
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.products_inventory'
        }),
        dataIndex: 'productInventoryCount',
        key: 'productInventoryCount'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.products_catalog'
        }),
        dataIndex: 'productsCount',
        key: 'productsCount'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.activated_bosta'
        }),
        dataIndex: 'businessStatus',
        render: (businessStatus) => businessStatus?.toString(),
        key: 'businessStatus'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.placed_orders'
        }),
        dataIndex: 'completedSllrOrders',
        key: 'completedSllrOrders'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.confirmed_orders'
        }),
        dataIndex: 'confirmedSllrOrders',
        key: 'confirmedSllrOrders'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.pickedup_orders'
        }),
        dataIndex: 'pickedUpSllrOrders',
        key: 'pickedUpSllrOrders'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.successful_orders'
        }),
        dataIndex: 'successfulSllrOrders',
        key: 'successfulSllrOrders'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.sllr.table_columns.total_sllr_revenue'
        }),
        dataIndex: 'totalSllrRevenue',
        key: 'totalSllrRevenue'
      }
    ];
  };

  promotionsTableColumns = () => {
    const { intl } = this.props;
    return [
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.value'
        }),
        dataIndex: 'percentage',
        // render: (value, { valueType }) =>
        //   valueType === VALUE_TYPES[0].value
        //     ? `${(value * 100).toFixed(2)}%`
        //     : intl.formatMessage(
        //         {
        //           id: COUNTRY_CURRENCY
        //         },
        //         {
        //           cod: getFormattedNumber(value)
        //         }
        //       )
        render: (percentage) => `${(percentage * 100).toFixed(2)}%`
      },
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.active'
        }),
        dataIndex: 'activeAt',
        render: (date) =>
          date
            ? this.formatDate(date)
            : intl.formatMessage({ id: 'common.empty_field' })
      },
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.inactive'
        }),
        dataIndex: 'inactiveAt',
        render: (date) =>
          date
            ? this.formatDate(date)
            : intl.formatMessage({ id: 'common.empty_field' })
      },
      // {
      //   title: intl.formatMessage({
      //     id: 'business_details.promotions.table_columns.target'
      //   }),
      //   dataIndex: 'target',
      //   render: (target) =>
      //     target?.map(
      //       (type, index) =>
      //         `${TARGET_TYPES_MAPPING[type]}${
      //           index !== target.length - 1 ? ', ' : ''
      //         }`
      //     )
      // },
      // {
      //   title: intl.formatMessage({
      //     id: 'promotions.modal.min_volume_cap'
      //   }),
      //   dataIndex: 'minVolumeCap',
      //   render: (value) =>
      //     value || intl.formatMessage({ id: 'common.empty_field' })
      // },
      // {
      //   title: intl.formatMessage({
      //     id: 'promotions.modal.max_volume_cap'
      //   }),
      //   dataIndex: 'maxVolumeCap',
      //   render: (value) =>
      //     value || intl.formatMessage({ id: 'common.empty_field' })
      // },
      // {
      //   title: intl.formatMessage({
      //     id: 'promotions.modal.interval'
      //   }),
      //   dataIndex: 'volumeCapIntervalInDays',
      //   render: (value) =>
      //     intl.formatMessage(
      //       { id: `common.${value ? 'days_amount' : 'empty_field'}` },
      //       {
      //         days: value
      //       }
      //     )
      // },
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.subtype'
        }),
        dataIndex: 'subType',
        render: (subtype) => PROMOTION_SUBTYPES_MAPPING[subtype]
      },
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.taken_by'
        }),
        dataIndex: ['ownerInfo', 'email'],
        render: (value) => value || 'N/A'
      },
      {
        title: intl.formatMessage({
          id: 'business_details.promotions.table_columns.created'
        }),
        dataIndex: 'createdAt',
        render: (date) => this.formatDate(date)
      }
    ];
  };

  actionsIntegrationMenu = (item) => {
    const { intl } = this.props;
    return (
      <Menu
        className="br-teams__table__actions-menu"
        onClick={(e) => e.domEvent.stopPropagation()}
      >
        <Menu.Item
          disabled={
            !isUserAuthorized(aclMatrix.API_KEY_ACTIONS, [
              ACL_MATRIX.BUSINESS_EDIT_API_KEY
            ])
          }
          onClick={() => this.handleEditApiKey(item)}
        >
          {intl.formatMessage({
            id: 'common.edit'
          })}
        </Menu.Item>
        <Menu.Item
          disabled={
            !isUserAuthorized(aclMatrix.API_KEY_ACTIONS, [
              ACL_MATRIX.BUSINESS_DELETE_API_KEY
            ])
          }
          onClick={() => this.handleDeleteApiKey(item)}
        >
          {intl.formatMessage({
            id: 'common.delete'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  handleDeleteApiKey = (item) => {
    const { intl } = this.props;

    openModal(BRConfirmationModal, {
      title: intl.formatMessage({
        id: 'business_details.api_integration.remove.title'
      }),
      message: intl.formatMessage({
        id: 'business_details.api_integration.remove.message'
      }),
      onConfirm: () => {
        this.handleConfirmDeleteApiKey(item);
      }
    });
  };

  handleConfirmDeleteApiKey = async (item) => {
    try {
      this.setState({
        isLoading: true
      });
      await deleteAPIKey(item._id);
      this.refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    this.setState({
      isLoading: false
    });
  };

  handleEditApiKey = async (item) => {
    this.setState({
      isLoading: true
    });
    openModal(CreateEditApiKeyModal, {
      apiKeyInfo: {
        apiGroupName: item.apiGroup?.name,
        ...item
      },
      onFinish: async (payload) => {
        try {
          await updateAPIKey(item._id, payload);
          notify('Api Key Updated successfully', 'success');
          this.refreshTable.current();
        } catch (error) {
          notify(error.message);
        }
      }
    });
    this.setState({
      isLoading: false
    });
  };

  actionsMenu = (item) => {
    const { intl } = this.props;
    const { isHaveRoleToEdit } = this.state;
    return (
      <Menu
        className="br-teams__table__actions-menu"
        onClick={(e) => e.domEvent.stopPropagation()}
      >
        <Menu.Item
          disabled={
            (isAdminGroupUser()
              ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                  ACL_MATRIX.BUSINESS_EDIT_TEAM_MEMBER_EMAIL
                ])
              : !isHaveRoleToEdit) ||
            !item?.emails[0]?.address ||
            !item?.profile
          }
          onClick={() => this.handleEditPhone(item)}
        >
          {intl.formatMessage({
            id: 'business_details.team_members.table_columns.edit_phone'
          })}
        </Menu.Item>
        <Menu.Item
          disabled={
            !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
              ACL_MATRIX.BUSINESS_EDIT_TEAM_MEMBER_EMAIL
            ]) || !item?.profile
          }
          onClick={() => this.handleEditEmail(item)}
        >
          {intl.formatMessage({
            id: 'business_details.team_members.table_columns.edit_email'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  handleEditEmail = (item) => {
    const { intl } = this.props;

    openModal(BROTPCodeModal, {
      title: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_email.title'
      }),
      subtitle: intl.formatMessage(
        {
          id: 'business_details.team_members.otp_screens.business_validation.edit_email.subtitle'
        },
        {
          phone: <span>{item.profile.phone?.slice(-3)}</span>
        }
      ),
      confirmOTP: (otp) =>
        confirmTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'email',
          otp
        }),
      generateOTP: () =>
        editTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'email'
        }),
      onSuccess: () => {
        openModal(EditPhoneEmailModal, {
          newEmail: true,
          memeberId: item._id,
          getBusinessInfo: this.getBusinessInfo,
          getTeamMembersInfo: this.getTeamMembersInfo
        });
      }
    });
  };

  handleEditPhone = (item) => {
    const { intl } = this.props;
    openModal(BROTPCodeModal, {
      title: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_phone.title'
      }),
      subtitle: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_phone.subtitle'
      }),
      confirmOTP: (otp) =>
        confirmTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'phone',
          otp
        }),
      generateOTP: () =>
        editTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'phone'
        }),
      onSuccess: () => {
        openModal(EditPhoneEmailModal, {
          newPhone: true,
          memeberId: item._id,
          getBusinessInfo: this.getBusinessInfo,
          getTeamMembersInfo: this.getTeamMembersInfo
        });
      }
    });
  };

  handleOnAddQuotaClick = (initialAmount) => {
    openModal(AddBostaShopQuotaModal, {
      onFinish: (payload) => this.handleAddBusinessFlyersQuota(payload),
      initialAmount
    });
  };

  handleAddBusinessFlyersQuota = async (payload) => {
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      await addExtraFlyersQuota(businessId, payload);
      notify('Extra quota added successfully', 'success');
      window.location.reload();
    } catch (error) {
      notify(error.message);
    }
  };

  handleAddReturnContact = () => {
    const {
      match: {
        params: { id: businessId }
      }
    } = this.props;

    openModal(AddReturnContactModal, {
      businessId,
      onSuccess: this.getBusinessInfo
    });
  };

  bostaShopColumn = () => {
    const { intl } = this.props;
    return [
      {
        key: 'availableNoOfFlyers',
        label: intl.formatMessage({
          id: 'business_details.bosta_shop.current_quota'
        }),
        render: (availableNoOfFlyers, record) => {
          const amount = record?.extraQuota
            ? availableNoOfFlyers + record?.extraQuota
            : availableNoOfFlyers;
          return `${amount} Free Flyers`;
        }
      },
      {
        key: 'maxLimitOfFlyers',
        label: intl.formatMessage({
          id: 'business_details.bosta_shop.initial_quota'
        }),
        render: (maxLimitOfFlyers) => `${maxLimitOfFlyers} Free Flyers`
      },
      {
        key: 'addQuota',
        label: intl.formatMessage({
          id: 'business_details.bosta_shop.action'
        }),
        render: (_, record) => (
          <Button
            type="primary"
            onClick={() =>
              this.handleOnAddQuotaClick(record.availableNoOfFlyers)
            }
            disabled={
              !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                ACL_MATRIX.BUSINESS_ADD_FLYERS_QUOTE
              ])
            }
          >
            {intl.formatMessage({
              id: 'common.edit'
            })}
          </Button>
        )
      }
    ];
  };

  defaultPickupContactsLocation = () => {
    const { intl } = this.props;
    return [
      {
        key: 'name',
        title: intl.formatMessage({
          id: 'business_details.business_default_pickup_contacts.table_columns.contact_name'
        }),
        dataIndex: 'name'
      },
      {
        key: 'phone',
        title: intl.formatMessage({
          id: 'business_details.team_members.table_columns.phone'
        }),
        dataIndex: 'phone'
      },
      {
        key: 'returnDefaultContact',
        title: intl.formatMessage({
          id: 'business_details.business_default_pickup_contacts.table_columns.return_default_contact'
        }),
        dataIndex: 'returnDefaultContact',
        render: (returnDefaultContact) =>
          returnDefaultContact
            ? intl.formatMessage({ id: 'common.yes' })
            : intl.formatMessage({ id: 'common.no' })
      },
      {
        key: 'pickupDefaultContact',
        title: intl.formatMessage({
          id: 'business_details.business_default_pickup_contacts.table_columns.pickup_default_contact'
        }),
        dataIndex: 'pickupDefaultContact',
        render: (pickupDefaultContact) =>
          pickupDefaultContact
            ? intl.formatMessage({ id: 'common.yes' })
            : intl.formatMessage({ id: 'common.no' })
      }
    ];
  };

  consigneeNotificationColumn = () => {
    const { intl } = this.props;
    const { isBotCallActive } = this.state;
    return [
      {
        label: intl.formatMessage({
          id: 'business_details.consignee_notifications.send_automated_calls'
        }),
        key: 'isBotCallActive',
        render: () => {
          return (
            ['boolean', 'undefined', 'string'].includes(
              typeof isBotCallActive
            ) && (
              <div>
                <Checkbox
                  disabled={
                    !isUserAuthorized(aclMatrix.EDIT_SEND_AUTOMATED_CALLS, [
                      ACL_MATRIX.BUSINESS_CONFIGURE_BOT_CALL
                    ])
                  }
                  onChange={this.handleOnChangeEditSendAutomatedCalls}
                  defaultChecked={isBotCallActive}
                >
                  {intl.formatMessage({
                    id: 'business_details.consignee_notifications.send_automated_calls'
                  })}
                </Checkbox>
              </div>
            )
          );
        }
      }
    ];
  };

  handleOnChangeEditSendAutomatedCalls = async ({ target: { checked } }) => {
    try {
      this.setState({
        isLoading: true
      });
      const {
        match: {
          params: { id: businessId }
        },
        intl
      } = this.props;
      await editSendAutomatedCalls(businessId, {
        isActive: checked
      });
      notify(
        intl.formatMessage({
          id: 'business_details.consignee_notifications.success_message'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    this.setState({
      isLoading: false
    });
  };

  getSllrData = async () => {
    this.setState({ isSllrDataLoading: true });
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const sllrData = await getSllrData(businessId);
      this.setState({
        sllrData: [sllrData.data]
      });
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isSllrDataLoading: false });
  };

  getBasicSllrData = async () => {
    this.setState({ isSllrBasicInfoLoading: true });
    try {
      const {
        match: {
          params: { id: businessId }
        }
      } = this.props;
      const { data } = await getBasicllrInfo(businessId);
      this.setState({
        basicSllrData: data
      });
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isSllrBasicInfoLoading: false });
  };
  componentDidMount() {
    this.getBusinessInfo();
    this.getTeamMembersInfo();
    this.getBasicSllrData();

    const isHaveRoleToEdit = isUserAuthorized(
      aclMatrix.BUSINESSES_EDIT,
      PAGES_ACCESS.BUSINESS_EDIT
    );

    this.setState({ isHaveRoleToEdit });
  }

  render() {
    const {
      isLoading,
      businessInfo,
      isHaveRoleToEdit,
      teamMemebersInfo,
      logTableInfo,
      hasPromotions,
      defaultPickupsContacts,
      apiIntegrationInfo,
      isSllrDataLoading,
      sllrData,
      isBotCallActive,
      basicSllrData,
      isSllrBasicInfoLoading
    } = this.state;
    const {
      intl,
      history,
      match: {
        params: { id: businessId }
      }
    } = this.props;
    return (
      <LoadingWrapper loading={isLoading}>
        <Container
          header={<BRHeader title="Business Details" showBackArrow />}
          content={
            <div className="br-business-details">
              <span className="br-business-details__edit-button">
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/businesses/${businessId}/edit`);
                  }}
                  disabled={!isHaveRoleToEdit}
                >
                  EDIT
                </Button>
              </span>
              <BRInformationTable
                title={intl.formatMessage({
                  id: 'business_details.business_info.title'
                })}
                dataSource={businessInfo}
                cells={this.businessInfoCells()}
                totalCol={3}
              />
              {businessInfo.bankInfo && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.business_bank_info.title'
                  })}
                  dataSource={businessInfo.bankInfo}
                  cells={this.bankInfo()}
                  totalCol={3}
                />
              )}
              {businessInfo.secondBankInfo && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.business_bank_info.international'
                  })}
                  dataSource={businessInfo.secondBankInfo}
                  cells={this.bankInfo()}
                  totalCol={3}
                />
              )}
              {businessInfo.tier && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.pricing_info.title'
                  })}
                  dataSource={businessInfo}
                  cells={this.pricingInfo()}
                  totalCol={3}
                />
              )}
              {businessInfo.fulfillmentPricingTierId && (
                <BRInformationTable
                  title="Fulfillment Pricing Info"
                  dataSource={businessInfo}
                  cells={this.fulfillmentPricingInfo()}
                  totalCol={1}
                />
              )}

              <BRInformationTable
                title={intl.formatMessage({
                  id: 'business_details.consignee_notifications.title'
                })}
                dataSource={isBotCallActive}
                cells={this.consigneeNotificationColumn()}
                totalCol={1}
              />

              <BRInformationTable
                title={intl.formatMessage({
                  id: 'business_details.bosta_shop.title'
                })}
                dataSource={businessInfo?.flyersInfo}
                cells={this.bostaShopColumn()}
                totalCol={3}
              />
              {hasPromotions &&
                isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                  ACL_MATRIX.PROMOTIONS_LIST
                ]) && (
                  <BRSearchableTable
                    className="br-business-details__promotions-table"
                    title={intl.formatMessage({
                      id: 'business_details.promotions.title'
                    })}
                    columns={this.promotionsTableColumns()}
                    listFunction={this.getPromotions}
                    onRowClick={() => {}}
                    withOutCheckBoxes
                    hidePagination
                  />
                )}
              {businessInfo?.paymentInfo && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.wallet_payment_info.title'
                  })}
                  dataSource={businessInfo}
                  cells={this.walletPaymentInfoCells()}
                  totalCol={3}
                />
              )}
              {isInternationalShippingAvailable() && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.international_wallet.title'
                  })}
                  dataSource={
                    businessInfo?.internationalShippingEnabled
                      ? businessInfo
                      : intl.formatMessage({ id: 'common.empty_field' })
                  }
                  cells={INTERNATIONAL_WALLET_CELLS}
                  totalCol={3}
                />
              )}
              <BRInformationTable
                title={intl.formatMessage({
                  id: 'business_details.operations_settings.title'
                })}
                dataSource={businessInfo}
                cells={this.operationsSettingsCells()}
                totalCol={3}
              />
              {!isLoading && teamMemebersInfo && (
                <BRSearchableTable
                  className="br-teams__table"
                  title={intl.formatMessage({
                    id: 'business_details.team_members.title'
                  })}
                  columns={this.TeamMembersColumns()}
                  listData={teamMemebersInfo}
                  onRowClick={() => {}}
                  withOutCheckBoxes
                  hidePagination
                />
              )}
              {!!businessInfo?.defaultPickupLocation && (
                <BRInformationTable
                  title={intl.formatMessage({
                    id: 'business_details.business_default_pickup_location.title'
                  })}
                  dataSource={businessInfo?.defaultPickupLocation}
                  cells={this.defaultPickupLocation()}
                  totalCol={3}
                />
              )}

              {!isLoading && !!defaultPickupsContacts.length && (
                <BRSearchableTable
                  title={
                    <div className="br-business-details__default-contacts-title">
                      <span>
                        {intl.formatMessage({
                          id: 'business_details.business_default_pickup_contacts.title'
                        })}
                      </span>
                      {isUserAuthorized(
                        [SUPER_ADMIN, EXPERIENCE],
                        [ACL_MATRIX.BUSINESS_ADD_RETURN_CONTACT]
                      ) && (
                        <Button
                          type="primary"
                          onClick={this.handleAddReturnContact}
                        >
                          {intl.formatMessage({
                            id: 'business_details.business_default_pickup_contacts.add_return_contact'
                          })}
                        </Button>
                      )}
                    </div>
                  }
                  className="br-business-details__default-contacts"
                  columns={this.defaultPickupContactsLocation()}
                  listData={defaultPickupsContacts}
                  onRowClick={() => {}}
                  withOutCheckBoxes
                  hidePagination
                />
              )}

              {/* <BRSearchableTable
                title={intl.formatMessage({
                  id: 'business_details.api_integration.title'
                })}
                columns={this.IntegrationTableColumn()}
                listFunction={this.getApiIntegrationInfo}
                onRowClick={() => {}}
                withOutCheckBoxes
                hidePagination
                shareMethods={this.acceptMethods}
              /> */}

              {!isLoading && logTableInfo.length && (
                <BRSearchableTable
                  title={intl.formatMessage({
                    id: 'business_details.logs.title'
                  })}
                  columns={this.logTableColumn()}
                  listData={logTableInfo}
                  onRowClick={() => {}}
                  withOutCheckBoxes
                  hidePagination
                />
              )}
              {!isSllrBasicInfoLoading && (
                <BRInformationTable
                  className="br-business_details__basic-sllr-info"
                  title={intl.formatMessage({
                    id: 'business_details.sllr.basic_info_title'
                  })}
                  dataSource={basicSllrData}
                  cells={this.basicSllrInfoCells()}
                  totalCol={4}
                />
              )}
              {!isSllrBasicInfoLoading &&
                isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
                  ACL_MATRIX.BUSINESS_VIEW_SLLR_ANALYTICS
                ]) &&
                !sllrData.length && (
                  <div className="br-business-details__load-sllr-info-btn">
                    <BRButton
                      label={intl.formatMessage({
                        id: 'business_details.sllr.load_btn'
                      })}
                      onClick={this.getSllrData}
                      loading={isSllrDataLoading}
                      type="primary"
                      className="button-md"
                    />
                  </div>
                )}
              {!!sllrData.length && (
                <BRSearchableTable
                  title={intl.formatMessage({
                    id: 'business_details.sllr.title'
                  })}
                  columns={this.sllrTableColumn()}
                  listData={sllrData}
                  onRowClick={() => {}}
                  withOutCheckBoxes
                  hidePagination
                />
              )}
            </div>
          }
        />
      </LoadingWrapper>
    );
  }
}

export default injectIntl(withRouter(ShowBusiness));
