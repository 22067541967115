import actionTypes from '../actions/ActionTypes';

const initialState = {
  routes: { count: 0, data: [], pagination: {} },
  onlineStars: [],
  routeRunSheet: {
    rtoDeliveries: [],
    restDeliveries: [],
    restDeliveriesCount: 0,
    route: {}
  },
  loading: true
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROUTES_LIST_RECEIVED:
      return { ...state, routes: action.payload };
    case actionTypes.GET_ALL_ONLINE_STARS_RECEIVED:
      return { ...state, onlineStars: action.payload };
    case actionTypes.GET_ROUTE_RUN_SHEET_RECEIVED:
      return { ...state, routeRunSheet: action.payload };
    case actionTypes.DELIVERIES_LOADING_STARTED:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELIVERIES_LOADING_ENDED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
