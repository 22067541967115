import { Checkbox, Form } from 'antd';
import classnames from 'classnames';

import './SearchCheckbox.less';

const SearchCheckbox = ({ label, name, className, extraContent }) => {
  return (
    <Form.Item
      valuePropName="checked"
      className={classnames('br-deliveries-search__checkbox', {
        [className]: className
      })}
      name={name}
    >
      <Checkbox>
        {label}
        {extraContent}
      </Checkbox>
    </Form.Item>
  );
};

export default SearchCheckbox;
