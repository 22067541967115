import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import Icon, { CloseOutlined } from '@ant-design/icons';

import BROTPCode from 'components/BROTPCode/BROTPCode';

import './BROTPCodeModal.less';

const BROTPCodeModal = ({
  visible,
  close,
  intl,
  confirmOTP,
  generateOTP,
  onSuccess,
  title,
  subtitle,
  handleResendCode,
}) => {
  const handleCloseModal = () => {
    close();
  };

  return (
    <Modal
      visible={visible}
      onCancel={close}
      footer={null}
      closeIcon={<CloseOutlined />}
      wrapClassName="br-otp-modal__container"
    >
      <BROTPCode
        title={title}
        subtitle={subtitle}
        generateOTP={generateOTP}
        confirmOTP={confirmOTP}
        onSuccess={onSuccess}
        handleResendCode={handleResendCode}
        closeModal={handleCloseModal}
      />
    </Modal>
  );
};

export default injectIntl(BROTPCodeModal);
