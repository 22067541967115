import React, { useState } from 'react';
import { Upload } from 'antd';
import { injectIntl } from 'react-intl';
import { saveAs } from 'file-saver';

import { useCancellablePromise } from 'utils/promise';
import { convertExcelBulkUpdateBusinessessPricingTierToJSON } from 'utils/wallet';
import { openModal } from 'utils/modal';
import { uploadBulkUpdateBusinessPricingTierSheet } from 'services/business';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import ConfirmUploadSheetModal from 'components/Wallet/components/BusinessConfiguration/ConfirmUploadSheetModal/ConfirmUploadSheetModal';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';

import UpdateBusinessPricingTiersSheet from 'assets/Update Business Pricing Tiers.xlsx';
import { ReactComponent as UploadImage } from 'assets/imgRevamp/upload-wallet-template-icon.svg';
import { ReactComponent as UploadButtonIcon } from 'assets/imgRevamp/wallet-button-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/imgRevamp/wallet-success-icon.svg';
import { ReactComponent as ExcelIcon } from 'assets/imgRevamp/excel-icon.svg';
import { ReactComponent as GreenInfoIcon } from 'assets/imgRevamp/green-info.svg';

import './BulkUpdatePricingTier.less';

const BulkUpdatePricingTier = ({ intl }) => {
  const { Dragger } = Upload;

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState({});
  const [updatedBusinessLength, setUpdatedBusinessLength] = useState(0);
  const [uploadRejectedBusinesses, setUploadRejectedBusinesses] = useState([]);

  const { cancellablePromise } = useCancellablePromise();

  const handleUpload = async (file) => {
    setFile({ name: file.name, size: file.size / 1024 / 1024 });
    setIsLoading(true);
    let payload = null;
    try {
      payload = await cancellablePromise(
        convertExcelBulkUpdateBusinessessPricingTierToJSON(file)
      );
      openModal(ConfirmUploadSheetModal, {
        onSuccess: () => handleUpdateBusinessessPricingTier(payload),
        businessCount: payload.length
      });
    } catch (error) {
      if (error.message.includes('Wrong template')) {
        notify('Unsupported Template!');
      } else {
        notify(error.message);
      }
    }
    setIsLoading(false);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: handleUpload
  };

  const handleOnClickUploadMore = () => {
    setUpdatedBusinessLength(0);
    setUploadRejectedBusinesses([]);
    setFile({});
  };

  const handleUpdateBusinessessPricingTier = async (payload) => {
    setIsLoading(true);

    try {
      const {
        success,
        data: { rejectedBusiesses: rejectedBusinesses, updatedBusinesses }
      } = await uploadBulkUpdateBusinessPricingTierSheet({
        businesses: payload
      });

      if (!rejectedBusinesses?.length) {
        setUpdatedBusinessLength(payload.length);
      } else {
        setUploadRejectedBusinesses(rejectedBusinesses);
        notify(
          intl.formatMessage(
            {
              id: `bulk_update_pricing_tier.${
                updatedBusinesses?.length
                  ? 'failed_and_success_message'
                  : 'failed_message'
              }`
            },
            {
              noOfSuccessBusinesses: updatedBusinesses?.length,
              noOfFailedBusinesses: rejectedBusinesses?.length
            }
          )
        );
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDownloadTemplateClick = async () => {
    setIsLoading(true);
    await saveAs(
      UpdateBusinessPricingTiersSheet,
      'Update Business Pricing Tiers.xlsx'
    );
    setIsLoading(false);
  };

  return (
    <Container
      className="br-wallet-container"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'bulk_update_pricing_tier.header'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          {!!updatedBusinessLength ? (
            <div className="br-bulk-update-businessess-pricing-tier-success">
              <SuccessIcon className="sucess-icon" />
              <span className="sucess-title">
                {intl.formatMessage({
                  id: 'bulk_update_pricing_tier.success_state.title'
                })}
              </span>
              <div className="br-bulk-update-businessess-pricing-tier-success-info">
                <ExcelIcon />
                <div>
                  <span className="file-name-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.business_configuration.success_screen.file_name'
                      },
                      {
                        fileName: <span>{file.name}</span>
                      }
                    )}
                  </span>
                  <span className="total-business-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.business_configuration.success_screen.total_records'
                      },
                      {
                        totalRecords: <span>{updatedBusinessLength}</span>
                      }
                    )}
                  </span>
                </div>
              </div>
              <BRButton
                onClick={handleOnClickUploadMore}
                disabled={isLoading}
                label={intl.formatMessage({
                  id: 'bulk_update_pricing_tier.success_state.action'
                })}
              />
            </div>
          ) : !!uploadRejectedBusinesses.length ? (
            <div>
              <div className="br-bulk-update-businessess-pricing-tier__failed">
                <span className="display-sm">
                  {intl.formatMessage({
                    id: 'bulk_update_pricing_tier.failed_state.title'
                  })}
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'bulk_update_pricing_tier.failed_state.sub'
                  })}
                </span>
                <BRButton
                  label={intl.formatMessage({
                    id: 'bulk_update_pricing_tier.failed_state.action'
                  })}
                  onClick={handleOnClickUploadMore}
                />
              </div>
              <BRTable
                columns={[
                  {
                    title: intl.formatMessage({
                      id: 'bulk_update_pricing_tier.failed_state.table_columns.business_id'
                    }),
                    dataIndex: 'businessId'
                  },
                  {
                    title: intl.formatMessage({
                      id: 'bulk_update_pricing_tier.failed_state.table_columns.message'
                    }),
                    dataIndex: 'message'
                  }
                ]}
                externalDataSource={uploadRejectedBusinesses}
                showFilter={false}
                showPagination={false}
              />
            </div>
          ) : (
            <div className="br-bulk-update-businessess-pricing-tier">
              <div className="br-update-business-pricing-tier__download-template-container">
                <GreenInfoIcon />
                <div>
                  <span>
                    {intl.formatMessage({
                      id: 'bulk_update_pricing_tier.download_banner.title'
                    })}
                  </span>
                  <span>
                    {intl.formatMessage({
                      id: 'bulk_update_pricing_tier.download_banner.sub'
                    })}
                  </span>
                  <BRButton
                    label={intl.formatMessage({
                      id: 'bulk_update_pricing_tier.download_banner.action'
                    })}
                    onClick={handleDownloadTemplateClick}
                  />
                </div>
              </div>
              <div className="br-update-business-pricing-tier__upload-container">
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <UploadImage />
                  </p>
                  <p className="ant-upload-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.balance_adjustment.upload_hint'
                      },
                      {
                        clickToUpload: (
                          <span>
                            {intl.formatMessage({
                              id: 'wallet.balance_adjustment.click_to_upload'
                            })}
                          </span>
                        )
                      }
                    )}
                  </p>
                  <p className="ant-upload-hint">
                    {intl.formatMessage({
                      id: 'wallet.balance_adjustment.support_text'
                    })}
                  </p>
                  <BRButton
                    prefixIcon={<UploadButtonIcon />}
                    label={intl.formatMessage({
                      id: 'orders.mass_upload.upload_dialog.upload_file'
                    })}
                    type={'primary'}
                  />
                </Dragger>
              </div>
            </div>
          )}
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(BulkUpdatePricingTier);
