import React, { useState } from 'react';
import { Modal, Select, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import {
  APP_PERFORMANCE_OPTIONS,
  APP_PERFORMANCE_MAPPING
} from 'constants/score-card';
import { openModal } from 'utils/modal';

import PointsContainer from 'components/NewSettings/components/ScoreCard/components/PointsContainer/PointsContainer';
import { notify } from 'components/Notify/Notify';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

import './AddEditFactorsModal.less';

const AddEditFactorsModal = ({
  close,
  message,
  title,
  onConfirm,
  editTemplate,
  templateId,
  factors = null,
  isEdit = false,
  ...props
}) => {
  const { Option } = Select;
  const [selectedOptions, setSelectedOptions] = useState(
    Object.keys(factors || {})
  );
  const [factorsWeight, setFactorsWeight] = useState(factors || {});

  const onSelectPerformance = (value) =>
    setSelectedOptions([...selectedOptions, value]);

  const onWeightChange = (value, key, fakeExceptionSecondParam = false) => {
    if (value) {
      setFactorsWeight({
        ...factorsWeight,
        [key]:
          key === 'fake_exceptions'
            ? {
                ...(fakeExceptionSecondParam
                  ? {
                      maxNumber: value,
                      weight: factorsWeight[key]?.weight || null
                    }
                  : {
                      weight: value,
                      maxNumber: factorsWeight[key]?.maxNumber || null
                    })
              }
            : value
      });
      return true;
    } else {
      setFactorsWeight({
        ...factorsWeight,
        [key]:
          key === 'fake_exceptions'
            ? {
                ...(fakeExceptionSecondParam
                  ? {
                      maxNumber: null,
                      weight: factorsWeight[key]?.weight || null
                    }
                  : {
                      weight: null,
                      maxNumber: factorsWeight[key]?.maxNumber || null
                    })
              }
            : null
      });
    }
  };

  const onRemovePerformance = (value) => {
    setSelectedOptions(selectedOptions.filter((option) => option !== value));
    setFactorsWeight({ ...factorsWeight, [value]: null });
  };

  const handleOnFinishAddFactors = () => {
    let sum = 0;
    const weights = Object.values(factorsWeight);
    weights.forEach((weight) => {
      if (weight) {
        sum += weight?.weight || weight;
      }
    });
    if (!selectedOptions.length) {
      notify('At least one factor should be added to the score card template.');
      return;
    }
    if (sum !== 100) {
      notify('The summation of the weight points must be 100 points');
      return;
    }
    if (
      factorsWeight.fake_exceptions?.weight &&
      !factorsWeight.fake_exceptions?.maxNumber
    ) {
      notify('Maximum number of fake exception cannot be empty');
      return;
    }
    if (isEdit) {
      openModal(ConfirmationModal, {
        onFinish: () => {
          editTemplate({
            values: factorsWeight,
            id: templateId,
            notifyMessage: 'Factors Edited successfully'
          });
          close();
        }
      });
    } else {
      editTemplate({
        values: factorsWeight,
        id: templateId,
        notifyMessage: 'Factors Added successfully'
      });
      close();
    }
  };

  return (
    <Modal
      onCancel={close}
      {...props}
      title={title || null}
      className="br-add-factors-modal"
      width={null}
      cancelText={'Cancel'}
      okText={'Save'}
      onOk={handleOnFinishAddFactors}
    >
      <div className="br-add-factors-container">
        <div className="br-add-factors-modal__header-container">
          <span className="br-add-factors-modal__header-title">
            {isEdit ? 'Edit Factors' : 'Add Factors'}
          </span>
          <CloseOutlined onClick={close} />
        </div>
        <div className="br-add-factors-modal__performance-factor-container">
          <span className="br-add-factors-modal__performance-factor-title">
            Performance Factors
          </span>
          <Select
            onChange={onSelectPerformance}
            className="br-factors__select-container"
            value={null}
          >
            {APP_PERFORMANCE_OPTIONS.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                disabled={selectedOptions.includes(option.value)}
              >
                {option.label}
              </Option>
            ))}
          </Select>
          <span className="br-add-factors-modal__performance-factor-warning">
            * The summation of the weight points must be 100 points
          </span>
        </div>
        <Divider />

        <div className="br-add-factors-modal__points-container">
          {selectedOptions.map((option) => (
            <>
              <PointsContainer
                key={option}
                value={option}
                title={APP_PERFORMANCE_MAPPING[option]}
                onInputChange={onWeightChange}
                onRemove={onRemovePerformance}
                defaultValue={
                  factors && Object.keys(factors).length
                    ? option === 'fake_exceptions'
                      ? factors[option]?.weight || null
                      : factors[option]
                    : null
                }
                maxNumberDefaultValue={
                  factors &&
                  Object.keys(factors).length &&
                  option === 'fake_exceptions'
                    ? factors[option]?.maxNumber || null
                    : null
                }
              />
              <Divider
                className={
                  option === 'fake_exceptions'
                    ? 'br-factor-divider-fake-exception'
                    : ''
                }
              />
            </>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddEditFactorsModal;
