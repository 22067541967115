import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, Input } from 'antd';
import Icon from '@ant-design/icons';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import { ReactComponent as closeIcon } from 'assets/imgRevamp/Union.svg';

import './RejectionReasonsModal.less';

const RejectionReasonsModal = ({ intl, onSuccess, close, ...props }) => {
  const [rejectionReasonValue, setRejectionReasonValue] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChange = ({ target: { value } }) => {
    setRejectionReasonValue(value);
    setShowErrorMessage(false);
  };

  const onConfirmRejection = () => {
    if (!rejectionReasonValue) {
      setShowErrorMessage(true);
      return;
    }
    onSuccess(rejectionReasonValue);
    close();
  };

  return (
    <Modal
      onCancel={close}
      {...props}
      title={null}
      footer={null}
      wrapClassName="br-rejection-reasons-modal"
    >
      <BRContentHeader
        title={
          <>
            <span>
              {intl.formatMessage({
                id: 'bonuses_deductions.rejection_reasons_modal.title'
              })}
            </span>
            <span onClick={close}>
              <Icon component={closeIcon} />
            </span>
          </>
        }
      />
      <Input onChange={onChange} allowClear />
      {showErrorMessage && (
        <span className="ant-form-item-explain ant-form-item-explain-error">
          {intl.formatMessage({
            id: 'bonuses_deductions.rejection_reasons_modal.error_message'
          })}
        </span>
      )}
      <div className="br-rejection-reasons-modal__footer">
        <Button onClick={close}>
          {intl.formatMessage({
            id: 'common.cancel'
          })}
        </Button>
        <Button type="danger" onClick={onConfirmRejection}>
          {intl.formatMessage({
            id: 'bonuses_deductions.actions.reject'
          })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(RejectionReasonsModal);
