import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Button,
  Menu,
  DatePicker,
  Select,
  Tooltip,
  Input,
  Checkbox
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';

import {
  getTemplateHubs,
  getTemplates,
  addTemplates
} from 'services/stars-performance';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import Templates from 'components/NewSettings/components/ScoreCard/components/Templates/Templates';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/info-circle.svg';

import './ScoreCard.less';

const ScoreCard = ({ intl }) => {
  const { Option } = Select;
  const [hubsOptions, setHubsOptions] = useState([]);
  const [hubsArray, setHubsArray] = useState([]);
  const [hubsState, setHubsState] = useState({
    assignedHubs: 0,
    unAssignedHubs: 0
  });
  const [templates, setTemplates] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [allHubs, setAllHubs] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const [searchedHubID, setSearcedHubId] = useState(null);

  useEffect(() => {
    setPageLoading(true);
    getHubs();
    getAllTemplates();
    setPageLoading(false);
  }, []);

  const getHubs = async () => {
    setPageLoading(true);
    try {
      const { assignedHubs, unAssignedHubs, rows } = await getTemplateHubs();
      setHubsState({
        assignedHubs,
        unAssignedHubs
      });

      let formattedHubsArray = [];
      let allHubs = [];
      rows.forEach((hub) => {
        let hubOption = {
          value: hub.hub_id,
          disabled: !!hub.template_id,

          label: hub.template_id ? (
            <div className="br-score-card__assigned-hub">
              <span>{hub.hub_name}</span>
              <span className="br-score-card__assigned-hub-template-no">
                Assigned to "{hub.template_id || 884433}"
              </span>
            </div>
          ) : (
            hub.hub_name
          ),
          search: hub.hub_name
        };
        let allHubOption = {
          value: hub.hub_id,

          label: hub.hub_name,

          templateId: hub.template_id
        };
        formattedHubsArray.push(hubOption);
        allHubs.push(allHubOption);
      });
      setHubsOptions(formattedHubsArray);
      setHubsArray(formattedHubsArray);
      setAllHubs(allHubs);
    } catch (error) {
      notify(error.message);
    }
    setPageLoading(false);
  };

  const getAllTemplates = async (hubId) => {
    setPageLoading(true);
    try {
      const result = await getTemplates({
        hubId
      });
      setTemplates(result);
    } catch (error) {
      notify(error.message);
    }
    setPageLoading(false);
  };

  const onChange = (value) => {
    setSelectedHubs(value);
  };

  const handleHubSearch = (e) => {
    const searchInput = e.target.value;
    const searchArray = [];
    hubsArray.forEach((option) => {
      if (option.search.toLowerCase().includes(searchInput.toLowerCase())) {
        searchArray.push(option);
      }
    });
    if (searchArray.length) {
      setHubsOptions(searchArray);
    } else {
      setHubsOptions([]);
    }
  };

  const handleOnAddTemplateClick = async () => {
    if (!selectedHubs?.length) {
      notify('You must selected hub(s) to add to the template');
      return;
    }
    let hubs = [];
    selectedHubs.forEach((hub) => {
      const correctHub = hubsArray.filter((h) => h.value === hub)[0];
      hubs.push({
        hub_id: hub,
        hub_name: correctHub.label
      });
    });
    setPageLoading(true);
    try {
      await addTemplates({ hubs });
      window.location.reload();
      setPageLoading(false);
    } catch (error) {
      notify(error.message);
    }
    setPageLoading(false);
  };

  const menu = (
    <Menu className="br-score-card__menu-container">
      <Menu.Item>
        <Input
          type="text"
          placeholder="search for hub"
          onClick={(e) => e.stopPropagation()}
          //   onChange={handleHubSearch}
          onKeyUp={handleHubSearch}
        />
      </Menu.Item>
      <Menu.Item className="br-scorecard__checkbox-list-container">
        <Checkbox.Group
          options={hubsOptions}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          className="br-score-card__checkbox-group-container"
          //   defaultValue={[1]}
        />
      </Menu.Item>
    </Menu>
  );

  const handleChange = (value) => {
    getAllTemplates(value);
  };

  return (
    <LoadingWrapper loading={pageLoading}>
      <div>
        <BRContentHeader
          title={intl.formatMessage({
            id: 'settings.score_card.title'
          })}
          subtitle={intl.formatMessage({
            id: 'settings.score_card.sub_title'
          })}
          isInternalComponent
        />

        <div className="br-score-card__hubs-section-container">
          <span className="br-score-car__hubs-section-title">
            {intl.formatMessage({
              id: 'settings.score_card.hubs_section.title'
            })}
          </span>
          <div className="br-score-card__hubs-section__select-hub-container">
            <Dropdown overlay={menu} trigger="click">
              <div className="br-score-card__input-container">
                <span>Select</span>
                <DownOutlined />
              </div>
            </Dropdown>
            <Button type="primary" onClick={handleOnAddTemplateClick}>
              {intl.formatMessage({
                id: 'settings.score_card.hubs_section.cta_text'
              })}
            </Button>
          </div>
          <span className="br-score-card__select-hub-tooltip">
            {intl.formatMessage(
              {
                id: 'settings.score_card.hubs_section.assigned_hubs'
              },
              {
                assignedHubs: hubsState.assignedHubs
              }
            )}
            <span className="br-score-card__divider">|</span>
            {intl.formatMessage(
              {
                id: 'settings.score_card.hubs_section.unassigned_hubs'
              },
              {
                unAssignedHubs: hubsState.unAssignedHubs
              }
            )}
            <Tooltip
              title={intl.formatMessage(
                {
                  id: 'settings.score_card.hubs_section.tool_tip'
                },
                {
                  assignedHubs: 10
                }
              )}
            >
              <TooltipsIcon />
            </Tooltip>
          </span>
        </div>

        <div className="br-score-card__template-section-container">
          <div className="br-score-card__tempalate-section-hub-filter-conatiner">
            <span className="br-score-card__template-section-title">
              {intl.formatMessage({
                id: 'settings.score_card.template_section.title'
              })}
            </span>
            <Select
              placeholder="All Hubs"
              allowClear
              onChange={handleChange}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allHubs.map((hub) => (
                <Option value={hub.value} key={hub.value}>
                  {hub.label}
                </Option>
              ))}

              <Option value="lucy">Lucy</Option>
            </Select>
          </div>

          <Templates
            templates={templates}
            getTemplates={getAllTemplates}
            allHubs={allHubs}
            getHubs={getHubs}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(ScoreCard);
