import React, { useEffect, useState } from 'react';
import { Modal, Image } from 'antd';
import { injectIntl } from 'react-intl';

import { COUNTRY_CURRENCY } from 'constants/helper';
import {
  cashierUploadReceiptImage,
  uploadCashierSignature
} from 'services/cashier';

import UploadAttachment from 'components/Stars/components/BonusesAndDeductions/components/UploadAttachment/UploadAttachment';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as CashierMoneyIcon } from 'assets/imgRevamp/cashier-money-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/imgRevamp/cashier-upload-icon.svg';

import './CashierBulkActionModal.less';

const CashierBulkActionModal = ({
  intl,
  close,
  onFinish,
  records = [],
  isSignature = false,
  ...rest
}) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [attachment, setAttachment] = useState([]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [signatureImageUrl, setSignatureImageUrl] = useState(
    userInfo?.cashierSignatureURL
  );
  const [newSignatureImageURL, setNewSignatureImageURL] = useState(null);

  const internationalNumberFormat = new Intl.NumberFormat('en-US');

  const getTotalAmount = (formatResult = true) => {
    let amount = 0;
    records?.forEach((record) => {
      const data = JSON.parse(record);
      amount += parseFloat(data?.cod_collected_amount ?? 0);
    });
    return formatResult
      ? internationalNumberFormat.format((amount * 100) / 100)
      : amount;
  };

  const getIDs = () => {
    let idArray = [];
    records?.forEach((record) => {
      const data = JSON.parse(record);
      idArray.push(data?.id);
    });
    return idArray;
  };

  const handleUpload = (file) => {
    if (file.type.includes('image') || file.type === 'application/pdf') {
      return true;
    } else {
      notify('Please upload only images (PNG, JPEG, JPG) or Files (PDF)');
      return false;
    }
  };

  const handleOnConfirm = () => {
    const payload = {
      ids: getIDs(),
      imageUrl: attachment[0]?.url,
      amount: getTotalAmount(false)
    };
    onFinish(payload);
    close();
  };

  useEffect(() => {
    if (attachment.length) {
      setDisableConfirmButton(false);
    } else {
      setDisableConfirmButton(true);
    }
  }, [attachment]);

  useEffect(() => {
    if (newSignatureImageURL) {
      onFinish(newSignatureImageURL);
      close();
    }
  }, [newSignatureImageURL]);

  const handleOnReuploadSignatureClick = () => {
    setSignatureImageUrl(null);
  };

  const handleUploadSignature = async (image) => {
    const imageData = await uploadCashierSignature(image);
    setNewSignatureImageURL(imageData?.imageUrl);
    return imageData;
  };

  return (
    <Modal
      {...rest}
      title={null}
      className="br-cashier-bulk-action-modal-container"
      centered
      onCancel={close}
      okButtonProps={{
        disabled: disableConfirmButton
      }}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      width={null}
      onOk={handleOnConfirm}
      {...(isSignature && { footer: null })}
    >
      <div className="br-cashier-bulk-action__modal-title">
        <div className="br-cashier-reject-modal-title-container">
          <span className="br-cashier-reject-modal__title">
            {intl.formatMessage({
              id: `cashier.bulk_modal.${
                !isSignature ? 'title' : 'title_signature'
              }`
            })}
          </span>
          {!isSignature && (
            <span className="br-cashier-reject-modal__subtitle">
              {intl.formatMessage({
                id: `cashier.bulk_modal.sub_title`
              })}
            </span>
          )}
        </div>
        {!isSignature && (
          <span className="br-cashier-bulk-action__money-title">
            <CashierMoneyIcon />
            {intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              {
                cod: getTotalAmount()
              }
            )}
          </span>
        )}
      </div>
      {isSignature && signatureImageUrl ? (
        <div className="br-cashier__signature-image-container">
          <Image src={signatureImageUrl} width={200} height={200} />
          <BRButton
            type={'primary'}
            label="Re-upload signature"
            onClick={handleOnReuploadSignatureClick}
          />
        </div>
      ) : (
        <UploadAttachment
          onChange={({ attachments }) => setAttachment(attachments)}
          customUploadImageEndpoint={(image) =>
            isSignature
              ? handleUploadSignature(image)
              : cashierUploadReceiptImage(image)
          }
          customContent={() => (
            <div className="br-cashier-bulk-modal__upload-empty-view-container">
              <span className="br-cashier-bulk-modal-upload-title body">
                {intl.formatMessage(
                  {
                    id: `cashier.bulk_modal.${
                      isSignature ? 'signature_upload_titke' : 'upload_title'
                    }`
                  },
                  {
                    click: (
                      <span className="br-cashier-bulk-modal__click-to-upload-button">
                        {intl.formatMessage({
                          id: 'cashier.bulk_modal.click_to_upload'
                        })}
                      </span>
                    )
                  }
                )}
              </span>
              <span className="caption">
                {isSignature
                  ? 'Files formats are Images (PNG, JPEG, JPG) with max files size 1.5 MB'
                  : ' Files formats are Images (PNG, JPEG, JPG) and Files (PDF) with max files size 1.5 MB'}
              </span>

              <BRButton
                label={intl.formatMessage({
                  id: 'orders.mass_upload.upload_dialog.upload_file'
                })}
                prefixIcon={<UploadIcon />}
                type="primary"
              />
            </div>
          )}
          max={1}
          beforeUpload={handleUpload}
          isCashier={true}
          accept={isSignature ? '.jpg, .jpeg, .png' : '.jpg, .jpeg, .png, .pdf'}
        />
      )}
    </Modal>
  );
};

export default injectIntl(CashierBulkActionModal);
