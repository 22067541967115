import { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl';

import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import {
  LOG_DATE_FORMAT,
  TICKET_LINK,
  TICKET_REQUESETER
} from 'constants/shipments';
import { getProofOfReturn, getTicketLogs } from 'services/shipments';
import { isReturnOrder } from 'utils/shipmentDetails';

import { notify } from 'components/Notify/Notify';
import DeliveryLogs from 'components/Shipments/components/NewDeliveryDetails/components/DeliveryLogs/DeliveryLogs';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import DeliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';

const DeliveryLogsDrawer = ({
  showDeliveryLogs,
  delivery,
  intl,
  setShowDeliveryLogs
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState({});

  useEffect(() => {
    fetchAdditionalDeliveryDetails();
  }, [delivery]);

  const fetchAdditionalDeliveryDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = getTicketLogs(delivery._id);

      const ticketLogs = formatTicketLogs(data);

      if (delivery?.type === ORDER_TYPES_VALUE.FXF_SEND) {
        delivery.type = ORDER_TYPES_VALUE.DELIVER;
      }

      setCurrentDelivery(delivery);

      let porData;
      if (isReturnOrder(delivery)) {
        porData = await fetchProofOfReturn(delivery?._id);
      }

      const formattedLogs = DeliveryStateFormatter.formatLog(
        delivery,
        porData
      ).reverse();

      setLogs(sortLogsByDate([...formattedLogs, ...ticketLogs]));
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const sortLogsByDate = (logs) => {
    if (!logs) {
      return [];
    }

    return logs?.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  };

  const formatTicketLogs = (logs = []) => {
    return logs.map((log) => ({
      action: intl.formatMessage(
        {
          id: 'deliveries.delivery_details.delivery_logs.ticket_log'
        },
        {
          ticketId: log.externalId,
          requester:
            TICKET_REQUESETER[log?.requesterType] || TICKET_REQUESETER.Business,
          Link: (children) => (
            <a
              href={`${TICKET_LINK}${log.externalId}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {children}
            </a>
          )
        }
      ),
      date: dayjs(log?.createdAt).format(LOG_DATE_FORMAT),
      takenBy: [
        log?.agentRole ||
          intl.formatMessage({
            id: 'deliveries.delivery_details.delivery_logs.agent_name'
          }),
        log?.agentName || log?.email
      ]
    }));
  };

  const fetchProofOfReturn = async (id) => {
    try {
      const { data } = await getProofOfReturn(id);
      return data;
    } catch ({ message }) {
      notify(message);
    }
    return null;
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Drawer
        visible={showDeliveryLogs}
        onClose={() => setShowDeliveryLogs(false)}
        width={600}
      >
        <DeliveryLogs
          logs={logs}
          liveOps={currentDelivery.liveOpsInfo}
          deliveryDetails={currentDelivery}
          setIsLoading={setIsLoading}
        />
      </Drawer>
    </LoadingWrapper>
  );
};

export default injectIntl(DeliveryLogsDrawer);
