import http from 'utils/http.js';
import { downloadHeader } from 'utils/download';
import { headerDownload } from 'utils';

export const fetchPickups = (payload) => {
  return http.get(`/pickups/search`, payload);
};

export const fetchPickup = (id) => {
  return http.get(`/pickups/${id}`);
};

export const createPickup = (payload) => {
  return http.post(`/pickups`, payload);
};

export const editPickup = ({ id, payload }) => {
  return http.put(`/pickups/${id}`, payload);
};

export const fetchPickupLocations = (payload) => {
  return http.get(`pickup-locations`, payload);
};

export const fetchPickupLocation = (id, payload) => {
  return http.get(`pickup-locations/${id}`, payload);
};

export const createPickupLocation = (payload) => {
  return http.post(`/pickup-locations`, payload);
};

export const deletePickup = (id) => {
  return http.delete(`/pickups/${id}`);
};

export const createContacts = ({ id, payload }) => {
  return http.put(`/pickup-locations/${id}`, payload);
};

export const exportedPickupsDetailsFile = (id, payload) => {
  return http.get(`/pickups/${id}/report/details`, payload, downloadHeader);
};

export const exportedPickupsFile = ({ payload }) => {
  return http.get('/pickups/xlsx', payload, downloadHeader);
};

export const exportPickups = async (data) => {
  return await http.get(`/pickups/xlsx`, data);
};

export const getBusinessPickupLocation = async (businessId) => {
  const { data } = await http.get(`/businesses/${businessId}/pickup-locations`);
  return data;
};

export const getPickupsCount = (payload) => {
  return http.post('/utils/pickups/count', payload);
};

export const updatePickupState = (payload) => {
  return http.put('/pickups/state', payload);
};

export const assignPickupToRoute = (payload) => {
  return http.put('/pickups/addToRoute', payload);
};
