import { useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { phoneNumberRule } from 'utils/forms';
import { addDefaultReturnContact } from 'services/business';

import { notify } from 'components/Notify/Notify';

import './AddReturnContactModal.less';

const AddReturnContactModal = ({
  visible,
  close,
  intl,
  businessId,
  onSuccess
}) => {
  const formRef = useRef('');

  const handleOnFinish = async (values) => {
    try {
      await addDefaultReturnContact(businessId, values);

      onSuccess();
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closeIcon={<CloseOutlined />}
      title={intl.formatMessage({
        id: 'business_details.business_default_pickup_contacts.add_return_contact'
      })}
    >
      <Form ref={formRef} onFinish={handleOnFinish} name="addReturnContactForm">
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: 'contact_form_labels.first_name'
          })}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input
            type="text"
            placeholder={intl.formatMessage({
              id: 'settings.pickup_locations.pickup_location_form.form_placeholders.first_name'
            })}
            maxLength={225}
            autoComplete={'' + Math.random()}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: 'contact_form_labels.last_name'
          })}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input
            type="text"
            placeholder={intl.formatMessage({
              id: 'settings.pickup_locations.pickup_location_form.form_placeholders.last_name'
            })}
            maxLength={225}
            autoComplete={'' + Math.random()}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={intl.formatMessage({
            id: 'contact_form_labels.phone'
          })}
          rules={[
            {
              label: intl.formatMessage({
                id: 'contact_form_labels.phone'
              }),
              required: true
            },
            phoneNumberRule({
              message: intl.formatMessage({
                id: 'form.phone_not_valid'
              }),
              internationlNumbers: true
            })
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'contact_form_labels.phone'
            })}
            autoComplete={'' + Math.random()}
          />
        </Form.Item>
        <div className="br-add-return-contact-modal__form__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.submit'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddReturnContactModal);
