import React from 'react';
import { Modal } from 'antd';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './BRPreviewImage.less';

class BRPreviewImage extends React.Component {
  render() {
    const { close, previewImage, previewImageTitle } = this.props;

    return (
      <Modal
        {...this.props}
        footer={null}
        title={
          <>
            <span>{previewImageTitle}</span>
            <CloseIcon onClick={close} />
          </>
        }
        className="br-preview-image-modal"
        onCancel={close}
      >
        <img
          alt="Item Image"
          className="br-support-tickets__preview-image"
          src={previewImage}
        />
      </Modal>
    );
  }
}

export default BRPreviewImage;
