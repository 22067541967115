import { fmt } from 'IntlWrapper/IntlWrapper';

export const RTO_TYPE = 'RTO';
export const ROUTES_STATE = {
  DRAFTED: 0,
  DISPATCHED: 1,
  DEBRIEFED: 2,
  DONE: 3,
  ARCHIVED: 4,
  UNASSIGNED: 5,
  ASSIGNED: 6,
  ACCEPTED: 7,
  REJECTED: 8
};

export const DROP_OFF = 'DROP_OFF';
export const PICK_UP = 'PICK_UP';
export const RTO_GROUP = 'RTO_GROUP';
export const DATE_FORMAT = 'DD MMM YYYY';

export const OTP = 'OTP';
export const SIGNATURE = 'signature';

export const UNASSIGNED = 'UnAssigned';

export const DYNAMIC_ROUTES_STATES = {
  Drafted: {
    STATE_NAME: 'Drafted',
    COLOR: '#FF0000'
  },
  Created: {
    STATE_NAME: 'Created',
    COLOR: '#008000'
  },
  Merged: {
    STATE_NAME: 'Merged',
    COLOR: '#FF0000'
  }
};

export const DEFAULT_CLUSTER_COLOR = '#FF0000';

export const RETURN_GROUPS_STATE = {
  REJECTED: fmt({ id: 'return_manifest.return_group_states.rejected' }),
  PARTIALLY_RETURNED: fmt({
    id: 'return_manifest.return_group_states.partially_returned'
  }),
  EXCEPTION: fmt({
    id: 'deliveries.states.exception'
  }),
  RETURNED: fmt({
    id: 'deliveries.states.returned'
  }),
  OUT_FOR_RETURN: fmt({
    id: 'deliveries.states.out_for_return'
  })
};

export const RETURN_GROUPS_STATE_COLORS = {
  REJECTED: 'br-state-red-group',
  PARTIALLY_RETURNED: 'br-state-green-group',
  EXCEPTION: 'br-state-red-group',
  RETURNED: 'br-state-green-group',
  OUT_FOR_RETURN: 'br-state-yellow-group'
};

export const ROUTE_STATES_LABEL = {
  DRAFTED: 'Drafted',
  DISPATCHED: 'Dispatched',
  DEBRIEFED: 'Debriefed',
  DONE: 'Done',
  ARCHIVED: 'Archived',
  UNASSIGNED: 'UnAssigned',
  ASSIGNED: 'Assigned',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const ROUTE_STATES_TAG_COLOR = {
  [ROUTE_STATES_LABEL.DRAFTED]: 'yellow',
  [ROUTE_STATES_LABEL.DISPATCHED]: 'purple',
  [ROUTE_STATES_LABEL.DEBRIEFED]: 'gray',
  [ROUTE_STATES_LABEL.DONE]: 'green',
  [ROUTE_STATES_LABEL.ARCHIVED]: 'gray',
  [ROUTE_STATES_LABEL.UNASSIGNED]: 'teal',
  [ROUTE_STATES_LABEL.ASSIGNED]: 'blue',
  [ROUTE_STATES_LABEL.ACCEPTED]: 'gray',
  [ROUTE_STATES_LABEL.REJECTED]: 'gray'
};

export const ROUTES_STATES = [
  {
    label: fmt({ id: 'routes.routes_listing.route_states.drafted' }),
    value: 0
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.dispatched' }),
    value: 1
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.debriefed' }),
    value: 2
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.done' }),
    value: 3
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.archived' }),
    value: 4
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.unassigned' }),
    value: 5
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.assigned' }),
    value: 6
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.accepted' }),
    value: 7
  },
  {
    label: fmt({ id: 'routes.routes_listing.route_states.rejected' }),
    value: 8
  }
];

export const ROUTES_TABLE_COLUMNS = (renderTableActions) => [
  {
    dataIndex: 'createdAt',
    title: fmt({ id: 'routes.routes_listing.table_columns.created_at' }),
    width: 230
  },
  {
    dataIndex: 'routeAge',
    title: fmt({ id: 'routes.routes_listing.table_columns.route_age' }),
    width: 120
  },
  {
    dataIndex: 'lastUpdatedAt',
    title: fmt({ id: 'routes.routes_listing.table_columns.last_updated' }),
    width: 120
  },
  {
    dataIndex: 'name',
    title: fmt({ id: 'routes.routes_listing.table_columns.name' }),
    width: 200
  },
  {
    dataIndex: 'warehouseName',
    title: fmt({ id: 'hubs.packages_debrief.hub_label' }),
    width: 200
  },
  {
    dataIndex: 'numberOfDeliveries',
    title: fmt({
      id: 'routes.routes_listing.table_columns.number_of_deliveries'
    })
  },
  {
    dataIndex: 'numberOfPickups',
    title: fmt({
      id: 'routes.routes_listing.table_columns.number_of_pickups'
    })
  },
  {
    dataIndex: 'stateTag',
    title: fmt({ id: 'routes.routes_listing.table_columns.state' })
  },
  {
    dataIndex: '',
    align: 'center',
    width: 80,
    render: (record) => renderTableActions(record)
  }
];

export const INITIAL_ROUTES_LISTING_FILTERS = {
  states: [ROUTES_STATE.UNASSIGNED]
};

export const ROUTE_EXPORT_TYPE = 'exportRoutes';
