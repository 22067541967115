import http from 'utils/http.js';

export const generateOTP = (payload) => {
  return http.post(`/users/generate-personal-otp`, payload);
};

export const confirmOTP = (data) => {
  return http.post(`/users/confirm-personal-otp`, data);
};

export const getUserProfileInfo = () => {
  return http.get(`/users/personal-info`);
};

export const editUserProfileInfo = (payload) => {
  return http.put(`/users/personal-info`, payload);
};
