import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import _debounce from 'lodash/debounce';

import { checkSerial } from 'services/sales-orders';
import Sounds from 'common/Sounds';
import { openModal } from 'utils/modal';
import {
  PACK_TYPE,
  SCAN_SERIALS_ERRORS,
  SCAN_SERIALS_ERRORS_KEYS,
  SKU_SERIAL_REGEX,
  SKU_SERIAL_SEPARATOR
} from 'constants/fulfillment-sales-orders';

import FailureModal from 'components/BRModals/FailureModal/FailureModal';
import { notify } from 'components/Notify/Notify';

import './ScanSerialsCard.less';

const { beep, warning } = Sounds;
const { SCANNED_BEFORE, WRONG } = SCAN_SERIALS_ERRORS_KEYS;

const ScanSerialsCard = ({
  intl,
  scannedSerials,
  setScannedSerials,
  isAllSerialsScanned,
  soId
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();
  const inputRef = useRef();

  const validateSerial = async ({ sku, serial }) => {
    setIsLoading(true);

    const payload = {
      productSku: sku,
      state: PACK_TYPE,
      soId
    };

    try {
      const { data } = await checkSerial(serial, payload);
      setIsLoading(false);
      return !!data;
    } catch ({ status, message }) {
      setIsLoading(false);
      if (status) {
        // Notify for errors such as Network Error
        notify(message);
        return null;
      }
    }
    return false;
  };

  const onValidScan = (skuSerial) => {
    setScannedSerials((prev) => [...prev, skuSerial]);
    beep();
    formRef.current?.setFieldsValue({
      serial: ''
    });
    inputRef.current?.focus();
  };

  const checkScannedSerial = async (skuSerial) => {
    let errorKey;

    if (SKU_SERIAL_REGEX.test(skuSerial)) {
      const [sku, serial] = skuSerial.split(SKU_SERIAL_SEPARATOR);
      const isScannedBefore = scannedSerials.find((s) => s === skuSerial);
      if (isScannedBefore) {
        errorKey = SCANNED_BEFORE;
      } else {
        const isValidSerial = await validateSerial({ sku, serial });

        if (isValidSerial) {
          onValidScan(skuSerial);
        } else if (isValidSerial !== null) {
          errorKey = WRONG;
        }
      }

      if (errorKey) {
        warning();
        const { cancelBtnLabel, ...rest } = SCAN_SERIALS_ERRORS[errorKey];
        openModal(FailureModal, {
          ...rest,
          title: (
            <span className="br-fulfillment-scan-serials-card__error-serial">
              {skuSerial}
            </span>
          ),
          cancelBtnProps: {
            label: cancelBtnLabel
          },
          closeTimer: 3
        });
      }
    }
  };

  const onScan = _debounce((value) => {
    checkScannedSerial(value);
  }, 500);

  const onChange = ({ target: { value } }) => {
    const trimmedValue = value?.trim();
    if (trimmedValue) {
      onScan(trimmedValue);
    }
  };

  return (
    <div className="br-po-section br-fulfillment-scan-serials-card">
      <Form ref={formRef}>
        <Form.Item
          label={intl.formatMessage({
            id: 'fulfillment_outbound.scan_serials.label'
          })}
          name="serial"
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'fulfillment_outbound.scan_serials.placeholder'
            })}
            onChange={onChange}
            disabled={isLoading || isAllSerialsScanned}
            loading={isLoading}
            ref={inputRef}
            autoFocus
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default injectIntl(ScanSerialsCard);
