import {
  FULFILLMENT_INBOUND_PO_MAIN_PATH,
  FULFILLMENT_BUSINESSES_MAIN_PATH,
  FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH
} from 'constants/fulfillment';
import { FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH } from 'constants/fulfillment-return-orders';
import { FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH } from 'constants/fulfillment-problematic-jobs';
import { FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH } from 'constants/fulfillment-return-to-vendor';
import { COUNTRIES } from 'constants/country-data';
import { fmt } from 'IntlWrapper/IntlWrapper';
import { getCurrency } from 'common/countries/countries-mapping';

export const IS_SAUDI =
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
  COUNTRIES[1].codeName;

export const isSaudi = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
    COUNTRIES[1].codeName || false;

export const COUNTRY_CURRENCY = getCurrency()?.id;

export const COUNTRY_CURRENCY_MODIFIED =
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
  COUNTRIES[1].codeName
    ? 'common.SAR_modified'
    : 'common.EGP_modified';

export const END_DATES_KEYS = ['createdAtEnd', 'dateRangeEnd'];

/*
 * Cairo Timezone -> Africa/Cairo
 * Riyadh Timezone -> Asia/Riyadh
 */

export const COUNTRIES_TIME_ZONE = {
  egypt: 'Africa/Cairo',
  ksa: 'Asia/Riyadh'
};

// The order of this array is MANDATORY, [Sunday - Saturday] = [0 - 6]
export const DAY_OF_WEEK = [
  fmt({ id: 'days_of_week.sunday' }),
  fmt({ id: 'days_of_week.monday' }),
  fmt({ id: 'days_of_week.tuesday' }),
  fmt({ id: 'days_of_week.wednesday' }),
  fmt({ id: 'days_of_week.thursday' }),
  fmt({ id: 'days_of_week.friday' }),
  fmt({ id: 'days_of_week.saturday' })
];

export const DAYS_OF_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

export const PATHS_LIST_TO_HIDE_SIDEBAR = [
  FULFILLMENT_INBOUND_PO_MAIN_PATH,
  `${FULFILLMENT_BUSINESSES_MAIN_PATH}/`,
  FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH,
  FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH,
  FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH,
  `${FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH}/`
];

export const ORDER_SORT = {
  ASCEND: 'ascend',
  DESCEND: 'descend'
};

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const alphanumericRegex = /^[a-zA-Z0-9]+$/;
export const regexNumber = /^\d+$/;
export const regexNumberWithNegative = /^-?\d*$/;
export const regexFractionsNumber = /^\d+(\.\d*)?$/;

export const INPUT_TYPES = {
  DATE: 'date',
  TEXT: 'text',
  MULTI_TEXT: 'multi-text',
  NUMBER: 'textNumber',
  RADIO: 'radio'
};
