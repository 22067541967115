import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { moneyFormatter } from 'utils';
import {
  getARReportDetails,
  downloadArReportDetailsCSV,
  removeReportData
} from '../../../actions';

import Container from '../../../components/Container';
import styles from '../styles.module.scss';
import BRHeader from 'components/BRHeader/BRHeader';

const codTransactionsDetailsTableColumns = [
  { dataIndex: 'tn', title: 'TN' },
  { dataIndex: 'businessName', title: 'Business Name' },
  { dataIndex: 'delivery_date', title: 'Delivery Date' },
  { dataIndex: 'gross_cod', title: 'Gross COD' },
  { dataIndex: 'shopping_fees', title: 'Shopping Fees' },
  { dataIndex: 'vat', title: 'VAT' },
  { dataIndex: 'net_cod', title: 'Net COD' },
  { dataIndex: 'paid', title: 'Paid' },
  { dataIndex: 'payment_type', title: 'Payment Type' }
];

const paymentStatusMap = {
  1: 'AWAITING_GENERATION',
  2: 'AWAITING PAYMENT',
  3: 'PAID'
};

const paymentTypeMap = {
  4: 'CHEQUE',
  5: 'BANK_TRANSFER',
  6: 'CASH'
};

const TransactionDetails = ({
  match,
  getARReportDetails,
  reportDetails,
  downloadArReportDetailsCSV,
  removeReportData
}) => {
  const {
    params: { id: businessId }
  } = match;

  const [reportRows, setReportRows] = useState([]);

  useEffect(() => {
    getARReportDetails(businessId);
    return () => removeReportData();
  }, []);

  useEffect(() => {
    if (reportDetails?.list?.length > 0) {
      formatArReport(reportDetails.list);
    }
  }, [reportDetails]);

  const formatArReport = (data) => {
    const newData = [];
    data.map((itm) =>
      newData.push({
        key: itm.trackingNumber,
        tn: itm.trackingNumber,
        businessName: itm.businessName,
        delivery_date: dayjs(new Date(itm.confirmedDeliveryAt))
          .tz()
          .format('LLL'),
        gross_cod: moneyFormatter(itm.grossCod),
        shopping_fees: moneyFormatter(itm.bostaFees),
        vat: moneyFormatter(itm.vat),
        net_cod: moneyFormatter(itm.netCod),
        paid: paymentStatusMap[itm.paymentStatus],
        payment_type: paymentTypeMap[itm.paymentType] || 'N/A'
      })
    );
    setReportRows(newData);
  };

  return (
    <Container
      header={<BRHeader title="Reimbursement Report Details" />}
      content={
        <>
          <div className={styles.downloadReport}>
            <Button
              className="primaryButton"
              onClick={() =>
                downloadArReportDetailsCSV(reportDetails[0].reportNumber)
              }
            >
              Download Report
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div className={styles.titleDiv}>
              <span>
                <b>Business Name:</b>{' '}
                {reportDetails[0]?.businessName
                  ? reportDetails[0]?.businessName
                  : 'N/A'}
              </span>
              <span>
                <b>No. of Deliveries:</b>{' '}
                {reportDetails?.list?.length
                  ? reportDetails?.list?.length
                  : '0'}
              </span>
            </div>
            <div className={styles.titleDiv}>
              <span>
                <b>Total Fees & Vat:</b>{' '}
                {reportDetails[0]?.totalFees
                  ? moneyFormatter(reportDetails[0]?.totalFees)
                  : '0'}
              </span>
              <span>
                <b>Payment Type:</b>{' '}
                {reportDetails?.list?.length > 0
                  ? paymentTypeMap[reportDetails?.list[0]?.paymentType]
                  : 'N/A'}
              </span>
            </div>
            <div className={styles.titleDiv}>
              <span>
                <b>Total Net COD:</b>{' '}
                {reportDetails[0]?.totalNetAmount
                  ? moneyFormatter(reportDetails[0]?.totalNetAmount)
                  : 'N/A'}
              </span>
              <span>
                <b>Report Generation Date:</b>{' '}
                {reportDetails[0]?.reportDate
                  ? dayjs(new Date(reportDetails[0]?.reportDate))
                      .tz()
                      .format('LLL')
                  : 'N/A'}
              </span>
            </div>
          </div>
          <div className={styles.tableDetails}>
            <span>
              <b>Deliveries.</b> {reportDetails?.list?.length || '0'}
            </span>
            <Table
              dataSource={reportRows}
              columns={codTransactionsDetailsTableColumns}
              pagination
            />
          </div>
        </>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getARReportDetails: (data) => dispatch(getARReportDetails(data)),
  removeReportData: (data) => dispatch(removeReportData(data)),
  downloadArReportDetailsCSV: (data) =>
    dispatch(downloadArReportDetailsCSV(data))
});

const mapStateToProps = ({ codAR }) => ({
  reportDetails: codAR.reportDetails,
  reportCSV: codAR.reportCSV
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionDetails));
