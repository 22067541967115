import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';

import { getFulfillmentBusinessTabsPath } from 'constants/fulfillment';

import SelectBusiness from 'components/NewFulfillment/FulfillmentInbound/components/SelectBusiness/SelectBusiness';

import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';

import './BusinessDetails.less';

const BusinessDetails = ({
  intl,
  isLoading,
  setIsLoading,
  selectedBusiness,
  setSelectedBusiness,
  isBusinessEditDisabled = false,
  businessId
}) => {
  return (
    <div className="br-po-business-details br-po-section">
      <div className="br-po-section__title">
        {intl.formatMessage({
          id: 'fulfillment_inbound.create_po.business_details.title'
        })}
      </div>
      <div className="br-po-section__content">
        <div className="br-po-business-details__form">
          <SelectBusiness
            label={intl.formatMessage({
              id: 'fulfillment_inbound.create_po.business_details.business_name.label'
            })}
            placeholder={intl.formatMessage({
              id: 'fulfillment_inbound.create_po.business_details.business_name.placeholder'
            })}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedBusiness={selectedBusiness}
            setSelectedBusiness={setSelectedBusiness}
            isBusinessEditDisabled={isBusinessEditDisabled}
            businessId={businessId}
          />
          <Form.Item
            label={intl.formatMessage({
              id: 'fulfillment_inbound.create_po.business_details.business_id.label'
            })}
          >
            <Input value={selectedBusiness?._id} disabled />
          </Form.Item>
        </div>
        {selectedBusiness && (
          <a
            className="br-po-business-details__view-info"
            href={`${getFulfillmentBusinessTabsPath(
              selectedBusiness._id
            )}/info`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {intl.formatMessage({
              id: 'fulfillment_inbound.create_po.business_details.view_business'
            })}
            <ExternalLink />
          </a>
        )}
      </div>
    </div>
  );
};

export default injectIntl(BusinessDetails);
