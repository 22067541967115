import React, { useEffect, useState } from 'react';
import { Timeline, Divider, Button } from 'antd';
import Icon from '@ant-design/icons';
import { injectIntl } from 'react-intl';

import { getImageURL } from 'services/shipments';
import { TICKET_LINK } from 'constants/shipments';
import { openInNewTab } from 'utils/helpers';
import { formatDate } from 'utils/shipmentDetails';
import { showBill } from 'utils';

import LiveOpsValidation from 'components/Shipments/components/NewDeliveryDetails/components/LiveOpsValidation/LiveOpsValidation';
import { notify } from 'components/Notify/Notify';
import CallLogs from './components/CallAndSmsLogs/CallAndSmsLogs';

import { ReactComponent as LogsIcon } from 'assets/imgRevamp/NewLogSectionIcon.svg';
import { ReactComponent as DownIcon } from 'assets/imgRevamp/downIcon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/imgRevamp/WhatsappIcon.svg';

import './DeliveryLogs.less';
import BRButton from 'components/BRButton/BRButton';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';
import { openModal } from 'utils/modal';

const DeliveryLogs = ({
  logs,
  liveOps,
  intl,
  setIsLoading,
  deliveryDetails
}) => {
  const [allLogs, setAllLogs] = useState([]);
  useEffect(() => {
    setAllLogs(logs);
  }, [logs]);

  const handleOnClick = (index) => {
    allLogs[index].showExceptionDeatils = !allLogs[index].showExceptionDeatils;
    setAllLogs((prev) => [...prev]);
  };

  const openImage = async ({ type, name }) => {
    setIsLoading(true);

    try {
      const { url } = await getImageURL(type, name);
      if (url) {
        openInNewTab(url);
      }
    } catch ({ message }) {
      notify(message);
    }

    setIsLoading(false);
  };

  const handleManifestIdClick = ({ routeId, returnGroupId }) => {
    window.open(
      `/routes/${routeId}/return-manifest-details/${returnGroupId}`,
      '_blank'
    );
  };

  const handleViewProofOfExceptionClick = (previewImage) => {
    openModal(BRPreviewImage, {
      previewImageTitle: intl.formatMessage({
        id: 'pickups.pickup_details.proof_of_exception'
      }),
      previewImage
    });
  };

  return (
    <div className="br-delivery-logs__container">
      <div className="br-delivery-logs__header">
        <LogsIcon />
        <span>Logs Timeline</span>
      </div>
      <Divider />
      <CallLogs deliveryDetails={deliveryDetails} />
      <Timeline>
        {allLogs.map((log, index) => {
          let exceptionExtraInfo = null;
          return (
            <Timeline.Item>
              <div>
                <div className="br-delivery-logs__log_container">
                  <div className="br-delivery-logs__log-content">
                    <span className="br-delivery-logs__log_description">
                      {log.action}
                      {log.proofOfException && (
                        <BRButton
                          type="link-color"
                          label={intl.formatMessage({
                            id: 'pickups.pickup_details.proof_of_exception'
                          })}
                          onClick={() =>
                            handleViewProofOfExceptionClick(
                              log.proofOfException
                            )
                          }
                        />
                      )}
                    </span>
                    <span>
                      {log.takenBy[0]}{' '}
                      <span className="br-delivery-logs__taken_by">
                        {log.takenBy[1]}
                      </span>
                      {log.hub && (
                        <>
                          Hub{' '}
                          <span className="br-delivery-logs__hub">
                            {log.hub}
                          </span>
                        </>
                      )}
                      {log.por?.isReturned && (
                        <div className="br-delivery-logs__por">
                          {log.por?.data ? (
                            <Button
                              className="br-delivery-logs__por-button"
                              type="text"
                              onClick={() => showBill(log.por?.data)}
                            >
                              {intl.formatMessage({
                                id: 'deliveries.delivery_details.proof_of_return.action'
                              })}
                            </Button>
                          ) : (
                            <span className="br-delivery-logs__por__not-available">
                              {intl.formatMessage({
                                id: 'deliveries.delivery_details.proof_of_return.error_msg'
                              })}
                            </span>
                          )}
                        </div>
                      )}
                      {log.returnManifest && (
                        <Button
                          type="text"
                          className="br-delivery-logs__extra-info__return-manifest"
                          onClick={() =>
                            handleManifestIdClick({
                              routeId: log.returnManifest.routeId,
                              returnGroupId: log.returnManifest.returnGroupId
                            })
                          }
                        >
                          {intl.formatMessage({
                            id: 'return_manifest.return_manifest_id'
                          })}
                        </Button>
                      )}
                    </span>
                  </div>
                  <div className="br-delivery-logs__log-date">
                    {formatDate(log.date, true)}
                  </div>
                </div>
                {log.extraInfo && (
                  <>
                    <div
                      className={`br-delivery-logs__extra-info ${
                        log.bannerColor || ''
                      }`}
                    >
                      <div>
                        {log.extraInfo}
                        {exceptionExtraInfo}
                      </div>
                      {log.liveOpsLogAttemptNumber && (
                        <div
                          className="br-delivery-logs__extra-info__down-icon"
                          onClick={() => handleOnClick(index)}
                        >
                          <Icon component={DownIcon} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {log.showExceptionDeatils && log.liveOpsLogAttemptNumber && (
                <LiveOpsValidation
                  liveOps={liveOps}
                  whatsAppValidation={log.whatsAppVerification}
                  whatsappFakeAttempt={log.whatsappFakeAttempt}
                  liveOpsActionId={log.liveOpsLogAttemptNumber}
                />
              )}
              {log.isWhatsappLog && (
                <div className="br-delivery-logs__whatsapp-log">
                  <Icon component={WhatsappIcon} />
                  <span className="br-delivery-logs__whatsapp-update">
                    {log.actionDetail}
                  </span>
                </div>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default injectIntl(DeliveryLogs);
