import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import classnames from 'classnames';
import { intl } from 'IntlWrapper/IntlWrapper';
import { WHATSAPP_LINK } from 'constants/action-center';
import { getStarInformation } from 'services/stars';
import { openInNewTab } from 'utils/helpers';
import { currentUserCountry } from 'common/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as OutForDeliveryIcon } from 'assets/bosta-icons/Out for Delivery.svg';
import { ReactComponent as PhoneIcon } from 'assets/bosta-icons/phone-number.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/bosta-icons/whatsapp.svg';
import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';

import './StarInfoPopover.less';

const StarInfoPopover = ({ starId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [starInfo, setStarInfo] = useState(null);

  useEffect(() => {
    getStarInfo();
  }, []);

  const getStarInfo = async () => {
    setIsLoading(true);
    try {
      const { message } = await getStarInformation(starId);
      setStarInfo(message);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const countryCodeNumber = currentUserCountry.codeNumber;
    const includesCountryCode = new RegExp(`^\\${countryCodeNumber}`, 'i').test(
      phoneNumber
    );

    if (!includesCountryCode) {
      return `${currentUserCountry.codeNumber}${phoneNumber}`;
    }

    return phoneNumber;
  };

  return isLoading ? (
    <Skeleton
      className="br-action-center__star-info-loading"
      avatar
      active
      paragraph={{
        rows: 2
      }}
    />
  ) : (
    <div className="br-action-center__star-container">
      <div className="br-action-center__star-info-container">
        <div className="br-action-center__star-info">
          <div className="br-action-center__star-icon">
            <OutForDeliveryIcon />
          </div>
          <div className="br-action-center__star-name heading">
            {`${starInfo?.firstName} ${starInfo?.lastName}`}
            <span
              className={classnames('br-action-center__star-active', {
                'star-busy': starInfo?.availabilityState === 'BUSY'
              })}
            />
            <span className="br-action-center__star-hub caption-lg">
              {intl.formatMessage(
                { id: 'action_center.requests.star_at' },
                {
                  hubName: starInfo?.warehouseInfo?.name
                }
              )}
            </span>
          </div>
        </div>
        <span className="br-action-center__star_phone caption-lg">
          <PhoneIcon />
          {starInfo?.phone}
        </span>
      </div>
      <div className="br-action-center__whatsapp-link-container">
        <WhatsAppIcon />
        <BRButton
          onClick={() =>
            openInNewTab(
              `${WHATSAPP_LINK}${formatPhoneNumber(starInfo?.phone)}`
            )
          }
          type="link-color"
          label={intl.formatMessage({
            id: 'action_center.requests.whatsapp'
          })}
        />
        <ExternalLink />
      </div>
    </div>
  );
};

export default StarInfoPopover;
