import { useContext, useEffect } from 'react';
import { Select, Form, Switch } from 'antd';
import { useIntl } from 'react-intl';

import { HubsContext } from 'contexts/hub.context';

import BRButton from 'components/BRButton/BRButton';

import './ZonesGeofencingSelectors.less';

const ZonesGeofencingSelectors = ({
  selectedHubId,
  setSelectedHubId,
  hubZones,
  fetchHubZones,
  selectedZoneId,
  setSelectedZoneId,
  selectedZoneGeometryPath,
  setSelectedZoneGeometryPath,
  setShowOtherZones,
  showOtherZones,
  handleOnSave,
  zoneGeofencingErrors
}) => {
  const { scopedHubs } = useContext(HubsContext);

  const intl = useIntl();

  useEffect(() => {
    if (selectedHubId) {
      fetchHubZones();
      setSelectedZoneId(null);
      setSelectedZoneGeometryPath([]);
    }
  }, [selectedHubId]);

  const handleClearZoneGeometry = () => {
    setSelectedZoneGeometryPath([]);
  };

  return (
    <div className="br-bosta-coding-selectors__container">
      <Form onFinish={handleOnSave}>
        <div className="br-bosta-coding-selectors-row">
          <Form.Item
            label={intl.formatMessage({
              id: 'bosta_coding.zones_geofencing.selectors.hub_label'
            })}
          >
            <Select
              options={scopedHubs}
              showSearch
              onChange={setSelectedHubId}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'bosta_coding.zones_geofencing.selectors.hub_placeholder'
              })}
              fieldNames={{ label: 'name', value: '_id' }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'bosta_coding.zones_geofencing.selectors.zone_label'
            })}
          >
            <Select
              options={hubZones}
              value={selectedZoneId}
              disabled={!selectedHubId}
              onChange={setSelectedZoneId}
              showSearch
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'bosta_coding.zones_geofencing.selectors.zone_placeholder'
              })}
              fieldNames={{ label: 'name', value: '_id' }}
            />
          </Form.Item>
        </div>
        <div className="br-bosta-coding__extra-actions">
          <Form.Item>
            <div className="br-bosta-coding__switch">
              <Switch
                checked={showOtherZones}
                onChange={setShowOtherZones}
                disabled={!selectedHubId}
              />
              <span className="body-medium">
                {intl.formatMessage({
                  id: 'bosta_coding.zones_geofencing.selectors.show_others'
                })}
              </span>
            </div>
          </Form.Item>
        </div>
        <div className="br-bosta-coding__actions">
          <BRButton
            htmlType="submit"
            type="primary"
            disabled={
              !selectedHubId ||
              !selectedZoneId ||
              !selectedZoneGeometryPath.length ||
              zoneGeofencingErrors.polygonsIntersecting
            }
            label={intl.formatMessage({ id: 'common.save' })}
          />
          <BRButton
            disabled={!selectedZoneGeometryPath.length}
            onClick={handleClearZoneGeometry}
            label={intl.formatMessage({
              id: 'bosta_coding.zones_geofencing.selectors.clear_zone_geofence'
            })}
          />
        </div>
        <div className="br-bosta-coding__errors">
          {zoneGeofencingErrors.polygonsIntersecting && (
            <span>
              {intl.formatMessage({
                id: 'bosta_coding.zones_geofencing.errors.intersecting_polygons'
              })}
            </span>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ZonesGeofencingSelectors;
