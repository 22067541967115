import { Card } from 'antd';

import './InfoCard.less';

const InfoCard = ({ title, infoList = [] }) => {
  return (
    <Card title={title} className="br-info-card">
      {infoList.map(
        ({ prefixIcon = null, content, suffixIcon = null }, index) => (
          <div className="br-info-card__content-item" key={index}>
            {prefixIcon}
            <span>{content || 'N/A'}</span>
            {suffixIcon}
          </div>
        )
      )}
    </Card>
  );
};

export default InfoCard;
