/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Select, Form, Typography, Button } from 'antd';

import { getOrderDetails, createOrder, editOrder } from 'services/shipments';
import { cleanEmptyString } from 'utils/helpers';
import { fetchBusinessPickupLocations, getBusiness } from 'services/business';
import { fetchCities } from 'services/cities';
import { editPickup, fetchPickups } from 'services/pickups';
import { getAllPriceSize } from 'services/priceTiers';
import { BUSINESS_TYPES } from 'constants/Businesses';
import {
  DELIVERY_TYPES_CODES,
  COD_OPTIONS,
  PACKAGE_TYPES,
  FLYER_SIZE,
  PACKAGE_TYPES_BULKY
} from 'constants/shipments';
import { mappingPayload, buisnessData } from 'utils/deliveries';
import { PICKUP_STATUS } from 'constants/pickups';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import OrderDetails from 'components/Shipments/CreateEditOrder/OrderDetails/OrderDetails';
import CustomerDetails from 'components/Shipments/CreateEditOrder/CustomerDetails/CustomerDetails';
import RequestPickup from 'components/Shipments/CreateEditOrder/RequestPickup/RequestPickup';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';

import './CreateEditOrder.less';

class CreateEditOrder extends Component {
  state = {
    orderData: null,
    isLoading: false,
    selectedBusiness: null,
    pricingSizes: null,
    selectedBusinessPickupLocations: [],
    selectedPickupLocation: null,
    sender: {},
    businessData: {},
    pickupLocations: [],
    isCitiesLoading: false,
    orderProps: {
      type: DELIVERY_TYPES_CODES.FORWARD,
      option: COD_OPTIONS.COD,
      packageType: PACKAGE_TYPES[0].value
    },
    isPickupSelected: false,
    isPickupRequestCreated: false,

    returnLocation: null,
    pickups: [],
    cities: []
  };

  formRef = React.createRef('');

  steps = [
    {
      title: 'order_details',
      component: ({ orderData }) => {
        const {
          pricingSizes,
          selectedBusinessPickupLocations,
          selectedBusiness,
          selectedPickupLocation
        } = this.state;

        return (
          <OrderDetails
            orderData={orderData}
            pricingSizes={pricingSizes}
            pickupLocations={selectedBusinessPickupLocations}
            formRef={this.formRef}
            setIsPickupSelected={this.setIsPickupSelected}
            selectedPickupLocation={selectedPickupLocation}
            handleOnChangeForm={this.handleOnChangeForm}
            setOrderTypeProps={this.setOrderTypeProps}
            selectedBusinessId={selectedBusiness?._id}
            selectedBusinessType={selectedBusiness?.type}
            selectedBusinessCodLimit={
              selectedBusiness?.paymentInfo?.codLimit || 30000
            }
            handleAddNewPickupLocation={this.handleAddNewPickupLocation}
          />
        );
      }
    },
    {
      title: 'customer_details',
      component: ({ orderData }) => {
        const { cities, isCitiesLoading } = this.state;
        return (
          <CustomerDetails
            orderData={orderData}
            formRef={this.formRef}
            cities={cities}
            isCitiesLoading={isCitiesLoading}
            handleOnChangeForm={this.handleOnChangeForm}
          />
        );
      }
    },
    {
      title: 'request_pickup',
      component: ({ pickupLocations, orderData }) => {
        const { pickups, orderProps, selectedBusiness, isPickupSelected } =
          this.state;
        return (
          <RequestPickup
            orderData={orderData}
            formRef={this.formRef}
            orderProps={orderProps}
            selectedBusiness={selectedBusiness}
            pickupLocations={pickupLocations}
            packageType={orderProps.packageType}
            pickups={pickups}
            setIsPickupSelected={this.setIsPickupSelected}
            isPickupSelected={isPickupSelected}
            setIsPickupCreated={this.setIsPickupCreated}
            getPickups={this.getPickups}
          />
        );
      }
    }
  ];

  setPickups = (pickups) => {
    this.setState({ pickups });
  };

  setIsPickupSelected = (isPickupSelected) => {
    this.setState({ isPickupSelected });
  };

  handleAddNewPickupLocation = (selectedBusinessPickupLocations) => {
    this.setState({ selectedBusinessPickupLocations });
  };

  setIsPickupCreated = (isPickupRequestCreated) => {
    this.setState({ isPickupRequestCreated });
  };

  setOrderTypeProps = ({ type, option, packageType }) => {
    this.setState({ orderProps: { type, option, packageType } });
  };

  handleOnChangeForm = (name, value) => {
    this.formRef.current.setFieldsValue({ [name]: value });
  };

  getOrderDetails = async (orderId) => {
    try {
      this.setState({ isLoading: true });
      const response = await getOrderDetails(orderId);
      this.setState({ isLoading: false });
      return response;
    } catch (error) {
      notify(error.message);
      this.setState({ isLoading: false });
    }
  };

  getCities = async () => {
    try {
      this.setState({ isCitiesLoading: true });
      const cities = await fetchCities();
      this.setState({ cities, isCitiesLoading: false });
    } catch (error) {
      notify(error.message);
    }
  };

  getPricingSizes = async () => {
    const {
      data: { sizes }
    } = await getAllPriceSize();
    this.setState({
      pricingSizes: sizes.map((item) => {
        if (item.alias && item.alias !== item.name) {
          item.name = `${item.alias} (${item.name})`;
        }
        return item;
      })
    });
  };

  handleGetOrderDetails = async (orderId) => {
    try {
      this.setState({ isLoading: true });
      const previousOrderData = await getOrderDetails(orderId);
      let orderDetails = previousOrderData[0];

      const { dropOffAddress, pickupAddress } = orderDetails;

      const { RETURN_TO_ORIGIN, SIGN_AND_RETURN, ...restDeliveryCodes } =
        DELIVERY_TYPES_CODES;

      const codeOfDeliveryType = Object.keys(DELIVERY_TYPES_CODES).findIndex(
        (item) => item === orderDetails.type
      );

      orderDetails = {
        ...orderDetails,
        type: {
          code: DELIVERY_TYPES_CODES[
            `${Object.keys(DELIVERY_TYPES_CODES)[codeOfDeliveryType]}`
          ]
        }
      };
      let address;
      if (
        orderDetails.type.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
        orderDetails.type.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
      ) {
        address = {
          cityCode: pickupAddress?.city?._id,
          districtId: pickupAddress?.district?._id,
          firstLine: pickupAddress?.firstLine,
          buildingNumber: pickupAddress?.buildingNumber,
          apartment: pickupAddress?.apartment,
          floor: pickupAddress?.floor,
          secondLine: pickupAddress?.secondLine,
          isWorkAddress: pickupAddress?.isWorkAddress
        };
      } else {
        address = {
          cityCode: dropOffAddress?.city?._id,
          districtId: dropOffAddress?.district?._id,
          firstLine: dropOffAddress?.firstLine,
          buildingNumber: dropOffAddress?.buildingNumber,
          apartment: dropOffAddress?.apartment,
          floor: dropOffAddress?.floor,
          secondLine: dropOffAddress?.secondLine,
          isWorkAddress: dropOffAddress?.isWorkAddress
        };
        this.setState({ selectedPickupLocation: pickupAddress });
      }
      this.onBusinessChange(orderDetails?.sender?._id);

      this.setState({
        orderData: orderDetails,
        returnLocation:
          orderDetails?.type?.code ===
          DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? orderDetails?.dropOffAddress
            : orderDetails?.returnAddress,
        isLoading: false,
        sender: orderDetails?.sender,
        selectedBusiness: { _id: orderDetails?.sender?._id },
        orderProps: {
          type: orderDetails.type.code,
          option:
            orderDetails.cod?.amount &&
            Math.sign(orderDetails.cod?.amount) === 1
              ? COD_OPTIONS.COD
              : COD_OPTIONS.REFUND,
          packageType:
            previousOrderData?.specs?.packageType === PACKAGE_TYPES_BULKY[0] ||
            previousOrderData?.specs?.packageType === PACKAGE_TYPES_BULKY[1]
              ? PACKAGE_TYPES[2].value
              : previousOrderData?.specs?.packageType,
          packageTypeBulky:
            previousOrderData?.specs?.packageType === PACKAGE_TYPES_BULKY[0] ||
            previousOrderData?.specs?.packageType === PACKAGE_TYPES_BULKY[1]
              ? previousOrderData?.specs?.packageType
              : undefined
        }
      });
      this.formRef.current.setFieldsValue({
        type: orderDetails?.type?.code,
        cod: orderDetails?.cod?.amount
          ? Math.abs(orderDetails.cod?.amount).toString()
          : COD_OPTIONS.WITH_OUT,
        itemsCount:
          orderDetails.type.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? orderDetails?.returnSpecs?.packageDetails?.itemsCount?.toString()
            : orderDetails?.specs?.packageDetails?.itemsCount?.toString(),
        description:
          orderDetails.type.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? orderDetails?.returnSpecs?.packageDetails?.description
            : orderDetails?.specs?.packageDetails?.description,
        returnItemsCount:
          orderDetails?.returnSpecs?.packageDetails?.itemsCount?.toString(),
        returnDescription:
          orderDetails?.returnSpecs?.packageDetails?.description,
        businessReference: orderDetails?.businessReference,
        locationId:
          orderDetails?.type?.code ===
          DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? orderDetails?.dropOffAddress?.businessLocationId
            : orderDetails?.returnAddress?.businessLocationId,

        allowToOpenPackage: orderDetails?.allowToOpenPackage,
        notes: orderDetails?.notes,
        fullName:
          orderDetails?.receiver?.fullName ||
          `${orderDetails?.receiver?.firstName} ${orderDetails?.receiver?.lastName}`,
        phone: orderDetails?.receiver?.phone,
        secondPhone: orderDetails?.receiver?.secondPhone,
        ...address,
        pickupRequestId: orderDetails?.pickupRequestId,
        packageType:
          orderDetails?.specs?.packageType === PACKAGE_TYPES_BULKY[0] ||
          orderDetails?.specs?.packageType === PACKAGE_TYPES_BULKY[1]
            ? PACKAGE_TYPES[2].value
            : orderDetails?.specs?.packageType,
        packageTypeBulky:
          orderDetails?.specs?.packageType === PACKAGE_TYPES_BULKY[0] ||
          orderDetails?.specs?.packageType === PACKAGE_TYPES_BULKY[1]
            ? orderDetails?.specs?.packageType
            : undefined,
        pricingPackageSize: orderDetails?.pricingPackageSize?.name,
        packageSize:
          orderDetails?.pricingPackageSize?.name === FLYER_SIZE.WHITE_BAG
            ? 1
            : 0
      });
    } catch (error) {
      notify(error.message);
      this.setState({ isLoading: false });
    }
  };

  getPickups = async (businessId) => {
    try {
      this.setState({ isLoading: true });
      const {
        result: { pickups }
      } = await fetchPickups({
        pageId: -1,
        state: PICKUP_STATUS.REQUESTED,
        businessId
      });
      this.setState({ pickups });
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleOnbusinessChange = (business) => {
    this.formRef.current.resetFields();
    this.onBusinessChange(business.value);
  };

  onBusinessChange = async (businessId) => {
    this.setState({ isLoading: true });
    const selectedBusiness = await getBusiness(businessId);

    const { data: selectedBusinessPickupLocations } =
      await fetchBusinessPickupLocations(businessId);
    this.getPickups(businessId);

    const selectedPickupLocation = selectedBusinessPickupLocations.find(
      (item) => item._id === selectedBusiness.defaultPickupLocation?._id
    );
    this.formRef.current.setFieldsValue({
      businessAddress: selectedPickupLocation?._id,
      sender: selectedBusiness._id,
      allowToOpenPackage: false
    });

    this.setState({
      selectedBusiness,
      selectedBusinessPickupLocations,
      selectedPickupLocation,
      isLoading: false,
      isPickupSelected: false,
      sender: {
        _id: selectedBusiness._id,
        name: selectedBusiness?.name,
        type: selectedBusiness?.type,
        phone: selectedBusiness?.defaultPickupLocation?.contactPerson?.phone
      }
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { id: orderId }
      }
    } = this.props;

    this.getCities();
    if (orderId) {
      this.handleGetOrderDetails(orderId);
      this.getPricingSizes();
    }
  }

  onFinish = async (values) => {
    const {
      orderProps,
      sender,
      selectedBusinessPickupLocations,
      orderData,
      cities,
      pickups,
      selectedPickupLocation,
      selectedBusiness,
      isPickupSelected,
      isPickupRequestCreated
    } = this.state;
    const {
      history,
      match: {
        params: { id: orderId }
      },
      intl
    } = this.props;

    let payload = mappingPayload(values, {
      cities,
      orderProps,
      sender,
      selectedBusinessPickupLocations,
      selectedPickupLocation
    });
    if (
      values.type === DELIVERY_TYPES_CODES.FORWARD &&
      values.packageType === PACKAGE_TYPES[2].value &&
      values.pickupRequestId
    ) {
      const selectedPickup = pickups.find(
        (pickup) => pickup.puid === values.pickupRequestId
      );

      try {
        await editPickup({
          id: selectedPickup._id,
          payload: {
            businessId: selectedBusiness._id,
            packageType:
              values.packageType === PACKAGE_TYPES[2].value
                ? values.packageTypeBulky
                : 'Normal'
          }
        });
      } catch (error) {
        notify(error.message);
      }
    }
    delete values.bulkyTypeSelected;
    if (orderId) {
      try {
        delete payload.type;
        delete payload.sender;
        if (
          payload.cod === orderData.cod?.amount ||
          (payload.cod === 0 && !orderData.cod)
        ) {
          delete payload.cod;
        }
        this.setState({ isLoading: true });

        const response = await editOrder(
          orderData._id,
          cleanEmptyString(payload)
        );

        this.setState({ isLoading: false });
        notify(
          intl.formatMessage({
            id: 'shipments.edit_order_successfully'
          }),
          'success'
        );

        history.push(`/deliveries/${orderId}/details`);
      } catch (error) {
        notify(error.message);
        this.setState({ isLoading: false });
      }
    } else {
      try {
        this.setState({ isLoading: true });
        payload = {
          ...payload,
          isPickupRequestCreated: isPickupRequestCreated.toString(),
          isPickupRequestSelected: isPickupSelected.toString(),
          sessionId: localStorage.getItem('ajs_anonymous_id'),
          creationSrc: 'Admin Web'
        };
        const successResponse = await createOrder(cleanEmptyString(payload));
        history.push({
          pathname: '/deliveries/create/order-summary',
          state: {
            successResponse
          }
        });
        this.setState({ isLoading: false });
      } catch (error) {
        notify(error.message);
        this.setState({ isLoading: false });
      }
    }
  };

  onBusinessPickupLocationChange = (businessLocationId) => {
    const { selectedBusinessPickupLocations } = this.state;
    const selectedPickupLocation = selectedBusinessPickupLocations.find(
      (item) => item._id === businessLocationId
    );

    this.setState({ selectedPickupLocation });

    this.formRef.current.setFieldsValue({
      businessAddress: selectedPickupLocation._id
    });
  };

  render() {
    const {
      orderData,
      isLoading,
      pickupLocations,
      businessData,
      selectedBusiness,
      selectedBusinessPickupLocations
    } = this.state;

    const {
      intl,
      match: {
        params: { id: orderId }
      }
    } = this.props;

    return (
      <LoadingWrapper loading={isLoading}>
        <Container
          className="br-new-order-parent"
          header={
            <BRHeader
              title={intl.formatMessage({
                id: 'shipments.title'
              })}
            />
          }
          content={
            <div className="br-new-order">
              <Typography.Title level={3} className="br-new-order__title">
                {intl.formatMessage({
                  id: `shipments.new_order.${orderId ? 'edit' : 'create'}_title`
                })}
              </Typography.Title>
              <Form
                scrollToFirstError
                ref={this.formRef}
                onFinish={this.onFinish}
                initialValues={{
                  type: DELIVERY_TYPES_CODES.FORWARD,
                  packageType: PACKAGE_TYPES[0].value,
                  itemsCount: '1',
                  returnItemsCount: '1',
                  bulkyTypeSelected: true,
                  locationId: businessData?.defaultPickupLocation?._id,
                  packageTypeBulky: PACKAGE_TYPES_BULKY[0]
                }}
              >
                {!orderData && (
                  <>
                    <h4>
                      <b>Business</b>
                    </h4>
                    <Form.Item
                      label="Business Name"
                      rules={[
                        {
                          required: true,
                          message: 'Business required'
                        }
                      ]}
                    >
                      <BusinessSelector
                        onChange={this.handleOnbusinessChange}
                        labelInValue
                        showValueInLabel
                        placeholder={intl.formatMessage({
                          id: 'orders.mass_upload.error_select_business'
                        })}
                      />
                    </Form.Item>
                    <BRInformationTable
                      dataSource={{}}
                      cells={buisnessData(selectedBusiness)}
                      totalCol={5}
                    />
                    <Form.Item
                      className="mt-3 mb-3"
                      name="businessAddress"
                      label="Pickup Address"
                      rules={[
                        {
                          required: true,
                          message: 'Business location required'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select Business pickup location"
                        disabled={!selectedBusiness}
                        onChange={this.onBusinessPickupLocationChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {selectedBusinessPickupLocations.map((item) => (
                          <Select.Option value={item._id} key={item._id}>
                            {item.locationName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                )}
                {this.steps.map((item, index) => {
                  return item?.component({
                    orderData,
                    pickupLocations
                  });
                })}
                <div className="br-new-order__actions__create-order">
                  <Button disabled={isLoading} type="primary" htmlType="submit">
                    {intl.formatMessage({
                      id: orderData
                        ? 'common.save'
                        : `shipments.new_order.actions.create_order`
                    })}
                  </Button>
                </div>
              </Form>
            </div>
          }
        />
      </LoadingWrapper>
    );
  }
}

export default injectIntl(withRouter(CreateEditOrder));
