import { injectIntl } from 'react-intl';

import { expireUserSession } from 'services/auth';

import SessionCard from 'components/NewSettings/components/ActiveSessions/components/SessionCard/SessionCard';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './SessionsList.less';

const SessionsList = ({
  currentSession,
  otherSessions = [],
  intl,
  setIsLoading,
  handleRemoveSession
}) => {
  const handleLogoutSession = async (sessionIds) => {
    setIsLoading(true);
    try {
      await expireUserSession({
        sessionIds
      });
      handleRemoveSession(sessionIds);
      notify(
        intl.formatMessage({
          id: 'settings.active_sessions.session_logout_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleLogoutAllSessions = () => {
    const otherSessionsIds = otherSessions.map((session) => session.sessionId);
    handleLogoutSession(otherSessionsIds);
  };

  return (
    <div className="br-sessions-list__container">
      <div className="br-current-session__container">
        <div className="br-sessions-list__title display-sm">
          {intl.formatMessage({
            id: 'settings.active_sessions.current_session'
          })}
        </div>
        {currentSession && (
          <SessionCard
            sessionInfo={currentSession}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
      <div className="br-other-sessions__container">
        <div className="br-sessions-list__title display-sm">
          {intl.formatMessage({
            id: 'settings.active_sessions.other_sessions'
          })}
          {!!otherSessions?.length && (
            <BRButton
              type="destructive-basic"
              label={intl.formatMessage({
                id: 'settings.active_sessions.logout_all_sessions'
              })}
              onClick={handleLogoutAllSessions}
            />
          )}
        </div>
        {otherSessions.map((session) => (
          <SessionCard
            sessionInfo={session}
            handleLogoutSession={handleLogoutSession}
          />
        ))}
      </div>
    </div>
  );
};

export default injectIntl(SessionsList);
