import React from 'react';
import { connect } from 'react-redux';

function SignPage({ content }) {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
      }}
    >
      {content}
    </div>
  );
}

export default connect()(SignPage);
