import React from 'react';
import { injectIntl } from 'react-intl';

const Vat = ({ intl }) => {
  return (
    <div className="br-pricing-plan__14-percent">
      <p>
        {intl.formatMessage({
          id: 'settings.pricing_plan.vat.vat_14'
        })}
      </p>
      <span>
        {intl.formatMessage({
          id: 'settings.pricing_plan.vat.vat_14_sub_title'
        })}
      </span>
    </div>
  );
};

export default injectIntl(Vat);
