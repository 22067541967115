import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import PackagingBalance from './components/PackagingBalance/PackagingBalance';
import ManageMaterials from './components/ManageMaterials/ManageMaterials';
import PackagingOrdersContainer from './components/PackagingOrdersContainer/PackagingOrdersContainer';

import './Packaging.less';

const ROUTES = [
  {
    path: '/packaging/balance',
    component: PackagingBalance,
    access: aclMatrix.PACKAGING_ACCESS,
    permission: [ACL_MATRIX.FLYERS_GET_BALANCE]
  },
  {
    path: '/packaging/manage-materials',
    component: ManageMaterials,
    access: aclMatrix.PACKAGING_ACCESS,
    permission: [ACL_MATRIX.FLYERS_GET_BALANCE]
  },
  {
    path: '/packaging/orders',
    component: PackagingOrdersContainer,
    access: aclMatrix.PACKAGING_ACCESS,
    permission: [ACL_MATRIX.FLYERS_ORDERS_PAGE]
  }
];

const Packaging = ({ match: { path }, location: { pathname }, intl }) => {
  const getDefaultPath = () => {
    let defaultPathName = '';

    ROUTES.some((route) => {
      if (isUserAuthorized(route.access, route.permission)) {
        defaultPathName = route.path;
        return true;
      }
    });

    return defaultPathName;
  };

  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'packaging.header' })} />
      }
      className="br-packaging-container"
      content={
        <div className="br-packaging">
          <Switch>
            {ROUTES.map(
              ({ path, component, access, permission }, index) =>
                isUserAuthorized(access, permission) && (
                  <Route key={index} path={path} component={component} />
                )
            )}
            <Redirect from={path} to={getDefaultPath()} />
          </Switch>
        </div>
      }
    />
  );
};

export default injectIntl(withRouter(Packaging));
