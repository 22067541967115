import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, DatePicker, Button, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { getCurrency } from 'common/countries/countries-mapping';

import './DateRangeModal.less';

const DateRangeModal = ({ visible, close, onSubmit, intl }) => {
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState({
    excludedFrom: null,
    excludedTo: null
  });

  const disabledDate = (current, date) => {
    const hours = date.getHours();
    if (hours >= 16) {
      return current && current.valueOf() <= date.setDate(date.getDate());
    } else
      return current && current.valueOf() < date.setDate(date.getDate() - 1);
  };

  const handleOnChangePickupDate = (evt) => {
    if (evt?.length) {
      setDates({
        excludedFrom: evt[0].utc().format(),
        excludedTo: evt[1].utc().format()
      });
    } else {
      setDates({
        excludedFrom: null,
        excludedTo: null
      });
    }
  };

  const onFinish = (values) => {
    if (dates.excludedFrom && dates.excludedTo) {
      onSubmit({ ...dates, excludedAmount: values?.amount });
      close();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={close}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      <p className="br-date-range-modal__title">Select Exclusion Date Range</p>
      <Form onFinish={onFinish} name="excludeFromLiabiltiyForm">
        <Form.Item
          label={intl.formatMessage({ id: 'common.amount' })}
          name="amount"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input
            placeholder={`0.00 ${getCurrency().type}`}
            allowClear
            type="number"
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true
            }
          ]}
        >
          <RangePicker
            disabledDate={(current) => disabledDate(current, new Date())}
            onChange={(evt) => handleOnChangePickupDate(evt)}
          />
        </Form.Item>
      </Form>
      <div className="br-date-range-modal__button-container">
        <Button onClick={close}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          form="excludeFromLiabiltiyForm"
        >
          {intl.formatMessage({ id: 'common.confirm' })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(DateRangeModal);
