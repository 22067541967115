import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Select, Button, Table, Modal, Form } from 'antd';
import Icon from '@ant-design/icons';
import { debounce } from 'lodash';

import { createRoute } from 'services/routes-form';

import { BIN, ROUTE, TRANSFER_TO_HUB } from 'constants/hubs';
import { openModal } from 'utils/modal';
import { isFulfillmentOrder } from 'utils/shipmentDetails';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as Trash } from 'assets/imgRevamp/trash.svg';

import './DispatchRTO.less';

const { Option } = Select;
const { confirm } = Modal;

const CreateNewRouteModal = ({
  close,
  placeholder,
  okText,
  cancelText,
  errorMsg,
  successMsg,
  afterSuccessCallback,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async ({ routeName }) => {
    setLoading(true);
    try {
      const payload = {
        name: routeName,
        deliveryIds: [],
        pickupRequestIds: []
      };
      const res = await createRoute(payload);
      if (res) {
        await afterSuccessCallback();
        notify(successMsg, 'success');
        close();
      }
    } catch ({ message }) {
      notify(message);
    }
    setLoading(false);
  };

  return (
    <Modal {...rest} onCancel={close} footer={null} centered>
      <Form
        name="create_new_route"
        initialValues={{ routeName: '' }}
        onFinish={onFinish}
      >
        <Form.Item
          name="routeName"
          rules={[{ required: true, message: errorMsg }]}
        >
          <Input placeholder={placeholder} disabled={loading} />
        </Form.Item>
        <div className="br-dispatch-rto_side-container_right_create-new-route">
          <Button onClick={close} disabled={loading}>
            {cancelText}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {okText}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const RTOScan = ({
  intl,
  isLoading,
  assignee,
  routes,
  shelves,
  setAssignee,
  setScannedQuerySearch,
  setScannedDeliveries,
  scannedDeliveries,
  clearScannedTNsTable,
  scannedValue,
  setScannedValue,
  selectedHub,
  selectedAssignee,
  assignDeliveriesToRoute,
  assignDeliveriesToShelf,
  setSelectedAssignee,
  transferToSortingFacility,
  fetchRoutes
}) => {
  const assignees = [TRANSFER_TO_HUB, ROUTE, BIN];
  const renderedAssigneeTypeOptions = assignees.map((assigneeName, index) => (
    <Option key={index} value={assigneeName}>
      {intl.formatMessage({
        id: `hubs.dispatch_rto.scan.select_assignee_dropdown.assignees.${assigneeName}`
      })}
    </Option>
  ));

  const renderedAssigneeOptions = (assignee === ROUTE ? routes : shelves).map(
    ({ label, value }) => (
      <Option key={value} value={value}>
        {label}
      </Option>
    )
  );

  const columns = [
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.header.tracking_number'
      }),
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: '30%'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.header.business'
      }),
      dataIndex: 'business',
      key: 'business',
      width: '50%'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.header.action'
      }),
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center'
    }
  ];

  const handleScanningTrackingNumbers = debounce((value) => {
    const scannedTN = value.trim();
    if (isNaN(scannedTN)) {
      notify(
        intl.formatMessage({
          id: 'hubs.dispatch_rto.scan.error_msgs.not_numeric'
        }),
        'error',
        true
      );
    } else if (
      scannedDeliveries.find(
        ({ trackingNumber }) => scannedTN === trackingNumber
      )
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.dispatch_rto.scan.error_msgs.scanned_before'
        }),
        'error',
        true
      );
    } else {
      setScannedQuerySearch(scannedTN);
    }
  }, 500);

  const handleCancelAllScannedTNsClick = () => {
    confirm({
      width: 450,
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.items_list_modal.title'
      }),
      content: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.items_list_modal.content'
      }),
      okText: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.items_list_modal.submit_btn'
      }),
      okType: 'danger',
      cancelText: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.items_list_modal.cancel_btn'
      }),
      onOk() {
        setScannedDeliveries([]);
        setScannedValue('');
      }
    });
  };

  const handleDeleteOneScannedTN = (trackingNumber, index) => {
    confirm({
      width: 450,
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.item_modal.title'
      }),
      content: intl.formatMessage(
        {
          id: 'hubs.dispatch_rto.scan.table.item_modal.content'
        },
        {
          trackingNumber
        }
      ),
      okText: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.item_modal.submit_btn'
      }),
      okType: 'danger',
      cancelText: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.table.item_modal.cancel_btn'
      }),
      onOk() {
        const newScannedDeliveries = [...scannedDeliveries];
        newScannedDeliveries.splice(index, 1);
        setScannedDeliveries(newScannedDeliveries);
      }
    });
  };

  const handleSave = () => {
    if (
      (selectedAssignee || assignee === TRANSFER_TO_HUB) &&
      selectedHub &&
      scannedDeliveries.length
    ) {
      switch (assignee) {
        case ROUTE:
          if (scannedDeliveries.some(isFulfillmentOrder)) {
            notify(
              intl.formatMessage({
                id: 'hubs.dispatch_rto.scan.error_msgs.fulfillment_orders'
              }),
              'error',
              true
            );
            return;
          } else {
            assignDeliveriesToRoute(
              selectedAssignee.value,
              scannedDeliveries.map(({ trackingNumber }) => trackingNumber)
            );
          }
          break;

        case BIN:
          assignDeliveriesToShelf(
            selectedAssignee.value,
            scannedDeliveries.map(({ _id }) => _id)
          );
          break;

        case TRANSFER_TO_HUB:
          transferToSortingFacility(
            scannedDeliveries.map(({ trackingNumber }) => trackingNumber)
          );
          break;

        default:
          break;
      }
      setScannedValue('');
      clearScannedTNsTable();
    }
  };

  const handleCreateNewRoute = () => {
    const modalData = {
      title: intl.formatMessage({
        id: `hubs.dispatch_rto.scan.assignee.route.modal_create_new.title`
      }),
      placeholder: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.assignee.route.modal_create_new.placeholder'
      }),
      okText: intl.formatMessage({
        id: 'common.save'
      }),
      cancelText: intl.formatMessage({
        id: 'common.cancel'
      }),
      errorMsg: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.assignee.route.modal_create_new.errorMsg'
      }),
      successMsg: intl.formatMessage({
        id: 'hubs.dispatch_rto.scan.assignee.route.modal_create_new.successMsg'
      }),
      afterSuccessCallback: fetchRoutes
    };

    openModal(CreateNewRouteModal, modalData);
  };

  useEffect(() => {
    handleScanningTrackingNumbers(scannedValue);
  }, [scannedValue]);

  return (
    <div>
      <div>
        {intl.formatMessage({
          id: 'hubs.dispatch_rto.scan.select_assignee_dropdown.label'
        })}
      </div>
      <Select
        labelInValue
        className="br-dispatch-rto_side-container_right_select-assignee-dropdown"
        placeholder={intl.formatMessage({
          id: 'hubs.dispatch_rto.scan.select_assignee_dropdown.placeholder'
        })}
        value={
          assignee && {
            label: intl.formatMessage({
              id: `hubs.dispatch_rto.scan.select_assignee_dropdown.assignees.${assignee}`
            }),
            value: assignee
          }
        }
        disabled={isLoading}
        onSelect={({ value }) => setAssignee(value)}
      >
        {renderedAssigneeTypeOptions}
      </Select>
      <p className="br-dispatch-rto_side-container_right_shelf-route-text">
        {intl.formatMessage({
          id: 'hubs.dispatch_rto.scan.select_assignee_dropdown.hint'
        })}
      </p>
      {assignee && assignee !== TRANSFER_TO_HUB && (
        <div>
          <Select
            labelInValue
            showSearch
            className="br-dispatch-rto_side-container_right_shelf-route-dropdown"
            placeholder={intl.formatMessage({
              id: `hubs.dispatch_rto.scan.assignee.${assignee}.dropdown_placeholder`
            })}
            value={selectedAssignee}
            optionFilterProp="children"
            disabled={isLoading}
            onSelect={setSelectedAssignee}
            filterOption={(input, { children }) =>
              children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderedAssigneeOptions}
          </Select>
          {assignee === ROUTE && (
            <Button
              className="br-dispatch-rto_side-container_right_create-new-route-btn"
              type="link"
              onClick={handleCreateNewRoute}
            >
              {`+ ${intl.formatMessage({
                id: `hubs.dispatch_rto.scan.assignee.route.extra`
              })}`}
            </Button>
          )}
        </div>
      )}
      <div>
        <div>
          {intl.formatMessage({
            id: 'hubs.dispatch_rto.scan.input_label'
          })}
        </div>
        <Input
          placeholder={intl.formatMessage({
            id: 'hubs.dispatch_rto.scan.input_placeholder'
          })}
          className="br-dispatch-rto_side-container_right_tracking-numbers_input"
          value={scannedValue}
          onChange={({ target: { value } }) => setScannedValue(value)}
          disabled={!selectedHub || isLoading}
        />
      </div>
      <div className="br-dispatch-rto_side-container_right_tracking-numbers_buttons">
        <Button
          disabled={!scannedDeliveries.length}
          onClick={handleCancelAllScannedTNsClick}
        >
          {intl.formatMessage({
            id: 'hubs.dispatch_rto.scan.cancel_btn'
          })}
        </Button>
        <Button
          disabled={
            !scannedDeliveries.length ||
            (!selectedAssignee && assignee !== TRANSFER_TO_HUB)
          }
          onClick={handleSave}
          type="primary"
        >
          {intl.formatMessage({
            id: `hubs.dispatch_rto.scan.submit_btn.${
              assignee === TRANSFER_TO_HUB ? 'next' : 'save'
            }`
          })}
        </Button>
      </div>
      {!!scannedDeliveries.length && (
        <Table
          className="br-dispatch-rto_side-container_right_tracking-numbers_list"
          title={() =>
            intl.formatMessage(
              {
                id: 'hubs.dispatch_rto.scan.table.title'
              },
              {
                count: scannedDeliveries.length
              }
            )
          }
          columns={columns}
          dataSource={scannedDeliveries.map(
            ({ trackingNumber, sender: { name }, type, oldType }, index) => ({
              key: trackingNumber,
              trackingNumber: (
                <>
                  {trackingNumber}
                  {isFulfillmentOrder({ type, oldType }) && (
                    <span className="fulfillment-orders__flag">
                      {intl.formatMessage({
                        id: 'fulfillment.fulfillment_flag'
                      })}
                    </span>
                  )}
                </>
              ),
              business: name,
              action: (
                <Button
                  type="text"
                  className="br-dispatch-rto_side-container_right_tracking-numbers_list_item-btn"
                  icon={<Icon component={Trash} />}
                  onClick={() =>
                    handleDeleteOneScannedTN(trackingNumber, index)
                  }
                />
              )
            })
          )}
          pagination={false}
          scroll={{ y: 120 }}
          size="small"
        />
      )}
    </div>
  );
};

export default injectIntl(RTOScan);
