import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Image } from 'antd';
import dayjs from 'dayjs';
import classnames from 'classnames';

import { getItemInfoDetails } from 'services/international-shipping-in';

import Container from 'components/Container';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRHeader from 'components/BRHeader/BRHeader';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import { notify } from 'components/Notify/Notify';

import './InternationalItemDetails.less';

const InternationalItemDetails = ({ intl, match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [orderInfo, setOrderInfo] = useState({});
  const [providerOrderInfo, setProviderOrderInfo] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [internationalAddressInfo, setInternationalAddressInfo] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [item, setItem] = useState({});
  useEffect(() => {
    const itemId = match?.params?.id;
    getItemInfo(itemId);
  }, []);
  const STATUS_MAPPING = {
    arrived_at_us_office: 'Arrived at USA office',
    in_transit_to_cairo: 'In transit to Egypt',
    arrived_at_cairo_office: 'Arrived to Egypt',
    in_progress: 'In Progress',
    out_for_delivery: 'Out for Delivery',
    delivered: 'Delivered'
  };
  const getItemInfo = async (itemId) => {
    setIsLoading(true);
    try {
      const { data } = await getItemInfoDetails(itemId);
      setItem(data);
      setItemInfo({
        providerItemId: data.providerItemId,
        providerPackageId: data.providerPackageId,
        total: data.providerPrice,
        status: STATUS_MAPPING[data.status] || 'N/A',
        lastMileTrackingNumber: data.lastMileTrackingNumber || 'N/A',
        lastMileShipmentTrackingUrl: data.lastMileShipmentTrackingUrl,
        createdAt: data.createdAt,
        imageLink: data?.extra?.photo
      });
      setOrderInfo({
        providerOrderId: data?.paymentOrder?.providerOrderId,
        subtotal: data?.paymentOrder?.breakdown?.subtotal
          ? `${data?.paymentOrder?.breakdown?.subtotal} ${data?.paymentOrder?.currency}`
          : 'N/A',
        delivery: data?.paymentOrder?.breakdown?.delivery
          ? `${data?.paymentOrder?.breakdown?.delivery} ${data?.paymentOrder?.currency}`
          : 'N/A',
        total: data?.paymentOrder?.breakdown?.total
          ? `${data?.paymentOrder?.breakdown?.total} ${data?.paymentOrder?.currency}`
          : 'N/A',
        adjustments: data?.paymentOrder?.breakdown?.adjustments
          ? `${data?.paymentOrder?.breakdown?.adjustments} ${data?.paymentOrder?.currency}`
          : 'N/A',
        items: data?.paymentOrder?.items.length || 'N/A',
        createdAt: data?.paymentOrder?.createdAt || 'N/A'
      });
      data?.providerOrder &&
        setProviderOrderInfo({
          completedAt: data?.providerOrder?.completedAt,
          courierName: data?.providerOrder?.courierName || 'N/A',
          delivery: data?.providerOrder?.delivery
            ? `${data?.providerOrder?.delivery} ${data?.paymentOrder?.currency}`
            : 'N/A',
          id: data?.providerOrder?.id,
          outForDeliveryAt: data?.providerOrder?.outForDeliveryAt,
          placedAt: data?.providerOrder?.placedAt,
          subtotal: data?.providerOrder?.subtotal
            ? `${data?.providerOrder?.subtotal} ${data?.paymentOrder?.currency}`
            : 'N/A',
          total: data?.providerOrder?.total
            ? `${data?.providerOrder?.total} ${data?.paymentOrder?.currency}`
            : 'N/A',
          totalDue: data?.providerOrder?.totalDue
            ? `${data?.providerOrder?.totalDue} ${data?.paymentOrder?.currency}`
            : 'N/A'
        });
      setBusinessInfo({
        name: data?.businessInfo?.name || 'N/A',
        email: data?.businessInfo?.email || 'N/A',
        phone: data?.businessInfo?.phone || 'N/A',
        businessId: data?.businessInfo?._id || 'N/A'
      });
      setInternationalAddressInfo({
        city: data?.inventory?.city || 'N/A',
        country: data?.inventory?.country || 'N/A',
        firstLine: data?.inventory?.firstLine || 'N/A',
        secondLine: data?.inventory?.secondLine || 'N/A',
        state: data?.inventory?.state || 'N/A',
        zipCode: data?.inventory?.zipCode || 'N/A',
        phone: data?.inventory?.phone || 'N/A',
        area: data?.inventory?.area || 'N/A'
      });
      setShippingAddress({
        firstLine: data?.shippingAddress?.address?.firstLine || 'N/A',
        area: data?.shippingAddress?.address?.district?.name
          ? data?.shippingAddress?.address?.zone?.name
            ? `${data?.shippingAddress?.address?.district?.name} - ${data?.shippingAddress?.address?.zone?.name}`
            : data?.shippingAddress?.address?.district?.name
          : 'N/A',
        city: data?.shippingAddress?.address?.city?.name || 'N/A',
        secondLine: data?.shippingAddress?.address?.secondLine || 'N/A',
        floor: data?.shippingAddress?.address?.floor || 'N/A',
        building: data?.shippingAddress?.address?.building || 'N/A'
      });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const itemInfoColumn = [
    {
      key: 'providerItemId',
      label: 'Item ID'
    },
    {
      key: 'providerPackageId',
      label: 'Package ID'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'lastMileTrackingNumber',
      label: 'Last Mile Tracking Number '
    },
    {
      key: 'lastMileShipmentTrackingUrl',
      label: 'Last Mile Tracking Url ',
      render: (trackingUrl) =>
        trackingUrl ? (
          <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
            Click Here
          </a>
        ) : (
          'N/A'
        )
    },
    {
      key: 'total',
      label: 'Total',
      render: (total) => (total ? `${total} EGP` : 'N/A')
    },

    {
      key: 'createdAt',
      label: 'Created at',
      render: (date) =>
        date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
    },
    {
      key: 'imageLink',
      label: 'Image',
      render: (imageLink) => <Image src={imageLink} alt="" width={100} />
    }
  ];

  const businessInfoColumns = [
    {
      key: 'name',
      label: 'Business Name'
    },
    {
      key: 'phone',
      label: 'Phone'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'businessId',
      label: 'Business ID',
      render: (id) => (
        <a href={`/businesses/${id}/details`} target="_blank">
          {id}
        </a>
      )
    }
  ];

  const internationalAddressColumns = [
    {
      key: 'country',
      label: 'Country'
    },
    {
      key: 'city',
      label: 'City'
    },
    {
      key: 'area',
      label: 'Area'
    },
    {
      key: 'firstLine',
      label: 'Street Name'
    },
    {
      key: 'secondLine',
      label: 'Second Line'
    },
    {
      key: 'state',
      label: 'State'
    },
    {
      key: 'zipCode',
      label: 'Zip Code'
    },
    {
      key: 'phone',
      label: 'Phone'
    }
  ];

  const shippingAddressColumns = [
    {
      key: 'firstLine',
      label: 'Street name'
    },

    {
      key: 'area',
      label: 'Area'
    },
    {
      key: 'city',
      label: 'City'
    },

    {
      key: 'building',
      label: 'Building'
    },
    {
      key: 'floor',
      label: 'Floor'
    },
    {
      key: 'secondLine',
      label: 'Landmark'
    }
  ];

  const orderInfoColumns = [
    {
      key: 'providerOrderId',
      label: 'Order ID',
      render: (orderId) =>
        orderId ? (
          <a href={`/box/order/${item?.paymentOrder?._id}`} target="_blank">
            {orderId}
          </a>
        ) : (
          'N/A'
        )
    },

    {
      key: 'items',
      label: 'Items count'
    },
    {
      key: 'createdAt',
      label: 'Created at',
      render: (date) =>
        date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
    },
    {
      key: 'subtotal',
      label: 'Subtotal'
    },
    {
      key: 'delivery',
      label: 'Delivery'
    },
    {
      label: 'Adjustments',
      key: 'adjustments',
      render: (value) =>
        value === 'N/A' ? (
          value
        ) : (
          <div>
            <span className="br-item-details__tag-value">{value}</span>
            <span
              className={classnames(
                { 'br-item-details__fees-tag': value > 0 },
                {
                  'br-item-details__discount-tag': value < 0
                },
                'br-item-details__tag'
              )}
            >
              {value > 0 ? 'Fees' : 'Discount'}
            </span>
          </div>
        )
    },
    {
      key: 'total',
      label: 'Total'
    }
  ];

  const providerOrderInfoColumns = [
    { label: 'ID', key: 'id' },
    {
      key: 'placedAt',
      label: 'Placed at',
      render: (date) =>
        date !== 'N/A' ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
    },
    {
      key: 'outForDeliveryAt',
      label: 'Out for delivery at',
      render: (date) =>
        date !== 'N/A' ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
    },
    { key: 'courierName', label: 'Courier Name' },
    { key: 'delivery', label: 'Delivery' },
    { key: 'subtotal', label: 'Subtotal' },
    { key: 'total', label: 'total' },
  ];

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'international_shipping.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-international__address-container">
            <p className="br-iitem-details__header">
              Item : {itemInfo.providerItemId}
            </p>
            <div className="br-completed-order-items__container">
              <span>Item Info</span>
              <BRInformationTable
                cells={itemInfoColumn}
                totalCol={3}
                dataSource={itemInfo}
              />
            </div>
            <div className="br-completed-order-items__container">
              <span>Paymob Order Info</span>
              <BRInformationTable
                cells={orderInfoColumns}
                totalCol={3}
                dataSource={orderInfo}
              />
            </div>
            {Object.keys(providerOrderInfo).length > 0 && (
              <div className="br-completed-order-items__container">
                <span>ExoHub Order Info</span>
                <BRInformationTable
                  cells={providerOrderInfoColumns}
                  totalCol={3}
                  dataSource={providerOrderInfo}
                />
              </div>
            )}
            <div className="br-completed-order-items__container">
              <span>Business Info</span>
              <BRInformationTable
                cells={businessInfoColumns}
                totalCol={4}
                dataSource={businessInfo}
              />
            </div>
            <div className="br-completed-order-items__container">
              <span>International Address</span>
              <BRInformationTable
                cells={internationalAddressColumns}
                totalCol={4}
                dataSource={internationalAddressInfo}
              />
            </div>
            <div className="br-completed-order-items__container">
              <span>Address Details</span>
              <BRInformationTable
                cells={shippingAddressColumns}
                totalCol={3}
                dataSource={shippingAddress}
              />
            </div>
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(InternationalItemDetails));
