import React, { useState, useEffect } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { Table } from 'antd';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { uploadArReport } from '../../actions';
import { notify } from 'components/Notify/Notify'
import styles from './styles.module.scss';

const Upload = ({ setUploadModal, reportId, uploadArReport, uploadCSV }) => {
  const [rows, setRows] = useState([]);
  const [tableCol, setTableCol] = useState([]);
  const [tableRow, setTableRow] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handlePreviewData = allRows => {
    let tableColumns = [];
    const tableRows = [];
    let tableRowTemp = {};
    tableColumns = allRows[0].map(row => {
      return {
        dataIndex: _.camelCase(row),
        title: row,
      };
    });
    setTableCol(tableColumns);
    for (let i = 1; i < allRows.length; i++) {
      for (let j = 0; j < allRows[i].length; j++) {
        tableRowTemp[tableColumns[j].dataIndex] =
          allRows[i][j] === undefined ? '' : allRows[i][j];
      }
      tableRows.push(tableRowTemp);
      tableRowTemp = {};
    }
    setTableRow(tableRows);
  };

  const fileHandler = e => {
    const file = e.target.files[0];
    if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv'
    ) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          notify(err);
        } else {
          const checkedRows = [...resp.rows];
          checkedRows.shift();
          let noRecord = false;
          const emptyRecord = {};
          const reportIdIndex = resp.rows[0].findIndex(
            row => row === 'Report Number' || row === 'Report ID',
          );
          const paymentTypeIndex = resp.rows[0].findIndex(
            row => row === 'Payment Type',
          );
          const trackingNumberIndex = resp.rows[0].findIndex(
            row => row === 'Tracking Number',
          );
          checkedRows.forEach((item, index) => {
            if (
              item[reportIdIndex] === ' ' ||
              item[reportIdIndex] === undefined
            ) {
              emptyRecord['Report ID'] = index + 1;
            }
            if (
              item[trackingNumberIndex] === ' ' ||
              item[trackingNumberIndex] === undefined
            ) {
              emptyRecord['Tracking Number'] = index + 1;
            }
            if (
              item[paymentTypeIndex] === ' ' ||
              item[paymentTypeIndex] === undefined
            ) {
              emptyRecord['Payment Type'] = index + 1;
            }
            if (
              item[reportIdIndex] !== '' &&
              item[reportIdIndex] !== undefined &&
              item[reportIdIndex] !== reportId.toString()
            ) {
              noRecord = true;
              return null;
            }
          });
          if (noRecord) {
            notify(
              'The file you’re uploading doesn’t match the selected AR report. Please try uploading another file.',
            );
            // @TODO: Be reviewed by Hassam or Abd-EL-Hay
          } else if (!_.isEmpty(emptyRecord)) {
            for (const [key, value] of Object.entries(emptyRecord)) {
              notify(
                `${key} is empty on Row No. ${value}, ${key} should not be empty in any of the report records.`,
              );
            }
          } else {
            setIsOpen(true);
            setRows(resp.rows);
            handlePreviewData(resp.rows);
          }
        }
      });
    } else {
      notify('Please Upload Xlsx file');
    }
  };

  const handleUploadSheet = () => {
    const records = [];
    let temp = {};
    for (let i = 1; i < rows.length; i++) {
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[0][j] === 'Report ID' || rows[0][j] === 'Report Number') {
          temp.reportNumber = rows[i][j];
        } else if (
          rows[0][j] === 'Tracking Number' ||
          rows[0][j] === 'Discount Notes'
        ) {
          temp[`${_.camelCase(rows[0][j])}`] = rows[i][j];
        } else if (
          rows[0][j] === 'Shipping Fees' ||
          rows[0][j] === 'Bulky Fees' ||
          rows[0][j] === 'Out of Flier Fees' ||
          rows[0][j] === 'Discount Amount'
        ) {
          temp[`${_.camelCase(rows[0][j])}`] = Number(rows[i][j]);
        } else if (rows[0][j] === 'Gross COD') {
          temp.grossCOD = Number(rows[i][j]);
        } else if (rows[0][j] === 'VAT') {
          temp.VAT = Number(rows[i][j]);
        } else if (rows[0][j] === 'Payment Type') {
          temp.paymentType = rows[i][j].toUpperCase().replace(' ', '_');
        } else continue;
      }
      records.push(temp);
      temp = {};
    }
    uploadArReport(records);
  };

  useEffect(() => {
    if (uploadCSV) {
      setIsOpen(false);
      setUploadModal(false);
      setConfirmModal(false);
    }
  }, [uploadCSV]);

  return (
    <>
      <input
        type="file"
        onChange={e => fileHandler(e)}
        className={styles.fileUpload}
      />
      <Modal size="lg" show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Preview Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'scroll' }}>
          <Table
            dataSource={tableRow.length > 0 ? tableRow : []}
            columns={tableCol}
            pagination={{
              pageSize: 100,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => setConfirmModal(true)}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to upload this file?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setConfirmModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleUploadSheet()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  uploadArReport: data => dispatch(uploadArReport(data)),
});

const mapStateToProps = ({ codAR }) => ({
  uploadCSV: codAR.uploadCSV,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Upload));
