import { Tag } from 'antd';
import { injectIntl } from 'react-intl';

import './ReturnManifestHeader.less';

const ReturnManifestHeader = ({ returnManifestId, stateInfo, intl }) => {
  return (
    <div className="br-return-manifest__header-container display-flex align-center">
      <div className="br-return-manifest__header-title">
        {intl.formatMessage({ id: 'return_manifest.return_manifest_id' })}:{' '}
        {returnManifestId}
      </div>
      <div className="br-return-manifest__header-state">
        <Tag className={stateInfo.stateColor}>{stateInfo.stateName}</Tag>
      </div>
    </div>
  );
};

export default injectIntl(ReturnManifestHeader);
