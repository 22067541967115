import { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Dropdown, Menu, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';

import {
  FORMAT_DATE_TO_UTC,
  FORMAT_DATE_TO_UTC_END_OF_DAY
} from 'utils/helpers';
import { STAR_MAP_DEFAULT_DATE_FORMAT } from 'constants/stars';

import { ReactComponent as CalenderIcon } from 'assets/imgRevamp/calender-icon.svg';

import './StarLogsDateFilter.less';

const StarLogsDateFilter = ({ intl, setDateFilter }) => {
  const [selectedDateString, setSelectedDateString] = useState(
    `${dayjs()
      .subtract(2, 'w')
      .add(1, 'd')
      .format(STAR_MAP_DEFAULT_DATE_FORMAT)} - ${dayjs().format(
      STAR_MAP_DEFAULT_DATE_FORMAT
    )}`
  );
  const dateMenu = () => (
    <Menu className="br-star-map-filter__date-menu">
      <span>
        <DatePicker.RangePicker
          onChange={onDateChange}
          disabledDate={disabledDate}
          defaultValue={[dayjs().subtract(2, 'w').add(1, 'd'), dayjs()]}
        />
      </span>
    </Menu>
  );

  const disabledDate = (current) => {
    const dateFrom = dayjs().subtract(2, 'w');
    return (
      current < dayjs(dateFrom, STAR_MAP_DEFAULT_DATE_FORMAT) ||
      current > dayjs()
    );
  };

  const onDateChange = (momentDate, stringDate) => {
    if (momentDate?.length) {
      setDateFilter({
        createdAtStart: stringDate[0],
        createdAtEnd: stringDate[1]
      });
      setSelectedDateString(`${stringDate[0]} - ${stringDate[1]}`);
    } else {
      setDateFilter({
        value: {
          createdAtStart: FORMAT_DATE_TO_UTC(
            dayjs()
              .subtract(2, 'w')
              .add(1, 'd')
              .format(STAR_MAP_DEFAULT_DATE_FORMAT)
          ),
          createdAtEnd: FORMAT_DATE_TO_UTC_END_OF_DAY(dayjs())
        }
      });
      setSelectedDateString(
        `${dayjs()
          .subtract(2, 'w')
          .add(1, 'd')
          .format(STAR_MAP_DEFAULT_DATE_FORMAT)} - ${dayjs().format(
          STAR_MAP_DEFAULT_DATE_FORMAT
        )}`
      );
    }
  };

  return (
    <div className="br-star-logs-date-filter">
      <Dropdown overlay={dateMenu} trigger="click">
        <Button className="br-order-actions-filter__trigger">
          <CalenderIcon className="br-order-actions-filter__trigger-icon" />
          {selectedDateString}
        </Button>
      </Dropdown>
    </div>
  );
};

export default injectIntl(StarLogsDateFilter);
