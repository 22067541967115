import { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';

import {
  ACTION_CENTER_ALL_HUBS_ACCESS,
  ACTION_CENTER_TABS
} from 'constants/action-center';
import { getHubShelves } from 'services/hubs';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { HubsContext } from 'contexts/hub.context';
import { getCurrentUserHubInfo } from 'utils/hubs';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import OrderActions from './components/OrderActions/OrderActions';
import OrderDetails from './components/OrderDetails/OrderDetails';
import { notify } from 'components/Notify/Notify';

import './ActionCenter.less';

const ActionCenter = ({ intl }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [hubBins, setHubBins] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedHubType, setSelectedHubType] = useState(null);
  const [activeTab, setActiveTab] = useState(ACTION_CENTER_TABS.ACTION_CENTER);

  const { scopedHubs } = useContext(HubsContext);

  const formatHubBins = (bins) => {
    if (bins) {
      return bins.map((bin) => ({ label: bin, value: bin }));
    }

    return [];
  };

  const getHubBins = async () => {
    const hubId = selectedHub;
    try {
      if (hubId) {
        const hubShelves = await getHubShelves(hubId);
        setHubBins(formatHubBins(hubShelves?.warehouse?.shelves));
      }
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    const currentUserHub = getCurrentUserHubInfo();

    if (!isAdminGroupUser()) {
      currentUserHub &&
        setSelectedHub(
          scopedHubs.find((hub) => hub._id === currentUserHub._id)?._id
        );
    } else {
      scopedHubs.length === 1 && setSelectedHub(scopedHubs[0]._id);
    }
  }, [scopedHubs]);

  useEffect(() => {
    if (selectedHub) {
      getHubBins();
      setSelectedHubType(
        scopedHubs.find((hub) => hub._id === selectedHub)?.type
      );
    }
  }, [selectedHub]);

  useEffect(() => {
    setSelectedRow(null);
  }, [selectedHub]);

  const handleActionsTableRowClick = (rowData) => {
    setSelectedRow(rowData);
  };

  const handleDataClear = () => {
    setSelectedRow();
  };

  const handleOnTabChange = (value) => {
    setActiveTab(value);
  };

  const renderHubsDropdown = scopedHubs?.map((option, index) => (
    <Select.Option key={index} value={option._id}>
      {option.name}
    </Select.Option>
  ));

  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'action_center.title' })} />
      }
      content={
        <div className="br-action-center-container">
          <div className="br-action-center-container__header">
            <h1 className="br-action-center-container-title">
              {intl.formatMessage({ id: 'action_center.manage_hub_actions' })}
            </h1>
            {isUserAuthorized(ACTION_CENTER_ALL_HUBS_ACCESS) && (
              <div className="br-action-center-hubs-filter">
                <Select
                  showSearch
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'action_center.select_hub_placeholder'
                  })}
                  value={selectedHub}
                  onSelect={setSelectedHub}
                  optionFilterProp="children"
                >
                  {renderHubsDropdown}
                </Select>
              </div>
            )}
          </div>
          <div className="br-action-center__content">
            <OrderActions
              hubBins={hubBins}
              hubId={selectedHub}
              hubType={selectedHubType}
              handleRowClick={handleActionsTableRowClick}
              selectedRowData={selectedRow}
              clearSelectedRow={handleDataClear}
              handleOnTabChange={handleOnTabChange}
            />
            <OrderDetails
              selectedRowData={selectedRow}
              handleDataClear={handleDataClear}
              isRequestsTab={activeTab === ACTION_CENTER_TABS.REQUESTS}
            />
          </div>
        </div>
      }
    />
  );
};

export default injectIntl(ActionCenter);
