import React from 'react';
import { Select, Table, Input, Button, Divider, Tooltip, Switch } from 'antd';
import { injectIntl } from 'react-intl';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/InformationButtonWithoutFilter.svg';

import './PricingTableMobile.less';

const PricingTableMobile = ({ data, intl }) => {
  return (
    <div className="br-pricing-table-mobile__content">
      {data &&
        data.map((item) => (
          <div>
            {item.value ? (
              <div className="br-pricing-table-mobile__sector-name">
                <span>{item.name}</span>
                <span>{item.value}</span>
              </div>
            ) : (
              <div className="br-pricing-table-mobile__list">
                <span className="br-pricing-table-mobile__list-package-size">
                  {item.name}
                </span>
                <div>
                  <div className="br-pricing-mobile__type-prices">
                    <span>
                      {intl.formatMessage({
                        id: 'settings.pricing_plan.table_columns.deliver'
                      })}
                      <Tooltip
                        title={intl.formatMessage({
                          id:
                            'settings.pricing_plan.table_columns.tool_tips.deliver'
                        })}
                        // autoAdjustOverflow={true}
                        // arrowPointAtCenter={true}
                      >
                        <TooltipsIcon />
                      </Tooltip>
                    </span>{' '}
                    <span>{item.deliver}</span>
                  </div>
                  {item.exchange && (
                    <div className="br-pricing-mobile__type-prices">
                      <span>
                        {intl.formatMessage({
                          id: 'settings.pricing_plan.table_columns.exchange'
                        })}{' '}
                        <Tooltip
                          title={intl.formatMessage({
                            id:
                              'settings.pricing_plan.table_columns.tool_tips.exchange'
                          })}
                          autoAdjustOverflow={true}
                          arrowPointAtCenter={true}
                        >
                          <TooltipsIcon />
                        </Tooltip>
                      </span>{' '}
                      <span>{item.exchange}</span>
                    </div>
                  )}
                  {item.return && (
                    <div className="br-pricing-mobile__type-prices">
                      <span>
                        {intl.formatMessage({
                          id: 'settings.pricing_plan.table_columns.return'
                        })}{' '}
                        <Tooltip
                          title={intl.formatMessage({
                            id:
                              'settings.pricing_plan.table_columns.tool_tips.return'
                          })}
                          //   autoAdjustOverflow={true}
                          //   arrowPointAtCenter={true}
                        >
                          <TooltipsIcon />
                        </Tooltip>
                      </span>{' '}
                      <span>{item.return}</span>
                    </div>
                  )}
                  {item.cash_collection && (
                    <div className="br-pricing-mobile__type-prices">
                      <span>
                        {intl.formatMessage({
                          id:
                            'settings.pricing_plan.table_columns.cash_collection'
                        })}{' '}
                        <Tooltip
                          title={intl.formatMessage({
                            id:
                              'settings.pricing_plan.table_columns.tool_tips.cash_collection'
                          })}
                          autoAdjustOverflow={true}
                          arrowPointAtCenter={true}
                        >
                          <TooltipsIcon />
                        </Tooltip>
                      </span>{' '}
                      <span>{item.cash_collection}</span>
                    </div>
                  )}
                  <div className="br-pricing-mobile__type-prices">
                    <span>
                      {intl.formatMessage({
                        id: 'settings.pricing_plan.table_columns.rto'
                      })}{' '}
                      <Tooltip
                        title={intl.formatMessage({
                          id:
                            'settings.pricing_plan.table_columns.tool_tips.rto'
                        })}
                        autoAdjustOverflow={true}
                        arrowPointAtCenter={true}
                      >
                        <TooltipsIcon />
                      </Tooltip>
                    </span>{' '}
                    <span>{item.rto}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default injectIntl(PricingTableMobile);
