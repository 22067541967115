import { injectIntl } from 'react-intl';
import moment from 'moment';

import { isSaudi } from 'constants/helper';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';

import './PriceBreakdownToolTip.less';

const PriceBreakdownToolTip = ({
  pricingInfo,
  isCredit = false,
  intl,
  liabilityFactor = false,
  totalCreditAmount,
  deliveryInfo
}) => {
  const orderInfoBreakDownItems = [
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.order_type'
      }),
      value: MAP_DELIVERY_TYPES[deliveryInfo?.type] || MAP_DELIVERY_TYPES.SEND
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.status'
      }),
      value: deliveryInfo?.state?.value
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.package_size'
      }),
      value:
        deliveryInfo?.pricingPackageSize?.alias === 'XXL'
          ? `${deliveryInfo?.pricingPackageSize?.alias} (${deliveryInfo?.pricingPackageSize?.name})`
          : deliveryInfo?.pricingPackageSize?.name
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.deliverd_at'
      }),
      value: deliveryInfo?.state?.delivering?.time
        ? moment(deliveryInfo?.state?.delivering?.time).format(
            'MMM d, YYYY hh:mm A'
          )
        : 'N/A'
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.confirmed_at'
      }),
      value: deliveryInfo?.confirmedAt || 'N/A'
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.cod'
      }),
      value: `${pricingInfo?.currency} ${deliveryInfo?.cod?.amount || 0}`
    }
  ];

  const breakDownItems = isCredit
    ? [
        {
          label: 'OPS',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.ops
            }
          ),
          amount: pricingInfo.ops
        },
        {
          label: 'Fleet',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.fleet
            }
          ),
          amount: pricingInfo.fleet
        },
        {
          label: 'Experience',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.exp
            }
          ),
          amount: pricingInfo.exp
        },
        {
          label: 'CS',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.cs
            }
          ),
          amount: pricingInfo.cs
        },
        {
          label: 'AM (Gold/Silver)',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.amSilver
            }
          ),
          amount: pricingInfo.amSilver
        },
        {
          label: 'AM (Red)',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.amRed
            }
          ),
          amount: pricingInfo.amRed
        },
        {
          label: 'AM (New Buss. Line)',
          value: intl.formatMessage(
            { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
            {
              cod: pricingInfo.amNew
            }
          ),
          amount: pricingInfo.amNew
        }
      ]
    : [
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.basic_fees'
          }),
          value: `${pricingInfo?.currency} ${
            pricingInfo?.shippingFee - pricingInfo?.size?.cost || 0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.package_size_fees'
          }),
          value: `${pricingInfo?.currency} ${pricingInfo?.size?.cost ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.insurance_fees'
          }),
          value: `${pricingInfo?.currency} ${
            pricingInfo?.insuranceFee?.amount ?? 0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.collection_fees'
          }),
          value: `${pricingInfo?.currency} ${
            (pricingInfo?.extraCodFee?.codAmount ||
              pricingInfo?.extraCodFee?.amount) ??
            0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.next_day_fees'
          }),
          value: `${pricingInfo?.currency} ${
            pricingInfo?.expediteFee?.amount ?? 0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.zero_cod_discount'
          }),
          value: `${pricingInfo?.currency} ${
            pricingInfo?.zeroCodDiscount?.amount ? '-' : ''
          } ${pricingInfo?.zeroCodDiscount?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.promotion_discount'
          }),
          value: `${pricingInfo?.currency} ${
            pricingInfo?.promotion?.amount ? '-' : ''
          }${pricingInfo?.promotion?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.total_before_vat'
          }),
          value: `${pricingInfo?.currency} ${pricingInfo?.priceBeforeVat ?? 0}`
        },
        {
          label: intl.formatMessage(
            {
              id: 'wallet.compensation.amount_tooltip.vat'
            },
            { amount: parseInt(pricingInfo?.vat * 100) || 14 }
          ),
          value: `${pricingInfo?.currency} ${
            Math.round(
              (pricingInfo?.priceAfterVat - pricingInfo?.priceBeforeVat) * 100
            ) / 100 ?? 0
          }`
        }
      ];

  const finalBreakDownItems = deliveryInfo
    ? [...orderInfoBreakDownItems, ...breakDownItems]
    : breakDownItems;

  return (
    <div className="price-breakdown-container">
      {finalBreakDownItems.map(
        (item, id) =>
          ((isCredit && item.amount) || !isCredit) && (
            <div key={id} className="price-breakdown-item-content">
              <span>{item.label}</span>
              <span>{item.value}</span>
            </div>
          )
      )}
      <div className="price-breakdown-total-amount">
        <span>Total {!isCredit && 'After VAT'}</span>
        <span>
          {!isCredit
            ? `${liabilityFactor ? `${liabilityFactor} x ` : ''} ${
                pricingInfo?.currency
              } ${Math.round(pricingInfo?.priceAfterVat * 100) / 100 ?? 0}`
            : `${intl.formatMessage(
                { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
                {
                  cod: totalCreditAmount
                }
              )}`}
        </span>
      </div>
    </div>
  );
};

export default injectIntl(PriceBreakdownToolTip);
