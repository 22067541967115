import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const CountdownTimer = ({ futureDate, showTime }) => {
  const [timeRemaining, setTimeRemaining] = useState('00:00');

  const intl = useIntl();

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const timeDifference = new Date(futureDate) - now;

      if (timeDifference <= 0) {
        setTimeRemaining(0);
      } else {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining(
          showTime
            ? getFormattedTime({ hours, minutes, seconds })
            : intl.formatMessage(
                {
                  id: `common.timer.${
                    hours > 0 ? 'hours' : minutes > 0 ? 'minutes' : 'seconds'
                  }`
                },
                {
                  hours,
                  minutes,
                  seconds
                }
              )
        );
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [futureDate]);

  const getFormattedTime = ({ hours, minutes, seconds }) => {
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return hours > 0
      ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      : `${formattedMinutes}:${formattedSeconds}`;
  };

  return <span>{timeRemaining}</span>;
};

export default CountdownTimer;
