import http from 'utils/http.js';

export const getZonesAndDistricts = (cityId) => {
  return http.get(`/cities/${cityId}/zones-districts`);
};

export const updateHubZone = (zoneId, payload) => {
  return http.put(`/zones/${zoneId}`, payload);
};

export const createHubZone = (payload) => {
  return http.post('/zones', payload);
};

export const deleteHubZone = (zoneId) => {
  return http.delete(`/zones/${zoneId}`);
};

export const getHubZones = (hubId) => {
  return http.get(`/zones/hub/${hubId}`);
};

export const getHubDistricts = (hubId) => {
  return http.get(`/district/hub/${hubId}`);
};

export const getDistrict = (districtId) => {
  return http.get(`/district/${districtId}`);
};

export const updateHubDistrict = (districtId, payload) => {
  return http.put(`/district/${districtId}`, payload);
};

export const createHubDistrict = (payload) => {
  return http.post('/district', payload);
};

export const deleteHubDistrict = (districtId) => {
  return http.delete(`/district/${districtId}`);
};

export const getStreetCodes = (payload) => {
  return http.get('/streetCodes', payload);
};

export const createStreetCode = (payload) => {
  return http.post('/streetCodes', payload);
};

export const updateStreetCode = ({ streetCodeId, payload }) => {
  return http.put(`/streetCodes/${streetCodeId}`, payload);
};

export const deleteStreetCode = (streetCodeId) => {
  return http.delete(`/streetCodes/${streetCodeId}`);
};
