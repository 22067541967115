import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from 'antd';
import classnames from 'classnames';

import {
  PRODUCT_DETAILS_HEADERS,
  PRODUCT_TYPE,
  INVOICING_POLICY,
  CATEGORIES
} from 'constants/fulfillment';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as BostaLogo } from 'assets/imgRevamp/BostaLogo.svg';

import './ProductDetails.less';

function ProductDetails({
  intl,
  productDetailsData,
  showProductDetails,
  setVisibleProductDetails,
  isLoading
}) {
  const [categoryName, setCategoryName] = useState();
  const {
    name,
    virtual_available,
    x_studio_item_description,
    categ_id,
    list_price,
    product_code,
    default_code,
    x_studio_color,
    x_studio_size,
    detailed_type,
    invoice_policy
  } = productDetailsData || [];
  const emptyField = intl.formatMessage({
    id: 'common.empty_field'
  });
  const productDescription = x_studio_item_description || emptyField;

  useEffect(() => {
    const categoryID = categ_id ? categ_id[0] : '';
    const category = CATEGORIES.find(({ id }) => id === categoryID);
    if (category) {
      setCategoryName(category.title);
    }
  }, [productDetailsData]);

  const renderProductDetailsDescription = () => {
    const price = intl.formatMessage(
      {
        id: 'common.egp_amount'
      },
      {
        amount: list_price
      }
    );
    const PRODUCT_DETAILS_CONTENT = [
      price,
      categoryName,
      product_code,
      PRODUCT_TYPE[detailed_type],
      INVOICING_POLICY[invoice_policy],
      default_code
    ];
    return PRODUCT_DETAILS_CONTENT.map((value, index) => {
      return (
        <div>
          <h3 className="br-product-details__header">
            {intl.formatMessage({
              id: `fulfillment.inventory.product_details.${PRODUCT_DETAILS_HEADERS[index]}`
            })}
          </h3>
          <p className={classnames({ 'br-product-details__price': !index })}>
            {value || emptyField}
          </p>
        </div>
      );
    });
  };

  return (
    <Drawer
      title={intl.formatMessage({
        id: 'fulfillment.inventory.product_details.title'
      })}
      placement="right"
      visible={showProductDetails}
      onClose={setVisibleProductDetails}
      className={classnames('br-product-details__drawer-container', {
        'br-product-details__not-active': !showProductDetails
      })}
    >
      <LoadingWrapper loading={isLoading}>
        {!isLoading && (
          <div className="br-product-details__container">
            <div className="br-product-details__description">
              <div>
                <div className="br-product-details__image-container">
                  <div className="br-product-details__image">
                    <BostaLogo />
                  </div>
                </div>

                <div className="br-product-details__product-name">
                  <h3 className="br-product-details__header">
                    {intl.formatMessage({
                      id: `fulfillment.inventory.product_details.product_name`
                    })}
                  </h3>
                  <p>{name || emptyField}</p>
                </div>

                <div>
                  <h3 className="br-product-details__header">
                    {intl.formatMessage({
                      id: `fulfillment.inventory.product_details.product_description`
                    })}
                  </h3>
                  <p className="br-product-details__product-description">
                    {productDescription}
                  </p>
                </div>
              </div>
            </div>

            <div className="br-product-details__additional-content">
              {renderProductDetailsDescription()}
            </div>

            <div className="br-product-details__available">
              <div>
                <h3 className="br-product-details__header">
                  {intl.formatMessage({
                    id: 'fulfillment.inventory.product_details.available_quantity'
                  })}
                </h3>
                <p>{virtual_available}</p>
              </div>

              <div>
                <h3 className="br-product-details__header">
                  {intl.formatMessage({
                    id: 'fulfillment.inventory.product_details.product_color'
                  })}
                </h3>
                <p>{x_studio_color || emptyField}</p>
              </div>

              <div>
                <h3 className="br-product-details__header">
                  {intl.formatMessage({
                    id: 'fulfillment.inventory.product_details.product_size'
                  })}
                </h3>
                <p>{x_studio_size || emptyField}</p>
              </div>
            </div>
          </div>
        )}
      </LoadingWrapper>
    </Drawer>
  );
}

export default injectIntl(ProductDetails);
