import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Select, Input, Form } from 'antd';

import { createAdminGroup } from 'services/roles-permissions';
import { notify } from 'components/Notify/Notify';

const CloneAdminGroupModal = ({ intl, adminGroups, close, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      await createAdminGroup(values);
      notify(
        intl.formatMessage({ id: 'roles_permissions.create_success' }),
        'success'
      );
      close();
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title="Clone Admin Group"
      onCancel={close}
      okText={intl.formatMessage({ id: 'common.create' })}
      okButtonProps={{
        htmlType: 'submit',
        form: 'cloneForm',
        loading: isLoading
      }}
      {...props}
    >
      <Form name="cloneForm" onFinish={handleSubmit}>
        <div className="display-flex flex-dir-col">
          <Form.Item
            label={intl.formatMessage({
              id: 'roles_permissions.new_name'
            })}
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'roles_permissions.admin_group'
              })}
            />
          </Form.Item>
          <Form.Item
            name="cloneName"
            label={intl.formatMessage({ id: 'roles_permissions.admin_group' })}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={intl.formatMessage({
                id: 'roles_permissions.admin_group_placeholder'
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {adminGroups?.map(({ label, _id }) => (
                <Select.Option key={_id} value={label}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(CloneAdminGroupModal);
