import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import {
  AUDIT_ACTION_REPORT_TYPE,
  RESCHEDULED,
  TRANSFER_TO_SORTING,
  UPDATED_DELIVERY_DETAILS
} from 'constants/action-center';
import {
  exportActionsSummary,
  getActionsSummary
} from 'services/action-center';

import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import { notify } from 'components/Notify/Notify';

import './AuditActionsTable.less';

const AuditActionsTable = ({ intl }) => {
  const [searchValue, setSearchValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'audit_actions.table_columns.hub_name' }),
      dataIndex: 'hubName'
    },
    {
      title: intl.formatMessage({
        id: 'audit_actions.table_columns.hub_leader'
      }),
      dataIndex: 'hubLeader',
      render: (hubLeader) => {
        return hubLeader ? (
          <>
            {hubLeader.name}
            <span className="br-audit-actions-hub-leader__phone">
              {hubLeader.phone}
            </span>
          </>
        ) : (
          'N/A'
        );
      }
    },
    {
      title: intl.formatMessage({ id: 'audit_actions.table_columns.one_day' }),
      dataIndex: ['DAY_1'],
      render: (record) => renderTableTooltip(record)
    },
    {
      title: intl.formatMessage({ id: 'audit_actions.table_columns.two_days' }),
      dataIndex: ['DAY_2'],
      render: (record) => renderTableTooltip(record)
    },
    {
      title: intl.formatMessage({
        id: 'audit_actions.table_columns.three_days'
      }),
      dataIndex: ['DAY_3'],
      render: (record) => renderTableTooltip(record)
    },
    {
      title: intl.formatMessage({
        id: 'audit_actions.table_columns.four_days'
      }),
      dataIndex: ['DAY_4'],
      render: (record) => renderTableTooltip(record)
    },
    {
      title: intl.formatMessage({
        id: 'audit_actions.table_columns.five_or_more_days'
      }),
      dataIndex: ['DAY_5'],
      render: (record) => renderTableTooltip(record)
    },
    {
      title: intl.formatMessage({ id: 'audit_actions.table_columns.total' }),
      dataIndex: ['total'],
      render: (record) => renderTableTooltip(record)
    }
  ];

  const renderTableTooltip = (record) => {
    return (
      <Tooltip
        title={
          <div className="br-audit-actions-table-tooltip">
            <div className="br-audit-actions-table-tooltip__item">
              <span className="tooltip__item-title">
                {intl.formatMessage({
                  id: 'audit_actions.order_summary_tooltip.rescheduled'
                })}
              </span>
              <span className="tooltip__item-value">
                {record.filter((item) => item.action === RESCHEDULED).length}
              </span>
            </div>
            <div className="br-audit-actions-table-tooltip__item">
              <span className="tooltip__item-title">
                {intl.formatMessage({
                  id: 'audit_actions.order_summary_tooltip.details_updated'
                })}
              </span>
              <span className="tooltip__item-value">
                {
                  record.filter(
                    (item) =>
                      item.action === UPDATED_DELIVERY_DETAILS ||
                      item.action === TRANSFER_TO_SORTING
                  ).length
                }
              </span>
            </div>
          </div>
        }
        getPopupContainer={(trigger) => trigger.parentElement.parentElement}
      >
        {record.length}
      </Tooltip>
    );
  };

  const getDaysDifference = (endDate, dayDifference) => {
    const current = dayjs().startOf('day');

    return dayDifference === 4
      ? current.diff(endDate, 'days') >= 4
      : current.diff(endDate, 'days') === dayDifference;
  };

  const getActionsDays = (actions) => {
    const actionsSummaryDaysDiff = {
      DAY_1: [],
      DAY_2: [],
      DAY_3: [],
      DAY_4: [],
      DAY_5: []
    };

    actions.map((action) => {
      if (getDaysDifference(action.createdAt, 0)) {
        actionsSummaryDaysDiff.DAY_1.push(action);
      } else if (getDaysDifference(action.createdAt, 1)) {
        actionsSummaryDaysDiff.DAY_2.push(action);
      } else if (getDaysDifference(action.createdAt, 2)) {
        actionsSummaryDaysDiff.DAY_3.push(action);
      } else if (getDaysDifference(action.createdAt, 3)) {
        actionsSummaryDaysDiff.DAY_4.push(action);
      } else {
        actionsSummaryDaysDiff.DAY_5.push(action);
      }
    });

    return actionsSummaryDaysDiff;
  };

  const formatActionsSummaryData = (data) => {
    return data.map((item, index) => {
      const { hubName, contactPerson, actions } = item;
      const actionsDay = getActionsDays(item.actions);
      return {
        _id: index,
        hubName: hubName,
        hubLeader: contactPerson,
        DAY_1: actionsDay.DAY_1,
        DAY_2: actionsDay.DAY_2,
        DAY_3: actionsDay.DAY_3,
        DAY_4: actionsDay.DAY_4,
        DAY_5: actionsDay.DAY_5,
        total: actions
      };
    });
  };

  const getTableData = async () => {
    try {
      const {
        data: { actionsSummary, total }
      } = await getActionsSummary();

      const filteredActionsSummary =
        (searchValue &&
          actionsSummary.filter((item) =>
            item?.hubName
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase() || '')
          )) ||
        actionsSummary;

      return {
        list: formatActionsSummaryData(filteredActionsSummary),
        total: total || 0
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportFile = async () => {
    const payload = {
      reportType: AUDIT_ACTION_REPORT_TYPE
    };
    try {
      const res = await exportActionsSummary(payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
  };

  return (
    <div className="br-audit-actions__table">
      <BRSearchableTable
        columns={columns}
        listFunction={getTableData}
        searchInputPlaceholder={intl.formatMessage({
          id: 'audit_actions.search_for_hub'
        })}
        title={intl.formatMessage({ id: 'audit_actions.all_hubs' })}
        showSearch
        handleTextInput={handleSearchValueChange}
        hidePagination
        withOutCheckBoxes
        exportListFileFunction={handleExportFile}
        onRowClick={() => {}}
      />
    </div>
  );
};

export default injectIntl(AuditActionsTable);
