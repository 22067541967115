import { fmt } from 'IntlWrapper/IntlWrapper';

export const FUNDING_REASONS = [
  { label: fmt({ id: 'borrow.funding_reasons.inventory' }) },
  { label: fmt({ id: 'borrow.funding_reasons.marketing' }) },
  { label: fmt({ id: 'borrow.funding_reasons.salaries' }) },
  { label: fmt({ id: 'borrow.funding_reasons.debt' }) }
];

export const CAPITAL_ROUTES_URL = {
  SETTINGS: '/capital/capital-settings',
  CAPITAL: '/capital/bosta-capital',
  APPLIED: '/capital/bosta-capital/applied',
  DIDNT_APPLY: '/capital/bosta-capital/didnt-apply'
};

const otherCourierOptions = [{ label: 'Yes', value: true }];
const acheivedTargetOptions = [{ label: 'Yes', value: true }];
export const CAPITAL_TABLE_FILTERS = (applied) => {
  return [
    {
      label: fmt({ id: 'capital.filters.funding_amount' }),
      menuType: 'textNumber',
      key: 'fundingAmount',
      maxNumber: 1000000,
      minNumber: 5000
    },
    ...(applied
      ? [
          {
            label: fmt({ id: 'capital.filters.applied_at' }),
            menuType: 'date',
            key: 'appliedAt'
          },
          {
            label: fmt({ id: 'capital.filters.eligability' }),
            menu: acheivedTargetOptions,
            key: 'eligible'
          }
        ]
      : []),
    {
      label: fmt({ id: 'capital.filters.business_id' }),
      menuType: 'text',
      key: 'businessId'
    },
    {
      label: fmt({ id: 'capital.filters.business_email' }),
      menuType: 'text',
      key: 'businessEmail'
    },
    {
      label: fmt({ id: 'capital.filters.business_phone' }),
      menuType: 'text',
      key: 'businessPhone'
    }
  ];
};
