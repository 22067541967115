import { ordersSchema } from 'constants/cod-reports';

const groupErrors = (errors) => {
  const errorObject = {
    'Tracking Number is required!': { fieldName: 'Tracking Number', rows: [] },
    'Credit Invoice Date is required!': {
      fieldName: 'Credit Invoice Date',
      rows: []
    },
    'Credit Invoice Number is required!': {
      fieldName: 'Credit Invoice Number',
      rows: []
    },
    'Value Added Tax Percent is required!': {
      fieldName: 'Value Added Tax Percent',
      rows: []
    },
    'Value Added Tax Amount is required!': {
      fieldName: 'Value Added Tax Amount',
      rows: []
    },
    'Terms Discount Amount is required!': {
      fieldName: 'Terms Discount Amount',
      rows: []
    },
    'Terms Net Days is required!': {
      fieldName: 'Terms Net Days',
      rows: []
    },
    'Terms Net Due Date is required!': {
      fieldName: 'Terms Net Due Date',
      rows: []
    },
    'Remit to country code is required!': {
      fieldName: 'Remit to country code',
      rows: []
    },
    'Remit to postal code is required!': {
      fieldName: 'Remit to postal code',
      rows: []
    },
    'Remit to State Or Province Code is required!': {
      fieldName: 'Remit to State Or Province Code',
      rows: []
    },
    'Remit to city name is required!': {
      fieldName: 'Remit to city name',
      rows: []
    },
    'Remit to addressr is required!': {
      fieldName: 'Remit to addressr',
      rows: []
    },
    'Remit to name is required!': { fieldName: 'Remit to name', rows: [] },
    'Bill to city is required!': { fieldName: 'Bill to city', rows: [] },
    'Bill to Address 2 is required!': {
      fieldName: 'Bill to Address 2',
      rows: []
    },
    'Bill to Address 1 is required!': {
      fieldName: 'Bill to Address 1',
      rows: []
    },
    'Bill to company is required!': { fieldName: 'Bill to company', rows: [] },
    'Bill to name is required!': { fieldName: 'Bill to name', rows: [] },
    'Account Number is required!': { fieldName: 'Account Number', rows: [] },
    'Currency Code is required!': { fieldName: 'Currency Code', rows: [] }
  };

  errors.forEach((error) => {
    const rowNumber = Number(error.path.split('.')[0].slice(1, -1)) + 2;
    errorObject[error.message].rows.push(rowNumber);
  });

  const errorArray = [];
  let keyIndex = 1;
  for (const [key, value] of Object.entries(errorObject)) {
    if (value.rows.length > 0) {
      errorArray.push({
        key: keyIndex++,
        name: value.fieldName,
        issue: key,
        rows: value.rows.join(', ')
      });
    }
  }

  return errorArray;
};

export const validateAmazonTipSheet = (orders) => {
  return new Promise(async (resolve, _) => {
    try {
      await ordersSchema.validate(orders, {
        abortEarly: false
      });
      resolve({ isValid: true });
    } catch (error) {
      resolve({ isValid: false, errors: groupErrors(error.inner) });
    }
  });
};
