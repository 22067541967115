import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH } from 'constants/fulfillment-return-orders';
import { FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH } from 'constants/fulfillment-problematic-jobs';
import { FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH } from 'constants/fulfillment';

import { ReactComponent as HashIcon } from 'assets/bosta-icons/HashIcon.svg';
import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';

import './LinkedOrdersCard.less';

const LinkedOrdersCard = ({ soId, roId, pjIds = [], exchangeSoId }) => {
  const { formatMessage } = useIntl();

  if (!soId && !roId && !pjIds.length && !exchangeSoId) return null;

  const renderItem = ({ title, name, link }) => (
    <div className="br-info-card__item">
      <HashIcon />
      <span>
        {title}
        <Link
          className="br-linked-orders__original-so-link"
          to={link}
          target="_blank"
        >
          {name}
          <ExternalLink />
        </Link>
      </span>
    </div>
  );

  return (
    <div className="br-info-card br-po-section">
      <div className="br-po-section__title">
        {formatMessage({
          id: 'fulfillment_returns.linked_orders_card.title'
        })}
      </div>
      <div className="br-po-section__content">
        {!!pjIds.length && (
          <div className="br-info-card__item">
            <HashIcon />
            <span>
              {formatMessage({
                id: 'fulfillment_returns.linked_orders_card.problematic_job'
              })}

              {pjIds.map((pjId, index) => (
                <>
                  <Link
                    className="br-linked-orders__original-so-link"
                    to={`${FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH}/${pjId}`}
                    target="_blank"
                  >
                    {formatMessage(
                      {
                        id: 'fulfillment_common.pj_id'
                      },
                      {
                        pjId
                      }
                    )}

                    <ExternalLink />
                  </Link>
                  {index !== pjIds.length - 1 ? ', ' : ''}
                </>
              ))}
            </span>
          </div>
        )}
        {roId &&
          renderItem({
            title: formatMessage({
              id: 'fulfillment_returns.linked_orders_card.original_ro'
            }),
            link: `${FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH}/${roId}`,
            name: formatMessage(
              {
                id: 'fulfillment_common.ro_id'
              },
              {
                roId
              }
            )
          })}
        {exchangeSoId &&
          renderItem({
            title: formatMessage({
              id: 'fulfillment_returns.linked_orders_card.exchange_forward'
            }),
            link: `${FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH}/${exchangeSoId}`,
            name: formatMessage(
              {
                id: 'fulfillment_common.so_id'
              },
              {
                soId: exchangeSoId
              }
            )
          })}
        {soId &&
          renderItem({
            title: formatMessage({
              id: 'fulfillment_returns.linked_orders_card.original_so'
            }),
            link: `${FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH}/${soId}`,
            name: formatMessage(
              {
                id: 'fulfillment_common.so_id'
              },
              {
                soId
              }
            )
          })}
      </div>
    </div>
  );
};

export default LinkedOrdersCard;
