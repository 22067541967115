import { put, takeLatest } from "redux-saga/effects";
import actionTypes from "../actions/ActionTypes";
import { getUsersByRole } from "../actions/UsersActions";

/**
 * fetch all users
 * @returns {?}
 */
function* fetchUsersByRole({ data }) {
  const action = data.role === "HUB_LEADER" ? actionTypes.GET_LEADERS : actionTypes.GET_CLERKS;
  let results = [];
  const users = yield getUsersByRole(data);
  if (users) {
    results = users;
  }
  yield put({ type: actionTypes[`${action}_RECEIVED`], payload: results });
}

export default function* usersWatcher() {
  yield takeLatest(actionTypes.GET_LEADERS, fetchUsersByRole);
  yield takeLatest(actionTypes.GET_CLERKS, fetchUsersByRole);
}