import React from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import { omitBy } from 'lodash';
import UploadIcon from '../../../assets/icons/upload_large.svg';
import { notify } from 'components/Notify/Notify'
import styles from './style.module.scss';
import { paymentTypes } from './common';
import { TABS_TYPES } from 'constants/cod-reports';

const handleFileValidation = (file) => {
  const n = file.lastIndexOf('.');
  const result = file.substring(n + 1);

  if (result === 'xlsx') return true;

  notify('Please upload xlsx file');

  return false;
};

const parseFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    // eslint-disable-next-line
    reader.onload = async (evt) => {
      if (handleFileValidation(file.name)) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        if (ws === undefined)
          notify(
            'This is a wrong template, please use the template associated to the page to upload the deliveries'
          );

        const deliveries = XLSX.utils.sheet_to_json(ws, {
          header: 0,
          blankrows: false,
          defval: ''
        });
        resolve(deliveries);
        // reader.readAsBinaryString(file);
      } else {
        reject(new Error('Unsupported file format.'));
      }
    };

    // eslint-disable-next-line func-names
    reader.onabort = function (event) {
      reject(event);
    };
    // eslint-disable-next-line func-names
    reader.onerror = function (event) {
      reject(event);
    };
  });
};

const mapImportedData = (data) => {
  return data
    .filter((el) => Object.keys(omitBy(el, (item) => !item)).length)
    .map((item) => {
      return {
        trackingNumber: item['Tracking Number'].toString(),
        paidAmt: item['Paid Amount'],
        discount: item.Discount || undefined, // optional
        outOfFlierFees: item['Out Of Fliers Fees'] || undefined, // optional
        businessId: item['Business Id'] || undefined, // optional
        paymentType: item['Payment Type'] || 'N/A',
        paymentTypeMapped: paymentTypes[item['Payment Type']]
      };
    });
};

const mapImportedAmzaonData = (data) => {
  return data
    .filter((el) => Object.keys(omitBy(el, (item) => !item)).length)
    .map((item) => {
      return {
        trackingNumber: item['TN'].toString(),
        paidAmt: item['paidAmt']
      };
    });
};

const UploadForm = ({ fileParsed, transactionType }) => {
  const onDrop = (files) => {
    parseFile(files[0]).then((deliveries) => {
      if (transactionType === TABS_TYPES.AMAZON) {
        fileParsed(mapImportedAmzaonData(deliveries));
      } else {
        fileParsed(mapImportedData(deliveries));
      }
    });
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <div className={styles.uploadTransactions}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <img alt="upload icon" src={UploadIcon} className="upload-icon" />
          <p className="upload-p">
            Drag & Drop your .xlsx delivery file here or click to&nbsp;
            <span style={{ color: '#1890ff', textDecoration: 'underline' }}>
              browse
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
