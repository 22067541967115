export const VALIDATE_MESSAGE = 'validateMessage';
export const VALIDATE_CALL = 'validateCall';
export const VALIDATE_EXCEPTION = 'validateException';

export const ENTER_CONSIGNEE_FEEDBACK = 'enterConsigneeFeedback';
export const UNASSIGN_LIVE_OPS = 'unassignToLiveOpsAgent';
export const ASSIGN_LIVE_OPS = 'assignToLiveOpsAgent';
export const CALL = 'call';
export const SMS = 'validate';
export const LIVE_OPS_LIMIT = 50;
export const EXCEPTION_REASONS = [
  { label: 'exception_reason1', value: 1 },
  { label: 'exception_reason2', value: 2 },
  { label: 'exception_reason3', value: 3 },
  { label: 'exception_reason4', value: 4 },
  { label: 'exception_reason5', value: 5 },
  { label: 'exception_reason6', value: 6 },
  { label: 'exception_reason7', value: 7 },
  { label: 'exception_reason8', value: 8 },
  { label: 'exception_reason9', value: 9 },
  { label: 'exception_reason10', value: 10 }
];
export const WHATSAPP_OPTIONS = [
  { name: 'Valid', value: false },
  { name: 'Invalid', value: true },
  { name: 'None', value: 'None' }
];

export const DATE_FORMAT = 'YYYY-MM-DD';
