import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';
import { CaretDownOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';

import { STAR_MAP_DEFAULT_DATE_FORMAT } from 'constants/stars';
import {
  getFlyersDailyBalance,
  getOrdersBalance,
  exportFlyersOrders
} from 'services/packaging';
import { getCurrency } from 'common/countries/countries-mapping';
import { formatKeyName } from 'utils/packaging';
import { FREE_ITEMS_ARRAY, PAID_ITEMS_ARRAY } from 'constants/packaging';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import PackageItemsRow from './components/PackageItemsRow';
import HubPerformanceDateFilter from 'components/BibAnalytics/HubPerformance/HubPerformanceDateFilter/HubPerformanceDateFilter';
import BRTable from 'components/BRTable/BRTable';

import { ReactComponent as CalenderIcon } from 'assets/imgRevamp/calender-icon.svg';
import SmartFlyerImage from 'assets/imgRevamp/ksa_smart_flyer.png';
import SmartBoxImage from 'assets/imgRevamp/ksa_smart_box.png';

import './PackagingBalance.less';

const PackagingBalance = ({ intl }) => {
  const [dateVisible, setDateVisible] = useState(false);
  const [selectedDailyDateString, setSelectedDailyDateString] = useState(
    `Yesterday, ${dayjs().add(-1, 'days').format('D MMMM')}`
  );

  const [selectedDailyDate, setSelectedDailyDate] = useState(
    dayjs().add(-1, 'days')
  );
  const [ordersDateFilter, setOrdersDateFilter] = useState({
    dateFrom: dayjs().format(STAR_MAP_DEFAULT_DATE_FORMAT),
    dateTo: dayjs().format(STAR_MAP_DEFAULT_DATE_FORMAT)
  });

  const [dailyBalanceData, setDailyBalanceData] = useState({});
  const [flyerOrdersAmount, setFlyerOrdersAmount] = useState({});
  const [isLoadingDailyBalance, setIsLoadingDailyBalance] = useState(true);
  const [isLoadingOrdersBalance, setIsLoadingOrdersBalance] = useState(true);
  const [count, setCount] = useState(0);
  const refreshTable = useRef();

  useEffect(() => {
    updateTable();
  }, [ordersDateFilter]);

  useEffect(() => {
    getFlyersBalance();
  }, [selectedDailyDate]);

  const getFlyersBalance = async () => {
    setIsLoadingDailyBalance(true);
    try {
      const { data } = await getFlyersDailyBalance({
        flyerBalanceDate: selectedDailyDate.utc().startOf('day').format()
      });
      setDailyBalanceData(formatFlyersBalance(data));
    } catch (error) {
      notify(error.message);
    }
    setIsLoadingDailyBalance(false);
  };

  const formatFlyersBalance = ({ freeFlyers, paidFlyers }) => {
    let formattedObject = {
      freeItems: [],
      paidItems: []
    };
    freeFlyers.forEach((item) => {
      const keyName = formatKeyName(item.title);
      const selectedItem = FREE_ITEMS_ARRAY.filter(
        (item) => item.key === keyName
      )[0];
      formattedObject.freeItems.push({
        key: keyName,
        title: item.title,
        titleAr: item.title_ar,
        icon: selectedItem?.icon,
        sizeEg: selectedItem?.sizeEg,
        sizeKsa: selectedItem?.sizeKsa,
        count: item.balance || item.sum || 0
      });
    });
    paidFlyers.forEach((item) => {
      const keyName = formatKeyName(item.title);
      const selectedItem = PAID_ITEMS_ARRAY.filter(
        (item) => item.key === keyName
      )[0];
      formattedObject.paidItems.push({
        key: keyName,
        title: item.title,
        titleAr: item.title_ar,
        icon: selectedItem?.icon
          ? selectedItem?.icon
          : item.is_smart && item?.title?.toLowerCase().includes('flyer')
          ? SmartFlyerImage
          : SmartBoxImage,
        sizeEg: selectedItem?.sizeEg || `Size: ${item.size}`,
        sizeKsa: selectedItem?.sizeKsa || `Size: ${item.size}`,
        count: item.balance || item.sum || 0
      });
    });
    return formattedObject;
  };

  const getOrdersBalanceInfo = async ({
    page,
    limit,
    status,
    searchInputText,
    ...props
  }) => {
    setIsLoadingOrdersBalance(true);
    try {
      const { data } = await getOrdersBalance({
        startDate: ordersDateFilter.dateFrom,
        endDate: ordersDateFilter.dateTo,
        status: status?.length ? status[0] : 'All',
        page,
        limit,
        ...(searchInputText && { orderId: searchInputText })
      });
      setFlyerOrdersAmount(formatFlyersBalance(data?.totalRequestedItems));
      setIsLoadingOrdersBalance(false);
      setCount(data?.deliveriesCount || 0);
      return {
        list: formatDeliveries(data?.deliveries || []),
        count: data?.deliveriesCount || 0
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoadingOrdersBalance(false);
    setCount(0);
    return {
      list: [],
      count: 0
    };
  };

  const formatDeliveries = (deliveries = []) => {
    const formattedDeliveries = [];
    deliveries.forEach((delivery) => {
      formattedDeliveries.push({
        _id: delivery._id,
        orderId: delivery.trackingNumber,
        customerInfo: {
          name:
            delivery.receiver?.firstName +
            (!delivery.receiver?.lastName || delivery.receiver?.lastName === '-'
              ? ''
              : delivery.receiver?.lastName),
          phone: delivery.receiver?.phone
        },
        amount: delivery.cod?.amount || 0,
        status:
          delivery.type === 'RTO' && delivery.state?.code === 46
            ? 'Returned'
            : 'Successful'
      });
    });
    return formattedDeliveries;
  };

  const onChange = (date, dateString) => {
    setSelectedDailyDateString(
      date
        ? dateString
        : `Yesterday, ${dayjs().add(-1, 'days').format('D MMMM')}`
    );
    setSelectedDailyDate(date || dayjs().add(-1, 'days'));
  };

  const disabledDates = (current) => {
    return current && current >= dayjs().startOf('day');
  };

  const orderDisabledDates = (current) => {
    return current && current > dayjs();
  };

  const dateMenu = () => (
    <Menu className="br-star-map-filter__date-menu">
      <span onClick={(e) => e.stopPropagation()}>
        <DatePicker
          onChange={onChange}
          showToday={false}
          disabledDate={disabledDates}
          value={selectedDailyDate}
          getPopupContainer={(trigger) =>
            trigger.parentElement.parentElement.parentElement.parentElement
          }
        />
      </span>
    </Menu>
  );

  const COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'packaging.table.order_id'
      }),
      dataIndex: 'orderId',
      render: (orderId) => (
        <span className="br-packaging-balance__order-id-text">{orderId}</span>
      )
    },
    {
      title: intl.formatMessage({
        id: 'packaging.table.customer_info'
      }),
      dataIndex: 'customerInfo',
      render: (customerInfo) => (
        <div className="br-packaging-balance__customer-info-container">
          <span className="body">{customerInfo.name}</span>
          <span className="caption-lg">{customerInfo.phone}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'packaging.table.amount'
      }),
      dataIndex: 'amount',
      render: (amount) =>
        amount ? (
          <div className="br-packaging-balance__amount-container">
            <span className="body">
              {getCurrency().type} {amount}
            </span>
            <span className="caption-lg">
              {intl.formatMessage({
                id: 'orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.cod'
              })}
            </span>
          </div>
        ) : (
          <span className="body">
            {intl.formatMessage({
              id: 'common.no_cash_on_delivery'
            })}
          </span>
        )
    },
    {
      title: intl.formatMessage({
        id: 'packaging.table.status'
      }),
      dataIndex: 'status',
      render: (status) => (
        <span
          className={`br-packaging-balance__status-text ${
            status === 'Returned' ? 'grey' : 'green'
          }`}
        >
          {status}
        </span>
      )
    }
  ];

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  const handleExportClick = async (data, { status, searchInputText }) => {
    try {
      const payload = {
        startDate: ordersDateFilter.dateFrom,
        endDate: ordersDateFilter.dateTo,
        status: status?.length ? status[0] : 'All',
        ...(searchInputText && { orderId: searchInputText })
      };
      const { message } = await exportFlyersOrders(payload);
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const statusOptions = [
    {
      label: intl.formatMessage({
        id: 'packaging.successful'
      }),
      value: 'Succesful'
    },
    {
      label: intl.formatMessage({
        id: 'packaging.return'
      }),
      value: 'Return'
    }
  ];

  const FILTERS = [
    {
      label: 'Status',
      menu: statusOptions,
      key: 'status'
    }
  ];

  return (
    <div className="br-packaging-balance__container">
      <div className="br-packaging-balance__daily-section">
        <span className="display-sm">
          {intl.formatMessage({ id: 'packaging.balance_title' })}
        </span>
        <Dropdown
          overlay={dateMenu}
          trigger="click"
          visible={dateVisible}
          onVisibleChange={(currentVisibility) =>
            setDateVisible(currentVisibility)
          }
          getPopupContainer={(trigger) =>
            trigger.parentElement.parentElement.parentElement.parentElement
          }
        >
          <BRButton
            className="br-order-actions-filter__trigger"
            prefixIcon={
              <CalenderIcon className="br-order-actions-filter__trigger-icon" />
            }
            suffixIcon={<CaretDownOutlined />}
            label={selectedDailyDateString}
          />
        </Dropdown>
      </div>
      <div className="br-packaging-balance__content-container">
        <PackageItemsRow
          title={intl.formatMessage({ id: 'packaging.flyers' })}
          data={dailyBalanceData.freeItems}
          isLoading={isLoadingDailyBalance}
        />
        <PackageItemsRow
          title={intl.formatMessage({ id: 'packaging.paid_items' })}
          freeItems={false}
          data={dailyBalanceData.paidItems}
          isLoading={isLoadingDailyBalance}
        />
      </div>

      <div className="br-packaging-balance__orders-section">
        <span className="display-sm">
          {intl.formatMessage({ id: 'packaging.orders' })}
        </span>
        <HubPerformanceDateFilter
          setDateFilter={setOrdersDateFilter}
          todayDefault
          customDisabledDate={orderDisabledDates}
        />
      </div>

      <div className="br-packaging-balance__orders-content-container">
        <PackageItemsRow
          title={intl.formatMessage({ id: 'packaging.flyers' })}
          isLoading={isLoadingOrdersBalance}
          data={flyerOrdersAmount.freeItems}
        />
        <PackageItemsRow
          title={intl.formatMessage({ id: 'packaging.paid_items' })}
          isLoading={isLoadingOrdersBalance}
          freeItems={false}
          data={flyerOrdersAmount.paidItems}
        />
      </div>
      <BRTable
        searchPlaceholder={intl.formatMessage({
          id: 'packaging.search_placeholder'
        })}
        title={intl.formatMessage(
          { id: 'packaging.table_title' },
          {
            count
          }
        )}
        columns={COLUMNS}
        showSearch
        pageLimit={50}
        pageSizeOptions={[50, 100, 200]}
        listFunction={getOrdersBalanceInfo}
        shareMethods={acceptMethods}
        exportListFileFunction={handleExportClick}
        showExport
        tableFilters={FILTERS}
        isLoading={isLoadingOrdersBalance}
      />
    </div>
  );
};

export default injectIntl(PackagingBalance);
