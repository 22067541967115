import React from 'react';
import { Upload } from 'antd';
import { saveAs } from 'file-saver';

import { useCancellablePromise } from 'utils/promise';
import { openModal } from 'utils/modal';
import { convertExcelBulkDebriefCashToJSON } from 'utils/hubs';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import ConfirmUploadSheetModal from 'components/Wallet/components/BusinessConfiguration/ConfirmUploadSheetModal/ConfirmUploadSheetModal';

import BulkUploadCashDebriefSheet from 'assets/bulk upload debrief cash .xlsx';

import './BulkUploadCash.less';
import { bulkAddCashDebriefRecords } from 'services/hubs';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

const { Dragger } = Upload;

const BulkUploadCash = () => {
  const { cancellablePromise } = useCancellablePromise();

  const handleUpload = async (file) => {
    let payload = null;
    try {
      payload = await cancellablePromise(
        convertExcelBulkDebriefCashToJSON(file)
      );
      openModal(ConfirmUploadSheetModal, {
        onSuccess: () => uploadRecords(payload),
        bodyText: `You will insert ${payload?.length} debreif cash records.`
      });
    } catch (error) {
      if (error.message.includes('Wrong template')) {
        notify('Unsupported Template!');
      } else {
        notify(error.message);
      }
    }
    return false;
  };

  const uploadRecords = async (cashRecords) => {
    try {
      const {
        data: { createdCashRecords = [], rejectedCashRecords = [] }
      } = await bulkAddCashDebriefRecords({ cashRecords });
      if (!rejectedCashRecords.length) {
        notify('All Records is added successfully', 'success');
      } else {
        openModal(ConfirmationModal, {
          title: 'Upload Errors',
          content: (
            <div className="br-bulk-upload-error-reasons__container">
              {!!createdCashRecords?.length && (
                <span>
                  {createdCashRecords?.length} of your debreif cash records is
                  submitted successfully and others got errors while confirming
                  them.
                </span>
              )}
              <span className="body-medium">
                Errors found after processing the sheet:
              </span>
              <ul>
                {rejectedCashRecords?.map((reason, id) => (
                  <li key={id}>{reason?.errorMessage}</li>
                ))}
              </ul>
            </div>
          ),
          showConfirmButton: false,
          cancelButtonLabel: 'Close'
        });
      }
    } catch (error) {
      notify(error.message);
    }
  };
  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: handleUpload
  };

  const handleDownloadTemplateClick = async () => {
    await saveAs(BulkUploadCashDebriefSheet, 'Bulk Upload Cash Debrief.xlsx');
  };

  return (
    <div className="br-bulk-upload-cash__container">
      <Dragger {...uploadProps}>
        <BRButton label={'Upload Debrief Cash Sheet'} type={'primary'} />
      </Dragger>

      <BRButton
        label={'Download Debrief Cash Sheet'}
        onClick={handleDownloadTemplateClick}
      />
    </div>
  );
};

export default BulkUploadCash;
