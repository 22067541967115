import './ReturnManifestInfoCard.less';

const ReturnManifestInfoCard = ({ title, infoData = [] }) => {
  return (
    <div className="br-return-manifest-info-card__container">
      <div className="br-return-manifest-info-card__title">{title}</div>
      <div className="br-return-manifest-info-card__content display-flex">
        {infoData.map((data, index) => (
          <div className="br-return-manifest-info" key={index}>
            <div className="br-return-manifest-info__title">{data.title}</div>
            <div className="br-return-manifest-info__value">{data.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReturnManifestInfoCard;
