import { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  Form,
  Input,
  Select,
  Divider,
  Tooltip,
  InputNumber,
  Checkbox,
  Tag,
  Switch
} from 'antd';
import Icon from '@ant-design/icons';
import { InfoCircleFilled } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import isObject from 'lodash/isObject';

import {
  createPromotion,
  getActivePromotions,
  updatePromotion,
  deletePromotion
} from 'services/promotions';
import {
  editOperationsSettings,
  updateBusiness,
  sandboxApprove,
  getBusiness,
  generateAPIKey,
  updateBusinessConfigurations
} from 'services/business';
import { getInternationalPricingTiers } from 'services/pricing';
import { getAllPriceTier } from 'services/priceTiers';
import { fetchCities, getAreasInfo } from 'services/cities';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import difference from 'utils/business';
import {
  SALES_CHANNEL_OPTIONS,
  INDUSTRY_OPTIONS,
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES_OPTIONS,
  BUSINESS_PAYMENT_OPTIONS,
  BUSINESS_TIERS,
  PAYMENT_FREQUENCY,
  NO_OF_ATTEMPTS,
  PAYMENT_TYPES,
  PICKUP_PROOF_TYPES,
  DEFAULT_CHANGE
} from 'constants/Businesses';
import { PROMOTION_SUBTYPES_MAPPING, DEFAULT_TYPE } from 'constants/promotions';
import {
  PAYMENT_FREQUENCY_SCHEDULE,
  PAYMENT_METHODS,
  BUSINESS_PRICING_FLAGS_CHECKERS
} from 'constants/pricing';
import aclMatrix, {
  SALES,
  SALES_MANAGER,
  SUPER_ADMIN,
  CUSTOMER_SUPPORT,
  ACCOUNTANT,
  COMMERCIAL_MANAGER
} from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { openModal } from 'utils/modal';
import { getFulfillmentTierList } from 'services/fulfillment-pricing';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import { notify } from 'components/Notify/Notify';
import BRArea, { AVAILABILITY } from 'components/BRArea/BRArea';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import AddPromotionModal from 'components/Promotions/components/AddPromotionModal';
import EditPromotionModal from 'components/Promotions/components/EditPromotionModal';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import BRTable from 'components/BRTable/BRTable';
import CreateEditApiKeyModal from '../CreateEditApiKeyModal/CreateEditApiKeyModal';

import { ReactComponent as Trash } from 'assets/imgRevamp/trash.svg';

import './EditBusiness.less';

const DATE_FORMAT = 'DD MMM YYYY  h:mm a';

const EditBusiness = ({
  intl,
  match: {
    params: { id: businessId }
  },
  history
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({});
  const [priceTiers, setPriceTiers] = useState([]);
  const [fulfillmentPriceTiers, setFulfillmentPriceTiers] = useState([]);
  const [internationalTiers, setInternationalTiers] = useState([]);
  const [businessCitiesValue, setBusinessCitiesValue] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [businessDistricts, setBusinessDistricts] = useState([]);
  const [showAddPromotion, setShowAddPromotion] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);

  const formRef = useRef('');
  const promotionsTableRef = useRef();

  const isFinancialUser = isUserAuthorized([
    SUPER_ADMIN,
    ACCOUNTANT,
    COMMERCIAL_MANAGER
  ]);
  const isAccountantUser = isUserAuthorized([SUPER_ADMIN, ACCOUNTANT]);
  const isCommercialUser = isUserAuthorized([
    SUPER_ADMIN,
    SALES,
    SALES_MANAGER
  ]);
  const isCustomerSupportRole = isUserAuthorized([CUSTOMER_SUPPORT]);
  const isCommercialManagerRole = isUserAuthorized([COMMERCIAL_MANAGER]);

  const canEditBusinessDetails = isAdminGroupUser()
    ? isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [ACL_MATRIX.BUSINESS_UPDATE])
    : !isCustomerSupportRole;

  const canEditBusinessLocation = isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
    ACL_MATRIX.BUSINESS_UPDATE_MAIN_LOCATION
  ]);

  const canUpdateWalletInfo = isAdminGroupUser()
    ? isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
        ACL_MATRIX.BUSINESS_UPDATE_WALLET
      ])
    : isFinancialUser || isCommercialUser;

  const canUpdatePickupLocation = isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
    ACL_MATRIX.BUSINESS_UPDATE_PICKUP_LOCATION
  ]);

  const canUpdateAllowToRefund = isAdminGroupUser()
    ? isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
        ACL_MATRIX.BUSINESS_UPDATE_REFUND_OPTION
      ])
    : isFinancialUser;

  const canUpdateSllrPay = isAdminGroupUser()
    ? isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
        ACL_MATRIX.BUSINESS_UPDATE_PRICING
      ])
    : isAccountantUser;

  const PROMOTIONS_TABLE_COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'business_details.promotions.table_columns.value'
      }),
      dataIndex: 'percentage',
      render: (percentage) => `${(percentage * 100).toFixed(2)}%`
    },
    {
      title: intl.formatMessage({
        id: 'business_details.promotions.table_columns.active'
      }),
      dataIndex: 'activeAt',
      render: (date) =>
        date
          ? dayjs(date).tz().format(DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' })
    },
    {
      title: intl.formatMessage({
        id: 'business_details.promotions.table_columns.inactive'
      }),
      dataIndex: 'inactiveAt',
      render: (date) =>
        date
          ? dayjs(date).tz().format(DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' })
    },
    {
      title: intl.formatMessage({
        id: 'business_details.promotions.table_columns.subtype'
      }),
      dataIndex: 'subType',
      render: (subtype) => PROMOTION_SUBTYPES_MAPPING[subtype]
    },
    {
      title: intl.formatMessage({
        id: 'business_details.promotions.table_columns.actions'
      }),
      render: (promotion) => (
        <div className="br-edit-business-container__promotion-actions">
          <Icon
            component={Trash}
            className="ant-icon-md"
            onClick={() => {
              handleDeletePromotionClick(promotion._id);
            }}
          />
          <BRButton
            onClick={() => {
              handleEditPromotionClick(promotion);
            }}
            label={intl.formatMessage({
              id: 'common.edit'
            })}
          />
        </div>
      )
    }
  ];

  const addPromotion = async ({
    subType,
    activeAt,
    inactiveAt,
    minVolumeCap,
    maxVolumeCap,
    volumeCapIntervalInDays,
    value,
    valueType,
    target,
    percentage
  }) => {
    try {
      await createPromotion({
        businessId,
        activeAt,
        inactiveAt,
        value,
        valueType,
        minVolumeCap,
        maxVolumeCap,
        volumeCapIntervalInDays,
        type: DEFAULT_TYPE,
        subType,
        target,
        percentage
      });
      promotionsTableRef.current();
    } catch (error) {
      notify(error.message);
    }
  };

  const editPromotion = async ({
    promotionId,
    activeAt,
    inactiveAt,
    minVolumeCap,
    maxVolumeCap,
    volumeCapIntervalInDays,
    value,
    valueType,
    percentage
  }) => {
    try {
      await updatePromotion({
        promotionId,
        activeAt,
        inactiveAt,
        minVolumeCap,
        maxVolumeCap,
        volumeCapIntervalInDays,
        value,
        valueType,
        percentage
      });
      promotionsTableRef.current();
    } catch (error) {
      notify(error.message);
    }
  };

  const removePromotion = async (promotionId) => {
    try {
      await deletePromotion({ promotionId });
      promotionsTableRef.current();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleCreatePromotionClick = () => {
    openModal(AddPromotionModal, { onSuccess: addPromotion });
  };

  const handleEditPromotionClick = (promotion) => {
    openModal(EditPromotionModal, { onSuccess: editPromotion, promotion });
  };

  const handleDeletePromotionClick = (promotionId) => {
    openModal(BRConfirmationModal, {
      title: intl.formatMessage({
        id: 'business_details.promotions.delete_modal.title'
      }),
      message: intl.formatMessage({
        id: 'business_details.promotions.delete_modal.message'
      }),
      onConfirm: () => {
        removePromotion(promotionId);
      }
    });
  };

  const handleOnChangeCity = async (cityID) => {
    formRef.current.setFieldsValue({ address: { district: { _id: null } } });
    try {
      const areas = await getAreasInfo(cityID);
      setBusinessDistricts(areas);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleChangeDefaultLocationCity = async (cityID) => {
    formRef.current.setFieldsValue({
      defaultPickupLocation: { address: { district: { _id: null } } }
    });
    try {
      const areas = await getAreasInfo(cityID);
      setDistricts(areas);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleInitialCashoutFrequency = (
    businessPaymentFrequency,
    businessPaymentSchedule
  ) => {
    const values = Object.values(PAYMENT_FREQUENCY_SCHEDULE);
    const initialCashoutFrequency = values.findIndex(
      ({ paymentFrequency, paymentSchedule }) =>
        paymentFrequency === businessPaymentFrequency &&
        isEqual(paymentSchedule, businessPaymentSchedule.sort())
    );
    formRef.current.setFieldsValue({
      walletPaymentInfo: { paymentSchedule: initialCashoutFrequency }
    });
  };

  const handleOnPaymentFrequencyChange = (index) => {
    const selectedFrequency = PAYMENT_FREQUENCY_SCHEDULE[index];
    const selectedPricingFlag = formRef.current.getFieldValue(
      'pricingFlagsOptions'
    );
    if (selectedFrequency.paymentFrequency === PAYMENT_FREQUENCY.NEXT_DAY) {
      formRef.current.setFieldsValue({
        pricingFlagsOptions: [...selectedPricingFlag, 'isExpediteFeesApplied']
      });
    }
  };

  const handleInternationalCashoutChange = (index) => {
    const { paymentFrequency, paymentSchedule, label } =
      PAYMENT_FREQUENCY_SCHEDULE[index];
    formRef.current.setFieldsValue({
      internationalPaymentInfo: {
        paymentFrequency,
        paymentSchedule,
        label
      }
    });
  };

  const handleInitialPricingConfig = (businessInfo) => {
    const {
      pricingFlags,
      paymentInfo: { paymentFrequency, paymentSchedule, paymentTransferMethod }
    } = businessInfo;
    let initialPricingFlags = { ...pricingFlags };
    Object.keys(initialPricingFlags).forEach((key) => {
      if (!initialPricingFlags[key]) {
        delete initialPricingFlags[key];
      }
    });
    formRef.current.setFieldsValue({
      pricingFlagsOptions: Object.keys(initialPricingFlags)
    });
    handleInitialCashoutFrequency(paymentFrequency, paymentSchedule);
  };

  const handleChangePricingFlag = (checkedValues) => {
    const result = BUSINESS_PRICING_FLAGS_CHECKERS.reduce(
      (previousValue, { value }) => {
        return { ...previousValue, [value]: checkedValues.includes(value) };
      },
      {}
    );
    return result;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const businessInfo = await getBusiness(businessId);
      setBusinessInfo(businessInfo);
      const cities = await fetchCities();
      setBusinessCitiesValue(cities);

      if (
        isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
          ACL_MATRIX.PRICING_TIERS_LIST
        ])
      ) {
        const response = await getAllPriceTier();
        setPriceTiers(response.data.tiers);
        const {
          data: { fulfillmentTiers }
        } = await getFulfillmentTierList();
        setFulfillmentPriceTiers(fulfillmentTiers);
      }

      let districts = [];
      if (businessInfo?.address && businessInfo?.address[0]?.city?._id) {
        districts = await getAreasInfo(businessInfo.address[0].city._id);
        setBusinessDistricts(districts);
      }
      if (businessInfo?.defaultPickupLocation?.address) {
        if (
          businessInfo?.defaultPickupLocation?.address?.city?._id ===
          businessInfo.address[0].city?._id
        ) {
          setDistricts(districts);
        } else {
          const defaultPickupLocationDistricts = await getAreasInfo(
            businessInfo?.defaultPickupLocation?.address?.city?._id
          );
          setDistricts(defaultPickupLocationDistricts);
        }
      }
      handleInitialPricingConfig(businessInfo);
      formRef.current.setFieldsValue({
        ...businessInfo,
        pricingTierId: businessInfo?.pricingTier?._id,
        address: businessInfo?.address?.length && businessInfo?.address[0]
      });

      handleInternationalPricing(businessInfo);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleInternationalPricing = async (businessInfo) => {
    if (businessInfo?.internationalShippingEnabled) {
      const { paymentFrequency, paymentSchedule, label } =
        PAYMENT_FREQUENCY_SCHEDULE.find(
          (item) =>
            item.paymentSchedule.toString() ===
              businessInfo?.internationalPaymentInfo?.paymentSchedule?.toString() &&
            item.paymentFrequency ===
              businessInfo?.internationalPaymentInfo?.paymentFrequency
        );
      formRef.current.setFieldsValue({
        internationalPricingTier_id:
          businessInfo?.internationalPricingTier?._id,
        internationalPricingTier_name:
          businessInfo?.internationalPricingTier?.name,
        internationalPaymentInfo: {
          paymentFrequency,
          paymentSchedule,
          label
        }
      });
      try {
        const { internationalTiers } = await getInternationalPricingTiers();
        let tiers = internationalTiers.map((itm) => ({
          value: itm?._id,
          label: itm?.name
        }));
        setInternationalTiers(tiers);
      } catch (error) {
        notify(error.message);
      }
    }
  };

  const onChangeFulfillmentPricingPlan = (tierId) => {
    // const tier = fulfillmentPriceTiers.find(({ _id }) => _id === tierId);
    // formRef.current.setFieldsValue({
    //   fulfillmentPricingTierId:tierId
    // });
  };

  const handleOnGenerateNewKey = async (payload) => {
    try {
      const { data } = await generateAPIKey({ businessId, ...payload });
      setBusinessInfo({ ...businessInfo, apiKey: data.key });
      notify(
        intl.formatMessage({
          id: 'business_details.api_generate_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const onApproveLiveIntegrationClick = async () => {
    try {
      await sandboxApprove(businessId, {
        sandboxApproved: true
      });
      setIsApproveDisabled(true);
      notify(
        intl.formatMessage({
          id: 'business_details.api_key_approve'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const getPromotions = async ({ page: pageNumber, limit: pageSize }) => {
    try {
      const {
        data: { promotions, total }
      } = await getActivePromotions({
        businessId,
        pageNumber: pageNumber - 1,
        pageSize
      });
      setShowAddPromotion(total === 0);
      return {
        list: promotions,
        total: total
      };
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [businessId, fetchCities, getBusiness]);

  const walletPaymentTooltip = (
    <Tooltip title="Permission to update is only given to the commercial and finance teams.">
      <InfoCircleFilled />
    </Tooltip>
  );

  const acceptMethods = (refreshMethod) => {
    promotionsTableRef.current = refreshMethod;
  };
  const onFinish = async (values) => {
    const formattedValues = {
      businessUpdatedData: {
        name: values?.name?.trim(),
        arabicName: values?.arabicName?.trim(),
        salesChannel: values.salesChannel,
        industry: values?.industry,
        website: values?.website,
        desc: values?.desc
      },
      walletPaymentInfo: {
        ...values?.paymentInfo,
        paymentTransferMethod:
          values.paymentInfo.paymentTransferMethod &&
          Array.isArray(values.paymentInfo.paymentTransferMethod)
            ? values.paymentInfo.paymentTransferMethod
            : [values.paymentInfo.paymentTransferMethod],
        paymentSchedule:
          values?.walletPaymentInfo &&
          PAYMENT_FREQUENCY_SCHEDULE[values.walletPaymentInfo.paymentSchedule]
            .paymentSchedule,
        paymentFrequency:
          values?.walletPaymentInfo &&
          PAYMENT_FREQUENCY_SCHEDULE[values.walletPaymentInfo.paymentSchedule]
            .paymentFrequency,
        businessType: values?.businessType,
        businessCategory: values?.businessCategory,
        businessTier: values?.businessTier
      },

      internationalPricingTierId: values?.internationalPricingTier_id,

      internationalPaymentInfo: {
        paymentFrequency: values?.internationalPaymentInfo?.paymentFrequency,
        paymentSchedule: values?.internationalPaymentInfo?.paymentSchedule
      },

      defaultPickupLocation: {
        ...values?.defaultPickupLocation,
        address: {
          ...values.defaultPickupLocation.address,
          districtId: values.defaultPickupLocation.address.district._id
        }
      },
      address: { ...businessInfo.address[0], ...values.address },
      pricingFlags: handleChangePricingFlag(values?.pricingFlagsOptions),
      pricingTierId: values.pricingTierId,
      allowedToRefund: values.allowedToRefund,
      fulfillmentPricingTierId: values.fulfillmentPricingTierId
    };

    const dataToCompare = {
      businessUpdatedData: {
        name: businessInfo?.name?.trim(),
        arabicName: businessInfo?.arabicName?.trim(),
        salesChannel: businessInfo?.salesChannel,
        industry: businessInfo?.industry,
        website: businessInfo?.website,
        desc: businessInfo?.desc
      },
      walletPaymentInfo: {
        ...businessInfo.paymentInfo,
        businessType: businessInfo.businessType,
        businessCategory: businessInfo.businessCategory,
        businessTier: businessInfo.businessTier
      },
      internationalPricingTierId: businessInfo?.internationalPricingTier?._id,
      internationalPaymentInfo: {
        paymentFrequency:
          businessInfo?.internationalPaymentInfo?.paymentFrequency,
        paymentSchedule: businessInfo?.internationalPaymentInfo?.paymentSchedule
      },
      address: {
        ...businessInfo.address[0],
        city: { _id: businessInfo.address[0].city._id },
        district: { _id: businessInfo.address[0]?.district?._id }
      },
      defaultPickupLocation: {
        locationName: businessInfo?.defaultPickupLocation?.locationName,
        address: {
          firstLine: businessInfo.defaultPickupLocation.address?.firstLine,
          floor: businessInfo.defaultPickupLocation.address?.floor,
          secondLine: businessInfo.defaultPickupLocation.address?.secondLine,
          apartment: businessInfo.defaultPickupLocation.address?.apartment,
          city: { _id: businessInfo.defaultPickupLocation.address?.city._id },
          district: {
            _id: businessInfo.defaultPickupLocation.address?.district?._id
          },
          districtId: businessInfo.defaultPickupLocation.address?.district?._id
        }
      },
      pricingFlags: businessInfo.pricingFlags,
      pricingTierId: businessInfo.pricingTier._id,
      allowedToRefund: businessInfo.allowedToRefund,
      isOnlinePaymentEnabled: businessInfo.isOnlinePaymentEnabled,
      fulfillmentPricingTierId: businessInfo.fulfillmentPricingTierId
    };

    delete dataToCompare?.walletPaymentInfo?._id;

    const operationalSettingsPayload = {
      numberOfAttempts: values?.numberOfAttempts,
      isRtoOtp: values.isRtoOtp,
      pickupProofType: values?.pickupProofType
    };
    const operationalSettingsDataToCompare = {
      numberOfAttempts: businessInfo?.numberOfAttempts,
      isRtoOtp: businessInfo?.isRtoOtp,
      pickupProofType: businessInfo?.pickupProofType
    };

    const featuresConfigurationsPayload = {
      isOnlinePaymentEnabled: values?.isOnlinePaymentEnabled
    };
    const featuresConfigurationsDataToCompare = {
      isOnlinePaymentEnabled: businessInfo.isOnlinePaymentEnabled
    };

    let payload = {};
    Object.keys(formattedValues).forEach((key) => {
      const diff = difference(formattedValues[key], dataToCompare[key], false);
      if (Object.keys(diff)?.length) {
        if (key === 'defaultPickupLocation') {
          payload = {
            ...payload,
            defaultPickupLocation: {
              ...formattedValues.defaultPickupLocation,
              address: {
                ...formattedValues.defaultPickupLocation.address,
                districtId: values.defaultPickupLocation.address?.district?._id,
                city: undefined,
                district: undefined
              },
              contactPerson: businessInfo.defaultPickupLocation.contactPerson,
              contacts: businessInfo.defaultPickupLocation.contacts,
              _id: businessInfo?.defaultPickupLocation._id
            }
          };
        } else if (key === 'address') {
          payload = {
            ...payload,
            address: [
              {
                ...formattedValues.address,
                district: undefined,
                city: undefined,
                districtId: values.address?.district?._id
              }
            ]
          };
        } else {
          payload = { ...payload, [key]: diff };
        }
      } else if (!isObject(diff)) {
        payload = { ...payload, [key]: diff };
      }
    });
    const operationalSettingsDiffValues = difference(
      operationalSettingsPayload,
      operationalSettingsDataToCompare
    );
    const featuresConfigurationsDiffValues = difference(
      featuresConfigurationsPayload,
      featuresConfigurationsDataToCompare
    );
    let isHavingErrors = false;

    if (Object.keys(payload).length) {
      try {
        isHavingErrors = false;
        await updateBusiness(businessId, payload);
      } catch (error) {
        isHavingErrors = true;
        notify(error.message);
      }
    }

    if (Object.keys(featuresConfigurationsDiffValues).length) {
      try {
        isHavingErrors = false;
        await updateBusinessConfigurations(
          businessId,
          featuresConfigurationsDiffValues
        );
      } catch (error) {
        isHavingErrors = true;
        notify(error.message);
      }
    }

    if (
      Object.keys(operationalSettingsDiffValues).length &&
      isUserAuthorized(
        [SUPER_ADMIN, SALES, SALES_MANAGER],
        [ACL_MATRIX.BUSINESS_CONFIGURE_OPERATIONS_SETTINGS]
      )
    ) {
      try {
        isHavingErrors = false;
        await editOperationsSettings(businessId, operationalSettingsDiffValues);
      } catch (error) {
        isHavingErrors = true;
        notify(error.message);
      }
    }
    !isHavingErrors && history.push(`/businesses/${businessId}/details`);
  };

  return (
    <Container
      className="br-edit-business-container"
      header={<BRHeader title="Edit Business Details" showBackArrow />}
      content={
        <LoadingWrapper loading={isLoading}>
          <Form ref={formRef} onFinish={onFinish}>
            <div className="br-edit-business__business-details-section">
              <span className="br-edit-business__section__title heading">
                {intl.formatMessage({ id: 'business_details.title' })}
              </span>
              <div className="br-form-row">
                <Form.Item
                  name="name"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.placeholders.business_name'
                  })}
                  rules={[
                    {
                      required: true,
                      whitespace: true
                    }
                  ]}
                >
                  <Input
                    disabled={!canEditBusinessDetails}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.placeholders.business_name'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="arabicName"
                  rules={[
                    {
                      whitespace: true,
                      label: intl.formatMessage({
                        id: 'business_details.business_info.placeholders.business_arabic_name'
                      })
                    }
                  ]}
                  label={intl.formatMessage({
                    id: 'business_details.business_info.placeholders.business_arabic_name'
                  })}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.placeholders.business_arabic_name'
                    })}
                    disabled={!canEditBusinessDetails}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name="salesChannel"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.placeholders.sales_channel'
                  })}
                >
                  <Select
                    options={SALES_CHANNEL_OPTIONS}
                    disabled={!canEditBusinessDetails}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  name="industry"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.placeholders.industry'
                  })}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.placeholders.industry'
                    })}
                    disabled={!canEditBusinessDetails}
                    options={INDUSTRY_OPTIONS}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="businessCategory"
                label={intl.formatMessage({
                  id: 'business_details.business_info.business_category'
                })}
              >
                <Select options={BUSINESS_CATEGORIES} />
              </Form.Item>
              <div className="br-form-row">
                <Form.Item
                  name="desc"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.placeholders.description'
                  })}
                >
                  <Input
                    disabled={!canEditBusinessDetails}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.placeholders.description'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="type"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.type'
                  })}
                  rules={[{ required: true, whitespace: true }]}
                >
                  <Input
                    disabled={!canEditBusinessDetails}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.type'
                    })}
                    rules={[
                      {
                        required: true,
                        label: 'Type'
                      }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="website"
                  label={intl.formatMessage({
                    id: 'business_details.business_info.website'
                  })}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_info.store_url'
                    })}
                    disabled={!canEditBusinessDetails}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider />
            <div className="br-edit-business__business-location-section">
              <span className="br-edit-business__section__title heading">
                {intl.formatMessage({
                  id: 'business_details.business_location.title'
                })}
                <span className="br-edit-business__section__subtitle caption-lg">
                  {intl.formatMessage({
                    id: 'business_details.business_location.sub_title'
                  })}
                </span>
              </span>
              <Form.Item
                name={['address', 'firstLine']}
                label={intl.formatMessage({
                  id: 'business_details.business_location.placeholders.firstLine'
                })}
                rules={[
                  {
                    required: true,
                    whitespace: true
                  }
                ]}
              >
                <Input
                  disabled={!canEditBusinessLocation}
                  placeholder={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.firstLine'
                  })}
                />
              </Form.Item>
              <div className="br-form-row br-form-row__city-area">
                <Form.Item
                  name={['address', 'city', '_id']}
                  rules={[{ required: true }]}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.city'
                  })}
                >
                  <Select
                    disabled={!canEditBusinessLocation}
                    onChange={handleOnChangeCity}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.city'
                    })}
                  >
                    {businessCitiesValue &&
                      businessCitiesValue.map((city) => (
                        <Select.Option value={city._id}>
                          {city?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <BRArea
                  disabled={!canEditBusinessLocation}
                  required={false}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.area'
                  })}
                  name={['address', 'district', '_id']}
                  areas={businessDistricts}
                />
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['address', 'buildingNumber']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.building'
                  })}
                >
                  <Input
                    disabled={!canEditBusinessLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.building'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name={['address', 'floor']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.floor'
                  })}
                >
                  <Input
                    disabled={!canEditBusinessLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.floor'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['address', 'apartment']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.apartment'
                  })}
                >
                  <Input
                    disabled={!canEditBusinessLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.apartment'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  disabled={!canEditBusinessLocation}
                  name={['address', 'secondLine']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.landmarks'
                  })}
                >
                  <Input
                    disabled={!canEditBusinessLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.landmarks'
                    })}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider />
            <div className="br-edit-business__wallet-payment-section">
              <span className="br-edit-business__section__title heading">
                {intl.formatMessage({
                  id: 'business_details.wallet_payment_info.title'
                })}
                <span className="br-edit-business__section__subtitle caption-lg">
                  {intl.formatMessage({
                    id: 'business_details.wallet_payment_info.subtitle'
                  })}
                </span>
              </span>
              <div className="br-form-row ">
                <Form.Item
                  name="businessType"
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.business_type'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={!canUpdateWalletInfo}
                    options={BUSINESS_TYPES_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  name="businessCategory"
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.business_category'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={!canUpdateWalletInfo}
                    options={BUSINESS_PAYMENT_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  name="businessTier"
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.business_tier'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={!canUpdateWalletInfo}
                    options={BUSINESS_TIERS}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row ">
                <Form.Item
                  name={['paymentInfo', 'paymentTransferMethod']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.payment_gateway'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET
                          ])
                        : !(isFinancialUser || isCommercialUser) ||
                          isCommercialManagerRole
                    }
                    options={PAYMENT_METHODS}
                  />
                </Form.Item>
                <Form.Item
                  name="pricingTierId"
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.pricing_plan'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET,
                            ...(businessInfo?.pricingTier?.changeType ===
                            DEFAULT_CHANGE
                              ? [ACL_MATRIX.BUSINESS_UPDATE_DEFAULT_PRICE_PLAN]
                              : [])
                          ])
                        : !isFinancialUser || !isCommercialUser
                    }
                    id="pricingTiers"
                    showSearch
                    optionLabelProp="label"
                    optionFilterProp="label"
                    showArrow
                    placeholder="price list:"
                  >
                    {priceTiers?.length &&
                      priceTiers.map(({ _id, name, configurations = {} }) => (
                        <Select.Option key={_id} value={_id} label={name}>
                          <div className="display-flex justify-space-between">
                            {name}
                            {configurations?.restriction
                              ?.merchantsRestricted && (
                              <span className="br-pricing-tiers__restricted-label">
                                {intl.formatMessage({
                                  id: 'pricing.pricing_configurations.restrictions.tier_label'
                                })}
                              </span>
                            )}
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="fulfillmentPricingTierId"
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.fulfillment_plan'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET,
                            ...(businessInfo?.pricingTier?.changeType ===
                            DEFAULT_CHANGE
                              ? [ACL_MATRIX.BUSINESS_UPDATE_DEFAULT_PRICE_PLAN]
                              : [])
                          ])
                        : !isFinancialUser || !isCommercialUser
                    }
                    id="fulfillmentPricingTiers"
                    showSearch
                    optionLabelProp="label"
                    optionFilterProp="label"
                    showArrow
                    placeholder="price list:"
                    onChange={onChangeFulfillmentPricingPlan}
                  >
                    {fulfillmentPriceTiers?.length &&
                      fulfillmentPriceTiers.map(
                        ({ _id, name, configurations = {} }) => (
                          <Select.Option key={_id} value={_id} label={name}>
                            <div className="display-flex justify-space-between">
                              {name}
                              {configurations?.restriction
                                ?.merchantsRestricted && (
                                <span className="br-pricing-tiers__restricted-label">
                                  {intl.formatMessage({
                                    id: 'pricing.pricing_configurations.restrictions.tier_label'
                                  })}
                                </span>
                              )}
                            </div>
                          </Select.Option>
                        )
                      )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['paymentInfo', 'paymentType']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.payment_type'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET
                          ])
                        : !isFinancialUser || isCommercialManagerRole
                    }
                    options={PAYMENT_TYPES}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['paymentInfo', 'creditLimit']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.credit_limit'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <InputNumber
                    name="creditLimit"
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET
                          ])
                        : !isFinancialUser
                    }
                    formatter={(value) =>
                      value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item
                  name={['walletPaymentInfo', 'paymentSchedule']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'business_details.wallet_payment_info.cashout_frequency'
                      })}
                      {walletPaymentTooltip}
                    </>
                  }
                >
                  <Select
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_WALLET
                          ])
                        : !isFinancialUser
                    }
                    onChange={handleOnPaymentFrequencyChange}
                  >
                    {PAYMENT_FREQUENCY_SCHEDULE.map(({ label }, index) => (
                      <Select.Option key={label} value={index}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Divider />
            {isUserAuthorized(
              aclMatrix.BUSINESSES_EDIT,
              [ACL_MATRIX.BUSINESS_UPDATE_WALLET],
              { countries: [COUNTRIES_CODE_NAME.AE, COUNTRIES_CODE_NAME.EG] }
            ) && (
              <div className="br-edit-business__wallet-payment-section">
                <span className="br-edit-business__section__title heading">
                  {intl.formatMessage({
                    id: 'business_details.international_wallet.pricing_plan'
                  })}
                  <span className="br-edit-business__section__subtitle caption-lg">
                    {intl.formatMessage({
                      id: 'business_details.wallet_payment_info.subtitle'
                    })}
                  </span>
                </span>

                <div className="br-form-row ">
                  <Form.Item
                    name="internationalPricingTier_id"
                    label={intl.formatMessage({
                      id: 'business_details.wallet_payment_info.pricing_plan'
                    })}
                  >
                    <Select
                      disabled={!canUpdateWalletInfo}
                      options={internationalTiers}
                    />
                  </Form.Item>

                  <Form.Item
                    name="internationalPaymentInfo"
                    label={intl.formatMessage({
                      id: 'business_details.international_wallet.cashout_frequency'
                    })}
                  >
                    <Select
                      disabled={!canUpdateWalletInfo}
                      onChange={handleInternationalCashoutChange}
                    >
                      {PAYMENT_FREQUENCY_SCHEDULE.map(({ label }, index) => (
                        <Select.Option key={label} value={index}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
            <Divider />
            {isUserAuthorized(
              [SUPER_ADMIN, SALES, SALES_MANAGER],
              [ACL_MATRIX.BUSINESS_CONFIGURE_OPERATIONS_SETTINGS]
            ) && (
              <div className="br-edit-business__operational-settings-section">
                <span className="br-edit-business__section__title heading">
                  {intl.formatMessage({
                    id: 'business_details.operations_settings.title'
                  })}
                </span>
                <Form.Item
                  name="numberOfAttempts"
                  label={intl.formatMessage({
                    id: 'business_details.operations_settings.number_of_attempts'
                  })}
                >
                  <Select>
                    {Object.values(NO_OF_ATTEMPTS).map(
                      (numberOfAttempt, index) => (
                        <Select.Option value={numberOfAttempt} key={index}>
                          {intl.formatMessage(
                            {
                              id: 'business_details.operations_settings.attempts'
                            },
                            { number: numberOfAttempt }
                          )}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isRtoOtp"
                  label={intl.formatMessage({
                    id: 'business_details.operations_settings.proof_of_return'
                  })}
                >
                  <Select>
                    <Select.Option value={false}>
                      {intl.formatMessage({
                        id: 'business_details.operations_settings.signature'
                      })}
                    </Select.Option>
                    <Select.Option value={true}>
                      {intl.formatMessage({
                        id: 'business_details.operations_settings.otp'
                      })}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="pickupProofType"
                  label={intl.formatMessage({
                    id: 'business_details.operations_settings.pickup_proof'
                  })}
                >
                  <Select>
                    {PICKUP_PROOF_TYPES.map((type, index) => (
                      <Select.Option value={type.value} key={index}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            <Divider />
            <div className="br-edit-business__pricing-info-section">
              <span className="br-edit-business__section__title heading">
                {intl.formatMessage({
                  id: 'business_details.pricing_info.title'
                })}
              </span>
              <div className="display-flex">
                <Form.Item name="pricingFlagsOptions">
                  <Checkbox.Group
                    disabled={
                      isAdminGroupUser()
                        ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
                            ACL_MATRIX.BUSINESS_UPDATE_PRICING
                          ])
                        : isCustomerSupportRole
                    }
                    options={BUSINESS_PRICING_FLAGS_CHECKERS}
                    onChange={handleChangePricingFlag}
                  />
                </Form.Item>
                <div className="br-edit-business__allow-refund">
                  <Tooltip
                    getPopupContainer={(trigger) => trigger.parentElement}
                    title={
                      !canUpdateAllowToRefund &&
                      intl.formatMessage({
                        id: 'business_details.pricing_info.allow_refund.disabled_tooltip'
                      })
                    }
                  >
                    <Form.Item name="allowedToRefund" valuePropName="checked">
                      <Switch disabled={!canUpdateAllowToRefund} />
                    </Form.Item>
                    {intl.formatMessage({
                      id: 'business_details.pricing_info.allow_refund.label'
                    })}
                  </Tooltip>
                  <Tooltip
                    getPopupContainer={(trigger) => trigger.parentElement}
                    title={
                      !canUpdateSllrPay &&
                      intl.formatMessage({
                        id: 'business_details.pricing_info.allow_refund.disabled_tooltip'
                      })
                    }
                    overlayClassName="br-edit-business__tooltip"
                  >
                    <Form.Item
                      name="isOnlinePaymentEnabled"
                      valuePropName="checked"
                    >
                      <Switch disabled={!canUpdateSllrPay} />
                    </Form.Item>
                    {intl.formatMessage({
                      id: 'business_details.pricing_info.sllr_pay'
                    })}
                  </Tooltip>
                </div>
              </div>
            </div>
            {isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
              ACL_MATRIX.PROMOTIONS_LIST
            ]) && (
              <>
                <Divider />

                <div className="br-edit-business__pricing-info-section">
                  <span className="br-edit-business__section__title heading">
                    <span className="br-edit-business__section__title-header">
                      {intl.formatMessage({
                        id: 'business_details.promotions.title'
                      })}
                      {showAddPromotion && (
                        <BRButton
                          label={intl.formatMessage({
                            id: 'business_details.promotions.button.add'
                          })}
                          onClick={handleCreatePromotionClick}
                        />
                      )}
                    </span>
                  </span>
                  {!showAddPromotion && (
                    <BRTable
                      columns={PROMOTIONS_TABLE_COLUMNS}
                      listFunction={getPromotions}
                      shareMethods={acceptMethods}
                      showFilter={false}
                      pagination={false}
                    />
                  )}
                </div>
              </>
            )}
            <Divider />
            <div className="br-edit-business__api-key-section">
              <span className="br-edit-business__section__title heading">
                <span>
                  {intl.formatMessage({
                    id: 'business_details.api_key.title'
                  })}
                  {!businessInfo?.sandboxApproved &&
                    businessInfo?.sandboxCompleted && (
                      <BRButton
                        type="primary"
                        label={intl.formatMessage({
                          id: 'business_details.api_approve'
                        })}
                        disabled={isApproveDisabled}
                        onClick={onApproveLiveIntegrationClick}
                      />
                    )}
                </span>
                {!businessInfo?.sandboxApproved &&
                  businessInfo?.sandboxCompleted && (
                    <span className="br-edit-business__section__subtitle caption-lg">
                      {intl.formatMessage({
                        id: 'business_details.api_key_waiting'
                      })}
                    </span>
                  )}
              </span>
              <div className="body-medium"> {businessInfo?.apiKey}</div>
            </div>
            <Divider />
            <div className="br-edit-business__default-pickup-location-section">
              <span className="br-edit-business__section__title heading">
                {intl.formatMessage({
                  id: 'business_details.business_default_pickup_location.title'
                })}
              </span>
              <Form.Item
                name={['defaultPickupLocation', 'locationName']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    label: intl.formatMessage({
                      id: 'location_form_labels.location_name'
                    })
                  }
                ]}
              >
                <Input
                  disabled={!canUpdatePickupLocation}
                  placeholder={intl.formatMessage({
                    id: 'location_form_labels.location_name'
                  })}
                />
              </Form.Item>
              <div className="br-form-row">
                <Form.Item
                  name={['defaultPickupLocation', 'address', 'firstLine']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      label: intl.formatMessage({
                        id: 'business_details.business_location.placeholders.firstLine'
                      })
                    }
                  ]}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.firstLine'
                  })}
                >
                  <Input
                    disabled={!canUpdatePickupLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.firstLine'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name={['defaultPickupLocation', 'address', 'secondLine']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.landmarks'
                  })}
                >
                  <Input
                    disabled={!canUpdatePickupLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.landmarks'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['defaultPickupLocation', 'address', 'floor']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.floor'
                  })}
                >
                  <Input
                    disabled={!canUpdatePickupLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.floor'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name={['defaultPickupLocation', 'address', 'apartment']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.apartment'
                  })}
                >
                  <Input
                    disabled={!canUpdatePickupLocation}
                    placeholder={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.apartment'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['defaultPickupLocation', 'address', 'city', '_id']}
                  label={intl.formatMessage({
                    id: 'business_details.business_location.placeholders.city'
                  })}
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={handleChangeDefaultLocationCity}
                    disabled={!canUpdatePickupLocation}
                  >
                    {businessCitiesValue &&
                      businessCitiesValue.map((city) => (
                        <Select.Option
                          value={city._id}
                          className="br-edit-business_cities__option"
                        >
                          {city?.name}
                          {!city.pickupAvailability && (
                            <Tag className="ant-tag-light-gray">
                              {intl.formatMessage({
                                id: 'form.uncovered_zone'
                              })}
                            </Tag>
                          )}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <BRArea
                    required={false}
                    label={intl.formatMessage({
                      id: 'business_details.business_location.placeholders.area'
                    })}
                    disabled={!canUpdatePickupLocation}
                    areas={districts}
                    availability={AVAILABILITY.PICKUP}
                    name={[
                      'defaultPickupLocation',
                      'address',
                      'district',
                      '_id'
                    ]}
                  />
                </Form.Item>
              </div>
            </div>
            <BRButton
              type="primary"
              label={intl.formatMessage({ id: 'common.save' })}
              className="button-lg"
              htmlType="submit"
            />
          </Form>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(EditBusiness));
