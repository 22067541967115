import POHeader from './components/POHeader/POHeader';
import BusinessDetails from './components/BusinessDetails/BusinessDetails';
import Products from './components/Products/Products';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './PurchaseOrder.less';

const PurchaseOrder = ({
  headerProps,
  products,
  setProducts,
  selectedBusiness,
  setSelectedBusiness,
  purchaseOrder = {},
  isBusinessEditDisabled,
  productsTableColumns,
  hideSelectProductBtn,
  isLoading = false,
  setIsLoading = () => {},
  isEditPOPage = false
}) => {
  return (
    <div className="br-purchase-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader {...headerProps} />

          <div className="br-po-content">
            <div className="start-side">
              <BusinessDetails
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedBusiness={selectedBusiness}
                setSelectedBusiness={setSelectedBusiness}
                setProducts={setProducts}
                isBusinessEditDisabled={isBusinessEditDisabled}
                businessId={purchaseOrder.businessId}
              />
            </div>
            <div className="end-side">
              <Products
                selectedBusiness={selectedBusiness}
                products={products}
                setProducts={setProducts}
                columns={productsTableColumns}
                hideSelectProductBtn={hideSelectProductBtn}
                isEditPOPage={isEditPOPage}
              />
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default PurchaseOrder;
