import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Form, Input } from 'antd';
import printJS from 'print-js';
import debounce from 'lodash/debounce';

import { printAirwaybill } from 'services/shipments';
import { getHubs } from 'services/hubs';
import Sounds from 'common/Sounds';
import { getUserWarehouses } from 'common/scopes';
import { SORTING_HUB } from 'constants/hubs';
import { AWB_TYPE } from 'constants/Deliveries';
import { getCurrentUserHubInfo } from 'utils/hubs';

import BRButton from 'components/BRButton/BRButton';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as LeftArrow } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';
import PrinterEmptyState from 'assets/imgRevamp/PrinterEmptyState.svg';

import './BarcodeScanning.less';

const BarcodeScanning = ({ intl, history }) => {
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [awbType, setAwbType] = useState(AWB_TYPE.A4);

  const formRef = useRef();
  const inputRef = useRef();

  const { beep, warning } = Sounds;

  useEffect(() => {
    focusInputField();
  }, [inputRef]);

  useEffect(() => {
    getAllHubs();
  }, []);

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      getUserActionableHubType({ allHubs: result });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getUserActionableHubType = ({ allHubs }) => {
    const userHubsScope = getUserWarehouses();

    if (userHubsScope) {
      const actionableHubs = userHubsScope.filter((hub) => hub.canTakeAction);

      if (actionableHubs.length) {
        const hubType = allHubs.find(
          (hub) => hub._id === actionableHubs[0]._id
        ).type;
        handleSetAwbType(hubType);
      } else {
        notify(intl.formatMessage({ id: 'barcode_to_awb.not_authorized' }));
      }
    } else {
      const hubType = allHubs.find(
        (hub) => hub._id === getCurrentUserHubInfo()._id
      ).type;
      handleSetAwbType(hubType);
    }
  };

  const handleSetAwbType = (hubType) => {
    setAwbType(hubType === SORTING_HUB ? AWB_TYPE.A6 : AWB_TYPE.A4);
  };

  const handleGoBack = () => {
    history.push('/hubs/hub-operation/barcode-to-awb');
  };

  const headerContent = () => (
    <div className="br-barcode-scanning-header__container">
      <BRButton prefixIcon={<LeftArrow />} onClick={handleGoBack} />
      <div className="br-barcode-scanning-header__title display-sm">
        {intl.formatMessage({ id: 'hubs.tabs.barcode_to_awb' })}
      </div>
    </div>
  );

  const emptyState = () => (
    <BREmptyState
      className="br-barcode-scanning__empty-state"
      emptyViewImage={PrinterEmptyState}
      title={intl.formatMessage({
        id: 'barcode_to_awb.barcode_scanning.empty_state_title'
      })}
    />
  );

  const focusInputField = () => {
    inputRef.current.focus();
  };

  const handleOnScan = debounce((e) => {
    const barcode = e.target.value?.trim()?.slice(0, 13);

    if (scannedBarcodes.includes(barcode)) {
      formRef.current.resetFields();
      focusInputField();
      return notify(
        intl.formatMessage({
          id: 'barcode_to_awb.barcode_scanning.already_scanned'
        })
      );
    }

    if (barcode) {
      handlePrintAWB({ barcode });
    }
  }, 500);

  const handlePrintAWB = async ({ barcode, isReprinting = false }) => {
    setIsLoading(true);
    try {
      const payload = {
        requestedAwbType: awbType
      };

      const { data } = await printAirwaybill(barcode, {
        isBarcode: true,
        payload
      });
      !isReprinting && beep();
      printJS({
        printable: data,
        type: 'pdf',
        base64: true,
        onPrintDialogClose: () =>
          !isReprinting && setScannedBarcodes((prev) => [...prev, barcode])
      });
      formRef.current.resetFields();
      focusInputField();
    } catch (error) {
      warning();
      formRef.current.resetFields();
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleReprint = (barcode) => {
    handlePrintAWB({ barcode, isReprinting: true });
  };

  return (
    <Container
      header={<BRHeader headerContent={headerContent} />}
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-barcode-scanning__container">
            <div className="br-barcode-scanning__input-container">
              <Form ref={formRef}>
                <Form.Item
                  name="barcode"
                  label={intl.formatMessage({
                    id: 'barcode_to_awb.barcode_scanning.form_label'
                  })}
                >
                  <Input ref={inputRef} onChange={handleOnScan} />
                </Form.Item>
              </Form>
            </div>
            <div className="br-barcode-scanning__list-container">
              <div className="br-barcode-scanning__list-header display-xs">
                {intl.formatMessage(
                  {
                    id: 'barcode_to_awb.barcode_scanning.list_title'
                  },
                  {
                    count: scannedBarcodes.length
                  }
                )}
              </div>
              <div className="br-barcode-scanning__list-body">
                {!!scannedBarcodes.length
                  ? scannedBarcodes.map((barcode) => (
                      <div className="br-barcode-scanning__list-item body-medium">
                        {barcode}
                        <BRButton
                          className="button-sm"
                          prefixIcon={<Printer />}
                          onClick={() => handleReprint(barcode)}
                        />
                      </div>
                    ))
                  : emptyState()}
              </div>
            </div>
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default withRouter(injectIntl(BarcodeScanning));
