import { combineReducers } from 'redux';
import deliveries from './deliveriesReducer';
import businesses from './businessesReducer';
import hubs from './hubsReducer';
import users from './usersReducer';
import cities from './citiesReducer';
import routes from './routesReducer';
import stars from './starsReducer';
import pickup from './pickupsReducer';
import codAR from './arReducer';
import app from './appReducer';

export default combineReducers({
  deliveries,
  businesses,
  hubs,
  users,
  cities,
  routes,
  stars,
  pickup,
  codAR,
  app,
});
