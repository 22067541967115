import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Select } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import './BRDropdownModal.less';

const { Option } = Select;

const BRDropdownModal = ({
  intl,
  close,
  title,
  subtitle,
  dropdownPlaceholder,
  dropdownData,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  isLoading,
  labelInValue,
  ...rest
}) => {
  const [selected, setSelected] = useState(null);

  const renderedOptions = dropdownData?.map((option, index) => (
    <Option key={index} value={option?.value || option}>
      {option?.label || option}
    </Option>
  ));

  const handleConfirm = () => {
    onConfirm(labelInValue ? selected : selected.value);
    handleCancel();
  };

  const handleCancel = () => {
    setSelected(null);
    close();
  };

  return (
    <Modal
      {...rest}
      title={
        title ||
        intl.formatMessage({
          id: `dropdown_modal.title`
        })
      }
      footer={
        <div className="br-dropdown-modal_footer">
          <BRButton
            className="br-dropdown-modal_footer_btn"
            onClick={handleCancel}
            label={
              cancelBtnText ||
              intl.formatMessage({
                id: `dropdown_modal.cancel`
              })
            }
          />
          <BRButton
            className="br-dropdown-modal_footer_btn"
            type="primary"
            onClick={handleConfirm}
            disabled={!selected}
            loading={isLoading}
            label={
              confirmBtnText ||
              intl.formatMessage({
                id: `dropdown_modal.confirm`
              })
            }
          />
        </div>
      }
      onCancel={handleCancel}
      className="br-dropdown-modal"
      centered
    >
      {subtitle && <div className="br-dropdown-modal_subtitle">{subtitle}</div>}
      <Select
        labelInValue
        showSearch
        size="large"
        className="br-dropdown-modal_dropdown"
        placeholder={
          dropdownPlaceholder ||
          intl.formatMessage({
            id: `dropdown_modal.placeholder`
          })
        }
        value={selected}
        optionFilterProp="children"
        loading={isLoading}
        disabled={isLoading}
        onSelect={setSelected}
        filterOption={(input, { children }) =>
          children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {renderedOptions}
      </Select>
    </Modal>
  );
};

export default injectIntl(BRDropdownModal);
