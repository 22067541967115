import { injectIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { RETURN_ORDER_ISSUES_OPTIONS } from 'constants/fulfillment-return-orders';

const IssuesFilter = ({ intl, updateFilters }) => {
  const onChange = (issueType) => {
    updateFilters({
      issueType
    });
  };

  return (
    <Form.Item
      label={intl.formatMessage({
        id: 'fulfillment_returns.issues_filter.label'
      })}
    >
      <Select
        options={RETURN_ORDER_ISSUES_OPTIONS}
        onChange={onChange}
        defaultValue={RETURN_ORDER_ISSUES_OPTIONS[0].value}
      />
    </Form.Item>
  );
};

export default injectIntl(IssuesFilter);
