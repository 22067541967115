import React, { createRef } from 'react';
import { Radio } from 'antd';

import './SingleSelectLookup.less';

class SingleSelectLookup extends React.Component {
  checkboxRef = createRef();
  state = { filterState: '' };

  clear = () => {
    this.setState({ filterState: '' });
  };

  render() {
    let { onChange, lookup } = this.props;
    let { filterState } = this.state;
    return (
      <div className="br-filter--single-select--container">
        <Radio.Group
          options={lookup}
          onChange={(e) => {
            this.setState({ filterState: e.target.value });
            onChange([e.target.value]);
          }}
          value={filterState}
        />
      </div>
    );
  }
}

export default SingleSelectLookup;
