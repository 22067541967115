import actionTypes from '../actions/ActionTypes';

const initialState = {
  businesses: [],
  hubs: [],
  cities: [],
  zones: [],
  districts: [],
  users: [],
  stars: { count: 0, data: [] },
  delivery: null,
  deliveries: { count: 0, data: [], pagination: {} },
  loading: false,
  pickupZones: [],
  dropOffZones: [],
  activeRoutes: [],
  airWayBill: undefined,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_VERIFIED_BUSINESSES_RECEIVED:
      return { ...state, businesses: action.payload };
    case actionTypes.GET_ALL_HUBS_RECEIVED:
      return { ...state, hubs: action.payload };
    case actionTypes.GET_ALL_DELIVERIES_RECEIVED:
      return { ...state, deliveries: { ...action.payload } };
    case actionTypes.DELIVERIES_LOADING_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELIVERIES_LOADING_ENDED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_DELIVERY_RECEIVED:
      return {
        ...state,
        delivery: { ...action.payload },
      };
    case actionTypes.GET_PICKUP_ZONES_RECEIVED:
      return {
        ...state,
        pickupZones: [...action.payload],
      };
    case actionTypes.GET_DROP_OFF_ZONES_RECEIVED:
      return {
        ...state,
        dropOffZones: [...action.payload],
      };
    case actionTypes.GET_ALL_CITIES_RECEIVED:
      return {
        ...state,
        cities: [...action.payload],
      };
    case actionTypes.PRINT_AIR_WAY_BILL_DONE: {
      return {
        ...state,
        airWayBill: action.payload,
      };
    }

    case actionTypes.PRINT_AIR_WAY_BILL_CLEAR: {
      return {
        ...state,
        airWayBill: undefined,
      };
    }
    case actionTypes.GET_ALL_STARS_RECEIVED: {
      return {
        ...state,
        stars: {
          count: action.payload.count,
          data: action.payload.stars,
        },
      };
    }

    case actionTypes.GET_ALL_ACTIVE_ROUTES_RECEIVED: {
      return {
        ...state,
        activeRoutes: action.payload,
      };
    }
    default:
      return state;
  }
}
