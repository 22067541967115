import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Select, Button } from 'antd';

import {
  CANCEL_ORDER,
  CHANGE_TO_RTO_UPDATE_TYPE
} from 'constants/action-center';
import { setDeliveriesOnShelf } from 'services/deliveries';
import { actionSubmit } from 'services/action-center';
import { updateOrder } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import './OrderActionModal.less';

const { Option } = Select;

const OrderActionModal = ({
  intl,
  title,
  dropdownPlaceholder,
  dropdownData,
  hubId,
  deliveryId,
  _id,
  isLoading,
  close,
  clearSelectedRow,
  updateTable,
  action,
  ...rest
}) => {
  const [selectedBin, setSelectedBin] = useState(null);

  const handleCancel = () => {
    setSelectedBin(null);
    close();
  };

  const handleSubmitClick = () => {
    assignToShelf();
  };

  const assignToShelf = async () => {
    const payload = {
      shelfValue: selectedBin?.value,
      hubId,
      deliveryIds: deliveryId
    };
    try {
      const res = await setDeliveriesOnShelf(payload);
      if (res?.msg) {
        notify(res?.msg, 'success');
        if (action === CANCEL_ORDER) {
          changeOrderToRTO();
        } else {
          handleActionApplied();
        }
      } else if (res?.failed) {
        notify(res?.reasons[0].reason);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const changeOrderToRTO = async () => {
    try {
      const payload = {
        updateType: CHANGE_TO_RTO_UPDATE_TYPE
      };
      await updateOrder({ id: deliveryId, payload });
      handleOnSuccess();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleActionApplied = async () => {
    const payload = {
      isApplied: true
    };
    try {
      const res = await actionSubmit(_id, payload);
      if (res?.success) {
        updateTable();
        clearSelectedRow();
        handleCancel();
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnSuccess = () => {
    updateTable();
    clearSelectedRow();
    handleCancel();
  };

  const renderDropdownData = dropdownData?.map((option, index) => (
    <Option key={index} value={option.value || option}>
      {option.label}
    </Option>
  ));

  return (
    <Modal
      {...rest}
      title={null}
      footer={null}
      centered
      onCancel={handleCancel}
    >
      <div className="br-order-action-modal__title">{title}</div>
      <Select
        labelInValue
        showSearch
        size="large"
        className="br-order-action-modal__dropdown"
        placeholder={dropdownPlaceholder}
        value={selectedBin}
        onSelect={setSelectedBin}
        optionFilterProp="children"
        loading={isLoading}
        disabled={isLoading}
      >
        {renderDropdownData}
      </Select>
      <div className="br-order-action-modal__footer">
        <Button onClick={handleCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>
        <Button
          type="primary"
          disabled={!selectedBin}
          onClick={handleSubmitClick}
        >
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(OrderActionModal);
