import { fmt } from 'IntlWrapper/IntlWrapper';
import { Tooltip } from 'antd';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/info-circle.svg';

const currentUserCountryCode = JSON.parse(localStorage.getItem('userInfo'))
  ?.country?.code;

export const SYSTEM_SUB_TITLE = (toggleValue) => {
  return toggleValue
    ? [
        fmt({ id: 'settings.system.cod.first_on_subtitle' }),
        fmt({ id: 'settings.system.cod.second_on_subtitle' })
      ]
    : [fmt({ id: 'settings.system.cod.off_subtitle' })];
};

export const WORKING_DAYS = {
  DEFAULT: [0, 1, 2, 3, 4, 6]
  // EG: Add it, if it's different than DEFAULT
  // SA: Add it, if it's different than DEFAULT
  // ...
};

export const getWorkingDays = (countryCode = currentUserCountryCode) => {
  return WORKING_DAYS[countryCode] || WORKING_DAYS.DEFAULT;
};

export const DEFAULT_TIMEZONE = 'Africa/Cairo';

export const HOLIDAY_SETTINGS_OPTIONS = [
  {
    label: (
      <div>
        {fmt({
          id: 'settings.holiday_settings.prevent_pickups'
        })}

        <Tooltip
          title={fmt({
            id: 'settings.holiday_settings.prevent_pickups_tooltip'
          })}
        >
          <TooltipsIcon className="tooltip-icon" />
        </Tooltip>
      </div>
    ),
    value: 'stopSchedulingPickups'
  },
  {
    label: (
      <div>
        {fmt({
          id: 'settings.holiday_settings.prevent_orders'
        })}

        <Tooltip
          title={fmt({
            id: 'settings.holiday_settings.prevent_orders_tooltip'
          })}
        >
          <TooltipsIcon className="tooltip-icon" />
        </Tooltip>
      </div>
    ),
    value: 'stopSchedulingOrders'
  },
  {
    label: (
      <div className="checkbox-item-container">
        {fmt({
          id: 'settings.holiday_settings.skip_automated_actions'
        })}

        <Tooltip
          title={fmt({
            id: 'settings.holiday_settings.skip_automated_actions_tooltip'
          })}
        >
          <TooltipsIcon className="tooltip-icon" />
        </Tooltip>
      </div>
    ),
    value: 'skipWenshActions'
  }
];
