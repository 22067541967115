import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Menu, Checkbox } from 'antd';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';

const EditHubsModal = ({
  close,
  message,
  title,
  onConfirm,
  addFactors,
  templateId,
  template,
  allHubs,
  editTemplate,
  ...props
}) => {
  const [selectedHubsValue, setSelectedHubsValue] = useState([]);

  useEffect(() => {
    const formattedHubsValue = [];
    template?.hubs.forEach((hub) => {
      formattedHubsValue.push(hub.hub_id);
    });
    setSelectedHubsValue(formattedHubsValue);
  }, [template]);

  const handleOnFinishEditHubs = () => {
    if (selectedHubsValue.length) {
      let payload = {
        hubs: [
          ...selectedHubsValue.map((value) => {
            return {
              hub_id: value,
              hub_name: allHubs.filter((hub) => hub.value === value)[0].label
            };
          })
        ]
      };
      editTemplate({
        values: payload,
        id: template.id,
        callHubEndpoint: true,
        notifyMessage: 'Hubs edited successfully'
      });
      close();
    } else {
      editTemplate({
        values: {
          hubs: []
        },
        id: template.id,
        callHubEndpoint: true,
        notifyMessage: 'Hubs edited successfully'
      });
      close();
    }
  };

  const onChange = (value) => {
    // setSelectedHubs();
    setSelectedHubsValue(value);
  };

  const menu = (
    <Menu className="br-score-card__menu-container">
      <Menu.Item className="br-scorecard__checkbox-list-container">
        <Checkbox.Group
          options={allHubs?.filter(
            (hub) =>
              !hub.templateId || hub.templateId === template.id.toString()
          )}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          className="br-score-card__checkbox-group-container"
          value={selectedHubsValue}
        />
      </Menu.Item>
    </Menu>
  );

  const removeSelectedHub = (value) => {
    setSelectedHubsValue(selectedHubsValue.filter((hub) => hub !== value));
  };

  return (
    <Modal
      onCancel={close}
      {...props}
      title={title || null}
      className="br-add-factors-modal"
      width={null}
      cancelText={'Cancel'}
      okText={'Save'}
      onOk={handleOnFinishEditHubs}
    >
      <div className="br-add-factors-container">
        <div className="br-add-factors-modal__header-container">
          <span className="br-add-factors-modal__header-title">Edit Hubs</span>
          <CloseOutlined onClick={close} />
        </div>
        <div className="br-add-factors-modal__performance-factor-container">
          <span className="br-add-factors-modal__performance-factor-title">
            Hubs
          </span>
          <Dropdown overlay={menu} trigger="click">
            <div className="br-score-card__input-container">
              <span>Select</span>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>

        <div className="br-hubs-factors-modal__selected_hubs_container">
          {selectedHubsValue.map((value) => (
            <span className="br-templates__hub-tag">
              {allHubs?.filter((hub) => hub.value === value)[0].label}
              <span
                className="br-templates__hub-remove-tag"
                onClick={() => removeSelectedHub(value)}
              >
                x
              </span>
            </span>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default EditHubsModal;
