import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { getFulfillmentUsers } from 'services/fulfillment';

import { notify } from 'components/Notify/Notify';

const CreatedBy = ({ intl, updateFilters }) => {
  const [fulfillmentUsers, setFulfillmentUsers] = useState([]);

  const fetchFulfillmentUsers = async () => {
    try {
      const data = await getFulfillmentUsers();
      setFulfillmentUsers(data.fulfillmentUsers);
    } catch (error) {
      notify(error.message);
    }
  };

  const onChange = (id) => {
    updateFilters({
      createdById: id
    });
  };

  useEffect(() => {
    fetchFulfillmentUsers();
  }, []);

  return (
    <Form.Item
      label={intl.formatMessage({
        id: 'fulfillment_inbound.po_filters.created_by.label'
      })}
    >
      <Select
        showSearch
        allowClear
        placeholder={intl.formatMessage({
          id: 'fulfillment_inbound.po_filters.created_by.placeholder'
        })}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={fulfillmentUsers.map(({ profile = {}, _id }) => ({
          label: `${profile.firstName} ${profile.lastName}`,
          value: _id
        }))}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default injectIntl(CreatedBy);
