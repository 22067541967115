import { BASE } from 'apis';
import { downloadAsXlsx } from 'utils';
import dayjs from 'dayjs';
import { notify } from 'components/Notify/Notify';

const today = dayjs().format('DD MM YYYY');
export const getReports = async ({ page, currentSearch }) => {
  const { data } = await BASE.get(
    `/ar-report/search?pageId=${page}${currentSearch}`
  );
  return data;
};

export const getReportDetails = async (payload) => {
  const { data } = await BASE.get(`/ar-report/${payload}`);
  return data;
};

export const generateReport = async (payload) => {
  try {
    await BASE.get(`/ar-report/${payload}`);
    return true;
  } catch (e) {
    notify(e?.response?.data?.message || 'Error while generating reports');
    return false;
  }
};

export const getReportCSV = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.get(`/ar-report/download${payload}`, header);
    downloadAsXlsx(data, 'AR Report.xlsx');
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return false;
  }
};

export const getSummaryReportCSV = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.get(
      `/ar-report/summaryReports/download${payload}`,
      header
    );
    downloadAsXlsx(data, `reimbursement_reports_summary_${today}.xlsx`);
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return false;
  }
};

export const uploadArReport = async (payload) => {
  try {
    const data = await BASE.put(`/ar-report`, {
      records: payload
    });
    notify('Sheet uploaded successfully', 'success');
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return false;
  }
};

export const getSummaryReports = async ({ page, currentSearch }) => {
  const { data } = await BASE.get(
    `/ar-report/summaryReports/view?pageId=${page}${currentSearch}`
  );
  return data;
};

function decodeUtf8(arrayBuffer) {
  let result = '';
  let i = 0;
  let c = 0;
  // let c1 = 0;
  let c2 = 0;
  let c3 = 0;

  const data = new Uint8Array(arrayBuffer);

  // If we have a BOM skip it
  if (
    data.length >= 3 &&
    data[0] === 0xef &&
    data[1] === 0xbb &&
    data[2] === 0xbf
  ) {
    i = 3;
  }

  while (i < data.length) {
    c = data[i];

    if (c < 128) {
      result += String.fromCharCode(c);
      // eslint-disable-next-line no-plusplus
      i++;
    } else if (c > 191 && c < 224) {
      if (i + 1 >= data.length) {
        throw new Error(
          'UTF-8 Decode failed. Two byte character was truncated.'
        );
      }
      c2 = data[i + 1];
      // eslint-disable-next-line no-bitwise
      result += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      if (i + 2 >= data.length) {
        throw new Error(
          'UTF-8 Decode failed. Multi byte character was truncated.'
        );
      }
      c2 = data[i + 1];
      c3 = data[i + 2];
      // eslint-disable-next-line no-bitwise
      result += String.fromCharCode(
        // eslint-disable-next-line no-bitwise
        ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
      );
      i += 3;
    }
  }
  return result;
}

export const downloadArReports = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.get(`/ar-report?${payload}`, header);
    downloadAsXlsx(data, 'AR Report.xlsx');
    return true;
  } catch (e) {
    const message = decodeUtf8(e.response.data);
    const err = message ? JSON.parse(message).message : e.message;
    notify(err);
    return false;
  }
};

export const getReportDetailsCSV = async ({ number, isDialy }) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };

    const { data } = await BASE.get(`/ar-report/download/${number}`, header);
    downloadAsXlsx(
      data,
      isDialy ? 'AR Report.xlsx' : `Reimbursement_report_${today}.xlsx`
    );
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return false;
  }
};

export const getReportDetailsXLSX = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.get(`/ar-report/download/${payload}`, header);
    downloadAsXlsx(data, `Reimbursement_report_${today}.xlsx`);
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return false;
  }
};

export const uploadTransactionReportXLSX = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.post(
      `/wallet/deliveries/transactions/xlsx`,
      {
        deliveries: payload
      },
      header
    );
    downloadAsXlsx(data, `transactions_report_${today}.xlsx`);
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return null;
  }
};

export const uploadSouqTransactionReportXLSX = async (payload) => {
  try {
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    };
    const { data } = await BASE.post(
      `/wallet/souq/transactions/xlsx`,
      {
        deliveries: payload
      },
      header
    );
    downloadAsXlsx(data, `transactions_report_${today}.xlsx`);
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return null;
  }
};
