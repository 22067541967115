import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { Formik } from 'formik';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { get as getValue, pick } from 'lodash';
import { connect } from 'react-redux';
import styles from '../styles.module.scss';
import {
  clearStarDebriefingReport,
  getHubs,
  getStarDayReport,
  getStarLiabilities,
  getStarsByHub
} from '../../../actions';

import { notify } from 'components/Notify/Notify';
import { formatOptions } from '../../../utils';
import { getHubStars } from 'services/hubs';
import { getCCODLiabilityAmount } from 'services/ccod';
import {
  isBankTransferAvailable,
  isCcodVisible
} from 'common/countries/countries-mapping';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

import BRTabs from 'components/BRTabs/BRTabs';
import DebriefCash from './components/DebriefCash/DebriefCash';
import DeliverdOrders from './components/DeliverdOrders/DeliverdOrders';
import BulkUploadCash from './components/BulkUploadCash/BulkUploadCash';

import './MoneyDebrief.less';

const hub = JSON.parse(localStorage.getItem('userInfo'))?.warehouseInfo || '';
const DEFAULT_TAB_PATH = 'cash';

const StarSelectionForm = ({
  handleStartDebriefing,
  hubs,
  stars,
  fetchStars,
  setSelectedStar,
  setSelectedHub,
  selectedHub,
  selectedStar,
  onHubSelected
}) => (
  <Formik
    enableReinitialize
    onSubmit={(values) => {
      handleStartDebriefing({
        hubId: getValue(values, 'hub.value'),
        starId: getValue(values, 'star.value')
      });
    }}
    handleChage
    initialValues={{
      hub: '',
      star: ''
    }}
    render={({
      // handleSubmit,
      handleChange,
      handleBlur,
      // values,
      touched,
      initialValues,
      setFieldValue,
      errors
      // ...rest
    }) => (
      <Form
        className={styles.moneyDebrief}
        onSubmit={(e) => {
          e.preventDefault();
          // handleSubmit(values);
        }}
      >
        <Form.Row className={styles.formRow}>
          <Form.Group as={Col} sm={4} controlId="hub-control">
            <Form.Label className={styles.formLabel}>Hub</Form.Label>
            <Select
              name="hub"
              onBlur={handleBlur}
              value={
                !isDefaultBostaCourier([...aclMatrix.THREE_PL])
                  ? [{ value: hub._id, label: hub.name }]
                  : selectedHub
              }
              options={formatOptions('_id', 'name', hubs || [])}
              isInvalid={!!errors.hub && !!touched.hub}
              onChange={(e) => {
                if (e) {
                  if (e.value) onHubSelected(e.value);
                  else notify('please choose hub');
                }

                setFieldValue('hub', e);
                setFieldValue('star', null);
                setSelectedHub(e);
                setSelectedStar(null);
              }}
              isDisabled={!isDefaultBostaCourier([...aclMatrix.THREE_PL])}
            />
            {errors.hub && touched.hub ? (
              <Form.Control.Feedback type="invalid">
                {errors.hub}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm={4} controlId="star-control">
            <Form.Label className={styles.formLabel}>Star</Form.Label>
            <Select
              options={formatOptions(
                '_id',
                'profile.firstName,profile.lastName',
                stars
              )}
              onBlur={handleBlur}
              value={selectedStar || ''}
              isInvalid={!!errors.stars && !!touched.star}
              onChange={async (e) => {
                setFieldValue('star', e);
                await setSelectedStar(e);
              }}
            />
          </Form.Group>
        </Form.Row>
      </Form>
    )}
  />
);

function MoneyDebrief({
  hubs,
  // hubStars,
  fetchHubs,
  fetchStars,
  starLiabilities,
  fetchStarLiabilities,
  clearStarReportData,
  match: { path }
}) {
  const [selectedStar, setSelectedStar] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);
  const [startDebriefing, setStartDebriefing] = useState(false);
  const [totalLiabilities, setTotalLiabilities] = useState(0);
  const [hubStars, setSelectedHubStars] = useState([]);
  const [totalCCODAmount, setTotalCCODAmount] = useState(0);
  const [totalBtAmount, setTotalBTAmount] = useState(0);

  const TABS = {
    cash: {
      label: 'Debrief Cash',
      component: () => (
        <DebriefCash
          selectedStar={selectedStar}
          setSelectedStar={setSelectedStar}
          clearStarReportData={clearStarReportData}
          totalCCODAmount={totalCCODAmount}
          setTotalCCODAmount={setTotalCCODAmount}
          selectedHub={selectedHub}
          setSelectedHub={setSelectedHub}
          totalBtAmount={totalBtAmount}
          setTotalBTAmount={setTotalBTAmount}
        />
      ),
      path: '/hubs/hub-operation/money-debrief/cash'
    },
    orders: {
      label: 'Deliverd Orders',
      component: DeliverdOrders,
      componentProps: {
        selectedStar
      },
      path: `/hubs/hub-operation/money-debrief/deliverd-orders`
    }
  };

  useEffect(() => {
    fetchHubs();
    return () => {
      clearStarReportData();
    };
  }, [fetchHubs]);

  useEffect(() => {
    if (!isDefaultBostaCourier([...aclMatrix.THREE_PL])) {
      setSelectedHub({ value: hub._id, label: hub.name });
      getStarsInHub(hub?._id);
    }
  }, []);

  useEffect(() => {
    if (starLiabilities.error) {
      setStartDebriefing(false);
      setTotalLiabilities(0);
      setTotalCCODAmount(0);
      setTotalBTAmount(0);
    } else {
      setStartDebriefing(true);
      setTotalLiabilities(starLiabilities.totalLiability);
    }
  }, [starLiabilities]);
  const requiredParams =
    (fn, condition) =>
    (...args) => {
      if (condition && !condition()) {
        return;
      }
      fn(...args);
    };
  const handleStartDebriefing = requiredParams(
    (values) => {
      // setStartDebriefing(true);
      fetchStarLiabilities(values);
    },
    () => {
      if (selectedStar === null) {
        notify('Please select a star to start debriefing process!');
        return false;
      }
      return true;
    }
  );

  useEffect(() => {
    if (selectedStar) {
      handleStartDebriefing({
        hubId: selectedHub.value,
        starId: selectedStar.value
      });
      if (isCcodVisible()) {
        getCCODLiabilityInKSA();
      }
    }
  }, [selectedStar]);

  const getStarsInHub = async (hubId) => {
    try {
      const { message } = await getHubStars(hubId);
      setSelectedHubStars(message);
    } catch (error) {
      notify(error.message);
    }
  };

  const getCCODLiabilityInKSA = async () => {
    try {
      const { totalCcod, totalBT } = await getCCODLiabilityAmount(
        selectedStar?._id
      );
      setTotalCCODAmount(totalCcod ?? 0);
      setTotalBTAmount(totalBT ?? 0);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-debrief-money__container">
      <h3>Choose the hub and the star to begin the debrief</h3>
      <StarSelectionForm
        handleStartDebriefing={handleStartDebriefing}
        stars={hubStars?.filter((el) => el.emails?.[0]?.verified)}
        hubs={hubs}
        fetchStars={fetchStars}
        setSelectedStar={setSelectedStar}
        setSelectedHub={setSelectedHub}
        selectedHub={selectedHub}
        selectedStar={selectedStar}
        onHubSelected={getStarsInHub}
      />
      <div className="br-money-debrief__title-and-actions-container">
        <h4>
          Total Liability = {totalLiabilities}{' '}
          {isCcodVisible() &&
            isBankTransferAvailable() &&
            `(COD= ${totalLiabilities - totalCCODAmount - totalBtAmount}, CCOD=
          ${totalCCODAmount}, BT= ${totalBtAmount})`}
        </h4>
        <BulkUploadCash />
      </div>
      <Switch>
        <Route
          path={[
            '/hubs/hub-operation/money-debrief/cash',
            '/hubs/hub-operation/money-debrief/deliverd-orders'
          ]}
          exact
          render={() => <BRTabs tabs={TABS} data={selectedStar} />}
        />
        <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
      </Switch>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs()),
  fetchStars: (data) => dispatch(getStarsByHub(data)),
  fetchStarDayReport: (data) => dispatch(getStarDayReport(data)),
  fetchStarLiabilities: (data) => dispatch(getStarLiabilities(data)),
  clearStarReportData: () => dispatch(clearStarDebriefingReport())
});

const mapStateToProps = ({ hubs }) => ({
  ...hubs
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MoneyDebrief));
