import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Tabs } from 'antd';
import each from 'lodash/each';
import map from 'lodash/map';

import './BRTabs.less';

const BRTabs = (props) => {
  const {
    tabs,
    history,
    history: {
      location: { pathname }
    },
    onChangeTabs,
    sameRouteTabs
  } = props;

  const routeConfig = tabs;
  const tabToRouteMap = {};
  const routeToTabsMap = {};

  each(routeConfig, (configObj, routeKey) => {
    const routeURL = configObj.path;
    tabToRouteMap[routeKey] = routeURL;
    routeToTabsMap[sameRouteTabs ? routeKey : routeURL] = routeKey;
  });

  const activeUrl = Object.keys(routeToTabsMap).find((route) =>
    pathname.includes(route)
  );

  const activeKey = routeToTabsMap[activeUrl];

  const tabPaneNodes = map(
    routeConfig,
    (
      {
        label,
        isUserAuthorized = true,
        disabled = false,
        component: Component,
        componentProps
      },
      routeKey
    ) =>
      isUserAuthorized && (
        <Tabs.TabPane tab={label} key={routeKey} disabled={disabled}>
          {sameRouteTabs && <Component {...componentProps} />}
        </Tabs.TabPane>
      )
  );

  const routeNodes = map(
    routeConfig,
    (
      {
        path,
        component,
        componentProps = {},
        isUserAuthorized = true,
        exact = true
      },
      routeKey
    ) => {
      const Component = component;
      return (
        isUserAuthorized && (
          <Route
            path={path}
            exact={exact}
            key={routeKey}
            render={(routeProps) => (
              <Component {...componentProps} {...routeProps} />
            )}
          />
        )
      );
    }
  );

  const onTabChange = (activeKey) => {
    history.push(tabToRouteMap[activeKey]);
    if (onChangeTabs) {
      onChangeTabs(activeKey);
    }
  };

  return (
    <div className="br-tabs">
      {props.children}
      <Tabs
        className="br-tabs__tab"
        onChange={onTabChange}
        activeKey={activeKey}
      >
        {tabPaneNodes}
      </Tabs>

      {!sameRouteTabs && <Switch>{routeNodes}</Switch>}
    </div>
  );
};

export default withRouter(BRTabs);
