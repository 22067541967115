import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';

import { getCapitalSettings, updateCapitalSetting } from 'services/capital';
import { getCurrency } from 'common/countries/countries-mapping';
import difference from 'utils/business';

import { notify } from 'components/Notify/Notify';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';

import './BostaCapitalSettings.less';

const BostaCapitalSettings = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [capitalValues, setCapitalValues] = useState({});
  const formRef = useRef();

  const getCapitalSettingsData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCapitalSettings();
      setCapitalValues(data);
      formRef.current.setFieldsValue(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCapitalSettingsData();
  }, []);

  const onSubmit = async (values) => {
    let payload = {
      _id: capitalValues._id
    };

    Object.keys(values).forEach((key) => {
      const diff = difference(values[key], capitalValues[key], false);
      if (Object.keys(diff)?.length) {
        payload = {
          ...payload,
          [key]: parseInt(diff)
        };
      }
    });

    try {
      setIsLoading(true);
      await updateCapitalSetting(payload);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Container
      className="br-bosta-capital-settings__container"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'capital_settings.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <Form
            layout="vertical"
            onFinish={onSubmit}
            ref={formRef}
            name="CapitalSettingsForm"
          >
            <div className="br-capital__form-row">
              <Form.Item
                label={intl.formatMessage({
                  id: 'capital_settings.max_loan'
                })}
                name="maxLoanAmount"
                rules={[{ required: true }]}
              >
                <Input
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'capital_settings.max_loan'
                  })}
                  prefix={getCurrency().type}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'capital_settings.min_orders'
                })}
                name="minOrderNumberPerMonth"
                rules={[{ required: true }]}
              >
                <Input
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'capital_settings.min_orders'
                  })}
                />
              </Form.Item>
            </div>
            <div className="br-capital__form-row">
              <Form.Item
                label={intl.formatMessage({
                  id: 'capital_settings.min_cod'
                })}
                type="number"
                name="minimumCodCollectedPerMonth"
                rules={[{ required: true }]}
              >
                <Input
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'capital_settings.min_cod'
                  })}
                  prefix={getCurrency().type}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'capital_settings.success_rate'
                })}
                name="percentDSR"
                rules={[{ required: true }]}
              >
                <Input
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'capital_settings.success_rate'
                  })}
                  prefix="%"
                />
              </Form.Item>
            </div>
            <BRButton
              className="br-capital__form-submit-btn"
              type="primary"
              label={intl.formatMessage({
                id: 'common.apply'
              })}
              htmlType="submit"
            />
          </Form>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(BostaCapitalSettings);
