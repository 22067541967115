import { useRef, useState } from 'react';
import { Form, Input, Collapse, Select, Switch } from 'antd';
import { injectIntl } from 'react-intl';

import { COVERAGE_FILTER_BY_OPTIONS } from 'constants/zoning';
import { cleanEmptyString } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';

import './ZoningFilters.less';

const ZoningFilters = ({
  intl,
  handleFilter,
  allHubs,
  selectedCityId,
  handleFilterClear
}) => {
  const [isFieldsTouched, setIsFieldsTouched] = useState(false);

  const formRef = useRef();

  const onFinish = (values) => {
    if (!values.coverageBy) {
      delete values.pickupAvailability;
      delete values.dropOffAvailability;
    }
    setIsFieldsTouched(false);
    handleFilter({ values: cleanEmptyString(values) });
  };

  const handleFormChange = () => {
    setIsFieldsTouched(true);
  };

  const handleReset = () => {
    formRef.current.resetFields();
    handleFilterClear();
  };

  return (
    <Collapse className="br-zoning-filters__container">
      <Collapse.Panel header={intl.formatMessage({ id: 'zoning.filters' })}>
        <Form
          ref={formRef}
          onFinish={onFinish}
          initialValues={{
            pickupAvailability: true,
            dropOffAvailability: true
          }}
          onValuesChange={handleFormChange}
        >
          <div className="br-zoning-filters__groups-container">
            <div className="br-zoning-filters__group">
              <div className="br-zoning-filters__group-row">
                <Form.Item
                  name="hub._id"
                  label={intl.formatMessage({ id: 'zoning.hub' })}
                >
                  <Select
                    options={allHubs}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    fieldNames={{ label: 'name', value: '_id' }}
                    placeholder={intl.formatMessage({
                      id: 'zoning.hub_placeholder'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="fmHub._id"
                  label={intl.formatMessage({
                    id: 'zoning.table_columns.fm_hub'
                  })}
                >
                  <Select
                    options={allHubs}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    fieldNames={{ label: 'name', value: '_id' }}
                    placeholder={intl.formatMessage({
                      id: 'zoning.hub_placeholder'
                    })}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="br-zoning-filters__coverage">
              <div className="br-zoning-filters__group-title heading">
                {intl.formatMessage({ id: 'zoning.coverage' })}
              </div>
              <div className="br-zoning-filters__group-row">
                <Form.Item name="coverageBy">
                  <Select
                    allowClear
                    options={COVERAGE_FILTER_BY_OPTIONS}
                    placeholder={intl.formatMessage({
                      id: 'zoning.coverage_filter_placeholder'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-zoning-filters__group-row">
                <Form.Item
                  name="pickupAvailability"
                  className="br-zoning-availability__toggle"
                  label={intl.formatMessage({
                    id: 'zoning.create_edit_zone_modal.pickup_coverage'
                  })}
                  valuePropName="checked"
                >
                  <Switch defaultChecked />
                </Form.Item>
              </div>
              <div className="br-zoning-filters__group-row">
                <Form.Item
                  name="dropOffAvailability"
                  className="br-zoning-availability__toggle"
                  label={intl.formatMessage({
                    id: 'zoning.create_edit_zone_modal.delivery_coverage'
                  })}
                  valuePropName="checked"
                >
                  <Switch defaultChecked />
                </Form.Item>
              </div>
            </div>
            <div className="br-zoning-filters__actions">
              <BRButton
                type="primary"
                disabled={!isFieldsTouched || !selectedCityId}
                label={intl.formatMessage({ id: 'common.filter' })}
                htmlType="submit"
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleReset}
              />
            </div>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default injectIntl(ZoningFilters);
