import { injectIntl } from 'react-intl';
import { Modal, Button } from 'antd';

const PrintConfirmationModal = ({
  intl,
  onSuccess,
  close,
  actionId,
  ...rest
}) => {
  const handleConfirm = () => {
    close();
    onSuccess(actionId);
  };

  return (
    <Modal {...rest} title={null} footer={null} centered onCancel={close}>
      <div className="br-order-action-modal__title">
        {intl.formatMessage({
          id: 'action_center.order_actions.confirm_printing_awb'
        })}
      </div>
      <div className="br-order-action-modal__footer">
        <Button onClick={close}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>
        <Button type="primary" onClick={handleConfirm}>
          {intl.formatMessage({ id: 'common.confirm' })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(PrintConfirmationModal);
