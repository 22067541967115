import dayjs from 'dayjs';

import { checkSmartDate, dates, isUserAuthorized } from './helpers';
import {
  INTERNATIONAL_DELIVERY_AWAITING_YOUR_ACTION_CODE,
  INTERNATIONAL_DELIVERY_DATE_FORMAT,
  INTERNATIONAL_DELIVERY_E2E_STATES_LABEL,
  INTERNATIONAL_DELIVERY_STATES_LABEL,
  INTERNATIONAL_DELIVERY_STATUS_CODES,
  INTERNATIONAL_DELIVERY_TAG_COLORS,
  INTERNATIONAL_ORDER_LOCATION
} from 'constants/international-orders';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import {
  COUNTRIES_CODE_NAME,
  COUNTRIES_NAME_TO_CODE_MAPPER
} from 'common/countries/constants/countries';
import { getCurrency } from 'common/countries/countries-mapping';

import BREllipsisCell from 'components/BREllipsisCell/BREllipsisCell';
import BRTag from 'components/BRTag/BRTag';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as ArrowRight } from 'assets/bosta-icons/Arrow-right.svg';

export const formatAddress = (address = {}) => {
  const firstLine = address.firstLine;
  const districtName = address.district?.name;
  const cityName = address.city.name;

  const formattedAddress = `${firstLine}, ${
    districtName ? `${districtName}, ` : ''
  }${cityName}`;
  return formattedAddress;
};

export const disabledDate = (current) => {
  return current && current > dayjs();
};

export const formatInternationalDeliveryData = ({
  delivery,
  onTrackingNumberClick = () => {},
  isE2E
}) => {
  if (delivery.trackingNumber !== delivery.activeDomesticTN && !isE2E) {
    delivery.state.code = INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED;
  }

  return {
    ...delivery,
    key: delivery.trackingNumber,
    businessName: delivery.sender.name,
    trackingNumberButton: (
      <div
        className="br-international-order__tracking-number body-medium"
        onClick={() => {
          if (
            isUserAuthorized(
              aclMatrix.DELIVERIES,
              [ACL_MATRIX.INTERNATIONAL_ORDER_GET],
              {
                countries: [
                  COUNTRIES_CODE_NAME.AE,
                  COUNTRIES_CODE_NAME.EG,
                  COUNTRIES_CODE_NAME.SA
                ]
              }
            )
          ) {
            onTrackingNumberClick(delivery);
          }
        }}
      >
        {delivery.trackingNumber}
      </div>
    ),
    state: (
      <BRTag color={INTERNATIONAL_DELIVERY_TAG_COLORS[delivery.state.code]}>
        {isE2E
          ? getE2EStateLabel(delivery)
          : INTERNATIONAL_DELIVERY_STATES_LABEL[delivery.state.code]}
      </BRTag>
    ),
    pickupAddress: formatAddress(delivery.pickupAddress),
    dropOffAddress: formatAddress(delivery.dropOffAddress),
    customerDetails: (
      <>
        <BREllipsisCell>
          {delivery.receiver.fullName
            ? delivery.receiver.fullName
            : `${delivery.receiver.firstName} ${delivery.receiver.lastName}`}
        </BREllipsisCell>
        <div className="br-international-delivery__column-subtext caption-lg">
          {delivery.receiver.phone}
        </div>
      </>
    ),
    createdAt: checkSmartDate(
      delivery.createdAt,
      INTERNATIONAL_DELIVERY_DATE_FORMAT,
      true
    ),
    pickedUpAt: delivery.collectedFromBusiness
      ? checkSmartDate(
          delivery.collectedFromBusiness,
          INTERNATIONAL_DELIVERY_DATE_FORMAT,
          true
        )
      : '-',
    intransitAt: delivery.inTransitAt
      ? checkSmartDate(
          delivery.inTransitAt,
          INTERNATIONAL_DELIVERY_DATE_FORMAT,
          true
        )
      : '-',
    completedAt: delivery.clearedFromCustomsAt
      ? checkSmartDate(
          delivery.clearedFromCustomsAt,
          INTERNATIONAL_DELIVERY_DATE_FORMAT,
          true
        )
      : '-',
    fromTo: (
      <div className="br-internatioanl-deliveries__fromTo">
        <span>
          {COUNTRIES_NAME_TO_CODE_MAPPER[delivery.pickupAddress.country.name]}
        </span>
        <ArrowRight />
        <span>
          {COUNTRIES_NAME_TO_CODE_MAPPER[delivery.dropOffAddress.country.name]}
        </span>
      </div>
    ),
    date: (
      <>
        {checkSmartDate(
          delivery.updatedAt ? delivery.updatedAt : delivery.createdAt
        )}
        <div className="br-international-delivery__column-subtext caption-lg">
          {dates(
            delivery.updatedAt ? delivery.updatedAt : delivery.createdAt,
            'h:mm A'
          )}
        </div>
      </>
    ),
    customVAT: (
      <div className="br-international-deliveries__custom-vat">
        <span>
          {
            getCurrency(
              delivery.customVAT?.value || 0,
              COUNTRIES_NAME_TO_CODE_MAPPER[
                delivery.dropOffAddress.country.name
              ]
            ).localized
          }
        </span>
        <span className="caption-lg">
          {delivery.customVAT?.type || delivery.customVAT}
        </span>
      </div>
    )
  };
};

export const getE2EStateLabel = (delivery) => {
  const { state, activeDomesticTN, trackingNumber } = delivery || {};
  let currentStateCode = state?.code;
  const isAtDestinationCountry = activeDomesticTN !== trackingNumber;

  if (state?.waitingForBusinessAction) {
    currentStateCode = INTERNATIONAL_DELIVERY_AWAITING_YOUR_ACTION_CODE;
  } else if (
    state?.code === INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE &&
    isAtDestinationCountry
  ) {
    currentStateCode =
      INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE_DESTINATION;
  } else if (
    state?.code === INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT &&
    isAtDestinationCountry
  ) {
    currentStateCode =
      INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT_DESTINATION;
  }

  return INTERNATIONAL_DELIVERY_E2E_STATES_LABEL[currentStateCode];
};

export const getAdjustedStateCode = (stateCode) => {
  const {
    RECEIVED_AT_WAREHOUSE,
    RECEIVED_AT_WAREHOUSE_DESTINATION,
    IN_TRANSIT_DESTINATION,
    IN_TRANSIT
  } = INTERNATIONAL_DELIVERY_STATUS_CODES;

  const { DESTINATION, SOURCE } = INTERNATIONAL_ORDER_LOCATION;

  const stateCodeNumber = Number(stateCode);

  if (stateCodeNumber === RECEIVED_AT_WAREHOUSE_DESTINATION) {
    return {
      state: RECEIVED_AT_WAREHOUSE,
      location: DESTINATION
    };
  } else if (stateCodeNumber === IN_TRANSIT_DESTINATION) {
    return {
      state: IN_TRANSIT,
      location: DESTINATION
    };
  } else if ([RECEIVED_AT_WAREHOUSE, IN_TRANSIT].includes(stateCodeNumber)) {
    return {
      state: stateCode,
      location: SOURCE
    };
  }

  return {
    state: stateCode
  };
};

export const formatReturnOption = (delivery) => {
  const { returnAddress, terminateIfFailed } = delivery || {};

  if (returnAddress?.fulfillmentHub) {
    return fmt({
      id: 'international_orders.deliveries.delivery_details.edit_return_location.options.return_to_fulfillment_label'
    });
  } else if (terminateIfFailed && returnAddress) {
    return fmt(
      {
        id: 'international_orders.deliveries.delivery_details.edit_return_location.options.return_to_origin_label'
      },
      {
        address: formatAddress(returnAddress)
      }
    );
  } else if (returnAddress) {
    return formatAddress(returnAddress);
  } else if (terminateIfFailed) {
    return fmt({
      id: 'international_orders.deliveries.delivery_details.edit_return_location.options.terminate_label'
    });
  }
};
