import { BASE } from '../apis';
import { notify } from 'components/Notify/Notify';

/**
 * get routes list by search query
 * @returns {Promise<null|string|string>}
 * @param options
 */
export const getRoutesList = async (options) => {
  try {
    const query = { ...options };
    if (query.states !== null) {
      query.states = query.states.map((el) => el.value);
    }
    if (query.warehouses) {
      query.warehouses = query.warehouses.map((el) => el.value);
    }
    const { data } = await BASE.get(`/routes`, { params: query });
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

/**
 * create route
 * @param payload
 * @returns {Promise<null|string|string>}
 */
export const createRoute = async (payload) => {
  try {
    const { data } = await BASE.post(`/routes`, payload);
    notify('Route created successfully', 'success');
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

export const getRoute = async (id) => {
  try {
    const { data } = await BASE.get(`/routes/${id}`);
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(data.message);
    return null;
  }
};

/**
 * get all online stars
 * @returns {Promise<null|*>}
 */
export const getAllOnlineStars = async () => {
  try {
    const { data } = await BASE.get('/users/stars/all');
    return data.message.stars.filter(
      (el) => el.starInfo.availabilityState === 'ONLINE'
    );
  } catch (e) {
    return null;
  }
};

/**
 * get route deliveries run sheet
 * @param routeId
 * @returns {Promise<null|string|string>}
 */
export const getRouteRunSheet = async ({ routeId }) => {
  try {
    const { data } = await BASE.get(
      `/routes/${routeId}?getRunSheetDetails=true`
    );
    return data;
  } catch (e) {
    return null;
  }
};

/**
 * update route
 * @param routeId
 * @param payload
 * @returns {Promise<null|string|string>}
 */
export const updateRoute = async ({ routeId, payload }) => {
  try {
    const { data } = await BASE.put(`/routes/${routeId}`, payload);
    return data;
  } catch (e) {
    const data = e.response?.data || e.message;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

/**
 * assign star to specific route
 * @param routeId
 * @param starId
 * @returns {Promise<null|string|string>}
 */
export const assignStarToRoute = async ({ routeId, starId }) => {
  try {
    const payload = {
      starId
    };
    const { data } = await BASE.put(`/routes/${routeId}/star`, payload);
    return data;
  } catch (e) {
    const data = e.response?.data?.message || e.message;
    notify(Array.isArray(data) ? data.map((el) => el).join(',') : data);
    return null;
  }
};

/**
 * delete route
 * @param routeId
 * @returns {Promise<null|string|string>}
 */
export const deleteRoute = async ({ routeId }) => {
  try {
    const { data } = await BASE.delete(`/routes/${routeId}`);
    return data.message;
  } catch (e) {
    notify(e.response.data.error);
    return null;
  }
};
