import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date-picker';
import { INTERNATIONAL_DELIVERY_MULTI_SEARCH_KEYS } from 'constants/international-orders';
import { cleanEmptyString } from 'utils/helpers';
import { disabledDate } from 'utils/international-order';
import { InternationalShippingContext } from 'contexts/international-shipping.context';

import BRButton from 'components/BRButton/BRButton';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';

const InternationalDeliveriesSearch = ({
  handleFilterDeliveries,
  isLoading
}) => {
  const { businesses } = useContext(InternationalShippingContext);

  const formRef = useRef();

  const intl = useIntl();

  const onFinish = (values) => {
    if (values.createdAt) {
      values['createdAtStart'] = dayjs(values.createdAt[0]).format(DATE_FORMAT);
      values['createdAtEnd'] = dayjs(values.createdAt[1]).format(DATE_FORMAT);

      delete values.createdAt;
    }

    Object.keys(cleanEmptyString(values)).forEach((key) => {
      if (INTERNATIONAL_DELIVERY_MULTI_SEARCH_KEYS.includes(key)) {
        values[key] = values[key]
          .trim()
          .replace(/,+\s*$/, '')
          .split(/[, ]+/);
      }
    });

    handleFilterDeliveries(cleanEmptyString(values));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleFilterDeliveries({});
  };

  return (
    <div className="br-international-shipping-search__container">
      <div className="br-international-shipping-search__title display-xs">
        {intl.formatMessage({ id: 'common.search' })}
      </div>
      <div className="br-international-shipping-search__content">
        <Form onFinish={onFinish} ref={formRef}>
          <div className="br-international-shipping-search__row ">
            <Form.Item
              name="trackingNumber"
              label={intl.formatMessage({
                id: 'international_orders.deliveries.search.tracking_number_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'international_orders.deliveries.search.tracking_number_placeholder'
                })}
              />
            </Form.Item>
            <Form.Item
              name="businessId"
              label={intl.formatMessage({
                id: 'business_details.business_info.business_name'
              })}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="name"
                placeholder={intl.formatMessage({
                  id: 'international_orders.pickups.search.business_placeholder'
                })}
                options={businesses}
                fieldNames={{ label: 'name', value: '_id' }}
              />
            </Form.Item>
            <Form.Item
              name="receiverPhone"
              label={intl.formatMessage({
                id: 'international_orders.deliveries.search.customer_phone_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'international_orders.deliveries.search.customer_phone_placeholder'
                })}
              />
            </Form.Item>
            <SearchDateRangePicker
              name="createdAt"
              label={intl.formatMessage({
                id: `international_orders.pickups.search.createdAt`
              })}
              disabledDate={disabledDate}
            />
          </div>
          <div className="br-international-shipping-search__actions">
            <BRButton
              onClick={handleResetFilters}
              label={intl.formatMessage({ id: 'common.clear_filters' })}
              disabled={isLoading}
            />
            <BRButton
              type="primary"
              htmlType="submit"
              label={intl.formatMessage({
                id: 'international_orders.pickups.search.apply_filters'
              })}
              disabled={isLoading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InternationalDeliveriesSearch;
