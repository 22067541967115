import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Table, Button } from 'antd';
import Icon from '@ant-design/icons';

import { DELIVERY_STATES_CODES } from 'constants/shipments';

import ViewDetails from './ViewDetails/ViewDetails';

import { ReactComponent as CorrectIcon } from 'assets/imgRevamp/correct.svg';
import { ReactComponent as WrongIcon } from 'assets/imgRevamp/wrong.svg';

import './RTOTab.less';

const RTOTab = ({
  intl,
  rtoDeliveries,
  routesIds,
  hubName,
  getUpdatedData
}) => {
  const [visible, setVisible] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  const { PICKED_UP, RETURNED_TO_BUSINESS } = DELIVERY_STATES_CODES;

  const columns = [
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.business'
      }),
      dataIndex: 'businessName',
      key: 'businessName'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.ofd'
      }),
      dataIndex: 'odfCount',
      key: 'odfCount',
      align: 'center'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.pending_orders'
      }),
      dataIndex: 'pendingOrdersCount',
      key: 'pendingOrdersCount',
      align: 'center'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.returned_orders'
      }),
      dataIndex: 'returnedOrdersCount',
      key: 'returnedOrdersCount',
      align: 'center'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.rejected_orders'
      }),
      dataIndex: 'rejectedOrdersCount',
      key: 'rejectedOrdersCount',
      align: 'center'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.otp'
      }),
      dataIndex: 'returnContactPerson',
      key: 'returnContactPerson',
      align: 'center',
      render: (returnContactPerson) => (
        <Icon component={returnContactPerson ? CorrectIcon : WrongIcon} />
      )
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.signature'
      }),
      dataIndex: 'signaturePhoto',
      key: 'signaturePhoto',
      align: 'center',
      render: (signaturePhoto) => (
        <Icon component={signaturePhoto ? CorrectIcon : WrongIcon} />
      )
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.national_id'
      }),
      dataIndex: 'nationalId',
      key: 'nationalId',
      align: 'center',
      render: (item) => <Icon component={item ? CorrectIcon : WrongIcon} />
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.actions'
      }),
      dataIndex: 'actions',
      key: 'actions',
      render: (businessData) => (
        <Button onClick={() => handleViewDetailsClick(businessData)}>
          {intl.formatMessage({
            id: 'hubs.packages_debrief.actions.view_details'
          })}
        </Button>
      )
    }
  ];

  const handleViewDetailsClick = (businessData) => {
    setViewDetailsData(businessData);
    setVisible(true);
  };

  useEffect(() => {
    setDataSource(
      rtoDeliveries?.map((businessData) => {
        const {
          _id,
          rtoNationalIdPhoto,
          deliveries,
          returnContactPerson,
          signaturePhoto
        } = businessData || {};

        let pendingOrdersCount = 0,
          returnedOrdersCount = 0,
          rejectedOrdersCount = 0;

        deliveries?.forEach((delivery) => {
          if (delivery?.state?.code === PICKED_UP) {
            pendingOrdersCount += 1;
          } else if (delivery?.state?.code === RETURNED_TO_BUSINESS) {
            returnedOrdersCount += 1;
          } else {
            rejectedOrdersCount += 1;
          }
        });

        return {
          businessName: _id?.businessName,
          odfCount: deliveries?.length,
          pendingOrdersCount,
          returnedOrdersCount,
          rejectedOrdersCount,
          returnContactPerson,
          signaturePhoto,
          nationalId: !!rtoNationalIdPhoto,
          actions: businessData
        };
      })
    );

    if (viewDetailsData) {
      setViewDetailsData((prev) =>
        rtoDeliveries.find(({ _id }) => prev._id.businessId === _id.businessId)
      );
    }
  }, [rtoDeliveries]);

  return (
    <div className="br-packages-debrief__rto-tab">
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      <ViewDetails
        visible={visible}
        setVisible={setVisible}
        businessData={viewDetailsData}
        routesIds={routesIds}
        hubName={hubName}
        getUpdatedData={getUpdatedData}
      />
    </div>
  );
};

export default injectIntl(RTOTab);
