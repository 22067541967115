import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Menu, Checkbox } from 'antd';
import isEmpty from 'lodash/isEmpty';

import {
  DELIVERY_COUNTS_MAPPING,
  INTERNATIONAL_DELIVERIES_COLUMNS,
  INTERNATIONAL_DELIVERIES_FILTERS,
  INTERNATIONAL_DELIVERY_STATUS_CODES,
  INTERNATIONAL_DELIVERY_UPDATE_TYPES
} from 'constants/international-orders';
import {
  exportInternationalOrder,
  printInternationalOrderAwb,
  searchInternationalOrders,
  updateInternationalOrders
} from 'services/international-orders';
import { formatInternationalDeliveryData } from 'utils/international-order';
import { showBill } from 'utils';
import { isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import InternationalDeliveriesSearch from './components/InternationalDeliveriesSearch/InternationalDeliveriesSearch';
import InternationalDeliveryDetails from './components/InternationalDeliveryDetails/InternationalDeliveryDetails';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import BRCardsFilter from 'components/BRCardsFilter/BRCardsFilter';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';
import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CheckCircle } from 'assets/bosta-icons/check-circle.svg';

import './InternationalDeliveries.less';

const InternationalDeliveries = () => {
  const [count, setCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clickedRowDetails, setClickedRowDetails] = useState({});
  const [toggleRowSelection, setToggleRowSelection] = useState(false);

  const refreshTable = useRef('');

  const intl = useIntl();

  const markAsReceivedAccess = isUserAuthorized(
    aclMatrix.DELIVERIES,
    [ACL_MATRIX.INTERNATIONAL_ORDER_MARK_AS_CLEARED_FROM_CUSTOMS],
    {
      countries: COUNTRIES_CODE_NAME.AE
    }
  );

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleFilterDeliveries = (filterValues) => {
    refreshTable.current({
      pageNumber: 1,
      filterValues: { state: selectedFilters.state, ...filterValues }
    });
  };

  const handleQuickFilterChange = (value) => {
    const stateFilter = { state: value ? [value] : undefined };

    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...stateFilter }));
    refreshTable.current({
      pageNumber: 1,
      filterValues: { ...selectedFilters, ...stateFilter }
    });
  };

  const formatDeliveriesData = (deliveries) =>
    deliveries.map((delivery) => ({
      ...formatInternationalDeliveryData({
        delivery,
        onTrackingNumberClick: handleShowDeliveryDetails
      }),
      actions: actionsDropdown(delivery)
    }));

  const getDeliveries = async ({ page, limit, filterValues }) => {
    setIsLoading(true);
    try {
      const payload = {
        page,
        limit,
        state: selectedFilters.state,
        ...filterValues
      };

      const { data } = await searchInternationalOrders(payload);

      setCount(data?.count || 0);
      setSelectedFilters({ state: selectedFilters.state, ...filterValues });
      setIsLoading(false);
      return {
        list: formatDeliveriesData(data?.list),
        total: data?.count || 0
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintAwb = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers
      };
      const { data } = await printInternationalOrderAwb(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleMarkAsReceivedAtDestination = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const payload = {
        updateType: INTERNATIONAL_DELIVERY_UPDATE_TYPES.CLEARED_FROM_CUSTOMS,
        trackingNumbers
      };
      await updateInternationalOrders(payload);
      notify(
        intl.formatMessage(
          {
            id: 'international_orders.deliveries.mark_as_received_at_destination_modal.success_message'
          },
          {
            count: trackingNumbers.length
          }
        ),
        'success'
      );
      setIsLoading(false);
      refreshTable.current({
        pageNumber: 1
      });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleMarkAsReceivedAtDestinationClick = (trackingNumbers) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'international_orders.deliveries.mark_as_received_at_destination_modal.title'
      }),
      content: intl.formatMessage({
        id: `international_orders.deliveries.mark_as_received_at_destination_modal.${
          trackingNumbers.length > 1 ? 'content_bulk' : 'content'
        }`
      }),
      confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
      confirmButtonType: 'primary',
      modalAction: () => handleMarkAsReceivedAtDestination(trackingNumbers)
    });
  };

  const handlePrintSelectedRows = () => {
    handlePrintAwb(selectedRows.join(','));
  };

  const handleBulkReceiveAtDestinationCountry = () => {
    handleMarkAsReceivedAtDestinationClick(selectedRows);
  };

  const handleExportDeliveries = async () => {
    if (isEmpty(selectedFilters) && !selectedRows.length) {
      return notify(
        intl.formatMessage({
          id: 'international_orders.deliveries.export_error'
        })
      );
    }

    try {
      const payload = {
        ...(selectedRows.length
          ? { trackingNumber: selectedRows }
          : selectedFilters)
      };

      await exportInternationalOrder(payload);
      notify(
        intl.formatMessage({
          id: 'international_orders.deliveries.export_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnDeliveryDetailsClose = () => {
    setClickedRowDetails({});
  };

  const handleShowDeliveryDetails = (record) => {
    setClickedRowDetails(record);
  };

  const handleSelectedRows = (rowKeys) => {
    setSelectedRows(rowKeys);
  };

  const handleResetSelection = () => {
    setToggleRowSelection(!toggleRowSelection);
  };

  const menuItems = (delivery) => {
    return (
      <Menu
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
        }}
      >
        <Menu.Item
          onClick={() => handlePrintAwb(delivery.trackingNumber)}
          disabled={isLoading}
          key="1"
        >
          <Printer />
          {intl.formatMessage({
            id: 'international_orders.deliveries.table_actions.print_awb'
          })}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            handleMarkAsReceivedAtDestinationClick([delivery.trackingNumber])
          }
          disabled={
            isLoading ||
            !markAsReceivedAccess ||
            delivery.state.code !==
              INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT
          }
          key="2"
        >
          <CheckCircle />
          {intl.formatMessage({
            id: 'international_orders.deliveries.table_actions.received_at_destination'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const actionsDropdown = (delivery) => {
    return (
      <Dropdown
        overlay={menuItems(delivery)}
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement
        }
        overlayClassName="br-international-deliveries__actions"
      >
        <BRButton
          type="table-actions"
          className="button-sm"
          suffixIcon={<Actions />}
        />
      </Dropdown>
    );
  };

  // Return Only Active Filter Count.
  const getCounts = () => {
    if (isLoading) {
      return {};
    }

    return {
      [DELIVERY_COUNTS_MAPPING[selectedFilters?.state || 0]]: count
    };
  };

  const renderTableTitle = () =>
    selectedRows?.length ? (
      <div className="br-international-table-selected-rows-container">
        <span className="br-international-table__title-count">
          <Checkbox indeterminate onChange={handleResetSelection} />
          {intl.formatMessage(
            { id: 'international_orders.pickups.selected' },
            {
              count: selectedRows?.length
            }
          )}
        </span>
        <BRButton
          label={intl.formatMessage(
            {
              id: 'international_orders.deliveries.table_actions.bulk_print'
            },
            {
              count: selectedRows.length
            }
          )}
          prefixIcon={<Printer />}
          onClick={handlePrintSelectedRows}
        />
        <BRButton
          label={intl.formatMessage({
            id: 'international_orders.deliveries.table_actions.received_at_destination'
          })}
          prefixIcon={<CheckCircle />}
          onClick={handleBulkReceiveAtDestinationCountry}
          disabled={!markAsReceivedAccess}
        />
        <BRButton
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={handleResetSelection}
        />
      </div>
    ) : (
      intl.formatMessage(
        { id: 'international_orders.deliveries.table_title' },
        {
          count
        }
      )
    );

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-international-deliveries__container">
        <BRCardsFilter
          items={INTERNATIONAL_DELIVERIES_FILTERS(getCounts())}
          defaultActive={''}
          onFilterChange={handleQuickFilterChange}
        />
        <InternationalDeliveriesSearch
          handleFilterDeliveries={handleFilterDeliveries}
          isLoading={isLoading}
        />
        <BRTable
          title={renderTableTitle()}
          columns={INTERNATIONAL_DELIVERIES_COLUMNS()}
          listFunction={getDeliveries}
          showFilter={false}
          allowSelection
          handleSelectedRows={handleSelectedRows}
          shareMethods={acceptMethods}
          exportListFileFunction={handleExportDeliveries}
          resetSelection={toggleRowSelection}
        />
        <InternationalDeliveryDetails
          open={!isEmpty(clickedRowDetails)}
          onClose={handleOnDeliveryDetailsClose}
          {...clickedRowDetails}
        />
      </div>
    </LoadingWrapper>
  );
};

export default InternationalDeliveries;
