import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date-picker';
import { SLLR_START_DATE } from 'constants/sllr-analytics';

export const SllrWrapper = createContext({});

export const SllrProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    startDate: dayjs(SLLR_START_DATE).format(DATE_FORMAT),
    endDate: dayjs().format(DATE_FORMAT)
  });

  return (
    <SllrWrapper.Provider
      value={{
        selectedCountry,
        dateFilter,
        isLoading,
        setSelectedCountry,
        setDateFilter,
        setIsLoading
      }}
    >
      {children}
    </SllrWrapper.Provider>
  );
};
