import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import './Banner.less';

export const TYPE_CLASS_NAMES = {
  warning: 'br-banner-warning',
  danger: 'br-banner-danger',
  success: 'br-banner-success',
  info: 'br-banner-info'
};

const Banner = ({
  content,
  icon,
  isAntIcon = false,
  title,
  subText,
  buttonProps,
  buttonText,
  className,
  parentClassName,
  type = 'warning',
  dismissIcon = false,
  onDismissIconClick,
  changeButtonToText = false,
  extraRender,
  bannerFixed = false
}) => {
  const typeClass = TYPE_CLASS_NAMES[type.toLowerCase()];

  return (
    <div className={classnames('br-banner-content__parent', parentClassName)}>
      <div
        className={classnames('br-banner-content', typeClass, className, {
          'br-banner-fixed': bannerFixed
        })}
      >
        <div className="br-banner-content__text-container">
          {icon ? isAntIcon ? icon : <img alt="" src={icon} /> : null}
          <div className="br-banner-content__text">
            <div className="d-flex">
              <div className="body-medium">{title}</div>
              <div className="br-banner-content__text__dismiss-icon-responsive">
                {dismissIcon && (
                  <CloseOutlined
                    className="br-banner-content__text__dismiss-icon"
                    onClick={onDismissIconClick}
                  />
                )}
              </div>
            </div>
            {subText && <p>{subText}</p>}
          </div>
        </div>
        {buttonText && (
          <div
            className={classnames('br-banner-content__button', {
              'br-banner-content__button-text': changeButtonToText
            })}
          >
            <BRButton label={buttonText} {...buttonProps} />
          </div>
        )}
        {dismissIcon && (
          <CloseOutlined
            className="br-banner-content__dismiss-icon"
            onClick={onDismissIconClick}
          />
        )}
        {extraRender && extraRender}
      </div>
    </div>
  );
};

export default injectIntl(withRouter(Banner));
