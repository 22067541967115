import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import './FailureModal.less';

const FailureModal = ({
  intl,
  close,
  icon,
  title,
  message,
  confirmBtnProps,
  cancelBtnProps,
  onConfirm = () => {},
  onClose = () => {},
  closeTimer,
  ...props
}) => {
  const [counter, setCounter] = useState(closeTimer);

  let timer;

  const onOk = () => {
    onConfirm();
    close();
  };

  const onCancel = () => {
    onClose();
    close();
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (closeTimer) {
      if (counter === 0) {
        onCancel();
      } else {
        timer = setTimeout(() => setCounter(counter - 1), 1000);
      }
    }
  }, [counter]);

  return (
    <Modal
      title={null}
      width={400}
      footer={null}
      wrapClassName="br-failure-modal"
      onCancel={onCancel}
      {...props}
    >
      {icon}
      {title && <div className="br-failure-modal__title">{title}</div>}
      {message && <div className="br-failure-modal__message">{message}</div>}
      <div className="br-failure-modal__actions">
        {confirmBtnProps && <BRButton onClick={onOk} {...confirmBtnProps} />}
        {cancelBtnProps && (
          <BRButton
            {...cancelBtnProps}
            onClick={onCancel}
            label={
              <>
                {cancelBtnProps.label}
                {closeTimer && (
                  <span className="br-failure-modal__timer">
                    ({counter}
                    {intl.formatMessage({
                      id: 'common.seconds_shortcut'
                    })}
                    )
                  </span>
                )}
              </>
            }
          />
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(FailureModal);
