import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import StarsListing from 'components/Stars/components/StarsListing/StarsListing';
import CreateEditStar from 'components/Stars/components/CreateEditStar/CreateEditStar';
import StarMap from 'components/Stars/components/StarMap/StarMap';
import StarSalary from './components/StarSalary/StarSalary';
import StarDetailsContainer from 'components/Stars/components/StarDetailsContainer/StarDetailsContainer';
import BonusesAndDeductions from 'components/Stars/components/BonusesAndDeductions/BonusesAndDeductions';
import VendorsListing from 'components/Stars/components/Vendors/VendorsListing/VendorsListing';
import CreateEditVendor from 'components/Stars/components/Vendors/CreateEditVendor/CreateEditVendor';

const ROUTES = [
  {
    path: '/stars/all',
    component: StarsListing,
    access: aclMatrix.STARS,
    permission: [ACL_MATRIX.STARS_LIST]
  },
  {
    path: '/stars/all/:id',
    name: 'Stars',
    component: StarDetailsContainer,
    exact: false,
    access: [...aclMatrix.STARS],
    permission: [ACL_MATRIX.STARS_GET]
  },
  {
    path: '/stars/all/:id/edit',
    component: CreateEditStar,
    access: [...aclMatrix.STARS_EDIT],
    permission: [ACL_MATRIX.STARS_UPDATE]
  },
  {
    path: '/stars/star-salary',
    component: StarSalary,
    access: aclMatrix.STARS_CYCLES,
    permission: [ACL_MATRIX.STARS_GET_SALARY]
  },
  {
    path: '/stars/bonuses-and-deductions',
    component: BonusesAndDeductions,
    access: aclMatrix.BONUSES_AND_DEDUCTIONS,
    permission: [ACL_MATRIX.STARS_GET_SALARY]
  },
  {
    path: '/stars/star-map',
    component: StarMap,
    access: aclMatrix.STARS_MAP,
    permission: [ACL_MATRIX.STAR_ACTIONS_LIST]
  },
  {
    path: '/stars/vendors',
    component: VendorsListing,
    access: aclMatrix.VENDOR,
    permission: [ACL_MATRIX.VENDORS_LIST]
  },
  {
    path: '/stars/stars-performance',
    component: StarMap,
    access: aclMatrix.STARS,
    permission: [ACL_MATRIX.STARS_GET_PERFORMANCE]
  },
  {
    path: '/stars/create-star',
    component: CreateEditStar,
    access: aclMatrix.STARS_CREATE,
    permission: [ACL_MATRIX.STARS_CREATE]
  },
  {
    path: '/stars/vendors/edit-vendor/:id',
    component: CreateEditVendor,
    access: [...aclMatrix.VENDOR],
    permission: [ACL_MATRIX.VENDORS_LIST]
  },
  {
    path: '/stars/vendors/create-vendor',
    component: CreateEditVendor,
    access: [...aclMatrix.VENDOR],
    permission: [ACL_MATRIX.VENDORS_LIST]
  }
];
class StarsRoutes extends React.Component {
  getDefaultPath = () => {
    let defaultPathName = '';

    ROUTES.some((route) => {
      if (isUserAuthorized(route.access, route.permission)) {
        defaultPathName = route.path;
        return true;
      }
    });

    return defaultPathName;
  };

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        {ROUTES.map(
          ({ path, component, access, permission, exact = true }, index) =>
            isUserAuthorized(access, permission) && (
              <Route
                exact={exact}
                key={index}
                path={path}
                component={component}
              />
            )
        )}
        <Redirect from={path} to={this.getDefaultPath()} />
      </Switch>
    );
  }
}

export default injectIntl(withRouter(StarsRoutes));
