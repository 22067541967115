import React from 'react';
import { Descriptions, Typography } from 'antd';
import classnames from 'classnames';

import './BRInformationTable.less';

// tableCells = [
//   {
//     key: 'puid',
// label: intl.formatMessage({ id: 'pickups.pickup_info.puid' }),
// render: (puid) => {
//   return (
//     <>
//       <p>{puid}</p>
//       <p>
//         <Tag
//           color={color}
//           className={`br-pickups-status__tag-color-${color}`}
//         >
//           {data?.newState}
//         </Tag>
//       </p>
//     </>
//   );
// }
// ];
// totalCol={5} ===> number
// data = {
//   puid: 12344,
//   state: 'pickedup',
//   business: { address: { city: { name: 'Cairo' } } }
// };

const getValueOfNestedKey = (obj, keys) => {
  return keys.reduce((obj, key) => obj && obj[key], obj);
};

const BRInformationTable = ({
  totalCol,
  dataSource,
  cells,
  title,
  className
}) => {
  return (
    <div className={classnames('br-information-table', className)}>
      <Descriptions
        layout="vertical"
        className="br-information-table__listing"
        title={title}
        bordered
        column={totalCol}
      >
        {cells?.map((item, index) => {
          if (item.key === null) return;
          const value = item.key
            ? getValueOfNestedKey(
                dataSource,
                Array.isArray(item.key) ? item.key : [item.key]
              ) != null
              ? getValueOfNestedKey(
                  dataSource,
                  Array.isArray(item.key) ? item.key : [item.key]
                )
              : 'N/A'
            : dataSource;

          return (
            <Descriptions.Item key={index} span={item.colSpan}>
              <>
                <p className="br-information-table__listing__field-label">
                  {item.label}
                </p>
                <div className="br-information-table__listing__field-value">
                  <Typography.Text type={item?.textColor}>
                    {item.render
                      ? item.render(value, dataSource) || 'N/A'
                      : value.toString()}
                  </Typography.Text>
                </div>
              </>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </div>
  );
};

export default BRInformationTable;
