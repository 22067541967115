import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button, Divider } from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  getBalanceReceipt,
  getInternationalBalanceReceipt,
  exportCompensation
} from 'services/wallet';
import { getBusiness } from 'actions/BusniessesAction';
import { downloadAsXlsx } from 'utils/download';
import { getFormattedNumber } from 'utils/number';
import {
  PAYMENT_TYPE,
  BALANCE_CATEGORIES,
  INTERNATIONAL_COUNTRY_CURRENCY_ID
} from 'constants/wallet';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { currentUserCountry } from 'common/countries/countries-mapping';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './BalanceReceipt.less';

class Receipt extends React.Component {
  state = {
    isBalanceReceiptLoading: false,
    balanceReceipt: null
  };

  async componentDidMount() {
    this.setState({ isBalanceReceiptLoading: true });

    const { transactionId, category, businessId, international } = this.props;

    try {
      const {
        bankInfo,
        secondBankInfo,
        country,
        paymentInfo: { paymentType } = {},
        name: businessName
      } = await getBusiness(businessId);

      const bank =
        international && country?.code === COUNTRIES_CODE_NAME.EG
          ? secondBankInfo
          : bankInfo;

      this.setState({
        businessName,
        accountNumber: bank?.accountNumber,
        ibanNumber: bank?.ibanNumber,
        paymentType
      });
    } catch (error) {
      notify(error.message);
    }

    try {
      const { data: balanceReceipt } = international
        ? await getInternationalBalanceReceipt({
            transactionId,
            businessId
          })
        : await getBalanceReceipt({
            transactionId,
            category,
            businessId
          });
      this.setState({ balanceReceipt });
    } catch (error) {
      notify(error.message);
    }

    this.setState({ isBalanceReceiptLoading: false });
  }

  handleViewBreakdown = () => {
    const { businessId, international, transactionId } = this.props;

    const url = international
      ? '/wallet-international/cycles'
      : '/wallet/cycles';

    const param = international ? 'cashOutId' : 'transaction-id';
    window.open(
      `${url}?${param}=${transactionId}&business-id=${businessId}`,
      '_blank'
    );
  };

  handleDownloadCompensation = async () => {
    this.setState({ isBalanceReceiptLoading: true });
    const { balanceReceipt } = this.state;
    const { businessId } = this.props;

    const payload = {
      cashout_id: balanceReceipt?.transaction_id
    };

    try {
      const { message } = await exportCompensation(payload, businessId);

      notify(message, 'success');
    } catch (error) {
      notify(error.message || fmt({ id: `wallet.export_error` }));
    }

    this.setState({ isBalanceReceiptLoading: false });
  };

  render() {
    const { intl, close, transactionId, category, international } = this.props;
    const {
      isBalanceReceiptLoading,
      balanceReceipt,
      businessName,
      accountNumber,
      paymentType
    } = this.state;

    const {
      transaction_id,
      cashCollectionCycle,
      bostaFeesCycle,
      receiptData,
      transactionDate,
      amount,
      openingBalance,
      closingBalance,
      cod_start_date,
      cod_end_date
    } = balanceReceipt || {};

    const {
      pickup_fees,
      borrow,
      rejected_cashout,
      fawry_cashout,
      promotion,
      packing_material,
      compensation,
      bosta_fees_cycle,
      cash_collection_cycle
    } = receiptData || {};

    const collectedAmountCycle = international
      ? {
          num: cashCollectionCycle?.numberOfOrders,
          amount: cashCollectionCycle?.amount
        }
      : {
          num: cash_collection_cycle?.number_of_orders,
          amount: cash_collection_cycle?.amount
        };

    const bostaCycle = international
      ? {
          num: bostaFeesCycle?.numberOfOrders,
          amount: bostaFeesCycle?.amount
        }
      : {
          num: bosta_fees_cycle?.number_of_orders,
          amount: bosta_fees_cycle?.amount
        };

    // const paymentType = PAYMENT_TYPE.PRE_PAID;
    const currencyId = international
      ? INTERNATIONAL_COUNTRY_CURRENCY_ID[currentUserCountry.codeName]
      : COUNTRY_CURRENCY;

    return (
      <Modal
        {...this.props}
        wrapClassName="br-balance-receipt"
        width={null}
        title={null}
        footer={null}
        onCancel={close}
      >
        <LoadingWrapper loading={isBalanceReceiptLoading}>
          <div className="display-flex align-center justify-space-between">
            <span className="br-balance-receipt__title">
              {category === BALANCE_CATEGORIES.CASH_OUT
                ? intl.formatMessage(
                    {
                      id: 'wallet.balance.table.receipt.title'
                    },
                    {
                      category: intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.cashout_title'
                      })
                    }
                  )
                : intl.formatMessage(
                    {
                      id: 'wallet.balance.table.receipt.title'
                    },
                    {
                      category: intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.topup_title'
                      })
                    }
                  )}
            </span>
            <Icon
              className="br-balance-receipt__close-icon"
              component={CloseIcon}
              onClick={close}
            />
          </div>
          <Divider className="br-balance-receipt__header-divider" />
          <div className="display-flex br-balance-receipt__row br-balance-receipt__receipt-date">
            <div className="display-flex flex-dir-col">
              <span className="br-balance-receipt__label">
                {intl.formatMessage({
                  id: 'wallet.balance.table.receipt.receipt_id'
                })}
              </span>
              <span className="br-balance-receipt__value">
                #{transactionId}
              </span>
            </div>
            <div className="display-flex flex-dir-col">
              <span className="br-balance-receipt__label">
                {intl.formatMessage({
                  id: 'wallet.balance.table.receipt.date'
                })}
              </span>
              <span className="br-balance-receipt__value">
                {dayjs(transactionDate).format('DD MMM, YYYY')}
              </span>
            </div>
          </div>
          <div className="display-flex br-balance-receipt__row">
            <div className="display-flex flex-dir-col">
              <span className="br-balance-receipt__label">
                {category === BALANCE_CATEGORIES.CASH_OUT
                  ? intl.formatMessage({
                      id: 'wallet.balance.table.receipt.paid_to'
                    })
                  : intl.formatMessage({
                      id: 'wallet.balance.table.receipt.paid_from'
                    })}
              </span>
              <span className="br-balance-receipt__value">{businessName}</span>
            </div>
            <div className="display-flex flex-dir-col">
              <span className="br-balance-receipt__label">
                {intl.formatMessage({
                  id: 'wallet.balance.table.receipt.bank_account'
                })}
              </span>
              <span className="br-balance-receipt__value">{accountNumber}</span>
            </div>
          </div>
          <Divider />
          <div className="display-flex justify-space-between br-balance-receipt__balance">
            <div className="display-flex flex-dir-col">
              <span className="br-balance-receipt__label">
                {intl.formatMessage({
                  id: 'wallet.balance.table.receipt.transfer_amount'
                })}
              </span>
              <span className="br-balance-receipt__amount br-balance-receipt__value">
                {intl.formatMessage(
                  {
                    id: currencyId
                  },
                  { cod: getFormattedNumber(amount) }
                )}
              </span>
            </div>
            <div className="display-flex">
              <div className="display-flex flex-dir-col br-balance-receipt__balance-box">
                <span className="br-balance-receipt__label">
                  {intl.formatMessage({
                    id: 'wallet.balance.table.receipt.opening_balance'
                  })}
                </span>
                <span className="br-balance-receipt__value">
                  {intl.formatMessage(
                    {
                      id: currencyId
                    },
                    {
                      cod: getFormattedNumber(openingBalance)
                    }
                  )}
                </span>
              </div>
              <div className="display-flex flex-dir-col br-balance-receipt__balance-box">
                <span className="br-balance-receipt__label">
                  {intl.formatMessage({
                    id: 'wallet.balance.table.receipt.closing_balance'
                  })}
                </span>
                <span className="br-balance-receipt__value">
                  {intl.formatMessage(
                    {
                      id: currencyId
                    },
                    {
                      cod: getFormattedNumber(closingBalance)
                    }
                  )}
                </span>
              </div>
            </div>
          </div>
          {category === BALANCE_CATEGORIES.TOP_UP ? (
            <span className="br-balance-receipt__top-up-text">
              {intl.formatMessage({
                id: 'wallet.balance.table.receipt.topup_text'
              })}
            </span>
          ) : (
            <div>
              <div className="br-balance-receipt__box">
                <div className="display-flex br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.collected_cash_title'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage(
                        {
                          id: 'wallet.balance.table.receipt.collected_cash_sub_title'
                        },
                        {
                          numberOfOrders: collectedAmountCycle?.num

                          // startDate: moment(
                          //   cod_start_date
                          // ).format('DD MMM, YYYY'),
                          // endDate: moment(cod_end_date).format(
                          //   'DD MMM, YYYY'
                          // )
                        }
                      )}
                    </span>
                  </div>
                  <div className="display-flex justify-space-between align-center">
                    <div className="display-flex">
                      <span className="br-balance-receipt__label">
                        {intl.formatMessage(
                          {
                            id: currencyId
                          },
                          {
                            cod: getFormattedNumber(
                              collectedAmountCycle?.amount
                            )
                          }
                        )}
                      </span>
                    </div>
                    {paymentType === PAYMENT_TYPE.POST_PAID && (
                      <Button
                        className="br-balance-receipt__view-breakdown-button"
                        type="link"
                        onClick={this.handleViewBreakdown}
                      >
                        {intl.formatMessage({
                          id: 'wallet.view_breakdown'
                        })}
                      </Button>
                    )}
                  </div>
                </div>
                {paymentType !== PAYMENT_TYPE.POST_PAID && (
                  <>
                    <Divider orientation="right">
                      <Button
                        type="link"
                        onClick={this.handleViewBreakdown}
                        className="br-balance-receipt__view-breakdown-button"
                      >
                        {intl.formatMessage({
                          id: 'wallet.view_breakdown'
                        })}
                      </Button>
                    </Divider>
                    <div className="display-flex br-balance-receipt__row">
                      <div className="display-flex flex-dir-col">
                        <span className="br-balance-receipt__label">
                          {intl.formatMessage({
                            id: 'wallet.balance.table.balance_categories.bosta_fees_title'
                          })}
                        </span>
                        <span className="br-balance-receipt__value">
                          {intl.formatMessage(
                            {
                              id: 'wallet.balance.table.receipt.collected_cash_sub_title'
                            },
                            {
                              numberOfOrders: bostaCycle?.num
                              // startDate: moment(
                              //   cod_start_date
                              // ).format('DD MMM, YYYY'),
                              // endDate: moment(
                              //   cod_end_date
                              // ).format('DD MMM, YYYY')
                            }
                          )}
                        </span>
                      </div>
                      <div className="display-flex">
                        <span className="br-balance-receipt__label br-balance-receipt__negative">
                          {intl.formatMessage(
                            {
                              id: currencyId
                            },
                            {
                              cod: `-${getFormattedNumber(bostaCycle?.amount)}`
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {pickup_fees?.amount && (
                <div className="br-balance-receipt__box br-balance-receipt__box-pickup">
                  <div>
                    <p className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.receipt.pickup_fees'
                      })}
                    </p>
                  </div>
                  <p className="br-balance-receipt__negative br-balance-receipt__label">
                    {intl.formatMessage(
                      {
                        id: COUNTRY_CURRENCY
                      },
                      {
                        cod: `-${pickup_fees.amount}`
                      }
                    )}
                  </p>
                </div>
              )}
              {!!rejected_cashout && rejected_cashout?.amount > 0 && (
                <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.rejected_cashout_title'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.receipt.rejected_transfer'
                      })}
                    </span>
                  </div>
                  <div className="display-flex justify-space-between align-center">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage(
                        {
                          id: currencyId
                        },
                        {
                          cod: getFormattedNumber(rejected_cashout?.amount)
                        }
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!!compensation && compensation?.amount > 0 && (
                <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.compensation_title'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage(
                        {
                          id: 'wallet.balance.table.receipt.compensation_sub_title'
                        },
                        {
                          numberOfOrders: compensation?.number_of_orders
                          // startDate: moment(
                          //   cod_start_date
                          // ).format('DD MMM, YYYY'),
                          // endDate: moment(cod_end_date).format(
                          //   'DD MMM, YYYY'
                          // )
                        }
                      )}
                    </span>
                  </div>
                  <div className="display-flex justify-space-between align-center">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage(
                        {
                          id: currencyId
                        },
                        {
                          cod: getFormattedNumber(compensation?.amount)
                        }
                      )}
                    </span>
                    <Button
                      type="link"
                      onClick={this.handleDownloadCompensation}
                    >
                      {intl.formatMessage({
                        id: 'wallet.download_sheet'
                      })}
                    </Button>
                  </div>
                </div>
              )}
              {!!fawry_cashout && fawry_cashout?.amount && (
                <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.fawry_cashout_title'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.fawry_cashout_sub_title'
                      })}
                    </span>
                  </div>
                  <div className="display-flex justify-space-between align-center">
                    <span className="br-balance-receipt__label br-balance-receipt__negative">
                      {intl.formatMessage(
                        {
                          id: currencyId
                        },
                        {
                          cod: `-${getFormattedNumber(fawry_cashout?.amount)}`
                        }
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!!promotion && promotion?.amount && (
                <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.promotion_title'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.promotion_sub_title'
                      })}
                    </span>
                  </div>
                  <div className="display-flex justify-space-between align-center">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage(
                        {
                          id: currencyId
                        },
                        {
                          cod: getFormattedNumber(promotion?.amount)
                        }
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!!borrow && !!borrow?.amount && (
                <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                  <div className="display-flex flex-dir-col">
                    <span className="br-balance-receipt__label">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.borrow'
                      })}
                    </span>
                    <span className="br-balance-receipt__value">
                      {intl.formatMessage({
                        id: 'wallet.balance.table.balance_categories.borrow_sub_title'
                      })}
                    </span>
                  </div>
                  <div className="display-flex">
                    <span className="br-balance-receipt__label br-balance-receipt__negative">
                      {intl.formatMessage(
                        {
                          id: currencyId
                        },
                        {
                          cod: `-${getFormattedNumber(borrow?.amount)}`
                        }
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!!packing_material &&
                !!packing_material?.amount &&
                paymentType !== PAYMENT_TYPE.POST_PAID && (
                  <div className="display-flex br-balance-receipt__box br-balance-receipt__row">
                    <div className="display-flex flex-dir-col">
                      <span className="br-balance-receipt__label">
                        {intl.formatMessage({
                          id: 'wallet.balance.table.balance_categories.packing_material_title'
                        })}
                      </span>
                      <span className="br-balance-receipt__value">
                        {intl.formatMessage(
                          {
                            id: 'wallet.balance.table.receipt.packing_material_fees_sub_title'
                          },
                          {
                            // startDate: moment(
                            //   cod_start_date
                            // ).format('DD MMM, YYYY'),
                            // endDate: moment(
                            //   cod_end_date
                            // ).format('DD MMM, YYYY')
                          }
                        )}
                      </span>
                    </div>
                    <div className="display-flex">
                      <span className="br-balance-receipt__label br-balance-receipt__negative">
                        {intl.formatMessage(
                          {
                            id: currencyId
                          },
                          {
                            cod: `-${getFormattedNumber(
                              packing_material?.amount
                            )}`
                          }
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          )}
        </LoadingWrapper>
      </Modal>
    );
  }
}

export default injectIntl(Receipt);
