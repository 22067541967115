import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Input, Button, Dropdown, Menu } from 'antd';

import { TAB_KEYS } from 'constants/debrief';
import { downloadAsPdf } from 'utils/download';
import { openModal } from 'utils/modal';
import { editOrder, printAirwaybill } from 'services/shipments';
import { receivePackagesAtHub } from 'services/hubs';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import SwappingTables from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/SwappingTables/SwappingTables';
import {
  CreateNewSecuritySeal,
  receivePickupsConfirmation
} from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/TableActionsModals/TableActionsModals';

import './SohoPickupTab.less';

const SohoPickupTab = ({
  starData,
  setIsConfirmReceivingDisabled,
  handleConfirmReceiving,
  getPickups,
  intl
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [securitySealValue, setSecuritySealValue] = useState(null);
  const [pickedupSohoDeliveries, setPickedupSohoDeliveries] = useState([]);
  const [scannedSohoDeliveries, setScannedSohoDeliveries] = useState([]);

  useEffect(() => {
    setPickedupSohoDeliveries(starData?.pickedupSohoDeliveries);
    setScannedSohoDeliveries([]);
  }, [starData]);

  useEffect(() => {
    setIsConfirmReceivingDisabled(isLoading || !scannedSohoDeliveries.length);
  }, [scannedSohoDeliveries]);

  useEffect(() => {
    if (handleConfirmReceiving) {
      handleConfirmReceiving[TAB_KEYS.SOHO_PICKUPS] =
        handleConfirmReceivingOrders;
    }
  });

  const handleConfirmReceivingOrders = async () => {
    setIsLoading(true);
    try {
      openModal(receivePickupsConfirmation, {
        messages: [
          `${scannedSohoDeliveries.length} ${intl.formatMessage({
            id: 'hubs.packages_debrief.soho_pickups_tab.soho_pickups'
          })}`
        ],
        onSuccess: async () => {
          const payload = {
            updateType: 'receiveMany',
            deliveries: scannedSohoDeliveries
          };
          try {
            const result = await receivePackagesAtHub(payload);
            if (result?.failedDeliveries) {
              notify(
                intl.formatMessage(
                  {
                    id: `hubs.packages_debrief.soho_pickups_tab.${
                      result?.failedDeliveries.length === 1
                        ? 'delivery'
                        : 'deliveries'
                    }_receiving_failed`
                  },
                  {
                    TN: result?.failedDeliveries.join(', ')
                  }
                )
              );
            } else {
              notify(
                intl.formatMessage({
                  id: 'hubs.packages_debrief.soho_pickups_tab.deliveries_received_successfully'
                }),
                'success'
              );
            }
          } catch (error) {
            notify(error.message);
          }
          await getPickups();
        }
      });
    } catch (error) {
      notify(error.message, 'error', true);
    }
    setIsLoading(false);
  };

  const onChangeSecuritySealInput = ({ target: { value } }) => {
    setSecuritySealValue(value);
    checkSecuritySealExistance(value);
  };

  const handleSecuritySealUpdate = async ({ newSecuritySeal, deliveryId }) => {
    setIsLoading(true);
    try {
      const payload = {
        id: deliveryId,
        updateData: { securitySealCode: newSecuritySeal },
        updateType: 'update'
      };
      await editOrder(payload);
      await getPickups();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const checkSecuritySealExistance = useCallback(
    debounce((sealNumber) => {
      if (sealNumber) {
        sealNumber = sealNumber.trim();
        const isSealNumberFound = pickedupSohoDeliveries.findIndex(
          (delivery) =>
            delivery.securitySeal.code === sealNumber &&
            delivery.securitySeal.verified
        );
        if (isSealNumberFound > -1) {
          setPickedupSohoDeliveries((prev) => {
            const prevDeliveries = prev.filter(
              (delivery) => delivery.securitySeal.code !== sealNumber
            );

            return [...prevDeliveries];
          });
          setScannedSohoDeliveries((prev) => [
            ...prev,
            pickedupSohoDeliveries[isSealNumberFound]
          ]);
        } else {
          notify(
            intl.formatMessage({
              id: 'hubs.packages_debrief.soho_pickups_tab.security_seal_not_found'
            })
          );
        }

        setSecuritySealValue(null);
      }
    }, 500),
    [scannedSohoDeliveries]
  );

  const menuItems = (item) => {
    return (
      <Menu onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item
          onClick={() => {
            openModal(CreateNewSecuritySeal, {
              deliveryId: item?._id,
              onSuccess: (delivery) => handleSecuritySealUpdate(delivery)
            });
          }}
        >
          {intl.formatMessage({
            id: 'hubs.packages_debrief.soho_pickups_tab.replace_new_security_seal'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const sohoPickupsColumns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: '',

      render: (item) => <>{item?.trackingNumber}</>
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.soho_pickups_tab.seal_number'
      }),
      dataIndex: '',
      render: (item) => <>{item?.securitySeal?.code}</>
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.soho_pickups_tab.action'
      }),
      dataIndex: '',
      render: (item) => (
        <div className="br-soho-pickups__actions">
          <Link target="_blank" to={`/deliveries/${item._id}/details`}>
            <Button className="br-soho-pickups-button">
              {intl.formatMessage({
                id: 'hubs.packages_debrief.actions.view_details'
              })}
            </Button>
          </Link>
          <Dropdown
            overlay={() => menuItems(item)}
            placement="bottomRight"
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement
            }
          >
            <Button className="br-soho-pickups__actions-button">...</Button>
          </Dropdown>
        </div>
      )
    }
  ];

  const handlePrintAWB = async (deliveryIds, trackingNumber) => {
    setIsLoading(true);
    try {
      const { data } = await printAirwaybill(deliveryIds);
      downloadAsPdf(data, trackingNumber);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintAllAWB = async () => {
    const scannedDeliveryIds = scannedSohoDeliveries.map(
      (delivery) => delivery?._id
    );
    if (!!scannedDeliveryIds.length) {
      handlePrintAWB(scannedDeliveryIds.join(','));
    }
  };

  const sohoReceivedPickupsColumns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: '',
      render: (item) => <>{item?.trackingNumber}</>
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.soho_pickups_tab.seal_number'
      }),
      dataIndex: '',
      render: (item) => <>{item?.securitySeal?.code}</>
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.soho_pickups_tab.action'
      }),
      dataIndex: '',
      render: (item) => (
        <div className="br-soho-pickups__actions">
          <Button
            className="br-soho-pickups-button"
            disabled={isLoading}
            onClick={() => handlePrintAWB(item._id, item.trackingNumber)}
          >
            {intl.formatMessage({
              id: 'hubs.packages_debrief.actions.print_awb'
            })}
          </Button>
        </div>
      )
    }
  ];

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-soho-pickups-container">
        <div className="br-soho-pickups-scan-label">
          {intl.formatMessage({
            id: 'hubs.packages_debrief.soho_pickups_tab.security_seal'
          })}
        </div>
        <Input
          className="br-soho-pickups-scan-input"
          placeholder={intl.formatMessage({
            id: 'hubs.packages_debrief.soho_pickups_tab.scan_security_seal'
          })}
          onChange={onChangeSecuritySealInput}
          value={securitySealValue}
        />
        <div className="br-soho-pickups-table-container">
          <SwappingTables
            title1={
              <div>{`${intl.formatMessage({
                id: 'hubs.packages_debrief.soho_pickups_tab.pickups'
              })} (${pickedupSohoDeliveries?.length || 0})`}</div>
            }
            title2={
              <div className="br-soho-pickups-received-table-header">
                <div className="br-soho-pickups-received-table-title">
                  {`${intl.formatMessage({
                    id: 'hubs.packages_debrief.soho_pickups_tab.received'
                  })} (${scannedSohoDeliveries.length} / 
                  ${starData?.pickedupSohoDeliveries?.length || 0})`}
                </div>
                <div>
                  <Button
                    className="br-soho-pickups-print-all-awbs"
                    disabled={!scannedSohoDeliveries?.length}
                    onClick={handlePrintAllAWB}
                  >
                    {intl.formatMessage({
                      id: 'hubs.packages_debrief.soho_pickups_tab.print_all_awb'
                    })}
                  </Button>
                </div>
              </div>
            }
            columns={sohoPickupsColumns}
            recievedColumns={sohoReceivedPickupsColumns}
            list={pickedupSohoDeliveries}
            scannedList={scannedSohoDeliveries}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(SohoPickupTab);
