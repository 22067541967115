import { useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Button } from 'antd';

import { CANCEL_ORDER_UPDATE_TYPE } from 'constants/Deliveries';
import { cancelOrder } from 'services/shipments';

import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import { notify } from 'components/Notify/Notify';

import './CancelOrderModal.less';

const CancelOrderModal = ({ visible, intl, close, delivery, onSuccess }) => {
  const formRef = useRef();

  const handleOnFinish = async (ticketNumber) => {
    const { _id } = delivery;
    try {
      const payload = {
        updateType: CANCEL_ORDER_UPDATE_TYPE,
        ...ticketNumber
      };

      await cancelOrder({ deliveryId: _id, payload });
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.cancel_modal.cancel_success'
        }),
        'success'
      );
      onSuccess();
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleTicketNumberChange = (name, value) => {
    formRef.current.setFieldsValue({
      [name]: value
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'deliveries.delivery_details.header.cancel'
      })}
      wrapClassName="br-order-details__cancel-order-modal-container"
      visible={visible}
      footer={null}
      centered
      onCancel={close}
    >
      <div className="br-order-details__cancel-order-modal-title">
        {intl.formatMessage({
          id: 'deliveries.delivery_details.cancel_modal.title'
        })}
      </div>

      <Form ref={formRef} onFinish={handleOnFinish} name="ticketNumber">
        <Form.Item
          name="ticketNumber"
          rules={[
            {
              required: true,
              label: intl.formatMessage({
                id: 'shipments.new_order.form_placeholder.ticket_number'
              })
            }
          ]}
        >
          <BRFormInputNumber
            filedName="ticketNumber"
            placeholder={intl.formatMessage({
              id: 'shipments.new_order.form_placeholder.ticket_number'
            })}
            handleOnChangeForm={handleTicketNumberChange}
          />
        </Form.Item>
        <div className="br-order-details__cancel-order-modal-footer">
          <Button onClick={close}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </Button>
          <Button type="danger" htmlType="submit" form="ticketNumber">
            {intl.formatMessage({
              id: 'deliveries.delivery_details.header.cancel'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default injectIntl(CancelOrderModal);
