import actionTypes from '../actions/ActionTypes';

const initialState = {
  pickupRequest: { count: 0, data: [], pagination: {} },
  hubs: { data: [] },
  route: { data: [] },
  stars: { data: [] },
  business: { data: [] },
  businessDetails: { data: [] },
  pickupsDetails: null,
  locationPickups: [],
  loading: true,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PICKUPS_REQUEST_RECEIVED:
      return { ...state, pickupRequest: { ...action.payload } };
    case actionTypes.GET_PICKUPS_REQUEST:
      return {
        ...state,
        pickupRequest: { count: 0, data: [], pagination: {} },
      };
    case actionTypes.GET_HUBS_REQUEST_RECEIVED:
      return { ...state, hubs: { data: action.payload } };
    case actionTypes.GET_HUBS_REQUEST:
      return { ...state, hubs: { data: [] } };
    case actionTypes.GET_ROUTE_REQUEST_RECEIVED:
      return { ...state, route: { ...action.payload } };
    case actionTypes.GET_ROUTE_REQUEST:
      return { ...state, route: { data: [] } };
    case actionTypes.GET_STARS_REQUEST_RECEIVED:
      return { ...state, stars: { ...action.payload } };
    case actionTypes.GET_STARS_REQUEST:
      return { ...state, stars: { data: [] } };
    case actionTypes.GET_BUSINESS_NAME_REQUEST_RECEIVED:
      return { ...state, business: { ...action.payload } };
    case actionTypes.GET_BUSINESS_NAME_REQUEST:
      return { ...state, business: { data: [] } };
    case actionTypes.GET_DETAILS_PICKUP_RECEIVED:
      return { ...state, Details: { ...action.payload } };
    case actionTypes.GET_DETAILS_PICKUP:
      return { ...state, Details: { data: [] } };
    case actionTypes.GET_SEARCH_PICKUP_RECEIVED:
      return { ...state, pickupRequest: { ...action.payload } };
    case actionTypes.GET_BUSINESS_RECEIVED:
      return { ...state, businessDetails: { ...action.payload } };
    case actionTypes.GET_ALL_LOCATION_PICKUPS_REQUEST:
      return { ...state, locationPickups: [] };
    case actionTypes.GET_ALL_LOCATION_PICKUPS_RECEIVED:
      return { ...state, locationPickups: action.payload };
    case actionTypes.GET_BUSINESS_REQUEST:
      return { ...state, businessDetails: { data: [] } };
    case actionTypes.GET_SEARCH_PICKUP:
      return {
        ...state,
        pickupRequest: { count: 0, data: [], pagination: {} },
      };
    case actionTypes.DELIVERIES_LOADING_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELIVERIES_LOADING_ENDED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SINGLE_PICKUPS_RECEIVED:
      return { ...state, pickupsDetails: { ...action.payload } };
    case actionTypes.GET_SINGLE_PICKUPS_REQUEST:
      return { ...state, pickupsDetails: { data: [] } };
    case actionTypes.CLEAN: {
      return {
        ...state,
        locationPickups: undefined,
        pickupsDetails: undefined,
      };
    }
    default:
      return state;
  }
}
