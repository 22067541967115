import {
  ALL_COUNTRIES,
  DEFAULT_COUNTRY,
  NATIONAL_ID_NUMBER_LENGTH,
  STARS_NATIONAL_ID_NUMBER_REQUIRED
} from './constants/countries';
import { ATTEMPT_TYPES, MAX_NUM_OF_ATTEMPTS } from './constants/attempts';
import {
  CURRENCY,
  DEPOSIT_TYPES,
  STAR_FAWRY_ID_OWNERSHIP,
  VAT,
  INTERNATIONAL_WALLET_VAT,
  DEBREIF_CASH_DEPOSIT_TYPES,
  DEBREIF_STARS_DEPOSIT_TYPES,
  MONEY_DEBRIEF_REPORT_DEPOSIT_TYPES,
  BANK_TRANSFER_AVAILABILITY,
  CASHIER_PAGE_AVAILABILITY
} from './constants/finance';
import {
  CREDIT_LIMIT,
  DEFAULT,
  PHONE_INPUT_PLACEHOLDER,
  PHONE_NUMBER_MAX_LENGTH,
  PHONE_NUMBER_REGEX,
  PHONE_VALIDATION_ERROR_MSG,
  WITHOUT_COUNTRY_CODE
} from './constants/validations';
import {
  FULFILLMENT_INBOUND_CUTOFF_TIME,
  PICKUP_CUTOFF_TIME,
  WORKING_DAYS
} from './constants/date-time';
import {
  AWB_LANGUAGE,
  BOSTA_MATERIAL_BUSINESS_ACCOUNTS_ID,
  MANAGE_PACKAGING_MATERIAL_AVAILABILTY,
  MAP_DEFAULT_LOCATION,
  MASS_UPLOAD_TEMPLATE,
  PACKAGE_TYPE_VIEW,
  PACKAGING_FREE_ITEMS_AVAILABLE,
  PRICE_COLUMN_IN_PACKAGING_MATERIAL_AVAILABILTY,
  STAR_PERFORMANCE_DEFAULT_HUB_FILTER_VALUE,
  OPEN_PACKAGE_FEES_AVAILABILTY,
  INTERNATIONAL_SHIPPING_AVAILABILTY,
  PACKAGE_SIZE_REQ_PAYLOAD,
  AWB_REPACK
} from './constants/common';
import { CCOD_VISIBLE } from './constants/ccod';
import {
  BARCODES_SHEET_TYPE_OPTIONS,
  FAWRY_OPTION_AVAILABLE,
  FIRST_MILE_VALIDATION_AVAILABLE,
  NEW_HUB_TRANSFER_PAGE_ACCESS,
  POS_OPTION_AVAILABLE,
  RECEIVE_FROM_BINS_AVAILABLE,
  THREE_PL_INTEGRATION_PAGE_ACCESS,
  VALU_OPTION_AVAILABLE
} from './constants/hubs';
import { MULTI_PACKAGE_DELIVERY_AVAILABILITY } from './constants/deliveries';
import { RETURN_TO_FULFILLMENT } from './constants/international-shipping';

const countryCode = JSON.parse(localStorage.getItem('userInfo'))?.country?.code;

const currentUserCountryCode =
  ALL_COUNTRIES.find(({ codeName }) => countryCode === codeName)?.codeName ||
  DEFAULT_COUNTRY.codeName;

export const currentUserCountry =
  ALL_COUNTRIES.find(({ codeName }) => countryCode === codeName) ||
  DEFAULT_COUNTRY;

/* Attempts */
export const getAttemptsTypes = () => {
  return ATTEMPT_TYPES;
};

export const getMaxNumOfAttempts = () => {
  const { DELIVERY, PICKUP, RETURN } =
    MAX_NUM_OF_ATTEMPTS[currentUserCountryCode] || MAX_NUM_OF_ATTEMPTS.DEFAULT;

  return {
    DELIVERY,
    PICKUP,
    RETURN
  };
};

export const isMaxNumOfAttempts = (numOfAttempts) => {
  const { DELIVERY, PICKUP, RETURN } = getMaxNumOfAttempts();

  return {
    DELIVERY: numOfAttempts === DELIVERY,
    PICKUP: numOfAttempts === PICKUP,
    RETURN: numOfAttempts === RETURN
  };
};

/* Finance */
export const getDepositTypes = () => {
  return DEPOSIT_TYPES[currentUserCountryCode];
};

export const getCurrency = (cod, countryCode) => {
  return CURRENCY(cod)[countryCode || currentUserCountryCode];
};

export const getVAT = () => {
  return VAT[currentUserCountryCode];
};

export const hasFawryId = () => {
  return (
    STAR_FAWRY_ID_OWNERSHIP[currentUserCountryCode] ||
    STAR_FAWRY_ID_OWNERSHIP.DEFAULT
  );
};

export const getDebriefStarsDepositTypes = () => {
  return DEBREIF_STARS_DEPOSIT_TYPES[currentUserCountryCode];
};

export const getDebriefCashDepositTypes = () => {
  return DEBREIF_CASH_DEPOSIT_TYPES[currentUserCountryCode];
};

export const getMoneyDebriefReportDepositTypes = () => {
  return MONEY_DEBRIEF_REPORT_DEPOSIT_TYPES[currentUserCountryCode];
};

/* Validations */
export const getPhoneNumberMaxLength = () => {
  return PHONE_NUMBER_MAX_LENGTH[currentUserCountryCode];
};

export const getPhoneNumberRegex = ({
  countryCode = currentUserCountryCode,
  withoutCountryCode
}) => {
  return PHONE_NUMBER_REGEX[countryCode][
    withoutCountryCode ? WITHOUT_COUNTRY_CODE : DEFAULT
  ];
};

export const getPhoneNumberInputPlaceholder = () => {
  return PHONE_INPUT_PLACEHOLDER[currentUserCountryCode];
};

export const getPhoneValidationErrorMsg = () => {
  return PHONE_VALIDATION_ERROR_MSG[currentUserCountryCode];
};

export const getCreditCardLength = () => {
  return CREDIT_LIMIT[currentUserCountryCode] || CREDIT_LIMIT.DEFAULT;
};

/* Date and Time */
export const getPickupCutOffTime = () => {
  return PICKUP_CUTOFF_TIME[currentUserCountryCode];
};

export const getFulfillmentInboundCutOffTime = () => {
  return (
    FULFILLMENT_INBOUND_CUTOFF_TIME[currentUserCountryCode] ||
    FULFILLMENT_INBOUND_CUTOFF_TIME.DEFAULT
  );
};

export const getWorkingDays = () => {
  return WORKING_DAYS[currentUserCountryCode] || WORKING_DAYS.DEFAULT;
};

/* Common or Other */
export const getStarPerformanceDefaultHubFilterValue = () => {
  return STAR_PERFORMANCE_DEFAULT_HUB_FILTER_VALUE[currentUserCountryCode];
};

export const getMassUploadTemplate = () => {
  return MASS_UPLOAD_TEMPLATE[currentUserCountryCode];
};

export const isPackageTypeViewable = () => {
  return PACKAGE_TYPE_VIEW[currentUserCountryCode] || PACKAGE_TYPE_VIEW.DEFAULT;
};

export const getCurrentUserCountryId = () => {
  return (
    ALL_COUNTRIES.find(({ codeName }) => countryCode === codeName)?.id ||
    DEFAULT_COUNTRY.id
  );
};

export const getAWBLanguage = () => {
  return AWB_LANGUAGE[currentUserCountryCode] || AWB_LANGUAGE.DEFAULT;
};

// CCOD

export const isCcodVisible = () => {
  return CCOD_VISIBLE[currentUserCountryCode] || CCOD_VISIBLE.DEFAULT;
};

// hub

export const hasThreePLIntegrationPageAccess = () => {
  return (
    THREE_PL_INTEGRATION_PAGE_ACCESS[currentUserCountryCode] ||
    THREE_PL_INTEGRATION_PAGE_ACCESS.DEFAULT
  );
};

export const haveNewTransferPageAccess =
  NEW_HUB_TRANSFER_PAGE_ACCESS[currentUserCountryCode];

export const mapDefaultLocation =
  MAP_DEFAULT_LOCATION[currentUserCountryCode] || MAP_DEFAULT_LOCATION.DEFAULT;

// bank transfer
export const isBankTransferAvailable = () => {
  return (
    BANK_TRANSFER_AVAILABILITY[currentUserCountryCode] ||
    BANK_TRANSFER_AVAILABILITY.DEFAULT
  );
};

export const isCashierPageAvailable = () => {
  return (
    CASHIER_PAGE_AVAILABILITY[currentUserCountryCode] ||
    CASHIER_PAGE_AVAILABILITY.DEFAULT
  );
};

export const isPackagingFreeItemsAvilable = () => {
  return PACKAGING_FREE_ITEMS_AVAILABLE[currentUserCountryCode];
};

export const getBostaMaterialBusinessCountryCode = () => {
  return BOSTA_MATERIAL_BUSINESS_ACCOUNTS_ID[currentUserCountryCode];
};

export const isManageMaterialTabInPricingAvailable = () => {
  return MANAGE_PACKAGING_MATERIAL_AVAILABILTY[currentUserCountryCode];
};

export const isPriceColumnInPackagingMaterialsVailable = () => {
  return PRICE_COLUMN_IN_PACKAGING_MATERIAL_AVAILABILTY[currentUserCountryCode];
};

export const isOpenPackageFeesAvailable = () => {
  return OPEN_PACKAGE_FEES_AVAILABILTY[currentUserCountryCode];
};

export const isInternationalShippingAvailable = () => {
  return INTERNATIONAL_SHIPPING_AVAILABILTY[currentUserCountryCode];
};

export const getBarcodesSheetTypes = () => {
  return BARCODES_SHEET_TYPE_OPTIONS[currentUserCountryCode];
};

export const isMultiPackageAvailable = () => {
  return MULTI_PACKAGE_DELIVERY_AVAILABILITY[currentUserCountryCode];
};

export const getflyerSizePayload = () => {
  return PACKAGE_SIZE_REQ_PAYLOAD[currentUserCountryCode];
};

export const isReceiveFromBinsAvailable = () => {
  return RECEIVE_FROM_BINS_AVAILABLE[currentUserCountryCode];
};

export const isValuOptionAvailable = () => {
  return VALU_OPTION_AVAILABLE[currentUserCountryCode];
};

export const isFawryOptionAvailable = () => {
  return FAWRY_OPTION_AVAILABLE[currentUserCountryCode];
};

export const isPosOptionAvailable = () => {
  return POS_OPTION_AVAILABLE[currentUserCountryCode];
};
export const isFirstMileValidationAvailable = () => {
  return FIRST_MILE_VALIDATION_AVAILABLE[currentUserCountryCode];
};

// International Shipping
export const isReturnToFulfillmentAvailable = (countryCode) => {
  return RETURN_TO_FULFILLMENT[countryCode];
};

export const isNationalIdRequiredForStars = () => {
  return STARS_NATIONAL_ID_NUMBER_REQUIRED[currentUserCountryCode];
};

export const getNationalIdLength = () => {
  return NATIONAL_ID_NUMBER_LENGTH[currentUserCountryCode];
};

export const getInternationalVatPercentage = () => {
  return INTERNATIONAL_WALLET_VAT[currentUserCountryCode] || 0;
};

export const isRepackAction = () => {
  return AWB_REPACK[countryCode];
};
