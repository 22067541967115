import { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { formatDate, formatSla, handleCODFormat } from 'utils/shipmentDetails';
import { formatSignAndReturnType } from 'utils/deliveries';
import { formatAddress } from 'utils';
import {
  CHANGE_DELIVERY_DETAILS,
  REQUESTS_TITLES,
  REQUESTS_TYPE
} from 'constants/action-center';
import { TICKET_LINK } from 'constants/shipments';

import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import OrderDetailsNote from '../OrderDetailsNote/OrderDetailsNote';
import BRButton from 'components/BRButton/BRButton';
import DeliveryLogsDrawer from '../DeliveryLogsDrawer/DeliveryLogsDrawer';

import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';

import './OrderDetailsInfo.less';

const OrderDetailsInfo = ({
  orderDetails,
  orderAction,
  intl,
  ofdEditsPage,
  isRequestsTab
}) => {
  const [type, setType] = useState();
  const [deliverySpecs, setDeliverySpecs] = useState();
  const [showDeliveryLogs, setShowDeliveryLogs] = useState(false);

  useEffect(() => {
    if (orderDetails) {
      setType(
        orderDetails?.type === ORDER_TYPES_VALUE.RTO
          ? orderDetails?.shipmentDetailsBusinessView?.type_before
          : orderDetails?.type
      );
      setDeliverySpecs(
        orderDetails?.type === ORDER_TYPES_VALUE.CRP
          ? orderDetails?.returnSpecs
          : orderDetails?.specs
      );
    }
  }, [orderDetails]);

  const formatOrderType = () => {
    return type === ORDER_TYPES_VALUE.SIGN_AND_RETURN ||
      orderDetails?.type === ORDER_TYPES_VALUE.RTO
      ? formatSignAndReturnType(orderDetails)
      : MAP_DELIVERY_TYPES[orderDetails?.type] || '';
  };

  const formatPackageType = () => {
    return type !== ORDER_TYPES_VALUE.CASH_COLLECTION &&
      type !== ORDER_TYPES_VALUE.EXCHANGE
      ? deliverySpecs?.packageType || 'N/A'
      : 'N/A';
  };

  const formatCustomerName = () => {
    return orderDetails?.receiver?.fullName &&
      orderDetails?.receiver?.fullName !== '-'
      ? orderDetails?.receiver.fullName
      : `${orderDetails?.receiver?.firstName} ${orderDetails?.receiver?.lastName}`;
  };

  const formatCustomerAddress = () => {
    const customerAddress = [
      ORDER_TYPES_VALUE.CRP,
      ORDER_TYPES_VALUE.CASH_COLLECTION
    ].includes(orderDetails?.type)
      ? orderDetails?.pickupAddress
      : orderDetails?.dropOffAddress;

    return customerAddress ? formatAddress(customerAddress) : 'N/A';
  };

  const getRequestDetails = () => {
    switch (orderAction.type) {
      case REQUESTS_TYPE.CHANGE_COD:
        return intl.formatMessage(
          { id: 'action_center.requests.request_details.cod_change' },
          {
            codBefore: orderDetails?.cod?.amount || 0,
            codAfter: orderAction.requestDetails.codAmount
          }
        );

      default:
        break;
    }
  };

  const getTicketLink = () => {
    const ticketNumber = orderDetails?.linkedTickets?.find(
      (ticket) => ticket.type === CHANGE_DELIVERY_DETAILS
    )?.number;

    return (
      <a
        className="br-action-center__ticket-link"
        href={`${TICKET_LINK}${ticketNumber}`}
        target="_blank"
      >
        {ticketNumber}
      </a>
    );
  };

  return (
    <div className="br-order-details__container">
      <div className="br-order-details__content">
        {isRequestsTab ? (
          <>
            <div className="br-order-requests__container">
              <div className="br-order-requests__type">
                {REQUESTS_TITLES[orderAction.type]}
              </div>
              <div className="br-order-requets__details">
                Details:
                <span>{getRequestDetails()}</span>
              </div>
            </div>
            <div className="br-order-ticket__container">
              <div className="br-order-ticket body-medium">
                {intl.formatMessage({ id: 'action_center.requests.ticket_no' })}
                {getTicketLink()}
                <ExternalLink />
              </div>
              <div className="br-order-star">
                <span className="caption">
                  {intl.formatMessage({
                    id: 'action_center.requests.star_name'
                  })}
                  :
                </span>
                {orderAction.star?.name}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="br-order-details__header">
              <h1 className="br-order-details__title">
                {intl.formatMessage({
                  id: 'action_center.order_details.changes_log'
                })}
              </h1>
            </div>
            <OrderDetailsNote
              orderDetails={orderDetails}
              orderAction={orderAction}
              customerAddress={formatCustomerAddress()}
              ofdEditsPage={ofdEditsPage}
            />
          </>
        )}

        <div className="br-order-details-shipment-info">
          <div className="br-order-details-shipment-info-tracking-number">
            TN : {orderDetails?.trackingNumber}
          </div>
          <div className="br-order-details-shipment-info-title">
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.CRP
            ].includes(orderDetails?.type)
              ? intl.formatMessage({
                  id: 'action_center.order_details.fm_pickup_date'
                })
              : intl.formatMessage({
                  id: 'action_center.order_details.creation_date'
                })}
            <span className="info-value">
              {![
                ORDER_TYPES_VALUE.CASH_COLLECTION,
                ORDER_TYPES_VALUE.CRP
              ].includes(orderDetails?.type)
                ? orderDetails?.collectedFromBusiness
                  ? formatDate(orderDetails?.collectedFromBusiness)
                  : 'N/A'
                : orderDetails?.createdAt
                ? formatDate(orderDetails?.createdAt)
                : 'N/A'}
            </span>
          </div>
          <div className="br-order-details-shipment-info-title">
            {intl.formatMessage({
              id: 'action_center.order_details.business_name'
            })}
            <span className="info-value">
              <Link
                to={`/businesses/${orderDetails?.sender?._id}/details`}
                className="br-order-details-business-link"
                target="_blank"
              >
                {orderDetails?.sender?.name}
              </Link>
            </span>
          </div>
          <div className="br-order-details-shipment-info-title">
            SLA
            <span className="info-value">
              {orderDetails?.sla?.e2eSla?.e2eSlaTimestamp
                ? formatSla(orderDetails)
                : 'N/A'}
            </span>
          </div>
        </div>
        <div className="br-order-details-shipment-info">
          <div className="br-order-details-shipment-info-details">
            <div className="br-order-details-shipment-info-title">
              {intl.formatMessage({
                id: 'action_center.order_details.type'
              })}
              <span className="info-value block">{formatOrderType()}</span>
            </div>
            <div className="br-order-details-shipment-info-title">
              {intl.formatMessage({
                id: 'action_center.order_details.state'
              })}
              <span className="info-value block">
                <span
                  className={classnames(
                    'br-order-info-delivery-details__state',
                    deliveryStateFormatter.getStateColor(orderDetails)
                  )}
                >
                  {deliveryStateFormatter.getStateName(orderDetails)}
                </span>
              </span>
            </div>
            <div className="br-order-details-shipment-info-title">
              COD
              <span className="info-value block">
                {handleCODFormat(orderDetails)}
              </span>
            </div>
            <div className="br-order-details-shipment-info-title">
              {intl.formatMessage({
                id: 'action_center.order_details.package_type'
              })}
              <span className="info-value block">{formatPackageType()}</span>
            </div>
            <div className="br-order-details-shipment-info-title">
              {intl.formatMessage({
                id: 'action_center.order_details.number_of_items'
              })}
              <span className="info-value block">
                {deliverySpecs?.packageDetails?.itemsCount || 'N/A'}
              </span>
            </div>
            <div className="br-order-details-shipment-info-title">
              {intl.formatMessage({
                id: 'action_center.order_details.opening_package'
              })}
              <span className="info-value block">
                {orderDetails?.allowToOpenPackage
                  ? intl.formatMessage({
                      id: 'common.yes'
                    })
                  : intl.formatMessage({
                      id: 'common.no'
                    })}
              </span>
            </div>
          </div>
          <BRButton
            onClick={() => setShowDeliveryLogs(true)}
            className="br-action-center-view-logs__btn"
            label={intl.formatMessage({ id: 'action_center.view_logs' })}
          />
          <DeliveryLogsDrawer
            showDeliveryLogs={showDeliveryLogs}
            setShowDeliveryLogs={setShowDeliveryLogs}
            delivery={orderDetails}
          />
        </div>
        <div className="br-order-details-customer-details">
          <div className="br-order-details-shipment-info-title">
            {intl.formatMessage({
              id: 'action_center.order_details.customer_name'
            })}
            <span className="info-value block">{formatCustomerName()}</span>
          </div>
          <div className="br-order-details-shipment-info-title">
            {intl.formatMessage({
              id: 'action_center.order_details.customer_address'
            })}
            <span className="info-value block">{formatCustomerAddress()}</span>
          </div>
          <div className="br-order-details-shipment-info-title">
            {intl.formatMessage({
              id: 'action_center.order_details.customer_phone'
            })}
            <span className="info-value block">
              {orderDetails?.receiver?.phone || 'N/A'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(OrderDetailsInfo);
