import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Drawer } from 'antd';

import { getPickupInfo } from 'services/pickupDetails';
import {
  INTERNATIONAL_PICKUP_DATE_FORMAT,
  INTERNATIONAL_PICKUP_STATES_LABEL,
  INTERNATIONAL_PICKUP_TAG_COLORS
} from 'constants/international-pickups';
import { formatAddress } from 'utils/international-order';

import BRTabs from 'components/BRTabs/BRTabs';
import BRTag from 'components/BRTag/BRTag';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PickupDetails from './components/PickupDetails/PickupDetails';
import PickupLogs from './components/PickupLogs/PickupLogs';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './InternationalPickupDetails.less';

const InternationalPickupDetails = ({ open, onClose, puid }) => {
  const [pickupDetails, setPickupDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (puid) {
      getPickupDetails();
    }
  }, [puid]);

  const formatPickupDetails = (pickupData) => ({
    ...pickupData,
    scheduledDate: dayjs(pickupData.scheduledDate).format(
      INTERNATIONAL_PICKUP_DATE_FORMAT
    ),
    pickupAddress: formatAddress(pickupData.business.address)
  });

  const getPickupDetails = async () => {
    setIsLoading(true);
    try {
      const { message } = await getPickupInfo(puid);
      setPickupDetails(formatPickupDetails(message));
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const TABS = {
    details: {
      label: intl.formatMessage({
        id: 'international_orders.pickups.pickup_details.title'
      }),
      component: PickupDetails,
      componentProps: {
        pickupDetails,
        setPickupDetails,
        setIsLoading
      }
    },
    logs: {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.tabs.logs'
      }),
      component: PickupLogs,
      componentProps: {
        logs: pickupDetails.log
      }
    }
  };

  const renderTitle = () => (
    <div className="br-international-pickup-details__title">
      <div>
        <span className="display-xs">{pickupDetails.puid}</span>
        <BRTag color={INTERNATIONAL_PICKUP_TAG_COLORS[pickupDetails.state]}>
          {INTERNATIONAL_PICKUP_STATES_LABEL[pickupDetails.state]}
        </BRTag>
      </div>
      <Close
        className="br-international-pickup-details__close"
        onClick={onClose}
      />
    </div>
  );

  return (
    <Drawer
      className="br-international-pickup-details__drawer"
      title={renderTitle()}
      width={null}
      placement="right"
      onClose={onClose}
      visible={open}
      closeIcon={false}
    >
      <LoadingWrapper loading={isLoading}>
        <BRTabs tabs={TABS} sameRouteTabs />
      </LoadingWrapper>
    </Drawer>
  );
};

export default InternationalPickupDetails;
