import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, InputNumber } from 'antd';

import { positiveNumberRule } from 'utils/forms';
import {
  getCountryFlyersConfiguration,
  updateCountryFlyersConfiguration
} from 'services/bosta-shop';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import './BostaShop.less';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

const BostaShop = ({ intl }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFlyersInfoPerCountry();
  }, []);

  const getFlyersInfoPerCountry = async () => {
    setIsLoading(true);
    try {
      const result = await getCountryFlyersConfiguration();
      setInitialValues(result);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleQuotaParsing = (value) => {
    if (!value) {
      return '';
    }
    if (value && parseInt(value) < 10) {
      return parseInt(value);
    }
    return parseInt(value) % 10 === 0
      ? parseInt(value)
      : parseInt(value) - (parseInt(value) % 10) + 10;
  };

  const onFinish = async (values) => {
    try {
      await updateCountryFlyersConfiguration(values);
      notify(
        'Bosta shop configuration per country updated successfully',
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-bosta-shop">
      <LoadingWrapper loading={isLoading}>
        <BRContentHeader
          title={intl.formatMessage({
            id: 'settings.bosta_shop.title'
          })}
          isInternalComponent
        />
        {initialValues && (
          <Form
            className="br-bosta-shop__form-container"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              name="flyersMultiplier"
              label={intl.formatMessage({
                id: 'settings.bosta_shop.factor'
              })}
              rules={[
                { required: true },
                positiveNumberRule(
                  intl.formatMessage({
                    id: 'settings.bosta_shop.factor_validation'
                  })
                )
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="minimumFlyersQuota"
              label={intl.formatMessage({
                id: 'settings.bosta_shop.minimum_quota'
              })}
              rules={[{ required: true }]}
            >
              <InputNumber step="10" min={10} formatter={handleQuotaParsing} />
            </Form.Item>
            <Form.Item className="br-bosta-shop__submit-action">
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({
                  id: 'common.submit'
                })}
              </Button>
            </Form.Item>
          </Form>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default injectIntl(BostaShop);
