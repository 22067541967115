import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/price-plan-tooltips.svg';

const PackageSize = ({ intl, pricingData }) => {
  return (
    <div className="br-pricing-plan__14-package-sizes">
      <p>
        {' '}
        {intl.formatMessage({
          id: `settings.pricing_plan.package_sizes`
        })}
      </p>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.normal`
          })}
        </span>
        <span>
          {pricingData[0]?.tierServiceTypes[0].tierSizes[0].sizeWidth}x{' '}
          {pricingData[0]?.tierServiceTypes[0].tierSizes[0].sizeHeight}
          {intl.formatMessage({
            id: `settings.pricing_plan.cm`
          })}
        </span>
      </div>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.large`
          })}
        </span>
        <span>
          {pricingData[0]?.tierServiceTypes[0].tierSizes[1].sizeWidth}x{' '}
          {pricingData[0]?.tierServiceTypes[0].tierSizes[1].sizeHeight}
          {intl.formatMessage({
            id: `settings.pricing_plan.cm`
          })}
        </span>
      </div>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.x_large`
          })}
        </span>
        <span>
          {pricingData[0]?.tierServiceTypes[0].tierSizes[2].sizeWidth}x{' '}
          {pricingData[0]?.tierServiceTypes[0].tierSizes[2].sizeHeight}
          {intl.formatMessage({
            id: `settings.pricing_plan.cm`
          })}
        </span>
      </div>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.xxl_white_bag`
          })}
        </span>
        <span>
          {pricingData[0]?.tierServiceTypes[0].tierSizes[3].sizeWidth}x{' '}
          {pricingData[0]?.tierServiceTypes[0].tierSizes[3].sizeHeight}
          {intl.formatMessage({
            id: `settings.pricing_plan.cm`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pricing_plan.tooltips.xxl_white_bag'
            })}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
          >
            <TooltipsIcon />
          </Tooltip>
        </span>
      </div>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.light_bulky`
          })}
        </span>
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.light_bulky_size`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pricing_plan.tooltips.light_bulky'
            })}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
          >
            <TooltipsIcon />
          </Tooltip>
        </span>
      </div>

      <div className="br-pricing-plan__14-package-size">
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.sizes.heavy_bulky`
          })}
        </span>
        <span>
          {intl.formatMessage({
            id: `settings.pricing_plan.heavy_bulky_size`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pricing_plan.tooltips.heavy_bulky'
            })}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
          >
            <TooltipsIcon />
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

export default injectIntl(PackageSize);
