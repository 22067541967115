import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Clock } from 'assets/bosta-icons/Clock.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as Box } from 'assets/bosta-icons/box.svg';

export const PICKUP_LOCATION_REGIONS = {
  INTERNATIONAL: 'INTERNATIONAL',
  LOCAL: 'LOCAL'
};

export const INTERNATIONAL_ORDERS_PICKUPS_DATE_FILTERS = [
  'createdAt',
  'assignedAt',
  'cancelledAt',
  'pickedUpAt'
];

export const INTERNATIONAL_PICKUPS_COLUMNS = [
  {
    title: fmt({ id: 'international_orders.pickups.search.pickup_id_label' }),
    dataIndex: 'puidButton'
  },
  {
    title: fmt({ id: 'business_details.business_info.business_name' }),
    dataIndex: 'businessName'
  },
  {
    title: fmt({ id: 'pickups.pickup_details.pickup_info.address' }),
    dataIndex: 'pickupAddress'
  },
  {
    title: fmt({ id: 'pickups.pickup_details.pickup_info.city' }),
    dataIndex: 'city'
  },
  {
    title: fmt({ id: 'international_orders.pickups.search.courier_label' }),
    dataIndex: 'courier'
  },
  {
    title: fmt({ id: 'international_orders.pickups.state' }),
    dataIndex: 'state_tag'
  },
  {
    title: fmt({ id: 'deliveries.search_filters.scheduledAt' }),
    dataIndex: 'scheduledAt'
  },
  {
    dataIndex: 'actions'
  }
];

export const INTERNATIONAL_PICKUPS_BULK_ACTIONS = {
  ASSIGN_COURIER: 'ASSIGN_COURIER',
  UNASSIGN_COURIER: 'UNASSIGN_COURIER'
};

export const INTERNATIONAL_PICKUPS_STATES = {
  REQUESTED: 'Requested',
  ASSIGNED: 'Picking up',
  COMPLETED: 'Picked up',
  CANCELED: 'Canceled'
};

export const INTERNATIONAL_PICKUP_TAG_COLORS = {
  [INTERNATIONAL_PICKUPS_STATES.REQUESTED]: 'yellow',
  [INTERNATIONAL_PICKUPS_STATES.ASSIGNED]: 'purple',
  [INTERNATIONAL_PICKUPS_STATES.COMPLETED]: 'green',
  [INTERNATIONAL_PICKUPS_STATES.CANCELED]: 'gray'
};

export const INTERNATIONAL_PICKUP_STATES_LABEL = {
  [INTERNATIONAL_PICKUPS_STATES.REQUESTED]: fmt({
    id: 'international_orders.pickups.states.not_assigned'
  }),
  [INTERNATIONAL_PICKUPS_STATES.ASSIGNED]: fmt({
    id: 'international_orders.pickups.states.assigned'
  }),
  [INTERNATIONAL_PICKUPS_STATES.COMPLETED]: fmt({
    id: 'international_orders.pickups.states.completed'
  }),
  [INTERNATIONAL_PICKUPS_STATES.CANCELED]: fmt({
    id: 'international_orders.pickups.states.canceled'
  })
};

export const INTERNATIONAL_PICKUP_DATE_FORMAT = 'DD MMM YYYY';

export const INTERNATIONAL_PICKUPS_FILTERS = (counts) => [
  {
    value: '',
    label: fmt({ id: 'common.all' }),
    count: counts.all,
    color: 'teal'
  },
  {
    value: INTERNATIONAL_PICKUPS_STATES.REQUESTED,
    label: fmt({
      id: 'international_orders.pickups.states.not_assigned'
    }),
    count: counts.requested,
    color: 'yellow',
    icon: <Box />,
    fill: false
  },
  {
    value: INTERNATIONAL_PICKUPS_STATES.ASSIGNED,
    label: fmt({
      id: 'international_orders.pickups.states.assigned'
    }),
    count: counts.assigned,
    color: 'purple',
    icon: <Clock />,
    fill: false
  },
  {
    value: INTERNATIONAL_PICKUPS_STATES.COMPLETED,
    label: fmt({
      id: 'international_orders.pickups.states.completed'
    }),
    count: counts.completed,
    color: 'green',
    icon: <Check />
  },
  {
    value: INTERNATIONAL_PICKUPS_STATES.CANCELED,
    label: fmt({
      id: 'international_orders.deliveries.states.canceled'
    }),
    count: counts.canceled,
    color: 'gray',
    icon: <Close />
  }
];

export const PICKUPS_COUNTS_MAPPING = {
  0: 'all',
  [INTERNATIONAL_PICKUPS_STATES.REQUESTED]: 'requested',
  [INTERNATIONAL_PICKUPS_STATES.ASSIGNED]: 'assigned',
  [INTERNATIONAL_PICKUPS_STATES.COMPLETED]: 'completed',
  [INTERNATIONAL_PICKUPS_STATES.CANCELED]: 'canceled'
};

export const INTERNATIONAL_PICKUP_DETAILS_FIELDS = [
  {
    title: fmt({
      id: 'international_orders.pickups.pickup_details.title'
    }),
    fields: [
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.pickup_date'
        }),
        dataIndex: 'scheduledDate',
        fullWidth: true
      },
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.pickup_location_name'
        }),
        dataIndex: 'business.locationName'
      },
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.pickup_hub'
        }),
        dataIndex: 'warehouse.name'
      },
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.pickup_address'
        }),
        dataIndex: 'pickupAddress',
        fullWidth: true
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.courier_name'
        }),
        dataIndex: 'star.name'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.courier_phone'
        }),
        dataIndex: 'star.phone'
      },
      {
        title: fmt({
          id: 'pickups.pickup_details.pickup_info.contact_person.signature'
        }),
        dataIndex: 'signature',
        isImage: true,
        imageAlt: 'signature-image'
      }
    ]
  },
  {
    title: fmt({
      id: 'international_orders.pickups.pickup_details.contact_person'
    }),
    fields: [
      {
        title: fmt({
          id: 'location_form_labels.name'
        }),
        dataIndex: 'contactPerson.name'
      },
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.phone_number'
        }),
        dataIndex: 'contactPerson.phone'
      },
      {
        title: fmt({
          id: 'international_orders.pickups.pickup_details.pickup_info.additional_phone_number'
        }),
        dataIndex: 'contactPerson.secondPhone'
      }
    ]
  }
];

export const INTERNATIONAL_PICKUP_DELIVERIES_COLUMNS = [
  {
    title: fmt({
      id: 'international_orders.deliveries.search.tracking_number_label'
    }),
    dataIndex: 'trackingNumber',
    width: 100
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.customer_details'
    }),
    dataIndex: 'customerDetails',
    width: 100
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.dropoff_address'
    }),
    dataIndex: 'dropOffAddress',
    width: 100
  }
];
