import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { fetchFulfillmentLocations } from 'constants/fulfillment-locations';

const SelectLevelValue = ({
  setIsLoading,
  setSelected,
  level,
  parentLevelName,
  parentLevelId,
  hasParent = true
}) => {
  const [list, setList] = useState([]);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!hasParent || parentLevelId) {
      fetchFulfillmentLocations({
        level: parentLevelName,
        id: parentLevelId,
        setIsLoading,
        setList
      });
    } else {
      setList([]);
    }
  }, [parentLevelId]);

  return (
    <Form.Item
      label={formatMessage({
        id: `fulfillment_locations.create_location_modal.parents.${level}`
      })}
      name={level}
    >
      <Select
        labelInValue
        options={list}
        fieldNames={{ label: 'label', value: 'id' }}
        placeholder={formatMessage({
          id: 'fulfillment_locations.create_location_modal.dropdown_placeholder'
        })}
        onChange={({ label, value }) => setSelected({ label, id: value })}
        disabled={hasParent && !parentLevelId}
      />
    </Form.Item>
  );
};

export default SelectLevelValue;
