import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { printMassAirwayBills } from 'services/shipments';
import { downloadAsPdf } from 'utils/download';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './MassUploadSuccess.less';

class MassUploadSuccess extends Component {
  state = {
    isLoading: false
  };

  componentDidMount() {
    const { ordersIds, businessName, setShowMassUploadHeader, isFulfillment } =
      this.props;
    sendSegment(
      isFulfillment ? 'FXF_ADMIN_UPLOAD_SUCCESS' : 'E_ADMIN_UPLOAD_SUCCESS',
      {
        'Business Name': businessName,
        'Number of orders': ordersIds.length
      }
    );
    setShowMassUploadHeader(false);
  }

  onCancelSuccessClick = () => {
    this.props.onCancel();
  };

  onViewOrderClick = () => {
    this.props.history.push('/deliveries');
  };

  onPrintAwbClick = async () => {
    const { intl, ordersIds } = this.props;
    this.setState({ isLoading: true });

    try {
      const { data, message } = await printMassAirwayBills({
        ids: ordersIds.join(',')
      });
      if (data) {
        downloadAsPdf(
          data,
          `Air-waybills_[${dayjs(new Date()).format('dd-mm-yyyy')}].pdf`,
          intl.formatMessage({
            id: 'orders.mass_upload.success.file_downloaded_successfully'
          })
        );
      } else {
        notify(message, 'success');
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { intl, isFulfillment } = this.props;
    const { isLoading } = this.state;

    return (
      <LoadingWrapper loading={isLoading}>
        <div className="br-mass-upload__success">
          <CheckCircleFilled />
          <div className="br-mass-upload__success-text">
            <h1>
              {intl.formatMessage({ id: 'orders.mass_upload.success.title' })}
            </h1>
            <p>
              {intl.formatMessage({
                id: 'orders.mass_upload.success.sub_title'
              })}
            </p>
          </div>
          <div className="br-mass-upload__success-actions">
            {!isFulfillment && (
              <Button type="primary" onClick={this.onPrintAwbClick}>
                {intl.formatMessage({
                  id: 'orders.mass_upload.success.print_all_airwaybills'
                })}
              </Button>
            )}
            <div className="br-mass-upload__success-secondary-actions">
              <Button onClick={this.onCancelSuccessClick}>
                {intl.formatMessage({
                  id: 'orders.mass_upload.success.upload_more'
                })}
              </Button>
              <Button onClick={this.onViewOrderClick}>
                {intl.formatMessage({
                  id: 'orders.mass_upload.success.view_orders'
                })}
              </Button>
            </div>
          </div>
        </div>
      </LoadingWrapper>
    );
  }
}

export default injectIntl(withRouter(MassUploadSuccess));
