import http from 'utils/http.js';

export const getActivePromotions = (payload) => {
  return http.get('/promotions', { ...payload, active: true });
};

export const createPromotion = (payload) => {
  return http.post('/promotions', payload);
};

export const updatePromotion = ({ promotionId, ...restPayload }) => {
  return http.put(`/promotions/${promotionId}`, restPayload);
};

export const deletePromotion = ({ promotionId }) => {
  return http.delete(`/promotions/${promotionId}`);
};

export const getBulkPromotionsUploadUrl = (payload) => {
  return http.get('/promotions/bulk', payload);
};

export const uploadBulkPromotions = ({ sheetName }) => {
  return http.post(`/promotions/bulk?sheetName=${sheetName}`);
};
