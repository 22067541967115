import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, InputNumber } from 'antd';

import { notify } from 'components/Notify/Notify';

import './AddBostaShopQuotaModal.less';

const AddBostaShopQuotaModal = ({
  intl,
  close,
  newEmail,
  newPhone,
  memeberId,
  onFinish,
  initialAmount,
  ...rest
}) => {
  const handleQuotaParsing = (value) => {
    if (!value) {
      return '';
    }
    return parseInt(value) % 10 === 0
      ? parseInt(value)
      : parseInt(value) - (parseInt(value) % 10) + 10;
  };

  const handleOnFinish = async (values) => {
    onFinish(values);
    close();
  };

  return (
    <Modal
      {...rest}
      className="br-bosta-shop__add-quota-modal-container"
      onCancel={close}
      title={intl.formatMessage({
        id: 'business_details.bosta_shop.add_quota_modal.title'
      })}
      okButtonProps={{
        htmlType: 'submit',
        form: 'bostaShopAddQuota'
      }}
      okText={intl.formatMessage({
        id: 'common.submit'
      })}
    >
      <Form
        onFinish={handleOnFinish}
        className="br-bosta-shop-modal__form"
        name="bostaShopAddQuota"
        initialValues={{
          extraQuota: initialAmount,
          reason: ''
        }}
      >
        <Form.Item
          name="extraQuota"
          label={intl.formatMessage({
            id: 'business_details.bosta_shop.add_quota_modal.extra_amount'
          })}
          rules={[{ required: true }]}
        >
          <InputNumber step="10" min={10} formatter={handleQuotaParsing} />
        </Form.Item>
        <Form.Item
          name="extraQuotaReason"
          label={intl.formatMessage({
            id: 'business_details.bosta_shop.add_quota_modal.reason'
          })}
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddBostaShopQuotaModal);
