import http from 'utils/http';

export const getStarsPerfromance = (payload) => {
  return http.get(`starPerformance`, payload);
};

export const exportStarsPerformanceTable = (payload) => {
  return http.get(`/starPerformance/export/StarPerformance`, payload);
};

export const getTemplateHubs = () => {
  return http.get(`/starPerformance/score-settings/hubs`);
};

export const getTemplates = (payload) => {
  return http.get(`/starPerformance/score-settings/templates`, payload);
};

export const addTemplates = (payload) => {
  return http.post(`/starPerformance/score-settings/template`, payload);
};

export const editTemplate = (templateId, payload) => {
  return http.put(
    `/starPerformance/score-settings/template/${templateId}`,
    payload
  );
};

export const deleteTemplate = (templateId) => {
  return http.delete(`/starPerformance/score-settings/template/${templateId}`);
};

export const getHubsPerformanceInfo = (payload) => {
  return http.get(`/starPerformance/score-card/hubs`, payload);
};

export const getHubStarsPerformanceInfo = (payload) => {
  return http.get(`/starPerformance/score-card/stars`, payload);
};

export const exportScoreCardHubsViewTable = (payload) => {
  return http.get(`/starPerformance/export/StarScorecardHubsView`, payload);
};

export const exportScoreCardStarsViewTable = (payload) => {
  return http.get(`/starPerformance/export/StarScorecardStarsView`, payload);
};
