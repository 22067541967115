import { Tag } from 'antd';

import { fmt } from 'IntlWrapper/IntlWrapper';

import InventorySerialsList from 'components/NewFulfillment/FulfillmentInventorySerials/InventorySerialsList/InventorySerialsList';

import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';

export const FULFILLMENT_INVENTORY_SERIALS_MAIN_PATH = '/fulfillment/inventory';

export const FULFILLMENT_INVENTORY_SERIALS_ROUTES = [
  {
    path: FULFILLMENT_INVENTORY_SERIALS_MAIN_PATH,
    name: 'Inventory Serials List',
    component: InventorySerialsList,
    exact: true
  }
];

export const SERIAL_STATE_IDS = {
  IN_STOCK: 1,
  PROCESSING: 2,
  TRANSFERRED: 3,
  DAMAGED: 4,
  LOST: 5,
  RTV: 6
};

export const SERIAL_STATE_NAMES = {
  [SERIAL_STATE_IDS.IN_STOCK]: 'in_stock',
  [SERIAL_STATE_IDS.PROCESSING]: 'processing',
  [SERIAL_STATE_IDS.TRANSFERRED]: 'transferred',
  [SERIAL_STATE_IDS.DAMAGED]: 'damaged',
  [SERIAL_STATE_IDS.LOST]: 'lost',
  [SERIAL_STATE_IDS.RTV]: 'rtv'
};

export const INVENTORY_SERIALS_SEARCH_OPTIONS_IDS = {
  SERIAL: {
    value: 'SERIAL',
    apiKey: 'serialNumber',
    label: fmt({
      id: 'fulfillment_common.serial'
    })
  },
  SKU: {
    value: 'SKU',
    apiKey: 'productSku',
    label: fmt({
      id: 'fulfillment_common.sku'
    })
  },
  TRACKING_NUMBER: {
    value: 'TRACKING_NUMBER',
    apiKey: 'trackingNumber',
    label: fmt({
      id: 'fulfillment_common.tracking_number'
    })
  },
  PO: {
    value: 'PO',
    apiKey: 'poId',
    label: fmt({
      id: 'fulfillment_common.po'
    })
  },
  SO: {
    value: 'SO',
    apiKey: 'soId',
    label: fmt({
      id: 'fulfillment_common.so'
    })
  },
  RO: {
    value: 'RO',
    apiKey: 'roId',
    label: fmt({
      id: 'fulfillment_common.ro'
    })
  },
  PROBLEMATIC: {
    value: 'PROBLEMATIC',
    apiKey: 'pjId',
    label: fmt({
      id: 'fulfillment_common.problematic'
    })
  },
  RTV: {
    value: 'RTV',
    apiKey: 'rtvId',
    label: fmt({
      id: 'fulfillment_common.rtv'
    })
  }
};

export const INVENTORY_SERIALS_SEARCH_OPTIONS = [
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.SERIAL,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.SKU,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.PO,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.SO,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.TRACKING_NUMBER,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.RO,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.PROBLEMATIC,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.RTV
];

export const getSerialStateId = ({
  lostAt,
  damagedAt,
  transferredAt,
  pickedAt,
  rtvAt
}) => {
  const { RTV, LOST, DAMAGED, TRANSFERRED, PROCESSING, IN_STOCK } =
    SERIAL_STATE_IDS;

  if (rtvAt) return RTV;
  if (lostAt) return LOST;
  if (damagedAt) return DAMAGED;
  if (transferredAt) return TRANSFERRED;
  if (pickedAt) return PROCESSING;

  return IN_STOCK;
};

export const renderSerialStateTag = (stateId) => {
  const stateName = SERIAL_STATE_NAMES[stateId];
  if (!stateName) return null;

  const extraIcon = {
    [SERIAL_STATE_IDS.TRANSFERRED]: <DoubleCheckIcon />
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_inventory_serials.serial_states.${stateName}`
        })}
        {extraIcon[stateId]}
      </Tag>
    </div>
  );
};

export const formatDatesForPayload = (dates = {}) => {
  let formattedDate = {};

  Object.keys(dates).forEach((key) => {
    const { from, to } = dates[key];

    formattedDate = {
      ...formattedDate,
      [`${key}From`]: from,
      [`${key}To`]: to
    };
  });

  return formattedDate;
};

export const checkIfSerialHasIssue = ({ stateId }) =>
  [SERIAL_STATE_IDS.DAMAGED, SERIAL_STATE_IDS.LOST].includes(stateId);

export const formatProductsFromSerials = ({
  serials,
  returnAsObject = false
}) => {
  const products = {};

  serials.forEach(({ productSku, serialNumber, ...rest }) => {
    const currProduct = products[productSku];

    products[productSku] = currProduct
      ? {
          ...currProduct,
          serialNumbers: [...currProduct.serialNumbers, serialNumber]
        }
      : {
          ...rest,
          productSku,
          serialNumbers: [serialNumber]
        };
  });

  return returnAsObject ? products : Object.values(products);
};
