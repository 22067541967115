import actionTypes from './ActionTypes';

export const startLoading = () => ({
  type: actionTypes.DELIVERIES_LOADING_STARTED
});

export const endLoading = () => ({
  type: actionTypes.DELIVERIES_LOADING_ENDED
});

export const getBusinesses = (data) => ({
  type: 'GET_BUSINESSES',
  data
});

export const printBill = (data) => ({
  type: actionTypes.PRINT_AIR_WAY_BILL,
  data
});

export const getAllDeliveries = (data = {}) => ({
  type: actionTypes.GET_ALL_DELIVERIES,
  data
});

export const getDelivery = (data = '') => ({
  type: actionTypes.GET_DELIVERY,
  data
});

export const clearAirWayBill = () => ({
  type: actionTypes.PRINT_AIR_WAY_BILL_CLEAR
});

export const cancelDelivery = (data) => ({
  type: actionTypes.CANCEL_DELIVERY,
  data
});

export const returnToBusiness = (data) => ({
  type: actionTypes.RETURN_DELIVERIES_TO_BUSINESS,
  data
});

export const addDeliveriesToRoute = (data) => ({
  type: actionTypes.ADD_DELIVERIES_TO_ROUTE,
  data
});

export const terminateDelivery = (data) => ({
  type: actionTypes.TERMINATE_DELIVERY,
  data
});

export const addDeliveryComment = (data) => ({
  type: actionTypes.ADD_DELIVERY_COMMENT,
  data
});

export const rerequestCancelledDelivery = (data) => ({
  type: actionTypes.REREQUEST_DELIVERY,
  data
});

export const exportDeliveriesAsCSV = (data) => ({
  type: actionTypes.EXPORT_DELIVERIES_AS_CSV,
  data
});

export const exportBusinessAsCSV = (data) => ({
  type: actionTypes.EXPORT_BUSINESS_AS_CSV,
  data
});
export const exportPickupsAsCSV = (data) => ({
  type: actionTypes.EXPORT_PICKUPS_AS_CSV,
  data
});
export const searchBusinesses = (data) => ({
  type: 'SEARCH_BUSINESSES',
  data
});

export const deleteBusiness = (data) => ({
  type: 'DELETE_BUSINESS',
  data
});

export const editBusiness = (data) => ({
  type: 'EDIT_BUSINESS',
  data
});

export const getBusiness = (data) => ({
  type: actionTypes.GET_BUSINESS,
  data
});

export const getAllStars = (data = { verified: true }) => ({
  type: actionTypes.GET_ALL_STARS,
  data
});

export const getVerifiedStars = (data = { verified: true }) => ({
  // eslint-disable-next-line no-underscore-dangle
  type: actionTypes.GET_STARS_,
  data
});
export const getStarsByHub = (data) => ({
  type: actionTypes.GET_STARS_BY_HUB,
  data
});

export const getStars = (data) => ({
  type: 'GET_STARS',
  data
});

export const getAllHubs = (data) => ({
  type: actionTypes.GET_ALL_HUBS,
  data
});

export const getHubs = (data) => ({
  type: actionTypes.GET_ALL_HUBS_PAGED,
  data
});
export const getAllUsers = (data) => ({
  type: actionTypes.GET_ALL_USERS,
  data
});
export const getAllCities = (data) => ({
  type: actionTypes.GET_ALL_CITIES,
  data
});

export const getAllZones = (data) => ({
  type: actionTypes.GET_ALL_ZONES,
  data
});

export const getAllPickupZones = (data) => ({
  type: actionTypes.GET_PICKUP_ZONES,
  data
});

export const getAllDropOffZones = (data) => ({
  type: actionTypes.GET_DROP_OFF_ZONES,
  data
});

export const getAllDistricts = (data) => ({
  type: actionTypes.GET_ALL_DISTRICTS,
  data
});

export const getAllActiveRoutes = () => ({
  type: actionTypes.GET_ALL_ACTIVE_ROUTES
});

export const getLeaders = (data = { role: 'HUB_LEADER' }) => ({
  type: actionTypes.GET_LEADERS,
  data
});
export const getClerks = (data = { role: 'WAREHOUSE_CLERK' }) => ({
  type: actionTypes.GET_CLERKS,
  data
});

export const fetchCities = () => ({
  type: actionTypes.FETCH_CITIES
});

export const fetchZonesByCity = (data = {}) => ({
  type: actionTypes.FETCH_ZONES,
  data
});

export const getMoneyDebriefReport = (data) => ({
  type: actionTypes.GET_MONEY_DEBRIEF_REPORT,
  data
});

export const clearCurrentMoneyDebriefReport = () => ({
  type: actionTypes.CLEAR_MONEY_DEBRIEF_REPORT
});

export const getHubTransfersReport = (data) => ({
  type: actionTypes.GET_HUB_TRANSFER_REPORT,
  data
});

export const exportHubTransfersReportAsCSV = (data) => ({
  type: actionTypes.GET_HUB_TRANSFER_REPORT_CSV,
  data
});

export const clearHubTransfersReport = () => ({
  type: actionTypes.CLEAR_HUB_TRANSFER_REPORT
});

export const getStarDayReport = (data) => ({
  type: actionTypes.GET_STAR_DAY_REPORT,
  data
});

export const getStarLiabilities = (data) => ({
  type: actionTypes.GET_STAR_LIABILITIES,
  data
});

export const clearStarDebriefingReport = (data) => ({
  type: actionTypes.CLEAR_STAR_DEBRIEF_REPORT,
  data
});

export const getCurrentUserHubInfo = (data) => ({
  type: actionTypes.GET_CURRENT_HUB_INFO,
  data
});
export const getCurrentUserHubId = () => ({
  type: actionTypes.GET_CURRENT_HUB_ID
});

export const getHub = (data) => ({
  type: actionTypes.GET_HUB,
  data
});

export const getTransfer = (data) => ({
  type: actionTypes.GET_INCOMING_TRANSFER_ITEMS,
  data
});

export const generateAPI = (data) => ({
  type: 'GENERATE_API',
  data
});

export const getRoutesList = (data) => ({
  type: actionTypes.GET_ROUTES_LIST,
  data
});

export const getAllOnlineStars = () => ({
  type: actionTypes.GET_ALL_ONLINE_STARS
});

export const getRouteRunSheet = (data) => ({
  type: actionTypes.GET_ROUTE_RUN_SHEET,
  data
});

export const getStarsFiltered = (data) => ({
  type: actionTypes.GET_STARS_FILTERED,
  data
});
export const getPickupRequest = (data) => ({
  type: actionTypes.GET_PICKUPS_REQUEST,
  data
});
export const CancelPickup = (data) => ({
  type: actionTypes.PICKUPS_REQUEST_DELETED_ACTION,
  data
});

export const getHubsRequest = (data) => ({
  type: actionTypes.GET_HUBS_REQUEST,
  data
});
export const addHubs = (data) => ({
  type: actionTypes.HUBS_REQUEST_ADD_ACTION,
  data
});

export const getRouteRequest = (data) => ({
  type: actionTypes.GET_ROUTE_REQUEST,
  data
});
export const addRoute = (data) => ({
  type: actionTypes.ROUTE_REQUEST_ADD_ACTION,
  data
});

export const allStars = (data) => ({
  type: actionTypes.GET_STARS_REQUEST,
  data
});
export const allBusinessName = (data) => ({
  type: actionTypes.GET_BUSINESS_NAME_REQUEST,
  data
});
export const getDetailsPickups = (data) => ({
  type: actionTypes.GET_DETAILS_PICKUP,
  data
});
export const getSearchPickups = (data) => ({
  type: actionTypes.GET_SEARCH_PICKUP,
  data
});

export const createPickups = (data) => ({
  type: actionTypes.CREATE_PICKUPS,
  data
});

export const editPickups = (data) => ({
  type: actionTypes.EDIT_PICKUPS_REQUEST,
  data
});

export const getBusinessDetails = (data) => ({
  type: actionTypes.GET_BUSINESS_REQUEST,
  data
});
export const getSinglePickups = (data) => ({
  type: actionTypes.GET_SINGLE_PICKUPS_REQUEST,
  data
});
export const getLocationPickups = (data) => ({
  type: actionTypes.GET_ALL_LOCATION_PICKUPS_REQUEST,
  data
});
export const getAllSalesUsers = (data) => ({
  type: actionTypes.GET_ALL_USERS_ROLES_SALES,
  data
});
export const assignAccountSalesNote = (data) => ({
  type: actionTypes.SET_ACCOUNT_OR_SALES_OR_NOTE,
  data
});
export const clean = () => ({
  type: actionTypes.CLEAN
});
export const resetDeliveredDelivery = (data) => ({
  type: actionTypes.RESET_DELIVERED_DELIVERY,
  data
});
export const resetDeliveredDeliveries = (data) => ({
  type: actionTypes.RESET_DELIVERED_DELVERIES,
  data
});
export const getWallet = (data) => ({
  type: actionTypes.GET_WALLAT,
  data
});

export const getARReports = (data) => ({
  type: actionTypes.GET_AR_REPORTS,
  data
});
export const getARReportDetails = (data) => ({
  type: actionTypes.GET_AR_REPORT_DETAILS,
  data
});
export const generateArReport = (data) => ({
  type: actionTypes.GET_AR_GENERATE_REPORT,
  data
});
export const getARReportCSV = (data) => ({
  type: actionTypes.GET_AR_REPORT_CSV,
  data
});
export const getSummaryReportCSV = (data) => ({
  type: actionTypes.GET_SUMMARY_REPORT_CSV,
  data
});
export const uploadArReport = (data) => ({
  type: actionTypes.UPLOAD_AR_REPORT,
  data
});
export const downloadArReports = (data) => ({
  type: actionTypes.GET_AR_REPORT,
  data
});
export const downloadArReportDetailsCSV = (data) => ({
  type: actionTypes.GET_AR_REPORT_CSV_DETAILS,
  data
});
export const beep = () => ({
  type: actionTypes.PLAY_BEEP
});

export const warning = () => ({
  type: actionTypes.PLAY_WARNING
});

export const getSummaryReports = (data) => ({
  type: actionTypes.GET_SUMMARY_REPORTS,
  data
});

export const removeReportData = (data) => ({
  type: actionTypes.GET_REMOVE_AR_REPORTS,
  data
});

export const removeGenerateReport = (data) => ({
  type: actionTypes.GET_REMOVE_GENERATE_REPORT,
  data
});
