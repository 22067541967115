import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { Dropdown, Menu } from 'antd';

import {
  exportInternationalOrder,
  searchInternationalOrders
} from 'services/international-orders';
import {
  formatInternationalDeliveryData,
  getAdjustedStateCode
} from 'utils/international-order';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

const InternationalDeliveriesTable = ({
  data: {
    columns,
    setIsLoading,
    handleShowDeliveryDetails = () => {},
    setSelectedRows,
    setCount,
    title,
    selectedRows,
    toggleRowSelection,
    actionsList,
    selectedFilters,
    setSelectedFilters,
    refreshTable,
    isE2E
  }
}) => {
  const location = useLocation();
  const history = useHistory();

  const { trackingNumber } = location?.state || {};

  const intl = useIntl();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleSelectedRows = (rowKeys) => {
    setSelectedRows(rowKeys);
  };

  const handleExportDeliveries = async () => {
    if (isEmpty(selectedFilters) && !selectedRows.length) {
      return notify(
        intl.formatMessage({
          id: 'international_orders.deliveries.export_error'
        })
      );
    }
    try {
      const payload = {
        ...(selectedRows.length
          ? { trackingNumber: selectedRows }
          : selectedFilters)
      };

      await exportInternationalOrder(payload);
      notify(
        intl.formatMessage({
          id: 'international_orders.deliveries.export_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const menuItems = (delivery) => (
    <Menu
      onClick={({ domEvent }) => {
        domEvent.stopPropagation();
      }}
      items={actionsList(delivery)}
    />
  );

  const actionsDropdown = (delivery) => {
    return (
      <Dropdown
        overlay={menuItems(delivery)}
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement
        }
        overlayClassName="br-international-deliveries__actions"
      >
        <BRButton
          type="table-actions"
          className="button-sm"
          suffixIcon={<Actions />}
        />
      </Dropdown>
    );
  };
  const formatDeliveriesData = (deliveries) =>
    deliveries.map((delivery) => ({
      ...formatInternationalDeliveryData({
        delivery,
        onTrackingNumberClick: handleShowDeliveryDetails,
        isE2E
      }),
      actions: actionsDropdown(delivery)
    }));

  const getDeliveries = async ({ page, limit, filterValues }) => {
    setIsLoading(true);
    try {
      let currentSelectedFilters = {
        ...(filterValues ? { ...filterValues } : { ...selectedFilters })
      };

      if (currentSelectedFilters.stateCode) {
        currentSelectedFilters = {
          ...currentSelectedFilters,
          ...getAdjustedStateCode(currentSelectedFilters.stateCode)
        };

        delete currentSelectedFilters.stateCode;
      }

      const payload = {
        page,
        limit,
        ...(!isE2E && { state: selectedFilters.state }),
        ...currentSelectedFilters,
        ...(trackingNumber && {
          internationalOrDomesticTN:
            currentSelectedFilters?.internationalOrDomesticTN || trackingNumber // expect trackingNumber as an array
        })
      };

      const { data } = await searchInternationalOrders(payload);

      setCount(data?.count || 0);
      setSelectedFilters({
        ...(!isE2E && { state: selectedFilters.state }),
        ...currentSelectedFilters
      });
      history.replace({ state: {} });
      setIsLoading(false);
      return {
        list: formatDeliveriesData(data?.list),
        total: data?.count || 0
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <BRTable
      title={title}
      columns={columns}
      listFunction={getDeliveries}
      showFilter={false}
      allowSelection
      handleSelectedRows={handleSelectedRows}
      shareMethods={acceptMethods}
      exportListFileFunction={handleExportDeliveries}
      resetSelection={toggleRowSelection}
      pageSizeOptions={[50, 100, 200]}
    />
  );
};

export default InternationalDeliveriesTable;
