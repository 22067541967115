import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu, Table, Modal as AntModal } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { withRouter } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import styles from 'containers/Route/styles.module.scss';
import { initialFormValues } from 'containers/Route/constant';
import RoutesContainer from 'containers/Route/RoutesContainer';
import {
  getAllOnlineStars,
  getHubsRequest,
  getRoutesList,
  allStars
} from 'actions';
import { FORMAT_DATE_TO_UTC } from 'utils/helpers';

import {
  assignStarToRoute,
  deleteRoute
  // updateRoute,
} from 'actions/RoutesActions';
import { notify } from 'components/Notify/Notify';
import SearchForm from 'containers/Route/RoutesList/SearchForm/SearchForm';

import './UndebriefedRoutes.less';

const routeTableColumns = [
  { dataIndex: 'createdAt', title: 'Create Date' },
  { dataIndex: '_id', title: 'Route Id' },
  {
    dataIndex: 'star',
    title: 'Star',
    render: (star, option) => (
      <>
        <p>{star?.name || ''}</p>
        <p>{star?.phone || ''}</p>
      </>
    )
  },
  { dataIndex: 'hubName', title: 'Hub' },
  {
    dataIndex: 'numberOfDeliveries',
    title: 'Number Of Deliveries',
    align: 'center'
  },
  { dataIndex: 'numberOfPickups', title: 'Number Of Pickups', align: 'center' },
  { dataIndex: 'secondDayRoute', title: 'Route' },
  { dataIndex: 'routeAge', title: 'Route Age' }
  // { dataIndex: 'alarmingLabel', title: 'Alarming label' }
  // { dataIndex: 'actions', title: 'Actions', align: 'center' }
];

/**
 * actions menu to be used in the table's action column
 * @param item
 * @param options
 * @returns {*}
 */
const actionsMenu = (item, options) => (
  <Menu>
    <Menu.Item
      onClick={() => {
        // options.history.push(`/routes/${item._id}/run-sheet`);
        window.open(`/routes/${item._id}/run-sheet`, '_blank');
      }}
    >
      Print Run Sheet
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        options.history.push(`/routes/${item._id}/edit`);
      }}
    >
      Edit Route
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        options.setSelectedRoute(item);
        options.setShowAssignStarModal(true);
      }}
    >
      Assign Star
    </Menu.Item>
    {(item.state === 'UnAssigned' || item.state === 'Rejected') && (
      <Menu.Item
        onClick={() => {
          AntModal.confirm({
            okButtonProps: { className: 'primaryButton' },
            cancelButtonProps: { className: 'secondaryButton' },
            content: 'Are you sure deleting this route?',
            onOk: () => {
              options.deleteSelectedRoute(item);
            }
          });
        }}
      >
        Delete Route
      </Menu.Item>
    )}
  </Menu>
);

/**
 * format datasource
 * @param data
 * @param options
 * @returns {*}
 */
const formatDatasource = (data, options) => {
  return data.map(
    ({
      _id,
      createdAt,
      name,
      deliveryIds,
      pickupRequestIds,
      state,
      isSecondDayRoute,
      warehouse,
      ...rest
    }) => {
      const durationHours = Math.floor(
        dayjs.duration(dayjs().diff(dayjs(createdAt))).asHours()
      );
      return {
        _id,
        key: _id,
        createdAt: dayjs(createdAt).format('LLL'),
        routeAge:
          (!isSecondDayRoute && durationHours >= 20) ||
          (isSecondDayRoute && durationHours >= 26) ? (
            <span className="br-undebriefed-routes__table-alarming-label">
              {`${durationHours}  Hours`}
            </span>
          ) : (
            `${durationHours}  Hours`
          ),
        secondDayRoute: isSecondDayRoute
          ? 'Second Day Route'
          : 'Same Day Route',
        name,
        numberOfDeliveries: deliveryIds?.length || 0,
        numberOfPickups: pickupRequestIds?.length || 0,
        hubName: warehouse?.name,
        state,
        ...rest,
        actions: (
          <Dropdown
            overlay={actionsMenu({ _id, name, state }, options)}
            trigger={['click']}
          >
            <MoreOutlined />
          </Dropdown>
        )
      };
    }
  );
};

const AssignStarModal = ({
  show,
  cancel,
  confirm,
  stars,
  isAssignStarLoading
}) => {
  const [selectedStar, setSelectedStar] = useState(null);
  return (
    <>
      <Modal show={show} onHide={cancel}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Star</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please select a star to assign to this route.</p>
          <Select
            options={stars.map((el) => ({
              value: el._id,
              label: `${el.profile.firstName} ${el.profile.lastName}`
            }))}
            onChange={(evt) => {
              setSelectedStar(evt);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={cancel}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={selectedStar === null || isAssignStarLoading}
            onClick={() => {
              confirm(selectedStar.value);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const UndebriefedRoutes = ({
  fetchRoutes,
  fetchOnlineStars,
  routes,
  onlineStars,
  history,
  loading,
  fetchStars,
  stars,
  fetchHubs,
  hubs
}) => {
  const [showAssignStarModal, setShowAssignStarModal] = useState(false);
  const [isAssignStarLoading, setIsAssignStarLoading] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [formValues, setFormValues] = useState({ ...initialFormValues });

  const resetSearchForm = () => {
    setFormValues({
      ...initialFormValues,
      states: null
    });
    fetchRoutes({
      ...initialFormValues,
      states: [
        { label: 'Done', value: 3 },
        { label: 'Assigned', value: 6 }
      ]
    });
  };

  const handleSearchFormChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    // handleSearchFormChange('active', null);
    // handleSearchFormChange('unassigned', null);
    handleSearchFormChange('pageId', 0);

    if (formValues.createdAt) {
      formValues.createdAt = FORMAT_DATE_TO_UTC(formValues.createdAt);
    }
    fetchRoutes({
      ...formValues,
      pageId: 0,
      states: [
        { label: 'Done', value: 3 },
        { label: 'Assigned', value: 6 }
      ]
    });
  };

  useEffect(() => {
    fetchRoutes({
      ...formValues,
      states: [
        { label: 'Done', value: 3 },
        { label: 'Assigned', value: 6 }
      ]
    });
    fetchOnlineStars();
    fetchStars();
    fetchHubs();
  }, [fetchRoutes, fetchOnlineStars]);

  const assignStar = async (starId) => {
    setIsAssignStarLoading(true);
    const res = await assignStarToRoute({
      routeId: selectedRoute._id,
      starId
    });
    setSelectedRoute(null);
    setIsAssignStarLoading(false);
    setShowAssignStarModal(false);
    if (res) {
      notify('Route updated successfully.', 'success');
      // handleSearchFormChange('unassigned', true);
      handleSearchFormChange('pageId', 0);
      // handleSearchFormChange('active', true);
      fetchRoutes({
        ...formValues,
        states: [
          { label: 'Done', value: 3 },
          { label: 'Assigned', value: 6 }
        ]
      });
    }
  };

  const deleteSelectedRoute = async (item) => {
    const res = await deleteRoute({ routeId: item._id });
    if (res) {
      handleSearchFormChange('unassigned', true);
      handleSearchFormChange('pageId', 0);
      notify(`Route ${item.name} deleted successfully.`, 'success');
      fetchRoutes({
        ...formValues,
        states: [
          { label: 'Done', value: 3 },
          { label: 'Assigned', value: 6 }
        ]
      });
    }
  };
  const handleTableChange = (pagingOptions) => {
    formValues.pageId =
      pagingOptions.current === 0
        ? pagingOptions.current
        : pagingOptions.current - 1;
    fetchRoutes({
      ...formValues,
      states: [
        { label: 'Done', value: 3 },
        { label: 'Assigned', value: 6 }
      ]
    });
  };
  return (
    <>
      <>
        <SearchForm
          handleFormChange={handleSearchFormChange}
          handleSubmit={handleSubmit}
          handleResetForm={resetSearchForm}
          formValues={formValues}
          stars={stars}
          hubs={hubs}
          formLoading={loading}
          removeStateOption
        />

        {routes.data.length > 0 && (
          <div className="mb-4 mt-4">
            <b>Total Routes:</b>
            <span className="ml-2 ">{routes.count}</span>
          </div>
        )}
        <div className="br-undebriefed-routes__table">
          <Table
            className={routes?.count ? styles.tableRoute : 'mt-3'}
            style={{ overflow: 'auto' }}
            scroll={{ x: 100 }}
            dataSource={formatDatasource(routes.data, {
              history,
              setShowAssignStarModal,
              deleteSelectedRoute,
              setSelectedRoute
            })}
            columns={routeTableColumns}
            pagination={{
              current: routes?.pagination?.pageId + 1,
              pageSize: routes?.pagination?.pageLimit || 50,
              total: routes.count,
              pageSizeOptions: ['50']
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </>
      <AssignStarModal
        show={showAssignStarModal}
        cancel={() => setShowAssignStarModal(false)}
        confirm={assignStar}
        stars={onlineStars}
        isAssignStarLoading={isAssignStarLoading}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchRoutes: (data) => dispatch(getRoutesList({ ...data })),
  fetchOnlineStars: () => dispatch(getAllOnlineStars()),
  fetchStars: () => dispatch(allStars()),
  fetchHubs: () => dispatch(getHubsRequest())
});

const mapStateToProps = ({ routes, pickup }) => {
  return { ...routes, ...pickup };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UndebriefedRoutes));
