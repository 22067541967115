import { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';

import { EDIT_OFD_ALL_HUBS_ACCESS } from 'constants/action-center';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { HubsContext } from 'contexts/hub.context';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import OrderDetails from 'components/ActionCenter/components/OrderDetails/OrderDetails';
import OrderActions from 'components/OfdEdits/components/OrderActions/OrderActions';

import './OfdEdits.less';

const OfdEdits = ({ intl }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);

  const { scopedHubs } = useContext(HubsContext);

  useEffect(() => {
    const currentUserHub = getCurrentUserHubInfo();

    if (!isAdminGroupUser()) {
      currentUserHub &&
        setSelectedHub(
          scopedHubs.find((hub) => hub._id === currentUserHub._id)?._id
        );
    } else {
      scopedHubs.length === 1 && setSelectedHub(scopedHubs[0]._id);
    }
  }, [scopedHubs]);

  const handleActionsTableRowClick = (rowData) => {
    setSelectedRow(rowData);
  };

  const handleDataClear = () => {
    setSelectedRow();
  };

  const renderHubsDropdown = scopedHubs?.map((option, index) => (
    <Select.Option key={index} value={option._id}>
      {option.name}
    </Select.Option>
  ));

  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'sidebar.ofd_edits' })} />
      }
      content={
        <div className="br-ofd-edits-container">
          <div className="br-ofd-edits-container__header">
            {isUserAuthorized(EDIT_OFD_ALL_HUBS_ACCESS) && (
              <div className="br-ofd-edits-hubs-filter">
                <Select
                  showSearch
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'action_center.select_hub_placeholder'
                  })}
                  value={selectedHub}
                  onSelect={setSelectedHub}
                  optionFilterProp="children"
                >
                  {renderHubsDropdown}
                </Select>
              </div>
            )}
          </div>
          <div className="br-ofd-edits__content">
            <OrderActions
              hubId={selectedHub}
              handleRowClick={handleActionsTableRowClick}
              selectedRowData={selectedRow}
              clearSelectedRow={handleDataClear}
            />
            <OrderDetails
              selectedRowData={selectedRow}
              handleDataClear={handleDataClear}
              ofdEditsPage
            />
          </div>
        </div>
      }
    />
  );
};

export default injectIntl(OfdEdits);
