import { put, takeLatest } from 'redux-saga/effects';
import actionTypes from '../actions/ActionTypes';
import * as PickupAction from '../actions/PickupRequestsAction';
import { endLoading, startLoading } from '../actions';
import { downloadAsCSV } from '../utils';

function* fetchPickups(dataPage) {
  let results = [];
  yield put(startLoading());
  const data = yield PickupAction.getAllPickup(dataPage);
  if (data) {
    results = data;
  }
  yield put({
    type: actionTypes.GET_PICKUPS_REQUEST_RECEIVED,
    payload: results,
  });
  yield put(endLoading());
}

function* CancelPickupSaga({ data }) {
  let results = [];
  yield put(startLoading());
  const res = yield PickupAction.StatePickup(data);
  if (res) {
    results = res;
  }
}

function* fetchBusiness() {
  const results = yield PickupAction.getAllBsinesses();
  if (results.data) {
    yield put({
      type: actionTypes.GET_BUSINESS_NAME_REQUEST_RECEIVED,
      payload: results,
    });
  }
}
function* fetchHubs() {
  let results = [];
  const data = yield PickupAction.getAllHubs();
  if (data.result) {
    results = data.result;
  }
  yield put({ type: actionTypes.GET_HUBS_REQUEST_RECEIVED, payload: results });
}

function* fetchRoutes() {
  let results = [];
  results = yield PickupAction.getAllRoute();

  yield put({ type: actionTypes.GET_ROUTE_REQUEST_RECEIVED, payload: results });
}
function* fetchStars() {
  let results = [];
  results = yield PickupAction.getAllStar();
  yield put({ type: actionTypes.GET_STARS_REQUEST_RECEIVED, payload: results });
}

function* addToHubs({ data }) {
  let results = [];
  yield put(startLoading());
  const res = yield PickupAction.assignToHub(
    data.ItemId,
    data.hubId,
    data.businessId,
  );
  if (res) {
    results = res;
  }
  yield put({
    type: actionTypes.PICKUPS_REQUEST_DELETED_ACTION_DONE,
    payload: results,
  });
  yield put(endLoading());
}
function* assignToRoute({ data }) {
  let results = [];
  yield put(startLoading());
  const res = yield PickupAction.addToRoute(data.ItemId, data.routeId);
  if (res) {
    results = res;
  }
  yield put({
    type: actionTypes.PICKUPS_REQUEST_DELETED_ACTION_DONE,
    payload: results,
  });
  yield put(endLoading());
}
function* fetchDetailsPickup(id) {
  let results = [];
  yield put(startLoading());

  const data = yield PickupAction.getDetailsPickups(id);
  if (data) {
    results = data;
  }
  yield put({
    type: actionTypes.GET_DETAILS_PICKUP_RECEIVED,
    payload: results,
  });
  yield put(endLoading());
}
function* fetchSearchPickups(queryParams) {
  yield put(startLoading());
  const data = yield PickupAction.searchPickups(queryParams);
  yield put({ type: actionTypes.GET_SEARCH_PICKUP_RECEIVED, payload: data });
  yield put(endLoading());
}

function* fetchBusinessDetails(action) {
  const response = yield PickupAction.getBusinessDetails(action.data);

  yield put({ type: actionTypes.GET_BUSINESS_RECEIVED, payload: response });
}
function* fetchLocationBusiness(action) {
  const response = yield PickupAction.getAllLocationPickups(action.data);
  yield put({
    type: actionTypes.GET_ALL_LOCATION_PICKUPS_RECEIVED,
    payload: response,
  });
}
function* fetchPickupsDetails(action) {
  const response = yield PickupAction.getSinglePickupsDetails(action.data);
  yield put({
    type: actionTypes.GET_SINGLE_PICKUPS_RECEIVED,
    payload: response,
  });
}
function* exportPickupsAsCSV({ data }) {
  yield put(startLoading());
  const res = yield PickupAction.exportCSV(data);
  if (res) {
    downloadAsCSV(res, 'pickups.csv');
  }
  yield put({ type: actionTypes.EXPORT_PICKUPS_AS_CSV_DONE, payload: res });
  yield put(endLoading());
}

export default function* PickupsWatcher() {
  yield takeLatest(actionTypes.GET_PICKUPS_REQUEST, fetchPickups);
  yield takeLatest(
    actionTypes.PICKUPS_REQUEST_DELETED_ACTION,
    CancelPickupSaga,
  );
  yield takeLatest(actionTypes.GET_HUBS_REQUEST, fetchHubs);
  yield takeLatest(actionTypes.GET_ROUTE_REQUEST, fetchRoutes);
  yield takeLatest(actionTypes.HUBS_REQUEST_ADD_ACTION, addToHubs);
  yield takeLatest(actionTypes.ROUTE_REQUEST_ADD_ACTION, assignToRoute);
  yield takeLatest(actionTypes.GET_STARS_REQUEST, fetchStars);
  yield takeLatest(actionTypes.GET_BUSINESS_NAME_REQUEST, fetchBusiness);
  yield takeLatest(actionTypes.GET_DETAILS_PICKUP, fetchDetailsPickup);
  yield takeLatest(actionTypes.GET_SEARCH_PICKUP, fetchSearchPickups);
  yield takeLatest(actionTypes.GET_BUSINESS_REQUEST, fetchBusinessDetails);
  yield takeLatest(
    actionTypes.GET_ALL_LOCATION_PICKUPS_REQUEST,
    fetchLocationBusiness,
  );
  yield takeLatest(actionTypes.GET_SINGLE_PICKUPS_REQUEST, fetchPickupsDetails);
  yield takeLatest(actionTypes.EXPORT_PICKUPS_AS_CSV, exportPickupsAsCSV);
}
