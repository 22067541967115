import {
  fetchHubTransferring,
  fetchHubTransferringOverview,
  hubTransferringExport
} from 'services/hubs';
import { DISPATCHING_ALL_HUBS_ACCESS, PAGES_TYPE } from 'constants/dispatching';
import { isUserAuthorized } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';

import DispatchingOverview from 'components/Dispatching/components/DispatchingOverview/DispatchingOverview';
import DispatchingHub from 'components/Dispatching/components/DispatchingHub/DispatchingHub';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

const { TRANSFERRING } = PAGES_TYPE;

const TransferringTab = ({
  allHubs,
  setIsLoading,
  isLoading,
  selectedHubId,
  setSelectedHubId
}) => {
  return (
    <LoadingWrapper loading={isLoading}>
      {isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
        ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
      ]) && !selectedHubId ? (
        <DispatchingOverview
          fetchData={fetchHubTransferringOverview}
          setIsLoading={setIsLoading}
          setSelectedHubId={setSelectedHubId}
          pageType={TRANSFERRING}
        />
      ) : (
        <DispatchingHub
          fetchHubData={fetchHubTransferring}
          exportHubData={hubTransferringExport}
          setIsLoading={setIsLoading}
          selectedHubId={selectedHubId}
          setSelectedHubId={setSelectedHubId}
          pageType={TRANSFERRING}
          allHubs={allHubs}
        />
      )}
    </LoadingWrapper>
  );
};

export default TransferringTab;
