import { fmt } from 'IntlWrapper/IntlWrapper';

export const COVERAGE_BY_OPTIONS = {
  ZONE: 'zone',
  DISTRICT: 'district'
};

export const COVERAGE_FILTER_BY_OPTIONS = [
  {
    label: fmt({ id: 'zoning.create_edit_district_modal.zone' }),
    value: COVERAGE_BY_OPTIONS.ZONE
  },
  {
    label: fmt({ id: 'zoning.district' }),
    value: COVERAGE_BY_OPTIONS.DISTRICT
  }
];

export const AVAILABILITY_FLAGS = ['dropOffAvailability', 'pickupAvailability'];
