import actionTypes from '../actions/ActionTypes';

const initialState = {
  stars: { count: 0, data: [] },
  starsFiltered: { data: [] },
  wallet: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_STARS_RECEIVED:
      return {
        ...state,
        stars: {
          count: action.payload.count,
          data: action.payload.stars,
        },
      };
    case actionTypes.GET_STARS_FILTERED_RECEIVED:
      return {
        ...state,
        starsFiltered: { ...action.payload },
      };
    case actionTypes.GET_WALLAT:
      return { ...state, wallet: [] };
    case actionTypes.GET_WALLAT_RECEIVED:
      return { ...state, wallet: action.payload };
    default:
      return state;
  }
}
