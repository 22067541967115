import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getBusinesses,
  searchBusinesses,
  getBusiness,
  deleteBusiness,
  updateBusiness,
  generateAPIForBusiness,
  getAllUserRoleSale,
  assignAccountSalesNote,
  exportCSV,
} from '../actions/BusniessesAction';
import { endLoading, startLoading } from '../actions';

import { getStars } from '../actions/StarAction';
import actionTypes from '../actions/ActionTypes';
import { notify } from 'components/Notify/Notify';

function* fetchBusinesses(action) {
  const response = yield getBusinesses(action.data);
  yield put({ type: 'BUSINESSES_RECEIVED', payload: response });
}

function* fetchSearchBusinesses(action) {
  const response = yield searchBusinesses(action.data);
  yield put({
    type: 'SEARCH_BUSINESSES_RECEIVED',
    payload: response || { result: [], count: 0 },
  });
}

function* fetchBusiness(action) {
  const response = yield getBusiness(action.data);
  yield put({ type: actionTypes.GET_BUSINESS_RECEIVED, payload: response });
}

function* fetchEditBusiness(action) {
  const response = yield updateBusiness(action);
  yield put({ type: 'BUSINESS_UPDATED', payload: response });
}

function* fetchStars(action) {
  const response = yield getStars(action.data);
  yield put({ type: 'STARS_RECEIVED', payload: response });
}

function* fetchDeleteBusiness(action) {
  yield deleteBusiness(action.data);
  notify(`Business deleted successfully`, 'success');
  yield put({ type: 'BUSINESS_DELETED', payload: action.data });
}

function* fetchGenerateAPI(action) {
  const response = yield generateAPIForBusiness(action.data);
  yield put({
    type: 'GENERATED_API',
    payload: { ...response, businessId: action.data },
  });
}
function* fetchSalesUser() {
  let results = [];
  results = yield getAllUserRoleSale();

  yield put({
    type: actionTypes.GET_ALL_USERS_ROLES_SALES_RECEIVED,
    payload: results,
  });
}

function* setAssignAccountSalesNote({ data }) {
  let results = [];
  const res = yield assignAccountSalesNote(data);
  if (res) {
    results = res;
  }
  yield put({
    type: actionTypes.SET_ACCOUNT_OR_SALES_OR_NOTE_RECEIVED,
    payload: results,
  });
}
function* exportBusinessAsCSV({ data }) {
  yield put(startLoading());
  const res = yield exportCSV(data);
  // if (res) {
  //   downloadAsCSV(res, 'business.csv');
  // }
  yield put({ type: actionTypes.EXPORT_BUSINESS_AS_CSV_DONE, payload: res });
  yield put(endLoading());
}

export default function* adminWatcher() {
  yield takeEvery('GET_BUSINESSES', fetchBusinesses);
  yield takeEvery('GET_BUSINESS', fetchBusiness);
  yield takeEvery('SEARCH_BUSINESSES', fetchSearchBusinesses);
  yield takeEvery('EDIT_BUSINESS', fetchEditBusiness);
  yield takeEvery('GENERATE_API', fetchGenerateAPI);
  yield takeEvery('DELETE_BUSINESS', fetchDeleteBusiness);
  yield takeEvery('GET_STARS', fetchStars);
  yield takeLatest(actionTypes.EXPORT_BUSINESS_AS_CSV, exportBusinessAsCSV);

  yield takeLatest(actionTypes.GET_ALL_USERS_ROLES_SALES, fetchSalesUser);
  yield takeLatest(
    actionTypes.SET_ACCOUNT_OR_SALES_OR_NOTE,
    setAssignAccountSalesNote,
  );
}
