import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select, Button, Checkbox, Tag } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { fetchCities, getAreasInfo } from 'services/cities';
import {
  createPickupLocation,
  createContacts as updatePickupLocation
} from 'services/pickups';
import { emailRule, phoneNumberRule } from 'utils/forms';
import { COUNTRIES } from 'constants/country-data';

import { notify } from 'components/Notify/Notify';
import BRFormInputPhoneNumber from 'components/BRFormInputPhoneNumber/BRFormInputPhoneNumber';
import BRArea, { AVAILABILITY } from 'components/BRArea/BRArea';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import Banner from 'components/Banner/Banner';

import Warning from 'assets/imgRevamp/warning-alert.svg';

import './CreatePickupLocationFormModal.less';

class CreatePickupLocationFormModal extends React.Component {
  state = {
    locationData: [],
    contactsFormData: [],
    showLocation: false,
    showContacts: false,
    cities: [],
    areas: [],
    areasLoading: false,
    fields: [],
    isFormLoading: false,
    isLocationDefault: false,
    updatedLocation: false
  };

  formRef = React.createRef();

  contactsFormRef = React.createRef();

  handleCityChange = async (cityId) => {
    this.formRef.current &&
      this.formRef.current.setFieldsValue({ districtId: null });
    try {
      this.setState({ areasLoading: true });
      const areas = await getAreasInfo(cityId);
      this.setState({ areas });
      this.setState({ areasLoading: false });
    } catch (error) {
      notify(error.message);
    }
  };

  getCities = async () => {
    try {
      const cities = await fetchCities();
      this.setState({ cities });
    } catch (error) {
      notify(error.message);
    }
  };

  handleOnChangeLocationForm = (name, value) => {
    this.formRef.current.setFieldsValue({ [name]: value });
  };

  handleOnChangeContactsForm = (name, value) => {
    this.contactsFormRef.current.setFieldsValue({ [name]: value });
  };

  componentDidMount() {
    const { pickupLocation } = this.props;

    this.setState({ showLocation: true });
    this.getCities();
    if (pickupLocation) {
      this.setState(
        {
          locationData: pickupLocation,
          showContacts: false,
          updatedLocation: true
        },
        () => {
          this.formRef.current.setFieldsValue({
            locationName: pickupLocation?.locationName,
            city: pickupLocation?.address?.city?._id,
            districtId: pickupLocation?.address?.district?._id,
            buildingNumber: pickupLocation?.address?.buildingNumber,
            firstLine: pickupLocation?.address?.firstLine,
            floor: pickupLocation?.address?.floor,
            apartment: pickupLocation?.address?.apartment,
            secondLine: pickupLocation?.address?.secondLine
          });
        }
      );
      this.handleCityChange(pickupLocation?.address?.city?._id);
    }
  }

  handleBackClick = () => {
    const { locationData } = this.state;

    this.setState({ showLocation: true, showContacts: false }, () => {
      this.formRef.current.setFieldsValue({
        locationName: locationData.locationName,
        city: locationData.city,
        districtId: locationData.districtId,
        buildingNumber: locationData.buildingNumber,
        firstLine: locationData.firstLine,
        floor: locationData.floor,
        apartment: locationData.apartment,
        secondLine: locationData.secondLine
      });
    });
  };

  onChangeCheck = (e) => {
    if (e.target.checked) {
      this.setState({ isLocationDefault: true });
    }
  };

  handleSubmit = async (values) => {
    const { locationData, updatedLocation, isLocationDefault } = this.state;
    const { onSuccess, close, intl, pickupLocation, businessId } = this.props;

    const locationName = locationData.locationName;
    delete locationData.locationName;
    delete locationData.city;
    try {
      this.setState({ isFormLoading: true });

      let payload = {
        businessId,
        locationName: locationName,
        address: {
          ...locationData
        },
        default: isLocationDefault
      };
      if (updatedLocation) {
        const newPickupLocationContacts = pickupLocation.contacts.map(
          (item, index) => {
            if (index === 0 && item.isDefault === undefined) {
              return { ...item, isDefault: true };
            } else if (item.isDefault === undefined) {
              return { ...item, isDefault: false };
            } else {
              return item;
            }
          }
        );

        payload = {
          ...payload,
          contacts: newPickupLocationContacts
        };

        await updatePickupLocation({
          id: pickupLocation._id,
          payload
        });

        notify(
          intl.formatMessage({
            id: `settings.pickup_locations.pickup_location_form.create_pickup_location_success`
          }),
          'success'
        );
        onSuccess();
      } else {
        payload = {
          ...payload,
          contacts: [
            {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              secPhone: values.secPhone,
              isDefault: true
            }
          ]
        };
        const { pickupId } = await createPickupLocation(payload);
        notify(
          intl.formatMessage({
            id: `settings.pickup_locations.pickup_location_form.create_pickup_location_success`
          }),
          'success'
        );
        onSuccess(pickupId);
      }
      close();
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isFormLoading: false });
  };

  handleLocationFormCancel = () => {
    const { close, onCancel } = this.props;
    this.formRef.current.resetFields(this.state.fileds);
    close();
    if (onCancel) onCancel();
  };

  handleContactsFormCancel = () => {
    const { close } = this.props;
    this.contactsFormRef.current.resetFields(this.state.fileds);
    close();
  };

  handleNextToModalLocation = (values) => {
    const { updatedLocation } = this.state;
    if (updatedLocation) {
      this.setState({ locationData: omitBy(values, isNil) });
      this.handleSubmit(omitBy(values, isNil));
    } else {
      const { contactsFormData } = this.state;
      this.setState(
        {
          showLocation: false,
          showContacts: true,
          locationData: values
        },
        () => {
          this.contactsFormRef.current.setFieldsValue({
            firstName: contactsFormData.firstName,
            lastName: contactsFormData.lastName,
            email: contactsFormData.email,
            phone: contactsFormData.phone,
            secPhone: contactsFormData.secPhone
          });
        }
      );
    }
  };

  onChangeSetInput = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  };

  render() {
    const { close, fields, intl, pickupLocation } = this.props;
    const {
      showLocation,
      showContacts,
      cities,
      areas,
      areasLoading,
      isFormLoading,
      locationFields
    } = this.state;
    return (
      <Modal
        {...this.props}
        className="br-location-contact-modal"
        title={null}
        footer={null}
        onCancel={close}
        width={null}
      >
        <BRContentHeader
          title={intl.formatMessage({
            id: pickupLocation
              ? 'settings.pickup_locations.pickup_location_form.update_pickup_location'
              : showLocation
              ? 'settings.pickup_locations.pickup_location_form.pickup_location_title'
              : 'settings.pickup_locations.pickup_location_form.contact_person_title'
          })}
          subtitle={
            pickupLocation
              ? ''
              : intl.formatMessage({
                  id: showLocation
                    ? 'settings.pickup_locations.pickup_location_form.pickup_location_sub_title'
                    : 'settings.pickup_locations.pickup_location_form.contact_person_sub_title'
                })
          }
          isInternalComponent
        />
        {true && (
          <Banner
            title={intl.formatMessage({
              id: 'banner.pickup_location.sub_text'
            })}
            icon={Warning}
          />
        )}
        {showLocation && (
          <Form
            ref={this.formRef}
            fields={locationFields}
            onValuesChange={(changedFields, allValues) => {
              this.setState({ fields: allValues });

              this.setState({ locationData: allValues });
            }}
            className="br-location-contact-form"
            onFinish={this.handleNextToModalLocation}
          >
            <div className="br-form-row">
              <Form.Item
                name="locationName"
                label={intl.formatMessage({
                  id: 'location_form_labels.location_name'
                })}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.location_name'
                  })}
                  maxLength={50}
                />
              </Form.Item>
            </div>
            <div className="br-form-row br-form-row__city-area">
              <Form.Item
                name="city"
                label={intl.formatMessage({
                  id: 'location_form_labels.city'
                })}
                rules={[{ required: true }]}
              >
                <Select
                  dropdownClassName="br-customer-details__city-dropdown"
                  dropdownStyle={{ minWidth: null }}
                  placeholder={intl.formatMessage({
                    id: `form.select_placeholder`
                  })}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.props.children[0]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={this.handleCityChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {cities.map((city) => (
                    <Select.Option
                      disabled={!city?.pickupAvailability}
                      key={city._id}
                      value={city._id}
                    >
                      <div
                        className={classnames(
                          'display-flex justify-space-between',
                          {
                            'br-uncovered-area': !city?.pickupAvailability
                          }
                        )}
                      >
                        {city?.name}
                        {!city?.pickupAvailability && (
                          <Tag className="ant-tag-light-gray">
                            {intl.formatMessage({
                              id: 'form.uncovered_zone'
                            })}
                          </Tag>
                        )}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <BRArea
                availability={AVAILABILITY.PICKUP}
                areas={areas}
                loading={areasLoading}
                onChange={this.handleOnClearDistrict}
                disabled={!this.formRef.current?.getFieldValue('city')}
              />
            </div>

            <Form.Item
              name="firstLine"
              label={intl.formatMessage({
                id: 'location_form_labels.street'
              })}
              rules={[{ required: true, max: 250 }]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'settings.pickup_locations.pickup_location_form.form_placeholders.street'
                })}
                maxLength={150}
              />
            </Form.Item>

            <div className="br-form-row">
              <Form.Item
                name="buildingNumber"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.building'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[{ max: 25 }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.building'
                  })}
                  maxLength={25}
                />
              </Form.Item>
              <Form.Item
                name="floor"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.floor'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[{ max: 25 }]}
              >
                <Input
                  maxLength={25}
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.floor'
                  })}
                />
              </Form.Item>
              <Form.Item
                name="apartment"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.apartment'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[{ max: 25 }]}
              >
                <Input
                  maxLength={25}
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.apartment'
                  })}
                />
              </Form.Item>
            </div>
            <div className="br-location-contact-form__footer">
              <Form.Item
                name="isDefault"
                className="br-location-contact-form__footer__checkbox"
              >
                <Checkbox onChange={this.onChangeCheck}>
                  {intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.set_default_location'
                  })}
                </Checkbox>
              </Form.Item>
              <div className="br-location-contact-form__footer__right-side-buttons">
                <Button
                  className="br-location-contact-form__footer__cancel-button"
                  onClick={() => this.handleLocationFormCancel()}
                >
                  {intl.formatMessage({
                    id: 'common.cancel'
                  })}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="br-location-contact-form__footer__next-button"
                  loading={isFormLoading}
                  disabled={isFormLoading}
                >
                  {intl.formatMessage({
                    id: pickupLocation
                      ? 'settings.pickup_locations.pickup_location_form.update_button'
                      : 'common.next'
                  })}
                </Button>
              </div>
            </div>
          </Form>
        )}
        {showContacts && (
          <Form
            ref={this.contactsFormRef}
            fields={fields}
            onValuesChange={(changedFields, allValues) => {
              this.setState({ fields: allValues, contactsFormData: allValues });
            }}
            className="br-location-contact-form"
            onFinish={this.handleSubmit}
          >
            <div className="br-form-row">
              <Form.Item
                name="firstName"
                label={intl.formatMessage({
                  id: 'contact_form_labels.first_name'
                })}
                rules={[{ required: true, max: 100 }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.first_name'
                  })}
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={intl.formatMessage({
                  id: 'contact_form_labels.last_name'
                })}
                rules={[{ required: true, max: 100 }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.last_name'
                  })}
                />
              </Form.Item>
            </div>
            <div className="br-form-row">
              <Form.Item
                name="phone"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    label: intl.formatMessage({
                      id: 'contact_form_labels.phone'
                    })
                  },
                  phoneNumberRule({
                    message: intl.formatMessage({
                      id: 'form.phone_not_valid'
                    }),
                    internationlNumbers: true
                  })
                ]}
              >
                <BRFormInputPhoneNumber
                  placeholder={intl.formatMessage({
                    id:
                      JSON.parse(localStorage.getItem('userInfo'))?.country
                        ?.code === COUNTRIES[1].codeName
                        ? 'form.saudi_arabia_phone_placeholder'
                        : 'form.egypt_phone_placeholder'
                  })}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.phone'
                  })}
                  filedName="phone"
                  handleOnChangeForm={this.handleOnChangeContactsForm}
                />
              </Form.Item>
              <Form.Item
                name="secPhone"
                validateTrigger="onBlur"
                rules={[
                  phoneNumberRule({
                    message: intl.formatMessage({
                      id: 'form.phone_not_valid'
                    }),
                    landlineNumbers: true
                  })
                ]}
              >
                <BRFormInputPhoneNumber
                  optional
                  placeholder={intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.form_placeholders.phone'
                  })}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.secondary_phone'
                  })}
                  filedName="secPhone"
                  handleOnChangeForm={this.handleOnChangeContactsForm}
                />
              </Form.Item>
            </div>
            <Form.Item
              name="email"
              className="br-form-row__half-width"
              label={
                <>
                  {intl.formatMessage({
                    id: 'contact_form_labels.email'
                  })}
                  <span className="br-form-optional-label">
                    {intl.formatMessage({
                      id: 'form.optional_label'
                    })}
                  </span>
                </>
              }
              rules={[
                emailRule(
                  intl.formatMessage({
                    id: 'form.email_not_valid'
                  })
                )
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'settings.pickup_locations.pickup_location_form.form_placeholders.email'
                })}
              />
            </Form.Item>

            <div className="br-location-contact-form__footer">
              <Button
                className="br-location-contact-form__footer__back-button"
                onClick={() => this.handleBackClick()}
              >
                <LeftOutlined />
                {intl.formatMessage({
                  id: 'common.back'
                })}
              </Button>
              <div className="br-location-contact-form__footer__right-side-buttons">
                <Button
                  className="br-location-contact-form__footer__cancel-button"
                  onClick={() => this.handleContactsFormCancel()}
                >
                  {intl.formatMessage({
                    id: 'common.cancel'
                  })}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="br-location-contact-form__footer__next-button"
                  loading={isFormLoading}
                  disabled={isFormLoading}
                >
                  {intl.formatMessage({
                    id: 'common.next'
                  })}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>
    );
  }
}

export default injectIntl(CreatePickupLocationFormModal);
