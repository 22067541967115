import classnames from 'classnames';

import './BarcodeCards.less';

const BarcodeCards = ({ title, count, icon, iconColor }) => {
  return (
    <div className="br-barcodes-to-awb__card">
      <div className="br-barcodes-to-awb__card-title">
        {title}
        <div className="br-barcodes-to-awb__card-count display-sm">{count}</div>
      </div>
      <div className={classnames('br-barcodes-to-awb__card-icon', [iconColor])}>
        {icon}
      </div>
    </div>
  );
};

export default BarcodeCards;
