import React from 'react';
import { Modal } from 'antd';

import './PreviewImage.less';

class PreviewImage extends React.Component {
  render() {
    const { close, previewImage, previewImageTitle } = this.props;

    return (
      <Modal
        {...this.props}
        footer={null}
        title={previewImageTitle}
        onCancel={close}
      >
        <img
          alt="Item Image"
          className="br-support-tickets__preview-image"
          src={previewImage}
        />
      </Modal>
    );
  }
}

export default PreviewImage;
