import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Tabs, Table } from 'antd';

import { getHubs } from 'actions';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import HubManagmentContainer from './HubManagmentContainer';

import styles from './styles.module.scss';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
// import 'antd/es/table/style/index.css';
// import 'antd/es/pagination/style/index.css';

/**
 * format address (first line, zone name, city name)
 * @param address
 * @returns {string}
 */
const formatAddress = (address) => {
  const addressArr = [];
  addressArr.push(address.firstLine);
  if (address.zone) {
    addressArr.push(address.zone.name);
  }
  if (address.city) {
    addressArr.push(address.city.name);
  }
  return addressArr.join(', ');
};

/**
 * format data read for binding in table
 * @param data
 * @returns {*}
 */
const formatDatasource = (data) => {
  return data.map(({ _id, name, address }) => {
    return {
      _id,
      key: _id,
      name,
      hubAddress: formatAddress(address)
    };
  });
};

function HubManagement({ history, hubs, fetchHubs, location: { pathname } }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetchHubs();
    setLoading(false);
  }, []);
  useEffect(() => {
    if (hubs.length) {
      setLoading(false);
    }
  });
  return (
    <Container
      className="br-tabs-container-content"
      header={<BRHeader title="Hub Management" />}
      hideWarningBanner
      content={
        <>
          <HubManagmentContainer
            children={
              <>
                <div className={styles.divCreate}>
                  <Link to="/hub-management/create-hub">
                    <Button 
                    disabled={!isUserAuthorized(aclMatrix.HUB_CREATE_EDIT)}
                    className="primaryButton">
                      Create New Hub
                      </Button>
                  </Link>
                </div>
                {hubs?.length > 0 && (
                  <div className="mb-4 mt-4">
                    <b>Total Hubs:</b>
                    <span className="ml-2 ">{hubs.length}</span>
                  </div>
                )}
                <Table
                  // rowSelection={rowSelection}
                  className="mt-3"
                  style={{ overflow: 'auto' }}
                  columns={[
                    {
                      dataIndex: 'name',
                      key: 'name',
                      title: 'Name',
                      render: (name, row) => (
                        <a
                        disabled={!isUserAuthorized(aclMatrix.HUB_CREATE_EDIT)}
                          href={`/hub-management/edit-hub/${row._id}`}
                          target="_blank"
                        >
                          {name}
                        </a>
                      )
                    },
                    {
                      dataIndex: 'hubAddress',
                      key: 'hubAddress',
                      title: 'Address'
                    }
                  ]}
                  dataSource={formatDatasource(hubs)}
                  loading={loading}
                  pagination={false}
                />
              </>
            }
          />
        </>
      }
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs())
});
const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HubManagement)
);
