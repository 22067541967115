import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';
import { SllrProvider } from 'contexts/sllr.context';
import {
  FULFILLMENT_BUSINESSES_MAIN_PATH,
  FULFILLMENT_INBOUND_MAIN_PATH,
  FULFILLMENT_OUTBOUND_MAIN_PATH,
  FULFILLMENT_PRODUCTS_MAIN_PATH
} from 'constants/fulfillment';
import { FULFILLMENT_RETURNS_MAIN_PATH } from 'constants/fulfillment-return-orders';
import { FULFILLMENT_LOCATIONS_MAIN_PATH } from 'constants/fulfillment-locations';
import { FULFILLMENT_INVENTORY_SERIALS_MAIN_PATH } from 'constants/fulfillment-inventory-serials';
import { FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH } from 'constants/fulfillment-problematic-jobs';
import { FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH } from 'constants/fulfillment-return-to-vendor';

import FulfillmentInbound from 'components/NewFulfillment/FulfillmentInbound/FulfillmentInbound';
import FulfillmentOutbound from 'components/NewFulfillment/FulfillmentOutbound/FulfillmentOutbound';
import FulfillmentProducts from 'components/NewFulfillment/FulfillmentProducts/FulfillmentProducts';
import Businesses from 'components/NewFulfillment/Businesses/Businesses';
import FulfillmentReturns from 'components/NewFulfillment/FulfillmentReturns/FulfillmentReturns';
import FulfillmentLocations from 'components/NewFulfillment/FulfillmentLocations/FulfillmentLocations';
import FulfillmentInventorySerials from 'components/NewFulfillment/FulfillmentInventorySerials/FulfillmentInventorySerials';
import FulfillmentProblematicJobs from 'components/NewFulfillment/FulfillmentProblematicJobs/FulfillmentProblematicJobs';
import FulfillmentReturnToVendor from 'components/NewFulfillment/FulfillmentReturnToVendor/FulfillmentReturnToVendor';

const FulfillmentRoutes = ({ match: { path } }) => {
  const access = [...aclMatrix.DELIVERIES],
    permissions = [ACL_MATRIX.BOSTA_FULFILLMENT_LIST];

  const FULFILLMENT_ROUTES = [
    {
      path: FULFILLMENT_INBOUND_MAIN_PATH,
      name: 'Fulfillment Inbound',
      component: FulfillmentInbound
    },
    {
      path: FULFILLMENT_OUTBOUND_MAIN_PATH,
      name: 'Fulfillment Outbound',
      component: FulfillmentOutbound
    },
    {
      path: FULFILLMENT_PRODUCTS_MAIN_PATH,
      name: 'Fulfillment Products',
      component: FulfillmentProducts
    },
    {
      path: FULFILLMENT_BUSINESSES_MAIN_PATH,
      name: 'Fulfillment Businesses',
      component: Businesses
    },
    {
      path: FULFILLMENT_RETURNS_MAIN_PATH,
      name: 'Fulfillment Returns',
      component: FulfillmentReturns
    },
    {
      path: FULFILLMENT_LOCATIONS_MAIN_PATH,
      name: 'Fulfillment Locations',
      component: FulfillmentLocations
    },
    {
      path: FULFILLMENT_INVENTORY_SERIALS_MAIN_PATH,
      name: 'Fulfillment Inventory Serials',
      component: FulfillmentInventorySerials
    },
    {
      path: FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH,
      name: 'Problematic List',
      component: FulfillmentProblematicJobs
    },
    {
      path: FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH,
      name: 'Return To Vendor List',
      component: FulfillmentReturnToVendor
    }
  ];

  return (
    <SllrProvider>
      <Switch>
        {FULFILLMENT_ROUTES.map(
          (route, index) =>
            isUserAuthorized(access, permissions) && (
              <Route key={index} {...route} />
            )
        )}
        <Redirect from={path} to={FULFILLMENT_INBOUND_MAIN_PATH} />
      </Switch>
    </SllrProvider>
  );
};

export default withRouter(FulfillmentRoutes);
