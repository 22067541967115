import actionTypes from "../actions/ActionTypes";

const initialState = {
  leaders: [],
  clerks: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LEADERS_RECEIVED:
      return { ...state, leaders: action.payload };
    case actionTypes.GET_CLERKS_RECEIVED:
      return { ...state, clerks: action.payload };
    default:
      return state;
  }
}
