import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as SuccessIcon } from 'assets/bosta-icons/check-circle.svg';

import './SuccessModal.less';

const SuccessModal = ({
  intl,
  close,
  modalAction,
  cancelAction = () => {},
  title,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonPrefixIcon,
  ...props
}) => {
  const confirmAction = () => {
    modalAction();
    close();
  };

  const onCancel = () => {
    cancelAction();
    close();
  };

  return (
    <Modal
      title={null}
      width={400}
      footer={null}
      wrapClassName="br-success-modal"
      onCancel={close}
      {...props}
    >
      <>
        <div className="br-success-modal__icon">
          <SuccessIcon />
        </div>
        <div className="br-success-modal__title display-xs">{title}</div>
        <div className="br-success-modal__actions">
          {modalAction && (
            <BRButton
              type="primary"
              className="button-lg"
              onClick={confirmAction}
              label={
                confirmButtonLabel ||
                intl.formatMessage({ id: 'common.confirm' })
              }
              prefixIcon={confirmButtonPrefixIcon}
            />
          )}

          <BRButton
            className="button-lg"
            onClick={onCancel}
            label={
              cancelButtonLabel || intl.formatMessage({ id: 'common.close' })
            }
          />
        </div>
      </>
    </Modal>
  );
};

export default injectIntl(SuccessModal);
