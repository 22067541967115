import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_INBOUND_MAIN_PATH,
  FULFILLMENT_ROUTES
} from 'constants/fulfillment';

import './FulfillmentInbound.less';

const FulfillmentInbound = () => {
  return (
    <Switch>
      {FULFILLMENT_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_INBOUND_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentInbound);
