import { Polygon } from '@react-google-maps/api';

const BostaCodingPolygon = ({
  geometryPath,
  editable = true,
  polygonRef,
  options,
  ...props
}) => {
  if (!geometryPath) {
    return null;
  }

  return (
    <Polygon
      paths={geometryPath}
      ref={polygonRef}
      editable={editable}
      options={options}
      {...props}
    />
  );
};

export default BostaCodingPolygon;
