import { getRandomHexColor } from 'utils/bosta-coding';

const HUB_POLYGON_COLORS = {
  FILL_COLOR: '#0098A5',
  STROKE_COLOR: '#FF0000'
};

const ZONE_POLYGON_COLORS = {
  FILL_COLOR: '#0047AB',
  STROKE_COLOR: '#FFBF00',
  NOTEDITABLE_FILL_COLOR: '#C04000',
  NOTEDITABLE_STROKE_COLOR: '#FFBF00'
};

const STREET_CODE_POLYGON_COLORS = {
  FILL_COLOR: '#0047AB',
  STROKE_COLOR: '#0047AB',
  NOTEDITABLE_FILL_COLOR: '#FF2E2E',
  NOTEDITABLE_STROKE_COLOR: '#FF2E2E'
};

export const HUB_POLYGON_OPTIONS = {
  fillColor: HUB_POLYGON_COLORS.FILL_COLOR,
  fillOpacity: 0.2,
  strokeColor: HUB_POLYGON_COLORS.STROKE_COLOR,
  strokeOpacity: 0.8,
  strokeWeight: 5
};

export const ZONE_POLYGON_OPTIONS = (editable) => ({
  fillColor: editable
    ? ZONE_POLYGON_COLORS.FILL_COLOR
    : ZONE_POLYGON_COLORS.NOTEDITABLE_FILL_COLOR,
  fillOpacity: 0.5,
  strokeColor: editable
    ? ZONE_POLYGON_COLORS.STROKE_COLOR
    : ZONE_POLYGON_COLORS.NOTEDITABLE_STROKE_COLOR,
  strokeOpacity: 0.8,
  strokeWeight: 3,
  zIndex: editable ? 8 : 7
});

export const STREET_CODE_POLYGON_OPTIONS = (editable) => ({
  fillColor: editable
    ? STREET_CODE_POLYGON_COLORS.FILL_COLOR
    : getRandomHexColor(),
  fillOpacity: 0.5,
  strokeColor: editable
    ? STREET_CODE_POLYGON_COLORS.STROKE_COLOR
    : STREET_CODE_POLYGON_COLORS.NOTEDITABLE_STROKE_COLOR,
  strokeOpacity: 0.8,
  strokeWeight: 3,
  zIndex: 9,
  suppressUndo: true
});
