import { useState } from 'react';
import { useIntl } from 'react-intl';

import { updateInternationalOrders } from 'services/international-orders';
import {
  INTERNATIONAL_DELIVERY_UPDATE_SUCCESS_MESSAGE,
  INTERNATIONAL_DELIVERY_UPDATE_TYPES
} from 'constants/international-orders';
import { openModal } from 'utils/modal';

import InternationalOrdersActionSetup from 'components/InternationalOrders/components/InternationalOrdersActions/components/InternationalOrdersActionSetup/InternationalOrdersActionSetup';
import InternationalOrdersActionScan from 'components/InternationalOrders/components/InternationalOrdersActions/components/InternationalOrdersActionScan/InternationalOrdersActionScan';
import InternationalOrdersActionTable from 'components/InternationalOrders/components/InternationalOrdersActions/components/InternationalOrdersActionTable/InternationalOrdersActionTable';
import NewConfirmationModal from 'components/BRModals/NewConfirmationModal/NewConfirmationModal';
import AddEditFlightDetailsModal from 'components/InternationalOrders/components/AddEditFlightDetailsModal/AddEditFlightDetailsModal';
import { notify } from 'components/Notify/Notify';

import './InternationalOrdersActions.less';

const InternationalOrdersActions = ({ updateType }) => {
  const [scannedDeliveries, setScannedDeliveries] = useState([]);
  const [isScanning, setIsScanning] = useState(false);

  const intl = useIntl();

  const handleActionConfirmation = async (updatedParams) => {
    try {
      const payload = {
        updateType,
        trackingNumbers: scannedDeliveries.map(
          (delivery) => delivery.trackingNumber
        ),
        fields: {
          ...updatedParams
        }
      };

      await updateInternationalOrders(payload);
      notify(
        INTERNATIONAL_DELIVERY_UPDATE_SUCCESS_MESSAGE(
          payload.trackingNumbers.length
        )[updateType],
        'success'
      );
      setScannedDeliveries([]);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleTableActionClick = () => {
    const count = scannedDeliveries.length;

    switch (updateType) {
      case INTERNATIONAL_DELIVERY_UPDATE_TYPES.RECEIVE_AT_WAREHOUSE:
        openModal(NewConfirmationModal, {
          title: intl.formatMessage(
            {
              id: 'international_orders.transfer.table.receive_confirmation_title'
            },
            {
              count
            }
          ),
          subtitle: intl.formatMessage(
            {
              id: 'international_orders.transfer.table.receive_confirmation_subtitle'
            },
            {
              count
            }
          ),
          onConfirm: handleActionConfirmation
        });
        break;
      case INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER:
        openModal(AddEditFlightDetailsModal, {
          count,
          onConfirm: (values) => handleActionConfirmation(values)
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="br-internaitonal-orders-transfer__container">
      <InternationalOrdersActionSetup
        isScanning={isScanning}
        setIsScanning={setIsScanning}
        updateType={updateType}
      />
      <InternationalOrdersActionScan
        isScanning={isScanning}
        scannedDeliveries={scannedDeliveries}
        setScannedDeliveries={setScannedDeliveries}
        updateType={updateType}
      />
      <InternationalOrdersActionTable
        scannedDeliveries={scannedDeliveries}
        handleTableActionClick={handleTableActionClick}
        updateType={updateType}
      />
    </div>
  );
};

export default InternationalOrdersActions;
