export default {
  common: {
    create: 'جديد ...',
    edit: 'تعديل',
    delete: 'مسح',
    yes: 'نعم',
    no: 'لا',
    cancel: 'إلغاء',
    done: 'تم',
    next: 'التالى',
    back: 'رجوع',
    ok: ' موافق',
    save: 'حفظ',
    submit: 'إرسل',
    export: 'حفظ كـ ملف',
    empty_field: '--',
    reset: 'إعادة تعيين',
    confirm: 'أكد'
  },
  form: {
    required: '${label} مطلوب!',
    required_custom: '{label} مطلوب!',
    email_not_valid: 'البريد الإلكتروني غير صحيح',
    phone_not_valid: 'رقم التليفون غير صحيح',
    phone_or_landline_length: 'يرجى إدخال التليفون المحمول أو التليفون الارضي',
    phone_length: 'يرجى إدخال التليفون المحمول',
    maxLen: '${label} لا يمكن أن يكون أكثر من ${max} أحرف',
    optional_label: '-اختياري',
    select_placeholder: 'اختار',
    new_optional_label: 'اختياري',
    uncovered_zone: 'منطقة غير مغطاه',
    area_select_placeholder: 'البحث بكتابة اسم المنطقة',
    egypt_phone_placeholder: 'مثال 01234567890',
    saudi_arabia_phone_placeholder: 'مثال: 0503344556 او 966503344556+',
    international_phone_placeholder: '+201234567890 مثال'
  },
  nav_bar: {
    orders: 'الأوردرات',
    pickups: 'استلام الأوردرات',
    support_tickets: 'الدعم',
    settings: 'إعدادات',
    logout: 'تسجيل الخروج',
    new_label: 'جديد'
  },

  contact_form_labels: {
    first_name: 'الاسم الأول',
    last_name: 'اسم العائلة',
    full_name: 'الاسم بالكامل',
    phone: 'التليفون',
    email: 'البريد الإلكتروني',
    secondary_phone: 'رقم تليفون أحتياطي',
    name: 'الاسم',
    address_line: 'العنوان'
  },
  location_form_labels: {
    city: 'المحافظه',
    area: 'المنطقة',
    street: 'اسم الشارع',
    building: 'عمارة رقم',
    floor: 'دور رقم',
    apartment: 'شقة رقم',
    name: 'الاسم',
    district: 'منطقة',
    location_name: 'اسم الموقع',
    zone: 'منطقة',
    landmarks: 'معالم',
    address_line: 'العنوان بالتفصبل'
  },
  notification: {
    title: 'التنبيهات',
    TICKET_NOTIFICATION: 'تنبيهات الدعم ',
    load_more: ' المزيد',
    empty_notification_status: 'مفيش إشعارات جديده '
  },
  banner: {
    pickup_location: {
      title: 'تحديث  مواقع الاستلام',
      sub_text:
        'بوسطة بدأت بإستخدام تقسيم عنواين جديد، جدد عنوان الاستلام عشان عملية استلام شحنات اسرع'
    }
  },
  header: {
    actions: {
      title: 'إنشاء',
      shipment: 'أوردر',
      order_sub_title: 'إنشاء الطلبات واحدًا تلو الآخر.',
      upload_file: 'رفع ملف',
      upload_file_sub_title: 'تحميل الطلبات باستخدام ورقة التحميل الشامل.',
      pickup: 'طلب استلام',
      pickup_sub_title: 'اطلب خدمة التوصيل من شركة الشحن لاستلام طلباتك.',
      download_bosta_template: 'تحميل نموذج بوسطة'
    },
    strip_content: {
      email_verification: {
        title: 'تحقق من عنوان بريدك الإلكتروني',
        sub_text:
          'للحفاظ على أمان حسابك ، يرجى التحقق من عنوان بريدك الإلكتروني ، فقد تم إرسال بريد إلكتروني إلى {email}',
        button_text: 'أرسل رمز التحقق'
      },
      bank_details: {
        title: 'أضف تفاصيل البنك الخاص بك',
        sub_text:
          'أدخل التفاصيل المصرفية الخاصة بك لبدء تلقي إيراداتك من خلال التحويلات المصرفية.',
        button_text: 'أضف تفاصيل البنك'
      }
    }
  },
  sign_in: {
    welcome_page: {
      title: 'أهلا بيك مرة أخرى',
      header: 'تسجيل الدخول إلى حسابك',
      email_label: 'ايميلك',
      password_label: 'كلمة السر',
      have_an_account: ' حساب جديد؟',
      trusted_by: 'شركاء النجاح',
      image_title: 'إرسال ومتابعة شحناتك بنجاح'
    },
    reset_password: {
      title: 'تغيير كلمة السر',
      heading: 'كلنا بننسى! دخل ايميلك ',
      sub_heading: 'و هنبعتلك لينك علشان تغير كلمة السر',
      email_label: 'ايميلك',
      confirm_reset_password: {
        header: 'هنساعدك على تغيير كلمة السر',
        confirm_email: 'تأكيد ايميلك',
        check_email_password: 'هنبعتلك لينك علشان تغير  كلمة السر',
        check_email_reset_password: 'ممكن دلوقتي تشوفها على ايميلك '
      },
      set_new_password: {
        header: 'كلمة السر الجديدة',
        password_label: 'كلمة السر',
        confirm_password_label: 'تأكيد كلمة السر',
        confirm_password_not_match: 'أتأكد من كلمات السر اللي كتبتها!'
      }
    },
    actions: {
      reset_button: 'تغيير الآن',
      create_new_account: 'إعمل حساب جديد',
      login_button: 'تسجيل الدخول',
      forget_password: 'نسيت كلمة السر؟'
    }
  },
  sign_up: {
    header: 'بناء قصة نجاح عملك مع بوسطة ..',
    title: 'إعمل حساب جديد',
    sub_title: 'ابدأ من بوسطه',
    confirm_password_not_match: 'أتأكد من كلمات السر اللي كتبتها!',
    how_required: 'سمعت عنا منين',
    more_than: 'أكثر من',
    less_than: 'أقل من',
    number: ' ١٠٠ ',
    order_per_month: 'أوردرات/الشهر',
    read_terms_first: ' يرجى قراءة وقبول',
    terms_and_conditions: ' الشروط والأحكام ',
    read_terms_second_confirmation:
      ' من أجل إكمال الاشتراك. هيجيلك رسالة على ايميلك مع الشروط والأحكام عند اكتمال الاشتراك.',
    have_an_account_header: 'عندك حساب ؟!',
    terms_pro_lite_validation: 'تحتاج إلى تحديد عدد الأوردرات أولا',
    orders_required_message: 'يرجى تحديد عدد الأوردرات',
    form_labels: {
      password: 'كلمة السر',
      orders_volume: ' عدد الأوردرات',
      confirm_password: 'تأكيد كلمة السر',
      about_us: 'سمعت عنا منين؟'
    },
    form_placeholders: {
      first_name: 'اسمك الأول',
      last_name: 'اسمك الأخير',
      phone: 'Ex: 01012345678',
      email: 'Example@email.com'
    },
    company_options: {
      facebook: 'فيسبوك',
      google: 'جوجل',
      event: 'مؤتمرات',
      friend: 'من صديق',
      old_customer: 'استخدمت بوسطة كعميل من قبل',
      expandcart: 'اكسباند كارت',
      zvendo: 'زفاندو',
      zyda: 'زيدا:',
      vowala: 'فوالا',
      shopify: 'شوبيفي',
      woocommerce: 'وو كوميرس'
    },
    terms_required: 'يرجى قبول الشروط والأحكام الخاصة بنا',
    shipment_volume_options: {
      lite_option: 'لايت',
      pro_option: 'برو'
    },
    terms_modal: {
      title: 'الشروط والأحكام',
      sub_title: 'يرجى قراءة الشروط والأحكام الكاملة قبل قبولك عليها',
      pricing_title: 'الأسعار',
      terms_title_plan: ' خطط أسعار التسليم في اليوم التالي',
      terms_cities: {
        cairo: 'القاهرة',
        alex: 'الإسكندرية',
        delta: 'دلتا/القناة',
        upper: 'الصعيد'
      },
      terms_notes: {
        flyer_note: 'كل ما يمكنك شحنه يصل إلى ٥ كيلو جرام',
        cod_note: 'شاملة المدفوعات',
        fees_note: 'شامل جميع الرسوم وضريبة القيمة المضافة',
        regular_flyer_size: 'شحنه عادية مقاس سم ٤٠ * ٣٥'
      },
      lite_pricing: {
        cairo_pricing: '٥٧ جنيه',
        alex_pricing: '٦٢ جنيه',
        delta_pricing: '٦٧ جنيه',
        upper_pricing: '٨٢ جنيه'
      },
      pro_pricing: {
        cairo_pricing: '٤٠ جنيه',
        alex_pricing: '٤٥ جنيه',
        delta_pricing: '٤٥ جنيه',
        upper_pricing: '٦٥ جنيه'
      },
      actions: {
        reject_btn: 'رفض',
        accept_btn: 'قبول'
      }
    },
    verify_phone: {
      header: 'جعل التواصل سهل بيننا ...',
      title: 'تأكد من رقم الموبيل',
      code_sent_label: 'نرسل رمز ٤ أرقام إلى رقم الموبيل',
      not_your_phone: ' مش رقمك ؟',
      number_validation_error: 'أدخل ارقام فقط: ٠ - ٩',
      verify_phone_modal: {
        phone_placeholder: 'ex: 01234567890',
        secondry_phone_placeholder: 'ex: 01299447733 or 0277448855',
        title: 'تحديث رقم موبيلك',
        sub_title: 'ضف رقم موبيلك',
        update_and_resend_code: 'تحديث وإعادة إرسال كود الدخول'
      }
    },
    verify_email: {
      header: 'الخطوة الأخيرة لبدء قصة نجاح عملك',
      confirm_email: 'تأكيد عنوان بريدك الإلكتروني',
      check_email:
        'تحقق من بريدك الإلكتروني {email} وتأكيد تسجيل الدخول إلى لوحة القيادة الخاصة بك',
      and: 'و',
      confirm_email_login: 'تأكد من تسجيل الدخول إلى لوحة القيادة الخاصة بك'
    },
    business_information: {
      title: 'مرحبا بكم في بوسطة',
      sub_title: 'اسمحوا لنا أن نعرف المزيد عن عملك',
      form_labels: {
        business_name: 'اسم العمل',
        business_name_english: 'اسم العمل (انجليزي)',
        business_name_arabic: 'اسم العمل (عربي)',
        sales_channel: 'قناة المبيعات',
        store_url: 'عنوان url المتجر',
        business_url: 'عنوان url العمل',
        referral_link: 'مصدر الإحالة',
        industry: 'الصناعة',
        build_type: 'حدد نوع المبنى',
        apartment: 'شقة',
        office: 'مكتب',
        villa: 'فيلا',
        shop: 'متجر'
      },
      form_placeholders: {
        business_placeholder: 'إضافة عملك/العلامة التجارية/اسم الشركة',
        business_english_placeholder: 'اكتب اسم العمل باللغة الإنجليزية',
        business_arabic_placeholder: 'اكتب اسم العمل باللغة العربية',
        sales_placeholder: 'اختر القناة',
        store_url_placeholder: 'Ex: https://www.website-name.com',
        ref_link_placeholder: 'يرجى إدخال مصدر الإحالة الخاص بك',
        industry_placeholder: 'اختر الصناعة',
        address_placeholder: 'أدخل العنوان',
        street_name_placeholder: 'أدخل اسم الشارع',
        landmarks_placeholder: 'مثال: بالقرب من النساجون الشرقيون'
      }
    },
    actions: {
      start_with_bosta: 'ابدأ مع بوسطة',
      signin_btn: 'تسجيل الدخول',
      back_to_signin: 'العودة إلى تسجيل الدخول',
      back_to_signup: 'العودة إلى الاشتراك',
      edit: 'تعديل',
      resend_verify_phone_code_msg: 'سوف تتلقى رمز التأكيد في خلال 1-5 دقائق.',
      resend_verify_phone_code: 'إعادة إرسال الكود',
      confirm_code: 'تأكيد الكود',
      skip: 'تخطي',
      save_and_continue: 'حفظ واستمرار'
    }
  },
  orders: {
    results: 'النتائج',
    loading: 'جارى التحميل ...',
    no_matching_orders: 'لا توجد طلبات مطابقة.',
    error_getting_orders: 'حدث خطأ ما أثناء تلقي الأوردرات..',
    title: 'الشحنات',
    empty_orders_view_title: 'ليس لديك أي شحنات حتى الأن',
    order_types: {
      forward: 'توصيل',
      exchange: 'تبادل',
      customer_return_pickup: 'إرجاع',
      cash_collection: 'تحصيل نقدي',
      all: 'الكل'
    },
    order_status: {
      awaiting_for_action: 'في انتظار الإجراء',
      on_hold: 'قيد الانتظار',
      terminated: 'تم إنهاؤها',
      processing: 'المعالجة',
      created: 'تم إنشاؤه',
      delivered: 'تم التسليم',
      returned: 'عاد',
      "can't_be_delivered": `لا يمكن التسليم`,
      returned_to_business: 'عاد إلى العمل',
      delivery_confirmed: 'تم تأكيد التسليم',
      lost_or_damaged: 'لا يمكن تسليمها'
    },
    new_order: {
      create_title: 'إنشاء طلب',
      edit_title: 'تحرير الأمر',
      tooltips: {
        package_description_tooltip:
          'لاحظ أن تكلفة الحقائب البيضاء 20 جنيهًا مصريًا (بما في ذلك ضريبة القيمة المضافة) بالإضافة إلى رسوم الشحن العادية لكل طلب.',
        open_package_tooltip:
          'لسماح للعملاء بفتح الحزمة يسمح لهم برفض أخذها. في هذه الحالة ، سوف تعيد البوسطة لك. سيتم تطبيق رسوم الإرجاع.',
        work_Address_tootip:
          'ضع علامة عليها لتسليمها خلال أيام العمل وساعات العمل',
        secondry_phone_tootltip:
          'الحصول على رقم هاتف آخر سيؤمن الوصول إلى العميل وتسليم الحزمة الخاصة بك'
      },
      order_details: {
        title: '  تفاصيل الطلب',
        order_type: '  نوع الطلب',
        package_type: 'نوع الحزمة',
        package_details: 'تفاصيل الحزمة',
        delivery_package_details: 'تفاصيل حزمة التسليم',
        return_package_details: 'تفاصيل حزمة الإرجاع',
        view_packing_guidelines: 'عرض إرشادات التعبئة',
        return_package: 'تفاصيل الإرجاع',
        collect_cash_from_customer: 'تحصيل النقود أو رد أموال من العميل',
        refund_cash_to_customer: 'رد الأموال النقدية للعميل',
        open_package_fees_note:
          'سيتم تطبيق رسوم {value} على عملية التسليم هذه.',
        package_types: {
          bulky: 'ضخم',
          document: 'مستند',
          parcel: 'طرد',
          normal: 'عادي'
        },
        order_types: {
          deliver: 'تسليم',
          exchange: 'تبادل',
          return: 'إرجاع',
          cash_collection: 'تحصيل نقدي',
          deliver_sub_title: 'تسليم حزمة إلى عميلك.',
          exchange_sub_title: 'تبادل الحزم مع عميلك',
          return_sub_title: 'احصل على حزمة مرتجعة من عميلك.',
          cash_collection_sub_title: 'تحصيل النقود من عميلك.',
          deliver_options: {
            with_cash_collection: 'مع التحصيل النقدي',
            without_cash_collection: 'بدون تحصيل نقدي'
          },
          exchange_options: {
            with_cash_difference: 'مع فرق نقدي',
            without_cash_difference: 'بدون فرق نقدي'
          },
          return_options: {
            with_refund: 'مع استرداد',
            without_refund: 'بدون استرداد'
          },
          cash_collection_options: {
            with_cash_collection: 'مع التحصيل النقدي',
            with_refund: 'مع استرداد'
          }
        },
        allowed_to_open_package: 'مسموح',
        not_allowed_to_open_package: 'غير مسموح'
      },
      customer_details: {
        title: 'تفاصيل العميل',
        order_notes: 'ملاحظات الطلب',
        address_info: 'معلومات العنوان',
        customer_info: 'معلومات العميل'
      },
      request_pickup: {
        title: 'طلب الاستلام',
        pickup_info: 'معلومات الاستلام',
        pickup_created_successfully: 'تم إنشاء الالتقاط بنجاح',
        skip_pickup_create_note:
          'يمكنك تخطي طلب الالتقاط الآن ولكن تأكد من طلب الالتقاط عندما يكون لديك طرود جاهزة للشحن.'
      },
      order_summary: {
        title: 'ملخص الطلب',
        order_created_successfully: 'تم إنشاء طلبك بنجاح',
        air_waybills_note:
          'لا يمكن شحن الطرود الخاصة بك بدون بوليصة الشحن المطبوعة.',
        skip_printing_note:
          'يمكنك تخطي طباعة بوليصة الشحن الآن وطباعتها لاحقًا ، وتأكد من طباعتها قبل يوم الاستلام',
        download_airwaybill_successfully: 'تم تنزيل بوليصة الشحن الجوي بنجاح'
      },
      request_pickup_modal: {
        title: 'طلب التقاط جديد',
        select: 'اختر',
        form_labels: {
          contact_person: 'جهة الاتصال',
          pickup_location: 'موقع الالتقاء',
          pickup_date: 'تاريخ الاستلام'
        },
        actions: {
          add_new_location: 'إنشاء موقع جديد',
          create_new_location: 'إنشاء موقع جديد',
          create_new_contact: 'إنشاء جهة اتصال جديدة',
          schedule_pickup: 'جدول الاستلام'
        }
      },
      form_labels: {
        forward_cod: 'الدفع عند الاستلام',
        exchange_cod: 'مبلغ الصرف النقدي',
        customer_return_pickup_cod: 'المبلغ المسترد',
        cash_collection_cod: 'النقد لتحصيله',
        cash_collection_cod_sub_title:
          'يقوم ساعي البوسطة بتحصيل هذا المبلغ من عميلك.',
        cash_collection_refund_sub_title:
          'يجب على ساعي البوسطة دفع هذا المبلغ إلى عميلك عند الاستبدال.',
        forward_cod_sub_title:
          'يجب على عميلك دفع هذا المبلغ إلى شركة الشحن عند التسليم.',
        exchange_cod_sub_title:
          'سيتلقى ساعي البوسطة هذا المبلغ من عميلك عند الاستبدال.',
        exchange_refund_sub_title:
          'يجب أن يدفع ساعي البوسطة هذا المبلغ إلى عميلك عند الاستبدال ..',
        customer_return_pickup_cod_sub_title:
          'يجب أن يدفع ساعي البوسطة هذا المبلغ إلى عميلك عند الاستلام.',
        number_of_items: 'عدد العناصر',
        number_of_items_sub_title:
          'إذا كانت عناصرك لا تتناسب مع أي فلاير بأي حجم ، فأنشئ طلبات متعددة.',
        number_of_items_sub_title_bulky:
          'عند طلب الالتقاط ، تأكد من طلب شاحنة لهذا العنصر الضخم.',
        return_location_sub_title: '"حدد الموقع الذي يجب إرجاع الحزمة إليه.',
        cod_amount: 'المبلغ النقدي عند التسليم',
        refund_amount: 'المبلغ المسترد',
        egp: 'جنيه',
        order_reference: 'مرجع الطلب',
        order_reference_sub_title:
          'أضف مرجعًا يمكنك استخدامه لاحقًا للبحث عن الطلب.',
        package_description: 'وصف الحزمة',
        open_package: 'السماح للعملاء بفتح الحزم؟',
        remember_my_choice: 'تذكر خياري.',
        work_address: 'هذا عنوان عمل',
        scheduled_pickups: 'عمليات الالتقاء المجدولة',
        select_from_scheduled_pickups: 'التحديد من عمليات الالتقاط المجدولة',
        landmarks: 'معالم',
        return_location: 'موقع الإرجاع',
        return_notes: 'ملاحظات الإرجاع',
        package_type_sub_title:
          'حدد نوع الحزمة للتأكد من اختيار السيارة المناسبة للاستلام.',
        package_type: 'نوع الحزمة',
        sign_and_collect: 'التوقيع و الجمع',
        sign_and_collect_tooltip: ' للمستندات التي يجب توقيعها وإعادتها إليك'
      },
      form_placeholder: {
        select: 'اختر',
        order_reference_placeholder: 'أحذية Nike - مقاس 41.',
        bulky_order_reference_placeholder: 'مثال: الرقم التسلسلي للمادة',
        description_placeholder_parcel: 'مثال: هاتف جوّال ، عطر ، أحذية .. إلخ',
        description_placeholder_bulky: 'مثال: ثلاجة ، فرن ، ميكروويف ، إلخ ..',
        description_placeholder_document: 'عقد - بطاقة ائتمان - بطاقة صراف آلي',
        order_reference_placeholder_bulky: 'رقم الجهاز التعريفي',
        description_placeholder: 'كود المنتج - اللون - الحجم',
        select_city: 'اختر المدينة',
        select_area: 'حدد المنطقة',
        street_placeholder: 'مثال: شارع مصدق',
        building_placeholder: '1A',
        apartment_placeholder: '22',
        floor_placeholder: '2',
        landmarks_placeholder:
          'على سبيل المثال: بالقرب من ماكدونالدز ، البنك الأهلي',
        notes_placeholder: 'يرجى الاتصال بالعميل قبل تسليم الطلب. ',
        delivery_notes_placeholder: 'يرجى الاتصال بالعميل قبل تسليم الطلب'
      },
      actions: {
        create_new_pickup: 'إنشاء التقاط جديد',
        create_order: 'إنشاء طلب',
        print_awb: 'طباعة',
        create_more_orders: 'إنشاء المزيد من الطلبات',
        view_orders: 'عرض الطلبات'
      }
    },
    mass_upload: {
      title: 'تحميل أكتر من اوردر واحد',
      sub_title: 'حمل أوردراتك بسهولة عن طريق تجميعها في النموذج.',
      steps_title: 'اتبع الخطوات التالية لتحميل أوردراتك',
      step_one: '1. {download} نموذج الأوردرات .',
      step_two: '2. {learn}  تملأ النموذج بالأوردرات الخاصة بيك.',
      step_three: '3. تقدر ترفع الملفات فقط في شكل إكسل (.xlsx)',
      download: 'نزل',
      learn: 'اعرف ازاي',
      business: 'العمل',
      business_placeholder: 'حدد الأعمال',
      upload_dialog: {
        title: 'تحميل ملف الأوردرات (اكسل)',
        sub_title: '“اسحب وارفق الملف هنا أو اضغط على زر “رفع الملف',
        hint: 'الحد الأقصى لعدد الشحنات هو 500 شحنة',
        upload_file: 'رفع ملف'
      },
      error_old_template_title: '!نموذج أوردرات جديد',
      error_old_template_sub_title:
        '.نزل نموذج الأوردرات الجديد واملاه قبل التحميل ',
      error_old_template_hint:
        '.او نسخة احدث لضمان الحصول على احسن تجربة  Microsoft Excel 2010 استخدم',
      error_old_template:
        'الملف الذي تم تحميله لم يعد مدعوم. يرجى تنزيل النموذج الجديد قبل تحميل طلباتك.',
      error_select_business: 'الرجاء تحديد العمل أولاً',
      error_table: {
        title:
          'فشل التحميل. الرجاء إصلاح الأخطاء المُوضَحة وإعادة تحميل الملف.',
        sub_title:
          '{issuesNumber} مشكلة تم العثور عليها في الأوردرات اللي تم تحميلها.',
        file_name: 'اسم الملف: {fileName}',
        file_size: 'حجم الملف: {fileSize} ميجابايت',
        total_orders: 'إجمالي الأوردرات: {ordersNumber}',
        reupload: 'إعادة تحميل',
        columns: {
          field_names: 'أسماء الخانة',
          issue: 'مشكلة',
          in_rows: 'في الصفوف',
          field_is_required: 'مطلوب كتابة البيانات في الخانة',
          field_is_not_valid: 'البيانات غير صحيحة',
          view_more: 'عرض المزيد',
          file_downloaded_successfully: 'تم تحميل الاوردرات بنجاح'
        },
        errors: {
          field_is_required: 'الخانة دي مطلوبة',
          full_name_less_than_50_characters:
            'لازم القيمة تكون اقل من او يساوي ل 50 حرف.',
          phone_not_valid:
            'لازم رقم الموبايل يكون ١١ رقم ويبدأ ب 010, 011, 012 أو 015 (مثال: 01233344556)',
          second_phone_not_valid:
            'لازم الرقم التاني يكون موبايل او ارضي  (مثال: 01233344556 او 0221038650)',
          street_name_less_than_250_characters:
            'لابد ان يكون اقل من او يساوي ل 250 حرف.',
          work_address_should_be_empty_yes_or_no:
            'لابد ان يكون القيمة فارغة او Yes او No.',
          delivery_notes_less_than_100_characters:
            'لابد ان تكون اقل من او يساوي ل 200 حرف.',
          order_type_should_either_be_empty_or_one_of_dropdown_values:
            'لازم القيمة تكون Deliver او Return او Exchange او Cash Collection.',
          deliver_negative_string:
            'لازم القيمة تكون اكبر من او يساوي صفر. بوسطة لا تدعم إعادة مبالغ نقدية مع اوردرات التوصيل حاليا. ',
          exchange_allowed_refund_string:
            'لازم القيمة تكون مبلغ نقدي. اكبر من صفر في حالة التحصيل و اصغر من صفر في حالة الاسترداد.',
          exchange_not_allowed_refund_negative_string:
            'لازم القيمة تكون اكبر من او يساوي صفر. لوعايز حسابك يسمح بإعادة مبلغ نقدي مع اوردرات الاستبدال، اتصل بفريق بوسطة.',
          return_allowed_refund_string: 'لازم القيمة فاضية او تكون مبلغ نقدي.',
          return_not_allowed_refund_positive_negative_string:
            'لازم القيمة تكون فاضية. لوعايز حسابك يسمح بإعادة مبلغ نقدي مع اوردرات المرتجعة، اتصل بفريق بوسطة.',
          cash_collection_allowed_refund_empty_zero_string:
            'لازم القيمة تكون مبلغ نقدي. اكبر من صفر في حالة التحصيل و اصغر من صفر في حالة الاسترداد.',
          cash_collection_not_allowed_refund_empty_zero_negative_string:
            'لازم يكون المبلغ المحصل أكبر من صفر. لوعايز حسابك يسمح بإعادة مبلغ نقدي مع اوردرات الاستبدال، اتصل بفريق بوسطة.',
          package_type_should_either_be_empty_or_one_of_dropdown_values:
            'لازم القيمة تكون فاضية اوParcel او Document او Bulky.',
          number_items_less_than_4: 'لازم القيمة تكون اقل من او يساوي ٤ ارقام.',
          package_description_less_than_500:
            'لازم القيمة تكون اقل من او يساوي 500 حرف.',
          order_reference_less_than_100:
            'لازم القيمة تكون اقل من او يساوي 100 حرف ورقم.',
          allow_open_packages_should_be_empty_yes_or_no:
            'لازم القيمة تكون فاضية Yes او No.',
          return_number_items_less_than_4:
            'لازم القيمة تكون اقل من او يساوي ٤ ارقام.',
          return_package_description_less_than_500:
            'لازم القيمة تكون اقل من او يساوي 500 حرف.',
          // uncovered_city:
          //   'مدينة {cityName} منطقة غير مغطاة، لمعرفة جميع المناطق الغير مغطاه من بوسطة {clickHere}، اختار عنوان أخر أو امسح هذا الأوردر',
          uncovered_city:
            'بوسطة مش بتخدم المدينة اللي انت مختارها. {clickHere} لمعرفة المدن والمناطق الخارجة عن الخدمة',
          click_here: 'اضغط هنا',
          not_found_city:
            'بوسطة مش بتخدم المدينة اللي انت مختارها. اتصل بخدمة العملاء لمزيد من المعلومات.',
          not_found_zone:
            'بوسطة مش بتخدم المنطقة اللي انت مختارها. اتصل بخدمة العملاء لمزيد من المعلومات.',
          not_found_district:
            'بوسطة مش بتخدم المنطقة اللي انت مختارها. اتصل بخدمة العملاء لمزيد من المعلومات.'
        }
      },
      success: {
        title: 'تم تحميل ملفك بنجاح',
        sub_title: 'يمكنك العثور على طلباتك في صفحة الطلبات.',
        print_all_airwaybills: 'طباعة جميع بوليصات الشحن الجوي',
        upload_more: 'تحميل المزيد من الطلبات',
        view_orders: 'عرض الطلبات'
      }
    },
    delete_modal: {
      title: 'مسح الأوردر',
      content: 'هل أنت متأكد أنك تريد مسح الأوردر',
      content_warning: '؟سيتم مسحه من الأوردرات الخاصة بك'
    },
    table_columns: {
      order_id: ' رقم',
      date: 'التاريخ',
      customer_info: 'معلومات العملاء',
      location: 'الموقع',
      cod: 'التحصيل',
      type: 'نوع',
      status: 'الحالة'
    },
    status_filter: {
      awaiting_for_action: 'في انتظار اتخاذ إجراء',
      on_hold: ' موقوف',
      terminated: 'إنهاء',
      processing: 'جاري الشحن',
      created: 'تم إنشاؤه',
      delivered: 'تم تسليمه',
      returned: 'مرتجع',
      cannot_be_delivered: 'لا يمكن تسليمها',
      all: 'الكل'
    },
    status_filter_labels: {
      city: 'المدينة',
      zone: 'المنطقة',
      customer_name: 'اسم العميل',
      mobile_phone: 'الموبيل',
      business_reference: 'مرجع العميل',
      shipment_type: 'نوع الشحنة',
      start_date: 'تاريخ البدء',
      end_date: 'تاريخ الانتهاء',
      states: 'الحاله'
    },
    advanced_search: {
      title: 'البحث',
      search_placeholder: 'البحث عن طريق تتبع الأرقام أو أرقام الهواتف',
      modal_title: 'البحث المتقدم',
      modal_sub_title: 'يمكنك استخدام ما يلي للبحث والتصفية',
      order_type: 'نوع الطلب',
      status_sub_title: 'حدد نوع واحد أو أكثر',
      business_reference: 'مرجع العميل',
      business_ref_sub_title: 'تصفية مع معلومات الحزمة الخاصة بك إذا لزم الأمر',
      customer_name: 'اسم العميل',
      customer_sub_title: 'تصفية مع معلومات العملاء إذا لزم الأمر',
      customer_location: 'موقع العميل',
      customer_location_sub_title: 'تصفية مع مدينة',
      zone_filter: 'تصفية مع منطقة',
      delivery_status: {
        title: 'حالة الشحنه',
        sub_title: 'حدد حالة واحدة أو أكثر ثم يمكنك إضافة نطاق التاريخ',
        all: 'الكل',
        created: 'تم إنشاؤه',
        exception: 'في انتظار الإجراء (استثناء)',
        delivered: 'تم التسليم',
        processing: 'معالجة',
        pickedup: 'تم استلامه من بوسطه',
        canceled: 'تم الإلغاء',
        returned: 'مرتجع',
        terminated: 'تم إنهاؤه',
        lost_or_damaged: 'لا يمكن التسليم'
      },
      clear_all: 'امسح جميع',
      select_placeholder: 'اختر ...'
    },
    shipment_details: {
      sidebar: {
        summary_label: 'موجز',
        tracking_label: 'تتبع'
      },
      title: 'رقم التتبع {trackingNumber}',
      shipment_details_timeline: {
        date_titles: {
          expected_return_date: 'تاريخ الإرجاع المتوقع:',
          delivery_date: 'يتم التوصيل في:',
          expected_delivery_date: 'تاريخ التسليم المتوقع:',
          returened_date: 'عاد في:',
          exception_reason: 'سبب الاستثناء:',
          expected_exchange_date: 'تاريخ الصرف المتوقع:'
        },
        footer: {
          next_action_label: 'الإجراء التالي:',
          shipment_age_label: 'عمر الشحن:',
          overdue_sla_label: 'تأخر الشحنات',
          shipment_age_tail_label: 'من 10 أيام '
        },
        shipment_age_tooltips: {
          10: 'من المتوقع تسليم شحنة أو إعادتها لك خلال 10 أيام ابتداء من تاريخ الاستلام. إذا لم يتم تسليم الشحنة لأكثر من 10 أيام ، فهي شحنة متأخرة (شحنة متأخرة).',
          15: 'من المتوقع أن يتم استلام الشحنة من عميلك وإعادتها إليك في غضون 10 أيام بدءًا من تاريخ الإنشاء. إذا لم تتم إعادة الشحنة إليك في غضون 10 أيام ، فهذه شحنة متأخرة (شحنة متأخرة)',
          20: 'من المتوقع أن يتم استلام الشحنة من عميلك وإعادتها إليك في غضون 10 أيام بدءًا من تاريخ الإنشاء. إذا لم تتم إعادة الشحنة إليك في غضون 10 أيام ، فهذه شحنة متأخرة (شحنة متأخرة)',
          25: 'من المتوقع أن يتم استلام الشحنة من عميلك وإعادتها إليك في غضون 10 أيام بدءًا من تاريخ الإنشاء. إذا لم تتم إعادة الشحنة إليك في غضون 10 أيام ، فهذه شحنة متأخرة (شحنة متأخرة)',
          30: 'تستغرق عملية تسليم شحنة إلى عميلك (إعادة توجيه) ، واستلام شحنة أخرى من العميل وإعادتها إليك (استلام العميل المرتجع) مدة أقصاها 10 أيام ، بدءًا من تاريخ الاستلام للأمام. توصيل. إذا تجاوز عمر الشحن 10 أيام ولم يكتمل بعد ، فهذا يعني أن الشحن متأخر. إذا تأخرت شحنتك ، يمكنك إنشاء طلب دعم وسنساعدك'
        }
      },
      shipment_details_summary: {
        order_types: {
          forward: 'توصيل',
          cash_collection: 'تحصيل نقدي',
          return_to_origin: 'ارجاع إلى المصدر',
          customer_return_pickup: 'إرجاع',
          exchange: 'تبادل'
        },
        tables_headers: {
          shipment_info: 'معلومات الشحن',
          deliver: 'توصيل',
          pickup_info: 'معلومات طلب استلام الشحنة',
          bosta_performance: 'أداء بوسطة',
          customer_info: 'معلومات العملاء',
          customer_info_reciever: 'معلومات العملاء ( المتلقي )',
          return_location: 'موقع الإرجاع',
          exchange: 'معلومات الأعمال',
          order_details: 'تفاصيل الطلب',
          return: 'الارجاع'
        },
        table_cells: {
          pickup_request_id: 'معرف طلب استلام الشحنة',
          pickup_date: 'تاريخ طلب استلام',
          area: 'المنطقة',
          city: 'المدينة',
          description: 'الوصف',
          star_name: 'اسم مندوب الشحن',
          star_phone: 'ستار الهاتف',
          dropOff_Address: 'عنوان دروب أوف',
          type: 'نوع',
          number_of_items: 'رقم العناصر',
          location_name: 'اسم الموقع',
          street_name: 'اسم الشارع',
          last_update_date: 'تاريخ التحديث الأخير',
          shipment_info_table: {
            business_refrence: 'مرجع الأعمال',
            cod: 'كاش عند الاستلام',
            cash_to_be_collected: 'النقدية التي يتعين جمعها',
            delivery_notes: 'ملاحظات التسليم',
            return_notes: 'ملاحظات الإرجاع',
            pickedup_items: 'البنود pickedup من العملاء',
            opening_package: 'فتح الطرد',
            refund_amount: 'المبلغ المسترد',
            package_type: 'نوع الحزمة'
          },
          customer_info_table: {
            reciever_name: 'اسم العميل',
            phone_number: 'رقم الهاتف',
            customer_address: 'عنوان العميل',
            second_phone_number: 'رقم الهاتف الثانوي',
            building_number: 'رقم المبنى',
            floor_apartment: 'الطابق, الشقة',
            work_address: 'مكان عمل'
          },
          bosta_performance: {
            delievery_attempts: 'محاولات التسليم',
            outbound_calls: 'مكالمات خارجية',
            bosta_promise: 'وعد التسليم',
            return_attempts: 'محاولات العودة',
            pickup_attempts: 'محاولات طلب استلام الشحنة'
          },
          parcels_table: {
            tracking_number: 'رقم التتبع',
            state: 'الدولة'
          }
        },
        table_cells_labels: {
          cod_egp_mount: '{cod} جنيه',
          calls: 'مكالمات',
          delivery_attempts: 'من أصل 3 محاولات',
          return_attempts: 'من بين 2 محاولات',
          promise_in_progress: 'قيد التنفيذ',
          promise_met: 'التقى',
          promise_not_met: 'لم يتم مقابلتها',
          promise_not_started: 'لم تبدأ بعد',
          cash_to_collect: 'تحصيل النقدية',
          refund_cash_to_customer: 'رد المبالغ النقدية للعميل',
          refund: 'رد اموال',
          allowed_to_open_package: 'مسموح',
          not_allowed_to_open_package: 'غير مسموح',
          pickedup_Items_Image_alt:
            '* الصورة ستكون متاحة في وقت طلب استلام الشحنة',
          enlarge_image: 'تكبير'
        },
        table_cells_tooltips: {
          bosta_performace:
            'سيساعدك هذا القسم على تتبع الجهود التي تبذلها بوسطة لتسليم شحنتك خلال الفترة الزمنية الموعودة.',
          delivery_attempts_number:
            'عدد المرات التي حاولت فيها بوسطة تسليم الشحنة إلى عملائك.',
          return_attempts: 'عدد المرات التي حاولت فيها بوسطة إعادة الشحنة إليك',
          outbound_effort:
            'عدد المكالمات التي قام بها الفريق الصادر للتحقق من إجراءات ستار واتخاذ إجراءات تصحيحية إذا لزم الأمر لتسليم الشحنة في الوقت المحدد',
          delivery_promise_tooltips: {
            forward:
              'بوسطة وعود تسليم اليوم التالي (محسوبة من تاريخ طلب استلام الشحنة) للشحنات مع القاهرة كما طلب استلام الشحنة وإسقاط المدينة. تزيد فترة التسليم المتوقعة إلى يومين أو ثلاثة أيام حسب المسافة بين الاستلام والمدن المنسدلة أي الإسكندرية أو الدلتا أو الصعيد',
            cash_collection:
              'بوسطة وعود جمع اليوم التالي (من يوم خلق الطلب). إذا تم إجراء المجموعة بعد اليوم التالي ، لا يتم الوفاء بوسطة وعد .',
            customer_return_pickup:
              'بوسطة وعود إلى 1) طلب استلام الشحنة الشحنة في اليوم التالي من تاريخ إنشاء وإلى 2) إعادة الشحنة في اليوم التالي من طلب استلام. يتم الوفاء بوسطة وعد إذا تم إعادة الشحنة إلى العمل في التاريخ المتوقع.',
            exchange:
              'بوسطة وعود تسليم اليوم التالي (محسوبة من تاريخ طلب استلام الشحنة) للشحنات مع القاهرة كما طلب استلام الشحنة وإسقاط المدينة. تزيد فترة التسليم المتوقعة إلى يومين أو ثلاثة أيام حسب المسافة بين الاستلام والمدن المنسدلة أي الإسكندرية أو الدلتا أو الصعيد',
            return_to_origin:
              'بوسطة وعود تسليم اليوم التالي (محسوبة من تاريخ طلب استلام الشحنة) للشحنات مع القاهرة كما طلب استلام الشحنة وإسقاط المدينة. تزيد فترة التسليم المتوقعة إلى يومين أو ثلاثة أيام حسب المسافة بين الاستلام والمدن المنسدلة أي الإسكندرية أو الدلتا أو الصعيد'
          }
        }
      }
    },
    actions: {
      confirm_delete_message: 'هل أنت متأكد أنك تريد مسح الشحنة {shipmentId}',
      print_airwaybill: 'طباعة بوليصة  ',
      delete: 'مسح',
      ticket: 'التذاكر',
      create_ticket: 'إنشاء طلب دعم',
      related_tickets: 'تذاكر ذات صلة ({relatedTickets})',
      search: 'بحث',
      new_shipment: 'شحنة جديدة',
      print_all: 'طباعة الكل',
      edit_shipment: 'تعديل الشحنة',
      make_default: 'وضع افتراضي'
    }
  },
  support_tickets: {
    header_title: 'الدعم الفني',
    ticket_status: {
      open: 'فتح',
      pending: 'في انتظار المراجعة',
      resolved: 'تم الحل',
      closed: 'مغلق',
      closed_by_customer: 'مغلق بواسطة العميل',
      waiting_customer_reply: 'انتظار رد العميل'
    },
    ticket_category: {
      cod: 'الدفع',
      delivery_issue: 'مشكلة التسليم',
      materials: 'المواد',
      order_modification: 'تعديل الطلب',
      order_inquiry: 'استعلام عن الطلب',
      other: 'أخرى',
      pickup_issue: 'مشكلات الاستلام',
      return_issue: 'مشكلة الإرجاع',
      star_behavior: 'سلوك مندوب الشحن',
      technical_issue: 'مشكلة فنية'
    },
    ticket_subcategory: {
      cod: {
        cod_reports: 'تقارير الدفع',
        bank_details: 'تمت إضافة / تحديث تفاصيل البنك',
        wrong_transfer_amount: 'مبلغ تحويل خاطئ',
        transfer_confirmation: 'تأكيد التحويل',
        wrongly_calculated_fees: 'محسوبة بشكل خاطئ من رسوم النشرات'
      },
      delivery_issue: {
        fake_update: 'تحديث مزيف',
        late_update: 'تحديث متأخر',
        delayed_order: 'طلب مؤجل',
        lost_order: 'ترتيب مفقود',
        damaged_order: 'أمر تالف',
        opened_orders: 'شحنة مفتوحةt',
        mixed_delivery: 'توصيل مختلط'
      },
      materials: {
        delayed_flyer_request: 'طلب المنشور المتأخر',
        wrong_flyers_quantity_recieved: 'تم استلام كمية / حجم منشورات خطأ',
        business_need_flyer: 'العمل بحاجة إلى نشرة إعلانية'
      },
      order_modification: {
        edit_order_data: 'تعديل بيانات الطلب',
        change_cod: 'تغيير الدفع',
        cancel_order: 'إلغاء الطلب'
      },
      other: {
        performance_report: 'تقرير الأداء',
        cant_react_acc_manager: 'لا يمكن أن تصل إلى مدير الحساب',
        needs_onboarding: 'يلزم الإعداد'
      },
      pickup_issues: {
        star_has_no_receipt: 'الطيار ليس لديه إيصال',
        wrong_pickup_num: 'عدد استلام الشحنات خاطئ',
        pickup_delay: 'تأخير الاستلام / عدم ظهور النجمة'
      },
      return_issue: { delayed_return: 'تأخر عودة' },
      star_behavior: {
        star_attitude: 'موقف النجوم',
        star_didnot_follow_instruction: 'النجم لم يتبع التعليمات (ملاحظة)'
      },
      technical_issue: 'مشكلة فنية'
    },
    ticket_list: {
      create_button: 'طلب دعم جديد',
      tickets_count: 'تذاكر ({ticketsCount})',
      labels: {
        need_attention: 'يحتاج إلى ردك',
        ticket_id: 'طلب الدعم'
      },
      no_tickets: {
        title: 'لم تقم بإنشاء أي طلبات دعم حتى الآن',
        subtitle: 'إنشاء طلب دعم لبدء',
        button: 'طلب دعم جديد'
      }
    },
    ticket_info: {
      ticket_id: 'رقم طلب الدعم',
      status_action_message: {
        re_open_ticket: 'هل أنت متأكد أنك تريد إعادة فتح طلب الدعم ؟',
        close_ticket: 'هل أنت متأكد أنك تريد إنهاء طلب الدعم ؟'
      },
      status_action: {
        re_open_ticket: 'إعادة فتح',
        close_ticket: 'إنهاء'
      }
    },
    create_ticket: {
      tooltips: {
        tracking_number:
          'إذا كانت المشكلة تتعلق بأكثر من رقم تتبع واحد ، فيرجى إدخال جميع أرقام التتبع وفصلها بواسطة فاصلة. أنا. إي. 23132123 ، 34234234 ، 23232323.'
      },
      place_holder: {
        tracking_number: 'يمكنك إضافة أكثر من رقم تتبع واحد',
        category: 'حدد المشكلة',
        subCategory: 'حدد نوع المشكلة',
        description: 'أضف وصفًا لمساعدة فريق الدعم في فهم مشكلتك ...'
      },
      labels: {
        create_new_ticket: 'إنشاء طلب دعم جديد',
        tracking_no: 'رقم التتبع',
        subtitle: 'اخبرنا أكثر عن مشكلتك',
        optional: '-اختياري-',
        category: 'لدي مشكلة مع',
        subCategory: 'نوع القضية',
        ticket_subject: 'موضوع طلب الدعم',
        description: 'وصف'
      },
      create_ticket: 'قم بإنشاء طلب دعم',
      notify_messages: {
        ticket_created_successfuly: 'تم إنشاء طلبك بنجاح'
      }
    },
    upload_images: {
      header: 'ارفع ملف',
      title: 'قم بسحب وإسقاط أو تحميل الصور أو أوراق Excel.',
      sub_title: 'يدعم JPG و PNG و XLSX - بحد أقصى (15 ميجابايت)',
      upload: 'رفع'
    },
    ticket_conversation_info: {
      pickup_id: 'رقم الشحنة',
      tracking_number: 'أرقام التتبع',
      description: 'وصف',
      attachemnts: 'المرفقات',
      input_placeholder: 'اكتب رسالتك  ...',
      start_chatting: 'ابدأ المحادثة'
    },
    search_tickets: {
      tickets_title: 'قائمة  طلبات دعم الخاصة بك',
      search_placeholder: 'البحث عن طلب دعم ...'
    },
    filter_tickets: {
      filter: 'منقي',
      status: 'الحالة',
      issue_type: 'نوع القضية'
    },
    tags: {
      filters: 'المرشحات'
    }
  },
  settings: {
    tabs: {
      pickup_locations: 'مواقع استلام الأوردرات',
      business_account: 'حساب العمل',
      api_key: 'مفتاح API',
      general: 'عام',
      payment_method: 'طريقة الدفع',
      account_preferences: 'تفضيلات الحساب'
    },
    account_preferences: {
      title: 'تفضيلات الحساب',
      sub_title: 'إدارة إعدادات حسابك',
      language: 'اللغة',
      english: 'English',
      arabic: 'العربية',
      language_changed_successfully_title: 'تم تغيير اللغة بنجاح',
      language_changed_successfully_content:
        'لقد قمت بتغيير اللغة من English الى العربية.'
    },
    business_account: {
      business_info_form: {
        title: 'معلومات العمل',
        sub_title: 'قم بإعداد معلومات حساب عملك'
      },
      business_location_form: {
        title: 'موقع العمل',
        sub_title: 'هذا هو العنوان الذي تؤدي فيه عملك.'
      },
      success: 'تم تحديث معلومات حسابك بنجاح.',
      save_no_changes: 'قم بتحديث أحد الحقول قبل النقر علي حفظ.',
      reset_no_changes: 'كل شيء جاهز ، لم يتغير شيء.'
    },
    pickup_locations: {
      title: 'مواقع العمل',
      sub_title: 'مواقع طلب استلام عملك',
      actions: {
        add_button: 'إضافة موقع جديد',
        back_button: 'مواقع الاستلام',
        create_button: 'إنشاء موقع',
        edit_button: 'تحديد الوجهة',
        add_person: 'إضافة شخص جديد',
        remove_person: 'إزالة الشخص',
        set_default_contact: 'تعيينه كشخص افتراضي'
      },
      default_location: 'الموقع الأساسي',
      delete_confirmation_msg: 'هل أنت متأكد أنك تريد مسح هذا الاستلام؟',
      table_columns: {
        location: 'اسم الموقع',
        address: 'عنوان',
        contact_person: 'الشخص الذي يمكن الاتصال به',
        action: 'عمل'
      },
      delete_modal: {
        title: 'مسح موقع الاستلام',
        content: 'هل أنت متأكد أنك تريد مسح هذا الموقع؟'
      },
      pickup_location_form: {
        pickup_location_title: 'الموقع',
        update_pickup_location: 'تحديث اماكن الاستلام',
        update_button: 'تحديث المكان',
        pickup_location_sub_title: 'أنشئ موقع استلام جديد',
        contact_person_title: 'جهات الاتصال',
        contact_person_sub_title: 'إضافة شخص الاتصال إلى هذا الموقع',
        set_default_location: 'تعيين كموقع افتراضي',
        add_contact_success: 'تمت إضافة جهات الاتصال الخاصة بك بنجاح',
        add_contact_error:
          'لا يمكنك إضافة جهة الاتصال إلى موقع استلام الأوردرات هذا لأنه يحتوي على عمليات طلب استلام نشطة.',
        add_contact_before_location_error:
          'يجب عليك اختيار موقع طلب استلام الشحنة اولًا',
        edit_pickup_location_error:
          'لا يمكنك تعديل موقع استلام الأوردرات هذا لأنه يحتوي على طلب استلامات نشطة',
        create_pickup_location_success:
          'لقد أضفت موقع استلام الأوردرات الجديد بنجاح',
        delete_pickup_location_success:
          'تم مسح موقع استلام الأوردرات الخاص بك بنجاح',
        update_pickup_location_success: 'تم تحديث مكان الاستلام الجديد بنجاح',
        form_placeholders: {
          location_name: 'جعل المكتب الرئيسي',
          city: 'مثال: الفرع الرئيسي',
          area: 'مثال: الفرع الرئيسي',
          street: 'اكتب اسم الشارع بالكامل',
          building: 'رقم المبني',
          floor: 'رقم الدور',
          apartment: 'رقم الشقة',
          first_name: 'اكتب اسمك الأول',
          last_name: 'اكتب اسمك الأخير',
          phone: 'أضف رقم الهاتف',
          second_phone: 'أضف رقم الهاتف الثاني',
          email: 'إضافة عنوان البريد الإلكتروني',
          landmarks: 'مثال: بالقرب من النساجون الشرقيون'
        }
      }
    },
    payment_method: {
      title: 'طريقة الدفع',
      sub_title: 'إدارة معلومات الدفع الخاصة بك للحصول على إيراداتك.',
      fill_bank_details: {
        title: 'املأ التفاصيل المصرفية الخاصة بك',
        sub_title:
          'سيتم تحويل إيراداتك إلى حسابك المصرفي تلقائيًا وفقًا لجدول الدفع الخاص بك.',
        add_bank_details: 'أضف تفاصيل البنك',
        edit_bank_details: 'تعديل تفاصيل البنك'
      },
      otp_screen: {
        title: 'تأكد من أنك مالك هذا الحساب',
        sub_title:
          'للتأكد من أنك مالك الحساب ، يتم إرسال رمز مكون من 4 أرقام إلى رقم هاتفك المنتهي بـ {phone}. أدخل هذا الرمز أدناه.',
        resend_code: 'أعد إرسال الرمز',
        receive_confirmation: 'سوف تتلقى رمز التأكيد في غضون دقيقتين.',
        error: 'الرمز غير صحيح'
      },
      bank_details_modal: {
        title: 'أدخل تفاصيل البنك الخاص بك',
        bank: 'البنك',
        account_owner_name_title: 'اسم صاحب الحساب',
        account_owner_name_sub_title: 'أدخل الاسم الكامل لصاحب الحساب.',
        account_owner_name_tool_tip:
          'أدخل الاسم الكامل لمالك الحساب أو أدخل اسم الشركة إذا كان هذا البنك مسجلاً لشركة.',
        account_owner_name_error: 'يجب ألا تحتوي {label} على أرقام',
        iban: 'IBAN',
        iban_title: 'IBAN (رقم الحساب المصرفي الدولي)',
        iban_sub_title:
          'أدخل رقم IBAN الخاص بك والمكون من 29 حرفًا. {click} لمزيد من المعلومات.',
        click_here: 'انقر هنا',
        iban_number_error: 'يجب ألا تقل {label} عن 29 حرفًا',
        iban_account_number_error: 'يجب عليك إدخال رقم IBAN أو رقم الحساب.',
        iban_tool_tip:
          'يزيد رقم IBAN من دقة وسرعة التحويلات المصرفية. إذا لم تتمكن من العثور عليه ، فاتصل بالمصرف الذي تتعامل معه لتقديمه لك.',
        account_number_title: 'رقم حساب',
        account_number_sub_title:
          'أدخل رقم حسابك إذا لم تتمكن من العثور على رقم IBAN.',
        account_number_tool_tip:
          'رقم الحساب ليس رقم بطاقة الائتمان أو الخصم الخاصة بك.',

        add_success: 'تم إضافة التفاصيل المصرفية الخاصة بك بنجاح.',
        edit_success: 'تم تعديل التفاصيل المصرفية الخاصة بك بنجاح.',
        place_holder: {
          account_owner_name: 'مثال: محمود محمد ياسر',
          iban: 'مثال: EG800002000156789012345180002',
          account_number: 'مثال: 112345180002'
        }
      }
    }
  },
  pickups: {
    tabs: {
      history_pickups: ' الاستلامات السابقه',
      upcoming_pickups: 'الاستلامات القادمة'
    },

    title: 'طلب استلام الأوردرات',
    pickup_settings: {
      edit_pickup_success: 'تم تحديث الاستلام بنجاح!'
    },
    subtitle: 'قائمة الاستلامات الخاصة بك',
    table_title: 'إجمالي عدد مرات الاستلام ({count})',
    search_input_placeholder: 'البحث عن طريق بيانات الاستلامات',
    table_columns: {
      pickupId: 'معرّف الاستلامات',
      location: 'الموقع',
      scheduled_date: 'التاريخ المقرر',
      type: 'نوع الاستلام',
      status: 'الحالة',
      pickedup_date: 'تاريخ الاستلام',
      PCKGS: 'الشحنات ',
      CardPCKGS: '.الشحنات ',
      action: 'تعديل',
      repeatation_types: {
        daily: 'يومي',
        weekly: 'أسبوعي',
        one_time: 'مرة واحدة'
      }
    },
    table_actions: {
      menu_headline: 'أجراءات',
      create_ticket: 'تواصل مع الدعم',
      delete: 'مسح',
      non_created_delete_message:
        'لا يمكنك مسح طلب استلام الأوردرات عند بدء المعالجة',
      non_created_edit_message:
        'لا يمكنك تعديل طلب استلام الأوردرات لأنه قيد التقدم',
      edit: 'تعديل',
      deleted: 'تم مسح طلب استلام الأوردرات بنجاح',
      confirm_delete_message:
        'هل أنت متأكد أنك تريد مسح طلب استلام الأوردرات هذا؟',
      ok_confirmation_text: 'نعم',
      cancel_confirmation_text: 'إلغاء',
      non_confirmation_edit_message:
        'الاستلام قيد التقدم بالفعل ، وبالتالي لا يمكن تعديله',
      created_ticket_message: 'تم إنشاء طلب الدعم بنجاح.',
      error_delete_completed_pickup: 'لا يمكن مسح انتقاء مكتمل.',
      error_delete_canceled_pickup_content:
        'لا يمكن مسح الاستلام لأنه تم إلغاؤه بالفعل.'
    },
    filter: {
      pickup_status: 'حالة الاستلام',
      pickup_locations: 'مواقع الالتقاط'
    },
    pickup_info: {
      title: 'معلومات استلام الأوردرات',
      repetition_title: 'معلومات التكرار',
      subtitle: 'عرض جميع البيانات حول طلب استلام الشحنة',
      delete_confirmation_msg: 'هل أنت متأكد أنك تريد مسح هذا الاستلام؟',
      search_input_placeholder: 'البحث برقم التتبع',
      table_cells: {
        puid: 'معرّف الاستلام',
        location: 'الموقع',
        date: 'اختر تاريخا',
        packages: 'العبوات المختارة',
        notes: 'ملاحظات',
        star_name: 'اسم النجمة',
        contact_person: 'الشخص الذي يمكن الاتصال به',
        signature: 'التوقيع',
        scheduled_date: 'التاريخ المقرر',
        pickups_status: {
          created: 'تم إنشاوه',
          in_progress: 'في تقدم',
          completed: 'تم استلام الطلب',
          canceled: 'تم إلغاؤه'
        },
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        repetition_days: 'أيام التكرار',
        repeated_type: 'نوع التكرار',
        next_pickup_date: 'تاريخ الاستلام التالي',
        package_type: 'نوع الالتقاط'
      },
      table_columns: {
        tracking_number: 'أرقام التتبع',
        type: 'اكتب',
        customer_info: 'معلومات العميل',
        dropoff_location: 'موقع تسليم الشحنه',
        cod: 'الدفع'
      }
    },
    create_edit_pickup: {
      pickup_create_title: 'إنشاء طلب استلام',
      pickup_edit_title: 'تعديل طلب استلام',
      pickup_sub_title: 'قم بإعداد معلومات الاستلام',
      create_pickup_success: 'تم إنشاء طلب الاستلام بنجاح',
      contact_title: 'الأشخاص الاتصال',
      contact_sub_title: 'إضافة شخص الاتصال إلى هذا الموقع',
      error_empty_pickup_location: 'الرجاء تحديد موقع الاستلام أولاً',
      edit_pickup_error:
        'لا يمكنك إنشاء بيك آب بتاريخ انتهاء أقدم من تاريخ البدء أو تاريخ الاستلام!',
      delete_default_pickup_error:
        'لا يمكن مسح موقع استلام الأوردرات باعتباره الموقع الأساسي. قم بتعديل موقع آخر كموقع افتراضي وحاول مرة أخرى',
      delete_pickup_error:
        'لا يمكن مسح موقع استلام الأوردرات المحدد لأنه مرتبط بطلبات الاستلام قيد المعالجة. يرجى المحاولة مرة أخرى بعد اكتمال طلبات الاستلام.',
      edit_pickup_success: 'تم تحديث الاستلام بنجاح!',
      error_delete_default_contact: 'لا يمكنك مسح جهة الاتصال الأساسية',
      error_create_new_zoning:
        'لايمكن انشاء طلب استلام لان بوسطه تستخدم تقسيم عنواين جديد، يرجى تحديث موقع الاستلام أولاً.',
      form_labels: {
        pickup_location: 'الموقع',
        pickup_date: 'اختر تاريخا',
        pickup_repeat: 'هل تريد تكرار الاستلام؟',
        notes: 'ملاحظات',
        package_type: 'لدي عنصر ضخم وأحتاج إلى شاحنة لاستلامه.',
        bulky_title: 'طلب التقاط ضخم',
        bulky_sub_title: 'تأكد من طلب شاحنة إذا كان لديك عنصر ضخم.'
      },
      form_placeholders: {
        select_location: 'الموقع',
        select_date: 'حدد تاريخ',
        name: 'اكتب اسمك الكامل',
        first_name: 'اسمك الأول',
        last_name: 'اسمك الثاني',
        phone: 'أضف رقم الهاتف',
        second_phone: 'أضف رقم الهاتف الثاني',
        email: 'إضافة عنوان البريد الإلكتروني',
        notes: 'اكتب ملاحظاتك هنا'
      },
      form_labels_tooltips: {
        pickup_location:
          'حدد موقع استلام الأوردرات الذي تريد أن يصل إليه النجم لاستلام عمليات التسليم.',
        select_date: 'حدد اليوم الذي يجب أن يأتي فيه النجم لاستلام الشحنات',
        select_contact:
          'حدد موظفك المسؤول عن مقابلة النجم وتسليم عمليات التسليم.'
      }
    },
    delete_pickup_modal: {
      title: 'مسح طلب الاستلام',
      delete_current_pickup: 'امسح هذا الاستلام فقط',
      delete_future_pickup: 'امسح هذا الاستلام وكل ما في المستقبل',
      delete_parent_pickup:
        'هل تريد مسح هذا الاستلام وكل عملية طلب استلام أخرى في المستقبل؟',
      delete_content:
        'لا يمكن مسح الاستلام لأنه قيد التقدم بالفعل. هل تريد مسح طلب استلامات في المستقبل؟',
      delete_pickedup_content:
        'لا يمكن مسح الاستلام لأنه تم طلب استلامه بالفعل. هل تريد مسح طلب استلامات في المستقبل؟',
      deleted_pickup_success: 'تم مسح الاستلام بنجاح',
      form_type_required: 'اختر واحدة من فضلك!'
    },
    repeated_modal: {
      title: 'إنشاء طلب استلام متكرر',
      sub_title: 'سيتم تكرار هذا الاستلام',
      every_day: 'كل يوم',
      every_week: 'كل اسبوع',
      form_labels: {
        start_date: 'تاريخ البدء',
        reptead_type: 'كرر',
        end_date: 'يغلق عند مستوى'
      },
      days: {
        saturday: 'السبت',
        sunday: 'الحد',
        monday: 'الأثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس'
      },
      form_placeholders: {
        date_placeholder: 'حدد تاريخ',
        reptead_type: 'تحديد'
      }
    },
    actions: {
      create: 'إنشاء طلب استلام',
      create_ticket: 'إنشاء طلب دعم',
      back_button: 'طلبات الاستلام',
      stop: 'توقف عن الاستلام المتكرر',
      create_recurring: 'إنشاء التكرار',
      add_location: 'إضافة موقع جديد',
      edit_repeated: 'تكرار',
      add_person: 'إضافة شخص جديد',
      create_button: 'إنشاء طلب استلام',
      edit_button: 'تحديد المكان',
      set_default: ' تحديد كأساسي',
      edit_recurring: 'تعديل التكرار',
      create_repeated: 'تفاصيل  تكرار الاستلام '
    }
  },
  br_searchable_table: {
    filter_by_date: 'تاريخ',
    empty_view: {
      title: 'لا توجد بيانات لعرضها',
      no_search_title: 'لم يتم العثور على نتائج عن البحث'
    }
  },
  br_filter: {
    title: 'منقي',
    reset_button: ' تعديل'
  },
  on_boarding: {
    free: 'مجانا',
    welcome_dialog: {
      title: 'مرحبا بكم في بوسطه!',
      sub_title: 'إليك دليل سريع لمساعدتك على تعلم كيفية شحن طلباتك.'
    },

    order_pickup_dialog: {
      title_order: 'على بعد خطوة واحدة فقط لتسليم طلبك الأول.',
      title_pickup: 'الان انشاء طلب التقاط',
      order_type: 'حدد نوع الاوردر',
      create_order: 'انشاء أوردر ',

      order_type_caption:
        'اختر من بين كل نوع من الأنواع الأربعة: التسليم أو الاستبدال أو الإرجاع أو التحصيل النقدي.',
      customer_details: 'أضف تفاصيل العميل',
      customer_details_caption: 'أضف هاتف العميل والعنوان وملاحظات التسليم.',
      create_pickup: 'طلب التقاط',
      create_pickup_caption:
        'اختر اليوم المناسب ، أضف عنوانك ، وسنقوم باستلام طلباتك.'
    },
    print_airwaybill: {
      title: 'تم إنشاء طلبك بنجاح!',
      sub_title:
        'كل ما تحتاجه الآن هو طباعة بوليصة الشحن  {tooltips} وإرفاقها مع طلبك.',
      print: 'اطبع بوليصه الشحن الان',
      skip: 'تخطي ، سأطبعها لاحقًا',
      tooltips:
        'بدون طباعة بوليصه الشحن ، لا يمكن استلام الطلبات. يرجى التأكد من طباعته قبل تاريخ الاستلام.',
      skip_warning:
        'بوليصة الشحن  هي إيصال للسلع المشحونة بعنوان المستلم. يجب أن تكون مع الطلبات المشحونة للسماح بالتتبع.',
      skip_button_later: 'اطبع لاحقًا',
      skip_button_now: 'اطبع الآن'
    },

    bosta_guide: {
      non_cash_collection: {
        title: 'انت انتهيت! ماذا الآن؟',
        content:
          'ستتلقى أيضًا نشراتنا الإعلانية {free} (مواد التعبئة في بوسطه) لتعبئة طلباتك.',
        footer: 'الآن ، تحقق من دليل بوسطة لإكمال التسجيل الخاص بك. ',
        guide_button: 'مشاهدة دليل بوسطة'
      },
      cash_collection_crp: {
        sub_title: 'نظرًا لأن نوع طلبك هو {type} ، فلا داعي لطلب الاستلام',
        content: {
          free_flyer:
            ' سوف تتلقى فلايرز  {free} لـ بوسطه (مواد التعبئة) لتعبئة طلباتك المستقبلية.',
          track: 'يمكنك أيضًا تتبع طلباتك من صفحة الطلبات.'
        },
        type: {
          25: 'لاقط عودة العملاء',
          15: 'استلام النقود'
        }
      }
    }
  },
  br_google_map: {
    title: 'تعيين الموقع على الخريطة',
    subtitle: 'سيساعدك هذا في إدخال موقع دقيق',
    move_map_note:
      'حرّك الخريطة أو اسحب الدبوس وأسقطه للعثور على موقع الالتقاط الدقيق.',
    search_placeholder: 'ابحث عن المدينة ، المنطقة ، اسم الشارع ، المعلم ..',
    location_access_error:
      'تحتاج إلى السماح  بمعرفة موقعخدمة الموقع معطلة. يرجى تمكين خدمات الموقع في إعدادات المستعرض الخاص بك لتمكين هذه الميزة.',
    actions: {
      set_location: 'تعيين الموقع',
      add_location: 'تعيين الموقع',
      confirm_location: 'تأكيد الموقع',
      position_error_message:
        'لا يمكننا تحديد هذا الموقع ، يرجى تحديد موقع آخر.',
      uncovered_zone_message: 'عذرًا ، لم نتمكن من الاستلام من هذا الموقع'
    }
  }
};
