import { ReactComponent as ProductIcon } from 'assets/bosta-icons/Product.svg';

import './ProductImage.less';

const ProductImage = ({ src }) => {
  return (
    <div className='br-product-image-container'>
      {src ? (
        <img src={src} alt="" className="br-product-image" />
      ) : (
        <div className="br-product-empty-image">
          <ProductIcon />
        </div>
      )}
    </div>
  );
};

export default ProductImage;
