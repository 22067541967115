import { Timeline } from 'antd';
import { useIntl } from 'react-intl';
import compact from 'lodash/compact';

import { checkSmartDate } from 'utils/helpers';
import {
  INTERNATIONAL_DELIVERY_DATE_FORMAT,
  INTERNATIONAL_DELIVERY_EVENTS_MAPPING,
  INTERNATIONAL_DELIVERY_EVENT_DATA_LABEL
} from 'constants/international-orders';

import './DeliveryLogs.less';

const DeliveryLogs = ({ deliveryEvents = [], deliveryDetails }) => {
  const intl = useIntl();

  const getLogs = () => {
    const logs = deliveryEvents.map((event) => {
      const deliveryEventExtraData = INTERNATIONAL_DELIVERY_EVENT_DATA_LABEL({
        eventData: event.deliveryEvents?.data,
        deliveryDetails
      })[event.deliveryEvents.eventName];

      if (
        INTERNATIONAL_DELIVERY_EVENTS_MAPPING[event.deliveryEvents?.eventName]
      ) {
        return {
          title:
            INTERNATIONAL_DELIVERY_EVENTS_MAPPING[
              event.deliveryEvents.eventName
            ],
          warehouseName: event.deliveryEvents?.actionBy?.warehouseName,
          actionBy: event.deliveryEvents?.actionBy?.userName,
          eventExtraData: deliveryEventExtraData,
          date: checkSmartDate(
            event.deliveryEvents.createdAt,
            INTERNATIONAL_DELIVERY_DATE_FORMAT,
            true
          )
        };
      }
    });

    return compact(logs);
  };

  return (
    <Timeline className="br-international-delivery-logs__timeline">
      {getLogs().map(
        ({ title, date, actionBy, warehouseName, eventExtraData }, index) => (
          <Timeline.Item color="gray" key={index}>
            <div className="br-international-delivery__column-subtext subheading">
              {date}
            </div>
            <div>
              {title} {intl.formatMessage({ id: 'common.by' })}{' '}
              <span className="body-medium">
                {actionBy} {warehouseName && `- ${warehouseName}`}
              </span>
            </div>
            {eventExtraData && (
              <div className="br-international-delivery__event-extra-data">
                {eventExtraData}
              </div>
            )}
          </Timeline.Item>
        )
      )}
    </Timeline>
  );
};

export default DeliveryLogs;
