import http from 'utils/http.js';

export const getFlyersDailyBalance = (payload) => {
  return http.get(`/flyers/totalBalance`, payload);
};

export const getOrdersBalance = (payload) => {
  return http.post(`/flyers/search`, payload);
};

export const exportFlyersOrders = (payload) => {
  return http.post(`/flyers/export`, payload);
};

export const getPackagingMaterials = ({
  pageNumber = 0,
  pageSize = 100,
  payload
}) => {
  return http.post(
    `flyers/list?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    payload
  );
};

export const editMaterial = ({ id, payload }) => {
  return http.put(`/flyers/${id}`, payload);
};

export const getMaterialLogs = ({ pageSize, pageNumber, payload }) => {
  return http.post(
    `/flyers/items/logs?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    payload
  );
};

export const bulkEditMaterials = (payload) => {
  return http.post(`/flyers/items/bulkUpdate`, payload);
};

export const getFlyersDraftOrder = ({ page, limit, payload }) => {
  return http.post(
    `/flyers/requests/list?pageSize=${limit}&pageNumber=${page}`,
    payload
  );
};

export const getFlyersItemDetails = (id) => {
  return http.get(`/flyers/requests/${id}/details`);
};

export const confirmFlyersSalesOrder = (payload) => {
  return http.post(`/flyers/requests/confirm`, payload);
};

export const cancelFlyersSalesOrder = (payload) => {
  return http.post(`/flyers/requests/cancel`, payload);
};

export const exportMaterials = (payload) => {
  return http.get(`/flyers/items/export`, payload);
};

export const getFlyersList = (payload) => {
  return http.post(`/flyers/list`, payload);
};

export const exportPackages = (payload) => {
  return http.post(`flyers/requests/export`, payload);
};

export const exportShippingOrders = (payload) => {
  return http.post(`flyers/deliveries/export`, payload);
};

export const getFlyersOrdersCount = (payload) => {
  return http.post('/flyers/deliveries/count', payload);
};
