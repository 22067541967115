import { BASE } from 'apis';

export const getUsersByRole = async ({ role }) => {
  const { data } = await BASE.get(`/users/users-by-role/${role}`);
  return data.users;
};

export const getAccountManagers = async () => {
  const { data } = await BASE.get(`/users/sales-users`);
  return data.salesUsers;
};
