import { fmt } from 'components/IntlWrapper/IntlWrapper';

import {
  AREA_MANAGER,
  EXPERIENCE,
  OPERATIONS_MANAGER,
  OPERATOR,
  SUPER_ADMIN
} from 'common/aclMatrix';

export const DISPATCHING_CARDS = ({ dispatchingData, pageType }) => {
  const { toBe, action, done, late, missed } = dispatchingData;

  return [
    {
      title: fmt({
        id: `monitor_hub_operations.${pageType}.cards_title.to_be`
      }),
      count: toBe
    },
    {
      title: fmt({
        id: `monitor_hub_operations.${pageType}.cards_title.action`
      }),
      count: action
    },
    {
      title: fmt({ id: `monitor_hub_operations.${pageType}.cards_title.done` }),
      count: done
    },
    {
      title: fmt({ id: `monitor_hub_operations.${pageType}.cards_title.late` }),
      count: late,
      cardColor: 'border-yellow'
    },
    {
      title: fmt({
        id: `monitor_hub_operations.${pageType}.cards_title.missed`
      }),
      count: missed,
      cardColor: 'border-red'
    }
  ];
};

export const DISPATCHING_ALL_HUBS_ACCESS = [
  SUPER_ADMIN,
  AREA_MANAGER,
  OPERATIONS_MANAGER,
  OPERATOR,
  EXPERIENCE
];

export const DISPATCHING_TABLE_QUICK_FILTERS = ({
  dispatchingData,
  pageType
}) => {
  const { delayed, rescheduled, dueToday, returns } = dispatchingData;

  return [
    {
      label: fmt(
        { id: 'monitor_hub_operations.common.quick_filters.all' },
        { count: delayed + rescheduled + dueToday + returns }
      ),
      value: 'ALL'
    },
    {
      label: fmt(
        { id: 'monitor_hub_operations.common.quick_filters.delayed' },
        { count: delayed }
      ),
      value: 'DELAYED'
    },
    ...(pageType === PAGES_TYPE.DISPATCHING
      ? [
          {
            label: fmt(
              { id: 'monitor_hub_operations.common.quick_filters.rescheduled' },
              { count: rescheduled }
            ),
            value: 'RESCHEDULED'
          }
        ]
      : []),
    {
      label: fmt(
        { id: 'monitor_hub_operations.common.quick_filters.due_today' },
        { count: dueToday }
      ),
      value: 'DUE_TODAY'
    }
    // {
    //   label: fmt({ id: 'dispatching.quick_filters.rto' }, { count: returns }),
    //   value: 'RETURN'
    // }
  ];
};

export const DISPATCHING_ISSUES = {
  ZERO_ATTEMPTS_DELAY: fmt({
    id: 'monitor_hub_operations.common.issues.zero_attempts'
  }),
  ABOUT_TO_EXCEED_E2E_SLA: fmt({
    id: 'monitor_hub_operations.common.issues.exceeded_sla'
  }),
  ALLOWED_INTRANSIT_EXCEEDED: fmt({
    id: 'monitor_hub_operations.common.issues.moving_between_hubs'
  }),
  RESCHEDULING_DATE_MISSED: fmt({
    id: 'monitor_hub_operations.common.issues.reschedule_date_missed'
  })
};

export const DISPATCHING_STATES = {
  DELAYED: 'DELAYED',
  RESCHEDULED: 'RESCHEDULED',
  DUE_TODAY: 'DUE_TODAY',
  RETURN: 'RETURN'
};

export const DISPATCHING_OVERVIEW_CARDS = ({ overviewData, pageType }) => {
  const { action, done, late, missed } = overviewData;
  return [
    {
      title: fmt({
        id: `monitor_hub_operations.${pageType}.cards_title.action`
      }),
      count: action,
      borderColor: 'border-yellow'
    },
    {
      title: fmt({ id: `monitor_hub_operations.${pageType}.cards_title.done` }),
      count: done,
      borderColor: 'border-blue'
    },
    {
      title: fmt({ id: `monitor_hub_operations.${pageType}.cards_title.late` }),
      count: late,
      borderColor: 'border-dark-yellow',
      countColor: 'text-dark-yellow'
    },
    {
      title: fmt({
        id: `monitor_hub_operations.${pageType}.cards_title.missed`
      }),
      count: missed,
      borderColor: 'border-red',
      countColor: 'text-red'
    }
  ];
};

export const INITIAL_OVERVIEW_SUMMARY = {
  action: 0,
  done: 0,
  late: 0,
  missed: 0,
  toBe: 0
};

export const OVERVIEW_SUMMARY_OBJ_MAPPING = {
  dispatching: {
    action: 'addedToRoute',
    done: 'dispatched',
    late: 'lateDispatch',
    missed: 'missedDispatch',
    toBe: 'toBeDispatched'
  },
  transferring: {
    action: 'readyForTransfer',
    done: 'transferred',
    late: 'lateTransfer',
    missed: 'missedTransfer',
    toBe: 'toBeTransferred'
  }
};

export const PAGES_TYPE = {
  DISPATCHING: 'dispatching',
  TRANSFERRING: 'transferring'
};

export const ATTEMPTS_STATE = {
  1: fmt({
    id: 'monitor_hub_operations.ofd_search.attempts_states.in_progress'
  }),
  2: fmt({
    id: 'monitor_hub_operations.ofd_search.attempts_states.failed'
  }),
  3: fmt({
    id: 'monitor_hub_operations.ofd_search.attempts_states.succeeded'
  })
};

export const ATTEMPTS_STATE_COLORS = {
  1: 'br-state-blue-group',
  2: 'br-state-red-group',
  3: 'br-state-green-group'
};

export const OFD_ATTEMPT_STATES = [
  { value: 1, label: ATTEMPTS_STATE[1] },
  { value: 2, label: ATTEMPTS_STATE[2] },
  { value: 3, label: ATTEMPTS_STATE[3] }
];

export const OFD_ATTEMPT_TYPES = [
  {
    value: 'forward',
    label: fmt({
      id: 'monitor_hub_operations.ofd_search.attempts_type.forward'
    })
  },
  {
    value: 'return',
    label: fmt({
      id: 'monitor_hub_operations.ofd_search.attempts_type.return'
    })
  }
];

export const OFD_TABLE_LIMIT = 30;

export const OFD_SEARCH_DATE_FORMAT = 'ddd, DD MMM YYYY';
export const OFD_SEARCH_TIME_FORMAT = 'h:mm A';

export const OFD_FALSE_KEYS_TO_REMOVE = [
  'internationalOrders',
  'isWhatsAppFake'
];
