import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import classnames from 'classnames';

import { PASSWORD_REGEX, PASSWORD_VALIDATION_RULES } from 'constants/Security';
import { passwordRule, requiredRule } from 'utils/forms';

import { ReactComponent as Check } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as Clear } from 'assets/bosta-icons/Circle-clear.svg';

import './BRPasswordInput.less';

const BRPasswordInput = ({
  intl,
  value,
  setValue,
  label,
  placeholder,
  name
}) => {
  const [focused, setFocused] = useState(false);

  const handlePasswordChange = async ({ target: { value } }) => {
    setValue(value);
  };

  const handleOnFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    PASSWORD_REGEX.test(value) && setFocused(false);
  };

  return (
    <Form.Item
      name={name}
      rules={[
        requiredRule(
          intl.formatMessage(
            {
              id: 'form.required_custom'
            },
            {
              label
            }
          )
        ),
        passwordRule()
      ]}
      label={label}
      className={classnames('br-password-input')}
      help={
        !PASSWORD_REGEX.test(value) && focused ? (
          <>
            {PASSWORD_VALIDATION_RULES.map((rule) => (
              <div
                className={classnames(
                  'caption',
                  'br-password-input__validation',
                  {
                    'br-password-input__validation--is-valid':
                      rule.regex.test(value)
                  },
                  {
                    'br-password-input__validation--is-not-valid':
                      !rule.regex.test(value)
                  }
                )}
              >
                {!rule.regex.test(value) ? <Clear /> : <Check />}
                {rule.label}
              </div>
            ))}
          </>
        ) : PASSWORD_REGEX.test(value) && focused ? (
          <div className="br-password-input__validation-success caption">
            <Check />
            {intl.formatMessage({
              id: 'change_password_form.validation_messages.validation_success'
            })}
          </div>
        ) : undefined
      }
    >
      <Input.Password
        onChange={handlePasswordChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        value={value}
      />
    </Form.Item>
  );
};

export default injectIntl(BRPasswordInput);
