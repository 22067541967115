import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { DISPATCHING_OVERVIEW_CARDS } from 'constants/dispatching';

import { ReactComponent as LocationIcon } from 'assets/bosta-icons/Location.svg';

import './HubSummaryCard.less';

const HubSummaryCard = ({
  hubSummaryData,
  setSelectedHubId,
  intl,
  pageType
}) => {
  return (
    <div
      className="br-dispatching-hub-summary-card__container"
      onClick={() => setSelectedHubId(hubSummaryData._id)}
    >
      <div className="br-dispatching-hub-summary-card__hub-name heading display-flex align-center">
        <LocationIcon />
        {hubSummaryData.name}
      </div>
      <div className="br-dispatching-hub-summary-card__info">
        <div className="br-dispatching-hub-summary__to-be-dispatched">
          <div className="display-xs">{hubSummaryData.toBe}</div>
          <div className="br-dispatching-hub-summary__to-be-dispatched-title subheading">
            {intl.formatMessage({
              id: `monitor_hub_operations.${pageType}.cards_title.to_be`
            })}
          </div>
        </div>
        <div className="br-dispatching-hub-summary__cards display-flex">
          {DISPATCHING_OVERVIEW_CARDS({
            overviewData: hubSummaryData,
            pageType
          }).map((hubSummary, index) => (
            <div
              className={classnames('br-dispatching-hub-summary__card', [
                hubSummary.borderColor
              ])}
              key={index}
            >
              <div className="body-medium">{hubSummary.count}</div>
              <div className="br-dispatching-hub-summary__card-title">
                {hubSummary.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(HubSummaryCard);
