import React, { useState } from 'react';
import { InputNumber } from 'antd';

import { ReactComponent as FactorRemoveIcon } from 'assets/imgRevamp/score-settings-remove-icon.svg';

const PointsContainer = ({
  title,
  value,
  onInputChange,
  onRemove,
  defaultValue,
  maxNumberDefaultValue = null
}) => {
  const [disableInput, setDisableInput] = useState(
    value === 'fake_exceptions' && !maxNumberDefaultValue ? true : false
  );

  const handleFakeExceptionInput = (inputValue) => {
    if (inputValue) {
      setDisableInput(false);
    } else {
      setDisableInput(true);
    }
    onInputChange(inputValue, value, true);
  };

  return (
    <div className="br-points-content-container">
      <div className="br-points__title-conatiner">{title || 'App Usage'}</div>
      <div className="br-points-action-conatiner">
        {value === 'fake_exceptions' && (
          <div className="br-points-action__maximum-number-conatiner">
            <InputNumber
              onChange={handleFakeExceptionInput}
              min={1}
              max={100}
              defaultValue={maxNumberDefaultValue}
              formatter={(value) => Math.floor(value)}
            />
            <span>Maximum no. of fake exceptions per cycle.</span>
          </div>
        )}
        <div className="br-points-action__points-conatiner">
          <InputNumber
            onChange={(inputValue) => onInputChange(inputValue, value)}
            min={1}
            max={100}
            disabled={disableInput}
            defaultValue={defaultValue}
            formatter={(value) => Math.floor(value)}
          />
          <span>Points</span>
        </div>
        <div
          className="br-points-action__remove-conatiner"
          onClick={() => onRemove(value)}
        >
          <FactorRemoveIcon />
          <span className="br-points-action__remove-text">Remove</span>
        </div>
      </div>
    </div>
  );
};
export default PointsContainer;
