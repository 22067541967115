import DatePicker from 'components/OpsControl/FilterComponents/DatePicker';

import './SortingFacilityFilter.less';
const SortingFacilityFilter = ({ selectedDate, setSelectedDate, loading }) => {
  return (
    <div className="br-sorting-facility-filter__container">
      <DatePicker
        loading={loading}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};
export default SortingFacilityFilter;
