import { Modal, Table } from 'antd';

import { AMAZON_TIPS_ERRORS_TABLE_COLUMNS } from 'constants/cod-reports';

import './AmazonTipsErrorsModal.less';

const AmazonTipsErrorsModal = ({ close, errors, formatMessage, ...props }) => {
  return (
    <Modal
      {...props}
      className="br-amzaon-tips__errors-table"
      width={null}
      onOk={close}
    >
      <Table
        dataSource={errors}
        columns={AMAZON_TIPS_ERRORS_TABLE_COLUMNS}
        pagination={false}
      />
    </Modal>
  );
};

export default AmazonTipsErrorsModal;
