import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  Table,
  Tabs,
  Input,
  Form as AntdForm,
  DatePicker,
  Button as AntdButton
} from 'antd';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { get as getValue } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';

import { moneyFormatter } from 'utils';
import { FORMAT_DATE_TO_UTC, excelSerialDateToDate } from 'utils/helpers';
import Container from 'components/Container';
import {
  getARReports,
  getARReportCSV,
  downloadArReportDetailsCSV,
  getSummaryReports,
  getSummaryReportCSV
} from '../../actions';
import {
  uploadAmazonTransaction,
  getAmazonRemittance,
  uploadAmazonTips
} from 'services/cod-reports';
import {
  AMAZON_TIPS_DATE_FIELDS,
  AMAZON_TIPS_HEADER_MAPPING,
  AMAZON_TIPS_SHEET_URL,
  TABS_TYPES
} from 'constants/cod-reports';
import { useCancellablePromise } from 'utils/promise';
import { convertXlsxToJSON } from 'utils/upload';
import { validateAmazonTipSheet } from 'utils/cod-reports';
import { openModal } from 'utils/modal';
import { FILE_SHEET_UPLOAD_ERROR_TYPES } from 'constants/upload';
import { DATE_FORMAT } from 'constants/date-picker';

import UploadCOD from './uploadCOD';
import Calendar from '../../components/Calendar';
import styles from './styles.module.scss';
import { notify } from 'components/Notify/Notify';
// import 'antd/dist/antd.css';
import UploadTransactions from './UploadTransactions/UploadTransactions';
import { cleanEmptyString } from 'utils/helpers';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BRHeader from 'components/BRHeader/BRHeader';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import BRButton from 'components/BRButton/BRButton';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import AmazonTipsErrorsModal from './UploadTransactions/components/AmazonTipsErrorsModal/AmazonTipsErrorsModal';

import { ReactComponent as UploadIcon } from 'assets/bosta-icons/Upload.svg';
import { ReactComponent as DownloadIcon } from 'assets/bosta-icons/Download.svg';

import './index.less';

const codTransactionsDialyTableColumns = [
  { dataIndex: 'id', title: 'Report Number' },
  { dataIndex: 'creation_date', title: 'Creation Date' },
  { dataIndex: 'businesses', title: 'Business' },
  { dataIndex: 'net_cod', title: 'Net COD' },
  { dataIndex: 'payment_date', title: 'Payment Date' },
  { dataIndex: 'paid', title: 'Payment Status' },
  { dataIndex: 'payment_type', title: 'Payment Type' },
  { dataIndex: 'actions', title: 'Actions' }
];

const codTransactionsSummaryTableColumns = [
  { dataIndex: 'id', title: 'Report Number' },
  { dataIndex: 'creation_date', title: 'Creation Date' },
  { dataIndex: 'businesses', title: 'No. Business' },
  { dataIndex: 'num_deliveries', title: 'No. Deliveries' },
  { dataIndex: 'gross_cod', title: 'Total Gross' },
  { dataIndex: 'net_cod', title: 'Total Net' },
  { dataIndex: 'payment_date', title: 'Payment Date' },
  { dataIndex: 'paid', title: 'Payment Status' },
  { dataIndex: 'actions', title: 'Actions' }
];

const paymentStatusMap = {
  1: 'AWAITING_GENERATION',
  2: 'AWAITING PAYMENT',
  3: 'PAID'
};

const paymentTypeMap = {
  4: 'CHEQUE',
  5: 'BANK_TRANSFER',
  6: 'CASH'
};

const amazonTableColumns = [
  {
    title: 'ID',
    dataIndex: '_id',
    render: (id) => {
      return (
        <Link
          to={`/cod-reports/${id}/amazon-details`}
          style={{
            textDecoration: 'underline'
          }}
        >
          {id}
        </Link>
      );
    }
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (createdAt) => {
      return createdAt && dayjs(createdAt).tz().format('D MMM YYYY');
    }
  },
  {
    title: 'Deposit date',
    dataIndex: 'depositDate',
    render: (depositDate) => {
      return depositDate && dayjs(depositDate).tz().format('D MMM YYYY');
    }
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    width: 50
  },
  {
    title: 'Transfer ID',
    dataIndex: 'transferId'
  },
  {
    title: 'No of. deliveries',
    dataIndex: 'deliveries',
    render: (deliveries) => {
      return deliveries?.length || 0;
    }
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmt'
  }
];

const CodTransactions = ({
  getARReports,
  arReports,
  getARReportCSV,
  downloadArReportDetailsCSV,
  getSummaryReports,
  summaryReports,
  getSummaryReportCSV,
  history,
  loading
}) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [currentSearch, setCurrentSearch] = useState('');
  const [reportId, setReportId] = useState('');
  const [reportType, setReportType] = useState('reimbursement');
  const [amazonData, setAmazonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateValues, setDateValues] = useState({});
  const [amazonSearchValues, setAmazonSearchValues] = useState({});
  const [amazonTipsErrors, setAmazonTipsErrors] = useState({});

  const refreshAmazonTable = useRef('');
  const { formatMessage } = useIntl();
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    getARReports({ page, currentSearch });
  }, [getARReports]);

  useEffect(() => {
    getSummaryReports({ page, currentSearch });
  }, [getSummaryReports]);

  const handleTableColumns = () => {
    if (reportType === 'dialy') {
      return codTransactionsDialyTableColumns;
    }
    return codTransactionsSummaryTableColumns;
  };

  const formatBusinessOptions = (keyFieldName, labelFieldName, data) => {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');
      return { value: item[keyFieldName], label: labelValue };
    });
  };

  const handleSearchSubmit = (values) => {
    let searchString = '';
    if (values.isPaid) {
      searchString += `&paymentStatus=${values.isPaid}`;
    }
    if (values.paymentType) {
      searchString += `&paymentType=${values.paymentType}`;
    }
    if (values.startDate) {
      searchString += `&createdDateFrom=${dayjs
        .utc(values.startDate.format('YYYY-MM-DD'))
        .format()}`;
    }
    if (values.endDate) {
      searchString += `&createdDateTo=${dayjs
        .utc(values.endDate.format('YYYY-MM-DD'))
        .format()}`;
    }
    if (values.paymentStartDate) {
      searchString += `&paymentDateFrom=${dayjs
        .utc(values.paymentStartDate.format('YYYY-MM-DD'))
        .format()}`;
    }
    if (values.paymentEndDate) {
      searchString += `&paymentDateTo=${dayjs
        .utc(values.paymentEndDate.format('YYYY-MM-DD'))
        .format()}`;
    }
    if (values.reportNumbers) {
      const reportNumbers = values.reportNumbers.replace(/ /g, '');
      searchString += `&reportNumbers=${reportNumbers}`;
    }
    if (values.businessIds.length > 0) {
      searchString += '&businesses=';
      values.businessIds.forEach((item) => {
        searchString += `${item.value},`;
      });
      searchString = searchString.slice(0, -1);
    }
    setCurrentSearch(searchString);
    if (reportType === 'dialy')
      getARReports({ page, currentSearch: searchString });
    else getSummaryReports({ page, currentSearch: searchString });
  };

  const formatArReports = (data) => {
    return data.map((itm) => ({
      key: itm._id,
      id: itm.reportNumber ? (
        <Link
          to={`/cod-reports/${itm.reportNumber}/details`}
          style={{
            textDecoration: 'underline'
          }}
        >
          {itm.reportNumber || 'N/A'}
        </Link>
      ) : (
        'N/A'
      ),
      creation_date: dayjs(new Date(itm.createdAt)).tz().format('LLL'),
      businesses: itm.businessName || itm.noOfBusiness,
      payment_date: itm.paidAt
        ? dayjs(new Date(itm.paidAt)).tz().format('LLL')
        : 'N/A',
      num_deliveries: itm?.noOfDeliveries,
      net_cod: moneyFormatter(itm.totalNetAmount || itm.netCod),
      gross_cod: moneyFormatter(itm.totalGrossAmount),
      paid: paymentStatusMap[itm.paymentStatus],
      payment_type: itm.paymentType ? paymentTypeMap[itm.paymentType] : 'N/A',
      actions: (
        <div
          style={{
            display: 'flex',
            textDecoration: 'underline'
          }}
        >
          {itm.reportNumber ? (
            <a
              href="#"
              style={{ marginRight: '10px' }}
              onClick={() => downloadArReportDetailsCSV(itm.reportNumber)}
            >
              Download
            </a>
          ) : (
            <p style={{ marginRight: '10px' }}>Download</p>
          )}
          {itm.reportNumber ? (
            <a
              href="#"
              onClick={() => {
                if (itm.paymentStatus === 3) {
                  notify(
                    "This report is already uploaded once and can't be uploaded again"
                  );
                } else {
                  setUploadModal(true);
                  setReportId(itm.reportNumber);
                }
              }}
            >
              Upload
            </a>
          ) : (
            <p> Upload </p>
          )}
        </div>
      )
    }));
  };

  const handleDataSource = () => {
    if (reportType === 'dialy')
      return arReports?.data?.length > 0 ? formatArReports(arReports.data) : [];
    return summaryReports?.data?.length > 0
      ? formatArReports(summaryReports.data)
      : [];
  };

  const handleTabChange = (key) => {
    setReportType(key);
  };

  const { TabPane } = Tabs;

  const schema = yup.object({
    startDate: yup.date().notRequired(),
    endDate: yup.date().notRequired(),
    paymentType: yup.string().notRequired(),
    isPaid: yup.string().notRequired(),
    businessIds: yup.string().notRequired(),
    paymentStartDate: yup.date().notRequired(),
    paymentEndDate: yup.date().notRequired(),
    reportNumbers: yup.string().notRequired()
  });

  const onChangeDatePicker = (a, dateValues) => {
    setDateValues({
      creationStart: dateValues[0],
      creationEnd: dateValues[1]
    });
  };

  const acceptMethods = (refreshMethod) => {
    refreshAmazonTable.current = refreshMethod;
  };

  const handleAmazonTabSearch = useCallback(
    (values) => {
      let searchValues = cleanEmptyString({ ...values, ...dateValues });
      if (searchValues.creationStart) {
        searchValues.creationStart = FORMAT_DATE_TO_UTC(
          searchValues.creationStart
        );
      }
      if (searchValues.creationEnd) {
        searchValues.creationEnd = FORMAT_DATE_TO_UTC(searchValues.creationEnd);
      }
      setAmazonSearchValues(searchValues);
      refreshAmazonTable.current();
    },
    [amazonSearchValues]
  );

  const handleUploadAmazonTransaction = async (values) => {
    try {
      setIsLoading(true);
      const { data } = await uploadAmazonTransaction({
        deliveries: values.map((item) => ({
          ...item
        }))
      });
      notify('Your file has been uploaded successfully', 'success');
      refreshAmazonTable.current();
      setIsLoading(false);
    } catch (e) {
      notify('Error while uploading file');
      setIsLoading(false);
    }
  };

  const fetchAmazonData = async ({ pageId, pageLimit, ...props }) => {
    try {
      setIsLoading(true);
      const {
        data: { uploads, total }
      } = await getAmazonRemittance({
        pageNumber: pageId ? pageId - 1 : 0,
        pageSize: pageLimit || 10,
        ...amazonSearchValues
      });
      setAmazonData({ list: uploads, count: total });
      setIsLoading(false);
      return { list: uploads, total };
    } catch (error) {
      notify(error.message);
      setIsLoading(false);
    }
  };

  const formatAmazonTipsDateFields = (delivery) => {
    Object.keys(delivery).map((key) => {
      const isDateField = AMAZON_TIPS_DATE_FIELDS.includes(key);

      if (isDateField && delivery[key]) {
        const date = dayjs(excelSerialDateToDate(delivery[key]));

        delivery[key] = date.isValid() ? dayjs(date).format(DATE_FORMAT) : '';
      }
    });

    return delivery;
  };

  function convertValueToString(obj) {
    Object.keys(obj).forEach((k) => {
      obj[k] = obj[k].toString();
    });

    return obj;
  }

  const formatAmazonTipsDeliveries = (deliveries) => {
    return deliveries.map((delivery) => {
      const formattedDatesDelivery = formatAmazonTipsDateFields(delivery);
      const { creditInvoiceDate, termsNetDueDate, invoiceDate, ...rest } =
        formattedDatesDelivery;
      return {
        creditInvoiceDate,
        termsNetDueDate,
        invoiceDate,
        ...convertValueToString(rest)
      };
    });
  };

  const OnDownloadAmazonInvoiceReportTemplate = () => {
    saveAs(AMAZON_TIPS_SHEET_URL, 'Amazon Tips Sheet.xlsx');
  };

  const OnUploadAmazonInvoiceReportTemplate = async (file) => {
    try {
      const deliveries = await convertXlsxToJSON(file, {
        headerMapping: AMAZON_TIPS_HEADER_MAPPING
      });
      const { isValid, errors } = await cancellablePromise(
        validateAmazonTipSheet(deliveries)
      );

      if (isValid) {
        const payload = { deliveries: formatAmazonTipsDeliveries(deliveries) };

        await uploadAmazonTips(payload);
        notify(
          formatMessage({
            id: 'bulk_update_pricing_tier.success_state.title'
          }),
          'success'
        );
      } else {
        openModal(AmazonTipsErrorsModal, {
          errors,
          formatMessage
        });
      }
    } catch (error) {
      if (FILE_SHEET_UPLOAD_ERROR_TYPES[error.errorType]) {
        return notify(
          formatMessage({
            id: `common.${FILE_SHEET_UPLOAD_ERROR_TYPES[
              error.errorType
            ].toLowerCase()}`
          })
        );
      }
      notify(error.message);
    }
  };

  return (
    <Container
      header={<BRHeader title="Reimbursement Reports" />}
      hideWarningBanner
      content={
        <>
          <Tabs defaultActiveKey={reportType} onChange={handleTabChange}>
            <TabPane
              tab="Reimbursement Reports"
              key={TABS_TYPES.REIMBURSEMENT}
            />
            <TabPane tab="Souq Reports" key={TABS_TYPES.SOUQ} />
            <TabPane tab="Amazon Reports" key={TABS_TYPES.AMAZON} />
          </Tabs>
          {reportType === 'reimbursement' ? (
            <>
              <Form.Label className={styles.titleHeader}>
                Search Reports:
              </Form.Label>
              <div className={styles.ArReport}>
                {reportType === TABS_TYPES.REIMBURSEMENT && (
                  <Button
                    className="primaryButton"
                    onClick={() => history.push('/cod-reports/generate-report')}
                  >
                    Generate Report
                  </Button>
                )}
                <UploadTransactions />
              </div>
              <Modal show={uploadModal} onHide={() => setUploadModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Upload Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <UploadCOD
                    setUploadModal={setUploadModal}
                    reportId={reportId}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="light" onClick={() => setUploadModal(false)}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>

              <Formik
                className="formContainer"
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => handleSearchSubmit(values)}
                initialValues={{
                  startDate: '',
                  paymentType: '',
                  isPaid: '',
                  businessIds: ''
                }}
                render={({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  // initialValues,
                  setFieldValue,
                  errors
                  // ...rest
                }) => (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}
                  >
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} controlId="businessIds-control">
                        <Form.Label className={styles.formLabel}>
                          Business Names
                        </Form.Label>
                        <BusinessSelector
                          mode="multiple"
                          onChange={(value) => {
                            setFieldValue('businessIds', value);
                          }}
                        />
                      </Form.Group>

                      {reportType === 'reimbursement' && (
                        <Form.Group as={Col} controlId="reportNumbers-control">
                          <Form.Label className={styles.formLabel}>
                            Report Numbers
                          </Form.Label>
                          <Input
                            name="reportNumbers"
                            value={values.reportNumbers}
                            size="large"
                            onChange={(e) => {
                              setFieldValue('reportNumbers', e.target.value);
                            }}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      )}

                      {reportType === 'dialy' && (
                        <Form.Group as={Col} controlId="paymentType-control">
                          <Form.Label className={styles.formLabel}>
                            Payment Type
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="paymentType"
                            className={styles.selectControl}
                            onBlur={handleBlur}
                            value={values.paymentType}
                            isInvalid={
                              !!errors.paymentType && !!touched.paymentType
                            }
                            onChange={(e) => {
                              setFieldValue('paymentType', e.target.value);
                            }}
                          >
                            <option disabled selected>
                              Payment Type
                            </option>
                            <option value="">All</option>
                            <option value="4">CHEQUE</option>
                            <option value="5">BANK TRANSFER</option>
                            <option value="6">CASH</option>
                          </Form.Control>
                          {errors.paymentType && touched.paymentType ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.paymentType}
                            </Form.Control.Feedback>
                          ) : null}
                        </Form.Group>
                      )}
                      <Form.Group
                        as={Col}
                        controlId="from-control"
                        className={styles.formGroup}
                      >
                        <Form.Label className={styles.formLabel}>
                          Creation Date
                        </Form.Label>
                        <Calendar
                          handleChange={(value) => {
                            setFieldValue(
                              `startDate`,
                              value && value[0] ? dayjs(value[0]) : ''
                            );
                            setFieldValue(
                              `endDate`,
                              value && value[1] ? dayjs(value[1]) : ''
                            );
                          }}
                          placeholder={['', '']}
                          value={[values.startDate, values.endDate]}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="from-control"
                        className={styles.formGroup}
                      >
                        <Form.Label className={styles.formLabel}>
                          Payment Date
                        </Form.Label>
                        <Calendar
                          handleChange={(value) => {
                            setFieldValue(
                              `paymentStartDate`,
                              value && value[0] ? dayjs(value[0]) : ''
                            );
                            setFieldValue(
                              `paymentEndDate`,
                              value && value[1] ? dayjs(value[1]) : ''
                            );
                          }}
                          placeholder={['', '']}
                          value={[
                            values.paymentStartDate,
                            values.paymentEndDate
                          ]}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="isPaid-control">
                        <Form.Label className={styles.formLabel}>
                          Payment Status
                        </Form.Label>
                        <Form.Control
                          className={styles.selectControl}
                          as="select"
                          type="isPaid"
                          placeholder="Comma separated tracking numbers"
                          name="isPaid"
                          value={values.isPaid}
                          onChange={(e) => {
                            setFieldValue('isPaid', e.target.value);
                          }}
                          onBlur={handleBlur}
                          isInvalid={!!errors.isPaid && !!touched.isPaid}
                        >
                          <option disabled selected>
                            Payment Status
                          </option>
                          <option value="">All</option>
                          <option value="2">AWAITING PAYMENT</option>
                          <option value="3">PAID</option>
                        </Form.Control>
                        {errors.isPaid && touched.isPaid ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.isPaid}
                          </Form.Control.Feedback>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button type="submit" className="primaryButton">
                        Search
                      </Button>
                    </div>
                  </Form>
                )}
              />
              <br />

              <div className={styles.buttonsDiv}>
                <span>
                  <b>Total Reports .</b>{' '}
                  {reportType === 'dialy'
                    ? arReports?.data?.length || 0
                    : summaryReports?.data?.length || 0}
                </span>
                <Button
                  className="primaryButton"
                  disabled={loading}
                  onClick={() =>
                    reportType === 'dialy'
                      ? getARReportCSV(`?${currentSearch}`)
                      : getSummaryReportCSV(`?${currentSearch}`)
                  }
                >
                  Download Summary Report
                </Button>
              </div>
              <br />
              <Table
                dataSource={handleDataSource()}
                columns={handleTableColumns()}
                pagination={{
                  total: arReports?.data?.count,
                  pageSize: arReports?.data?.pageSize,
                  current: page,
                  onChange: (currentPage) => {
                    setPage(currentPage);
                    reportType === 'dialy'
                      ? getARReports({ currentPage, currentSearch })
                      : getSummaryReports({ currentPage, currentSearch });
                  }
                }}
              />
            </>
          ) : reportType === 'souq' ? (
            <>
              <Form.Label className={styles.titleHeader}>
                Upload transaction report:
              </Form.Label>
              <div className={styles.SouqReport}>
                <UploadTransactions transactionType="souq" />
              </div>
            </>
          ) : (
            <>
              <div className="br-amazon-reports__actions">
                <span className="br-amazon-reports__invoice-actions">
                  <BRButton
                    label={formatMessage({
                      id: 'cod_reports.amazon.invoice_report_template'
                    })}
                    type="basic"
                    prefixIcon={<DownloadIcon />}
                    className="button-md"
                    onClick={OnDownloadAmazonInvoiceReportTemplate}
                  />
                  <UploadAttachment
                    accept=".xlsx"
                    listType="text"
                    maxCount={1}
                    beforeUpload={OnUploadAmazonInvoiceReportTemplate}
                  >
                    <BRButton
                      label={formatMessage({
                        id: 'cod_reports.amazon.upload_invoice_report'
                      })}
                      type="basic"
                      prefixIcon={<UploadIcon />}
                      className="button-md"
                    />
                  </UploadAttachment>
                </span>
                <UploadTransactions
                  transactionType={TABS_TYPES.AMAZON}
                  handleUploadAmazonTransaction={handleUploadAmazonTransaction}
                />
              </div>
              <AntdForm onFinish={handleAmazonTabSearch}>
                <div className="br-form-row">
                  <AntdForm.Item name="trackingNumber" label="Tracking Number">
                    <Input />
                  </AntdForm.Item>
                  <AntdForm.Item name="transferId" label="Transfer ID">
                    <Input />
                  </AntdForm.Item>
                  <AntdForm.Item label="Creation Date">
                    <DatePicker.RangePicker onChange={onChangeDatePicker} />
                  </AntdForm.Item>
                </div>
                <div>
                  <AntdButton type="primary" htmlType="submit">
                    Search
                  </AntdButton>
                </div>
              </AntdForm>

              <BRSearchableTable
                title={`Total Reports (${amazonData.count || 0})`}
                listFunction={fetchAmazonData}
                columns={amazonTableColumns}
                shareMethods={acceptMethods}
                onRowClick={() => {}}
                withOutCheckBoxes
              />
            </>
          )}
        </>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getARReports: (data) => dispatch(getARReports(data)),
  getSummaryReports: (data) => dispatch(getSummaryReports(data)),
  getARReportCSV: (data) => dispatch(getARReportCSV(data)),
  getSummaryReportCSV: (data) => dispatch(getSummaryReportCSV(data)),
  downloadArReportDetailsCSV: (data) =>
    dispatch(downloadArReportDetailsCSV(data))
});

const mapStateToProps = ({ deliveries, codAR }) => ({
  ...deliveries,
  arReports: codAR.reports,
  reportCSV: codAR.reportCSV,
  summaryReports: codAR.summaryReports,
  loading: codAR.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CodTransactions));
