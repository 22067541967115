import { useState, useRef } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';

import { createCompensationRecord } from 'services/wallet';
import { COMPENSATION_DEPARTMENTS_NAMES } from 'constants/wallet';

import BusinessInfoSection from 'components/AddCompensationFormModal/components/BusinessInfoSection/BusinessInfoSection';
import CompensationInfoSection from 'components/AddCompensationFormModal/components/CompensationInfoSection/CompensationInfoSection';
import { notify } from 'components/Notify/Notify';

import './AddCompensationFormModal.less';

const AddCompensationFormModal = ({
  onSuccess,
  close,
  onSumbit,
  intl,
  delivery,
  ...props
}) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedReason, setSelectedReason] = useState(null);
  const [assignedDepartments, setAssignedDepartment] = useState(null);
  const [freeOrdersTotalAmount, setFreeOrdersTotalAmount] = useState(null);
  const [wrongPackageSizeAmounts, setWrongPackageSizeAmounts] = useState({});

  const handleOnTrackClick = () => {
    window.open(`/wallet/compensation?tab=3`, '_blank');
  };

  const handleOnOk = async (values) => {
    try {
      const indexOfHub = values?.liabilityOn?.indexOf('Hub');
      if (indexOfHub >= 0) {
        values.liabilityOn[indexOfHub] = values.hubName.join(', ');
      }
      const payload = {
        compensation: {
          reasonDetails: {
            ticketUrl: values?.ticketUrl,
            reasonText: selectedReason?.reason,
            reasonId: selectedReason?._id
          },
          businessInfo: {
            businessName: delivery?.sender?.name,
            businessId: delivery?.sender?._id
          },
          deliveries: [
            ...(values?.compensationType === 'Free'
              ? Object.keys(values?.noOfFreeOrder).map((tn) => {
                  return {
                    trackingNumber: tn,
                    totalAmount: freeOrdersTotalAmount[tn],
                    liabilityType: values?.compensationType?.toUpperCase(),
                    liabilityFactor: values?.noOfFreeOrder[tn]
                  };
                })
              : values?.orderId?.split(',').map((tn) => {
                  return {
                    trackingNumber: tn,
                    totalAmount:
                      selectedReason?.reason === 'Wrong Package Size'
                        ? wrongPackageSizeAmounts[tn] || 0
                        : totalAmount,
                    liabilityType: values?.compensationType?.toUpperCase()
                  };
                }))
          ],
          assignedDepartments: [
            ...values.assignedDepartment.map((deparment) => {
              const selectedDepartment = assignedDepartments.filter(
                (depart) => depart.name === deparment
              )[0];
              return {
                departmentId: selectedDepartment._id,
                departmentName: deparment,
                assigneeName: values?.approvedBy[deparment],
                liability: {
                  hasLiability:
                    values?.compensationType === 'Free' ? false : true,
                  liabilityAmount:
                    values?.compensationType === 'Free'
                      ? 0
                      : values?.compensationAmount[deparment],
                  liabilityOn: values?.liabilityOn
                },
                ...(deparment ===
                  COMPENSATION_DEPARTMENTS_NAMES.INVESTIGATION && {
                  reason: values?.investigationReason
                })
              };
            })
          ],
          additionalApproval: {
            isMDApproved:
              values?.approvedByLargeAmount?.includes('MD') || false,
            isCFOApproved:
              values?.approvedByLargeAmount?.includes('CFO') || false
          }
        }
      };
      await createCompensationRecord(payload);
      notify(
        <div className="br-compensation-form-modal__success-message">
          {intl.formatMessage(
            { id: 'request_compensation_modal.success_message' },
            {
              trackRequest: (
                <span
                  className="br-compensation__track-request-message"
                  onClick={handleOnTrackClick}
                >
                  {intl.formatMessage({
                    id: 'request_compensation_modal.track_request'
                  })}
                </span>
              )
            }
          )}
        </div>,
        'success',
        false,
        1,
        'br-compensation__success-notify-message'
      );
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  const formRef = useRef('');
  return (
    <Modal
      onCancel={close}
      {...props}
      title="Request compensation"
      width={null}
      okText="Confirm"
      className="br-add-compensation-form-modal__container"
      okButtonProps={{
        htmlType: 'submit',
        form: 'compensationInfoForm'
      }}
    >
      <CompensationInfoSection
        delivery={delivery}
        onSubmit={handleOnOk}
        formRef={formRef}
        setTotalAmount={setTotalAmount}
        totalAmount={totalAmount}
        setCompensationReason={setSelectedReason}
        setAssignedDepartment={setAssignedDepartment}
        setFreeOrdersTotalAmount={setFreeOrdersTotalAmount}
        setWrongPackageSizeAmounts={setWrongPackageSizeAmounts}
      />
      <BusinessInfoSection delivery={delivery} totalAmount={totalAmount} />
    </Modal>
  );
};

export default injectIntl(AddCompensationFormModal);
