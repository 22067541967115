import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Drawer } from 'antd';

import { handlePrintSerials } from 'constants/fulfillment-return-orders';
import { SKU_SERIAL_SEPARATOR } from 'constants/fulfillment-sales-orders';

import SerialsSameType from 'components/NewFulfillment/FulfillmentReturns/ReturnOrder/components/SerialsView/components/SerialsSameType/SerialsSameType';
import SerialsViewHeader from 'components/NewFulfillment/FulfillmentReturns/ReturnOrder/components/SerialsView/components/SerialsViewHeader/SerialsViewHeader';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './SerialsView.less';

const SerialsView = ({
  onClose,
  productSku,
  fetchData,
  formatTypedSerialsList,
  getSerialType,
  showExpandBtn = false
}) => {
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [allFetchedSerials, setAllFetchedSerials] = useState([]);
  const [typedSerials, setTypedSerials] = useState([]);
  const [scannedSerial, setScannedSerial] = useState('');

  const fetchSerials = async () => {
    setIsLoading(true);

    try {
      const { data } = await fetchData();

      const allSerials = data.serials || [];
      const formattedTypedList = formatTypedSerialsList(allSerials);
      setAllFetchedSerials(
        formattedTypedList.map(({ serials }) => serials).flat()
      );
      setTypedSerials(formattedTypedList);
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const handleValidScan = (serial) => {
    const foundSerial = allFetchedSerials.find(
      ({ serialNumber }) => serialNumber === serial
    );

    if (!foundSerial) {
      notify(
        formatMessage({
          id: 'common.no_results_found'
        })
      );
    }

    setScannedSerial(foundSerial);
  };

  const printSerials = (serials) =>
    handlePrintSerials({
      serials,
      setIsLoading
    });

  const copySerial = (serialNumber) => {
    navigator.clipboard.writeText(
      `${productSku}${SKU_SERIAL_SEPARATOR}${serialNumber}`
    );
  };

  useEffect(() => {
    fetchSerials();
  }, []);

  return (
    <Drawer
      title={
        <>
          <span>
            {formatMessage({
              id: 'fulfillment_returns.serials_view.title'
            })}
          </span>
          <span className="text-gray">{productSku}</span>
        </>
      }
      className="br-serials-view"
      placement="right"
      width={536}
      onClose={onClose}
      visible
    >
      <LoadingWrapper loading={isLoading}>
        <SerialsViewHeader
          allFetchedSerials={allFetchedSerials}
          typedSerials={[
            ...(typedSerials.length > 1
              ? [
                  {
                    title: formatMessage({
                      id: 'common.all'
                    }),
                    serials: allFetchedSerials
                  }
                ]
              : []),
            ...typedSerials
          ]}
          scannedSerial={scannedSerial}
          handleValidScan={handleValidScan}
          printSerials={printSerials}
          copySerial={copySerial}
          getSerialType={getSerialType}
        />
        {typedSerials.map(
          ({ type, serials }) =>
            !!serials.length && (
              <SerialsSameType
                key={type}
                type={type}
                serials={serials}
                printSerials={printSerials}
                copySerial={copySerial}
                showExpandBtn={showExpandBtn}
              />
            )
        )}
      </LoadingWrapper>
    </Drawer>
  );
};

export default SerialsView;
