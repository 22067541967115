import http from 'utils/http';

const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country?._id;

export const fetchCountries = () => {
  return http.get(`/countries`);
};

export const fetchCitiesbyCountryId = (countryId) => {
  return http.get(`/cities/${countryId}/cities`);
};

export const fetchCities = (countryId = false) => {
  let url = `/cities`;
  if (userCountry) {
    url = `/cities?countryId=${countryId || userCountry}`;
  }
  return http.get(url);
};
export const fetchZones = (id) => {
  return http.get(`/zones?cityId=${id}`);
};

export const getAreasInfo = (cityId, payload) => {
  return http.get(`/cities/${cityId}/districts`);
};
