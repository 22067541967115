import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  fetchFulfillmentLocations,
  getNextLevelValue
} from 'constants/fulfillment-locations';

import './LastLevelContent.less';

const LastLevelContent = ({
  setIsLoading,
  level,
  parentLevelName,
  parentLevelId,
  isLoading,
  setNewLastLevelValue
}) => {
  const [list, setList] = useState([]);
  const { formatMessage } = useIntl();

  const renderLevelRange = () => {
    const len = list.length;
    const lastItem = list[len - 1];

    return (
      <>
        {formatMessage(
          {
            id: 'fulfillment_locations.create_location_modal.you_have'
          },
          {
            count: len,
            level: formatMessage({
              id: `fulfillment_locations.location_levels.${level}`
            })?.toLowerCase()
          }
        )}
        <span className="br-last-level-content__tag">{list[0].label}</span>
        {len > 1 && (
          <>
            -
            <span className="br-last-level-content__tag">{lastItem.label}</span>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    fetchFulfillmentLocations({
      level: parentLevelName,
      id: parentLevelId,
      setIsLoading,
      setList,
      callback: ({ newList }) => {
        setNewLastLevelValue(
          getNextLevelValue({
            level,
            previousValue: newList[newList.length - 1]?.label
          })
        );
      }
    });
  }, [parentLevelId]);

  return (
    <div className="br-last-level-content">
      {!isLoading &&
        (list.length ? (
          renderLevelRange()
        ) : (
          <span className="br-last-level-content__empty-list">
            {formatMessage(
              {
                id: 'fulfillment_locations.create_location_modal.not_have'
              },
              {
                level: formatMessage({
                  id: `fulfillment_locations.location_levels.${level}`
                })?.toLowerCase(),
                parentLevel: formatMessage({
                  id: `fulfillment_locations.location_levels.${parentLevelName}`
                })?.toLowerCase()
              }
            )}
          </span>
        ))}
    </div>
  );
};

export default LastLevelContent;
