import { currentUserCountry } from 'common/countries/countries-mapping';
import { isAdminGroupUser } from 'utils/helpers';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getUserWarehouseScope = (allHubs) => {
  const warehouseScopeIds = userInfo?.scopes?.warehouse
    ? userInfo?.scopes?.warehouse[currentUserCountry.codeName].map(
        (warehouse) => warehouse._id
      )
    : [];

  if (!isAdminGroupUser() || !warehouseScopeIds?.length) {
    return allHubs;
  }

  return allHubs.filter((hub) => warehouseScopeIds.includes(hub._id));
};

export const getUserWarehouses = () => {
  return userInfo?.scopes?.warehouse
    ? userInfo?.scopes?.warehouse[currentUserCountry.codeName]
    : null;
};

export const hasWarehouseScope = () => {
  if (userInfo?.scopes) {
    return Object.keys(userInfo.scopes).includes('warehouse');
  }

  return false;
};
