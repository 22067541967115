import { Radio } from 'antd';

import { HUB_RECEIVE_FROM_SORTING_OPTIONS } from 'constants/hubs';

import './ScanningTypeSelector.less';

const ScanningTypeSelector = ({ scanningType, handleOnScanningTypeChange }) => {
  const handleOnChange = (e) => {
    handleOnScanningTypeChange(e.target.value);
  };

  return (
    <div className="br-hub-receiving__scanning-type-selector">
      <Radio.Group value={scanningType} onChange={handleOnChange}>
        {HUB_RECEIVE_FROM_SORTING_OPTIONS.map(({ label, value }) => (
          <Radio.Button value={value}>{label}</Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ScanningTypeSelector;
