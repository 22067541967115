import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Timeline, Tag } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import groupBy from 'lodash/groupBy';

import { checkDate, dates, secondsToTime } from 'utils/helpers';
import { checkIfOrderRestarted } from 'utils/order-logs';
import { DELIVERY_STATES_CODES, TICKET_REQUESETER } from 'constants/shipments';
import {
  CALL_AND_SMS_LOGS,
  DATE_FORMAT,
  DATE_FORMAT_WITH_DAY,
  FINAL_STATE_CODES,
  MISSED_CALL,
  OFD_CODES,
  ORDER_LOGS,
  RTO,
  SMS
} from 'constants/order-logs';

import { ReactComponent as PhoneIcon } from 'assets/bosta-icons/Phone.svg';
import { ReactComponent as SMSIcon } from 'assets/bosta-icons/Chat.svg';

import './ShipmentDetailsTracking.less';

const ShipmentDetailsTracking = ({
  shipmentDetails,
  intl,
  isExpanded,
  setHaveManyLogs,
  haveManyLogs
}) => {
  const [formattedLogs, setFormattedLogs] = useState({});

  const getLogs = (logs = []) => {
    const allLogs = [];

    if (logs.length) {
      logs.map((log, index) => {
        const { actionType, actionsList } = log;
        const logKeys = actionsList ? Object.keys(actionsList) : [];

        const suitableLog = ORDER_LOGS({
          delivery: shipmentDetails,
          log,
          validLogs: logs,
          logIndex: index
        })?.filter(
          (log) =>
            log.condition &&
            log.actionType === actionType &&
            log.keys.every((key) => logKeys.includes(key)) &&
            log.excludedKeys.every((key) => !logKeys.includes(key))
        );
        suitableLog?.length && allLogs.push(...suitableLog);
      });
    }

    const sortedLogs = sortLogsByDate([
      ...allLogs,
      ...getCallAndSmsLogs(),
      ...getTicketLogs()
    ]);

    return groupLogs(sortedLogs);
  };

  const getTicketLogs = () => {
    if (!shipmentDetails?.ticketLogs?.length) {
      return [];
    }

    return shipmentDetails.ticketLogs.map((log) => ({
      log: intl.formatMessage(
        {
          id: `orders.states_logs.logs.ticket_created_by_${
            log?.requesterType === TICKET_REQUESETER.Customer
              ? 'consignee'
              : 'business'
          }`
        },
        {
          ticketId: log.externalId
        }
      ),
      date: log.createdAt
    }));
  };

  const getCallAndSmsLogs = () => {
    if (shipmentDetails?.starActions?.length) {
      return shipmentDetails.starActions.map((starAction) => {
        const { action, phoneNumber, callDuration, date } = starAction;
        return {
          log: (
            <>
              <span className="br-order-logs__log-title display-flex align-center">
                {CALL_AND_SMS_LOGS[action]}
                {action === SMS ? <SMSIcon /> : <PhoneIcon />}
              </span>
              <span className="br-order-logs__call-log-details display-flex align-center">
                <span className="br-order-logs__call-logs-phone-number caption">
                  {intl.formatMessage(
                    { id: `orders.states_logs.logs.phone_number` },
                    { number: phoneNumber }
                  )}
                </span>
                {action !== SMS && (
                  <Tag className="ant-tag-dark-gray caption-medium">
                    {intl.formatMessage({
                      id: `orders.states_logs.logs.call_duration`
                    })}
                    :
                    {secondsToTime(
                      action === MISSED_CALL || !callDuration ? 0 : callDuration
                    )}
                  </Tag>
                )}
              </span>
            </>
          ),
          date: starAction.date
        };
      });
    }

    return [];
  };

  const groupLogs = (logs) => {
    return groupBy(logs, (log) => {
      const day = checkDate(log.date);
      return `${day ? `${day},` : ''} ${dates(
        log.date,
        day ? DATE_FORMAT : DATE_FORMAT_WITH_DAY
      )}`;
    });
  };

  const cleanLogs = (logs = []) => {
    const validLogs = [];

    logs.forEach((log, index) => {
      if (
        ([...FINAL_STATE_CODES, DELIVERY_STATES_CODES.EXCEPTION].includes(
          log?.actionsList?.state_code?.after
        ) ||
          log?.actionsList?.type?.after === RTO) &&
        checkIfOrderRestarted({
          logs,
          index,
          currentLog: log
        })
      ) {
        return;
      }

      if (
        log?.actionsList?.state_code?.before ===
          DELIVERY_STATES_CODES.EXCEPTION &&
        OFD_CODES.includes(log?.actionsList?.state_code?.after)
      ) {
        return;
      }

      if (
        FINAL_STATE_CODES.includes(log?.actionsList?.state_code?.before) &&
        OFD_CODES.includes(log?.actionsList?.state_code?.after)
      ) {
        return;
      }

      return validLogs.push(log);
    });

    return validLogs;
  };

  useEffect(() => {
    if (shipmentDetails) {
      const { log } = shipmentDetails;
      setFormattedLogs(getLogs(cleanLogs(log)));
    }
  }, [shipmentDetails]);

  useEffect(() => {
    const logsValues = Object.values(formattedLogs);
    setHaveManyLogs(logsValues[0]?.length > 2 || logsValues?.length > 1);
  }, [formattedLogs]);

  const sortLogsByDate = (logs) => {
    if (!logs) {
      return [];
    }

    return logs?.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  };

  return (
    <div
      className={classNames(
        'br-shipment-details__tracking',
        {
          'br-shipment-details__tracking-expanded': isExpanded || !haveManyLogs
        },
        { 'br-shipment-tracking__many-logs': !haveManyLogs }
      )}
    >
      <span className="br-shipment-details__tracking-title subheading">
        {intl.formatMessage({ id: 'order_details.activity_log' })}
      </span>
      <Timeline className="br-shipment-details__tracking-timeline">
        {Object.keys(formattedLogs)
          .reverse()
          .map((date, index) => (
            <Timeline.Item key={index}>
              <p className="br-shipment-details_tracking-status body-medium">
                {date}
              </p>
              {formattedLogs[date]
                .map(({ log, date, icon, extraInfo, location }, index) => (
                  <div className="br-details-status">
                    <div className="br-shipment-details-logs" key={index}>
                      <span className="br-shipment-details_tracking-details">
                        {log}
                      </span>
                      {extraInfo && (
                        <span className="br-shipment-details_tracking-logs caption">
                          {extraInfo}
                        </span>
                      )}
                      <span className="br-shipment-details_tracking-details-date">
                        {dates(date, 'h:mm A')}
                        {location && (
                          <span className="br-shipment-details_tracking-details-location">
                            {location}
                          </span>
                        )}
                        {icon && (
                          <Icon
                            className="ant-icon-sm br-shipment-details_tracking-details-icon"
                            component={icon}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                ))
                .reverse()}
            </Timeline.Item>
          ))}
      </Timeline>
    </div>
  );
};

export default injectIntl(ShipmentDetailsTracking);
