import { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix, { EXPERIENCE } from 'common/aclMatrix';
import { hasThreePLIntegrationPageAccess } from 'common/countries/countries-mapping';
import { getHubs } from 'services/hubs';
import { HubsContext } from 'contexts/hub.context';
import { getUserWarehouseScope } from 'common/scopes';
import { ACL_MATRIX, PAGES_ACCESS } from 'common/permissions';

import HubManagmentRoutes from 'containers/HubManagment/HubManagmentRoutes';
import ActionCenter from 'components/ActionCenter/ActionCenter';
import AuditActions from 'components/AuditActions/AuditActions';
import OfdEdits from 'components/OfdEdits/OfdEdits';
import Geofences from 'components/Geofences/Geofences';
import DispatchingContainer from 'components/Dispatching/DispatchingContainer';
import ThreePLIntegrations from 'components/ThreePLIntegrations/ThreePLIntegartions';
import HubOperationsContainer from 'components/HubOperations/HubOperationsContainer';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Zoning from 'components/Zoning/Zoning';
import { notify } from 'components/Notify/Notify';
import BostaCoding from 'components/BostaCoding/BostaCoding';

const HubsRoutes = ({ match: { path } }) => {
  const [allHubs, setAllHubs] = useState([]);
  const [scopedHubs, setScopedHubs] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    getAllHubs();
  }, []);

  const getAllHubs = async () => {
    setIsPageLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(result);
      setScopedHubs(getUserWarehouseScope(result));
    } catch (error) {
      notify(error.message);
    }
    setIsPageLoading(false);
  };

  const HUB_ROUTES = [
    {
      path: '/hubs/hub-operation',
      component: HubOperationsContainer,
      access: aclMatrix.HUB,
      permission: PAGES_ACCESS.HUBS_OPERATIONS
    },
    {
      path: '/hubs/hub-management',
      component: HubManagmentRoutes,
      access: [...aclMatrix.HUB_MANAGEMENT, EXPERIENCE],
      permission: PAGES_ACCESS.HUBS_MANAGEMENT
    },
    {
      path: '/hubs/action-center',
      component: ActionCenter,
      access: aclMatrix.ACTION_CENTER,
      permission: PAGES_ACCESS.ACTION_CENTER_HUB_VIEW
    },
    {
      path: '/hubs/audit-actions',
      component: AuditActions,
      access: aclMatrix.AUDIT_ACTIONS,
      permission: PAGES_ACCESS.ACTION_CENTER_AUDIT_VIEW
    },
    {
      path: '/hubs/ofd-edits',
      component: OfdEdits,
      access: aclMatrix.OFD_EDITS,
      permission: [ACL_MATRIX.NOTIFICATION_LIST_STAR_NOTIFICATION]
    },
    {
      path: '/hubs/geofences',
      component: Geofences,
      access: aclMatrix.GEOFENCES,
      permission: PAGES_ACCESS.GEOFENCES
    },
    {
      path: '/hubs/monitor-hub-operations',
      component: DispatchingContainer,
      access: aclMatrix.DISPATCHING,
      permission: PAGES_ACCESS.HUBS_OPERATIONS_MONITORING
    },
    {
      path: '/hubs/three-pl-integrations',
      component: ThreePLIntegrations,
      access: aclMatrix.THREE_PL_INTEGRATIONS,
      countryAccess: hasThreePLIntegrationPageAccess(),
      permission: [ACL_MATRIX.THREE_PL_PROVIDERS_CREATE_ORDER]
    },
    {
      path: '/hubs/zoning',
      component: Zoning,
      access: aclMatrix.ZONING,
      permission: [ACL_MATRIX.ZONES_UPDATE]
    },
    {
      path: '/hubs/bosta-coding',
      component: BostaCoding,
      access: aclMatrix.BOSTA_CODING,
      permission: [ACL_MATRIX.STREET_CODES_CREATE]
    }
  ];

  const getDefaultPath = () => {
    let defaultPathName = '/hubs/hub-operation';

    HUB_ROUTES.some((route) => {
      if (isUserAuthorized(route.access, route.permission)) {
        defaultPathName = route.path;
        return true;
      }
    });

    return defaultPathName;
  };

  return (
    <LoadingWrapper loading={isPageLoading}>
      <HubsContext.Provider value={{ allHubs, scopedHubs }}>
        <Switch>
          {HUB_ROUTES.map(
            (
              { path, component, access, permission, countryAccess = true },
              index
            ) =>
              isUserAuthorized(access, permission) &&
              countryAccess && (
                <Route key={index} path={path} component={component} />
              )
          )}
          <Redirect from={path} to={getDefaultPath()} />
        </Switch>
      </HubsContext.Provider>
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(HubsRoutes));
