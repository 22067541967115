import React from 'react';
import { Modal } from 'antd';

const ConfirmationModal = ({
  close,
  message,
  title,
  onConfirm,
  onFinish,
  ...props
}) => {
  const handleOnOkEdit = () => {
    onFinish();
    close();
  };
  return (
    <Modal
      onCancel={close}
      {...props}
      title={'Warning'}
      width={null}
      cancelText={'Cancel'}
      okText={'Yes'}
      onOk={handleOnOkEdit}
    >
      <div className="br-add-factors-container">
        Are you sure you want to update this template? any updates will be
        applied starting from the next cycle which will start at 12:00 AM next
        Saturday.
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
