import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const PRODUCTS_SEARCH_OPTIONS_IDS = {
  SKU: {
    KEY: 'SKU',
    API_KEY: 'searchString',
    LABEL: fmt({
      id: 'fulfillment_products.products_table.columns.sku'
    })
  },
  BOSTA_SKU: {
    KEY: 'SKU',
    API_KEY: 'bostaSku',
    LABEL: fmt({
      id: 'fulfillment_products.products_table.columns.sku'
    })
  },
  PRODUCT_NAME: {
    KEY: 'PRODUCT_NAME',
    API_KEY: 'searchString',
    LABEL: fmt({
      id: 'fulfillment_products.products_table.columns.product_name'
    })
  },
  REFERENCE_ID: {
    KEY: 'REFERENCE_ID',
    API_KEY: 'referenceId',
    LABEL: fmt({
      id: 'fulfillment_products.products_table.columns.reference_id'
    })
  }
};

export const PRODUCTS_SEARCH_OPTIONS = [
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.SKU.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.SKU.KEY
  },
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
  },
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.KEY
  }
];

export const ADD_PRODUCT_SEARCH_OPTIONS = [
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
  },
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.KEY
  }
];

export const SELECT_PRODUCTS_SEARCH_OPTIONS = [
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
  },
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.SKU.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.SKU.KEY
  },
  {
    label: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.LABEL,
    value: PRODUCTS_SEARCH_OPTIONS_IDS.REFERENCE_ID.KEY
  }
];
