import { fmt } from 'IntlWrapper/IntlWrapper';
import {
  AT_LEAST_EIGHT_LETTERS,
  AT_LEAST_ONE_DIGIT,
  AT_LEAST_ONE_SPECIAL_CHAR,
  AT_LEAST_ONE_UPPERCASE
} from './form';

export const SECURITY_TYPES = {
  SMS: 'SMS_TWO_FA',
  APP: 'TOTP_TWO_FA',
  EMAIL: 'EMAIL_TWO_FA'
};

export const PASSWORD_VALIDATION_RULES = [
  {
    label: fmt({
      id: 'change_password_form.validation_messages.contains_number'
    }),
    regex: AT_LEAST_ONE_DIGIT
  },
  {
    label: fmt({
      id: 'change_password_form.validation_messages.at_least_8_char'
    }),
    regex: AT_LEAST_EIGHT_LETTERS
  },
  {
    label: fmt({
      id: 'change_password_form.validation_messages.at_least_uppercase'
    }),
    regex: AT_LEAST_ONE_UPPERCASE
  },
  {
    label: fmt({
      id: 'change_password_form.validation_messages.at_least_special_char'
    }),
    regex: AT_LEAST_ONE_SPECIAL_CHAR
  }
];

export const PASSWORD_EXPIRED_ERROR_CODE = 4133;
export const PASSWORD_REGEX =
  /^.*(?=.{8,})((?=.*(\W|_)){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const SESSION_DATE_FORMAT = 'dddd, D MMMM YYYY';
