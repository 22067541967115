export const MAP_CATEGORY_TO_STRING = {
  0: 'Bonus',
  1: 'Deduction',
  2: 'Allowance'
};

export const MAP_STATE_TO_STRING = {
  0: 'Pending Approval',
  1: 'Approved',
  2: 'Rejected'
};

export const STATES = [
  { value: 0, label: 'Pending Approval' },
  { value: 1, label: 'Approved' },
  { value: 2, label: 'Rejected' }
];

export const CATEGORIES = [
  { value: 0, label: 'Bonus' },
  { value: 1, label: 'Deduction' },
  { value: 2, label: 'Allowance' }
];

export const BONUS_REASONS = [
  'Deliver Outside Zone',
  'Deliver after working shift',
  'Work in vacations days',
  'Collect pickup outside his zone',
  'Bike collects a pickups needs a car ( if there is no car )',
  'Extra Pickup load',
  'Take extra work out of his Zone if there is a drop in this area'
];

export const DEDUCTION_REASONS = [
  'Fake Update',
  'Not attending without Permission',
  'Bad Behaviour ( In Hub - With Customers )',
  'Leaving work before informing',
  'Late attending in morning',
  'late attending at night',
  'Deposit is not full or late',
  'Miss Pickup',
  'Not follow our process for delivery-pickup-CRP-cash'
];

export const ALLOWANCE_REASONS = [
  'Phone Allowance',
  'Road Fees (Karta)',
  'Support at Hub Sorting'
];
