import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Tag } from 'antd';
import classnames from 'classnames';
import Highlighter from 'react-highlight-words';

import { LOCALE } from 'constants/intl-wrapper';

import EmptySearchView from 'assets/imgRevamp/EmptySearchView.png';

import './BRArea.less';

export const AVAILABILITY = {
  DROPOFF: 'dropOffAvailability',
  PICKUP: 'pickupAvailability'
};

const BRArea = (props) => {
  const {
    intl,
    name,
    areas,
    limitedAreas,
    availability,
    formItemClassName,
    value,
    suffixIcon,
    withLabel = true,
    required = true,
    ...restOfProps
  } = props;

  const [searchValue, setSearchValue] = useState('');

  const checkAreas = (area) => {
    if (
      (area?.[availability] && area?.districtName === 'Others') ||
      area?.districtName !== 'Others'
    ) {
      return areaOption(area);
    }
  };

  const areaOption = (area) => {
    const { zoneName, zoneNameAr, districtId, districtName, districtNameAr } =
      area;

    return (
      <Select.Option
        disabled={area?.[availability] === false}
        key={districtId}
        value={districtId}
      >
        <div
          className={classnames('display-flex justify-space-between', {
            'br-uncovered-area': area?.[availability] === false,
            bold: zoneNameAr === districtNameAr || zoneName === districtName
          })}
        >
          <Highlighter
            highlightClassName="br-area__highlighted-text"
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={
              intl.locale === LOCALE.AR
                ? zoneNameAr === districtNameAr
                  ? zoneNameAr
                  : `${zoneNameAr} - ${districtNameAr}`
                : zoneName === districtName
                ? zoneName
                : `${zoneName} - ${districtName}`
            }
          />

          {area?.[availability] === false && (
            <Tag className="br-area__uncovered-tag ant-tag-light-gray">
              {intl.formatMessage({
                id: 'form.uncovered_zone'
              })}
            </Tag>
          )}
        </div>
      </Select.Option>
    );
  };

  return (
    <Form.Item
      name={value ? undefined : name || 'districtId'}
      rules={[{ required }]}
      className={formItemClassName}
      label={
        withLabel &&
        intl.formatMessage({
          id: 'location_form_labels.area'
        })
      }
    >
      <Select
        placeholder={intl.formatMessage({
          id: `form.area_select_placeholder`
        })}
        value={value}
        // autoComplete={'' + Math.random()}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        suffixIcon={suffixIcon}
        allowClear
        showSearch
        onSearch={async (value) => await setSearchValue(value)}
        filterOption={(input, option) =>
          option.children.props.children[0].props.textToHighlight
            .toLowerCase()
            .indexOf(input?.toLowerCase()) >= 0
        }
        dropdownRender={(menu) =>
          searchValue || limitedAreas ? (
            menu
          ) : (
            <div className="br-area__no-character-search">
              <img src={EmptySearchView} />
              <span className="br-area__title">
                {intl.formatMessage({ id: 'br_area.dropdown_search_title' })}
              </span>
              <span className="br-area__sub-title">
                {intl.formatMessage({
                  id: 'br_area.dropdown_search_sub_title'
                })}
              </span>
            </div>
          )
        }
        notFoundContent={
          <div className="br-area__no-results-search">
            <img src={EmptySearchView} />
            <span className="br-area__title">
              {intl.formatMessage({
                id: 'br_searchable_table.empty_view.no_search_title'
              })}
            </span>
          </div>
        }
        dropdownAlign={{
          points: ['tl', 'bl'],
          offset: [0, 4],
          overflow: {
            adjustX: 0,
            adjustY: 0
          }
        }}
        {...restOfProps}
      >
        {areas?.map(checkAreas)}
      </Select>
    </Form.Item>
  );
};
export default injectIntl(BRArea);
