import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import { getSalesOrderLineSerialNumbers } from 'services/sales-orders';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as BarcodeIcon } from 'assets/bosta-icons/BarcodeIcon.svg';
import { ReactComponent as CheckedIcon } from 'assets/bosta-icons/Circle-Check.svg';

import './SerialNumbersDataTooltip.less';

const SerialNumbersDataTooltip = ({
  intl,
  line: { id, serialsCount },
  lineScannedSerials = [],
  hideScannedSerialsCount = false,
  fetchSerials
}) => {
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isAllLineSerialsScanned = () =>
    lineScannedSerials.length === Number(serialsCount);

  const fetchLineSerialNumbers = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await (fetchSerials
        ? fetchSerials()
        : getSalesOrderLineSerialNumbers(id));
      setSerialNumbers(data.serials || []);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const renderSerial = ({ serialNumber }) => {
    const isChecked = lineScannedSerials.find((skuSerial) =>
      skuSerial.includes(serialNumber)
    );

    return (
      <div
        key={serialNumber}
        className={classNames('br-confirmed-po__tooltip__serial-number', {
          checked: isChecked
        })}
      >
        {serialNumber}
        {isChecked && <CheckedIcon className="checked-icon" />}
      </div>
    );
  };

  const renderSerials = () => {
    const middleIndex = Math.ceil(serialNumbers.length / 2);

    return (
      <LoadingWrapper loading={isLoading}>
        <div>{serialNumbers.slice(0, middleIndex).map(renderSerial)}</div>
        <div>{serialNumbers.slice(middleIndex).map(renderSerial)}</div>
      </LoadingWrapper>
    );
  };

  return (
    <>
      {isAllLineSerialsScanned() ? (
        <CheckedIcon className="checked-icon" />
      ) : (
        <BarcodeIcon />
      )}

      <Tooltip
        overlayClassName="br-confirmed-po__tooltip br-serial-numbers-tooltip"
        trigger="click"
        color="white"
        placement="bottom"
        onClick={
          serialsCount && !serialNumbers.length && fetchLineSerialNumbers
        }
        {...(serialsCount && {
          title: (
            <>
              <span className="br-serial-numbers-tooltip__count">
                {intl.formatMessage(
                  {
                    id: 'fulfillment_outbound.serials_count'
                  },
                  {
                    count: serialsCount
                  }
                )}
              </span>
              {renderSerials()}
            </>
          )
        })}
      >
        <span
          className={classNames('br-confirmed-po__tooltip__text has-serials', {
            checked: isAllLineSerialsScanned()
          })}
        >
          {hideScannedSerialsCount
            ? serialsCount
            : `${lineScannedSerials.length}/${serialsCount}`}
        </span>
      </Tooltip>
    </>
  );
};

export default injectIntl(SerialNumbersDataTooltip);
