import dayjs from 'dayjs';
import { fmt } from 'IntlWrapper/IntlWrapper';
import XlsxPopulate from 'xlsx-populate';
import { omitBy } from 'lodash';

import {
  ADDRESS_UPDATED,
  CANCEL_ORDER,
  RESCHEDULED,
  RESCHEDULED_TOMORROW,
  RESTORED_DELIVERY,
  UPDATED_DELIVERY_DETAILS
} from 'constants/action-center';
import { openModal } from 'utils/modal';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { FIRST_MILE_HUB, SORTING_HUB } from 'constants/hubs';

import { notify } from 'components/Notify/Notify';
import BRScanningPopup from 'components/BRScanningPopup/BRScanningPopup';

export const endDebriefingConditions = ({ starPickups, starDeliveries }) => {
  if (
    starPickups.length &&
    starPickups.some((el) => el.state === 'Picked up')
  ) {
    notify(
      'You have to receive all exception packages and pickup packages before ending the debrief.'
    );
    return false;
  }
  if (
    starDeliveries.length &&
    starDeliveries.some((el) => el.state.code === 45 || el.state.code === 46)
  ) {
    notify(
      'You have packages that are not confirmed yet, Confirm all deliveries before ending the debrief'
    );
    return false;
  }
  return true;
};

const prioritizeActions = (actions) => {
  if (actions.length) {
    if (actions.includes(CANCEL_ORDER)) {
      return CANCEL_ORDER;
    } else if (actions.includes(ADDRESS_UPDATED)) {
      return ADDRESS_UPDATED;
    } else if (actions.includes(UPDATED_DELIVERY_DETAILS)) {
      return UPDATED_DELIVERY_DETAILS;
    } else if (actions.includes(RESCHEDULED_TOMORROW)) {
      return RESCHEDULED_TOMORROW;
    } else if (actions.includes(RESCHEDULED)) {
      return RESCHEDULED;
    }
  }
};

export const getDeliveryPendingActions = (deliveries) => {
  if (deliveries?.actionItems) {
    const pendingActions = deliveries?.actionItems.filter(
      (item) => !item.isApplied
    );

    if (pendingActions) {
      const allPendingActionsType = pendingActions.map((item) => {
        if (item.action === UPDATED_DELIVERY_DETAILS) {
          return item.actionDetails?.dropOffAddress
            ? ADDRESS_UPDATED
            : UPDATED_DELIVERY_DETAILS;
        } else if (
          item.action === RESCHEDULED &&
          dayjs(item.scheduledAt).isSameOrAfter(dayjs().startOf('day'))
        ) {
          const current = dayjs().startOf('day');
          const rescheduleDate = dayjs(item.scheduledAt);
          return rescheduleDate.diff(current, 'days') > 1
            ? RESCHEDULED
            : RESCHEDULED_TOMORROW;
        } else if (item.action === CANCEL_ORDER) {
          return CANCEL_ORDER;
        }
      });
      return prioritizeActions(allPendingActionsType);
    }
  }
};

export const showNotification = (action, delivery) => {
  if (!action) {
    return;
  }

  let actionTitle = '';
  let actionData = '';
  let bgClassName = '';

  switch (action) {
    case ADDRESS_UPDATED:
      actionTitle = fmt({ id: 'action_center.address_updated' });
      actionData = fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      });
      bgClassName = 'bg-blue';
      break;

    case UPDATED_DELIVERY_DETAILS:
      actionTitle = fmt({ id: 'action_center.order_details_updated' });
      actionData = fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      });
      bgClassName = 'bg-blue';
      break;

    case RESCHEDULED:
      actionTitle = fmt({ id: 'orders.order_new_statues.rescheduled' });
      actionData = dayjs(delivery.scheduledAt).format('dddd, DD MMM YYYY');
      bgClassName = 'bg-green';
      break;

    case RESCHEDULED_TOMORROW:
      actionTitle = fmt({ id: 'hubs.packages_debrief.tomorrow' });
      bgClassName = 'bg-gray';
      break;

    case CANCEL_ORDER:
      actionTitle = fmt({ id: 'action_center.customer_request_to_cancel' });
      bgClassName = 'bg-red';
      break;

    case RESTORED_DELIVERY:
      actionTitle = fmt({ id: 'action_center.deleted_order' });
      actionData = fmt({ id: 'action_center.make_it_rto' });
      bgClassName = 'bg-red';
      break;

    default:
      break;
  }

  openModal(BRScanningPopup, {
    actionTitle,
    actionData,
    bgClassName
  });
};

export const getCurrentUserHubInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  return userInfo?.warehouseInfo;
};

export const getVendorHubId = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
    userInfo?.vendor?.hubId
  );
};

const BULK_UPLOAD_DEBRIEF_CASH_SHEET_COLUMNS = [
  'Star ID',
  'Deposit Type',
  'Receipt No.',
  'Receipt Date',
  'Amount'
];
const getJsDateFromExcel = (excelDate) => {
  // excel file parse any date and send it as seconds from 1990
  const secondsInDay = 24 * 60 * 60;
  const missingLeapYearDay = secondsInDay * 1000;
  const magincNumberOfDays = 25567 + 2;
  if (!Number(excelDate)) {
    alert('wrong input format');
  }

  const delta = excelDate - magincNumberOfDays;
  const parsed = delta * missingLeapYearDay;
  const date = new Date(parsed);

  return date;
};

const constructBulkUploadDebreifCashPayload = (row) => {
  return {
    starId: row[0]?.toString().trim(),
    depositType: row[1]?.toString().trim(),
    receiptNo: row[2]?.toString().trim(),
    receiptDate: row[3]
      ? dayjs(getJsDateFromExcel(row[3])).format('YYYY-MM-DD')
      : '',
    amount: row[4]?.toString().trim()
  };
};

export const convertExcelBulkDebriefCashToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('debriefCash');

        const sheetAdjusment = sheet.usedRange().value().slice(1);
        const sheetWithoutHeaders = sheet.usedRange().value();

        if (
          JSON.stringify(sheetWithoutHeaders[0]) !==
          JSON.stringify(BULK_UPLOAD_DEBRIEF_CASH_SHEET_COLUMNS)
        ) {
          reject({
            message: 'Unsupported Template!'
          });
        }

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructBulkUploadDebreifCashPayload);
        resolve(adjusments);
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};

export const validateMultiPackageLastScan = (delivery) => {
  const { multiPackages, scannedTimes } = delivery || {};

  if (multiPackages) {
    return multiPackages === scannedTimes;
  }

  return true;
};

export const generateCounterOptions = (length) => {
  const options = [];
  for (let i = 0; i < length; i++) {
    options.push({ value: i + 1, label: i + 1 });
  }
  return options;
};

export const getAllowedTransferHubsIds = ({
  destinationHub,
  sourceHubId,
  allHubs
}) => {
  if (!destinationHub || !allHubs) return [];

  const {
    allowedTransfersHubIds: destinationAllowedHubIds = [],
    _id: destinationHubId
  } = destinationHub;

  const validHubIds = allHubs
    .filter(
      ({ _id, type }) =>
        ![SORTING_HUB, FIRST_MILE_HUB].includes(type) &&
        _id !== sourceHubId &&
        destinationAllowedHubIds.includes(_id)
    )
    .map(({ allowedTransfersHubIds }) => allowedTransfersHubIds);

  const combinedHubIds = [
    ...destinationAllowedHubIds,
    ...validHubIds.flat(),
    destinationHubId
  ];

  return [...new Set(combinedHubIds)];
};
