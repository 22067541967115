import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  OTP,
  RETURN_GROUPS_STATE,
  RETURN_GROUPS_STATE_COLORS
} from 'constants/route';
import {
  EXCEPTION_STATE_CODE,
  OFD_STATE_CODE,
  ONHOLD_STATE_CODES,
  ORDER_TYPES_STRING,
  REJECTED_RETURN_STATE_CODES,
  RETURNED_STATE_CODE,
  RETURN_REJECTED_EXCEPTION_CODES,
  TICKET_LINK
} from 'constants/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { exceptionsMapping } from 'constants/hubs';
import { getReturnManifestDetails } from 'services/routes-form';

import Container from 'components/Container/index';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRHeader from 'components/BRHeader/BRHeader';
import ReturnManifestHeader from 'components/Routes/ReturnManifest/components/ReturnManifestHeader/ReturnManifestHeader';
import ReturnManifestInfoCard from 'components/Routes/ReturnManifest/components/ReturnManifestInfoCard/ReturnManifestInfoCard';
import BRImage from 'components/BRImage/BRImage';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import './ReturnManifest.less';

const ReturnManifest = ({
  intl,
  history,
  match: {
    params: { routeId, returnManifestId }
  }
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [returnGroupData, setReturnGroupData] = useState({});

  useEffect(() => {
    fetchReturnManifestDetails();
  }, []);

  const fetchReturnManifestDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getReturnManifestDetails({
        routeId,
        returnManifestId
      });

      setReturnGroupData(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const formatReturnInfoData = () => {
    const { business, businessAddress, returnGroupDetails, createdAtDate } =
      returnGroupData;

    return [
      {
        title: intl.formatMessage({
          id: 'business_details.business_info.business_name'
        }),
        value: business?.name
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.return_info.total_returns'
        }),
        value: returnGroupDetails?.deliveries?.length
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.return_info.out_for_return_date'
        }),
        value: dayjs(createdAtDate).format('ddd, D MMM YYYY')
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.return_info.return_date'
        }),
        value: dayjs(createdAtDate).format('ddd, D MMM YYYY')
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.return_info.business_address'
        }),
        value: `${businessAddress?.firstLine} - ${businessAddress?.zone?.name} - ${businessAddress?.district?.name} - ${businessAddress?.city?.name}`
      }
    ];
  };

  const formatStarInfoData = () => {
    const { routeInfo: { star, routeId, warehouse } = {} } = returnGroupData;

    return [
      {
        title: intl.formatMessage({
          id: 'return_manifest.route_info.star_id'
        }),
        value: star?._id
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.route_info.star_name'
        }),
        value: star?.name
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.route_info.star_phone'
        }),
        value: star?.phone
      },
      {
        title: intl.formatMessage({
          id: 'stars.create_edit_star.form_labels.hub'
        }),
        value: warehouse?.name
      },
      {
        title: intl.formatMessage({
          id: 'return_manifest.route_info.route_id'
        }),
        value: routeId
      }
    ];
  };

  const formatProofOfReturnData = () => {
    const { proofOfReturnDetails: { proofOfReturnType, proofOfReturn } = {} } =
      returnGroupData;

    return proofOfReturnType === OTP
      ? [
          {
            title: intl.formatMessage({
              id: 'return_manifest.proof_of_return.proof_of_return_type'
            }),
            value: proofOfReturnType
          },
          {
            title: intl.formatMessage({
              id: 'return_manifest.proof_of_return.contact_name'
            }),
            value: proofOfReturn?.returnContactPersonName
          },
          {
            title: intl.formatMessage({
              id: 'return_manifest.proof_of_return.contact_phone'
            }),
            value: proofOfReturn?.returnContactPersonPhone
          },
          ,
          {
            title: intl.formatMessage({
              id: 'return_manifest.proof_of_return.otp'
            }),
            value: proofOfReturn?.confirmationOTP
          }
        ]
      : [
          {
            title: intl.formatMessage({
              id: 'return_manifest.proof_of_return.proof_of_return_type'
            }),
            value: proofOfReturnType
          },
          {
            value: (
              <Card
                title={intl.formatMessage({
                  id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.signature'
                })}
              >
                <BRImage src={proofOfReturn?.signaturePhoto} preview />
              </Card>
            )
          },
          {
            value: (
              <Card
                title={intl.formatMessage({
                  id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.national_id'
                })}
              >
                <BRImage src={proofOfReturn?.rtoNationalIdPhoto} preview />
              </Card>
            )
          }
        ];
  };

  const getReturnGroupState = () => {
    const { returnGroupDetails: { deliveries = [] } = {} } = returnGroupData;

    if (
      deliveries.every(
        (delivery) =>
          REJECTED_RETURN_STATE_CODES.includes(delivery.state.code) &&
          RETURN_REJECTED_EXCEPTION_CODES.includes(
            delivery.state?.lastExceptionCode
          )
      )
    ) {
      return {
        stateName: RETURN_GROUPS_STATE.REJECTED,
        stateColor: RETURN_GROUPS_STATE_COLORS.REJECTED
      };
    }

    if (
      deliveries.some(
        (delivery) =>
          REJECTED_RETURN_STATE_CODES.includes(delivery.state.code) &&
          RETURN_REJECTED_EXCEPTION_CODES.includes(
            delivery.state?.lastExceptionCode
          )
      ) &&
      deliveries.every((delivery) => delivery.state.code !== OFD_STATE_CODE)
    ) {
      return {
        stateName: RETURN_GROUPS_STATE.PARTIALLY_RETURNED,
        stateColor: RETURN_GROUPS_STATE_COLORS.PARTIALLY_RETURNED
      };
    }

    if (
      deliveries.every(
        (delivery) => delivery.state.code === EXCEPTION_STATE_CODE
      )
    ) {
      return {
        stateName: RETURN_GROUPS_STATE.EXCEPTION,
        stateColor: RETURN_GROUPS_STATE_COLORS.EXCEPTION
      };
    }

    if (
      deliveries.every(
        (delivery) => delivery.state.code === RETURNED_STATE_CODE
      )
    ) {
      return {
        stateName: RETURN_GROUPS_STATE.RETURNED,
        stateColor: RETURN_GROUPS_STATE_COLORS.RETURNED
      };
    }

    return {
      stateName: RETURN_GROUPS_STATE.OUT_FOR_RETURN,
      stateColor: RETURN_GROUPS_STATE_COLORS.OUT_FOR_RETURN
    };
  };

  const getOrderType = (type) => {
    if (type === ORDER_TYPES_VALUE.EXCHANGE) {
      return ORDER_TYPES_STRING.EXCHANGE_RETURN;
    }
    if (type === ORDER_TYPES_VALUE.CRP) {
      return ORDER_TYPES_STRING.CRP_RETURN;
    }

    return ORDER_TYPES_STRING[type];
  };

  const getOrderState = (state, delivery) => {
    if (
      REJECTED_RETURN_STATE_CODES.includes(delivery.state.code) &&
      RETURN_REJECTED_EXCEPTION_CODES.includes(state?.lastExceptionCode)
    ) {
      return (
        <>
          {intl.formatMessage({
            id: 'return_manifest.return_group_states.rejected'
          })}{' '}
          - {exceptionsMapping(delivery)[state.lastExceptionCode]}
        </>
      );
    }

    if (state.code === EXCEPTION_STATE_CODE) {
      return `${intl.formatMessage({ id: 'deliveries.states.exception' })} - ${
        exceptionsMapping(delivery)[state.lastExceptionCode]
      }`;
    }

    if (
      state.code === RETURNED_STATE_CODE &&
      delivery.type === ORDER_TYPES_VALUE.EXCHANGE
    ) {
      return intl.formatMessage({ id: 'deliveries.states.exchanged_returned' });
    }

    if (
      state.code === RETURNED_STATE_CODE &&
      delivery.type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
    ) {
      return intl.formatMessage({
        id: 'return_manifest.return_group_states.signed_and_returned'
      });
    }

    if (
      state.code === RETURNED_STATE_CODE &&
      delivery.type === ORDER_TYPES_VALUE.CRP
    ) {
      return intl.formatMessage({ id: 'deliveries.states.returned' });
    }

    if (state.code === RETURNED_STATE_CODE) {
      return intl.formatMessage({ id: 'deliveries.states.returned_to_origin' });
    }

    if (ONHOLD_STATE_CODES.includes(state.code)) {
      return intl.formatMessage({ id: 'deliveries.states.on_hold' });
    }

    return intl.formatMessage({ id: 'deliveries.states.out_for_return' });
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.header.tracking_number'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber, record) => (
        <Link target="_blank" to={`/deliveries/${record._id}/details`}>
          {trackingNumber}
        </Link>
      )
    },
    {
      title: intl.formatMessage({
        id: 'dispatching.table_columns.order_type'
      }),
      dataIndex: 'type',
      render: (type) => getOrderType(type)
    },
    {
      title: intl.formatMessage({ id: `hubs.packages_debrief.columns.state` }),
      dataIndex: 'state',
      render: (state, record) => getOrderState(state, record)
    }
  ];

  const getData = async () => {
    return {
      list: returnGroupData?.returnGroupDetails?.deliveries,
      total: returnGroupData?.returnGroupDetails?.deliveries?.length
    };
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title={intl.formatMessage({ id: 'return_manifest.title' })}
          />
        }
        content={
          <div className="br-return-manifest__container">
            <ReturnManifestHeader
              returnManifestId={returnManifestId}
              stateInfo={getReturnGroupState()}
            />
            <ReturnManifestInfoCard
              title={intl.formatMessage({
                id: 'return_manifest.return_info.title'
              })}
              infoData={formatReturnInfoData()}
            />
            <ReturnManifestInfoCard
              title={intl.formatMessage({
                id: 'return_manifest.proof_of_return.title'
              })}
              infoData={formatProofOfReturnData()}
            />
            <ReturnManifestInfoCard
              title={intl.formatMessage({
                id: 'return_manifest.route_info.title'
              })}
              infoData={formatStarInfoData()}
            />
            {!!returnGroupData?.returnGroupDetails?.deliveries?.length && (
              <BRTable
                columns={columns}
                title={intl.formatMessage(
                  { id: 'return_manifest.returns' },
                  {
                    count:
                      returnGroupData?.returnGroupDetails?.deliveries?.length
                  }
                )}
                onRowClick={() => {}}
                listFunction={getData}
                showSearchFilter={false}
                showFilter={false}
              />
            )}
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default withRouter(injectIntl(ReturnManifest));
