import { useEffect, useRef, useState } from 'react';
import { Form, Collapse, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { fetchCities } from 'services/cities';
import { COUNTRIES } from 'constants/country-data';
import { cleanEmptyString, trimObjectStrings } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './VendorsSearch.less';

const VendorsSearch = ({ handleOnSubmit }) => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();

  const intl = useIntl();

  const [form] = Form.useForm();
  const countryId = Form.useWatch('countryId', form);

  useEffect(() => {
    if (countryId) {
      getCities();
    } else {
      setCities([]);
    }
  }, [countryId]);

  const getCities = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCities(countryId);
      setCities(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onFinish = (values) => {
    handleOnSubmit(trimObjectStrings(cleanEmptyString(values)));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleOnSubmit({});
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <Icon
          component={DropDownIcon}
          rotate={isActive ? 0 : -90}
          className="ant-icon-xl"
        />
      )}
    >
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'common.search'
        })}
      >
        <Form onFinish={onFinish} ref={formRef} form={form}>
          <div className="br-filters-search__row">
            <Form.Item
              name="vendorId"
              label={intl.formatMessage({
                id: 'vendors.vendor_id_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'vendors.vendor_id_label'
                })}
              />
            </Form.Item>
            <Form.Item
              name="companyName"
              label={intl.formatMessage({
                id: 'vendors.company_name_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'vendors.company_name_label'
                })}
              />
            </Form.Item>
            <Form.Item
              name="countryId"
              label={intl.formatMessage({
                id: 'vendors.country_label'
              })}
            >
              <Select
                options={COUNTRIES}
                allowClear
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
            <Form.Item
              name="cityId"
              label={intl.formatMessage({
                id: 'businesses.search_filters.city'
              })}
            >
              <Select
                allowClear
                showSearch
                options={cities}
                disabled={!cities.length || isLoading}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'businesses.search_filters.city'
                })}
              />
            </Form.Item>
          </div>
          <div className="br-vendors-search__actions">
            <BRButton
              type="primary"
              htmlType="submit"
              label={intl.formatMessage({ id: 'common.search' })}
            />
            <BRButton
              label={intl.formatMessage({ id: 'common.clear' })}
              onClick={handleResetFilters}
            />
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default VendorsSearch;
