import messages from 'messages';
import { LOCALE, LOCAL_STORAGE_LOCALE_KEY } from 'constants/intl-wrapper';

export const flattenMessages = (nestedMessages = messages.en, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export function getLocaleFromLocalStorage() {
  return localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || LOCALE.EN;
}

export function setLocaleInLocalStorage(locale) {
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
}

export function changeDocumentDirection(locale) {
  if (locale === 'ar') {
    document.dir = 'rtl';
  } else {
    document.dir = 'ltr';
  }
}
