import { Modal, Button } from 'antd';
import { injectIntl } from 'react-intl';

import { printAirwaybill } from 'services/shipments';
import { actionSubmit } from 'services/action-center';
import { showBill } from 'utils';
import { openModal } from 'utils/modal';
import { UPDATED_DELIVERY_DETAILS } from 'constants/action-center';

import { notify } from 'components/Notify/Notify';
import PrintConfirmationModal from 'components/ActionCenter/components/OrderActions/components/PrintConfirmationModal/PrintConfirmationModal';

import './TransferToHubErrorModal.less';
import { downloadAsPdf } from 'utils/download';

const TransferToHubErrorModal = ({
  delivery,
  close,
  intl,
  visible,
  onSuccess,
  title
}) => {
  const handleApplyAction = async (actions) => {
    if (actions) {
      const action = actions.filter(
        (item) => item.action === UPDATED_DELIVERY_DETAILS && !item.isApplied
      );
      const payload = {
        isApplied: true
      };
      try {
        await actionSubmit(action[0]._id, payload);
        onSuccess();
      } catch (error) {
        notify(error.message);
      }
    }
  };

  const handlePrintAWB = async () => {
    const { _id, trackingNumber } = delivery;
    try {
      const { data } = await printAirwaybill(_id);
      downloadAsPdf(data, trackingNumber);
      close();
      openModal(PrintConfirmationModal, {
        onSuccess: () => handleApplyAction(delivery?.actionItems)
      });
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      wrapClassName="br-error-hub__container"
    >
      <div>
        <h3>{title}</h3>
        <div className="br-error-hub__container__btn display-flex ">
          <Button
            className="br-error-hub__container__btn__reschedule-cancel-button"
            onClick={close}
          >
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button
            className="br-error-hub__container__btn__printAWB"
            onClick={handlePrintAWB}
          >
            {intl.formatMessage({
              id: 'action_center.order_details.action_notes.print_awb'
            })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(TransferToHubErrorModal);
