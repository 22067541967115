import dayjs from 'dayjs';

import { SLLR_DATE_FILTER_FORMAT } from 'constants/sllr-analytics';

import BRDatePicker from 'components/BRDatePicker/BRDatePicker';

const CashierDateFilter = (props) => {
  const disabledDate = (current) => {
    return current && current > dayjs();
  };
  return (
    <BRDatePicker
      {...props}
      disabledDate={disabledDate}
      getPopupContainer={(trigger) =>
        trigger.parentElement.parentElement.parentElement.parentElement
          .parentElement
      }
      getFilterData={() => {}}
      dateRangeToDate
      fixedLabel
      label={`${dayjs(props?.filterData?.createdAtStart).format(
        SLLR_DATE_FILTER_FORMAT
      )} -
            ${dayjs(props?.filterData?.createdAtEnd).format(
              SLLR_DATE_FILTER_FORMAT
            )}
          `}
      datePickerValue={[
        dayjs(props?.filterData?.createdAtStart),
        dayjs(props?.filterData?.createdAtEnd)
      ]}
    />
  );
};

export default CashierDateFilter;
