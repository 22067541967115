import http from 'utils/http.js';
import { getCurrentUserCountryId } from 'common/countries/countries-mapping';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const currentUserCountryCode = userInfo?.country?.code;

export const toggleCOD = () => {
  return http.put(`users/toggle-COD`);
};

export const addTimeSlot = (payload) => {
  return http.post(`/time-slots`, payload);
};

export const getUserTimeSlots = () => {
  return http.get(`/time-slots`);
};

export const removeTimeSlot = (id) => {
  return http.delete(`/time-slots/${id}`);
};

export const getOfficialHolidays = ({
  payload,
  countryCode = currentUserCountryCode
} = {}) => {
  return http.get(`/sla/holidays/?countryCode=${countryCode}`, payload);
};

export const setCountryConfigurations = ({ countryId, payload }) => {
  return http.put(`/cities/country/${countryId}`, payload);
};

export const getCountryConfiguration = ({ countryId }) => {
  return http.get(`/cities/country/${countryId}`);
};

export const addHoliday = (payload) => {
  return http.post('/sla/holidays', payload);
};

export const getCountryTimezone = (countryId = getCurrentUserCountryId()) => {
  return http.get(`/cities/country/time-zone/${countryId}`);
};

export const deleteHoliday = (id) => {
  return http.delete(`/sla/holidays/${id}`);
};
