import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import {
  INITIAL_ROUTES_LISTING_FILTERS,
  ROUTES_TABLE_COLUMNS,
  ROUTE_EXPORT_TYPE,
  ROUTE_STATES_TAG_COLOR
} from 'constants/route';
import { STAR_AVAILABILITY_STATE } from 'constants/stars';
import { getRoutes } from 'services/hubs';
import { exportRoutes } from 'services/routes-form';
import { fetchStars } from 'services/live-ops-priorities';
import { formatOptions } from 'utils';

import RoutesTableActions from './components/RoutesTableActions/RoutesTableActions';
import BRTable from 'components/BRTable/BRTable';
import BRHeader from 'components/BRHeader/BRHeader';

import BRTag from 'components/BRTag/BRTag';
import Container from 'components/Container/index';
import RoutesSearch from './components/RoutesSearch/RoutesSearch';
import { notify } from 'components/Notify/Notify';

import './RoutesListing.less';

const RoutesListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [routesCount, setRoutesCount] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState(
    INITIAL_ROUTES_LISTING_FILTERS
  );
  const [onlineStars, setOnlineStars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const refreshTable = useRef();

  const intl = useIntl();

  useEffect(() => {
    getStars();
  }, []);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleRefreshTable = () => {
    refreshTable.current({
      pageNumber: currentPage,
      filterValues: selectedFilters
    });
  };

  const fetchRoutes = async ({ page, filterValues }) => {
    setIsLoading(true);
    try {
      const payload = {
        ...(filterValues ? { ...filterValues } : { ...selectedFilters }),
        pageId: page - 1
      };

      const { result, totalCount } = await getRoutes(payload);
      setRoutesCount(totalCount);
      setCurrentPage(page);
      setIsLoading(false);

      return {
        list: formatRoutes(result),
        total: totalCount
      };
    } catch (error) {
      setIsLoading(false);
      notify(error.message);
    }
  };

  const getStars = async () => {
    setIsLoading(true);
    try {
      const { message } = await fetchStars();

      const availableStars = message?.stars.filter(
        (star) =>
          star.starInfo.availabilityState === STAR_AVAILABILITY_STATE.ONLINE
      );

      setOnlineStars(
        formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          availableStars || []
        )
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOnSubmit = (values) => {
    setSelectedFilters(values);
    refreshTable.current({ pageNumber: 1, filterValues: values });
  };

  const formatRoutes = (data) => {
    return data.map(
      ({
        _id,
        createdAt,
        name,
        deliveryIds,
        pickupRequestIds,
        state,
        updatedAt,
        warehouse,
        ...rest
      }) => {
        return {
          _id,
          key: _id,
          createdAt: dayjs(createdAt).tz().format('LLL'),
          routeAge: intl.formatMessage(
            { id: 'common.timer.hours' },
            {
              hours: Math.floor(
                dayjs.duration(dayjs().diff(dayjs(createdAt))).as('hours')
              )
            }
          ),
          lastUpdatedAt: intl.formatMessage(
            { id: 'common.timer.hours' },
            {
              hours: Math.floor(
                dayjs.duration(dayjs().diff(dayjs(updatedAt))).as('hours')
              )
            }
          ),
          name,
          warehouseName: warehouse?.name,
          numberOfDeliveries: deliveryIds.length,
          numberOfPickups: pickupRequestIds.length,
          state,
          stateTag: (
            <BRTag color={ROUTE_STATES_TAG_COLOR[state]}>{state}</BRTag>
          ),
          ...rest
        };
      }
    );
  };

  const renderTableActions = (record) => (
    <RoutesTableActions
      {...record}
      handleRefreshTable={handleRefreshTable}
      onlineStars={onlineStars}
      setIsLoading={setIsLoading}
    />
  );

  const handleExport = async () => {
    if (isEmpty(selectedFilters)) {
      return notify(intl.formatMessage({ id: 'common.export_error' }));
    }

    try {
      const { message } = await exportRoutes({
        exportType: ROUTE_EXPORT_TYPE,
        payload: { ...selectedFilters }
      });
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'routes.routes_listing.title'
          })}
        />
      }
      content={
        <div className="br-routes-listing__container">
          <RoutesSearch handleOnSubmit={handleOnSubmit} isLoading={isLoading} />
          <BRTable
            title={intl.formatMessage(
              { id: 'routes.routes_listing.table_title' },
              {
                count: routesCount
              }
            )}
            listFunction={fetchRoutes}
            columns={ROUTES_TABLE_COLUMNS(renderTableActions)}
            showFilter={false}
            exportListFileFunction={handleExport}
            shareMethods={acceptMethods}
          />
        </div>
      }
    />
  );
};

export default RoutesListing;
