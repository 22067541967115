import { useEffect, useState } from 'react';
import { Select, Form, Switch } from 'antd';
import { injectIntl } from 'react-intl';

import {
  getAdminGroup,
  getAdminGroups,
  getSystemPermissions,
  updateAdminGroupAccess
} from 'services/roles-permissions';
import { openModal } from 'utils/modal';
import { isUserAuthorized } from 'utils/helpers';
import { SUPER_ADMIN } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import SpecialPermissionsSelect from './component/SpecialPermissionsSelect/SpecialPermissionsSelect';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import CloneAdminGroupModal from '../CloneAdminGroupModal';

import './Permissions.less';

const Permissions = ({ intl }) => {
  const [adminGroups, setAdminGroups] = useState([]);
  const [systemModules, setSystemModules] = useState([]);
  const [systemPermissions, setSystemPermissions] = useState([]);
  const [selectedAdminGroup, setSelectedAdminGroup] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [groupSpecialPermissions, setGroupSpecialPermissions] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAdminGroups();
    fetchSystemPermissions();
  }, []);

  const handleUpdatePermissions = async () => {
    setIsLoading(true);
    try {
      const payload = {
        ...(!isSuperAdmin && {
          specialPermissions: groupSpecialPermissions
        }),
        isSuperAdmin
      };

      await updateAdminGroupAccess(selectedAdminGroup, payload);
      notify(
        intl.formatMessage({ id: 'roles_permissions.update_success' }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const fetchAdminGroups = async () => {
    try {
      const { data } = await getAdminGroups();

      const formattedAdminGroups = data?.list?.map((adminGroup) => ({
        value: adminGroup._id,
        label: adminGroup.name
      }));

      setAdminGroups(formattedAdminGroups);
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchSystemPermissions = async () => {
    try {
      const { data } = await getSystemPermissions();
      if (data) {
        const systemModules = Object.keys(data);

        setSystemPermissions(
          systemModules.reduce(
            (acc, group) => ({ ...acc, [group]: Object.keys(data[group]) }),
            {}
          )
        );
        setSystemModules(
          systemModules.map((systemModule) => ({
            label: systemModule,
            value: systemModule
          }))
        );
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handleAdminGroupChange = async (value) => {
    setIsLoading(true);

    setSelectedAdminGroup(value);

    try {
      const { data } = await getAdminGroup(value);
      setGroupSpecialPermissions(data.specialPermissions);
      setIsSuperAdmin(data.isSuperAdmin);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleSpecialPermissionsChange = ({ itemToUpdate, value }) => {
    setGroupSpecialPermissions((prev) => ({
      ...prev,
      [selectedModule]: { ...prev?.[selectedModule], [itemToUpdate]: value }
    }));
  };

  const handleCloneClick = () => {
    openModal(CloneAdminGroupModal, {
      adminGroups: adminGroups
    });
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Form>
        <div className="br-permissions__form-items">
          <Form.Item
            label={intl.formatMessage({ id: 'roles_permissions.admin_group' })}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={adminGroups}
              onChange={handleAdminGroupChange}
              placeholder={intl.formatMessage({
                id: 'roles_permissions.admin_group_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'roles_permissions.module' })}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={systemModules}
              disabled={!selectedAdminGroup || isSuperAdmin}
              onChange={setSelectedModule}
              placeholder={intl.formatMessage({
                id: 'roles_permissions.module_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'roles_permissions.special_permissions'
            })}
          >
            <SpecialPermissionsSelect
              list={systemPermissions[selectedModule]}
              values={groupSpecialPermissions?.[selectedModule]}
              setValues={handleSpecialPermissionsChange}
              disabled={!selectedModule || isSuperAdmin}
            />
          </Form.Item>
        </div>
        <div className="br-permissions-super-admin__toggle body-medium">
          <Switch
            checked={isSuperAdmin}
            onChange={setIsSuperAdmin}
            disabled={!selectedAdminGroup}
          />
          {intl.formatMessage({ id: 'roles_permissions.act_as_super_admin' })}
        </div>
        <div className="br-permissions__actions">
          <BRButton
            type="primary"
            label="Save Changes"
            onClick={handleUpdatePermissions}
          />
        </div>
      </Form>
      {isUserAuthorized([SUPER_ADMIN], [ACL_MATRIX.ADMIN_GROUPS_CREATE]) && (
        <div className="br-permissions-close-admin-group__section">
          <div className="br-permissions-close-admin-group__title heading">
            {intl.formatMessage({
              id: 'roles_permissions.create_new_admin_group'
            })}
          </div>
          <BRButton
            type="primary"
            onClick={handleCloneClick}
            label={intl.formatMessage({ id: 'roles_permissions.clone' })}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};

export default injectIntl(Permissions);
