import http from 'utils/http';
import { headerDownload } from 'utils';

export const fetchCapitalInfo = (page, limit, payload) => {
  return http.post(
    `/borrow/businesses?pageNumber=${page}&pageSize=${limit}`,
    payload
  );
};

export const exportCapitalInfo = (payload) => {
  return http.post(`/borrow/businesses/export`, payload);
};

export const updateBusinessCapitalStatus = (payload) => {
  return http.put(`/borrow/status`, payload);
};

export const downloadCapitalLegalDocuments = (businessId, payload) => {
  return http.get(
    `/borrow/business/${businessId}/legal-docs`,
    payload,
    headerDownload
  );
};

export const getCapitalSettings = () => {
  return http.get(`/borrow/capital/configurations`);
};

export const updateCapitalSetting = (payload) => {
  return http.put(`/borrow/capital/configurations`, payload);
};
