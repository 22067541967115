import { fmt } from 'IntlWrapper/IntlWrapper';

export const PROMOTION_TYPES_MAPPING = {
  SALES_PROMOTION: fmt({
    id: 'promotions.promotion_types.sales_promotion'
  }),
  DIRECT_MARKETING_PROMOTION: fmt({
    id: 'promotions.promotion_types.direct_marketing_promotion'
  }),
  PUBLIC_RELATION_PROMOTION: fmt({
    id: 'promotions.promotion_types.public_relation_promotion'
  }),
  ADVERTISING_PROMOTION: fmt({
    id: 'promotions.promotion_types.advertising_promotion'
  })
};

export const PROMOTION_SUBTYPES_MAPPING = {
  PERCENTAGE_SALES: fmt({
    id: 'promotions.promotion_subtypes.percentage_sales'
  }),
  SEASONAL: fmt({
    id: 'promotions.promotion_subtypes.seasonal'
  }),
  REFERRAL: fmt({
    id: 'promotions.promotion_subtypes.referral'
  }),
  LOYALTY: fmt({
    id: 'promotions.promotion_subtypes.loyalty'
  }),
  CAPPED_VOLUME_PROMOTION: fmt({
    id: 'promotions.promotion_subtypes.capped_volume'
  }),
  DURATION_PROMOTION: fmt({
    id: 'promotions.promotion_subtypes.duration'
  }),
  FREE_SHIPPING: fmt({
    id: 'promotions.promotion_subtypes.free_shipping'
  })
};

export const DEFAULT_TYPE = 'SALES_PROMOTION';

export const DEFAULT_SUBTYPE = 'PERCENTAGE_SALES';

export const PROMOTION_SUBTYPES = [
  {
    label: fmt({
      id: 'promotions.promotion_subtypes.duration'
    }),
    value: 'DURATION_PROMOTION'
  },
  {
    label: fmt({
      id: 'promotions.promotion_subtypes.capped_volume'
    }),
    value: 'CAPPED_VOLUME_PROMOTION'
  }
];

export const TARGET_TYPES = [
  {
    label: fmt({
      id: 'promotions.target_types.domestic'
    }),
    value: 'DOMESTIC'
  },
  {
    label: fmt({
      id: 'promotions.target_types.international'
    }),
    value: 'INTERNATIONAL'
  }
];

export const TARGET_TYPES_MAPPING = {
  DOMESTIC: fmt({
    id: 'promotions.target_types.domestic'
  }),
  INTERNATIONAL: fmt({
    id: 'promotions.target_types.international'
  })
};

export const VALUE_TYPES = [
  {
    label: fmt({
      id: 'promotions.modal.percentage'
    }),
    value: 'PERCENTAGE'
  },
  {
    label: fmt({
      id: 'promotions.modal.fixed'
    }),
    value: 'FIXED_PROMOTION_VALUE_TYPE'
  }
];
