import http from 'utils/http.js';

export const getSecurityTypeInfo = (payload) => {
  return http.get(`/users/2fa/setup`, payload);
};

export const setupSecurityType = (payload) => {
  return http.post(`/users/2fa/setup`, payload);
};

export const disable2FA = (twoFAType) => {
  return http.delete(`/users/2fa/setup?twoFAType=${twoFAType}`);
};

export const verify2FASetup = (payload) => {
  return http.post(`/users/2fa/verify`, payload);
};

export const changePassword = (payload) => {
  return http.put('/users/change-password', payload);
};

export const forgetPassword = (payload) => {
  return http.post('/users/forgot', payload);
};

export const resetPassword = ({ password, token }) => {
  return http.post(`/users/reset/${token}`, { password });
};

export const login = (payload) => {
  return http.post('/users/login', payload);
};
