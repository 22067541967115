import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import {
  exportProblematicJobs,
  getProblematicJobs
} from 'services/fulfillment-problematic-jobs';
import { downloadAsXlsx } from 'utils';
import {
  getSerialForScan,
  getSkuForScan
} from 'constants/fulfillment-sales-orders';
import {
  FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH,
  PROBLEMATIC_JOBS_OPTIONS_IDS,
  PROBLEMATIC_JOBS_SEARCH_OPTIONS,
  renderProblematicJobTag
} from 'constants/fulfillment-problematic-jobs';
import { SllrWrapper } from 'contexts/sllr.context';

import {
  formatDateTime,
  TABLE_PAGE_SIZE_OPTIONS
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

const ProblematicJobsTable = ({
  intl,
  tableFilters,
  isFirstFetchDone,
  setIsFirstFetchDone
}) => {
  const { setIsLoading } = useContext(SllrWrapper);
  const [tableItemsCount, setTableItemsCount] = useState(0);
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    PROBLEMATIC_JOBS_OPTIONS_IDS.SERIAL.value
  );
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const refreshTable = useRef();

  const columns = [
    {
      dataIndex: 'id',
      title: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.problematic_jobs_table.columns.job_id'
      }),
      render: (id) => (
        <div className="br-fulfillment-returns-table__tracking-number-col">
          <Link
            target="_blank"
            to={`${FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH}/${id}`}
          >
            {intl.formatMessage(
              {
                id: 'fulfillment_common.pj_id'
              },
              {
                pjId: id
              }
            )}
          </Link>
        </div>
      )
    },
    {
      dataIndex: 'businessName',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.business_name'
      })
    },
    {
      dataIndex: 'totalQuantity',
      title: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.problematic_jobs_table.columns.serials'
      })
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.status'
      }),
      render: renderProblematicJobTag
    },
    {
      dataIndex: 'createdAt',
      title: intl.formatMessage({
        id: `fulfillment_returns.return_orders_table.columns.created_at`
      }),
      render: (createdAt) => {
        const { formattedDate, formattedTime } = formatDateTime(createdAt);

        return `${formattedDate}, ${formattedTime}`;
      }
    }
  ];

  const handleGetTableData = async ({ page, limit, searchInputText }) => {
    setIsLoading(true);
    let value = searchInputText?.trim();
    const { SERIAL, SKU } = PROBLEMATIC_JOBS_OPTIONS_IDS;
    switch (selectedSearchOption) {
      case SERIAL.value:
        value = getSerialForScan(value);
        break;

      case SKU.value:
        value = getSkuForScan(value);
        break;

      default:
        break;
    }

    setSearchValue(value);

    const payload = omitBy(
      {
        page,
        limit,
        ...tableFilters
      },
      isNull
    );

    if (value) {
      payload[PROBLEMATIC_JOBS_OPTIONS_IDS[selectedSearchOption].apiKey] =
        value;
    }

    setSharedPayload(payload);
    setIsFirstFetchDone(true);

    try {
      const {
        data: { problematicJobs = [], count = 0 }
      } = await getProblematicJobs(payload);
      setTableItemsCount(count);
      setIsLoading(false);

      return {
        list: problematicJobs,
        total: count
      };
    } catch (error) {
      notify(error.message);
      setIsLoading(false);
    }
  };

  const handleExportData = async () => {
    setIsLoading(true);
    try {
      const data = await exportProblematicJobs(sharedPayload);
      const fileName = `Problematic_Jobs_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (isFirstFetchDone && refreshTable?.current) {
      updateTable();
    }
  }, [tableFilters]);

  return (
    <BRTable
      className="br-fulfillment-table br-fulfillment-problematic-jobs-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_problematic_jobs.problematic_jobs_table.title'
        },
        {
          count: tableItemsCount
        }
      )}
      columns={columns}
      listFunction={handleGetTableData}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage(
        {
          id: 'fulfillment_returns.return_orders_table.search_placeholder'
        },
        {
          searchBy: PROBLEMATIC_JOBS_OPTIONS_IDS[selectedSearchOption].label
        }
      )}
      exportListFileFunction={handleExportData}
      showFilter={false}
      showSearch
      searchOptions={PROBLEMATIC_JOBS_SEARCH_OPTIONS}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
    />
  );
};

export default injectIntl(ProblematicJobsTable);
