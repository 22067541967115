import {
  ALLOWANCE_REASONS,
  BONUS_REASONS,
  DEDUCTION_REASONS
} from 'constants/bonuses-deductions';

export const getTypeReasons = (type) => {
  switch (type) {
    case 0:
      return BONUS_REASONS;
    case 1:
      return DEDUCTION_REASONS;
    case 2:
      return ALLOWANCE_REASONS;
  }
};

export const getExtension = (filename) => {
  var parts = filename.split('.');
  return parts[parts.length - 1];
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
