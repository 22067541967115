import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './Container.less';

export default function Container({ header, content, className }) {
  return (
    <div className="br-container shadow-pages-bg">
      {header}
      <div
        className={classnames('br-container-content', {
          [className]: !!className
        })}
      >
        {content}
      </div>
    </div>
  );
}

Container.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
};
