import http from 'utils/http';

export const fetchStars = (payload) => {
  return http.get('/users/stars/all', payload);
};

export const fetchLiveOpsPriorityList = (payload) => {
  return http.get('/live-ops-priority-rules', payload);
};

export const addPriority = (payload) => {
  return http.post('/live-ops-priority-rules', payload);
};

export const deletePriority = (payload) => {
  return http.delete(`/live-ops-priority-rules/${payload.id}`);
};

export const updatePriorityWeight = (payload) => {
  return http.put(`/live-ops-priority-rules/${payload.id}`, payload);
};
