import { useState, useCallback } from 'react';
import { Tooltip } from 'antd';

import { getSerialLocation } from 'services/fulfillment-inventory-serials';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as EyeIcon } from 'assets/bosta-icons/Eye.svg';

import './SerialLocationTooltip.less';

const SerialLocationTooltip = ({ serialId }) => {
  const [locationData, setLocationData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchSerialLocationData = useCallback(
    async (open) => {
      if (!open) {
        return;
      }
      setIsLoading(true);
      try {
        const data = await getSerialLocation(serialId);
        setLocationData(data);
      } catch (error) {
        notify(error.message);
      }

      setIsLoading(false);
    },
    [serialId]
  );

  return (
    <Tooltip
      overlayClassName="br-serial-location-tooltip"
      trigger="click"
      color="white"
      title={
        <LoadingWrapper loading={isLoading}>
          <span>{locationData.location}</span>
          <span className="br-serial-location-tooltip__zone">
            {locationData.zone}
          </span>
        </LoadingWrapper>
      }
      onVisibleChange={serialId && fetchSerialLocationData}
    >
      <span className="br-serial-location__btn">
        <EyeIcon />
      </span>
    </Tooltip>
  );
};

export default SerialLocationTooltip;
