import { fmt } from 'IntlWrapper/IntlWrapper';
import { allPendingSum } from 'utils/sorting-facility';
import { DELIVERY_CHILD_STATES, DELIVERY_STATES_CODES } from './shipments';
import { deliveryTypes } from 'containers/Deliveries/constants';

export const TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.business_name'
    }),
    dataIndex: 'business_name'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.order_type'
    }),
    dataIndex: 'order_type'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.order_state'
    }),
    dataIndex: 'order_state'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.picked_up'
    }),
    dataIndex: 'picked_up'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.last_receive'
    }),
    dataIndex: 'last_receive'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.seal_number'
    }),
    dataIndex: 'seal_number'
  }
];

const ORDER_STATE_OPTIONS = [
  {
    label: fmt({
      id: 'ops_control.orders_table.filters.order_state_menu.received_label'
    }),
    value: `${DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE}`
  },
  {
    label: fmt({
      id: 'ops_control.orders_table.filters.order_state_menu.ready_to_dispatch_label'
    }),
    value: `${DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE}&${DELIVERY_CHILD_STATES.READY_TO_DISPATCH}`
  }
];

export const SORTING_FACILITY_FILTERS = (businesses) => [
  {
    label: fmt({ id: 'ops_control.orders_table.filters.order_state' }),
    key: 'orderState',
    type: 'select',
    menu: ORDER_STATE_OPTIONS
  },
  {
    label: fmt({ id: 'ops_control.orders_table.filters.order_type' }),
    key: 'orderType',
    type: 'select',
    menu: deliveryTypes
  },
  {
    label: fmt({ id: 'ops_control.orders_table.filters.business' }),
    key: 'business',
    type: 'dropdown',
    menu: businesses
  }
];

export const SORTING_FACILITY_PENDING_DELIVERIES_COUNT = (
  sortingFacilityData
) => {
  const {
    pending_transfer_deliveries_count,
    ready_to_dispatch_deliveries_count
  } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.dispatching.all_pending' }),
      data: allPendingSum(sortingFacilityData)
    },
    {
      title: fmt({ id: 'ops_control.dispatching.pending_transfer' }),
      data: pending_transfer_deliveries_count || 0
    },
    {
      title: fmt({ id: 'ops_control.dispatching.pending_dispatching' }),
      data: ready_to_dispatch_deliveries_count || 0
    }
  ];
};

export const SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT = (
  sortingFacilityData
) => {
  const { lost_deliveries_count, damaged_deliveries_count } =
    sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.dispatching.lost' }),
      data: lost_deliveries_count || 0
    },
    {
      title: fmt({ id: 'ops_control.dispatching.damaged' }),
      data: damaged_deliveries_count || 0
    }
  ];
};

export const DELIVERIES_TYPE = {
  PENDING: 'pending',
  DAMAGED: 'damaged'
};

export const TO_SORTING_FACILITY_CARDS_IFNO = (sortingFacilityData) => {
  const { incoming_deliveries_count } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.sorting_facility.transferred_from_hubs' }),
      data: incoming_deliveries_count || 0
    }
  ];
};

export const RECEIVED_AT_SF_CARDS_INFO = (sortingFacilityData) => {
  const {
    fresh_deliveries_received_count,
    transferred_deliveries_received_count,
    rto_deliveries_received_count
  } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.sorting_facility.fresh' }),
      data: fresh_deliveries_received_count || 0
    },
    {
      title: fmt({ id: 'ops_control.sorting_facility.transferred' }),
      data: transferred_deliveries_received_count || 0
    },
    {
      title: fmt({ id: 'ops_control.sorting_facility.rto' }),
      data: rto_deliveries_received_count || 0
    }
  ];
};

export const DATE_FORMAT = 'DD/MM/YYYY';
