import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Image, InputNumber } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as HubIcon } from 'assets/bosta-icons/cashier-hub-icon.svg';
import { ReactComponent as DateIcon } from 'assets/bosta-icons/date-icon.svg';
import { ReactComponent as HubsDepositIcon } from 'assets/bosta-icons/hubs-deposit-icon.svg';

import './FinanceSettleModal.less';

const FinanceSettleModal = ({ record, close, intl, onFinish, ...rest }) => {
  const [depositAmount, setDepositAmount] = useState(record?.depositedClaim);
  const onConfirm = () => {
    onFinish(depositAmount || 0);
    close();
  };
  return (
    <Modal
      {...rest}
      title={
        <div className="br-cashier-approve-modal-title-container">
          <span className="heading">
            {intl.formatMessage({ id: 'cashier.settle_amount_modal.title' })}
          </span>
          <span className="sidebar-child">
            {intl.formatMessage({ id: 'cashier.settle_amount_modal.subtitle' })}
          </span>
        </div>
      }
      className="br-finance-settle-modal-container"
      centered
      onCancel={close}
      okText={intl.formatMessage({ id: 'cashier.settle_amount_modal.title' })}
      cancelText={intl.formatMessage({
        id: 'common.back'
      })}
      onOk={onConfirm}
      width={null}
    >
      <div className="br-finance-settle-modal__content">
        <div className="br-finance-settle-modal__hub-info">
          <div>
            <span className="body-medium">
              {intl.formatMessage({
                id: 'cashier.settle_amount_modal.deposit_details'
              })}
            </span>
            <div>
              <span className="body">
                <HubIcon /> {record?.hub?.name}
              </span>
              <span className="body">
                <DateIcon />
                <div>
                  <span className="body">
                    {dayjs(record?.date).tz().format('MMM D, YYYY')}
                  </span>
                  <span className="caption">
                    {dayjs(record?.date).tz().format('h:mm A')}
                  </span>
                </div>
              </span>
            </div>
          </div>
          <Image src={record?.pod} width={270} height={203} />
        </div>
        <div className="br-finance-settle-modal__settle-amount-info">
          <HubsDepositIcon />
          <div>
            <span className="body-medium">
              {intl.formatMessage({
                id: 'cashier.settle_amount_modal.hubs_deposit'
              })}
            </span>
            <InputNumber prefix="SAR" value={record?.depositedClaim} disabled />
          </div>
          <div>
            <span className="body-medium">
              {intl.formatMessage({
                id: 'cashier.settle_amount_modal.actual_deposit'
              })}
            </span>
            <InputNumber
              prefix="SAR"
              value={depositAmount}
              onChange={setDepositAmount}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(FinanceSettleModal);
