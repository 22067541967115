import { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Tabs, Tag } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  ACTION_CENTER_PAGE_SIZE,
  OFD_EDITS_ORDER_STATES,
  UPDATES_TO_STRING
} from 'constants/action-center';
import { stateColor } from 'constants/order-states';
import { getHubNotifications } from 'services/hubs';

import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import './OrderActions.less';

const OrderActions = ({ handleRowClick, selectedRowData, hubId, intl }) => {
  const refreshTable = useRef();
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState([]);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (refreshTable?.current) {
      updateTable();
    }
  }, [hubId]);

  const columns = [
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.tracking_no'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber) => {
        return (
          <Link
            to={`/deliveries/${trackingNumber}/details`}
            className="br-order-actions-delivery-link"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            {trackingNumber}
          </Link>
        );
      }
    },
    {
      title: 'Star',
      dataIndex: 'starProfile',
      sorter: true,
      render: (starProfile) => {
        const { name, phone } = starProfile;
        return (
          <>
            {name}
            <span className="br-order-actions-table-subtext">{phone}</span>
          </>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.updates'
      }),
      dataIndex: 'action',
      sorter: true,
      render: (action) => {
        return (
          <Tag className="br-order-actions__updates-tag">
            {UPDATES_TO_STRING[action]}
          </Tag>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.date_and_time'
      }),
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt) => {
        return (
          <>
            {dayjs(new Date(createdAt)).format('DD MMM YYYY')}
            <span className="br-order-actions-table-subtext">
              {dayjs(new Date(createdAt)).tz().format('h:mm A')}
            </span>
          </>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'ofd_edits.star_update'
      }),
      dataIndex: 'seenAt',
      sorter: true,
      render: (seenAt) => {
        return seenAt ? (
          <>
            {intl.formatMessage({ id: 'ofd_edits.seen' })}
            <span className="br-order-actions-table-subtext">
              {dayjs(seenAt).tz().format('h:mm A')}
            </span>
          </>
        ) : (
          intl.formatMessage({ id: 'ofd_edits.not_seen' })
        );
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'delivery',
      sorter: true,
      render: (delivery) => {
        return delivery ? (
          <Tag className={stateColor[delivery.state.code]}>
            {OFD_EDITS_ORDER_STATES[delivery.state.code]}
          </Tag>
        ) : (
          'N/A'
        );
      }
    }
  ];

  const handleSearchValueChange = (value) => {
    if (!value) {
      return setSearchValue([]);
    }

    setSearchValue(
      value
        .trim()
        .replace(/,+\s*$/, '')
        .split(/[, ]+/)
        .join(',')
    );
  };

  const formatActionsData = (data) =>
    data.map((item) => ({
      _id: item._id,
      trackingNumber: item?.extra?.trackingNumber,
      starProfile: item.starProfile,
      action: item?.extra?.actionType,
      createdAt: item.createdAt,
      seenAt: item.seenAt,
      delivery: item.delivery,
      actionId: item?.extra?.actionId,
      noteId: item?.extra?.noteId
    }));

  const getTableData = async ({
    pageId: pageNumber,
    pageLimit: pageSize,
    sortField,
    sortOrder
  }) => {
    try {
      const payload = {
        ...(searchValue.length && { trackingNumbers: searchValue }),
        page: pageNumber,
        limit: pageSize,
        sortBy: sortField
          ? `${sortOrder === 'descend' ? '-' : ''}${
              sortField === 'action' ? 'extra.actionType' : sortField
            }`
          : 'createdAt'
      };
      if (hubId) {
        const { data } = await getHubNotifications({ hubId, payload });

        setCount(data.total || 0);

        return {
          list: formatActionsData(data.list),
          total: data.total || 0
        };
      }
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-order-actions__container">
      <div className="br-order-actions__tabs-container">
        <Tabs className="br-order-actions__tabs">
          <Tabs.TabPane
            tab={`${intl.formatMessage({
              id: 'deliveries.states.out_for_delivery'
            })} (${count})`}
            key={1}
          >
            <BRSearchableTable
              columns={columns}
              pageLimit={ACTION_CENTER_PAGE_SIZE}
              listFunction={getTableData}
              rowClassName={(record) =>
                record?._id === selectedRowData?._id ? 'selected-row' : ''
              }
              searchInputPlaceholder={intl.formatMessage({
                id: 'action_center.order_actions.search_for_tracking_number'
              })}
              showSearch
              handleTextInput={handleSearchValueChange}
              shareMethods={acceptMethods}
              onRowClick={handleRowClick}
              withOutCheckBoxes
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default injectIntl(OrderActions);
