import { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Input } from 'antd';

import { fetchNotDebriefedCashMoney, changeCashMoneyType } from 'services/hubs';
import { openModal } from 'utils/modal';
import { isUserAuthorized } from 'utils/helpers';
import {
  CCOD,
  COD,
  MONEY_SOURCES_CAN_BE_CHANGED_TO_COD_SOURCE
} from 'constants/hubs';

import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import ChangeToOtherSourceModal from 'components/HubOperations/ChangeToOtherSourceModal/ChangeToOtherSourceModal';

import './DeliverdOrders.less';

const DeliverdOrders = ({ intl, selectedStar }) => {
  const hasMounted = useRef(false);

  const refreshTable = useRef('');
  const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country;

  const COLUMNS = [
    {
      title: intl.formatMessage({ id: 'money_debriefing.columns.amount' }),
      dataIndex: 'amount'
    },
    {
      title: intl.formatMessage({
        id: 'money_debriefing.columns.business_name'
      }),
      dataIndex: ['business', 'name'],
      width: 200
    },
    {
      title: intl.formatMessage({
        id: 'money_debriefing.columns.tracking_number'
      }),
      dataIndex: 'trackingNumber',
      render: (value) =>
        value || (
          <span className="br-table__empty-field">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        )
    },
    {
      title: intl.formatMessage({
        id: 'money_debriefing.columns.money_resource'
      }),
      dataIndex: 'src',
      render: (src, { _id, trackingNumber }) => (
        <div className="br-deliverd-orders__src">
          {src}

          <BRButton
            label={
              src === COD && userCountry.code === 'EG'
                ? 'Change to other source'
                : MONEY_SOURCES_CAN_BE_CHANGED_TO_COD_SOURCE.includes(src)
                ? 'Change to COD'
                : 'Change to CCOD'
            }
            disabled={!isUserAuthorized(['ACCOUNTANT', 'SUPER_ADMIN'])}
            type="basic"
            onClick={() =>
              src === COD && userCountry.code === 'EG'
                ? handleOnChangeFromCodSource({
                    cashId: _id,
                    src,
                    trackingNumber
                  })
                : onChangeCashType({ cashId: _id, src, trackingNumber })
            }
          />
        </div>
      ),
      width: 250
    },
    {
      title: intl.formatMessage({
        id: 'money_debriefing.columns.transaction_number'
      }),
      dataIndex: '_id',
      render: (value) =>
        value || (
          <span className="br-table__empty-field">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        )
      // width: 200
    },
    {
      title: intl.formatMessage({
        id: 'money_debriefing.columns.collected_at'
      }),
      dataIndex: 'createdAt',
      render: (date) =>
        date
          ? dayjs(date).utc().tz().format('MMM DD, YYYY h:mm a')
          : intl.formatMessage({ id: 'common.empty_field' })
    },
    {
      title: intl.formatMessage({ id: 'money_debriefing.columns.notes' }),
      dataIndex: 'notes',
      render: (value) =>
        value || (
          <span className="br-table__empty-field">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        )
    }
  ];

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    refreshTable.current();
  }, [selectedStar]);

  const getNotDebriefedData = async ({ page, limit }) => {
    if (!selectedStar?._id) {
      return { list: [], total: 0 };
    }

    try {
      const { data, total } = await fetchNotDebriefedCashMoney(
        selectedStar?._id,
        {
          pageId: page - 1
        }
      );
      return { list: data, total };
    } catch (error) {
      notify(error.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const confirmChangeCashType = async ({ cashId, payload, trackingNumber }) => {
    try {
      const { message } = await changeCashMoneyType(cashId, payload);
      refreshTable.current();

      notify(
        message
          ? message
          : intl.formatMessage(
              {
                id: `money_debriefing.change_to_success_msg`
              },
              { trackingNumber, newMoneySource: payload.changeTo }
            ),
        message ? 'error' : 'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnChangeFromCodSource = (props) => {
    openModal(ChangeToOtherSourceModal, {
      ...props,
      onChangeConfrim: confirmChangeCashType
    });
  };

  const onChangeCashType = async ({ cashId, src, trackingNumber }) => {
    let payload = {
      changeTo: MONEY_SOURCES_CAN_BE_CHANGED_TO_COD_SOURCE.includes(src)
        ? COD
        : CCOD
    };
    let receiptNo = null;
    openModal(BRConfirmationModal, {
      confirmButtonType: 'primary',
      confirmButtonText: 'Confirm',
      title: `Change order #${trackingNumber || ''} to ${
        MONEY_SOURCES_CAN_BE_CHANGED_TO_COD_SOURCE.includes(src) ? COD : CCOD
      }`,
      message: src === COD && (
        <>
          <div className="body-medium">Receipt No.</div>
          <div>
            <Input onChange={({ target: { value } }) => (receiptNo = value)} />
          </div>
        </>
      ),
      onConfirm: () => {
        if (src === COD) {
          payload = { ...payload, receiptNo };
        }
        confirmChangeCashType({ cashId, payload, trackingNumber });
      }
    });
  };

  return (
    <div>
      <BRTable
        showFilter={false}
        columns={COLUMNS}
        shareMethods={acceptMethods}
        listFunction={getNotDebriefedData}
      />
    </div>
  );
};

export default injectIntl(withRouter(DeliverdOrders));
