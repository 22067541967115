import { BASE } from 'apis';

const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country?._id;
/**
 * fetch all cities
 * @returns {Promise<*>}
 */
//get cities related to user country

export const fetchCities = async () => {
  let url = `/cities`;
  if (userCountry) {
    url = `/cities?countryId=${userCountry}`;
  }
  const { data } = await BASE.get(url);
  return data;
};

/**
 * fetch zones by cityId
 * @returns {Promise<*>}
 * @param query
 */

export const fetchZones = async (id) => {
  try {
    const { data } = await BASE.get(`/zones?cityId=${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const fetchZonesWithCity = async (id) => {
  try {
    let url = `/cities/getAllDistricts`;
    if (userCountry) {
      url = `/cities/getAllDistricts?countryId=${userCountry}`;
    }
    const { data } = await BASE.get(url);
    return data;
  } catch (e) {
    return null;
  }
};
