import { get as getValue } from 'lodash';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { COUNTRIES } from 'constants/country-data';
import { isSaudi } from 'constants/helper';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { isDefaultBostaCourier, isVendor } from 'utils/helpers';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { STARS_RATING } from 'components/ServiceRating/ServiceRating';

dayjs.extend(utc);
dayjs.extend(tz);

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const RepetitionTypes = [
  { label: 'One time', value: 'One Time' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' }
];

export const TypeAssignHubs = [
  { label: 'Auto-assigned', value: true },
  { label: 'Manually assigned', value: false }
];

export const DetailsColumnsMapped = [
  { dataIndex: 'trakingNumber', title: 'Tracking number' },
  { dataIndex: 'customerInfo', title: 'Customer Info ' },
  { dataIndex: 'orderType', title: 'Order Type' },
  { dataIndex: 'dropOffAdress', title: 'Drop of Address' }
];

export const pickupsStatusColors = {
  'In progress': {
    color: 'orange',
    backgroundColor: '#FFEEE5'
  },
  Created: {
    color: 'yellow',
    backgroundColor: '#FFFBE5'
  },
  Completed: {
    color: 'green',
    backgroundColor: '#E7F7E5'
  },
  Canceled: { color: 'red', backgroundColor: '#FFE5E5' }
};

export const PICKUP_STATUS = {
  REQUESTED: 'Requested',
  ROUTE_ASSIGNED: 'Route Assigned',
  PICKINGUP: 'Picking up',
  PICKEDUP: 'Picked up',
  CANCELED: 'Canceled',
  EXCEPTION: 'Exception'
};

export const REPEATED_TYPE_SELECTION = [
  { label: 'Every Day', value: 'Daily' },
  { label: 'Every Week', value: 'Weekly' }
];

export const DAYS = [
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' }
];

export const WEEKLY_VALUE = 'Weekly';
export const REPEATED_TYPE_ONE_TIME = 'One Time';

export const CREATED = 'Created';
export const REQUESTED = 'Requested';

export const NORMAL_PACKAGE_TYPE = 'Normal';
export const DEFAULT_WEIGHT = 1;
export const DEFAULT_SCAN_TYPE = 'Default';
export const BULKY_PACKAGE_TYPE = 'Bulky';
export const LIGHT_BULKY_PACKAGE_TYPE = 'Light Bulky';
export const HEAVY_BULKY_PACKAGE_TYPE = 'Heavy Bulky';

export const upcomingMaskedPickupsStatus = [
  { label: 'Created', value: 'Requested' },
  { label: 'In progress', value: ['Route Assigned', 'Picking up'] }
];

export const historyMaskedPickupsStatus = [
  { label: 'Completed', value: 'Picked up' },
  { label: 'Canceled', value: 'Canceled' }
];

export const PICKUP_PHONE_NUMBER_MAX_LENGHT =
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
  COUNTRIES[1].codeName
    ? 9
    : 11;

export const ASSGIN_TO_HUB = [
  'SUPER_ADMIN',
  'WAREHOUSE_CLERK',
  'OPERATIONS_MANAGER',
  'HEAD_OF_OPS',
  'HUB_LEADER',
  'HUB_COORDINATOR'
];

export const CAN_CANCEL_ROLE = [
  'OPERATIONS_MANAGER',
  'LAST_MILE_COORDINATOR',
  'FIRST_MILE_COORDINATOR',
  'SUPER_ADMIN'
];

export const formatAddress = (address = {}) => {
  const addressArr = [];
  if (address.buildingNumber) {
    addressArr.push(address.buildingNumber);
  }

  if (address.firstLine) {
    addressArr.push(address.firstLine);
  }
  if (address.floor) {
    addressArr.push(`Floor No. ${address.floor} `);
  }
  if (address.apartment) {
    addressArr.push(`Apartment No. ${address.apartment} `);
  }

  if (address.secondLine) {
    addressArr.push(address.secondLine);
  }
  return addressArr.join(', ');
};

export const CRP_PICKUP = 'Customer Return Pickup';
export const OUTBOUND_AGENT = 'OUTBOUND_AGENT';
export const OUTBOUND = 'Outbound';

export const PACKAGE_TYPE_COLOR = {
  [NORMAL_PACKAGE_TYPE]: 'black',
  [LIGHT_BULKY_PACKAGE_TYPE]: 'green',
  [HEAVY_BULKY_PACKAGE_TYPE]: 'yellow'
};

export const PICKUPS_REQUESTS_COLUMNS = (actions) => [
  {
    dataIndex: 'pickupRequestID',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.pickup_request_id'
    }),
    fixed: 'left',
    width: 150
  },
  {
    dataIndex: 'scheduledDate',
    title: fmt({ id: 'pickups.pickup_request.table_columns.scheduled_date' }),
    fixed: 'left',
    width: 150
  },
  {
    dataIndex: 'pickupAddress',
    title: fmt({ id: 'pickups.pickup_request.table_columns.pickup_address' }),
    width: 400,
    render: (address, record) =>
      isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
      record?.showAddress
        ? address
        : ''
  },
  {
    dataIndex: 'city',
    title: fmt({ id: 'businesses.search_filters.city' }),
    width: 150
  },
  {
    dataIndex: 'area',
    title: fmt({ id: 'location_form_labels.area' }),
    width: 150
  },
  {
    dataIndex: 'fmDistrictCode',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.fm_district_code'
    }),
    width: 150
  },
  {
    dataIndex: 'state',
    title: fmt({ id: 'pickups.pickup_request.search.state' }),
    width: 150
  },
  {
    dataIndex: 'business',
    title: fmt({ id: 'pickups.pickup_request.search.business' }),
    width: 150
  },
  {
    dataIndex: 'cancelReason',
    title: fmt({ id: 'pickups.pickup_request.table_columns.exception_reason' }),
    width: 150
  },
  {
    dataIndex: 'pickupRescheduledForId',
    title: fmt({ id: 'pickups.pickup_request.table_columns.old_pickup_id' }),
    width: 150
  },
  {
    dataIndex: 'packageType',
    title: fmt({ id: 'pickups.pickup_request.table_columns.package_type' }),
    width: 150
  },
  {
    dataIndex: 'hub',
    title: fmt({ id: 'stars.create_edit_star.form_labels.hub' }),
    width: 150
  },
  {
    dataIndex: 'starInfo',
    title: fmt({ id: 'pickups.pickup_request.table_columns.star_info' }),
    width: 200
  },
  {
    dataIndex: 'pickedUpDate',
    title: fmt({ id: 'pickups.pickup_request.table_columns.picked_up_date' }),
    width: 150
  },
  {
    dataIndex: 'attemptDate',
    title: fmt({ id: 'pickups.pickup_request.table_columns.attemptDate' }),
    width: 150
  },
  {
    dataIndex: 'assignedDate',
    title: fmt({ id: 'pickups.pickup_request.table_columns.assignDate' }),
    width: 150
  },
  {
    dataIndex: 'isBusinessFirstPickup',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.business_first_pickup'
    }),
    width: 170
  },
  {
    dataIndex: 'firstPickedUpDate',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.first_pickup_date'
    }),
    width: 150
  },
  {
    dataIndex: 'numberOfPackages',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.number_of_packages'
    }),
    width: 150
  },
  {
    dataIndex: 'numberOfParcels',
    title: fmt({ id: 'pickups.pickup_details.pickup_info.number_of_parcels' }),
    width: 150
  },
  {
    dataIndex: 'type',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.type'
    }),
    width: 150
  },
  {
    dataIndex: 'repetition',
    title: fmt({
      id: 'pickups.pickup_request.table_columns.repetition'
    }),
    width: 150
  },
  {
    dataIndex: '',
    title: '',
    width: 80,
    fixed: 'right',
    render: (record) => actions(record.rawData)
  }
];

export const pickupStates = {
  Route: 'Route Assigned',
  Requested: 'Requested',
  Picked: 'Picked up',
  Cancel: 'Canceled',
  Picking_up: 'Picking up',
  Exception: 'Exception'
};
export const TextState = {
  Cancel: 'cancel',
  AssignHub: 'Assign to hub',
  AddRoute: 'Add to route',
  Rest: 'Restart'
};

export const formatOptions = (keyFieldName, labelFieldName, data) => {
  const labelFields = labelFieldName.split(',');
  if (!Array.isArray(data)) {
    const arr = Array.from(Object.keys(data), (k) => data[k]);
    data = arr;
  }
  return data.map((item) => {
    const labelValue = labelFields.reduce((acc, value) => {
      if (!value) return acc;
      return `${acc} ${getValue(item, value, '')}`;
    }, '');
    return { value: item[keyFieldName], label: labelValue };
  });
};

export const stateTypes = [
  { label: pickupStates.Route, value: pickupStates.Route },
  { label: pickupStates.Requested, value: pickupStates.Requested },
  { label: pickupStates.Cancel, value: pickupStates.Cancel },
  { label: pickupStates.Picked, value: pickupStates.Picked },
  { label: pickupStates.Picking_up, value: pickupStates.Picking_up },
  { label: pickupStates.Exception, value: pickupStates.Exception }
];
export const statusTypes = [
  { label: 'Verified', value: 'Verified' },
  { label: 'Unverified', value: 'Unverified' },
  { label: 'Deleted', value: 'Deleted' }
];
export const fawryRegistered = [
  { label: 'Registered', value: 'true' },
  { label: 'Not Registered', value: 'false' }
];

export const PICKUP_TYPES_OPTIONS = [
  { label: 'First mile pickup request', value: 'Business Pickup' },
  { label: 'CRP-pickup request', value: 'Customer Return Pickup' },
  {
    label: 'Sign & Return pickup request',
    value: 'Sign and Return Pickup'
  }
];

export const truncate = (str) => {
  return str.length > 10 ? str.substring(0, 30) + '...' : str;
};
export const initialFormValuesCreate = {
  businessId: null,
  businessLocationId: null,
  warehouseId: null,
  scheduledDate: null,
  scheduledTimeSlot: null,
  contactPerson: {
    name: null,
    phone: null,
    email: null
  }
};

export const PICKUP_CANCEL_REASONS_OPTIONS = [
  {
    value: 'reschedule_business_needs_change_pickup_location',
    label: 'تأجيل - البيزنس يحتاج الى تغيير عنوان الاستلام'
  },
  {
    value: 'business_reschedule',
    label: 'تأجيل - البيزنس طلب تاجيل الاستلام'
  },
  {
    value: 'business_unreachable',
    label: 'تأجيل - المندوب لم يتمكن من الوصول الى البيزنس'
  },
  {
    value: 'orders_failed',
    label: 'تأجيل - المندوب فشل فى استلام الشحنات'
  },
  {
    value: 'reschedule_business_needs_onboarding',
    label: 'تأجيل - البيزنس بحاجة الى معلومات إضافية'
  },
  {
    value: 'business_flyer',
    label: 'إلغاء - البيزنس يحتاج الى فلاير'
  },
  {
    value: 'orders_not_ready',
    label: 'إلغاء - الشحنة غير جاهزة'
  },
  {
    value: 'canceled_order_size_inappropriate',
    label: 'إلغاء - حجم الشحنة غير مناسب'
  },

  {
    value: 'business_cancelled',
    label: 'إلغاء - البيزنس طلب الالغاء'
  },

  {
    value: 'orders_no_tn',
    label: 'إلغاء - الشحنة بدون بوليصة'
  },
  {
    value: 'canceled_by_outbound_team',
    label: 'تم الإلغاء بواسطة فريق الصادر - تمت إعادة جدولة وهمية '
  }
];

export const DATE_KEYS = [
  'pickedUpAtStart',
  'pickedUpAtEnd',
  'createdAtStart',
  'createdAtEnd',
  'scheduledAtStart',
  'scheduledAtEnd'
];

export const DELIVERIES_TYPES = {
  CUSTOMER_RETURN_PICKUP: 'Customer Return Pickup',
  SIGN_RETURN_PICKUP: 'Sign and Return Pickup'
};

export const DEFAULT_PICKUP_CUTOFF_TIME = 16;

export const PICKUP_CUTOFF_TIME =
  userInfo?.country?.latestPickupHourInUtc !== undefined
    ? dayjs(dayjs().utc().hour(userInfo?.country?.latestPickupHourInUtc))
        .tz()
        .hour()
    : DEFAULT_PICKUP_CUTOFF_TIME;

export const SOHO_PICKUP_TYPE = 'Mobile First Mile pickup';

export const CRP_SIGN_AND_RETURN_PICKUP_TYPES = [
  'Customer Return Pickup',
  'Sign and Return Pickup'
];

export const PICKUP_TICKET_LOG = 'Pickup counters mismatch ticket is created';

const SILVER = 'Silver';
const GOLD = 'Gold';
const RED = 'Red';
export const RECURRING_PICKUP_BUSINESS_TIERS = [RED, GOLD];

export const PICKEDUP_DATE_FORMAT = 'ddd, DD MMM YYYY';

export const PICKUP_TYPES = {
  FIRST_MILE_PICKUP: 'First Mile pickup',
  CRP_PICKUP: 'Customer Return Pickup',
  SIGN_AND_RETURN_PICKUP: 'Sign and Return Pickup'
};

export const PICKUP_REQUESTS_CANCEL_REASON = {
  [PICKUP_TYPES.FIRST_MILE_PICKUP]: {
    1: ' طلب تغيير عنوان الاستلام من البزنيس',
    2: 'طلب تأجيل الاستلام من البزنيس',
    3: 'لم يتم الرد',
    6: 'البيزنس يحتاج الي فلاير - الشحنات غير مغلفة',
    8: 'حجم الشحنات غير مناسب للاستلام',
    9: 'الشحنات بدون بوليصة',
    10: 'طلب الغاء الاستلام من البزنيس',
    12: 'العنوان غير صحيح',
    13: 'معلومات التوصيل غير مكتملة',
    14: 'رقم الهاتف غير صحيح'
  },
  [PICKUP_TYPES.CRP_PICKUP]: {
    1: fmt({ id: 'pickups.cancel_reasons.customer_is_not_in_address' }),
    2: fmt({ id: 'pickups.cancel_reasons.customer_changed_address' }),
    3: fmt({ id: 'pickups.cancel_reasons.customer_postponed' }),
    4: fmt({ id: 'pickups.cancel_reasons.customer_wants_to_open_order' }),
    5: fmt({ id: 'pickups.cancel_reasons.address_is_not_clear' }),
    6: fmt({ id: 'pickups.cancel_reasons.cancel_request' }),
    7: fmt({ id: 'pickups.cancel_reasons.customer_not_answering' }),
    8: fmt({ id: 'pickups.cancel_reasons.customer_refused_to_receive' }),
    9: fmt({ id: 'pickups.cancel_reasons.customer_refused_to_give_the_order' }),
    11: fmt({ id: 'pickups.cancel_reasons.star_didnt_call' }),
    12: fmt({ id: 'pickups.cancel_reasons.uncovered_zone' }),
    13: fmt({ id: 'pickups.cancel_reasons.address_not_clear' }),
    14: fmt({ id: 'pickups.cancel_reasons.wrong_phone' }),
    15: fmt({ id: 'pickups.cancel_reasons.customer_refuse_to_sign' }),
    20: fmt({ id: 'pickups.cancel_reasons.customer_changed_address_return' }),
    21: fmt({ id: 'pickups.cancel_reasons.business_postponed' }),
    22: fmt({ id: 'pickups.cancel_reasons.address_is_not_clear' }),
    23: fmt({ id: 'pickups.cancel_reasons.business_not_answering' }),
    24: fmt({ id: 'pickups.cancel_reasons.customer_refused_to_receive' }),
    25: fmt({ id: 'pickups.cancel_reasons.business_not_in_address' }),
    100: fmt({ id: 'pickups.cancel_reasons.bad_weather' }),
    101: fmt({ id: 'pickups.cancel_reasons.suspicious_consignee' }),
    26: fmt({ id: 'pickups.cancel_reasons.order_damaged' }),
    27: fmt({ id: 'pickups.cancel_reasons.empty_order' }),
    28: fmt({ id: 'pickups.cancel_reasons.incomplete_order' }),
    29: fmt({ id: 'pickups.cancel_reasons.order_doesnt_belong_to_business' }),
    30: fmt({ id: 'pickups.cancel_reasons.the_order_was_opened' })
  }
};

export const PICKUPS_DATE_RANGE_FILTERS_KEYS = [
  'scheduledAt',
  'createdAt',
  'pickedUpAt',
  'attemptDate',
  'assignedAt'
];

export const ROUTE_ASSIGNING_TYPES = {
  ASSIGNED_ROUTES: 'assignedRoutes',
  UNASSIGNED_ROUTES: 'unassignedRoutes'
};

export const PICKUP_LOG_ACTIONS = {
  EXCEPTION: 'Exception'
};

export const PICKUP_RATING_ITEMS = [
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.star_rate' }),
    dataIndex: 'starRating',
    type: STARS_RATING
  },
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.rate_comment' }),
    dataIndex: 'starComment'
  },
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.pickup_rate' }),
    dataIndex: 'serviceRating',
    type: STARS_RATING
  },
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.rate_comment' }),
    dataIndex: 'serviceComment'
  }
];
