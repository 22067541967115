export const sumOfTotalReceived = (sortingFacilityData) => {
  const {
    fresh_deliveries_received_count,
    transferred_deliveries_received_count,
    rto_deliveries_received_count
  } = sortingFacilityData || {};
  return (
    fresh_deliveries_received_count +
      transferred_deliveries_received_count +
      rto_deliveries_received_count || 0
  );
};

export const allPendingSum = (sortingFacilityData) => {
  const {
    pending_transfer_deliveries_count,
    ready_to_dispatch_deliveries_count
  } = sortingFacilityData || {};
  return (
    pending_transfer_deliveries_count + ready_to_dispatch_deliveries_count || 0
  );
};
