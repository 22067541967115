import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect
} from 'react-router-dom';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
import get from 'lodash/get';
import { useIntl } from 'react-intl';

import { getCountryTimezone, getOfficialHolidays } from 'services/system';
import VerifyUser from 'containers/SignIn/VerifyUser/VerifyUser';
import routes from './routes';
import { restrictBusinessRoles, BUSINESS_ADMIN } from './common/aclMatrix';
import { DEFAULT_TIMEZONE, getWorkingDays } from 'constants/System';
import { logout } from './utils';
import { isUserAuthorized } from 'utils/helpers';
import { ALL_COUNTRIES_CODES } from 'common/countries/constants/countries';

import aclMatrix from './common/aclMatrix';
import ChangePassword from 'components/ChangePassword/ChangePassword';
import { notify } from 'components/Notify/Notify';

// import 'antd/dist/antd.css';
import './App.scss';
import './styles/global-styles.less';

const loading = () => <div className="animated fadeIn pt-3 text-center" />;

// Containers
const DefaultLayout = React.lazy(() =>
  import('./components/DefaultLayout/DefaultLayout')
);

// Pages
const SignIn = React.lazy(() => import('components/SignIn/SignIn'));
const ForgetPassword = React.lazy(() =>
  import('components/ForgotPassword/ForgotPassword')
);
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
// const LoadingPage = React.lazy(() => import('./views/Pages/LoadingPage'));
const Unauthorized = React.lazy(() =>
  import('./views/Pages/Unauthorized/Unauthorized')
);
const AuthCallback = React.lazy(() =>
  import('./components/AuthCallback/AuthCallback.js')
);

const isAuthenticated = () => !!localStorage.getItem('token');
const userInfo = () => JSON.parse(localStorage.getItem('userInfo'));
const isAuthorized = (
  userRoles = [],
  accessRoles,
  permissions,
  adminGroup,
  { countries = ALL_COUNTRIES_CODES } = {
    countries: ALL_COUNTRIES_CODES
  }
) => {
  if (adminGroup) {
    return permissions
      ? permissions.some((permission) =>
          get(adminGroup.permissions, permission?.join('.'))
        ) && countries.includes(userInfo()?.country?.code)
      : true;
  }
  return (
    userRoles.some((el) => accessRoles.indexOf(el) !== -1) &&
    countries.includes(userInfo()?.country?.code)
  );
};

function PrivateRoute({
  component: Component,
  access,
  module,
  permission,
  countries,
  initialProps,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: '/signin'
              }}
            />
          );
        }
        if (module && module === 'BUSINESS') {
          const { roles = [] } = userInfo();
          if (roles.length > 0) {
            const matched = Object.values(restrictBusinessRoles).some(
              (item) => {
                return (
                  JSON.stringify(roles.sort()) === JSON.stringify(item.sort())
                );
              }
            );
          }
        }
        if (
          access &&
          !isAuthorized(
            userInfo()?.roles,
            access,
            permission,
            userInfo()?.adminGroup,
            { countries }
          )
        ) {
          return <Redirect to={{ pathname: '/401' }} />;
        }
        return (
          <>
            <DefaultLayout userInfo={userInfo()}>
              <Component {...props} {...initialProps} />
            </DefaultLayout>
          </>
        );
      }}
    />
  );
}

export default function App({}) {
  const intl = useIntl();
  const fetchOfficialHolidays = async () => {
    try {
      const { data } = await getOfficialHolidays();
      const holidays = data?.map(({ holidayDate }) => holidayDate) || [];
      const options = {
        workingWeekdays: getWorkingDays(),
        holidays: holidays,
        holidayFormat: `DD-MM-YYYY`
      };
      dayjs.extend(dayjsBusinessDays, options);
    } catch (error) {
      //
    }
  };

  const setUserDefaultTimezone = async () => {
    try {
      const { data } = await getCountryTimezone();
      const timezone = data?.timeZone?.name || DEFAULT_TIMEZONE;

      dayjs.tz.setDefault(timezone);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      const isPackPriceAnnouncemnetShown = JSON.parse(
        localStorage.getItem('IsPackPriceAnnouncemnetShown')
      );

      isUserAuthorized(aclMatrix.HUB) && fetchOfficialHolidays();
      setUserDefaultTimezone();
    }
  }, []);
  const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country
    ?.code;

  const getHomeUrlForUser = () => {
    let pathName = '/401';

    routes.some((route) => {
      if (
        isUserAuthorized(route.access, route.permission, {
          countries: route.countries
        }) &&
        route.mainPage
      ) {
        pathName = route.path;
        return true;
      }
    });

    return pathName;
  };

  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            path="/"
            basename="/"
            exact
            name="Home"
            render={(props) =>
              isAuthenticated() ? (
                isUserAuthorized(userInfo()?.roles) ||
                userInfo()?.adminGroup ? (
                  <Redirect
                    to={{
                      pathname: getHomeUrlForUser()
                    }}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/401'
                    }}
                  />
                )
              ) : (
                <Redirect
                  to={{
                    pathname: '/signin'
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/signin"
            name="Login Page"
            render={() =>
              isAuthenticated() ? <Redirect to="/" /> : <SignIn />
            }
          />
          <Route
            exact
            path="/verify-user"
            name="2FA Login Page"
            render={() =>
              isAuthenticated() ? <Redirect to="/" /> : <VerifyUser />
            }
          />
          <Route
            exact
            path="/change-password"
            name="Change Password"
            render={() =>
              isAuthenticated() ? <Redirect to="/" /> : <ChangePassword />
            }
          />
          <Route
            exact
            path="/auth-callback"
            name="Auth Callback"
            render={(extraProps) => <AuthCallback {...extraProps} />}
          />
          <Route
            exact
            path="/forget-password/"
            name="Forget Password Page"
            render={(extraProps) =>
              isAuthenticated() ? (
                <Redirect to="/" />
              ) : (
                <ForgetPassword currentStep={1} {...extraProps} />
              )
            }
          />
          <Route
            exact
            path="/reset/:token"
            name="Reset Password Page"
            render={(extraProps) =>
              isAuthenticated() ? (
                <Redirect to="/" />
              ) : (
                <ForgetPassword currentStep={2} {...extraProps} />
              )
            }
          />
          {routes
            .filter((el) => !el.exclude)
            .map((route) => {
              return route.component ? (
                <Route
                  key={`route_${route.name}`}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  private={route.private}
                  render={(props) => {
                    return route.private === false ? (
                      <route.component {...props} />
                    ) : (
                      <PrivateRoute
                        access={route.access}
                        permission={route.permission}
                        component={route.component}
                        module={route.module}
                        countries={route.countries}
                        initialProps={route.initialProps}
                      />
                    );
                  }}
                />
              ) : null;
            })}
          <Route
            path="/logout"
            exact
            name="Logout"
            render={(extraProps) =>
              isAuthenticated() ? logout() : <Redirect to="/" {...extraProps} />
            }
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(extraProps) => <Page500 {...extraProps} />}
          />
          <Route
            exact
            path="/401"
            name="Unauthorized"
            render={(extraProps) =>
              !isAuthenticated() &&
              !(
                userInfo()?.user.roles?.length === 1 &&
                userInfo()?.user.roles?.includes(BUSINESS_ADMIN)
              ) ? (
                <Redirect to="/logout" {...extraProps} />
              ) : (
                <Unauthorized {...extraProps} />
              )
            }
          />
          <Route
            path="*"
            render={(extraProps) => {
              return <Redirect to="/" {...extraProps} />;
            }}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
}
