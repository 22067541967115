import http from 'utils/http';

export const getImpersonationRequestStatus = (payload) => {
  return http.get('/users/request-impersonation', payload);
};

export const sendImpersonationRequest = (payload) => {
  return http.post('/users/request-impersonation', payload);
};

export const getImpersonationAccess = (businessId) => {
  return http.get(`/users/request-impersonation-access/${businessId}`);
};

export const revokeImpersonationAccess = (businessId) => {
  return http.delete(`/users/request-impersonation-access/${businessId}`);
};
