import { injectIntl } from 'react-intl';

import {
  HUB_TRANSFER_DISPATCHING_TYPES,
  IN_TRANSIT,
  SEALS
} from 'constants/hubs';

import ReceiveSealsCard from '../ReceiveSealsCard/ReceiveSealsCard';
import ScannerInput from '../ScannerInput/ScannerInput';
import ScanningTypeSelector from 'containers/Hub/ReceiveSeals/components/ScanningTypeSelector/ScanningTypeSelector';

import './SealScan.less';

const SealScan = ({
  intl,
  getSeals,
  goToNextView,
  setCurrentViewProps,
  isLoading,
  masterSeal,
  hubId,
  scanningType,
  handleOnScanningTypeChange
}) => {
  const handleChange = async (value) => {
    if (masterSeal?.sealNumbers?.includes(value)) {
      const payload = {
        state: IN_TRANSIT,
        destinationHubId: hubId,
        searchType: SEALS,
        type: HUB_TRANSFER_DISPATCHING_TYPES.join(','),
        sealNumber: value,
        pageId: 1
      };
      const data = await getSeals(payload);
      const seal = data?.results?.find(
        ({ sealNumber }) => sealNumber === value
      );
      if (seal) {
        goToNextView();
        setCurrentViewProps({
          masterSeal,
          seal
        });
      } else {
        return intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.error_msgs.not_found'
        });
      }
    } else {
      return intl.formatMessage({
        id: 'hubs.receive_from_hub.receive_seals.error_msgs.not_found'
      });
    }
  };

  return (
    <ReceiveSealsCard
      header={
        <>
          {intl.formatMessage({
            id: 'hubs.receive_from_hub.receive_seals.master_seal_scan.label'
          })}
          <span className="br-seal-scan__master-seal-code">
            {masterSeal?.sealNumber}
          </span>
          <div>
            <ScanningTypeSelector
              scanningType={scanningType}
              handleOnScanningTypeChange={handleOnScanningTypeChange}
            />
          </div>
        </>
      }
    >
      <ScannerInput
        label={intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.seal_scan.label'
        })}
        placeholder={intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.seal_scan.placeholder'
        })}
        onChange={handleChange}
        disabled={isLoading}
      />
    </ReceiveSealsCard>
  );
};

export default injectIntl(SealScan);
