import { Tag, Tooltip } from 'antd';

import { PURCHASE_STATES } from 'constants/fulfillment';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import BRButton from 'components/BRButton/BRButton';

export const getStatusTag = (status, request, businessId) => {
  let tagInfo = {};
  switch (status) {
    case PURCHASE_STATES.RECEIVED:
      tagInfo = {
        label: fmt({ id: 'fulfillment.inventory_requests.filters.received' }),
        className: 'ant-tag-green',
        tooltipTitle: fmt({
          id: 'fulfillment.inventory_requests.table_columns.received_tooltip'
        })
      };
      break;
    case PURCHASE_STATES.CANCELED:
      tagInfo = {
        label: fmt({ id: 'fulfillment.inventory_requests.filters.canceled' }),
        className: 'ant-tag-dark-gray',
        tooltipTitle: fmt({
          id: 'fulfillment.inventory_requests.table_columns.canceled_tooltip'
        })
      };
      break;
    case PURCHASE_STATES.PARTIALLY_RECEIVED:
      tagInfo = {
        label: fmt({
          id: 'fulfillment.inventory_requests.filters.partially_received'
        }),
        className: 'ant-tag-yellow',
        tooltipTitle: (
          <span>
            {fmt({
              id: 'fulfillment.inventory_requests.table_columns.partially_received_tooltip'
            })}
            <div>
              <BRButton
                type="primary"
                className="button-md"
                onClick={() =>
                  window.open(
                    `/inventory/requests/${businessId}/${request?.id}`,
                    '_blank'
                  )
                }
                label={fmt({
                  id: 'fulfillment.inventory_requests.table_columns.view_details'
                })}
              />
            </div>
          </span>
        )
      };
      break;
    default:
      tagInfo = {
        label: fmt({
          id: 'fulfillment.inventory_requests.filters.processing'
        }),
        className: 'ant-tag-light-blue',
        tooltipTitle: fmt({
          id: 'fulfillment.inventory_requests.table_columns.processing_tooltip'
        })
      };
      break;
  }
  return (
    <Tooltip
      title={tagInfo.tooltipTitle}
      placement="top"
      overlayClassName="br-inventory-requests__status-tooltip"
      getPopupContainer={(trigger) =>
        trigger.parentElement.parentElement.parentElement.parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .parentElement.parentElement.parentElement
      }
      autoAdjustOverflow={true}
      arrowPointAtCenter={true}
      trigger={['click', 'hover']}
    >
      <Tag className={tagInfo.className}>{tagInfo.label}</Tag>
    </Tooltip>
  );
};
