import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { haveNewTransferPageAccess } from 'common/countries/countries-mapping';

import ReceiveFromHub from 'containers/Hub/ReceiveFromHub/ReceiveFromHub';
import ReceiveTransfer from 'containers/Hub/ReceiveFromHub/ReceiveTransfer';
import ReceiveSeals from 'containers/Hub/ReceiveSeals/ReceiveSeals';

const ReceiveFromHubContainer = () => {
  return (
    <Switch>
      <Route
        path={['/hubs/hub-operation/receive/receive-from-hub']}
        exact
        component={haveNewTransferPageAccess ? ReceiveSeals : ReceiveFromHub}
      />
      <Route
        path={['/hubs/hub-operation/receive/receive-from-hub/:id']}
        exact
        component={ReceiveTransfer}
      />
    </Switch>
  );
};

export default withRouter(ReceiveFromHubContainer);
