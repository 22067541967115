import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_BUSINESSES_MAIN_PATH,
  FULFILLMENT_BUSINESSES_ROUTES
} from 'constants/fulfillment';

const Businesses = () => {
  return (
    <Switch>
      {FULFILLMENT_BUSINESSES_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_BUSINESSES_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(Businesses);
