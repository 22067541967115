import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Button, Select } from 'antd';

import { addTimeSlot } from 'services/system';

import { notify } from 'components/Notify/Notify';

import './AddTimeSlotModal.less';

const DEFAULT_START_DATE = 8;
const DEFAULT_END_DATE = 19;

const AddTimeSlotModal = ({ close, onSuccess, intl, ...props }) => {
  const generateTimeSlots = (
    start = DEFAULT_START_DATE,
    end = DEFAULT_END_DATE
  ) => {
    let hoursArray = [];
    for (let i = 0; i <= end - start; i++) {
      let hour = start + i;
      if (hour >= 12) {
        hoursArray.push({
          label: `${hour % 12 || 12}:00 PM`,
          value: hour
        });
      } else hoursArray.push({ label: `${hour}:00 AM`, value: hour });
    }
    return hoursArray;
  };

  const onFinish = async (values) => {
    const { startTime, endTime } = values;
    const countryId = JSON.parse(localStorage.getItem('userInfo'))?.country
      ?._id;
    if (Math.abs(startTime - endTime) < 2) {
      notify(
        intl.formatMessage({
          id: 'settings.system.time_slots.adding_time_slot_warning'
        })
      );
    } else {
      try {
        const res = await addTimeSlot({
          countryId,
          ...values
        });
        close();
        onSuccess();
      } catch (error) {
        notify(error.message);
      }
    }
  };

  return (
    <Modal {...props} onCancel={close} title={null} footer={null}>
      <div className="br-add-time-slot-modal__title">Add New Time Slot</div>
      <Form onFinish={onFinish}>
        <Form.Item
          name="startTime"
          label={intl.formatMessage({
            id: 'settings.system.time_slots.start_time'
          })}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={intl.formatMessage({
              id: 'settings.system.time_slots.select_time_placeholder'
            })}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {generateTimeSlots().map((hour) => (
              <Select.Option key={hour.value} value={hour.value}>
                {hour.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="endTime"
          label={intl.formatMessage({
            id: 'settings.system.time_slots.end_time'
          })}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={intl.formatMessage({
              id: 'settings.system.time_slots.select_time_placeholder'
            })}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {generateTimeSlots().map((hour) => (
              <Select.Option key={hour.value} value={hour.value}>
                {hour.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="br-add-time-slot-modal__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'settings.system.time_slots.add_time_slot'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddTimeSlotModal);
