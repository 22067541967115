import { all, fork } from 'redux-saga/effects';
import deliveriesWatcher from './deliveriesSaga';
import hubsWatcher from './hubsSaga';
import usersWatcher from './usersSaga';
import citiesWatcher from './citiesSaga';
import businessWatcher from './businessSaga';
import routesWatcher from './routesSaga';
import starWatchers from './starsSaga';
import PickupsWatcher from './pickupsSaga';
import soundsWatcher from './soundsSaga';
import ARReport from './arSaga';

export default function* rootSaga() {
  yield all([
    fork(businessWatcher),
    fork(deliveriesWatcher),
    fork(hubsWatcher),
    fork(usersWatcher),
    fork(citiesWatcher),
    fork(routesWatcher),
    fork(starWatchers),
    fork(PickupsWatcher),
    fork(soundsWatcher),
    fork(ARReport),
  ]);
}
