import React, { useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Descriptions, Divider, InputNumber } from 'antd';
import classnames from 'classnames';

import { SYSTEM_SUB_TITLE } from 'constants/System';
import {
  toggleCOD,
  getUserTimeSlots,
  removeTimeSlot,
  getCountryConfiguration,
  setCountryConfigurations
} from 'services/system';
import { openModal } from 'utils/modal';
import { getCurrentUserCountryId } from 'common/countries/countries-mapping';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import AddTimeSlotModal from './AddTimeSlotModal/AddTimeSlotModal';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import BRButton from 'components/BRButton/BRButton';

import fawry from 'assets/imgRevamp/fawry.png';
import { ReactComponent as AddSquare } from 'assets/imgRevamp/add-square.svg';

import './System.less';

const System = ({ intl }) => {
  const countryAutoCod = JSON.parse(
    localStorage.getItem('userAutoCodCollection')
  );

  const [isChecked, setIsChecked] = useState(
    countryAutoCod ? countryAutoCod.autoCODCollection : true
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [pickupsScanningNumber, setPickupsScanningNumber] = useState(0);

  const currentUserCountryId = getCurrentUserCountryId();

  const onChangeAutoCOD = async () => {
    setIsLoading(true);
    try {
      const { countryObject } = await toggleUserCOD();
      setIsChecked(!isChecked);
      localStorage.setItem(
        'userAutoCodCollection',
        JSON.stringify(countryObject)
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const toggleUserCOD = async () => {
    try {
      const result = await toggleCOD();
      return result;
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOpenTimeSlotModal = () => {
    openModal(AddTimeSlotModal, {
      onSuccess: () => {
        handleCheckTimeSlots();
      }
    });
  };

  const handleCheckTimeSlots = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getUserTimeSlots();
      setTimeSlots(response);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  }, []);

  const handleConfirmRemoveTimeSlot = async (timeSlotId) => {
    try {
      setIsLoading(true);
      await removeTimeSlot(timeSlotId);
      handleCheckTimeSlots();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleRemoveTimeSlot = async (timeSlotId) => {
    if (timeSlots.length === 1) {
      notify(
        intl.formatMessage({
          id: 'settings.system.time_slots.removing_last_time_slot_warning'
        })
      );
    } else {
      openModal(BRConfirmationModal, {
        title: intl.formatMessage({
          id: 'settings.system.time_slots.removing_time_slot_title'
        }),
        message: intl.formatMessage({
          id: 'settings.system.time_slots.removing_last_time_slot_confirmation_message'
        }),
        onConfirm: () => {
          handleConfirmRemoveTimeSlot(timeSlotId);
        }
      });
    }
  };

  const getPickupsConfiguration = async () => {
    try {
      const { data } = await getCountryConfiguration({
        countryId: currentUserCountryId
      });
      setPickupsScanningNumber(data.scannedPickupsLimit);
    } catch (error) {
      notify(error.message);
    }
  };

  const setPickupsConfiguration = async () => {
    try {
      const payload = {
        scannedPickupsLimit: pickupsScanningNumber
      };

      await setCountryConfigurations({
        countryId: currentUserCountryId,
        payload
      });
      notify(
        intl.formatMessage({
          id: 'settings.system.pickups.success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    handleCheckTimeSlots();
    getPickupsConfiguration();
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      <BRContentHeader title="System Settings" isInternalComponent />
      <div>
        <span className="br-settings-system__cod__title">
          {intl.formatMessage({ id: 'settings.system.cod.title' })}
        </span>
        <div className="br-settings-system__toggle-container">
          <div>
            <div className="br-settings-system__toggle-header">
              <span>
                {intl.formatMessage({ id: 'settings.system.cod.toggle_label' })}
              </span>
              <Button
                onClick={onChangeAutoCOD}
                className={classnames('br-settings-system__default-toggle', {
                  'br-settings-system__active-toggle': isChecked
                })}
              >
                {intl.formatMessage({
                  id: `settings.system.cod.${
                    isChecked ? 'deactivate_cod' : 'activate_cod'
                  }`
                })}
              </Button>
            </div>
            {SYSTEM_SUB_TITLE(isChecked).map((subtitle) => (
              <div className="br-settings-system__subtitle">{subtitle}</div>
            ))}
          </div>
          {isChecked && (
            <div className="br-settings-system__activated-cod">
              <img src={fawry} alt="Fawry Logo" />
              <span className="br-settings-system__fawry-hint">
                {intl.formatMessage({ id: 'settings.system.cod.fawry_hint' })}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="br-settings-system__time-slots-container">
        <span className="br-settings-system__cod__title">Time Slots</span>
        <div className="br-settings-system__toggle-container">
          <div>
            <div className="br-settings-system__toggle-header">
              <span>
                {intl.formatMessage({
                  id: 'settings.system.time_slots.title'
                })}
              </span>
              <Button
                onClick={handleOpenTimeSlotModal}
                className="br-settings-system__default-toggle"
              >
                <AddSquare />
                {intl.formatMessage({
                  id: 'settings.system.time_slots.add_time_slot'
                })}
              </Button>
            </div>
            <div className="br-settings-system__subtitle">
              {intl.formatMessage({
                id: 'settings.system.time_slots.add_time_slot_hint'
              })}
            </div>
          </div>
          {!!timeSlots.length && (
            <div className="br-settings-system__time-slots">
              {timeSlots.map((timeSlot, index) => (
                <>
                  <Descriptions className="br-settings-system__time-slots-table">
                    <Descriptions.Item
                      label={intl.formatMessage({
                        id: 'settings.system.time_slots.start_at'
                      })}
                    >
                      {timeSlot.startTime >= 12
                        ? `${timeSlot.startTime % 12 || 12}:00 PM`
                        : `${timeSlot.startTime}:00 AM`}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={intl.formatMessage({
                        id: 'settings.system.time_slots.end_at'
                      })}
                    >
                      {timeSlot.endTime >= 12
                        ? `${timeSlot.endTime % 12 || 12}:00 PM`
                        : `${timeSlot.endTime}:00 AM`}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Button
                        className="br-settings-system__active-toggle"
                        onClick={() => handleRemoveTimeSlot(timeSlot._id)}
                      >
                        {intl.formatMessage({
                          id: 'settings.system.time_slots.remove'
                        })}
                      </Button>
                    </Descriptions.Item>
                  </Descriptions>
                  {index !== timeSlots.length - 1 && (
                    <Divider className="br-settings-system__time-slots-divider" />
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="br-settings-system__pickups-container">
        <span className="br-settings-system__cod__title">
          {intl.formatMessage({ id: 'settings.system.pickups.title' })}
        </span>
        <div className="br-settings-system__toggle-container">
          <div>
            <div className="br-settings-system__toggle-header">
              <span>
                {intl.formatMessage({
                  id: 'settings.system.pickups.pickups_to_scan'
                })}
              </span>
            </div>
            <div className="br-settings-system__pickups display-flex justify-space-between align-center">
              <span>
                {intl.formatMessage({
                  id: 'settings.system.pickups.number_of_scanning'
                })}
              </span>
              <InputNumber
                value={pickupsScanningNumber}
                min={0}
                step={1}
                onChange={setPickupsScanningNumber}
              />
            </div>
            <div className="br-settings-system__pickups-save display-flex justify-flex-end">
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'settings.system.pickups.save_settings'
                })}
                onClick={setPickupsConfiguration}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(System);
