import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, Descriptions, Tag } from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  MAP_CATEGORY_TO_STRING,
  MAP_STATE_TO_STRING,
  STATES
} from 'constants/bonuses-deductions';
import {
  formatBigMoneyValues,
  capitalizaeFirstLetterOfSentence,
  isUserAuthorized
} from 'utils/helpers';
import { openModal } from 'utils/modal';
import { updateBonusesOrDeductionsState } from 'services/bonuses-deductions';
import { getExtension } from 'utils/bonus-and-dedcution';
import aclMatrix from 'common/aclMatrix';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import RejectionReasonsModal from 'components/Stars/components/BonusesAndDeductions/components/RejectionReasonsModal/RejectionReasonsModal';
import PreviewImage from 'components/PreviewImage/PreviewImage';

import { ReactComponent as closeIcon } from 'assets/imgRevamp/Union.svg';
import excelSheet from 'assets/imgRevamp/excelSheet.png';

import './BonusAndDeductionDetails.less';

const BonusAndDeductionDetails = ({
  intl,
  close,
  values,
  updateTable,
  ...props
}) => {
  const openConfirmationModal = () => {
    openModal(BRConfirmationModal, {
      title: intl.formatMessage(
        {
          id: 'bonuses_deductions.details_modal.approve_confirmation_title'
        },
        { type: MAP_CATEGORY_TO_STRING[values?.type] }
      ),
      message: intl.formatMessage(
        {
          id: 'bonuses_deductions.details_modal.approve_confirmation_message'
        },
        { type: MAP_CATEGORY_TO_STRING[values?.type] }
      ),
      onConfirm: () => {
        handleApprove();
      }
    });
  };

  const openRejectionModal = () => {
    openModal(RejectionReasonsModal, {
      onSuccess: (rejectionReason) => {
        handleReject(rejectionReason);
      }
    });
  };

  const handleApprove = async () => {
    try {
      const payload = {
        id: values.id,
        state: STATES[1].value
      };
      await updateBonusesOrDeductionsState(payload);
      updateTable();
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleReject = async (rejectionReason) => {
    try {
      const payload = {
        id: values.id,
        state: STATES[2].value,
        rejection_reason: rejectionReason
      };
      await updateBonusesOrDeductionsState(payload);
      updateTable();
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  const handlePreviewImage = (imageUrl) => {
    openModal(PreviewImage, {
      previewImage: imageUrl
    });
  };

  const handleDownloadFile = async (file) => {
    window.open(file, '_blank').focus();
  };

  return (
    <Modal
      wrapClassName="br-bonus-and-deduction-details"
      onCancel={close}
      footer={null}
      title={null}
      width={null}
      {...props}
    >
      <BRContentHeader
        title={
          <>
            <span>{MAP_CATEGORY_TO_STRING[values?.type]} Details</span>
            <span onClick={close}>
              <Icon component={closeIcon} />
            </span>
          </>
        }
      />
      <Descriptions>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.columns.star_name'
          })}
        >
          {values?.star_name}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({ id: 'bonuses_deductions.columns.hub' })}
        >
          {values?.hub_name || 'N/A'}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.columns.amount'
          })}
        >
          {formatBigMoneyValues(values?.amount)}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.details_modal.labels.status'
          })}
          className="br-bonus-and-deduction-details__status"
        >
          <Tag
            className={`br-bonus-and-deduction-details__status_${MAP_STATE_TO_STRING[
              values?.state
            ]?.toLowerCase()}`}
          >
            {MAP_STATE_TO_STRING[values?.state]}
          </Tag>
          {
            <span>
              {values?.action_taker_role
                ? `${capitalizaeFirstLetterOfSentence(
                    values?.action_taker_role.replace(/_/g, ' ')
                  )}:`
                : ''}{' '}
              {values?.action_taker_name || ''}
            </span>
          }
          {values?.action_time && (
            <span>
              / {dayjs.utc(values.action_time).tz().format('ddd, D MMM YYYY')}
            </span>
          )}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.columns.tracking_number'
          })}
        >
          {values?.tracking_number || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.columns.pickup_request_id'
          })}
        >
          {values?.pickup_request_id || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.details_modal.labels.images'
          })}
        >
          {values?.json_col?.imageUrls?.length
            ? values?.json_col?.imageUrls?.map((image) =>
                ['xls', 'xlsx', 'CSV'].includes(
                  getExtension(image).toLowerCase()
                ) ? (
                  <img
                    onClick={() => handleDownloadFile(image)}
                    className="br-bonus-and-deduction-details__image"
                    src={excelSheet}
                  />
                ) : (
                  <img
                    onClick={() => handlePreviewImage(image)}
                    className="br-bonus-and-deduction-details__image"
                    src={image}
                  />
                )
              )
            : 'N/A'}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'bonuses_deductions.columns.reason'
          })}
        >
          {values?.reason || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Added By">
          {capitalizaeFirstLetterOfSentence(
            values?.user_role.replace(/_/g, ' ')
          )}
          : {values?.user_name || 'N/A'}
        </Descriptions.Item>
        {values?.rejection_reason && (
          <Descriptions.Item
            label={intl.formatMessage({
              id: 'bonuses_deductions.details_modal.labels.rejection_reason'
            })}
          >
            {values?.rejection_reason}
          </Descriptions.Item>
        )}
      </Descriptions>
      {values?.state === STATES[0].value && (
        <div className="br-bonus-and-deduction-details__footer">
          <Button
            type="danger"
            onClick={openRejectionModal}
            disabled={
              !isUserAuthorized(aclMatrix.APPROVE_REJECT_BONUS_AND_DEDUCTION)
            }
          >
            {intl.formatMessage({
              id: 'bonuses_deductions.actions.reject'
            })}
          </Button>
          <Button
            type="primary"
            onClick={openConfirmationModal}
            disabled={
              !isUserAuthorized(aclMatrix.APPROVE_REJECT_BONUS_AND_DEDUCTION)
            }
          >
            {intl.formatMessage({
              id: 'bonuses_deductions.actions.approve'
            })}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default injectIntl(BonusAndDeductionDetails);
