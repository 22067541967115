import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, Form, Input, Select } from 'antd';

import { getAllHubZones } from 'services/stars';
import { mediaHook } from 'customHooks';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';

import './CreateStarSalaryZoneModel.less';

class CreateStarSalaryZoneModel extends React.Component {
  state = {
    hubZones: []
  };
  AllHubZones = (values) => {
    this.setState({ hubZones: values });
  };
  formRef = React.createRef();

  onFinish = async (formValues) => {
    const { close, onSuccess } = this.props;
    const { hubZones } = this.state;

    formValues.zoneId = hubZones.find(
      (el) => el.zoneName === formValues.similarZone
    )?._id;

    onSuccess(formValues);
    close();
  };

  render() {
    const { close, intl, allHubs } = this.props;
    const { hubZones } = this.state;
    const getHubZones = async (value) => {
      this.formRef.current.setFieldsValue({
        similarZone: null
      });
      const { data } = await getAllHubZones(value);
      this.AllHubZones(data);
    };
    return (
      <Modal
        {...this.props}
        onCancel={close}
        wrapClassName="br-create-star-salary-zone"
        width={null}
        footer={null}
      >
        <BRContentHeader
          title={intl.formatMessage({
            id: `stars.create_edit_star.create_star_salary_zone_modal.add_new_zone`
          })}
        />
        <Form ref={this.formRef} scrollToFirstError onFinish={this.onFinish}>
          <div className="br-create-star-salary-zone__content">
            <div className="br-create-star-salary-zone__form">
              <Form.Item
                label={intl.formatMessage({
                  id: 'stars.create_edit_star.create_star_salary_zone_modal.label.new_zone_name'
                })}
                name="newZone"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'stars.create_edit_star.create_star_salary_zone_modal.placeholders.zone_name'
                  })}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="warehouseInfo"
                label={intl.formatMessage({
                  id: 'stars.create_edit_star.form_labels.hub'
                })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={intl.formatMessage({
                    id: `form.select_placeholder`
                  })}
                  showSearch
                  autoComplete={`${Math.random()}`}
                  allowClear
                  onChange={getHubZones}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allHubs &&
                    allHubs.map((hub) => (
                      <Select.Option key={hub._id} value={hub._id}>
                        {hub.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'stars.create_edit_star.create_star_salary_zone_modal.label.similarZone'
                })}
                name="similarZone"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Select
                  placeholder={intl.formatMessage({
                    id: 'form.select_placeholder'
                  })}
                  allowClear
                >
                  {hubZones?.map((zone) => (
                    <Select.Option key={zone._id} value={zone.zoneName}>
                      {zone.zoneName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <span className="br-create-star-salary-zone__actions">
            <BRButton
              type="basic"
              className="button-sm"
              label={intl.formatMessage({
                id: 'common.cancel'
              })}
              onClick={close}
            />
            <Button htmlType="submit" type="danger">
              {intl.formatMessage({
                id: 'common.save'
              })}
            </Button>
          </span>
        </Form>
      </Modal>
    );
  }
}

export default mediaHook(injectIntl(CreateStarSalaryZoneModel));
