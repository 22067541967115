import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Table, Popover, Button, Upload } from 'antd';

import { fmt } from 'IntlWrapper/IntlWrapper';
import { shrinkText } from 'utils/orders';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import SheetIcon from 'assets/imgRevamp/sheets.svg';

import './MassUploadErrorTable.less';

const columns = [
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.field_names'
    }),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.issue'
    }),
    dataIndex: 'issue',
    key: 'issue'
  },
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.in_rows'
    }),
    dataIndex: 'rows',
    key: 'rows',
    render: (text) => {
      const { editedText, edited } = shrinkText(text);
      return edited ? (
        <>
          {editedText}...{' '}
          <Popover content={text} trigger="click" placement="bottom">
            <a href="#">
              {fmt({
                id: 'orders.mass_upload.error_table.columns.view_more'
              })}
            </a>
          </Popover>
        </>
      ) : (
        editedText
      );
    }
  }
];

class MassUploadErrorTable extends Component {
  componentDidMount() {
    const { intl, errorArray, businessName, orders, setShowMassUploadHeader } =
      this.props;
    notify(
      intl.formatMessage({
        id: 'orders.mass_upload.error_table.title'
      })
    );
    sendSegment(
      'E_ADMIN_UPLOAD_FAILED',
      {
        'Business Name': businessName,
        'number of orders': orders,
        'No of errors': errorArray.length,
        'Error type': errorArray.map((error) => error.issue).join(', ')
      },
      'Failed'
    );
    setShowMassUploadHeader(false);
  }

  onCancelErrorClick = () => {
    this.props.onCancel();
  };

  render() {
    const {
      intl,
      errorArray,
      file,
      orders,
      uploadProps,
      onReUpload,
      isFulfillment
    } = this.props;

    return (
      <div>
        <BRContentHeader
          title={intl.formatMessage({
            id: `orders.mass_upload${isFulfillment ? '.fulfillment' : ''}.title`
          })}
          subtitle={intl.formatMessage({
            id: `orders.mass_upload${
              isFulfillment ? '.fulfillment' : ''
            }.sub_title`
          })}
          className="br-mass-upload__title"
        />

        <div className="br-mass__upload__error-file">
          <img alt="" src={SheetIcon} />
          <div className="br-mass__upload__error-file__info">
            <p className="br-mass__upload__error-file__info__file-name">
              {intl.formatMessage(
                {
                  id: 'orders.mass_upload.error_table.file_name'
                },
                {
                  fileName: file.name
                }
              )}
            </p>
            <p>
              {intl.formatMessage(
                {
                  id: 'orders.mass_upload.error_table.file_size'
                },
                { fileSize: file.size.toFixed(2) }
              )}
            </p>
            <p>
              {intl.formatMessage(
                {
                  id: 'orders.mass_upload.error_table.total_orders'
                },
                { ordersNumber: orders }
              )}
            </p>
          </div>
        </div>
        <div className="br-mass-upload__error-file__options">
          <Button onClick={this.onCancelErrorClick}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Upload {...uploadProps}>
            <Button type="primary" onClick={onReUpload}>
              {intl.formatMessage({
                id: 'orders.mass_upload.error_table.reupload'
              })}
            </Button>
          </Upload>
        </div>
        <p className="br-mass-upload__error-file__issues-found-text">
          {intl.formatMessage(
            {
              id: 'orders.mass_upload.error_table.sub_title'
            },
            { issuesNumber: errorArray.length }
          )}
        </p>
        <p className="br-mass-upload__error-file__table-title">
          {intl.formatMessage({
            id: 'orders.mass_upload.error_table.table_title'
          })}
        </p>
        <Table
          dataSource={errorArray}
          columns={columns}
          pagination={false}
          className="br-mass-upload__errors-table"
        />
      </div>
    );
  }
}

export default injectIntl(withRouter(MassUploadErrorTable));
