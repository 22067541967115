import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { get as getValue, pick } from 'lodash';
import { connect } from 'react-redux';
import styles from '../../../styles.module.scss';
import {
  clearStarDebriefingReport,
  getHubs,
  getStarDayReport,
  getStarLiabilities,
  getStarsByHub
} from 'actions';
import CodDebrief from '../../../PackageDebrief/CodDebrief';
import { notify } from 'components/Notify/Notify';
import { collectStarMoney } from 'actions/HubsActions';
import { formatOptions } from 'utils';
import { getHubStars } from 'services/hubs';
import { getCCODLiabilityAmount } from 'services/ccod';
import { isCcodVisible } from 'common/countries/countries-mapping';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

const hub = JSON.parse(localStorage.getItem('userInfo'))?.warehouseInfo || '';

function DebriefCash({
  starLiabilities,
  clearStarReportData,
  startDebriefing,
  selectedStar,
  setSelectedStar,
  totalCCODAmount,
  setTotalCCODAmount,
  selectedHub,
  setSelectedHub,
  setTotalBTAmount,
  totalBtAmount,
  ...props
}) {
  const [debriefingData, setDebriefingData] = useState([]);

  const requiredParams =
    (fn, condition) =>
    (...args) => {
      if (condition && !condition()) {
        return;
      }
      fn(...args);
    };

  const collectCashMoneyConditions = () => {
    if (selectedStar === null || debriefingData.length === 0) {
      notify('Missing debriefing data!', 'warning');
      return false;
    }
    return true;
  };
  const codTableClear = useRef(null);
  const confirmCashCollection = requiredParams(async () => {
    let errorArr = [];
    const cashMoneyArray = debriefingData.map((el) =>
      pick(el, ['depositType', 'amount', 'receiptNo', 'receiptDate'])
    );
    cashMoneyArray.forEach((item, index) => {
      if (item.depositType === 'Fawry Deposit') {
        for (
          let indexNumber = index + 1;
          indexNumber <= cashMoneyArray.length - 1;
          indexNumber++
        ) {
          if (cashMoneyArray[indexNumber].receiptNo === item.receiptNo) {
            notify(
              `You have Fawary Deposit duplicate Receipt No.	in row ${index},${indexNumber} `
            );
            errorArr.push(true);
          }
        }
      }
    });
    if (errorArr.length === 0) {
      const res = await collectStarMoney({
        cashMoneyArray,
        starId: selectedStar.value
      });
      if (res) {
        clearStarReportData();
        setDebriefingData([]);
        codTableClear?.current?.clear();
        setSelectedStar(null);
        setSelectedHub(null);
        setTotalCCODAmount(0);
        setTotalBTAmount(0);
      }
    }
  }, collectCashMoneyConditions);

  return (
    <div className={`${false ? 'dimmed' : ''} `}>
      <CodDebrief
        ref={codTableClear}
        debreifingData={debriefingData}
        setDebriefingData={setDebriefingData}
        confirmCashCollection={confirmCashCollection}
        starLiabilities={starLiabilities}
      />
    </div>
  );
}

export default DebriefCash;
