import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import Icon from '@ant-design/icons';

import DeliveryStateFormatter from 'utils/orderStatesLogsFormatter';
import { TIMELINE_STATES, STATES } from 'constants/order-states';
import { dates, checkSmartDate } from 'utils/helpers';

import ShipmentDetailsTracking from '../ShipmentDetailsTracking/ShipmentDetailsTracking';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as DownArrow } from 'assets/bosta-icons/Chevron-down.svg';
import { ReactComponent as UpArrow } from 'assets/bosta-icons/Chevron-up.svg';

import './ShipmentDetailsTimeline.less';

const ShipmentDetailsTimeline = ({ intl, shipmentDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [haveManyLogs, setHaveManyLogs] = useState(false);

  const getCurrentStep = () => {
    if (
      STATES.NEW.includes(shipmentDetails?.state?.code) &&
      ![
        intl.formatMessage({
          id: `orders.order_new_statues.ready_for_collection`
        }),
        intl.formatMessage({
          id: `orders.order_new_statues.ready_for_pickup`
        })
      ].includes(DeliveryStateFormatter.getStateName(shipmentDetails))
    ) {
      return TIMELINE_STATES.NEW;
    } else if (STATES.COMPLETED.includes(shipmentDetails?.state?.code)) {
      return TIMELINE_STATES.COMPLETED;
    } else {
      return TIMELINE_STATES.PROCESSING;
    }
  };

  const getTitle = (stepNumber) => {
    if (getCurrentStep() === stepNumber) {
      return DeliveryStateFormatter.getStateName(shipmentDetails);
    } else {
      return intl.formatMessage({
        id: `orders.order_status.${
          stepNumber === TIMELINE_STATES.NEW
            ? 'created'
            : stepNumber === TIMELINE_STATES.PROCESSING
            ? 'processing'
            : 'completed'
        }`
      });
    }
  };

  const getStateDate = () => {
    return `${checkSmartDate(shipmentDetails?.updatedAt, 'ddd')}${dates(
      shipmentDetails?.updatedAt,
      ', D MMM'
    )}`;
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="br-shipment-details__timeline">
        {shipmentDetails?._id && (
          <div className="br-shipment-details__timeline__container">
            <div
              className={classnames(
                'br-shipment-details__steps',
                `br-shipment-details__steps-${getCurrentStep()}`,
                `br-shipment-details__steps-${DeliveryStateFormatter.getStateColor(
                  shipmentDetails
                )}`
              )}
            >
              <div className="br-shipment-details__steps-stepper">
                <div className="br-shipment-details__steps-dots">
                  <span className="br-shipment-details__steps-dot"></span>
                  <span className="br-shipment-details__steps-dot"></span>
                  <span className="br-shipment-details__steps-dot"></span>
                </div>
                <div className="br-shipment-details__steps-track">
                  <div className="br-shipment-details__steps-progress"></div>
                </div>
              </div>
              <div className="br-shipment-details__steps-description">
                <div className="br-shipment-details__steps-description-0">
                  <span className="br-shipment-details__steps-state-name-0">
                    {getTitle(TIMELINE_STATES.NEW)}
                  </span>
                  {getCurrentStep() === TIMELINE_STATES.NEW && (
                    <span className="br-shipment-details__steps-date caption-lg">
                      {getStateDate()}
                    </span>
                  )}
                </div>
                <div className="br-shipment-details__steps-description-1">
                  <span className="br-shipment-details__steps-state-name-1">
                    {getTitle(TIMELINE_STATES.PROCESSING)}
                  </span>
                  {getCurrentStep() === TIMELINE_STATES.PROCESSING && (
                    <span className="br-shipment-details__steps-date caption-lg">
                      {getStateDate()}
                    </span>
                  )}
                </div>
                <div className="br-shipment-details__steps-description-2">
                  <span className="br-shipment-details__steps-state-name-2">
                    {getTitle(TIMELINE_STATES.COMPLETED)}
                  </span>
                  {getCurrentStep() === TIMELINE_STATES.COMPLETED && (
                    <span className="br-shipment-details__steps-date caption-lg">
                      {getStateDate()}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div
              className={classnames(
                'br-shipment-details__description',
                DeliveryStateFormatter.getStateColor(shipmentDetails)
              )}
            >
              <div className="br-shipment-details__description-text">
                <span className="br-shipment-details__timeline__footer__next_action_label">
                  {DeliveryStateFormatter.getStateDescription(shipmentDetails)}
                </span>
              </div>
            </div>

            <div onClick={handleExpandClick}>
              <ShipmentDetailsTracking
                shipmentDetails={shipmentDetails}
                isExpanded={isExpanded}
                setHaveManyLogs={setHaveManyLogs}
                haveManyLogs={haveManyLogs}
              />
            </div>
            {haveManyLogs && (
              <BRButton
                type="link-color"
                className="button br-shipment-details__expand-collapse"
                label={intl.formatMessage({
                  id: `order_details.${isExpanded ? 'collapse' : 'expand'}`
                })}
                suffixIcon={
                  <Icon
                    component={isExpanded ? UpArrow : DownArrow}
                    className="ant-icon-md"
                  />
                }
                onClick={handleExpandClick}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default injectIntl(withRouter(ShipmentDetailsTimeline));
