import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, Form, Input, Select } from 'antd';

import { CATEGORIES } from 'constants/bonuses-deductions';
import {
  createBonusOrDeduction,
  editBonusesOrDeductions
} from 'services/bonuses-deductions';
import { getTypeReasons } from 'utils/bonus-and-dedcution';
import { bonusAndDeductionAmountRule } from 'utils/forms';
import { cleanEmptyString } from 'utils/helpers';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import UploadAttachment from 'components/Stars/components/BonusesAndDeductions/components/UploadAttachment/UploadAttachment';

import './AddEditBonusesAndDeductions.less';

const AddEditBonusesAndDeductions = (props) => {
  const { close, values, intl, initialValues, onSuccess } = props;
  const [categoryType, setCategoryType] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsInitialValues, setAttachmentsInitialValues] = useState([]);

  const [isSendLoading, setIsSendLoading] = useState(false);
  const formRef = useRef('');

  const onFinish = async (formValues) => {
    const attachmentsURLS = attachments.map((attachment) => attachment.url);
    let payload = {
      ...formValues,
      json_col: { imageUrls: attachmentsURLS }
    };

    if (initialValues) {
      try {
        payload = {
          ...payload,
          id: initialValues?.id
        };
        await editBonusesOrDeductions(
          cleanEmptyString({ ...payload, id: initialValues?.id })
        );
        if (onSuccess) {
          onSuccess();
        }
        close();
      } catch (error) {
        notify(error.message);
      }
    } else {
      try {
        payload = {
          ...payload,
          starInfo: {
            ...values.profile,
            _id: values?.key
          },
          warehouseInfo: values?.warehouseInfo
        };
        setIsSendLoading(true);
        await createBonusOrDeduction(cleanEmptyString(payload));
        if (onSuccess) {
          onSuccess();
        }
        close();
      } catch (error) {
        notify(error.message);
      }
    }

    setIsSendLoading(false);
  };
  useEffect(() => {
    if (initialValues) {
      setAttachmentsInitialValues(initialValues?.json_col?.imageUrls || []);
      setCategoryType(initialValues?.type);
      formRef.current.setFieldsValue({
        pickup_request_id: initialValues?.pickup_request_id,
        hub_id: initialValues?.hub_id,
        reason: initialValues?.reason,
        tracking_number: initialValues?.tracking_number,
        notes: initialValues?.notes,
        type: initialValues?.type,
        amount: initialValues?.amount
      });
    }
  }, [initialValues]);
  return (
    <Modal
      {...props}
      onCancel={close}
      wrapClassName="br-add-edit-bonuses-and-decductions"
      width={null}
      footer={null}
    >
      <BRContentHeader
        title={intl.formatMessage({
          id: `bonuses_deductions.create_edit_modal.${
            initialValues ? 'edit_title' : 'create_title'
          }`
        })}
      />
      <Form
        layout="vertical"
        ref={formRef}
        scrollToFirstError
        onFinish={onFinish}
      >
        <div className="br-add-edit-bonuses-and-decductions__content">
          <div className="br-add-edit-bonuses-and-decductions__form">
            <Form.Item
              label={intl.formatMessage({
                id: 'bonuses_deductions.columns.amount'
              })}
              name="amount"
              rules={[
                {
                  required: true
                },
                bonusAndDeductionAmountRule('Maximum amount is 10,000 EGP')
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.add_amount'
                })}
                allowClear
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'bonuses_deductions.columns.type'
              })}
              name="type"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.select'
                })}
                onChange={(value) => {
                  setCategoryType(value);
                  formRef.current.setFieldsValue({
                    reason: null
                  });
                }}
                allowClear
              >
                {CATEGORIES.map((category) => (
                  <Select.Option key={category.value} value={category.value}>
                    {category.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <>
                  {intl.formatMessage({
                    id: 'bonuses_deductions.columns.tracking_number'
                  })}
                  <span className="br-form-optional-label">
                    {intl.formatMessage({
                      id: 'form.optional_label'
                    })}
                  </span>
                </>
              }
              name="tracking_number"
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.add_tracking_number'
                })}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="pickup_request_id"
              label={
                <>
                  {intl.formatMessage({
                    id: 'bonuses_deductions.columns.pickup_request_id'
                  })}
                  <span className="br-form-optional-label">
                    {intl.formatMessage({
                      id: 'form.optional_label'
                    })}
                  </span>
                </>
              }
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.add_pickup_request_id'
                })}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="reason"
              label={intl.formatMessage({
                id: 'bonuses_deductions.columns.reason'
              })}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.select'
                })}
                allowClear
              >
                {categoryType !== null &&
                  getTypeReasons(categoryType).map((reason) => (
                    <Select.Option key={reason} value={reason}>
                      {reason}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="notes"
              label={
                <>
                  {intl.formatMessage({
                    id: 'bonuses_deductions.columns.notes'
                  })}
                  <span className="br-form-optional-label">
                    {intl.formatMessage({
                      id: 'form.optional_label'
                    })}
                  </span>
                </>
              }
            >
              <Input.TextArea
                placeholder={intl.formatMessage({
                  id: 'bonuses_deductions.create_edit_modal.placeholders.add_notes'
                })}
                autoSize={{ minRows: 6, maxRows: 6 }}
                allowClear
              />
            </Form.Item>
          </div>
          <div className="br-add-edit-bonuses-and-decductions__upload-attachments">
            <UploadAttachment
              onChange={({ attachments }) => setAttachments(attachments)}
              initialValues={attachmentsInitialValues}
            />
          </div>
        </div>
        <span className="br-add-edit-bonuses-and-deductions__actions">
          <Button
            disabled={isSendLoading}
            loading={isSendLoading}
            onClick={close}
          >
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button
            htmlType="submit"
            type="danger"
            disabled={isSendLoading}
            loading={isSendLoading}
          >
            {intl.formatMessage({
              id: 'common.save'
            })}
          </Button>
        </span>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddEditBonusesAndDeductions);
