import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router';

import { deleteVendor, searchVendor } from 'services/vendor';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { openModal } from 'utils/modal';

import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTable from 'components/BRTable/BRTable';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import BRButton from 'components/BRButton/BRButton';
import VendorsSearch from './components/VendorsSearch/VendorsSearch';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

import './VendorsListing.less';

const VendorsListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const refreshTable = useRef();

  const intl = useIntl();
  const history = useHistory();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleDeleteVendor = async (vendorId) => {
    setIsLoading(true);
    try {
      await deleteVendor(vendorId);
      refreshTable.current({ pageNumber: 1, filterValues: searchValues });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDeleteActionClick = (vendorId) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'vendors.delete_confirmation_modal.title'
      }),
      content: intl.formatMessage({
        id: 'vendors.delete_confirmation_modal.content'
      }),
      modalAction: () => handleDeleteVendor(vendorId)
    });
  };

  const vendorsTableColumns = [
    {
      key: 'companyName',
      dataIndex: 'companyName',
      title: intl.formatMessage({ id: 'vendors.table_columns.company_name' }),
      fixed: 'left'
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: intl.formatMessage({ id: 'vendors.table_columns.phone' })
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: intl.formatMessage({ id: 'vendors.table_columns.address' })
    },
    {
      title: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item
                className="br-delete-action"
                onClick={() => {
                  handleDeleteActionClick(row.key);
                }}
              >
                {intl.formatMessage({ id: 'common.delete' })}
              </Menu.Item>
            </Menu>
          }
        >
          <BRButton
            type="table-actions"
            className="button-sm"
            suffixIcon={<Actions />}
          />
        </Dropdown>
      )
    }
  ];

  const formatVendors = (vendors) =>
    vendors.map(({ _id, companyName, phone, address }) => ({
      key: _id,
      companyName: (
        <Link
          className="br-vendor__name"
          to={`/stars/vendors/edit-vendor/${_id}`}
        >{`${companyName}`}</Link>
      ),
      phone: phone,
      address: address || intl.formatMessage({ id: 'common.empty_field' })
    }));

  const fetchVendors = async ({ page, filterValues }) => {
    setIsLoading(true);

    try {
      const payload = {
        pageId: page - 1,
        ...(filterValues ? { ...filterValues } : { ...searchValues })
      };
      const { vendors, count } = await searchVendor(payload);

      setCount(count);
      setIsLoading(false);

      return {
        list: formatVendors(vendors),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleCreateNewVendor = () => {
    history.push('/stars/vendors/create-vendor');
  };

  const handleOnSubmit = (filterValues) => {
    setSearchValues(filterValues);
    refreshTable.current({ pageNumber: 1, filterValues });
  };

  return (
    <Container
      className="br-vendors-listing__container"
      header={<BRHeader title={intl.formatMessage({ id: 'vendors.title' })} />}
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <div className="br-vendors__header">
              <BRButton
                type="primary"
                disabled={!isUserAuthorized(aclMatrix.VENDOR)}
                label={intl.formatMessage({ id: 'vendors.new_vendor' })}
                onClick={handleCreateNewVendor}
              />
            </div>
            <VendorsSearch handleOnSubmit={handleOnSubmit} />
            <BRTable
              title={intl.formatMessage(
                { id: 'vendors.table_title' },
                {
                  count
                }
              )}
              columns={vendorsTableColumns}
              listFunction={fetchVendors}
              shareMethods={acceptMethods}
              showFilter={false}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default VendorsListing;
