import { fmt } from 'IntlWrapper/IntlWrapper';

export const ACTIVE_ROUTE = 'Active Route';
export const PENDING = 'Pending';
export const REJECTED = 'Rejected';
export const COLLECTED = 'Collected';
export const IN_TRANSIT = 'In-transit';
export const INVESTIGATION = 'Investigation';
export const DEPOSITED = 'Deposited';
export const SETTLED = 'Settled';
export const CASHIER_ROLE = 'cashier';
export const ACCOUNTANT_ROLE = 'accountant';

export const APPROVE_ACTION = 'approve';
export const REJECT_ACTION = 'rejection';

export const STATES_COLOR = {
  Pending: 'yellow',
  'In-transit': 'yellow',
  Deposited: 'blue',
  Rejected: 'red',
  Investigation: 'red',
  Collected: 'green',
  'Active Route': 'green',
  Settled: 'green'
};

const STATES_OPTIONS = [
  {
    label: fmt({ id: 'cashier.states.active_route' }),
    value: 'Active Route'
  },
  {
    label: fmt({ id: 'cashier.states.pending' }),
    value: 'Pending'
  },
  {
    label: fmt({ id: 'cashier.states.rejected' }),
    value: 'Rejected'
  },
  {
    label: fmt({ id: 'cashier.states.collected' }),
    value: 'Collected'
  },
  {
    label: fmt({ id: 'cashier.states.deposited' }),
    value: 'Deposited'
  },
  {
    label: fmt({ id: 'cashier.states.settled' }),
    value: 'Settled'
  }
];

export const FILTERS = [
  {
    label: 'States',
    menu: STATES_OPTIONS,
    key: 'state'
  }
];
