import Icon from '@ant-design/icons';

import BRButton from 'components/BRButton/BRButton';

import './POHeader.less';

const POHeader = ({
  icon,
  title,
  secondaryTitle,
  renderTag = () => null,
  actions = [],
  iconOnClick = () => {},
  rightLabel
}) => {
  return (
    <div className="br-po-header">
      <div className="br-po-header__title">
        <span className="br-po-header__title__icon" onClick={iconOnClick}>
          <Icon component={icon} />
        </span>
        <span className="br-po-header__title__text">{title}</span>
        {secondaryTitle && (
          <span className="br-po-header__secondary-title__text">
            {secondaryTitle}
          </span>
        )}
        {renderTag()}
      </div>
      {(!!actions.length || rightLabel) && (
        <div className="br-po-header__actions">
          {rightLabel}
          {actions?.map((btnProps = {}, index) => {
            const { renderTooltip, ...rest } = btnProps;
            const btn = <BRButton key={index} {...rest} />;

            return renderTooltip ? renderTooltip(btn) : btn;
          })}
        </div>
      )}
    </div>
  );
};

export default POHeader;
