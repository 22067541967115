import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select } from 'antd';

import {
  ACTIONS,
  BARCODE_SHEET_TO_VAR_MAP,
  NUMBER_OF_SHEETS,
  NUMBER_OF_STICKERS_PER_SHEET
} from 'constants/hubs';
import { getFormattedNumber } from 'utils/number';
import { openModal } from 'utils/modal';
import { generateBarcodes } from 'services/hubs';
import { getBarcodesSheetTypes } from 'common/countries/countries-mapping';
import { BARCODES_SHEET_TYPES } from 'common/countries/constants/hubs';

import BRButton from 'components/BRButton/BRButton';
import Banner from 'components/Banner/Banner';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Dropdown } from 'assets/bosta-icons/Dropdown.svg';

import './GenerateBarcodeModal.less';

const BARCODE_TYPE_OPTIONS = getBarcodesSheetTypes();

const GenerateBarcodeModal = ({ intl, close, reopenModal, ...rest }) => {
  const [numberOfSheets, setNumberOfSheets] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [printType, setPrintType] = useState(BARCODE_TYPE_OPTIONS[0].value);
  const formRef = useRef();

  const onFinish = async ({ numberOfSheets, sheetType }) => {
    setIsLoading(true);
    try {
      await generateBarcodes({
        [BARCODE_SHEET_TO_VAR_MAP[sheetType]]: numberOfSheets,
        sheetType
      });
      close();
      handleOpenSuccessModal(numberOfSheets);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOpenSuccessModal = (count) => {
    openModal(SuccessModal, {
      title: intl.formatMessage(
        { id: 'barcode_to_awb.success_modal.title' },
        {
          count
        }
      ),
      confirmButtonLabel: intl.formatMessage({
        id: 'barcode_to_awb.success_modal.generate_more_sheets'
      }),
      modalAction: reopenModal
    });
  };

  const handleChange = ({ target, action }) => {
    const { MAX, MIN } = NUMBER_OF_SHEETS[printType];

    const formattedValue =
      action === ACTIONS.INCREMENT
        ? numberOfSheets + 1
        : action === ACTIONS.DECREMENT
        ? numberOfSheets - 1
        : target.value.replace(/\D/g, '');

    const currentValue = formattedValue
      ? Math.max(MIN, Math.min(MAX, Number(formattedValue)))
      : '';

    formRef.current.setFieldsValue({ numberOfSheets: currentValue });
    setNumberOfSheets(currentValue);
  };

  const handleOnPrintTypeChange = (type) => {
    setPrintType(type);
    formRef.current.setFieldsValue({
      numberOfSheets: NUMBER_OF_SHEETS[type].MIN
    });
  };

  return (
    <Modal
      {...rest}
      className="br-generate-barcodes__modal"
      footer={
        <>
          <BRButton
            disabled={isLoading}
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            disabled={isLoading}
            label={intl.formatMessage({
              id: 'barcode_to_awb.generate_barcodes_modal.send_to_email'
            })}
            htmlType="submit"
            form="generateBarcodes"
          />
        </>
      }
      title={
        <>
          <span>
            {intl.formatMessage({
              id: 'barcode_to_awb.generate_barcodes_modal.title'
            })}
          </span>
          <CloseIcon onClick={close} />
        </>
      }
    >
      <LoadingWrapper loading={isLoading}>
        <Form
          initialValues={{
            numberOfSheets: NUMBER_OF_SHEETS[printType].MIN,
            sheetType: printType
          }}
          ref={formRef}
          onFinish={onFinish}
          name="generateBarcodes"
        >
          <Form.Item
            name="sheetType"
            label={intl.formatMessage({
              id: 'barcode_to_awb.generate_barcodes_modal.type_label'
            })}
            rules={[{ required: true }]}
          >
            <Select
              value={printType}
              onChange={handleOnPrintTypeChange}
              options={BARCODE_TYPE_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            name="numberOfSheets"
            label={intl.formatMessage({
              id: `barcode_to_awb.generate_barcodes_modal.${
                printType === BARCODES_SHEET_TYPES.CSV
                  ? 'number_of_barcodes_label'
                  : 'number_of_sheets_label'
              }`
            })}
            rules={[{ required: true }]}
          >
            <Input
              onChange={handleChange}
              suffix={
                <div className="br-generate-barcodes-modal__input-actions">
                  <BRButton
                    disabled={
                      numberOfSheets === NUMBER_OF_SHEETS[printType].MAX
                    }
                    onClick={() => handleChange({ action: ACTIONS.INCREMENT })}
                    prefixIcon={<Dropdown />}
                  />
                  <BRButton
                    disabled={
                      numberOfSheets === NUMBER_OF_SHEETS[printType].MIN
                    }
                    onClick={() => handleChange({ action: ACTIONS.DECREMENT })}
                    prefixIcon={<Dropdown />}
                  />
                </div>
              }
            />
          </Form.Item>
          <span className="br-generate-barcodes__help-text">
            {intl.formatMessage(
              {
                id: `barcode_to_awb.generate_barcodes_modal.${
                  printType === BARCODES_SHEET_TYPES.ZEBRA
                    ? 'csv_zebra_help_text'
                    : 'number_of_sheets_help_text'
                }`
              },
              { count: NUMBER_OF_SHEETS[printType].MAX }
            )}
          </span>
        </Form>
        {printType === BARCODES_SHEET_TYPES.A5 && (
          <div className="br-generate-barcodes__banner">
            <Banner
              subText={intl.formatMessage(
                {
                  id: 'barcode_to_awb.generate_barcodes_modal.number_of_sheets_banner'
                },
                {
                  count: numberOfSheets,
                  stickersCount: getFormattedNumber(
                    numberOfSheets * NUMBER_OF_STICKERS_PER_SHEET
                  )
                }
              )}
              type="info"
            />
          </div>
        )}
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(GenerateBarcodeModal);
