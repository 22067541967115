import { useState, useEffect } from 'react';
import { Select } from 'antd';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './BRFetchSelect.less';

const BRFetchSelect = ({
  options = [],
  className,
  formRef,
  name,
  fetchAPI,
  fetchAPIPayload,
  searchKey,
  fieldNames = { label: 'label', value: 'value' },
  showValueInLabel = false,
  prefixIcon,
  ...props
}) => {
  const [previousSearchValue, setPreviousSearchValue] = useState('');
  const [selectOptions, setSelectOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetchAPI) {
      handleFetchData();
    }
  }, []);

  const handleFetchData = async ({ searchValue } = {}) => {
    setIsLoading(true);
    try {
      const payload = {
        ...fetchAPIPayload,
        [searchKey]: searchValue
      };
      const data = await fetchAPI(payload);
      setSelectOptions(data);
      setPreviousSearchValue(searchValue);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleResetSearchValue = () => {
    previousSearchValue && handleFetchData();
  };

  const OnChangeSelectValue = (value) => {
    if (name && formRef) {
      formRef.current.setFieldsValue({ [name]: value });
    }
    handleResetSearchValue();
  };

  const handleSearch = debounce((searchValue) => {
    handleFetchData({ searchValue });
  }, 350);

  const handleFilter = () => {
    return selectOptions;
  };

  return (
    <div
      className={classnames('br-select__container', {
        'has-icon': prefixIcon
      })}
    >
      {prefixIcon && <div className="br-select__icon">{prefixIcon}</div>}
      <Select
        className={classnames('br-select', className)}
        optionFilterProp="label"
        filterOption={handleFilter}
        onSelect={OnChangeSelectValue}
        onSearch={handleSearch}
        onBlur={handleResetSearchValue}
        dropdownRender={(menu) => (isLoading ? <LoadingWrapper /> : menu)}
        {...props}
      >
        {selectOptions.map((option) => (
          <Select.Option
            key={option[fieldNames.value]}
            value={option[fieldNames.value]}
          >
            {showValueInLabel ? (
              <div className="br-select-value-in-label__container">
                {option[fieldNames.label]}{' '}
                <span>{option[fieldNames.value]}</span>
              </div>
            ) : (
              option[fieldNames.label]
            )}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BRFetchSelect;
