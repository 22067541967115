import React from 'react';
import { Modal } from 'antd';

const BRBasicModal = ({
  title = null,
  footer,
  onCancel,
  onSumbit,
  content,
  close,
  ...rest
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  const handleOnOk = () => {
    if (onSumbit) {
      onSumbit();
    }
    close();
  };
  return (
    <Modal
      {...rest}
      close={close}
      title={title}
      {...((footer || footer === null) && { footer })}
      centered
      onCancel={handleCancel}
      onOk={handleOnOk}
    >
      {content}
    </Modal>
  );
};

export default BRBasicModal;
