import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { DISPATCHING_OVERVIEW_CARDS } from 'constants/dispatching';
import { getFormattedNumber } from 'utils/number';

import './DispatchingOverviewSummary.less';

const DispatchingOverviewSummary = ({ overviewSummary, intl, pageType }) => {
  const { toBe } = overviewSummary;

  return (
    <div className="br-dispatching-overview-summary__container display-flex align-center">
      <div className="br-dispatching-overview-summary__to-be-dispatched subheading display-flex flex-dir-col justify-center ">
        {intl.formatMessage(
          { id: `monitor_hub_operations.${pageType}.overview.summary_title` },
          {
            count: <div className="display-md">{getFormattedNumber(toBe)}</div>
          }
        )}
      </div>
      <div className="br-dispatching-overview-summary__cards display-flex">
        {DISPATCHING_OVERVIEW_CARDS({
          overviewData: overviewSummary,
          pageType
        }).map((card, index) => (
          <div
            className={classnames('br-dispatching-overview-summary__card', [
              card.borderColor
            ])}
            key={index}
          >
            <div
              className={classnames(
                'br-dispatching-overview-summary-count display-md',
                [card?.countColor]
              )}
            >
              {getFormattedNumber(card.count)}
            </div>
            <div className="br-dispatching-overview-summary-title">
              {card.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectIntl(DispatchingOverviewSummary);
