import { useEffect, useRef, useState } from 'react';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { searchReceivingManifests } from 'services/hubs';
import { fetchStars } from 'services/live-ops-priorities';
import { checkSmartDate, cleanEmptyString, openInNewTab } from 'utils/helpers';
import { formatOptions } from 'utils';
import {
  GENERAL_RECEIVING,
  HUB_RECEIVING_MANIFEST_COLUMNS
} from 'constants/hubs';

import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import BRButton from 'components/BRButton/BRButton';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';

import './Manifests.less';

const Manifests = () => {
  const [stars, setStars] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const [count, setCount] = useState(0);

  const refreshTable = useRef();
  const formRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    getStars();
  }, []);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const formatManifests = (manifests) =>
    manifests.map((manifest) => ({
      ...manifest,
      createdAt: checkSmartDate(
        manifest.createdAt,
        'dddd, DD MMM, h:mm A',
        true
      ),
      starName:
        manifest.starName !== GENERAL_RECEIVING ? (
          manifest.starName
        ) : (
          <span className="br-hub-manifests__no-star">
            {intl.formatMessage({ id: 'hubs.receiving_manifest.no_star' })}
          </span>
        ),
      action: (
        <BRButton
          type="table-actions"
          prefixIcon={<Printer />}
          onClick={() => openInNewTab(manifest.sheetUrl)}
        />
      )
    }));

  const fetchManifests = async ({ page, limit, filterValues }) => {
    try {
      const payload = {
        page,
        limit,
        ...(filterValues ? { ...filterValues } : { ...currentFilters })
      };
      const {
        data: { receivingManifests, count }
      } = await searchReceivingManifests(payload);

      setCount(count);

      return {
        list: formatManifests(receivingManifests),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const getStars = async () => {
    try {
      const { message } = await fetchStars();
      setStars(
        formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          message?.stars || []
        )
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = (filterValues) => {
    if (filterValues.createdAt) {
      filterValues.createdAtStart = dayjs(filterValues.createdAt[0])
        .startOf('day')
        .utcOffset(0, true)
        .format();

      filterValues.createdAtEnd = dayjs(filterValues.createdAt[1])
        .endOf('day')
        .utcOffset(0, true)
        .format();

      delete filterValues.createdAt;
    }

    const cleanedFilterValues = cleanEmptyString(filterValues);

    setCurrentFilters(cleanedFilterValues);

    refreshTable.current({
      pageNumber: 1,
      filterValues: cleanedFilterValues
    });
  };

  const handleSearchClear = () => {
    setCurrentFilters({});
    formRef.current.resetFields();
  };

  return (
    <div className="br-hub-manifests__container">
      <Form onFinish={onFinish} ref={formRef}>
        <div className="br-hub-manifests__filters">
          <SearchDateRangePicker
            name="createdAt"
            label={intl.formatMessage({
              id: 'deliveries.search_filters.createdAt'
            })}
          />
          <Form.Item
            name="starId"
            label={intl.formatMessage({
              id: 'deliveries.search_filters.stars'
            })}
          >
            <Select
              options={stars}
              placeholder={intl.formatMessage({ id: 'common.select' })}
              showSearch
              optionFilterProp="label"
              allowClear
            />
          </Form.Item>
        </div>
        <div className="br-hub-manifests__filters-action">
          <BRButton
            type="primary"
            htmlType="submit"
            label={intl.formatMessage({ id: 'common.search' })}
          />
        </div>
      </Form>
      <BRTable
        title={intl.formatMessage(
          { id: 'hubs.receiving_manifest.table_title' },
          {
            count
          }
        )}
        columns={HUB_RECEIVING_MANIFEST_COLUMNS}
        listFunction={fetchManifests}
        showFilter={false}
        shareMethods={acceptMethods}
        doAfterClearSearch={handleSearchClear}
      />
    </div>
  );
};

export default Manifests;
