import { injectIntl } from 'react-intl';

import './ConfirmationToolTip.less';

const ConfirmationToolTip = ({
  type,
  takenBy = '',
  rejectionReason = null,
  intl
}) => {
  return !rejectionReason ? (
    <span>
      {intl.formatMessage(
        {
          id: 'wallet.compensation.approve_reject_by'
        },
        {
          action:
            type === 'approval'
              ? intl.formatMessage({
                  id: 'wallet.compensation.approved'
                })
              : intl.formatMessage({
                  id: 'wallet.compensation.rejected'
                }),
          takenBy
        }
      )}
    </span>
  ) : (
    <div className="br-confirmation-tool-tip-container">
      <div className="br-confirmation-tool-tip__title">
        <span>
          {intl.formatMessage(
            {
              id: 'wallet.compensation.approve_reject_by'
            },
            {
              action: intl.formatMessage({
                id: 'wallet.compensation.rejected'
              }),
              takenBy
            }
          )}
        </span>
      </div>
      {rejectionReason && (
        <p className="br-confirmation-tool-tip__rejection-reason">
          {rejectionReason}
        </p>
      )}
    </div>
  );
};

export default injectIntl(ConfirmationToolTip);
