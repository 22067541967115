import { injectIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { SALES_ORDER_TYPES_OPTIONS } from 'constants/fulfillment-sales-orders';

const TypeFilter = ({ intl, updateFilters, label }) => {
  const onChange = (id) => {
    updateFilters({
      orderType: id
    });
  };

  return (
    <Form.Item
      label={
        label ||
        intl.formatMessage({
          id: 'fulfillment_returns.type_filter.label'
        })
      }
    >
      <Select
        options={SALES_ORDER_TYPES_OPTIONS}
        onChange={onChange}
        defaultValue={SALES_ORDER_TYPES_OPTIONS[0].value}
      />
    </Form.Item>
  );
};

export default injectIntl(TypeFilter);
