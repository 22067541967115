import { put, takeLatest } from 'redux-saga/effects';
import actionTypes from '../actions/ActionTypes';
import {
  getAllOnlineStars,
  getRoutesList,
  getRouteRunSheet,
} from '../actions/RoutesActions';
import { endLoading, startLoading } from '../actions';

/**
 * fetch all routes
 * @returns {?}
 */
function* fetchRoutes(action) {
  yield put(startLoading());

  let results = { data: [], count: 0 };
  const res = yield getRoutesList(action.data);

  if (res) {
    const { result, totalCount, pageId, pageLimit } = res;
    results = {
      data: result,
      count: totalCount,
      pagination: { pageId: Number(pageId), pageLimit },
    };
  }
  yield put({ type: actionTypes.GET_ROUTES_LIST_RECEIVED, payload: results });
  yield put(endLoading());
}

/**
 * fetch all online stars
 * @returns {?}
 */
function* fetchAllOnlineStars() {
  let results = [];
  const data = yield getAllOnlineStars();
  if (data) {
    results = data;
  }
  yield put({
    type: actionTypes.GET_ALL_ONLINE_STARS_RECEIVED,
    payload: results,
  });
}

/**
 * fetch route run sheet
 * @returns {?}
 */
function* fetchRouteRunSheet(query) {
  let results = [];
  const data = yield getRouteRunSheet(query.data);
  if (data) {
    results = data;
  }
  yield put({
    type: actionTypes.GET_ROUTE_RUN_SHEET_RECEIVED,
    payload: results,
  });
}

export default function* routesWatcher() {
  yield takeLatest(actionTypes.GET_ROUTES_LIST, fetchRoutes);
  yield takeLatest(actionTypes.GET_ALL_ONLINE_STARS, fetchAllOnlineStars);
  yield takeLatest(actionTypes.GET_ROUTE_RUN_SHEET, fetchRouteRunSheet);
}
