import { useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { formatProductsFromSerials } from 'constants/fulfillment-inventory-serials';

import BRButton from 'components/BRButton/BRButton';
import ProductImageName from 'components/NewFulfillment/CommonComponents/ProductImageName/ProductImageName';

import './IssueDecisionModal.less';

const IssueDecisionModal = ({
  close,
  title,
  serials = [],
  onConfirm,
  onCancel,
  confirmBtnProps = {},
  cancelBtnProps = {},
  wrapClassName = '',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { formatMessage } = useIntl();

  const onOk = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    close();
  };

  const renderFooter = () => (
    <div className="br-issue-decision-modal__footer__actions">
      <BRButton
        label={formatMessage({
          id: 'common.back'
        })}
        onClick={close}
        disabled={isLoading}
        {...cancelBtnProps}
      />
      <BRButton
        type="destructive-primary"
        onClick={onOk}
        loading={isLoading}
        {...confirmBtnProps}
      />
    </div>
  );

  return (
    <Modal
      footer={renderFooter()}
      title={title}
      wrapClassName={classnames('br-issue-decision-modal', wrapClassName)}
      {...props}
    >
      {formatProductsFromSerials({ serials }).map(
        (
          {
            defaultImage,
            productName,
            productNameAr,
            businessName,
            serialNumbers
          },
          index
        ) => (
          <div key={index} className="br-issue-decision-modal__product">
            <div className="br-issue-decision-modal__product-details">
              <ProductImageName
                imgSrc={defaultImage}
                productName={productName || productNameAr}
              />
              <span className="text-gray">{businessName}</span>
            </div>
            <div>
              {serialNumbers.map((serial) => (
                <div key={serial} className="br-issue-decision-modal__serial">
                  {serial}
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </Modal>
  );
};

export default IssueDecisionModal;
