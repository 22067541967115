import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getProblematicJobsStatistics = (payload) => {
  return http.get('/fulfillment-v2/problematic-jobs/statistics', payload);
};

export const getProblematicJobs = (payload) => {
  return http.get('/fulfillment-v2/problematic-jobs', payload);
};

export const exportProblematicJobs = (payload) => {
  return http.get(
    '/fulfillment-v2/problematic-jobs/export',
    payload,
    downloadHeader
  );
};

export const getFulfillmentProblematicJob = (id) => {
  return http.get(`/fulfillment-v2/problematic-jobs/${id}`);
};

export const getProblematicJobLineSerialNumbers = (lineId) => {
  return http.get(`/fulfillment-v2/problematic-jobs/lines/${lineId}/serials`);
};

export const createProblematicJob = (payload) => {
  return http.post(`/fulfillment-v2/problematic-jobs`, payload);
};
