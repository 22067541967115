import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Typography } from 'antd';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { MAP_DELIVERY_TYPES } from 'constants/hubs';

import MultiPackagesCount from 'components/MultiPackages/components/MultiPackagesCount/MultiPackagesCount';

import { ReactComponent as TrashIcon } from 'assets/imgRevamp/trash.svg';

import './NewSwappingContainer.less';

const NewSwappingContainer = ({
  list = {},
  scannedList = {},
  title1,
  title2,
  isMissedFirstMilePickups,
  intl,
  fmPickupsCount,
  showExtraDataInReceivedTable = false,
  useWeights,
  handleOnRemoveReceived,
  groupByBusiness = true,
  handleSizeChange
}) => {
  const { Panel } = Collapse;
  const { Paragraph } = Typography;

  return (
    <>
      <div className="br-swapping-container">
        <div className="br-swapping-container__content left">
          <span className="br-swapping-container__title body-medium">
            {title1}
            {isMissedFirstMilePickups && (
              <span>
                {intl.formatMessage(
                  {
                    id: `hubs.packages_debrief.new_fm_pickup_table.missed_pickup`
                  },
                  {
                    count: fmPickupsCount
                  }
                )}
              </span>
            )}
          </span>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="br-swapping-container__collapse-container"
          >
            {Object.keys(list).map(
              (businessName) =>
                (list[businessName].deliveries?.length ||
                  list[businessName].deliveriesCount) && (
                  <Panel
                    header={
                      <span className="br-new-swapping-container__panel_title">
                        {businessName} (
                        {list[businessName].deliveriesCount ||
                          list[businessName].deliveries?.length}
                        )
                        {isMissedFirstMilePickups && (
                          <span>
                            {intl.formatMessage(
                              {
                                id: `hubs.packages_debrief.new_fm_pickup_table.missed_pickup`
                              },
                              {
                                count:
                                  list[businessName].deliveriesCount ||
                                  list[businessName].deliveries?.length
                              }
                            )}
                          </span>
                        )}
                      </span>
                    }
                    key={list[businessName].businessId}
                    className="site-collapse-custom-panel"
                    collapsible={
                      !list[businessName].deliveries?.length ? 'disabled' : null
                    }
                    showArrow={
                      !list[businessName].deliveries?.length ? false : true
                    }
                  >
                    {list[businessName].deliveries?.map((delivery) => (
                      <div
                        className={classnames(
                          'br-new-swapping-container__panel-content',
                          {
                            'br-new-swapping-container__panel-content-missing':
                              isMissedFirstMilePickups
                          }
                        )}
                      >
                        <span> {delivery.trackingNumber}</span>
                        <span>
                          {MAP_DELIVERY_TYPES[delivery.type] ||
                            intl.formatMessage({
                              id: `common.empty_field`
                            })}
                        </span>
                      </div>
                    ))}
                  </Panel>
                )
            )}
          </Collapse>
        </div>
        <div className="br-swapping-container__content right">
          <span className="br-swapping-container__title body-medium">
            {title2}
          </span>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="br-swapping-container__collapse-container"
          >
            {groupByBusiness
              ? Object.keys(scannedList).map(
                  (businessName) =>
                    (scannedList[businessName].deliveries?.length ||
                      scannedList[businessName].deliveriesCount) && (
                      <Panel
                        header={
                          <span className="br-new-swapping-container__panel_title">
                            {businessName} (
                            {scannedList[businessName].deliveriesCount ||
                              scannedList[businessName].deliveries?.length}
                            )
                          </span>
                        }
                        key={businessName}
                        className="site-collapse-custom-panel"
                        collapsible={
                          !scannedList[businessName].deliveries?.length
                            ? 'disabled'
                            : null
                        }
                        showArrow={
                          !scannedList[businessName].deliveries?.length
                            ? false
                            : true
                        }
                      >
                        {scannedList[businessName].deliveries?.map(
                          (delivery) => (
                            <div
                              className={classnames(
                                'br-new-swapping-container__panel-content',
                                {
                                  'br-swapping-containr-panel__missing-scans':
                                    delivery.multiPackages !==
                                    delivery.scannedTimes
                                }
                              )}
                            >
                              <span> {delivery.trackingNumber}</span>
                              {delivery.multiPackages && (
                                <MultiPackagesCount delivery={delivery} />
                              )}
                              <span>
                                {MAP_DELIVERY_TYPES[delivery.type] ||
                                  intl.formatMessage({
                                    id: `common.empty_field`
                                  })}
                              </span>
                              {showExtraDataInReceivedTable && (
                                <>
                                  <span>{delivery?.state?.value}</span>
                                  {useWeights ? (
                                    <span className="br-new-swapping-container-editable-text">
                                      <Paragraph
                                        editable={{
                                          onChange: (value) =>
                                            handleSizeChange({
                                              value,
                                              delivery,
                                              businessName
                                            }),
                                          triggerType: ['text', 'icon']
                                        }}
                                      >
                                        {intl.formatMessage(
                                          { id: 'common.weight_kg' },
                                          {
                                            weight: delivery.packageWeight
                                          }
                                        )}
                                      </Paragraph>
                                    </span>
                                  ) : (
                                    <span>
                                      {delivery.pricingPackageSize?.name}
                                    </span>
                                  )}

                                  <span>
                                    <TrashIcon
                                      onClick={() =>
                                        handleOnRemoveReceived(
                                          businessName,
                                          delivery
                                        )
                                      }
                                    />
                                  </span>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </Panel>
                    )
                )
              : scannedList.map((delivery) => (
                  <div className="br-new-swapping-container__panel-content br-new-swapping-ungroup">
                    <span> {delivery.trackingNumber}</span>
                    {delivery.multiPackages && (
                      <MultiPackagesCount delivery={delivery} />
                    )}
                    <span>
                      {MAP_DELIVERY_TYPES[delivery.type] ||
                        intl.formatMessage({
                          id: `common.empty_field`
                        })}
                    </span>
                    {showExtraDataInReceivedTable && (
                      <>
                        <span>{delivery?.state?.value}</span>
                        {useWeights ? (
                          <span className="br-new-swapping-container-editable-text">
                            <Paragraph
                              editable={{
                                onChange: (value) =>
                                  handleSizeChange({
                                    value,
                                    delivery
                                  }),
                                triggerType: ['text', 'icon']
                              }}
                            >
                              {delivery.packageWeight}
                            </Paragraph>
                            {intl.formatMessage({ id: 'common.kg' })}
                          </span>
                        ) : (
                          <span>{delivery.pricingPackageSize?.name}</span>
                        )}
                        <span>
                          <TrashIcon
                            onClick={() =>
                              handleOnRemoveReceived(
                                delivery.sender?.name,
                                delivery
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  </div>
                ))}
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default injectIntl(NewSwappingContainer);
