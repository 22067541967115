import { INPUT_TYPES, IS_SAUDI } from 'constants/helper';
import { fmt } from 'IntlWrapper/IntlWrapper';

import BostaBoxIcon from 'assets/imgRevamp/bosta-flyers-box.png';
import BostaBubbleIcon from 'assets/imgRevamp/bosta-flyers-bubble-cushions.png';
import BostaFragileIcon from 'assets/imgRevamp/bosta-flyers-fragile.png';
import BostaSecuritySealIcon from 'assets/imgRevamp/bosta-flyers-security-seal.png';
import BostaSecurityStickerIcon from 'assets/imgRevamp/bosta-flyers-security-stickers.png';
import BostaWhiteBagIcon from 'assets/imgRevamp/bosta-flyers-white-bag.png';
import Barcode from 'assets/bosta-icons/Barcode.svg';
import BostaFlyersIcon from 'assets/imgRevamp/bosta-flyers-icon.png';

export const FREE_ITEMS_ARRAY = [
  {
    key: 'small_flyers',
    title: 'Small Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 30 X 25',
    sizeKsa: 'Size 30 X 25'
  },
  {
    key: 'medium_flyers',
    title: 'Medium Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 40 X 35',
    sizeKsa: 'Size 40 X 35'
  },
  {
    key: 'large_flyers',
    title: 'Large Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 50 X 45',
    sizeKsa: 'Size 45 X 45'
  },
  {
    key: 'x_large_flyers',
    title: 'X-Large Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 60 X 50',
    sizeKsa: 'Size 55 X 55'
  },
  {
    key: 'white_bags_flyers',
    title: 'White bags Flyer',
    icon: BostaWhiteBagIcon,
    sizeEg: 'Size 50 X 100',
    sizeKsa: 'Size 50 X 100'
  }
];

export const PAID_ITEMS_ARRAY = [
  {
    key: 'small_packing_box',
    title: 'Small Packing box',
    icon: BostaBoxIcon,
    sizeEg: 'Size 20 X 13 X 9',
    sizeKsa: 'Size 20 X 13 X 9'
  },
  {
    key: 'medium_packing_box',
    title: 'Medium Packing box',
    icon: BostaBoxIcon,
    sizeEg: 'Size 24 X 20 X 11',
    sizeKsa: 'Size 24 X 21 X 11'
  },
  {
    key: 'large_packing_box',
    title: 'Large Packing box',
    icon: BostaBoxIcon,
    sizeEg: 'Size 35 X 22 X 12',
    sizeKsa: 'Size 35 X 22 X 12'
  },
  {
    key: 'x_large_packing_box',
    title: 'X.Large Packing box',
    icon: BostaBoxIcon,
    sizeEg: 'Size 35 X 27 X 15',
    sizeKsa: 'Size 35 X 27 X 15'
  },
  {
    key: 'fragile_sticker',
    title: 'Fragile Sticker',
    icon: BostaFragileIcon,
    sizeEg: 'Pack of 50',
    sizeKsa: 'Pack of 50'
  },
  {
    key: 'sealed_air_bubble_cushions',
    title: 'Sealed Air - Bubble Cushions',
    icon: BostaBubbleIcon,
    sizeEg: 'Pack of 10',
    sizeKsa: 'Pack of 10'
  },
  {
    key: 'security_seal',
    title: 'Security Seal',
    icon: BostaSecuritySealIcon,
    sizeEg: 'Pack of 50',
    sizeKsa: 'Pack of 50'
  },
  {
    key: 'security_sticker',
    title: 'Security Sticker',
    icon: BostaSecurityStickerIcon,
    sizeEg: 'Pack of 50',
    sizeKsa: 'Pack of 50'
  },
  {
    key: 'smart_sticker',
    title: 'Smart Sticker',
    icon: Barcode,
    sizeEg: 'Pack of 10',
    sizeKsa: 'Pack of 10',
    hide: IS_SAUDI
  },
  {
    key: 'small_flyers',
    title: 'Small Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 30 X 25',
    sizeKsa: 'Size 30 X 25'
  },
  {
    key: 'medium_flyers',
    title: 'Medium Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 40 X 35',
    sizeKsa: 'Size 40 X 35'
  },
  {
    key: 'large_flyers',
    title: 'Large Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 50 X 45',
    sizeKsa: 'Size 45 X 45'
  },
  {
    key: 'x_large_flyers',
    title: 'X-Large Flyer',
    icon: BostaFlyersIcon,
    sizeEg: 'Size 60 X 50',
    sizeKsa: 'Size 55 X 55'
  },
  {
    key: 'white_bags_flyers',
    title: 'White bags Flyer',
    icon: BostaWhiteBagIcon,
    sizeEg: 'Size 50 X 100',
    sizeKsa: 'Size 50 X 100'
  }
];

export const LOG_ACTIONS = {
  availability: 'Update Availability',
  price: 'Update Price',
  balance: 'Update Balance'
};

export const SHIPMENTS_COLUMNS = [
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.order_id`
    }),
    dataIndex: 'order_id',
    fixed: 'left',
    width: 75
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.type`
    }),
    dataIndex: 'type',
    width: 115
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.customer_info`
    }),
    dataIndex: 'customerInfo',
    width: 130
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.location`
    }),
    dataIndex: 'location',
    width: 130
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.amount`
    }),
    dataIndex: 'cod',
    width: 135
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.status`
    }),
    dataIndex: 'status',
    width: 80
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.attempts`
    }),
    dataIndex: 'attempts',
    width: 80
  },
  {
    title: fmt({
      id: `packaging.orders_tab.shipping_orders.table.last_update`
    }),
    dataIndex: 'date',
    width: 120
  }
];

const PACKAGING_FILTER_STATES = [
  {
    label: fmt({
      id: 'deliveries.states.created'
    }),
    value: 'Created'
  },
  {
    label: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    value: 'Received at warehouse'
  },
  {
    label: fmt({
      id: 'deliveries.states.in_transit'
    }),
    value: 'In Transit'
  },
  {
    label: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    value: 'Out for delivery'
  },
  {
    label: fmt({
      id: 'deliveries.states.deliverd_pudo'
    }),
    value: 'Delivered'
  },
  {
    label: fmt({
      id: 'deliveries.states.exception'
    }),
    value: 'Exception'
  },
  {
    label: fmt({
      id: 'deliveries.states.returned_to_origin'
    }),
    value: 'Returned to origin'
  }
];

export const PACKAGING_SHIPPING_ORDERS_TABLE_FILTERS = [
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.order_id'
    }),
    menuType: 'text',
    key: 'trackingNumber'
  },
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.phone_number'
    }),
    menuType: 'text',
    key: 'phoneNumber'
  },
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.ref_num'
    }),
    menuType: INPUT_TYPES.TEXT,
    key: 'orderReference'
  },
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.status'
    }),
    menuType: INPUT_TYPES.RADIO,
    key: 'status',
    menu: PACKAGING_FILTER_STATES
  },
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.updated_at'
    }),
    key: 'updatedAt',
    menuType: INPUT_TYPES.DATE
  },
  {
    label: fmt({
      id: 'packaging.orders_tab.shipping_orders.filters.confirmed_at'
    }),
    key: 'confirmedAt',
    menuType: INPUT_TYPES.DATE
  }
];
