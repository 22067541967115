import { injectIntl } from 'react-intl';

import { ReactComponent as ProfileIcon } from 'assets/bosta-icons/Profile.svg';
import { ReactComponent as PhoneIcon } from 'assets/bosta-icons/Phone.svg';

const CustomerInfoCard = ({ intl, customerInfo }) => {
  const { fullName, firstName = '', lastName = '', phone } = customerInfo || {};

  const customerContent = [
    {
      icon: <ProfileIcon />,
      content: fullName || `${firstName} ${lastName}`
    },
    {
      icon: <PhoneIcon />,
      content: phone
    }
  ];

  return (
    <div className="br-info-card br-po-section">
      <div className="br-po-section__title">
        {intl.formatMessage({
          id: 'fulfillment_outbound.customer_info.title'
        })}
      </div>
      <div className="br-po-section__content">
        {customerContent.map(({ icon, content }, index) => (
          <div key={index} className="br-info-card__item">
            {icon}
            <span>{content}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectIntl(CustomerInfoCard);
