import actionTypes from '../actions/ActionTypes';

const initialState = {
  reports: [],
  reportDetails: [],
  reportCSV: false,
  uploadCSV: false,
  generateArReport: false,
  generateReport: false,
  summaryReportCSV: false,
  reportDetailsCSV: false,
  summaryReports: [],
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_AR_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case actionTypes.SET_AR_REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.payload,
      };
    case actionTypes.SET_AR_GENERATE_REPORT:
      return {
        ...state,
        generateArReport: action.payload,
      };
    case actionTypes.SET_GENERATE_CSV:
      return {
        ...state,
        generateReport: action.payload,
      };
    case actionTypes.SET_AR_REPORT_CSV:
      return {
        ...state,
        reportCSV: action.payload,
      };
    case actionTypes.GET_SUMMARY_REPORT_CSV:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_SUMMARY_REPORT_CSV:
      return {
        ...state,
        summaryReportCSV: action.payload,
        loading: false,
      };
    case actionTypes.SET_UPLOAD_AR_REPORT:
      return {
        ...state,
        uploadCSV: action.payload,
      };
    case actionTypes.SET_AR_REPORT_CSV_DETAILS:
      return {
        ...state,
        reportDetailsCSV: action.payload,
      };
    case actionTypes.SET_SUMMARY_REPORTS:
      return {
        ...state,
        summaryReports: action.payload,
      };
    default:
      return state;
  }
}
