import React from 'react';
import { Spin } from 'antd';

const LoadingWrapper = ({ className = '', loading, children }) => {
  return (
    <Spin className={className} size="middle" spinning={loading}>
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
