import { fmt } from 'IntlWrapper/IntlWrapper';

export const APP_PERFORMANCE_OPTIONS = [
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.attendance'
    }),
    value: 'attendance'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.delivery_sr'
    }),
    value: 'delivery_sr'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.pickup_sr'
    }),
    value: 'pickup_sr'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.total_route_progress'
    }),
    value: 'route_progress'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.total_fake_exceptions'
    }),

    value: 'fake_exceptions'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.total_app_usage'
    }),
    value: 'app_usage'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.total_shortage'
    }),
    value: 'shortage'
  },
  {
    label: fmt({
      id: 'stars_performance.star_performance_tab.total_rating'
    }),
    value: 'rating'
  }
];

export const APP_PERFORMANCE_MAPPING = {
  attendance: fmt({
    id: 'stars_performance.star_performance_tab.attendance'
  }),
  out_for_delivery: fmt({
    id: 'stars_performance.star_performance_tab.ofd'
  }),
  delivery_sr: fmt({
    id: 'stars_performance.star_performance_tab.delivery_sr'
  }),
  pickup_sr: fmt({
    id: 'stars_performance.star_performance_tab.pickup_sr'
  }),
  route_progress: fmt({
    id: 'stars_performance.star_performance_tab.total_route_progress'
  }),
  fake_exceptions: fmt({
    id: 'stars_performance.star_performance_tab.total_fake_exceptions'
  }),
  app_usage: fmt({
    id: 'stars_performance.star_performance_tab.total_app_usage'
  }),
  shortage: fmt({
    id: 'stars_performance.star_performance_tab.total_shortage'
  }),
  rating: fmt({
    id: 'stars_performance.star_performance_tab.total_rating'
  }),
  performance: fmt({
    id: 'stars_performance.star_performance_tab.total_perfomance'
  }),
  max_number_of_fake_exceptions: 'Max No. Of Fake Exceptions'
};
