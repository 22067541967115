import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Checkbox, Select } from 'antd';

import {
  API_KEYS_SCOPES_OPTIONS,
  API_SUPER_ADMIN_ACCESS
} from 'constants/Businesses';

import './CreateEditApiKeyModal.less';

const CreateEditApiKeyModal = ({
  intl,
  close,
  apiKeyInfo,
  onFinish,
  ...rest
}) => {
  const handleOnFinish = async (values) => {
    onFinish(values);
    close();
  };
  return (
    <Modal
      {...rest}
      className="br-api-key__create-edit-modal-container"
      onCancel={close}
      title={intl.formatMessage({
        id: 'business_details.api_integration.create_edit_modal.title'
      })}
      okButtonProps={{
        htmlType: 'submit',
        form: 'createEditKeyForm'
      }}
      okText={intl.formatMessage({
        id: 'common.submit'
      })}
    >
      <Form
        onFinish={handleOnFinish}
        className="br-api-key__create-edit-modal__form"
        name="createEditKeyForm"
        initialValues={{
          ...apiKeyInfo,
          ...(!apiKeyInfo && { isActive: true })
        }}
      >
        <Form.Item
          name="name"
          label={
            <>
              {intl.formatMessage({
                id: 'business_details.api_integration.create_edit_modal.key_name'
              })}
              <span className="br-form-optional-label">
                {intl.formatMessage({
                  id: 'form.optional_label'
                })}
              </span>
            </>
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="apiGroupName"
          label={intl.formatMessage({
            id: 'business_details.api_integration.table_columns.permission'
          })}
        >
          <Select
            options={API_KEYS_SCOPES_OPTIONS}
            defaultValue={API_SUPER_ADMIN_ACCESS}
          />
        </Form.Item>
        <Form.Item name="isActive" valuePropName="checked">
          <Checkbox defaultChecked>
            {intl.formatMessage({
              id: 'business_details.api_integration.create_edit_modal.key_active_state'
            })}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(CreateEditApiKeyModal);
