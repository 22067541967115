import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Tag } from 'antd';
import classnames from 'classnames';
import Icon from '@ant-design/icons';

import {
  getOrderDetails,
  getProofOfReturn,
  getTicketLogs
} from 'services/shipments';
import { DELIVERY_TYPES_CODES } from 'constants/shipments';
import { mediaHook } from 'customHooks';
import DeliveryStateFormatter from 'utils/orderStatesLogsFormatter';
import { dates, checkSmartDate } from 'utils/helpers';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container/index';
import ShipmentDetailsSummary from './components/ShipmentDetailsSummary/ShipmentDetailsSummary';
import ShipmentDetailsTimeline from './components/ShipmentDetailsTimeline/ShipmentDetailsTimeline';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRHeader from 'components/BRHeader/BRHeader';

import { ReactComponent as Left } from 'assets/bosta-icons/Left.svg';
import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';

import './ShipmentDetails.less';

const ShipmentDetails = ({
  intl,
  match: {
    params: { id: shipmentId }
  },
  history,
  mobileScreenSizes: { isSmallMobileScreen }
}) => {
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [isFulfillmentOrder, setIsFulfillmentOrder] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [deliveryDetails, ticketLogs] = await Promise.all([
        getOrderDetails(shipmentId),
        getTicketLogs(shipmentId)
      ]);
      const shipmentDetailsObj = deliveryDetails[0].shipmentDetailsBusinessView;

      setBusinessId(deliveryDetails[0]?.sender?._id);

      if (shipmentDetailsObj.type.code === DELIVERY_TYPES_CODES.FXF_SEND) {
        shipmentDetailsObj.type.code = DELIVERY_TYPES_CODES.SEND;
        setIsFulfillmentOrder(true);
      }

      if (
        shipmentDetailsObj.state.code === 10 &&
        !shipmentDetailsObj.pendingPickup &&
        (shipmentDetailsObj.type.code === 10 ||
          shipmentDetailsObj.type.code === 30 ||
          shipmentDetailsObj.type.code === 35)
      ) {
        shipmentDetailsObj.state.waitingForBusinessAction = true;
      }

      const proofOfReturn = await getProofOfReturn(shipmentDetailsObj._id);

      setShipmentDetails({
        ...shipmentDetailsObj,
        proofOfReturn,
        ticketLogs
      });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleGoBackClick = () => {
    history.push('/deliveries');
  };

  const getTitle = () => {
    return (
      <div
        className={classnames(
          'br-shipment-details__header-tag',
          DeliveryStateFormatter.getStateColor(shipmentDetails)
        )}
      >
        <span>
          {intl.formatMessage(
            {
              id: 'orders.header_title'
            },
            { trackingNumber: shipmentDetails?.trackingNumber || '' }
          )}
        </span>
        <Tag className="caption-lg">
          {DeliveryStateFormatter.getStateName(shipmentDetails)}
          {shipmentDetails?.isConfirmedDelivery && <DoubleCheckIcon />}
        </Tag>
      </div>
    );
  };

  const getSubtitle = () => {
    return `${checkSmartDate(shipmentDetails?.updatedAt, 'ddd')}${dates(
      shipmentDetails?.updatedAt,
      ', D MMM'
    )} ${intl.formatMessage({
      id: 'order_details.at'
    })} ${dates(shipmentDetails?.updatedAt, 'h:mm a')} `;
  };

  useEffect(() => {
    fetchData();
  }, [shipmentId]);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        className="br-shipment-details"
        containerClassName="br-shipment-details__container"
        header={
          <BRHeader
            title={getTitle()}
            showBackArrow
            handleGoBackClick={handleGoBackClick}
          />
        }
        content={
          <>
            {isSmallMobileScreen && (
              <div className="br-shipment-details__actions-small">
                <div className="br-shipment-details__actions-small__details">
                  <Icon
                    component={Left}
                    onClick={handleGoBackClick}
                    className="br-header__go-back-btn ant-icon-md"
                  />
                  <div>
                    <span className="display-sm">{getTitle()}</span>
                    <span className="br-shipment-details__actions-small__date">
                      {getSubtitle()}
                    </span>
                  </div>
                </div>
                {/* <ShipmentDetailsActions
                  shipmentDetails={shipmentDetails}
                  isFulfillmentOrder={isFulfillmentOrder}
                /> */}
              </div>
            )}
            <ShipmentDetailsTimeline
              shipmentDetails={shipmentDetails}
              fetchData={fetchData}
            />
            <ShipmentDetailsSummary
              shipmentDetails={shipmentDetails}
              businessId={businessId}
            />
          </>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(mediaHook(ShipmentDetails)));
