import { fmt } from 'IntlWrapper/IntlWrapper';

import { getFormattedNumber } from 'utils/number';

export const SLLR_DATE_FILTER_FORMAT = 'D MMM, YYYY';

export const SLLR_KPIS_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.stores_created'
    }),
    dataIndex: 'createdStoresCount'
  },
  {
    name: fmt({ id: 'sllr_analytics.sllr_kpis_rows.stores_published' }),
    dataIndex: 'storesCount'
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.stores_active'
    }),
    dataIndex: 'storesWithAtleastTwoCompletedSalesOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.products'
    }),
    render: ({ productsCount, productInventoryCount }) => {
      return `#C_${productsCount} - #I_${productInventoryCount}`;
    }
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.products_active'
    }),
    render: ({ activeProductsCount, productActiveInventoryCount }) => {
      return `#C_${activeProductsCount} - #I_${productActiveInventoryCount}`;
    }
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.active_product_sllr'
    }),
    render: ({ activeSllrProductVariancesCount, activeSllrProductsCount }) => {
      return `#C_${activeSllrProductsCount} - #I_${activeSllrProductVariancesCount}`;
    }
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.average_products_per_store'
    }),
    render: ({ averageProductsPerStore, averageProductInventoryPerStore }) => {
      return `#C_${averageProductsPerStore?.toFixed(
        2
      )} - #I_${averageProductInventoryPerStore?.toFixed(2)}`;
    }
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.average_basket_size'
    }),
    dataIndex: 'avgDraftOrderProductItemsQuantity'
  },
  {
    name: fmt({ id: 'sllr_analytics.sllr_kpis_rows.created_sllr_orders' }),
    dataIndex: 'draftOrdersCount'
  },
  {
    name: fmt({ id: 'sllr_analytics.sllr_kpis_rows.confirmed_sllr_orders' }),
    dataIndex: 'sllrDeliveries'
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.shipped_orders'
    }),
    dataIndex: 'shippedOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.confirmed_sales'
    }),
    dataIndex: 'confirmedSalesCod'
  },
  {
    name: fmt({
      id: 'sllr_analytics.sllr_kpis_rows.created_orders_with_multiple_products'
    }),
    dataIndex: 'withProductDeliveries'
  }
];

export const ORDER_CREATION_SRCS_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders'
    }),
    dataIndex: 'allOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_apis'
    }),
    dataIndex: 'ordersByAPI'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_massupload'
    }),
    dataIndex: 'massUploadOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_plugins'
    }),
    dataIndex: 'ordersByPlugins'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_create_order'
    }),
    dataIndex: 'dashboardOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_sllr_confirm_orders'
    }),
    dataIndex: 'confirmedSllrOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_business_app'
    }),
    dataIndex: 'mobileAppOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.order_creation_sources_rows.orders_sllr_app'
    }),
    dataIndex: 'sllrAppOpers'
  }
];

export const SLLR_ADOPTION_COLUMNS = [
  {
    title: fmt({
      id: 'sllr_analytics.sllr_adoption_rows.kpi_name'
    }),
    dataIndex: 'name'
  },
  {
    title: fmt({
      id: 'sllr_analytics.sllr_adoption_rows.bosta_account'
    }),
    dataIndex: 'bosta'
  },
  {
    title: fmt({
      id: 'sllr_analytics.sllr_adoption_rows.sllr_account'
    }),
    dataIndex: 'sllr'
  }
];

export const TOP_SELLRS_COLUMNS = [
  {
    title: fmt({
      id: 'sllr_analytics.headers.business_name'
    }),
    dataIndex: 'name',
    width: 200,
    fixed: 'left',
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.account_type'
    }),
    dataIndex: 'accountType',
    width: 120
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.created_sllrs'
    }),
    dataIndex: 'isPublished',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.products'
    }),
    dataIndex: 'productsCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.discounted_products'
    }),
    dataIndex: 'activeDiscountProductsCount',
    width: 240,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.store_views'
    }),
    dataIndex: 'storeViewsCount',
    width: 130,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.completed'
    }),
    dataIndex: 'completedSalesOrdersCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.confirmed'
    }),
    dataIndex: 'confirmedDeliveriesCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.shipped'
    }),
    dataIndex: 'shippedSllrOrders',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.total_orders'
    }),
    dataIndex: 'totalDeliveriesCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.store_link'
    }),
    dataIndex: 'storeLink',
    width: 100
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.settings_store_url'
    }),
    dataIndex: 'website',
    width: 120
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.last_confirmed_order_date'
    }),
    dataIndex: 'lastOrderConfirmedOrderAt',
    width: 200,
    sorter: {
      multiple: 1
    }
  }
];

export const SLLR_ACTIVATION_COLUMNS = [
  {
    title: fmt({
      id: 'sllr_analytics.headers.business_name'
    }),
    dataIndex: 'name',
    width: 200,
    fixed: 'left',
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'international_shipping.orders.table_columns.created_at'
    }),
    dataIndex: 'creationDate',
    width: 120
  },
  {
    title: fmt({
      id: 'sllr_analytics.sllr_activation_filters.bosta'
    }),
    dataIndex: 'bosta',
    width: 120
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.created_sllrs'
    }),
    dataIndex: 'isPublished',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.products'
    }),
    dataIndex: 'productsCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.store_views'
    }),
    dataIndex: 'storeViewsCount',
    width: 130,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.completed'
    }),
    dataIndex: 'completedSalesOrdersCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.confirmed'
    }),
    dataIndex: 'confirmedDeliveriesCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.shipped'
    }),
    dataIndex: 'shippedSllrOrders',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.total_orders'
    }),
    dataIndex: 'totalDeliveriesCount',
    width: 120,
    sorter: {
      multiple: 1
    }
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.store_link'
    }),
    dataIndex: 'storeLink',
    width: 100
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.settings_store_url'
    }),
    dataIndex: 'website',
    width: 120
  },
  {
    title: fmt({
      id: 'sllr_analytics.headers.last_confirmed_order_date'
    }),
    dataIndex: 'lastOrderConfirmedOrderAt',
    width: 200,
    sorter: {
      multiple: 1
    }
  }
];

export const SHIPPING_FEES_ADOPTION_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_adoption_rows.published_stores'
    }),
    dataIndex: 'storesCount'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_adoption_rows.disabled_shipping_fees'
    }),
    dataIndex: 'disabledShippingFeesStores'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_adoption_rows.enabled_shipping_fees'
    }),
    dataIndex: 'enabledShippingFeesStores'
  }
];

export const SHIPPING_FEES_TYPES_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_types_rows.free_shipping'
    }),
    dataIndex: 'freeShippingStores'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_types_rows.conditional_free_shipping'
    }),
    dataIndex: 'conditionalFreeShippingStores'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_types_rows.flat_rate'
    }),
    dataIndex: 'flatRateStores'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_types_rows.custom_rate'
    }),
    dataIndex: 'customRateStores'
  }
];

export const SHIPPING_FEES_IMPACT_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders'
    }),
    dataIndex: 'completedOrders_allOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders_disabled_shipping_fees'
    }),
    dataIndex: 'completedOrders_disabledShippingFees'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders_free_shipping'
    }),
    dataIndex: 'completedOrders_freeShipping'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders_conditional_free_shipping'
    }),
    dataIndex: 'completedOrders_conditionalFreeShipping'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders_flat_rate'
    }),
    dataIndex: 'completedOrders_flatRate'
  },
  {
    name: fmt({
      id: 'sllr_analytics.shipping_fees_impact_rows.completed_orders_custom_rate'
    }),
    dataIndex: 'completedOrders_customRate'
  }
];

export const DISCOUNT_PRICE_ADOPTION_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.discount_products_analytics_rows.total_active_products'
    }),
    render: ({ activeProductsCategoryCount, activeProductsInventoryCount }) => {
      return `${
        getFormattedNumber(parseFloat(activeProductsCategoryCount)) || 0
      } - ${
        getFormattedNumber(parseFloat(activeProductsInventoryCount)) || 0
      } `;
    }
  },
  {
    name: fmt({
      id: 'sllr_analytics.discount_products_analytics_rows.active_products_with_discounts'
    }),
    render: ({
      activeDiscountProductsCategoryCount,
      activeDiscountProductsInventoryCount
    }) => {
      return `${
        getFormattedNumber(parseFloat(activeDiscountProductsCategoryCount)) || 0
      } - ${
        getFormattedNumber(parseFloat(activeDiscountProductsInventoryCount)) ||
        0
      } `;
    }
  }
];

export const DISCOUNT_PRICE_IMPACT_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.discount_products_analytics_rows.completed_orders_with_discounted_products'
    }),
    dataIndex: 'completedOrdersWithDiscountedProducts'
  },
  {
    name: fmt({
      id: 'sllr_analytics.discount_products_analytics_rows.completed_orders_total_products'
    }),
    dataIndex: 'completedOrdersTotalProducts'
  },
  {
    name: fmt({
      id: 'sllr_analytics.discount_products_analytics_rows.completed_orders_discounted_products'
    }),
    dataIndex: 'completedOrdersDiscountedProducts'
  }
];

export const PUDO_ADOPTION_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.pudo_adoption_rows.eg_published_stores'
    }),
    dataIndex: 'publishedStores'
  },
  {
    name: fmt({
      id: 'sllr_analytics.pudo_adoption_rows.eg_disabled_pudo'
    }),
    dataIndex: 'pudoDisabled'
  },
  {
    name: fmt({
      id: 'sllr_analytics.pudo_adoption_rows.eg_enabled_pudo'
    }),
    dataIndex: 'pudoEnabled'
  },
  {
    name: fmt({
      id: 'sllr_analytics.pudo_adoption_rows.pudo_shipped_orders'
    }),
    dataIndex: 'puduSllrShippedOrders'
  },
  {
    name: fmt({
      id: 'sllr_analytics.pudo_adoption_rows.pudo_delivered_orders'
    }),
    dataIndex: 'puduSllrDeliveredOrders'
  }
];

export const PRODUCT_VARIANCE_ADOPTION_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.product_variance_adoption_rows.products_inventory'
    }),
    dataIndex: 'productInventoryCount'
  },
  {
    name: fmt({
      id: 'sllr_analytics.product_variance_adoption_rows.products_catalog'
    }),
    dataIndex: 'productCatalogCount'
  },
  {
    name: fmt({
      id: 'sllr_analytics.product_variance_adoption_rows.products_disabled_options'
    }),
    dataIndex: 'productWithDisabledOptions'
  },
  {
    name: fmt({
      id: 'sllr_analytics.product_variance_adoption_rows.products_enabled_options'
    }),
    dataIndex: 'productWithEnabledOptions'
  }
];

export const AVG_PRODUCT_VARIANCES_ROWS = [
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.avg_product_options'
    }),
    dataIndex: 'avgProductOptions'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.avg_product_values'
    }),
    dataIndex: 'avgProductOptionsValues'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_size_options'
    }),
    dataIndex: 'productsUsedSizeOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_color_options'
    }),
    dataIndex: 'productsUsedColorOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_material_option'
    }),
    dataIndex: 'productsUsedMaterialOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_condition_option'
    }),
    dataIndex: 'productsUsedConditionOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_brand_option'
    }),
    dataIndex: 'productsUsedBrandOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_storage_option'
    }),
    dataIndex: 'productsUsedStorageOption'
  },
  {
    name: fmt({
      id: 'sllr_analytics.avg_product_variances.products_used_custom_option'
    }),
    dataIndex: 'productsUsedCustomOption'
  }
];

export const TOP_SELLERS_TABLE_SORTING = {
  ascend: 'asc',
  descend: 'desc'
};

export const PRODUCT_OPTIONS_KEYS = {
  SIZE: 'productsUsedSizeOption',
  STORAGE: 'productsUsedStorageOption',
  COLOR: 'productsUsedColorOption',
  CONDITION: 'productsUsedConditionOption',
  MATERIAL: 'productsUsedMaterialOption',
  BRAND: 'productsUsedBrandOption'
};

export const SLLR_URL = 'https://sllr.co/';
export const SLLR_DATE_FORMAT = 'ddd, DD MMM YY';
export const SLLR_START_DATE = '2022-09-04';

export const SLLR_ACTIVATION_QUICK_FILTERS = [
  {
    label: fmt({
      id: 'sllr_analytics.sllr_activation_filters.all'
    }),
    value: null
  },
  {
    label: fmt({
      id: 'sllr_analytics.sllr_activation_filters.bosta'
    }),
    value: 'DASHBOARD'
  },
  {
    label: fmt({
      id: 'sllr_analytics.sllr_activation_filters.sllr'
    }),
    value: 'SLLR'
  }
];
