import React, { useEffect, useState, useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dropdown, Menu, Select } from 'antd';
import Icon, { MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classnames from 'classnames';

import { downloadAsXlsx } from 'utils';
import {
  CATEGORIES,
  INVENTORY_TABLE_COLUMNS,
  PAGE_SIZE_DEFAULT
} from 'constants/fulfillment';
import { LOCALE } from 'constants/intl-wrapper';
import {
  getProductList,
  getProductDetails,
  exportCSVInventory
} from 'services/fulfillment';
import { BusinessesContext } from 'contexts/fulfilment.context';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRInventoryTable from 'components/BRInventoryTable/BRInventoryTable';
import ProductDetails from 'components/Fulfillment/Inventory/ProductDetails/ProductDetails';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as ActionIcon } from 'assets/imgRevamp/more.svg';

import './Inventory.less';

function Inventory({ intl }) {
  const { businesses, isPageLoading } = useContext(BusinessesContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isProductDetailsloading, setIsProductDetailsloading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [productDetailsData, setProductDetailsData] = useState([]);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [count, setCount] = useState(0);
  const [businessId, setBusinessId] = useState(null);
  const [searchTableInput, setSearchTableInput] = useState('');
  const [selectedFilter, setSelectedFilter] = useState([]);

  const searchPlaceholder = intl.formatMessage({
    id: 'fulfillment.inventory.search_section.search_placeholder'
  });
  const productCountFormat = intl.formatMessage(
    {
      id: 'fulfillment.inventory.products_count'
    },
    { count: count }
  );
  const emptyTableTitle = intl.formatMessage({
    id: 'fulfillment.inventory.table.empty_table_title'
  });
  const emptyTableButtonText = intl.formatMessage({
    id: 'fulfillment.inventory.table.empty_table_button'
  });

  const filterItemGenerator = () => {
    const items = [];
    CATEGORIES.map(({ title, id }) => {
      const item = {
        label: title,
        value: id
      };
      items.push(item);
    });
    return items;
  };

  useEffect(() => {
    setFilterItems([
      {
        header: `${intl.formatMessage({
          id: 'fulfillment.inventory.table.category.title'
        })}`,
        key: 'category',
        lookup: filterItemGenerator(),
        type: 'MULTI'
      }
    ]);
  }, []);

  async function fetchDeliveries(
    pageId,
    pageSize,
    filterOptions,
    search_sku,
    businessId
  ) {
    try {
      setIsLoading(true);
      let payload = {
        pageNumber: pageId,
        pageLimit: pageSize
      };
      if (businessId) {
        payload = { ...payload, businessId: businessId };
      }
      if (filterOptions?.length) {
        payload = { ...payload, categoryId: filterOptions };
      }
      if (search_sku) {
        payload = { ...payload, searchByNameOrSKU: search_sku };
      }
      const productList = await getProductList(payload);
      const { count, data } = productList.data;
      if (businessId) {
        setCount(count);
        setDataSource(data);
      } else {
        setCount(0);
        setDataSource([]);
      }
      if ((search_sku || filterOptions?.length) && !data.length) {
        notify(
          intl.formatMessage({
            id: 'fulfillment.inventory.product_not_found'
          })
        );
      }
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  }

  const formatInventory = (RAW_DATA) => {
    return RAW_DATA.map(
      ({
        product_code,
        name,
        categ_id,
        list_price,
        virtual_available,
        qty_available
      }) => {
        return {
          key: product_code,
          bostaSKU: (
            <div onClick={() => setVisibleProductDetails(product_code)}>
              {product_code}
            </div>
          ),
          Name: (
            <div onClick={() => setVisibleProductDetails(product_code)}>
              {name}
            </div>
          ),
          price: (
            <span className="br-fulfillment-price">
              {intl.formatMessage(
                {
                  id: 'common.egp_amount'
                },
                {
                  amount: list_price
                }
              )}
            </span>
          ),
          quantity: (
            <span
              className={classnames({
                'br-inventory-table__negative-quantity': virtual_available < 0
              })}
            >
              {virtual_available}
            </span>
          ),
          qty_available,
          category: (
            <span className="br-inventory-table__category-cell">
              {setCategoryTitle(categ_id[0])}
            </span>
          ),
          action: (
            <div className="br-inventory-table__action">
              <Dropdown
                overlay={() => actionMenuItems(product_code)}
                trigger={['click']}
                placement={
                  intl.locale === LOCALE.AR ? 'bottomLeft' : 'bottomRight'
                }
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                    .parentElement
                }
              >
                <Button>
                  <Icon component={ActionIcon} />
                </Button>
              </Dropdown>
            </div>
          ),
          actionCard: (
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              overlay={() => actionMenuItems(product_code)}
            >
              <div>
                <MoreOutlined type="more" />
              </div>
            </Dropdown>
          )
        };
      }
    );
  };

  const actionMenuItems = (bostaSKU) => {
    return (
      <Menu>
        <Menu.Item key="1" disabled={true}>
          {intl.formatMessage({
            id: 'fulfillment.inventory.table.action.menu_items.create_order'
          })}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => setVisibleProductDetails(bostaSKU)}>
          {intl.formatMessage({
            id: 'fulfillment.inventory.table.action.menu_items.product_details'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const downloadInventoryAsCSV = async (
    selectedRows,
    filterOptions,
    search_sku
  ) => {
    try {
      setIsLoading(true);
      let payload = {
        businessId: businessId,
        ...(selectedRows?.length && { bostaSkuArray: selectedRows }),
        ...(filterOptions?.length && { categoryId: filterOptions })
      };
      if (search_sku?.length) {
        const itemsCodes = dataSource.map((item) => item.product_code);
        payload = { ...payload, bostaSkuArray: itemsCodes };
      }
      const DATA = await exportCSVInventory(payload);
      const todaysDate = dayjs().format('DD-MM-YYYY');
      downloadAsXlsx(DATA, `Inventory_Export ${todaysDate}.xlsx`);
      notify(
        intl.formatMessage({
          id: 'orders.mass_upload.success.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  };

  const setVisibleProductDetails = async (bostaSKU) => {
    setShowProductDetails(!showProductDetails);
    if (bostaSKU) {
      setIsProductDetailsloading(true);
      try {
        const {
          data: { data: productDetails }
        } = await getProductDetails(bostaSKU);
        setProductDetailsData(productDetails[0]);
      } catch (err) {
        notify(err.message);
      }
    }
    setIsProductDetailsloading(false);
  };

  const setCategoryTitle = (category_id) => {
    const category = CATEGORIES.find(({ id }) => id === category_id);
    if (category) return category.title;
  };

  const handleBusinessChange = useCallback((businessId) => {
    setBusinessId(businessId);
    setPageId(1);
    fetchDeliveries(1, pageSize, selectedFilter, searchTableInput, businessId);
  }, []);

  return (
    <LoadingWrapper loading={isPageLoading}>
      <div className="br-inventory-container">
        <div className="br-inventory__business-selection-container">
          <div className="br-inventory__business-header">
            {intl.formatMessage({
              id: 'fulfillment.inventory.business'
            })}
          </div>
          <Select
            className="br-inventory__business-selector"
            placeholder={intl.formatMessage({
              id: `orders.mass_upload.business_placeholder`
            })}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={businessId}
            onChange={handleBusinessChange}
          >
            {businesses?.map(({ _id, name }) => (
              <Select.Option key={_id} value={_id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <BRInventoryTable
          businessId={businessId}
          fetchDeliveries={fetchDeliveries}
          columns={INVENTORY_TABLE_COLUMNS}
          dataSource={formatInventory(dataSource)}
          filterItems={filterItems}
          isLoading={isLoading}
          handleOnClickCard={(item) => {}}
          pageId={pageId}
          setPageId={setPageId}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageLimit={PAGE_SIZE_DEFAULT}
          downloadAsCSV={downloadInventoryAsCSV}
          count={count}
          searchPlaceholder={searchPlaceholder}
          productCountFormat={productCountFormat}
          emptyTableTitle={emptyTableTitle}
          emptyTableButtonText={emptyTableButtonText}
          handleEmptyTableButton={() => {}}
          showEmptyTableButton={false}
          searchTableInput={searchTableInput}
          setSearchTableInput={setSearchTableInput}
          filterOptions={selectedFilter}
          setFilterOptions={setSelectedFilter}
        />
        <ProductDetails
          productDetailsData={productDetailsData}
          showProductDetails={showProductDetails}
          setVisibleProductDetails={setVisibleProductDetails}
          isLoading={isProductDetailsloading}
        />
      </div>
    </LoadingWrapper>
  );
}

export default injectIntl(Inventory);
