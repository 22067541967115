import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import {
  deleteSerial,
  getInventorySerialsStatistics
} from 'services/fulfillment-inventory-serials';
import { createProblematicJob } from 'services/fulfillment-problematic-jobs';
import {
  SERIAL_STATE_IDS,
  checkIfSerialHasIssue
} from 'constants/fulfillment-inventory-serials';
import { handlePrintSerials } from 'constants/fulfillment-return-orders';
import { goToProblematicJobsList } from 'constants/fulfillment-problematic-jobs';
import { openModal } from 'utils/modal';
import { SllrWrapper } from 'contexts/sllr.context';

import POStatistics from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POStatistics/POStatistics';
import POFilters from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POFilters/POFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import TypeFilter from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/TypeFilter/TypeFilter';
import InventorySerialsTable from 'components/NewFulfillment/FulfillmentInventorySerials/InventorySerialsList/components/InventorySerialsTable/InventorySerialsTable';
import BRButton from 'components/BRButton/BRButton';
import DatesFilter from 'components/NewFulfillment/FulfillmentInventorySerials/InventorySerialsList/components/DatesFilter/DatesFilter';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import { notify } from 'components/Notify/Notify';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';
import IssueDecisionModal from 'components/NewFulfillment/FulfillmentInventorySerials/InventorySerialsList/components/IssueDecisionModal/IssueDecisionModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as DeletedIcon } from 'assets/bosta-icons/Delete.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CloseIcon } from 'assets/imgRevamp/closeIconThin.svg';
import { ReactComponent as CornerUpLeftIcon } from 'assets/bosta-icons/CornerUpLeft.svg';
import ReturnBoxImg from 'assets/imgRevamp/ReturnBox.png';

import './InventorySerialsList.less';

const InventorySerials = ({ intl }) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [tableFilters, setTableFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [statisticsFilters, setStatisticsFilters] = useState({});
  const [isDatesFilterDrawerVisible, setIsDatesFilterDrawerVisible] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [datesFilter, setDatesFilter] = useState({});

  const { IN_STOCK, PROCESSING, TRANSFERRED, DAMAGED, LOST, RTV } =
    SERIAL_STATE_IDS;

  const getStatisticsListToRender = ({
    totalCount,
    inStockCount,
    processingCount,
    transferredCount,
    damagedCount,
    lostCount,
    rtvCount
  }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.total'
      }),
      content: totalCount,
      stateIdFilterValue: null
    },
    {
      key: 'inStock',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.in_stock'
      }),
      content: inStockCount,
      stateIdFilterValue: IN_STOCK
    },
    {
      key: 'processing',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.processing'
      }),
      content: processingCount,
      stateIdFilterValue: PROCESSING
    },
    {
      key: 'transferred',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.transferred'
      }),
      content: transferredCount,
      stateIdFilterValue: TRANSFERRED
    },
    {
      key: 'damaged',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.damaged'
      }),
      content: damagedCount,
      stateIdFilterValue: DAMAGED
    },
    {
      key: 'lost',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.lost'
      }),
      content: lostCount,
      stateIdFilterValue: LOST
    },
    {
      key: 'rtv',
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.serial_states.rtv'
      }),
      content: rtvCount,
      stateIdFilterValue: RTV
    }
  ];

  const updateFilters = (filter) => {
    setSelectedRows([]);
    if (isFirstFetchDone) {
      setTableFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const updateStatisticsFilters = (filter) => {
    if (isFirstFetchDone) {
      setStatisticsFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const renderExtraFilters = () => (
    <>
      <TypeFilter
        updateFilters={updateFilters}
        label={intl.formatMessage({
          id: 'fulfillment_inventory_serials.type_filter.label'
        })}
      />
    </>
  );

  const openDrawer = () => setIsDatesFilterDrawerVisible(true);
  const closeDrawer = () => setIsDatesFilterDrawerVisible(false);

  const handleAddToStock = async ({ jobs }) => {
    const payload = {
      jobs
    };

    try {
      await createProblematicJob(payload);
      openModal(SuccessModal, {
        title: intl.formatMessage(
          {
            id: 'fulfillment_problematic_jobs.add_to_stock.success_modal.title'
          },
          {
            count: jobs.length
          }
        ),
        confirmButtonLabel: intl.formatMessage({
          id: 'fulfillment_problematic_jobs.add_to_stock.success_modal.cancel_btn'
        }),
        cancelButtonLabel: intl.formatMessage({
          id: 'common.dismiss'
        }),
        modalAction: goToProblematicJobsList,
        onCancel: null,
        maskClosable: false
      });
    } catch (error) {
      notify(error.message);
    }
  };

  const confirmOnUserBeforeAddingToStock = (serials = []) => {
    const uniqueOrders = {};
    let problematicJobsCount = 0;

    serials.forEach(({ soId, serialNumber }) => {
      const isFound = uniqueOrders[soId];

      if (!isFound) {
        uniqueOrders[soId] = [serialNumber];
        problematicJobsCount++;
      } else {
        uniqueOrders[soId].push(serialNumber);
      }
    });

    openModal(BRConfirmationModal, {
      wrapClassName: 'br-confirm-po__confirmation-modal',
      icon: <img src={ReturnBoxImg} alt="box" />,
      message: intl.formatMessage(
        {
          id: 'fulfillment_problematic_jobs.add_to_stock.confirmation_modal.content'
        },
        {
          jobsCount: (
            <span className="br-confirm-po__confirmation-modal__teal-color">
              {problematicJobsCount}
            </span>
          ),
          serialsCount: (
            <span className="br-confirm-po__confirmation-modal__teal-color">
              {serials.length}
            </span>
          )
        }
      ),
      onConfirm: () =>
        handleAddToStock({
          jobs: Object.keys(uniqueOrders).map((key) => ({
            soId: Number(key),
            serials: uniqueOrders[key]
          }))
        }),
      confirmButtonText: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.add_to_stock.confirmation_modal.confirm_btn'
      }),
      cancelButtonText: intl.formatMessage({
        id: 'common.cancel'
      }),
      confirmButtonType: 'primary',
      width: 430,
      maskClosable: false
    });
  };

  const updatePage = () => {
    // State ineffective update to recall the APIs
    updateFilters({});
    updateStatisticsFilters({});
  };

  const handleDeleteSerial = async ({ serialNumber }) => {
    try {
      const { message } = await deleteSerial(serialNumber);
      notify(message, 'success');
      updatePage();
    } catch (error) {
      notify(error.message);
    }
  };

  const confirmOnUserBeforeDeleteSerials = (serials = []) => {
    openModal(IssueDecisionModal, {
      title: intl.formatMessage({
        id: 'fulfillment_inventory_serials.delete_serial_modal.title'
      }),
      serials,
      onConfirm: () => handleDeleteSerial(serials[0]), // Currently only one serial can be deleted
      confirmBtnProps: {
        label: intl.formatMessage({
          id: 'fulfillment_returns.actions.delete_serial'
        })
      }
    });
  };

  const menuItems = ({ serials, hasIssue, isInStock }) => {
    const isSerialsArray = Array.isArray(serials);

    const renderActionItem = ({ className, text, icon = null, onClick }) => (
      <div
        className={classNames('br-action-menu-item', className)}
        onClick={onClick}
      >
        {icon}
        {text}
      </div>
    );

    const addToStockAction = renderActionItem({
      text: intl.formatMessage({
        id: 'fulfillment_returns.actions.add_to_stock'
      }),
      icon: <CornerUpLeftIcon />,
      onClick: () => confirmOnUserBeforeAddingToStock(serials)
    });

    const printLabelsAction = renderActionItem({
      text: intl.formatMessage({
        id: `fulfillment_common.${
          serials.length > 1 ? 'print_labels' : 'print_label'
        }`
      }),
      icon: <PrinterIcon />,
      onClick: () =>
        handlePrintSerials({
          serials: isSerialsArray
            ? serials.map(({ serialNumber }) => serialNumber)
            : [serials.serialNumber],
          setIsLoading
        })
    });

    const deleteAction = renderActionItem({
      className: 'cancel-action',
      text: intl.formatMessage({
        id: 'fulfillment_returns.actions.delete_serial'
      }),
      icon: <DeletedIcon />,
      onClick: () => confirmOnUserBeforeDeleteSerials(serials)
    });

    const items = [
      ...(hasIssue ? [addToStockAction] : []),
      printLabelsAction,
      ...(isInStock ? [deleteAction] : [])
    ];

    return (
      <Menu className="br-fulfillment-inventory-serials__actions-menu">
        {items.map((item, index) => (
          <Menu.Item key={index}>{item}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const renderBulkyActions = (serials) => {
    const selectedRowsHasIssues = selectedRows.filter(
      (row) => row?.damagedAt || row?.lostAt
    );

    const menu = menuItems({
      serials,
      hasIssue:
        !!selectedRowsHasIssues?.length ||
        checkIfSerialHasIssue({ stateId: tableFilters.state })
    });

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={!selectedRows.length}
      >
        <BRButton
          label={intl.formatMessage({
            id: 'common.bulky_actions'
          })}
        />
      </Dropdown>
    );
  };

  const handleResetAllDates = () => {
    setDatesFilter({});
  };

  const updateFiltersByDates = () => {
    updateFilters({ datesFilter });
    updateStatisticsFilters({ datesFilter });
  };

  useEffect(() => {
    updateFiltersByDates();
  }, [datesFilter]);

  return (
    <Container
      className="br-fulfillment-inventory-serials"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_inventory_serials.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <div className="br-fulfillment-return-list__date-filter-section">
              <BRButton
                label={intl.formatMessage({
                  id: 'fulfillment_common.filter_by_date_drawer.open_drawer_btn'
                })}
                onClick={openDrawer}
              />
              {!isEmpty(datesFilter) && (
                <BRButton
                  label={intl.formatMessage({
                    id: 'common.reset'
                  })}
                  prefixIcon={<CloseIcon />}
                  onClick={handleResetAllDates}
                />
              )}
            </div>
            {isDatesFilterDrawerVisible && (
              <DatesFilter
                onClose={closeDrawer}
                datesList={[
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.received_at'
                    }),
                    apiKey: 'SerialPutAwayAt'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.so_created_at'
                    }),
                    apiKey: 'SoCreationDate'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.transferred_at'
                    }),
                    apiKey: 'transferredAt'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.ro_created_at'
                    }),
                    apiKey: 'RoCreationDate'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.damaged_at'
                    }),
                    apiKey: 'damagedAt'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.lost_at'
                    }),
                    apiKey: 'lostAt'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'fulfillment_common.dates.rtv_at'
                    }),
                    apiKey: 'rtvAt'
                  }
                ]}
                datesFilter={datesFilter}
                setDatesFilter={setDatesFilter}
              />
            )}
            <POStatistics
              updateFilters={updateFilters}
              poFilters={tableFilters}
              fetchData={getInventorySerialsStatistics}
              getStatisticsListToRender={getStatisticsListToRender}
              statisticsFilters={statisticsFilters}
            />
            <POFilters
              updateFilters={updateFilters}
              renderExtraFilters={renderExtraFilters}
              hideDateFilter
            />
            <div className="br-fulfillment-inventory-serials__bulky-actions-btn">
              {renderBulkyActions(selectedRows)}
            </div>
            <InventorySerialsTable
              tableFilters={tableFilters}
              isFirstFetchDone={isFirstFetchDone}
              setIsFirstFetchDone={setIsFirstFetchDone}
              setSelectedRows={setSelectedRows}
              actionMenuItems={menuItems}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(InventorySerials));
