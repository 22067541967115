import actionTypes from "../actions/ActionTypes";

const initialState = {
  cities: [],
  zones: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CITIES_RECEIVED:
      return { ...state, cities: action.payload };
    case actionTypes.FETCH_ZONES_RECEIVED:
      return { ...state, zones: action.payload };
    default:
      return state;
  }
}
