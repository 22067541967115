import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Banner from 'components/Banner/Banner';

import './MissingMultiPackagesBanner.less';

const MissingMultiPackagesBanner = ({ missingScanDeliveries }) => {
  const intl = useIntl();

  const { missingPackagesCount, missingDeliveries } = missingScanDeliveries;

  const renderMissingPackages = useCallback(
    () => (
      <ul className="br-missing-multi-packages__banner-list">
        {missingDeliveries.map(
          ({ _id, trackingNumber, multiPackages, scannedTimes }) => (
            <li key={_id}>
              {intl.formatMessage(
                { id: 'missing_multi_package_modal.missing_package_list_item' },
                {
                  trackingNumber,
                  missingPackages: multiPackages - scannedTimes,
                  span: (children) => (
                    <span className="body-medium">{children}</span>
                  )
                }
              )}
            </li>
          )
        )}
      </ul>
    ),
    [missingDeliveries]
  );

  return (
    <Banner
      title={intl.formatMessage(
        { id: 'missing_multi_package_modal.title' },
        {
          span: (children) => <span>{children}</span>,
          missingPackages: missingPackagesCount,
          multiPackages: missingDeliveries.length
        }
      )}
      subText={renderMissingPackages()}
    />
  );
};

export default MissingMultiPackagesBanner;
