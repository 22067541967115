import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as DownloadIcon } from 'assets/bosta-icons/Download.svg';
import './PreviousDateCard.less';
const PreviousDateCard = ({
  Icon,
  header,
  exportHeader,
  discreption,
  exportButtonTitle,
  downloadFunction,
  loading
}) => {
  return (
    <div className="br-Previous-date-card__container">
      <div className="br-previous-date-card__header">
        <Icon />
        <h4 className="heading">{header}</h4>
      </div>
      <div className="br-previous-date-card__export-button-container">
        <h4 className="br-previous-date-card__export-button-container-header display-xs">
          {exportHeader}
        </h4>
        <p className="br-previous-date-card__export-button-container-header body-medium">
          {discreption}
        </p>
        <BRButton
          disabled={loading}
          className="br-previous-date-card__export-button"
          label={exportButtonTitle}
          type={'primary'}
          prefixIcon={<DownloadIcon />}
          onClick={downloadFunction}
        />
      </div>
    </div>
  );
};

export default PreviousDateCard;
