import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Form, Input, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import {
  generateOTP,
  confirmOTP,
  getUserProfileInfo,
  editUserProfileInfo
} from 'services/personal-profile';
import { emailRule, phoneNumberRule } from 'utils/forms';
import { COUNTRIES } from 'constants/country-data';
import { PHONE_NUMBER_MAX_LENGHT } from 'constants/form';
import { openModal } from 'utils/modal';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';
import BROTPCode from 'components/BROTPCode/BROTPCode';
import { notify } from 'components/Notify/Notify';

import './PersonalProfile.less';

const USER_INFO = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  EMAIL: 'email'
};
class PersonalProfile extends Component {
  state = {
    isDataLoading: false,
    isUserVerified: false,
    showEmailOTPVerfication: false,
    showPhoneOTPVerfication: false,
    isInputDisabled: {
      firstName: true,
      lastName: true,
      email: true,
      phone: true
    },
    newUserData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    prevUserData: {}
  };

  userFormRef = React.createRef('');

  getUserInfoData = async () => {
    try {
      this.setState({ isDataLoading: true });
      const response = await getUserProfileInfo();
      this.userFormRef.current &&
        this.userFormRef.current.setFieldsValue(response);
      this.setState({ newUserData: response, prevUserData: response });
    } catch (error) {
      this.setState({ isDataLoading: false });
      notify(error.message);
    }
    this.setState({ isDataLoading: false });
  };

  componentDidMount() {
    this.getUserInfoData();
  }

  discardOTPVerification = (editedValue) => {
    const { prevUserData, newUserData } = this.state;
    if (editedValue === USER_INFO.PHONE) {
      this.setState({
        showPhoneOTPVerfication: false,
        newUserData: { ...newUserData, phone: prevUserData?.phone }
      });
      this.userFormRef.current.setFieldsValue({
        phone: prevUserData?.phone
      });
    } else if (editedValue === USER_INFO.EMAIL) {
      this.setState({
        showEmailOTPVerfication: false,
        newUserData: { ...newUserData, email: prevUserData?.email }
      });
      this.userFormRef.current.setFieldsValue({
        email: prevUserData?.email
      });
    }
  };

  otpVerficationCodeFooter = (editedValue) => {
    const { intl } = this.props;
    return (
      <div className="br-personal-profile__otp-verfication-footer">
        <Button onClick={() => this.discardOTPVerification(editedValue)}>
          {intl.formatMessage({
            id: 'common.discard'
          })}
        </Button>
        <Button type="primary" htmlType="submit">
          {intl.formatMessage({
            id: 'common.confirm'
          })}
        </Button>
      </div>
    );
  };

  controlDisablingInputs = (updatedValue) => {
    const { isInputDisabled } = this.state;
    switch (updatedValue) {
      case USER_INFO.PHONE:
        return this.setState({
          isInputDisabled: {
            ...isInputDisabled,
            phone: !isInputDisabled.phone
          }
        });
      case USER_INFO.EMAIL:
        return this.setState({
          isInputDisabled: {
            ...isInputDisabled,
            email: !isInputDisabled.email
          }
        });
      case USER_INFO.FIRST_NAME:
        return this.setState({
          isInputDisabled: {
            ...isInputDisabled,
            firstName: !isInputDisabled.firstName
          }
        });
      case USER_INFO.LAST_NAME:
        return this.setState({
          isInputDisabled: {
            ...isInputDisabled,
            lastName: !isInputDisabled.lastName
          }
        });

      default:
        return '';
    }
  };

  controlFormValues = (itemKey) => {
    const {
      newUserData: { firstName, lastName, phone, email }
    } = this.state;
    switch (itemKey) {
      case USER_INFO.PHONE:
        return this.userFormRef.current.setFieldsValue({ phone });
      case USER_INFO.EMAIL:
        return this.userFormRef.current.setFieldsValue({ email });
      case USER_INFO.FIRST_NAME:
        return this.userFormRef.current.setFieldsValue({ firstName });
      case USER_INFO.LAST_NAME:
        return this.userFormRef.current.setFieldsValue({ lastName });
      default:
        return '';
    }
  };

  onCheckItemValidation = async (itemKey) => {
    try {
      await this.userFormRef.current.validateFields([itemKey]);
      return true;
    } catch (errorInfo) {
      notify(errorInfo);
    }
  };

  handleChangeUserName = async (editedKey) => {
    const { intl } = this.props;
    const { newUserData } = this.state;
    const updatedFirstName =
      this.userFormRef.current.getFieldValue('firstName');
    const updatedLastName = this.userFormRef.current.getFieldValue('lastName');
    const result = await this.onCheckItemValidation(editedKey);
    if (result) {
      try {
        await editUserProfileInfo({
          firstName: updatedFirstName,
          lastName: updatedLastName
        });
        this.controlDisablingInputs(editedKey);
        this.setState({
          newUserData: {
            ...newUserData,
            firstName: updatedFirstName,
            lastName: updatedLastName
          }
        });
        notify(
          intl.formatMessage({
            id: `settings.personal_profile.personal_info.${
              editedKey === USER_INFO.FIRST_NAME ? 'first_name' : 'last_name'
            }_updated_successfully`
          }),
          'success'
        );
      } catch (error) {
        notify(error.message);
      }
    }
  };

  handleEditUserData = (editedValue) => {
    const { isUserVerified } = this.state;
    if (
      isUserVerified ||
      editedValue === USER_INFO.FIRST_NAME ||
      editedValue === USER_INFO.LAST_NAME
    ) {
      this.controlDisablingInputs(editedValue);
    } else {
      this.handleOpenOtpModal(editedValue);
    }
  };

  handleOpenOtpModal = (editedItem) => {
    const { intl } = this.props;
    const { prevUserData } = this.state;
    openModal(BROTPCodeModal, {
      title: intl.formatMessage({
        id: 'settings.payment_method.otp_screen.title'
      }),
      subtitle: intl.formatMessage(
        {
          id: 'settings.payment_method.otp_screen.sub_title'
        },
        {
          phone: <span>{prevUserData.phone?.slice(-3)}</span>
        }
      ),
      confirmOTP: (personalInfoOtp) =>
        confirmOTP({
          personalInfoOtp
        }),
      generateOTP,
      onSuccess: () => {
        this.setState({ isUserVerified: true });
        this.controlDisablingInputs(editedItem);
      }
    });
  };

  handleShowOTPVerfication = async (editedValue) => {
    const { newUserData } = this.state;
    const updatedEmail = this.userFormRef.current.getFieldValue('email');
    const updatedPhone = this.userFormRef.current.getFieldValue('phone');
    const result = await this.onCheckItemValidation(editedValue);
    if (result) {
      const payload =
        editedValue === USER_INFO.EMAIL
          ? { email: updatedEmail }
          : { phone: updatedPhone };
      try {
        await generateOTP(payload);
        this.controlDisablingInputs(editedValue);
        editedValue === USER_INFO.EMAIL
          ? this.setState({
              showEmailOTPVerfication: true,
              newUserData: { ...newUserData, email: updatedEmail }
            })
          : this.setState({
              showPhoneOTPVerfication: true,
              newUserData: { ...newUserData, phone: updatedPhone }
            });
      } catch (error) {
        notify(error.message);
      }
    }
  };

  handleOnClickEditLabel = (key) => {
    const { intl } = this.props;
    return (
      <span
        onClick={() => this.handleEditUserData(key)}
        className="br-peronsal-info__form__edit-label"
      >
        {intl.formatMessage({
          id: 'common.edit'
        })}
      </span>
    );
  };

  cancelChangedValue = (key) => {
    key === USER_INFO.PHONE
      ? this.setState({
          showPhoneOTPVerfication: false
        })
      : this.setState({
          showEmailOTPVerfication: false
        });
  };

  handleOnClickSaveCancelIcons = (key) => {
    return (
      <>
        <CloseOutlined
          onClick={() => {
            this.controlDisablingInputs(key);
            this.controlFormValues(key);
            (key === USER_INFO.PHONE || key === USER_INFO.EMAIL) &&
              this.cancelChangedValue(key);
          }}
        />
        <CheckOutlined
          onClick={() =>
            key === USER_INFO.FIRST_NAME || key === USER_INFO.LAST_NAME
              ? this.handleChangeUserName(key)
              : this.handleShowOTPVerfication(key)
          }
        />
      </>
    );
  };

  handleEditUserEmailPhone = (editedKey) => {
    const { newUserData } = this.state;
    const { intl, history } = this.props;
    notify(
      intl.formatMessage({
        id: `settings.personal_profile.personal_info.${editedKey}_updated_successfully`
      }),
      'success'
    );
    if (editedKey === USER_INFO.PHONE) {
      this.setState({
        newUserData: {
          ...newUserData,
          phone: newUserData.phone,
          showPhoneOTPVerfication: false
        }
      });
    } else {
      this.setState({
        newUserData: {
          ...newUserData,
          email: newUserData.email,
          showEmailOTPVerfication: false
        }
      });
      setTimeout(() => {
        history.push(`/logout`);
      }, 1000);
    }
  };

  render() {
    const { intl } = this.props;
    const {
      isDataLoading,
      isInputDisabled: { email, phone, firstName, lastName },
      newUserData,
      showEmailOTPVerfication,
      showPhoneOTPVerfication
    } = this.state;
    return (
      <div className="br-personal-info">
        <BRContentHeader
          title={intl.formatMessage({
            id: 'settings.personal_profile.personal_info.title'
          })}
          subtitle={intl.formatMessage({
            id: 'settings.personal_profile.personal_info.sub_title'
          })}
          isInternalComponent
        />
        <LoadingWrapper loading={isDataLoading}>
          <>
            <Form ref={this.userFormRef}>
              <div className="br-form-row">
                <Form.Item
                  name="firstName"
                  label={intl.formatMessage({
                    id: 'contact_form_labels.first_name'
                  })}
                  rules={[{ required: true }]}
                >
                  <Input
                    maxLength={25}
                    disabled={firstName}
                    suffix={
                      firstName
                        ? this.handleOnClickEditLabel(USER_INFO.FIRST_NAME)
                        : this.handleOnClickSaveCancelIcons(
                            USER_INFO.FIRST_NAME
                          )
                    }
                    onKeyDown={({ keyCode }) =>
                      (keyCode === 9 || keyCode === 13) &&
                      this.handleChangeUserName(USER_INFO.FIRST_NAME)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={intl.formatMessage({
                    id: 'contact_form_labels.last_name'
                  })}
                  rules={[{ required: true }]}
                >
                  <Input
                    maxLength={25}
                    disabled={lastName}
                    suffix={
                      lastName
                        ? this.handleOnClickEditLabel(USER_INFO.LAST_NAME)
                        : this.handleOnClickSaveCancelIcons(USER_INFO.LAST_NAME)
                    }
                    onKeyDown={({ keyCode }) =>
                      (keyCode === 9 || keyCode === 13) &&
                      this.handleChangeUserName(USER_INFO.LAST_NAME)
                    }
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <div className="br-personal-profile__phone-section">
                  <Form.Item
                    name="phone"
                    validateTrigger="onBlur"
                    label={intl.formatMessage({
                      id: 'contact_form_labels.phone'
                    })}
                    rules={[
                      {
                        label: intl.formatMessage({
                          id: 'contact_form_labels.phone'
                        }),
                        required: true
                      },
                      phoneNumberRule({
                        message: intl.formatMessage({
                          id: 'form.phone_not_valid'
                        })
                      })
                    ]}
                  >
                    <Input
                      autoComplete={`${Math.random()}`}
                      disabled={phone}
                      // suffix={
                      //   !showPhoneOTPVerfication &&
                      //   (phone
                      //     ? this.handleOnClickEditLabel(USER_INFO.PHONE)
                      //     : this.handleOnClickSaveCancelIcons(USER_INFO.PHONE))
                      // }
                      // onKeyDown={({ keyCode }) =>
                      //   (keyCode === 9 || keyCode === 13) &&
                      //   this.handleShowOTPVerfication(USER_INFO.PHONE)
                      // }
                    />
                  </Form.Item>
                  {showPhoneOTPVerfication && (
                    <div className="br-personal-info__otp-screen ">
                      <BROTPCode
                        title={intl.formatMessage({
                          id: 'settings.personal_profile.personal_info.phone_otp_screen.title'
                        })}
                        subtitle={intl.formatMessage(
                          {
                            id: 'settings.personal_profile.personal_info.phone_otp_screen.sub_title'
                          },
                          {
                            phone: <span>{newUserData.phone?.slice(-3)}</span>
                          }
                        )}
                        verificationGenerateOTPCode={() =>
                          this.handleShowOTPVerfication(USER_INFO.PHONE)
                        }
                        footer={this.otpVerficationCodeFooter(USER_INFO.PHONE)}
                        confirmOTP={(personalInfoOtp) =>
                          confirmOTP({
                            phoneUpdateOtp: personalInfoOtp
                          })
                        }
                        onSuccess={() => {
                          this.handleEditUserEmailPhone(USER_INFO.PHONE);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="br-personal-profile__email-section">
                  <Form.Item
                    name="email"
                    label={intl.formatMessage({
                      id: 'contact_form_labels.email'
                    })}
                    rules={[
                      emailRule(
                        intl.formatMessage({
                          id: 'form.email_not_valid'
                        })
                      )
                    ]}
                  >
                    <Input
                      type="email"
                      maxLength={225}
                      disabled={email}
                      autoComplete={`${Math.random()}`}
                      // suffix={
                      //   !showEmailOTPVerfication &&
                      //   (email
                      //     ? this.handleOnClickEditLabel(USER_INFO.EMAIL)
                      //     : this.handleOnClickSaveCancelIcons(USER_INFO.EMAIL))
                      // }
                      // onKeyDown={({ keyCode }) =>
                      //   (keyCode === 9 || keyCode === 13) &&
                      //   this.handleShowOTPVerfication(USER_INFO.EMAIL)
                      // }
                    />
                  </Form.Item>
                  {showEmailOTPVerfication && (
                    <div className="br-personal-info__otp-screen ">
                      <BROTPCode
                        title={intl.formatMessage({
                          id: 'settings.personal_profile.personal_info.email_otp_screen.title'
                        })}
                        subtitle={intl.formatMessage(
                          {
                            id: 'settings.personal_profile.personal_info.email_otp_screen.sub_title'
                          },
                          {
                            email: (
                              <span>
                                {newUserData.email?.split('@')[0]?.slice(-4)}@
                                {newUserData.email?.split('@')[1]}
                              </span>
                            )
                          }
                        )}
                        footer={this.otpVerficationCodeFooter(USER_INFO.EMAIL)}
                        confirmOTP={(emailUpdateOtp) =>
                          confirmOTP({
                            emailUpdateOtp
                          })
                        }
                        verificationGenerateOTPCode={() =>
                          this.handleShowOTPVerfication(USER_INFO.EMAIL)
                        }
                        onSuccess={() => {
                          this.handleEditUserEmailPhone(USER_INFO.EMAIL);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </>
        </LoadingWrapper>
      </div>
    );
  }
}

export default injectIntl(withRouter(PersonalProfile));
