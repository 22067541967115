import { useContext, useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { DISPATCHING_ALL_HUBS_ACCESS, PAGES_TYPE } from 'constants/dispatching';
import { HubsContext } from 'contexts/hub.context';
import { ACL_MATRIX } from 'common/permissions';

import DispatchingTab from './components/Tabs/DispatchingTab';
import TransferringTab from './components/Tabs/TransferringTab';
import OFDSearchTab from './components/Tabs/OFDSearchTab';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import BRTabs from 'components/BRTabs/BRTabs';

import './DispatchingContainer.less';

const { DISPATCHING, TRANSFERRING } = PAGES_TYPE;

const DEFAULT_TAB_PATH = DISPATCHING;

const DispatchingContainer = ({ intl, match }) => {
  const { path } = match;
  const [selectedHubId, setSelectedHubId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { scopedHubs: allHubs } = useContext(HubsContext);

  const TABS = {
    dispatching: {
      label: intl.formatMessage({
        id: 'monitor_hub_operations.dispatching.tab_title'
      }),
      component: DispatchingTab,
      componentProps: {
        allHubs,
        setIsLoading,
        isLoading,
        selectedHubId,
        setSelectedHubId
      },
      path: `/hubs/monitor-hub-operations/${DISPATCHING}`
    },
    transferring: {
      label: intl.formatMessage({
        id: 'monitor_hub_operations.transferring.tab_title'
      }),
      component: TransferringTab,
      componentProps: {
        allHubs,
        setIsLoading,
        isLoading,
        selectedHubId,
        setSelectedHubId
      },
      path: `/hubs/monitor-hub-operations/${TRANSFERRING}`
    },
    ofdSearch: {
      label: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.tab_title'
      }),
      component: OFDSearchTab,
      path: '/hubs/monitor-hub-operations/ofd-search'
    }
  };

  const resetSelectedHubId = () => {
    setSelectedHubId(null);
  };

  useEffect(() => {
    if (isAdminGroupUser()) {
      allHubs.length === 1 && setSelectedHubId(allHubs[0]._id);
    } else {
      const currentUserHub = getCurrentUserHubInfo();
      !isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
        ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
      ]) &&
        currentUserHub &&
        setSelectedHubId(currentUserHub._id);
    }
  }, [allHubs]);

  useEffect(() => {
    if (
      isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
        ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
      ])
    ) {
      resetSelectedHubId();
    }
  }, [match]);

  return (
    <Switch>
      <Route
        path={Object.keys(TABS).map((key) => TABS[key].path)}
        exact
        render={() => (
          <Container
            className="br-dispatching-container br-tabs-container-content"
            header={
              <BRHeader
                title={intl.formatMessage({
                  id: 'sidebar.monitor_hub_operations'
                })}
                showBackArrow={
                  isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
                    ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
                  ]) && selectedHubId
                }
                handleGoBackUrl={resetSelectedHubId}
              />
            }
            content={
              <BRTabs
                tabs={TABS}
                onChangeTabs={
                  isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
                    ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
                  ])
                    ? resetSelectedHubId
                    : undefined
                }
              />
            }
          />
        )}
      />
      <Route
        path={`/hubs/monitor-hub-operations/${TRANSFERRING}`}
        exact
        render={(props) => (
          <TransferringTab
            {...props}
            allHubs={allHubs}
            setIsLoading={setIsLoading}
            selectedHubId={selectedHubId}
            setSelectedHubId={setSelectedHubId}
          />
        )}
      />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default injectIntl(withRouter(DispatchingContainer));
