import { BASE } from 'apis';
import { notify } from 'components/Notify/Notify';

/**
 * get all hubs
 * @returns {Promise<*>}
 */
export const getAllHubs = async () => {
  const { data } = await BASE.get(`/hubs`);
  return data;
};

/**
 * get hub by id
 * @param id
 * @returns {Promise<*>}
 */
export const getHubById = async (id) => {
  const { data } = await BASE.get(`/hubs/${id}`);
  return data.message;
};

/**
 * create new hub
 * @param payload
 * @returns {Promise<*>}
 */
export const createHub = async (payload) => {
  const { data } = await BASE.post(`/hubs`, payload);
  return data;
};

/**
 * create new hub transfer
 * @param payload
 * @returns {Promise<*>}
 */
export const hubTransfer = async (payload) => {
  const { data } = await BASE.post(`/warehouseTransfer`, payload);
  return data;
};

/**
 * return all stars with query
 * @returns {Promise<{data: ({data: [], count: number}|null|{data, count: *}|{data: *, count: *}), count: *}>}
 */
export const getAllStars = async () => {
  let responseStatusCode = 200;
  try {
    const res = await BASE.get(`/users/stars/all`);
    responseStatusCode = res.status;
    return { data: res?.data?.message?.stars, count: res.data.message.count };
  } catch (e) {
    if (responseStatusCode === 204) return { data: [], count: 0 };
    else {
      notify('Error while loading stars lookup!');
      return null;
    }
  }
};

/**
 * return all stars with query
 * @returns {Promise<{data: ({data: [], count: number}|null|{data, count: *}|{data: *, count: *}), count: *}>}
 */
export const getStarsByHubId = async ({ hubId }) => {
  try {
    const { data } = await BASE.get(`/hubs/${hubId}/stars`);
    return { data: data.message, count: data.message.length };
  } catch (e) {
    notify(
      'Either there is no stars assigned for this hub or there is an system error'
    );
    return null;
  }
};

/**
 * get debriefing money report by query
 * @param query
 * @returns {Promise<null|*>}
 */
export const getMoneyDebriefingReport = async (query) => {
  try {
    const queryStr = Object.entries(query).reduce(
      (acc, value) => `${acc}${value[0]}=${value[1]}&`,
      ''
    );
    const { data } = await BASE.get(`/cash/collect-money-report?${queryStr}`);
    return data;
  } catch (e) {
    const messages = e.response
      ? Array.isArray(e.response.data)
        ? e.response.data.map((el) => el.message).join(', ')
        : e.response.data.message
      : e.message;
    notify(messages);
    return null;
  }
};

/**
 * get hub transfers report according to criteria
 * @param query
 * @returns {Promise<null|*>}
 */
export const getHubTransfersReport = async (query) => {
  try {
    const queryStr = Object.entries(query).reduce(
      (acc, value) => `${acc}${value[0]}=${encodeURIComponent(value[1])}&`,
      ''
    );
    const { data } = await BASE.get(
      `/warehouseTransfer/containers?${queryStr}`
    );
    return data;
  } catch (e) {
    return null;
  }
};

/**
 * export Hub transfers as CSV
 * @param query
 * @returns {Promise<null|*>}
 */
export const exportHubTransfersReportAsCSV = async (query) => {
  try {
    const queryStr = Object.entries(query).reduce(
      (acc, value) =>
        value[1] ? `${acc}${value[0]}=${encodeURIComponent(value[1])}&` : acc,
      ''
    );
    const { data } = await BASE.get(
      `/warehouseTransfer/containers/csv?${queryStr}`
    );
    return data.message;
  } catch (e) {
    return null;
  }
};

/**
 * collect star money
 * @param payload
 * @returns {Promise<null|*>}
 */
export const collectStarMoney = async (payload) => {
  try {
    const { data } = await BASE.post('/cash/collect-star-money', payload);
    notify('A new cash money for star created successfully', 'success');
    return data;
  } catch (e) {
    notify(e.response?.data?.message || e.message);
    return null;
  }
};

/**
 * get star liabilities
 * @param starId
 * @returns {Promise<null|*>}
 */
export const getStarLiabilities = async ({ starId }) => {
  try {
    const { data } = await BASE.get(
      `/cash/calculate-star-liabilities/${starId}`
    );
    return data;
  } catch (e) {
    notify(e.response?.data?.message);

    return null;
  }
};

/**
 * get star liabilities
 * @returns {Promise<null|*>}
 * @param transfer
 */
export const createWarehouseTransfer = async (transfer) => {
  try {
    const payload = { warehouseTransfer: { ...transfer } };
    const { data } = await BASE.post(`/warehouseTransfer`, payload);
    return data;
  } catch (e) {
    notify(
      `${
        Array.isArray(e.response.data)
          ? e.response.data.reduce((acc, item) => `${acc} ${item.message},`, '')
          : e.response.data.message
      }`
    );
    return null;
  }
};

/**
 * get user warehouse info
 * @returns {Promise<void>}
 */
export const getCurrentUserHubInfo = async () => {
  try {
    const { data } = await BASE.get('/users/user-warehouse');
    return data;
  } catch (e) {
    notify(`Error while getting current hub info`);
    return null;
  }
};

/**
 * get hub incoming transfers
 * @param hubId
 * @returns {Promise<null|*>}
 */
export const getHubIncomingTransfers = async ({
  hubId,
  pageId,
  pageLimit,
  sealNumbers
}) => {
  try {
    const { data } = await BASE.get(
      `/warehouseTransfer/${hubId}?pageId=${pageId}&pageLimit=${pageLimit}${
        sealNumbers ? `&sealNumbers=${sealNumbers}` : ''
      }`
    );
    return data;
  } catch (e) {
    notify(`Error while getting current hub info`);
    return null;
  }
};

/**
 * get transfer containers
 * @param transferId
 * @returns {null|string|string}
 */
export const getTransferContainers = async ({ _id }) => {
  try {
    const { data } = await BASE.get(`/warehouseTransfer/transfer/${_id}`);
    return data.message;
  } catch (e) {
    notify(`Error while getting current transfer info`);
    return null;
  }
};

/**
 * get transfer containers
 * @param transferId
 * @returns {null|string|string}
 */
export const receiveTransfer = async ({
  _id,
  transferredDeliveries,
  packageSize,
  pricingPackageSize,
  packageWeight
}) => {
  try {
    const { data } = await BASE.put(`/warehouseTransfer/${_id}`, {
      transferredDeliveries,
      packageSize,
      pricingPackageSize,
      packageWeight
    });
    return data.message;
  } catch (e) {
    notify(`Error while receiving this transfer packages`);
    return null;
  }
};

export const validateShelve = async ({ shelfValue, hubId }) => {
  return BASE.get(`/hubs/${hubId}/scanShelves?shelfName=${shelfValue}`);
};

export const setDeliveriesOnShelve = ({ shelfValue, deliveryIds }) => {
  return BASE.put(`/admin/deliveries/setShelfLocation`, {
    shelfValue,
    deliveryIds
  });
};
