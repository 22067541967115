import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Modal, Divider } from 'antd';
import Icon from '@ant-design/icons';

import BRButton from 'components/BRButton/BRButton';
import MultiPackagesCount from '../MultiPackagesCount/MultiPackagesCount';

import { ReactComponent as Alert } from 'assets/bosta-icons/alert-triangle.svg';

import './MissingMultiPackagesModal.less';

const MissingMultiPackagesModal = ({
  close,
  deliveries = [],
  onConfirm,
  onCancel = () => {},
  ...props
}) => {
  const [missingScanDeliveries, setMissingScanDeliveries] = useState({
    missingPackagesCount: 0,
    missingDeliveries: []
  });

  const intl = useIntl();

  const handleCancel = () => {
    onCancel(missingScanDeliveries);
    close();
  };

  const handleOnConfirm = () => {
    close();
    onConfirm();
  };

  const getMissingScanDeliveries = () => {
    const result = deliveries.reduce(
      (acc, delivery) => {
        const { multiPackages, scannedTimes } = delivery;

        if (multiPackages !== scannedTimes) {
          acc.missingPackagesCount += multiPackages - scannedTimes;
          acc.missingDeliveries.push(delivery);
        }

        return acc;
      },
      { missingPackagesCount: 0, missingDeliveries: [] }
    );

    setMissingScanDeliveries(result);
  };

  useEffect(() => {
    if (deliveries.length) {
      getMissingScanDeliveries();
    }
  }, [deliveries]);

  return (
    <Modal onCancel={handleCancel} footer={null} title={null} {...props}>
      <div className="br-missing-multi-packages__container">
        <div className="br-missing-multi-packages__header">
          <div className="br-missing-multi-packages__icon__container">
            <Icon
              component={Alert}
              className="br-missing-multi-packages__icon ant-icon-lg"
            />
          </div>
          <div className="br-missing-multi-packages__title display-xs">
            {intl.formatMessage(
              { id: 'missing_multi_package_modal.title' },
              {
                span: (children) => <span>{children}</span>,
                missingPackages: missingScanDeliveries.missingPackagesCount,
                multiPackages: missingScanDeliveries.missingDeliveries.length
              }
            )}
          </div>
        </div>
        <div className="br-missing-multi-packages__content">
          <div className="br-missing-multi-packages-content__row header">
            <span>
              {intl.formatMessage({
                id: 'missing_multi_package_modal.multi_package_orders'
              })}
            </span>
            <span>
              {intl.formatMessage({
                id: 'missing_multi_package_modal.scanned'
              })}
            </span>
          </div>
          <Divider />
          <div className="br-missing-multi-packages__content-items">
            {missingScanDeliveries.missingDeliveries.map((delivery, index) => (
              <div
                className="br-missing-multi-packages-content__row content"
                key={index}
              >
                <span className="body-medium">{delivery.trackingNumber}</span>
                <MultiPackagesCount delivery={delivery} />
              </div>
            ))}
          </div>
        </div>
        <div className="br-missing-multi-packages__actions">
          <BRButton
            label={intl.formatMessage({
              id: 'missing_multi_package_modal.back_to_scanning'
            })}
            onClick={handleCancel}
            block
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({ id: 'common.confirm' })}
            onClick={handleOnConfirm}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default MissingMultiPackagesModal;
