import { fmt, intl } from 'IntlWrapper/IntlWrapper';

import { URL_FLAGS } from 'constants/country-data';
import { PICKUP_LOCATION_OPTIONS, BULKY } from 'constants/pricing';

export const formatSizeName = (name) => {
  let formattedName = '';

  if (name.sizeAlias !== name.sizeName) {
    formattedName = name.sizeAlias + ' ' + name.sizeName;
    formattedName = formattedName.replace('-', '_');
    formattedName = formattedName.replace(/\s/g, '_');
    formattedName = formattedName.toLowerCase();
  } else {
    formattedName = name.sizeAlias;
    formattedName = formattedName.replace('-', '_');
    formattedName = formattedName.replace(/\s/g, '_');
    formattedName = formattedName.toLowerCase();
  }
  return formattedName;
};

export const formatData = (sector, index) => {
  let sectorsFormat = [
    {
      key: index++ / 10,
      name:
        intl.locale === 'en'
          ? sector.dropoffSectorName
          : sector.dropoffSectorNameArabic,
      value: PICKUP_LOCATION_OPTIONS[sector.dropoffSectorId - 1].value,
      dropOffSectorName: sector.dropoffSectorId
    }
  ];
  for (let i = 0; i < sector.tierServiceTypes[0].tierSizes.length; i++) {
    if (sector.tierServiceTypes[0].tierSizes[i].sizeName !== BULKY) {
      sectorsFormat.push({
        key: index++ * 10,
        name: fmt({
          id: `settings.pricing_plan.sizes.${formatSizeName(
            sector.tierServiceTypes[0].tierSizes[i]
          )}`
        }),
        otherDay: sector.tierServiceTypes[0].tierSizes[i]?.otherDay,
        deliver:
          Math.round(sector.tierServiceTypes[0].tierSizes[i].cost * 10) / 10,
        exchange:
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Bulky' ||
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Sign and Return'
            ? '-'
            : Math.round(sector.tierServiceTypes[1].tierSizes[i].cost * 10) /
              10,
        return:
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Bulky' ||
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Sign and Return'
            ? '-'
            : Math.round(sector.tierServiceTypes[2].tierSizes[i].cost * 10) /
              10,
        cash_collection:
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Bulky' ||
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Sign and Return'
            ? '-'
            : Math.round(sector.tierServiceTypes[3].tierSizes[i].cost * 10) /
              10,
        rto:
          sector.tierServiceTypes[0].tierSizes[i].sizeName === 'Sign and Return'
            ? '-'
            : Math.round(sector.tierServiceTypes[4].tierSizes[i].cost * 10) /
              10,
        dropOffSectorName: sector.dropoffSectorId
      });
    }
  }
  return sectorsFormat;
};

export const formatPricingTableData = (pricingData) => {
  const tableData = [];
  pricingData.map((sector, index) => {
    let sectorData = formatData(sector, index * 10);
    tableData.push(...sectorData);
  });
  return tableData;
};

export const typeObject = {
  CASH_COLLECTION: 3,
  CUSTOMER_RETURN_PICKUP: 2,
  EXCHANGE: 1,
  SEND: 0,
  RTO: 4
};

export const hasEqualRates = (list) => {
  return list.every(({ rate }) => rate === list[0].rate);
};

export const renderInternationalExchangeSelect = (options) => {
  const { list, currency, countryName, currencyDetails } = options;
  return list.map((itm) => ({
    key: itm?.currency,
    value: itm?.currency,
    label: (
      <div className="br-pricing__inter-location-option">
        <img
          className="br-international-pricing__country-flag"
          src={URL_FLAGS + itm?.code + '.svg'}
          alt="flag"
        />
        <span>
          <p className="body">{currency && itm?.currency}</p>
          <p className="body br-pricing__exchange-options-text">
            {countryName ? itm?.name : currencyDetails && itm?.currencyDetails}
          </p>
        </span>
      </div>
    )
  }));
};
