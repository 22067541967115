import { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, Rate, Input } from 'antd';
import classnames from 'classnames';

import { rateStar } from 'services/deliveries';

import { notify } from 'components/Notify/Notify';

import './RateStarModal.less';

const RateStarModal = ({ close, intl, onSumbit, ...props }) => {
  const [rateIndex, setRateIndex] = useState(null);
  const [feedback, setFeedback] = useState(null);

  const [showErrorMessage, setShowErrorMessage] = useState({
    rateIndexError: false,
    feedbackError: false
  });

  const checkFormValues = ({
    rateValue = rateIndex,
    feedBackValue = feedback
  }) => {
    switch (true) {
      case !rateValue:
        return setShowErrorMessage({
          rateIndexError: true,
          feedbackError: false
        });
      case rateValue > 5:
        return setShowErrorMessage({
          rateIndexError: false,
          feedbackError: false
        });
      case rateValue <= 5 && !feedBackValue:
        return setShowErrorMessage({
          rateIndexError: false,
          feedbackError: true
        });
      default:
        return setShowErrorMessage({
          rateIndexError: false,
          feedbackError: false
        });
    }
  };

  const handleOnChangeFeedback = useCallback(
    ({ target: { value } }) => {
      setFeedback(value);
      checkFormValues({ rateValue: rateIndex, feedBackValue: value });
    },
    [feedback, rateIndex]
  );

  const handleOnChangeRate = useCallback(
    (rateValue) => {
      setRateIndex(rateValue);
      checkFormValues({ rateValue, feedBackValue: feedback });
    },
    [rateIndex, feedback]
  );

  const handleSumbitRate = useCallback(async () => {
    checkFormValues({});
    if (!((rateIndex && rateIndex <= 5 && !feedback) || !rateIndex)) {
      try {
        const payload = {
          rate: rateIndex,
          rateReason: feedback || null
        };
        const result = await rateStar(props.delivery._id, payload);
        onSumbit();
        close();
      } catch (error) {
        notify(error.message);
      }
    }
  }, [rateIndex, feedback]);

  return (
    <Modal onCancel={close} {...props} title={null} footer={null} width={null}>
      <div className="br-rate-star__header">Rate Star</div>

      <div className="br-rate-star__number-cards">
        <span className="br-rate-star__number-cards__title">
          {intl.formatMessage({
            id: 'deliveries.delivery_details.rate_star_modal.title'
          })}
        </span>
        <div className="br-rate-star__number-cards__rates">
          <Rate
            onChange={handleOnChangeRate}
            character={({ index }) => (
              <span
                className={classnames('br-rate-star__number-card', {
                  'br-rate-star__number-card--focused': rateIndex === index + 1
                })}
              >
                {index + 1}
              </span>
            )}
            count={10}
          />
          {showErrorMessage?.rateIndexError && (
            <span className="ant-form-item-explain ant-form-item-explain-error">
              {intl.formatMessage({
                id: 'deliveries.delivery_details.rate_star_modal.rate_validation_error'
              })}
            </span>
          )}
        </div>
        <Input.TextArea
          placeholder="Add consignee feedback about the star.."
          autoSize={{ minRows: 3.1, maxRows: 3.1 }}
          onChange={handleOnChangeFeedback}
        />
        {showErrorMessage?.feedbackError && (
          <span className="ant-form-item-explain ant-form-item-explain-error full-width">
            {intl.formatMessage({
              id: 'deliveries.delivery_details.rate_star_modal.consignee_feedback_validation_error'
            })}
          </span>
        )}
      </div>
      <div className="br-rate-star__footer">
        <Button onClick={close}>
          {intl.formatMessage({
            id: 'common.cancel'
          })}
        </Button>
        <Button type="primary" onClick={handleSumbitRate}>
          {intl.formatMessage({
            id: 'common.submit'
          })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(RateStarModal);
