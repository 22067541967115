import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Drawer, Card } from 'antd';
import Icon from '@ant-design/icons';

import { DELIVERY_STATES_CODES } from 'constants/shipments';

import ViewDetailsTabs from './ViewDetailsTabs';
import BRImage from 'components/BRImage/BRImage';

import { ReactComponent as CorrectIcon } from 'assets/imgRevamp/correct.svg';
import { ReactComponent as WrongIcon } from 'assets/imgRevamp/wrong.svg';

import './ViewDetails.less';

const OrdersCount = ({ title, count }) => (
  <div>
    <div>{title}</div>
    <span>{count}</span>
  </div>
);

const ViewDetails = ({
  visible,
  setVisible,
  intl,
  businessData,
  routesIds,
  hubName,
  getUpdatedData
}) => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [returnedOrders, setReturnedOrders] = useState([]);
  const [rejectedOrders, setRejectedOrders] = useState([]);

  const { PICKED_UP, RETURNED_TO_BUSINESS } = DELIVERY_STATES_CODES;

  const {
    _id,
    signaturePhoto,
    rtoNationalIdPhoto,
    deliveries,
    returnContactPerson
  } = businessData || {};
  const renderedSignatureIcon = (
    <Icon component={signaturePhoto ? CorrectIcon : WrongIcon} />
  );
  const renderedNationalIdPhotoIcon = (
    <Icon component={rtoNationalIdPhoto ? CorrectIcon : WrongIcon} />
  );

  const renderedReturnContactPersonIcon = (
    <Icon component={returnContactPerson ? CorrectIcon : WrongIcon} />
  );

  const orders = [
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.pending_orders'
      }),
      count: pendingOrders.length
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.returned_orders'
      }),
      count: returnedOrders.length
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.rejected_orders'
      }),
      count: rejectedOrders.length
    }
  ];

  const onClose = () => {
    setVisible(false);
  };

  const splitOrders = () => {
    const pending = [];
    const returned = [];
    const rejected = [];

    deliveries?.forEach((delivery) => {
      if (delivery?.state?.code === PICKED_UP) {
        pending.push(delivery);
      } else if (delivery?.state?.code === RETURNED_TO_BUSINESS) {
        returned.push(delivery);
      } else {
        rejected.push(delivery);
      }
    });

    setPendingOrders(pending);
    setReturnedOrders(returned);
    setRejectedOrders(rejected);
  };

  useEffect(() => {
    splitOrders();
  }, [businessData]);

  return (
    <Drawer
      className="br-packages-debrief__rto-tab__view-details"
      title={
        <>
          <span>
            {intl.formatMessage({
              id: 'hubs.packages_debrief.rto_tab.view_details.title'
            })}
          </span>
          <div className="br-packages-debrief__rto-tab__view-details__title">
            {_id?.businessName}
          </div>
        </>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <div className="br-packages-debrief__rto-tab__view-details__cards">
        <Card className="br-packages-debrief__rto-tab__view-details__cards__orders-counts">
          {orders.map(({ title, count }) => (
            <OrdersCount title={title} count={count} />
          ))}
        </Card>
        {returnContactPerson && (
          <Card
            title={intl.formatMessage({
              id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.contact_info'
            })}
            extra={renderedReturnContactPersonIcon}
          >
            <div>
              <div>{returnContactPerson.name}</div>
              <span>{returnContactPerson.phone}</span>
            </div>
          </Card>
        )}
        {signaturePhoto && (
          <Card
            title={intl.formatMessage({
              id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.signature'
            })}
            extra={renderedSignatureIcon}
          >
            <BRImage src={signaturePhoto} preview />
          </Card>
        )}
        {!returnContactPerson && (
          <Card
            title={intl.formatMessage({
              id: 'hubs.packages_debrief.rto_tab.view_details.tabs.cards.national_id'
            })}
            extra={renderedNationalIdPhotoIcon}
          >
            <BRImage src={rtoNationalIdPhoto} preview />
          </Card>
        )}
      </div>
      <ViewDetailsTabs
        pendingOrders={pendingOrders}
        returnedOrders={returnedOrders}
        rejectedOrders={rejectedOrders}
        routesIds={routesIds}
        hubName={hubName}
        getUpdatedData={getUpdatedData}
      />
    </Drawer>
  );
};

export default injectIntl(ViewDetails);
