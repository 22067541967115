import { useState } from 'react';
import classnames from 'classnames';

import './BRCardsFilter.less';

// Colors: yellow, purple, blue, green, gray, teal

const BRCardsFilter = ({ items, defaultActive, onFilterChange = () => {} }) => {
  const [activeKey, setActiveKey] = useState(defaultActive);

  const handleCardClick = (value) => {
    setActiveKey(value);
    onFilterChange(value);
  };

  return (
    <div className="br-cards-filter__container">
      {items.map(
        ({ value, label, color = 'gray', count, icon, fill = true }) => (
          <div
            key={value}
            onClick={() => handleCardClick(value)}
            className={classnames(
              'br-cards-filter__card',
              `br-cards-filter__card-${color}`,
              {
                'br-cards-filter__card-active': activeKey === value
              }
            )}
          >
            <div className="br-cards-filter-card__content">
              <div className="br-cards-filter-card__label">{label}</div>
              <div className="display-sm">{count || '-'}</div>
            </div>
            {icon && (
              <div
                className={classnames('br-cards-filter-card__icon', {
                  'no-fill': !fill
                })}
              >
                {icon}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default BRCardsFilter;
