import http from 'utils/http';

export const generateAPIKey = (businessId, payload) => {
  return http.post(`/apikey/${businessId}`, payload);
};

export const getAllApiKeysInfo = (businessId) => {
  return http.get(`/apikey/${businessId}`);
};
export const updateAPIKey = (id, payload) => {
  return http.put(`/apikey/${id}`, payload);
};
export const deleteAPIKey = (id) => {
  return http.delete(`/apikey/${id}`);
};
