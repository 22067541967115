import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import { getBusiness } from 'services/business';
import {
  getImpersonationAccess,
  getImpersonationRequestStatus,
  revokeImpersonationAccess,
  sendImpersonationRequest
} from 'services/impersonation';
import {
  IMPERSONATION_REQUEST_STATUS,
  IMPERSONATION_REQUEST_STATUS_ICONS,
  SLLR_DASHBOARD_URL
} from 'constants/impersonation';
import { openInNewTab } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Banner from 'components/Banner/Banner';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import CountdownTimer from 'components/CountdownTimer/CountDownTimer';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Shop } from 'assets/bosta-icons/Shop.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';

import './Impersonation.less';

const { APPROVED, PENDING } = IMPERSONATION_REQUEST_STATUS;

const Impersonation = ({ close, ...props }) => {
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [selectedBusinessDetails, setSelectedBusienssDetails] = useState({});
  const [requestStatus, setRequestStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const interval = useRef(null);

  const intl = useIntl();

  useEffect(() => {
    clearInterval(interval.current);
    if (selectedBusinessId) {
      getBusinessDetails();
      getRequestStatus();
    }

    return () => clearInterval(interval.current);
  }, [selectedBusinessId]);

  const registerPolling = () => {
    interval.current = setInterval(getRequestStatus, 5000);
  };

  const getBusinessDetails = async () => {
    setIsLoading(true);
    try {
      const data = await getBusiness(selectedBusinessId);
      setSelectedBusienssDetails(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getRequestStatus = async () => {
    try {
      const { data } = await getImpersonationRequestStatus({
        businessIds: selectedBusinessId
      });
      if (data?.length) {
        setRequestStatus(data[0]);

        if (
          data[0].targetBusiness.impersonationStatus === PENDING &&
          !interval.current
        ) {
          registerPolling();
        } else if (data[0].targetBusiness.impersonationStatus === APPROVED) {
          clearInterval(interval.current);
        }
      } else {
        setRequestStatus({});
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handleSendRequestClick = async () => {
    clearInterval(interval.current);
    setIsLoading(true);
    try {
      const { data } = await sendImpersonationRequest({
        businessId: selectedBusinessId
      });
      setRequestStatus(data);
      registerPolling();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleAccessDashboard = async () => {
    setIsLoading(true);
    try {
      const { data } = await getImpersonationAccess(selectedBusinessId);
      if (data.token) {
        openInNewTab(
          `${SLLR_DASHBOARD_URL}/overview?token=${data.token
            .replace('Bearer', '')
            .trim()}`
        );
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleRevokeAccess = async () => {
    setIsLoading(true);
    try {
      await revokeImpersonationAccess(selectedBusinessId);
      setRequestStatus({});
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getBusinessOwnerDetails = () => ({
    ownerName: selectedBusinessDetails.admins[0].name,
    phoneNumber: selectedBusinessDetails.admins[0].phone,
    businessName: selectedBusinessDetails.name
  });

  const renderStatusDetails = () => {
    const { impersonationStatus, expirationDate } =
      requestStatus.targetBusiness || {};
    const { ownerName, phoneNumber, businessName } = getBusinessOwnerDetails();

    if ([APPROVED, PENDING].includes(impersonationStatus)) {
      return (
        <BREmptyState
          emptyViewImage={
            IMPERSONATION_REQUEST_STATUS_ICONS[impersonationStatus]
          }
          className={
            impersonationStatus === APPROVED
              ? 'approved-state'
              : 'pending-state'
          }
          title={intl.formatMessage(
            {
              id: `impersonation.status.${impersonationStatus.toLowerCase()}.title`
            },
            {
              businessName,
              span: (children) => (
                <span className="br-impersonation__business-name">
                  {children}
                </span>
              )
            }
          )}
          subTitle={intl.formatMessage(
            {
              id: `impersonation.status.${impersonationStatus.toLocaleLowerCase()}.subtitle`
            },
            {
              phoneNumber,
              timeLeft: <CountdownTimer futureDate={expirationDate} />,
              span: (children) => (
                <span className="body-medium">{children}</span>
              )
            }
          )}
        />
      );
    } else {
      return (
        <Banner
          type="info"
          icon={<TooltipIcon />}
          isAntIcon
          subText={intl.formatMessage(
            {
              id: 'impersonation.business_details_banner'
            },
            {
              ownerName,
              phoneNumber,
              span: (children) => (
                <span className="body-medium">{children}</span>
              )
            }
          )}
        />
      );
    }
  };

  const renderFooter = () => {
    const { impersonationStatus } = requestStatus.targetBusiness || {};
    const updatedAt = requestStatus.updatedAt;

    const waitTimeFutureDate = dayjs(updatedAt).add(10, 'minutes');
    const waitTimePassed = waitTimeFutureDate < dayjs();

    switch (impersonationStatus) {
      case PENDING:
        return (
          <BRButton
            type="primary"
            disabled={!waitTimePassed || isLoading}
            onClick={handleSendRequestClick}
            label={intl.formatMessage(
              {
                id: `impersonation.${
                  waitTimePassed ? 'resend_request' : 'unlock_waiting'
                }`
              },
              {
                timeLeft: (
                  <CountdownTimer futureDate={waitTimeFutureDate} showTime />
                )
              }
            )}
          />
        );
      case APPROVED:
        return (
          <>
            <BRButton
              disabled={isLoading}
              label={intl.formatMessage({ id: 'impersonation.revoke_access' })}
              onClick={handleRevokeAccess}
            />
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'impersonation.access_dashboard'
              })}
              suffixIcon={<ExternalLink />}
              onClick={handleAccessDashboard}
              disabled={isLoading}
            />
          </>
        );
      default:
        return (
          <BRButton
            type="primary"
            onClick={handleSendRequestClick}
            label={intl.formatMessage({ id: 'impersonation.send_request' })}
            disabled={!selectedBusinessId || isLoading}
          />
        );
    }
  };

  return (
    <Modal
      width={null}
      centered
      wrapClassName="br-impersonation__modal"
      title={
        <>
          {intl.formatMessage({ id: 'impersonation.modal_title' })}
          <Close onClick={close} />
        </>
      }
      footer={renderFooter()}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <div className="br-impersonation-modal__container">
          {!selectedBusinessId && (
            <div className="br-impersonation-modal__header">
              <div className="display-xs">
                {intl.formatMessage({ id: 'impersonation.title' })}
              </div>
              <div className="br-impersonation-modal__subtitle">
                {intl.formatMessage({ id: 'impersonation.subtitle' })}
              </div>
            </div>
          )}
          <Form.Item
            label="Business"
            className="br-impersonation-business__selector"
          >
            <BusinessSelector
              onChange={setSelectedBusinessId}
              showValueInLabel
              placeholder={intl.formatMessage({
                id: 'impersonation.business_placeholder'
              })}
              prefixIcon={<Shop />}
            />
          </Form.Item>
          {!isEmpty(selectedBusinessDetails) && (
            <div className="br-impersonation-status__content">
              {renderStatusDetails()}
            </div>
          )}
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default Impersonation;
