import { fmt } from 'IntlWrapper/IntlWrapper';

export const courierStates = {
  SEND: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    25: fmt({
      id: 'deliveries.states.fulfilled'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    45: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  SIGN_AND_RETURN: {
    deliver: {
      10: fmt({
        id: 'deliveries.states.created'
      }),
      20: fmt({
        id: 'deliveries.states.route_assigned'
      }),
      21: fmt({
        id: 'deliveries.states.picked_up_from_business'
      }),
      24: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
      30: fmt({
        id: 'deliveries.states.in_transit_to_bosta'
      }),
      22: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
      23: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
      47: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      })
    },
    pickup: {
      42: fmt({
        id: 'deliveries.states.pickup_requested'
      }),
      43: fmt({
        id: 'deliveries.states.debriefed_successfully'
      }),
      47: fmt({
        id: 'deliveries.states.exception'
      }),
      20: fmt({
        id: 'deliveries.states.route_assigned'
      }),
      22: fmt({
        id: 'deliveries.states.out_for_pickup'
      }),
      23: fmt({
        id: 'deliveries.states.picked_up'
      }),
      24: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
      30: fmt({
        id: 'deliveries.states.in_transit_to_bosta'
      }),
      41: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
      46: fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      })
    },
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  RTO: {
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    46: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47.1: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    60: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  EXCHANGE_return: {
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    46: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47.1: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },

  destructive: {
    48: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    50: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    100: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    101: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    102: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    103: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    105: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      }),
    104: () =>
      fmt({
        id: 'deliveries.states.received_at_bosta_warehouse'
      })
  },
  CUSTOMER_RETURN_PICKUP: {
    10: fmt({
      id: 'deliveries.states.requested'
    }),
    20: fmt({
      id: 'deliveries.states.pickup_route_assigned'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_pickup'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    46: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49.1: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  EXCHANGE: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    45: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    46: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  EXCHANGE_forward: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    24: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_to_bosta'
    }),
    41: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    45: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    47: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    }),
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  },
  CASH_COLLECTION: {
    11: fmt({
      id: 'deliveries.states.waiting_for_route'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    40: fmt({
      id: 'deliveries.states.out_for_collection'
    }),
    45: fmt({
      id: 'deliveries.states.collected'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49: fmt({
      id: 'deliveries.states.deleted'
    }),
    49.1: fmt({
      id: 'deliveries.states.canceled'
    })
  },
  Return: {
    49: fmt({
      id: 'deliveries.states.received_at_bosta_warehouse'
    })
  }
};
