import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { CAPITAL_ROUTES_URL } from 'constants/borrow';

import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import BRTabs from 'components/BRTabs/BRTabs';
import CapitalAppliedBusinesses from './CapitalAppliedBusinesses';

import './BostaCapital.less';

const BostaCapital = ({ intl }) => {
  const TABS = {
    applied: {
      label: intl.formatMessage({ id: 'capital.tabs.tab_1' }),
      component: CapitalAppliedBusinesses,
      withProps: true,
      componentProps: {
        applied: true
      },
      path: CAPITAL_ROUTES_URL.APPLIED
    },
    not_applied: {
      label: intl.formatMessage({ id: 'capital.tabs.tab_2' }),
      component: CapitalAppliedBusinesses,
      withProps: true,
      componentProps: {
        applied: false
      },
      path: CAPITAL_ROUTES_URL.DIDNT_APPLY
    }
  };

  return (
    <Container
      className="br-bosta-capital__container"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'capital.title'
          })}
        />
      }
      content={
        <Switch>
          <Route
            path={[CAPITAL_ROUTES_URL.APPLIED, CAPITAL_ROUTES_URL.DIDNT_APPLY]}
            render={() => <BRTabs tabs={TABS} />}
          />
          <Redirect to={CAPITAL_ROUTES_URL.APPLIED} />
        </Switch>
      }
    />
  );
};

export default injectIntl(withRouter(BostaCapital));
