import { put, takeLatest } from 'redux-saga/effects';
import {
  getReports,
  getReportDetails,
  getReportCSV,
  uploadArReport,
  downloadArReports,
  generateReport,
  getSummaryReports,
  getSummaryReportCSV,
  getReportDetailsXLSX,
} from '../actions/ARReportsAction';
import { endLoading, startLoading } from '../actions';
import actionTypes from '../actions/ActionTypes';

function* getAllARReports(payload) {
  let results = [];
  const { success, data } = yield getReports(payload.data);
  if (success) results = data;
  yield put({
    type: actionTypes.SET_AR_REPORTS,
    payload: results,
  });
}

function* getAllSummaryReports(payload) {
  let results = [];
  const { success, data } = yield getSummaryReports(payload.data);
  if (success) results = data;
  yield put({
    type: actionTypes.SET_SUMMARY_REPORTS,
    payload: results,
  });
}

function* getAllARReportDetails(payload) {
  let results = [];
  const { success, data } = yield getReportDetails(payload.data);
  if (success) results = data;
  yield put({
    type: actionTypes.SET_AR_REPORT_DETAILS,
    payload: results,
  });
}

function* getRemoveARReportDetails() {
  yield put({
    type: actionTypes.SET_AR_REPORT_DETAILS,
    payload: [],
  });
}
function* getRemoveGenerateReport() {
  yield put({
    type: actionTypes.SET_AR_GENERATE_REPORT,
    payload: false,
  });
}

function* generateArReport(payload) {
  const data = yield generateReport(payload.data);
  yield put({
    type: actionTypes.SET_AR_GENERATE_REPORT,
    payload: data,
  });
}

function* getAllARReportCSV(payload) {
  yield put(startLoading());
  const data = yield getReportCSV(payload.data);
  if (data) {
    yield put({
      type: actionTypes.SET_AR_REPORT_CSV,
      payload: true,
    });
  }
  yield put(endLoading());
}

function* getAllSummaryReportCSV(payload) {
  yield put(startLoading());
  const data = yield getSummaryReportCSV(payload.data);
  if (data) {
    yield put({
      type: actionTypes.SET_SUMMARY_REPORT_CSV,
      payload: true,
    });
  }
  yield put(endLoading());
}

function* uploadArReportSaga(payload) {
  yield put(startLoading());
  const data = yield uploadArReport(payload.data);
  if (data) {
    yield put({
      type: actionTypes.SET_UPLOAD_AR_REPORT,
      payload: true,
    });
  }
  yield put(endLoading());
}

function* downloadArReportsSaga(payload) {
  yield put(startLoading());
  const response = yield downloadArReports(payload.data);
  yield put({
    type: actionTypes.SET_GENERATE_CSV,
    payload: !!response,
  });
  yield put(endLoading());
}

function* downloadArReportsDetailsCSVSaga(payload) {
  yield put(startLoading());
  let results = [];
  const { success, data } = yield getReportDetailsXLSX(payload.data);
  if (success) results = data;
  yield put({
    type: actionTypes.SET_AR_REPORT_CSV_DETAILS,
    payload: results,
  });

  yield put(endLoading());
}

export default function* adminWatcher() {
  yield takeLatest(actionTypes.GET_AR_REPORTS, getAllARReports);
  yield takeLatest(actionTypes.GET_SUMMARY_REPORTS, getAllSummaryReports);
  yield takeLatest(actionTypes.GET_SUMMARY_REPORT_CSV, getAllSummaryReportCSV);
  yield takeLatest(actionTypes.GET_AR_REPORT_DETAILS, getAllARReportDetails);
  yield takeLatest(actionTypes.GET_AR_GENERATE_REPORT, generateArReport);
  yield takeLatest(actionTypes.GET_AR_REPORT_CSV, getAllARReportCSV);
  yield takeLatest(actionTypes.UPLOAD_AR_REPORT, uploadArReportSaga);
  yield takeLatest(
    actionTypes.GET_REMOVE_GENERATE_REPORT,
    getRemoveGenerateReport,
  );
  yield takeLatest(actionTypes.GET_REMOVE_AR_REPORTS, getRemoveARReportDetails);
  yield takeLatest(actionTypes.GET_AR_REPORT, downloadArReportsSaga);
  yield takeLatest(
    actionTypes.GET_AR_REPORT_CSV_DETAILS,
    downloadArReportsDetailsCSVSaga,
  );
}
