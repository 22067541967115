import { fmt } from 'IntlWrapper/IntlWrapper';

export const GOOGLE_MAP_API_KEY =
  window.env && window.env.GOOGLE_MAPS_KEY ? window.env.GOOGLE_MAPS_KEY : '';

export const COLORS = {
  RED: '#FF0000',
  BLUE: '#0000FF',
  YELLOW: '#FFFF00'
};

export const RANDOM_COLORS_LIST = [
  '#0098A5',
  '#DC6803',
  '#12B76A',
  '#FA7066',
  '#FEC84B',
  '#7A2E0E',
  '#667085',
  '#101828'
];

export const DYNAMIC_ROUTES_STATES = {
  Drafted: {
    STATE_NAME: 'Drafted',
    COLOR: COLORS.RED
  },
  Created: {
    STATE_NAME: 'Created',
    COLOR: '#008000'
  },
  Merged: {
    STATE_NAME: 'Merged',
    COLOR: COLORS.RED
  },
  Splitted: {
    STATE_NAME: 'Splitted',
    COLOR: COLORS.RED
  }
};

export const DEFAULT_COORDINATES_CENTER = {
  lat: 30.0444,
  lng: 31.2357
};

export const TOMORROW_ROUTES_ACTIONS = {
  MERGE_ROUTES: 'MERGE_ROUTES',
  SPLIT_ROUTES: 'SPLIT_ROUTES',
  MERGE_DELIVERIES: 'MERGE_DELIVERIES'
};

export const TOMORROW_ROUTES_ACTIONS_OPTIONS = [
  {
    value: TOMORROW_ROUTES_ACTIONS.MERGE_ROUTES,
    label: fmt({
      id: 'geofences.route_geofences.tabs.tomorrow_routes.merge_routes'
    })
  },
  {
    value: TOMORROW_ROUTES_ACTIONS.SPLIT_ROUTES,
    label: fmt({
      id: 'geofences.route_geofences.tabs.tomorrow_routes.split_routes'
    })
  },
  {
    value: TOMORROW_ROUTES_ACTIONS.MERGE_DELIVERIES,
    label: fmt({
      id: 'geofences.route_geofences.tabs.tomorrow_routes.merge_deliveries'
    })
  }
];

export const POLYGON = 'Polygon';
