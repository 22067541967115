import { injectIntl } from 'react-intl';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import InfoCard from 'components/NewFulfillment/Businesses/components/BusinessesList/components/BusinessTabs/components/BusinessInfo/components/InfoCard/InfoCard';

import { ReactComponent as ShopIcon } from 'assets/bosta-icons/Shop.svg';
import { ReactComponent as HashIcon } from 'assets/bosta-icons/HashIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/bosta-icons/Location.svg';
import { ReactComponent as ProfileIcon } from 'assets/bosta-icons/Profile.svg';
import { ReactComponent as PhoneNumberIcon } from 'assets/bosta-icons/phone-number.svg';
import { ReactComponent as EmailIcon } from 'assets/bosta-icons/Email.svg';
import { ReactComponent as CopyIcon } from 'assets/bosta-icons/Copy.svg';

import './BusinessInfo.less';

const BusinessInfo = ({ intl, businessInfo, isLoading }) => {
  const copyEmail = () => {
    navigator.clipboard.writeText(businessInfo.contactInfo?.email);
    notify(
      intl.formatMessage({
        id: 'fulfillment_businesses.business_details.email_copied_successfully'
      }),
      'success'
    );
  };

  const cards = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_businesses.business_details.business_info'
      }),
      infoList: [
        {
          prefixIcon: <ShopIcon />,
          content: businessInfo.businessName
        },
        {
          prefixIcon: <HashIcon />,
          content: businessInfo._id
        },
        {
          prefixIcon: <LocationIcon />,
          content: businessInfo.cityName
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_businesses.business_details.contact_info'
      }),
      infoList: [
        {
          prefixIcon: <ProfileIcon />,
          content: businessInfo.contactInfo?.name
        },
        {
          prefixIcon: <PhoneNumberIcon />,
          content: businessInfo.contactInfo?.phone
        },
        {
          prefixIcon: <EmailIcon />,
          content: businessInfo.contactInfo?.email,
          suffixIcon: <CopyIcon className="copy-icon" onClick={copyEmail} />
        }
      ]
    }
  ];

  return (
    <div className="br-fulfillment-business-info">
      <LoadingWrapper loading={isLoading}>
        {!isLoading &&
          cards.map((cardProps, index) => (
            <InfoCard key={index} {...cardProps} />
          ))}
      </LoadingWrapper>
    </div>
  );
};

export default injectIntl(BusinessInfo);
