import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Typography, Button, Tag, Table, Tooltip, Rate } from 'antd';
import dayjs from 'dayjs';

import { notify } from 'components/Notify/Notify';
import { getPickupInfo } from 'services/pickupDetails';
import {
  pickupsStatusColors,
  formatAddress,
  OUTBOUND_AGENT,
  OUTBOUND,
  DELIVERIES_TYPES,
  PICKUP_TICKET_LOG,
  PICKUP_REQUESTS_CANCEL_REASON,
  PICKUP_TYPES,
  PICKUP_LOG_ACTIONS,
  PICKUP_RATING_ITEMS
} from 'constants/pickups';
import {
  mapStateToStatus,
  pickupsStatus,
  mapCancelReason
} from 'utils/pickups';
import { openModal } from 'utils/modal';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { TICKET_LINK } from 'constants/shipments';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRInformationtable from 'components/BRInformationTable/BRInformationTable';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';
import CallAndSmsLogs from 'components/Shipments/components/NewDeliveryDetails/components/DeliveryLogs/components/CallAndSmsLogs/CallAndSmsLogs';
import BRButton from 'components/BRButton/BRButton';
import ServiceRating from 'components/ServiceRating/ServiceRating';

import { ReactComponent as NewOrder } from 'assets/bosta-icons/New order.svg';

import './PickupDetails.less';

const pickupConfigArray = (intl, stateStyle, data) => {
  return [
    {
      key: 'puid',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.pickup_id'
      }),
      render: (puid) => (
        <div className="display-flex">
          {puid}
          {data?.starInitiatedAction && (
            <Tooltip
              placement="bottom"
              title={intl.formatMessage({
                id: 'pickups.created_by_star'
              })}
            >
              <div className="br-pickups-created-by-star__icon">
                <NewOrder />
              </div>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      key: 'scheduledDate',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.scheduled_date'
      })
    },
    {
      key: 'state',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.state'
      }),
      render: (state) => {
        return (
          <>
            <Tag
              color={stateStyle?.color}
              className={`br-pickups-status__tag-color-${stateStyle?.color}`}
            >
              {state}
            </Tag>
            {state === pickupsStatus.Canceled[0] &&
            (data.cancelReason || data.exceptionCode) ? (
              <div className="br-pickups-reasons-info">
                {data.exceptionCode
                  ? PICKUP_REQUESTS_CANCEL_REASON[
                      data.type === PICKUP_TYPES.FIRST_MILE_PICKUP
                        ? PICKUP_TYPES.FIRST_MILE_PICKUP
                        : PICKUP_TYPES.CRP_PICKUP
                    ][data.exceptionCode]
                  : intl.formatMessage({
                      id: `pickups.cancel_reasons.${
                        data.cancelReason.includes('_')
                          ? data.cancelReason
                          : mapCancelReason[data.cancelReason]
                      }`
                    })}
                {data.nextDayRescheduledPickupId && (
                  <p>
                    {intl.formatMessage({
                      id: `pickups.new_pickups`
                    })}
                    {data.nextDayRescheduledPickupId}
                  </p>
                )}
              </div>
            ) : (
              data.pickupRescheduledForId && (
                <p>
                  {intl.formatMessage({
                    id: `pickups.old_pickups`
                  })}
                  {data.pickupRescheduledForId}
                </p>
              )
            )}
          </>
        );
      }
    },
    {
      key: 'business',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.business'
      }),
      render: (business) => {
        return isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])
          ? business?.name
          : business?._id;
      }
    },
    {
      key: 'type',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.type'
      })
    },
    {
      key: 'deliveries',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.order_tn'
      }),
      render: (deliveries) =>
        deliveries && deliveries[0] ? deliveries[0].trackingNumber : 'N/A'
    },
    {
      key: 'pickedupDate',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.pickup_date'
      })
    },
    {
      key: 'contactPerson',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.contact_person.name'
      }),
      render: (contactPerson) => {
        return contactPerson?.name;
      }
    },
    {
      key: 'business',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.address'
      }),
      render: (business) => {
        if (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])) {
          return data.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP ||
            data.type === DELIVERIES_TYPES.SIGN_RETURN_PICKUP
            ? formatAddress(data?.consigneeAddress)
            : formatAddress(data?.business?.address);
        }
        return 'N/A';
      }
    },
    {
      key: 'packages',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.number_of_packages'
      })
    },
    {
      key: 'numberOfParcels',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.number_of_parcels'
      })
    },
    {
      key: 'contactPerson',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.contact_person.phone'
      }),
      render: (contactPerson) => {
        return isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])
          ? contactPerson?.phone
          : 'N/A';
      }
    },
    ...(data?.rating
      ? [
          {
            key: 'rating',
            render: (rating) => (
              <ServiceRating
                rating={rating}
                ratingsList={PICKUP_RATING_ITEMS}
              />
            ),
            colSpan: 3
          }
        ]
      : []),
    {
      key: 'business',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.area'
      }),
      render: () => {
        return data.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP ||
          data.type === DELIVERIES_TYPES.SIGN_RETURN_PICKUP
          ? `${data?.consigneeAddress?.zone?.name}-${data?.consigneeAddress?.district?.name}`
          : `${data?.business?.address?.zone?.name}-${data?.business?.address?.district?.name}`;
      }
    },
    {
      key: 'business',
      label: intl.formatMessage({
        id: 'pickups.pickup_request.table_columns.fm_district_code'
      }),
      render: () => {
        return (
          data.business?.address?.district?.fmCode ||
          intl.formatMessage({ id: 'common.empty_field' })
        );
      }
    },
    {
      key: 'warehouse',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.hub'
      }),
      render: (warehouse) => {
        return (
          <>
            <div>{warehouse?.name || 'N/A'}</div>
            {data.isHubAutoAssigned && !data.isHubReassigned && (
              <Tag color="blue">
                {intl.formatMessage({
                  id: 'pickups.auto_hub'
                })}
              </Tag>
            )}
          </>
        );
      }
    },
    {
      key: 'contactPerson.email',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.contact_person.email'
      }),
      render: (contactPerson) => {
        return contactPerson?.email;
      }
    },
    {
      key: 'business',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.city'
      }),
      render: () => {
        return data.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP ||
          data.type === DELIVERIES_TYPES.SIGN_RETURN_PICKUP
          ? data?.consigneeAddress?.city?.name
          : data?.business?.address?.city?.name;
      }
    },
    {
      key: 'routeId',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.route'
      })
    },
    {
      key: 'signature',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.contact_person.signature'
      }),
      render: (signature) => {
        return signature !== 'N/A' ? (
          <img
            onClick={() => {
              if (signature) {
                openModal(BRPreviewImage, {
                  previewImage: signature
                });
              }
            }}
            className="br-pickup-details__signature-image"
            src={signature}
          />
        ) : (
          'N/A'
        );
      }
    },
    {
      key: 'pickupReceipt',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.proof_of_pickup'
      }),
      render: (pickupReceipt) => {
        return pickupReceipt !== 'N/A' ? (
          <img
            onClick={() => {
              if (pickupReceipt) {
                openModal(BRPreviewImage, {
                  previewImage: pickupReceipt
                });
              }
            }}
            className="br-pickup-details__signature-image"
            src={pickupReceipt}
          />
        ) : (
          intl.formatMessage({ id: 'common.empty_field' })
        );
      }
    },
    {
      key: 'createdAt',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.creation_date'
      })
    },
    {
      key: 'star',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.star.name'
      }),
      render: (star) => {
        return star?.name;
      }
    },
    {
      key: 'star',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.star.phone'
      }),
      render: (star) => {
        return star?.phone;
      }
    },
    {
      key: 'notes',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.notes'
      })
    },
    {
      key: 'packageType',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.package_type'
      }),
      render: (packageType) => {
        return packageType;
      }
    },
    {
      key: 'pickupProofType',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.proof_of_pickup'
      }),
      render: (pickupProofType) => pickupProofType
    },
    {
      key: 'pickupOtp',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.pickup_info.otp'
      }),
      render: (pickupOTP) => pickupOTP
    }
  ];
};

const repetitionConfigArray = (intl) => {
  return [
    {
      key: 'repeatedData',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.start_date'
      }),
      render: (repeatedData) => {
        return repeatedData?.startDate;
      }
    },
    {
      key: 'repeatedData',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.repeated_type'
      }),
      render: (repeatedData) => {
        return repeatedData?.repeatedType;
      }
    },
    {
      key: 'repeatedData',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.next_pickup_date'
      }),
      render: (repeatedData) => {
        return repeatedData?.nextPickupDate;
      }
    },
    {
      key: 'repeatedData',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.end_date'
      }),
      render: (repeatedData) => {
        return repeatedData?.endDate;
      }
    },
    {
      key: 'repeatedData',
      label: intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.days'
      }),
      render: (repeatedData) => {
        return repeatedData?.days;
      }
    }
  ];
};

const PickupDetails = ({
  match: {
    params: { id }
  },
  history,
  intl
}) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [stateStyle, setStateStyle] = useState('');
  const formatData = (data) => {
    setStateStyle(pickupsStatusColors[mapStateToStatus(data.state)]);
    data.scheduledDate = data?.scheduledDate
      ? dayjs(data?.scheduledDate).tz().format('LLL')
      : 'N/A';
    data.createdAt = data?.createdAt
      ? dayjs(data?.createdAt).tz().format('LLL')
      : 'N/A';
    data.pickedupDate = data?.pickedupDate
      ? dayjs(data?.pickedupDate).tz().format('LLL')
      : 'N/A';
    data.address =
      data?.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP
        ? `${data?.consigneeAddress?.buildingNumber || ''} ${
            data?.consigneeAddress?.firstLine
          }`
        : `${data?.business?.address?.buildingNumber || ''} ${
            data?.business?.address?.firstLine
          }`;
    data.packages = data?.deliveries?.length || '0';
    data.city = data?.business?.address?.city?.name;
    data.zone =
      data?.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP
        ? data?.consigneeAddress?.zone?.name
        : data?.business?.address?.zone?.name;
    if (data?.repeatedData) {
      data.repeatedData.days = data?.repeatedData?.days?.join(',');
      data.repeatedData.startDate = data?.repeatedData?.startDate
        ? dayjs(data.repeatedData.startDate).tz().format('LL')
        : 'N/A';
      data.repeatedData.endDate = data?.repeatedData?.endDate
        ? dayjs(data.repeatedData?.endDate).tz().format('LL')
        : 'N/A';
      data.repeatedData.nextPickupDate = data?.repeatedData?.nextPickupDate
        ? dayjs(data.repeatedData?.nextPickupDate).tz().format('LL')
        : 'N/A';
    }
    data.deliveries = data.deliveries;
  };

  const handleViewProofOfExceptionClick = (previewImage) => {
    openModal(BRPreviewImage, {
      previewImageTitle: intl.formatMessage({
        id: 'pickups.pickup_details.proof_of_exception'
      }),
      previewImage
    });
  };

  const renderProofOfExceptionButton = (log) => {
    if (!log.exceptionProofUrl) {
      return;
    }

    return (
      <BRButton
        type="link-color"
        label={intl.formatMessage({
          id: 'pickups.pickup_details.proof_of_exception'
        })}
        onClick={() => handleViewProofOfExceptionClick(log.exceptionProofUrl)}
      />
    );
  };

  const renderLogAction = (log) => {
    if (log.action === PICKUP_LOG_ACTIONS.EXCEPTION) {
      return (
        <div>
          {intl.formatMessage(
            {
              id: 'pickups.pickup_details.action_reason'
            },
            {
              action: log.action,
              reason: mapCancelReason[log.reason]
                ? intl.formatMessage({
                    id: `pickups.cancel_reasons.${
                      log.reason.includes('_')
                        ? log?.reason
                        : mapCancelReason[log.reason]
                    }`
                  })
                : log.reason
            }
          )}
          {renderProofOfExceptionButton(log)}
        </div>
      );
    }

    return (
      <div>
        {log.action}
        {log.action === PICKUP_TICKET_LOG ? (
          <a
            className="br-pickup-details__ticket-link"
            href={`${TICKET_LINK}${log.ticketId}`}
            target="_blank"
          >
            {log.ticketId}
          </a>
        ) : (
          ''
        )}
        {renderProofOfExceptionButton(log)}
      </div>
    );
  };

  const logColumns = [
    {
      title: 'Date',
      dataIndex: 'time',
      render: (time) => {
        return dayjs(time).tz().format('ddd, D MMM  hh:mm A');
      },
      width: '250px'
    },
    {
      title: 'Action',
      render: (item) => renderLogAction(item),
      width: '250px'
    },
    {
      title: 'Done by',
      render: (item) => {
        const { takenBy } = item;
        if (
          item.actionType === OUTBOUND &&
          takenBy.userRole !== OUTBOUND_AGENT
        ) {
          return `${takenBy.userRole}: ${takenBy.userName} ${intl.formatMessage(
            {
              id: 'pickups.pickup_details.outbound_action'
            }
          )} `;
        }
        return `${takenBy.userRole}: ${takenBy.userName}`;
      }
    }
  ];

  const columns = [
    {
      title: 'Tracking number',
      dataIndex: 'trackingNumber',
      render: (trackingNumber, delivery) => {
        return delivery.isMissing ? (
          <div>
            {trackingNumber}
            <span className="br-pickup-details__missed-delivery-tag">
              Missing
            </span>
          </div>
        ) : (
          trackingNumber
        );
      }
    },
    {
      title: 'Customer Info',
      dataIndex: 'receiver',
      render: (receiver) => (
        <>
          <p>
            {receiver?.firstName} {receiver?.lastName}
          </p>
          <p>{receiver?.phone}</p>
        </>
      )
    },
    ,
    {
      title: 'Order type',
      dataIndex: 'type',
      render: (type) => {
        return type;
      }
    },

    {
      title: 'Dropoff location',
      dataIndex: 'dropOffAddress',
      render: (dropOffAddress) => (
        <>
          <p>{dropOffAddress?.firstLine}</p>
          <p>
            {`${dropOffAddress?.district?.name || 'N/A'}${
              dropOffAddress?.zone?.name ? `-${dropOffAddress?.zone?.name}` : ''
            },${dropOffAddress?.city?.name || 'N/A'}`}
          </p>
        </>
      )
    },
    ,
  ];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setIsLoading(true);
      const { message } = await getPickupInfo(id);
      formatData(message);
      await setData(message);
      setIsLoading(false);
      return {
        list: message?.deliveries,
        total: message?.deliveries?.length
      };
    } catch (error) {
      notify(error.message);
    }
  }

  return (
    <>
      <LoadingWrapper loading={isLoading}>
        <Container
          header={<BRHeader title="Pickup Requests Details" showBackArrow />}
          content={
            <div className="br-pickup-details">
              {isDefaultBostaCourier([
                ...aclMatrix.THREE_PL,
                VENDOR_MANAGER
              ]) && (
                <Button
                  type="primary"
                  className="br-pickup-details__header__edit-button"
                  hidden={data?.state !== 'Requested'}
                  onClick={() => {
                    history.push(`/pickups/edit/${id}`);
                  }}
                >
                  {intl.formatMessage({
                    id: 'pickups.pickup_details.edit_button'
                  })}
                </Button>
              )}
              <Typography.Title level={4}>
                {intl.formatMessage({
                  id: 'pickups.pickup_details.pickup_info.title'
                })}
              </Typography.Title>
              <BRInformationtable
                className="br-pickup-details__information-table"
                dataSource={data}
                cells={pickupConfigArray(intl, stateStyle, data)}
                totalCol={3}
              />
              {data?.repeatedData && data?.isRepeated && (
                <>
                  <Typography.Title level={4}>
                    {intl.formatMessage({
                      id: 'pickups.pickup_details.repetition_info.title'
                    })}
                  </Typography.Title>
                  <BRInformationtable
                    className="br-pickup-details__information-table"
                    dataSource={data}
                    cells={repetitionConfigArray(intl)}
                    totalCol={4}
                  />
                </>
              )}
              <BRSearchableTable
                title={intl.formatMessage(
                  { id: 'pickups.pickup_details.deliveries' },
                  { count: data?.deliveries?.length }
                )}
                className="br-pickup-details__table"
                // listFunction={fetchData}
                listData={data.deliveries}
                columns={columns}
                onRowClick={(item) => {}}
                withOutCheckBoxes
                hidePagination
              />
              <CallAndSmsLogs pickupDetails={data} />
              <div className="br-searchable-component__header">
                <Typography.Title level={4}>
                  {intl.formatMessage({ id: 'pickups.pickup_details.logs' })}
                </Typography.Title>
              </div>
              <Table
                scroll={{ x: 'max-content' }}
                rowKey="_id"
                className="br-searchable-table__listing"
                loading={isLoading}
                columns={logColumns}
                dataSource={data.log}
                pagination={false}
              />
            </div>
          }
        />
      </LoadingWrapper>
    </>
  );
};

export default injectIntl(PickupDetails);
