import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const EG = 'EG';

export const COUNTRIES_MAPPING = {
  US: {
    label: fmt({
      id: 'settings.international_shipping.countries_labels.us'
    }),
    value: 'US'
  },
  AE: {
    label: fmt({
      id: 'settings.international_shipping.countries_labels.ae'
    }),
    value: 'AE'
  },
  UK: {
    label: fmt({
      id: 'settings.international_shipping.countries_labels.uk'
    }),
    value: 'UK'
  },
  CN: {
    label: fmt({
      id: 'settings.international_shipping.countries_labels.cn'
    }),
    value: 'CN'
  }
};
