import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';
import { get as getValue } from 'lodash';
import dayjs from 'dayjs';
import { withRouter } from 'react-router';
import {
  getCurrentUserHubInfo,
  getHubs,
  getCurrentUserHubId
} from '../../../actions';
import styles from '../styles.module.scss';
import { notify } from 'components/Notify/Notify';

const hubTransferReportTableColumns = [
  { dataIndex: 'sentFrom', title: 'Sent From' },
  { dataIndex: 'numberOfPackages', title: 'Number Of Packages' },
  { dataIndex: 'sealNumbers', title: 'Seal Numbers' },
  { dataIndex: 'transferDate', title: 'Transfer Date' },
  { dataIndex: 'action', title: 'Actions' }
];

/**
 * format data read for binding in table
 * @param data
 * @param hubs
 * @param receiveTransfer
 * @returns {*}
 */
const formatDatasource = (data = [], { hubs, receiveTransfer }) => {
  return data.map(
    ({
      _id,
      sealNumbers,
      createdAt,
      deliveriesTransferredCount,
      originWarehouseId,
      deliveriesToBeTransferredCount
    }) => {
      const originHub = hubs
        ? hubs.find((el) => el._id === originWarehouseId)
        : null;
      return {
        _id,
        sealNumbers: sealNumbers.join(', '),
        numberOfPackages: `${deliveriesTransferredCount} From ${deliveriesToBeTransferredCount}`,
        transferDate: dayjs(new Date(createdAt)).tz().format('LLL'),
        originWarehouseId,
        sentFrom: getValue(originHub, 'name', ''),
        action: (
          <DownloadOutlined
            onClick={() => {
              receiveTransfer({ _id });
            }}
          />
        )
      };
    }
  );
};

const { Search } = Input;

function ReceiveFromHub({
  fetchCurrentUserHubInfo,
  fetchHubs,
  fetchGetCurrentHubId,
  currentUserHubId,
  currentUserHubInfo,
  hubs,
  history,
  intl
}) {
  const [hubId, setHubId] = useState({});
  const [sealNumbers, setSealNumbers] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchHubs();
    fetchGetCurrentHubId();
  }, [fetchHubs, fetchGetCurrentHubId]);

  useEffect(() => {
    if (currentUserHubId) {
      const { _id } = currentUserHubId;
      fetchCurrentUserHubInfo({
        hubId: _id,
        pageId: 0,
        pageLimit: 50
      });
      setHubId(_id);
    }
  }, [currentUserHubId]);

  useEffect(() => {
    if (sealNumbers) {
      fetchCurrentUserHubInfo({
        hubId,
        pageId: 0,
        pageLimit: 50,
        sealNumbers
      });
    }
  }, [sealNumbers]);

  useEffect(() => {
    if (sealNumbers && !currentUserHubInfo?.data?.length) {
      notify(
        intl.formatMessage({
          id: 'hubs.receive_from_hub.seal_numbers_search.error_msgs.no_results'
        }),
        'error'
      );
    }
  }, [currentUserHubInfo]);

  const receiveTransfer = ({ _id }) => {
    history.push(`/hubs/hub-operation/receive/receive-from-hub/${_id}`);
  };

  const handleTableChange = (pagingOptions) => {
    fetchCurrentUserHubInfo({
      hubId,
      pageId: pagingOptions.current - 1,
      pageLimit: pagingOptions.pageSize
    });
  };

  const handleSealNumbersSearch = (query) => {
    setSealNumbers(query?.trimStart() || null);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setSealNumbers(null);
    fetchCurrentUserHubInfo({
      hubId,
      pageId: 0,
      pageLimit: 50
    });
  };

  return (
    <div className="br-hub-receive-from-sorting__container">
      <div className={styles.hubButtons}>
        {/* <Button className="primaryButton">Confirm Receiving</Button> */}
      </div>
      <div className={styles.searchContainer}>
        <Search
          className={styles.searchInput}
          placeholder={intl.formatMessage({
            id: 'hubs.receive_from_hub.seal_numbers_search.input_placeholder'
          })}
          value={searchQuery}
          onChange={({ target: { value } }) =>
            setSearchQuery(value?.trimStart())
          }
          onSearch={handleSealNumbersSearch}
          enterButton={intl.formatMessage({
            id: 'hubs.receive_from_hub.seal_numbers_search.submit_btn'
          })}
        />
        <Button onClick={handleClearSearch}>
          {intl.formatMessage({
            id: 'hubs.receive_from_hub.seal_numbers_search.clear_btn'
          })}
        </Button>
      </div>
      <div className="mb-4 mt-4">
        <b>Total Result:</b>
        <span className="ml-2 ">{currentUserHubInfo?.data?.length || 0}</span>
      </div>
      <Table
        className="mt-3"
        style={{ overflow: 'auto' }}
        columns={hubTransferReportTableColumns}
        dataSource={
          currentUserHubInfo
            ? formatDatasource(currentUserHubInfo.data, {
                hubs,
                receiveTransfer
              })
            : []
        }
        // loading={loading}
        pagination={{
          current: (currentUserHubInfo?.pageId || 0) + 1,
          total: currentUserHubInfo?.count || 0,
          pageSize: currentUserHubInfo?.pageLimit || 50,
          showSizeChanger: false,
          pageSizeOptions: ['20', '50', '100', '200']
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs()),
  fetchCurrentUserHubInfo: (data) => dispatch(getCurrentUserHubInfo(data)),
  fetchGetCurrentHubId: () => dispatch(getCurrentUserHubId())
});

const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ReceiveFromHub)));
