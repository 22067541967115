import React, { createRef } from 'react';
import { Checkbox } from 'antd';

import './MultiSelectLookup.less';

class MultiSelectLookup extends React.Component {
  checkboxRef = createRef();
  state = { filterState: [] };

  clear = () => {
    this.setState({ filterState: [] });
  };

  selectAll = () => {
    let { lookup } = this.props;
    this.setState({ filterState: [...lookup.map((val) => val.value)] });
  };

  render() {
    let { onChange, lookup } = this.props;
    let { filterState } = this.state;
    return (
      <div className="br-filter--multi-select--container">
        <Checkbox.Group
          options={lookup}
          onChange={(values) => {
            this.setState({ filterState: [...values] });
            onChange(values);
          }}
          ref={this.checkboxRef}
          value={filterState}
        />
      </div>
    );
  }
}

export default MultiSelectLookup;
