import http from 'utils/http';
import { headerDownload } from 'utils';

export const getPaidOrders = ({
  pageNumber,
  pageSize,
  status = 'SUCCEEDED',
  type = 'SHIPPING_IN',
  searchQuery = '',
  sortQuery = ''
}) => {
  return http.get(
    `/payment/orders?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}&status=${status}${searchQuery}`
  );
};

export const getShippingOrders = ({
  pageNumber,
  pageSize,

  searchQuery = ''
}) => {
  return http.get(
    `/shippingIn/items?pageNumber=${pageNumber}&pageSize=${pageSize}${searchQuery}`
  );
};

export const getOrderInfoDetails = (id) => {
  return http.get(`/payment/orders/${id}`);
};

export const getItemInfoDetails = (id) => {
  return http.get(`/shippingIn/items/${id}`);
};

export const exportItems = (ids) => {
  return http.get(`shippingIn/items/export`, { ids }, headerDownload);
};

export const exportOrders = (ids) => {
  return http.get(`payment/orders/export`, { ids }, headerDownload);
};

export const fetchInternationalItemsShippingBusinessess = () => {
  return http.get(`shippingIn/businesses`);
};

export const fetchInternationalOrdersShippingBusinessess = () => {
  return http.get(`payment/businesses`);
};

export const fetchRecapInfo = () => {
  return http.get(`shippingIn/insights/recaps`);
};

export const fetchInsightInfo = ({ pageNumber, pageSize }) => {
  return http.get(
    `shippingIn/insights?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const fetchBusinessItemsInfo = (businessId) => {
  return http.get(`/shippingIn/businesses/${businessId}/items`);
};

export const fetchInternationalFees = (payload) => {
  return http.get('/shippingIn/countries', payload);
};

export const addCategory = (payload) => {
  return http.patch('/shippingIn/countries', payload);
};

export const updateCategory = (payload, id) => {
  return http.patch(`/shippingIn/countries/${id}`, {
    action: 'update',
    ...payload
  });
};

export const deleteCategory = (payload, id) => {
  return http.patch(`/shippingIn/countries/${id}`, {
    action: 'delete',
    ...payload
  });
};
