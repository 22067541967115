import './BREllipsisCell.less';

const BREllipsisCell = ({ children }) => {
  return (
    <div className="br-ellipsis-cell">
      <div className="br-ellipsis-cell__container">{children}</div>
    </div>
  );
};

export default BREllipsisCell;
