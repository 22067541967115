import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { getHubZones, updateHubZone } from 'services/zoning';
import { POLYGON } from 'constants/dynamic-routing';
import { formatPolygonCoordinates } from 'utils/bosta-coding';

import ZonesGeofencingSelectors from './components/ZonesGeofencingSelectors/ZonesGeofencingSelectors';
import ZonesGeofencingMap from './components/ZonesGeofencingMap/ZonesGeofencingMap';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './ZonesGeofencing.less';

const ZonesGeofencing = () => {
  const [selectedHubId, setSelectedHubId] = useState(null);
  const [hubZones, setHubZones] = useState([]);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedZoneGeometryPath, setSelectedZoneGeometryPath] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtherZones, setShowOtherZones] = useState(false);
  const [otherZonesGeometry, setOtherZonesGeometry] = useState([]);
  const [zoneGeofencingErrors, setZoneGeofencingErrors] = useState({
    polygonsIntersecting: false
  });

  const zonePolygonRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    if (selectedZoneId) {
      setSelectedZoneGeometryPath([]);
      const selectedZone = hubZones.find((zone) => zone._id === selectedZoneId);

      if (selectedZone.geometry) {
        setSelectedZoneGeometryPath(
          selectedZone.geometry.coordinates[0].map((coordinates) => ({
            lat: coordinates[1],
            lng: coordinates[0]
          }))
        );
      }
    }
  }, [selectedZoneId]);

  useEffect(() => {
    if (selectedHubId) {
      setShowOtherZones(false);
      setOtherZonesGeometry([]);
      setSelectedZoneGeometryPath([]);
      setSelectedZoneId(null);
    }
  }, [selectedHubId]);

  useEffect(() => {
    if (showOtherZones) {
      const otherZones = hubZones.filter((zone) => zone._id !== selectedZoneId);

      const zonesGeometry = otherZones.map((zone) => {
        if (zone.geometry) {
          const coordinates = zone.geometry.coordinates[0].map(
            (coordinates) => ({
              lat: coordinates[1],
              lng: coordinates[0]
            })
          );

          return {
            ...zone,
            coordinates
          };
        }

        return;
      });

      setOtherZonesGeometry(zonesGeometry.filter(Boolean));
    } else {
      setOtherZonesGeometry([]);
    }
  }, [showOtherZones, selectedZoneId]);

  const fetchHubZones = async () => {
    setIsLoading(true);
    try {
      const hubZones = await getHubZones(selectedHubId);
      setHubZones(hubZones);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const updateZoneData = () => {
    const selectedZoneIndex = hubZones.findIndex(
      (zone) => zone._id === selectedZoneId
    );
    const newHubZones = [...hubZones];
    const geometry = {
      coordinates: [formatPolygonCoordinates(zonePolygonRef)]
    };
    newHubZones[selectedZoneIndex].geometry = geometry;

    setHubZones(newHubZones);
  };

  const handleOnSave = async () => {
    setIsLoading(true);

    const payload = {
      geometry: {
        coordinates: [formatPolygonCoordinates(zonePolygonRef)],
        type: POLYGON
      }
    };

    try {
      await updateHubZone(selectedZoneId, payload);
      updateZoneData();
      notify(
        intl.formatMessage({
          id: 'bosta_coding.zones_geofencing.updated_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-zone-geofencing__container">
        <ZonesGeofencingSelectors
          selectedHubId={selectedHubId}
          setSelectedHubId={setSelectedHubId}
          hubZones={hubZones}
          fetchHubZones={fetchHubZones}
          selectedZoneId={selectedZoneId}
          setSelectedZoneId={setSelectedZoneId}
          selectedZoneGeometryPath={selectedZoneGeometryPath}
          setSelectedZoneGeometryPath={setSelectedZoneGeometryPath}
          setShowOtherZones={setShowOtherZones}
          showOtherZones={showOtherZones}
          handleOnSave={handleOnSave}
          zoneGeofencingErrors={zoneGeofencingErrors}
        />
        <ZonesGeofencingMap
          selectedHubId={selectedHubId}
          selectedZoneId={selectedZoneId}
          selectedZoneGeometryPath={selectedZoneGeometryPath}
          setSelectedZoneGeometryPath={setSelectedZoneGeometryPath}
          zonePolygonRef={zonePolygonRef}
          otherZonesGeometry={otherZonesGeometry}
          setSelectedZoneId={setSelectedZoneId}
          hubZones={hubZones}
          setZoneGeofencingErrors={setZoneGeofencingErrors}
        />
      </div>
    </LoadingWrapper>
  );
};

export default ZonesGeofencing;
