export const checkIfLogIsException = (log) => {
  if (log.includes('exception') || log.includes('Exception')) {
    return true;
  }
  return false;
};

const CANCELLATION_ARRAY = [
  'Cancelation',
  'Cancel',
  'Cancellation',
  'cancel',
  'cancelation',
  'cancellation'
];

const checkIfLogContainsCancel = (log) => {
  let isFound = false;
  CANCELLATION_ARRAY.map((cancel) => {
    if (log.includes(cancel)) isFound = true;
  });
  return isFound;
};

export const checkIfLogIsCancel = (log) => {
  if (
    (checkIfLogIsException(log) && checkIfLogContainsCancel(log)) ||
    checkIfLogContainsCancel(log)
  ) {
    return true;
  }
  return false;
};
