import http from 'utils/http';

export const searchInternationalOrders = (payload) => {
  return http.post('/international-order/search', payload);
};

export const getInternationalOrder = (orderId) => {
  return http.get(`/international-order/${orderId}`);
};

export const updateInternationalOrder = ({ orderId, payload }) => {
  return http.put(`/international-order/${orderId}`, payload);
};

export const printInternationalOrderAwb = (payload) => {
  return http.get(`/international-order/awb`, payload);
};

export const exportInternationalOrder = (payload) => {
  return http.post('/international-order/export', payload);
};

export const unassignPickupFromCourier = (payload) => {
  return http.post('/routes/stars/unassign-pickups', payload);
};

export const scanInternationalOrder = (payload) => {
  return http.post('/international-order/scan', payload);
};

export const updateInternationalOrders = (payload) => {
  return http.post('/international-order/updateMany', payload);
};

export const printInvoiceBill = (payload) => {
  return http.get('/international-order/bills', payload);
};
