import { ReactComponent as ProductIcon } from 'assets/bosta-icons/Product.svg';

import './EmptyState.less';

const EmptyState = ({ title, subtitle, renderActions = () => {} }) => {
  return (
    <div className="br-po-products__empty-state">
      <ProductIcon className="br-po-products__empty-state__icon" />
      <div className="br-po-products__empty-state__title">{title}</div>
      {subtitle && (
        <span className="br-po-products__empty-state__subtitle">
          {subtitle}
        </span>
      )}
      <div className="br-po-products__empty-state__actions">
        {renderActions()}
      </div>
    </div>
  );
};

export default EmptyState;
