import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import Permissions from './components/Permissions/Permissions';

import './RolesPermissions.less';

const ROUTES = [
  {
    path: '/roles-permissions/permissions',
    component: Permissions,
    access: aclMatrix.ROLES_PERMISSIONS,
    permission: [ACL_MATRIX.ADMIN_GROUPS_LIST, ACL_MATRIX.ADMIN_GROUPS_UPDATE]
  }
];

const RolesPermissions = ({ intl, match: { path } }) => {
  const getDefaultPath = () => {
    let defaultPathName = '';

    ROUTES.some((route) => {
      if (isUserAuthorized(route.access, route.permission)) {
        defaultPathName = route.path;
        return true;
      }
    });

    return defaultPathName;
  };

  return (
    <Container
      className="br-roles-permissions"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'roles_permissions.title'
          })}
        />
      }
      content={
        <Switch>
          {ROUTES.map(
            ({ path, component, access, permission }, index) =>
              isUserAuthorized(access, permission) && (
                <Route key={index} path={path} component={component} />
              )
          )}
          <Redirect from={path} to={getDefaultPath()} />
        </Switch>
      }
    />
  );
};

export default injectIntl(withRouter(RolesPermissions));
