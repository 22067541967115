import React, { useEffect, useState } from 'react';

import { fetchRecapInfo } from 'services/international-shipping-in';

import { notify } from 'components/Notify/Notify';

const InsightsCards = () => {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getRecapInfo();
  }, []);

  const getRecapInfo = async () => {
    try {
      const { data } = await fetchRecapInfo();
      setInfo(data);
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    info && (
      <div className="br-international-insights-card__container">
        <div className="br-international-insights-card-content">
          <span className="br-insights-card__title-number">
            {info.businesses || 'N/A'}
          </span>
          <span className="br-insights-card__title-text">Total Businesses</span>
        </div>
        <div className="br-international-insights-card-content">
          <span className="br-insights-card__title-number">
            {Math.round(info?.orders[0]?.average_orders_amount * 100) / 100 ||
              'N/A'}
          </span>
          <span className="br-insights-card__title-text">
            AVG. order per business
          </span>
        </div>
        <div className="br-international-insights-card-content">
          <span className="br-insights-card__title-number">
            {Math.round(info?.items[0]?.average_items_amount * 100) / 100 ||
              'N/A'}
          </span>
          <span className="br-insights-card__title-text">
            AVG. items per business
          </span>
        </div>
        <div className="br-international-insights-card-content">
          <span className="br-insights-card__title-number">
            {Math.round(
              info?.itemsPerOrder[0]?.average_items_per_order_amount * 100
            ) / 100 || 'N/A'}
          </span>
          <span className="br-insights-card__title-text">
            AVG. items per order
          </span>
        </div>
      </div>
    )
  );
};

export default InsightsCards;
