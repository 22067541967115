import { Rate } from 'antd';
import { useIntl } from 'react-intl';
import get from 'lodash/get';

import './ServiceRating.less';

export const STARS_RATING = 'STARS_RATING';

const ServiceRating = ({ rating, ratingsList = [] }) => {
  const intl = useIntl();

  return (
    <div className="br-service-rating__container">
      {ratingsList.map(({ label, dataIndex, type }) => (
        <div className="br-service-rating__item">
          <div className="br-service-rating__label">{label}</div>
          {type === STARS_RATING ? (
            <Rate disabled defaultValue={get(rating, dataIndex, 0)} />
          ) : (
            get(
              rating,
              dataIndex,
              intl.formatMessage({ id: 'common.empty_field' })
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceRating;
