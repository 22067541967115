import { injectIntl } from 'react-intl';
import { Breadcrumb } from 'antd';

import './LocationBreadcrumb.less';

const LocationBreadcrumb = ({ intl, locationLevelsParams = {} }) => {
  const { zone, aisle, rack, shelf, tote } = locationLevelsParams;
  const list = [zone, aisle, rack, shelf, tote];

  return (
    <Breadcrumb separator=">" className="br-fulfillment-locations-breadcrumb">
      <Breadcrumb.Item className="br-fulfillment-locations-breadcrumb__first-title">
        {intl.formatMessage({
          id: 'fulfillment_locations.title'
        })}
      </Breadcrumb.Item>
      {list.map((item, index) => (
        <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default injectIntl(LocationBreadcrumb);
