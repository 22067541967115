import { useState } from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import './BRConfirmationModal.less';

const BRConfirmationModal = ({
  intl,
  close,
  icon,
  message,
  title,
  onConfirm = () => {},
  onCancel,
  confirmButtonText,
  cancelButtonText,
  hideCancel = false,
  confirmButtonClassName = '',
  confirmButtonType = 'ant-btn-danger',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmRemoveItem = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    close();
  };

  const handleCancel = () => {
    onCancel?.();
    close();
  };

  return (
    <Modal
      onCancel={handleCancel}
      className={classnames('br-delete-confirmation-modal', {
        'br-confirmation-modal__without-body': !message
      })}
      {...props}
      footer={
        <>
          {!hideCancel && (
            <BRButton
              onClick={handleCancel}
              type="basic"
              label={
                cancelButtonText ||
                intl.formatMessage({
                  id: 'common.cancel'
                })
              }
              disabled={isLoading}
            />
          )}
          <BRButton
            type={confirmButtonType}
            onClick={handleConfirmRemoveItem}
            label={
              confirmButtonText ||
              intl.formatMessage({
                id: 'common.yes'
              })
            }
            loading={isLoading}
          />
        </>
      }
      title={title}
    >
      {icon && icon}
      {message && (
        <div className="br-delete-confirmation-modal__message">{message}</div>
      )}
      <div className="br-delete-confirmation-modal__footer"></div>
    </Modal>
  );
};

export default injectIntl(BRConfirmationModal);
