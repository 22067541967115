import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';

import {
  INTERNATIONAL_DELIVERY_UPDATE_TYPES,
  INTERNATIONAL_TRANSFER_DEFAULT_HUBS
} from 'constants/international-orders';
import { getCurrentUserHubInfo } from 'utils/hubs';

import BRButton from 'components/BRButton/BRButton';

const InternationalOrdersActionSetup = ({
  isScanning,
  setIsScanning,
  updateType
}) => {
  const intl = useIntl();

  const { name: hubName } = getCurrentUserHubInfo() || {};

  const handleStartScanning = () => {
    setIsScanning(true);
  };

  return (
    <div className="br-international-shipping-search__container">
      <div className="br-international-shipping-search__title display-xs">
        {intl.formatMessage({
          id: 'international_orders.transfer.setup.title'
        })}
      </div>
      <div className="br-international-shipping-search__content">
        <div className="br-international-shipping-search__row">
          <Form.Item
            label={intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.source_hub'
            })}
          >
            <Select
              value={hubName || INTERNATIONAL_TRANSFER_DEFAULT_HUBS.SOURCE_HUB}
              disabled
            />
          </Form.Item>
          {updateType === INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER && (
            <Form.Item
              label={intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.destination_hub'
              })}
            >
              <Select
                value={INTERNATIONAL_TRANSFER_DEFAULT_HUBS.DESTINATION_HUB}
                disabled
              />
            </Form.Item>
          )}
        </div>
        <div className="br-international-shipping-search__actions">
          <BRButton
            type="primary"
            disabled={isScanning}
            onClick={handleStartScanning}
            label={intl.formatMessage({
              id: `international_orders.transfer.setup.${
                isScanning ? 'scanning_started' : 'start_scanning'
              }`
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default InternationalOrdersActionSetup;
