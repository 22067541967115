import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import {
  receiveBulkySeals,
  receivePackagesAtHub,
  receiveSealsTNs,
  removeScannedDeliveries,
  scanDeliveries
} from 'services/hubs';
import { openModal } from 'utils/modal';
import {
  DEFAULT,
  DELIVERIES_SCAN_TYPES,
  NORMAL,
  RECEIVE_MANY,
  SEAL_SCAN_TYPE
} from 'constants/hubs';

import BRButton from 'components/BRButton/BRButton';
import BRSwappingTables from 'components/BRSwappingTables/BRSwappingTables';
import { notify } from 'components/Notify/Notify';
import ReceiveSealsCard from '../ReceiveSealsCard/ReceiveSealsCard';
import ScannerInput from '../ScannerInput/ScannerInput';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import ScanningTypeSelector from 'containers/Hub/ReceiveSeals/components/ScanningTypeSelector/ScanningTypeSelector';
import BRTable from 'components/BRTable/BRTable';

import { ReactComponent as DeleteIcon } from 'assets/bosta-icons/Delete.svg';
import { ReactComponent as ArrowIcon } from 'assets/imgRevamp/wallet-transit-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './TrackingNumberScan.less';

const TrackingNumberScan = ({
  intl,
  geToPreviousView,
  setCurrentViewProps,
  isLoading,
  masterSeal,
  seal,
  setIsLoading,
  scanningType,
  handleOnScanningTypeChange,
  currentViewProps
}) => {
  const [sealTrackingNumbers, setSealTrackingNumbers] = useState([]);
  const [scannedTrackingNumbers, setScannedTrackingNumbers] = useState([]);
  const [wrongScannedDeliveries, setWrongScannedDeliveries] = useState([]);
  const [scannedBulkyDeliveries, setScannedBulkyDeliveries] = useState([]);

  const leftColumns = [
    {
      dataIndex: 'trackingNumber',
      title: intl.formatMessage({
        id: 'hubs.receive_from_hub.receive_seals.tracking_number.tables.columns.tracking_number'
      })
    }
  ];

  const rightColumns = [
    ...leftColumns,
    {
      dataIndex: 'remove',
      render: (_, record) => (
        <Icon
          component={DeleteIcon}
          className="br-tracking-number-scan__delete-icon"
          onClick={() =>
            handleRemoveTrackingNumberClick(record?.trackingNumber)
          }
        />
      )
    }
  ];

  const handleCloseClick = () => {
    geToPreviousView();
    setCurrentViewProps({
      masterSeal
    });
  };

  const getScannedDeliveries = () => {
    return {
      list: formatTableData(scannedTrackingNumbers)
    };
  };

  const scanDelivery = async ({ trackingNumber }) => {
    try {
      const payload = {
        trackingNumbers: [trackingNumber],
        searchType: DELIVERIES_SCAN_TYPES.SORTING_RECEIVE_ORDER_SCAN
      };

      const { data } = await scanDeliveries(payload);
      return data;
    } catch (error) {
      notify(error.message);
    }
  };

  const checkIfAlreadyScanned = (trackingNumber) => {
    if (scanningType === SEAL_SCAN_TYPE.BULKY) {
      return scannedBulkyDeliveries.find(
        (delivery) => delivery.trackingNumber === trackingNumber
      );
    } else {
      return scannedTrackingNumbers.find((tn) => tn === trackingNumber);
    }
  };

  const handleChange = async (trackingNumber) => {
    if (sealTrackingNumbers.includes(trackingNumber)) {
      setSealTrackingNumbers((prev) =>
        prev.filter((tn) => tn !== trackingNumber)
      );
      setScannedTrackingNumbers((prev) => [...prev, trackingNumber]);
    } else if (checkIfAlreadyScanned(trackingNumber)) {
      return intl.formatMessage({
        id: 'hubs.receive_from_hub.receive_seals.error_msgs.scanned_before'
      });
    } else {
      const scannedDelivery = await scanDelivery({ trackingNumber });
      if (scannedDelivery) {
        if (scanningType === SEAL_SCAN_TYPE.BULKY) {
          if (
            currentViewProps.masterSeal?.sealNumbers?.includes(
              scannedDelivery[0].sealNumber
            )
          ) {
            setScannedBulkyDeliveries((prevState) => [
              ...prevState,
              scannedDelivery[0]
            ]);
          } else {
            return intl.formatMessage({
              id: 'hubs.receive_from_hub.receive_seals.error_msgs.bulky_seal_doesnt_exist'
            });
          }
        } else {
          setWrongScannedDeliveries((prev) => [...prev, scannedDelivery[0]]);
          setScannedTrackingNumbers((prev) => [
            ...prev,
            scannedDelivery[0]?.trackingNumber
          ]);
        }
      } else {
        return intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.error_msgs.not_found'
        });
      }
    }
  };
  const addWrongScanLog = async (id) => {
    const payload = {
      searchType: DELIVERIES_SCAN_TYPES.SORTING_RECEIVE_ORDER_SCAN,
      trackingNumbers: [id]
    };
    try {
      await removeScannedDeliveries(payload);
    } catch (error) {
      notify(error.message);
    }
  };
  const handleRemoveTrackingNumberClick = (trackingNumber) => {
    addWrongScanLog(trackingNumber);
    if (scanningType === SEAL_SCAN_TYPE.BULKY) {
      return setScannedBulkyDeliveries((prevState) =>
        prevState.filter(
          (delivery) => delivery.trackingNumber !== trackingNumber
        )
      );
    }

    const isWrongScannedDelivery = wrongScannedDeliveries?.find(
      (delivery) => delivery?.trackingNumber === trackingNumber
    );

    isWrongScannedDelivery
      ? setWrongScannedDeliveries((prev) =>
          prev.filter((delivery) => delivery?.trackingNumber !== trackingNumber)
        )
      : setSealTrackingNumbers((prev) => [...prev, trackingNumber]);

    setScannedTrackingNumbers((prev) =>
      prev.filter((tn) => tn !== trackingNumber)
    );
  };

  const handleConfirmReceivingClick = () => {
    if (sealTrackingNumbers?.length) {
      openModal(ConfirmationModal, {
        content: intl.formatMessage(
          {
            id: 'hubs.receive_from_hub.receive_seals.error_msgs.not_scanned'
          },
          {
            trackingNumbers: sealTrackingNumbers.join(', ')
          }
        ),
        confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
        modalAction: handleReceiveTrackingNumbers
      });
    } else {
      scanningType === SEAL_SCAN_TYPE.BULKY
        ? handleReceiveBulkyTrackingNumbers()
        : handleReceiveTrackingNumbers();
    }
  };

  const receiveWrongScannedDeliveries = async () => {
    try {
      const payload = {
        deliveries: wrongScannedDeliveries.map((delivery) => {
          return {
            _id: delivery?._id,
            pricingPackageSize: delivery?.pricingPackageSize?.name || NORMAL,
            scanType: DEFAULT
          };
        }),
        updateType: RECEIVE_MANY
      };
      const res = await receivePackagesAtHub(payload);

      if (res) {
        if (res.failedDeliveries?.length > 0) {
          notify(
            intl.formatMessage(
              {
                id: 'hubs.receive_from_hub.receive_seals.error_msgs.failed_to_receive'
              },
              {
                trackingNumbers: res.failedDeliveries.join(', ')
              }
            )
          );
        }
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const getSealsTrackingNumbersPayload = () => {
    if (!wrongScannedDeliveries?.length) {
      return scannedTrackingNumbers;
    }

    const wrongScannedTrackingNumbers = wrongScannedDeliveries?.map(
      (delivery) => delivery?.trackingNumber
    );

    return scannedTrackingNumbers.filter(
      (trackingNumber) => !wrongScannedTrackingNumbers.includes(trackingNumber)
    );
  };

  const constructBulkyTrackingNumbersPayload = () => {
    const groupedBulkyDeliveries = scannedBulkyDeliveries.reduce(
      (acc, { sealNumber, trackingNumber }) => {
        if (!acc[sealNumber]) {
          acc[sealNumber] = { sealNumber, trackingNumbers: [] };
        }
        acc[sealNumber].trackingNumbers.push(trackingNumber);
        return acc;
      },
      {}
    );

    return Object.values(groupedBulkyDeliveries);
  };

  const handleReceiveBulkyTrackingNumbers = async () => {
    setIsLoading(true);

    try {
      const payload = {
        seals: constructBulkyTrackingNumbersPayload()
      };
      const { message } = await receiveBulkySeals(payload);
      notify(message, 'success');
      geToPreviousView();
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const handleReceiveTrackingNumbers = async () => {
    setIsLoading(true);

    const payload = {
      trackingNumbers: getSealsTrackingNumbersPayload()
    };

    try {
      const { message } = await receiveSealsTNs(seal?.sealNumber, payload);
      wrongScannedDeliveries?.length && (await receiveWrongScannedDeliveries());
      notify(message, 'success');
      geToPreviousView();
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const formatTableData = (data) => {
    return data.map((trackingNumber) => ({ trackingNumber }));
  };

  const formatBulkyTableData = (data) => {
    return data.map(({ trackingNumber }) => ({ trackingNumber }));
  };

  useEffect(() => {
    if (seal) {
      const { trackingNumbers = [], transferredDeliveries = [] } = seal;
      const untransferred = [];
      if (transferredDeliveries.length) {
        trackingNumbers.forEach(({ trackingNumber }) => {
          if (!transferredDeliveries.includes(trackingNumber)) {
            untransferred.push(trackingNumber);
          }
        });

        setSealTrackingNumbers(untransferred);
      } else {
        setSealTrackingNumbers(
          trackingNumbers.map(({ trackingNumber }) => trackingNumber)
        );
      }
    }
  }, [seal]);

  return (
    <>
      <ReceiveSealsCard
        header={
          <>
            <div className="br-tracking-number-scan__header">
              <span>
                {intl.formatMessage({
                  id: 'hubs.receive_from_hub.receive_seals.master_seal_scan.label'
                })}
                <span className="br-seal-scan__master-seal-code">
                  {seal?.masterSealNumber || masterSeal?.sealNumber}
                </span>
              </span>
              {seal?.sealNumber && (
                <>
                  <Icon
                    component={ArrowIcon}
                    className="br-tracking-number-scan__arrow-icon"
                  />
                  <span className="br-tracking-number-scan__seal-number br-receive-seals__tag">
                    {intl.formatMessage({
                      id: 'hubs.receive_from_hub.receive_seals.tracking_number.seal'
                    })}
                    <span>{seal.sealNumber}</span>
                    <Icon
                      component={CloseIcon}
                      className="br-tracking-number-scan__close-icon"
                      onClick={handleCloseClick}
                    />
                  </span>
                </>
              )}
            </div>
            {!seal?.sealNumber && (
              <div>
                <ScanningTypeSelector
                  scanningType={scanningType}
                  handleOnScanningTypeChange={handleOnScanningTypeChange}
                />
              </div>
            )}
          </>
        }
      >
        <div className="br-tracking-number-scan">
          <ScannerInput
            label={intl.formatMessage({
              id: 'hubs.receive_from_hub.receive_seals.tracking_number.label'
            })}
            placeholder={intl.formatMessage({
              id: 'hubs.receive_from_hub.receive_seals.tracking_number.placeholder'
            })}
            onChange={handleChange}
            disabled={isLoading}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'hubs.receive_from_hub.receive_seals.confirm_receiving'
            })}
            onClick={handleConfirmReceivingClick}
            disabled={
              !scannedTrackingNumbers.length && !scannedBulkyDeliveries.length
            }
          />
        </div>
      </ReceiveSealsCard>

      {scanningType === SEAL_SCAN_TYPE.BULKY ? (
        <BRTable
          className="br-hub-receiving-bulky__table"
          columns={rightColumns}
          listingData={formatBulkyTableData(scannedBulkyDeliveries)}
          showFilter={false}
          preTableHeaderContent={
            <div className="br-hub-transfer-table__title heading">
              {intl.formatMessage(
                {
                  id: 'hubs.receive_from_hub.receive_seals.tracking_number.tables.headers.scanned_tracking_numbers_count'
                },
                {
                  count: scannedBulkyDeliveries.length
                }
              )}
            </div>
          }
        />
      ) : (
        <BRSwappingTables
          leftTableProps={{
            columns: leftColumns,
            preTableHeaderContent: (
              <div className="br-hub-transfer-table__title heading">
                {intl.formatMessage(
                  {
                    id: 'hubs.receive_from_hub.receive_seals.tracking_number.tables.headers.tracking_numbers_count'
                  },
                  {
                    count: sealTrackingNumbers.length
                  }
                )}
              </div>
            )
          }}
          rightTableProps={{
            columns: rightColumns,
            preTableHeaderContent: (
              <div className="br-hub-transfer-table__title heading">
                {intl.formatMessage(
                  {
                    id: 'hubs.receive_from_hub.receive_seals.tracking_number.tables.headers.scanned_tracking_numbers_count'
                  },
                  {
                    count: scannedTrackingNumbers.length
                  }
                )}
              </div>
            )
          }}
          leftTableDataSource={formatTableData(sealTrackingNumbers)}
          rightTableDataSource={formatTableData(scannedTrackingNumbers)}
        />
      )}
    </>
  );
};

export default injectIntl(TrackingNumberScan);
