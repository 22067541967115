import React, { useState, useRef, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Form } from 'antd';
import classnames from 'classnames';

import {
  secondsToTime,
  numberRegex,
  countDown,
  TWO_MINUTES
} from 'utils/helpers';
import { LOCALE } from 'constants/intl-wrapper';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import './BROTPCode.less';

const BROTPCode = ({
  title,
  subtitle,
  closeModal,
  intl,
  confirmOTP,
  generateOTP,
  onSuccess,
  resendCodeSegmentEvent,
  verificationGenerateOTPCode,
  handleResendCode
}) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [pasted, setPasted] = useState(false);
  const [timer, setTimer] = useState(TWO_MINUTES);
  const [names] = useState([
    'firstDigit',
    'secondDigit',
    'thirdDigit',
    'fourthDigit'
  ]);
  const formRef = useRef('');

  const codeStepFirst = useRef(null);
  const codeStepTwo = useRef(null);
  const codeStepThree = useRef(null);
  const codeStepFour = useRef(null);

  const sendOtp = useCallback(async () => {
    try {
      setPageLoading(true);
      if (generateOTP) {
        await generateOTP();
      } else if (verificationGenerateOTPCode) {
        verificationGenerateOTPCode();
      }
    } catch (error) {
      notify(error.message);
    }
    // codeStepFirst.current.focus();
    setPageLoading(false);
  }, [generateOTP, verificationGenerateOTPCode]);

  useEffect(() => {
    if (generateOTP) {
      sendOtp();
    } else {
      codeStepFirst.current.focus();
    }
  }, [generateOTP, sendOtp]);

  useEffect(() => {
    const myInterval = countDown({ timer, setTimer, setDisabled });
    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  const handleOnFinish = async (values) => {
    if (values) {
      try {
        setPageLoading(true);
        const response = await confirmOTP(values);
        if (response.success) {
          setIsErrorVisible(false);
          onSuccess();
          if (closeModal) {
            closeModal();
          }
        }
      } catch (error) {
        setIsErrorVisible(true);
        formRef.current.resetFields();
        codeStepFirst.current.focus();
      }
    }
    setPageLoading(false);
  };

  const handleOnChangeDigits = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
    if (name === 'firstDigit' && value !== '') {
      codeStepTwo.current.focus();
    } else if (name === 'secondDigit' && value !== '') {
      codeStepThree.current.focus();
    } else if (name === 'thirdDigit' && value !== '') {
      codeStepFour.current.focus();
    }
    setPasted(false);
    if (
      formRef.current.getFieldValue('firstDigit') &&
      formRef.current.getFieldValue('secondDigit') &&
      formRef.current.getFieldValue('thirdDigit') &&
      formRef.current.getFieldValue('fourthDigit')
    ) {
      const values =
        formRef.current.getFieldValue('firstDigit') +
        formRef.current.getFieldValue('secondDigit') +
        formRef.current.getFieldValue('thirdDigit') +
        formRef.current.getFieldValue('fourthDigit');
      handleOnFinish(values);
    }
  };

  const handleOnPaste = (e) => {
    setPasted(true);
    e.clipboardData.items[0].getAsString((str) => {
      if (numberRegex.test(str)) {
        names.forEach((item, index) => {
          formRef.current.setFieldsValue({ [item]: str[index] });
        });
        handleOnFinish(str);
        codeStepFour.current.focus();
      } else {
        setIsErrorVisible(true);
        codeStepFirst.current.focus();
      }
    });
    setPasted(false);
  };

  const formArray = [
    {
      render: (
        <Form.Item name="firstDigit">
          <BRFormInputNumber
            maxLength={pasted ? 4 : 1}
            filedName="firstDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="0"
            handleOnPaste={handleOnPaste}
            refName={codeStepFirst}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item name="secondDigit">
          <BRFormInputNumber
            refName={codeStepTwo}
            maxLength={pasted ? 4 : 1}
            filedName="secondDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="0"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item name="thirdDigit">
          <BRFormInputNumber
            refName={codeStepThree}
            maxLength={pasted ? 4 : 1}
            filedName="thirdDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="0"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item name="fourthDigit">
          <BRFormInputNumber
            refName={codeStepFour}
            maxLength={pasted ? 4 : 1}
            filedName="fourthDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="0"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    }
  ];

  const handleSendAgain = () => {
    setDisabled(true);
    setIsErrorVisible(false);
    setTimer(TWO_MINUTES);
    handleResendCode ? handleResendCode() : sendOtp();
  };

  return (
    <LoadingWrapper loading={pageLoading}>
      <BRContentHeader
        title={title}
        subtitle={subtitle}
        titleClassName="br-otp-validation__title"
        subTitleClassName="br-otp-validation__subtitle"
      />
      <div className="br-otp-validation__content">
        <Form className="br-otp-modal__otp-code" ref={formRef}>
          <div
            className={classnames('br-otp-modal__form-items', {
              'error-visible': isErrorVisible
            })}
          >
            {intl.locale === LOCALE.AR
              ? formArray.reverse().map((item) => item.render)
              : formArray.map((item) => item.render)}
          </div>

          <div className="br-opt-modal__form-text">
            {isErrorVisible && (
              <p className="br-opt-modal__error-text">
                {intl.formatMessage({
                  id: 'otp_screen.error_message',
                })}
              </p>
            )}

            {disabled && (
              <span className="br-otp-validation__receive-confirmation">
                {intl.formatMessage(
                  {
                    id: 'otp_screen.receive_confirmation',
                  },
                  {
                    time: (
                      <span className="br-otp-validation__receive-confirmation__timer">
                        {secondsToTime(timer)}
                      </span>
                    )
                  }
                )}
              </span>
            )}
            <Button
              type="link"
              className={classnames({
                'br-otp-code__resend-code-link': !disabled,
                'br-otp-code__resend-code-link-disabled': disabled
              })}
              disabled={disabled}
              onClick={handleSendAgain}
            >
              {intl.formatMessage({
                id: 'otp_screen.resend_code',
              })}
            </Button>
          </div>
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(BROTPCode);
