import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { getSingleAmazonRemittance } from 'services/cod-reports';

import Container from 'components/Container';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BRHeader from 'components/BRHeader/BRHeader';

const amazonTableColumns = [
  {
    title: 'Tracking Number',
    dataIndex: 'trackingNumber'
  },
  {
    title: 'Type',
    dataIndex: 'type'
  },
  {
    title: 'COD amount',
    dataIndex: 'codAmt'
  }
];

const detailsTableCells = [
  {
    key: 'transferId',
    label: 'Transfer ID'
  },
  {
    key: 'totalAmt',
    label: 'Total amount'
  },
  ,
  {
    key: 'deliveries',
    label: 'No. of deliveries',
    render: (deliveries) => {
      return deliveries?.length || 0;
    }
  },
  {
    key: 'createdAt',
    label: 'Created At',
    render: (createdAt) => {
      return createdAt
        ? dayjs(new Date(createdAt)).tz().format('DD MMM YYYY - hh:mmA')
        : 'N/A';
    }
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
    render: (updatedAt) => {
      return updatedAt
        ? dayjs(new Date(updatedAt)).tz().format('DD MMM YYYY')
        : 'N/A';
    }
  },
  {
    label: 'Deposit date',
    key: 'depositDate',
    render: (depositDate) => {
      return depositDate && dayjs(depositDate).tz().format('DD MMM YYYY');
    }
  },
  {
    key: 'fileName',
    label: 'File name'
  }
];

const AmazonRemittanceDetails = ({
  match: {
    params: { id }
  }
}) => {
  const [amazonData, setAmazonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAmazonRemittance = async () => {
    try {
      setIsLoading(true);
      const { data } = await getSingleAmazonRemittance(id);
      setAmazonData(data);
      setIsLoading(false);
    } catch (error) {
      notify(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAmazonRemittance();
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={<BRHeader title="Amazon Remittance Details" />}
        content={
          amazonData && (
            <>
              <BRInformationTable
                dataSource={amazonData}
                cells={detailsTableCells}
                totalCol={3}
              />
              <BRSearchableTable
                title={`Total Deliveries (${amazonData.deliveries.length})`}
                listData={amazonData.deliveries}
                count={amazonData.deliveries.length}
                columns={amazonTableColumns}
                withOutCheckBoxes
                hidePagination
              />
            </>
          )
        }
      />
    </LoadingWrapper>
  );
};

export default AmazonRemittanceDetails;
