import React from 'react';
import { message } from 'antd';
import Icon from '@ant-design/icons';

import Sounds from 'common/Sounds';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './Notify.less';

const MESSAGES_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
};

let toastConfigObject = {
  duration: 5,
  maxCount: 1
};

message.config(toastConfigObject);

const configureContentShape = (msg, title) => {
  const onCloseMessage = () => {
    message.success({
      content: 'Hide',
      className: 'br-notify-message__hidden'
    });
  };
  return (
    <div className="br-notify__message">
      <div className="br-notify__message__content">
        <span className="br-notify__message__title">{msg}</span>
      </div>
      <Icon
        className="br-notify__message__close-icon"
        component={Close}
        onClick={onCloseMessage}
      />
    </div>
  );
};

const controlNotificationType = (msg, { messageType }) => {
  switch (messageType) {
    case MESSAGES_TYPES.SUCCESS:
      return message.success({
        content: configureContentShape(msg),
        className: MESSAGES_TYPES.SUCCESS
      });
    case MESSAGES_TYPES.WARNING:
      return message.warning({
        content: configureContentShape(msg),
        className: MESSAGES_TYPES.WARNING
      });
    case MESSAGES_TYPES.INFO:
      return message.info({
        content: configureContentShape(msg),
        className: MESSAGES_TYPES.INFO
      });
    case MESSAGES_TYPES.ERROR:
    default:
      return message.error({
        content: configureContentShape(msg),
        className: MESSAGES_TYPES.ERROR
      });
  }
};

export const notify = (
  msg,
  type = 'error',
  withSound = false,
  id = 1,
  className = ''
) => {
  if (withSound) {
    switch (type) {
      case 'error':
        Sounds.warning();
        break;
      case 'warning':
      case 'success':
      default:
        Sounds.beep();
        break;
    }
  }
  controlNotificationType(msg, { messageType: type, toastId: id });
};
