import { Modal } from 'antd';
import Icon from '@ant-design/icons';

import BRButton from 'components/BRButton/BRButton';
import ImagesCarousel from 'components/ImagesCarousel/ImagesCarousel';

import { ReactComponent as warning } from 'assets/imgRevamp/warning_icon.svg';

import './PrintAwbModal.less';

const PrintAwbModal = ({
  close,
  onSuccess,
  messages,
  item,
  intl,
  ...props
}) => {
  const handleOnOk = () => {
    onSuccess();
    close();
  };
  const ProductImages = item?.extraData?.starProofOfReturnedPackages?.map(
    (img) => {
      return img;
    }
  );
  return (
    <Modal {...props} footer={null}>
      <div className="br-print-awb-modal__warning-icon font-24">
        <Icon component={warning} />
      </div>
      <h1 className="br-print-awb-modal__header-text font-18">
        {intl?.formatMessage({
          id: 'hubs.packages_debrief.print_awb_modal.header'
        })}
      </h1>
      {!!ProductImages?.length && (
        <div className="br-print-awb-modal__images-slider">
          <ImagesCarousel imagesArray={ProductImages} intl={intl} />
        </div>
      )}
      <div className="br-print-awb-modal__footer-buttons">
        <BRButton
          label={intl?.formatMessage({
            id: 'hubs.packages_debrief.print_awb_modal.cancel'
          })}
          className="br-print-awb-modal__cancel-button"
          onClick={close}
        />
        <BRButton
          type={'primary'}
          label={intl?.formatMessage({
            id: 'hubs.packages_debrief.print_awb_modal.confirm'
          })}
          className="br-print-awb-modal__confirm-button"
          onClick={handleOnOk}
        />
      </div>
    </Modal>
  );
};

export default PrintAwbModal;
