import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { Tooltip, Typography, Tag, Button } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import { openModal } from 'utils/modal';

import {
  FINAL_STATS,
  DELIVERY_TYPES_CODES,
  PROMISE,
  MAP_DELIVERIES_CODES,
  ORDER_FINAL_STATES,
  ORDER_SUCCESS_FINAL_STATES
} from 'constants/shipments';
import { LOCALE } from 'constants/intl-wrapper';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { dates } from 'utils/helpers';
import { getFormattedNumber } from 'utils/number';
import {
  BALANCE_CATEGORIES,
  CASHOUT_FREQUENCY,
  FLYER_SIZE
} from 'constants/wallet';
import { getBusiness } from 'actions/BusniessesAction';
import { getBusinessPricingTierInfo } from 'services/pricing';

import { notify } from 'components/Notify/Notify';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BostaFeesContent from 'components/Wallet/components/BostaFeesContent/BostaFeesContent';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import BalanceReceipt from 'components/Wallet/components/BalanceReceipt/BalanceReceipt';

import thumbnail from 'assets/img/thumbnail.png';
import { ReactComponent as UnpaidIcon } from 'assets/imgRevamp/unpaid-unfilled-dot.svg';
import { ReactComponent as PaidIcon } from 'assets/imgRevamp/paid-filled-dot.svg';

import './ShipmentDetailsSummary.less';

const ShipmentDetailsSummary = ({ intl, shipmentDetails = {}, businessId }) => {
  const [promise, setProimse] = useState(PROMISE.IN_PROGRESS);

  const [accountNumber, setAccountNumber] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [tierInfo, setTierInfo] = useState({});
  const [promotion, setPromotion] = useState({});
  const [ibanNumber, setIbanNumber] = useState('');

  const { type, returnAttemptsLength, wallet, isConfirmedDelivery, state } =
    shipmentDetails;

  useEffect(() => {
    if (businessId) {
      getBusinessInfo();
    }
  }, [businessId]);

  const getBusinessInfo = async () => {
    try {
      const {
        bankInfo: { accountNumber, ibanNumber } = {},
        paymentInfo: { paymentFrequency, paymentSchedule } = {}
      } = await getBusiness(businessId);
      setPaymentFrequency(paymentFrequency);
      setPaymentSchedule(paymentSchedule);
      setAccountNumber(accountNumber);
      setIbanNumber(ibanNumber);
      const {
        data: { pricingTier: tierInfo, promotion }
      } = await getBusinessPricingTierInfo(businessId);
      setTierInfo(tierInfo);
      setPromotion(promotion);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    async function checkProimse(shipmentDetails) {
      try {
        if (shipmentDetails?.sla) {
          const isExceededPromise =
            shipmentDetails?.sla?.e2eSla?.isExceededE2ESla;
          const finalState = shipmentDetails?.state?.value;
          if (isExceededPromise) {
            setProimse(PROMISE.NOT_MET);
          }
          for (let i = 0; i < FINAL_STATS.length; i++) {
            if (FINAL_STATS[i] === finalState && !isExceededPromise) {
              setProimse(PROMISE.MET);
            }
          }
        } else {
          setProimse(PROMISE.NOT_STARTED);
        }
      } catch (error) {
        notify(error);
      }
    }

    checkProimse(shipmentDetails);
  }, [shipmentDetails]);

  const shipmentInfo = [
    {
      key: 'type',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.type`
      }),
      render: (type) => {
        return (
          MAP_DELIVERIES_CODES[type?.code] &&
          intl.formatMessage({
            id: `orders.shipment_details.shipment_details_summary.order_types.${
              MAP_DELIVERIES_CODES[type?.code]
            }`
          })
        );
      }
    },
    {
      key: 'businessReference',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.business_refrence`
      }),
      colSpan: 2
    },
    {
      key: 'cod',
      label: intl.formatMessage({
        id:
          type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION
            ? `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.cash_to_be_collected`
            : type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.refund_amount`
            : `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.cod`
      }),
      render: (cod) => {
        return (
          <div className="display-flex justify-space-between br__shipment-details__cod-row ">
            {type?.code === DELIVERY_TYPES_CODES.EXCHANGE && !cod
              ? intl.formatMessage({
                  id: `common.empty_field`
                })
              : intl.formatMessage(
                  {
                    id: COUNTRY_CURRENCY
                  },
                  { cod: Math.abs(cod) || 0 }
                )}
            {!!cod && (
              <Tag color="green">
                {intl.formatMessage({
                  id: `orders.shipment_details.shipment_details_summary.table_cells_labels.${
                    Math.sign(cod) === 1
                      ? 'cash_to_collect'
                      : 'refund_cash_to_customer'
                  }`
                })}
              </Tag>
            )}
          </div>
        );
      }
    },
    ,
    {
      key: 'specs',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.package_type`
      }),
      render: (specs) => {
        return (
          specs?.packageType && (
            <div className="br-shipment-details__summary__package-type">
              <span>
                {intl.formatMessage({
                  id: `shipments.new_order.order_details.package_types.${specs?.packageType
                    ?.replace(/\s/g, '')
                    ?.toLowerCase()}`
                })}
              </span>
              <span className="br-shipment-details__summary__package-type__subtitle">
                {type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN &&
                  `${intl.formatMessage({
                    id: `orders.shipment_details.shipment_details_summary.table_cells.sign_and_return`
                  })} - ${intl.formatMessage({
                    id: `orders.shipment_details.shipment_details_summary.table_cells.${
                      shipmentDetails.pickupRequestId ? 'other_day' : 'same_day'
                    }`
                  })}`}
              </span>
            </div>
          )
        );
      }
    },
    {
      key: type?.code !== DELIVERY_TYPES_CODES.CASH_COLLECTION ? 'specs' : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.number_of_items`
      }),
      render: (specs) => {
        return type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP &&
          shipmentDetails?.returnSpecs?.packageDetails?.itemsCount
          ? shipmentDetails?.returnSpecs?.packageDetails?.itemsCount
          : specs?.packageDetails?.itemsCount;
      }
    },
    {
      key: 'allowToOpenPackage',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.opening_package`
      }),
      colSpan: type?.code === DELIVERY_TYPES_CODES.FORWARD ? 1 : 2,
      render: (allowToOpenPackage) => {
        return intl.formatMessage({
          id: `orders.shipment_details.shipment_details_summary.table_cells_labels.${
            allowToOpenPackage ? 'allowed' : 'not_allowed'
          }_to_open_package`
        });
      }
    },
    {
      key:
        type?.code !== DELIVERY_TYPES_CODES.CASH_COLLECTION
          ? 'updatedAt'
          : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.last_update_date`
      }),
      render: (updatedAt) =>
        updatedAt &&
        dayjs(new Date(updatedAt)).tz().format('DD MMM YYYY - hh:mmA'),
      colSpan: 2
    },
    {
      key: type?.code !== DELIVERY_TYPES_CODES.CASH_COLLECTION ? 'specs' : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.description`
      }),
      render: (specs) => {
        return type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP &&
          shipmentDetails?.returnSpecs?.packageDetails?.description
          ? shipmentDetails?.returnSpecs?.packageDetails?.description
          : specs?.packageDetails?.description;
      },
      colSpan: 2
    },
    {
      key: 'notes',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.${
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? 'return_notes'
            : 'delivery_notes'
        }`
      }),
      colSpan: type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ? 3 : 6
    },
    {
      key:
        type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION
          ? 'updatedAt'
          : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.last_update_date`
      }),
      render: (updatedAt) =>
        updatedAt &&
        dayjs(new Date(updatedAt)).tz().format('DD MMM YYYY - hh:mmA')
    },
    {
      key:
        type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
        type?.code === DELIVERY_TYPES_CODES.EXCHANGE
          ? 'crpImageUrl'
          : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.pickedup_items`
      }),
      render: (crpImageUrl) => {
        if (
          (type?.code === DELIVERY_TYPES_CODES.EXCHANGE ||
            type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP) &&
          crpImageUrl
        ) {
          return (
            <>
              <img
                onClick={() => {
                  if (crpImageUrl) {
                    openModal(PreviewImage, {
                      previewImage: crpImageUrl
                    });
                  }
                }}
                className="br-shipment-details__summary__crp-image"
                src={crpImageUrl ? crpImageUrl : thumbnail}
                alt="Items pickupd up"
              />
              <br />
              <Typography.Text
                onClick={() => {
                  if (crpImageUrl) {
                    openModal(PreviewImage, {
                      previewImage: crpImageUrl
                    });
                  }
                }}
                className={classnames(
                  'br-shipment-details__summary__crp-image-sub-title',
                  {
                    'br-shipment-details__summary__crp-image-enlarge':
                      crpImageUrl
                  }
                )}
              >
                {intl.formatMessage({
                  id: `orders.shipment_details.shipment_details_summary.table_cells_labels.${
                    crpImageUrl ? 'enlarge_image' : 'pickedup_Items_Image_alt'
                  }`
                })}
              </Typography.Text>
            </>
          );
        }
      }
    }
  ];

  const customerInfoCells = [
    {
      key: 'receiver',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.reciever_name`
      }),
      render: (receiver) => {
        return receiver?.fullName
          ? receiver.fullName
          : `${receiver?.firstName} ${receiver?.lastName}`;
      },
      colSpan: 2
    },
    {
      key: ['receiver', 'phone'],
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.phone_number`
      }),
      colSpan: 5
    },
    {
      key: ['receiver', 'secondPhone'],
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.second_phone_number`
      })
    },
    {
      key: 'address',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.customer_address`
      }),
      render: () => {
        return (
          <>
            {type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
            type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
              ? `${
                  shipmentDetails?.pickupAddress?.firstLine ||
                  intl.formatMessage({
                    id: `common.empty_field`
                  })
                }  ${
                  shipmentDetails?.pickupAddress?.secondLine
                    ? ` / ${shipmentDetails?.pickupAddress?.secondLine}`
                    : ''
                }
            `
              : shipmentDetails?.dropOffAddress
              ? `${
                  shipmentDetails?.dropOffAddress?.firstLine ||
                  intl.formatMessage({
                    id: `common.empty_field`
                  })
                } ${
                  shipmentDetails?.dropOffAddress?.secondLine
                    ? ` / ${shipmentDetails?.dropOffAddress?.secondLine}`
                    : ''
                }
            `
              : ''}
            {shipmentDetails?.dropOffAddress?.isWorkAddress && (
              <Tag color="green">
                {intl.formatMessage({
                  id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.work_address`
                })}
              </Tag>
            )}
          </>
        );
      },
      colSpan: 8
    },
    {
      key: 'Area',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.area`
      }),
      render: () => {
        return type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? typeof shipmentDetails?.pickupAddress?.district !== 'object'
            ? `${
                shipmentDetails?.pickupAddress?.zone
                  ? `${shipmentDetails?.pickupAddress?.zone?.name} - `
                  : ''
              } ${shipmentDetails?.pickupAddress?.district}`
            : intl.locale === LOCALE.EN
            ? `${shipmentDetails?.pickupAddress?.zone?.name || ''} - ${
                shipmentDetails?.pickupAddress?.district?.name || ''
              }`
            : `${shipmentDetails?.pickupAddress?.zone?.nameAr || ''} - ${
                shipmentDetails?.pickupAddress?.district?.nameAr || ''
              }` ||
              intl.formatMessage({
                id: `common.empty_field`
              })
          : shipmentDetails?.dropOffAddress?.district &&
            typeof shipmentDetails?.dropOffAddress?.district !== 'object'
          ? `${
              shipmentDetails?.dropOffAddress?.zone
                ? `${shipmentDetails.dropOffAddress.zone?.name} - `
                : ''
            }  ${shipmentDetails?.dropOffAddress?.district}`
          : intl.locale === LOCALE.EN
          ? `${shipmentDetails?.dropOffAddress?.zone?.name || ''} - ${
              shipmentDetails?.dropOffAddress?.district?.name || ''
            }`
          : `${shipmentDetails?.dropOffAddress?.zone?.nameAr || ''} - ${
              shipmentDetails?.dropOffAddress?.district?.nameAr || ''
            }` ||
            intl.formatMessage({
              id: `common.empty_field`
            });
      },
      colSpan: 2
    },
    {
      key: 'pickupAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.city`
      }),
      render: () => {
        return type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? intl.locale === LOCALE.EN
            ? shipmentDetails?.pickupAddress?.city?.name
            : shipmentDetails?.pickupAddress?.city?.nameAr ||
              shipmentDetails?.pickupAddress?.city?.name
          : intl.locale === LOCALE.EN
          ? shipmentDetails?.dropOffAddress?.city?.name
          : shipmentDetails?.dropOffAddress?.city?.nameAr ||
            shipmentDetails?.dropOffAddress?.city?.name;
      },
      colSpan: 2
    },
    {
      key: 'building',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.building_number`
      }),
      render: () => {
        return type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? shipmentDetails?.pickupAddress?.buildingNumber
          : shipmentDetails?.dropOffAddress?.buildingNumber;
      },
      colSpan: 2
    },
    {
      key: 'floor_apartment',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.floor_apartment`
      }),
      render: () => {
        return type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION ||
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? shipmentDetails?.pickupAddress?.floor &&
            shipmentDetails?.pickupAddress?.apartment
            ? `${`${shipmentDetails?.pickupAddress?.floor},${shipmentDetails?.pickupAddress?.apartment}`}`
            : shipmentDetails?.pickupAddress?.apartment ||
              shipmentDetails?.pickupAddress?.floor
          : shipmentDetails?.dropOffAddress?.floor &&
            shipmentDetails?.dropOffAddress?.apartment
          ? `${`${shipmentDetails?.dropOffAddress?.floor},${shipmentDetails?.dropOffAddress?.apartment}`}`
          : shipmentDetails?.dropOffAddress?.apartment ||
            shipmentDetails?.dropOffAddress?.floor;
      },
      colSpan: 2
    },

    {
      key:
        type?.code === DELIVERY_TYPES_CODES.EXCHANGE ? 'pickupRequestId' : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.pickup_request_id`
      })
    },
    {
      key:
        type?.code === DELIVERY_TYPES_CODES.EXCHANGE
          ? 'collectedFromConsignee'
          : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.pickup_date`
      }),
      render: (collectedFromConsignee) => {
        return (
          shipmentDetails?.pickupRequestId &&
          collectedFromConsignee &&
          dayjs(new Date(shipmentDetails?.collectedFromConsignee))
            .tz()
            .format('DD MMM YYYY - hh:mmA')
        );
      },
      colSpan: 2
    }
  ];

  const returnLocation = [
    {
      key: ['dropOffAddress', 'locationName'],
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.location_name`
      }),
      colSpan: 1
    },
    {
      key: ['dropOffAddress', 'firstLine'],
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.street_name`
      }),
      colSpan: 3
    },
    {
      key: 'dropOffAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.city`
      }),
      render: (dropOffAddress) => {
        return intl.locale === LOCALE.EN
          ? dropOffAddress?.city?.name
          : dropOffAddress?.city?.nameAr || dropOffAddress?.city?.name;
      }
    },
    {
      key: 'dropOffAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.area`
      }),
      render: (dropOffAddress) => {
        return typeof dropOffAddress?.district !== 'object'
          ? `${
              dropOffAddress?.zone?.name ? `${dropOffAddress.zone.name} - ` : ''
            } ${dropOffAddress?.district}`
          : intl.locale === LOCALE.EN
          ? `${dropOffAddress?.zone?.name} - ${dropOffAddress?.district?.name} `
          : `${dropOffAddress?.zone?.nameAr} - ${dropOffAddress?.district?.nameAr} `;
      }
    },
    {
      key: ['dropOffAddress', 'buildingNumber'],
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.building_number`
      })
    },
    {
      key: 'floor_apartment',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.floor_apartment`
      }),
      render: () => {
        return shipmentDetails?.dropOffAddress?.floor &&
          shipmentDetails?.dropOffAddress?.apartment
          ? `${`${shipmentDetails?.dropOffAddress?.floor},${shipmentDetails?.dropOffAddress?.apartment}`}`
          : shipmentDetails?.dropOffAddress?.apartment ||
              shipmentDetails?.dropOffAddress?.floor;
      }
    }
  ];
  const signAndReturnLocation = [
    {
      key: 'returnAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.location_name`
      }),
      render: (returnAddress) => {
        return returnAddress?.locationName;
      },
      colSpan: 1
    },
    {
      key: 'returnAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.street_name`
      }),
      render: (returnAddress) => {
        return returnAddress?.firstLine;
      },
      colSpan: 3
    },
    {
      key: 'returnAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.area`
      }),
      render: (returnAddress) => {
        return typeof returnAddress?.district !== 'object'
          ? `${
              returnAddress?.zone?.name ? `${returnAddress.zone.name} - ` : ''
            } ${returnAddress?.district}`
          : intl.locale === LOCALE.EN
          ? `${returnAddress?.zone?.name} - ${returnAddress?.district?.name} `
          : `${returnAddress?.zone?.nameAr} - ${returnAddress?.district?.nameAr} `;
      }
    },
    {
      key: 'returnAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.city`
      }),
      render: (returnAddress) => {
        return intl.locale === LOCALE.EN
          ? returnAddress?.city?.name
          : returnAddress?.city?.nameAr || returnAddress?.city?.name;
      }
    },
    {
      key: 'building',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.building_number`
      }),
      render: () => {
        return shipmentDetails?.returnAddress?.buildingNumber;
      }
    },
    {
      key: 'floor_apartment',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.floor_apartment`
      }),
      render: () => {
        return shipmentDetails?.returnAddress?.floor &&
          shipmentDetails?.returnAddress?.apartment
          ? `${`${shipmentDetails?.returnAddress?.floor},${shipmentDetails?.returnAddress?.apartment}`}`
          : shipmentDetails?.returnAddress?.floor;
      }
    }
  ];

  const pickupInfo = [
    {
      key: 'pickupAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.location_name`
      }),
      render: (pickupAddress) => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? shipmentDetails?.returnAddress.locationName
          : pickupAddress?.locationName;
      },
      colSpan: 1
    },
    {
      key: 'pickupAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.street_name`
      }),
      render: (pickupAddress) => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? shipmentDetails?.returnAddress.firstLine
          : pickupAddress?.firstLine;
      },
      colSpan: 3
    },
    {
      key: 'pickupAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.area`
      }),
      render: (pickupAddress) => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? typeof shipmentDetails?.returnAddress?.district !== 'object'
            ? `${
                shipmentDetails?.returnAddress?.zone?.name
                  ? `${shipmentDetails.returnAddress.zone.name} - `
                  : ''
              } ${shipmentDetails?.returnAddress?.district}`
            : intl.locale === LOCALE.EN
            ? `${
                shipmentDetails?.returnAddress?.zone?.name
                  ? `${shipmentDetails?.returnAddress?.zone?.name} - `
                  : ''
              } ${shipmentDetails?.returnAddress?.district?.name}`
            : `${
                shipmentDetails?.returnAddress?.zone?.name
                  ? `${shipmentDetails?.returnAddress?.zone?.name} - `
                  : ''
              } ${shipmentDetails?.returnAddress?.district?.nameAr} `
          : typeof pickupAddress?.district !== 'object'
          ? ` ${pickupAddress?.zone?.name} - ${pickupAddress?.district}`
          : intl.locale === LOCALE.EN
          ? ` ${
              pickupAddress?.zone?.name ? `${pickupAddress?.zone?.name} -` : ''
            } ${pickupAddress?.district?.name}`
          : ` ${
              pickupAddress?.zone?.name ? `${pickupAddress?.zone?.name} -` : ''
            } ${pickupAddress?.district?.nameAr}`;
      }
    },
    {
      key: 'pickupAddress',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.city`
      }),
      render: (pickupAddress) => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? intl.locale === LOCALE.EN
            ? shipmentDetails?.returnAddress.city?.name
            : shipmentDetails?.returnAddress.city?.nameAr ||
              shipmentDetails?.returnAddress.city?.name
          : intl.locale === LOCALE.EN
          ? pickupAddress?.city?.name
          : pickupAddress?.city?.nameAr || pickupAddress?.city?.name;
      }
    },
    {
      key: 'building',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.building_number`
      }),
      render: () => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? shipmentDetails?.returnAddress.buildingNumber
          : shipmentDetails?.pickupAddress?.buildingNumber;
      }
    },
    {
      key: 'floor_apartment',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.floor_apartment`
      }),
      render: () => {
        return shipmentDetails?.type?.code === DELIVERY_TYPES_CODES.EXCHANGE &&
          shipmentDetails?.returnAddress
          ? shipmentDetails?.returnAddress?.floor &&
            shipmentDetails?.returnAddress?.apartment
            ? `${`${shipmentDetails?.returnAddress?.floor},${shipmentDetails?.returnAddress?.apartment}`}`
            : shipmentDetails?.returnAddress?.apartment ||
              shipmentDetails?.returnAddress?.floor
          : shipmentDetails?.pickupAddress?.floor &&
            shipmentDetails?.pickupAddress?.apartment
          ? `${`${shipmentDetails?.pickupAddress?.floor},${shipmentDetails?.pickupAddress?.apartment}`}`
          : shipmentDetails?.pickupAddress?.apartment ||
            shipmentDetails?.pickupAddress?.floor;
      },
      colSpan: 2
    },
    {
      key: 'pickupRequestId',
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.pickup_request_id`
      }),
      colSpan: 1
    },
    {
      key: shipmentDetails?.pickupRequestId
        ? type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN
          ? 'collectedFromConsignee'
          : 'collectedFromBusiness'
        : null,
      label: intl.formatMessage({
        id: `orders.shipment_details.shipment_details_summary.table_cells.pickup_date`
      }),
      render: (pickupDate) => {
        return pickupDate !== 'N/A'
          ? dayjs(new Date(pickupDate)).tz().format('DD MMM YYYY - hh:mmA')
          : type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN &&
              pickupDate === 'N/A' &&
              shipmentDetails?.scheduledAt &&
              dayjs(new Date(shipmentDetails.scheduledAt))
                .tz()
                .format('DD MMM YYYY - hh:mmA');
      }
    }
  ];

  const bostaPerformance = [
    {
      label: (
        <>
          {intl.formatMessage({
            id:
              type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
              type?.code === DELIVERY_TYPES_CODES.CASH_COLLECTION
                ? `orders.shipment_details.shipment_details_summary.table_cells.bosta_performance.pickup_attempts`
                : `orders.shipment_details.shipment_details_summary.table_cells.bosta_performance.delievery_attempts`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: `orders.shipment_details.shipment_details_summary.table_cells_tooltips.delivery_attempts_number`
            })}
            trigger={['click']}
          >
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
      key:
        type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? 'pickupAttemptsLength'
          : 'deliveryAttemptsLength',
      render: () => {
        return `${
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? shipmentDetails?.pickupAttemptsLength
            : shipmentDetails?.deliveryAttemptsLength
        } ${intl.formatMessage({
          id: `orders.shipment_details.shipment_details_summary.table_cells_labels.delivery_attempts`
        })}`;
      }
    },
    {
      key:
        type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? 'deliveryAttemptsLength'
          : returnAttemptsLength
          ? 'returnAttemptsLength'
          : null,

      label: (
        <>
          {intl.formatMessage({
            id: `orders.shipment_details.shipment_details_summary.table_cells.bosta_performance.return_attempts`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: `orders.shipment_details.shipment_details_summary.table_cells_tooltips.return_attempts`
            })}
            trigger={['click']}
          >
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
      render: () => {
        return `${
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
            ? shipmentDetails?.deliveryAttemptsLength
            : shipmentDetails?.returnAttemptsLength &&
              shipmentDetails?.returnAttemptsLength
        } ${intl.formatMessage({
          id: `orders.shipment_details.shipment_details_summary.table_cells_labels.return_attempts`
        })}`;
      }
    },

    {
      key: 'outboundActionsCount',
      label: (
        <>
          {intl.formatMessage({
            id: `orders.shipment_details.shipment_details_summary.table_cells.bosta_performance.outbound_calls`
          })}
          <Tooltip
            title={intl.formatMessage({
              id: `orders.shipment_details.shipment_details_summary.table_cells_tooltips.outbound_effort`
            })}
            trigger={['click']}
          >
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
      render: (outboundActionsCount) => {
        return `${outboundActionsCount} ${intl.formatMessage({
          id: `orders.shipment_details.shipment_details_summary.table_cells_labels.calls`
        })}`;
      }
    },
    {
      key: 'promise',
      label: (
        <>
          {intl.formatMessage({
            id: `orders.shipment_details.shipment_details_summary.table_cells.bosta_performance.bosta_promise`
          })}
          <Tooltip
            title={
              MAP_DELIVERIES_CODES[type?.code] &&
              intl.formatMessage({
                id: `orders.shipment_details.shipment_details_summary.table_cells_tooltips.delivery_promise_tooltips.${
                  MAP_DELIVERIES_CODES[type?.code]
                }`
              })
            }
            trigger={['click']}
          >
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
      render: () => {
        return (
          <p className={`br-shipment-details__summary__promise-${promise}`}>
            {intl.formatMessage({
              id: `orders.shipment_details.shipment_details_summary.table_cells_labels.promise_${promise}`
            })}
          </p>
        );
      }
    }
  ];

  const parcels = [
    {
      key: 'type',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.table_cells.type'
      })
    },
    {
      key: 'itemsCount',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.table_cells.number_of_items'
      })
    },
    {
      key: 'state',
      textColor:
        shipmentDetails?.parcels?.crp?.state === FINAL_STATS.DELIVERED &&
        'success',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.table_cells.parcels_table.state'
      })
    },
    {
      key: 'description',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.table_cells.description'
      })
    },
    {
      key: 'returnNotes',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.return_notes'
      }),
      render: (returnNotes) => {
        return returnNotes ? returnNotes : shipmentDetails?.notes;
      }
    }
  ];

  const cashCycle = [
    {
      key: 'deposited_at',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.deposited_at'
      }),
      render: (depositedAt) => dates(depositedAt, 'ddd, DD MMM')
    },
    {
      key: 'cod',
      textColor: 'success',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.collected_cash'
      }),
      render: (cod) =>
        intl.formatMessage(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: getFormattedNumber(cod) }
        )
    },
    {
      key: 'bosta_fees',
      textColor: 'danger',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.bosta_fees'
      }),
      labelPopover: (
        <BostaFeesContent
          bostaFees={shipmentDetails?.wallet?.cashCycle}
          tierInfo={tierInfo}
          promotion={promotion}
          isShipment
        />
      ),
      render: (bostaFees) =>
        intl.formatMessage(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: getFormattedNumber(bostaFees) }
        )
    },
    {
      key: 'size',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.flyer_size'
      }),
      labelTooltip: intl.formatMessage(
        {
          id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.flyer_size_tooltip'
        },
        {
          pricingButton: (
            <Button
              type="link"
              className="br-shipment-details__summary__wallet__pricing-plan"
              onClick={() => window.open('/settings/pricing-plan', '_blank')}
            >
              {intl.formatMessage({
                id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.pricing_plan'
              })}
            </Button>
          )
        }
      ),
      render: (flyerSize) => FLYER_SIZE[flyerSize]
    }
  ];

  const cashOut = [
    ...(wallet?.cashout?.next_cashout_date
      ? [
          {
            key: 'next_cashout_date',
            label: intl.formatMessage({
              id: 'wallet.cycles.table_columns.payment_status'
            }),
            labelTooltip: intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.next_cashout_date_tooltip'
            }),
            render: (nextCashoutDate) =>
              dayjs() > dayjs(nextCashoutDate) ? (
                <Tooltip
                  title={intl.formatMessage({ id: 'wallet.delayed_tooltip' })}
                  trigger={['click']}
                  overlayClassName="br-cycles-table-payment_status__tooltip"
                  placement="bottomLeft"
                >
                  <span className="br-cycles-payment-status">
                    <UnpaidIcon />
                    {intl.formatMessage({ id: 'wallet.delayed' })}
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={intl.formatMessage(
                    { id: 'wallet.un_paid_tooltip' },
                    {
                      date: dates(nextCashoutDate, 'ddd, DD MMM')
                    }
                  )}
                  trigger={['click']}
                  overlayClassName="br-cycles-table-payment_status__tooltip"
                  placement="bottomLeft"
                >
                  <span className="br-cycles-payment-status">
                    <UnpaidIcon />
                    {intl.formatMessage({ id: 'wallet.un_paid' })}
                  </span>
                </Tooltip>
              )
          },
          {
            key: 'cashout_frequency',
            label: intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.cashout_frequency'
            }),
            labelTooltip: intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.cashout_frequency_tooltip'
            }),
            render: () => {
              let value = '';
              let valueTooltip = '';

              if (
                paymentFrequency === CASHOUT_FREQUENCY.WEEKLY &&
                paymentSchedule.length === 2
              ) {
                value = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_two_days_week'
                });
                valueTooltip = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_two_days_week_tooltip'
                });
              } else if (
                paymentFrequency === CASHOUT_FREQUENCY.WEEKLY &&
                paymentSchedule.length === 3
              ) {
                value = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_three_days_week'
                });
                valueTooltip = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_three_days_week_tooltip'
                });
              } else if (paymentFrequency === CASHOUT_FREQUENCY.DAILY) {
                value = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_daily'
                });
                valueTooltip = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_daily_tooltip'
                });
              } else if (paymentFrequency === CASHOUT_FREQUENCY.WEEKLY) {
                value = intl.formatMessage({
                  id: 'wallet.balance.header.cashout.cashout_frequency_weekly'
                });
                valueTooltip = intl.formatMessage({
                  id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.cashout_frequency_tooltip'
                });
              }

              return (
                <span className="display-flex align-center">
                  {value}
                  <Tooltip title={valueTooltip} trigger={['click']}>
                    <InfoCircleFilled />
                  </Tooltip>
                </span>
              );
            }
          }
        ]
      : [
          {
            key: 'transaction_date',
            label: intl.formatMessage({
              id: 'wallet.cycles.table_columns.payment_status'
            }),
            render: (transferedDate) => (
              <Tooltip
                title={
                  <div>
                    {intl.formatMessage(
                      { id: 'wallet.paid_tooltip' },
                      {
                        date: dates(transferedDate, 'ddd, DD MMM')
                      }
                    )}
                  </div>
                }
                trigger={['click']}
                overlayClassName="br-cycles-table-payment_status__tooltip"
                placement="bottomLeft"
              >
                <span className="br-cycles-payment-status success">
                  <PaidIcon />
                  {intl.formatMessage({ id: 'wallet.paid' })}
                </span>
              </Tooltip>
            )
          },
          {
            key: 'transaction_id',
            label: intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.transfer_id'
            }),
            render: (transferId) => (
              <Button
                type="link"
                onClick={() =>
                  openModal(BalanceReceipt, {
                    transactionId: transferId,
                    category: BALANCE_CATEGORIES.CASH_OUT
                  })
                }
              >
                #{transferId}
              </Button>
            )
          },
          {
            key: 'amount',
            label: intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.transfer_amount'
            }),
            render: (transferAmount) =>
              intl.formatMessage(
                {
                  id: COUNTRY_CURRENCY
                },
                { cod: getFormattedNumber(transferAmount) }
              )
          }
        ])
  ];

  const compensation = [
    {
      key: 'transaction_date',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.compensation.compensated_at'
      }),
      render: (compensatedAt) => dates(compensatedAt, 'ddd, DD MMM')
    },
    {
      key: 'transaction_id',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.compensation.compensation_id'
      }),
      render: (compensationId) => (
        <Button
          type="link"
          // onClick={() => handleDownloadCompensation(compensationId)}
        >
          #{compensationId}
        </Button>
      )
    },
    {
      key: 'total',
      label: intl.formatMessage({
        id: 'orders.shipment_details.shipment_details_summary.wallet.compensation.compensation_amount'
      }),
      render: (compensationAmount) =>
        intl.formatMessage(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: getFormattedNumber(compensationAmount) }
        )
    }
  ];

  return (
    <div className="br-shipment-details__summary">
      <div className="br-shipment-details__summary__shipment-table-title">
        {type?.code === DELIVERY_TYPES_CODES.EXCHANGE
          ? intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.tables_headers.deliver_order_details'
            })
          : intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.tables_headers.order_details'
            })}
      </div>
      <BRInformationTable
        dataSource={shipmentDetails}
        cells={shipmentInfo}
        totalCol={4}
      />
      {type?.code === DELIVERY_TYPES_CODES.EXCHANGE && (
        <>
          <div className="br-shipment-details__summary__parcels-tabel-title">
            {intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.tables_headers.return_order_details'
            })}
          </div>
          <BRInformationTable
            dataSource={{
              ...shipmentDetails?.parcels?.crp,
              ...shipmentDetails?.returnSpecs?.packageDetails,
              returnNotes: shipmentDetails?.returnNotes
            }}
            cells={parcels}
            totalCol={3}
          />
        </>
      )}

      <div className="br-shipment-details__summary__customer-tabel-title">
        {intl.formatMessage({
          id: 'orders.shipment_details.shipment_details_summary.tables_headers.customer_info'
        })}
      </div>
      <BRInformationTable
        dataSource={shipmentDetails}
        cells={customerInfoCells}
        totalCol={8}
      />
      {type?.code !== DELIVERY_TYPES_CODES.CASH_COLLECTION && (
        <div className="br-shipment-details__summary__pickup-table-title">
          {intl.formatMessage({
            id:
              type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
              type?.code === DELIVERY_TYPES_CODES.EXCHANGE
                ? 'orders.shipment_details.shipment_details_summary.tables_headers.return_location'
                : 'orders.shipment_details.shipment_details_summary.tables_headers.pickup_info'
          })}
        </div>
      )}

      {type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN && (
        <>
          <BRInformationTable
            dataSource={shipmentDetails}
            cells={signAndReturnLocation}
            totalCol={4}
          />
        </>
      )}
      {type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ? (
        <BRInformationTable
          dataSource={shipmentDetails}
          cells={returnLocation}
          totalCol={4}
        />
      ) : (
        type?.code !== DELIVERY_TYPES_CODES.CASH_COLLECTION && (
          <BRInformationTable
            dataSource={shipmentDetails}
            cells={pickupInfo}
            totalCol={4}
          />
        )
      )}
      <div className="br-shipment-details__summary__performance-table-title">
        {intl.formatMessage({
          id: 'orders.shipment_details.shipment_details_summary.tables_headers.bosta_performance'
        })}
        <span className="br-shipment-details__summary__performance_tooltip">
          <Tooltip
            title={intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.table_cells_tooltips.bosta_performace'
            })}
            trigger={['click']}
          >
            <InfoCircleFilled />
          </Tooltip>
        </span>
      </div>
      <BRInformationTable
        dataSource={shipmentDetails}
        cells={bostaPerformance}
        totalCol={
          type?.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
          (type?.code === DELIVERY_TYPES_CODES.EXCHANGE && returnAttemptsLength)
            ? 4
            : 3
        }
      />
      {(accountNumber || ibanNumber) && (
        <>
          <div className="br-shipment-details__summary__wallet-title">
            {intl.formatMessage({
              id: 'orders.shipment_details.shipment_details_summary.wallet.title'
            })}
          </div>
          {!ORDER_FINAL_STATES.includes(state?.code) ? (
            <div className="br-shipment-details__summary__wallet-empty-state">
              <BREmptyState
                title={intl.formatMessage({
                  id: 'orders.shipment_details.shipment_details_summary.wallet.empty_state_update'
                })}
              />
            </div>
          ) : ORDER_SUCCESS_FINAL_STATES.includes(state?.code) &&
            !isConfirmedDelivery ? (
            <div className="br-shipment-details__summary__wallet-empty-state">
              <BREmptyState
                title={intl.formatMessage({
                  id: 'orders.shipment_details.shipment_details_summary.wallet.empty_state_processing'
                })}
              />
            </div>
          ) : (
            <>
              {wallet?.cashCycle && (
                <BRInformationTable
                  className="br-information-table-wallet"
                  dataSource={wallet?.cashCycle}
                  cells={cashCycle}
                  totalCol={4}
                  title={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.title'
                  })}
                  titleTooltip={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.cash_cycles.title_tooltip'
                  })}
                />
              )}
              {wallet?.cashout && (
                <BRInformationTable
                  className="br-information-table-wallet"
                  dataSource={wallet?.cashout}
                  cells={cashOut}
                  totalCol={wallet?.cashout?.next_cashout_date ? 2 : 3}
                  title={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.title'
                  })}
                  titleTooltip={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.cashout.title_tooltip'
                  })}
                />
              )}
              {wallet?.compensation && (
                <BRInformationTable
                  className="br-information-table-wallet"
                  dataSource={wallet?.compensation}
                  cells={compensation}
                  totalCol={3}
                  title={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.compensation.title'
                  })}
                  titleTooltip={intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.wallet.compensation.title_tooltip'
                  })}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default injectIntl(ShipmentDetailsSummary);
