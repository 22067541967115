import React, { useEffect } from 'react';
import { Table } from 'antd';
import { deliverySchema } from './common';
import styles from './style.module.scss';

const columns = [
  {
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    title: 'Tracking Number',
    render: (text) => text || <b className={styles.missing}>MISSING</b>
  },
  {
    dataIndex: 'paidAmt',
    key: 'paidAmt',
    title: 'Paid Amount',
    render: (text) => text || <b className={styles.missing}>MISSING</b>
  },
  {
    dataIndex: 'discount',
    key: 'discount',
    title: 'Discount'
  },
  {
    dataIndex: 'outOfFlierFees',
    key: 'outOfFlierFees',
    title: 'Out Of Flier Fees'
  },
  {
    dataIndex: 'businessId',
    key: 'businessId',
    title: 'Business Id'
  },
  {
    dataIndex: 'paymentType',
    key: 'paymentType',
    title: 'Payment Type',
    render: (text) =>
      text === 'N/A' ? <b className={styles.missing}>MISSING</b> : text
  }
];

const amazonColumns = [
  {
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    title: 'Tracking Number',
    render: (text) => text || <b className={styles.missing}>MISSING</b>
  },
  {
    dataIndex: 'paidAmt',
    key: 'paidAmt',
    title: 'Paid Amount',
    render: (text) => text || <b className={styles.missing}>MISSING</b>
  }
];

const TransactionsTable = ({ data, transactionType }) => {
  return (
    <>
      <Table
        columns={transactionType === 'amazon' ? amazonColumns : columns}
        dataSource={data}
        pagination={{
          current: 1,
          total: data.length,
          pageSize: 50,
          showSizeChanger: false
        }}
        scroll={{ y: 480 }}
      />
    </>
  );
};

export default TransactionsTable;
