import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  fetchInternationalItemsShippingBusinessess,
  fetchInternationalOrdersShippingBusinessess
} from 'services/international-shipping-in';

import Container from 'components/Container';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRHeader from 'components/BRHeader/BRHeader';
import InternationalOrders from 'components/InternationalShipping/components/InternationalOrders/InternationalOrders';
import InternationalItems from 'components/InternationalShipping/components/InternationalItems/InternationalItems';
import Insights from 'components/InternationalShipping/components/Insights/Insights';
import { notify } from 'components/Notify/Notify';

import './InternationalShipping.less';

const InternationalShipping = ({ intl }) => {
  const { TabPane } = Tabs;
  const [businessesItems, setBusinessItems] = useState([]);
  const [businessesOrders, setBusinessOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAllBusiness();
  }, []);

  const getAllBusiness = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchInternationalItemsShippingBusinessess();
      const result = await fetchInternationalOrdersShippingBusinessess();
      setBusinessOrders(result.data);
      setBusinessItems(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };
  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'international_shipping.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <Tabs className="br-international-shipping__tabs">
            <TabPane
              tab={intl.formatMessage({
                id: 'international_shipping.tabs.items'
              })}
              key={2}
            >
              <InternationalItems businesses={businessesItems} />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({
                id: 'international_shipping.tabs.orders'
              })}
              key={1}
            >
              <InternationalOrders businesses={businessesOrders} />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({
                id: 'international_shipping.tabs.insights'
              })}
              key={3}
            >
              <Insights businesses={businessesItems} />
            </TabPane>
          </Tabs>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(InternationalShipping));
