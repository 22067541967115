import './CrumbsBar.less';

const CrumbsBar = () => {
  return (
    <div className="crumbsbar__container">
      <div className="crumbsbar__slider">
        <div className="crumbsbar__point crumbsbar__start"></div>
        <div className="crumbsbar__point crumbsbar__point crumbsbar__middle"></div>
        <div className="crumbsbar__point crumbsbar__end"></div>
      </div>
    </div>
  );
};

export default CrumbsBar;
