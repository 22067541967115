import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { useIntl } from 'react-intl';

import { CHANGE_FROM_COD_TO_OTHER_SOURCE_OPTIONS } from 'constants/hubs';

import BRButton from 'components/BRButton/BRButton';

import './ChangeToOtherSourceModal.less';

const ChangeToOtherSourceModal = ({
  close,
  cashId,
  src,
  trackingNumber,
  onChangeConfrim,
  ...props
}) => {
  const [selectedMoneySource, setSelectedMoneySource] = useState('');

  const intl = useIntl();

  const handleOnChangeClick = () => {
    onChangeConfrim({
      cashId,
      payload: { changeTo: selectedMoneySource },
      trackingNumber
    });
    close();
  };

  return (
    <Modal
      onCancel={close}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type={'primary'}
            label={intl.formatMessage({ id: 'common.change' })}
            onClick={handleOnChangeClick}
            disabled={!selectedMoneySource}
          />
        </>
      }
      title={intl.formatMessage(
        {
          id: `money_debriefing.change_to_modal_title`
        },
        { trackingNumber, selectedMoneySource }
      )}
      width={null}
      className="br-change-to-other-source-modal"
      {...props}
    >
      <Select onChange={setSelectedMoneySource}>
        {CHANGE_FROM_COD_TO_OTHER_SOURCE_OPTIONS.map((option, id) => (
          <Select.Option value={option} key={id}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ChangeToOtherSourceModal;
