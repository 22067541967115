import { useIntl } from 'react-intl';
import classNames from 'classnames';

import BRButton from 'components/BRButton/BRButton';
import ScanInput from 'components/NewFulfillment/FulfillmentReturns/ScanSerialsModal/components/ScanInput/ScanInput';

import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CopyIcon } from 'assets/bosta-icons/Copy.svg';

import './SerialsViewHeader.less';

const SerialsViewHeader = ({
  allFetchedSerials = [],
  typedSerials,
  scannedSerial,
  handleValidScan,
  printSerials,
  copySerial,
  getSerialType
}) => {
  const { formatMessage } = useIntl();

  const renderSearchResult = () => {
    const { serialNumber } = scannedSerial || {};

    return (
      <div className="br-serials-view-header__search-result">
        <span>{serialNumber}</span>
        <span>
          {`(${formatMessage({
            id: `fulfillment_returns.serials_view.${getSerialType(
              scannedSerial
            )}.serial_type`
          })})`}
        </span>
        <BRButton
          prefixIcon={
            <CopyIcon
              className="copy-icon"
              onClick={() => copySerial(serialNumber)}
            />
          }
        />
        <BRButton
          label={formatMessage({
            id: 'common.print'
          })}
          prefixIcon={<PrinterIcon />}
          onClick={() => printSerials([serialNumber])}
        />
      </div>
    );
  };

  return (
    <div className="br-serials-view-header">
      <ScanInput
        handleValidScan={handleValidScan}
        disabled={!allFetchedSerials.length}
      />
      {scannedSerial && renderSearchResult()}
      <div className="br-serials-view-header__counts">
        {typedSerials.map(({ type, title, serials = [] }) => (
          <div
            key={type}
            className={classNames('br-serials-view-header__item', {
              empty: !serials.length
            })}
          >
            <span className="br-serials-view-header__item__count">
              {serials.length || 0}
            </span>
            <span className="br-serials-view-header__item__title">{title}</span>
          </div>
        ))}
      </div>
      {typedSerials.length > 1 && (
        <BRButton
          label={formatMessage({
            id: 'fulfillment_returns.serials_view.print_all_serials'
          })}
          prefixIcon={<PrinterIcon />}
          disabled={!allFetchedSerials.length}
          onClick={() =>
            printSerials(
              allFetchedSerials.map(({ serialNumber }) => serialNumber)
            )
          }
          block
        />
      )}
    </div>
  );
};

export default SerialsViewHeader;
