import { Timeline } from 'antd';
import dayjs from 'dayjs';

import { INTERNATIONAL_DELIVERY_DATE_FORMAT } from 'constants/international-orders';

import './PickupLogs.less';

const PickupLogs = ({ logs = [] }) => {
  return (
    <Timeline className="br-international-pickup-logs__timeline">
      {logs.map(({ action, time, takenBy }, index) => (
        <Timeline.Item color="gray" key={index}>
          <div className="br-international-pickup__column-subtext subheading">
            {dayjs(time).format(INTERNATIONAL_DELIVERY_DATE_FORMAT)}
          </div>
          <div className="body-medium">{action}</div>
          <div className="br-international-pickup__user subheading">
            {takenBy.userName} - {takenBy.userRole}
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default PickupLogs;
