import React from 'react';
import { Modal, Form, Input } from 'antd';
import { injectIntl } from 'react-intl';

import './CashierRejectModal.less';

const CashierRejectModal = ({ intl, onFinish, close, ...rest }) => {
  const onSubmit = (values) => {
    onFinish(values);
    close();
  };

  return (
    <Modal
      {...rest}
      title={
        <div className="br-cashier-reject-modal-title-container">
          <span className="br-cashier-reject-modal__title">
            {intl.formatMessage({
              id: 'cashier.rejection_modal.title'
            })}
          </span>
          <span className="br-cashier-reject-modal__subtitle">
            {intl.formatMessage({
              id: 'cashier.rejection_modal.sub_title'
            })}
          </span>
        </div>
      }
      className="br-cashier-rejection-modal-container"
      centered
      onCancel={close}
      okButtonProps={{
        htmlType: 'submit',
        form: 'cashierRejectionForm'
      }}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      onOk={null}
    >
      <Form name="cashierRejectionForm" onFinish={onSubmit}>
        <Form.Item
          label={intl.formatMessage({
            id: 'cashier.rejection_modal.rejection_reason_label'
          })}
          name="rejectionReason"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'cashier.rejection_modal.rejection_reason_placeholder'
            })}
            autoSize={{ minRows: 3, maxRows: 3 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(CashierRejectModal);
