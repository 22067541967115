import { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Tabs, Tag, Button, Popover } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  ACTION_CENTER_PAGE_SIZE,
  ACTION_CENTER_TABS_INDEX,
  CANCEL_ORDER,
  CHANGE_COD,
  REQUEST_UPDATE_TYPE,
  RESCHEDULED,
  RETURN_ISSUE_RESOLVED,
  TRANSFER_TO_SORTING,
  UPDATED_DELIVERY_DETAILS,
  UPDATES_COLORS,
  UPDATES_TO_ACTION,
  UPDATES_TO_STRING
} from 'constants/action-center';
import { openModal } from 'utils/modal';
import {
  actionSubmit,
  exportActionItems,
  getActions,
  getRequests,
  requestAction
} from 'services/action-center';
import { printAirwaybill } from 'services/shipments';
import { downloadAsPdf } from 'utils/download';

import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import OrderActionModal from './components/OrderActionModal/OrderActionModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PrintConfirmationModal from './components/PrintConfirmationModal/PrintConfirmationModal';
import BRButton from 'components/BRButton/BRButton';
import StarInfoPopover from './components/StarInfoPopover/StarInfoPopover';

import './OrderActions.less';

const OrderActions = ({
  handleRowClick,
  selectedRowData,
  clearSelectedRow,
  hubBins,
  hubId,
  hubType,
  handleOnTabChange,
  intl
}) => {
  const refreshTable = useRef();
  const [count, setCount] = useState(0);
  const [requestsCount, setRequestsCount] = useState(0);
  const [searchValue, setSearchValue] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (refreshTable?.current) {
      updateTable();
    }
    if (hubId) {
      getRequestsCount();
    }
  }, [hubId]);

  const getRequestsCount = async () => {
    try {
      const { data } = await getRequests({ hubId });
      setRequestsCount(data?.total);
    } catch (error) {
      notify(error.message);
    }
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.tracking_no'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber, record) => {
        return (
          <Link
            to={`/deliveries/${record.deliveryId}/details`}
            className="br-order-actions-delivery-link"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            {trackingNumber}
          </Link>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.bin'
      }),
      dataIndex: 'shelf',
      sorter: true
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.updates'
      }),
      dataIndex: 'action',
      sorter: true,
      render: (action) => {
        return (
          <Tag className={UPDATES_COLORS[action]}>
            {UPDATES_TO_STRING[action]}
          </Tag>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.date_and_time'
      }),
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt) => {
        return (
          <>
            {dayjs(new Date(createdAt)).format('DD MMM YYYY')}
            <span className="br-order-actions-table-time">
              {dayjs(new Date(createdAt)).tz().format('h:mm A')}
            </span>
          </>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.scheduled_date'
      }),
      dataIndex: 'scheduledAt',
      sorter: true,
      render: (scheduledAt) => {
        return scheduledAt
          ? dayjs(new Date(scheduledAt)).format('DD MMM YYYY')
          : 'N/A';
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.action'
      }),
      dataIndex: 'action',
      render: (_, record) => {
        return renderActionButton(record);
      }
    }
  ];

  const requestsColumns = [
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.tracking_no'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber, record) => {
        return (
          <Link
            to={`/deliveries/${record.deliveryId}/details`}
            className="br-order-actions-delivery-link"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            {trackingNumber}
          </Link>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.requests.table_columns.star'
      }),
      dataIndex: 'star',
      render: (star) => {
        return (
          <Popover
            overlayClassName="br-action-center__star-info-popover"
            content={<StarInfoPopover starId={star._id} />}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement
            }
          >
            {star?.name}
          </Popover>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.date_and_time'
      }),
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt) => {
        return (
          <>
            {dayjs(new Date(createdAt)).format('DD MMM YYYY')}
            <span className="br-order-actions-table-time">
              {dayjs(new Date(createdAt)).tz().format('h:mm A')}
            </span>
          </>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: 'action_center.order_actions.action'
      }),
      width: 100,
      dataIndex: '',
      render: (_, record) => (
        <div
          className="br-action-center__request-actions-container"
          onClick={(e) => e.stopPropagation()}
        >
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'action_center.requests.actions.approve'
            })}
            onClick={() =>
              handleApplyRequestAction({
                requestId: record._id,
                rejectionType: REQUEST_UPDATE_TYPE.APPROVE
              })
            }
          />
        </div>
      )
    }
  ];

  const handleApplyRequestAction = async ({
    values,
    requestId,
    rejectionType
  }) => {
    setIsLoading(true);
    const payload = {
      updateType: rejectionType,
      ...values
    };
    try {
      await requestAction({ requestId, payload });
      updateTable();
      clearSelectedRow();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleActionApplied = async (_id) => {
    const payload = {
      isApplied: true
    };
    try {
      const res = await actionSubmit(_id, payload);
      if (res?.success) {
        updateTable();
        clearSelectedRow();
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handlePrintAWB = async ({ deliveryId, _id, trackingNumber }) => {
    setIsLoading(true);
    try {
      const { data } = await printAirwaybill(deliveryId);
      downloadAsPdf(data, trackingNumber);
      openModal(PrintConfirmationModal, {
        actionId: _id,
        onSuccess: (actionId) => handleActionApplied(actionId)
      });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOpenMoveToBinModal = (item) => {
    const { deliveryId, _id, action } = item;
    openModal(OrderActionModal, {
      title: intl.formatMessage({
        id: 'action_center.order_actions.move_to_bin'
      }),
      dropdownPlaceholder: intl.formatMessage({
        id: 'action_center.order_actions.select_bin'
      }),
      dropdownData: hubBins,
      hubId,
      deliveryId,
      _id,
      clearSelectedRow,
      action,
      updateTable: () => updateTable()
    });
  };

  const handleActionButtonClick = (e, item) => {
    e.stopPropagation();
    const { deliveryId, _id, action, trackingNumber } = item;

    if ([RESCHEDULED, CANCEL_ORDER, RETURN_ISSUE_RESOLVED].includes(action)) {
      handleOpenMoveToBinModal(item);
    } else if ([UPDATED_DELIVERY_DETAILS, CHANGE_COD].includes(action)) {
      handlePrintAWB({ deliveryId, _id, trackingNumber });
    }
  };

  const handleExportFile = async () => {
    const payload = {
      reportType: 'actionItems',
      ...(!!selectedRows.length && { actionIds: selectedRows })
    };
    try {
      const res = await exportActionItems(payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const renderActionButton = (item) => {
    return (
      <div className="br-action-center-cta-button">
        <Button
          onClick={(e) => handleActionButtonClick(e, item)}
          disabled={item?.action === TRANSFER_TO_SORTING}
        >
          {UPDATES_TO_ACTION(hubType)[item?.action]}
        </Button>
      </div>
    );
  };

  const handleSearchValueChange = (value) => {
    if (!value) {
      return setSearchValue(undefined);
    }
    setSearchValue(value.split(','));
  };

  const getActionItem = (item) => {
    if (item?.actionDetails?.relatedAction === RETURN_ISSUE_RESOLVED) {
      return RETURN_ISSUE_RESOLVED;
    } else if (item?.actionDetails?.codAmount) {
      return CHANGE_COD;
    }

    return item.action;
  };

  const formatActionsData = (data) =>
    data.map((item) => ({
      _id: item._id,
      deliveryId: item.deliveryId,
      trackingNumber: item.trackingNumber,
      shelf: item.shelf,
      action: getActionItem(item),
      createdAt: item.createdAt,
      scheduledAt: item.scheduledAt,
      actionDetails: item?.actionDetails
    }));

  const getTableData = async ({
    pageId: pageNumber,
    pageLimit: pageSize,
    sortField,
    sortOrder
  }) => {
    try {
      const payload = {
        trackingNumbers: searchValue,
        hubId,
        page: pageNumber,
        limit: pageSize,
        sortBy: sortField
          ? `${sortOrder === 'descend' ? '-' : ''}${sortField}`
          : 'createdAt'
      };

      const { data } = await getActions({
        payload
      });

      setCount(data.total || 0);

      return {
        list: formatActionsData(data.list),
        total: data.total || 0
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const getRequestsTableData = async ({
    pageId: pageNumber,
    pageLimit: pageSize,
    sortField,
    sortOrder
  }) => {
    try {
      const payload = {
        trackingNumbers: searchValue,
        hubId,
        page: pageNumber,
        limit: pageSize,
        sortBy: sortField
          ? `${sortOrder === 'descend' ? '-' : ''}${sortField}`
          : 'createdAt'
      };

      const { data } = await getRequests(payload);

      setRequestsCount(data.total || 0);

      return {
        list: data.list,
        total: data.total || 0
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleTabChange = (value) => {
    handleOnTabChange(ACTION_CENTER_TABS_INDEX[value]);
  };

  return (
    <div className="br-order-actions__container">
      <LoadingWrapper loading={isLoading}>
        {hubBins ? (
          <div className="br-order-actions__tabs-container">
            <Tabs className="br-order-actions__tabs" onChange={handleTabChange}>
              <Tabs.TabPane
                tab={`${intl.formatMessage({
                  id: 'action_center.actions_needed'
                })} (${count})`}
                key={1}
              >
                <BRSearchableTable
                  columns={columns}
                  pageLimit={ACTION_CENTER_PAGE_SIZE}
                  listFunction={getTableData}
                  rowClassName={(record) =>
                    record?._id === selectedRowData?._id ? 'selected-row' : ''
                  }
                  searchInputPlaceholder={intl.formatMessage({
                    id: 'action_center.order_actions.search_for_tracking_number'
                  })}
                  showSearch
                  handleTextInput={handleSearchValueChange}
                  shareMethods={acceptMethods}
                  onRowClick={handleRowClick}
                  selectedRows={setSelectedRows}
                  exportListFileFunction={handleExportFile}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={`${intl.formatMessage({
                  id: 'action_center.requests.title'
                })} (${requestsCount})`}
                key={2}
              >
                <BRSearchableTable
                  columns={requestsColumns}
                  pageLimit={ACTION_CENTER_PAGE_SIZE}
                  listFunction={getRequestsTableData}
                  rowClassName={(record) =>
                    record?._id === selectedRowData?._id ? 'selected-row' : ''
                  }
                  searchInputPlaceholder={intl.formatMessage({
                    id: 'action_center.order_actions.search_for_tracking_number'
                  })}
                  showSearch
                  handleTextInput={handleSearchValueChange}
                  shareMethods={acceptMethods}
                  onRowClick={handleRowClick}
                  selectedRows={setSelectedRows}
                  withOutCheckBoxes
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        ) : (
          <BREmptyState />
        )}
      </LoadingWrapper>
    </div>
  );
};

export default injectIntl(OrderActions);
