import { get as getValue } from 'lodash';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const formatOptions = (keyFieldName, labelFieldName, data) => {
  const labelFields = labelFieldName.split(',');
  if (!Array.isArray(data)) {
    const arr = Array.from(Object.keys(data), (k) => data[k]);
    data = arr;
  }
  return data.map((item) => {
    const labelValue = labelFields.reduce((acc, value) => {
      if (!value) return acc;
      return `${acc} ${getValue(item, value, '')}`;
    }, '');
    return { value: item[keyFieldName], label: labelValue };
  });
};

export const statusTypes = [
  { label: 'Valid', value: 'Valid' },
  { label: 'Invalid', value: 'Invalid' },
  { label: 'Deleted', value: 'Deleted' }
];

export const INVALIDATION_REASONS = {
  DUPLICATE: 'Duplicate Account',
  UNPERMITTED: 'Unpermitted Items',
  SERVER_NOT_AVAILABLE: 'Service Not Available',
  TESTING_ACCOUNT: 'Testing Account',
  COMPETITION: 'Competition',
  LOST: 'Lost',
  NOT_INTERESTED: 'Not Interested',
  DEAD: 'Dead'
};

export const InvalidationReasons = [
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.duplicate'
    }),
    value: INVALIDATION_REASONS.DUPLICATE
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.unpermitted'
    }),
    value: INVALIDATION_REASONS.UNPERMITTED
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.service_not_available'
    }),
    value: INVALIDATION_REASONS.SERVER_NOT_AVAILABLE
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.testing_account'
    }),
    value: INVALIDATION_REASONS.TESTING_ACCOUNT
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.competition'
    }),
    value: INVALIDATION_REASONS.COMPETITION
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.lost'
    }),
    value: INVALIDATION_REASONS.LOST
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.not_interested'
    }),
    value: INVALIDATION_REASONS.NOT_INTERESTED
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.reasons.dead'
    }),
    value: INVALIDATION_REASONS.DEAD
  }
];

export const SERVICES_REASONS_VALUES = {
  FULFILLMENT: 'Fulfillment',
  INTERNATIONAL: 'International',
  SAME_DAY_DELIVERY: 'Same Day Delivery',
  BULKY: 'Bulky',
  C2C: 'C2C',
  BANKS_AND_DOCUMENTS: 'Banks/Documents',
  OTHER: 'OTHER'
};

export const ServicesReasons = [
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.fulfillment'
    }),
    value: SERVICES_REASONS_VALUES.FULFILLMENT
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.international'
    }),
    value: SERVICES_REASONS_VALUES.INTERNATIONAL
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.same_day_delivery'
    }),
    value: SERVICES_REASONS_VALUES.SAME_DAY_DELIVERY
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.bulky'
    }),
    value: SERVICES_REASONS_VALUES.BULKY
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.c_to_c'
    }),
    value: SERVICES_REASONS_VALUES.C2C
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.banks_documents'
    }),
    value: SERVICES_REASONS_VALUES.BANKS_AND_DOCUMENTS
  },
  {
    label: fmt({
      id: 'business_details.business_invalidation.services_reasons.other'
    }),
    value: SERVICES_REASONS_VALUES.OTHER
  }
];

export const SALES_CHANNEL_OPTIONS = [
  { value: 'FACEBOOK_SELLER', label: 'Facebook' },
  { value: 'INSTAGRAM_SELLER', label: 'Instagram' },
  { value: 'WEBSITE_SELLER', label: 'Website' },
  { value: 'MARKETPLACE_SELLER', label: 'Marketplace' }
];

export const INDUSTRY_OPTIONS = [
  { value: 'Books, Arts and Media', label: 'Books, Arts and Media' },
  { value: 'Electronics', label: 'Electronics' },
  {
    value: 'Cosmetics and personal care',
    label: 'Cosmetics and personal care'
  },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Furniture and appliances', label: 'Furniture and appliances' },
  { value: 'Healthcare supplements', label: 'Healthcare supplements' },
  { value: 'Home and living', label: 'Home and living' },
  { value: 'Gifts', label: 'Gifts' },
  { value: 'Jewelry and accessories', label: 'Jewelry and accessories' },
  { value: 'Leather', label: 'Leather' },
  { value: 'Mothers and babies', label: 'Mothers and babies' },
  { value: 'Medical supplies', label: 'Medical supplies' },
  {
    value: 'Office equipment and supplies',
    label: 'Office equipment and supplies'
  },
  { value: 'Pet supplies', label: 'Pet supplies' },
  { value: 'Sports wear and equipment', label: 'Sports wear and equipment' },
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Food', label: 'Food' },
  { value: 'Shoes', label: 'Shoes' }
];

export const BUSINESS_CATEGORIES = [
  { value: 'C2C', label: 'C2C' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Platinum', label: 'Platinum' },
  { value: 'VIP', label: 'VIP' }
];

export const BUSINESS_TYPES_OPTIONS = [
  { value: 'E_COMMERCE', label: 'E-commerce' },
  { value: 'BULKY', label: 'Bulky' },
  { value: 'B2B', label: 'B2B' },
  { value: 'DONATION', label: 'Donation' },
  { value: 'DSP', label: 'DSP' },
  { value: 'VDO', label: 'VDO' }
];

export const BUSINESS_PAYMENT_OPTIONS = [
  { value: 'SOHO', label: 'E-SOHO' },
  { value: 'PRO', label: 'PRO' }
];

export const BUSINESS_TIERS = [
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Red', label: 'Red' }
];

export const PAYMENT_TYPES = [
  { value: 'Prepaid', label: 'Prepaid' },
  { value: 'Postpaid', label: 'Postpaid' }
];

export const logTableColumn = [
  {
    title: 'Log Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Taken By',
    dataIndex: 'takenBy',
    key: 'takenBy'
  }
];

export const logTypeMapping = {
  STATUS_CHANGE_LOG: 'Status Change',
  PRICING_PLAN_CHANGE_LOG: 'Pricing Plan Change',
  PRICING_FLAGS_CHANGE_LOG: 'Pricing Flags Change',
  FULFILLMENT_PRICING_PLAN_CHANGE_LOG: 'Fulfillment Pricing Plan Change',
  BULK_UPDATE_LOG: 'Bulk Update'
};
export const MAP_PERMISSIONS_CODES = {
  100: {
    value: 'Default'
  },
  101: {
    value: 'Integrations'
  },

  102: {
    value: 'Business'
  },

  103: {
    value: 'Business , Integrations'
  },

  104: {
    value: 'Wallet'
  },

  105: {
    value: 'Wallet , Integrations'
  },

  106: {
    value: 'Wallet , Business'
  },

  107: {
    value: 'Wallet , Integrations , Business'
  },
  108: {
    value: 'Finance'
  },

  109: {
    value: 'Finance , Integrations'
  },
  110: {
    value: 'Finance , Business'
  },

  111: {
    value: 'Finance , Wallet'
  },

  112: {
    value: 'Finance , Wallet , Integrations'
  },

  113: {
    value: 'Finance , Integrations , Business'
  },

  114: {
    value: 'Finance , Wallet , Business'
  },

  115: { value: 'Full Access' },
  200: {
    value: 'Editor Default'
  },
  201: {
    value: 'Editor Integrations'
  },

  202: {
    value: 'Editor Business'
  },

  203: {
    value: 'Editor Business , Integrations'
  },

  204: {
    value: 'Editor Wallet'
  },

  205: {
    value: 'Editor Wallet , Integrations'
  },

  206: {
    value: 'Editor Wallet , Business'
  },

  207: {
    value: 'Editor Wallet , Integrations , Business'
  },
  208: {
    value: 'Editor Finance'
  },

  209: {
    value: 'Editor Finance , Integrations'
  },
  210: {
    value: 'Editor Finance , Business'
  },

  211: {
    value: 'Editor Finance , Wallet'
  },

  212: {
    value: 'Editor Finance , Wallet , Integrations'
  },

  213: {
    value: 'Editor Finance , Integrations , Business'
  },

  214: {
    value: 'Editor Finance , Wallet , Business'
  },

  215: { value: 'Editor Access' }
};

export const LOG_TYPE_MAPPING = {
  STATUS_CHANGE_LOG: 'Status Change',
  PRICING_PLAN_CHANGE_LOG: 'Pricing Plan Change',
  EXTRA_FLYERS_QUOTA_CHANGE_LOG: 'Extra Flyers Quota Change',
  PRICING_FLAGS_CHANGE_LOG: 'Pricing Flags Change',
  FULFILLMENT_PRICING_PLAN_CHANGE_LOG: 'Fulfillment Pricing Plan Change',
  BULK_UPDATE_LOG: 'Bulk Update Change',
  RECURRING_PICKUPS_CHANGE_LOG_TYPE: 'Recurring Pickups Change',
  PAYMENT_FREQUENCY_CHANGE_LOG_TYPE: 'Cash-out Frequency Change'
};

export const STATE_COLOR = {
  10: 'yellow',
  15: 'yellow',
  20: 'green',
  30: 'red'
};
export const INTEGRATION_STATES_COLOR = {
  ENABLED: 'green',
  DISABLED: 'red'
};
export const BUSINESS_TYPES = { B2B: 'B2B' };

export const PAYMENT_FREQUENCY_API = {
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  NEXT_DAY: 'Next Day'
};

export const PAYMENT_FREQUENCY = {
  WEEKLY_SUN: 'Weekly (Sun)',
  WEEKLY_MON: 'Weekly (Mon)',
  WEEKLY_WED: 'Weekly (Wed)',
  DAILY: 'Daily (Sun to Thu)',
  TWO_DAYS: '2 days (Mon, Wed)',
  THREE_DAYS: '3 Days (Sun, Tue, Thu)',
  NEXT_DAY: 'Next Day'
};

export const PAYMENT_SCHEDULE = {
  DAILY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
  WEEKLY_SUN: ['Sunday'],
  WEEKLY_MON: ['Monday'],
  WEEKLY_WED: ['Wednesday'],
  TWO_DAYS: ['Monday', 'Wednesday'],
  THREE_DAYS: ['Sunday', 'Tuesday', 'Thursday']
};

export const NO_OF_ATTEMPTS = {
  ONE_ATTEMPT: '1',
  TWO_ATTEMPTS: '2',
  THREE_ATTEMPTS: '3',
  FOUR_ATTEMPTS: '4',
  FIVE_ATTEMPTS: '5'
};

export const JOB_TITLES = {
  OWNER: fmt({ id: 'business_details.team_members.job_titles.owner' }),
  ADMINISTRATION: fmt({
    id: 'business_details.team_members.job_titles.administration'
  }),
  SALES: fmt({ id: 'business_details.team_members.job_titles.sales' }),
  CUSTOMER_SERVICE_ACCOUNT_MANAGER: fmt({
    id: 'business_details.team_members.job_titles.customer_service'
  }),
  FINANCE_AND_ACCOUNTING: fmt({
    id: 'business_details.team_members.job_titles.finance_and_accounting'
  }),
  OPERATIONS_MANAGER: fmt({
    id: 'business_details.team_members.job_titles.operations_manager'
  }),
  OPERATIONS_SUPERVISOR: fmt({
    id: 'business_details.team_members.job_titles.operations_supervisor'
  }),
  OPERATIONS_CLERK: fmt({
    id: 'business_details.team_members.job_titles.operations_clerk'
  }),
  MARKETING_AND_ADVERTISING: fmt({
    id: 'business_details.team_members.job_titles.marketing_and_advertising'
  }),
  LEGAL: fmt({
    id: 'business_details.team_members.job_titles.legal'
  }),
  PRODUCT_MANAGER: fmt({
    id: 'business_details.team_members.job_titles.product_manager'
  }),
  PROCURMENT: fmt({
    id: 'business_details.team_members.job_titles.procurement'
  })
};

export const OTP = 'OTP';

export const PICKUP_PROOF_TYPES = [
  {
    label: fmt({ id: 'business_details.operations_settings.signature' }),
    value: 'Signature'
  },
  {
    label: fmt({ id: 'business_details.operations_settings.otp' }),
    value: 'OTP'
  }
];

export const DEFAULT_CHANGE = 'DEFAULT_CHANGE';

export const API_SUPER_ADMIN_ACCESS = 'API_SUPER_ADMIN_ACCESS';

export const API_KEYS_SCOPES = {
  API_READ_ACCESS: fmt({
    id: 'business_details.api_integration.permissions_scopes.read'
  }),
  API_READ_WRITE_ACCESS: fmt({
    id: 'business_details.api_integration.permissions_scopes.read_write'
  }),
  API_SUPER_ADMIN_ACCESS: fmt({
    id: 'business_details.api_integration.permissions_scopes.full_access'
  })
};

export const API_KEYS_SCOPES_OPTIONS = [
  { value: 'API_READ_ACCESS', label: API_KEYS_SCOPES.API_READ_ACCESS },
  {
    value: 'API_READ_WRITE_ACCESS',
    label: API_KEYS_SCOPES.API_READ_WRITE_ACCESS
  },
  {
    value: 'API_SUPER_ADMIN_ACCESS',
    label: API_KEYS_SCOPES.API_SUPER_ADMIN_ACCESS
  }
];

export const BUSINESS_LEGAL_DOCUMENTS_FLAGS = [
  'isFreelanceCertificateIdUploaded',
  'isCommercialRegisterIdUploaded',
  'isBusinessTaxIdUploaded',
  'isBusinessNationalFrontIDUploaded',
  'isBusinessNationalBackIDUploaded',
  'isInternationalContractUploaded'
];

export const BUSINESS_DATE_RANGE_FILTERS_KEYS = ['registeredAt', 'activeAt'];

export const BUSINESS_MANAGERS_UPDATE_KEYS = {
  SALES_MANAGER: 'salesManagerId',
  ACCOUNT_MANAGER: 'accountManagerId'
};

export const BUSINESS_STATUS = {
  VALID: 'Valid',
  INVALID: 'Invalid'
};

export const BUSINESSES_COLUMNS = [
  {
    title: fmt({ id: 'businesses.table_columns.business_name' }),
    dataIndex: 'businessName',
    width: 200
  },
  {
    title: fmt({ id: 'businesses.table_columns.address' }),
    dataIndex: 'address',
    width: 500
  },
  {
    title: fmt({ id: 'businesses.table_columns.registered_at' }),
    dataIndex: 'registeredAt',
    width: 130
  },
  {
    title: fmt({ id: 'businesses.table_columns.status' }),
    dataIndex: 'status',
    width: 80
  },
  {
    title: fmt({ id: 'businesses.table_columns.account_manager' }),
    dataIndex: 'accountManger',
    width: 160
  },
  {
    title: fmt({ id: 'businesses.table_columns.sales_manager' }),
    dataIndex: 'salesManager',
    width: 160
  },
  {
    dataIndex: 'Actions',
    width: 80
  }
];

export const INTERNATIONAL_SERVICE_DOCUMENT_KEYS = [
  'pickup_location',
  'international_pricing_plan',
  'international_bank_details',
  'identity_number',
  'birth_date',
  'id_copy',
  'commercial_registration',
  'tax_id'
];

export const INTERNATIONAL_CONTACT_DOCUMENT_NAMES = [
  'international_contract',
  'international_activation_doc_2',
  'international_activation_doc_3',
  'international_activation_doc_4'
];

export const MAX_NUMBER_OF_UPLOADABLE_FILES = 4;

export const REG_SOURCE = {
  SLLR: 'Sllr',
  DASHBOARD: 'Bosta',
  BUSINESS_APP: 'Bosta'
};
