import { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Select, Dropdown, Menu, Switch, Form } from 'antd';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { getZonesAndDistricts } from 'services/zoning';
import { fetchCities } from 'services/cities';
import { openModal } from 'utils/modal';
import { HubsContext } from 'contexts/hub.context';
import { AVAILABILITY_FLAGS, COVERAGE_BY_OPTIONS } from 'constants/zoning';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import ZoningTable from 'components/Zoning/ZoningTable/ZoningTable';
import CreateEditZoneModal from './CreateEditZoneModal/CreateEditZoneModal';
import CreateEditDistrictModal from './CreateEditDistrictModal/CreateEditDistrictModal';
import ZoningFilters from './ZoningFilters/ZoningFilters';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as AddZoneIcon } from 'assets/imgRevamp/add-zone-icon.svg';
import { ReactComponent as AddDistrictIcon } from 'assets/imgRevamp/add-district-icon.svg';

import './Zoning.less';

const Zoning = ({ intl }) => {
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [zoningData, setZoningData] = useState([]);
  const [filteredZoningData, setFilteredZoningData] = useState([]);
  const [zones, setZones] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [filteredDistrictsCount, setFilteredDistrictsCount] = useState(0);
  const [zonesExpanded, setIsZonesExpanded] = useState(true);
  const [currentFilterValues, setCurrentFilterValues] = useState({});

  const { allHubs } = useContext(HubsContext);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (selectedCityId) {
      getCityZonesAndDistricts();
    }
  }, [selectedCityId]);

  useEffect(() => {}, [zonesExpanded]);

  const getCities = async () => {
    try {
      const cities = await fetchCities();
      setCities(cities);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnExpandChange = (checked) => {
    formatZoningData(
      filteredZoningData.length ? filteredZoningData : zoningData,
      checked
    );
    setIsZonesExpanded(checked);
  };

  const getCityZonesAndDistricts = async () => {
    setIsLoading(true);
    try {
      const data = await getZonesAndDistricts(selectedCityId);
      setZones(data);
      formatZoningData(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getCityName = (cityId) =>
    cities.find((city) => city._id === cityId).name;

  const formatZoningData = (
    zonesAndDistricts,
    isZonesExpanded = zonesExpanded
  ) => {
    const cityZones = {
      key: selectedCityId,
      isCity: true,
      city: (
        <span className="br-zoning-table__city-name body-medium">
          {`${getCityName(selectedCityId)} (${zonesAndDistricts.length})`}
        </span>
      ),
      children: formatZonesAndDistricts(zonesAndDistricts, isZonesExpanded)
    };

    setZoningData([cityZones]);
  };

  const formatZonesAndDistricts = (zonesData, isZonesExpanded) => {
    let districtsCount = 0;

    const zonesAndDistricts = zonesData.map((zone) => {
      zone.isZone = true;
      zone.name = zone.zoneName;
      zone.nameAr = zone.zoneNameAr;

      districtsCount += zone.districts.length;

      return [zone, ...(isZonesExpanded ? zone.districts : [])];
    });

    setFilteredDistrictsCount(districtsCount);
    return zonesAndDistricts.flat();
  };

  const formatAppliedFilters = ({ filterValues, target }) => {
    const coverageBy = filterValues.coverageBy;
    const newValues = Object.assign({}, filterValues);

    delete newValues.coverageBy;

    const filters = Object.keys(newValues);

    if (!coverageBy) {
      return filters;
    } else {
      return target === coverageBy
        ? filters
        : filters.filter((filter) => !AVAILABILITY_FLAGS.includes(filter));
    }
  };

  const handleFilter = ({
    values = currentFilterValues,
    searchText = currentSearchText
  }) => {
    setCurrentFilterValues(values);

    const zonesFilters = formatAppliedFilters({
      filterValues: values,
      target: COVERAGE_BY_OPTIONS.ZONE
    });
    const districtsFilters = formatAppliedFilters({
      filterValues: values,
      target: COVERAGE_BY_OPTIONS.DISTRICT
    });
    const relativeZonesIds = [];

    const zonesWithFilteredDistricts = zones.map((zone) => {
      const filteredDistricts = districtsFilters.length
        ? zone.districts.filter((district) => {
            const isMatchingFilters = districtsFilters.every((filter) =>
              isEqual(get(district, filter), values[filter])
            );
            if (
              searchText &&
              isMatchingFilters &&
              [district.name, district.nameAr].includes(searchText)
            ) {
              relativeZonesIds.push(zone.zoneId);
              return true;
            } else if (isMatchingFilters && !searchText) {
              relativeZonesIds.push(zone.zoneId);
              return true;
            }
          })
        : zone.districts;

      return { ...zone, districts: filteredDistricts };
    });

    const filteredZones = zonesWithFilteredDistricts.filter((zone) => {
      const isMatchingFilters = zonesFilters.every((filter) =>
        isEqual(get(zone, filter), values[filter])
      );
      const isRelativeToDistrict = relativeZonesIds.includes(zone.zoneId);

      return searchText
        ? (isMatchingFilters &&
            [zone.zoneName, zone.zoneNameAr].includes(searchText)) ||
            isRelativeToDistrict
        : isMatchingFilters || isRelativeToDistrict;
    });

    setFilteredZoningData(filteredZones);
    formatZoningData(filteredZones);
  };

  const handleFilterClear = () => {
    setFilteredZoningData([]);
    formatZoningData(zones);
  };

  const handleOpenZoneModal = () => {
    openModal(CreateEditZoneModal, {
      cities,
      updateZoningData: getCityZonesAndDistricts,
      selectedCityId,
      allHubs,
      zoningData
    });
  };

  const handleOpenDistrictModal = () => {
    openModal(CreateEditDistrictModal, {
      cities,
      updateZoningData: getCityZonesAndDistricts,
      selectedCityId,
      allHubs,
      zoningData
    });
  };

  const addNewMenu = () => (
    <Menu>
      <Menu.Item onClick={handleOpenZoneModal}>
        <AddZoneIcon />
        {intl.formatMessage({ id: 'zoning.create_edit_zone_modal.add_zone' })}
      </Menu.Item>

      <Menu.Item onClick={handleOpenDistrictModal}>
        <AddDistrictIcon />
        {intl.formatMessage({
          id: 'zoning.create_edit_district_modal.add_district'
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <Container
      header={<BRHeader title={intl.formatMessage({ id: 'sidebar.zoning' })} />}
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-zoning__container">
            <div className="br-zoning__header">
              <div className="br-zoning__header-title display-xs">
                {intl.formatMessage({ id: 'zoning.title' })}
              </div>
              <div className="caption-lg">
                {intl.formatMessage({ id: 'zoning.subtitle' })}
              </div>
            </div>
            <div className="display-flex justify-space-between align-center">
              <div className="br-zoning__hub-selector">
                <span className="body-medium">
                  {intl.formatMessage({ id: 'zoning.city' })}
                </span>
                <Select
                  showSearch
                  onChange={setSelectedCityId}
                  fieldNames={{ label: 'name', value: '_id' }}
                  optionFilterProp="name"
                  options={cities}
                  placeholder={intl.formatMessage({
                    id: 'zoning.hub_placeholder'
                  })}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                />
              </div>
              <Dropdown
                overlay={addNewMenu}
                trigger="click"
                overlayClassName="br-zoning-add-new__dropdown"
                placement="bottomRight"
                disabled={!selectedCityId}
              >
                <BRButton
                  type="primary"
                  className="br-zoning__add-button"
                  label={intl.formatMessage({ id: 'zoning.add_new' })}
                  prefixIcon={<PlusIcon />}
                />
              </Dropdown>
            </div>
            <div className="br-zoning__zone-expander">
              <Form.Item
                label={intl.formatMessage({ id: 'zoning.show_districts' })}
              >
                <Switch
                  defaultChecked
                  onChange={handleOnExpandChange}
                  disabled={!selectedCityId}
                />
              </Form.Item>
            </div>
            <div className="br-zoning__filters">
              <ZoningFilters
                allHubs={allHubs}
                handleFilter={handleFilter}
                handleFilterClear={handleFilterClear}
                selectedCityId={selectedCityId}
              />
            </div>
            <ZoningTable
              zoningData={zoningData}
              selectedCityId={selectedCityId}
              cities={cities}
              filterZoningData={handleFilter}
              filteredDistrictsCount={filteredDistrictsCount}
              allHubs={allHubs}
              currentSearchText={currentSearchText}
              setCurrentSearchText={setCurrentSearchText}
              handleFilterClear={handleFilterClear}
              updateZoningData={getCityZonesAndDistricts}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(Zoning);
