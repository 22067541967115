import { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { isEqualRule, requiredRule } from 'utils/forms';
import { changePassword } from 'services/Security';

import SignPage from 'containers/SignPage/index';
import BRButton from 'components/BRButton/BRButton';
import BRPasswordInput from 'components/BRPasswordInput/BRPasswordInput';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as BostaIcon } from 'assets/imgRevamp/BostaLogo.svg';

import './ChangePassword.less';

const ChangePassword = ({ intl, location: { state = {} }, history }) => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!state.email) {
      return history.push('/');
    }

    setEmail(state.email);
    history.replace({ state: {} });
  }, []);

  const handleChangePassword = async (values) => {
    delete values.confirmNewPassword;

    setIsLoading(true);
    try {
      const payload = {
        ...values,
        email
      };
      await changePassword(payload);
      notify(
        intl.formatMessage({
          id: 'change_password_form.validation_messages.password_changed_successfully'
        }),
        'success'
      );
      history.push('/');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <SignPage
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-change-password__container">
            <div className="br-change-password__card">
              <BostaIcon />
              <div className="br-change-password__title dispaly-md">
                {intl.formatMessage({ id: 'change_password_form.title' })}
              </div>
              <Form ref={formRef} onFinish={handleChangePassword}>
                <Form.Item
                  name="oldPassword"
                  label={intl.formatMessage({
                    id: 'change_password_form.labels.old_password_label'
                  })}
                  rules={[
                    requiredRule(
                      intl.formatMessage(
                        {
                          id: 'form.required_custom'
                        },
                        {
                          label: intl.formatMessage({
                            id: 'change_password_form.labels.old_password_label'
                          })
                        }
                      )
                    )
                  ]}
                >
                  <Input.Password
                    placeholder={intl.formatMessage({
                      id: 'change_password_form.placeholders.old_password_placeholder'
                    })}
                  />
                </Form.Item>
                <BRPasswordInput
                  formRef={formRef}
                  name="newPassword"
                  label={intl.formatMessage({
                    id: 'change_password_form.labels.new_password_label'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'change_password_form.placeholders.new_password_placeholder'
                  })}
                  value={newPasswordValue}
                  setValue={setNewPasswordValue}
                />
                <Form.Item
                  name="confirmNewPassword"
                  label={intl.formatMessage({
                    id: 'change_password_form.labels.new_password_confirm_label'
                  })}
                  rules={[
                    isEqualRule({
                      message: intl.formatMessage({
                        id: 'change_password_form.validation_messages.confirm_password_error'
                      }),
                      value: newPasswordValue
                    })
                  ]}
                >
                  <Input.Password
                    placeholder={intl.formatMessage({
                      id: 'change_password_form.placeholders.new_password_confirm_placeholder'
                    })}
                  />
                </Form.Item>
                <BRButton
                  type="primary"
                  htmlType="submit"
                  className="br-change-password__submit-btn"
                  label={intl.formatMessage({
                    id: 'change_password_form.set_new_password'
                  })}
                />
              </Form>
            </div>
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default withRouter(injectIntl(ChangePassword));
