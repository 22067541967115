import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Menu, Dropdown } from 'antd';

import { deleteBulkySeal, printBulkySeal, searchSeal } from 'services/hubs';
import { getCurrentUserHubInfo } from 'utils/hubs';
import {
  HUB_TRANSFER_DISPATCHING_TYPES,
  PENDING,
  SEALS,
  SEAL_NUMBER,
  SEAL_SCAN_TYPE,
  SEAL_STATE,
  TRACKING_NUMBER
} from 'constants/hubs';
import { openModal } from 'utils/modal';
import { showBill } from 'utils';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import DateTime from 'containers/Hub/SealsLogs/components/DateTime/DateTime';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

const SealsList = ({ intl, viewSeal, renderState, setIsLoading }) => {
  const [selectedSearchOption, setSelectedSearchOption] = useState(SEAL_NUMBER);

  const refreshTable = useRef();

  const userHubInfo = getCurrentUserHubInfo();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const columns = [
    {
      dataIndex: 'sealNumber',
      title: intl.formatMessage({
        id: 'hubs.seals_logs.headers.seal_code'
      })
    },
    {
      dataIndex: 'noOfPackages',
      title: intl.formatMessage({
        id: 'hubs.seals_logs.headers.no_of_packages'
      })
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'hubs.seals_logs.headers.seal_state'
      })
    },
    {
      dataIndex: 'closeTime',
      title: intl.formatMessage({
        id: 'hubs.seals_logs.headers.closed_at'
      })
    },
    {
      dataIndex: 'actions'
    }
  ];

  const searchOptions = [
    {
      label: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.seal_code'
      }),
      value: SEAL_NUMBER
    },
    {
      label: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.tracking_number'
      }),
      value: TRACKING_NUMBER
    }
  ];

  const handleDeleteSeal = async ({ sealNumber } = {}) => {
    setIsLoading(true);
    try {
      await deleteBulkySeal(sealNumber);
      notify(
        intl.formatMessage({
          id: 'hubs.seals_logs.delete_seal_modal.success_message'
        }),
        'success'
      );
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDeleteBulkySealClick = ({ sealNumber }) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'hubs.seals_logs.delete_seal_modal.title'
      }),
      content: intl.formatMessage({
        id: 'hubs.seals_logs.delete_seal_modal.content'
      }),
      modalAction: () => handleDeleteSeal({ sealNumber })
    });
  };

  const handlePrintBulkySeal = async (sealNumbers) => {
    setIsLoading(true);
    try {
      const { printed } = await printBulkySeal({ sealNumbers });
      showBill(printed);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const menuItems = (seal) => {
    const { sealNumber, type, state, trackingNumbers = [] } = seal;

    return (
      <Menu
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
        }}
      >
        <Menu.Item onClick={() => viewSeal(seal)} key="1">
          {intl.formatMessage({
            id: 'common.view'
          })}
        </Menu.Item>
        <Menu.Item
          disabled={type !== SEAL_SCAN_TYPE.BULKY || state !== PENDING}
          onClick={() => handlePrintBulkySeal(sealNumber)}
          key="2"
        >
          {intl.formatMessage({
            id: 'hubs.seals_logs.print_seal'
          })}
        </Menu.Item>
        <Menu.Item
          key="3"
          className="br-delete-action"
          disabled={
            type !== SEAL_SCAN_TYPE.BULKY ||
            state !== PENDING ||
            !!trackingNumbers.length
          }
          onClick={() => handleDeleteBulkySealClick({ sealNumber })}
        >
          {intl.formatMessage({ id: 'common.delete' })}
        </Menu.Item>
      </Menu>
    );
  };

  const actionsDropdown = (seal) => {
    return (
      <Dropdown
        overlay={menuItems(seal)}
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement
        }
      >
        <BRButton
          type="table-actions"
          className="button-sm"
          suffixIcon={<Actions />}
        />
      </Dropdown>
    );
  };

  const formatSealsData = (data) =>
    data.map((seal) => {
      const {
        _id,
        sealNumber,
        state,
        trackingNumbers = [],
        closeTime,
        type
      } = seal;

      return {
        _id,
        sealNumber,
        noOfPackages: trackingNumbers.length,
        state: renderState(state),
        closeTime: <DateTime dateTime={closeTime} />,
        viewSeal: (
          <div className="br-seal-logs__tabl-actions">
            <BRButton
              label={intl.formatMessage({
                id: 'common.view'
              })}
              onClick={() => viewSeal(seal)}
            />
            {type === SEAL_SCAN_TYPE.BULKY && (
              <BRButton
                type="destructive-primary"
                label={intl.formatMessage({ id: 'common.delete' })}
              />
            )}
          </div>
        ),
        actions: actionsDropdown(seal)
      };
    });

  const getTableData = async ({ page, limit, searchInputText }) => {
    try {
      const payload = {
        pageId: page,
        pageLimit: limit,
        searchType: SEALS,
        ...(selectedSearchOption !== TRACKING_NUMBER && {
          type: HUB_TRANSFER_DISPATCHING_TYPES.join(',')
        }),
        [selectedSearchOption]: searchInputText?.trim(),
        sourceHubId: userHubInfo?._id
      };

      const {
        data: { results = [], totalSeals = 0 }
      } = await searchSeal(payload);

      return {
        list: formatSealsData(results),
        total: totalSeals
      };
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <BRTable
      title={intl.formatMessage({
        id: 'hubs.seals_logs.table_title'
      })}
      columns={columns}
      listFunction={getTableData}
      pageLimit={50}
      showSearch
      showExport={false}
      showFilter={false}
      searchPlaceholder={intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.search_placeholder'
      })}
      searchOptions={searchOptions}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      shareMethods={acceptMethods}
    />
  );
};

export default injectIntl(SealsList);
