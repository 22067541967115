import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getInventorySerialsStatistics = (payload) => {
  return http.get('/fulfillment-v2/serials/statistics', payload);
};

export const getInventorySerials = (payload) => {
  return http.get('/fulfillment-v2/serials/list', payload);
};

export const exportInventorySerials = (payload) => {
  return http.get('/fulfillment-v2/serials/export', payload, downloadHeader);
};

export const getSerialLocation = (id) => {
  return http.get(`/fulfillment-v2/serials/${id}/location`);
};

export const deleteSerial = (serialNumber) => {
  return http.delete(`/fulfillment-v2/serials?serialNumber=${serialNumber}`);
};
