import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { saveAs } from 'file-saver';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import { notify } from 'components/Notify/Notify';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import UploadAttachment from 'components/Stars/components/BonusesAndDeductions/components/UploadAttachment/UploadAttachment';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import {
  getBulkPromotionsUploadUrl,
  uploadBulkPromotions
} from 'services/promotions';
import { uploadSheetToGoogleBucket } from 'services/wallet';
import { openModal } from 'utils/modal';
import { useCancellablePromise } from 'utils/promise';
import { convertExcelToJSON, validatePromotions } from 'utils/promotions';

import { ReactComponent as UploadImage } from 'assets/imgRevamp/upload-wallet-template-icon.svg';
import { ReactComponent as UploadButtonIcon } from 'assets/imgRevamp/wallet-button-icon.svg';
import { ReactComponent as GreenInfoIcon } from 'assets/imgRevamp/green-info.svg';
import { ReactComponent as SuccessIcon } from 'assets/imgRevamp/wallet-success-icon.svg';
import { ReactComponent as ExcelIcon } from 'assets/imgRevamp/excel-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/imgRevamp/warning_icon.svg';
import BulkPromotionsTemplate from 'assets/BulkPromotionTemplate.xlsx';

import './BulkPromotions.less';

const BulkPromotions = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState({});
  const [count, setCount] = useState();
  const [errorsArray, setErrorsArray] = useState([]);
  const [hasUploaded, setHasUploaded] = useState(false);

  const tableRef = useRef();

  const { cancellablePromise } = useCancellablePromise();

  const handleSheetDownloadClick = () => {
    saveAs(BulkPromotionsTemplate, 'BulkPromotionTemplate.xlsx');
  };

  const handleUpload = async (file) => {
    setIsLoading(true);
    setFile({ name: file.name, size: file.size / 1024 / 1024 });
    try {
      const promotions = await cancellablePromise(convertExcelToJSON(file));
      setCount(promotions.length);
      const { isValid, errors } = await cancellablePromise(
        validatePromotions(promotions)
      );
      if (!isValid) {
        setErrorsArray(errors);
      } else {
        openModal(BRConfirmationModal, {
          onConfirm: async () => {
            let sheetName = file.name.slice(0, -5);
            const isoDate = new Date().toISOString();
            sheetName = sheetName + ` ${isoDate}.xlsx`;
            try {
              const { url } = await getBulkPromotionsUploadUrl({
                sheetName: sheetName
              });
              await uploadSheetToGoogleBucket(url, file);
              await uploadBulkPromotions({ sheetName: sheetName });
              setHasUploaded(true);
            } catch (error) {
              notify(error.message);
            }
          },
          title: intl.formatMessage({
            id: 'promotions.bulk.header'
          }),
          message: intl.formatMessage(
            {
              id: 'promotions.bulk.confirmation_message'
            },
            {
              count: promotions.length
            }
          ),
          confirmButtonClassName: 'br-bulk-promotions__modal__confirm-btn'
        });
      }
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'orders.mass_upload.error_table.columns.field_names'
      }),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: intl.formatMessage({
        id: 'orders.mass_upload.error_table.columns.issue'
      }),
      dataIndex: 'issue',
      key: 'issue'
    },
    {
      title: intl.formatMessage({
        id: 'orders.mass_upload.error_table.columns.in_rows'
      }),
      dataIndex: 'rows',
      key: 'rows'
    }
  ];

  const customContent = () => (
    <div className="br-bulk-promotions__content">
      <p className="ant-upload-drag-icon">
        <UploadImage />
      </p>
      <p className="br-bulk-promotions__content-text">
        {intl.formatMessage(
          {
            id: 'wallet.balance_adjustment.upload_hint'
          },
          {
            clickToUpload: (
              <span>
                {intl.formatMessage({
                  id: 'wallet.balance_adjustment.click_to_upload'
                })}
              </span>
            )
          }
        )}
      </p>
      <p className="br-bulk-promotions__content-hint">
        {intl.formatMessage({
          id: 'wallet.balance_adjustment.support_text'
        })}
      </p>
      <Button type="primary">
        <UploadButtonIcon />
        {intl.formatMessage({
          id: 'orders.mass_upload.upload_dialog.upload_file'
        })}
      </Button>
    </div>
  );

  const reuploadContent = () => (
    <Button type="primary">
      {intl.formatMessage({
        id: 'orders.mass_upload.error_table.reupload'
      })}
    </Button>
  );

  const acceptMethods = (refreshMethod) => {
    tableRef.current = refreshMethod;
  };

  useEffect(() => {
    if (errorsArray?.length) {
      tableRef.current();
    }
  }, [errorsArray]);

  return (
    <Container
      className="br-wallet-container"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'promotions.bulk.header'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          {hasUploaded ? (
            <div className="br-balance-adjustment-success">
              <SuccessIcon className="sucess-icon" />
              <span className="sucess-title">
                {intl.formatMessage({
                  id: 'wallet.business_configuration.success_screen.title'
                })}
              </span>
              <span className="sucess-subtitle">
                {intl.formatMessage({
                  id: 'promotions.bulk.success_subtitle'
                })}
              </span>
              <div className="br-balance-adjustment-sucess-info">
                <ExcelIcon />
                <div>
                  <span className="file-name-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.business_configuration.success_screen.file_name'
                      },
                      {
                        fileName: <span>{file.name}</span>
                      }
                    )}
                  </span>
                  <span className="total-business-text">
                    {intl.formatMessage(
                      {
                        id: 'promotions.bulk.records_number'
                      },
                      {
                        count
                      }
                    )}
                  </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setHasUploaded(false);
                }}
                disabled={isLoading}
              >
                {intl.formatMessage({
                  id: 'promotions.bulk.upload_more'
                })}
              </Button>
            </div>
          ) : errorsArray?.length ? (
            <>
              <div className="br-bulk-promotions__errors-box">
                <div className="display-flex justify-space-between">
                  <div className="display-flex align-center">
                    <span>
                      <ExcelIcon />
                    </span>
                    <div className="br-bulk-promotions__errors-box__file-info">
                      <span>
                        {intl.formatMessage({
                          id: 'promotions.bulk.file_name'
                        })}
                        <span>{file?.name}</span>
                      </span>
                      <span className="br-bulk-promotions__errors-box__file-info__subtitle">
                        {intl.formatMessage(
                          {
                            id: 'wallet.balance_adjustment.success_screen.total_records'
                          },
                          {
                            totalRecords: count
                          }
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="display-flex">
                    <Button
                      onClick={() => {
                        setHasUploaded(false);
                        setErrorsArray([]);
                      }}
                    >
                      {intl.formatMessage({
                        id: 'settings.system.time_slots.remove'
                      })}
                    </Button>
                    <div className="br-bulk-promotions__errors-box__upload">
                      <UploadAttachment
                        multiple={false}
                        accept={'.xlsx'}
                        beforeUpload={handleUpload}
                        showUploadList={false}
                        customRequest={() => {}}
                        customContent={reuploadContent}
                      />
                    </div>
                  </div>
                </div>
                <div className="br-bulk-promotions__errors-inner-box">
                  <WarningIcon />
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'promotions.bulk.error_text'
                      },
                      {
                        errorCount: errorsArray?.length
                      }
                    )}
                  </span>
                </div>
              </div>
              <BRSearchableTable
                className="br-bulk-promotions__errors-table"
                columns={columns}
                listFunction={() => ({
                  list: errorsArray,
                  total: errorsArray.length
                })}
                onRowClick={() => {}}
                withOutCheckBoxes
                hidePagination
                shareMethods={acceptMethods}
              />
            </>
          ) : (
            <div className="br-wallet-balance-adjustment">
              <div className="br-balance-adjustment__download-template-container">
                <GreenInfoIcon />
                <div>
                  <span>
                    {intl.formatMessage({
                      id: 'promotions.bulk.title'
                    })}
                  </span>
                  <span>
                    {intl.formatMessage({
                      id: 'promotions.bulk.subtitle'
                    })}
                  </span>
                  <Button onClick={handleSheetDownloadClick}>
                    {intl.formatMessage({
                      id: 'common.export'
                    })}
                  </Button>
                </div>
              </div>
              <div className="br-bulk-promotions__upload-container ">
                <UploadAttachment
                  multiple={false}
                  accept={'.xlsx'}
                  beforeUpload={handleUpload}
                  showUploadList={false}
                  customRequest={() => {}}
                  customContent={customContent}
                />
              </div>
            </div>
          )}
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(BulkPromotions);
