import { Input, Menu, Checkbox, Dropdown, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { ReactComponent as Down } from 'assets/bosta-icons/Down.svg';

import './SpecialPermissionsSelect.less';

const SpecialPermissionsSelect = ({
  list = [],
  intl,
  values,
  setValues,
  disabled
}) => {
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    list?.length && setListItems(list);
  }, [list]);

  const onSerach = (value) => {
    setListItems(
      list.filter((item) => item?.toLowerCase().includes(value?.toLowerCase()))
    );
  };

  const handleOnChange = ({ e, item }) => {
    setValues({ itemToUpdate: item, value: e.target.checked });
  };

  const menuOverlay = () => (
    <Menu onClick={(e) => e.domEvent.stopPropagation()}>
      <div className="br-permissions-select__container">
        <div>
          <Input
            placeholder={intl.formatMessage({ id: 'common.search' })}
            onChange={({ target: { value } }) => onSerach(value)}
            allowClear
          />
        </div>
        <div>
          {listItems?.map((item) => (
            <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  className="br-permissions-select__checkbox"
                  checked={values?.[item]}
                  onChange={(e) => handleOnChange({ e, item })}
                >
                  {item}
                </Checkbox>
              </div>
            </Menu.Item>
          ))}
        </div>
      </div>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menuOverlay()} trigger={['click']} disabled={disabled}>
        <Radio.Button className="br-permissions-button">
          {intl.formatMessage({
            id: 'roles_permissions.edit_special_permissions'
          })}
          <Down />
        </Radio.Button>
      </Dropdown>
    </div>
  );
};

export default injectIntl(SpecialPermissionsSelect);
