import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getReturnToVendorStatistics = (payload) => {
  return http.get('/fulfillment-v2/rtv/statistics', payload);
};

export const getReturnToVendorList = (payload) => {
  return http.get('/fulfillment-v2/rtv', payload);
};

export const exportReturnToVendorList = (payload) => {
  return http.get('/fulfillment-v2/rtv/export', payload, downloadHeader);
};

export const getFulfillmentReturnToVendor = (id) => {
  return http.get(`/fulfillment-v2/rtv/${id}`);
};

export const getFulfillmentReturnToVendorSKUsQuantities = (payload) => {
  return http.get(`/fulfillment-v2/rtv/skus-quantities`, payload);
};

export const getFulfillmentReturnToVendorLineSerialNumbers = (lineId) => {
  return http.get(`/fulfillment-v2/rtv/lines/${lineId}/serials`);
};

export const getFulfillmentReturnToVendorSKUSerials = (payload) => {
  return http.get(`/fulfillment-v2/rtv/sku-serials`, payload);
};

export const createReturnToVendor = (payload) => {
  return http.post('/fulfillment-v2/rtv', payload);
};
