import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import './SwappingTables.less';

const SwappingTables = ({
  list,
  scannedList,
  title1,
  title2,
  className1 = '',
  className2 = '',
  columns,
  recievedColumns,
  components = {},
  ...props
}) => {
  return (
    <>
      <div className="br-swapping-tables">
        <div className="br-swapping-tables__left-table-container">
          <BRSearchableTable
            className={className1}
            columns={columns}
            title={title1}
            listData={list}
            onRowClick={() => {}}
            scrollY={430}
            withOutCheckBoxes
            hidePagination
            components={components}
            {...props}
          />
        </div>
        <div>
          <BRSearchableTable
            className={className2}
            title={title2}
            customColumns={recievedColumns}
            onRowClick={() => {}}
            listData={scannedList}
            scrollY={430}
            withOutCheckBoxes
            hidePagination
            components={components}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default SwappingTables;
