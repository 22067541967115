import { omitBy, isNil } from 'lodash';
import { BASE } from '../apis';
import { notify } from 'components/Notify/Notify';

export const getAllPickup = async (dataPage) => {
  try {
    const response = await BASE.get(`/pickups?pageId=${dataPage.data}`);
    return {
      data: response.data.result.pickups,
      count: response.data.result.count,
      pagination: { current: dataPage.data, limit: 50 }
    };
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const getAllBsinesses = async () => {
  try {
    const data = await BASE.get(`/businesses/valid-business-names`);
    return data;
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const getAllStar = async () => {
  try {
    const response = await BASE.get(`/users/stars`);
    return { data: response?.data?.message?.stars };
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const getPickupPoints = async ({ pickupId }) => {
  try {
    const { data } = await BASE.get(`/pickups/${pickupId}/getPoints`);
    return data.results;
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const getDetailsPickups = async (payload) => {
  try {
    const response = await BASE.get(`/pickups/${payload.data}`);
    return { data: response.data.message };
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const StatePickup = async (payload) => {
  try {
    const response = await BASE.put(`/pickups/state`, payload);
    notify(`Pickup cancelled successfully!`, 'success');
    return response;
  } catch (e) {
    notify(e.response?.data?.message || e.message);
    return null;
  }
};

export const getAllRoute = async () => {
  try {
    const response = await BASE.get(
      `/routes?active=true&field=name&pageId=-1&states[]=1&states[]=6`
    );
    return { data: response.data.result };
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const getAllHubs = async () => {
  const { data } = await BASE.get(`/hubs`);
  return data;
};

export const addToRoute = async (ItemId, routesId) => {
  try {
    const payload = {
      routeId: routesId,
      pickupRequestIds: Array.isArray(ItemId) ? ItemId : [ItemId]
    };

    const response = await BASE.put(`/pickups/addToRoute`, payload);
    notify('Added to route successfully!', 'success');
    return response;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return null;
  }
};

export const assignToHub = async (ItemId, hubId, businessId) => {
  try {
    const payload = {
      businessId,
      warehouseId: hubId
    };

    const response = await BASE.put(`/pickups/${ItemId}`, payload);
    notify('Assign to hub successfully!', 'success');
    return response;
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const searchPickups = async ({ data }) => {
  try {
    const result = omitBy(data, isNil);
    const response = await BASE.get(
      `/pickups/search?${new URLSearchParams(result)}`
    );
    return {
      data: response.data.result.pickups,
      count: response.data.result.count,
      pagination: { current: data.pageId, limit: 50 }
    };
  } catch (e) {
    notify(e.message);
    return null;
  }
};
export const editPickups = async (id, data) => {
  try {
    const response = await BASE.put(`/pickups/${id}`, data);
    notify('Pickup Edit successfully', 'success');

    return response;
  } catch (e) {
    notify(e.response.data.message);
    return null;
  }
};

export const getAllPoints = async (payload) => {
  try {
    const { data } = await BASE.post(`/routes/getPoints`, payload);
    return data;
  } catch (e) {
    notify(e.message);
    return null;
  }
};

export const createPickups = async (data) => {
  try {
    const response = await BASE.post(`/pickups`, data);
    notify('Pickup Created successfully', 'success');

    return response;
  } catch (e) {
    notify(e.response.data.message);
    return null;
  }
};
export const getBusinessDetails = async (id) => {
  const { data } = await BASE.get(`/businesses/${id}`);
  return data;
};

export const getSinglePickupsDetails = async (id) => {
  const { data } = await BASE.get(`/pickups/${id}`);
  return data.message;
};
export const getAllLocationPickups = async (id) => {
  const { data } = await BASE.get(`/businesses/${id}/pickup-locations`);

  return data;
};
export const exportCSV = async (data) => {
  try {
    const queryStr = Object.entries(omitBy(data, isNil)).reduce(
      (acc, value) => `${acc}${value[0]}=${value[1]}&`,
      ''
    );
    const res = await BASE.get(`/pickups/csv?${queryStr}`, {});
    if (res.headers['content-type'].includes('application/json')) {
      notify('Pickups exported to your mail successfully.', 'success');
    } else {
      notify('Pickups loaded successfully, exporting in progress.', 'success');
      return res.data;
    }
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};
