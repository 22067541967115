import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { TimePicker, Form, Input, Button } from 'antd';
import { injectIntl } from 'react-intl';

import {
  getCountryConfiguration,
  setCountryConfigurations
} from 'services/system';
import { getCurrentUserCountryId } from 'common/countries/countries-mapping';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import { notify } from 'components/Notify/Notify';

const DispatchingConfiguration = ({ intl }) => {
  const formRef = useRef();

  const currentUserCountryId = getCurrentUserCountryId();
  const cutOffTimeList = [
    {
      name: 'lateOrderCutOffInUtc',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.late_orders_cutoff_time'
      })
    },
    {
      name: 'missedOrderCutOffInUtc',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.missed_orders_cutoff_time'
      })
    },
    {
      name: 'receivingCutOffTimeInUtc',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.receiving_cutoff_time'
      })
    },
    {
      name: 'lateTransferCutOffInUtc',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.late_transfer_cutoff_time_last_mile'
      })
    },
    {
      name: 'missedTransferCutOffInUtc',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.missed_transfer_cutoff_time_last_mile'
      })
    },

    {
      name: 'lateTransferCutOffInUtcFM',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.late_transfer_cutoff_time_first_mile'
      })
    },
    {
      name: 'missedTransferCutOffInUtcFM',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.missed_transfer_cutoff_time_first_mile'
      })
    },
    {
      name: 'lateTransferCutOffInUtcSH',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.late_transfer_cutoff_time_sorting'
      })
    },
    {
      name: 'missedTransferCutOffInUtcSH',
      label: intl.formatMessage({
        id: 'settings.dispatching_configurations.missed_transfer_cutoff_time_sorting'
      })
    }
  ];

  const getConfiguration = async () => {
    try {
      const { data } = await getCountryConfiguration({
        countryId: currentUserCountryId
      });

      const formData = {
        hubSupervisorsEmails: data?.hubSupervisorsEmails?.join(',')
      };

      cutOffTimeList.forEach(({ name }) => {
        if (data?.[name]) {
          formData[name] = dayjs(dayjs.utc(data?.[name], 'HH:mm').format());
        }
      });

      formRef.current.setFieldsValue(formData);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const payload = {
        hubSupervisorsEmails: values.hubSupervisorsEmails?.split(',')
      };

      cutOffTimeList.forEach(({ name }) => {
        payload[name] = dayjs(values?.[name]).utc().format('HH:mm');
      });

      await setCountryConfigurations({
        countryId: currentUserCountryId,
        payload
      });

      notify(
        intl.formatMessage({
          id: 'settings.dispatching_configurations.edit_success_message'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-dispatching-configuration__container">
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.tabs.dispatching_configurations'
        })}
        isInternalComponent
      />

      <div className="br-dispatching-configuration__options">
        <Form ref={formRef} onFinish={handleSubmit}>
          {cutOffTimeList.map(({ name, label }) => (
            <Form.Item key={name} name={name} label={label}>
              <TimePicker
                use12Hours
                format={'hh:mm A'}
                minuteStep={30}
                showNow={false}
              />
            </Form.Item>
          ))}
          <Form.Item
            name="hubSupervisorsEmails"
            label={intl.formatMessage({
              id: 'settings.dispatching_configurations.emails_to_be_notified'
            })}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.confirm'
            })}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default injectIntl(DispatchingConfiguration);
