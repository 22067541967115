import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { BARCODES_COUNTERS_CARDS } from 'constants/hubs';
import { getNotPrintedBarcodes, getPrintedBarcodes } from 'services/barcodes';
import { getCurrentUserHubInfo } from 'utils/hubs';

import BarcodeCards from './components/BarcodeCards/BarcodeCards';
import BarcodesList from './components/BarcodesList/BarcodesList';
import { notify } from 'components/Notify/Notify';

import './BarcodeToAWB.less';

const BarcodeToAWB = ({ intl }) => {
  const [counts, setCounts] = useState({
    notPrintedCount: 0,
    printedCount: 0
  });
  const [notPrintedBarcodes, setNotPrintedBarcodes] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPrinted();
    getNotPrinted();
  }, []);

  const getPrinted = async () => {
    const currentUserHubId = getCurrentUserHubInfo()?._id;
    try {
      const { data } = await getPrintedBarcodes({
        hubId: currentUserHubId,
        limit: 1
      });
      setCounts((prev) => ({ ...prev, printedCount: data.total }));
    } catch (error) {
      notify(error.message);
    }
  };

  const getNotPrinted = async (newPage) => {
    const currentUserHubId = getCurrentUserHubInfo()?._id;
    setIsLoading(true);
    setHasMore(false);
    try {
      const payload = {
        hubId: currentUserHubId,
        page: newPage || 1,
        limit: 50
      };

      const { data } = await getNotPrintedBarcodes(payload);

      const newBarcodes = [...notPrintedBarcodes, ...data.barcodes];

      setNotPrintedBarcodes(newBarcodes);
      setHasMore(newBarcodes.length < data.total);

      !newPage &&
        setCounts((prev) => ({ ...prev, notPrintedCount: data.total }));
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="br-barcodes-to-awb__container display-xs">
        {intl.formatMessage({ id: 'barcode_to_awb.title' })}
      </div>
      <div className="br-barcodes-to-awb-cards__container">
        {BARCODES_COUNTERS_CARDS.map(
          ({ title, dataIndex, icon, iconColor }, index) => (
            <BarcodeCards
              key={index}
              title={title}
              count={counts[dataIndex]}
              icon={icon}
              iconColor={iconColor}
            />
          )
        )}
      </div>
      <BarcodesList
        loadMore={getNotPrinted}
        hasMore={hasMore}
        isLoading={isLoading}
        barcodes={notPrintedBarcodes}
      />
    </div>
  );
};

export default injectIntl(BarcodeToAWB);
