import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from 'antd';

import {
  SCAN_TYPE_MAPPING,
  WRONG_SCAN_QUICK_FILTERS,
  WRONG_SCAN_TABLE_COLUMNS,
  WRONG_SCAN_TABS
} from 'constants/hubs';
import { exportScanLogs, searchScanLogs } from 'services/hubs';
import { isUserAuthorized } from 'utils/helpers';
import { downloadAsXlsx } from 'utils/download';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTable from 'components/BRTable/BRTable';
import BRDateTime from 'components/BRDateTime/BRDateTime';
import WrongScanFilters from 'components/HubOperations/WrongScan/components/WrongScanFilters/WrongScanFilters';
import { notify } from 'components/Notify/Notify';

import './WrongScan.less';

const WrongScan = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({});

  const refreshTable = useRef();

  const intl = useIntl();
  const [form] = Form.useForm();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleClearFilters = ({ refreshTable } = {}) => {
    form.resetFields();
    setSelectedFilters({});

    if (refreshTable) {
      handleSubmitFilters({});
    }
  };

  const handleSubmitFilters = (filterValues) => {
    setSelectedFilters(filterValues);
    refreshTable.current({ pageNumber: 1, filterValues });
  };

  const formatData = (logsData) => {
    return logsData.map((data) => {
      const {
        business,
        currentHub,
        intendedHub,
        scannedBy,
        scannedInHub,
        createdAt,
        scanType,
        trackingNumber
      } = data;

      return {
        ...data,
        trackingNumberLink: (
          <Link to={`/deliveries/${trackingNumber}/details`} target="_blank">
            {trackingNumber}
          </Link>
        ),
        businessName: business.name,
        sourceHub: currentHub?.name || (
          <span className="br-wrong-scan__subtitle">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        ),
        destinationHub: intendedHub?.name || (
          <span className="br-wrong-scan__subtitle">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        ),
        userHub: scannedInHub?.name || (
          <span className="br-wrong-scan__subtitle">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        ),
        user: scannedBy?.name || (
          <span className="br-wrong-scan__subtitle">
            {intl.formatMessage({ id: 'common.empty_field' })}
          </span>
        ),
        lastAction: <BRDateTime date={createdAt} />,
        scanningEntryPoint:
          SCAN_TYPE_MAPPING[scanType] ||
          intl.formatMessage({ id: 'common.empty_field' })
      };
    });
  };

  const getWrongScanLogs = async ({
    page,
    limit,
    quickFiltersValue,
    filterValues
  }) => {
    setIsLoading(true);
    try {
      const payload = {
        page,
        limit,
        ...(filterValues ? { ...filterValues } : { ...selectedFilters }),
        ...(quickFiltersValue === WRONG_SCAN_TABS.WRONG_SCANS && {
          wrongScans: true
        }),
        ...(quickFiltersValue === WRONG_SCAN_TABS.REMOVED_SCANS && {
          scanType: WRONG_SCAN_TABS.REMOVED_SCANS
        })
      };

      const { scansLogs, count } = await searchScanLogs(payload);
      setCurrentTab(quickFiltersValue);
      setIsLoading(false);

      return {
        list: formatData(scansLogs),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportWrongScans = async () => {
    try {
      const payload = {
        ...selectedFilters,
        ...(currentTab === WRONG_SCAN_TABS.WRONG_SCANS && { wrongScans: true }),
        ...(currentTab === WRONG_SCAN_TABS.REMOVED_SCANS && {
          scanType: WRONG_SCAN_TABS.REMOVED_SCANS
        })
      };
      const data = await exportScanLogs(payload);
      downloadAsXlsx(data, `Scans_Logs_${Date.now()}.xlsx`);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-wrong-scan__container">
        <div className="br-wrong-scan__title display-sm">
          {intl.formatMessage({ id: 'hubs.tabs.wrong_scan' })}
        </div>
        <div className="br-wrong-scan__subtitle">
          {intl.formatMessage({ id: 'hubs.wrong_scan.subtitle' })}
        </div>

        <WrongScanFilters
          form={form}
          handleClearFilters={handleClearFilters}
          handleSubmitFilters={handleSubmitFilters}
        />
        <BRTable
          columns={WRONG_SCAN_TABLE_COLUMNS}
          quickFilters={WRONG_SCAN_QUICK_FILTERS}
          initialQuickFilter={currentTab}
          listFunction={getWrongScanLogs}
          showFilter={false}
          shareMethods={acceptMethods}
          doAfterClearSearch={handleClearFilters}
          exportListFileFunction={handleExportWrongScans}
          isExportButtonDisabled={
            !isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
              ACL_MATRIX.DELIVERY_SCANS_LOGS_EXPORT
            ])
          }
        />
      </div>
    </LoadingWrapper>
  );
};

export default WrongScan;
