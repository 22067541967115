import React from 'react';
import { Input } from 'antd';

import { isSaudi } from 'constants/helper';

class BRFormInputPhoneNumber extends React.Component {
  state = {
    value: null,
    regex: isSaudi() ? /^\+?(?:[\d -]*)+$/ : /^\+?(?:[\d]*)$/
  };

  handleOnChange = (evt) => {
    const { filedName, handleOnChangeForm, parent } = this.props;
    const { regex } = this.state;
    const { value } = evt.currentTarget;
    const regexValue = regex.test(value);
    if (regexValue) {
      handleOnChangeForm(filedName, value, parent);
      this.setState({ value });
      return true;
    }
    return false;
  };

  render() {
    const {
      maxLength,
      placeholder,
      label,
      autoComplete,
      value,
      disabled,
      refName,
      handleOnPaste,
      inputProps
    } = this.props;

    return (
      <>
        <Input
          type="text"
          label={label}
          value={value || null}
          onChange={this.handleOnChange}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
          onPaste={handleOnPaste}
          ref={refName}
          {...inputProps}
        />
      </>
    );
  }
}

export default BRFormInputPhoneNumber;
