import React from 'react';
import { Button } from 'antd';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import './SecurityTypeCard.less';

function SecurityTypeCard({
  title,
  subtitle,
  buttonText,
  handleOnClickButton,
}) {
  return (
    <div className="br-security-type">
      <div className="br-security-type-card">
        <BRContentHeader
          title={title}
          subtitle={subtitle}
          className="br-security-type-card__content"
          isInternalComponent
        />
        <Button type="primary" onClick={handleOnClickButton}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default SecurityTypeCard;
