import React from 'react';
import { injectIntl } from 'react-intl';
import { Switch } from 'antd';

const ViewVatButton = ({ intl, onChange }) => {
  return (
    <div className="br-pricing-plan__switch-vat">
      <span>
        {intl.formatMessage({
          id: 'settings.pricing_plan.vat.title'
        })}
      </span>
      <Switch onChange={onChange} size="small" />
    </div>
  );
};

export default injectIntl(ViewVatButton);
