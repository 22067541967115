import React, { useState } from 'react';
import { Modal, Button, Select, Input } from 'antd';
import dayjs from 'dayjs';

import { createTicket } from 'services/hubs';

import { fmt } from 'IntlWrapper/IntlWrapper';
import { notify } from 'components/Notify/Notify';

import './TableActionsModals.less';

export const DeleteModal = (props) => {
  const { close, onSuccess } = props;
  const handleCancel = () => {
    close();
  };

  const handleOnOk = () => {
    onSuccess();
    close();
  };

  return (
    <Modal
      {...props}
      className="br-exceptions-tab__delete-modal"
      onCancel={handleCancel}
      onOk={handleOnOk}
    >
      {fmt({
        id: 'hubs.packages_debrief.exceptions_tab.modals.remove_order_modal.confirmation_message'
      })}
    </Modal>
  );
};

export const CreateTicketModal = (props) => {
  const { close, onSuccess, trackingNumber, hubName, ticketReasonsButtons } =
    props;
  const handleCancel = () => {
    close();
  };
  const handleOnOk = () => {
    onSuccess();
    close();
  };

  const handleTicketReason = (reason) => {
    // trackingNumber, category, subCategory, reason, description
    const ticketCreationDate = dayjs().format('D MMM');
    switch (reason) {
      case 'order_lost':
        return {
          trackingNumber,
          category: 'Delivery Issue',
          subCategory: 'Lost Order',
          reason: `Lost Order || ${ticketCreationDate} || ${hubName}`,
          description: `Order of tracking number: [${trackingNumber}] is lost. Reported by: [${hubName}].`,
          rank: 'Low'
        };
      case 'order_damage':
        return {
          trackingNumber,
          category: 'Delivery Issue',
          subCategory: 'Damaged Order',
          reason: `Damaged Order || ${ticketCreationDate} || ${hubName}`,
          description: `Order of tracking number: [${trackingNumber}] is damaged. Reported by: [${hubName}]`,
          rank: 'Low'
        };
      case 'order_opened_by_force':
        return {
          trackingNumber,
          category: 'Delivery Issue',
          subCategory: 'Opened Shipment',
          reason: `Opened by Force || ${ticketCreationDate} || ${hubName}`,
          description: `Order of tracking number: [${trackingNumber}] is is opened by force. Reported by: [${hubName}].`,
          rank: 'Low'
        };
      case 'order_picked_by_mistake':
        return {
          trackingNumber,
          category: 'Delivery Issue',
          subCategory: 'Delivered by Mistake',
          reason: `Order picked up by Mistake || ${ticketCreationDate} || ${hubName}`,
          description: `Order delivered by mistake. Please restart order of tracking number:${trackingNumber}`,
          rank: 'Low'
        };
      default:
        return;
    }
  };

  const handleTicketCreation = async (reason) => {
    const ticketData = handleTicketReason(reason);
    try {
      const { externalId } = await createTicket({ ...ticketData });
      onSuccess(externalId);
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      {...props}
      className="br-exceptions-tab__ticket-modal"
      footer={null}
      onCancel={handleCancel}
      onOk={handleOnOk}
    >
      <p className="br-exceptions-tab__ticket-modal__title">Ticket Reason:</p>
      <div className="br-exceptions-tab__ticket-modal__actions">
        {ticketReasonsButtons?.map((reason, index) => (
          <Button
            type="primary"
            key={index}
            onClick={() => handleTicketCreation(reason)}
          >
            {fmt({
              id: `hubs.packages_debrief.exceptions_tab.modals.ticket_modal.${reason}`
            })}
          </Button>
        ))}
      </div>
    </Modal>
  );
};

export const PickupRequestModal = (props) => {
  const { close, onSuccess, pickups } = props;
  const [selectedPickupRequest, setSelectedPickupRequest] = useState(null);
  const handleCancel = () => {
    close();
  };

  const handleOnOk = () => {
    onSuccess(selectedPickupRequest);
    close();
  };

  return (
    <Modal
      {...props}
      className="br-exceptions-tab__delete-modal"
      onCancel={handleCancel}
      onOk={handleOnOk}
    >
      <h5>
        You scanned a pickup that is not route, please select a pickup request:
      </h5>
      <Select
        className="full-width"
        name="packageSize"
        allowClear
        onChange={(value) => setSelectedPickupRequest(value)}
      >
        {pickups?.map((pickup) => (
          <Select.Option key={pickup.puid} value={pickup._id}>
            {`${pickup?.puid} ${pickup.business.name}`}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export const receivePickupsConfirmation = (props) => {
  const { close, onSuccess, messages } = props;
  const handleCancel = () => {
    close();
  };

  const handleOnOk = () => {
    onSuccess();
    close();
  };

  return (
    <Modal
      {...props}
      className="br-receive-pickup-confirmation-modal"
      footer={null}
    >
      <p>You scanned:</p>
      {messages?.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
      <div className="br-receive-pickup-confirmation-modal__footer">
        <Button onClick={handleCancel}>Back</Button>
        <Button type="primary" onClick={handleOnOk}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export const CreateNewSecuritySeal = (props) => {
  const { close, onSuccess, deliveryId } = props;

  const [newSecuritySeal, setNewSecuritySeal] = useState(null);

  const handleNewSecuritySealChange = ({ target: { value } }) => {
    setNewSecuritySeal(value);
  };

  const handleNewSecuritySealReplace = () => {
    if (newSecuritySeal) {
      onSuccess({ newSecuritySeal, deliveryId });
      close();
    }
  };

  return (
    <Modal
      {...props}
      onCancel={close}
      className="br-receive-pickup-confirmation-modal"
      footer={null}
    >
      <div className="br-soho-pickups-scan-label">
        {fmt({
          id: 'hubs.packages_debrief.soho_pickups_tab.create_new_security_seal'
        })}
      </div>
      <Input
        placeholder={fmt({
          id: 'hubs.packages_debrief.soho_pickups_tab.write_or_scan_new_security_seal'
        })}
        onChange={handleNewSecuritySealChange}
      />
      <div className="br-create-new-security-seal-modal__footer">
        <Button onClick={close}>
          {fmt({
            id: 'common.cancel'
          })}
        </Button>
        <Button type="primary" onClick={handleNewSecuritySealReplace}>
          {fmt({
            id: 'common.replace'
          })}
        </Button>
      </div>
    </Modal>
  );
};
