import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_PROBLEMATIC_JOBS_ROUTES,
  FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH
} from 'constants/fulfillment-problematic-jobs';

const FulfillmentProblematic = () => {
  return (
    <Switch>
      {FULFILLMENT_PROBLEMATIC_JOBS_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentProblematic);
