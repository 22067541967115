import { injectIntl } from 'react-intl';

import { SEALS, SEAL_SCAN_TYPE } from 'constants/hubs';

import ReceiveSealsCard from '../ReceiveSealsCard/ReceiveSealsCard';
import ScannerInput from '../ScannerInput/ScannerInput';

const MasterSealScan = ({
  intl,
  getSeals,
  goToNextView,
  setCurrentViewProps,
  isLoading,
  hubId
}) => {
  const handleChange = async (value) => {
    const payload = {
      sealNumber: value,
      destinationHubId: hubId,
      searchType: SEALS,
      type: SEAL_SCAN_TYPE.MASTER,
      pageId: 1
    };

    const data = await getSeals(payload);

    const masterSeal = data?.results?.find(
      ({ sealNumber }) => sealNumber === value
    );

    if (masterSeal) {
      goToNextView();
      setCurrentViewProps({
        masterSeal
      });
    } else {
      return intl.formatMessage({
        id: 'hubs.receive_from_hub.receive_seals.error_msgs.not_found'
      });
    }
  };

  return (
    <ReceiveSealsCard>
      <ScannerInput
        label={intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.master_seal_scan.label'
        })}
        placeholder={intl.formatMessage({
          id: 'hubs.receive_from_hub.receive_seals.master_seal_scan.placeholder'
        })}
        onChange={handleChange}
        disabled={isLoading}
      />
    </ReceiveSealsCard>
  );
};

export default injectIntl(MasterSealScan);
