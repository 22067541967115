import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import { injectIntl } from 'react-intl';

import { emailRule, phoneNumberRule } from 'utils/forms';
import { cleanEmptyString } from 'utils/helpers';
import { COUNTRIES } from 'constants/country-data';
import { createContacts } from 'services/pickups';

import { notify } from 'components/Notify/Notify';

import './CreateContactPersonFormModal.less';

const CreateContactPersonFormModal = (props) => {
  const { intl, close, getPickupLocation, pickupLocation, businessId } = props;
  const [pickupLocationPayload, setPickupLocationPayload] = useState(null);

  useEffect(() => {
    setPickupLocationPayload(pickupLocation);
  }, [pickupLocation]);

  const handleOnOk = useCallback(async (values) => {
    if (!values.isDefault) {
      values.isDefault = false;
    }
    if (pickupLocation.length !== 0) {
      try {
        const contacts = pickupLocationPayload.contacts.map((item) => {
          if (item?.isDefault && values?.isDefault) {
            item.isDefault = false;
          }
          return cleanEmptyString(item);
        });
        contacts.push(cleanEmptyString(values));
        delete pickupLocationPayload.canEdit;
        await createContacts({
          id: pickupLocationPayload._id,
          payload: {
            ...pickupLocationPayload,
            businessId,
            _id: undefined,
            contacts,
            isDefault: undefined,
            contactPerson: undefined,
            assignedHub: undefined,
            isNewAddressPickupLocation: undefined,
            isNewAreaPickupLocation: undefined,
            isNewCityPickupLocation: undefined
          }
        });
        notify(
          intl.formatMessage({
            id: 'settings.pickup_locations.pickup_location_form.add_contact_success'
          }),
          'success'
        );
        getPickupLocation(pickupLocationPayload._id);
        // segmentTracking('E_PICKUP_NEW_CONTACT_CREATED', []);
        close();
      } catch (error) {
        notify(error.message);
      }
    } else {
      notify(
        intl.formatMessage({
          id: 'pickups.pickup_settings.contacts.error_pickup_location'
        })
      );
      close();
    }
  });

  const handleOnCancel = () => {
    // segmentTracking('E_PICKUP_NEW_CONTACT_CANCELED', []);
    close();
  };

  return (
    <Modal
      {...props}
      wrapClassName="br-contact-person-modal"
      title={null}
      onCancel={close}
      footer={null}
      width={null}
    >
      <>
        <div className="br-contact-person-modal__heading">
          {intl.formatMessage({
            id: 'settings.pickup_locations.pickup_location_form.contact_person_title'
          })}
        </div>
        <div className="br-contact-person-modal__sub-heading">
          {intl.formatMessage({
            id: 'settings.pickup_locations.pickup_location_form.contact_person_sub_title'
          })}
        </div>
      </>
      <Form className="br-contact-person-modal__form" onFinish={handleOnOk}>
        <div className="br-form-row">
          <Form.Item
            name="firstName"
            className="br-contact-person-modal__form__item"
            label={intl.formatMessage({
              id: 'contact_form_labels.first_name'
            })}
            rules={[{ required: true, max: 100 }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'settings.pickup_locations.pickup_location_form.form_placeholders.first_name'
              })}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="br-contact-person-modal__form__item"
            label={intl.formatMessage({
              id: 'contact_form_labels.last_name'
            })}
            rules={[{ required: true, max: 100 }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'settings.pickup_locations.pickup_location_form.form_placeholders.last_name'
              })}
            />
          </Form.Item>
        </div>
        <div className="br-form-row">
          <Form.Item
            name="phone"
            className="br-contact-person-modal__form__item"
            label={intl.formatMessage({
              id: 'contact_form_labels.phone'
            })}
            validateTrigger="onBlur"
            rules={[
              { required: true },
              phoneNumberRule({
                message: intl.formatMessage({
                  id: 'form.phone_not_valid'
                }),
                internationlNumbers: true
              })
            ]}
          >
            <Input
              type="text"
              placeholder={intl.formatMessage({
                id:
                  JSON.parse(localStorage.getItem('userInfo'))?.country
                    ?.code === COUNTRIES[1].codeName
                    ? 'form.saudi_arabia_phone_placeholder'
                    : 'form.egypt_phone_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            name="secPhone"
            className="br-contact-person-modal__form__item"
            label={
              <>
                {intl.formatMessage({
                  id: 'contact_form_labels.secondary_phone'
                })}

                <span className="br-form-optional-label">
                  {intl.formatMessage({
                    id: 'form.optional_label'
                  })}
                </span>
              </>
            }
            validateTrigger="onBlur"
            rules={[
              phoneNumberRule({
                message: intl.formatMessage({
                  id: 'form.phone_not_valid'
                }),
                landlineNumbers: true
              })
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'settings.pickup_locations.pickup_location_form.form_placeholders.second_phone'
              })}
            />
          </Form.Item>
        </div>
        <div className="br-form-row">
          <Form.Item
            name="email"
            className="br-contact-person-modal__form__item"
            label={
              <>
                {intl.formatMessage({
                  id: 'contact_form_labels.email'
                })}

                <span className="br-form-optional-label">
                  {intl.formatMessage({
                    id: 'form.optional_label'
                  })}
                </span>
              </>
            }
            rules={[
              emailRule(
                intl.formatMessage({
                  id: 'form.email_not_valid'
                })
              )
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'settings.pickup_locations.pickup_location_form.form_placeholders.email'
              })}
            />
          </Form.Item>
        </div>

        <div className="br-create-contact-modal__form__footer">
          <Form.Item
            name="isDefault"
            valuePropName="checked"
            className="br-create-contact-modal__form__set-contact-default"
          >
            <Checkbox>
              {intl.formatMessage({
                id: 'settings.pickup_locations.actions.set_default_contact'
              })}
            </Checkbox>
          </Form.Item>
          <Button onClick={handleOnCancel}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.ok'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default injectIntl(CreateContactPersonFormModal);
