import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { CAPITAL_TABLE_FILTERS } from 'constants/borrow';
import {
  fetchCapitalInfo,
  exportCapitalInfo,
  updateBusinessCapitalStatus,
  downloadCapitalLegalDocuments
} from 'services/capital';
import { openModal } from 'utils/modal';
import { downloadAsZip } from 'utils/download';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import EditCapitalStatusModal from './components/EditCapitalStatusModal';

import './BostaCapital.less';

const CapitalAppliedBusinesses = ({ intl, applied = true }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [count, setCount] = useState(0);
  const refreshTableRef = useRef();

  const acceptMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const refreshCapitalTable = () => {
    refreshTableRef.current();
  };
  const handleEditStatus = (data) => {
    openModal(EditCapitalStatusModal, {
      status: data?.status,
      handleOnOk: (newStatus) =>
        handleStatusChange(newStatus, data.businessInfo.id)
    });
  };

  const handleStatusChange = async (newStatus, businessId) => {
    setIsPageLoading(true);
    try {
      await updateBusinessCapitalStatus({
        status: newStatus,
        businessId
      });
      refreshCapitalTable();
    } catch (error) {
      notify(error.message);
    }
    setIsPageLoading(false);
  };

  const COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'capital.table.business'
      }),
      dataIndex: 'businessInfo',
      render: ({ name, email, phone, id }) => (
        <div>
          <p>
            {name ? name : intl.formatMessage({ id: 'common.empty_field' })}
          </p>
          <p>
            {email ? email : intl.formatMessage({ id: 'common.empty_field' })}
          </p>
          <p>
            {phone ? phone : intl.formatMessage({ id: 'common.empty_field' })}
          </p>
          <p>ID: {id || intl.formatMessage({ id: 'common.empty_field' })}</p>
        </div>
      ),
      width: 300
    },
    ...(applied
      ? [
          {
            title: intl.formatMessage({
              id: 'capital.table.capital_id'
            }),
            dataIndex: 'capitalId'
          },
          {
            title: intl.formatMessage({
              id: 'capital.table.apply_date'
            }),
            dataIndex: 'applyDate',
            render: (applyDate) =>
              applyDate || intl.formatMessage({ id: 'common.empty_field' }),
            className: 'br-capital__white-space-column'
          }
        ]
      : []),
    {
      title: intl.formatMessage({
        id: 'capital.table.eligability'
      }),
      dataIndex: 'eligibility',
      render: (eligibility) =>
        intl.formatMessage({ id: eligibility ? 'common.yes' : 'common.no' })
    },
    {
      title: intl.formatMessage({
        id: 'capital.table.fund_amount'
      }),
      dataIndex: 'fundAmount'
    },
    {
      title: intl.formatMessage({
        id: 'capital.table.avg_orders'
      }),
      dataIndex: 'avgOrders'
    },
    {
      title: intl.formatMessage({
        id: 'capital.table.avg_cod'
      }),
      dataIndex: 'avgCod'
    },

    ...(applied
      ? [
          {
            title: intl.formatMessage({
              id: 'capital.table.status'
            }),
            dataIndex: 'status'
          },
          {
            dataIndex: 'status',
            render: (_, data) => (
              <BRButton
                label={intl.formatMessage({
                  id: 'capital.table.down_legal_docs'
                })}
                onClick={() => handleDownloadLegalDocuments(data)}
              />
            )
          },
          {
            dataIndex: 'actions',
            render: (_, data) => (
              <BRButton
                label={intl.formatMessage({
                  id: 'capital.table.edit_status'
                })}
                onClick={() => handleEditStatus(data)}
              />
            )
          }
        ]
      : [])
  ];

  const formatCapitalData = (dataArray) => {
    const formattedArray = [];

    dataArray.map((info) => {
      formattedArray.push({
        capitalId: info?._id,
        businessInfo: {
          name: info?.businessInfo?.name,
          email: info?.userInfo?.email,
          phone: info?.userInfo?.phone,
          id: info?.businessInfo?._id
        },
        applyDate: dayjs(info?.businessOrdersInfo?.appliedDate).format(
          'DD-MM-YYYY hh:mm A'
        ),
        eligibility: info?.isEligibleForFunding,
        fundAmount: info?.latestCalculatedFundAmount,
        avgOrders: info?.businessOrdersInfo?.avgMonthlyOrders,
        avgCod: info?.businessOrdersInfo?.avgCOD,
        status: info?.status
      });
    });

    return formattedArray;
  };

  const getBostaCapitalInfo = async (data) => {
    setIsPageLoading(true);
    try {
      const payload = {
        usedCouriers: data.otherCouriers && data.otherCouriers[0],
        appliedAtMin: data.appliedAtStart,
        appliedAtMax: data.appliedAtEnd,
        createdAtMin: data.startedAtStart,
        createdAtMax: data.startedAtEnd,
        lastYearOrdersMin: data.orders ? 1 : undefined,
        lastYearOrdersMax: data.orders,
        lastYearRevenueMax: data.revenu,
        lastYearRevenueMin: data.revenu ? 1 : undefined,
        businessPhone: data.businessPhone,
        businessEmail: data.businessEmail,
        businessId: data.businessId,
        fundAmountMin: data.fundingAmount ? 5000 : undefined,
        fundAmountMax: data.fundingAmount,
        eligible: data.eligible && data.eligible[0],
        applied
      };
      const {
        data: { total, profiles }
      } = await fetchCapitalInfo(data?.page - 1 || 0, data.limit, payload);

      setCount(total);

      setIsPageLoading(false);
      return {
        list: formatCapitalData(profiles),
        total
      };
    } catch (error) {
      notify(error.message);
      setCount(0);
    }
    setIsPageLoading(false);
  };

  const handleExportOrders = async (a, data) => {
    setIsPageLoading(true);
    try {
      const payload = {
        usedCouriers: data.otherCouriers && data.otherCouriers[0],
        appliedAtMin: data.appliedAtStart,
        appliedAtMax: data.appliedAtEnd,
        createdAtMin: data.startedAtStart,
        createdAtMax: data.startedAtEnd,
        lastYearOrdersMin: data.orders ? 1 : undefined,
        lastYearOrdersMax: data.orders,
        lastYearRevenueMax: data.revenu,
        lastYearRevenueMin: data.revenu ? 1 : undefined,
        businessPhone: data.businessPhone,
        businessEmail: data.businessEmail,
        businessId: data.businessId,
        fundAmountMin: data.fundingAmount ? 5000 : undefined,
        fundAmountMax: data.fundingAmount,
        eligible: data.eligible && data.eligible[0],
        applied
      };

      const result = await exportCapitalInfo(payload);
      notify(result.message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsPageLoading(false);
  };

  const handleDownloadLegalDocuments = async (record) => {
    setIsPageLoading(true);
    try {
      const data = await downloadCapitalLegalDocuments(record.businessInfo.id);
      downloadAsZip(data, `${record.businessInfo.id}-Legal-Documents`);
    } catch (error) {
      notify(error.message);
    }
    setIsPageLoading(false);
  };

  return (
    <LoadingWrapper loading={isPageLoading}>
      <BRTable
        className="br-bosta-capital-table"
        title={intl.formatMessage(
          {
            id: 'capital.table.title'
          },
          {
            count
          }
        )}
        columns={COLUMNS}
        pageLimit={50}
        listFunction={getBostaCapitalInfo}
        showFilter={true}
        hideFilterButton={true}
        tableFilters={CAPITAL_TABLE_FILTERS(applied)}
        exportListFileFunction={handleExportOrders}
        shareMethods={acceptMethods}
      />
    </LoadingWrapper>
  );
};

export default injectIntl(CapitalAppliedBusinesses);
