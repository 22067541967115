import { useState, useEffect } from 'react';

import { getOrderDetails } from 'services/shipments';
import { getAction } from 'services/action-center';
import { STAR_INSTRUCTION } from 'constants/action-center';

import EmptyOrderDetails from './components/EmptyOrderDetails/EmptyOrderDetails';
import OrderDetailsInfo from './components/OrderDetailsInfo/OrderDetailsInfo';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './OrderDetails.less';

const OrderDetails = ({
  selectedRowData,
  handleDataClear,
  ofdEditsPage,
  isRequestsTab
}) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [actionDetails, setActionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getOrderDetailsInfo = async () => {
    if (selectedRowData) {
      setIsLoading(true);
      const { deliveryId, trackingNumber } = selectedRowData;
      try {
        const deliveryInfo = await getOrderDetails(
          deliveryId || trackingNumber
        );
        setOrderDetails(deliveryInfo[0]);
      } catch (error) {
        notify(error.message);
        handleDataClear();
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedRowData) {
      getOrderDetailsInfo();

      if (ofdEditsPage) {
        selectedRowData?.action !== STAR_INSTRUCTION
          ? getActionDetails()
          : setActionDetails({
              action: selectedRowData?.action,
              noteId: selectedRowData?.noteId
            });
      }
    }
  }, [selectedRowData]);

  const getActionDetails = async () => {
    setIsLoading(true);

    try {
      const { actionId } = selectedRowData;

      const { data } = await getAction(actionId);

      setActionDetails(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="br-action-center-order-details-wrapper">
      <LoadingWrapper loading={isLoading}>
        {selectedRowData && orderDetails ? (
          <OrderDetailsInfo
            orderDetails={orderDetails}
            orderAction={ofdEditsPage ? actionDetails : selectedRowData}
            ofdEditsPage={ofdEditsPage}
            isRequestsTab={isRequestsTab}
          />
        ) : (
          <EmptyOrderDetails />
        )}
      </LoadingWrapper>
    </div>
  );
};

export default OrderDetails;
