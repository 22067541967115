import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { createPO } from 'services/fulfillment';
import { SllrWrapper } from 'contexts/sllr.context';
import { openModal } from 'utils/modal';
import {
  getSKU,
  goToPOsList,
  renderPOName
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import { FULFILLMENT_INBOUND_PO_MAIN_PATH } from 'constants/fulfillment';

import PurchaseOrder from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/PurchaseOrder';
import { notify } from 'components/Notify/Notify';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

const CreatePO = ({ intl }) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [products, setProducts] = useState([]);
  const { isLoading, setIsLoading } = useContext(SllrWrapper);

  const goToPO = (id) =>
    window.open(`${FULFILLMENT_INBOUND_PO_MAIN_PATH}/${id}`, '_self');

  const handleCreatePO = async () => {
    setIsLoading(true);

    try {
      const payload = {
        businessId: selectedBusiness._id,
        lines: products.map(({ quantity, ...rest }) => ({
          productSku: getSKU(rest),
          quantity
        }))
      };

      const { data } = await createPO(payload);
      const poId = data?.purchaseOrder?.id;

      openModal(SuccessModal, {
        title: intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.success_modal.title'
          },
          {
            poId: renderPOName(poId)
          }
        ),
        confirmButtonLabel: intl.formatMessage({
          id: 'fulfillment_inbound.create_po.products.success_modal.confirm_btn'
        }),
        cancelButtonLabel: intl.formatMessage({
          id: 'common.done'
        }),
        modalAction: () => goToPO(poId),
        cancelAction: goToPOsList,
        onCancel: null
      });
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setProducts([]);
  }, [selectedBusiness]);

  return (
    <PurchaseOrder
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      products={products}
      setProducts={setProducts}
      selectedBusiness={selectedBusiness}
      setSelectedBusiness={setSelectedBusiness}
      handleCreatePO={handleCreatePO}
      headerProps={{
        icon: CloseIcon,
        iconOnClick: goToPOsList,
        title: intl.formatMessage({
          id: 'fulfillment_inbound.create_po.title'
        }),
        actions: [
          {
            label: intl.formatMessage({
              id: 'common.cancel'
            }),
            onClick: goToPOsList
          },
          {
            label: intl.formatMessage({
              id: 'fulfillment_inbound.create_po.actions.confirm'
            }),
            type: 'primary',
            disabled: !products.length,
            onClick: handleCreatePO
          }
        ]
      }}
    />
  );
};

export default injectIntl(withRouter(CreatePO));
