import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input } from 'antd';

import { whiteSpaceRule } from 'utils/forms';

import BRButton from 'components/BRButton/BRButton';

import './CancelFlyersOrderModal.less';

const CancelFlyersOrderModal = ({
  intl,
  close,
  onConfirm = () => {},
  cancelAction = false,
  ...props
}) => {
  const confirmAction = (value) => {
    if (onConfirm) {
      onConfirm(value);
    }
    close();
  };

  const onCancel = () => {
    if (cancelAction) {
      cancelAction();
    }
    close();
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.delete_modal.modal_title`
      })}
      onCancel={onCancel}
      width={null}
      wrapClassName="br-cancle-flyers-order-modal"
      footer={
        <>
          <BRButton
            className="button"
            type="basic"
            label={intl.formatMessage({
              id: `packaging.orders_tab.draft_orders.delete_modal.keep`
            })}
            onClick={onCancel}
          />

          <BRButton
            className="button"
            type="destructive-primary"
            label={intl.formatMessage({
              id: `packaging.orders_tab.draft_orders.delete_modal.delete`
            })}
            htmlType="submit"
            form="cancellationReasonForm"
          />
        </>
      }
      maskClosable={false}
      {...props}
    >
      {intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.delete_modal.delete_confirmation`
      })}

      <Form
        className="flyers-order-cancel-reason-form"
        name="cancellationReasonForm"
        onFinish={confirmAction}
      >
        <Form.Item
          rules={[{ required: true }, whiteSpaceRule()]}
          label={intl.formatMessage({
            id: `packaging.orders_tab.draft_orders.delete_modal.rejection_reason_title`
          })}
          name="cancellationReason"
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: `packaging.orders_tab.draft_orders.delete_modal.rejection_reason_placeholder`
            })}
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={500}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(CancelFlyersOrderModal);
