const actionTypes = {
  GET_ALL_VERIFIED_BUSINESSES: 'GET_ALL_BUSINESSES',
  GET_ALL_CITIES: 'GET_ALL_CITIES',
  GET_ALL_CITIES_RECEIVED: 'GET_ALL_CITIES_RECEIVED',
  GET_ALL_ZONES: 'GET_ALL_ZONES',
  GET_ALL_DISTRICTS: 'GET_ALL_DISTRICTS',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ALL_STARS: 'GET_ALL_STARS',
  GET_STARS_: 'GET_STARS_',
  GET_ALL_STARS_RECEIVED: 'GET_ALL_STARS_RECEIVED',
  GET_STARS_RECEIVED: 'GET_STARS_RECEIVED',
  GET_ALL_HUBS: 'GET_ALL_HUBS',
  GET_ALL_HUBS_PAGED: 'GET_ALL_HUBS_PAGED',
  GET_HUB: 'GET_HUB',
  GET_HUB_RECEIVED: 'GET_HUB_RECEIVED',
  GET_ALL_VERIFIED_BUSINESSES_RECEIVED: 'GET_ALL_BUSINESSES_RECEIVED',
  GET_ALL_HUBS_RECEIVED: 'GET_ALL_HUBS_RECEIVED',
  GET_ALL_HUBS_PAGED_RECEIVED: 'GET_ALL_HUBS_PAGED_RECEIVED',
  GET_ALL_DELIVERIES: 'GET_ALL_DELIVERIES',
  DELIVERIES_LOADING_STARTED: 'DELIVERIES_LOADING_STARTED',
  DELIVERIES_LOADING_ENDED: 'DELIVERIES_LOADING_ENDED',
  GET_ALL_DELIVERIES_RECEIVED: 'GET_ALL_DELIVERIES_RECEIVED',
  GET_DELIVERY: 'GET_DELIVERY',
  GET_DELIVERY_RECEIVED: 'GET_DELIVERY_RECEIVED',
  GET_BUSINESS: 'GET_BUSINESS',
  GET_BUSINESS_RECEIVED: 'GET_BUSINESS_RECEIVED',
  GET_PICKUP_ZONES: 'GET_PICKUP_ZONES',
  GET_PICKUP_ZONES_RECEIVED: 'GET_PICKUP_ZONES_RECEIVED',
  GET_DROP_OFF_ZONES: 'GET_DROP_OFF_ZONES',
  GET_DROP_OFF_ZONES_RECEIVED: 'GET_DROP_OFF_ZONES_RECEIVED',
  PRINT_AIR_WAY_BILL: 'PRINT_AIR_WAY_BILL',
  PRINT_AIR_WAY_BILL_DONE: 'PRINT_AIR_WAY_BILL_DONE',
  PRINT_AIR_WAY_BILL_CLEAR: 'PRINT_AIR_WAY_BILL_CLEAR',
  CANCEL_DELIVERY: 'CANCEL_DELIVERY',
  CANCEL_DELIVERY_DONE: 'CANCEL_DELIVERY_DONE',
  ADD_DELIVERY_COMMENT: 'ADD_DELIVERY_COMMENT',
  ADD_DELIVERY_COMMENT_DONE: 'ADD_DELIVERY_COMMENT_DONE',
  MARK_DELIVERY_AS_EXCEPTION: 'MARK_DELIVERY_AS_EXCEPTION',
  MARK_DELIVERY_AS_EXCEPTION_DONE: 'MARK_DELIVERY_AS_EXCEPTION_DONE',
  REREQUEST_DELIVERY: 'REREQUEST_DELIVERY',
  REREQUEST_DELIVERY_DONE: 'REREQUEST_DELIVERY_DONE',
  EXPORT_DELIVERIES_AS_CSV: 'EXPORT_DELIVERIES_AS_CSV',
  EXPORT_DELIVERIES_AS_CSV_DONE: 'EXPORT_DELIVERIES_AS_CSV_DONE',
  EXPORT_BUSINESS_AS_CSV: 'EXPORT_BUSINESS_AS_CSV',
  EXPORT_BUSINESS_AS_CSV_DONE: 'EXPORT_BUSINESS_AS_CSV_DONE',
  EXPORT_PICKUPS_AS_CSV: 'EXPORT_PICKUPS_AS_CSV',
  EXPORT_PICKUPS_AS_CSV_DONE: 'EXPORT_PICKUPS_AS_CSV_DONE',
  GET_ALL_ACTIVE_ROUTES: 'GET_ALL_ACTIVE_ROUTES',
  GET_ALL_ACTIVE_ROUTES_RECEIVED: 'GET_ALL_ACTIVE_ROUTES_RECEIVED',
  RETURN_DELIVERIES_TO_BUSINESS: 'RETURN_DELIVERIES_TO_BUSINESS',
  RETURN_DELIVERIES_TO_BUSINESS_DONE: 'RETURN_DELIVERIES_TO_BUSINESS_DONE',
  ADD_DELIVERIES_TO_ROUTE: 'ADD_DELIVERIES_TO_ROUTE',
  ADD_DELIVERIES_TO_ROUTE_DONE: 'ADD_DELIVERIES_TO_ROUTE_DONE',
  TERMINATE_DELIVERY: 'TERMINATE_DELIVERY',
  TERMINATE_DELIVERY_DONE: 'TERMINATE_DELIVERY_DONE',
  RESET_DELIVERED_DELVERIES: 'RESET_DELIVERED_DELVERIES',
  RESET_DELIVERED_DELVERIES_DONE: 'RESET_DELIVERED_DELVERIES_DONE',
  GET_LEADERS: 'GET_LEADERS',
  GET_LEADERS_RECEIVED: 'GET_LEADERS_RECEIVED',
  GET_CLERKS: 'GET_CLERKS',
  GET_CLERKS_RECEIVED: 'GET_CLERKS_RECEIVED',
  FETCH_CITIES: 'FETCH_CITIES',
  FETCH_CITIES_RECEIVED: 'FETCH_CITIES_RECEIVED',
  FETCH_ZONES: 'FETCH_ZONES',
  FETCH_ZONES_RECEIVED: 'FETCH_ZONES_RECEIVED',
  PRINT_SMALL_AIR_WAY_BILL: 'PRINT_SMALL_AIR_WAY_BILL',
  PRINT_SMALL_AIR_WAY_BILL_DONE: 'PRINT_SMALL_AIR_WAY_BILL_DONE',
  CLEAR_MONEY_DEBRIEF_REPORT: 'CLEAR_MONEY_DEBRIEF_REPORT',
  GET_MONEY_DEBRIEF_REPORT: 'GET_MONEY_DEBRIEF_REPORT',
  GET_MONEY_DEBRIEF_REPORT_RECEIVED: 'GET_MONEY_DEBRIEF_REPORT_RECEIVED',
  GET_MONEY_DEBRIEF_REPORT_FILTERED:
    'GET_MONEY_DEBRIEF_REPORT_FILTERED_RECEIVED',
  GET_MONEY_DEBRIEF_REPORT_FILTERED_RECEIVED:
    'GET_MONEY_DEBRIEF_REPORT_FILTERED_RECEIVED',
  GET_HUB_TRANSFER_REPORT: 'GET_HUB_TRANSFER_REPORT',
  GET_HUB_TRANSFER_REPORT_RECEIVED: 'GET_HUB_TRANSFER_REPORT_RECEIVED',
  GET_HUB_TRANSFER_REPORT_CSV: 'GET_HUB_TRANSFER_REPORT_CSV',
  GET_HUB_TRANSFER_REPORT_CSV_RECEIVED: 'GET_HUB_TRANSFER_REPORT_CSV_RECEIVED',
  CLEAR_HUB_TRANSFER_REPORT: 'CLEAR_HUB_TRANSFER_REPORT',
  GET_STARS_BY_HUB: 'GET_STARS_BY_HUB',
  GET_STARS_BY_HUB_RECEIVED: 'GET_STARS_BY_HUB_RECEIVED',
  GET_STAR_DAY_REPORT: 'GET_STAR_DAY_REPORT',
  GET_STAR_DAY_REPORT_RECEIVED: 'GET_STAR_DAY_REPORT_RECEIVED',
  GET_STAR_LIABILITIES: 'GET_STAR_LIABILITIES',
  GET_STAR_LIABILITIES_RECEIVED: 'GET_STAR_LIABILITIES_RECEIVED',
  CLEAR_STAR_DEBRIEF_REPORT: 'CLEAR_STAR_DEBRIEF_REPORT',
  GET_CURRENT_HUB_INFO: 'GET_CURRENT_HUB_INFO',
  GET_CURRENT_HUB_INFO_RECEIVED: 'GET_CURRENT_HUB_INFO_RECEIVED',
  GET_INCOMING_TRANSFER_ITEMS: 'GET_INCOMING_TRANSFER_ITEMS',
  GET_INCOMING_TRANSFER_ITEMS_RECEIVED: 'GET_INCOMING_TRANSFER_ITEMS_RECEIVED',
  GET_ROUTES_LIST: 'GET_ROUTES_LIST',
  GET_ROUTES_LIST_RECEIVED: 'GET_ROUTES_LIST_RECEIVED',
  GET_ALL_ONLINE_STARS: 'GET_ALL_ONLINE_STARS',
  GET_ALL_ONLINE_STARS_RECEIVED: 'GET_ALL_ONLINE_STARS_RECEIVED',
  GET_ROUTE_RUN_SHEET: 'GET_ROUTE_RUN_SHEET',
  GET_ROUTE_RUN_SHEET_RECEIVED: 'GET_ROUTE_RUN_SHEET_RECEIVED',
  GET_STARS_FILTERED: 'GET_STARS_FILTERED',
  GET_STARS_FILTERED_RECEIVED: 'GET_STARS_FILTERED_RECEIVED',
  GET_PICKUPS_REQUEST: 'GET_PICKUPS_REQUEST',
  GET_PICKUPS_REQUEST_RECEIVED: 'GET_PICKUPS_REQUEST_RECEIVED',
  PICKUPS_REQUEST_DELETED_ACTION: 'PICKUPS_REQUEST_DELETED_ACTION',
  PICKUPS_REQUEST_DELETED_ACTION_DONE: 'PICKUPS_REQUEST_DELETED_ACTION_DONE',
  GET_CURRENT_HUB_ID: 'GET_CURRENT_HUB_ID',
  GET_CURRENT_HUB_ID_RECIEVED: 'GET_CURRENT_HUB_ID_RECIEVED',
  GET_ROUTE_REQUEST: 'GET_ROUTE_REQUEST',
  GET_ROUTE_REQUEST_RECEIVED: 'GET_ROUTE_REQUEST_RECEIVED',
  ROUTE_REQUEST_ADD_ACTION: 'ROUTE_REQUEST_ADD_ACTION',
  ROUTE_REQUEST_ADD_ACTION_DONE: 'ROUTE_REQUEST_ADD_ACTION_DONE',

  GET_HUBS_REQUEST: 'GET_HUBS_REQUEST',
  GET_HUBS_REQUEST_RECEIVED: 'GET_HUBS_REQUEST_RECEIVED',
  HUBS_REQUEST_ADD_ACTION: 'HUBS_REQUEST_ADD_ACTION',
  HUBS_REQUEST_ADD_ACTION_DONE: 'HUBS_REQUEST_ADD_ACTION_DONE',

  GET_STARS_REQUEST: 'GET_STARS_REQUEST',
  GET_STARS_REQUEST_RECEIVED: 'GET_STARS_REQUEST_RECEIVED',

  GET_BUSINESS_NAME_REQUEST: 'GET_BUSINESS_NAME_REQUEST',
  GET_BUSINESS_NAME_REQUEST_RECEIVED: 'GET_BUSINESS_NAME_REQUEST_RECEIVED',

  GET_DETAILS_PICKUP: 'GET_DETAILS_PICKUP',
  GET_DETAILS_PICKUP_RECEIVED: 'GET_DETAILS_PICKUP_RECEIVED',
  GET_SEARCH_PICKUP: 'GET_SEARCH_PICKUP',
  GET_SEARCH_PICKUP_RECEIVED: 'GET_SEARCH_PICKUP_RECEIVED',
  CREATE_PICKUPS: 'CREATE_PICKUPS',
  CREATE_PICKUPS_RECEIVED: 'CREATE_PICKUPS_RECEIVED',
  EDIT_PICKUPS_REQUEST: 'EDIT_PICKUPS_REQUEST',
  EDIT_PICKUPS_REQUEST_RECIVED: 'EDIT_PICKUPS_REQUEST_RECIVED',
  GET_BUSINESS_REQUEST: 'GET_BUSINESS_REQUEST',
  GET_SINGLE_PICKUPS_REQUEST: 'GET_SINGLE_PICKUPS_REQUEST',
  GET_SINGLE_PICKUPS_RECEIVED: 'GET_SINGLE_PICKUPS_RECEIVED',
  GET_ALL_LOCATION_PICKUPS_RECEIVED: 'GET_ALL_LOCATION_PICKUPS_RECEIVED',
  GET_ALL_LOCATION_PICKUPS_REQUEST: 'GET_ALL_LOCATION_PICKUPS_REQUEST',

  GET_ALL_USERS_ROLES_SALES: 'GET_ALL_USERS_ROLES_SALES',
  GET_ALL_USERS_ROLES_SALES_RECEIVED: 'GET_ALL_USERS_ROLES_SALES_RECEIVED',

  SET_ACCOUNT_OR_SALES_OR_NOTE: 'SET_ACCOUNT_OR_SALES_OR_NOTE',
  SET_ACCOUNT_OR_SALES_OR_NOTE_RECEIVED:
    'SET_ACCOUNT_OR_SALES_OR_NOTE_RECEIVED',
  CLEAN: 'CLEAN',
  RESET_DELIVERED_DELIVERY: 'RESET_DELIVERED_DELIVERY',
  RESET_DELIVERED_DELIVERY_DONE: 'RESET_DELIVERED_DELIVERY_DONE',

  GET_WALLAT: 'GET_WALLAT',
  GET_WALLAT_RECEIVED: 'GET_WALLAT_RECEIVED',

  PLAY_BEEP: 'PLAY_BEEP',
  PLAY_WARNING: 'PLAY_WARNING',

  GET_AR_REPORTS: 'GET_AR_REPORTS',
  SET_AR_REPORTS: 'SET_AR_REPORTS',
  GET_AR_REPORT_DETAILS: 'GET_AR_REPORT_DETAILS',
  SET_AR_REPORT_DETAILS: 'SET_AR_REPORT_DETAILS',
  GET_AR_REPORT_CSV: 'GET_AR_REPORT_CSV',
  SET_AR_REPORT_CSV: 'SET_AR_REPORT_CSV',
  SET_GENERATE_CSV: 'SET_GENERATE_CSV',
  UPLOAD_AR_REPORT: 'UPLOAD_AR_REPORT',
  SET_UPLOAD_AR_REPORT: 'SET_UPLOAD_AR_REPORT',
  GET_AR_REPORT: 'GET_AR_REPORT',
  SET_AR_REPORT: 'SET_AR_REPORT',
  GET_AR_REPORT_CSV_DETAILS: 'GET_AR_REPORT_CSV_DETAILS',
  SET_AR_REPORT_CSV_DETAILS: 'SET_AR_REPORT_CSV_DETAILS',
  GET_SUMMARY_REPORTS: 'GET_SUMMARY_REPORTS',
  SET_SUMMARY_REPORTS: 'SET_SUMMARY_REPORTS',
  GET_SUMMARY_REPORT_CSV: 'GET_SUMMARY_REPORT_CSV',
  SET_SUMMARY_REPORT_CSV: 'SET_SUMMARY_REPORT_CSV',
  GET_AR_GENERATE_REPORT: 'GET_AR_GENERATE_REPORT',
  SET_AR_GENERATE_REPORT: 'SET_AR_GENERATE_REPORT',
  GET_REMOVE_AR_REPORTS: 'GET_REMOVE_AR_REPORTS',
  GET_REMOVE_GENERATE_REPORT: 'GET_REMOVE_GENERATE_REPORT',
};

export default actionTypes;
