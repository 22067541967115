import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { createTicket } from 'services/hubs';
import { getTicketsFields } from 'services/support-tickets';
import { SUPPORT_TICKET_FIELDS } from 'constants/support-tickets';

import { notify } from 'components/Notify/Notify';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';

import './CreateTicketModal.less';

const CreateTicketModal = ({ intl, close, onSuccess, delivery, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [descTextValue, setDescTextValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [assignees, setAssignees] = useState([]);

  useEffect(() => {
    getTicketCategories();
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        ...values,
        attachments: fileList
      };

      await createTicket(payload);

      notify(
        intl.formatMessage({
          id: 'support_tickets.create_ticket.notify_messages.ticket_created_successfuly'
        }),
        'success'
      );

      close();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getTicketCategories = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTicketsFields();

      const categoriesAndSubCategories = data.find(
        (field) => field.label === SUPPORT_TICKET_FIELDS.CATEGORY
      );
      const assignees = data.find(
        (field) => field.label === SUPPORT_TICKET_FIELDS.GROUP
      );

      const categoriesKeys = Object.keys(
        categoriesAndSubCategories.choices || []
      );

      const categories = categoriesKeys.reduce((acc, category) => {
        return { ...acc, [category]: category };
      }, {});

      const subCategories = categoriesKeys.reduce((acc, category) => {
        return {
          ...acc,
          [category]: Object.keys(
            categoriesAndSubCategories.choices[category]
          ).map((subCategory) => ({ label: subCategory, value: subCategory }))
        };
      }, {});

      setCategories(formatSelectValues(categories));
      setSubCategories(subCategories || {});
      setAssignees(formatSelectValues(assignees.choices));
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const formatSelectValues = (data) => {
    if (isEmpty(data)) {
      return [];
    }

    const labels = Object.keys(data || []);
    return labels.map((label) => ({ label, value: data[label] }));
  };

  const descriptionTextHandle = ({ target: { value } }) => {
    setDescTextValue(value);
  };

  return (
    <Modal
      {...props}
      onCancel={close}
      wrapClassName="br-support-tickets__create-ticket__Modal"
      centered
      width={null}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            className="button-md"
            type="basic"
            disabled={isLoading}
            onClick={close}
          />
          <BRButton
            label={intl.formatMessage({
              id: 'common.confirm'
            })}
            className="button-md"
            type="primary"
            htmlType="submit"
            form="Create ticket form"
            disabled={isLoading}
          />
        </>
      }
    >
      <LoadingWrapper loading={isLoading}>
        <BRContentHeader
          title={intl.formatMessage({
            id: 'support_tickets.header_title'
          })}
          subtitle={intl.formatMessage({
            id: 'support_tickets.header_subtitle'
          })}
          isInternalComponent
        />
        <Form
          layout="vertical"
          id="Create ticket form"
          onFinish={onFinish}
          initialValues={{ trackingNumber: delivery?.trackingNumber }}
        >
          <div className="br-support-tickets__create-ticket">
            <div className="br-support-tickets__create-ticket__form">
              <Form.Item
                name="trackingNumber"
                hasFeedback
                label={
                  <>
                    {intl.formatMessage({
                      id: 'support_tickets.create_ticket.labels.tracking_no'
                    })}
                    {!delivery?.trackingNumber && (
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'support_tickets.create_ticket.tooltips.tracking_number'
                          })}
                          getPopupContainer={(trigger) =>
                            trigger.parentElement.parentElement.parentElement
                              .parentElement
                          }
                        >
                          <TooltipIcon />
                        </Tooltip>
                      </span>
                    )}
                  </>
                }
              >
                <Input disabled={delivery?.trackingNumber} />
              </Form.Item>
              <div className="br-form-row">
                <Form.Item
                  rules={[{ required: true }]}
                  label={intl.formatMessage({
                    id: 'support_tickets.create_ticket.labels.category'
                  })}
                  name="category"
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'support_tickets.create_ticket.place_holder.category'
                    })}
                    showSearch
                    options={categories}
                    onChange={setSelectedCategory}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  rules={[{ required: true }]}
                  label={intl.formatMessage({
                    id: 'support_tickets.create_ticket.labels.sub_category'
                  })}
                  name="subCategory"
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'support_tickets.create_ticket.place_holder.sub_category'
                    })}
                    showSearch
                    disabled={!selectedCategory}
                    options={subCategories[selectedCategory]}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  rules={[{ required: true }]}
                  label={intl.formatMessage({
                    id: 'support_tickets.create_ticket.labels.assignee'
                  })}
                  name="assigneeGroup"
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'support_tickets.create_ticket.place_holder.assignee'
                    })}
                    options={assignees}
                  />
                </Form.Item>
              </div>
              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    max: 100,
                    label: intl.formatMessage({
                      id: 'support_tickets.create_ticket.labels.ticket_subject'
                    })
                  }
                ]}
                label={intl.formatMessage({
                  id: 'support_tickets.create_ticket.labels.ticket_subject'
                })}
                name="reason"
              >
                <Input placeholder="" />
              </Form.Item>
              <div className="br-support-tickets__create-ticket__form_description-field">
                <label>
                  <span className="br-support-tickets__create-ticket__form_label">
                    {intl.formatMessage({
                      id: 'support_tickets.create_ticket.labels.description'
                    })}
                  </span>
                </label>
                {descTextValue?.length >= 500 && (
                  <span className="br-support-tickets__create-ticket__form-word-count">
                    {`${descTextValue.length} / 500`}
                  </span>
                )}
              </div>
              <Form.Item
                className="br-support-tickets__create-ticket__form_description"
                name="description"
                rules={[
                  {
                    required: true,
                    label: intl.formatMessage({
                      id: 'support_tickets.create_ticket.labels.description'
                    })
                  }
                ]}
              >
                <Input.TextArea
                  placeholder={intl.formatMessage({
                    id: `support_tickets.create_ticket.place_holder.description`
                  })}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={500}
                  value={descTextValue}
                  onChange={descriptionTextHandle}
                />
              </Form.Item>
            </div>
            <div className="br-support-tickets__create-ticket-upload-dialog ">
              <UploadAttachment
                onChange={({ attachments }) => {
                  setFileList(attachments);
                }}
                encodeBase64
              />
            </div>
          </div>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(CreateTicketModal);
