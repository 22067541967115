import { useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  exportFulfillmentBusinesses,
  getFulfillmentBusinesses
} from 'services/fulfillment';
import { downloadAsXlsx } from 'utils';
import { getFulfillmentBusinessTabsPath } from 'constants/fulfillment';
import { TABLE_PAGE_SIZE_OPTIONS } from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import './BusinessesTable.less';

const BusinessesTable = ({ intl }) => {
  const [businessesCount, setBusinessesCount] = useState(0);
  const [sharedPayload, setSharedPayload] = useState({});
  const [selectedSearchOption, setSelectedSearchOption] =
    useState('businessName');

  const columns = [
    {
      dataIndex: '_id',
      title: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.columns.id'
      }),
      render: (id) => (
        <Link target="_blank" to={`${getFulfillmentBusinessTabsPath(id)}/info`}>
          {id}
        </Link>
      )
    },
    {
      dataIndex: 'name',
      title: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.columns.business_name'
      })
    },
    {
      dataIndex: 'phone',
      title: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.columns.contact_number'
      })
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.columns.contact_email'
      })
    },
    {
      dataIndex: 'cityName',
      title: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.columns.city'
      })
    }
  ];

  const searchOptions = [
    {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.search_options.business_name'
      }),
      value: 'businessName'
    },
    {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.search_options.business_id'
      }),
      value: 'businessId'
    }
  ];

  const handleGetBusinesses = async ({ page, limit, searchInputText }) => {
    const searchValue = searchInputText?.trim();
    const payload = {
      page,
      limit,
      ...(searchValue && {
        [selectedSearchOption]: searchInputText?.trim()
      })
    };
    setSharedPayload(payload);

    try {
      const {
        data: { business = [], count = 0 }
      } = await getFulfillmentBusinesses(payload);
      setBusinessesCount(count);

      return {
        list: business,
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportBusinesses = async () => {
    try {
      const data = await exportFulfillmentBusinesses(sharedPayload);
      const fileName = `Fulfillment_Businesses_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <BRTable
      className="br-fulfillment-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_businesses.businesses_table.title'
        },
        {
          count: businessesCount
        }
      )}
      columns={columns}
      listFunction={handleGetBusinesses}
      searchPlaceholder={intl.formatMessage({
        id: 'fulfillment_businesses.businesses_table.search_placeholder'
      })}
      exportListFileFunction={handleExportBusinesses}
      showFilter={false}
      showSearch
      searchOptions={searchOptions}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
    />
  );
};

export default injectIntl(BusinessesTable);
