import React from 'react';
import { Input } from 'antd';
import BRFormInputCounter from 'components/BRFormInputCounter/BRFormInputCounter';

class BRFormInputNumber extends React.Component {
  state = {
    value: null,
    regex: /^\d+$/,
    negativeRegex: /^(\-(\d*))$/,
    floatNegativeRegex: /^([+-]?(\d*)[.])?(\d*)+$/,
    floatRegex: /^((\d*)[.])?(\d*)+$/
  };

  handleOnChange = (evt) => {
    const {
      filedName,
      handleOnChangeForm,
      acceptFloat = false,
      acceptNegative,
      parent
    } = this.props;
    const { regex, negativeRegex, floatRegex, floatNegativeRegex } = this.state;
    const { value } = evt.currentTarget;

    const regexValue = acceptFloat
      ? acceptNegative
        ? regex.test(value) ||
          negativeRegex.test(value) ||
          floatRegex.test(value) ||
          floatNegativeRegex.test(value)
        : regex.test(value) || floatRegex.test(value)
      : acceptNegative
      ? regex.test(value) || negativeRegex.test(value)
      : regex.test(value);
    if (regexValue) {
      handleOnChangeForm(filedName, value, parent);
      this.setState({ value });
      return true;
    }
    if (!value.length) {
      handleOnChangeForm(filedName, value, parent);
      this.setState({ value });
      return true;
    }
    return false;
  };

  render() {
    const {
      maxLength,
      placeholder,
      label,
      isWithCounter,
      optional,
      autoComplete,
      value,
      disabled,
      refName,
      handleOnPaste,
      inputProps,
      addonBefore,
      onChange,
      ...rest
    } = this.props;

    return (
      <>
        {isWithCounter ? (
          <BRFormInputCounter
            onChange={this.handleOnChange}
            label={label}
            maxCount={maxLength}
            optional={optional}
            value={value}
          >
            <Input
              type="text"
              maxLength={maxLength}
              placeholder={placeholder}
              autoComplete={autoComplete}
              disabled={disabled}
            />
          </BRFormInputCounter>
        ) : (
          <Input
            value={value || null}
            onChange={this.handleOnChange}
            maxLength={maxLength}
            placeholder={placeholder}
            autoComplete={autoComplete}
            // disabled={disabled}
            onPaste={handleOnPaste}
            ref={refName}
            addonBefore={addonBefore || null}
            // {...inputProps}
            {...rest}
          />
        )}
      </>
    );
  }
}

export default BRFormInputNumber;
