import React from 'react';
// import { connect } from 'react-redux';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import messages from 'messages';
import {
  flattenMessages,
  getLocaleFromLocalStorage,
  // setLocaleInLocalStorage,
  changeDocumentDirection
} from 'utils/intl-wrapper';
// import { setLocale } from 'actions/app';

const initialLocale = 'en';
const locale = getLocaleFromLocalStorage();
export const cache = createIntlCache();
/** You can use this variable in other files even after reassigning it. */
export let intl = createIntl(
  { locale: initialLocale, messages: flattenMessages(messages[locale]) },
  cache
);

export let customizedIntl = (localeVal) =>
  createIntl(
    { locale: localeVal, messages: flattenMessages(messages[localeVal]) },
    cache
  );

export let fmt = intl.formatMessage;
export let customizedFmt = (value) => {
  return customizedIntl(value).formatMessage;
};

class IntlWrapper extends React.Component {
  componentDidMount() {
    const locale = getLocaleFromLocalStorage();
    // if (!locale) {
    //   setLocaleInLocalStorage('en');
    // } else {
    // const { setLocalization } = this.props;
    // setLocalization(locale);
    document.documentElement.lang = locale;
    changeDocumentDirection(locale);
    // }
  }

  render() {
    // const { children, locale } = this.props;
    const { children } = this.props;
    const locale = getLocaleFromLocalStorage();

    intl = createIntl(
      { locale, messages: flattenMessages(messages[locale]) },
      cache
    );
    fmt = intl.formatMessage;
    // const x = locale;
    // const m1 = messages;
    // const m = messages[locale];
    // document.documentElement.lang = locale;

    // changeDocumentDirection(locale);

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

// const mapDispatchToProps = (dispatch) => ({
//   setLocalization: (locale) => dispatch(setLocale(locale))
// });

// const mapStateToProps = ({ app: { locale } }) => {
//   return { locale };
// };

export default IntlWrapper;
// export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper);
