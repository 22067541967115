import dayjs from 'dayjs';

import { DELIVERY_STATES_CODES, PUDO_PROVIDER } from 'constants/shipments';
import {
  beforeChangedToRto,
  deliveryAttemptsCount,
  getAddressUpdates,
  orderIsCollected
} from 'utils/order-logs';
import DeliveryStateFormatter from 'utils/orderStatesLogsFormatter';
import { COUNTRY_CURRENCY } from './helper';
import { BUSINESS_ADMIN } from 'common/aclMatrix';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as WhatsappIcon } from 'assets/bosta-icons/whatsapp.svg';

export const ACTION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  WHATSAPP_SCHEDULED_DATE_LOG: 'WHATSAPP_SCHEDULED_DATE_LOG',
  WHATSAPP_ADDRESS_LOG: 'WHATSAPP_ADDRESS_LOG',
  CHANGE_REQUEST_REJECTED: 'CHANGE_REQUEST_REJECTED',
  CHANGE_REQUEST_CREATED: 'CHANGE_REQUEST_CREATED',
  REPACKING: 'Repacking'
};

export const ACTION_LEVEL = {
  TRACKING_SERVICE_ACTION_LEVEL: 'TRACKING_SERVICE_ACTION_LEVEL'
};

export const OFD_CODES = [40, 41];
export const FINAL_STATE_CODES = [45, 46];
export const INVESTIGATION_EXCEPTIONS_CODES = [26, 27, 28, 29, 30];

export const RTO = 'RTO';
export const CANCELLATION_REQUEST = 'CANCELLATION_REQUEST';
export const DROP_OFF_ADDRESS = 'dropOffAddress';
export const DROP_OFF_ADDRESS_WORK_ADDRESS = 'dropOffAddress_isWorkAddress';
export const MARKETPLACE = 'MARKETPLACE';
const OTP = 'OTP';
export const DATE_FORMAT = 'D MMM';
export const DATE_FORMAT_WITH_DAY = 'ddd, D MMM';

const DELIVERY_TYPES_CODES = {
  SEND: 10,
  CASH_COLLECTION: 15,
  RETURN_TO_ORIGIN: 20,
  CUSTOMER_RETURN_PICKUP: 25,
  EXCHANGE: 30,
  SIGN_AND_RETURN: 35,
  FXF_SEND: 10
};

export const ORDER_LOGS = ({ delivery, log, validLogs, logIndex }) => [
  {
    actionType: ACTION_TYPES.CREATE,
    keys: [],
    excludedKeys: [],
    condition: true,
    log: fmt({
      id: `orders.states_logs.logs.order_created`
    }),
    date:
      delivery.creationSrc === MARKETPLACE &&
      delivery?.draftOrderInfo?.createdAt
        ? delivery?.draftOrderInfo?.createdAt
        : delivery.createdAt,
    extraInfo: fmt(
      {
        id: `orders.shipment_details.action_by`
      },
      {
        name:
          delivery?.creationSrc === MARKETPLACE
            ? delivery?.receiver?.fullName
            : log?.takenBy?.userName
      }
    )
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['pendingPickup'],
    excludedKeys: [],
    condition: true,
    log: fmt({
      id: `orders.states_logs.logs.pickup_scheduled`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['collectedFromBusiness', 'routeId'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after >=
      DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS,
    log: delivery?.state?.receivedAtFulfillmentCenter
      ? fmt({
          id: `orders.states_logs.logs.picked_up_fulfillment`
        })
      : fmt({
          id: `orders.states_logs.logs.picked_up`
        }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['firstHub_warehouse'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE,
    log: `${fmt({
      id: `orders.states_logs.logs.received_at_warehouse`
    })}`,
    date: log?.time,
    location: log?.actionsList?.firstHub_warehouse?.after?.name
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: ['firstHub_warehouse'],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE,
    log: fmt({
      id: `orders.states_logs.logs.${
        delivery?.integrationInfo?.provider === PUDO_PROVIDER
          ? 'in_transit'
          : 'received_at_warehouse'
      }`
    }),
    date: log?.time,
    location:
      log?.actionsList?.warehouseTransit?.before?.destinationWarehouse?.name ||
      log?.actionsList?.state_receivedAtWarehouse_warehouse?.after?.name ||
      delivery?.state?.receivedAtWarehouse?.warehouse?.name
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['inTransit', 'warehouseTransit'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.IN_TRANSIT_BETWEEN_HUBS,
    log: `${fmt({
      id: `orders.states_logs.logs.in_transit`
    })}`,
    date: log?.time,
    location:
      log?.actionsList?.warehouseTransit?.after?.destinationWarehouse?.name
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
        DELIVERY_STATES_CODES.OUT_FOR_DELIVERY &&
      delivery.type.code !== DELIVERY_TYPES_CODES.RETURN_TO_ORIGIN,
    log: fmt({
      id: `orders.states_logs.${
        delivery?.integrationInfo?.provider === PUDO_PROVIDER
          ? 10
          : delivery.type.code
      }.${
        delivery?.integrationInfo?.provider === PUDO_PROVIDER
          ? 'pudo_at_locker'
          : '41'
      }`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
        DELIVERY_STATES_CODES.OUT_FOR_DELIVERY &&
      delivery.type.code === DELIVERY_TYPES_CODES.RETURN_TO_ORIGIN,
    log: fmt({
      id: `orders.states_logs.${
        beforeChangedToRto(delivery, log)
          ? DELIVERY_TYPES_CODES[delivery?.type_before]
          : 20
      }.41`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.OUT_FOR_COLLECTION,
    log: fmt({
      id: `orders.states_logs.15.40`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.DELIVERED,
    log: fmt({
      id: `orders.states_logs.${delivery.type.code}.45`
    }),
    date: log?.time
  },

  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['state_exception'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.EXCEPTION,
    log:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.EXCEPTION &&
      exceptionsMapping(delivery, log)[
        beforeChangedToRto(delivery, log)
          ? DELIVERY_TYPES_CODES[delivery?.type_before]
          : delivery.type.code
      ][log?.actionsList?.state_exception?.after?.code],
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['state_canceled'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.UNCOVERED_CITY,
    log: fmt({
      id: 'orders.states_logs.logs.uncovered_address'
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
        DELIVERY_STATES_CODES.ROUTE_ASSIGNED &&
      !orderIsCollected({ logs: validLogs, logIndex }),
    log: fmt({
      id: `orders.states_logs.${delivery.type.code}.20`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
        DELIVERY_STATES_CODES.ROUTE_ASSIGNED &&
      orderIsCollected({ logs: validLogs, logIndex }),
    log: fmt({
      id: `orders.states_logs.20.20`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.OUT_FOR_PICKUP,
    log: fmt({
      id: `orders.states_logs.${delivery.type.code}.22`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS,
    log: fmt({
      id: 'orders.states_logs.25.46'
    }),
    date: log?.time,
    extraInfo:
      log?.actionsList?.holder_handedBy?.after === OTP &&
      `${fmt({ id: `orders.shipment_details.received_by` })} ${
        log?.actionsList?.holder_name?.after
      } - ${log?.actionsList?.holder_phone?.after}`
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.PICKED_UP_FROM_CONSIGNEE,
    log: fmt({
      id: `orders.states_logs.${delivery.type.code}.23`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.CREATE,
    keys: [],
    excludedKeys: [],
    condition:
      delivery.type.code === DELIVERY_TYPES_CODES.CASH_COLLECTION &&
      delivery?.attemptsCount,
    log: `${fmt({
      id: `orders.states_logs.15.8`
    })}`,
    date: delivery?.createdAt,
    location: delivery?.assignedHub?.name
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['changedToRTODate'],
    excludedKeys: [],
    condition: log?.actionsList?.type?.after === RTO,
    log:
      deliveryAttemptsCount(delivery) >= 3
        ? fmt({
            id: `orders.states_logs.20.1`
          })
        : delivery?.state?.businessActionTaken === 2
        ? fmt({
            id: `orders.states_logs.exceptions.20.return4`
          })
        : fmt({
            id: `orders.states_logs.20.${
              delivery?.state?.receivedAtFulfillmentCenter ? '13' : '12'
            }`
          }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.ARCHIVED,
    log: fmt({
      id: 'orders.states_logs.logs.order_archived'
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.LOST,
    log: fmt({
      id: 'orders.states_logs.logs.order_lost'
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.DAMAGED,
    log: fmt({
      id: 'orders.states_logs.logs.order_damaged'
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after ===
      DELIVERY_STATES_CODES.INVESTIGATION,
    log: INVESTIGATION_EXCEPTIONS_CODES.includes(
      delivery?.state?.lastExceptionCode
    )
      ? fmt({
          id: `orders.states_logs.return_investigation`
        })
      : fmt({
          id: `orders.states_logs.investigation`
        }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: ['linkedTickets'],
    condition: [
      DELIVERY_STATES_CODES.TERMINATED,
      DELIVERY_STATES_CODES.COLLECTION_FAILED
    ].includes(log?.actionsList?.state_code?.after),
    log: fmt({
      id: `orders.states_logs.${delivery.type.code}.${
        !delivery?.attemptsCount
          ? '48_2'
          : delivery?.attemptsCount >= 3
          ? '48_1'
          : '48'
      }`
    }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['scheduledAt'],
    excludedKeys: ['sla_e2eSla', 'sla_orderSla', 'state_exception', 'sla'],
    condition: true,
    log: `${fmt({
      id: `orders.states_logs.rescheduled.${
        log?.takenBy?.userRole === BUSINESS_ADMIN
          ? 'order_rescheduled_by_business'
          : log?.actionLevel === ACTION_LEVEL.TRACKING_SERVICE_ACTION_LEVEL
          ? 'whatsapp_order_rescheduled'
          : 'order_rescheduled_by_cs'
      }`
    })} ${dayjs(log?.actionsList?.scheduledAt?.after).format('DD MMM YYYY')} ${
      log?.actionsList?.linkedTickets
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })} ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.WHATSAPP_SCHEDULED_DATE_LOG,
    keys: ['scheduledAt'],
    excludedKeys: ['sla_e2eSla', 'sla_orderSla', 'state_exception', 'sla'],
    condition: true,
    log: `${fmt({
      id: 'orders.states_logs.rescheduled.whatsapp_order_rescheduled'
    })} ${dayjs(log?.actionsList?.scheduledAt?.after).format('DD MMM YYYY')}`,
    date: log?.time,
    icon: WhatsappIcon
  },
  {
    actionType: ACTION_TYPES.WHATSAPP_ADDRESS_LOG,
    keys: [],
    excludedKeys: ['state_code'],
    condition: Object.keys(log.actionsList || []).filter((action) =>
      action.includes('dropOffAddress')
    ).length,
    log: getAddressUpdates(log),
    date: log?.time,
    icon: WhatsappIcon
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['allowToOpenPackage'],
    excludedKeys: [],
    condition: true,
    log: `${fmt({
      id: `orders.states_logs.order_details_updates.order_${
        log?.actionsList?.allowToOpenPackage?.after ? 'allow' : 'disallow'
      }_open_${
        log?.takenBy?.userRole === BUSINESS_ADMIN ? 'business_update' : 'update'
      }`
    })} ${
      log?.actionsList?.linkedTickets
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })} ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['receiver_phone'],
    excludedKeys: [],
    condition: true,
    log: `${fmt({
      id: `orders.states_logs.order_details_updates.order_phone_${
        log?.takenBy?.userRole === BUSINESS_ADMIN ? 'business_update' : 'update'
      }`
    })} ${log?.actionsList?.receiver_phone?.after} ${
      log?.actionsList?.linkedTickets
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })} ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['receiver_secondPhone'],
    excludedKeys: [],
    condition: true,
    log: `${fmt({
      id: `orders.states_logs.order_details_updates.order_phone_${
        log?.takenBy?.userRole === BUSINESS_ADMIN ? 'business_update' : 'update'
      }`
    })} ${log?.actionsList?.receiver_secondPhone?.after} ${
      log?.actionsList?.linkedTickets
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })} ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: ['state_terminated'],
    condition:
      log?.actionsList?.linkedTickets?.after?.type === CANCELLATION_REQUEST,
    log: `${fmt({
      id: `orders.states_logs.logs.cancellation_request_by_${
        log?.actionsList?.linkedTickets?.after?.directBusinessRequest
          ? 'business'
          : 'cs'
      }`
    })} ${
      log?.actionsList?.linkedTickets?.after?.number
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })}: ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['state_terminated'],
    excludedKeys: [],
    condition:
      log?.actionsList?.linkedTickets?.after?.type === CANCELLATION_REQUEST,
    log: `${fmt({
      id: `orders.states_logs.logs.order_cancelled_by_cs`
    })} ${
      log?.actionsList?.linkedTickets?.after?.number
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })}: ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['tickets'],
    excludedKeys: [],
    condition:
      log?.actionsList?.state_code?.after === DELIVERY_STATES_CODES.ON_HOLD,
    log: fmt({ id: 'orders.states_logs.logs.rejection_ticket' }),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: [],
    excludedKeys: ['state_code'],
    condition: Object.keys(log.actionsList || []).filter((action) =>
      action.includes('dropOffAddress')
    ).length,
    log: getAddressUpdates(log),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.UPDATE,
    keys: ['cod_amount'],
    excludedKeys: [],
    condition: true,
    log: `${fmt(
      {
        id: `orders.states_logs.logs.${
          log?.takenBy?.userRole === BUSINESS_ADMIN
            ? 'cod_amount_updated_by_business'
            : 'cod_amount_updated'
        }`
      },
      {
        amount: fmt(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: log?.actionsList?.cod_amount?.after }
        )
      }
    )} ${
      log?.actionsList?.linkedTickets
        ? `(${fmt({
            id: `orders.states_logs.rescheduled.ticket_ref_number`
          })} ${log?.actionsList?.linkedTickets?.after?.number})`
        : ''
    }`,
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.CHANGE_REQUEST_REJECTED,
    keys: [],
    excludedKeys: [],
    condition: true,
    log: fmt(
      { id: 'orders.states_logs.logs.cod_change_request_rejected' },
      {
        rejectionReason: log?.actionsList?.rejectionReason
      }
    ),
    date: log?.time
  },
  {
    actionType: ACTION_TYPES.CHANGE_REQUEST_CREATED,
    keys: [],
    excludedKeys: [],
    condition: true,
    log: fmt({ id: 'orders.states_logs.logs.cod_change_request_created' }),
    date: log?.time
  }
];

const exceptionsMapping = (delivery, log) => {
  return {
    10: {
      1: fmt({
        id: `orders.states_logs.exceptions.10.1`
      }),
      2: fmt({
        id: `orders.states_logs.exceptions.10.2`
      }),
      3: fmt(
        {
          id: `orders.states_logs.exceptions.10.3`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      4: fmt({
        id: `orders.states_logs.exceptions.10.${
          delivery?.state?.waitingForBusinessAction ? 'new4' : 'accept4'
        }`
      }),
      5: fmt({
        id: `orders.states_logs.exceptions.10.5`
      }),
      13: fmt({
        id: `orders.states_logs.exceptions.10.${
          delivery?.state?.waitingForBusinessAction
            ? 'address5'
            : delivery?.state?.businessActionTaken
            ? 'address_change_5'
            : 'address_not_changes_5'
        }`
      }),
      14: fmt({
        id: `orders.states_logs.exceptions.10.${
          delivery?.state?.waitingForBusinessAction
            ? 'phone5'
            : delivery?.state?.businessActionTaken
            ? 'phone_change_5'
            : 'phone_not_changes_5'
        }`
      }),
      6: fmt({
        id: `orders.states_logs.exceptions.10.6`
      }),
      7: fmt({
        id: `orders.states_logs.exceptions.10.7`
      }),
      8: fmt({
        id: `orders.states_logs.exceptions.10.8`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.10.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.10.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.10.101`
      })
    },
    15: {
      1: fmt({
        id: `orders.states_logs.exceptions.15.1`
      }),
      2: fmt({
        id: `orders.states_logs.exceptions.15.2`
      }),
      3: fmt(
        {
          id: `orders.states_logs.exceptions.15.3`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      5: fmt({
        id: `orders.states_logs.exceptions.15.5`
      }),
      13: fmt({
        id: `orders.states_logs.exceptions.15.${
          delivery?.state?.waitingForBusinessAction
            ? 'address5'
            : delivery?.state?.businessActionTaken
            ? 'address_change_5'
            : 'address_not_changes_5'
        }`
      }),
      14: fmt({
        id: `orders.states_logs.exceptions.15.${
          delivery?.state?.waitingForBusinessAction
            ? 'phone5'
            : delivery?.state?.businessActionTaken
            ? 'phone_change_5'
            : 'phone_not_changes_5'
        }`
      }),
      6: fmt({
        id: `orders.states_logs.exceptions.15.6`
      }),
      7: fmt({
        id: `orders.states_logs.exceptions.15.7`
      }),
      10: fmt({
        id: `orders.states_logs.exceptions.15.10`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.15.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.15.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.15.101`
      })
    },
    20: {
      20: fmt({
        id: `orders.states_logs.exceptions.20.20`
      }),
      21: fmt(
        {
          id: `orders.states_logs.exceptions.20.21`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      22: fmt({
        id: `orders.states_logs.exceptions.20.22`
      }),
      23: fmt({
        id: `orders.states_logs.exceptions.20.23`
      }),
      24: fmt({
        id: `orders.states_logs.exceptions.20.24`
      }),
      25: fmt({
        id: `orders.states_logs.exceptions.20.25`
      }),
      26: fmt({
        id: `orders.states_logs.exceptions.20.26`
      }),
      27: fmt({
        id: `orders.states_logs.exceptions.20.27`
      }),
      28: fmt({
        id: `orders.states_logs.exceptions.20.28`
      }),
      29: fmt({
        id: `orders.states_logs.exceptions.20.29`
      }),
      30: fmt({
        id: `orders.states_logs.exceptions.20.30`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.20.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.20.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.20.101`
      })
    },
    25: {
      1: fmt({
        id: `orders.states_logs.exceptions.25.1`
      }),
      2: fmt({
        id: `orders.states_logs.exceptions.25.2`
      }),
      3: fmt(
        {
          id: `orders.states_logs.exceptions.25.3`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      5: fmt({
        id: `orders.states_logs.exceptions.25.5`
      }),
      13: fmt({
        id: `orders.states_logs.exceptions.25.${
          delivery?.state?.waitingForBusinessAction
            ? 'address5'
            : delivery?.state?.businessActionTaken
            ? 'address_change_5'
            : 'address_not_changes_5'
        }`
      }),
      14: fmt({
        id: `orders.states_logs.exceptions.25.${
          delivery?.state?.waitingForBusinessAction
            ? 'phone5'
            : delivery?.state?.businessActionTaken
            ? 'phone_change_5'
            : 'phone_not_changes_5'
        }`
      }),
      6: fmt({
        id: `orders.states_logs.exceptions.25.6`
      }),
      7: fmt({
        id: `orders.states_logs.exceptions.25.7`
      }),
      9: fmt({
        id: `orders.states_logs.exceptions.25.9`
      }),
      20: fmt({
        id: `orders.states_logs.exceptions.20.20`
      }),
      21: fmt(
        {
          id: `orders.states_logs.exceptions.20.21`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      22: fmt({
        id: `orders.states_logs.exceptions.20.22`
      }),
      23: fmt({
        id: `orders.states_logs.exceptions.20.23`
      }),
      24: fmt({
        id: `orders.states_logs.exceptions.20.24`
      }),
      25: fmt({
        id: `orders.states_logs.exceptions.20.25`
      }),
      26: fmt({
        id: `orders.states_logs.exceptions.20.26`
      }),
      27: fmt({
        id: `orders.states_logs.exceptions.20.27`
      }),
      28: fmt({
        id: `orders.states_logs.exceptions.20.28`
      }),
      29: fmt({
        id: `orders.states_logs.exceptions.20.29`
      }),
      30: fmt({
        id: `orders.states_logs.exceptions.20.30`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.20.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.25.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.25.101`
      })
    },
    30: {
      1: fmt({
        id: `orders.states_logs.exceptions.30.1`
      }),
      2: fmt({
        id: `orders.states_logs.exceptions.30.2`
      }),
      3: fmt(
        {
          id: `orders.states_logs.exceptions.30.3`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      4: fmt({
        id: `orders.states_logs.exceptions.30.${
          delivery?.state?.waitingForBusinessAction ? 'new4' : 'accept4'
        }`
      }),
      5: fmt({
        id: `orders.states_logs.exceptions.30.5`
      }),
      13: fmt({
        id: `orders.states_logs.exceptions.30.${
          delivery?.state?.waitingForBusinessAction
            ? 'address5'
            : delivery?.state?.businessActionTaken
            ? 'address_change_5'
            : 'address_not_changes_5'
        }`
      }),
      14: fmt({
        id: `orders.states_logs.exceptions.30.${
          delivery?.state?.waitingForBusinessAction
            ? 'phone5'
            : delivery?.state?.businessActionTaken
            ? 'phone_change_5'
            : 'phone_not_changes_5'
        }`
      }),
      6: fmt({
        id: `orders.states_logs.exceptions.30.6`
      }),
      7: fmt({
        id: `orders.states_logs.exceptions.30.7`
      }),
      8: fmt({
        id: `orders.states_logs.exceptions.30.8`
      }),
      20: fmt({
        id: `orders.states_logs.exceptions.20.20`
      }),
      21: fmt(
        {
          id: `orders.states_logs.exceptions.20.21`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      22: fmt({
        id: `orders.states_logs.exceptions.20.22`
      }),
      23: fmt({
        id: `orders.states_logs.exceptions.20.23`
      }),
      24: fmt({
        id: `orders.states_logs.exceptions.20.24`
      }),
      25: fmt({
        id: `orders.states_logs.exceptions.20.25`
      }),
      26: fmt({
        id: `orders.states_logs.exceptions.20.26`
      }),
      27: fmt({
        id: `orders.states_logs.exceptions.20.27`
      }),
      28: fmt({
        id: `orders.states_logs.exceptions.20.28`
      }),
      29: fmt({
        id: `orders.states_logs.exceptions.20.29`
      }),
      30: fmt({
        id: `orders.states_logs.exceptions.20.30`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.20.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.30.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.30.101`
      })
    },
    35: {
      1: fmt({
        id: `orders.states_logs.exceptions.35.1`
      }),
      2: fmt({
        id: `orders.states_logs.exceptions.35.2`
      }),
      3: fmt(
        {
          id: `orders.states_logs.exceptions.35.3`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      4: fmt({
        id: `orders.states_logs.exceptions.35.${
          delivery.state.waitingForBusinessAction ? 'new4' : 'accept4'
        }`
      }),
      5: fmt({
        id: `orders.states_logs.exceptions.35.5`
      }),
      13: fmt({
        id: `orders.states_logs.exceptions.35.${
          delivery.state.waitingForBusinessAction
            ? 'address5'
            : delivery.state.businessActionTaken
            ? 'address_change_5'
            : 'address_not_changes_5'
        }`
      }),
      14: fmt({
        id: `orders.states_logs.exceptions.35.${
          delivery.state.waitingForBusinessAction
            ? 'phone5'
            : delivery.state.businessActionTaken
            ? 'phone_change_5'
            : 'phone_not_changes_5'
        }`
      }),
      6: fmt({
        id: `orders.states_logs.exceptions.35.6`
      }),
      7: fmt({
        id: `orders.states_logs.exceptions.35.7`
      }),
      8: fmt({
        id: `orders.states_logs.exceptions.35.8`
      }),
      9: fmt({
        id: `orders.states_logs.exceptions.35.9`
      }),
      20: fmt({
        id: `orders.states_logs.exceptions.20.20`
      }),
      21: fmt(
        {
          id: `orders.states_logs.exceptions.20.21`
        },
        {
          date: DeliveryStateFormatter._formatDate(
            log?.actionsList?.state_exception?.after?.scheduledAt ||
              delivery.scheduledAt,
            true
          )
        }
      ),
      22: fmt({
        id: `orders.states_logs.exceptions.20.22`
      }),
      23: fmt({
        id: `orders.states_logs.exceptions.20.23`
      }),
      24: fmt({
        id: `orders.states_logs.exceptions.20.24`
      }),
      25: fmt({
        id: `orders.states_logs.exceptions.20.25`
      }),
      26: fmt({
        id: `orders.states_logs.exceptions.20.26`
      }),
      27: fmt({
        id: `orders.states_logs.exceptions.20.27`
      }),
      28: fmt({
        id: `orders.states_logs.exceptions.20.28`
      }),
      29: fmt({
        id: `orders.states_logs.exceptions.20.29`
      }),
      30: fmt({
        id: `orders.states_logs.exceptions.20.30`
      }),
      12: fmt({
        id: `orders.states_logs.exceptions.20.12`
      }),
      100: fmt({
        id: `orders.states_logs.exceptions.35.100`
      }),
      101: fmt({
        id: `orders.states_logs.exceptions.35.101`
      })
    }
  };
};

export const ADDRESS_UPDATES = {
  dropOffAddress_city_name: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.city'
  }),
  dropOffAddress_firstLine: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.street'
  }),
  dropOffAddress_secondLine: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.landmarks'
  }),
  dropOffAddress_buildingNumber: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.building'
  }),
  dropOffAddress_floor: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.floor'
  }),
  dropOffAddress_apartment: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.apartment'
  }),
  dropOffAddress_isWorkAddress: fmt({
    id: 'orders.states_logs.logs.work_address'
  }),
  dropOffAddress_district: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.district'
  }),
  dropOffAddress_district_name: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.district'
  }),
  dropOffAddress_zone: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.zone'
  }),
  dropOffAddress_zone_name: fmt({
    id: 'orders.states_logs.order_details_updates.address_updates.zone'
  })
};

export const SMS = 'SMS';
export const MISSED_CALL = 'MISSED_CALL';

export const CALL_AND_SMS_LOGS = {
  SMS: fmt({ id: 'orders.states_logs.logs.sms_sent' }),
  CALL: fmt({ id: 'orders.states_logs.logs.outgoing_call' }),
  MISSED_CALL: fmt({ id: 'orders.states_logs.logs.missed_call' }),
  INCOMING_CALL: fmt({ id: 'orders.states_logs.logs.incoming_call' })
};
