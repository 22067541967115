import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Upload } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { openModal } from 'utils/modal';
import { getImageURL } from 'services/bonuses-deductions';
import { getBase64 } from 'utils/bonus-and-dedcution';

import PreviewImage from 'components/PreviewImage/PreviewImage';
import { notify } from 'components/Notify/Notify';

import uploadImage from 'assets/imgRevamp/upload.svg';
import { ReactComponent as trash } from 'assets/imgRevamp/trash.svg';

import './UploadAttachment.less';

class UploadAttachment extends React.Component {
  state = {
    fileList: [],
    imageValid: false,
    isSendLoading: false,
    attachments: []
  };

  getTotalFilesSize = (filesList) =>
    filesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );

  handleUploadedFileURL = async (fileBase46) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await getImageURL({ image: fileBase46 });
        resolve(result?.imageUrl);
      } catch (error) {
        notify(error.message);
        reject(error);
      }
    });
  };

  handleChange = async (info) => {
    const { attachments } = this.state;
    const { isCashier = false } = this.props;

    this.setState({
      fileList: info.fileList.filter((file) => !!file.status)
    });
    if (info.file.status === 'removed') {
      this.setState(
        {
          attachments: isCashier
            ? []
            : attachments.filter((file) => file.name !== info.file.name)
        },
        () =>
          this.props.onChange &&
          this.props.onChange({
            attachments: isCashier
              ? []
              : attachments.filter((file) => file.name !== info.file.name)
          })
      );
    } else {
      this.props.onChange && this.props.onChange({ attachments });
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file.type.split('/')[0] === 'image')
      openModal(PreviewImage, {
        previewImage: file.url || file.preview,
        previewImageTitle:
          file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      });
  };

  customRequest = async ({ file, onSuccess, onError }) => {
    const { isCashier = false, customUploadImageEndpoint = false } = this.props;
    const fileBase64 = await getBase64(file);
    try {
      const result = customUploadImageEndpoint
        ? await customUploadImageEndpoint({ image: fileBase64 })
        : await getImageURL({ image: fileBase64 });
      this.setState({
        attachments: [
          ...(!isCashier ? this.state.attachments : []),
          { url: result.imageUrl, name: file.name }
        ]
      });
      onSuccess();
    } catch (error) {
      notify(error.message);
      onError();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.initialValues !== this.props?.initialValues) {
      let formattedArray = this.props?.initialValues.map((item, index) => {
        const imagesExtension = ['png', 'jpg', 'jpeg'];
        const imageType = imagesExtension.includes(
          item.split('.')?.pop()?.toLowerCase()
        )
          ? `image/${item.split('.')?.pop()}`
          : item.split('.')?.pop();
        return {
          uid: index,
          name: `${item.split('star-allowance/')[1]}`,
          status: 'done',
          url: item,
          type: imageType,
          preview: item
        };
      });
      this.setState({ fileList: formattedArray, attachments: formattedArray });
    }
  }

  render() {
    const { fileList } = this.state;
    const {
      accept = null,
      customSubtitle = '',
      customTitle,
      multiple = true,
      name = 'file',
      showUploadList = {
        showRemoveIcon: true,
        removeIcon: <Icon component={trash} />
      },
      beforeUpload = () => {},
      customRequest = this.customRequest,
      customContent = false,
      max = null
    } = this.props;

    const uploadContent = () => {
      if (fileList.length === 0 || !showUploadList) {
        return (
          <div className="br-upload-dialog__empty-view">
            <span className="br-upload-dialog__empty-view__image">
              <img alt="" src={uploadImage} />
            </span>
            <span className="br-upload-dialog__empty-view__title">
              {customTitle || 'Drag & drop your files here...'}
            </span>

            <span className="br-upload-dialog__empty-view__sub-title">
              {customSubtitle ||
                'Files formats are Images (PNG, JPEG) and Files (xxlx, sheets) with max files size 1.5 MB'}
            </span>
            <Button className="br-upload-dialog__empty-view__upload-button">
              Upload File
            </Button>
          </div>
        );
      }
      return (
        <>
          {multiple && (
            <div className="br-upload-dialog__files-header">
              <span>{fileList.length} Uploaded Files</span>
              <Button>Upload File</Button>
            </div>
          )}
        </>
      );
    };
    return (
      <Upload
        className={classnames('br-upload-dialog__upload', {
          'br-upload-dialog__upload-empty': !fileList.length || !showUploadList
        })}
        name={name}
        showUploadList={showUploadList}
        beforeUpload={beforeUpload}
        listType="picture"
        accept={accept || '.jpg, .jpeg, .png, .csv, .xlsx, .xls'}
        fileList={fileList}
        multiple={max ? false : true}
        customRequest={customRequest}
        onPreview={this.handlePreview}
        onChange={this.handleChange}
        maxCount={max}
      >
        {customContent ? customContent() : uploadContent()}
      </Upload>
    );
  }
}

export default injectIntl(UploadAttachment);
