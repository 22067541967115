import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const PROVIDERS_STRING = {
  JNT_PROVIDER: fmt({ id: 'three_pl_integrations.providers.jnt' }),
  SHIPOX_PROVIDER: fmt({ id: 'three_pl_integrations.providers.thabit' }),
  SAEE_PROVIDER: fmt({ id: 'three_pl_integrations.providers.saee' }),
  TOROD_PROVIDER: fmt({ id: 'three_pl_integrations.providers.torod' }),
  IMILE_PROVIDER: fmt({ id: 'three_pl_integrations.providers.imile' }),
  LOGESTECHS_PROVIDER: fmt({
    id: 'three_pl_integrations.providers.logestechs'
  }),
  FLOWPL_PROVIDER: fmt({ id: 'three_pl_integrations.providers.flow' })
};

export const PROVIDERS = {
  JNT_PROVIDER: 'JNT_PROVIDER',
  SHIPOX_PROVIDER: 'SHIPOX_PROVIDER',
  SAEE_PROVIDER: 'SAEE_PROVIDER',
  TOROD_PROVIDER: 'TOROD_PROVIDER',
  IMILE_PROVIDER: 'IMILE_PROVIDER',
  LOGESTECHS_PROVIDER: 'LOGESTECHS_PROVIDER'
};
