import { injectIntl } from 'react-intl';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import AuditActionsTable from './components/AuditActionsTable/AuditActionsTable';

import './AuditActions.less';

const AuditActions = ({ intl }) => {
  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'audit_actions.title' })} />
      }
      content={
        <div className="br-audit-actions-container">
          <h1 className="br-audiut-actions-container-title">
            {intl.formatMessage({ id: 'audit_actions.manage_audit_action' })}
          </h1>
          <div className="br-audit-actions__content">
            <AuditActionsTable />
          </div>
        </div>
      }
    />
  );
};

export default injectIntl(AuditActions);
