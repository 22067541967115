import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown, Button, Menu } from 'antd';

import { openModal } from 'utils/modal';
import { editTemplate, deleteTemplate } from 'services/stars-performance';
import { APP_PERFORMANCE_MAPPING } from 'constants/score-card';

import AddEditFactorsModal from 'components/NewSettings/components/ScoreCard/components/AddEditFactorsModal/AddEditFactorsModal';
import { notify } from 'components/Notify/Notify';
import EditHubsModal from 'components/NewSettings/components/ScoreCard/components/EditHubsModal/EditHubsModal';
import DeleteTemplateModal from 'components/NewSettings/components/ScoreCard/components/DeleteTemplateModal/DeleteTemplateModal';

import { ReactComponent as TemplateEmptyStateIcon } from 'assets/imgRevamp/ScoreCardTemplateEmptyState.svg';

import './Templates.less';

const Templates = ({ intl, templates, getTemplates, allHubs, getHubs }) => {
  const showTemplateHubs = (hubArray) => {
    let hubString = '';
    hubArray.forEach((hub, index) => {
      if (index === hubArray.length - 1) {
        hubString += `${hub.hub_name}.`;
      } else {
        hubString += `${hub.hub_name},   `;
      }
    });
    return hubString;
  };

  const editTemplateAttributes = async ({
    values,
    id,
    callHubEndpoint = false,
    deleteAction = false,
    notifyMessage
  }) => {
    try {
      if (deleteAction) {
        await deleteTemplate(id);
      } else {
        await editTemplate(id, values);
      }
      await getTemplates();
      if (callHubEndpoint) {
        await getHubs();
      }
      if (notifyMessage) {
        notify(notifyMessage, 'success');
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const showTemplateFactors = (factors) => {
    return Object.keys(factors).map((factor) => (
      <>
        {APP_PERFORMANCE_MAPPING[factor]}
        <span className="factor-weight">{factors[factor]}</span>
      </>
    ));
  };

  const handleEditFactorClick = (template) => {
    let templateObject = template.factors;

    if (
      templateObject?.max_number_of_fake_exceptions &&
      templateObject?.fake_exceptions
    ) {
      templateObject = {
        ...templateObject,
        fake_exceptions: {
          maxNumber: templateObject?.max_number_of_fake_exceptions,
          weight: templateObject?.fake_exceptions
        }
      };
      delete templateObject.max_number_of_fake_exceptions;
    }
    return openModal(AddEditFactorsModal, {
      editTemplate: editTemplateAttributes,
      templateId: template.id,
      factors: templateObject,
      isEdit: true
    });
  };

  const handleDeleteTempalte = (template) => {};

  const menu = (template) => (
    <Menu>
      <Menu.Item
        onClick={() =>
          openModal(EditHubsModal, {
            template: template,
            allHubs,
            editTemplate: editTemplateAttributes
          })
        }
      >
        Edit Hubs
      </Menu.Item>
      <Menu.Item onClick={() => handleEditFactorClick(template)}>
        Edit Factors
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          openModal(DeleteTemplateModal, {
            template: template,
            editTemplate: editTemplateAttributes
          })
        }
      >
        Remove Template
      </Menu.Item>
    </Menu>
  );

  return templates.length ? (
    <div className="br-score-card-templates__container">
      {templates.map((template) => (
        <div className="br-score-card-template__template-conent-container">
          <div className="br-score-card-template__info-section">
            <div className="br-score-card-template__id-section">
              {template.id}
            </div>
            <div className="br-score-card-template__hubs-section">
              <div>
                <span className="br-score-card-template__info-title">Hubs</span>
                <span className="br-score-card-template__info-subtitle">
                  {showTemplateHubs(template.hubs)}
                </span>
              </div>
              <div>
                <span className="br-score-card-template__info-title">
                  Factors
                </span>
                {Object.keys(template?.factors).length ? (
                  <span className="br-score-card-template__info-subtitle">
                    {/* {template.factors.toString()} */}
                    {showTemplateFactors(template.factors)}
                  </span>
                ) : (
                  <span className="br-templates__empty-factor-text">
                    {intl.formatMessage({
                      id: 'settings.score_card.template_section.no_factors_text'
                    })}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="br-score-card-template__actions-section">
            {!Object.keys(template?.factors).length && (
              <Button
                type="primary"
                className="br-score-card-template__add-factor-button"
                onClick={() =>
                  openModal(AddEditFactorsModal, {
                    editTemplate: editTemplateAttributes,
                    templateId: template.id
                  })
                }
              >
                +{' '}
                {intl.formatMessage({
                  id: 'settings.score_card.template_section.add_factors'
                })}
              </Button>
            )}{' '}
            <Dropdown
              overlay={() => menu(template)}
              placement="bottomLeft"
              trigger="click"
            >
              <Button>. . .</Button>
            </Dropdown>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="br-score-card-templates__empty-state-container">
      <TemplateEmptyStateIcon />
      <span className="br-templates-empty-state__title">
        {intl.formatMessage({
          id: 'settings.score_card.template_section.empty_state.title'
        })}
      </span>
      <span className="br-templates-empty-state__subtitle">
        {intl.formatMessage({
          id: 'settings.score_card.template_section.empty_state.sub_title'
        })}
      </span>
    </div>
  );
};

export default injectIntl(Templates);
