import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Collapse, Checkbox } from 'antd';

import { getSerials } from 'services/fulfillment';
import { handlePrintSerials } from 'constants/fulfillment-return-orders';
import { formatProductsFromSerials } from 'constants/fulfillment-inventory-serials';
import { openModal } from 'utils/modal';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';

import PrintingProcessGif from 'assets/imgRevamp/PrintingProcess.gif';

import './PrintSerialsModal.less';

const { Panel } = Collapse;

const PrintSerialsModal = ({ intl, close, payload, ...restProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productsObj, setProductsObj] = useState({});
  const [selectedProductsObj, setSelectedProductsObj] = useState({});
  const [allFetchedSerials, setAllFetchedSerials] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const getSelectedSerialsList = () =>
    Object.values(selectedProductsObj).flat();

  const counts = [
    intl.formatMessage(
      {
        id: 'fulfillment_inbound.create_po.products.select_products_modal.products_count'
      },
      {
        count: isAllSelected
          ? Object.keys(productsObj).length
          : Object.keys(selectedProductsObj).length
      }
    ),
    intl.formatMessage(
      {
        id: 'fulfillment_inbound.create_po.products.select_products_modal.quantity_count'
      },
      {
        count: isAllSelected
          ? allFetchedSerials.length
          : getSelectedSerialsList().length
      }
    )
  ];

  const onSerialSelectionChange = ({
    checked,
    prevSelectedProductsObj,
    productSku,
    serial
  }) => {
    const currProductSerials = prevSelectedProductsObj[productSku];

    if (checked) {
      prevSelectedProductsObj[productSku] = currProductSerials
        ? [...currProductSerials, serial]
        : [serial];
    } else {
      prevSelectedProductsObj[productSku] = currProductSerials.filter(
        (s) => s !== serial
      );

      if (!prevSelectedProductsObj[productSku]?.length) {
        delete prevSelectedProductsObj[productSku];
      }
    }

    return prevSelectedProductsObj;
  };

  const onSKUSelectionChange = ({
    checked,
    prevSelectedProductsObj,
    productSku
  }) => {
    if (checked) {
      prevSelectedProductsObj[productSku] =
        productsObj[productSku].serialNumbers;
    } else {
      delete prevSelectedProductsObj[productSku];
    }

    return prevSelectedProductsObj;
  };

  const onSelectionChange = (e, productSku, serial) => {
    const { checked } = e.target;

    setSelectedProductsObj((prev) => {
      if (serial) {
        onSerialSelectionChange({
          checked,
          prevSelectedProductsObj: prev,
          productSku,
          serial
        });
      } else {
        onSKUSelectionChange({
          checked,
          prevSelectedProductsObj: prev,
          productSku
        });
      }

      return { ...prev };
    });
  };

  const onSelectAll = ({ target: { checked } }) => {
    setIsAllSelected(checked);
    setSelectedProductsObj({});
  };

  const onConfirm = async () => {
    close();

    const serials = isAllSelected
      ? allFetchedSerials
      : getSelectedSerialsList();

    openModal(BRConfirmationModal, {
      wrapClassName: 'br-confirm-po__confirmation-modal',
      icon: <img src={PrintingProcessGif} alt="printing" />,
      message: intl.formatMessage(
        {
          id: 'fulfillment_inbound.print_PO_labels.success_modal.title'
        },
        {
          count: serials.length
        }
      ),
      confirmButtonText: intl.formatMessage({
        id: 'common.done'
      }),
      confirmButtonType: 'basic',
      width: 431,
      hideCancel: true
    });

    await handlePrintSerials({ serials });
  };

  const renderFooter = () => {
    const serialsCount = getSelectedSerialsList().length;

    return (
      <>
        <div className="br-po-select-products-modal__footer__counts">
          {counts.map((count, index) => (
            <span key={index}>{count}</span>
          ))}
        </div>
        <div className="br-po-select-products-modal__footer__actions">
          <BRButton
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            onClick={close}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage(
              {
                id: 'fulfillment_returns.print_serials_modal.confirm_btn'
              },
              { count: isAllSelected ? allFetchedSerials.length : serialsCount }
            )}
            disabled={isLoading || (!serialsCount && !isAllSelected)}
            onClick={onConfirm}
            loading={isLoading}
          />
        </div>
      </>
    );
  };

  const fetchSerials = async () => {
    setIsLoading(true);

    try {
      const { data } = await getSerials(payload);
      const products = formatProductsFromSerials({
        serials: data?.serials,
        returnAsObject: true
      });

      setProductsObj(products);
      setAllFetchedSerials(
        data.serials?.map(({ serialNumber }) => serialNumber) || []
      );
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const renderProductsCollapse = () => {
    const productsObjKeys = Object.keys(productsObj);

    return (
      !!productsObjKeys.length && (
        <Collapse
          className="br-print-serials-modal__collapse"
          expandIconPosition="end"
          defaultActiveKey={productsObjKeys}
        >
          {productsObjKeys.map((key) => {
            const {
              productName,
              productNameAr,
              serialNumbers,
              productSku,
              defaultImage
            } = productsObj[key];

            return (
              <Panel
                key={productSku}
                header={
                  <Checkbox
                    className="br-print-serials-modal__collapse__product"
                    onChange={(e) => onSelectionChange(e, productSku)}
                    checked={
                      selectedProductsObj[productSku] &&
                      selectedProductsObj[productSku].length ===
                        productsObj[productSku]?.serialNumbers?.length
                    }
                    disabled={isAllSelected}
                  >
                    <ProductImage src={defaultImage} />
                    {productName || productNameAr}
                  </Checkbox>
                }
              >
                {serialNumbers.map((serial) => (
                  <div
                    key={serial}
                    className="br-print-serials-modal__collapse__serial"
                  >
                    <Checkbox
                      onChange={(e) => onSelectionChange(e, productSku, serial)}
                      checked={
                        !!selectedProductsObj[productSku]?.find(
                          (s) => s === serial
                        )
                      }
                      disabled={isAllSelected}
                    >
                      {serial}
                    </Checkbox>
                  </div>
                ))}
              </Panel>
            );
          })}
        </Collapse>
      )
    );
  };

  useEffect(() => {
    fetchSerials();
  }, []);

  return (
    <Modal
      {...restProps}
      wrapClassName="br-print-serials-modal br-po-select-products-modal"
      title={intl.formatMessage({
        id: 'fulfillment_returns.print_serials_modal.title'
      })}
      footer={renderFooter()}
    >
      <LoadingWrapper
        loading={isLoading}
        className="br-print-serials-modal__loading-wrapper"
      >
        <Checkbox
          className="br-print-serials-modal__select-all"
          onChange={onSelectAll}
          checked={isAllSelected}
        >
          {intl.formatMessage(
            {
              id: 'fulfillment_returns.print_serials_modal.select_all'
            },
            {
              count: allFetchedSerials.length
            }
          )}
        </Checkbox>
        {renderProductsCollapse()}
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(PrintSerialsModal);
