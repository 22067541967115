import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import SelectBusiness from 'components/NewFulfillment/FulfillmentInbound/components/SelectBusiness/SelectBusiness';
import DateFilter from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/DateFilter/DateFilter';

import './POFilters.less';

const POFilters = ({
  intl,
  updateFilters,
  dateFilter = null,
  renderExtraFilters = () => null,
  rightContent = null,
  hideDateFilter = false
}) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  useEffect(() => {
    updateFilters({ businessId: selectedBusiness?._id });
  }, [selectedBusiness]);

  return (
    <div className="br-po-filters">
      <div className="br-po-filters__content">
        <SelectBusiness
          label={intl.formatMessage({
            id: 'fulfillment_inbound.po_filters.business.label'
          })}
          placeholder={intl.formatMessage({
            id: 'fulfillment_inbound.po_filters.business.placeholder'
          })}
          selectedBusiness={selectedBusiness}
          setSelectedBusiness={setSelectedBusiness}
        />
        {dateFilter ||
          (!hideDateFilter && (
            <DateFilter
              label={intl.formatMessage({
                id: 'fulfillment_inbound.po_filters.created_on.label'
              })}
              updateFilters={updateFilters}
            />
          ))}
        {renderExtraFilters()}
        {rightContent}
      </div>
    </div>
  );
};

export default injectIntl(POFilters);
