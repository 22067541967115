import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Table, Tag, Tooltip } from 'antd';
import Icon, { QuestionCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';

import {
  BUSINESS,
  SHELVES,
  ROUTE,
  SHELF,
  TRANSFER_TO_SORTING_FACILITY,
  ROW_TYPE
} from 'constants/hubs';
import { openModal } from 'utils/modal';
import { getOrderAge } from 'utils/shipmentDetails';

import { notify } from 'components/Notify/Notify';
import AssigneeModal from 'containers/Route/RTOModal/AssigneeModal';

import { ReactComponent as EmptyContent } from 'assets/imgRevamp/EmptyContent.svg';

import './DispatchRTO.less';

const { DELIVERY, GROUP } = ROW_TYPE;

const RTOTable = ({
  intl,
  isLoading,
  groupedBy,
  routes,
  shelves,
  assignDeliveriesToRoute,
  assignDeliveriesToShelf,
  rtoList,
  rtoSearchResults,
  rtoListTrackingNumbers,
  selectedHub,
  setSelectedTrackingNumbers,
  rtoCount,
  transferToSortingFacility
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const expandable = {
    expandedRowKeys: dataSource.map(({ key }) => key),
    expandIcon: () => null
  };

  const renderedUnknownWord = (
    <div className="br-dispatch-rto_side-container_left_table_unknown-word">
      <span
        className={classnames({
          'br-dispatch-rto_side-container_left_table_unknown-word-colored':
            groupedBy.value === BUSINESS
        })}
      >
        {intl.formatMessage({
          id: 'hubs.dispatch_rto.rto_table.unknown.word'
        })}
      </span>
      <Tooltip
        title={intl.formatMessage({
          id: 'hubs.dispatch_rto.rto_table.unknown.tooltip'
        })}
      >
        <QuestionCircleFilled className="br-dispatch-rto_question-circle-icon" />
      </Tooltip>
    </div>
  );

  const transferToSortingFacilityLabel = (
    <Tag className="br-dispatch-rto_side-container_left_table_tag">
      {intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.transfer_to_sorting_facility'
      })}
    </Tag>
  );

  const actions = [
    {
      actionKey: ROUTE,
      name: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.actions.assign_to_route'
      }),
      callback: ({ rowType, payload }) => {
        assignTo(rowType, payload, ROUTE, routes, assignDeliveriesToRoute);
      }
    },
    {
      actionKey: SHELF,
      name: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.actions.assign_to_shelf'
      }),
      callback: ({ rowType, payload }) => {
        assignTo(rowType, payload, SHELF, shelves, assignDeliveriesToShelf);
      }
    },
    {
      actionKey: TRANSFER_TO_SORTING_FACILITY,
      name: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.actions.assign_to_sorting_facility'
      }),
      callback: ({ rowType, payload }) => {
        const deliveryIds = getDeliveryIds({ rowType, payload });
        const trackingNumbers = [];

        if (deliveryIds.length) {
          if (rowType === DELIVERY) {
            const { trackingNumber } = payload.delivery;
            trackingNumbers.push(trackingNumber);
          } else {
            let isAllSelectedTransfer = true;
            for (let i = 0; i < selectedRows.length; i++) {
              const { rowType: selectRowType, payload: selectRowPayload } =
                selectedRows[i];
              if (
                selectRowType === DELIVERY &&
                !selectRowPayload.isOrderTransferToSortingFacility
              ) {
                isAllSelectedTransfer = false;
                break;
              }
            }

            if (!isAllSelectedTransfer) {
              notify(
                intl.formatMessage({
                  id: `hubs.dispatch_rto.rto_table.success_msgs.not_all_selected_sorting`
                })
              );
              return;
            } else {
              selectedRows.forEach(({ payload: selectRowPayload }) => {
                const trackingNumber =
                  selectRowPayload.delivery?.trackingNumber;
                if (trackingNumber) {
                  trackingNumbers.push(trackingNumber);
                }
              });
            }
          }

          transferToSortingFacility(trackingNumbers);
        }
      }
    }
  ];

  const renderedAgeHeader = (
    <div className="br-dispatch-rto_side-container_left_table_age-column-header">
      {intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.header.age.title'
      })}
      <span className="br-dispatch-rto_side-container_left_table_age-column-header_day">
        {intl.formatMessage({
          id: 'hubs.dispatch_rto.rto_table.header.age.day'
        })}
      </span>
    </div>
  );
  const columns = [
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.header.tracking_number'
      }),
      dataIndex: 'groupNameOrTN',
      key: 'groupNameOrTN',
      width: '42%'
    },
    {
      title: intl.formatMessage({
        id: `hubs.dispatch_rto.group_by.${
          groupedBy.value === BUSINESS ? SHELVES : BUSINESS
        }`
      }),
      dataIndex: 'groupBy',
      key: 'groupBy',
      width: '20%'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.dispatch_rto.rto_table.header.order_type'
      }),
      dataIndex: 'orderType',
      key: 'orderType',
      width: '20%'
    },
    {
      title: renderedAgeHeader,
      dataIndex: 'age',
      key: 'age',
      width: '18%',
      align: 'center'
    }
  ];

  const rowSelection = {
    checkStrictly: false,
    selectedRowKeys: selectedRows.map(({ key }) => key),
    hideSelectAll: true,
    onChange: (_, tableSelectedRows) => {
      const selectedRow = tableSelectedRows[tableSelectedRows.length - 1];
      let newSelectedRows = [...tableSelectedRows];
      if (selectedRow) {
        const { groupName } = selectedRow.payload;
        if (selectedRows.length) {
          if (groupName !== selectedGroup) {
            newSelectedRows = tableSelectedRows.filter(
              ({ payload }) => payload.groupName === groupName
            );
            setSelectedGroup(groupName);
          }
        } else {
          setSelectedGroup(groupName);
        }
      } else {
        setSelectedGroup(null);
      }

      setSelectedRows(newSelectedRows);
    }
  };

  const getDeliveryIds = ({ rowType, payload }) => {
    const { groupName } = payload;
    const deliveryIds = [];

    if (rowType === DELIVERY) {
      const { delivery } = payload;
      deliveryIds.push(delivery.deliveryId);
    } else if (groupName === selectedGroup) {
      selectedRows.forEach((row) => {
        const { delivery } = row.payload;
        if (delivery) {
          deliveryIds.push(delivery.deliveryId);
        }
      });
    } else {
      notify(
        intl.formatMessage(
          {
            id: 'hubs.dispatch_rto.rto_table.error_msgs.select_right_group'
          },
          {
            group: groupedBy.label
          }
        )
      );
    }

    return deliveryIds;
  };

  const assignTo = (
    rowType,
    payload,
    assignee,
    assigneeList,
    assignDeliveriesCallback
  ) => {
    const deliveryIds = getDeliveryIds({ rowType, payload });

    if (deliveryIds.length) {
      openModal(AssigneeModal, {
        modalTitle: intl.formatMessage({
          id: `hubs.dispatch_rto.assignee.${assignee}.modal.title`
        }),
        dropdownPlaceholder: intl.formatMessage({
          id: `hubs.dispatch_rto.assignee.${assignee}.dropdown_placeholder`
        }),
        dropdownData: assigneeList,
        onConfirm: (assigneeId, assigneeName) => {
          let successMsg = '';
          if (rowType === DELIVERY) {
            successMsg = intl.formatMessage(
              {
                id: `hubs.dispatch_rto.rto_table.success_msgs.single_order_assigned_to_${assignee}`
              },
              {
                name: assigneeName
              }
            );
          } else {
            successMsg = intl.formatMessage(
              {
                id: `hubs.dispatch_rto.rto_table.success_msgs.group_orders_assigned_to_${assignee}`
              },
              {
                groupName: selectedGroup,
                name: assigneeName
              }
            );
          }
          assignDeliveriesCallback(assigneeId, deliveryIds, successMsg);
        },
        isLoading
      });
    }
  };

  const renderOrderAge = (delivery) => {
    const { orderAge, tooltip } = getOrderAge(delivery);
    return (
      <div className="vertically-centered">
        {!isNaN(orderAge)
          ? orderAge
          : intl.formatMessage({ id: 'common.empty_field' })}{' '}
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleFilled className="br-dispatch-rto_question-circle-icon" />
          </Tooltip>
        )}
      </div>
    );
  };

  const setRTOTableData = () => {
    setSelectedRows([]);
    const data = rtoSearchResults.map(({ _id: groupId, deliveries }, index) => {
      const isAllGroupTransfer = deliveries.every(
        ({ assignedHub }) => assignedHub !== selectedHub.value
      );

      const isGroupHaveAtLeastOneTransfer = deliveries.some(
        ({ assignedHub }) => assignedHub !== selectedHub.value
      );

      const groupColName =
        groupedBy.value === BUSINESS ? groupId.businessName : groupId.binCode;
      const groupName = `${groupColName || `unknown-bin-${index}`}`;

      return {
        key: groupName,
        groupNameOrTN: (
          <span className="br-dispatch-rto_side-container_left_table_row-header">
            {groupColName || renderedUnknownWord} ({deliveries.length})
            {isAllGroupTransfer && transferToSortingFacilityLabel}
          </span>
        ),
        children: deliveries.map((delivery) => {
          const {
            trackingNumber,
            assignedHub,
            shelfLocation,
            businessName,
            _id: deliveryId
          } = delivery;
          const isOrderTransferToSortingFacility =
            assignedHub !== selectedHub.value;

          return {
            key: trackingNumber,
            groupNameOrTN: (
              <span>
                {trackingNumber}
                {isOrderTransferToSortingFacility &&
                  !isAllGroupTransfer &&
                  transferToSortingFacilityLabel}
              </span>
            ),
            groupBy:
              groupedBy.value === BUSINESS
                ? shelfLocation || renderedUnknownWord
                : businessName,
            age: renderOrderAge(delivery),
            rowType: DELIVERY,
            payload: {
              delivery: {
                trackingNumber,
                deliveryId
              },
              groupName,
              isOrderTransferToSortingFacility
            }
          };
        }),
        rowType: GROUP,
        payload: {
          groupName,
          isAllGroupTransfer,
          isGroupHaveAtLeastOneTransfer
        }
      };
    });

    setDataSource(data);
  };

  useEffect(() => {
    setRTOTableData();
  }, [rtoSearchResults]);

  const setSelectedTNsToExport = () => {
    let newSelectedTrackingNumbers = [];
    if (selectedRows.length) {
      selectedRows.forEach(({ payload }) => {
        const { delivery } = payload;
        const trackingNumber = delivery?.trackingNumber;
        if (trackingNumber) {
          newSelectedTrackingNumbers.push(trackingNumber);
        }
      });
    } else {
      newSelectedTrackingNumbers = [...rtoListTrackingNumbers];
    }
    setSelectedTrackingNumbers(newSelectedTrackingNumbers);
  };

  useEffect(() => {
    setSelectedTNsToExport();
  }, [selectedRows, rtoListTrackingNumbers]);

  return (
    <Table
      className="br-dispatch-rto_side-container_left_table"
      title={() =>
        intl.formatMessage(
          {
            id: 'hubs.dispatch_rto.rto_table.title'
          },
          { rtoCount }
        )
      }
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      expandable={expandable}
      rowClassName={({ children }) =>
        children && 'br-dispatch-rto_side-container_left_table_row-header'
      }
      pagination={false}
      loading={isLoading}
      scroll={{ y: 260 }}
      locale={{
        emptyText: () => {
          if (!rtoList.length) {
            return (
              <>
                <Icon
                  component={EmptyContent}
                  className="br-dispatch-rto_side-container_left_table_empty-img"
                />
                <h4>
                  {intl.formatMessage({
                    id: 'hubs.dispatch_rto.rto_table.empty_content.title'
                  })}
                </h4>
                <span className="br-dispatch-rto_side-container_left_table_empty-text">
                  {intl.formatMessage({
                    id: 'hubs.dispatch_rto.rto_table.empty_content.subtitle'
                  })}
                </span>
              </>
            );
          } else {
            return intl.formatMessage({
              id: 'hubs.dispatch_rto.rto_table.no_search_results'
            });
          }
        }
      }}
    />
  );
};

export default injectIntl(RTOTable);
