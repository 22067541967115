import React, { useState } from 'react';
import { Form, Select, Modal } from 'antd';

const EditCapitalStatusModal = ({ close, handleOnOk, status, ...props }) => {
  const STATUS_VALUES = ['Applied', 'Proceed', 'Accepted', 'Rejected'];
  const [selectedStatus, setSelectedStatus] = useState(status);

  const handleOnFinish = () => {
    handleOnOk(selectedStatus);
    close();
  };
  return (
    <Modal
      {...props}
      title={'Edit Status'}
      onCancel={close}
      onOk={handleOnFinish}
    >
      <Form.Item name="status" rules={[{ required: true }]} label={'Status'}>
        <Select
          placeholder="Select status"
          onChange={setSelectedStatus}
          defaultValue={status}
        >
          {STATUS_VALUES?.map((status) => (
            <Select.Option key={status} value={status}>
              {status}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};

export default EditCapitalStatusModal;
