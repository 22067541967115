import http from 'utils/http';

export const getStarBonusAndDeduction = (payload) => {
  return http.get(`/starSalaries/stars-allowance`, payload);
};

export const getImageURL = (payload) => {
  return http.post(
    `/starSalaries/stars-allowance/create/allowance-image-upload`,
    payload
  );
};

export const createBonusOrDeduction = (payload) => {
  return http.post(`/starSalaries/stars-allowance/create`, payload);
};

export const editBonusesOrDeductions = (payload) => {
  return http.put('/starSalaries/stars-allowance/edit-record', payload);
};

export const deleteBonusesOrDeductions = (id) => {
  return http.delete(`/starSalaries/stars-allowance/${id}`);
};

export const updateBonusesOrDeductionsState = (payload) => {
  return http.put(`starSalaries/stars-allowance/update-state`, payload);
};

export const exportBounusOrDeductionsTable = (payload) => {
  return http.get(`/starSalaries/export/bonusAndDeduction`, payload);
};
