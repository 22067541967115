import React, { useState, useEffect } from 'react';

import { formatDate } from 'utils/shipmentDetails';

import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';

import { ReactComponent as DeliveryHighlightsEmptyStateIcon } from 'assets/imgRevamp/NewDeliveryHighlightsEmptyStateIcon.svg';

import './DeliveryHighlights.less';

const DeliveryHighlight = ({ dateName, date, extraData, timeSlot, isDate }) => {
  return (
    <div className="br-delivery-highlight__content">
      <span className="br-delivery-highlight__date-name">{dateName}</span>
      <span>
        {extraData && isDate
          ? `${extraData} | ${formatDate(date)}`
          : timeSlot
          ? `${formatDate(date)} ${timeSlot}`
          : !isDate
          ? date
          : formatDate(date)}
      </span>
      {extraData && !isDate && (
        <span className="br-delivery-highlight__date-name">{extraData}</span>
      )}
    </div>
  );
};
const DeliveryHighlights = ({ content = [], delivery }) => {
  const [highlights, setHighlights] = useState([]);
  useEffect(() => {
    const formattedDeliveryHighlights =
      deliveryStateFormatter.handleDeliveryHighlight(delivery);
    let isEmpty = true;
    formattedDeliveryHighlights.forEach((highlight) => {
      if (highlight?.value) isEmpty = false;
    });
    if (!isEmpty && formattedDeliveryHighlights.length) {
      setHighlights(formattedDeliveryHighlights);
    }
  }, [delivery]);

  return highlights.length ? (
    <div className="br-delivery-highlights__container">
      {highlights.map(
        (hightlight) =>
          hightlight &&
          hightlight.value && (
            <DeliveryHighlight
              dateName={hightlight.name}
              date={hightlight.value}
              extraData={hightlight.extraData}
              timeSlot={hightlight.timeSlot}
              isDate={hightlight.isDate}
            />
          )
      )}
    </div>
  ) : (
    <div className="br-delivery-highlights__empty-state">
      <DeliveryHighlightsEmptyStateIcon />
      <span>This delivery doesn’t have highlights</span>
    </div>
  );
};

export default DeliveryHighlights;
