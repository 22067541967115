import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Button } from 'antd';

import { addSettlement } from 'services/stars';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './SettlementModal.less';

const SettlementModal = ({ intl, starId, onSuccess, close, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAddSettlement = async (values) => {
    try {
      setIsLoading(true);
      const { message } = await addSettlement(starId, values);
      notify(message, 'success');
      onSuccess();
      close();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Modal
        wrapClassName="br-settlement__modal"
        title={intl.formatMessage({
          id: `stars.star_details.actions.add_settlement`
        })}
        {...props}
        onCancel={close}
        footer={null}
      >
        <Form onFinish={handleAddSettlement} scrollToFirstError>
          <Form.Item
            rules={[{ required: true }]}
            name="amount"
            label={intl.formatMessage({
              id: `stars.star_details.table_columns.amount`
            })}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={intl.formatMessage({
              id: `stars.star_details.notes`
            })}
          >
            <Input />
          </Form.Item>
          <div className="br-settlement-modal__footer">
            <Button onClick={close}>
              {intl.formatMessage({
                id: `common.cancel`
              })}
            </Button>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({
                id: `common.ok`
              })}
            </Button>
          </div>
        </Form>
      </Modal>
    </LoadingWrapper>
  );
};

export default injectIntl(SettlementModal);
