import { notify } from 'components/Notify/Notify';
import { COLLECTED } from 'constants/cashier';

export const checkSearchInputRegex = (searchText, loopedArray) => {
  const stringRegex = /^[a-zA-Z ]+$/;
  if (stringRegex.test(searchText)) {
    const filteredHub = loopedArray.filter((hub) =>
      hub.name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (filteredHub.length) {
      return {
        hub_id: filteredHub[0]._id
      };
    } else {
      notify('Wrong hub name, Please check hub name you entered');
    }
  } else {
    return {
      receipt_number: searchText
    };
  }
};

export const getStateReciptsCount = (state, loopedArray) => {
  const selectedCard = loopedArray.filter(
    (cardInfo) => cardInfo?.state === state
  );
  if (selectedCard.length) {
    return selectedCard[0].count;
  } else {
    return 0;
  }
};

export const processSelectedRows = (row) => {
  const formattedSelection = [];
  row.forEach((record) => {
    let recordObject = JSON.parse(record);
    if (recordObject.state === COLLECTED) {
      formattedSelection.push(record);
    }
  });
  return formattedSelection;
};
