import React, { useState, useEffect } from 'react';
import { Drawer, Tooltip, Image, Select } from 'antd';
import { injectIntl } from 'react-intl';

import {
  COLLECTED,
  DEPOSITED,
  REJECTED,
  ACTIVE_ROUTE,
  PENDING,
  STATES_COLOR,
  SETTLED
} from 'constants/cashier';
import { getOrderBankTransferData } from 'services/cashier';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DoubleCheckIcon } from 'assets/imgRevamp/cycles-states-column-double-check.svg';
import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/info-circle.svg';
import BtImage from 'assets/bosta-icons/bt-image.png';

import './CashierReceiptNumberInfoDrawer.less';

const CashierReceiptNumberInfoDrawer = ({
  open,
  onClose,
  record,
  actions,
  intl
}) => {
  const [btData, setBtData] = useState([]);
  useEffect(() => {
    if (open) {
      getBtData();
    }
  }, [open]);
  const getBtData = async () => {
    try {
      const { data } = await getOrderBankTransferData(record.recordId);
      setBtData(data?.data || []);
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <Drawer
      closable={false}
      width={536}
      title={
        <div className="br-cashier-recipt-drawer-title">
          <span className="display-xs">
            {intl.formatMessage({ id: 'cashier.receipt_modal.title' })}{' '}
            <div
              className={`br-cashier__state-column-container sidebar-child ${
                STATES_COLOR[record.status]
              }`}
            >
              {record.status}
              {[COLLECTED].includes(record.status) && <DoubleCheckIcon />}
              {record.status === REJECTED && (
                <Tooltip title={record?.rejectionReason}>
                  <TooltipsIcon />
                </Tooltip>
              )}
            </div>
          </span>
          <span onClick={onClose}> X </span>
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={open}
      className="br-cashier-receipt-info-drawer"
    >
      <div className="br-cashier-recepit-drawer__receipt-number-content">
        <div>
          <span className="caption-lg">
            {intl.formatMessage({ id: 'cashier.receipt_modal.receipt_number' })}
          </span>
          <span className="display-xs">
            {record?.status === ACTIVE_ROUTE ? 'N/A' : record?.receiptNo}
          </span>
        </div>
        {actions(record)}
      </div>
      {[SETTLED, DEPOSITED].includes(record.status) && (
        <div className="br-cashier-recepit-drawer__amount-details-content">
          <span className="body-medium">
            {intl.formatMessage({
              id: 'cashier.receipt_modal.proof_of_deposit'
            })}
          </span>
          <div>
            {['jpeg', 'jpg', 'png'].includes(
              record.inTransit_image.split('.').slice(-1)[0]
            ) && <Image src={record.inTransit_image} width={90} height={90} />}
            {['pdf'].includes(
              record.inTransit_image.split('.').slice(-1)[0]
            ) && (
              <span>
                Click{' '}
                <span
                  className="body br-cashier__show-pdf-receipt"
                  onClick={() => window.open(record.inTransit_image)}
                >
                  here
                </span>{' '}
                to show the pdf proof of deposit
              </span>
            )}
          </div>
        </div>
      )}
      <div className="br-cashier-recepit-drawer__star-details-content">
        <div>
          <span className="body">
            {intl.formatMessage({
              id: 'cashier.receipt_modal.star_name'
            })}
          </span>
          <span className="body">{record?.star?.name}</span>
        </div>
        <div>
          <span className="body">
            {intl.formatMessage({
              id: 'cashier.receipt_modal.star_id'
            })}
          </span>
          <span className="body">{record?.star?.id}</span>
        </div>
      </div>
      <div className="br-cashier-recepit-drawer__pos-details-content">
        <span>POS ID</span>
        <Select disabled value={record?.posId}>
          <Select.Option value={record?.posId}>
            <span className="br-cashier-recepit-drawer__dropdown-label">
              {record?.posId}
              {/* <span>1234</span> */}
            </span>
          </Select.Option>
        </Select>
      </div>
      {record.status === REJECTED && (
        <div className="br-cashier-recepit-drawer__rejection-details-content">
          <span className="body-medium">
            {intl.formatMessage({
              id: 'cashier.receipt_modal.rejection_reason'
            })}
          </span>
          <span className="body">{record?.rejectionReason}</span>
        </div>
      )}

      <div className="br-cashier-recepit-drawer__amount-details-content">
        <span className="body-medium">
          {intl.formatMessage({
            id: 'cashier.receipt_modal.amount_details'
          })}
        </span>
        <div>
          <div>
            <span className="body">
              {intl.formatMessage({
                id: 'cashier.receipt_modal.total_amount'
              })}
            </span>
            <span className="body-medium">
              {record?.status === ACTIVE_ROUTE
                ? 'N/A'
                : parseFloat(record?.collectedAmounts?.cashCollected) +
                  parseFloat(record?.collectedAmounts?.ccodCollected) +
                  parseFloat(record?.collectedAmounts?.btCollected)}
            </span>
          </div>
          <div>
            <span className="body">
              {intl.formatMessage({
                id: 'cashier.receipt_modal.cod_amount'
              })}
            </span>
            <span className="body">
              {record?.status === ACTIVE_ROUTE
                ? 'N/A'
                : parseFloat(record?.collectedAmounts?.cashCollected)}
            </span>
          </div>
          <div>
            <span className="body">
              {intl.formatMessage({
                id: 'cashier.receipt_modal.pos_amount'
              })}
            </span>
            <span className="body">
              {record?.status === ACTIVE_ROUTE
                ? 'N/A'
                : parseFloat(record?.collectedAmounts?.ccodCollected)}
            </span>
          </div>
          <div>
            <span className="body">
              {intl.formatMessage({
                id: 'cashier.receipt_modal.bt_amount'
              })}
            </span>
            <span className="body">
              {record?.status === ACTIVE_ROUTE
                ? 'N/A'
                : parseFloat(record?.collectedAmounts?.btCollected)}
            </span>
          </div>
        </div>
      </div>
      {record?.status !== ACTIVE_ROUTE && (
        <div className="br-cashier-recepit-drawer__bt-details-content">
          <div>
            <span className="body-medium">
              {intl.formatMessage(
                {
                  id: 'cashier.receipt_modal.bt_proof'
                },
                {
                  count: btData.length
                }
              )}
            </span>
            <span className="body">
              {intl.formatMessage({
                id: 'cashier.receipt_modal.bt_proof_sub'
              })}
            </span>
          </div>
          {!!btData.length && (
            <div>
              {btData.map((order, id) => (
                <div key={id}>
                  <div>
                    <span className="heading">#{order.tracking_number}</span>
                    <span className="body-medium">
                      SAR {parseFloat(order.amount ?? 0)}
                    </span>
                  </div>
                  <Image
                    src={order.bt_image_url}
                    alt=""
                    width={90}
                    height={90}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default injectIntl(CashierReceiptNumberInfoDrawer);
