import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Modal, Select, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { editOrder } from 'services/shipments';
import { cleanEmptyString } from 'utils/helpers';
import { fetchBusinessPickupLocations } from 'services/business';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';

import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import { notify } from 'components/Notify/Notify';

import './EditReturnDetailsModal.less';

const EditReturnDetailsModal = ({
  intl,
  visible,
  close,
  delivery,
  onSuccess,
  initialValues
}) => {
  const formRef = useRef(null);
  const [pickupLocations, setPickupLocations] = useState([]);

  const handleOnChangeForm = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
  };
  useEffect(() => {
    if (delivery) {
      getPricingSizes();
    }
  }, [delivery]);

  const getPricingSizes = async () => {
    try {
      const { data: pickups } = await fetchBusinessPickupLocations(
        delivery?.sender?._id
      );
      setPickupLocations(pickups);
    } catch (error) {
      notify(error.message);
    }
  };

  const getDiffrence = (values) => {
    const newValues = {};
    for (const key in values) {
      if (values[key] !== initialValues[key]) {
        newValues[key] = values[key];
      }
    }
    return newValues;
  };

  const handleOnFinish = async (values) => {
    values = getDiffrence(values);
    if (values.locationId) {
      const pickupLocation = pickupLocations.find(
        (location) => location._id === values.locationId
      );

      if (delivery.type !== ORDER_TYPES_VALUE.CRP) {
        values.returnAddress = {
          ...pickupLocation?.address,
          businessLocationId: pickupLocation?._id,
          returnAddressMobile: pickupLocation?.contactPerson.phone
        };
      } else {
        values.dropOffAddress = {
          ...pickupLocation?.address,
          businessLocationId: pickupLocation?._id,
          returnAddressMobile: pickupLocation?.contactPerson.phone
        };
      }
      delete values.locationId;
    }

    let payload = {};
    if (values.itemsCount || values.description) {
      payload.returnSpecs = {
        packageDetails: {
          itemsCount: values.itemsCount || initialValues.itemsCount,
          description: values.description || initialValues.description
        }
      };
      delete values.itemsCount;
      delete values.description;
    }
    payload = {
      ...payload,
      ...values
    };
    try {
      await editOrder({
        id: delivery._id,
        updateData: cleanEmptyString(payload)
      });
      notify('Your updates has been saved successfully.', 'success');
      close();
      onSuccess();
    } catch (e) {
      notify(e.message);
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={close}
      className="br-reschedule-modal__container"
      okText="Update Order"
      okType=""
      okButtonProps={{
        className: 'br-reschdule-modal__primary-button',
        htmlType: 'submit',
        form: 'editDeliveryModalForm'
      }}
    >
      <div>
        <div className="br-reschedule-modal__header">
          <span>
            {[
              ORDER_TYPES_VALUE.CRP,
              ORDER_TYPES_VALUE.SIGN_AND_RETURN
            ].includes(delivery?.type)
              ? 'Edit Return Location details'
              : 'Edit CRP details'}
          </span>
          <CloseOutlined onClick={close} />
        </div>
        {initialValues && (
          <Form
            scrollToFirstError
            ref={formRef}
            //   onFinish={this.onFinish}
            initialValues={initialValues}
            className="br-edit-delivery-modal__form-container"
            name="editDeliveryModalForm"
            onFinish={handleOnFinish}
          >
            {delivery?.type === ORDER_TYPES_VALUE.EXCHANGE && (
              <Row>
                <Col span={24}>
                  <Form.Item name="itemsCount" label="No. of Return Items">
                    <BRFormInputNumber
                      maxLength={10}
                      label={intl.formatMessage({
                        id: 'shipments.new_order.form_labels.number_of_items'
                      })}
                      filedName="itemsCount"
                      handleOnChangeForm={handleOnChangeForm}
                      autoComplete={`${Math.random()}`}
                      rules={[
                        {
                          required: true,
                          label: intl.formatMessage({
                            id: `shipments.new_order.form_labels.number_of_items`
                          })
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={24}>
                <Form.Item name="locationId" label="Return location">
                  <Select>
                    {pickupLocations?.map((location) => (
                      <Select.Option key={location?._id} value={location?._id}>
                        {location.locationName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {delivery?.type === ORDER_TYPES_VALUE.EXCHANGE && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Return Package Description"
                  >
                    <Input.TextArea
                      maxLength={500}
                      autoSize={{ minRows: 4.1, maxRows: 4.1 }}
                      autoComplete={`${Math.random()}`}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(EditReturnDetailsModal);
