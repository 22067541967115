import { Tag } from 'antd';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import {
  DELIVERY_TYPES_CODES,
  COD_OPTIONS,
  FLYER_SIZE,
  PACKAGE_TYPES,
  SOHO
} from 'constants/shipments';
import { deliveryTypesEnum } from 'containers/Deliveries/constants';
import { calculateAging, formatAddress } from 'utils';

// type deliver => dropoff address => user enter (form value) , pickup address choosing from selection
// type exchange => dropoff address => user enter (form value) , return location as return address , pickup address choosing from selection
// type return => pickup address => user enter (form value) , return location =>dropoff address
// type cash colletion => pickup address => user enter (form value) , pickup address selection  as dropoff address

export const mappingPayload = (values, state) => {
  const {
    sender,
    selectedBusinessPickupLocations,
    orderProps,
    selectedPickupLocation
  } = state;
  const { packageTypeBulky, packageType, multiPackages } = values;
  let returnAddress;
  if (values.locationId) {
    const pickupLocation = selectedBusinessPickupLocations.find(
      (location) => location._id === values.locationId
    );

    returnAddress = {
      ...pickupLocation?.address,
      businessLocationId: pickupLocation?._id,
      returnAddressMobile: pickupLocation?.contactPerson.phone
    };
  }
  const orderDetails = {
    type:
      values.type === DELIVERY_TYPES_CODES.FORWARD && values?.locationId
        ? DELIVERY_TYPES_CODES.SIGN_AND_RETURN
        : values.type,
    cod:
      values?.cod && values?.cod !== COD_OPTIONS.WITH_OUT
        ? orderProps?.option === COD_OPTIONS.COD
          ? values.cod
          : `-${values.cod}`
        : 0,
    specs:
      values.type !== DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP &&
      values.type !== DELIVERY_TYPES_CODES.CASH_COLLECTION
        ? {
            packageDetails: {
              itemsCount: values?.itemsCount,
              description: values?.description
            },
            packageType:
              values?.packageType === PACKAGE_TYPES[2].value
                ? packageTypeBulky
                : packageType
          }
        : undefined,

    returnSpecs:
      values.type === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
        ? (values?.itemsCount || values?.description) && {
            packageDetails: {
              itemsCount: values?.itemsCount,
              description: values?.description
            }
          }
        : values?.returnItemsCount || values?.returnDescription
        ? {
            packageDetails: {
              itemsCount: values?.returnItemsCount,
              description: values?.returnDescription
            }
          }
        : undefined,
    businessReference: values?.businessReference,
    allowToOpenPackage: values?.allowToOpenPackage,
    pricingPackageSize: values?.pricingPackageSize,
    packageSize: values?.pricingPackageSize === FLYER_SIZE.WHITE_BAG ? 1 : 0,
    ...(multiPackages && { multiPackages: Number(multiPackages) })
  };

  const address = {
    apartment: values.apartment,
    buildingNumber: values.buildingNumber,
    firstLine: values.firstLine,
    floor: values.floor,
    districtId: values.districtId,
    secondLine: values.secondLine,
    isWorkAddress: values?.isWorkAddress
  };

  const receiver = {
    fullName: values.fullName,
    phone: values.phone,
    email: values.email,
    secondPhone: values.secondPhone
  };
  return {
    ...orderDetails,
    notes: values?.notes,
    dropOffAddress:
      values.type === DELIVERY_TYPES_CODES.CASH_COLLECTION
        ? {
            businessLocationId: selectedPickupLocation?._id,
            ...selectedPickupLocation.address
          }
        : values.type === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
        ? returnAddress
        : address,

    pickupAddress:
      values.type === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
      values.type === DELIVERY_TYPES_CODES.CASH_COLLECTION
        ? address
        : {
            businessLocationId: selectedPickupLocation?._id,
            ...(selectedPickupLocation.address || selectedPickupLocation)
          },

    receiver,
    sender,
    allowToOpenPackage: values.allowToOpenPackage,
    pickupRequestId: values.pickupRequestId,
    returnAddress:
      values.type === DELIVERY_TYPES_CODES.EXCHANGE ||
      values.type === DELIVERY_TYPES_CODES.FORWARD
        ? returnAddress
        : undefined
  };
};

export const buisnessData = (selectedBusiness) => {
  return [
    {
      key: 'selectedBusiness',
      label: 'Contact',
      render: () => {
        return `${
          selectedBusiness?.defaultPickupLocation?.contactPerson?.name || 'N/A'
        } - ${
          selectedBusiness?.defaultPickupLocation?.contactPerson?.phone || 'N/A'
        }`;
      }
    },
    {
      key: 'selectedBusiness:',
      label: 'Type',
      render: () => {
        return selectedBusiness?.type;
      }
    },
    {
      key: 'address:',
      label: 'Address',
      render: () => {
        return formatAddress(
          selectedBusiness?.defaultPickupLocation?.address || 'N/A'
        );
      }
    }
  ];
};

export const formatSignAndReturnType = (delivery) => {
  const {
    type,
    collectedFromConsignee,
    type_before,
    oldType,
    pickupRequestType
  } = delivery;
  const { RTO, SIGN_AND_RETURN } = ORDER_TYPES_VALUE;

  // rto will be type rto and check type before
  if (type === RTO) {
    const oldTypeValue = MAP_DELIVERY_TYPES[oldType];

    if (oldTypeValue) return `${oldTypeValue} - RTO`;
    else if (type_before === SIGN_AND_RETURN) return '(RTO) Sign & Return';
    else return 'RTO';
  } else if (
    pickupRequestType === 'Sign and Return Pickup' &&
    !collectedFromConsignee
  ) {
    return 'Sign & Return - Pickup';
  } else if (collectedFromConsignee) {
    return 'Sign & Return - Return';
  } else {
    return 'Sign & Return - Forward';
  }
};

export const formatType = (item) => {
  const { RTO, DELIVER, SIGN_AND_RETURN, FXF_SEND, CRP, CASH_COLLECTION } =
    ORDER_TYPES_VALUE;

  switch (item.type) {
    case SIGN_AND_RETURN:
    case RTO:
      return formatSignAndReturnType(item);

    case DELIVER:
    case FXF_SEND:
      return (
        <div className="br-delivery-type">
          <div>Deliver</div>
          {item?.creationSrc?.includes(SOHO) && (
            <Tag className="br-soho-tag">{SOHO}</Tag>
          )}
        </div>
      );
    case CRP:
      return 'CRP';
    case CASH_COLLECTION:
      return 'Cash Collection';

    default:
      return get(item, 'type', '');
  }
};

export const DATE_PAYLOAD = [
  'createdAtStart',
  'createdAtEnd',
  'pickedUpAtStart',
  'pickedUpAtEnd',
  'exceptionAtStart',
  'exceptionAtEnd',
  'canceledAtStart',
  'canceledAtEnd',
  'deliveredAtStart',
  'deliveredAtEnd',
  'damagedAtStart',
  'damagedAtEnd',
  'lostAtStart',
  'lostAtEnd',
  'ofdAtStart',
  'ofdAtEnd',
  'investigationAtStart',
  'investigationAtEnd',
  'scheduledAtStart',
  'scheduledAtEnd',
  'slaAtStart',
  'slaAtEnd',
  'deliverySlaAtStart',
  'deliverySlaAtEnd',
  'collectedAtStart',
  'collectedAtEnd',
  'confirmedAtStart',
  'confirmedAtEnd'
];

export const getDeliveryType = ({ type, collectedFromConsignee }) => {
  const { CUSTOMER_RETURN_PICKUP, EXCHANGE, SIGN_AND_RETURN } =
    deliveryTypesEnum;
  let mappedType = MAP_DELIVERY_TYPES[type] || type;

  if (collectedFromConsignee) {
    mappedType = `${mappedType} - Return`;
  } else {
    switch (type) {
      case CUSTOMER_RETURN_PICKUP:
        mappedType = `${mappedType} - Pickup`;
        break;

      case EXCHANGE:
      case SIGN_AND_RETURN:
        mappedType = `${mappedType} - Forward`;
        break;

      default:
        break;
    }
  }

  return mappedType;
};

export const isForwardOrder = (delivery) => {
  return !delivery.changedToRTODate && !delivery.collectedFromConsignee;
};

export const isPickupOrder = (delivery) => {
  const { CASH_COLLECTION, CRP } = ORDER_TYPES_VALUE;

  return (
    [CASH_COLLECTION, CRP].includes(delivery.type) &&
    !delivery.collectedFromConsignee
  );
};

export const isReturnOrder = (delivery) => {
  const { RTO, CRP, EXCHANGE, SIGN_AND_RETURN } = ORDER_TYPES_VALUE;
  return (
    delivery.type === RTO ||
    ([CRP, EXCHANGE, SIGN_AND_RETURN].includes(delivery.type) &&
      delivery.collectedFromConsignee)
  );
};

export const getOrderAge = (delivery) => {
  let orderStartingDatetime = dayjs();

  if (isForwardOrder(delivery)) {
    orderStartingDatetime = isPickupOrder(delivery)
      ? delivery.createdAt
      : delivery.collectedFromBusiness;
  } else {
    orderStartingDatetime =
      delivery.type == ORDER_TYPES_VALUE.RTO
        ? delivery.changedToRTODate
        : delivery.collectedFromConsignee;
  }

  return calculateAging(orderStartingDatetime);
};
