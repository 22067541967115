import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { InternationalShippingContext } from 'contexts/international-shipping.context';
import { getInternationalBusinesses } from 'services/business';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';
import { INTERNATIONAL_DELIVERY_UPDATE_TYPES } from 'constants/international-orders';

import BRTabs from 'components/BRTabs/BRTabs';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import InternationalPickups from 'components/InternationalOrders/components/InternationalPickups/InternationalPickups';
import InternationalDeliveries from 'components/InternationalOrders/components/InternationalDeliveries/InternationalDeliveries';
import InternationalOrdersActions from 'components/InternationalOrders/components/InternationalOrdersActions/InternationalOrdersActions';
import { notify } from 'components/Notify/Notify';

import './InternationalOrdersContainer.less';

const InternationalOrdersContainer = () => {
  const [businesses, setBusinesses] = useState([]);

  const intl = useIntl();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (
      isUserAuthorized(aclMatrix.BUSINESSES, [
        ACL_MATRIX.BUSINESSES_LIST,
        ACL_MATRIX.BUSINESS_LIST_INTERNATIONAL
      ])
    ) {
      fetchInternationalBusinesses();
    }
  }, []);

  const TABS = {
    deliveries: {
      label: intl.formatMessage({ id: 'international_orders.tabs.deliveries' }),
      component: InternationalDeliveries,
      path: '/international-shipping/deliveries',
      isUserAuthorized: isUserAuthorized(
        aclMatrix.DELIVERIES,
        [ACL_MATRIX.INTERNATIONAL_ORDER_LIST],
        { countries: COUNTRIES_CODE_NAME.AE }
      )
    },
    pickups: {
      label: intl.formatMessage({ id: 'international_orders.tabs.pickups' }),
      component: InternationalPickups,
      path: '/international-shipping/pickups',
      isUserAuthorized: isUserAuthorized(
        aclMatrix.DELIVERIES,
        [ACL_MATRIX.PICKUPS_LIST],
        { countries: COUNTRIES_CODE_NAME.AE }
      )
    },
    receive: {
      label: intl.formatMessage({ id: 'international_orders.tabs.receive' }),
      component: InternationalOrdersActions,
      componentProps: {
        updateType: INTERNATIONAL_DELIVERY_UPDATE_TYPES.RECEIVE_AT_WAREHOUSE
      },
      path: '/international-shipping/receive',
      isUserAuthorized: isUserAuthorized(
        aclMatrix.DELIVERIES,
        [ACL_MATRIX.INTERNATIONAL_ORDER_RECEIVE_AT_WAREHOUSE],
        { countries: COUNTRIES_CODE_NAME.AE }
      )
    },
    transfer: {
      label: intl.formatMessage({ id: 'international_orders.tabs.transfer' }),
      component: InternationalOrdersActions,
      componentProps: {
        updateType: INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER
      },
      path: '/international-shipping/transfer',
      isUserAuthorized: isUserAuthorized(
        aclMatrix.DELIVERIES,
        [ACL_MATRIX.INTERNATIONAL_ORDER_MARK_AS_IN_TRANSIT],
        { countries: [COUNTRIES_CODE_NAME.AE, COUNTRIES_CODE_NAME.EG] }
      )
    }
  };

  const fetchInternationalBusinesses = async () => {
    try {
      const data = await getInternationalBusinesses();
      setBusinesses(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const getTabsPath = () => {
    const path = [];
    Object.keys(TABS).filter((tab) => {
      return TABS[tab].isUserAuthorized && path.push(TABS[tab].path);
    });

    return path;
  };

  const getDefaultTabPath = () => {
    let pathName = '';
    Object.keys(TABS).some((tab) => {
      if (TABS[tab].isUserAuthorized) {
        pathName = TABS[tab].path;
        return true;
      }
    });
    return pathName;
  };

  return (
    <Switch>
      <Route
        path={getTabsPath()}
        render={() => (
          <Container
            className="br-international-orders__container"
            header={
              <BRHeader
                title={intl.formatMessage({
                  id: 'sidebar.international_orders'
                })}
              />
            }
            content={
              <InternationalShippingContext.Provider value={{ businesses }}>
                <BRTabs tabs={TABS} />
              </InternationalShippingContext.Provider>
            }
          />
        )}
      />
      <Redirect from={path} to={getDefaultTabPath()} />
    </Switch>
  );
};

export default InternationalOrdersContainer;
