import { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Button } from 'antd';

import aclMatrix from 'common/aclMatrix';
import { getAllHubs, getUsers, getAllStars } from 'services/stars';
import { CATEGORIES, STATES } from 'constants/bonuses-deductions';

import { notify } from 'components/Notify/Notify';

import './BonusAndDeductionFilter.less';

const BonusAndDeductionFilter = ({ intl, updateTable }) => {
  const [isLoading, setIsLoading] = useState({
    isHubsLoading: false,
    isStarsLoading: false,
    isUsersLoading: false
  });
  const [stars, setStars] = useState([]);
  const [allHubs, setHubs] = useState([]);
  const [users, setUsers] = useState([]);

  const formRef = useRef('');

  const getAccessdUsers = async () => {
    try {
      setIsLoading({ ...isLoading, isUsersLoading: true });
      const { users } = await getUsers(
        aclMatrix.BONUSES_AND_DEDUCTIONS.join(',')
      );
      setUsers(users);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading({ ...isLoading, isUsersLoading: false });
  };

  const getHubs = async () => {
    try {
      setIsLoading({ ...isLoading, isHubsLoading: true });
      const { result } = await getAllHubs();
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading({ ...isLoading, isHubsLoading: false });
  };

  const fetchAllStars = async () => {
    try {
      setIsLoading({ ...isLoading, isStarsLoading: true });
      const {
        message: { stars }
      } = await getAllStars({ verified: true });
      setStars(stars);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading({ ...isLoading, isStarsLoading: false });
  };

  useEffect(() => {
    fetchAllStars();
    getHubs();
    getAccessdUsers();
  }, []);

  const handleFormSubmit = (values) => {
    updateTable(values);
  };
  const handleResetFilter = async () => {
    formRef.current.resetFields();
    updateTable({});
  };
  return (
    <div>
      <Form onFinish={handleFormSubmit} ref={formRef} scrollToFirstError>
        <div className="display-flex br-bonus-deduction__filter">
          <Form.Item
            name="hub_id"
            label={intl.formatMessage({
              id: 'stars.create_edit_star.form_labels.hub'
            })}
          >
            <Select
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              autoComplete={`${Math.random()}`}
              showArrow
              loading={isLoading.isHubsLoading}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allHubs?.map((hub) => (
                <Select.Option key={hub._id} value={hub._id}>
                  {hub.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="star_id"
            label={intl.formatMessage({
              id: 'deliveries.search_filters.stars'
            })}
          >
            <Select
              placeholder={intl.formatMessage({
                id: `hubs.packages_debrief.select`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ?.join('')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              showArrow
              allowClear
              loading={isLoading.isStarsLoading}
            >
              {stars.map((star) => (
                <Select.Option key={star._id} value={star._id}>
                  {star.profile.firstName} {star.profile.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Type" name="type">
            <Select
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              autoComplete={`${Math.random()}`}
              allowClear
            >
              {CATEGORIES.map((category) => (
                <Select.Option key={category.value} value={category.value}>
                  {category.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              autoComplete={`${Math.random()}`}
              allowClear
            >
              {STATES.map((state) => (
                <Select.Option key={state.value} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Added By" name="user_id">
            <Select
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ?.join('')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              autoComplete={`${Math.random()}`}
              loading={isLoading.isUsersLoading}
              allowClear
            >
              {users?.map((user) => (
                <Select.Option key={user._id} value={user._id}>
                  {user?.profile?.firstName || ''}{' '}
                  {user.profile?.lastName || ''}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <span className="br-bonus-and-deduction__filter__actions">
            <Button type="danger" htmlType="submit">
              {intl.formatMessage({
                id: `bonuses_deductions.actions.search`
              })}
            </Button>
            <Button onClick={handleResetFilter}>
              {intl.formatMessage({
                id: `star_cycles.cycle_details.actions.clear`
              })}
            </Button>
          </span>
        </div>
      </Form>
    </div>
  );
};

export default injectIntl(BonusAndDeductionFilter);
