import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Popover } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CopyIcon } from 'assets/bosta-icons/Copy.svg';

import './SerialsSameType.less';

const SerialsSameType = ({
  type,
  serials = [],
  printSerials,
  copySerial,
  showExpandBtn
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const MAX_NUM_BEFORE_EXPAND = 10;

  const { formatMessage } = useIntl();

  const expand = () => setIsExpanded(true);

  const renderSerials = (list) =>
    list.map(({ serialNumber, productSku }) => (
      <Popover
        key={serialNumber}
        overlayClassName="br-serial-number__popover"
        content={
          <>
            <BRButton
              prefixIcon={<CopyIcon className="copy-icon" />}
              onClick={() => copySerial(serialNumber)}
            />
            <BRButton
              label={formatMessage({
                id: 'common.print'
              })}
              prefixIcon={<PrinterIcon />}
              onClick={() => printSerials([serialNumber])}
            />
          </>
        }
        trigger="click"
        placement="bottom"
      >
        <BRButton
          type="link"
          className="br-serial-number-btn"
          label={serialNumber}
        />
      </Popover>
    ));

  return (
    <div className="br-serials-same-type">
      <div className="br-serials-same-type__header">
        <span className="heading">
          {formatMessage(
            {
              id: `fulfillment_returns.serials_view.${type}.header_count`
            },
            {
              count: serials.length
            }
          )}
        </span>
        <BRButton
          label={formatMessage({
            id: `fulfillment_returns.serials_view.${type}.print_btn`
          })}
          prefixIcon={<PrinterIcon />}
          onClick={() =>
            printSerials(serials.map(({ serialNumber }) => serialNumber))
          }
        />
      </div>
      <div className="br-serials-same-type__serials">
        {showExpandBtn ? (
          <>
            {renderSerials(serials.slice(0, MAX_NUM_BEFORE_EXPAND))}
            {isExpanded && renderSerials(serials.slice(MAX_NUM_BEFORE_EXPAND))}
          </>
        ) : (
          renderSerials(serials)
        )}
      </div>
      {showExpandBtn && !isExpanded && serials.length > MAX_NUM_BEFORE_EXPAND && (
        <BRButton
          className="br-serials-same-type__view-all-btn"
          label={formatMessage({
            id: 'common.view_all'
          })}
          onClick={expand}
          type="treitary-color"
        />
      )}
    </div>
  );
};

export default SerialsSameType;
