import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';

const PRESET_RANGES = {
  'Last 7 Days': [dayjs().add(-7, 'd'), dayjs()],
  'Last 14 Days': [dayjs().add(-14, 'd'), dayjs()],
  'Last 30 Day': [dayjs().add(-30, 'd'), dayjs()]
};

const SearchDateRangePicker = ({ name, label, ...props }) => {
  return (
    <Form.Item name={name} label={label}>
      <DatePicker.RangePicker
        ranges={PRESET_RANGES}
        getPopupContainer={(trigger) => trigger.parentElement}
        {...props}
      />
    </Form.Item>
  );
};

export default SearchDateRangePicker;
