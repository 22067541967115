import { put, takeLatest } from 'redux-saga/effects';
import {
  getAllDeliveries,
  getAllHubs,
  getDelivery,
  getZonesByCity,
  getAllCities,
  printAirWayBill,
  cancelDeliveryWithReason,
  addDeliveryComment,
  rerequestDelivery,
  exportCSV,
  getAllStars,
  getAllActiveRoutes,
  returnDeliveriesToSender,
  addDeliveryToRoute,
  terminateDeliverys,
  resetDeliveredDelivery,
  resetDeliveredDeliveries
} from '../actions/DeliveriesActions';
import actionTypes from '../actions/ActionTypes';
import { endLoading, startLoading } from '../actions';
import { downloadAsCSV, downloadAsXlsx, showBill } from '../utils';
import { downloadAsPdf } from 'utils/download';

/**
 * fetch all hubs saga
 * @returns {?}
 */
function* fetchAllHubs() {
  let results = [];
  const data = yield getAllHubs();
  if (data.result) {
    results = data.result.map(({ _id, name, type }) => ({ _id, name, type }));
  }
  yield put({ type: actionTypes.GET_ALL_HUBS_RECEIVED, payload: results });
}

function* fetchAllDeliveries(queryParams) {
  /**
   * fetch all deliveries with query saga
   * @param queryParams
   * @returns {?}
   */
  let results = { count: 0, data: [], pagination: {} };
  yield put(startLoading());
  const { deliveries } = yield getAllDeliveries(queryParams);
  const { pageLimit, pageNumber } = queryParams?.data || {};
  if (deliveries) {
    results = {
      count:
        deliveries.length === queryParams.data.pageLimit
          ? deliveries.length * pageNumber + 1
          : deliveries.length,
      data: deliveries
    };
    results.pagination = { pageLimit, pageNumber };

    yield put({
      type: actionTypes.GET_ALL_DELIVERIES_RECEIVED,
      payload: results
    });
  }

  yield put(endLoading());
}

/**
 * fetch single delivery saga
 * @param queryParams
 * @returns {?}
 */
function* fetchDelivery(queryParams) {
  yield put(startLoading());
  const data = yield getDelivery(queryParams);
  yield put({ type: actionTypes.GET_DELIVERY_RECEIVED, payload: data });
  yield put(endLoading());
}

/**
 * fetch cities lookup saga
 * @returns {?}
 */
function* fetchCities() {
  yield put(startLoading());
  const data = yield getAllCities();
  yield put({ type: actionTypes.GET_ALL_CITIES_RECEIVED, payload: data });
  yield put(endLoading());
}

/**
 * fetch zones by city id saga
 * @param data
 * @returns {?}
 */
function* fetchZones({ data }) {
  yield put(startLoading());
  const res = yield getZonesByCity({ id: data.id });
  yield put({
    type:
      data.type === 'pickup'
        ? actionTypes.GET_PICKUP_ZONES_RECEIVED
        : actionTypes.GET_DROP_OFF_ZONES_RECEIVED,
    payload: res
  });
  yield put(endLoading());
}

/**
 * get ready print air wat bill pdf file saga
 * @param data
 * @returns {?}
 */
function* printBill({ data }) {
  yield put(startLoading());
  const res = yield printAirWayBill(data.ids);
  if (res?.data) {
    downloadAsPdf(res.data, data.trackingNumber);
  }
  yield put(endLoading());
}

/**
 * cancel delivery saga
 * @param data
 * @returns {?}
 */
function* cancelDelivery({ data }) {
  yield put(startLoading());
  const res = yield cancelDeliveryWithReason(data);
  yield put({
    type: actionTypes.CANCEL_DELIVERY_DONE,
    payload: res
  });
  yield put(endLoading());
}

/**
 * add comment to delievery saga
 * @param data
 * @returns {?}
 */
function* addCommentToDelivery({ data }) {
  yield put(startLoading());
  const res = yield addDeliveryComment(data);
  yield put({
    type: actionTypes.ADD_DELIVERY_COMMENT_DONE,
    payload: res
  });
  yield put(endLoading());
}

/**
 * re-request cancelled delivery saga
 * @param data
 * @returns {?}
 */
function* rerequestCancelledDelivery({ data }) {
  yield put(startLoading());
  const res = yield rerequestDelivery(data);
  yield put({ type: actionTypes.REREQUEST_DELIVERY_DONE, payload: res });
  yield put(endLoading());
}

/**
 * exporting deliveries with filter applies as CSV saga
 * @param data
 * @returns {?}
 */
function* exportDeliveriesAsCSV({ data }) {
  yield put(startLoading());
  const res = yield exportCSV(data);
  if (res) {
    // downloadAsCSV(res, 'deliveries.csv');
    downloadAsXlsx(res, `deliveries.xlsx`);
  }
  yield put({ type: actionTypes.REREQUEST_DELIVERY_DONE, payload: res });
  yield put(endLoading());
}

/**
 * get all stars lookup
 * @returns {?}
 */
function* getStars() {
  yield put(startLoading());
  let res = yield getAllStars();
  if (!res) res = { count: 0, data: [] };
  yield put({ type: actionTypes.GET_ALL_STARS_RECEIVED, payload: res });
  yield put(endLoading());
}

/**
 * get active routes
 * @returns {?}
 */
function* getActiveRoutes() {
  yield put(startLoading());
  let results = [];
  const res = yield getAllActiveRoutes();
  if (res) results = [...res.result];

  yield put({
    type: actionTypes.GET_ALL_ACTIVE_ROUTES_RECEIVED,
    payload: results
  });
  yield put(endLoading());
}

/**
 * return to business
 * @param data
 * @returns {?}
 */
function* returnToBusiness({ data }) {
  yield put(startLoading());
  let res = yield returnDeliveriesToSender(data);
  if (!res) res = [];
  yield put({
    type: actionTypes.RETURN_DELIVERIES_TO_BUSINESS_DONE,
    payload: res
  });
  yield put(endLoading());
}

/**
 * add deliveries to route
 * @param data
 * @returns {?}
 */
function* addToRoute({ data }) {
  yield put(startLoading());
  let res = yield addDeliveryToRoute(data);
  if (!res) res = [];
  yield put({ type: actionTypes.ADD_DELIVERIES_TO_ROUTE_DONE, payload: res });
  yield put(endLoading());
}

/**
 * terminate deliveries
 * @param data
 * @returns {?}
 */
function* terminateDeliveries({ data }) {
  yield put(startLoading());
  let res = yield terminateDeliverys(data);
  if (!res) res = [];
  yield put({ type: actionTypes.TERMINATE_DELIVERY_DONE, payload: res });
  yield put(endLoading());
}

/**
 * reset deliveries
 * @param data
 * @returns {?}
 */
function* resetDelivery({ data }) {
  yield put(startLoading());
  let res = yield resetDeliveredDelivery(data);
  if (!res) res = [];
  yield put({ type: actionTypes.RESET_DELIVERED_DELIVERY_DONE, payload: res });
  yield put(endLoading());
}

/**
 * reset multible deliveries
 * @param data
 * @returns {?}
 */
function* resetDeliveries({ data }) {
  yield put(startLoading());
  let res = yield resetDeliveredDeliveries(data);
  if (!res) res = [];
  yield put({ type: actionTypes.RESET_DELIVERED_DELVERIES_DONE, payload: res });
  yield put(endLoading());
}

/**
 * deliveries watcher
 * @returns {?}
 */
export default function* deliveriesWatcher() {
  yield takeLatest(actionTypes.GET_ALL_HUBS, fetchAllHubs);
  yield takeLatest(actionTypes.GET_ALL_DELIVERIES, fetchAllDeliveries);
  yield takeLatest(actionTypes.GET_DELIVERY, fetchDelivery);
  yield takeLatest(actionTypes.GET_ALL_CITIES, fetchCities);
  yield takeLatest(actionTypes.GET_PICKUP_ZONES, fetchZones);
  yield takeLatest(actionTypes.GET_DROP_OFF_ZONES, fetchZones);
  yield takeLatest(actionTypes.PRINT_AIR_WAY_BILL, printBill);
  yield takeLatest(actionTypes.CANCEL_DELIVERY, cancelDelivery);
  yield takeLatest(actionTypes.ADD_DELIVERY_COMMENT, addCommentToDelivery);
  yield takeLatest(actionTypes.REREQUEST_DELIVERY, rerequestCancelledDelivery);
  yield takeLatest(actionTypes.EXPORT_DELIVERIES_AS_CSV, exportDeliveriesAsCSV);
  yield takeLatest(actionTypes.GET_ALL_STARS, getStars);
  yield takeLatest(actionTypes.GET_ALL_ACTIVE_ROUTES, getActiveRoutes);
  yield takeLatest(actionTypes.RETURN_DELIVERIES_TO_BUSINESS, returnToBusiness);
  yield takeLatest(actionTypes.ADD_DELIVERIES_TO_ROUTE, addToRoute);
  yield takeLatest(actionTypes.TERMINATE_DELIVERY, terminateDeliveries);
  yield takeLatest(actionTypes.RESET_DELIVERED_DELIVERY, resetDelivery);
  yield takeLatest(actionTypes.RESET_DELIVERED_DELVERIES, resetDeliveries);
}
