import React, { useState } from 'react';
import { Button, Upload, message } from 'antd';
import { injectIntl } from 'react-intl';

import { useCancellablePromise } from 'utils/promise';
import { convertExcelBusinessConfigurationToJSON } from 'utils/wallet';
import {
  uploadBusinessConfig,
  updateUploadSuccess,
  uploadSheetToGoogleBucket,
  exportBusinessConfigSheet
} from 'services/wallet';
import { openModal } from 'utils/modal';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import ConfirmUploadSheetModal from './ConfirmUploadSheetModal/ConfirmUploadSheetModal';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';

import { ReactComponent as UploadImage } from 'assets/imgRevamp/upload-wallet-template-icon.svg';
import { ReactComponent as UploadButtonIcon } from 'assets/imgRevamp/wallet-button-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/imgRevamp/wallet-success-icon.svg';
import { ReactComponent as ExcelIcon } from 'assets/imgRevamp/excel-icon.svg';
import { ReactComponent as GreenInfoIcon } from 'assets/imgRevamp/green-info.svg';

import './BusinessConfiguration.less';

const BusinessConfiguration = ({ intl }) => {
  const { Dragger } = Upload;

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState({});
  const [businessConfigLength, setBusinessConfigLength] = useState(0);

  const { cancellablePromise } = useCancellablePromise();

  const handleUpload = async (file) => {
    setFile({ name: file.name, size: file.size / 1024 / 1024 });
    setIsLoading(true);
    let adjustments = null;
    try {
      adjustments = await cancellablePromise(
        convertExcelBusinessConfigurationToJSON(file)
      );
      openModal(ConfirmUploadSheetModal, {
        onSuccess: async () => {
          let sheetName = file.name.slice(0, -5);
          const isoDate = new Date().toISOString();
          sheetName = sheetName + ` ${isoDate}.xlsx`;
          const { url } = await uploadBusinessConfig({ sheetName });
          await uploadSheetToGoogleBucket(url, file);
          const { message } = await updateUploadSuccess(sheetName);
          setBusinessConfigLength(adjustments.length);
          notify(message, 'success');
        },
        businessCount: adjustments.length
      });
    } catch (error) {
      if (error.message.includes('Wrong template')) {
        notify('Unsupported Template!');
      } else {
        notify(error.message);
      }
    }
    setIsLoading(false);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: handleUpload
  };

  const handleOnClickUploadMore = async () => {
    setIsLoading(true);
    try {
      const { message } = await exportBusinessConfigSheet();
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Container
      className="br-wallet-container"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'wallet.business_configuration.header'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          {!!businessConfigLength ? (
            <div className="br-balance-adjustment-success">
              <SuccessIcon className="sucess-icon" />
              <span className="sucess-title">
                {intl.formatMessage({
                  id: 'wallet.business_configuration.success_screen.title'
                })}
              </span>
              <span className="sucess-subtitle">
                {intl.formatMessage({
                  id: 'wallet.business_configuration.success_screen.sub_title'
                })}
              </span>
              <div className="br-balance-adjustment-sucess-info">
                <ExcelIcon />
                <div>
                  <span className="file-name-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.business_configuration.success_screen.file_name'
                      },
                      {
                        fileName: <span>{file.name}</span>
                      }
                    )}
                  </span>
                  <span className="total-business-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.business_configuration.success_screen.total_records'
                      },
                      {
                        totalRecords: <span>{businessConfigLength}</span>
                      }
                    )}
                  </span>
                </div>
              </div>
              <Button onClick={handleOnClickUploadMore} disabled={isLoading}>
                {intl.formatMessage({
                  id: 'wallet.business_configuration.success_screen.export_button'
                })}
              </Button>
            </div>
          ) : (
            <div className="br-wallet-balance-adjustment">
              <div className="br-balance-adjustment__download-template-container">
                <GreenInfoIcon />
                <div>
                  <span>Business Configurations Template!</span>
                  <span>
                    Please make sure to download this sheet to upload business
                    configurations.
                  </span>
                  <Button onClick={handleOnClickUploadMore}>
                    Export First
                  </Button>
                </div>
              </div>
              <div className="br-balance-adjustment__upload-container">
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <UploadImage />
                  </p>
                  <p className="ant-upload-text">
                    {intl.formatMessage(
                      {
                        id: 'wallet.balance_adjustment.upload_hint'
                      },
                      {
                        clickToUpload: (
                          <span>
                            {intl.formatMessage({
                              id: 'wallet.balance_adjustment.click_to_upload'
                            })}
                          </span>
                        )
                      }
                    )}
                  </p>
                  <p className="ant-upload-hint">
                    {intl.formatMessage({
                      id: 'wallet.balance_adjustment.support_text'
                    })}
                  </p>
                  <Button type="primary">
                    <UploadButtonIcon />
                    {intl.formatMessage({
                      id: 'orders.mass_upload.upload_dialog.upload_file'
                    })}
                  </Button>
                </Dragger>
              </div>
            </div>
          )}
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(BusinessConfiguration);
