import { useEffect, useState } from 'react';

import { searchSeal } from 'services/hubs';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { SEAL_SCAN_TYPE } from 'constants/hubs';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import SealsTable from './components/SealsTable/SealsTable';
import MasterSealScan from './components/MasterSealScan/MasterSealScan';
import SealScan from './components/SealScan/SealScan';
import TrackingNumberScan from './components/TrackingNumberScan/TrackingNumberScan';
import ReceiveSealsHeader from './components/ReceiveSealsHeader/ReceiveSealsHeader';

import './ReceiveSeals.less';

const VIEWS_KEYS = {
  SEALS_TABLE: 'SEALS_TABLE',
  MASTER_SEAL_SCAN: 'MASTER_SEAL_SCAN',
  SEAL_SCAN: 'SEAL_SCAN',
  TRACKING_NUMBER_SCAN: 'TRACKING_NUMBER_SCAN'
};

const { SEALS_TABLE, MASTER_SEAL_SCAN, SEAL_SCAN, TRACKING_NUMBER_SCAN } =
  VIEWS_KEYS;

const ReceiveSeals = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState(SEALS_TABLE);
  const [currentViewProps, setCurrentViewProps] = useState({});
  const [scanningType, setScanningType] = useState(SEAL_SCAN_TYPE.NORMAL);
  const [isReceivingStarted, setIsReceivingStarted] = useState(false);

  const currentUserHubInfo = getCurrentUserHubInfo();

  const handleOnScanningTypeChange = (value) => {
    const { NORMAL, BULKY } = SEAL_SCAN_TYPE;

    const SCANNING_TYPE_MAPPING = {
      [NORMAL]: SEAL_SCAN,
      [BULKY]: TRACKING_NUMBER_SCAN
    };

    setCurrentViewProps({
      masterSeal: currentViewProps.masterSeal
    });
    setScanningType(value);
    setCurrentView(SCANNING_TYPE_MAPPING[value]);
  };

  const renderContent = () => {
    const hubId = currentUserHubInfo?._id;
    const sharedProps = {
      setCurrentView,
      currentViewProps,
      setCurrentViewProps,
      scanningType,
      handleOnScanningTypeChange,
      isLoading
    };

    const views = {
      [SEALS_TABLE]: {
        Component: SealsTable,
        componentProps: {
          hubId
        }
      },
      [MASTER_SEAL_SCAN]: {
        Component: MasterSealScan,
        componentProps: {
          ...sharedProps,
          hubId,
          goToNextView: () => setCurrentView(SEAL_SCAN)
        }
      },
      [SEAL_SCAN]: {
        Component: SealScan,
        componentProps: {
          ...sharedProps,
          hubId,
          goToNextView: () => setCurrentView(TRACKING_NUMBER_SCAN)
        }
      },
      [TRACKING_NUMBER_SCAN]: {
        Component: TrackingNumberScan,
        componentProps: {
          ...sharedProps,
          setIsLoading,
          geToPreviousView: () => {
            setScanningType(SEAL_SCAN_TYPE.NORMAL);
            setCurrentView(SEAL_SCAN);
          }
        }
      }
    };

    const { Component, componentProps = {} } = views[currentView];

    return (
      <Component
        getSeals={getSeals}
        {...componentProps}
        {...currentViewProps}
      />
    );
  };

  const getSeals = async (payload) => {
    setIsLoading(true);

    try {
      const { data } = await searchSeal(payload);
      setIsLoading(false);

      return data;
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const handleStartReceivingClick = () => {
    setCurrentView(MASTER_SEAL_SCAN);
    setIsReceivingStarted(true);
  };

  const handleCancelReceivingClick = () => {
    setCurrentView(SEALS_TABLE);
    setScanningType(SEAL_SCAN_TYPE.NORMAL);
    setIsReceivingStarted(false);
    setCurrentViewProps({});
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-receive-seals">
        <ReceiveSealsHeader
          isReceivingStarted={isReceivingStarted}
          handleStartReceivingClick={handleStartReceivingClick}
          handleCancelReceivingClick={handleCancelReceivingClick}
        />
        <div className="br-receive-seals__content">{renderContent()}</div>
      </div>
    </LoadingWrapper>
  );
};

export default ReceiveSeals;
