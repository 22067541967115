import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { requiredRule } from 'utils/forms';
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Typography,
  Tooltip,
  Checkbox
} from 'antd';
import Icon, { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { openModal } from 'utils/modal';
import { DATE_FORMAT } from 'constants/date-picker';
import { disabledDate, UTCWithZeroTime } from 'utils/pickups';
import {
  REPEATED_TYPE_ONE_TIME,
  PICKUP_STATUS,
  NORMAL_PACKAGE_TYPE,
  LIGHT_BULKY_PACKAGE_TYPE,
  HEAVY_BULKY_PACKAGE_TYPE,
  RECURRING_PICKUP_BUSINESS_TIERS
} from 'constants/pickups';
import {
  fetchBusinessPickupLocations,
  getBusiness,
  getInternationalBusinesses
} from 'services/business';
import {
  fetchPickupLocation,
  fetchPickup,
  createPickup,
  editPickup
} from 'services/pickups';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { HUB_LEADER, WAREHOUSE_CLERK } from 'common/aclMatrix';
import { PICKUP_LOCATION_REGIONS } from 'constants/international-pickups';
import { getOfficialHolidays } from 'services/system';

import { notify } from 'components/Notify/Notify';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import CreatePickupLocationFormModal from 'components/Pickups/components/CreatePickupLocationFormModal/CreatePickupLocationFormModal';
import CreatePickupRecurrenceFormModal from 'components/Pickups/components/CreatePickupRecurrenceFormModal/CreatePickupRecurrenceFormModal';
import CreateContactPersonFormModal from 'components/Pickups/components/CreateContactPersonFormModal/CreateContactPersonFormModal';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Calendar } from 'assets/imgRevamp/Calendar.svg';

import './CreateEditPickupForm.less';

const CreateEditPickupForm = ({
  intl,
  history,
  match: {
    params: { id: pickupId }
  },
  isInternationalPickup
}) => {
  const [isLightBulky, setIsLightBulky] = useState(false);
  const [isHeavyBulky, setIsHeavyBulky] = useState(false);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [pickupLocation, setPickupLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPickupDataLoading, setDefaultPickupDataLoading] =
    useState(false);
  const [repeatValues, setRepeatValues] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [isNewZoning, setIsNewZoning] = useState(null);
  const [businessInfo, setBusinessInfo] = useState({});
  const [internationalBusinesses, setInternationalBusinesses] = useState([]);
  const [holidaysDates, setHolidaysDates] = useState([]);

  const formRef = useRef('');

  const checkPickupLocation = useCallback((PickupLocation) => {
    if (PickupLocation?.isNewZoning) {
      setIsNewZoning(true);
    } else {
      setIsNewZoning(false);
      openModal(CreatePickupLocationFormModal, {
        pickupLocation: PickupLocation,
        businessId: formRef.current.getFieldValue('businessId') || businessId,
        onSuccess: () => {
          getBusinessPickupLocations(
            formRef.current.getFieldValue('businessId') || businessId
          );
        }
      });
    }
  }, []);

  const getPickup = async () => {
    try {
      setDefaultPickupDataLoading(true);
      const { message: pickup } = await fetchPickup(pickupId);
      const {
        business: { _id: businessId },
        businessLocationId,
        state,
        scheduledDate,
        contactPerson: { name, phone, secPhone, email },
        repeatedData,
        notes
      } = pickup;

      if (state !== PICKUP_STATUS.REQUESTED) {
        notify('Denied Action');
        history.push('/pickups');
      }

      formRef.current.setFieldsValue({
        businessId,
        locationId: businessLocationId,
        pickupDate: dayjs(scheduledDate),
        name,
        phone,
        secPhone,
        email,
        notes
      });

      setRepeatValues(
        repeatedData.repeatedType !== REPEATED_TYPE_ONE_TIME
          ? repeatedData
          : null
      );

      setDefaultPickupDataLoading(false);

      return pickup;
    } catch (error) {
      if (error.message.includes('not found')) {
        history.push('/pickups');
      }
      notify(error.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (pickupId) {
        const {
          business: { _id: businessId },
          businessLocationId: pickupLocationId,
          packageType
        } = await getPickup();
        if (packageType && packageType === LIGHT_BULKY_PACKAGE_TYPE) {
          formRef.current.setFieldsValue({
            packageType: LIGHT_BULKY_PACKAGE_TYPE
          });
          setIsLightBulky(true);
        } else if (packageType && packageType === HEAVY_BULKY_PACKAGE_TYPE) {
          formRef.current.setFieldsValue({
            packageType: HEAVY_BULKY_PACKAGE_TYPE
          });
          setIsHeavyBulky(true);
        }
        getBusinessPickupLocations(businessId);
        setBusinessId(businessId);
        getPickupLocation(pickupLocationId, businessId);
      }
    }
    fetchData();
  }, [getPickup, pickupId]);

  useEffect(() => {
    if (isInternationalPickup) {
      fetchInternationalBusinesses();
    }
  }, []);

  useEffect(() => {
    if (businessInfo?._id) {
      fetchHolidays();
    }
  }, [businessInfo]);

  const fetchHolidays = async () => {
    try {
      const { data } = await getOfficialHolidays();

      const holidays = data
        .filter(
          (holiday) =>
            holiday?.stopSchedulingPickups &&
            (!holiday?.businessTiers ||
              holiday?.businessTiers?.includes(businessInfo?.businessTier))
        )
        .map(({ holidayDate }) => dayjs(holidayDate, 'DD-MM-YYYY'));

      setHolidaysDates(holidays);
    } catch (error) {
      notify(error.message);
    }
  };

  const getBusinessPickupLocations = async (businessId) => {
    try {
      setIsLoading(true);
      const { data } = await fetchBusinessPickupLocations(businessId);

      const locations = isInternationalPickup
        ? data.filter(
            (location) =>
              location.pickupLocationRegion === PICKUP_LOCATION_REGIONS.LOCAL
          )
        : data;

      setPickupLocations(locations);
      setBusinessId(businessId);
      setIsLoading(false);
    } catch (error) {
      notify(error.message);
    }
  };

  const getBusinessInfo = async (businessId) => {
    setIsLoading(true);
    try {
      const data = await getBusiness(businessId);
      setBusinessInfo(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getPickupLocation = async (locationId, businessId) => {
    try {
      const { data: pickupLocation } = await fetchPickupLocation(locationId, {
        businessId: formRef.current.getFieldValue('businessId') || businessId
      });
      checkPickupLocation(pickupLocation);
      setPickupLocation(pickupLocation);
      return pickupLocation;
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchInternationalBusinesses = async () => {
    setIsLoading(true);
    try {
      const data = await getInternationalBusinesses();
      setInternationalBusinesses(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleBusinessChange = async (businessId) => {
    setBusinessId(businessId);
    await getBusinessPickupLocations(businessId);

    if (!isInternationalPickup) {
      await getBusinessInfo(businessId);
    }

    formRef.current.setFieldsValue({
      locationId: null,
      name: null,
      phone: null,
      secPhone: null,
      email: null
    });
  };

  const handlePickupLocationChange = async (locationId) => {
    await getPickupLocation(locationId);
    formRef.current.setFieldsValue({
      locationId,
      name: null,
      phone: null,
      secPhone: null,
      email: null
    });
  };

  const handleContactChange = async (contactName) => {
    const { phone, secPhone, email } = pickupLocation.contacts.find(
      (contact) => contact.name === contactName
    );
    formRef.current.setFieldsValue({
      phone,
      secPhone,
      email
    });
  };

  const handleOnSelectLightBulky = ({ target: { checked } }) => {
    if (checked) {
      setIsLightBulky(true);
      setIsHeavyBulky(false);
      formRef.current.setFieldsValue({ packageType: LIGHT_BULKY_PACKAGE_TYPE });
    } else {
      setIsLightBulky(false);
      formRef.current.setFieldsValue({ packageType: null });
    }
  };

  const handleOnSelectHeavyBulky = ({ target: { checked } }) => {
    if (checked) {
      setIsHeavyBulky(true);
      setIsLightBulky(false);
      formRef.current.setFieldsValue({ packageType: HEAVY_BULKY_PACKAGE_TYPE });
    } else {
      setIsHeavyBulky(false);
      formRef.current.setFieldsValue({ packageType: null });
    }
  };
  const onFinish = async (values) => {
    if (isNewZoning) {
      try {
        const {
          businessId,
          locationId,
          notes,
          pickupDate,
          name,
          phone,
          secPhone,
          packageType,
          email
        } = values;

        if (
          repeatValues &&
          !repeatValues.endDate &&
          repeatValues.repeatedType !== REPEATED_TYPE_ONE_TIME
        ) {
          notify(
            intl.formatMessage({
              id: `pickups.create_edit_pickup.edit_pickup_error`
            })
          );
        } else {
          let payload = {
            businessId,
            businessLocationId: locationId,
            notes,
            scheduledDate: dayjs(pickupDate).format(DATE_FORMAT),
            packageType: packageType || NORMAL_PACKAGE_TYPE,
            contactPerson: {
              name,
              phone,
              ...(secPhone && { secPhone }),
              ...(email && { email })
            },
            repeatedData: repeatValues
              ? {
                  ...repeatValues,

                  endDate: UTCWithZeroTime(repeatValues.endDate),
                  startDate: UTCWithZeroTime(repeatValues.startDate)
                }
              : undefined
          };
          if (pickupId) {
            delete payload?.repeatedData?.nextPickupDate;
            await editPickup({ id: pickupId, payload });
            notify(
              intl.formatMessage({
                id: `pickups.create_edit_pickup.edit_pickup_success`
              }),
              'success'
            );
          } else {
            payload = {
              ...payload,
              creationSrc: 'Admin',
              dashboardSource: 'Pickup request page'
            };
            await createPickup(payload);
            notify(
              intl.formatMessage({
                id: `pickups.create_edit_pickup.create_pickup_success`
              }),
              'success'
            );
          }
          history.push(
            isInternationalPickup
              ? '/international-shipping/pickups'
              : '/pickups'
          );
        }
      } catch (error) {
        notify(error.message);
      }
    } else {
      const { locationId } = values;
      notify(
        intl.formatMessage({
          id: `pickups.create_edit_pickup.error_create_new_zoning`
        })
      );
      checkPickupLocation(
        pickupLocations.find(
          (pickupLocationItem) => pickupLocationItem._id === locationId
        )
      );
    }
  };

  const handleOnOk = (values) => {
    if (
      values.repeatedType !== REPEATED_TYPE_ONE_TIME &&
      formRef.current.getFieldValue('pickupDate') !== values.startDate
    ) {
      formRef.current.setFieldsValue({
        pickupDate: dayjs(new Date(values.startDate))
      });
    }
    setRepeatValues(values);
  };

  const handleCreateContactPersonClick = useCallback(() => {
    if (!businessId) {
      notify(
        intl.formatMessage({
          id: `pickups.create_edit_pickup.error_empty_business_name`
        })
      );
    } else if (pickupLocation.canEdit) {
      // segmentTracking('E_PICKUP_NEW_CONTACT_CREATE', []);

      openModal(CreateContactPersonFormModal, {
        businessId,
        pickupLocation,
        getPickupLocation,
        onOk: handleOnOk
      });
    } else {
      notify(
        intl.formatMessage({
          id: !formRef.current.getFieldValue('locationId')
            ? 'settings.pickup_locations.pickup_location_form.add_contact_before_location_error'
            : 'settings.pickup_locations.pickup_location_form.add_contact_error'
        })
      );
    }
  });

  const handleCreateRecurringClick = useCallback(() => {
    // sendSegment('E_PICKUP_REAPEAT');
    openModal(CreatePickupRecurrenceFormModal, {
      values: repeatValues,
      onOk: handleOnOk,
      pickupDate: formRef.current.getFieldValue('pickupDate')
    });
  });

  const handleOnChangePickupDate = (evt) => {
    if (
      repeatValues &&
      UTCWithZeroTime(evt) > UTCWithZeroTime(repeatValues.endDate)
    ) {
      setRepeatValues((prev) => ({
        ...prev,
        endDate: undefined
      }));
    }
    if (
      repeatValues &&
      repeatValues.repeatedType !== REPEATED_TYPE_ONE_TIME &&
      UTCWithZeroTime(evt) !== UTCWithZeroTime(repeatValues.startDate)
    ) {
      setRepeatValues((prev) => ({
        ...prev,
        startDate: UTCWithZeroTime(evt)
      }));
    }
  };

  const handleAddNewLocation = () => {
    if (!businessId) {
      return notify(
        intl.formatMessage({
          id: `pickups.create_edit_pickup.error_empty_business_name`
        })
      );
    }

    openModal(CreatePickupLocationFormModal, {
      businessId,
      onSuccess: (pickupLocationId) => {
        getBusinessPickupLocations(businessId);
        handlePickupLocationChange(pickupLocationId);
      }
    });
  };

  const disabledPickupDate = (current) => {
    if (isInternationalPickup) {
      return current < dayjs().startOf('day');
    }

    return disabledDate(current, new Date(), holidaysDates);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title={`${pickupId ? 'Edit' : 'Create'} Pickup Requests`}
            showBackArrow
          />
        }
        content={
          <div className="br-pickups__pickup-settings">
            <Link to="/pickups" className="br-header-back-btn">
              <LeftOutlined />
              <span>
                {intl.formatMessage({
                  id: 'pickups.actions.back_button'
                })}
              </span>
            </Link>
            <div className="br-pickups__pickup-settings__form__header">
              <span className="br-pickups__pickup-settings__form__header__title">
                <Typography.Title level={3}>
                  {intl.formatMessage({
                    id: pickupId
                      ? `pickups.create_edit_pickup.pickup_edit_title`
                      : `pickups.create_edit_pickup.pickup_create_title`
                  })}
                </Typography.Title>
              </span>
              <span className="br-pickups__pickup-settings__form__header__sub-title">
                {intl.formatMessage({
                  id: `pickups.create_edit_pickup.pickup_sub_title`
                })}
              </span>
            </div>
            <Form
              className="br-pickups__pickup-settings__form"
              onFinish={onFinish}
              ref={formRef}
              scrollToFirstError
            >
              <Form.Item
                name="businessId"
                rules={[{ required: true }]}
                label={intl.formatMessage({
                  id: `pickups.create_edit_pickup.form_labels.business`
                })}
              >
                {isInternationalPickup ? (
                  <Select
                    className="br-pickups__pickup-settings__form__selector"
                    showSearch
                    allowClear
                    options={internationalBusinesses}
                    onChange={handleBusinessChange}
                    fieldNames={{ label: 'name', value: '_id' }}
                    placeholder={intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_placeholders.select_business`
                    })}
                  />
                ) : (
                  <BusinessSelector
                    className="br-pickups__pickup-settings__form__selector"
                    placeholder={intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_placeholders.select_business`
                    })}
                    onChange={handleBusinessChange}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="locationId"
                rules={[
                  requiredRule(
                    intl.formatMessage(
                      {
                        id: 'form.required_custom'
                      },
                      { label: 'Location Name' }
                    )
                  )
                ]}
                label={
                  <Tooltip
                    title={
                      !pickupId
                        ? intl.formatMessage({
                            id: `pickups.create_edit_pickup.form_labels_tooltips.pickup_location`
                          })
                        : ''
                    }
                  >
                    {intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_labels.pickup_location`
                    })}
                  </Tooltip>
                }
              >
                <Select
                  className="br-pickups__pickup-settings__form__selector"
                  placeholder={intl.formatMessage({
                    id: `pickups.create_edit_pickup.form_placeholders.select_location`
                  })}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handlePickupLocationChange}
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {pickupLocations.map((location) => (
                    <Select.Option key={location._id} value={location._id}>
                      {location.locationName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!isInternationalPickup && (
                <BRButton
                  type="link-color"
                  prefixIcon={<PlusOutlined />}
                  label={intl.formatMessage({
                    id: `pickups.actions.add_location`
                  })}
                  onClick={handleAddNewLocation}
                />
              )}

              <div className="br-pickups__pickup-settings__form__pickup_date">
                <Form.Item
                  name="pickupDate"
                  rules={[
                    requiredRule(
                      intl.formatMessage(
                        {
                          id: 'form.required_custom'
                        },
                        { label: 'Pickup date' }
                      )
                    )
                  ]}
                  label={
                    <Tooltip
                      title={
                        !pickupId
                          ? intl.formatMessage({
                              id: `pickups.create_edit_pickup.form_labels_tooltips.select_date`
                            })
                          : ''
                      }
                    >
                      {intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_labels.pickup_date`
                      })}
                    </Tooltip>
                  }
                >
                  <DatePicker
                    placeholder={intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_placeholders.select_date`
                    })}
                    onChange={(evt) => handleOnChangePickupDate(evt)}
                    className="br-pickups__pickup-settings__form__selector"
                    disabledDate={disabledPickupDate}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    disabled={
                      pickupId &&
                      isUserAuthorized([HUB_LEADER, WAREHOUSE_CLERK]) &&
                      !isAdminGroupUser()
                    }
                  />
                </Form.Item>
              </div>
              {(RECURRING_PICKUP_BUSINESS_TIERS.includes(
                businessInfo.businessTier
              ) ||
                businessInfo?.allowRecurringPickups) &&
                (repeatValues === null ||
                repeatValues.repeatedType === REPEATED_TYPE_ONE_TIME ? (
                  <div className="br-pickups__pickup-settings__form__repeat-recurring-title">
                    <Typography.Title level={4}>
                      {intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_labels.pickup_repeat`
                      })}
                    </Typography.Title>
                    <Button type="primary" onClick={handleCreateRecurringClick}>
                      {intl.formatMessage({
                        id: `pickups.actions.create_repeated`
                      })}
                    </Button>
                  </div>
                ) : (
                  <div className="br-pickups__pickup-settings__form__repeat-recurring-edit-content">
                    <Button onClick={handleCreateRecurringClick}>
                      {intl.formatMessage({
                        id: `pickups.actions.edit_repeated`
                      })}
                    </Button>
                    <div>
                      <Icon component={Calendar} />
                      {repeatValues.repeatedType}
                      {` - ${repeatValues?.days?.map((Item) =>
                        Item.slice(0, 3)
                      )} ,`}
                    </div>
                    <div>
                      <Icon component={Calendar} />
                      {intl.formatMessage({
                        id: `pickups.repeated_modal.form_labels.end_date`
                      })}
                      :
                      {repeatValues.endDate
                        ? dayjs(repeatValues.endDate).format('DD MMM,YYYY')
                        : 'N/A'}
                    </div>
                  </div>
                ))}

              <div className="br-pickups__pickup-settings__form__contacts-header">
                <span className="br-pickups__pickup-settings__form__contacts-header__title">
                  <Typography.Title level={3}>
                    {intl.formatMessage({
                      id: `pickups.create_edit_pickup.contact_title`
                    })}
                  </Typography.Title>
                </span>
                <div className="display-flex justify-space-between align-center">
                  <Tooltip
                    title={
                      !pickupId
                        ? intl.formatMessage({
                            id: `pickups.create_edit_pickup.form_labels_tooltips.select_contact`
                          })
                        : ''
                    }
                  >
                    <span className="br-pickups__pickup-settings__form__contacts-header__sub-title">
                      {intl.formatMessage({
                        id: `pickups.create_edit_pickup.contact_sub_title`
                      })}
                    </span>
                  </Tooltip>
                  {!isInternationalPickup && (
                    <BRButton
                      type="link-color"
                      onClick={handleCreateContactPersonClick}
                      prefixIcon={<PlusOutlined />}
                      label={intl.formatMessage({
                        id: `pickups.actions.add_person`
                      })}
                    />
                  )}
                </div>
              </div>

              <div className="br-pickups__pickup-settings__form__contacts">
                <div className="br-form-row">
                  <Form.Item
                    className="br-pickups__pickup-settings__form__form-input"
                    rules={[{ required: true }]}
                    name="name"
                    label={intl.formatMessage({
                      id: `contact_form_labels.name`
                    })}
                  >
                    <Select
                      className="br-pickups__pickup-settings__form__form-input"
                      placeholder={intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_placeholders.name`
                      })}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={defaultPickupDataLoading}
                      onChange={handleContactChange}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {pickupLocation?.contacts?.length &&
                        pickupLocation.contacts.map((contact) => (
                          <Select.Option
                            key={contact.name}
                            value={contact.name}
                          >
                            {contact.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="br-pickups__pickup-settings__form__form-input"
                    label={intl.formatMessage({
                      id: `contact_form_labels.phone`
                    })}
                    name="phone"
                  >
                    <Input
                      className="br-pickups__pickup-settings__form__form-input"
                      placeholder={intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_placeholders.phone`
                      })}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="br-form-row">
                  <Form.Item
                    className="br-pickups__pickup-settings__form__form-input"
                    label={
                      <>
                        {intl.formatMessage({
                          id: `contact_form_labels.secondary_phone`
                        })}
                        <span className="br-form-optional-label">
                          {intl.formatMessage({
                            id: 'form.optional_label'
                          })}
                        </span>
                      </>
                    }
                    name="secPhone"
                  >
                    <Input
                      className="br-pickups__pickup-settings__form__form-input"
                      placeholder={intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_placeholders.phone`
                      })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    className="br-pickups__pickup-settings__form__form-input"
                    label={
                      <>
                        {intl.formatMessage({
                          id: `contact_form_labels.email`
                        })}
                        <span className="br-form-optional-label">
                          {intl.formatMessage({
                            id: 'form.optional_label'
                          })}
                        </span>
                      </>
                    }
                    name="email"
                  >
                    <Input
                      className="br-pickups__pickup-settings__form__form-input"
                      placeholder={intl.formatMessage({
                        id: `pickups.create_edit_pickup.form_placeholders.email`
                      })}
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                className="br-pickups__pickup-settings__form__notes-header"
                label={intl.formatMessage({
                  id: `pickups.create_edit_pickup.form_labels.notes`
                })}
                name="notes"
              >
                <Input.TextArea
                  className="br-pickups__pickup-settings__form__notes-field"
                  placeholder={intl.formatMessage({
                    id: `pickups.create_edit_pickup.form_placeholders.notes`
                  })}
                  autoSize={{ minRows: 7, maxRows: 7 }}
                />
              </Form.Item>
              {!isInternationalPickup && (
                <Form.Item name="packageType">
                  <Checkbox
                    onClick={handleOnSelectLightBulky}
                    checked={isLightBulky}
                  >
                    {intl.formatMessage({
                      id: 'pickups.create_edit_pickup.form_placeholders.light'
                    })}
                  </Checkbox>
                  <Checkbox
                    onClick={handleOnSelectHeavyBulky}
                    checked={isHeavyBulky}
                  >
                    {intl.formatMessage({
                      id: 'pickups.create_edit_pickup.form_placeholders.heavy'
                    })}
                  </Checkbox>
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  className="br-pickups__pickup-settings__form__submit-button"
                  type="primary"
                  htmlType="submit"
                >
                  {intl.formatMessage({
                    id: pickupId
                      ? `common.save`
                      : `pickups.actions.create_button`
                  })}
                </Button>
              </Form.Item>
            </Form>
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(CreateEditPickupForm));
