import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';

import { GEOCODE_SEARCH_TYPE } from 'constants/common';
import { formatAddress } from 'constants/pickups';
import { getGeocode } from 'services/utils';

import BRGoogleMap from 'components/BRGoogleMap/BRGoogleMap';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './PickupMapModal.less';

const PickupMapModal = ({ pickupAddress, close, intl, ...props }) => {
  const [geoLocation, setGeoLocation] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (pickupAddress?.geoLocation) {
      setGeoLocation(pickupAddress.geoLocation);
    } else {
      getGeoLocationFromAddress();
    }
  }, []);

  const getGeoLocationFromAddress = async () => {
    try {
      const payload = {
        searchType: GEOCODE_SEARCH_TYPE.ADDRESS,
        searchString: encodeURI(
          `${formatAddress(pickupAddress)}, ${pickupAddress?.zone?.name}, ${
            pickupAddress?.district?.name
          },${pickupAddress.city.name}, ${pickupAddress.country.name}`
        )
      };

      const { data } = await getGeocode(payload);
      if (data?.results?.length && data.results[0]?.geometry?.location) {
        setGeoLocation(data.results[0].geometry.location);
      } else {
        setShowError(true);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      {...props}
      onCancel={close}
      footer={
        <BRButton
          onClick={close}
          label={intl.formatMessage({ id: 'common.cancel' })}
        />
      }
    >
      <div className="br-pickup-map-modal__container">
        {geoLocation ? (
          <BRGoogleMap markerPosition={geoLocation} />
        ) : showError ? (
          <span className="heading">
            {intl.formatMessage({ id: 'pickups.failed_to_find_location' })}
          </span>
        ) : (
          <LoadingWrapper />
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(PickupMapModal);
