import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

const ReceiveSealsHeader = ({
  intl,
  counts,
  isReceivingStarted,
  handleStartReceivingClick,
  handleCancelReceivingClick
}) => {
  return (
    <div className="br-receive-seals__header">
      {isReceivingStarted ? (
        <BRButton
          type="destructive-primary"
          label={intl.formatMessage({
            id: 'hubs.receive_from_hub.receive_seals.cancel_receiving'
          })}
          onClick={handleCancelReceivingClick}
        />
      ) : (
        <BRButton
          type="primary"
          label={intl.formatMessage({
            id: 'hubs.receive_from_hub.receive_seals.start_receiving'
          })}
          onClick={handleStartReceivingClick}
        />
      )}
    </div>
  );
};

export default injectIntl(ReceiveSealsHeader);
