import { LOCALE } from './intl-wrapper';

export const KEYBOARD_KEY_CODE = {
  ENTER: 13
};

export const GEOCODE_SEARCH_TYPE = {
  ADDRESS: 'address',
  LATLNG: 'latlng'
};

export const DEFAULT_PAGE_SIZE = 50