import { takeLatest } from 'redux-saga/effects';
import Sounds from 'common/Sounds';
import actionTypes from '../actions/ActionTypes';
/**
 * fetch all users
 * @returns {?}
 */
function* playSound({ action }) {
  switch (action) {
    case actionTypes.PLAY_WARNING:
      Sounds.warning();
      break;
    case actionTypes.PLAY_BEEP:
    default:
      Sounds.beep();
      break;
  }
}

export default function* usersWatcher() {
  yield takeLatest(actionTypes.PLAY_WARNING, playSound);
  yield takeLatest(actionTypes.PLAY_BEEP, playSound);
}
