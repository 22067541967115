import http from 'utils/http.js';

export const getResources = () => {
  return http.get('/auth/resources');
};

export const getAdminGroups = () => {
  return http.get('/auth/groups/obj');
};

export const getAdminGroup = (id) => {
  return http.get(`/auth/group/${id}/obj`);
};

export const updateAdminGroupAccess = (id, payload) => {
  return http.put(`auth/group/${id}`, payload);
};

export const createAdminGroup = (payload) => {
  return http.post('auth/group', payload);
};

export const getSystemPermissions = () => {
  return http.get('/auth/permissions');
};
