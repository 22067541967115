import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
  Input,
  InputNumber,
  Checkbox,
  Form,
  Button,
  Select
} from 'antd';

import { COUNTRIES_MAPPING } from 'constants/international-shipping';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/closeIconThin.svg';

import './AddCategoryModal.less';

const AddCategoryModal = ({ onSuccess, close, intl, ...restProps }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  const handleFormChange = (_, values) => {
    const { selectAll } = values;
    if (selectAll) {
      delete values.countryCode;
    }
    setFormValues({ ...values });
  };

  const handleOnSubmit = () => {
    let payload;
    const { selectAll } = formValues;
    delete formValues.selectAll;
    if (selectAll) {
      payload = {
        category: formValues
      };
    } else {
      const { countryCode } = formValues;
      delete formValues.countryCode;
      payload = {
        countryCode: countryCode,
        category: formValues
      };
    }
    onSuccess(payload);
    close();
  };
  return (
    <Modal
      className="br-add-category-modal"
      title={null}
      okText={intl.formatMessage({
        id: 'settings.international_shipping.modal.add_category'
      })}
      okButtonProps={{ htmlType: 'submit', form: 'addCategoryForm' }}
      onCancel={close}
      {...restProps}
    >
      <div className="br-add-category-modal__title">
        <span>
          {intl.formatMessage({
            id: 'settings.international_shipping.modal.title'
          })}
        </span>
        <Button icon={<CloseIcon />} onClick={close}></Button>
      </div>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        className="br-add-category-modal__form"
        name="addCategoryForm"
        onFinish={handleOnSubmit}
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.name'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'settings.international_shipping.modal.name_placeholder'
            })}
          ></Input>
        </Form.Item>
        <Form.Item
          name="fixedFees"
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.fees'
          })}
          rules={[{ required: true }]}
        >
          <InputNumber placeholder="0.00" />
        </Form.Item>
        <Form.Item
          name="feesPer100gm"
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.cost'
          })}
          rules={[{ required: true }]}
        >
          <InputNumber placeholder="0.00" />
        </Form.Item>
        <Form.Item
          name="deliverySLA"
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.delivery'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'settings.international_shipping.modal.delivery_placeholder'
            })}
          ></Input>
        </Form.Item>
        <Form.Item
          name="countryCode"
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.select'
          })}
          rules={[
            {
              required: !formValues?.selectAll,
              message: intl.formatMessage({
                id: 'settings.international_shipping.modal.select_error'
              })
            }
          ]}
        >
          <Select placeholder="Select" disabled={formValues?.selectAll}>
            {Object.values(COUNTRIES_MAPPING).map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'settings.international_shipping.modal.select_all'
          })}
          className="br-add-category-modal__form-select-all"
          name="selectAll"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddCategoryModal);
