import { Tag } from 'antd';

import {
  SERIAL_NUMBERS_TYPES,
  openPrintSerialsModal
} from 'constants/fulfillment-return-orders';
import { getProblematicJobLineSerialNumbers } from 'services/fulfillment-problematic-jobs';
import { fmt } from 'IntlWrapper/IntlWrapper';

import ProblematicJobsList from 'components/NewFulfillment/FulfillmentProblematicJobs/ProblematicJobsList/ProblematicJobsList';
import ProblematicJob from 'components/NewFulfillment/FulfillmentProblematicJobs/ProblematicJob/ProblematicJob';
import SerialNumbersDataTooltip from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/SerialNumbersDataTooltip/SerialNumbersDataTooltip';

import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

export const FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH =
  '/fulfillment/problematic-jobs';

export const FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH = `${FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH}/pj`;

export const FULFILLMENT_PROBLEMATIC_JOBS_ROUTES = [
  {
    path: FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH,
    name: 'Problematic List',
    component: ProblematicJobsList,
    exact: true
  },
  {
    path: `${FULFILLMENT_PROBLEMATIC_JOB_MAIN_PATH}/:id`,
    name: 'View Problematic Job',
    component: ProblematicJob,
    exact: true
  }
];

export const PROBLEMATIC_JOBS_STATE_IDS = {
  ON_HOLD: 1,
  PUTAWAY: 2
};

export const PROBLEMATIC_JOBS_STATE_NAMES = {
  [PROBLEMATIC_JOBS_STATE_IDS.ON_HOLD]: 'created',
  [PROBLEMATIC_JOBS_STATE_IDS.PUTAWAY]: 'completed'
};

export const PROBLEMATIC_JOBS_OPTIONS_IDS = {
  SERIAL: {
    value: 'SERIAL',
    apiKey: 'serialId',
    label: fmt({
      id: 'fulfillment_inbound.po_table.search_options.serial'
    })
  },
  SKU: {
    value: 'SKU',
    apiKey: 'sku',
    label: fmt({
      id: 'fulfillment_inbound.po_table.search_options.sku'
    })
  }
};

export const PROBLEMATIC_JOBS_SEARCH_OPTIONS = [
  PROBLEMATIC_JOBS_OPTIONS_IDS.SERIAL,
  PROBLEMATIC_JOBS_OPTIONS_IDS.SKU
];

export const PJ_KEY = 'PJ-';

export const goToProblematicJobsList = () =>
  window.open(FULFILLMENT_PROBLEMATIC_JOBS_MAIN_PATH, '_self');

export const renderProblematicJobTag = ({
  stateId,
  showProblematicTag = false
}) => {
  const stateName = PROBLEMATIC_JOBS_STATE_NAMES[stateId];
  if (!stateName) {
    return null;
  }

  const stateInsideIcon = {
    [PROBLEMATIC_JOBS_STATE_IDS.PUTAWAY]: <DoubleCheckIcon />
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_problematic_jobs.state_name.${stateName}`
        })}
        {stateInsideIcon[stateId]}
      </Tag>
      {showProblematicTag && (
        <Tag className="problematic">
          {fmt({
            id: 'fulfillment_problematic_jobs.title'
          })}
        </Tag>
      )}
    </div>
  );
};

export const getViewProblematicJobContentForRendering = ({
  problematicJob,
  isLoading,
  setIsLoading
}) => {
  const { state } = problematicJob;
  const { ON_HOLD, PUTAWAY } = PROBLEMATIC_JOBS_STATE_IDS;

  const handlePrintLabels = () =>
    openPrintSerialsModal({
      payload: {
        pjId: problematicJob.id,
        serialsType: SERIAL_NUMBERS_TYPES.ALL
      }
    });

  const sharedHeaderProps = {
    icon: CloseIcon,
    iconOnClick: goToProblematicJobsList,
    actions: [
      {
        label: fmt({
          id: 'fulfillment_returns.actions.print_labels'
        }),
        type: 'treitary-gray',
        prefixIcon: <PrinterIcon />,
        disabled: isLoading,
        onClick: handlePrintLabels
      }
    ]
  };

  const sharedProductsTableColumns = [
    {
      title: fmt({
        id: 'fulfillment_returns.return_orders_table.columns.qty'
      }),
      render: (line = {}) => {
        const { id: lineId, quantity } = line;
        return (
          <SerialNumbersDataTooltip
            line={{ ...line, serialsCount: quantity }}
            fetchSerials={() => getProblematicJobLineSerialNumbers(lineId)}
            hideScannedSerialsCount
          />
        );
      }
    }
  ];

  const PROBLEMATIC_JOB_VIEW = {
    [ON_HOLD]: {
      headerProps: sharedHeaderProps,
      productsTableColumns: sharedProductsTableColumns
    },
    [PUTAWAY]: {
      headerProps: sharedHeaderProps,
      productsTableColumns: sharedProductsTableColumns
    }
  };

  return PROBLEMATIC_JOB_VIEW[state?.stateId] || {};
};
