import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Select, Tooltip } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';

import {
  MAP_DELIVERY_TYPES,
  MAP_PICKUPS_TYPES,
  PENDING_ORDERS_CODES,
  COMPLETED_ORDERS_CODES,
  PENDING_PICKUPS,
  COMPLETED_PICKUPS,
  ORDER_TYPES,
  TASKS_TAB_COLUMNS_TYPE
} from 'constants/hubs';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import { ReactComponent as NewOrder } from 'assets/bosta-icons/New order.svg';

import './TasksTab.less';

const TasksTab = ({ intl, starData }) => {
  const [filterType, setFilterType] = useState('pending');
  const [orderTypeFilter, setOrderTypeFilter] = useState([]);
  const [tablesData, setTablesData] = useState({
    PENDING_PICKUPS: [],
    COMPLETED_PICKUPS: [],
    PENDING_ORDERS: [],
    COMPLETED_ORDERS: []
  });

  const handleTablesData = () => {
    setTablesData({
      PENDING_PICKUPS: starData.pickups.filter(
        (el) => el.state === PENDING_PICKUPS
      ),
      COMPLETED_PICKUPS: starData.pickups.filter((el) =>
        COMPLETED_PICKUPS.includes(el.state)
      ),
      PENDING_ORDERS: starData.deliveries.filter((el) =>
        PENDING_ORDERS_CODES.includes(el.state.code)
      ),
      COMPLETED_ORDERS: starData.deliveries.filter((el) =>
        COMPLETED_ORDERS_CODES.includes(el.state.code)
      )
    });
  };

  useEffect(() => {
    handleTablesData();
    setOrderTypeFilter([]);
  }, [starData]);

  const columns = (type) => [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.${
          type === TASKS_TAB_COLUMNS_TYPE.PICKUPS
            ? 'pickup_id'
            : 'tracking_number'
        }`
      }),
      dataIndex:
        type === TASKS_TAB_COLUMNS_TYPE.PICKUPS ? 'puid' : 'trackingNumber',
      render: (data, record) => {
        return type === TASKS_TAB_COLUMNS_TYPE.PICKUPS ? (
          <div className="display-flex">
            {data}
            {record?.starInitiatedAction && (
              <Tooltip
                placement="bottom"
                title={intl.formatMessage({
                  id: 'pickups.created_by_star'
                })}
              >
                <div className="br-pickups-created-by-star__icon">
                  <NewOrder />
                </div>
              </Tooltip>
            )}
          </div>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.last_update_date`
      }),
      dataIndex: 'updatedAt',
      render: (updatedAt) =>
        dayjs(new Date(updatedAt)).tz().format('DD MMMM, HH:mm A')
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.type`
      }),
      dataIndex: 'type',
      render: (value) => {
        return (
          (type === TASKS_TAB_COLUMNS_TYPE.PICKUPS
            ? MAP_PICKUPS_TYPES[value]
            : MAP_DELIVERY_TYPES[value]) || value
        );
      }
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.state`
      }),

      render: (item) => {
        return type === TASKS_TAB_COLUMNS_TYPE.PICKUPS
          ? item.state
          : deliveryStateFormatter.getStateName(item);
      }
    }
  ];

  const OnChangeOrderFilter = (value) => {
    if (value?.length) {
      const selectedTypes = value.map((type) => type.split(',')).flat();

      setOrderTypeFilter(value);
      filterType === 'pending'
        ? setTablesData({
            ...tablesData,
            PENDING_ORDERS: starData.deliveries.filter(
              (el) =>
                PENDING_ORDERS_CODES.includes(el.state.code) &&
                selectedTypes.includes(el.type)
            )
          })
        : setTablesData({
            ...tablesData,
            COMPLETED_ORDERS: starData.deliveries.filter(
              (el) =>
                COMPLETED_ORDERS_CODES.includes(el.state.code) &&
                selectedTypes.includes(el.type)
            )
          });
    } else {
      setOrderTypeFilter([]);
      handleTablesData();
    }
  };

  return (
    <div>
      <div className="display-flex">
        <Button
          className={classnames('br-tasks-tab__filter-btn', 'mr-3', {
            'br-tasks-tab__filter-btn__clicked': filterType === 'pending'
          })}
          onClick={() => setFilterType('pending')}
        >
          {intl.formatMessage(
            {
              id: `hubs.packages_debrief.tasks_tab.filters.pending`
            },
            {
              pendingLength:
                tablesData.PENDING_ORDERS.length +
                tablesData.PENDING_PICKUPS.length
            }
          )}
        </Button>
        <Button
          className={classnames('br-tasks-tab__filter-btn', {
            'br-tasks-tab__filter-btn__clicked': filterType === 'completed'
          })}
          onClick={() => setFilterType('completed')}
        >
          {intl.formatMessage(
            {
              id: `hubs.packages_debrief.tasks_tab.filters.completed`
            },
            {
              completedLength:
                tablesData.COMPLETED_ORDERS.length +
                tablesData.COMPLETED_PICKUPS.length
            }
          )}
        </Button>
      </div>

      <BRSearchableTable
        title={
          <div className="display-flex justify-space-between br-tasks-tab__order-table">
            {filterType === 'pending'
              ? intl.formatMessage(
                  {
                    id: `hubs.packages_debrief.tasks_tab.pending_orders`
                  },
                  { pendingOrdersLength: tablesData.PENDING_ORDERS.length }
                )
              : intl.formatMessage(
                  {
                    id: `hubs.packages_debrief.tasks_tab.completed_orders`
                  },
                  { completedOrdersLength: tablesData.COMPLETED_ORDERS.length }
                )}

            <Select
              className="br-tasks-tab__order-filter"
              placeholder={intl.formatMessage({
                id: `hubs.packages_debrief.select`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              value={orderTypeFilter}
              onChange={OnChangeOrderFilter}
              getPopupContainer={(trigger) => trigger.parentElement}
              mode="multiple"
              showArrow
              options={ORDER_TYPES}
            />
          </div>
        }
        listData={
          filterType === 'pending'
            ? tablesData.PENDING_ORDERS
            : tablesData.COMPLETED_ORDERS
        }
        columns={columns(TASKS_TAB_COLUMNS_TYPE.ORDERS)}
        onRowClick={() => {}}
        scrollY={193}
        tableTitleClassName="br-tasks-tab__table__title"
        withOutCheckBoxes
        hidePagination
      />
      <BRSearchableTable
        title={
          filterType === 'pending'
            ? intl.formatMessage(
                {
                  id: `hubs.packages_debrief.tasks_tab.pending_pickup`
                },
                { pendingPickupsLength: tablesData.PENDING_PICKUPS.length }
              )
            : intl.formatMessage(
                {
                  id: `hubs.packages_debrief.tasks_tab.completed_pickup`
                },
                { completedPickupsLength: tablesData.COMPLETED_PICKUPS.length }
              )
        }
        listData={
          filterType === 'pending'
            ? tablesData.PENDING_PICKUPS
            : tablesData.COMPLETED_PICKUPS
        }
        columns={columns(TASKS_TAB_COLUMNS_TYPE.PICKUPS)}
        onRowClick={() => {}}
        scrollY={193}
        tableTitleClassName="br-tasks-tab__table__title"
        withOutCheckBoxes
        hidePagination
      />
    </div>
  );
};

export default injectIntl(TasksTab);
