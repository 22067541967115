import React from 'react';

import './CardHeader.less';

const CardHeader = ({ rightTitle, leftTitle, cardNumber, optional }) => {
  return (
    <div className="br-new-order-card__header">
      <div className="br-new-order-card__title">
        <div className="br-new-order-card__title__number">
          <span>{cardNumber}</span>
        </div>
        {leftTitle}
      </div>
      <span className="br-new-order-card__title">{rightTitle}</span>
    </div>
  );
};

export default CardHeader;
