import { useRef } from 'react';
import { Input, Form } from 'antd';
import debounce from 'lodash/debounce';

import Sounds from 'common/Sounds';

import { notify } from 'components/Notify/Notify';

const { beep, warning } = Sounds;

const ScannerInput = ({ label, placeholder, onChange, disabled = false }) => {
  const formRef = useRef('');

  const scan = async (value) => {
    const errorMsg = await onChange?.(value);
    if (!errorMsg) {
      beep();
    } else {
      warning();
      notify(errorMsg);
    }

    formRef?.current?.setFieldsValue({ trackingNumber: '' });
  };

  const handleChange = debounce(({ target: { value } }) => {
    const trimmedValue = value?.trim();
    if (trimmedValue) {
      scan(trimmedValue);
    }
  }, 500);

  return (
    <div>
      <p className="br-scanner-input__label">{label}</p>
      <Form ref={formRef}>
        <Form.Item name="trackingNumber">
          <Input
            autoFocus
            className="br-scanner-input__input"
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScannerInput;
