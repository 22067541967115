import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { FormatStarLogEvent } from 'utils/stars';

import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import './DataLog.less';

const DataLog = ({ starDetails, intl }) => {
  const [formattedLogs, setFormattedLogs] = useState([]);

  const formatStarLogs = (logs) => {
    let formattedLogs = [];
    logs?.reverse().map((log) => {
      let newData = {};
      if (log?.actionsList?.excludedFrom && log?.actionsList?.excludedTo) {
        let excludedFromLiability = {
          Before: log?.actionsList?.excludedFrom?.After,
          After: log?.actionsList?.excludedTo.After
        };
        newData = FormatStarLogEvent({
          actionType: log?.actionType,
          actionName: 'excludedFromLiability',
          actionData: excludedFromLiability,
          actionsList: log?.actionsList,
          takenBy: log?.takenBy
        });
        formattedLogs.push(newData);
      }
      if (log?.actionsList && Object.keys(log?.actionsList)?.length) {
        Object.keys(log.actionsList).map((action) => {
          newData = FormatStarLogEvent({
            actionType: log?.actionType,
            actionName: action,
            actionData: log?.actionsList[action],
            actionsList: log?.actionsList,
            time: log?.time,
            takenBy: log?.takenBy
          });
          newData && formattedLogs.push(newData);
        });
      }
    });
    return formattedLogs;
  };

  const DataLogColumns = [
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.date_time`
      }),
      dataIndex: 'time'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.event_description`
      }),
      dataIndex: 'event'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.taken_by`
      }),
      dataIndex: 'takenBy'
    }
  ];

  useEffect(() => {
    if (starDetails?.logs?.length) {
      setFormattedLogs(formatStarLogs(starDetails.logs));
    }
  }, [starDetails]);
  return (
    <>
      <BRSearchableTable
        className="br-data-log-table"
        title={intl.formatMessage({
          id: `stars.star_details.data_log`
        })}
        columns={DataLogColumns}
        listData={formattedLogs}
        onRowClick={() => {}}
        withOutCheckBoxes
      />
    </>
  );
};

export default injectIntl(DataLog);
