import { useIntl } from 'react-intl';
import { useRef, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Checkbox } from 'antd';
import { useLocation } from 'react-router';

import {
  INTERNATIONAL_BILL_TYPE,
  INTERNATIONAL_DELIVERIES_E2E_COLUMNS,
  INTERNATIONAL_DELIVERY_STATUS_CODES,
  INTERNATIONAL_DELIVERY_UPDATE_TYPES,
  INVOICE_BILL_TYPES
} from 'constants/international-orders';
import {
  printInternationalOrderAwb,
  printInvoiceBill,
  updateInternationalOrders
} from 'services/international-orders';
import { showBill } from 'utils';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import InternationalDeliveriesTable from 'components/InternationalOrders/components/InternationalDeliveriesTable/InternationalDeliveriesTable';
import InternationalDeliveryDetails from 'components/InternationalOrders/components/InternationalDeliveries/components/InternationalDeliveryDetails/InternationalDeliveryDetails';
import InternationalDeliveriesE2ESearch from 'components/InternationalOrders/components/InternationalDeliveriesE2E/components/InternationalDeliveriesE2ESearch/InternationalDeliveriesE2ESearch';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as EyeIcon } from 'assets/bosta-icons/Eye.svg';
import { ReactComponent as CheckCircle } from 'assets/bosta-icons/check-circle.svg';

import './InternationalDeliveriesE2E.less';

const InternationalDeliveriesE2E = () => {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [count, setCount] = useState(0);
  const [clickedRowDetails, setClickedRowDetails] = useState({});
  const [toggleRowSelection, setToggleRowSelection] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const refreshTable = useRef('');

  const markAsReceivedAccess = isUserAuthorized(aclMatrix.DELIVERIES, [
    ACL_MATRIX.INTERNATIONAL_ORDER_MARK_AS_CLEARED_FROM_CUSTOMS
  ]);

  const handleShowDeliveryDetails = (record) => {
    setClickedRowDetails(record);
  };

  const handleOnDeliveryDetailsClose = () => {
    setClickedRowDetails({});
  };

  const handleFilterDeliveries = (filterValues) => {
    setSelectedFilters(filterValues);
    refreshTable.current({
      pageNumber: 1,
      filterValues: { ...filterValues }
    });
  };

  const handlePrintAwb = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers
      };
      const { data } = await printInternationalOrderAwb(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintInvoice = async ({ trackingNumbers } = {}) => {
    setIsLoading(true);
    try {
      const payload = {
        ...(trackingNumbers
          ? { trackingNumbers }
          : { trackingNumbers: selectedRows.join(',') }),
        type: INTERNATIONAL_BILL_TYPE.INVOICE_BILL,
        invoiceBillType: INVOICE_BILL_TYPES.PREFILLED
      };
      const { data } = await printInvoiceBill(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintVatReceipt = async ({ trackingNumbers } = {}) => {
    setIsLoading(true);
    try {
      const payload = {
        ...(trackingNumbers
          ? { trackingNumbers }
          : { trackingNumbers: selectedRows.join(',') }),
        type: INTERNATIONAL_BILL_TYPE.VAT_RECEIPT
      };
      const { data } = await printInvoiceBill(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const actionsList = (delivery) => [
    {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.table_actions.view'
      }),
      key: '1',
      icon: <EyeIcon />,
      onClick: () => handleShowDeliveryDetails(delivery)
    },
    {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.table_actions.print_awb'
      }),
      key: '2',
      icon: <Printer />,
      onClick: () => handlePrintAwb(delivery?.trackingNumber)
    },
    {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.table_actions.print_invoice'
      }),
      key: '3',
      icon: <Printer />,
      onClick: () =>
        handlePrintInvoice({ trackingNumbers: delivery.trackingNumber })
    },
    {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.table_actions.print_vat_receipt'
      }),
      key: '4',
      icon: <Printer />,
      onClick: () =>
        handlePrintVatReceipt({ trackingNumbers: delivery.trackingNumber })
    },
    {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.table_actions.received_at_destination'
      }),
      key: '5',
      icon: <CheckCircle />,
      disabled:
        isLoading ||
        !markAsReceivedAccess ||
        delivery.state.code !== INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT,
      onClick: () =>
        handleMarkAsReceivedAtDestinationClick([delivery.trackingNumber])
    }
  ];

  const handleMarkAsReceivedAtDestination = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const payload = {
        updateType: INTERNATIONAL_DELIVERY_UPDATE_TYPES.CLEARED_FROM_CUSTOMS,
        trackingNumbers
      };
      await updateInternationalOrders(payload);
      notify(
        intl.formatMessage(
          {
            id: 'international_orders.deliveries.mark_as_received_at_destination_modal.success_message'
          },
          {
            count: trackingNumbers.length
          }
        ),
        'success'
      );
      setIsLoading(false);
      refreshTable.current({
        pageNumber: 1
      });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleMarkAsReceivedAtDestinationClick = (trackingNumbers) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'international_orders.deliveries.mark_as_received_at_destination_modal.title'
      }),
      content: intl.formatMessage({
        id: `international_orders.deliveries.mark_as_received_at_destination_modal.${
          trackingNumbers.length > 1 ? 'content_bulk' : 'content'
        }`
      }),
      confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
      confirmButtonType: 'primary',
      modalAction: () => handleMarkAsReceivedAtDestination(trackingNumbers)
    });
  };

  const handleResetSelection = () => {
    setToggleRowSelection(!toggleRowSelection);
  };

  const handleBulkReceiveAtDestinationCountry = () => {
    handleMarkAsReceivedAtDestinationClick(selectedRows);
  };

  const renderTableTitle = () =>
    selectedRows?.length ? (
      <div className="br-international-table-selected-rows-container">
        <span className="br-international-table__title-count">
          <Checkbox indeterminate onChange={handleResetSelection} />
          {intl.formatMessage(
            { id: 'international_orders.pickups.selected' },
            {
              count: selectedRows?.length
            }
          )}
        </span>
        <BRButton
          label={intl.formatMessage(
            {
              id: 'international_orders.deliveries.table_actions.bulk_print'
            },
            {
              count: selectedRows.length
            }
          )}
          prefixIcon={<Printer />}
          onClick={() => handlePrintAwb(selectedRows.join(','))}
          disabled={isLoading || !selectedRows.length}
        />
        <BRButton
          label={intl.formatMessage(
            {
              id: 'international_orders.deliveries.table_actions.bulk_print_invoice'
            },
            {
              count: selectedRows.length
            }
          )}
          prefixIcon={<Printer />}
          onClick={handlePrintInvoice}
          disabled={isLoading || !selectedRows.length}
        />
        <BRButton
          label={intl.formatMessage(
            {
              id: 'international_orders.deliveries.table_actions.bulk_print_vat_receipt'
            },
            {
              count: selectedRows.length
            }
          )}
          prefixIcon={<Printer />}
          onClick={handlePrintVatReceipt}
          disabled={isLoading || !selectedRows.length}
        />
        <BRButton
          label={intl.formatMessage({
            id: 'international_orders.deliveries.table_actions.received_at_destination'
          })}
          prefixIcon={<CheckCircle />}
          onClick={handleBulkReceiveAtDestinationCountry}
          disabled={!markAsReceivedAccess}
        />
        <BRButton
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={handleResetSelection}
        />
      </div>
    ) : (
      intl.formatMessage(
        { id: 'international_orders.deliveries.table_title' },
        {
          count
        }
      )
    );

  return (
    <Container
      className="br-international-deliveries-e2e__container"
      header={
        <BRHeader
          title={intl.formatMessage({ id: 'sidebar.international_deliveries' })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <InternationalDeliveriesE2ESearch
              handleFilterDeliveries={handleFilterDeliveries}
              isLoading={isLoading}
            />
            <InternationalDeliveriesTable
              data={{
                setIsLoading,
                isLoading,
                columns: INTERNATIONAL_DELIVERIES_E2E_COLUMNS,
                setSelectedRows,
                selectedRows,
                setCount,
                actionsList,
                title: renderTableTitle(),
                toggleRowSelection,
                handleShowDeliveryDetails,
                refreshTable,
                selectedFilters,
                setSelectedFilters,
                isE2E: true
              }}
            />
            <InternationalDeliveryDetails
              isE2E
              open={!isEmpty(clickedRowDetails)}
              onClose={handleOnDeliveryDetailsClose}
              {...clickedRowDetails}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default InternationalDeliveriesE2E;
