import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Divider, InputNumber, Select } from 'antd';

import { COLLECTED } from 'constants/cashier';

import { ReactComponent as CodIcon } from 'assets/bosta-icons/cod-amount-icon.svg';
import { ReactComponent as PosIcon } from 'assets/bosta-icons/pos-amount-icon.svg';
import { ReactComponent as BtIcon } from 'assets/bosta-icons/bt-amount-icon.svg';
import { ReactComponent as TotalAmountIcon } from 'assets/bosta-icons/total-amount-icon.svg';

import './CashierApproveModal.less';

const CashierApproveModal = ({
  record,
  close,
  intl,
  onFinish,
  stars,
  ...rest
}) => {
  const [amounts, setAmounts] = useState({
    cod_amount: record?.cod_amount >= 0 ? record?.cod_amount : 0,
    pos_amount: record?.ccod_amount >= 0 ? record?.ccod_amount : 0,
    bt_amount: record?.bt_amount >= 0 ? record?.bt_amount : 0,
    total_amount:
      (record?.cod_amount >= 0 ? parseFloat(record?.cod_amount) : 0) +
      (record?.ccod_amount >= 0 ? parseFloat(record?.ccod_amount) : 0) +
      (record?.bt_amount >= 0 ? parseFloat(record?.bt_amount) : 0),
    posId: record?.posId
  });

  const amountPerType = [
    {
      icon: <CodIcon />,
      title: 'COD Amount',
      secondTitle: 'Actual COD Amount',
      value: record?.cod_amount,
      secondValue: amounts.cod_amount,
      key: 'cod_amount'
    },
    {
      icon: <PosIcon />,
      title: 'POS Amount',
      secondTitle: 'Actual POS Amount',
      value: record?.ccod_amount,
      secondValue: amounts.pos_amount,
      key: 'pos_amount',
      showPosSelect: true,
      posSelectTitle: 'POS ID'
    },
    {
      icon: <BtIcon />,
      title: 'BT Amount',
      secondTitle: 'Actual BT Amount',
      value: record?.bt_amount,
      secondValue: amounts.bt_amount,
      key: 'bt_amount'
    }
  ];

  const onChangeInput = (amount, key) => {
    setAmounts((prev) => {
      return {
        ...prev,
        [key]: amount,
        total_amount:
          key === 'posId'
            ? prev.total_amount
            : prev.total_amount - prev[key] + amount
      };
    });
  };

  const onConfirm = () => {
    onFinish({
      state: COLLECTED,
      star_id: record?.star?.id,
      receipt_number: record?.receiptNo,
      amount: amounts.total_amount,
      ccod_amount: amounts.pos_amount,
      bt_amount: amounts.bt_amount,
      pos_id: amounts.posId
    });
    close();
  };

  return (
    <Modal
      {...rest}
      title={
        <div className="br-cashier-approve-modal-title-container">
          <span className="heading">Collect Receipt</span>
          <span className="sidebar-child">
            confirm the amount you want to collect from star.
          </span>
        </div>
      }
      className="br-cashier-approve-modal-container"
      centered
      onCancel={close}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      onOk={onConfirm}
      width={null}
    >
      <div className="br-cashier-approve-modal-content-container">
        {amountPerType.map((type, id) => (
          <div
            className="br-cashier-approve-modal-content__card-container"
            key={id}
          >
            {type.icon}
            <div>
              {!!type.showPosSelect && (
                <div className="br-cashier-approve-modal-content__pos-id-container">
                  <span className="body-medium">{type.posSelectTitle}</span>
                  <Select
                    onChange={(value) => onChangeInput(value, 'posId')}
                    value={amounts.posId}
                  >
                    {stars?.map(
                      (star) =>
                        star?.starInfo?.posId && (
                          <Select.Option
                            value={star?.starInfo?.posId}
                            key={star._id}
                          >
                            <span className="br-cashier-recepit-drawer__dropdown-label">
                              {star?.starInfo?.posId}{' '}
                              <span>
                                {star?.profile?.firstName}{' '}
                                {star?.profile?.lastName}
                              </span>
                            </span>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </div>
              )}
              <div className="br-cashier-approve-modal-content__input-container">
                <div>
                  <span className="body-medium">{type.title}</span>
                  <InputNumber prefix="SAR" value={type.value} disabled />
                </div>
                <div>
                  <span className="body-medium">{type.secondTitle}</span>
                  <InputNumber
                    prefix="SAR"
                    value={type.secondValue}
                    onChange={(value) => onChangeInput(value, type.key)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <Divider />
        <div className="br-cashier-approve-modal-content__card-container total-amount-content">
          <TotalAmountIcon />
          <div>
            <div className="br-cashier-approve-modal-content__input-container">
              <div>
                <span className="body-medium">Total Amount</span>
                <InputNumber prefix="SAR" value={record?.amount} disabled />
              </div>
              <div>
                <span className="body-medium">Actual Total Amount</span>
                <InputNumber prefix="SAR" value={amounts.total_amount} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(CashierApproveModal);
