import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { getFulfillmentBusinessDetails } from 'services/fulfillment';
import {
  FULFILLMENT_BUSINESSES_MAIN_PATH,
  getFulfillmentBusinessTabsPath
} from 'constants/fulfillment';

import Container from 'components/Container/index';
import { notify } from 'components/Notify/Notify';
import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import BRTabs from 'components/BRTabs/BRTabs';
import BusinessInfo from 'components/NewFulfillment/Businesses/components/BusinessesList/components/BusinessTabs/components/BusinessInfo/BusinessInfo';

import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Chevron-left.svg';

import './BusinessTabs.less';

const BusinessTabs = ({
  intl,
  history,
  match: {
    params: { id }
  }
}) => {
  const [businessInfo, setBusinessInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const mainPath = getFulfillmentBusinessTabsPath(id);

  const goToBusinessList = () => {
    history.push(FULFILLMENT_BUSINESSES_MAIN_PATH);
  };

  const getBusinessDetails = async () => {
    setIsLoading(true);

    try {
      const {
        data: { business = {} }
      } = await getFulfillmentBusinessDetails(id);
      setBusinessInfo(business);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getBusinessDetails();
  }, []);

  const TABS = {
    BUSINESS_INFO: {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.business_tabs.business_info'
      }),
      component: BusinessInfo,
      componentProps: {
        businessInfo,
        isLoading
      },
      path: `${mainPath}/info`
    },
    PRODUCTS: {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.business_tabs.products'
      }),
      path: `${mainPath}/products`,
      disabled: true
    },
    INVENTORY: {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.business_tabs.inventory'
      }),
      path: `${mainPath}/inventory`,
      disabled: true
    },
    ANALYTICS: {
      label: intl.formatMessage({
        id: 'fulfillment_businesses.business_tabs.analytics'
      }),
      path: `${mainPath}/analytics`,
      disabled: true
    }
  };

  return (
    <Switch>
      <Route
        path={Object.keys(TABS).map((key) => TABS[key].path)}
        exact
        render={() => (
          <Container
            className="br-fulfillment-business"
            header={
              <POHeader
                icon={LeftArrowIcon}
                title={businessInfo.businessName}
                iconOnClick={goToBusinessList}
              />
            }
            content={<BRTabs tabs={TABS} />}
          />
        )}
      />
    </Switch>
  );
};

export default injectIntl(withRouter(BusinessTabs));
