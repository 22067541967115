import React from 'react';
import { Divider } from 'antd';
import { injectIntl } from 'react-intl';

import PackageSize from '../PackageSize/PackageSize';
import CollectionFees from '../CollectionFees/CollectionFees';
import Vat from '../Vat/Vat';
import ViewVatButton from '../ViewVatButton/ViewVatButton';

const PricingPlanVatMobile = ({
  type,
  isWide,
  intl,
  onChange,
  pricingData
}) => {
  return (
    <>
      {isWide ? (
        <div className="br-pricing-plan-mobile__content">
          <ViewVatButton onChange={onChange} />
          <Vat />
        </div>
      ) : (
        <>
          <ViewVatButton onChange={onChange} />
          <Divider />
          <Vat />
        </>
      )}

      <Divider type={type} />
      <CollectionFees />
      <Divider type={type} />
      <PackageSize pricingData={pricingData} />
    </>
  );
};

export default injectIntl(PricingPlanVatMobile);
