import { useIntl } from 'react-intl';
import get from 'lodash/get';
import classnames from 'classnames';

import {
  ADDRESS_FIELD_KEY,
  CUSTOMS_VAT_VALUE,
  CUSTOM_VAT_TYPE,
  INTERNATIONAL_BILL_TYPE,
  INTERNATIONAL_DELIVERY_DETAILS_FIELDS,
  INTERNATIONAL_DELIVERY_STATUS_CODES,
  INVOICE_BILL_TYPES,
  RETURN_OPTION_KEY,
  VAT_TYPE_OPTIONS,
  WEIGHT_FIELD_KEY
} from 'constants/international-orders';
import {
  printInternationalOrderAwb,
  printInvoiceBill,
  updateInternationalOrder
} from 'services/international-orders';
import { showBill } from 'utils';
import { openModal } from 'utils/modal';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import {
  currentUserCountry,
  getCurrency
} from 'common/countries/countries-mapping';

import EditWeightModal from '../EditWeightModal/EditWeightModal';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import BREditInputField from 'components/BREditInputField/BREditInputField';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import EditAddressModal from '../EditAddressModal.js/EditAddressModal';
import EditReturnLocationModal from '../EditReturnLocationModal/EditReturnLocationModal';

import { ReactComponent as PrintIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as Edit } from 'assets/bosta-icons/Edit.svg';
import { ReactComponent as BottomRightArrow } from 'assets/bosta-icons/BottomRightArrow.svg';

import './DeliveryDetails.less';

const DeliveryDetails = ({
  deliveryDetails = {},
  setDeliveryDetails,
  getDeliveryDetails,
  setIsLoading,
  isE2E
}) => {
  const intl = useIntl();

  const pickupCountryCode = deliveryDetails?.pickupAddress?.country?.code;
  const userCountryCode = currentUserCountry.codeName;
  const isBusinessNameClickable =
    deliveryDetails?.sender?._id && pickupCountryCode === userCountryCode;

  const handlePrintAwb = async () => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers: deliveryDetails.trackingNumber
      };
      const { data } = await printInternationalOrderAwb(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintInvoice = async () => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers: deliveryDetails.trackingNumber,
        type: INTERNATIONAL_BILL_TYPE.INVOICE_BILL,
        invoiceBillType: INVOICE_BILL_TYPES.PREFILLED
      };
      const { data } = await printInvoiceBill(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintVatReceipt = async () => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers: deliveryDetails.trackingNumber,
        type: INTERNATIONAL_BILL_TYPE.VAT_RECEIPT
      };
      const { data } = await printInvoiceBill(payload);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleUpdateCustomsVatValue = async ({ customsVatValue }) => {
    try {
      await updateInternationalOrder({
        orderId: deliveryDetails._id,
        payload: { customVAT: { value: customsVatValue } }
      });

      setDeliveryDetails((prev) => ({
        ...prev,
        customVAT: {
          ...prev.customVAT,
          value: customsVatValue
        },
        customsVatValueWithCurrency: getCurrency(
          customsVatValue,
          deliveryDetails.dropOffAddress.country.code
        ).localized
      }));
    } catch (error) {
      notify(error.message);
    }
  };

  const handleEditCustomsVat = () => {
    openModal(BREditInputField, {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.fields.customs_vat'
      }),
      title: intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.edit_customs_vat'
      }),
      prefix: intl.formatMessage({
        id: 'common.SAR_label'
      }),
      name: 'customsVatValue',
      initialValues: {
        customsVatValue: deliveryDetails.customVAT?.value || 0
      },
      handleUpdateInputValue: handleUpdateCustomsVatValue
    });
  };

  const handleEdit = (dataIndex) => {
    switch (dataIndex) {
      case WEIGHT_FIELD_KEY:
        handleEditWeight();
        break;
      case CUSTOMS_VAT_VALUE:
        handleEditCustomsVat();
        break;
      case CUSTOM_VAT_TYPE:
        handleEditCustomsVatType();
        break;
      case ADDRESS_FIELD_KEY:
        handleEditCustomerAddress();
        break;
      case RETURN_OPTION_KEY:
        handleEditReturnLocation();
        break;
      default:
        break;
    }
  };

  const handleEditReturnLocation = () => {
    openModal(EditReturnLocationModal, {
      deliveryDetails,
      onConfirm: (payload) => handleUpdateDelivery({ payload })
    });
  };

  const handleEditCustomerAddress = () => {
    openModal(EditAddressModal, {
      deliveryDetails,
      onConfirm: (payload) => handleUpdateDelivery({ payload })
    });
  };

  const handleUpdateDelivery = async ({ payload }) => {
    try {
      await updateInternationalOrder({
        orderId: deliveryDetails._id,
        payload
      });
      getDeliveryDetails();
      notify(
        intl.formatMessage({
          id: 'international_orders.deliveries.delivery_details.updated_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleEditCustomsVatType = () => {
    openModal(BRDropdownModal, {
      dropdownData: VAT_TYPE_OPTIONS,
      onConfirm: (value) =>
        handleUpdateDelivery({
          payload: {
            customVAT: {
              ...deliveryDetails.customVAT,
              type: value
            }
          }
        })
    });
  };

  const handleEditWeight = () => {
    openModal(EditWeightModal, {
      weight: deliveryDetails.specs?.weight,
      handleUpdateWeight
    });
  };

  const handleUpdateWeight = async (weight) => {
    try {
      await updateInternationalOrder({
        orderId: deliveryDetails._id,
        payload: { specs: { weight } }
      });

      setDeliveryDetails((prev) => ({
        ...prev,
        specs: {
          ...prev.specs,
          weight
        },
        weight: intl.formatMessage(
          { id: 'international_orders.deliveries.delivery_details.weight' },
          {
            weight
          }
        )
      }));
    } catch (error) {
      notify(error.message);
    }
  };

  const handleGoToBusinessDetails = () => {
    window.open(`/businesses/${deliveryDetails.sender._id}/details`, '_blank');
  };

  const renderBusinessName =
    isE2E && isBusinessNameClickable ? (
      <div
        className="br-international-delivery-details__business_name"
        onClick={handleGoToBusinessDetails}
      >
        <span className="display-sm">{deliveryDetails.sender?.name}</span>
        <BottomRightArrow />
      </div>
    ) : (
      <span className="display-sm">{deliveryDetails.sender?.name}</span>
    );

  return (
    <div className="br-international-delivery-details__container">
      <div className="br-international-delivery-details__header">
        <div>
          <span className="br-international-delivery-details__subtitle">
            {intl.formatMessage({
              id: 'deliveries.search_filters.business'
            })}
          </span>
          <span className="display-sm">{renderBusinessName}</span>
        </div>
        <div className="br-international-delivery-details__header__actions">
          {isE2E && (
            <>
              <BRButton
                prefixIcon={<PrintIcon />}
                label={intl.formatMessage({
                  id: 'international_orders.deliveries.table_actions.print_invoice'
                })}
                onClick={handlePrintInvoice}
              />
              <BRButton
                prefixIcon={<PrintIcon />}
                label={intl.formatMessage({
                  id: 'international_orders.deliveries.table_actions.print_vat_receipt'
                })}
                onClick={handlePrintVatReceipt}
              />
            </>
          )}
          <BRButton
            prefixIcon={<PrintIcon />}
            label={intl.formatMessage({
              id: 'deliveries.delivery_details.header.print_awb'
            })}
            onClick={handlePrintAwb}
          />
        </div>
      </div>

      <div className="br-international-delivery-details__content">
        {INTERNATIONAL_DELIVERY_DETAILS_FIELDS({ isE2E }).map(
          ({ access = true, ...section }, index) =>
            access && (
              <div
                className={classnames(
                  'br-international-delivery-details__section',
                  section?.className
                )}
                key={index}
              >
                <div className="br-international-delivery-details__section-title body-medium">
                  {!!section?.icon && section.icon}
                  {section.title}
                </div>
                <div className="br-international-delivery-details__section-fields">
                  {section.fields.map(
                    ({
                      title,
                      dataIndex,
                      fullWidth,
                      isEditable,
                      access = true
                    }) =>
                      access && (
                        <div
                          className={classnames(
                            'br-international-delivery-details__field',
                            { 'full-width': fullWidth }
                          )}
                        >
                          <div>
                            <div className="br-international-delivery-details__subtitle">
                              {title}
                            </div>
                            <div className="br-international-delivery-details__field-value">
                              {get(
                                deliveryDetails,
                                dataIndex,
                                intl.formatMessage({ id: 'common.empty_field' })
                              )}
                            </div>
                          </div>
                          {isEditable &&
                            [
                              INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED,
                              INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS,
                              INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE,
                              INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT
                            ].includes(deliveryDetails.state?.code) &&
                            isUserAuthorized(aclMatrix.DELIVERIES, [
                              ACL_MATRIX.INTERNATIONAL_ORDER_UPDATE
                            ]) && (
                              <BRButton
                                prefixIcon={<Edit />}
                                onClick={() => handleEdit(dataIndex)}
                                label={intl.formatMessage({
                                  id: 'common.edit'
                                })}
                              />
                            )}
                        </div>
                      )
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DeliveryDetails;
