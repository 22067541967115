import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router';

import Container from 'components/Container/index';
import BRTabs from 'components/BRTabs/BRTabs';
import DraftOrders from './components/DraftOrders/DraftOrders';
import ShippingOrders from './components/ShippingOrders/ShippingOrders';

import './PackagingOrdersContainer.less';

const PackagingOrdersContainer = ({ match }) => {
  const { path } = match;
  const intl = useIntl();
  const TABS = {
    tracking: {
      label: intl.formatMessage({
        id: 'packaging.orders_tab.tabs.shipping_orders'
      }),
      component: ShippingOrders,
      path: '/packaging/orders/shipping',
      getRoute: (url) => `${url}`,
      withProps: true
    },
    draft: {
      label: intl.formatMessage({
        id: 'packaging.orders_tab.tabs.sales_orders'
      }),
      component: DraftOrders,
      path: '/packaging/orders/draft',
      withProps: true
    },
    cancelled: {
      label: intl.formatMessage({
        id: 'packaging.orders_tab.tabs.cancelled_orders'
      }),
      component: DraftOrders,
      path: '/packaging/orders/cancelled',
      withProps: true,
      componentProps: {
        isCancelledFlyersTab: true
      }
    }
  };
  return (
    <Container
      className="br-packaging-orders-container"
      content={
        <Switch>
          <Route
            path={[
              '/packaging/orders/shipping',
              '/packaging/orders/draft',
              '/packaging/orders/cancelled'
            ]}
            exact
            render={() => <BRTabs tabs={TABS} />}
          />
          <Redirect from={path} to={`${path}/shipping`} />
        </Switch>
      }
    />
  );
};

export default withRouter(PackagingOrdersContainer);
