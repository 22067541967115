import { fetchBusinesses } from 'services/business';

import BRFetchSelect from 'components/BRFetchSelect/BRFetchSelect';

const BusinessSelector = ({ ...props }) => {
  return (
    <BRFetchSelect
      showArrow
      showSearch
      fieldNames={{ label: 'name', value: '_id' }}
      fetchAPI={fetchBusinesses}
      searchKey="search"
      {...props}
    />
  );
};

export default BusinessSelector;
