import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Highlighter from 'react-highlight-words';
import { Select, Table, Input, Button, Tooltip, Tag } from 'antd';
import useMedia from 'use-media';
import classnames from 'classnames';

import { getPricingInfo } from 'services/pricing';
import { getPickupLocations } from 'services/pickup-locations';
import { PICKUP_LOCATION_OPTIONS } from 'constants/pricing';
import { BUSINESS_TYPES } from 'constants/Businesses';
import { formatPricingTableData, typeObject } from 'utils/pricing';
import { getBusiness } from 'services/business';
import { getBusinessPickupLocation } from 'actions/BusniessesAction';

import { notify } from 'components/Notify/Notify'
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PricingPlanVatMobile from './PricingPlanVatMobile/PricingPlanVatMobile';
import PricingTableMobile from './PricingTableMobile/PricingTableMobile';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/InformationButtonWithoutFilter.svg';
import { ReactComponent as SearchIcon } from 'assets/imgRevamp/PricingPlanSearchIcon.svg';
import { ReactComponent as SignAndReturnTooltip } from 'assets/imgRevamp/SignAndReturnTooltip.svg';

import './PricingPlan.less';

const PricingPlan = ({ intl, businessType, location, history }) => {
  const [pickupLocationDesc, setPickupLocationDesc] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [pickupLocationDefaultValue, setPickupLocationDefaultValue] =
    useState('');
  const [dropOffSector, setDropOffSector] = useState(null);
  const [isVat, setIsVat] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBusinessId, setCurrentBusinessId] = useState(null);
  const inputRef = useRef();

  const isWide = useMedia({ minWidth: '601px', maxWidth: '1125px' });
  const showTableMobile = useMedia({ maxWidth: '750px' });

  useEffect(() => {
    if (currentBusinessId) {
      getUserPricingDetails();
    }
  }, [currentBusinessId, dropOffSector, isVat]);

  useEffect(() => {
    const businessId = new URLSearchParams(location.search).get('businessId');
    setCurrentBusinessId(businessId);
  }, [location]);

  const getUserPricingDetails = async () => {
    setIsLoading(true);
    try {
      const business = await getBusiness(currentBusinessId);
      const pricingTierId = business?.pricingTier?._id;
      let businessSector;
      if (!dropOffSector) {
        try {
          const businessPickupLocations = await getBusinessPickupLocation(
            currentBusinessId
          );
          businessPickupLocations.data.forEach((location) => {
            if (location.isDefault)
              businessSector = location.address.city.sector
                ? location.address.city.sector - 1
                : 0;
          });
        } catch {
          businessSector = 0;
        }
      } else {
        businessSector = dropOffSector - 1;
      }
      const result = await getPricingInfo(
        pricingTierId,
        businessSector + 1,
        isVat
      );
      result.data.map((x) => {
        let deliverNormalSize = null;
        let exchangeNormalSize = null;
        deliverNormalSize = x.tierServiceTypes
          .find(({ typeName }) => typeName === 'SEND')
          .tierSizes.find(({ sizeName }) => sizeName === 'Normal');
        exchangeNormalSize = x.tierServiceTypes
          .find(({ typeName }) => typeName === 'EXCHANGE')
          .tierSizes.find(({ sizeName }) => sizeName === 'Normal');
        x.tierServiceTypes
          .sort((a, b) => typeObject[a.typeName] - typeObject[b.typeName])
          .map((tier) => {
            businessType === BUSINESS_TYPES.B2B &&
              tier.tierSizes.push(
                {
                  ...deliverNormalSize,
                  sizeName: 'Sign and Return',
                  sizeAlias: 'Sign and Return'
                },
                {
                  ...exchangeNormalSize,
                  sizeName: 'Sign and Return',
                  sizeAlias: 'Sign and Return',
                  otherDay: true
                }
              );

            tier.tierSizes.sort((a, b) => a.sizeWidth - b.sizeWidth);
          });
      });
      setPricingData(result.data);
      setPickupLocationDesc(PICKUP_LOCATION_OPTIONS[businessSector].value);
      setPickupLocationDefaultValue(
        PICKUP_LOCATION_OPTIONS[businessSector].label
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div className="br-pricing__drop-off-search">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() =>
              handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
            }
            ref={inputRef}
          />

          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
            }
            size="small"
          >
            {intl.formatMessage({
              id: 'settings.pricing_plan.search'
            })}
          </Button>
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => inputRef.current.focus(), 100);
        }
      },
      filterIcon: (isFilterd) => (
        <>
          <SearchIcon className="br-pricing__search-icon" />
          {searchText && (
            <Tag
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {searchText}{' '}
              <span
                className="tag-close-button"
                onClick={() => handleSearch(null)}
              >
                x
              </span>
            </Tag>
          )}
        </>
      ),
      onFilter: (value, record) =>
        record.dropOffSectorName
          ? PICKUP_LOCATION_OPTIONS[record.dropOffSectorName - 1].label
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : '',
      render: (text, data) => (
        <div className="br-pricing-plan-highlighter">
          <Highlighter
            className={data.className || ''}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
          <Highlighter
            searchWords={[searchText]}
            autoEscape
            textToHighlight={data.value ? data.value : ''}
          />
          {(text.toString() === 'Sign & Return' ||
            text.toString() === 'توقيع و إرجاع') &&
            data?.otherDay && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'settings.pricing_plan.table_columns.tool_tips.sign_and_return'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
                className="br-pricing-plan__sign_and_return_tooltip"
              >
                <SignAndReturnTooltip />
              </Tooltip>
            )}
        </div>
      )
    };
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'settings.pricing_plan.table_columns.dropoff_location'
      }),
      dataIndex: 'name',
      key: 'name',
      className: 'br-pricing-plan__city-table-header',
      filteredValue: [searchText],
      ...getColumnSearchProps('name')
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.deliver'
          })}{' '}
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pricing_plan.table_columns.tool_tips.deliver'
            })}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
          >
            <TooltipsIcon />
          </Tooltip>
        </div>
      ),
      dataIndex: 'deliver',
      key: 'deliver',
      width: '10%',
      render: (text) => text
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.exchange'
          })}{' '}
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pricing_plan.table_columns.tool_tips.exchange'
            })}
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
          >
            <TooltipsIcon />
          </Tooltip>
        </div>
      ),
      dataIndex: 'exchange',
      key: 'exchange',
      render: (text) => text,
      width: '10%'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.return'
          })}{' '}
          <Tooltip
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
            title={intl.formatMessage({
              id: 'settings.pricing_plan.table_columns.tool_tips.return'
            })}
          >
            <TooltipsIcon />
          </Tooltip>
        </div>
      ),
      dataIndex: 'return',
      key: 'return',
      render: (text) => text,
      width: '10%'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.cash_collection'
          })}{' '}
          <Tooltip
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
            title={intl.formatMessage({
              id: 'settings.pricing_plan.table_columns.tool_tips.cash_collection'
            })}
          >
            <TooltipsIcon />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cash_collection',
      key: 'cash_collection',
      render: (text) => text,
      width: '10%'
    },
    {
      title: (
        <div className="br-pricing-plan__table-header-title">
          {intl.formatMessage({
            id: 'settings.pricing_plan.table_columns.rto'
          })}{' '}
          <Tooltip
            getPopupContainer={(trigger) =>
              trigger.parentElement.parentElement.parentElement.parentElement
            }
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
            overlayClassName="br-pricing-plan__tool-tip"
            title={intl.formatMessage({
              id: 'settings.pricing_plan.table_columns.tool_tips.rto'
            })}
          >
            <TooltipsIcon />
          </Tooltip>
        </div>
      ),
      dataIndex: 'rto',
      key: 'rto',
      render: (text) => text,
      width: '10%'
    }
  ];

  const handleSearch = (selectedKeys, confirm) => {
    if (selectedKeys === null || !selectedKeys[0]) {
      setSearchText('');
      if (confirm) {
        confirm();
      }
    } else {
      confirm();
      setSearchText(selectedKeys[0]);
    }
  };

  const onPickupChange = (id) => {
    setPickupLocationDesc(PICKUP_LOCATION_OPTIONS[id - 1].value);
    setDropOffSector(id);
    setSearchValue('');
  };

  const onChange = (checked) => {
    setIsVat(checked);
  };

  const renderPriceCrrencySection = () => (
    <div className="br-pricing-page__currency-container">
      <span className="br-pricing-page__currency">
        {intl.formatMessage(
          {
            id: `settings.pricing_plan.currency_text`
          },
          {
            currency: (
              <span>
                {intl.formatMessage({
                  id: `settings.pricing_plan.currency`
                })}
              </span>
            )
          }
        )}
      </span>
    </div>
  );

  const areaOption = (area) => {
    return (
      <Select.Option key={area.key} value={area.label} label={area.label}>
        <div className="br-pricing-plane__select-options">
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={area.zone}
            className="br-area__first-highlighter"
            highlightClassName="br-area__highlighted-text"
          />
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={area.value}
            className="br-area__second-highlighter"
            highlightClassName="br-area__highlighted-text"
          />
        </div>
      </Select.Option>
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title="Deliveries"
            handleGoBackUrl={() => history.push(`/deliveries`)}
            showBackArrow
          />
        }
        content={
          <div>
            <BRContentHeader
              title={intl.formatMessage({
                id: 'settings.pricing_plan.title'
              })}
              subtitle={intl.formatMessage({
                id: 'settings.pricing_plan.sub_title'
              })}
              isInternalComponent
              className="br-pricing-plan__header"
            />

            <div className="br-pricing-plan-sider">
              <>
                <PricingPlanVatMobile
                  onChange={onChange}
                  isWide={isWide}
                  type={isWide ? 'vertical' : ''}
                  pricingData={pricingData}
                />

                {!isWide && !showTableMobile && renderPriceCrrencySection()}
              </>
            </div>

            {(isWide || showTableMobile) && renderPriceCrrencySection()}
            <p className="br-pricing__pickup-location-title">
              {intl.formatMessage({
                id: 'settings.pricing_plan.pickup_location'
              })}
            </p>
            <Select
              showSearch
              style={{ minWidth: 230, maxWidth: 260, width: '100%' }}
              listHeight={200}
              defaultValue={pickupLocationDefaultValue}
              dropdownClassName="br-pricing-plan__cities-dropdown"
              virtual={false}
              onChange={(value, option) => onPickupChange(option.key)}
              onSearch={(value) => setSearchValue(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {PICKUP_LOCATION_OPTIONS.map((location) => areaOption(location))}
            </Select>

            {pickupLocationDesc && (
              <p className="br-pricing-plan__pickup-location-desc">
                {pickupLocationDesc}
              </p>
            )}

            <div
              className={classnames('br-pricing-plan__main-content', {
                'br-pricing-plan__main-content-table': searchText
              })}
            >
              {showTableMobile ? (
                <PricingTableMobile
                  data={formatPricingTableData(pricingData)}
                />
              ) : (
                <Table
                  columns={columns}
                  dataSource={formatPricingTableData(pricingData)}
                  className="br-pricing-plan__table"
                  pagination={false}
                  size="small"
                  scroll={isWide ? { x: 0 } : false}
                />
              )}
            </div>
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(PricingPlan));
