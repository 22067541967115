import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Radio, Form, Input } from 'antd';

import {
  DEFAULT_DESTINATION_HUB_ID,
  HUB_RECEIVE_SCANNING_TYPE,
  SEAL_SCAN_TYPE
} from 'constants/hubs';
import { getCurrentUserHubInfo } from 'utils/hubs';
import Sounds from 'common/Sounds';
import { searchDeliveries } from 'services/deliveries';
import { receivePackagesAtHub } from 'services/hubs';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import BRSwappingTables from 'components/BRSwappingTables/BRSwappingTables';

import { ReactComponent as Delete } from 'assets/bosta-icons/Delete.svg';

import './ReceiveFromBin.less';

const ReceiveFromBin = ({ intl }) => {
  const [deliveries, setDeliveries] = useState([]);
  const [scannedDeliveries, setScannedDeliveries] = useState([]);
  const [scanningType, setScanningType] = useState(SEAL_SCAN_TYPE.BINS);
  const [bin, setBin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const formRef = useRef('');
  const trackingNumberInputRef = useRef('');
  const binInputRef = useRef('');

  const { beep, warning } = Sounds;

  const currentUserHubInfo = getCurrentUserHubInfo();

  const clearTrackingNumberField = () => {
    formRef.current.setFieldsValue({ trackingNumber: '' });
  };

  const scanShelf = debounce(async (binName) => {
    if (binName) {
      setBin(binName);
      beep();
      trackingNumberInputRef.current.focus();
      getBinDeliveries({ shelfNames: binName });
    }
  }, 500);

  const getBinDeliveries = async ({ shelfNames }) => {
    setIsLoading(true);
    try {
      const payload = {
        hubs: [DEFAULT_DESTINATION_HUB_ID],
        assignedHub: currentUserHubInfo?._id,
        shelfNames,
        stateCodes: ['24']
      };
      const { deliveries } = await searchDeliveries(payload);
      setDeliveries(deliveries);
      setCount(deliveries?.length || 0);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const scanDelivery = debounce(async (trackingNumber) => {
    if (trackingNumber.length >= 6) {
      const alreadyExist = scannedDeliveries.findIndex(
        (delivery) => delivery.trackingNumber === trackingNumber
      );

      if (alreadyExist > -1) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.already_scanned'
            },
            { trackingNumber }
          ),
          'error'
        );
        warning();
        clearTrackingNumberField();
        return;
      }
      setIsLoading(true);

      const delivery = deliveries.find(
        (delivery) => delivery.trackingNumber === trackingNumber
      );

      if (delivery) {
        setScannedDeliveries((prevState) => [...prevState, delivery]);
        setDeliveries((prevState) =>
          prevState.filter(
            (delivery) => delivery.trackingNumber !== trackingNumber
          )
        );
        clearTrackingNumberField();
        beep();
      } else {
        notify(
          intl.formatMessage({
            id: 'hubs.receive.receive_bins.invalid_tracking_number'
          })
        );
        clearTrackingNumberField();
        warning();
      }

      setIsLoading(false);
    }
  }, 500);

  const handleEndReceiving = async () => {
    if (deliveries?.length) {
      warning();
      return notify(
        intl.formatMessage({
          id: 'hubs.receive.receive_bins.end_receiving_error'
        })
      );
    }
    setIsLoading(true);
    try {
      const payload = {
        updateType: 'receiveMany',
        deliveries: scannedDeliveries.map((delivery) => ({
          _id: delivery._id
        }))
      };
      await receivePackagesAtHub(payload);
      setDeliveries([]);
      setScannedDeliveries([]);
      setBin(null);
      setCount(0);
      formRef.current.resetFields();
      notify(
        intl.formatMessage({
          id: 'hubs.receive.receive_bins.packages_received'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const deliveriesColumns = [
    {
      dataIndex: 'trackingNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.tracking_number'
      })
    },
    {
      dataIndex: 'type',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.type'
      })
    }
  ];

  const scannedDeliveriesColumns = [
    ...deliveriesColumns,
    {
      dataIndex: 'actions',
      title: '',
      align: 'center'
    }
  ];

  const formatScannedDeliveriesData = () =>
    scannedDeliveries.map((delivery) => ({
      _id: delivery._id,
      trackingNumber: delivery.trackingNumber,
      type: delivery.type,
      actions: (
        <Delete
          className="br-hub-transfer-delete__action"
          onClick={() => handleScannedDeliveryDelete(delivery.trackingNumber)}
        />
      )
    }));

  const handleScannedDeliveryDelete = (trackingNumber) => {
    const delivery = scannedDeliveries.find(
      (delivery) => delivery.trackingNumber === trackingNumber
    );

    setDeliveries((prevState) => [...prevState, delivery]);
    setScannedDeliveries((prevState) =>
      prevState.filter((delivery) => delivery.trackingNumber !== trackingNumber)
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-receive-from-bin__container">
        <div className="br-receive-from-bin__counter body-medium">
          {intl.formatMessage(
            { id: 'hubs.receive.receive_bins.deliveries_count' },
            { count: <span>{count}</span> }
          )}
        </div>
        <div className="br-receive-from-bin__scanning">
          <div className="br-receive-from-bin-scanning__type">
            <Form.Item
              label={intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_type'
              })}
            >
              <Radio.Group
                value={scanningType}
                onChange={(e) => setScanningType(e.target.value)}
              >
                {HUB_RECEIVE_SCANNING_TYPE.map(
                  ({ label, value, disabled = false }) => (
                    <Radio.Button value={value} disabled={disabled}>
                      {label}
                    </Radio.Button>
                  )
                )}
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="br-hub-receive-from-bin__scanning display-flex">
            <Form ref={formRef}>
              <Form.Item
                name="bin"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.transfer_seals.scanning.scan_bin'
                })}
              >
                <Input
                  ref={binInputRef}
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.scanning.scan_bin'
                  })}
                  onChange={(e) => scanShelf(e.target.value?.trim())}
                />
              </Form.Item>
              <span>
                {intl.formatMessage({
                  id: 'hubs.hub_transfer.transfer_seals.scanning.and'
                })}
              </span>
              <Form.Item
                name="trackingNumber"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.transfer_seals.scanning.tracking_number'
                })}
              >
                <Input
                  ref={trackingNumberInputRef}
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.scanning.tracking_number_placeholder'
                  })}
                  onChange={(e) => scanDelivery(e.target.value?.trim())}
                  disabled={!bin}
                />
              </Form.Item>
            </Form>
            <div className="br-hub-receive-from-bin__actions display-flex">
              <BRButton
                type="destructive-primary"
                label={intl.formatMessage({
                  id: 'hubs.receive.receive_bins.end_receiving'
                })}
                onClick={handleEndReceiving}
                disabled={!scannedDeliveries.length}
              />
            </div>
          </div>
        </div>
        <div className="br-hub-recceive-from-bin-table__container">
          <BRSwappingTables
            leftTableProps={{
              columns: deliveriesColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.transfer_seals.table.edit_title'
                    },
                    {
                      count: deliveries.length
                    }
                  )}
                </div>
              )
            }}
            rightTableProps={{
              columns: scannedDeliveriesColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.transfer_seals.table.title'
                    },
                    {
                      count: scannedDeliveries.length
                    }
                  )}
                </div>
              )
            }}
            leftTableDataSource={deliveries}
            rightTableDataSource={formatScannedDeliveriesData()}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(ReceiveFromBin);
