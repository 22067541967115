import { injectIntl } from 'react-intl';

import { formatPrice } from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import './TotalOrdersPrice.less';

const TotalOrdersPrice = ({ intl, cod, totalPrice }) => {
  const prices = [
    {
      label: intl.formatMessage({
        id: 'fulfillment_outbound.cod_from_business'
      }),
      value: cod
    },
    {
      label: intl.formatMessage({
        id: 'fulfillment_outbound.system_price'
      }),
      value: totalPrice
    }
  ];

  return (
    <div className="br-po-section br-sales-order__total-orders-price">
      {prices.map(
        ({ label, value }) =>
          value !== undefined && (
            <div className="br-sales-order__total-orders-price__item">
              <span className="heading">{label}</span>
              <span className="heading">{formatPrice(value)}</span>
            </div>
          )
      )}
    </div>
  );
};

export default injectIntl(TotalOrdersPrice);
