import React, { useState, useEffect } from 'react';

import { formatStarNoteDate } from 'utils/shipmentDetails';

import { ReactComponent as NotesEmptyStateIcon } from 'assets/imgRevamp/NewNotesEmptyStateIcon.svg';

import './StarNotes.less';

const Note = ({ key, content, takenBy, date, role }) => {
  return (
    <div className="br-notes__container" key={key}>
      <span className="br-notes__note-title">{content}</span>
      <span className="br-notes__note-subtitle">
        <span>{formatStarNoteDate(date, true)}</span>
        <span>By: {takenBy}</span>
      </span>
    </div>
  );
};

const StarNotes = ({ starNotes, delivery, setIsLoading }) => {
  const [deliveryNotes, setDeliveryNotes] = useState([]);

  useEffect(() => {
    setDeliveryNotes(starNotes.reverse());
  }, [delivery]);

  return (
    <div className="br-delivery-star-notes__contatiner">
      {deliveryNotes.length ? (
        <div className="br-note__content-container">
          {deliveryNotes.map((note, index) => (
            <Note
              key={index}
              content={note.note}
              takenBy={note.userName}
              date={note.time}
              role={note.userRole}
            />
          ))}
        </div>
      ) : (
        <div className="br-delivery-notes__empty-state">
          <NotesEmptyStateIcon />
          <span>The delivery has no star notes yet</span>
        </div>
      )}
    </div>
  );
};

export default StarNotes;
