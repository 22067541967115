import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { Select } from 'antd';

import {
  getFulfillmentReturnToVendorSKUSerials,
  getFulfillmentReturnToVendorSKUsQuantities,
  createReturnToVendor
} from 'services/fulfillment-return-to-vendor';
import { getBusinessProductVariances } from 'services/products';
import {
  PRODUCTS_SEARCH_OPTIONS_IDS,
  SELECT_PRODUCTS_SEARCH_OPTIONS
} from 'constants/fulfillment-products';
import {
  RTV_LINE_TYPES,
  getRTVLineTypesOptions,
  getRTVPathById,
  goToReturnToVendorList,
  renderSerialsViewForRTV
} from 'constants/fulfillment-return-to-vendor';
import { openModal } from 'utils/modal';
import {
  getSKU,
  unselectProduct
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import BusinessDetails from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/BusinessDetails/BusinessDetails';
import Products from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/Products';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import SelectProductsModal from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/SelectProductsModal/SelectProductsModal';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import EmptyState from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/EmptyState/EmptyState';
import { notify } from 'components/Notify/Notify';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import ScanModal from 'components/NewFulfillment/FulfillmentReturnToVendor/components/ScanModal/ScanModal';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as BarcodeIcon } from 'assets/bosta-icons/BarcodeIcon.svg';

import './CreateReturnToVendor.less';

const CreateReturnToVendor = ({ history }) => {
  const { formatMessage } = useIntl();

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedLineData, setClickedLineData] = useState(null);

  const { ALL, DAMAGED, PARTIAL } = RTV_LINE_TYPES;

  const updateProducts = ({ sku, serialsType, serials = [] }) => {
    setProducts((prev) => {
      const index = prev.findIndex(({ productSku }) => productSku === sku);
      prev[index] = {
        ...prev[index],
        serialsType,
        serials
      };

      return [...prev];
    });
  };

  const handleRTVLineTypeChange = ({ serialsType, sku }) => {
    if (serialsType === PARTIAL) {
      updateProducts({
        serialsType,
        sku
      });
    } else {
      fetchSKUSerials({
        serialsType,
        sku
      });
    }
  };

  const openScanModal = ({ sku, serials, serialsType, allSerialsCount }) => {
    openModal(ScanModal, {
      sku,
      serials: serials.map((serialNumber) => ({ serialNumber })),
      allSerialsCount,
      onConfirm: (scannedSerials) => {
        updateProducts({
          serialsType,
          sku,
          serials: scannedSerials
        });
      }
    });
  };

  const removeSKU = (productSku) => {
    notify(
      formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.remove_sku_confirmation_modal.success_msg'
      }),
      'success'
    );
    unselectProduct({ productSku, setProducts });
  };

  const confirmBeforeRemoveSKU = (productSku) => {
    openModal(BRConfirmationModal, {
      wrapClassName: 'br-create-rtv__confirmation-modal',
      message: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.remove_sku_confirmation_modal.message'
      }),
      title: `${formatMessage({
        id: 'common.remove'
      })} ${productSku}`,
      onConfirm: () => removeSKU(productSku),
      confirmButtonText: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.remove_sku_confirmation_modal.confirm'
      }),
      cancelButtonText: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.remove_sku_confirmation_modal.cancel'
      })
    });
  };

  const productsTableColumns = [
    {
      dataIndex: 'onShelf',
      title: formatMessage({
        id: 'fulfillment_common.on_shelf'
      })
    },
    {
      dataIndex: 'damaged',
      title: formatMessage({
        id: 'fulfillment_common.damaged'
      })
    },
    {
      title: formatMessage({
        id: 'fulfillment_common.return'
      }),
      render: ({ productSku, damaged }) => (
        <Select
          defaultValue={getRTVLineTypesOptions()[0].value}
          onChange={(value) =>
            handleRTVLineTypeChange({ serialsType: value, sku: productSku })
          }
          options={getRTVLineTypesOptions({ disabledDamaged: !damaged })}
          className="br-rounded-dropdown br-line-serials-type-dropdown"
        />
      )
    },
    {
      title: formatMessage({
        id: 'fulfillment_common.qty'
      }),
      render: ({
        serials = [],
        serialsType = PARTIAL,
        productSku,
        onShelf = 0,
        damaged = 0
      }) =>
        serialsType === PARTIAL ? (
          <>
            <BarcodeIcon
              className="clickable"
              onClick={() =>
                openScanModal({
                  serials,
                  sku: productSku,
                  serialsType,
                  allSerialsCount: onShelf + damaged
                })
              }
            />
            <span className="br-confirmed-po__tooltip__text">
              {serials.length}
            </span>
          </>
        ) : (
          serials.length
        )
    },
    {
      render: (line = {}) =>
        line.serialsType !== PARTIAL &&
        renderSerialsViewForRTV({
          line,
          clickedLineData,
          setClickedLineData,
          fetchData: () =>
            getFulfillmentReturnToVendorSKUSerials({
              sku: line.productSku,
              serialsType: line.serialsType
            })
        })
    },
    {
      dataIndex: 'productSku',
      width: 20,
      render: (productSku) => (
        <CloseIcon
          className="close-icon"
          onClick={() => confirmBeforeRemoveSKU(productSku)}
        />
      )
    }
  ];

  const fetchSKUSerials = async ({ sku, serialsType }) => {
    setIsLoading(true);

    const payload = {
      sku,
      serialsType
    };

    try {
      const { data } = await getFulfillmentReturnToVendorSKUSerials(payload);

      const { onShelf = [], damaged = [] } = data.serials || {};
      let serials = [];

      switch (serialsType) {
        case ALL:
          serials = [...onShelf, ...damaged];
          break;

        case DAMAGED:
          serials = damaged;
          break;

        default:
          break;
      }

      updateProducts({
        serialsType,
        sku,
        serials: serials.map(({ serialNumber }) => serialNumber)
      });
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const checkValidSKUsQuantities = (skus = []) => {
    const validSKUs = skus.filter(({ onShelf, damaged }) => onShelf || damaged);
    const invalidSKUsCount = skus.length - validSKUs.length;

    if (invalidSKUsCount) {
      notify(
        formatMessage(
          {
            id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.error_msg.skus_with_no_serials'
          },
          {
            count: invalidSKUsCount
          }
        )
      );
    }

    return validSKUs;
  };

  const fetchSKUsQuantities = async (skus) => {
    const payload = {
      businessId: selectedBusiness?._id,
      skus: skus.join(',')
    };

    try {
      const { data } = await getFulfillmentReturnToVendorSKUsQuantities(
        payload
      );

      const validSKUs = checkValidSKUsQuantities(data.skus);

      setProducts((prev) =>
        validSKUs.map((product) => {
          const addedBefore =
            prev.find(({ productSku }) => product.productSku === productSku) ||
            {};

          return {
            serialsType: PARTIAL,
            ...addedBefore,
            ...product,
            key: product.id
          };
        })
      );
    } catch (e) {
      notify(e.message);
    }
  };

  const openSelectProductsModal = () => {
    openModal(SelectProductsModal, {
      selectedBusiness,
      products,
      onSuccess: (selectedProducts) =>
        fetchSKUsQuantities(selectedProducts.map((product) => getSKU(product))),
      checkById: true,
      okText: formatMessage({
        id: 'fulfillment_products.select_products_modal.confirm'
      }),
      getCounts: (selectedProducts) => [
        formatMessage(
          {
            id: 'fulfillment_products.select_products_modal.selected_products_count'
          },
          {
            count: selectedProducts.length
          }
        )
      ],
      columns: [
        {
          dataIndex: 'productImage',
          render: (productImage) => <ProductImage src={productImage} />,
          width: 60
        },
        {
          dataIndex: 'productName',
          ellipsis: true
        },
        {
          dataIndex: 'bostaSku',
          render: (bostaSku) => <span className="text-gray">{bostaSku}</span>,
          width: 150
        }
      ],
      listKey: 'variances',
      fetchData: getBusinessProductVariances,
      formatData: (list) =>
        list.map(
          ({
            productName,
            productNameAr,
            optionsString,
            variantDefaultImage,
            ...rest
          }) => ({
            productName: `${productName || productNameAr}${
              optionsString ? ` - ${optionsString}` : ''
            }`,
            key: rest.id,
            productImage: variantDefaultImage,
            ...rest
          })
        ),
      renderEmptyState: () => (
        <EmptyState
          title={formatMessage({
            id: 'fulfillment_products.select_products_modal.empty_state.title'
          })}
        />
      ),
      searchOptions: SELECT_PRODUCTS_SEARCH_OPTIONS,
      searchOptionsIds: PRODUCTS_SEARCH_OPTIONS_IDS,
      defaultSearchOptionKey: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
    });
  };

  const handleCreateRTV = async (lines) => {
    try {
      const payload = {
        businessId: selectedBusiness._id,
        lines
      };

      const { data } = await createReturnToVendor(payload);

      const rtvId = data.rtv?.id;

      openModal(SuccessModal, {
        title: formatMessage(
          {
            id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.success_modal.title'
          },
          {
            rtvId
          }
        ),
        confirmButtonLabel: formatMessage({
          id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.success_modal.confirm'
        }),
        cancelButtonLabel: formatMessage({
          id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.success_modal.cancel'
        }),
        modalAction: () => history.push(getRTVPathById(rtvId)),
        cancelAction: goToReturnToVendorList,
        onCancel: null
      });
    } catch (e) {
      notify(e.message);
    }
  };

  const confirmBeforeExitRTV = () => {
    openModal(BRConfirmationModal, {
      wrapClassName: 'br-create-rtv__confirmation-modal',
      title: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.exit_rtv_confirmation_modal.title'
      }),
      message: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.exit_rtv_confirmation_modal.message'
      }),
      onConfirm: goToReturnToVendorList,
      confirmButtonText: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.exit_rtv_confirmation_modal.confirm'
      }),
      cancelButtonText: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.exit_rtv_confirmation_modal.cancel'
      })
    });
  };

  const exitRTV = products.length
    ? confirmBeforeExitRTV
    : goToReturnToVendorList;

  const confirmBeforeCompleteRTV = () => {
    const lines = [];

    for (let i = 0; i < products.length; i++) {
      const { productSku, serials = [], serialsType } = products[i];

      if (!(serialsType && serials.length)) {
        notify(
          formatMessage({
            id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.error_msg.skus_with_no_serials_scanned'
          })
        );

        return;
      }

      lines.push({
        productSku,
        quantity: serials.length,
        serials,
        type: serialsType
      });
    }

    let serialsCount = 0,
      skuCounts = 0;

    products.forEach(({ serials = [] }) => {
      skuCounts++;
      serialsCount += serials.length;
    });

    openModal(BRConfirmationModal, {
      wrapClassName: 'br-create-rtv__confirmation-modal',
      title: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.confirmation_modal.title'
      }),
      message: (
        <pre>
          {formatMessage(
            {
              id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.confirmation_modal.message'
            },
            {
              serialsCount,
              skuCounts
            }
          )}
        </pre>
      ),
      onConfirm: () => handleCreateRTV(lines),
      confirmButtonText: formatMessage({
        id: 'fulfillment_return_to_vendor.create_rtv.complete_rtv.confirmation_modal.confirm'
      }),
      cancelButtonText: formatMessage({
        id: 'common.back'
      }),
      confirmButtonType: 'primary'
    });
  };

  useEffect(() => {
    setProducts([]);
  }, [selectedBusiness]);

  return (
    <div className="br-purchase-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader
            icon={CloseIcon}
            iconOnClick={exitRTV}
            title={formatMessage({
              id: 'fulfillment_return_to_vendor.create_rtv.title'
            })}
            actions={[
              {
                label: formatMessage({
                  id: 'common.cancel'
                }),
                onClick: exitRTV
              },
              {
                label: formatMessage({
                  id: 'fulfillment_return_to_vendor.create_rtv.actions.confirm'
                }),
                type: 'primary',
                disabled: !products.length,
                onClick: confirmBeforeCompleteRTV
              }
            ]}
          />

          <div className="br-po-content">
            <div className="start-side">
              <BusinessDetails
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedBusiness={selectedBusiness}
                setSelectedBusiness={setSelectedBusiness}
                setProducts={setProducts}
              />
            </div>
            <div className="end-side">
              <Products
                selectedBusiness={selectedBusiness}
                products={products}
                setProducts={setProducts}
                columns={productsTableColumns}
                handleSelectProductsClick={openSelectProductsModal}
                hideUploadSheetBtn
              />
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default withRouter(CreateReturnToVendor);
