import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Input, Form, Button } from 'antd';

import { logout } from 'utils';
import { SECURITY_TYPES } from 'constants/Security';
import { verify2FASetup } from 'services/Security';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import { notify } from 'components/Notify/Notify';

import './SecurityPINModal.less';

function SecurityPINModal(props) {
  const { close, type, intl, response } = props;
  const onFinish = async values => {
    try {
      const response = await verify2FASetup({ twoFAType: type, ...values });
      close();
      notify(response.message, 'success');
      logout();
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <Modal {...props} footer={null} className="br-security-modal" width={null}>
      <BRContentHeader
        title={intl.formatMessage({
          id: `settings.security.authentication_types.${
            type === SECURITY_TYPES.APP
              ? 'app'
              : type === SECURITY_TYPES.SMS
              ? 'sms'
              : 'email'
          }.modal_title`,
        })}
        className="br-security__qr-code-modal"
        titleClassName="br-security__qr-code-modal__title"
        isInternalComponent
      />
      {type === SECURITY_TYPES.APP && (
        <div className="br-security__qr-code">
          <img src={response.data.dataURL} alt="Cannot load QRScan" />
          <div className="br-security__qr-code__secret-token">
            <span>
              {intl.formatMessage({
                id: 'settings.security.bosta_Secret_token',
              })}
            </span>
            <span>{response.data.tempSecret}</span>
          </div>
        </div>
      )}
      <Form onFinish={onFinish}>
        <div className="br-form-row">
          <Form.Item
            name="token"
            label={intl.formatMessage({
              id: 'settings.security.PIN_label',
            })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'signin.verify_user.PIN_code_placeholder',
              })}
            />
          </Form.Item>
        </div>
        <div className="br-security-pin-modal__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel',
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.submit',
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default injectIntl(SecurityPINModal);
