import { downloadHeader } from 'utils/download';
import http from 'utils/http.js';

export const fetchTickets = (payload) => {
  return http.post('/tickets/filter', payload);
};

export const getTicketDetails = (ticketId) => {
  return http.get(`/tickets/${ticketId}/ticket-details`);
};

export const getConversation = ({ ticketId, payload }) => {
  return http.get(`/tickets/${ticketId}/ticket-conversation`, payload);
};

export const sendMessage = ({ ticketId, payload, isReply = false }) => {
  return http.post(
    `tickets/${ticketId}/${isReply ? 'reply' : 'notes'}`,
    payload
  );
};

export const getTicketsFields = () => {
  return http.get('/tickets/tickets-fields');
};

export const reassignTicket = ({ ticketId, payload }) => {
  return http.put(`/tickets/${ticketId}/re-assign`, payload);
};

export const updateTicketStatus = ({ ticketId, payload }) => {
  return http.put(`tickets/${ticketId}/status`, payload);
};

export const forwardTicket = ({ ticketId, payload }) => {
  return http.post(`tickets/${ticketId}/forward`, payload);
};

export const reassignTicketToAgent = ({ ticketId, agentId }) => {
  return http.put(`tickets/${ticketId}/assign/${agentId}`);
};

export const getAllAgents = (payload) => {
  return http.get(`tickets/get-agents`, payload);
};

export const addTrackingNumber = (ticketId, payload) => {
  return http.put(`tickets/${ticketId}`, payload);
};

export const getDeliveryTickets = (deliveryId, payload) => {
  return http.get(`tickets/${deliveryId}/tickets`, payload);
};
