import './ReceiveSealsCard.less';

const ReceiveSealsCard = ({ header, children }) => (
  <div className="br-receive-seals__card">
    {header && <div className="br-receive-seals__card__header">{header}</div>}
    <div className="br-receive-seals__card__content">{children}</div>
  </div>
);

export default ReceiveSealsCard;
