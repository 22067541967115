import { useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import _debounce from 'lodash/debounce';

import {
  SKU_SERIAL_REGEX,
  SKU_SERIAL_SEPARATOR
} from 'constants/fulfillment-sales-orders';
import { getProductDataForSerial } from 'constants/fulfillment-return-orders';
import Sounds from 'common/Sounds';

import { notify } from 'components/Notify/Notify';

import './ScanInput.less';

const { beep, warning } = Sounds;

const ScanInput = ({
  intl,
  scannedSerials = [],
  setScannedSerials = () => {},
  notScannedSerials = [],
  setNotScannedSerials = () => {},
  allFetchedSerials = [],
  handleValidScan,
  disabled = false,
  allSerialsCount
}) => {
  const formRef = useRef();
  const inputRef = useRef();

  const onValidScan = (serial) => {
    beep();
    setScannedSerials((prev) => [
      ...prev,
      {
        serialNumber: serial,
        ...getProductDataForSerial({ allSerials: allFetchedSerials, serial })
      }
    ]);
    setNotScannedSerials((prev = []) =>
      prev.filter(({ serialNumber }) => serialNumber !== serial)
    );
    formRef.current?.setFieldsValue({
      serial: ''
    });
    inputRef.current?.focus();
  };

  const checkScannedSerial = (skuSerial) => {
    let errorMsg;

    if (SKU_SERIAL_REGEX.test(skuSerial)) {
      const [sku, serial] = skuSerial.split(SKU_SERIAL_SEPARATOR);

      if (handleValidScan) {
        handleValidScan(serial);
      } else {
        const isScannedBefore = scannedSerials.find(
          ({ serialNumber }) => serialNumber === serial
        );

        if (isScannedBefore) {
          errorMsg = intl.formatMessage({
            id: 'fulfillment_returns.scan_serials_modal.error_msgs.scanned_before'
          });
        } else if (
          notScannedSerials.length &&
          !notScannedSerials.find(({ serialNumber }) => serialNumber === serial)
        ) {
          errorMsg = intl.formatMessage({
            id: 'fulfillment_returns.scan_serials_modal.error_msgs.not_belong_to_order'
          });
        } else if (scannedSerials.length === allSerialsCount) {
          errorMsg = intl.formatMessage({
            id: 'fulfillment_returns.scan_serials_modal.error_msgs.max_num_of_scan'
          });
        } else if (
          !allFetchedSerials.find(({ serialNumber }) => serialNumber === serial)
        ) {
          errorMsg = intl.formatMessage({
            id: 'fulfillment_returns.scan_serials_modal.error_msgs.can_not_added'
          });
        }

        if (errorMsg) {
          warning();
          notify(errorMsg);
        } else {
          onValidScan(serial);
        }
      }
    }
  };

  const onScan = _debounce((value) => {
    checkScannedSerial(value);
  }, 500);

  const onChange = ({ target: { value } }) => {
    const trimmedValue = value?.trim();
    if (trimmedValue) {
      onScan(trimmedValue);
    }
  };

  return (
    <Form ref={formRef}>
      <Form.Item
        label={intl.formatMessage({
          id: 'fulfillment_returns.scan_serials_modal.scan_serials_field.label'
        })}
        name="serial"
        className="br-scan-serials-modal__scan-field"
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'fulfillment_returns.scan_serials_modal.scan_serials_field.placeholder'
          })}
          onChange={onChange}
          ref={inputRef}
          disabled={disabled}
          autoFocus
        />
      </Form.Item>
    </Form>
  );
};

export default injectIntl(ScanInput);
