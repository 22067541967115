import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select, Switch } from 'antd';

import { createHubZone, updateHubZone } from 'services/zoning';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Banner from 'components/Banner/Banner';

const CreateEditZoneModal = ({
  intl,
  close,
  zoneId,
  name,
  nameAr,
  selectedCityId,
  hubId,
  fmHubId,
  pickupAvailability = false,
  dropOffAvailability = false,
  cities = [],
  updateZoningData,
  allHubs,
  isEditMode,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef('');

  const handleOnFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        ...values
      };

      if (isEditMode) {
        delete payload.name;
        delete payload.nameAr;
      }

      isEditMode
        ? await updateHubZone(zoneId, payload)
        : await createHubZone(payload);

      updateZoningData();
      notify(
        intl.formatMessage({
          id: `zoning.create_edit_zone_modal.${
            isEditMode ? 'update' : 'create'
          }_success`
        }),
        'success'
      );
      close();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `zoning.create_edit_zone_modal.${
          isEditMode ? 'edit_zone' : 'title'
        }`
      })}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            type="primary"
            htmlType="submit"
            form="zoneModalForm"
            disabled={isLoading}
            label={intl.formatMessage({
              id: `zoning.create_edit_zone_modal.${
                isEditMode ? 'edit' : 'add'
              }_zone`
            })}
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <Form
          ref={formRef}
          name="zoneModalForm"
          initialValues={{
            name,
            nameAr,
            cityId: selectedCityId,
            hubId,
            fmHubId,
            pickupAvailability,
            dropOffAvailability
          }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.table_columns.zone_name_en'
            })}
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              disabled={isEditMode}
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_zone_modal.zone_name_en_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.table_columns.zone_name_ar'
            })}
            name="nameAr"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              disabled={isEditMode}
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_zone_modal.zone_name_ar_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.city'
            })}
            name="cityId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              disabled
              showSearch
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_zone_modal.city_placeholder'
              })}
              optionFilterProp="children"
            >
              {cities.map((city) => (
                <Select.Option value={city._id} key={city._id}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.hub'
            })}
            name="hubId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              showSearch
              options={allHubs}
              fieldNames={{ label: 'name', value: '_id' }}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'zoning.hub_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.table_columns.fm_hub'
            })}
            name="fmHubId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              showSearch
              options={allHubs}
              fieldNames={{ label: 'name', value: '_id' }}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'zoning.hub_placeholder'
              })}
            />
          </Form.Item>
          {isEditMode && (
            <div className="br-zoning-availability__banner">
              <Banner
                subText={intl.formatMessage({
                  id: 'zoning.create_edit_zone_modal.coverage_warning'
                })}
              />
            </div>
          )}

          <Form.Item
            className="br-zoning-availability__toggle"
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.pickup_coverage'
            })}
            name="pickupAvailability"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="br-zoning-availability__toggle"
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.delivery_coverage'
            })}
            name="dropOffAvailability"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(CreateEditZoneModal);
