import React, { useState, useRef } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { injectIntl } from 'react-intl';

import {
  InvalidationReasons,
  INVALIDATION_REASONS,
  ServicesReasons,
  SERVICES_REASONS_VALUES,
  BUSINESS_STATUS
} from 'constants/Businesses';
import { requiredRule } from 'utils/forms';
import { cleanEmptyString } from 'utils/helpers';

import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';

const InvalidatedBusinessModal = ({
  close,
  businessId,
  handleChangeBusinessStatus,
  intl,
  ...props
}) => {
  const [showServiceReason, setShowServiceReason] = useState(false);
  const [showServiceReasonOther, setShowServiceReasonOther] = useState(false);
  const [showBuisnesses, setShowBuisnesses] = useState(false);

  const form = useRef(null);

  return (
    <>
      <Modal
        title={intl.formatMessage({
          id: 'businesses.invalidate_business_modal.title'
        })}
        onCancel={close}
        onOk={() => {
          form.current.validateFields().then((values) => {
            if (values.errorFields === undefined) {
              handleChangeBusinessStatus({
                businessId,
                status: BUSINESS_STATUS.INVALID,
                restPayload: cleanEmptyString({
                  ...form.current.getFieldValue()
                })
              });
              form.current.resetFields();

              close();
            }
          });
        }}
        {...props}
      >
        <Form ref={form} name="control-hooks">
          <Form.Item
            name="invalidationReason"
            label={intl.formatMessage({
              id: 'businesses.invalidate_business_modal.choose_reason'
            })}
            rules={[{ required: true }]}
          >
            <>
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(evt) => {
                  if (evt === INVALIDATION_REASONS.SERVER_NOT_AVAILABLE) {
                    setShowServiceReason(true);
                    setShowBuisnesses(false);
                  } else if (evt === INVALIDATION_REASONS.DUPLICATE) {
                    setShowBuisnesses(true);
                    setShowServiceReason(false);
                  } else {
                    setShowServiceReason(false);
                    setShowBuisnesses(false);
                    form.current.setFieldsValue({
                      notSupportedService: null,
                      duplicateBusinessAccount: null
                    });
                  }
                  if (evt) {
                    form.current.setFieldsValue({
                      invalidationReason: evt
                    });
                  }
                }}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              >
                {InvalidationReasons
                  ? InvalidationReasons.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </>
          </Form.Item>
          {showBuisnesses && (
            <Form.Item
              name="duplicateBusinessAccount"
              label={intl.formatMessage({
                id: 'business_details.business_invalidation.select_business_label'
              })}
              hasFeedback
              rules={
                showBuisnesses
                  ? [
                      requiredRule(
                        intl.formatMessage({
                          id: 'business_details.business_invalidation.field_is_required'
                        })
                      )
                    ]
                  : ''
              }
            >
              <BusinessSelector
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => {
                  if (value) {
                    form.current.setFieldsValue({
                      duplicateBusinessAccount: value
                    });
                  }
                }}
              />
            </Form.Item>
          )}
          {showServiceReason && (
            <Form.Item
              name="notSupportedService"
              label="Choose services reasons"
              hasFeedback
              rules={
                showServiceReason
                  ? [requiredRule('This field is required ')]
                  : ''
              }
            >
              <>
                <Select
                  showSearch
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  placeholder={intl.formatMessage({ id: 'common.select' })}
                  onChange={(evt) => {
                    if (evt === SERVICES_REASONS_VALUES.OTHER) {
                      setShowServiceReasonOther(true);
                    } else {
                      setShowServiceReasonOther(false);
                    }
                    if (evt) {
                      form.current.setFieldsValue({
                        notSupportedService: evt
                      });
                    }
                  }}
                >
                  {ServicesReasons
                    ? ServicesReasons.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </>
            </Form.Item>
          )}
          {showServiceReasonOther && (
            <Form.Item name="other" label="Service name">
              <>
                <Input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'businesses.invalidate_business_modal.enter_service_name'
                  })}
                  onChange={(evt) => {
                    if (evt) {
                      form.current.setFieldsValue({
                        notSupportedService:
                          evt.target.value.length > 0 ? evt.target.value : null
                      });
                    }
                  }}
                />
              </>
            </Form.Item>
          )}

          <Form.Item
            label={intl.formatMessage({
              id: 'businesses.invalidate_business_modal.note_label'
            })}
            name="invalidationNote"
          >
            <>
              <Input
                type="text"
                onChange={(evt) => {
                  if (evt) {
                    form.current.setFieldsValue({
                      invalidationNote:
                        evt.target.value.length > 0 ? evt.target.value : null
                    });
                  }
                }}
              />
            </>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default injectIntl(InvalidatedBusinessModal);
