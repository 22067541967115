import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getSalesOrdersStatistics = () => {
  return http.get(`/fulfillment-v2/sales-orders/statistics`);
};

export const getFulfillmentSalesOrders = (payload) => {
  return http.get(`/fulfillment-v2/sales-orders`, payload);
};

export const exportFulfillmentSalesOrders = (payload) => {
  return http.get(
    `/fulfillment-v2/sales-orders/export`,
    payload,
    downloadHeader
  );
};

export const getFulfillmentSalesOrder = (id) => {
  return http.get(`/fulfillment-v2/sales-orders/${id}`);
};

export const checkSerial = (serial, payload) => {
  return http.get(
    `/fulfillment-v2/sales-orders/check-serial/${serial}`,
    payload
  );
};

export const packSalesOrder = (id, payload) => {
  return http.post(`/fulfillment-v2/sales-orders/${id}/pack`, payload);
};

export const getSalesOrderLineSerialNumbers = (lineId) => {
  return http.get(`/fulfillment-v2/sales-orders/lines/${lineId}/serials`);
};
