import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, InputNumber, Button } from 'antd';

import { priorityTypes } from 'constants/LiveOpsPriorities';
import { openModal } from 'utils/modal';

import {
  addPriority,
  deletePriority,
  fetchLiveOpsPriorityList,
  fetchStars
} from 'services/live-ops-priorities';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import EditWeightModal from './components/EditWeightModal';
import { notify } from 'components/Notify/Notify';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';

import { ReactComponent as Trash } from 'assets/imgRevamp/trash.svg';

import './LiveOpsPriorities.less';

const LiveOpsPriorities = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priorityType, setPriorityType] = useState('');
  const [stars, setStars] = useState([]);
  const [selectedBusiness, setSelectedBusienss] = useState({});

  const BUSINESS = 'Business';
  const STAR = 'Star';
  const ZONE = 'Zone';
  const formRef = useRef('');

  const [prioritiesCount, setPrioritiesCount] = useState(0);
  const tableRef = useRef('');

  const getStars = async () => {
    try {
      const starsList = stars.length ? stars : await fetchStars();
      !stars.length && setStars(starsList?.message?.stars);
    } catch (error) {
      notify(error.message);
    }
  };

  const getZones = async () => {};

  const handlePriorityTypeChange = async (value) => {
    setPriorityType(value);
    formRef.current.setFieldsValue({ priorityData: null });
    formRef.current.setFields([
      {
        name: 'priorityIndex',
        errors: []
      }
    ]);
    setIsLoading(true);
    switch (value) {
      case STAR:
        getStars();
        break;
      case ZONE:
        getZones();
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const handleOnBusinessChange = (value) => {
    setSelectedBusienss(value);
  };

  const getLiveOpsPriorityList = async ({ pageId }) => {
    try {
      const {
        data: { list, total }
      } = await fetchLiveOpsPriorityList({ page: pageId });
      setPrioritiesCount(total);
      return {
        list: list,
        total: total
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnFinish = async (values) => {
    const { typeValue, priorityIndex, weightValue } = values;
    const payload = {
      weight: weightValue,
      name:
        typeValue === BUSINESS
          ? selectedBusiness.label
          : `${stars[priorityIndex].profile.firstName} ${stars[priorityIndex].profile.lastName}`,
      type: typeValue,
      referrerId:
        typeValue === BUSINESS
          ? selectedBusiness.value
          : stars[priorityIndex]._id
    };
    try {
      setIsLoading(true);
      await addPriority(payload);
      tableRef.current();
      notify(
        intl.formatMessage({
          id: 'settings.live_ops_priorities.form.success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleFormReset = () => {
    setPriorityType('');
    formRef.current.resetFields();
  };

  const tableActionButtons = (priorityRule) => (
    <div className="br-live-ops-priorities__table__actions">
      <Button
        className="br-live-ops-priorities__table__buttons__edit"
        onClick={() => {
          handleEditClick(priorityRule);
        }}
      >
        {intl.formatMessage({
          id: 'settings.live_ops_priorities.table.buttons.edit'
        })}
      </Button>
      <Button
        className="br-live-ops-priorities__table__buttons__delete"
        icon={<Trash />}
        onClick={() => handleDeleteClick(priorityRule)}
      ></Button>
    </div>
  );

  const tableColumns = () => {
    return [
      {
        title: intl.formatMessage({
          id: 'settings.live_ops_priorities.table.columns.type'
        }),
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: intl.formatMessage({
          id: 'settings.live_ops_priorities.table.columns.name'
        }),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: intl.formatMessage({
          id: 'settings.live_ops_priorities.table.columns.weight'
        }),
        dataIndex: 'weight',
        key: 'weight'
      },
      {
        title: intl.formatMessage({
          id: 'settings.live_ops_priorities.table.columns.actions'
        }),
        render: (priorityRule) => tableActionButtons(priorityRule)
      }
    ];
  };

  const acceptMethods = (refreshMethod) => {
    tableRef.current = refreshMethod;
  };

  const handleDeleteClick = (priorityRule) => {
    openModal(BRConfirmationModal, {
      title: intl.formatMessage({
        id: 'settings.live_ops_priorities.table.delete_title'
      }),
      message: intl.formatMessage({
        id: 'settings.live_ops_priorities.table.delete_message'
      }),
      onConfirm: () => {
        handlePriorityDelete(priorityRule);
      }
    });
  };

  const handleEditClick = (priorityRule) => {
    openModal(EditWeightModal, {
      title: intl.formatMessage({
        id: 'settings.live_ops_priorities.table.edit_title'
      }),
      id: priorityRule._id,
      currentWeight: priorityRule.weight,
      tableRef
    });
  };

  const handlePriorityDelete = async (priorityRule) => {
    try {
      const payload = { id: priorityRule._id };
      await deletePriority(payload);
      tableRef.current();
      notify(
        intl.formatMessage({
          id: 'settings.live_ops_priorities.table.delete_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-live-ops-priorities">
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.live_ops_priorities.title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.live_ops_priorities.sub_title'
        })}
        isInternalComponent
      />
      <Form
        ref={formRef}
        onFinish={handleOnFinish}
        scrollToFirstError
        initialValues={{ weightValue: 1 }}
      >
        <div className="br-form-row br-live-ops-priorities__form">
          <Form.Item
            name="typeValue"
            label={intl.formatMessage({
              id: 'settings.live_ops_priorities.form.priority_type'
            })}
          >
            <Select
              showSearch
              allowClear
              onChange={(value) => handlePriorityTypeChange(value)}
              placeholder="Select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {priorityTypes.map((priorityType) => (
                <Select.Option
                  key={priorityType.label}
                  value={priorityType.value}
                >
                  {priorityType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="priorityIndex"
            label={intl.formatMessage({
              id:
                priorityType === BUSINESS
                  ? 'settings.live_ops_priorities.form.business'
                  : priorityType === STAR
                  ? 'settings.live_ops_priorities.form.star'
                  : priorityType === ZONE
                  ? 'settings.live_ops_priorities.form.zone'
                  : 'settings.live_ops_priorities.form.select'
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id:
                    priorityType === BUSINESS
                      ? 'settings.live_ops_priorities.form.errors.business'
                      : priorityType === STAR
                      ? 'settings.live_ops_priorities.form.errors.star'
                      : priorityType === ZONE
                      ? 'settings.live_ops_priorities.form.errors.zone'
                      : 'settings.live_ops_priorities.form.errors.select'
                })
              }
            ]}
          >
            {priorityType === BUSINESS ? (
              <BusinessSelector
                placeholder={intl.formatMessage({ id: 'common.select' })}
                listHeight={320}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleOnBusinessChange}
                labelInValue
              />
            ) : (
              <Select
                showSearch
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
                loading={isLoading}
                disabled={isLoading}
                optionFilterProp="children"
                listHeight={320}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {stars.map((star, index) => (
                  <Select.Option key={star._id} value={index}>
                    {star?.profile?.firstName + ' ' + star?.profile?.lastName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            name="weightValue"
            label={intl.formatMessage({
              id: 'settings.live_ops_priorities.form.weight'
            })}
            rules={[{ required: true }]}
          >
            <InputNumber min={1} max={4} />
          </Form.Item>
        </div>
        <div className="br-live-ops-priorities__form__buttons">
          <Button
            className="br-live-ops-priorities__form__buttons__clear"
            disabled={isLoading}
            onClick={handleFormReset}
          >
            {intl.formatMessage({
              id: 'settings.live_ops_priorities.form.clear_button'
            })}
          </Button>
          <Button
            disabled={isLoading}
            htmlType="submit"
            className="br-live-ops-priorities__form__buttons__add"
          >
            {intl.formatMessage({
              id: 'settings.live_ops_priorities.form.add_button'
            })}
          </Button>
        </div>
      </Form>
      <BRSearchableTable
        title={intl.formatMessage(
          {
            id: 'settings.live_ops_priorities.table.title'
          },
          { prioritiesCount }
        )}
        columns={tableColumns()}
        listFunction={getLiveOpsPriorityList}
        paginationPostion={['bottomRight']}
        showPagination
        onRowClick={() => {}}
        shareMethods={acceptMethods}
        withOutCheckBoxes
      />
    </div>
  );
};

export default injectIntl(LiveOpsPriorities);
