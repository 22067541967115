import aclMatrix from 'common/aclMatrix';
import { get as getValue } from 'lodash';
import { isDefaultBostaCourier } from 'utils/helpers';

export const formatOptions = (keyFieldName, labelFieldName, data) => {
  const labelFields = labelFieldName.split(',');
  if (!Array.isArray(data)) {
    const arr = Array.from(Object.keys(data), (k) => data[k]);
    data = arr;
  }
  return data.map((item) => {
    const labelValue = labelFields.reduce((acc, value) => {
      if (!value) return acc;
      return `${acc} ${getValue(item, value, '')}`;
    }, '');
    return { value: item[keyFieldName], label: labelValue };
  });
};
const hub = JSON.parse(localStorage.getItem('userInfo'))?.warehouseInfo || null;

export const initialFormValues = {
  // states: null,
  createdAt: null,
  starId: null,
  // unassigned: true,
  // active: true,
  states: [{ label: 'UnAssigned', value: 5 }],
  pageId: 0,
  warehouses:
    !isDefaultBostaCourier([...aclMatrix.THREE_PL]) && hub
      ? [{ value: hub._id, label: hub.name }]
      : null
};

export const routesState = [
  { label: 'Drafted', value: 0 },
  { label: 'Dispatched', value: 1 },
  { label: 'Debriefed', value: 2 },
  { label: 'Done', value: 3 },
  { label: 'Archived', value: 4 },
  { label: 'UnAssigned', value: 5 },
  { label: 'Assigned', value: 6 },
  { label: 'Accepted', value: 7 },
  { label: 'Rejected', value: 8 }
];

export const ROUTE_EXPORT_TYPE = 'exportRoutes';
