import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getPO, getPOSerialsStatus } from 'services/fulfillment';
import { SllrWrapper } from 'contexts/sllr.context';
import { PO_STATE_IDS } from 'constants/fulfillment';
import {
  getViewPOContentForRendering,
  renderPOName,
  goToPOsList
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import PurchaseOrder from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/PurchaseOrder';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Chevron-left.svg';

import './ViewPO.less';

const ViewPO = ({
  intl,
  match: {
    params: { id }
  }
}) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [products, setProducts] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [isSerialNumbersGenerated, setIsSerialNumbersGenerated] =
    useState(false);
  const [clickedLineData, setClickedLineData] = useState(null);

  const { QUALITY_CHECKED, READY_TO_PUTAWAY, PARTIALLY_PUTAWAY, COMPLETED } =
    PO_STATE_IDS;
  let timer;

  const fetchPO = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await getPO(id);
      const { lines, state, serialsGenerated, serialsCount } =
        data.purchaseOrder;
      setPurchaseOrder(data.purchaseOrder);
      setProducts(lines);
      if (COMPLETED) {
        setIsSerialNumbersGenerated(true);
      } else if (
        [QUALITY_CHECKED, READY_TO_PUTAWAY, PARTIALLY_PUTAWAY].includes(
          state?.stateId
        )
      ) {
        if (serialsGenerated) {
          setIsSerialNumbersGenerated(true);
        } else {
          checkPOSerialsStatusContinuously(serialsCount);
        }
      }
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const checkPOSerialsStatus = async (serialsCount) => {
    try {
      const { status = {} } = await getPOSerialsStatus(id);
      if (status.serialsGenerated) {
        setIsSerialNumbersGenerated(true);
        clearInterval(timer);
        notify(
          intl.formatMessage(
            {
              id: 'fulfillment_inbound.quality_check.serials_generate_success_msg'
            },
            {
              count: serialsCount
            }
          ),
          'success'
        );
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const { actions, productsTableColumns, renderTag } =
    getViewPOContentForRendering({
      poId: id,
      stateId: purchaseOrder.state?.stateId,
      products,
      isLoading,
      setProducts,
      isSerialNumbersGenerated,
      clickedLineData,
      setClickedLineData,
      setIsLoading
    });

  const checkPOSerialsStatusContinuously = (serialsCount) => {
    timer = setInterval(() => {
      checkPOSerialsStatus(serialsCount);
    }, 5000);
  };

  useEffect(() => {
    fetchPO();

    return () => clearInterval(timer);
  }, []);

  return (
    <PurchaseOrder
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      purchaseOrder={purchaseOrder}
      products={products}
      setProducts={setProducts}
      selectedBusiness={selectedBusiness}
      setSelectedBusiness={setSelectedBusiness}
      productsTableColumns={productsTableColumns}
      headerProps={{
        icon: LeftArrowIcon,
        iconOnClick: goToPOsList,
        title: renderPOName(id),
        renderTag,
        actions
      }}
      isBusinessEditDisabled
      hideSelectProductBtn
    />
  );
};

export default withRouter(injectIntl(ViewPO));
