import { injectIntl } from 'react-intl';
import { Form, Input, Select, DatePicker } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { HubsContext } from 'contexts/hub.context';
import { OFDDeliveriesExport, getHubStars } from 'services/hubs';
import {
  OFD_ATTEMPT_STATES,
  OFD_ATTEMPT_TYPES,
  OFD_FALSE_KEYS_TO_REMOVE,
  OFD_TABLE_LIMIT
} from 'constants/dispatching';
import {
  cleanEmptyString,
  isAdminGroupUser,
  isUserAuthorized
} from 'utils/helpers';
import { getCurrentUserHubInfo } from 'utils/hubs';
import {
  INTEGRATION_HUB,
  LAST_MILE_HUB,
  SORTING_STATION_HUB
} from 'constants/hubs';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import SearchCheckbox from 'containers/Deliveries/DeliveriesSearch/components/SearchCheckbox/SearchCheckbox';
import InternationalOrdersTag from 'components/InternationalOrders/components/InternationalOrdersTag/InternationalOrdersTag';
import { notify } from 'components/Notify/Notify';

import './OFDSearchFilters.less';

const OFDSearchFilters = ({ intl, setAppliedFilters, setGetSuccessRate }) => {
  const [allStars, setAllStars] = useState([]);
  const [selectedHubId, setSelectedHubId] = useState(null);
  const [availableHubs, setAvailableHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const trackingNumbers = Form.useWatch('trackingNumbers', form);

  const formRef = useRef();

  const { scopedHubs } = useContext(HubsContext);

  useEffect(() => {
    selectedHubId && getStars();
  }, [selectedHubId]);

  useEffect(() => {
    setAvailableHubs(
      scopedHubs.filter((hub) =>
        [LAST_MILE_HUB, INTEGRATION_HUB, SORTING_STATION_HUB].includes(hub.type)
      )
    );
  }, [scopedHubs]);

  // TODO: REFACTOR DEFAULT HUB ASSIGNING
  useEffect(() => {
    const currentUserHub = getCurrentUserHubInfo();
    if (!isAdminGroupUser()) {
      if (currentUserHub) {
        const hubId = availableHubs.find(
          (hub) => hub._id === currentUserHub._id
        )?._id;
        if (hubId) {
          setSelectedHubId(hubId);
          formRef.current.setFieldsValue({
            hubId
          });
        }
      }
    } else {
      if (scopedHubs.length === 1 && scopedHubs[0].type === LAST_MILE_HUB) {
        formRef.current.setFieldsValue({
          hubId: scopedHubs[0]._id
        });
        setSelectedHubId(scopedHubs[0]._id);
      } else if (currentUserHub) {
        const hubId = availableHubs.find(
          (hub) => hub._id === currentUserHub._id
        )?._id;
        if (hubId) {
          setSelectedHubId(hubId);
          formRef.current.setFieldsValue({
            hubId
          });
        }
      }
    }
  }, [scopedHubs, availableHubs]);

  const getStars = async () => {
    try {
      const { message } = await getHubStars(selectedHubId);

      const starsData = message.map((star) => ({
        label: `${star.profile?.firstName} ${star.profile?.lastName}`,
        value: star._id
      }));

      setAllStars(starsData);
    } catch (error) {
      notify(error.message);
    }
  };

  const formatPayload = (values) => {
    const payload = {
      ...values,
      ...(values.trackingNumbers && {
        trackingNumbers: trackingNumbers?.trim().split(/[, ]+/).join(',')
      }),
      ...(values.ofdAt && {
        ofdAtStart: dayjs.utc(values.ofdAt[0]).startOf('day').format(),
        ofdAtEnd: dayjs.utc(values.ofdAt[1]).endOf('day').format()
      })
    };

    OFD_FALSE_KEYS_TO_REMOVE.forEach((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });

    delete payload.ofdAt;

    return payload;
  };

  const onFinish = (values) => {
    const payload = {
      ...formatPayload(values),
      pageNumber: 1,
      pageLimit: OFD_TABLE_LIMIT
    };

    setGetSuccessRate(true);
    setAppliedFilters(payload);
  };

  const handleHubChange = (value) => {
    formRef.current.resetFields(['starId']);
    setSelectedHubId(value);
  };

  const handleClear = () => {
    formRef.current.resetFields();
  };

  const handleExport = async () => {
    setIsLoading(true);

    try {
      await formRef.current.validateFields();
      const payload = formatPayload(formRef.current.getFieldsValue());
      await OFDDeliveriesExport(cleanEmptyString(payload));
      notify(
        intl.formatMessage({
          id: 'monitor_hub_operations.common.export_successful'
        }),
        'success'
      );
    } catch (error) {
      if (error.errorFields) {
        setIsLoading(false);
        return;
      }

      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Form onFinish={onFinish} ref={formRef} form={form}>
        <div className="br-ofd-search-filters__row">
          <Form.Item
            name="ofdAt"
            rules={[{ required: !trackingNumbers }]}
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.ofd_at_label'
            })}
          >
            <DatePicker.RangePicker
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
          <Form.Item
            name="trackingNumbers"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.tracking_number'
            })}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.filters.tracking_number_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            name="hubId"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.hub_label'
            })}
          >
            <Select
              options={availableHubs}
              showSearch
              optionFilterProp="name"
              onChange={handleHubChange}
              fieldNames={{ label: 'name', value: '_id' }}
              allowClear
              placeholder={intl.formatMessage({ id: 'common.select' })}
            />
          </Form.Item>
        </div>
        <div className="br-ofd-search-filters__row">
          {isUserAuthorized(aclMatrix.BUSINESSES, [
            ACL_MATRIX.BUSINESSES_LIST
          ]) && (
            <Form.Item
              name="businessId"
              label={intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.filters.business_label'
              })}
            >
              <BusinessSelector
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
          )}

          <Form.Item
            name="starId"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.star_label'
            })}
          >
            <Select
              options={allStars}
              disabled={!selectedHubId}
              showSearch
              optionFilterProp="label"
              allowClear
              placeholder={intl.formatMessage({ id: 'common.select' })}
            />
          </Form.Item>
          <Form.Item
            name="attemptState"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.attempt_state_label'
            })}
          >
            <Select
              options={OFD_ATTEMPT_STATES}
              allowClear
              placeholder={intl.formatMessage({ id: 'common.select' })}
            />
          </Form.Item>
        </div>
        <div className="br-ofd-search-filters__row">
          <Form.Item
            name="type"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.attempt_type'
            })}
          >
            <Select
              options={OFD_ATTEMPT_TYPES}
              allowClear
              placeholder={intl.formatMessage({ id: 'common.select' })}
            />
          </Form.Item>
        </div>
        <div className="br-ofd-search-filters__row">
          <SearchCheckbox
            name="internationalOrders"
            extraContent={<InternationalOrdersTag />}
            label={intl.formatMessage({
              id: 'deliveries.search_filters.international_orders'
            })}
          />
          <SearchCheckbox
            name="isWhatsAppFake"
            label={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.filters.fake_exception'
            })}
          />
        </div>
        <div className="br-ofd-search-filters">
          <BRButton
            type="primary"
            htmlType="submit"
            label={intl.formatMessage({ id: 'common.search' })}
          />
          <BRButton
            label={intl.formatMessage({ id: 'common.clear' })}
            onClick={handleClear}
          />
          <BRButton
            label={intl.formatMessage({ id: 'common.export' })}
            onClick={handleExport}
          />
        </div>
      </Form>
    </LoadingWrapper>
  );
};

export default injectIntl(OFDSearchFilters);
