import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { Button, Form, Input, Select, Divider } from 'antd';

import { COUNTRIES } from 'constants/country-data';
import { fetchCitiesbyCountryId } from 'services/cities';
import { createVendor, getVendor, editVendor } from 'services/vendor';
import { phoneNumberRule, emailRule } from 'utils/forms';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './CreateEditVender.less';
import { intl } from 'components/IntlWrapper/IntlWrapper';
import { getHubs } from 'services/hubs';
const CreateEditVender = ({
  history,
  match: {
    params: { id: vendorID }
  }
}) => {
  const [allHubs, setAllHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [countrySellected, setCountrySellected] = useState(null);
  const formRef = useRef('');
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();

      setAllHubs(result);
      if (vendorID) {
        await handleEditVender();
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleEditVender = async () => {
    try {
      const message = await getVendor(vendorID);
      await handleCountryChange(message.country._id, false);
      formRef.current.setFieldsValue({
        companyName: message.companyName,
        vendorEmail: message.admins[0].email,
        phone: message.phone,
        address: message.address,
        country: message.country._id,
        servingCities: message.servingCities.map((city) => city._id),
        servingHubs: message.servingHubs.map((hub) => hub._id)
      });
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnFinish = async (values) => {
    setIsLoading(true);
    const { companyName, address, phone, vendorEmail } = values;
    try {
      const payload = {
        companyName,
        vendorEmail,
        phone,
        address,
        countryId: values.country,
        servingCityIds: values.servingCities,
        servingHubIds: values.servingHubs
      };
      if (vendorID) {
        await editVendor(vendorID, payload);
        notify(`Vendor edited successfully`, 'success');
      } else {
        await createVendor(payload);
        notify(`Vendor created successfully`, 'success');
      }
      history.push('/stars/vendors');
    } catch (e) {
      notify(e.message);
    }
    setIsLoading(false);
  };

  const handleCountryChange = async (countryId, resetFields = true) => {
    setIsLoading(true);
    if (countryId) {
      try {
        setCountrySellected(countryId);
        const cities = await fetchCitiesbyCountryId(countryId);
        const { result } = await getHubs({ countryId });
        setCities(cities || []);
        setAllHubs(result || []);
        if (resetFields) {
          formRef.current.setFieldsValue({
            phone: '',
            servingCities: [],
            servingHubs: []
          });
        }
      } catch (error) {
        notify(error.message);
        setCities([]);
        setCountrySellected(null);
      }
    } else {
      setCities([]);
      setCountrySellected(null);
    }
    setIsLoading(false);
  };

  return (
    <Container
      header={<BRHeader title={vendorID ? 'Edit Vendor' : 'Create Vendor'} />}
      content={
        <div>
          <LoadingWrapper loading={isLoading}>
            <Form
              scrollToFirstError
              name="createEditVendorForm"
              onFinish={handleOnFinish}
              ref={formRef}
            >
              <div className="br-create-vendor__form-row">
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  className="br-form-row__half-width"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter company name" />
                </Form.Item>
                <Form.Item
                  name="vendorEmail"
                  label="Vendor Email"
                  rules={[
                    { required: true },
                    emailRule(
                      intl.formatMessage({
                        id: 'form.email_not_valid'
                      })
                    )
                  ]}
                >
                  <Input placeholder="Enter bosta email" disabled={vendorID} />
                </Form.Item>
              </div>
              <div className="br-create-vendor__form-row">
                <Form.Item
                  name="phone"
                  label="Phone"
                  className="br-form-row__half-width"
                  rules={[
                    {
                      required: true
                    },
                    phoneNumberRule({
                      message: intl.formatMessage({
                        id: 'form.phone_not_valid'
                      }),
                      internationlNumbers: true,
                      country: COUNTRIES.find(
                        (country) => country.id === countrySellected
                      )
                    })
                  ]}
                >
                  <Input
                    placeholder="Enter phone number"
                    disabled={!countrySellected}
                  />
                </Form.Item>
              </div>
              <Divider className="br-create-vendor__divider" />
              <div className="br-create-vendor__form-row">
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: true }]}
                >
                  <Select
                    // mode="multiple"
                    showSearch
                    placeholder="Select Country"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    onChange={handleCountryChange}
                    disabled={vendorID}
                  >
                    {COUNTRIES?.map((COUNTRY) => (
                      <Select.Option key={COUNTRY.id} value={COUNTRY.id}>
                        {COUNTRY.displayName.split('+')[0]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="servingCities"
                  label="Serving Cities"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select Cities"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {cities?.map((city) => (
                      <Select.Option key={city._id} value={city._id}>
                        {city.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="br-create-vendor__form-row">
                <Form.Item
                  name="servingHubs"
                  label="Serving Hubs"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select hubs"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {allHubs?.map((hub) => (
                      <Select.Option key={hub._id} value={hub._id}>
                        {hub.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={'address'}
                  label="Address First Line"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter first line" />
                </Form.Item>
              </div>
              <Divider className="br-create-vendor__divider" />
            </Form>

            <Button
              type="primary"
              htmlType="submit"
              form="createEditVendorForm"
              className="br-create-vendor__action-button"
            >
              {vendorID ? 'Edit' : 'Create'}
            </Button>
          </LoadingWrapper>
        </div>
      }
    />
  );
};

export default withRouter(CreateEditVender);
