import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import { createFulfillmentLocation } from 'services/fulfillment-locations';
import {
  FULFILLMENT_LOCATION_LEVELS_KEYS,
  getConcatenatedLocationName,
  getLocationURL
} from 'constants/fulfillment-locations';
import { openModal } from 'utils/modal';

import BRButton from 'components/BRButton/BRButton';
import LocationAddressCard from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/CreateLocationModal/components/LocationAddressCard/LocationAddressCard';
import LocationForm from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/CreateLocationModal/components/LocationForm/LocationForm';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

import './CreateLocationModal.less';

const CreateLocationModal = ({
  intl,
  close,
  okText,
  onOk,
  currentLevel,
  printLocations,
  reloadData,
  ...restProps
}) => {
  const { ZONE, AISLE, RACK, SHELF, TOTE } =
    FULFILLMENT_LOCATION_LEVELS_KEYS || {};

  const [selectedLevel, setSelectedLevel] = useState(ZONE);
  const [locationName, setLocationName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedAisle, setSelectedAisle] = useState(null);
  const [selectedRack, setSelectedRack] = useState(null);
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [newLastLevelValue, setNewLastLevelValue] = useState('');

  const [form] = Form.useForm();

  const isZone = selectedLevel === ZONE;

  const onCancel = () => {
    close();
  };

  const handleCreateLocation = async () => {
    setIsLoading(true);

    const selectedLevelPayload = {
      [AISLE]: {
        zoneId: selectedZone?.id
      },
      [RACK]: {
        aisleId: selectedAisle?.id
      },
      [SHELF]: {
        rackId: selectedRack?.id
      },
      [TOTE]: {
        shelfId: selectedShelf?.id
      }
    };

    const isTote = selectedLevel === TOTE;

    try {
      let payload = {
        type: selectedLevel
      };

      if (isZone) {
        payload.label = locationName;
      } else {
        payload.label = newLastLevelValue;
        payload = {
          ...payload,
          ...selectedLevelPayload[selectedLevel]
        };
      }
      const { data } = await createFulfillmentLocation(payload);
      await reloadData();
      onCancel();
      openModal(SuccessModal, {
        title: intl.formatMessage(
          {
            id: 'fulfillment_locations.create_location_modal.success_modal.title'
          },
          {
            name: isZone ? (
              <span className="br-location-name">{locationName}</span>
            ) : (
              <div>
                <span className="br-zone-name">{selectedZone?.label}/</span>
                {locationName}
                {locationName && newLastLevelValue && '-'}
                <span className="br-location-name">{newLastLevelValue}</span>
              </div>
            ),
            level: intl
              .formatMessage({
                id: `fulfillment_locations.location_levels.${selectedLevel}`
              })
              ?.toLowerCase()
          }
        ),
        ...(isTote
          ? {
              confirmButtonLabel: intl.formatMessage({
                id: 'fulfillment_locations.create_location_modal.success_modal.print_label'
              }),
              confirmButtonPrefixIcon: <PrinterIcon />,
              modalAction: () => {
                printLocations(selectedShelf?.id);
              }
            }
          : {
              confirmButtonLabel: intl.formatMessage({
                id: 'fulfillment_locations.create_location_modal.success_modal.go_to_location'
              }),
              modalAction: () => {
                window.open(
                  getLocationURL({
                    zoneId: selectedZone?.id,
                    aisleId: selectedAisle?.id,
                    rackId: selectedRack?.id,
                    shelfId: selectedShelf?.id,
                    lastLevelId: data[selectedLevel]?.id
                  }),
                  '_self'
                );
              }
            })
      });
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const renderFooter = () => (
    <>
      <BRButton
        label={intl.formatMessage({
          id: 'common.cancel'
        })}
        onClick={onCancel}
        disabled={isLoading}
      />
      <BRButton
        type="primary"
        label={intl.formatMessage(
          {
            id: 'fulfillment_locations.create_location_modal.create'
          },
          {
            level: intl.formatMessage({
              id: `fulfillment_locations.location_levels.${selectedLevel}`
            })
          }
        )}
        disabled={isLoading || (isZone ? !locationName : !newLastLevelValue)}
        onClick={handleCreateLocation}
      />
    </>
  );

  useEffect(() => {
    setLocationName(
      getConcatenatedLocationName({
        aisle: selectedAisle?.label,
        rack: selectedRack?.label,
        shelf: selectedShelf?.label
      })
    );
  }, [selectedAisle?.label, selectedRack?.label, selectedShelf?.label]);

  useEffect(() => {
    setLocationName('');
    setNewLastLevelValue(null);
    setSelectedZone(null);
    setSelectedAisle(null);
    setSelectedRack(null);
    setSelectedShelf(null);
    form.setFieldsValue({
      zone: null,
      aisle: null,
      rack: null,
      shelf: null
    });
  }, [selectedLevel]);

  useEffect(() => {
    setNewLastLevelValue(null);
    setSelectedAisle(null);
    setSelectedRack(null);
    setSelectedShelf(null);
    form.setFieldsValue({
      aisle: null,
      rack: null,
      shelf: null
    });
  }, [selectedZone]);

  useEffect(() => {
    setNewLastLevelValue(null);
    setSelectedRack(null);
    setSelectedShelf(null);
    form.setFieldsValue({
      rack: null,
      shelf: null
    });
  }, [selectedAisle]);

  useEffect(() => {
    setNewLastLevelValue(null);
    setSelectedShelf(null);
    form.setFieldsValue({
      shelf: null
    });
  }, [selectedRack]);

  useEffect(() => {
    setNewLastLevelValue(null);
  }, [selectedShelf]);

  return (
    <Modal
      wrapClassName="br-fulfillment-create-location-modal"
      title={intl.formatMessage({
        id: 'fulfillment_locations.create_location_modal.title'
      })}
      footer={renderFooter()}
      width={null}
      {...restProps}
    >
      <LoadingWrapper loading={isLoading}>
        <LocationAddressCard
          selectedZoneName={selectedZone?.label}
          locationName={locationName}
          newLastLevelValue={newLastLevelValue}
        />
        <LocationForm
          form={form}
          isLoading={isLoading}
          setLocationName={setLocationName}
          locationName={locationName}
          currentLevel={currentLevel}
          setIsLoading={setIsLoading}
          selectedZone={selectedZone}
          selectedAisle={selectedAisle}
          selectedRack={selectedRack}
          selectedShelf={selectedShelf}
          newLastLevelValue={newLastLevelValue}
          setSelectedZone={setSelectedZone}
          setSelectedAisle={setSelectedAisle}
          setSelectedRack={setSelectedRack}
          setSelectedShelf={setSelectedShelf}
          setNewLastLevelValue={setNewLastLevelValue}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
        />
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(CreateLocationModal);
