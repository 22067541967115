import { useCallback, useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Menu, Dropdown } from 'antd';
import dayjs from 'dayjs';

import {
  deleteBonusesOrDeductions,
  getStarBonusAndDeduction,
  exportBounusOrDeductionsTable
} from 'services/bonuses-deductions';
import {
  MAP_CATEGORY_TO_STRING,
  MAP_STATE_TO_STRING,
  STATES
} from 'constants/bonuses-deductions';
import {
  formatBigMoneyValues,
  capitalizaeFirstLetterOfSentence,
  isUserAuthorized
} from 'utils/helpers';
import { openModal } from 'utils/modal';
import {
  FLEET_PERFORMANCE,
  AREA_MANAGER,
  FLEET_MANAGER,
  OPERATIONS_MANAGER
} from 'common/aclMatrix';
import { IS_SAUDI } from 'constants/helper';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import BonusAndDeductionFilter from './components/BonusAndDeductionFilter/BonusAndDeductionFilter';
import AddEditBonusesAndDeductions from './components/AddEditBonusesAndDeductions/AddEditBonusesAndDeductions';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import BonusAndDeductionDetails from './components/BonusAndDeductionDetails/BonusAndDeductionDetails';

import './BonusesAndDeductions.less';

const BonusesAndDeductions = ({ intl }) => {
  const [data, setData] = useState({ rows: [], count: 0 });
  const [filterValues, setFilterValues] = useState({});

  const refreshTable = useRef();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTableAfterFilteration = async (filterValue) => {
    await setFilterValues(filterValue);
    refreshTable.current();
  };

  const getAllBonusesAndDeductions = async ({ pageId, pageLimit }) => {
    try {
      const {
        data: { rows, count }
      } = await getStarBonusAndDeduction({
        limit: pageLimit,
        page: pageId,
        ...filterValues
      });
      setData({ rows, count });
      return { list: rows, total: count };
    } catch (error) {
      notify(error.message);
    }
  };

  const BONUS_AND_DEDUCTION_COLUMNS = [
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.star_name' }),
      dataIndex: 'star_name'
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.hub' }),
      dataIndex: 'hub_name'
    },
    ,
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.amount' }),
      dataIndex: 'amount',
      render: (amount) =>
        `${formatBigMoneyValues(amount)} ${IS_SAUDI ? 'SAR' : 'EGP'}`
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.type' }),
      dataIndex: 'type',
      render: (type) => MAP_CATEGORY_TO_STRING[type]
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.reason' }),
      dataIndex: 'reason'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.tracking_number'
      }),
      dataIndex: 'tracking_number'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.pickup_request_id'
      }),
      dataIndex: 'pickup_request_id'
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.added_by' }),
      dataIndex: 'user_name',
      render: (userName, record) =>
        `${capitalizaeFirstLetterOfSentence(
          record?.user_role.replace(/_/g, ' ')
        )} ${record?.user_role ? ':' : ''}  ${userName || ''}`
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.added_at' }),
      dataIndex: 'createdAt',
      render: (createdAt) =>
        createdAt !== 'N/A' ? dayjs(createdAt).format('ddd, D MMM') : 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.approved_by'
      }),
      dataIndex: 'action_taker_name',
      render: (actionTaker, record) =>
        actionTaker && record?.action_taken === 1
          ? `${capitalizaeFirstLetterOfSentence(
              record?.action_taker_role.replace(/_/g, ' ')
            )} ${record?.action_taker_role ? ':' : ''} ${actionTaker || ''}`
          : 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.approved_at'
      }),
      dataIndex: 'updatedAt',
      render: (updatedAt, record) =>
        record?.action_taken === 1 && updatedAt !== 'N/A'
          ? dayjs(updatedAt).format('ddd, D MMM')
          : 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.rejected_by'
      }),
      dataIndex: 'action_taker_name',
      render: (actionTaker, record) =>
        actionTaker && record?.action_taken === 2
          ? `${capitalizaeFirstLetterOfSentence(
              record?.action_taker_role.replace(/_/g, ' ')
            )}${record?.action_taker_role ? ':' : ''} ${actionTaker || ''}`
          : 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bonuses_deductions.columns.rejected_at'
      }),
      dataIndex: 'updatedAt',
      render: (updatedAt, record) =>
        record?.action_taken === 2 && updatedAt !== 'N/A'
          ? dayjs(updatedAt).format('ddd, D MMM')
          : 'N/A'
    },
    {
      title: intl.formatMessage({ id: 'bonuses_deductions.columns.state' }),
      dataIndex: 'state',
      render: (state) => MAP_STATE_TO_STRING[state]
    },
    {
      title: intl.formatMessage({ id: 'pickups.table_columns.action' }),
      width: 50,
      render: (_, record) => {
        return (
          <Dropdown
            overlay={() => actionsMenu(record)}
            placement="bottomRight"
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
            getPopupContainer={(trigger) => trigger.parentElement.parentElement}
          >
            <div className="br-bonus-and-deductions__table-actions">. . .</div>
          </Dropdown>
        );
      }
    }
  ];

  const handleConfirmDelete = async (item) => {
    try {
      await deleteBonusesOrDeductions(item.id);
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleRemoveBonusAndDeduction = (item) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo?._id !== item.user_id) {
      openModal(BRConfirmationModal, {
        title: intl.formatMessage(
          {
            id: 'bonuses_deductions.remove.title'
          },
          { type: MAP_CATEGORY_TO_STRING[item.type] }
        ),
        message: intl.formatMessage(
          {
            id: 'bonuses_deductions.remove.error_message'
          },
          { type: MAP_CATEGORY_TO_STRING[item.type].toLowerCase() }
        ),
        onConfirm: () => {},
        confirmButtonText: intl.formatMessage({
          id: 'bonuses_deductions.remove.ok'
        }),
        hideCancel: true
      });
      return;
    }
    openModal(BRConfirmationModal, {
      title: intl.formatMessage(
        {
          id: 'bonuses_deductions.remove.title'
        },
        { type: MAP_CATEGORY_TO_STRING[item.type] }
      ),
      message: intl.formatMessage(
        {
          id: 'bonuses_deductions.remove.confirmation_message'
        },
        { type: MAP_CATEGORY_TO_STRING[item.type].toLowerCase() }
      ),
      onConfirm: () => {
        handleConfirmDelete(item);
      }
    });
  };

  const checkRemoveOptionDisable = (item) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (item.state === STATES[0].value) {
      if (
        userInfo?._id === item.user_id &&
        userInfo.roles.includes(FLEET_PERFORMANCE)
      ) {
        return false;
      } else if (
        userInfo?._id !== item.user_id &&
        userInfo.roles.includes(FLEET_PERFORMANCE)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (item.state === STATES[1].value) {
      if (
        (userInfo?._id === item.user_id &&
          userInfo.roles.includes(OPERATIONS_MANAGER)) ||
        userInfo.roles.includes(AREA_MANAGER) ||
        userInfo.roles.includes(FLEET_MANAGER)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const actionsMenu = (item) => {
    return (
      <Menu onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item
          onClick={() =>
            openModal(BonusAndDeductionDetails, {
              values: item,
              updateTable: () => refreshTable.current()
            })
          }
        >
          {intl.formatMessage({
            id: 'bonuses_deductions.columns.actions.view_more'
          })}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            openModal(AddEditBonusesAndDeductions, {
              initialValues: item,
              onSuccess: () => refreshTable.current()
            })
          }
          disabled={item.state !== STATES[0].value}
        >
          {intl.formatMessage({
            id: 'bonuses_deductions.columns.actions.edit'
          })}
        </Menu.Item>
        <Menu.Item
          onClick={() => handleRemoveBonusAndDeduction(item)}
          disabled={checkRemoveOptionDisable(item)}
        >
          {intl.formatMessage({
            id: 'bonuses_deductions.columns.actions.remove'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const handleExportedFile = async () => {
    try {
      const res = await exportBounusOrDeductionsTable(filterValues);
      notify(res.msg, 'success');
    } catch (e) {
      notify(e.message);
    }
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'sidebar.stars'
          })}
        />
      }
      content={
        <div>
          <div className="display-flex align-center justify-content-between">
            <BRContentHeader
              title={intl.formatMessage({
                id: 'bonuses_deductions.view_title'
              })}
              isInternalComponent
            />
            {/* <Button
              type="primary"
              onClick={() => openModal(AddEditBonusesAndDeductions)}
            >
              Add Bonus or Deduction
            </Button> */}
          </div>
          <BonusAndDeductionFilter updateTable={updateTableAfterFilteration} />
          <BRSearchableTable
            title={intl.formatMessage(
              {
                id: 'bonuses_deductions.columns.title'
              },
              {
                count: data?.count
              }
            )}
            listFunction={getAllBonusesAndDeductions}
            shareMethods={acceptMethods}
            columns={BONUS_AND_DEDUCTION_COLUMNS}
            onRowClick={() => {}}
            withOutCheckBoxes
            exportListFileFunction={handleExportedFile}
            showExportIcon={false}
          />
        </div>
      }
    />
  );
};

export default injectIntl(BonusesAndDeductions);
