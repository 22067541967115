import { injectIntl } from 'react-intl';
import { InputNumber } from 'antd';
import sumBy from 'lodash/sumBy';

import { getBusinessProductVariances } from 'services/products';
import { openModal } from 'utils/modal';
import {
  PRODUCTS_SEARCH_OPTIONS_IDS,
  SELECT_PRODUCTS_SEARCH_OPTIONS
} from 'constants/fulfillment-products';
import {
  unselectProduct,
  getSKU
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import SelectProductsModal from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/SelectProductsModal/SelectProductsModal';
import EmptyState from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/EmptyState/EmptyState';
import ImportMultipleProductsModal from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ImportMultipleProductsModal/ImportMultipleProductsModal';
import AddProductBtn from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/AddProductBtn/AddProductBtn';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import QuantityInput from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/QuantityInput/QuantityInput';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as UploadIcon } from 'assets/bosta-icons/Upload.svg';
import { ReactComponent as CloseIcon } from 'assets/imgRevamp/closeIconThin.svg';

import './Products.less';

const Products = ({
  intl,
  selectedBusiness,
  products,
  setProducts,
  columns,
  hideSelectProductBtn = false,
  hideOverlay = false,
  isEditPOPage = false,
  hideUploadSheetBtn = false,
  handleSelectProductsClick
}) => {
  const productsCommonColumns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.columns.product_name'
      }),
      render: ({ productName, productNameAr, productImage, defaultImage }) => (
        <div className="br-product-name-column">
          <ProductImage src={productImage || defaultImage} />
          <span>{productName || productNameAr}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.columns.bo_sku'
      }),
      render: (product) => (
        <span className="br-sku-column">{getSKU(product)}</span>
      )
    }
  ];

  const productsTableColumns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.columns.quantity'
      }),
      render: ({ quantity, ...rest }) => (
        <QuantityInput
          initialValue={quantity}
          productSku={getSKU(rest)}
          setProducts={setProducts}
        />
      )
    },
    {
      width: 20,
      render: (product) => (
        <CloseIcon
          className="close-icon"
          onClick={() =>
            unselectProduct({ productSku: getSKU(product), setProducts })
          }
        />
      )
    }
  ];

  const openUploadSheetModal = () => {
    openModal(ImportMultipleProductsModal, {
      selectedBusiness,
      onSuccess: (list) => {
        setProducts(list);
      }
    });
  };

  const selectProducts = () => {
    openModal(SelectProductsModal, {
      isEditPOPage,
      selectedBusiness,
      products,
      onSuccess: addProductsToPO,
      okText: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.select_products_modal.confirm_btn'
      }),
      getCounts: (selectedProducts) => [
        intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.select_products_modal.products_count'
          },
          {
            count: selectedProducts.length
          }
        ),
        intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.select_products_modal.quantity_count'
          },
          {
            count: sumBy(
              selectedProducts,
              ({ updatedQuantity, quantity }) => updatedQuantity || quantity
            )
          }
        )
      ],
      columns: [
        {
          dataIndex: 'productImage',
          render: (productImage) => <ProductImage src={productImage} />,
          width: 40
        },
        {
          ellipsis: true,
          width: '40%',
          render: ({ productName, productNameAr }) =>
            productName || productNameAr
        },
        {
          width: 104,
          render: (product) => (
            <span className="br-sku-column">{getSKU(product)}</span>
          )
        },
        {
          dataIndex: 'quantity',
          width: 104,
          render: () => <InputNumber defaultValue={0} disabled />
        }
      ],
      listKey: 'variances',
      fetchData: getBusinessProductVariances,
      formatData: (list) =>
        list.map(
          ({
            productName,
            productNameAr,
            optionsString,
            variantDefaultImage,
            id,
            ...rest
          }) => ({
            productName: `${productName || productNameAr}${
              optionsString ? ` - ${optionsString}` : ''
            }`,
            key: getSKU(rest),
            quantity: 1,
            productImage: variantDefaultImage,
            ...rest
          })
        ),
      renderEmptyState: () => (
        <EmptyState
          title={intl.formatMessage(
            {
              id: 'fulfillment_inbound.create_po.products.select_products_modal.empty_state.title'
            },
            {
              businessName: selectedBusiness.name
            }
          )}
          subtitle={intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.select_products_modal.empty_state.subtitle'
          })}
          renderActions={() => <AddProductBtn type="outline" />}
        />
      ),
      showAddProductBtn: true,
      searchOptions: SELECT_PRODUCTS_SEARCH_OPTIONS,
      searchOptionsIds: PRODUCTS_SEARCH_OPTIONS_IDS,
      defaultSearchOptionKey: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
    });
  };

  const renderSelectProductsBtn = () => (
    <BRButton
      type="outline"
      label={intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.empty_state.select_products'
      })}
      prefixIcon={<PlusIcon />}
      disabled={!selectedBusiness}
      onClick={handleSelectProductsClick || selectProducts}
    />
  );

  const renderEmptyState = () => (
    <EmptyState
      title={intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.empty_state.title'
      })}
      renderActions={() => (
        <>
          {renderSelectProductsBtn()}
          {!hideUploadSheetBtn && (
            <>
              <span className="br-po-products__empty-state__or">
                {intl.formatMessage({
                  id: 'fulfillment_inbound.create_po.products.empty_state.or'
                })}
              </span>
              <BRButton
                label={intl.formatMessage({
                  id: 'fulfillment_inbound.create_po.products.empty_state.upload_sheet'
                })}
                prefixIcon={<UploadIcon />}
                disabled={!selectedBusiness}
                onClick={openUploadSheetModal}
              />
            </>
          )}
        </>
      )}
    />
  );

  const addProductsToPO = (list) => {
    setProducts(list);
  };

  return (
    <div className="br-po-products br-po-section">
      {!selectedBusiness && !hideOverlay && <div className="br-po-overlay" />}

      <BRTable
        title={intl.formatMessage({
          id: 'fulfillment_inbound.create_po.products.title'
        })}
        columns={[
          ...productsCommonColumns,
          ...(columns || productsTableColumns)
        ]}
        emptyState={renderEmptyState}
        showExport={false}
        showFilter={false}
        pagination={false}
        footer={!hideSelectProductBtn && renderSelectProductsBtn}
        externalDataSource={products}
        showUpperPart
      />
    </div>
  );
};

export default injectIntl(Products);
