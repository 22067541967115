import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';

export const getFormattedNumber = (number) => {
  if (!number) {
    // return number;
    return 0;
  }

  const locale = getLocaleFromLocalStorage();

  // ar-EG

  let formattedNumber = Number(number).toLocaleString(
    locale === LOCALE.AR ? 'en-US' : 'en-US',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  );
  if (formattedNumber === '-0') {
    formattedNumber = '0';
  }
  return formattedNumber;
};

export const formatBigMoneyValues = (number) => {
  if (!number) {
    return 0;
  } else if (number < 1000) {
    return getFormattedNumber(number);
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  return item ? getFormattedNumber(number / item.value) + item.symbol : '0';
};
