import actionTypes from '../actions/ActionTypes';

const initialState = {
  admin: [],
  uesrSales: [],
  businessInfo: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'BUSINESSES_RECEIVED':
      return {
        ...state,
        businesses: action.payload.result,
        count: action.payload.count,
      };
    case 'SEARCH_BUSINESSES_RECEIVED':
      return {
        ...state,
        businesses: action.payload.result,
        count: action.payload.count,
      };
    case actionTypes.GET_BUSINESS_RECEIVED:
      return { ...state, businessInfo: action.payload };
    case 'BUSINESS_UPDATED':
      return { ...state, businessInfo: undefined };
    case 'GENERATED_API':
      return { ...state, businessAPI: action.payload };
    case actionTypes.CLEAN: {
      return { ...state, businessInfo: undefined };
    }
    case 'STARS_RECEIVED':
      return {
        ...state,
        stars: action.payload.message.stars,
        count: action.payload.message.count,
      };
    case actionTypes.GET_ALL_USERS_ROLES_SALES_RECEIVED:
      return { ...state, uesrSales: { ...action.payload } };
    case actionTypes.GET_ALL_USERS_ROLES_SALES:
      return { ...state, uesrSales: { data: [] } };
    default:
      return state;
  }
}
