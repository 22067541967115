import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Select } from 'antd';

import {
  RETURN_LOCATIONS_OPTIONS,
  RETURN_TYPES
} from 'constants/international-orders';
import { PICKUP_LOCATION_REGIONS } from 'constants/international-pickups';
import { fetchBusinessPickupLocations } from 'services/business';
import { formatOptions } from 'utils';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import BRButton from 'components/BRButton/BRButton';

const { RETURN_ADDRESS, RETURN_TO_ORIGIN, TERMINATE, RETURN_TO_FULFILLMENT } =
  RETURN_TYPES;

const EditReturnLocationModal = ({
  close,
  deliveryDetails,
  onConfirm,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [internationalBusinessLocations, setInternatioanlBusinessLocations] =
    useState([]);
  const [allBusinessLocations, setAllBusinessLocations] = useState([]);

  const intl = useIntl();

  const [form] = Form.useForm();
  const returnOption = Form.useWatch('returnOption', form);

  useEffect(() => {
    if (!allBusinessLocations.length) {
      getBusinessLocations();
    }
  }, []);

  useEffect(() => {
    const { terminateIfFailed, returnAddress } = deliveryDetails || {};

    let activeOption = RETURN_ADDRESS;
    let isLocalLocation;

    if (returnAddress?._id) {
      isLocalLocation = allBusinessLocations.find(
        (location) =>
          location._id === returnAddress.businessLocationId &&
          location?.pickupLocationRegion === PICKUP_LOCATION_REGIONS.LOCAL
      );
    }

    if (isLocalLocation && returnAddress) {
      activeOption = RETURN_TO_ORIGIN;
    } else if (terminateIfFailed) {
      activeOption = TERMINATE;
    } else if (returnAddress?.fulfillmentHub) {
      activeOption = RETURN_TO_FULFILLMENT;
    }

    form.setFieldsValue({
      returnOption: activeOption,
      ...(activeOption === RETURN_ADDRESS &&
        allBusinessLocations.length && {
          businessLocation: !isLocalLocation
            ? returnAddress?.businessLocationId
            : null
        })
    });
  }, [deliveryDetails, allBusinessLocations]);

  const getBusinessLocations = async () => {
    setIsLoading(true);

    try {
      const businessId = deliveryDetails?.sender?._id;
      const { data } = await fetchBusinessPickupLocations(businessId);

      setAllBusinessLocations(data);
      setInternatioanlBusinessLocations(
        data.filter(
          (location) =>
            location.pickupLocationRegion ===
            PICKUP_LOCATION_REGIONS.INTERNATIONAL
        )
      );
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const onFinish = (values) => {
    const { returnOption, businessLocation } = values;

    const payload = {
      [returnOption]: returnOption === RETURN_ADDRESS ? businessLocation : true
    };

    onConfirm(payload);
    close();
  };

  return (
    <Modal
      onCancel={close}
      title={intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.edit_return_location.title'
      })}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({ id: 'common.confirm' })}
            htmlType="submit"
            form="editReturnLocationform"
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <Form form={form} name="editReturnLocationform" onFinish={onFinish}>
          <Form.Item
            name="returnOption"
            label={intl.formatMessage({
              id: 'international_orders.deliveries.delivery_details.edit_return_location.return_option'
            })}
          >
            <Select options={RETURN_LOCATIONS_OPTIONS(deliveryDetails)} />
          </Form.Item>
          {returnOption === RETURN_ADDRESS && (
            <Form.Item
              name="businessLocation"
              label={intl.formatMessage({
                id: 'international_orders.deliveries.delivery_details.edit_return_location.return_locations'
              })}
            >
              <Select
                options={formatOptions(
                  '_id',
                  'locationName',
                  internationalBusinessLocations
                )}
              />
            </Form.Item>
          )}
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default EditReturnLocationModal;
