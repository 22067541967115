import { injectIntl } from 'react-intl';

import './LocationAddressCard.less';

const LocationAddressCard = ({
  intl,
  selectedZoneName,
  locationName,
  newLastLevelValue
}) => {
  return (
    <div className="br-fulfillment-location-address-card">
      {selectedZoneName && (
        <div className="br-fulfillment-location-address-card__header">
          {selectedZoneName}
        </div>
      )}
      <div className="br-fulfillment-location-address-card__content">
        <div className="br-fulfillment-location-address-card__title">
          {locationName || newLastLevelValue ? (
            <span className="br-fulfillment-location-address-card__text">
              {locationName}
              {locationName && newLastLevelValue && '-'}

              <span className="br-fulfillment-location-address-card__colored-text">
                {newLastLevelValue}
              </span>
            </span>
          ) : (
            <span className="br-fulfillment-location-address-card__empty-text">
              ----
            </span>
          )}
        </div>
        <div className="br-fulfillment-location-address-card__label">
          {intl.formatMessage({
            id: 'fulfillment_locations.create_location_modal.location_address'
          })}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(LocationAddressCard);
