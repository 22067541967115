import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Dropdown, Menu } from 'antd';
import isEmpty from 'lodash/isEmpty';

import {
  INTERNATIONAL_PICKUPS_BULK_ACTIONS,
  INTERNATIONAL_PICKUPS_COLUMNS,
  INTERNATIONAL_PICKUPS_FILTERS,
  INTERNATIONAL_PICKUPS_STATES,
  INTERNATIONAL_PICKUP_DATE_FORMAT,
  INTERNATIONAL_PICKUP_STATES_LABEL,
  INTERNATIONAL_PICKUP_TAG_COLORS,
  PICKUPS_COUNTS_MAPPING
} from 'constants/international-pickups';
import { formatAddress } from 'constants/pickups';
import { STAR_AVAILABILITY_STATE } from 'constants/stars';
import { exportPickups, fetchPickups, getPickupsCount } from 'services/pickups';
import { assignPickupsToRoute } from 'services/routes-form';
import { fetchStars } from 'services/live-ops-priorities';
import { unassignPickupFromCourier } from 'services/international-orders';
import { formatOptions } from 'utils';
import { openModal } from 'utils/modal';
import { downloadAsXlsx } from 'utils/download';
import { checkSmartDate, isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import BRCardsFilter from 'components/BRCardsFilter/BRCardsFilter';
import BRTable from 'components/BRTable/BRTable';
import InternationalPickupsSearch from './components/InternationalPickupsSearch/InternationalPickupsSearch';
import InternationalPickupDetails from './components/InternationalPickupDetails/InternationalPickupDetails';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import BRTag from 'components/BRTag/BRTag';
import BRButton from 'components/BRButton/BRButton';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as BulkyIcon } from 'assets/bosta-icons/Bulky.svg';
import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';
import { ReactComponent as CornerUpLeft } from 'assets/bosta-icons/corner-up-left.svg';

import './InternationalPickups.less';

const InternationalPickups = () => {
  const [pickupsCount, setPickupsCounter] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleRowSelection, setToggleRowSelection] = useState(false);
  const [stars, setStars] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [bulkActionType, setBulkActionType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedRowDetails, setClickedRowDetails] = useState({});

  const refreshTable = useRef('');

  const intl = useIntl();

  const canCreateRoute = isUserAuthorized(
    aclMatrix.DELIVERIES,
    [ACL_MATRIX.ROUTES_CREATE],
    {
      countries: COUNTRIES_CODE_NAME.AE
    }
  );

  useEffect(() => {
    getStars();
  }, []);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleFilterPickups = (filterValues) => {
    refreshTable.current({
      pageNumber: 1,
      filterValues: { state: selectedFilters.state, ...filterValues }
    });
  };

  const handleQuickFilterChange = (value) => {
    const stateFilter = { state: value ? value : undefined };

    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...stateFilter }));
    refreshTable.current({
      pageNumber: 1,
      filterValues: { ...selectedFilters, ...stateFilter }
    });
  };

  const formatPickupsData = ({ pickups, stars }) =>
    pickups.map((pickup) => ({
      ...pickup,
      key: pickup.puid,
      puidButton: (
        <div
          className="br-international-pickups__puid body-medium"
          onClick={() => handleShowPickupDetails(pickup)}
        >
          {pickup.puid}
        </div>
      ),
      businessName: pickup.business.name,
      pickupAddress: formatAddress(pickup.business.address),
      city: pickup.business.address?.city?.name,
      courier: pickup.star
        ? pickup.star.name
        : intl.formatMessage({ id: 'common.empty_field' }),
      state_tag: (
        <BRTag
          showDot={pickup.state === INTERNATIONAL_PICKUPS_STATES.REQUESTED}
          color={INTERNATIONAL_PICKUP_TAG_COLORS[pickup.state] || 'gray'}
        >
          {INTERNATIONAL_PICKUP_STATES_LABEL[pickup.state] || pickup.state}
        </BRTag>
      ),
      scheduledAt: checkSmartDate(
        pickup.scheduledDate,
        INTERNATIONAL_PICKUP_DATE_FORMAT,
        true
      ),
      actions: actionsDropdown({ pickup, stars })
    }));

  const menuItems = ({ pickup, stars }) => {
    return (
      <Menu
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
        }}
      >
        <Menu.Item
          disabled={
            ![
              INTERNATIONAL_PICKUPS_STATES.ASSIGNED,
              INTERNATIONAL_PICKUPS_STATES.REQUESTED
            ].includes(pickup.state) || !canCreateRoute
          }
          onClick={() =>
            pickup.state === INTERNATIONAL_PICKUPS_STATES.REQUESTED
              ? handleAssignToCourierClick([pickup.puid], stars)
              : handleUnassignCourierClick({ pickupRequestIds: [pickup.puid] })
          }
        >
          <div className="br-international-pickup__unassign-action">
            {pickup.state === INTERNATIONAL_PICKUPS_STATES.REQUESTED ? (
              <>
                <BulkyIcon />
                {intl.formatMessage({
                  id: 'international_orders.pickups.assign_to_courier'
                })}
              </>
            ) : (
              <>
                <CornerUpLeft />
                {intl.formatMessage({
                  id: 'international_orders.pickups.unassign'
                })}
              </>
            )}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const actionsDropdown = ({ pickup, stars }) => {
    return (
      <Dropdown
        overlay={menuItems({ pickup, stars })}
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
        }
      >
        <BRButton
          type="table-actions"
          className="button-sm"
          suffixIcon={<Actions />}
        />
      </Dropdown>
    );
  };

  const getStars = async () => {
    let starsList;

    if (!stars.length) {
      try {
        const { message } = await fetchStars();
        starsList = formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          message?.stars || []
        );
        setStars(starsList);
      } catch (error) {
        notify(error.message);
      }
    } else {
      starsList = stars;
    }

    return starsList;
  };

  const getPickups = async ({ page, limit, filterValues }) => {
    setIsLoading(true);
    try {
      const selectedFilterValues = {
        state: selectedFilters.state,
        ...filterValues
      };
      const payload = {
        pageId: page,
        pageLimit: limit,
        ...selectedFilterValues
      };

      const [{ result }, { count }] = await Promise.all([
        fetchPickups(payload),
        getPickupsCount(selectedFilterValues)
      ]);
      const starsList = await getStars();

      setPickupsCounter(count);
      setSelectedFilters({ state: selectedFilters.state, ...filterValues });
      setIsLoading(false);
      return {
        list: formatPickupsData({
          pickups: result?.pickups || [],
          stars: starsList
        }),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleAssignToCourier = async ({ starId, pickupRequestIds }) => {
    try {
      const payload = {
        pickupRequestIds
      };

      await assignPickupsToRoute({ starId, payload });
      refreshTable.current({ pageNumber: 1 });
      notify(
        intl.formatMessage({
          id: 'international_orders.pickups.pickup_assign_success'
        }),
        'success'
      );
      handleResetSelection();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleAssignToCourierClick = (pickupRequestIds, starsList) => {
    const starData = starsList || stars;

    openModal(BRDropdownModal, {
      wrapClassName: 'br-international-pickup-assign__modal',
      title: intl.formatMessage(
        { id: 'international_orders.pickups.assign_courier_title' },
        {
          count: selectedRows.length || 1
        }
      ),
      subtitle: intl.formatMessage({
        id: 'international_orders.pickups.choose_courier'
      }),
      dropdownData: starData.filter((star) =>
        [STAR_AVAILABILITY_STATE.ONLINE, STAR_AVAILABILITY_STATE.BUSY].includes(
          star.starInfo?.availabilityState
        )
      ),
      confirmBtnText: intl.formatMessage({ id: 'common.confirm' }),
      onConfirm: (starId) =>
        handleAssignToCourier({
          starId,
          pickupRequestIds
        })
    });
  };

  const handleExportPickups = async () => {
    try {
      const payload = {
        ...(selectedRows.length
          ? { pickupRequestId: selectedRows.join(',') }
          : selectedFilters)
      };

      const data = await exportPickups(payload);

      notify(
        intl.formatMessage({
          id: 'international_orders.pickups.export_success'
        }),
        'success'
      );
      downloadAsXlsx(data, 'Pickups.xlsx');
    } catch (error) {
      notify(error.message);
    }
  };

  const handleUnassignCourier = async (pickupRequestIds) => {
    try {
      await unassignPickupFromCourier({ pickupRequestIds });

      notify(
        intl.formatMessage(
          {
            id: 'international_orders.pickups.unassign_success'
          },
          {
            count: pickupRequestIds.length
          }
        ),
        'success'
      );
      refreshTable.current({ pageNumber: 1 });
    } catch (error) {
      notify(error.message);
    }
  };

  const handleUnassignCourierClick = ({ pickupRequestIds }) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'international_orders.pickups.unassign_confirmation_modal.title'
      }),
      content: intl.formatMessage({
        id: 'international_orders.pickups.unassign_confirmation_modal.content'
      }),
      confirmButtonLabel: intl.formatMessage({
        id: 'international_orders.pickups.unassign_confirmation_modal.confirm_action'
      }),
      cancelButtonLabel: intl.formatMessage({
        id: 'international_orders.pickups.unassign_confirmation_modal.cancel_action'
      }),
      modalAction: () => handleUnassignCourier(pickupRequestIds)
    });
  };

  const handleSelectedRows = (rowKeys, records = []) => {
    if (records.length) {
      const isAssignBulkAction = records?.every(
        (record) => record.state === INTERNATIONAL_PICKUPS_STATES.REQUESTED
      );
      const isUnassignBulkAction = records?.every(
        (record) => record.state === INTERNATIONAL_PICKUPS_STATES.ASSIGNED
      );

      setBulkActionType(
        isAssignBulkAction
          ? INTERNATIONAL_PICKUPS_BULK_ACTIONS.ASSIGN_COURIER
          : isUnassignBulkAction
          ? INTERNATIONAL_PICKUPS_BULK_ACTIONS.UNASSIGN_COURIER
          : null
      );
    } else {
      setBulkActionType(null);
    }

    setSelectedRows(rowKeys);
  };

  const handleResetSelection = () => {
    setToggleRowSelection(!toggleRowSelection);
  };

  const handleOnPickupDetailsClose = () => {
    setClickedRowDetails({});
  };

  const handleShowPickupDetails = (record) => {
    setClickedRowDetails(record);
  };

  const renderTableTitle = () =>
    selectedRows?.length ? (
      <div className="br-international-table-selected-rows-container">
        <span className="br-international-table__title-count">
          <Checkbox indeterminate onChange={handleResetSelection} />
          {intl.formatMessage(
            { id: 'international_orders.pickups.selected' },
            {
              count: selectedRows?.length
            }
          )}
        </span>
        <BRButton
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={handleResetSelection}
        />
      </div>
    ) : (
      intl.formatMessage(
        { id: 'international_orders.pickups.table_title' },
        {
          count: pickupsCount
        }
      )
    );

  const renderTableAction = () =>
    bulkActionType === INTERNATIONAL_PICKUPS_BULK_ACTIONS.ASSIGN_COURIER ? (
      <BRButton
        type="primary"
        label={intl.formatMessage({
          id: 'international_orders.pickups.assign_to_courier'
        })}
        prefixIcon={<BulkyIcon />}
        onClick={() => handleAssignToCourierClick(selectedRows)}
        disabled={!canCreateRoute}
      />
    ) : bulkActionType ===
      INTERNATIONAL_PICKUPS_BULK_ACTIONS.UNASSIGN_COURIER ? (
      <BRButton
        type="primary"
        label={intl.formatMessage({
          id: 'international_orders.pickups.unassign_from_courier'
        })}
        prefixIcon={<BulkyIcon />}
        onClick={() =>
          handleUnassignCourierClick({ pickupRequestIds: selectedRows })
        }
        disabled={!canCreateRoute}
      />
    ) : null;

  // Return Only Active Filter Count.
  const getCounts = () => {
    if (isLoading) {
      return {};
    }

    return {
      [PICKUPS_COUNTS_MAPPING[selectedFilters?.state || 0]]: pickupsCount
    };
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-international-pickups__container">
        <BRCardsFilter
          items={INTERNATIONAL_PICKUPS_FILTERS(getCounts())}
          defaultActive={''}
          onFilterChange={handleQuickFilterChange}
        />
        <InternationalPickupsSearch
          stars={stars}
          handleFilterPickups={handleFilterPickups}
        />
        <div className="br-international-pickups__table">
          <BRTable
            title={renderTableTitle()}
            tableActions={renderTableAction}
            columns={INTERNATIONAL_PICKUPS_COLUMNS}
            listFunction={getPickups}
            showFilter={false}
            allowSelection
            handleSelectedRows={handleSelectedRows}
            resetSelection={toggleRowSelection}
            shareMethods={acceptMethods}
            exportListFileFunction={handleExportPickups}
          />
        </div>
      </div>
      <InternationalPickupDetails
        open={!isEmpty(clickedRowDetails)}
        onClose={handleOnPickupDetailsClose}
        {...clickedRowDetails}
      />
    </LoadingWrapper>
  );
};

export default InternationalPickups;
