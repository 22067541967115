import React from 'react';
import { withRouter } from 'react-router';
import { Select } from 'antd';
import Icon from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { switchCountry } from 'services/auth';
import { isUserAuthorized } from 'utils/helpers';
import { mediaHook } from 'customHooks';
import { ACCOUNTANT, SUPER_ADMIN } from 'common/aclMatrix';
import { openModal } from 'utils/modal';
import { ACL_MATRIX } from 'common/permissions';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import BRButton from 'components/BRButton/BRButton';
import Impersonation from 'components/Impersonation/Impersonation';
import { notify } from 'components/Notify/Notify';

import EgyptFlag from 'assets/imgRevamp/EgyptFlag.svg';
import SaudiArabiaFlag from 'assets/imgRevamp/SaudiArabiaFlag.svg';
import UAEFlag from 'assets/imgRevamp/uae-flag.svg';
import QatarFlag from 'assets/imgRevamp/qatar-flag.svg';
import BackArrow from 'assets/imgRevamp/arrow-left.svg';
import { ReactComponent as Arrow } from 'assets/imgRevamp/Rectangle.svg';
import { ReactComponent as Lock } from 'assets/bosta-icons/lock.svg';

import './BRHeader.less';

const COUNTRIES = [
  { label: 'Egypt', value: 'Egypt', code: 'EG', icon: <img src={EgyptFlag} /> },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    code: 'SA',
    icon: <img src={SaudiArabiaFlag} />
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    code: 'AE',
    icon: <img src={UAEFlag} />
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    code: 'QA',
    icon: <img src={QatarFlag} />
  }
];
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const BRHeader = ({
  title,
  history,
  handleGoBackUrl,
  showBackArrow,
  headerContent,
  mobileScreenSizes: { isMediumMobileScreen }
}) => {
  const intl = useIntl();

  const handleChange = async (value) => {
    try {
      const result = await switchCountry(value);
      history.push('/logout');
    } catch (error) {
      notify(error.message);
    }
  };
  const handleGoBackClick = () => {
    handleGoBackUrl ? handleGoBackUrl() : history.goBack();
  };

  const handleRemoteAccessClick = () => {
    openModal(Impersonation);
  };

  return (
    <div className="br-header">
      {headerContent ? (
        headerContent()
      ) : (
        <>
          {' '}
          <span className="display-flex br-header__title">
            {!!showBackArrow && (
              <img
                role="button"
                onClick={handleGoBackClick}
                alt="Go Back"
                src={BackArrow}
                className="br-header__back-button"
              />
            )}
            <h2>{title}</h2>
          </span>
          <span className="br-header__right-side">
            {isUserAuthorized(
              [SUPER_ADMIN],
              [ACL_MATRIX.USERS_REQUEST_IMPERSONATION]
            ) && (
              <BRButton
                label={intl.formatMessage({
                  id: 'impersonation.remote_access'
                })}
                prefixIcon={<Lock />}
                className="br-header__remote-access-btn button-sm"
                onClick={handleRemoteAccessClick}
              />
            )}
            <Select
              className="br-header__countries-menu"
              dropdownClassName="br-header__countries-dropdown"
              defaultValue={userInfo?.country?.code || 'EG'}
              onChange={handleChange}
              suffixIcon={<Icon component={Arrow} />}
              dropdownMatchSelectWidth={false}
            >
              {isUserAuthorized([SUPER_ADMIN, ACCOUNTANT], undefined, {
                countries: [
                  COUNTRIES_CODE_NAME.EG,
                  COUNTRIES_CODE_NAME.SA,
                  COUNTRIES_CODE_NAME.AE,
                  COUNTRIES_CODE_NAME.QA
                ]
              }) ? (
                COUNTRIES.map((country) => (
                  <Select.Option value={country.code} key={country.code}>
                    {country.icon}
                    <span className="br-header__countries-dropdown-label">
                      {country.label}
                    </span>
                  </Select.Option>
                ))
              ) : userInfo?.country?.code ? (
                COUNTRIES.map(
                  (country) =>
                    userInfo?.country?.code === country?.code && (
                      <Select.Option value={country.code} key={country.code}>
                        {country.icon}
                        {!isMediumMobileScreen && country.label}
                      </Select.Option>
                    )
                )
              ) : (
                <Select.Option
                  value={COUNTRIES[0].code}
                  key={COUNTRIES[0].code}
                >
                  {COUNTRIES[0].icon}
                  {!isMediumMobileScreen && COUNTRIES[0].label}
                </Select.Option>
              )}
            </Select>
            <span className="br-header__user-info">
              <span className="br-header__user-info__name body-medium">
                {`${userInfo?.profile?.firstName || ''} ${
                  userInfo?.profile?.lastName || ''
                }`}
              </span>
              <span className="br-header__user-info__email caption">
                {userInfo?.emails[0]?.address || ''}
              </span>
            </span>
          </span>
        </>
      )}
    </div>
  );
};

export default withRouter(mediaHook(BRHeader));
