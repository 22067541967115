import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  INITIAL_OVERVIEW_SUMMARY,
  OVERVIEW_SUMMARY_OBJ_MAPPING
} from 'constants/dispatching';

import HubSummaryCard from 'components/Dispatching/components/DispatchingOverview/components/HubSummaryCard/HubSummaryCard';
import DispatchingOverviewSummary from 'components/Dispatching/components/DispatchingOverview/components/DispatchingOverviewSummary/DispatchingOverviewSummary';
import { notify } from 'components/Notify/Notify';

import './DispatchingOverview.less';

const DispatchingOverview = ({
  setIsLoading,
  setSelectedHubId,
  fetchData,
  intl,
  pageType
}) => {
  const [overviewSummary, setOverviewSummary] = useState({
    ...INITIAL_OVERVIEW_SUMMARY
  });
  const [overviewData, setOverviewData] = useState([]);

  useEffect(() => {
    getHubDispatchingOverview();
  }, []);

  const getHubDispatchingOverview = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchData();
      setSummaryData(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const sortOverviewData = (data) => {
    return data.sort((a, b) => b.toBe - a.toBe);
  };

  const setSummaryData = (data) => {
    const summaryData = { ...INITIAL_OVERVIEW_SUMMARY };
    const newData = [];

    data.forEach((hubData) => {
      const newObj = { ...INITIAL_OVERVIEW_SUMMARY };
      const hubDataObj = hubData;
      Object.keys(newObj).forEach((key) => {
        const objKey = OVERVIEW_SUMMARY_OBJ_MAPPING[pageType][key];
        const currentValue = hubData[objKey];
        summaryData[key] += currentValue;
        newObj[key] = currentValue;
        delete hubDataObj[objKey];
      });
      newData.push({ ...newObj, ...hubDataObj });
    });
    setOverviewSummary(summaryData);
    setOverviewData(sortOverviewData(newData));
  };

  return (
    <div className="br-dispatching-overview__container">
      <div className="br-dispatching-overview__title">
        {intl.formatMessage({ id: `monitor_hub_operations.${pageType}.overview.title` })}
      </div>
      <DispatchingOverviewSummary
        overviewSummary={overviewSummary}
        pageType={pageType}
      />
      <div className="br-dispatching-overview__hub-cards-container flex-equal-children">
        {overviewData.map((data, index) => (
          <HubSummaryCard
            pageType={pageType}
            key={index}
            hubSummaryData={data}
            setSelectedHubId={setSelectedHubId}
          />
        ))}
      </div>
    </div>
  );
};

export default injectIntl(DispatchingOverview);
