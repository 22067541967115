import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form, Button } from 'antd';
import { withRouter } from 'react-router';

import {
  PASSWORD_EXPIRED_ERROR_CODE,
  SECURITY_TYPES
} from 'constants/Security';
import { login } from 'services/Security';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import { notify } from 'components/Notify/Notify';

import verify_user from 'assets/imgRevamp/verify_user.jpg';

import './VerifyUser.less';

function VerifyUser(props) {
  const { intl, history, location } = props;
  const [userSecurityType, setUserSecurityType] = useState(null);
  useEffect(() => {
    if (location?.state?.response) {
      const { response } = location.state;
      setUserSecurityType(response.data.twoFAType);
    }
  }, []);

  const onFinish = async (values) => {
    const { userEmailPassword, response } = location.state;
    try {
      const result = await login({
        ...userEmailPassword,
        ...values
      });

      const { token, user, refreshToken } = result;

      if (user.roles.length === 1 && user.roles.includes('BUSINESS_ADMIN')) {
        history.push('/401');
        return;
      }
      localStorage.setItem('userInfo', JSON.stringify(user));
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      window.location.reload();
    } catch (error) {
      if (error?.errorCode === PASSWORD_EXPIRED_ERROR_CODE) {
        history.push({
          pathname: '/change-password',
          state: {
            email: userEmailPassword.email
          }
        });
      }
      notify(error.message);
    }
  };
  return (
    <div className="br-signin__verify-user">
      <img src={verify_user} alt="Cannot load content" />
      <div className="br-signin__verify-user__content">
        <BRContentHeader
          title={intl.formatMessage({
            id: 'signin.verify_user.title'
          })}
          subtitle={intl.formatMessage({
            id: `signin.verify_user.subtitle_OTP_${
              userSecurityType === SECURITY_TYPES.SMS
                ? 'sms'
                : userSecurityType === SECURITY_TYPES.EMAIL
                ? 'email'
                : 'app'
            }_type`
          })}
          isInternalComponent
        />
        <Form onFinish={onFinish}>
          <div className="br-form-row">
            <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'signin.verify_user.otp_validation_message'
                  })
                }
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'signin.verify_user.PIN_code_placeholder'
                })}
              />
            </Form.Item>
          </div>
          <div className="br-signin__pin-code-modal__footer">
            <Button onClick={() => history.push('/signin')}>
              {intl.formatMessage({
                id: 'common.back'
              })}
            </Button>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({
                id: 'common.submit'
              })}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default injectIntl(withRouter(VerifyUser));
