import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { debounce } from 'lodash';
import { Table, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { formatOptions, showBill } from '../../../utils';
import { getAllDeliveries } from '../../../actions/DeliveriesActions';
import { setDeliveriesOnShelf } from 'services/deliveries';
import { getHubShelvesCode, getHubShelves } from 'services/hubs';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { VENDOR_MANAGER } from 'common/aclMatrix';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { HubsContext } from 'contexts/hub.context';

import { notify } from 'components/Notify/Notify';

import styles from '../styles.module.scss';

function MoneyDebrief() {
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [selectedHubShelves, setSelectedHubShelves] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [itemsToReceived, setItemsToReceived] = useState([]);
  const [loading, setLoading] = useState(null);
  const [generateShelfCodeLoading, setGenerateShelfCodeLoading] =
    useState(false);
  const [getHubShelvesLoading, setGetHubShelvesLoading] = useState(false);
  const [isHubSelectorDisabled, setIsHubSelectorDisabled] = useState(false);

  const { scopedHubs } = useContext(HubsContext);

  useEffect(() => {
    const currentUserHub = getCurrentUserHubInfo() || {};
    const userHub = scopedHubs.find((hub) => hub._id === currentUserHub._id);

    if (isAdminGroupUser()) {
      scopedHubs.length === 1 &&
        setSelectedHub({
          label: scopedHubs[0].name,
          value: scopedHubs[0]._id,
          ...scopedHubs[0]
        });
    } else {
      const userHub = scopedHubs.find((hub) => hub._id === currentUserHub._id);
      if (userHub) {
        setIsHubSelectorDisabled(!isUserAuthorized([VENDOR_MANAGER]));
        setSelectedHub({ label: userHub.name, value: userHub._id, ...userHub });
      }
    }
  }, [scopedHubs]);

  useEffect(() => {
    const selectedHubId = selectedHub?._id;
    if (selectedHubId) {
      handleGetListHubShelves(selectedHubId);
      setSelectedShelf(null);
    }
  }, [selectedHub]);

  const validatePackageStatus = (item) => {
    const { state, type } = item;
    if (type === 'CASH_COLLECTION') {
      notify('You can not scan a cash collection!', 'error', true);
      return false;
    }
    if (state.value === 'Delivered') {
      notify('The package you scanned is delivered!', 'error', true);
      return false;
    }
    if (state.value === 'Returned to business') {
      notify('The package you scanned is returned!', 'error', true);
      return false;
    }
    if (state.value !== 'Received at warehouse') {
      notify(
        'The package you scanned is not received at warehouse!',
        'error',
        true
      );
      return false;
    }

    return true;
  };
  const changeSearchFor = useCallback(
    debounce(async (value) => {
      setLoading(true);
      const item = itemsToReceived.find((el) => el.trackingNumber === value);
      if (item) {
        notify(
          `This tracking number: ${item.trackingNumber} is already added!`,
          'error',
          true
        );
        setTrackingNumber('');
      } else {
        const { deliveries } = await getAllDeliveries({
          data: { trackingNumbers: value }
        });
        if (deliveries && deliveries.length > 0) {
          if (validatePackageStatus(deliveries[0])) {
            setItemsToReceived((prev) => [...prev, deliveries[0]]);
            setTrackingNumber('');
          }
        } else {
          notify('Invalid tracking number!', 'error', true);
        }
      }
      setLoading(false);
    }, 500),
    [itemsToReceived]
  );

  const handleTrackingChange = (evt) => {
    setTrackingNumber(evt.currentTarget.value.trim());
    if (evt.currentTarget.value.trim().length >= 6) {
      changeSearchFor(evt.currentTarget.value.trim());
    }
  };

  const handleGetListHubShelves = async (hubId) => {
    setGetHubShelvesLoading(true);
    try {
      const { warehouse } = await getHubShelves(hubId);
      if (warehouse?.hasOwnProperty('shelves')) {
        setSelectedHubShelves(warehouse.shelves);
      } else {
        setSelectedHubShelves([]);
        setSelectedShelf(null);
      }
    } catch (err) {
      const message = err?.message;
      notify(message);
    }
    setGetHubShelvesLoading(false);
  };

  const handleEndScanning = async () => {
    try {
      const scannedData = {
        deliveryIds: itemsToReceived.map((el) => el._id).join(','),
        shelfValue: selectedShelf,
        hubId: selectedHub._id
      };
      const { data } = await setDeliveriesOnShelf(scannedData);
      if (data?.failed) {
        data?.reasons?.forEach((el) => {
          notify(`Tracking Number "${el.trackingNumber}" ${el.reason}`);
        });
      } else {
        notify('Operation done successfully', 'success');
        setSelectedShelf(null);
        setTrackingNumber('');
        setItemsToReceived([]);
      }
    } catch (e) {
      const message = e.response?.data || e.message;
      notify(
        Array.isArray(message)
          ? message.map((el) => el.message).join(', ')
          : message
      );
    }
  };

  const handlePrintShelvesBarcode = async () => {
    setGenerateShelfCodeLoading(true);
    try {
      const { data } = await getHubShelvesCode(selectedHub._id);
      if (data) {
        showBill(data);
      } else {
        notify('Operation failed. Please, try again.');
      }
    } catch (err) {
      const message = err?.message;
      notify(message);
    }
    setGenerateShelfCodeLoading(false);
  };

  const removeItem = ({ _id }) => {
    setItemsToReceived((prev) => prev.filter((el) => el._id !== _id));
  };

  return (
    <div className="br-shelves-scan__container">
      <div className={loading ? 'dimmed' : ''}>
        <Form.Row className={styles.formRow}>
          <Form.Group as={Col} sm={4} controlId="hub-control">
            <Form.Label className={styles.formLabel}>Hub</Form.Label>
            <Select
              name="hub"
              value={selectedHub}
              options={formatOptions('_id', 'name', scopedHubs || [])}
              onChange={setSelectedHub}
              noOptionsMessage={() => 'Not found'}
              isDisabled={
                isHubSelectorDisabled ||
                generateShelfCodeLoading ||
                getHubShelvesLoading
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className={styles.formRow}>
          <Form.Group as={Col} sm={4} controlId="hub-control">
            <Form.Label className={styles.formLabel}>Shelf</Form.Label>
            <Select
              name="shelf"
              value={
                selectedShelf && {
                  label: selectedShelf,
                  value: selectedShelf?.replace(/\s+/g, '-').toLowerCase()
                }
              }
              placeholder="Select or scan shelf"
              options={selectedHubShelves.map((shelf) => ({
                label: shelf,
                value: shelf.replace(/\s+/g, '-').toLowerCase()
              }))}
              onChange={({ label }) => {
                setSelectedShelf(label);
              }}
              noOptionsMessage={() => {
                if (!!selectedHubShelves.length) {
                  notify(
                    `Undefined shelf code. Please scan a shelf that belongs to ${selectedHub.name}.`,
                    'error',
                    true
                  );
                }
                return 'Not found';
              }}
              isDisabled={getHubShelvesLoading}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className={`${styles.formRow} display-flex`}>
          <Form.Group as={Col} sm={4} controlId="hub-control">
            <Form.Label className={styles.formLabel}>Scan Package</Form.Label>
            <Form.Control
              type="text"
              name="trackingNumber"
              value={trackingNumber || ''}
              onChange={handleTrackingChange}
            />
          </Form.Group>
        </Form.Row>
        <div
          className={`display-flex justify-content-lg-start ${styles.shelvesScanBtns}`}
        >
          <Button
            onClick={handlePrintShelvesBarcode}
            loading={generateShelfCodeLoading}
            disabled={!selectedHub}
          >
            Print Shelves Barcode
          </Button>
          <Button
            type="primary"
            onClick={handleEndScanning}
            disabled={
              !selectedShelf || itemsToReceived.length === 0 || !selectedHub
            }
          >
            End Scanning
          </Button>
        </div>
        <div className="my-4">{itemsToReceived.length} packages added</div>
        <div>
          <Table
            style={{ overflow: 'auto', width: '100%' }}
            columns={[
              {
                dataIndex: 'trackingNumber',
                title: 'Tracking Number',
                width: '30%'
              },
              { dataIndex: 'type', title: 'Type', width: '30%' },
              { dataIndex: ['cod', 'amount'], title: 'COD', width: '30%' },
              {
                dataIndex: 'actions',
                title: 'actions',
                align: 'center',
                width: '10%'
              }
            ]}
            dataSource={itemsToReceived.map((el) => ({
              ...el,
              actions: (
                <DeleteOutlined
                  title="Remove Item"
                  onClick={() => {
                    removeItem(el);
                  }}
                />
              )
            }))}
            size="small"
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
}

export default MoneyDebrief;
