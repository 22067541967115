import { memo, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, InfoBox } from '@react-google-maps/api';
import { useIntl } from 'react-intl';

import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';
import { mapDefaultLocation } from 'common/countries/countries-mapping';
import {
  checkPolygonsIntersection,
  generateInitialPolygon,
  getPolygonCentroid,
  getZoomLevelForPolygon,
  polygonContainsPointsOutsideParentPolygon
} from 'utils/bosta-coding';
import {
  STREET_CODE_POLYGON_OPTIONS,
  ZONE_POLYGON_OPTIONS
} from 'constants/bosta-coding';

import BostaCodingPolygon from 'components/BostaCoding/components/BostaCodingPolygon/BostaCodingPolygon';

const containerStyle = {
  width: '100%',
  height: '70vh'
};

const CodesGeofencingMap = ({
  selectedZoneId,
  hubZones,
  isCreatingCodeEnabled,
  setIsCreatingCodeEnabled,
  setIsDrawingCodeGeofencing,
  selectedCodeGeometry,
  setSelectedCodeGeometry,
  codePolygonRef,
  setCodeGeofencingErrors,
  otherStreetCodesGeometry,
  setSelectedStreetCode,
  selectedStreetCode,
  zoneCodes
}) => {
  const [selectedZoneGeometry, setSelectedZoneGeometry] = useState([]);

  const intl = useIntl();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  useEffect(() => {
    if (selectedZoneId) {
      const zone = hubZones.find((zone) => zone._id === selectedZoneId);

      const geometryPath = zone.geometry
        ? zone.geometry.coordinates[0].map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0]
          }))
        : [];

      setSelectedZoneGeometry(geometryPath);
    }
  }, [selectedZoneId]);

  useEffect(() => {
    if (selectedCodeGeometry?.length && zoneCodes?.length) {
      validateCodeGeofencing();
    }
  }, [selectedCodeGeometry, zoneCodes]);

  const handleMapClick = ({ latLng: { lat: getLat, lng: getLng } }) => {
    if (isCreatingCodeEnabled) {
      setIsCreatingCodeEnabled(!isCreatingCodeEnabled);
      setIsDrawingCodeGeofencing(true);

      const lat = getLat();
      const lng = getLng();

      setSelectedCodeGeometry(
        generateInitialPolygon({ lat, lng, radius: 0.01 })
      );
    }
  };

  const validateCodeGeofencing = () => {
    const codeCoordinates =
      codePolygonRef?.current?.state?.polygon
        ?.getPath()
        ?.getArray()
        ?.map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() })) || [];

    const allPolygons = zoneCodes.map((streetCode) => {
      if (
        streetCode._id === selectedStreetCode &&
        streetCode.geometry &&
        codeCoordinates.length
      ) {
        return codeCoordinates;
      } else if (streetCode.geometry) {
        return streetCode.geometry.coordinates[0].map((coordinates) => ({
          lat: coordinates[1],
          lng: coordinates[0]
        }));
      }
    });

    setCodeGeofencingErrors((prev) => ({
      ...prev,
      containOutsidePoints: polygonContainsPointsOutsideParentPolygon({
        polygon: codeCoordinates,
        parentPolygonCoords: selectedZoneGeometry
      }),
      polygonsIntersecting: checkPolygonsIntersection([
        ...allPolygons.filter(Boolean),
        ...(!selectedStreetCode && codeCoordinates.length
          ? [codeCoordinates]
          : [])
      ])
    }));
  };

  if (!isLoaded) {
    return intl.formatMessage({ id: 'common.map_failed_to_load' });
  }
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={
        selectedCodeGeometry?.length
          ? getZoomLevelForPolygon(selectedCodeGeometry)
          : selectedZoneId
          ? getZoomLevelForPolygon(selectedZoneGeometry)
          : 10
      }
      streetView={false}
      options={{
        streetViewControl: false,
        clickableIcons: false,
        mapTypeControl: false
      }}
      mapTypeControl={false}
      center={
        selectedCodeGeometry?.length
          ? getPolygonCentroid(selectedCodeGeometry)
          : selectedZoneId
          ? getPolygonCentroid(selectedZoneGeometry)
          : mapDefaultLocation
      }
      onClick={handleMapClick}
    >
      <InfoBox
        position={
          selectedZoneGeometry.length
            ? getPolygonCentroid(selectedZoneGeometry)
            : mapDefaultLocation
        }
        options={{
          isHidden: !isCreatingCodeEnabled
        }}
      >
        <h5 className="br-bosta-coding-info-box__container">
          {intl.formatMessage({
            id: 'bosta_coding.codes_geofencing.add_street_code_geofence'
          })}
        </h5>
      </InfoBox>
      <BostaCodingPolygon
        geometryPath={selectedCodeGeometry}
        polygonRef={codePolygonRef}
        onMouseUp={validateCodeGeofencing}
        options={STREET_CODE_POLYGON_OPTIONS(true)}
      />
      <BostaCodingPolygon
        geometryPath={selectedZoneGeometry}
        onClick={handleMapClick}
        editable={false}
        options={ZONE_POLYGON_OPTIONS(false)}
      />
      {otherStreetCodesGeometry.map((streetCode, index) => (
        <div key={index}>
          <BostaCodingPolygon
            onClick={() => setSelectedStreetCode(streetCode._id)}
            geometryPath={streetCode.coordinates}
            editable={false}
            options={STREET_CODE_POLYGON_OPTIONS(false)}
          />
        </div>
      ))}
    </GoogleMap>
  );
};

export default memo(CodesGeofencingMap);
