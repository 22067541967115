import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const TICKET_STATUS = {
  open: 'open',
  pending: 'pending',
  resolved: 'resolved',
  closed: 'closed',
  closedByCustomer: 'closed by customer',
  waitingCustomerReply: 'waiting customer reply',
  pendingCustomerResponse: 'pending customer response'
};

export const SUPPORT_TICKET_FIELDS = {
  CATEGORY: 'Category',
  GROUP: 'Group'
};

export const TICKET_SUBCATEGORY = {
  BUSINESS_DASHBOARD_DEFAULT: 'Business Dashboard Default'
};

export const MAP_UPDATE_TICKETS_STATUS = {
  'closed by customer': 'Open',
  closed: 'Open',
  resolved: 'Open',
  open: 'Closed by customer',
  pending: 'Closed by customer',
  'waiting customer reply': 'Closed by customer'
};

export const MAP_TICKETS_STATUS_ACTION = {
  'closed by customer': 're_open',
  closed: 're_open',
  resolved: 're_open',
  open: 'close',
  pending: 'close',
  'waiting customer reply': 'close'
};
