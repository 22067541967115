import React, { useRef, useState, useEffect } from 'react';
import { Input, Button, Form, Row, Col, Select, DatePicker } from 'antd';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { FORMAT_DATE_TO_UTC } from 'utils/helpers';
import { downloadAsXlsx } from 'utils';
import {
  getPaidOrders,
  exportOrders
} from 'services/international-shipping-in';

import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import './InternationalOrders.less';

const InternationalOrders = ({ intl, businesses }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState(businesses);
  const [disableExport, setDisableExport] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [count, setCount] = useState(0);

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const refreshUpcomingOrders = useRef();

  const getOrders = async ({ pageId: pageNumber, pageLimit: pageSize }) => {
    setIsLoading(true);
    try {
      const { data } = await getPaidOrders({
        pageNumber: pageNumber - 1,
        pageSize,
        searchQuery
      });
      setCount(data.total || 0);
      setIsLoading(false);
      return {
        list: formatOrders(data.orders),
        total: data.total || 0
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOnExportClick = async () => {
    setDisableExport(true);
    setIsLoading(true);
    try {
      const ids = selectedRows?.toString();
      const result = await exportOrders(ids);
      downloadAsXlsx(result, `Orders-Info.xlsx`);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
    setDisableExport(false);
  };

  const handleOnFinish = async (values) => {
    if (values.createdAt?.length) {
      values.createdAtMin = FORMAT_DATE_TO_UTC(values.createdAt[0]);
      values.createdAtMax = FORMAT_DATE_TO_UTC(values.createdAt[1]);
      delete values.createdAt;
    }
    if (values.updatedAt?.length) {
      values.updatedAtMin = FORMAT_DATE_TO_UTC(values.updatedAt[0]);
      values.updatedAtMax = FORMAT_DATE_TO_UTC(values.updatedAt[1]);
      delete values.updatedAt;
    }
    let searchQuery = '&';
    for (const key in values) {
      if (values[key]) {
        searchQuery += `${key}=${values[key]}&`;
      }
    }
    if (searchQuery !== '&') {
      searchQuery = searchQuery.slice(0, searchQuery.length - 1);
      await setSearchQuery(searchQuery);
      refreshOrdersTable();
    } else {
      await setSearchQuery('');
      refreshOrdersTable();
    }
  };

  const formatOrders = (orders) => {
    const formattedOrders = [];
    orders.map((order) => {
      formattedOrders.push({
        _id: order._id,
        provider_order_id: order.providerOrderId,
        createdAt: order.createdAt,
        updatedAt: order.updatedAt,
        provider: order.provider,
        shippingProviderOrderId: order.shippingProviderOrderId,
        no_of_items: order.items.length,
        amount: `${order.amountInCents / 100} ${order.currency}`,
        business: {
          name: order?.businessInfo?.name || 'N/A',
          phone: order?.businessInfo?.phone || 'N/A'
        },
        status: order?.status || 'N/A'
      });
    });
    return formattedOrders;
  };

  const columns = () => {
    return [
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.business'
        }),
        dataIndex: 'business',
        render: (business) => {
          return (
            <>
              <p>{business.name}</p>
              <p>{business.phone}</p>
            </>
          );
        }
      },

      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.order_provider_id'
        }),
        dataIndex: 'provider_order_id',
        render: (providerId, order) =>
          providerId ? (
            <a
              href={`/box/order/${order._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {providerId}
            </a>
          ) : (
            'N/A'
          )
      },

      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.provider'
        }),
        dataIndex: 'provider'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.shipping_provider_order_id'
        }),
        dataIndex: 'shippingProviderOrderId'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.status'
        }),
        dataIndex: 'status'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.no_of_items'
        }),
        dataIndex: 'no_of_items'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.total'
        }),
        dataIndex: 'amount'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.created_at'
        }),
        dataIndex: 'createdAt',
        render: (date) =>
          date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.updated_at'
        }),
        dataIndex: 'updatedAt',
        render: (date) =>
          date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
      }
    ];
  };

  const acceptMethods = (refreshMethod) => {
    refreshUpcomingOrders.current = refreshMethod;
  };

  const refreshOrdersTable = () => {
    refreshUpcomingOrders.current({ pageId: 1 });
  };

  useEffect(() => {
    setDisableExport(!selectedRows?.length);
  }, [selectedRows]);

  return (
    <div>
      <Form
        scrollToFirstError
        name="InternationalOrdersForm"
        onFinish={handleOnFinish}
        className="br-international-orders__form-container"
      >
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="providerOrderId" label="Provider Order ID">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="businessId" label="Business">
              <Select
                placeholder="Select Business"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {business.map((b) => (
                  <Option key={b._id} value={b._id}>
                    {b.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="priceMin" label="Price Min Range">
              <Input type="number" min={1} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="priceMax" label="Price Max Range">
              <Input type="number" min={1} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="createdAt" label="Created At ">
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="updatedAt" label="Updated At ">
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="br-internationa-orders__form-actions">
        <Button
          type="primary"
          htmlType="submit"
          form="InternationalOrdersForm"
          disabled={isLoading}
        >
          Search
        </Button>
        <Button
          type="primary"
          disabled={disableExport}
          onClick={handleOnExportClick}
        >
          Export
        </Button>
      </div>

      <div className="mb-4">
        <b>Total Orders:</b>
        <span className="ml-2">{count}</span>
      </div>

      <BRSearchableTable
        columns={columns()}
        onRowClick={() => {}}
        showPagination
        pageLimit={50}
        listFunction={getOrders}
        shareMethods={acceptMethods}
        selectedRows={setSelectedRows}
      />
    </div>
  );
};

export default injectIntl(withRouter(InternationalOrders));
