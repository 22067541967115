import { Tag } from 'antd';

import {
  changeReturnOrderOState,
  getReturnOrderLineSerialNumbers
} from 'services/fulfillment-return-orders';
import { getSerialsToPrint } from 'services/fulfillment';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { openModal } from 'utils/modal';
import { showBill } from 'utils';
import { handlePrintAirwaybill } from 'constants/fulfillment-sales-orders';

import ReturnsList from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/ReturnsList';
import ReturnOrder from 'components/NewFulfillment/FulfillmentReturns/ReturnOrder/ReturnOrder';
import { notify } from 'components/Notify/Notify';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';
import DamageSerialsCheckModal from 'components/NewFulfillment/FulfillmentReturns/DamageSerialsCheckModal/DamageSerialsCheckModal';
import PrintSerialsModal from 'components/NewFulfillment/FulfillmentReturns/PrintSerialsModal/PrintSerialsModal';
import ScanSerialsModal from 'components/NewFulfillment/FulfillmentReturns/ScanSerialsModal/ScanSerialsModal';
import SerialsView from 'components/NewFulfillment/FulfillmentReturns/ReturnOrder/components/SerialsView/SerialsView';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as BarcodeIcon } from 'assets/bosta-icons/BarcodeIcon.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

export const FULFILLMENT_RETURNS_MAIN_PATH = '/fulfillment/returns';

export const FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH = `${FULFILLMENT_RETURNS_MAIN_PATH}/ro`;

export const FULFILLMENT_RETURNS_ROUTES = [
  {
    path: FULFILLMENT_RETURNS_MAIN_PATH,
    name: 'Returns List',
    component: ReturnsList,
    exact: true
  },
  {
    path: `${FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH}/:id`,
    name: 'View Return Order',
    component: ReturnOrder,
    exact: true
  }
];

export const RETURN_ORDER_STATE_IDS = {
  ON_HOLD: 1,
  CONFIRMED: 2,
  READY_TO_PUTAWAY: 3,
  PUTAWAY: 4
};

export const RETURN_ORDER_STATE_NAMES = {
  [RETURN_ORDER_STATE_IDS.ON_HOLD]: 'on_hold',
  [RETURN_ORDER_STATE_IDS.CONFIRMED]: 'confirmed',
  [RETURN_ORDER_STATE_IDS.READY_TO_PUTAWAY]: 'ready',
  [RETURN_ORDER_STATE_IDS.PUTAWAY]: 'completed'
};

export const RETURN_ORDER_TYPES_IDS = {
  RTO: 1,
  PICKED_INTERNAL: 2,
  PACKED_INTERNAL: 3,
  RECEIVED_INTERNAL: 4,
  TRANSFERRED_INTERNAL: 5,
  CRP: 6,
  EXCHANGE_RETURN: 7
};

export const RETURN_ORDER_TYPES_NAMES = {
  [RETURN_ORDER_TYPES_IDS.RTO]: fmt({
    id: 'fulfillment_returns.order_types.rto'
  }),
  [RETURN_ORDER_TYPES_IDS.PICKED_INTERNAL]: fmt({
    id: 'fulfillment_returns.order_types.picked_internal'
  }),
  [RETURN_ORDER_TYPES_IDS.PACKED_INTERNAL]: fmt({
    id: 'fulfillment_returns.order_types.packed_internal'
  }),
  [RETURN_ORDER_TYPES_IDS.RECEIVED_INTERNAL]: fmt({
    id: 'fulfillment_returns.order_types.received_internal'
  }),
  [RETURN_ORDER_TYPES_IDS.TRANSFERRED_INTERNAL]: fmt({
    id: 'fulfillment_returns.order_types.transferred_internal'
  }),
  [RETURN_ORDER_TYPES_IDS.CRP]: fmt({
    id: 'fulfillment_returns.order_types.crp'
  }),
  [RETURN_ORDER_TYPES_IDS.EXCHANGE_RETURN]: fmt({
    id: 'fulfillment_returns.order_types.exchange_return'
  })
};

export const RETURN_ORDER_TYPES_OPTIONS = [
  {
    label: fmt({
      id: 'fulfillment_returns.order_types.all'
    }),
    value: null
  },
  {
    label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.RTO],
    value: RETURN_ORDER_TYPES_IDS.RTO
  },
  {
    label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.PICKED_INTERNAL],
    value: RETURN_ORDER_TYPES_IDS.PICKED_INTERNAL
  },
  {
    label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.PACKED_INTERNAL],
    value: RETURN_ORDER_TYPES_IDS.PACKED_INTERNAL
  },
  {
    label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.CRP],
    value: RETURN_ORDER_TYPES_IDS.CRP
  },
  {
    label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.EXCHANGE_RETURN],
    value: RETURN_ORDER_TYPES_IDS.EXCHANGE_RETURN
  }
  // {
  //   label: RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.RECEIVED_INTERNAL],
  //   value: RETURN_ORDER_TYPES_IDS.RECEIVED_INTERNAL
  // },
  // {
  //   label:
  //     RETURN_ORDER_TYPES_NAMES[RETURN_ORDER_TYPES_IDS.TRANSFERRED_INTERNAL],
  //   value: RETURN_ORDER_TYPES_IDS.TRANSFERRED_INTERNAL
  // }
];

export const RETURN_ORDER_ISSUES_OPTIONS = [
  {
    label: fmt({
      id: 'fulfillment_returns.order_types.all'
    }),
    value: null
  },
  {
    label: fmt({
      id: 'fulfillment_returns.issues_filter.lost'
    }),
    value: 'containsLost'
  },
  {
    label: fmt({
      id: 'fulfillment_returns.issues_filter.damaged'
    }),
    value: 'containsDamaged'
  }
];

export const RETURN_ORDERS_SEARCH_OPTIONS_IDS = {
  SKU: {
    KEY: 'SKU',
    API_KEY: 'sku',
    LABEL: fmt({
      id: 'fulfillment_returns.return_orders_table.search_options.sku'
    })
  },
  TRACKING_NUMBER: {
    KEY: 'TRACKING_NUMBER',
    API_KEY: 'trackingNumber',
    LABEL: fmt({
      id: 'fulfillment_returns.return_orders_table.search_options.tracking_number'
    })
  },
  REFERENCE_ID: {
    KEY: 'REFERENCE_ID',
    API_KEY: 'referenceId',
    LABEL: fmt({
      id: 'fulfillment_returns.return_orders_table.search_options.reference_id'
    })
  }
};

export const RETURN_ORDERS_SEARCH_OPTIONS = [
  {
    label: RETURN_ORDERS_SEARCH_OPTIONS_IDS.SKU.LABEL,
    value: RETURN_ORDERS_SEARCH_OPTIONS_IDS.SKU.KEY
  },
  {
    label: RETURN_ORDERS_SEARCH_OPTIONS_IDS.TRACKING_NUMBER.LABEL,
    value: RETURN_ORDERS_SEARCH_OPTIONS_IDS.TRACKING_NUMBER.KEY
  },
  {
    label: RETURN_ORDERS_SEARCH_OPTIONS_IDS.REFERENCE_ID.LABEL,
    value: RETURN_ORDERS_SEARCH_OPTIONS_IDS.REFERENCE_ID.KEY
  }
];

export const RO_KEY = 'RO-';

export const shouldShowIssues = (stateId) =>
  [
    RETURN_ORDER_STATE_IDS.READY_TO_PUTAWAY,
    RETURN_ORDER_STATE_IDS.PUTAWAY
  ].includes(stateId);

export const goToNextStep = () => window.location.reload();

export const renderSOName = (soId) => `SO-${soId}`;

export const goToReturnOrdersList = () =>
  window.open(FULFILLMENT_RETURNS_MAIN_PATH, '_self');

export const handleChangeState = async ({
  roId,
  stateId,
  lines,
  successModalProps
}) => {
  try {
    const payload = {
      state: stateId,
      lines
    };

    await changeReturnOrderOState(roId, payload);
    openModal(SuccessModal, successModalProps);
  } catch (error) {
    notify(error.message);
  }
};

export const getProductDataForSerial = ({ allSerials = [], serial }) =>
  allSerials.find(({ serialNumber }) => serialNumber === serial) || {};

export const getReturnOrderRenderedTag = ({ stateId, typeId }) => {
  const stateName = RETURN_ORDER_STATE_NAMES[stateId];
  if (!stateName) {
    return null;
  }

  const stateInsideIcon = {
    [RETURN_ORDER_STATE_IDS.PUTAWAY]: <DoubleCheckIcon />
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_returns.state_name.${stateName}`
        })}
        {stateInsideIcon[stateId]}
      </Tag>
      {typeId && (
        <Tag className="br-status-tag__return-type">
          {RETURN_ORDER_TYPES_NAMES[typeId]}
        </Tag>
      )}
    </div>
  );
};

const openScanSerialsModal = (props) => {
  openModal(ScanSerialsModal, props);
};

export const openPrintSerialsModal = (props) => {
  openModal(PrintSerialsModal, props);
};

export const handlePrintSerials = async ({
  serials,
  setIsLoading = () => {}
}) => {
  setIsLoading(true);

  try {
    const payload = {
      serialNumbers: serials.join(',')
    };

    const { data, message } = await getSerialsToPrint(payload);

    if (data) {
      showBill(data);
    } else {
      notify(message, 'success');
    }
  } catch (error) {
    notify(error.message);
  }

  setIsLoading(false);
};

export const SERIAL_NUMBERS_TYPES = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  LOST: 'lost',
  DAMAGED: 'damaged',
  ALL: 'all',
  GENERATED: 'generated',
  ACTIVE: 'active',
  PACKED: 'packed',
  TRANSFERRED: 'transferred',
  COMPLETED: 'completed',
  ON_SHELF: 'on_shelf'
};

export const renderSerialsView = ({
  clickedLineId,
  setClickedLineData,
  line,
  isViewBtnDisabled = false,
  ...rest
}) => {
  const openDrawer = () => setClickedLineData(line);
  const closeDrawer = () => setClickedLineData(null);

  return (
    <>
      <BRButton
        label={fmt({
          id: 'fulfillment_returns.serials_view.view_serial_btn'
        })}
        onClick={openDrawer}
        size="small"
        disabled={isViewBtnDisabled}
      />
      {clickedLineId === line.id && (
        <SerialsView onClose={closeDrawer} {...line} {...rest} />
      )}
    </>
  );
};

export const renderSerialsCount = (serialsCount) => (
  <>
    <BarcodeIcon />
    <span className="br-confirmed-po__tooltip__text">{serialsCount}</span>
  </>
);

export const isFulfillmentCRP = (type = {}) =>
  type.typeId === RETURN_ORDER_TYPES_IDS.CRP;

export const isFulfillmentExchangeReturn = (type = {}) =>
  type.typeId === RETURN_ORDER_TYPES_IDS.EXCHANGE_RETURN;

export const getViewReturnOrderContentForRendering = ({
  returnOrder,
  isLoading,
  setIsLoading,
  products,
  clickedLineData,
  setClickedLineData
}) => {
  const {
    state,
    soId,
    originalSalesOrderId,
    id: roId,
    trackingNumber,
    type = {}
  } = returnOrder;
  const { ON_HOLD, CONFIRMED, READY_TO_PUTAWAY, PUTAWAY } =
    RETURN_ORDER_STATE_IDS;

  const sharedHeaderProps = {
    icon: LeftArrowIcon,
    iconOnClick: goToReturnOrdersList
  };

  const getSharedProductsTableColumns = (props = {}) => {
    const { showDamagedSerials = true } = props;
    const { DAMAGED, LOST, ACTIVE } = SERIAL_NUMBERS_TYPES;

    return [
      {
        title: fmt({
          id: 'fulfillment_inbound.create_po.products.columns.actual_quantity'
        }),
        render: ({ confirmedQuantity = 0 }) =>
          renderSerialsCount(confirmedQuantity)
      },
      {
        title: fmt({
          id: 'fulfillment_returns.return_orders_table.columns.lost'
        }),
        render: ({ confirmedQuantity = 0, quantity = 0 }) => {
          const hasLostSerials = confirmedQuantity !== quantity;

          return hasLostSerials ? (
            renderSerialsCount(quantity - confirmedQuantity)
          ) : (
            <span className="text-gray">
              {fmt({
                id: 'common.all_good'
              })}
            </span>
          );
        }
      },
      ...(showDamagedSerials
        ? [
            {
              title: fmt({
                id: 'fulfillment_returns.return_orders_table.columns.damaged'
              }),
              render: ({
                qualityCheckedQuantity = 0,
                confirmedQuantity = 0
              }) => {
                const hasDamagedSerials =
                  qualityCheckedQuantity !== confirmedQuantity;

                return hasDamagedSerials ? (
                  renderSerialsCount(confirmedQuantity - qualityCheckedQuantity)
                ) : (
                  <span className="text-gray">
                    {fmt({
                      id: 'common.all_good'
                    })}
                  </span>
                );
              }
            }
          ]
        : []),
      {
        render: (line = {}) =>
          renderSerialsView({
            clickedLineId: clickedLineData?.id,
            line,
            setClickedLineData,
            fetchData: () =>
              getReturnOrderLineSerialNumbers({
                lineId: line.id,
                payload: {
                  serialsType: SERIAL_NUMBERS_TYPES.ALL
                }
              }),
            formatTypedSerialsList: (allSerials) => {
              const active = [],
                lost = [],
                damaged = [];

              allSerials.forEach((serial) => {
                const { isLost, isDamaged } = serial;

                if (isLost) {
                  lost.push(serial);
                } else if (isDamaged) {
                  damaged.push(serial);
                } else {
                  active.push(serial);
                }
              });

              return [
                {
                  type: ACTIVE,
                  title: fmt({
                    id: 'fulfillment_returns.serials_view.active.title'
                  }),
                  serials: active
                },
                {
                  type: LOST,
                  title: fmt({
                    id: 'fulfillment_returns.serials_view.lost.title'
                  }),
                  serials: lost
                },
                {
                  type: DAMAGED,
                  title: fmt({
                    id: 'fulfillment_returns.serials_view.damaged.title'
                  }),
                  serials: damaged
                }
              ];
            },
            getSerialType: ({ isLost, isDamaged }) =>
              isLost ? LOST : isDamaged ? DAMAGED : ACTIVE
          })
      }
    ];
  };

  const handlePrintLabels = () =>
    openPrintSerialsModal({
      payload: {
        roId,
        serialsType: SERIAL_NUMBERS_TYPES.CONFIRMED
      }
    });

  const RETURN_ORDER_VIEW = {
    [ON_HOLD]: {
      headerProps: {
        ...sharedHeaderProps,
        actions: [
          {
            label: fmt({
              id: 'fulfillment_returns.actions.scan_serials'
            }),
            type: 'primary',
            prefixIcon: <BarcodeIcon />,
            disabled: isLoading,
            onClick: () =>
              openScanSerialsModal({
                roId,
                soId,
                originalSalesOrderId,
                products,
                trackingNumber,
                type
              })
          }
        ]
      },
      productsTableColumns: [
        {
          dataIndex: 'quantity',
          title: fmt({
            id: 'fulfillment_inbound.create_po.products.columns.original_quantity'
          })
        }
      ]
    },
    [CONFIRMED]: {
      headerProps: {
        ...sharedHeaderProps,
        actions: [
          {
            label: fmt({
              id: 'fulfillment_returns.actions.check_ro'
            }),
            type: 'primary',
            prefixIcon: <CheckIcon />,
            disabled: isLoading,
            onClick: () =>
              openModal(DamageSerialsCheckModal, {
                roId,
                soId,
                products,
                trackingNumber
              })
          }
        ]
      },
      productsTableColumns: getSharedProductsTableColumns({
        showDamagedSerials: false
      })
    },
    [READY_TO_PUTAWAY]: {
      headerProps: {
        ...sharedHeaderProps,
        actions: [
          {
            label: fmt({
              id: 'fulfillment_returns.actions.print_labels'
            }),
            type: 'treitary-gray',
            prefixIcon: <PrinterIcon />,
            disabled: isLoading,
            onClick: handlePrintLabels
          }
        ]
      },
      productsTableColumns: getSharedProductsTableColumns()
    },
    [PUTAWAY]: {
      headerProps: {
        ...sharedHeaderProps,
        actions: [
          {
            label: fmt({
              id: 'fulfillment_returns.actions.print_labels'
            }),
            type: 'treitary-gray',
            prefixIcon: <PrinterIcon />,
            disabled: isLoading,
            onClick: handlePrintLabels
          },
          ...(isFulfillmentCRP(type)
            ? [
                {
                  label: fmt({
                    id: 'fulfillment_returns.actions.print_crp_awb'
                  }),
                  prefixIcon: <PrinterIcon />,
                  disabled: isLoading,
                  onClick: () =>
                    handlePrintAirwaybill({
                      list: [trackingNumber],
                      isTrackingNumbers: true,
                      setIsLoading
                    })
                }
              ]
            : [])
        ]
      },
      productsTableColumns: getSharedProductsTableColumns()
    }
  };

  return RETURN_ORDER_VIEW[state?.stateId] || {};
};
