import http from 'utils/http';

export const getLocationZones = () => {
  return http.get('/fulfillment-v2/fcs/zones');
};

export const getZoneAisles = (zoneId) => {
  return http.get(`/fulfillment-v2/fcs/${zoneId}/aisles`);
};

export const getAisleRacks = (aisleId) => {
  return http.get(`/fulfillment-v2/fcs/${aisleId}/racks`);
};

export const getRackShelves = (rackId) => {
  return http.get(`/fulfillment-v2/fcs/${rackId}/shelves`);
};

export const getShelveTotes = (shelveId) => {
  return http.get(`/fulfillment-v2/fcs/${shelveId}/totes`);
};

export const createFulfillmentLocation = (payload) => {
  return http.post(`/fulfillment-v2/fcs/layout`, payload);
};

export const getPrintedLocationsCode = (payload) => {
  return http.get(`/fulfillment-v2/fcs/print-locations`, payload);
};
