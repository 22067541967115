import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getSalesOrdersStatistics } from 'services/sales-orders';
import { SllrWrapper } from 'contexts/sllr.context';
import { SALES_ORDER_STATE_IDS } from 'constants/fulfillment';

import POStatistics from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POStatistics/POStatistics';
import POFilters from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POFilters/POFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import SalesOrdersTable from 'components/NewFulfillment/FulfillmentOutbound/components/OutboundList/components/SalesOrdersTable/SalesOrdersTable';
import DateFilter from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/DateFilter/DateFilter';
import BRButton from 'components/BRButton/BRButton';
import TypeFilter from 'components/NewFulfillment/FulfillmentOutbound/components/OutboundList/components/TypeFilter/TypeFilter';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as PickIcon } from 'assets/bosta-icons/PickIcon.svg';
import { ReactComponent as PackIcon } from 'assets/bosta-icons/PackIcon.svg';
import { ReactComponent as TransferIcon } from 'assets/bosta-icons/TransferIcon.svg';
import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';
import { ReactComponent as InTransitIcon } from 'assets/bosta-icons/In Transit.svg';
import { ReactComponent as DeleteIcon } from 'assets/bosta-icons/DeleteIcon.svg';

import './OutboundList.less';

const OutboundList = ({ intl }) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);
  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [salesOrderFilters, setSalesOrderFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);

  const { CREATED, PICKED, PACKED, TRANSFERRED, CANCELED } =
    SALES_ORDER_STATE_IDS;

  const getStatisticsListToRender = ({
    total,
    createdCount,
    pickedCount,
    packedCount,
    transferredCount,
    canceledCount
  }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'pick',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.pick'
      }),
      content: createdCount,
      icon: <PickIcon />,
      stateIdFilterValue: CREATED
    },
    {
      key: 'pack',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.pack'
      }),
      content: pickedCount,
      icon: <PackIcon />,
      stateIdFilterValue: PICKED
    },
    {
      key: 'transfer',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.transfer'
      }),
      content: packedCount,
      icon: <TransferIcon />,
      stateIdFilterValue: PACKED
    },
    {
      key: 'transferred',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.transferred'
      }),
      content: transferredCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: TRANSFERRED
    },
    {
      key: 'canceled',
      title: intl.formatMessage({
        id: 'fulfillment_outbound.statistics.titles.canceled'
      }),
      content: canceledCount,
      icon: <DeleteIcon />,
      stateIdFilterValue: CANCELED
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setSalesOrderFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const dateFilter = {
    [PICKED]: (
      <DateFilter
        label={intl.formatMessage({
          id: 'fulfillment_outbound.filters.picked_on'
        })}
        updateFilters={updateFilters}
      />
    ),
    [PACKED]: (
      <DateFilter
        label={intl.formatMessage({
          id: 'fulfillment_outbound.filters.packed_on'
        })}
        updateFilters={updateFilters}
      />
    ),
    [TRANSFERRED]: (
      <DateFilter
        label={intl.formatMessage({
          id: 'fulfillment_outbound.filters.transferred_on'
        })}
        updateFilters={updateFilters}
      />
    )
  };

  const rightContent = {
    [PACKED]: (
      <BRButton
        type="primary"
        label={intl.formatMessage({
          id: 'fulfillment_outbound.actions.receive_transfer'
        })}
        prefixIcon={<InTransitIcon />}
        onClick={() =>
          window.open('/hubs/hub-operation/receive/receive-new-pickups')
        }
      />
    )
  };

  const renderExtraFilters = () => <TypeFilter updateFilters={updateFilters} />;

  return (
    <Container
      className="br-fulfillment-outbound-list"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_outbound.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <POStatistics
              updateFilters={updateFilters}
              poFilters={salesOrderFilters}
              fetchData={getSalesOrdersStatistics}
              getStatisticsListToRender={getStatisticsListToRender}
            />
            <POFilters
              updateFilters={updateFilters}
              dateFilter={dateFilter[salesOrderFilters.state]}
              rightContent={rightContent[salesOrderFilters.state]}
              renderExtraFilters={renderExtraFilters}
            />
            <SalesOrdersTable
              salesOrderFilters={salesOrderFilters}
              isFirstFetchDone={isFirstFetchDone}
              setIsFirstFetchDone={setIsFirstFetchDone}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(OutboundList));
