import './DispatchingCard.less';

const DispatchingCard = ({ count = 0, title, cardColor }) => {
  return (
    <div className={`br-dispatching-card__container ${cardColor}`}>
      <div className="br-dispatching-card__count">{count}</div>
      <div className="br-dispatching-card__title">{title}</div>
    </div>
  );
};

export default DispatchingCard;
