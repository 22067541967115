import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Radio, Button, Dropdown, Menu } from 'antd';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Pie } from '@ant-design/plots';

import { getAllHubsScores, exportHubsPerformanceTable } from 'services/bib';
import {
  STAR_MAP_DEFAULT_DATE_FORMAT,
  TOP_PERFORMANCE_SCORE,
  AVG_PERFORMANCE_SCORE,
  HIGH_PLACEHOLDER,
  AVG_PLACEHOLDER,
  LOW_PLACEHOLDER
} from 'constants/stars';
import { getCurrency } from 'common/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import HubPerformanceDateFilter from '../HubPerformance/HubPerformanceDateFilter/HubPerformanceDateFilter';
import BRTable from 'components/BRTable/BRTable';

import { ReactComponent as Filters } from 'assets/bosta-icons/Filters.svg';
import { ReactComponent as ExpandOpenIcon } from 'assets/imgRevamp/table-expand-opn-icon.svg';
import { ReactComponent as ExpandCloseIcon } from 'assets/imgRevamp/table-expand-close-icon.svg';

import './HubsPerformance.less';

const HubsPerformance = ({ intl, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    dateFrom: dayjs().add(-1, 'days').format(STAR_MAP_DEFAULT_DATE_FORMAT),
    dateTo: dayjs().add(-1, 'days').format(STAR_MAP_DEFAULT_DATE_FORMAT),
    initial: true
  });
  const [prevDate, setPrevDate] = useState({
    dateFrom: null,
    dateTo: null
  });
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [performanceCardInfo, setPerformanceCardInfo] = useState({});
  const [scoreCardWeights, setScoreCardWeights] = useState({});
  const [count, setCount] = useState(0);
  const [disableApplyButton, setDisableApplyButton] = useState(true);
  const [performanceFilter, setPerformanceFilter] = useState(null);
  const [tableFilters, setTableFilters] = useState({});
  const [hubsData, setHubsData] = useState({
    data: [],
    weights: {}
  });
  const [highlights, setHighlights] = useState({});

  const refreshTable = useRef();

  useEffect(() => {
    if (!dateFilter?.initial) {
      updateTable();
    }
  }, [dateFilter]);

  const performanceStandards = [
    {
      label: intl.formatMessage({
        id: 'bib.hubs_performance.top_performance'
      }),
      value: 'HIGH'
    },
    {
      label: intl.formatMessage({
        id: 'bib.hubs_performance.good'
      }),
      value: 'AVERAGE'
    },
    {
      label: intl.formatMessage({
        id: 'bib.hubs_performance.low'
      }),
      value: 'AVERAGE'
    }
  ];

  const onChange = (e) => {
    setPerformanceFilter(e.target.value);
    if (e.target.value) {
      setDisableApplyButton(false);
    } else {
      setDisableApplyButton(true);
    }
  };

  const handleOnApplyFilterClick = () => {
    setFilterMenuVisible(false);
    updateTable();
  };

  const handleOnClearFilterClick = async () => {
    await setPerformanceFilter(null);
    setDisableApplyButton(true);
    updateTable();
  };

  const filterOptionsMenu = () => {
    return (
      <>
        <div
          className="br-analytics-filter__cities-header"
          onClick={(e) => e.stopPropagation()}
        >
          <Button onClick={handleOnClearFilterClick}>
            {intl.formatMessage({
              id: `common.cancel`
            })}
          </Button>
          <span>
            {intl.formatMessage({
              id: 'bib.hubs_performance.table.performance_standards'
            })}
          </span>
          <Button
            type="primary"
            onClick={handleOnApplyFilterClick}
            disabled={disableApplyButton}
            className="br-star-logs__apply-filter-action"
          >
            {intl.formatMessage({ id: 'common.apply' })}
          </Button>
        </div>

        <Menu trigger={['click']} onClick={(e) => e.domEvent.stopPropagation()}>
          <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
            <div>
              <div className="br-star-logs__filter-menu-content">
                <Radio.Group
                  options={performanceStandards}
                  onChange={onChange}
                  value={performanceFilter}
                />
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  const filterComponent = () => {
    return (
      <div className="br-star-logs__filter-component-container">
        <Dropdown
          visible={filterMenuVisible}
          onVisibleChange={setFilterMenuVisible}
          overlay={filterOptionsMenu()}
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentElement}
          overlayClassName="br-analytics-filter__cities-dropdown"
          destroyPopupOnHide
          placement="bottomRight"
        >
          <Button
            loading={isLoading}
            className="br-analytics-filter__cities-button"
            onClick={() => setFilterMenuVisible(!filterMenuVisible)}
          >
            <Filters />
            <span className="br-analytics-filter__cities-dropdown-title">
              {intl.formatMessage({
                id: 'common.filter'
              })}
            </span>

            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const formatAllHubsData = (
    dataArray = [],
    performanceFilter = false,
    searchText = ''
  ) => {
    const formattedArray = [];
    const sorttedArray = dataArray.sort((a, b) => b.score - a.score);
    sorttedArray.forEach((data, index) => {
      formattedArray.push({
        _id: data?._id,
        rank: data?.rank,
        hubName: data?.name,
        assignedVolume: data?.assignedVolume,
        totalScore: data?.score.toFixed(2),
        costPerShipment: data?.costPerShipment,
        scoreCardInfo: {
          assignedVolume: data?.assignedVolume,
          attemptSuccessRate: data?.attemptSuccessRate,
          businessPromiseSuccessRate: data?.businessPromiseSuccessRate,
          deliverySuccessRate: data?.deliverySuccessRate,
          pending_in_transit: data?.pending_intransit,
          routesDispatchRate: data?.routesDispatchRate,
          fdaSuccessRate: data?.fdaSuccessRate,
          rank: data?.rank,
          score: data?.score.toFixed(2)
        }
      });
    });
    let result = formattedArray;
    if (searchText) {
      result = result.filter((hub) =>
        hub.hubName.toLowerCase().includes(searchText)
      );
    }
    switch (performanceFilter) {
      case HIGH_PLACEHOLDER:
        result = result.filter((i) => i.totalScore >= TOP_PERFORMANCE_SCORE);
        break;
      case AVG_PLACEHOLDER:
        result = result.filter(
          (i) =>
            i.totalScore >= AVG_PERFORMANCE_SCORE &&
            i.totalScore < TOP_PERFORMANCE_SCORE
        );
        break;
      case LOW_PLACEHOLDER:
        result = result.filter((i) => i.totalScore < AVG_PERFORMANCE_SCORE);
        break;
      default:
        break;
    }

    let deliverySR = 0,
      attempSR = 0,
      fda = 0,
      sla = 0;
    result.forEach((item) => {
      deliverySR += parseFloat(item.scoreCardInfo.deliverySuccessRate);
      attempSR += parseFloat(item.scoreCardInfo.attemptSuccessRate);
      fda += parseFloat(item.scoreCardInfo.fdaSuccessRate);
      sla += parseFloat(item.scoreCardInfo.businessPromiseSuccessRate);
    });
    return {
      result,
      count: result.length,
      highlights: {
        deliverySR: result.length
          ? (deliverySR / result.length).toFixed(2)
          : '0.00',
        attempSR: result.length
          ? (attempSR / result.length).toFixed(2)
          : '0.00',
        fda: result.length ? (fda / result.length).toFixed(2) : '0.00',
        sla: result.length ? (sla / result.length).toFixed(2) : '0.00'
      }
    };
  };

  const expandedInfoContent = (record) => [
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.daily_volume'
      }),
      value: record?.scoreCardInfo?.assignedVolume,
      isPercentage: false
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.dsr'
      }),
      value: parseFloat(record?.scoreCardInfo?.deliverySuccessRate)?.toFixed(2),
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.asr'
      }),
      value: parseFloat(record?.scoreCardInfo?.attemptSuccessRate)?.toFixed(2),
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.fda'
      }),
      value: parseFloat(record?.scoreCardInfo?.fdaSuccessRate)?.toFixed(2),
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.business_sla'
      }),
      value: parseFloat(
        record?.scoreCardInfo?.businessPromiseSuccessRate
      )?.toFixed(2),
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.route_dispatch_time'
      }),
      value: parseFloat(record?.scoreCardInfo?.routesDispatchRate)?.toFixed(2),
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.total_score'
      }),
      value: parseFloat(record?.scoreCardInfo?.score)?.toFixed(2),
      isPercentage: true
    }
  ];

  const getHubsPerformance = async ({ page, limit, searchInputText }) => {
    try {
      let dataToFormat = hubsData?.data;
      let weightsToShow = hubsData?.weights;
      if (
        dateFilter?.dateFrom !== prevDate?.dateFrom &&
        dateFilter?.dateTo !== prevDate?.dateTo
      ) {
        const { weights, allHubsScore } = await getAllHubsScores({
          ...dateFilter
        });
        dataToFormat = allHubsScore.filter((hub) => hub.score !== 0);
        weightsToShow = weights;
        setPrevDate(dateFilter);
        setTableFilters({
          ...dateFilter
        });
        setHubsData({
          data: dataToFormat,
          weights: weights
        });
      }

      setScoreCardWeights(weightsToShow);

      const { result, count, highlights } = formatAllHubsData(
        dataToFormat,
        performanceFilter,
        searchInputText
      );
      setPerformanceCardInfo(formatPerformanceCardInfo(dataToFormat));
      setCount(count);
      setHighlights(highlights);
      return {
        count: count,
        list: result
      };
    } catch (error) {
      notify(error.message);
      setCount(0);
      return {
        count: 0,
        list: []
      };
    }
  };

  const formatPerformanceCardInfo = (hubsScores = []) => {
    let performanceCard = {
      highCount: 0,
      averageCount: 0,
      lowCount: 0,
      totalCount: hubsScores.length
    };
    hubsScores.forEach((hub) => {
      if (hub.score >= TOP_PERFORMANCE_SCORE) {
        performanceCard.highCount++;
      } else if (
        hub.score >= AVG_PERFORMANCE_SCORE &&
        hub.score < TOP_PERFORMANCE_SCORE
      ) {
        performanceCard.averageCount++;
      } else {
        performanceCard.lowCount++;
      }
    });
    return performanceCard;
  };

  const COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.table.rank'
      }),
      dataIndex: 'rank',
      render: (rank) => rank || 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.table.hub_name'
      }),
      dataIndex: 'hubName',
      render: (hubName, record) =>
        (
          <span
            className="br-hubs-performance__hub-name-text"
            onClick={() =>
              history.push(
                `hub-performance?hubId=${record?._id}&startDate=${dateFilter?.dateFrom}&endDate=${dateFilter?.dateTo}`
              )
            }
          >
            {hubName}
          </span>
        ) || 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.table.assigned_volume'
      }),
      dataIndex: 'assignedVolume',
      render: (assignedVolume) => assignedVolume
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.table.total_score'
      }),
      dataIndex: 'totalScore',
      render: (totalScore) => totalScore
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.table.cost_per_shipment'
      }),
      dataIndex: 'costPerShipment',
      render: (costPerShipment) => `${costPerShipment} ${getCurrency().type}`
    }
  ];

  const data = [
    {
      type: 'top',
      value: performanceCardInfo?.highCount
    },
    {
      type: 'good',
      value: performanceCardInfo?.averageCount
    },
    {
      type: 'low',
      value: performanceCardInfo?.lowCount
    }
  ];

  const config = {
    appendPadding: 0,
    padding: 0,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,
    width: 156,
    height: 156,
    pieStyle: { lineWidth: 5, shadowBlur: 20 },
    color: ({ type }) =>
      type === 'top' ? '#039855' : type === 'good' ? '#FDB022' : '#F04438',
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        customHtml: () => (
          <div className="br-hubs-performance__donut-chart-inner">
            <span className="br-hubs-performance__donut-chart-inner__value">
              {performanceCardInfo?.totalCount}
            </span>
            <span className="br-hubs-performance__donut-chart-inner__text">
              {intl.formatMessage({
                id: 'bib.hubs_performance.total_hubs'
              })}
            </span>
          </div>
        )
      }
    },
    label: null,
    tooltip: false,
    legend: false
  };

  const chartLegendContent = [
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.top_performance'
      }),
      value: performanceCardInfo?.highCount || 0,
      class: 'top',
      payload: 'HIGH'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.good'
      }),
      value: performanceCardInfo?.averageCount || 0,
      class: 'good',
      payload: 'AVERAGE'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.low'
      }),
      value: performanceCardInfo?.lowCount || 0,
      class: 'low',
      payload: 'LOW'
    }
  ];

  const scoreCardsContent = [
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.dsr'
      }),
      value: scoreCardWeights?.deliverySuccessRateWeight || 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.asr'
      }),
      value: scoreCardWeights?.attemptSuccessRateWeight || 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.fda'
      }),
      value: scoreCardWeights?.fdaWeight || 'N/A'
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.business_sla'
      }),
      value: scoreCardWeights?.businessPromiseSLAWeight || 'N/A'
    }
  ];

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  const performanceFilterMapping = {
    HIGH: intl.formatMessage({
      id: 'bib.hubs_performance.top_performance'
    }),
    AVERAGE: intl.formatMessage({
      id: 'bib.hubs_performance.good'
    }),
    LOW: intl.formatMessage({
      id: 'bib.hubs_performance.low'
    })
  };

  const HIGHLIGHt_ANALYTICS = [
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.delivery_sr'
      }),
      value: highlights.deliverySR
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.attempt_sr'
      }),
      value: highlights.attempSR
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.fda_performance'
      }),
      value: highlights.fda
    },
    {
      title: intl.formatMessage({
        id: 'bib.hubs_performance.sla'
      }),
      value: highlights.sla
    }
  ];

  const handleExportClick = async () => {
    setIsLoading(true);
    try {
      const { message } = await exportHubsPerformanceTable(tableFilters);
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOnClickLegendFilter = async (legend) => {
    await onChange({ target: { value: legend?.payload } });
    updateTable();
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'sidebar.hubs_performance'
          })}
        />
      }
      content={
        <div className="br-hubs-performance-container">
          <LoadingWrapper loading={isLoading}>
            <div className="br-hub-performance__hub-date-filter-container br-hubs-performance-align-left">
              <HubPerformanceDateFilter setDateFilter={setDateFilter} />
            </div>

            <div className="br-hub-performance__card-container">
              <div className="br-hub-performance__card-rank-container br-hubs-performance_donut-chart-container">
                <Pie {...config} />
                <div className="br-hubs-performance__donut-chart-legend">
                  {chartLegendContent.map((legend) => (
                    <div
                      className="br-hubs-performance-donut-chart-legend-content"
                      onClick={() => handleOnClickLegendFilter(legend)}
                    >
                      <div
                        className={`br-hubs-performance__donut-chart-legend-indicator ${legend.class}`}
                      ></div>
                      {legend?.value} {legend?.title}
                    </div>
                  ))}
                </div>
              </div>
              <div className="br-hub-performance__higlight-card-container br-hubs-performance__rating-container">
                <h1>
                  {intl.formatMessage({
                    id: 'bib.hubs_performance.highlight_label'
                  })}
                </h1>
                <div className="br-hub-performance__higlight__highlight-content">
                  <div className="br-hub-performance__highlight-row">
                    {HIGHLIGHt_ANALYTICS.map(
                      (weight, index) =>
                        index < 2 && (
                          <div className="br-hub-performance__highlight-content-info">
                            <h2>{weight.value}%</h2>
                            <h4>{weight.title}</h4>
                          </div>
                        )
                    )}
                  </div>
                  <div className="br-hub-performance__highlight-row">
                    {HIGHLIGHt_ANALYTICS.map(
                      (weight, index) =>
                        index >= 2 && (
                          <div className="br-hub-performance__highlight-content-info">
                            <h2>{weight.value}%</h2>
                            <h4>{weight.title}</h4>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {performanceFilter && (
              <div className="br-hubs-performance__filter_tag_container">
                <span>
                  {intl.formatMessage({
                    id: 'common.filter'
                  })}
                  :
                </span>
                <span className="br-hubs-performance__filter-tag-content">
                  {' '}
                  {performanceFilterMapping[performanceFilter]}
                  <span
                    className="br-hubs-performance__filter-tag-remove"
                    onClick={handleOnClearFilterClick}
                  >
                    x
                  </span>
                </span>
              </div>
            )}

            <div className="br-hub-performance__table-container">
              <span className="br-hub-performance__table-header-text">
                {intl.formatMessage({
                  id: 'bib.hub_performance.star_performance_table.header'
                })}
              </span>
              <BRTable
                className="br-orders-table"
                searchPlaceholder={intl.formatMessage({
                  id: 'bib.hubs_performance.table.search_hub_placeholder'
                })}
                title={intl.formatMessage(
                  {
                    id: 'bib.hubs_performance.table.title'
                  },
                  {
                    count: count
                  }
                )}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className="br-hubs-performance__expand-container">
                      {expandedInfoContent(record).map((info) => (
                        <div className="br-hubs-performance__expand-content">
                          <span>{info?.title}</span>
                          <span>
                            {info?.value}
                            {info.isPercentage && '%'}
                          </span>
                        </div>
                      ))}
                    </div>
                  ),
                  expandRowByClick: true,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <ExpandOpenIcon onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <ExpandCloseIcon onClick={(e) => onExpand(record, e)} />
                    )
                }}
                columns={COLUMNS}
                showSearch
                showExport
                pageLimit={50}
                pageSizeOptions={[50, 100, 200]}
                listFunction={getHubsPerformance}
                onRowClick={() => {}}
                emptySearchContent={{
                  title: intl.formatMessage({
                    id: 'cashier.empty_search_title'
                  }),
                  subtitle: intl.formatMessage({
                    id: 'cashier.empty_search_sub_title'
                  })
                }}
                showCustomFilterComponent={filterComponent}
                showSearchFilter={false}
                preTableHeaderContent={
                  <div className="br-hubs-performance__pre-header-container">
                    <span>
                      {intl.formatMessage({
                        id: 'bib.hub_performance.score_card.title'
                      })}
                    </span>
                    {scoreCardsContent?.map((scoreCard) => (
                      <div className="br-hubs-performance__pre-header__score-card-content">
                        <span>{scoreCard?.title}</span>
                        <span>{scoreCard?.value}</span>
                      </div>
                    ))}
                  </div>
                }
                shareMethods={acceptMethods}
                exportListFileFunction={handleExportClick}
                showFilter={false}
              />
            </div>
          </LoadingWrapper>
        </div>
      }
    />
  );
};

export default injectIntl(withRouter(HubsPerformance));
