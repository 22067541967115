import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Table, Input } from 'antd';

import { INVENTORY_PAGE_SIZE_OPTIONS } from 'constants/fulfillment';

import BRFilter from 'components/BRFilter/BRFilter';

import { ReactComponent as SearchIcon } from 'assets/imgRevamp/search_icon.svg';
import { ReactComponent as ExportIcon } from 'assets/imgRevamp/ExportIcon.svg';
import { ReactComponent as TableEmptyIcon } from 'assets/imgRevamp/emptyTable.svg';

import './BRInventoryTable.less';

function BRInventoryTable({
  intl,
  columns,
  dataSource,
  filterItems,
  isLoading,
  fetchDeliveries,
  downloadAsCSV,
  count,
  pageId,
  setPageId,
  pageSize,
  setPageSize,
  searchPlaceholder,
  productCountFormat,
  emptyTableTitle,
  emptyTableButtonText,
  handleEmptyTableButton,
  showEmptyTableButton,
  searchTableInput,
  setSearchTableInput,
  filterOptions,
  setFilterOptions,
  businessId
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchInputText, setSearchInputText] = useState('');

  const rowSelection = {
    selectedRowKeys,
    onChange: (changableRowKey) => {
      setSelectedRowKeys(changableRowKey);
    }
  };

  const emptyTableContainer = {
    emptyText: (
      <div className="br-inventory-table__empty-container">
        <div className="br-inventory-table__empty-icon">
          <TableEmptyIcon />
        </div>
        <div className="br-inventory-table__empty-title">{emptyTableTitle}</div>
        {showEmptyTableButton && (
          <div>
            <Button
              type="primary"
              onClick={handleEmptyTableButton}
              className="br-inventory-table__empty-button"
            >
              {emptyTableButtonText}
            </Button>
          </div>
        )}
      </div>
    )
  };

  const tableHeader = () => {
    return (
      <div>
        <div className="br-inventory-table__search-container">
          <div className="br-inventory-table__product-count">
            {productCountFormat}
          </div>
          <Input.Search
            allowClear
            placeholder={searchPlaceholder}
            className="br-inventory-table__search-bar"
            suffix={!searchInputText.length && <SearchIcon />}
            disabled={
              isLoading ||
              (!dataSource?.length &&
                !filterOptions?.length &&
                !searchTableInput?.length)
            }
            defaultValue={searchTableInput}
            onSearch={fetchBySearch}
            onChange={({ target }) => setSearchInputText(target.value)}
          />

          <div className="br-inventory-table__search-bar-buttons">
            <div className="br-inventory-table__filter">
              <BRFilter
                filterItems={filterItems}
                value={filterOptions}
                isDisabled={
                  isLoading ||
                  (!dataSource?.length &&
                    !filterOptions?.length &&
                    !searchTableInput?.length)
                }
                onSubmitFilter={({ category }) => {
                  fetchByFilterOptions(category);
                }}
                selectedItems={filterOptions}
              />
            </div>

            <Button
              className="br-inventory-table__export"
              onClick={handleExport}
              disabled={isLoading || !dataSource.length}
            >
              <ExportIcon />
              {intl.formatMessage({
                id: 'common.export'
              })}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const fetchByFilterOptions = (filterOptions) => {
    setFilterOptions(filterOptions);
    fetchDeliveries(1, pageSize, filterOptions, searchTableInput, businessId);
  };

  const fetchBySearch = (searchInput) => {
    setSearchTableInput(searchInput);
    fetchDeliveries(1, pageSize, filterOptions, searchInput, businessId);
  };

  const handleExport = () => {
    downloadAsCSV(selectedRowKeys, filterOptions, searchTableInput, businessId);
  };

  const handlePaginationChange = async ({ current, pageSize }) => {
    setPageId(current);
    setPageSize(pageSize);
    fetchDeliveries(
      current,
      pageSize,
      filterOptions,
      searchTableInput,
      businessId
    );
  };

  return (
    <div className="br-inventory-table__container">
      <Table
        scroll={{ x: '100%' }}
        title={tableHeader}
        columns={columns}
        dataSource={dataSource}
        locale={emptyTableContainer}
        rowSelection={rowSelection}
        pagination={{
          total: count,
          current: pageId,
          pageSize: pageSize,
          showSizeChanger: INVENTORY_PAGE_SIZE_OPTIONS.length,
          pageSizeOptions: INVENTORY_PAGE_SIZE_OPTIONS,
          position: ['bottomCenter']
        }}
        loading={isLoading}
        onChange={handlePaginationChange}
      />
    </div>
  );
}

export default injectIntl(BRInventoryTable);
