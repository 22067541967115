import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Select, Tooltip, Tag } from 'antd';
import { Link } from 'react-router-dom';

import {
  DISPATCHING_ALL_HUBS_ACCESS,
  DISPATCHING_CARDS,
  DISPATCHING_ISSUES,
  DISPATCHING_STATES,
  DISPATCHING_TABLE_QUICK_FILTERS,
  INITIAL_OVERVIEW_SUMMARY,
  OVERVIEW_SUMMARY_OBJ_MAPPING
} from 'constants/dispatching';
import { ORDER_TYPES_STRING } from 'constants/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { isUserAuthorized } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';

import DispatchingCard from 'components/Dispatching/components/DispatchingCard/DispatchingCard';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as ExportIcon } from 'assets/imgRevamp/ActionCenterExportIcon.svg';

import './DispatchingHub.less';

const DispatchingHub = ({
  intl,
  selectedHubId,
  setSelectedHubId,
  setIsLoading,
  allHubs,
  pageType,
  fetchHubData,
  exportHubData
}) => {
  const [dispatchingData, setDispatchingData] = useState({
    ...INITIAL_OVERVIEW_SUMMARY,
    delayed: 0,
    rescheduled: 0,
    dueToday: 0,
    returns: 0
  });
  const [currentHub, setCurrentHub] = useState(null);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState(null);

  const refreshTableRef = useRef();

  const acceptMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const refreshDispatchingTable = () => {
    refreshTableRef.current({ pageNumber: 1 });
  };

  useEffect(() => {
    if (selectedHubId && currentHub) {
      refreshDispatchingTable();
    }
  }, [selectedHubId]);

  const renderHubsDropdown = allHubs?.map((option, index) => (
    <Select.Option key={index} value={option._id}>
      {option.name}
    </Select.Option>
  ));

  const getData = async ({ quickFiltersValue, page, limit }) => {
    setIsLoading(true);

    try {
      const payload = {
        hubId: selectedHubId,
        filterBy: quickFiltersValue,
        page: currentHub !== selectedHubId ? 1 : page,
        limit
      };
      const data = await fetchHubData(payload);

      const { list, ...rest } = data;

      setDispatchingData((prevState) => {
        const obj = { ...INITIAL_OVERVIEW_SUMMARY };
        Object.keys(obj).forEach((key) => {
          const objKey = OVERVIEW_SUMMARY_OBJ_MAPPING[pageType][key];
          obj[key] = rest[objKey];
          delete rest[objKey];
        });
        return { ...prevState, ...obj, ...rest };
      });
      setIsLoading(false);
      setSelectedQuickFilter(quickFiltersValue);
      setCurrentHub(selectedHubId);

      return {
        list,
        total: getTotalCount(data, quickFiltersValue)
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleExport = async () => {
    try {
      const payload = {
        hubId: selectedHubId
      };
      await exportHubData(payload);
      notify(
        intl.formatMessage({
          id: 'monitor_hub_operations.common.export_successful'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const renderComments = (data) => {
    const { dispatchingState, issues } = data;

    switch (dispatchingState) {
      case DISPATCHING_STATES.DELAYED:
        return (
          <Tooltip
            overlayClassName="br-dispatching__issues-tooltip"
            title={
              <div>
                {issues.map((issue, index) => (
                  <div key={index}>- {DISPATCHING_ISSUES[issue]}</div>
                ))}
              </div>
            }
            getPopupContainer={(trigger) => trigger.parentElement.parentElement}
          >
            <div className="br-dispatching__issues-tooltip-text">
              {issues.length}{' '}
              {intl.formatMessage({
                id: 'monitor_hub_operations.common.issues_title'
              })}
            </div>
          </Tooltip>
        );
      case DISPATCHING_STATES.RESCHEDULED:
        return (
          <Tag className="br-dispatching__issues-tag">
            {intl.formatMessage({
              id: 'audit_actions.order_summary_tooltip.rescheduled'
            })}
          </Tag>
        );
      default:
        return;
    }
  };

  const getTotalCount = (data, filter) => {
    const { delayed = 0, rescheduled = 0, dueToday = 0, returns = 0 } = data;
    switch (filter) {
      case DISPATCHING_STATES.DELAYED:
        return delayed;
      case DISPATCHING_STATES.RESCHEDULED:
        return rescheduled;
      case DISPATCHING_STATES.DUE_TODAY:
        return dueToday;
      case DISPATCHING_STATES.RETURN:
        return returns;
      default:
        return delayed + rescheduled + dueToday + returns;
    }
  };

  const getOrderType = (type, record) => {
    if (type === ORDER_TYPES_VALUE.EXCHANGE) {
      return record?.collectedFromConsignee
        ? ORDER_TYPES_STRING.EXCHANGE_RETURN
        : ORDER_TYPES_STRING.EXCHANGE_FORWARD;
    }
    if (type === ORDER_TYPES_VALUE.CRP) {
      return record?.collectedFromConsignee
        ? ORDER_TYPES_STRING.CRP_RETURN
        : ORDER_TYPES_STRING.CRP_FORWARD;
    }

    return ORDER_TYPES_STRING[type] || type;
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.tracking_no'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber, record) => (
        <Link target="_blank" to={`/deliveries/${record._id}/details`}>
          {trackingNumber}
        </Link>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.comments'
      }),
      dataIndex: '',
      render: (_, record) => renderComments(record)
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.shelf'
      }),
      dataIndex: 'shelf',
      render: (shelf) => (
        <div className="br-dispatching__table-shelf">
          {shelf ||
            intl.formatMessage({ id: 'monitor_hub_operations.common.unknown' })}
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.order_type'
      }),
      dataIndex: 'type',
      render: (type, record) => getOrderType(type, record)
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.age'
      }),
      dataIndex: 'age'
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.common.table_columns.attempts'
      }),
      dataIndex: 'attemptsCount'
    }
  ];

  return (
    <div className="br-dispatching__container">
      <div className="br-dispatching__cards-container">
        {DISPATCHING_CARDS({ dispatchingData, pageType }).map((card, index) => (
          <DispatchingCard
            key={index}
            title={card.title}
            cardColor={card?.cardColor}
            count={card?.count}
          />
        ))}
      </div>
      <div className="br-dispatching__content">
        <div className="br-dispatching__content-header">
          <div className="br-dispatching__content-title">
            {intl.formatMessage({
              id: `monitor_hub_operations.${pageType}.title`
            })}
          </div>
          <div className="br-dispatching__table-actions display-flex align-center">
            <BRButton
              className="br-dispatching__export-button"
              label={intl.formatMessage({ id: 'common.export' })}
              prefixIcon={<ExportIcon />}
              onClick={handleExport}
            />
            {isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
              ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
            ]) && (
              <div className="br-dispatching-hubs-filter">
                <Select
                  showSearch
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'action_center.select_hub_placeholder'
                  })}
                  value={selectedHubId}
                  onSelect={setSelectedHubId}
                  optionFilterProp="children"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {renderHubsDropdown}
                </Select>
              </div>
            )}
          </div>
        </div>
        <div className="br-dispatching__table-container">
          {selectedHubId && (
            <BRTable
              className="br-orders-table"
              columns={columns}
              onRowClick={() => {}}
              quickFilters={DISPATCHING_TABLE_QUICK_FILTERS({
                dispatchingData,
                pageType
              })}
              rowClassName={(record) =>
                record.dispatchingState === DISPATCHING_STATES.DELAYED
                  ? 'br-dispatching__table-delayed-row'
                  : ''
              }
              listFunction={getData}
              pageLimit={50}
              emptySearchContent={{
                title: intl.formatMessage({
                  id: 'cashier.empty_search_title'
                }),
                subtitle: intl.formatMessage({
                  id: 'cashier.empty_search_sub_title'
                })
              }}
              showSearchFilter={false}
              showFilter={false}
              shareMethods={acceptMethods}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DispatchingHub);
