import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { getActiveSessions } from 'services/auth';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import SessionsList from 'components/NewSettings/components/ActiveSessions/components/SessionsList/SessionsList';

const ActiveSessions = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentSession, setCurrentSession] = useState({});
  const [otherSessions, setOtherSessions] = useState([]);

  useEffect(() => {
    fetchActiveSessions();
  }, []);

  const fetchActiveSessions = async () => {
    setIsLoading(true);
    try {
      const { data } = await getActiveSessions();
      const otherSessionsData = data?.filter((session) => {
        if (session?.isCurrentSession) {
          setCurrentSession(session);
        } else {
          return session;
        }
      });
      setOtherSessions(otherSessionsData);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleRemoveSession = (sessionsIds) => {
    setOtherSessions((prev) =>
      prev.filter((session) => !sessionsIds.includes(session.sessionId))
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-active-sessions__container">
        <BRContentHeader
          isInternalComponent
          title={intl.formatMessage({ id: 'settings.active_sessions.title' })}
          subtitle={intl.formatMessage({
            id: 'settings.active_sessions.subtitle'
          })}
        />
        <SessionsList
          currentSession={currentSession}
          otherSessions={otherSessions}
          setIsLoading={setIsLoading}
          handleRemoveSession={handleRemoveSession}
        />
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(ActiveSessions);
