import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Tag } from 'antd';

import { getSerials } from 'services/fulfillment';
import {
  RETURN_ORDER_STATE_IDS,
  SERIAL_NUMBERS_TYPES,
  goToReturnOrdersList,
  handleChangeState
} from 'constants/fulfillment-return-orders';
import { openModal } from 'utils/modal';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import ScanInput from 'components/NewFulfillment/FulfillmentReturns/ScanSerialsModal/components/ScanInput/ScanInput';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import BRTable from 'components/BRTable/BRTable';

import DislikeBoxImg from 'assets/imgRevamp/DislikeBox.png';

import './DamageSerialsCheckModal.less';
import BREmptyState from 'components/BREmptyState/BREmptyState';

const DamageSerialsCheckModal = ({
  intl,
  roId,
  close,
  soId,
  products,
  trackingNumber,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allFetchedSerials, setAllFetchedSerials] = useState([]);
  const [notScannedSerials, setNotScannedSerials] = useState([]);
  const [scannedSerials, setScannedSerials] = useState([]);

  const counts = [
    intl.formatMessage(
      {
        id: 'fulfillment_returns.scan_damaged_serials_modal.counts.damaged'
      },
      {
        count: scannedSerials.length
      }
    )
  ];

  const columns = [
    {
      dataIndex: 'serialNumber',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.serial'
      }),
      render: (serialNumber) => (
        <span className="br-serial-number">{serialNumber}</span>
      )
    },
    {
      dataIndex: 'productSku',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.sku'
      }),
      render: (productSku) => (
        <span className="br-sku-column">{productSku}</span>
      )
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.status'
      }),
      render: ({ serialNumber, productSku }) => {
        const isScanned = scannedSerials.find(
          ({ serialNumber: sn }) => sn === serialNumber
        );

        const removeFromDamaged = () => {
          setScannedSerials((prev) =>
            prev.filter(({ serialNumber: sn }) => sn !== serialNumber)
          );
          setNotScannedSerials((prev) => [
            ...prev,
            {
              serialNumber,
              productSku
            }
          ]);
        };

        return (
          isScanned && (
            <Tag
              className="br-damage-serial-tag"
              onClose={removeFromDamaged}
              closable
            >
              {intl.formatMessage({
                id: 'fulfillment_returns.return_orders_table.columns.damaged'
              })}
            </Tag>
          )
        );
      }
    }
  ];

  const onConfirm = async () => {
    setIsLoading(true);

    const linesObj = {};
    scannedSerials.forEach(({ roLineId, serialNumber }) => {
      const currLine = linesObj[roLineId];
      linesObj[roLineId] = currLine
        ? [...currLine, serialNumber]
        : [serialNumber];
    });

    try {
      await handleChangeState({
        stateId: RETURN_ORDER_STATE_IDS.READY_TO_PUTAWAY,
        roId,
        lines: products.map(({ id, serialsCount }) => {
          const damagedSerials = linesObj[id] || [];
          return {
            id,
            quantity: serialsCount - damagedSerials.length,
            damagedSerials
          };
        }),
        successModalProps: {
          title: intl.formatMessage(
            {
              id: 'fulfillment_returns.scan_damaged_serials_modal.success_modal.title'
            },
            {
              trackingNumber
            }
          ),
          cancelButtonLabel: intl.formatMessage({
            id: 'fulfillment_returns.scan_damaged_serials_modal.success_modal.cancel_btn'
          }),
          cancelAction: goToReturnOrdersList,
          onCancel: null
        }
      });
      close();
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const confirmOnUserBeforeConfirmReturn = () => {
    openModal(BRConfirmationModal, {
      wrapClassName: 'br-confirm-po__confirmation-modal',
      icon: <img src={DislikeBoxImg} alt="box" />,
      message: (
        <>
          <div className="text-gray subtitle-md">
            {intl.formatMessage(
              {
                id: 'fulfillment_returns.scan_damaged_serials_modal.confirmation_modal.title'
              },
              {
                count: scannedSerials.length
              }
            )}
          </div>
          <span>
            {intl.formatMessage(
              {
                id: 'fulfillment_returns.scan_damaged_serials_modal.confirmation_modal.content'
              },
              {
                trackingNumber: (
                  <span className="br-confirm-po__confirmation-modal__teal-color">{`${trackingNumber}?`}</span>
                )
              }
            )}
          </span>
        </>
      ),
      onConfirm,
      confirmButtonText: intl.formatMessage({
        id: 'fulfillment_returns.scan_damaged_serials_modal.confirm_btn'
      }),
      cancelButtonText: intl.formatMessage({
        id: 'common.back'
      }),
      confirmButtonType: 'primary',
      width: 430
    });
  };

  const renderFooter = () => {
    return (
      <>
        <div className="br-po-select-products-modal__footer__counts">
          {counts.map((count, index) => (
            <span key={index}>{count}</span>
          ))}
        </div>
        <div className="br-po-select-products-modal__footer__actions">
          <BRButton
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            onClick={close}
            loading={isLoading}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'fulfillment_returns.scan_damaged_serials_modal.confirm_btn'
            })}
            loading={isLoading}
            onClick={
              scannedSerials.length
                ? confirmOnUserBeforeConfirmReturn
                : onConfirm
            }
          />
        </div>
      </>
    );
  };

  const fetchSerials = async () => {
    setIsLoading(true);

    try {
      const payload = {
        roId,
        serialsType: SERIAL_NUMBERS_TYPES.CONFIRMED
      };
      const { data } = await getSerials(payload);
      setAllFetchedSerials(data.serials);
      setNotScannedSerials(data.serials);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSerials();
  }, []);

  return (
    <Modal
      {...restProps}
      wrapClassName="br-damage-serials-modal br-po-select-products-modal"
      title={intl.formatMessage({
        id: 'fulfillment_returns.scan_damaged_serials_modal.title'
      })}
      footer={renderFooter()}
    >
      <LoadingWrapper
        loading={isLoading}
        className="br-print-serials-modal__loading-wrapper"
      >
        <ScanInput
          scannedSerials={scannedSerials}
          setScannedSerials={setScannedSerials}
          notScannedSerials={notScannedSerials}
          setNotScannedSerials={setNotScannedSerials}
          allFetchedSerials={allFetchedSerials}
        />
        <BRTable
          columns={columns}
          listingData={[...scannedSerials, ...notScannedSerials]}
          showFilter={false}
          pagination={false}
          emptyState={() => (
            <BREmptyState
              hideImage
              title={intl.formatMessage({
                id: 'common.no_data'
              })}
            />
          )}
        />
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(DamageSerialsCheckModal);
