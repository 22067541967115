import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const sendToIntegration = (payload) => {
  return http.post('/integrations/create-bulk', payload);
};

export const printIntegrationAwb = (payload) => {
  return http.post('/integrations/print-awbs', payload, downloadHeader);
};

export const printShipoxAwb = (payload) => {
  return http.post('/integrations/print-shipox-awbs', payload);
};

export const getThreePLProviders = () => {
  return http.get('/integrations/list-providers');
};
