import { Modal, Form, Input, Select } from 'antd';
import { useIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

const CreateEditCodeModal = ({
  close,
  onSubmit,
  isEdit,
  selectedCode,
  hubStars = [],
  ...props
}) => {
  const intl = useIntl();

  const onFinish = (values) => {
    onSubmit(values);
    close();
  };

  const formatStarsOptions = () => {
    if (hubStars.length) {
      return hubStars.map((star) => ({
        value: star._id,
        label: `${star.profile?.firstName} ${star.profile?.lastName}`
      }));
    }
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `bosta_coding.codes_geofencing.create_edit_modal.${
          isEdit ? 'edit_title' : 'title'
        }`
      })}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            htmlType="submit"
            form="createEditCodeGeofencing"
            type="primary"
            label={intl.formatMessage({
              id: isEdit ? 'common.save' : 'common.create'
            })}
          />
        </>
      }
      {...props}
    >
      <Form
        onFinish={onFinish}
        initialValues={{ ...selectedCode }}
        name="createEditCodeGeofencing"
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({
            id: 'bosta_coding.codes_geofencing.create_edit_modal.name_label'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.codes_geofencing.create_edit_modal.name_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          name="nameAr"
          label={intl.formatMessage({
            id: 'bosta_coding.codes_geofencing.create_edit_modal.name_ar_label'
          })}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.codes_geofencing.create_edit_modal.name_ar_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          name={['assignedStar', '_id']}
          label={intl.formatMessage({
            id: 'bosta_coding.codes_geofencing.create_edit_modal.star_label'
          })}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={formatStarsOptions()}
            placeholder={intl.formatMessage({
              id: 'bosta_coding.codes_geofencing.create_edit_modal.star_placeholder'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditCodeModal;
