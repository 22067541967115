import { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { getFulfillmentReturnToVendorSKUSerials } from 'services/fulfillment-return-to-vendor';
import { RTV_LINE_TYPES } from 'constants/fulfillment-return-to-vendor';

import ScanInput from 'components/NewFulfillment/FulfillmentReturns/ScanSerialsModal/components/ScanInput/ScanInput';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './ScanModal.less';

const ScanModal = ({
  serials,
  allSerialsCount,
  close,
  onConfirm,
  sku,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scannedSerials, setScannedSerials] = useState(serials);
  const [allFetchedSerials, setAllFetchedSerials] = useState([]);

  const removeItem = (serial) => {
    setScannedSerials((prev) =>
      prev.filter(({ serialNumber }) => serialNumber !== serial)
    );
  };

  const onOk = () => {
    onConfirm(scannedSerials.map(({ serialNumber }) => serialNumber));
    close();
  };

  const fetchSerials = async () => {
    setIsLoading(true);

    const payload = {
      sku,
      serialsType: RTV_LINE_TYPES.ALL
    };

    try {
      const { data } = await getFulfillmentReturnToVendorSKUSerials(payload);

      const { onShelf = [], damaged = [] } = data.serials || {};
      setAllFetchedSerials([...onShelf, ...damaged]);
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSerials();
  }, []);

  return (
    <Modal
      {...restProps}
      wrapClassName="br-scan-modal"
      onOk={onOk}
      onCancel={close}
      okButtonProps={{ disabled: isLoading }}
    >
      <LoadingWrapper loading={isLoading}>
        <ScanInput
          scannedSerials={scannedSerials}
          setScannedSerials={setScannedSerials}
          allFetchedSerials={allFetchedSerials}
          allSerialsCount={allSerialsCount}
          disabled={isLoading}
        />
        {scannedSerials.map(({ serialNumber }) => (
          <div key={serialNumber} className="br-scan-modal__item">
            <span>{serialNumber}</span>
            <CloseIcon
              className="clickable"
              onClick={() => removeItem(serialNumber)}
            />
          </div>
        ))}
      </LoadingWrapper>
    </Modal>
  );
};

export default ScanModal;
