import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Button, DatePicker, Popconfirm } from 'antd';

import {
  starCollectCashMoney,
  collectAllCash,
  exportStarCashRecord
} from 'services/stars';
import { openModal } from 'utils/modal';
import { downloadAsXlsx } from 'utils/download';
import { isUserAuthorized, isDefaultBostaCourier } from 'utils/helpers';
import { PAGE_LIMIT, FIRST_PAGE } from 'constants/stars';
import aclMatrix, {
  SUPER_ADMIN,
  ACCOUNTANT,
  VENDOR_MANAGER
} from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isSaudi } from 'constants/helper';

import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import SettlementModal from '../SettlementModal/SettlementModal';
import BRButton from 'components/BRButton/BRButton';
import InternationalOrdersTag from 'components/InternationalOrders/components/InternationalOrdersTag/InternationalOrdersTag';

import './CashRecords.less';

const CashRecords = ({
  match: {
    params: { id: starId }
  },
  history,
  intl,
  listData,
  listFunction,
  getStarWallet,
  count
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const startDateQuery = new URLSearchParams(history?.location?.search)?.get(
    'startDate'
  );
  const endDateQuery = new URLSearchParams(history?.location?.search)?.get(
    'endDate'
  );
  const [exportDates, setExportDates] = useState({
    exportDateFrom: startDateQuery
      ? dayjs(startDateQuery).format('YYYY-MM-DD')
      : null,
    exportDateTo: endDateQuery ? dayjs(endDateQuery).format('YYYY-MM-DD') : null
  });
  const handlePagination = async (pageId, dates) => {
    setPageNumber(pageId);
    const { list } = await listFunction({
      pageId,
      dates: {
        startDate: dates
          ? dates?.startDate === -1
            ? null
            : dates?.startDate
          : exportDates?.exportDateFrom,
        endDate: dates
          ? dates?.endDate === -1
            ? null
            : dates?.endDate
          : exportDates?.exportDateTo
      }
    });
    list.length ? setPageNumber(pageId) : setPageNumber(0);
  };

  const cashRecordsColumns = [
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.amount`
      }),
      dataIndex: 'amount'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.business`
      }),
      dataIndex: ['business', 'name']
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.tracking_no`
      }),
      dataIndex: '',
      render: (delivery) => (
        <div className="br-star__international-order-icon">
          <span> {delivery?.trackingNumber}</span>
          {delivery?.isInternationalCashRecord && <InternationalOrdersTag />}
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.money_source`
      }),
      dataIndex: 'src'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.collected_at`
      }),
      dataIndex: 'createdAt',
      render: (createdAt) => dayjs(createdAt).tz().format('DD MMM YYYY  h:mm a')
    },

    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.notes`
      }),
      dataIndex: 'description'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.actions.title`
      }),
      dataIndex: '',
      render: (delivery) => (
        <div className="display-flex">
          {delivery?.deliveryI || isUserAuthorized(ACCOUNTANT) ? (
            <>
              {delivery?.deliveryId && (
                <Button>
                  <a
                    href={`/deliveries/${delivery?.deliveryId}/details`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: `stars.star_details.actions.delivery_details`
                    })}
                  </a>
                </Button>
              )}
              {isUserAuthorized(ACCOUNTANT) && (
                <Button
                  className="br-cash-records__actions__export ml-2"
                  onClick={() => handleCollectCashMoney(delivery?._id)}
                >
                  {intl.formatMessage({
                    id: `stars.star_details.actions.collect`
                  })}
                </Button>
              )}
            </>
          ) : (
            'N/A'
          )}
        </div>
      )
    }
  ];

  const handleCollectCashMoney = async (MoneyId) => {
    try {
      const { message } = await starCollectCashMoney(starId, {
        cashMoneyId: MoneyId
      });
      notify(message, 'success');
      await getStarWallet();
      handlePagination(FIRST_PAGE);
    } catch (error) {
      notify(error.message);
    }
  };

  const collectAllStarCash = async () => {
    try {
      await collectAllCash(starId);
      handlePagination(FIRST_PAGE);
      await getStarWallet();
      notify(
        intl.formatMessage({
          id: `stars.star_details.notification_messages.collect_cash_success`
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const onClickAddSettlement = useCallback(() => {
    openModal(SettlementModal, {
      starId,
      onSuccess: async () => {
        handlePagination(FIRST_PAGE);
        await getStarWallet();
      }
    });
  }, []);

  const handleExportDates = (_, dateValues) => {
    setExportDates({
      exportDateFrom: dateValues[0] || null,
      exportDateTo: dateValues[1] || null
    });
    handlePagination(
      FIRST_PAGE,
      dateValues[0] && dateValues[1]
        ? { startDate: dateValues[0], endDate: dateValues[1] }
        : { startDate: -1, endDate: -1 }
    );
  };

  const handleExportData = async () => {
    if (Object.values(exportDates).length) {
      try {
        const data = await exportStarCashRecord(starId, { ...exportDates });
        notify(
          intl.formatMessage({
            id: `stars.star_details.notification_messages.star_transaction_loading_success`
          }),
          'success'
        );
        if (data) {
          downloadAsXlsx(data, `StarsTransactions.xlsx`);
        }
      } catch (error) {
        notify(error.message);
      }
    } else {
      notify(
        intl.formatMessage({
          id: `stars.star_details.notification_messages.empty_export_date_range`
        })
      );
    }
  };
  return (
    <div className="br-cash-records">
      <div className="br-cash-records__actions">
        <span>
          <DatePicker.RangePicker
            size="small"
            onChange={handleExportDates}
            defaultValue={
              startDateQuery && [
                dayjs(startDateQuery),
                dayjs(endDateQuery).subtract(isSaudi() ? 3 : 2, 'hours')
              ]
            }
          />
        </span>
        {isUserAuthorized(
          [ACCOUNTANT, SUPER_ADMIN],
          [ACL_MATRIX.CASH_MONEY_ADD_SETTLEMENT]
        ) && (
          <BRButton
            onClick={onClickAddSettlement}
            label={intl.formatMessage({
              id: `stars.star_details.actions.add_settlement`
            })}
          />
        )}
        {isUserAuthorized(
          [ACCOUNTANT, SUPER_ADMIN],
          [ACL_MATRIX.CASH_MONEY_COLLECT]
        ) && (
          <Popconfirm
            title={intl.formatMessage({
              id: `stars.star_details.actions.confirm`
            })}
            onConfirm={collectAllStarCash}
            okText={intl.formatMessage({
              id: `stars.star_details.actions.collect_all`
            })}
            cancelText={intl.formatMessage({
              id: `stars.star_details.actions.cancel`
            })}
          >
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: `stars.star_details.actions.collect_all`
              })}
            />
          </Popconfirm>
        )}
        <Button
          className="br-cash-records__actions__export"
          onClick={handleExportData}
          disabled={
            !isUserAuthorized(aclMatrix.STARS_EXPORT_REPORT, [
              ACL_MATRIX.CASH_MONEY_EXPORT
            ])
          }
        >
          {intl.formatMessage({
            id: `stars.star_details.actions.export`
          })}
        </Button>
      </div>

      <BRSearchableTable
        title={intl.formatMessage({
          id: `stars.star_details.cash_records`
        })}
        columns={cashRecordsColumns}
        count={count}
        listData={listData}
        handlePagination={handlePagination}
        pageLimit={PAGE_LIMIT}
        pageNumber={pageNumber}
        onRowClick={() => {}}
        withOutCheckBoxes
      />
    </div>
  );
};

export default withRouter(injectIntl(CashRecords));
