import * as yup from 'yup';

export const paymentTypes = {
  FAWRY_DEPOSIT: 1,
  FAWRY_WITHDRAW: 2,
  BOSTA_OFFICE_DEPOSIT: 3,
  CHEQUE: 4,
  BANK_TRANSFER: 5,
  CASH: 6,
  COD: 7
};

export const deliverySchema = yup.array().of(
  yup.object().shape({
    trackingNumber: yup.string().required(),
    paidAmt: yup.string().required(),
    paymentTypeMapped: yup
      .mixed()
      .oneOf(Object.entries(paymentTypes).map((item) => item[1]))
      .required(),
    paymentType: yup
      .mixed()
      .oneOf(Object.entries(paymentTypes).map((item) => item[0]))
      .required(),
    businessId: yup.string().notRequired(),
    discount: yup.string().notRequired(),
    outOfFlierFees: yup.string().notRequired()
  })
);

export const amazonDeliverySchema = yup.array().of(
  yup.object().shape({
    trackingNumber: yup.string().required(),
    paidAmt: yup.string().required()
  })
);
