import { useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { HubsContext } from 'contexts/hub.context';
import { openModal } from 'utils/modal';

import BRTabs from 'components/BRTabs/BRTabs';
import ScanSealsAndBins from 'components/HubOperations/HubTransfer/components/ScanSealsAndBins/ScanSealsAndBins';
import DispatchSeals from 'components/HubOperations/HubTransfer/components/DispatchSeals/DispatchSeals';
import BRButton from 'components/BRButton/BRButton';
import GenerateBulkySealsModal from './components/GenerateBulkySealsModal/GenerateBulkySealsModal';

import './HubTransfer.less';

const DEFAULT_TAB_PATH = 'scan-seals';

const HubTransfer = ({ intl, match: { path } }) => {
  const { scopedHubs, allHubs } = useContext(HubsContext);

  const TABS = {
    scan_seals: {
      label: intl.formatMessage({
        id: 'hubs.hub_transfer.tabs.transfer_seals'
      }),
      component: ScanSealsAndBins,
      path: '/hubs/hub-operation/transfer-to-hub/scan-seals'
    },
    dispatch: {
      label: intl.formatMessage({
        id: 'hubs.hub_transfer.tabs.dispatch'
      }),
      component: DispatchSeals,
      path: '/hubs/hub-operation/transfer-to-hub/dispatch'
    }
  };

  const handleGenerateBulkySealClick = () => {
    openModal(GenerateBulkySealsModal, {
      hubs: scopedHubs,
      allHubs
    });
  };

  return (
    <Switch>
      <Route
        path={[
          '/hubs/hub-operation/transfer-to-hub/scan-seals',
          '/hubs/hub-operation/transfer-to-hub/dispatch'
        ]}
        render={() => (
          <div className="br-hub-transfer__container">
            <div className="br-hub-transfer__header-title heading">
              {intl.formatMessage({ id: 'hubs.hub_transfer.title' })}
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.generate_bulky_seals_modal.title'
                })}
                onClick={handleGenerateBulkySealClick}
              />
            </div>
            <div className="br-hub-transfer__tabs">
              <BRTabs tabs={TABS} />
            </div>
          </div>
        )}
      />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default withRouter(injectIntl(HubTransfer));
