import { injectIntl } from 'react-intl';

import {
  FULFILLMENT_LOCATION_LEVELS_KEYS,
  getLevelChildKey
} from 'constants/fulfillment-locations';

import LocationCard from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/LocationsListing/components/LocationCard/LocationCard';

import './LocationsListing.less';

const LocationsListing = ({ intl, currentLevel, list }) => {
  const { LOCATION, SHELF } = FULFILLMENT_LOCATION_LEVELS_KEYS || {};
  const levelChild = getLevelChildKey(currentLevel);
  const isShelf = currentLevel === SHELF;
  const hasParent = currentLevel !== LOCATION;

  return (
    <div className="br-fulfillment-locations-listing">
      {list.map(({ label, id }) => (
        <LocationCard
          key={id}
          title={label}
          currentLevelId={id}
          hasChild={!isShelf}
          hasParent={hasParent}
          subtitle={intl.formatMessage({
            id: `fulfillment_locations.location_levels.${levelChild}`
          })}
        />
      ))}
    </div>
  );
};

export default injectIntl(LocationsListing);
