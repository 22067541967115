import UploadOrdersTemplate from 'assets/Bosta Upload Template.V2.7.xlsx';
import UploadFXFOrdersTemplate from 'assets/Bosta Fulfillment Upload Template.V4.2.xlsx';
import UploadOrdersTemplateKSA from 'assets/Bosta Upload Template KSA.V2.7.xlsx';

export const STAR_PERFORMANCE_DEFAULT_HUB_FILTER_VALUE = {
  EG: 'Mohandeseen hub',
  SA: 'Riyadh Hub'
};

export const MASS_UPLOAD_TEMPLATE = {
  EG: {
    DEFAULT: UploadOrdersTemplate,
    FULFILLMENT: UploadFXFOrdersTemplate
  },
  SA: {
    DEFAULT: UploadOrdersTemplateKSA
  }
};

export const PACKAGE_TYPE_VIEW = {
  DEFAULT: true,
  SA: false
};

export const AWB_REPACK = {
  EG: true,
  SA: false
};
export const AWB_LANGUAGE = {
  EG: 'ar',
  SA: 'en',
  DEFAULT: 'ar'
};

export const MAP_DEFAULT_LOCATION = {
  EG: { lat: 29.9563293, lng: 31.2720199 },
  SA: { lat: 24.774265, lng: 46.738586 },
  DEFAULT: { lat: 29.9563293, lng: 31.2720199 }
};

export const PACKAGING_FREE_ITEMS_AVAILABLE = {
  EG: true,
  SA: false
};

export const BOSTA_MATERIAL_BUSINESS_ACCOUNTS_ID = {
  EG: 'HnRWYTmEgoBELeiPd',
  SA: '2LbbTCDYIZC0xq8iPkh0M'
};

export const MANAGE_PACKAGING_MATERIAL_AVAILABILTY = {
  EG: true,
  SA: false
};

export const PRICE_COLUMN_IN_PACKAGING_MATERIAL_AVAILABILTY = {
  EG: false,
  SA: true
};

export const OPEN_PACKAGE_FEES_AVAILABILTY = {
  EG: true,
  SA: false
};

export const INTERNATIONAL_SHIPPING_AVAILABILTY = {
  EG: true,
  SA: false,
  AE: true
};

export const PACKAGE_SIZE_REQ_PAYLOAD = {
  EG: true,
  SA: false
};
