import { useEffect, useRef, useState } from 'react';
import { Form, Collapse, Select, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { formatOptions } from 'utils';
import {
  FORMAT_DATE_TO_UTC,
  cleanEmptyString,
  trimObjectStrings
} from 'utils/helpers';
import { fetchStars } from 'services/live-ops-priorities';
import { getHubs } from 'services/hubs';
import { ROUTES_STATE, ROUTES_STATES } from 'constants/route';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './RoutesSearch.less';

const RoutesSearch = ({ handleOnSubmit, isLoading }) => {
  const [stars, setStars] = useState([]);
  const [hubs, setHubs] = useState([]);

  const formRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    getStars();
    fetchHubs();

    formRef.current.setFieldsValue({ states: [ROUTES_STATE.UNASSIGNED] });
  }, []);

  const getStars = async () => {
    try {
      const { message } = await fetchStars();

      setStars(
        formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          message?.stars || []
        )
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchHubs = async () => {
    try {
      const { result } = await getHubs();
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = (values) => {
    if (values.createdAt) {
      values.createdAt = FORMAT_DATE_TO_UTC(values.createdAt);
    }
    handleOnSubmit(trimObjectStrings(cleanEmptyString(values)));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleOnSubmit({});
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <Icon
          component={DropDownIcon}
          rotate={isActive ? 0 : -90}
          className="ant-icon-xl"
        />
      )}
    >
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'common.search'
        })}
      >
        <Form onFinish={onFinish} ref={formRef}>
          <div className="br-filters-search__row">
            <Form.Item
              name="states"
              label={intl.formatMessage({
                id: 'routes.routes_listing.filters.route_state'
              })}
            >
              <Select
                mode="multiple"
                options={ROUTES_STATES}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="starId"
              label={intl.formatMessage({
                id: 'hubs.packages_debrief.star_label'
              })}
            >
              <Select
                allowClear
                showSearch
                options={stars}
                optionFilterProp="label"
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="createdAt"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.createdAt'
              })}
            >
              <DatePicker />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="warehouses"
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.hub'
              })}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                options={hubs}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-routes-search__actions">
            <div>
              <BRButton
                type="primary"
                htmlType="submit"
                label={intl.formatMessage({ id: 'common.search' })}
                disabled={isLoading}
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleResetFilters}
                disabled={isLoading}
              />
            </div>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default RoutesSearch;
