import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Input, Radio } from 'antd';

import {
  enableOrDisableFulfillmentForProducts,
  getFulfillmentProductNames
} from 'services/products';
import { openModal } from 'utils/modal';
import {
  ADD_PRODUCT_SEARCH_OPTIONS,
  PRODUCTS_SEARCH_OPTIONS_IDS
} from 'constants/fulfillment-products';

import BREmptyState from 'components/BREmptyState/BREmptyState';
import SelectProductsModal from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/SelectProductsModal/SelectProductsModal';
import { notify } from 'components/Notify/Notify';
import EmptyState from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/EmptyState/EmptyState';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as SearchIcon } from 'assets/bosta-icons/Search.svg';

import './AddProductModal.less';

const AddProductModal = ({
  intl,
  businesses = [],
  close,
  refreshTable,
  ...restProps
}) => {
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [searchResults, setSearchResults] = useState(businesses);

  const onCancel = () => {
    close();
  };

  const onBusinessSelect = ({ target: { value } }) => {
    setSelectedBusinessId(value);
  };

  const onSearch = ({ target: { value } }) => {
    setSearchResults(
      businesses.filter(({ name }) =>
        name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const addProductsToBusiness = async (list) => {
    const payload = {
      enable: true,
      productsIds: list.map(({ key }) => key),
      businessId: selectedBusinessId
    };

    try {
      await enableOrDisableFulfillmentForProducts(payload);
      notify(
        intl.formatMessage({
          id: 'fulfillment_products.select_products_modal.success_msg'
        }),
        'success'
      );
      refreshTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const onOk = () => {
    openModal(SelectProductsModal, {
      checkById: true,
      selectedBusiness: {
        _id: selectedBusinessId
      },
      onSuccess: addProductsToBusiness,
      okText: intl.formatMessage({
        id: 'fulfillment_products.select_products_modal.confirm'
      }),
      getCounts: (selectedProducts) => [
        intl.formatMessage(
          {
            id: 'fulfillment_products.select_products_modal.selected_products_count'
          },
          {
            count: selectedProducts.length
          }
        )
      ],
      columns: [
        {
          dataIndex: 'productImage',
          render: (productImage) => <ProductImage src={productImage} />,
          width: 60
        },
        {
          dataIndex: 'productName',
          ellipsis: true
        }
      ],
      listKey: 'products',
      fetchData: getFulfillmentProductNames,
      formatData: (list) =>
        list.map(({ name, nameAr, id, defaultImage, ...rest }) => ({
          productName: name || nameAr,
          id,
          key: id,
          productImage: defaultImage,
          ...rest
        })),
      renderEmptyState: () => (
        <EmptyState
          title={intl.formatMessage({
            id: 'fulfillment_products.select_products_modal.empty_state.title'
          })}
        />
      ),
      searchOptions: ADD_PRODUCT_SEARCH_OPTIONS,
      searchOptionsIds: PRODUCTS_SEARCH_OPTIONS_IDS,
      defaultSearchOptionKey: PRODUCTS_SEARCH_OPTIONS_IDS.PRODUCT_NAME.KEY
    });

    onCancel();
  };

  const renderFooter = () => (
    <>
      <BRButton
        label={intl.formatMessage({
          id: 'common.cancel'
        })}
        onClick={onCancel}
      />
      <BRButton
        type="primary"
        label={intl.formatMessage({
          id: 'common.continue'
        })}
        disabled={!selectedBusinessId}
        onClick={onOk}
      />
    </>
  );

  return (
    <Modal
      {...restProps}
      wrapClassName="br-add-product-modal"
      title={intl.formatMessage({
        id: 'fulfillment_products.add_product_modal.title'
      })}
      onCancel={onCancel}
      footer={renderFooter()}
    >
      <div className="br-add-product-modal__search-container">
        <Input
          placeholder={intl.formatMessage({
            id: 'fulfillment_products.add_product_modal.search_placeholder'
          })}
          onChange={onSearch}
          suffix={<SearchIcon />}
          allowClear
        />
      </div>
      <Radio.Group
        options={searchResults.map(({ name, nameAr, _id }) => ({
          label: name || nameAr,
          value: _id
        }))}
        onChange={onBusinessSelect}
        value={selectedBusinessId}
      />
      {!!businesses.length && !searchResults.length && <BREmptyState search />}
    </Modal>
  );
};

export default injectIntl(AddProductModal);
