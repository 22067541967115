import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get as getValue, omitBy } from 'lodash';
import { Dropdown, Menu, Table, Tag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Accordion, Badge, Button, Col, Form, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { exportXlsx } from 'services/deliveries';
import { openModal } from 'utils/modal';
import {
  cancelDeliveryWithReason,
  markAsLostOrDamaged,
  restartDeliveredDelivery,
  redispatchRTODelivery
} from 'actions/DeliveriesActions';

import { badgesColors, showBill, downloadAsXlsx } from 'utils';
import {
  UNDERCOVERED_ZONE,
  deliveriesStates,
  ALLOWED_STATES_TO_RESET
} from 'constants/Deliveries';
import { notify } from 'components/Notify/Notify';
import Calendar from 'components/Calendar';
import {
  addDeliveriesToRoute,
  addDeliveryComment,
  cancelDelivery,
  clearAirWayBill,
  getAllActiveRoutes,
  getAllCities,
  getAllDeliveries,
  getAllDistricts,
  getAllHubs,
  getAllStars,
  getAllZones,
  printBill,
  rerequestCancelledDelivery,
  returnToBusiness,
  terminateDelivery,
  resetDeliveredDelivery,
  resetDeliveredDeliveries
} from 'actions';
import { FORMAT_DATE_TO_UTC } from 'utils/helpers';
import { DATE_PAYLOAD } from 'utils/deliveries';
import { formatSignAndReturnType } from 'utils/deliveries';

import {
  deliveriesColumnsMapped,
  deliveryTypes,
  formValidationSchema,
  initialFormValues,
  locatedInHubState,
  tablePageSize,
  transitionState
} from 'containers/Deliveries/constants';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { getAllPriceSize } from 'services/priceTiers';

import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';

import 'containers/Deliveries/styles.less';
import styles from 'containers/Deliveries/styles.module.scss';

/**
 * format options used to be bound in the search form lookups
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue };
    });
  }
  return data.map((item) => ({ value: item, label: item }));
};

/**
 * actions menu to be used in the table's action column
 * @param item
 * @param options
 * @returns {*}
 */

const actionsMenu = (item, options) => (
  <Menu>
    {/* <Menu.Item onClick={() => {
      options.history.push(`/deliveries/${item._id}/details`);
    }}>Show</Menu.Item> */}

    <Menu.Item
      onClick={() => {
        options.printAirWayBill({
          ids: item._id,
          trackingNumber: item.trackingNumber
        });
      }}
    >
      Print airway bill
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        options.setProposedCommentedDelivery(item);
        options.setOpenCommentModal(true);
      }}
    >
      Add comment
    </Menu.Item>
    <Menu.Item
      onClick={() => options.cancelDeliveryAsUncoveredZone([item._id])}
    >
      Uncovered Zone
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        if (item.type !== 'CASH_COLLECTION') {
          options.setProposedLostDelivery(item);
          options.setOpenLostModal(true);
        } else {
          notify(
            'You can not mark delivery of type "Cash Collection" as lost or damaged!'
          );
        }
      }}
    >
      Mark delivery as lost or damaged
    </Menu.Item>
    {!item.isConfirmedDelivery &&
    ALLOWED_STATES_TO_RESET.includes(item?.state?.code) ? (
      <Menu.Item
        onClick={() => {
          options.setOpenResetModal(true);
          options.setProposedResetDelivery(item);
        }}
      >
        Reset
      </Menu.Item>
    ) : null}
    {/* <Menu.Item
      onClick={() => {
        options.setProposedCancelledDelivery(item);
        options.setOpenCancelModal(true);
      }}
      disabled={
        item.state.value === 'Canceled' ||
        item.state.value === 'Pickup requested' ||
        item.type === 'CASH_COLLECTION'
      }
    >
      Cancel
    </Menu.Item> */}
    <Menu.Item
      onClick={() => {
        options.setProposedRerequestedDelivery(item);
        options.setOpenRerequestModal(true);
      }}
      disabled={item?.state?.value !== 'Canceled'}
    >
      Re-request
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        options.setProposedRedispatchDelivery(item);
        options.setOpenRedispatchModal(true);
      }}
      disabled={item?.type !== 'RTO'}
    >
      Re-dispatch
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        options.setProposedTerminateDelivery(item);
        options.setOpenTerminateModal(true);
      }}
    >
      Terminate
    </Menu.Item>
  </Menu>
);

/**
 * format address string
 * @param addressItems
 * @returns {*}
 */
const formatAddress = (addressItems) =>
  addressItems.reduce((acc, value) => {
    if (!acc) {
      return value;
    }
    if (value) {
      return `${acc}, ${value}`;
    }
    return acc;
  }, '');

/**
 * format data to bound in the table
 * @param data
 * @param options
 * @returns {*}
 */
const formatData = (data, options) => {
  const { intl } = options;
  return data.map((item) => {
    if (item.type === 'INTERNATIONAL') item.type = 'SEND';
    let receiverDetails;
    if (item.type === 'RTO' || item.type === 'CUSTOMER_RETURN_PICKUP') {
      receiverDetails = item.receiver
        ? `${item.receiver?.name || ''} ${item.receiver?.phone || ''}`
        : '-';
    } else {
      receiverDetails = item.receiver
        ? `${item.receiver.firstName || ''} ${item.receiver.lastName || ''} ${
            item.receiver.phone || ''
          }`
        : '-';
    }
    const timeDiff = dayjs
      .duration(
        dayjs(new Date(item.sla?.e2eSla?.e2eSlaTimestamp)).diff(
          dayjs(new Date())
        )
      )
      .days();
    const nearToSLAPeriod =
      item?.state?.value !== 'Delivered' && timeDiff >= -2 && timeDiff <= 0;
    return {
      key: item._id,
      id: item._id,
      business: `${getValue(item, 'sender.name', '')} ${getValue(
        item,
        'sender.phone',
        ''
      )}`,
      trackingNumber: (
        <Link
          to={`/deliveries/${item._id}/details`}
          style={{ textDecoration: 'underline' }}
          target="_blank"
        >
          {item.trackingNumber}
        </Link>
      ),
      createdAt: dayjs(new Date(item.createdAt)).tz().format('LLL'),
      pickedUpAt: item.collectedFromBusiness
        ? dayjs(new Date(item.collectedFromBusiness)).tz().format('LLL')
        : 'N/A',
      updatedAt: item.updatedAt
        ? dayjs(new Date(item.updatedAt)).tz().format('LLL')
        : 'N/A',
      e2eSLADate: item.sla?.e2eSla?.e2eSlaTimestamp ? (
        <span
          className={`p-1 ${
            item.sla?.e2eSla?.isExceededE2ESla
              ? styles.exceedSLA
              : nearToSLAPeriod
              ? styles.nearToSLA
              : ''
          }`}
        >
          {dayjs(new Date(item.sla?.e2eSla?.e2eSlaTimestamp)).tz().format('LL')}
        </span>
      ) : (
        'N/A'
      ),
      orderSLADate: item.sla?.orderSla?.orderSlaTimestamp ? (
        <span
          className={`p-1 ${
            item.sla?.orderSla?.isExceededOrderSla ? styles.exceedSLA : ''
          }`}
        >
          {dayjs(new Date(item.sla?.orderSla?.orderSlaTimestamp))
            .tz()
            .format('LL')}
        </span>
      ) : (
        'N/A'
      ),
      scheduledAt: item.scheduledAt
        ? dayjs(new Date(item.scheduledAt)).tz().format('LL')
        : 'N/A',
      state: (
        <>
          {item.state ? (
            <Tag className={deliveryStateFormatter.getStateColor(item)}>
              {deliveryStateFormatter.getStateName(item)}
            </Tag>
          ) : (
            'N/A'
          )}
          <p>Attempts: {item.attemptsCount || 0}</p>
        </>
      ),
      warehouseTransit: item.warehouseTransit
        ? `In Transit to: ${item.warehouseTransit?.destinationWarehouse?.name}`
        : 'N/A',
      // item.warehouseTransit !== null ? item.warehouseTransit : false,
      hub: item.state?.receivedAtWarehouse?.warehouse?.name || 'N/A',
      locationInHub: item.state?.receivedAtWarehouse?.location || 'N/A',
      assignedHub: item.assignedHub?.name || 'N/A',
      type:
        item.type === 'SIGN_AND_RETURN' || item.type === 'RTO'
          ? formatSignAndReturnType(item)
          : getValue(item, 'type', ''),
      linkedDelivery: getValue(item, 'linkedDelivery.trackingNumber', '--'),
      receiverDetails,
      pickupAddress: formatAddress([
        getValue(item, 'pickupAddress.buildingNumber', ''),
        getValue(item, 'pickupAddress.firstLine', ''),
        getValue(item, 'pickupAddress.secondLine', ''),
        getValue(item, 'pickupAddress.district.name', ''),
        getValue(item, 'pickupAddress.zone.name', ''),
        getValue(item, 'pickupAddress.city.name', '')
      ]),
      dropOffAddress: formatAddress([
        getValue(item, 'dropOffAddress.buildingNumber', ''),
        getValue(item, 'dropOffAddress.firstLine', ''),
        getValue(item, 'dropOffAddress.secondLine', ''),
        getValue(item, 'dropOffAddress.district.name', ''),
        getValue(item, 'dropOffAddress.zone.name', ''),
        getValue(item, 'dropOffAddress.city.name', '')
      ]),
      cancelledAt: item.cancelledAt
        ? dayjs(new Date(item.cancelledAt)).tz().format('LLL')
        : 'N/A',
      cod:
        item.cod && item.cod.amount
          ? `${intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              { cod: item.cod.amount }
            )} `
          : 'N/A',
      starInfo: item.star ? `${item.star.name}, ${item.star.phone}` : 'N/A',
      // holderInfo: item.holder ? `${item.holder.name}, ${item.holder.phone}` : "",
      priceBeforeVat: item?.pricing
        ? ` ${intl.formatMessage(
            {
              id: COUNTRY_CURRENCY
            },
            { cod: item?.pricing?.priceBeforeVat }
          )}`
        : 'N/A',
      priceAfterVat: item?.pricing
        ? ` ${intl.formatMessage(
            {
              id: COUNTRY_CURRENCY
            },
            { cod: item?.pricing?.priceAfterVat }
          )}`
        : 'N/A',
      businessRefNumber: item.businessReference || 'N/A',
      notes: item.notes ? item.notes : 'N/A',
      comments: item.comments ? item.comments : 'N/A',
      action: (
        <Dropdown overlay={actionsMenu(item, options)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      )
    };
  });
};

/**
 * Date range picker used in search form
 * @param name
 * @param label
 * @param handleChange
 * @param value
 * @returns {*}
 * @constructor
 */
const DateRangePicker = ({ name, label, handleChange, value }) => {
  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates && dates.length > 0) handleChange(`${name}`, dateStrings, true);
    else {
      handleChange(`${name}`, [null, null], true);
    }
  };

  return (
    <>
      <Form.Group as={Col} sm={3} controlId={`${name}-control`}>
        <Form.Label className={styles.formLabel}>{label}</Form.Label>
        <Calendar
          handleChange={handleDateRangeChange}
          placeholder={['', '']}
          value={value[0] !== null ? [dayjs(value[0]), dayjs(value[1])] : null}
          size="medium"
        />
      </Form.Group>
    </>
  );
};

/**
 * Search form used to apply filter and search deliveries
 * @param businesses
 * @param stars
 * @param hubs
 * @param handleFormChange
 * @param handleFormSubmit
 * @param handleResetForm
 * @param formLoading
 * @returns {*}
 * @constructor
 */
const SearchForm = ({
  stars,
  hubs,
  // handleFormChange,
  handleFormSubmit,
  handleResetForm,
  // formValues,
  formLoading,
  intl
}) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const handleFormChange = () => {};
  const typeRef = useRef(null);
  const stateRef = useRef(null);
  const packageSizeRef = useRef(null);
  const hubRef = useRef(null);
  const transitRef = useRef(null);
  const exceptionRef = useRef(null);
  const businessRef = useRef(null);
  const starRef = useRef(null);
  const locatedInHubRef = useRef(null);
  const isInvalidBusinessRef = useRef(null);
  const exceededSLARef = useRef(null);
  const withinSLARef = useRef(null);
  const fakeAttemptRef = useRef(null);
  const onHoldRef = useRef(null);
  const withinDeliverySLARef = useRef(null);
  const exceededDeliverySLARef = useRef(null);
  const ofdByStarRef = useRef(null);
  const inProgressRef = useRef(null);
  const assignedHubRef = useRef(null);
  const clearCurrentControls = () => {
    typeRef.current.select.clearValue();
    isInvalidBusinessRef.current.select.clearValue();
    stateRef.current.select.clearValue();
    packageSizeRef.current.select.clearValue();
    hubRef.current.select.clearValue();
    transitRef.current.select.clearValue();
    exceptionRef.current.select.clearValue();
    businessRef.current.select.clearValue();
    starRef.current.select.clearValue();
    locatedInHubRef.current.select.clearValue();
    exceededSLARef.current.select.clearValue();
    withinSLARef.current.select.clearValue();
    fakeAttemptRef.current.select.clearValue();
    onHoldRef.current.select.clearValue();
    withinDeliverySLARef.current.select.clearValue();
    exceededDeliverySLARef.current.select.clearValue();
    ofdByStarRef.current.select.clearValue();
    inProgressRef.current.select.clearValue();
    assignedHubRef.current.select.clearValue();
  };
  const [allPackageSizes, setAllPackageSizes] = useState([]);
  const getAllSizes = useCallback(async () => {
    try {
      const response = await getAllPriceSize();
      let formattedArray = [];
      response?.data?.sizes?.map((size) => {
        formattedArray.push({ value: size?.name, label: size?.name });
      });
      setAllPackageSizes(formattedArray);
    } catch (error) {
      notify(error.message);
    }
  }, []);
  useEffect(() => {
    getAllSizes();
  }, []);
  return (
    <>
      <Accordion>
        <div>
          <Accordion.Toggle
            as="a"
            variant="button"
            eventKey="0"
            onClick={() => setOpenAccordion(!openAccordion)}
            className={
              openAccordion
                ? styles.SearchToggleOpen
                : styles.SearchToggleClosed
            }
          >
            Search
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div>
              <Formik
                className="formContainer"
                enableReinitialize
                validationSchema={formValidationSchema}
                onChange={handleFormChange}
                onSubmit={handleFormSubmit}
                initialValues={initialFormValues}
                render={({
                  // handleSubmit,
                  // handleChange,
                  handleBlur,
                  values,
                  // touched,
                  initialValues,
                  setFieldValue,
                  // errors
                  resetForm
                }) => (
                  <Form
                    className={styles.formWidth}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleFormSubmit(values);
                    }}
                  >
                    {/* tracking number, delivery type, delivery state */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="trackingNumber-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Tracking Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.trackingNumbers || ''}
                          onChange={(evt) => {
                            handleFormChange(
                              'trackingNumbers',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'trackingNumbers',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="trackingNumbers"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="deliveryType-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Delivery Type
                        </Form.Label>
                        <Select
                          ref={typeRef}
                          name="deliveryType"
                          options={deliveryTypes}
                          // value={values.type}
                          onChange={(evt) => {
                            handleFormChange('type', evt?.value);
                            setFieldValue('type', evt?.value);
                          }}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="packageSize-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Package Size
                        </Form.Label>

                        <Select
                          ref={packageSizeRef}
                          options={allPackageSizes}
                          // value={values.state || ''}
                          name="packageSize"
                          onChange={(evt) => {
                            handleFormChange(
                              'packageSize',
                              evt ? evt.map((item) => item.value) : []
                            );
                            setFieldValue(
                              'packageSize',
                              evt ? evt.map((item) => item.value) : []
                            );
                          }}
                          defaultValue={initialValues.packageSize}
                          isMulti
                          isClearable
                        ></Select>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="deliveryState-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Delivery State
                        </Form.Label>
                        <Select
                          ref={stateRef}
                          options={deliveriesStates}
                          // value={values.state || ''}
                          name="deliveryState"
                          onChange={(evt) => {
                            handleFormChange(
                              'stateCodes',
                              evt ? evt.map((item) => item.value) : []
                            );
                            setFieldValue(
                              'stateCodes',
                              evt ? evt.map((item) => item.value) : []
                            );
                          }}
                          defaultValue={initialValues.state}
                          isMulti
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>
                    {/* hub, transit state, exception by */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} sm={4} controlId="hub-control">
                        <Form.Label className={styles.formLabel}>
                          Hub
                        </Form.Label>
                        <Select
                          ref={hubRef}
                          name="hub"
                          options={formatOptions('_id', 'name', hubs)}
                          onChange={(evt) => {
                            // handleFormChange('hubs', evt?.value);
                            // setFieldValue('hubs', evt?.value);
                            handleFormChange(
                              'hubs',
                              evt ? evt.map((item) => item.value) : []
                            );
                            setFieldValue(
                              'hubs',
                              evt ? evt.map((item) => item.value) : []
                            );
                          }}
                          defaultValue={initialValues?.hub}
                          onBlur={handleBlur}
                          isMulti
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="inTransitBetweenHubs-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          In transition between hubs
                        </Form.Label>
                        <Select
                          ref={transitRef}
                          // value={values.inTransit || ''}
                          options={transitionState}
                          isClearable
                          name="inTransitBetweenHubs"
                          onChange={(evt) => {
                            handleFormChange('inTransit', evt?.value);
                            setFieldValue('inTransit', evt?.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="exceptionBy-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Exception By
                        </Form.Label>
                        <Select
                          ref={exceptionRef}
                          name="exceptionBy"
                          // value={values.exceptionBy}
                          options={
                            stars && stars.data
                              ? formatOptions(
                                  '_id',
                                  'profile.firstName,profile.lastName',
                                  stars.data
                                )
                              : []
                          }
                          onChange={(evt) => {
                            handleFormChange(
                              'exceptionBy',
                              evt ? evt.map((item) => item.value) : []
                            );
                            setFieldValue(
                              'exceptionBy',
                              evt ? evt.map((item) => item.value) : []
                            );
                          }}
                          isMulti
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>
                    <hr />
                    {/* business / business ref number */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} sm={3} controlId="business-control">
                        <Form.Label className={styles.formLabel}>
                          Business
                        </Form.Label>
                        <BusinessSelector
                          mode="multiple"
                          onChange={(value) => {
                            handleFormChange('businessId', value);
                            setFieldValue('businessId', value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={3} controlId="id-control">
                        <Form.Label className={styles.formLabel}>
                          {intl.formatMessage({
                            id: `deliveries.search_filters.business_id`
                          })}
                        </Form.Label>
                        <Form.Control
                          value={values.id || ''}
                          type="text"
                          onChange={(evt) => {
                            handleFormChange('id', evt.currentTarget.value);
                            setFieldValue('id', evt.currentTarget.value);
                          }}
                          onBlur={handleBlur}
                          name="id"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="businessRefNumber-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Business Reference Number
                        </Form.Label>
                        <Form.Control
                          value={values.businessReference || ''}
                          type="text"
                          onChange={(evt) => {
                            handleFormChange(
                              'businessReference',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'businessReference',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="businessRefNumber"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="is-invalid-business-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Is Invalid Business
                        </Form.Label>
                        <Select
                          ref={isInvalidBusinessRef}
                          name="is_invalid_business"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('is_invalid_business', evt?.value);
                            setFieldValue('is_invalid_business', evt?.value);
                          }}
                          defaultValue={initialValues.is_invalid_business}
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>

                    {/* Customer info (first name, last name, phone) */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="customerFirstName-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Customer First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.customersFirstNames || ''}
                          onChange={(evt) => {
                            handleFormChange(
                              'customersFirstNames',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'customersFirstNames',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="customersFirstNames"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="customerLastName-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Customer Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.customersLastNames || ''}
                          onChange={(evt) => {
                            handleFormChange(
                              'customersLastNames',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'customersLastNames',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="customersLastNames"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="customerPhone-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Customer Phone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.mobilePhones || ''}
                          onChange={(evt) => {
                            handleFormChange(
                              'mobilePhones',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'mobilePhones',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="mobilePhones"
                        />
                      </Form.Group>
                    </Form.Row>
                    <hr />

                    {/* Dates section */}
                    <Form.Row className={styles.formRow}>
                      <DateRangePicker
                        label="Picked At:"
                        handleChange={(_, value) => {
                          setFieldValue(`pickedUpAtStart`, value[0]);
                          setFieldValue(`pickedUpAtEnd`, value[1]);
                        }}
                        name="pickedUpAt"
                        value={[values.pickedUpAtStart, values.pickedUpAtEnd]}
                      />
                      <DateRangePicker
                        label="Delivered At:"
                        handleChange={(_, value) => {
                          setFieldValue(`deliveredAtStart`, value[0]);
                          setFieldValue(`deliveredAtEnd`, value[1]);
                        }}
                        name="deliveredAt"
                        value={[values.deliveredAtStart, values.deliveredAtEnd]}
                      />
                      <DateRangePicker
                        label="Exception At:"
                        handleChange={(_, value) => {
                          setFieldValue(`exceptionAtStart`, value[0]);
                          setFieldValue(`exceptionAtEnd`, value[1]);
                        }}
                        name="expectationAt"
                        value={[values.exceptionAtStart, values.exceptionAtEnd]}
                      />
                      <DateRangePicker
                        label="Cancelled At:"
                        handleChange={(_, value) => {
                          setFieldValue(`canceledAtStart`, value[0]);
                          setFieldValue(`canceledAtEnd`, value[1]);
                        }}
                        name="cancelledAt"
                        value={[values.canceledAtStart, values.canceledAtEnd]}
                      />
                      <DateRangePicker
                        label="Created At:"
                        handleChange={(_, value) => {
                          setFieldValue(`createdAtStart`, value[0]);
                          setFieldValue(`createdAtEnd`, value[1]);
                        }}
                        name="createdAt"
                        value={[values.createdAtStart, values.createdAtEnd]}
                      />
                      <DateRangePicker
                        label="Lost At:"
                        handleChange={(_, value) => {
                          setFieldValue(`lostAtStart`, value[0]);
                          setFieldValue(`lostAtEnd`, value[1]);
                        }}
                        name="lostAt"
                        value={[values.lostAtStart, values.lostAtEnd]}
                      />
                      <DateRangePicker
                        label="Damaged At:"
                        handleChange={(_, value) => {
                          setFieldValue(`damagedAtStart`, value[0]);
                          setFieldValue(`damagedAtEnd`, value[1]);
                        }}
                        name="damagedAt"
                        value={[values.damagedAtStart, values.damagedAtEnd]}
                      />
                      <DateRangePicker
                        label="OFD At:"
                        handleChange={(_, value) => {
                          setFieldValue(`ofdAtStart`, value[0]);
                          setFieldValue(`ofdAtEnd`, value[1]);
                        }}
                        name="damagedAt"
                        value={[values.ofdAtStart, values.ofdAtEnd]}
                      />
                      <DateRangePicker
                        label="Investigation At:"
                        handleChange={(_, value) => {
                          setFieldValue(`investigationAtStart`, value[0]);
                          setFieldValue(`investigationAtEnd`, value[1]);
                        }}
                        name="investigationAt"
                        value={[
                          values.investigationAtStart,
                          values.investigationAtEnd
                        ]}
                      />
                      <DateRangePicker
                        label="Scheduled At:"
                        handleChange={(_, value) => {
                          setFieldValue(`scheduledAtStart`, value[0]);
                          setFieldValue(`scheduledAtEnd`, value[1]);
                        }}
                        name="scheduledAt"
                        value={[values.scheduledAtStart, values.scheduledAtEnd]}
                      />
                      <DateRangePicker
                        label="E2E SLA At:"
                        handleChange={(_, value) => {
                          setFieldValue(`slaAtStart`, value[0]);
                          setFieldValue(`slaAtEnd`, value[1]);
                        }}
                        name="slaAt"
                        value={[values.slaAtStart, values.slaAtEnd]}
                      />
                      <DateRangePicker
                        label="Delivery Promise SLA At:"
                        handleChange={(_, value) => {
                          setFieldValue(`deliverySlaAtStart`, value[0]);
                          setFieldValue(`deliverySlaAtEnd`, value[1]);
                        }}
                        name="deliverySlaAt"
                        value={[
                          values.deliverySlaAtStart,
                          values.deliverySlaAtEnd
                        ]}
                      />
                      <DateRangePicker
                        label="Collected From Consignee:"
                        handleChange={(_, value) => {
                          setFieldValue(`collectedAtStart`, value[0]);
                          setFieldValue(`collectedAtEnd`, value[1]);
                        }}
                        name="collectedAt"
                        value={[values.collectedAtStart, values.collectedAtEnd]}
                      />
                    </Form.Row>
                    <hr />

                    {/* Stars / number of attempts */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} sm={4} controlId="star-control">
                        <Form.Label className={styles.formLabel}>
                          Stars
                        </Form.Label>
                        <Select
                          ref={starRef}
                          name="stars"
                          // value={values.starId}
                          options={
                            stars && stars.data
                              ? formatOptions(
                                  '_id',
                                  'profile.firstName,profile.lastName',
                                  stars.data
                                )
                              : []
                          }
                          onChange={(evt) => {
                            handleFormChange(
                              'starId',
                              evt ? evt.map((item) => item.value) : []
                            );
                            setFieldValue(
                              'starId',
                              evt ? evt.map((item) => item.value) : []
                            );
                          }}
                          isMulti
                          defaultValue={initialValues.starId}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="numberOfAttempts-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Number of Attempts
                        </Form.Label>
                        <Form.Control
                          value={values.numberOfAttempts || ''}
                          type="number"
                          onChange={(evt) => {
                            handleFormChange(
                              'numberOfAttempts',
                              evt.currentTarget.value
                            );
                            setFieldValue(
                              'numberOfAttempts',
                              evt.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          name="numberOfAttempts"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="location-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Located In Hub
                        </Form.Label>
                        <Select
                          ref={locatedInHubRef}
                          name="locatedInHub"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('locatedInHub', evt?.value);
                            setFieldValue('locatedInHub', evt?.value);
                          }}
                          defaultValue={initialValues.locatedInHub}
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className={styles.formRow}>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="withinSLA-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Active deliveries within E2E SLA
                        </Form.Label>
                        <Select
                          ref={withinSLARef}
                          name="withinSLA"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('withinSLA', evt?.value);
                            setFieldValue('withinSLA', evt?.value);
                          }}
                          defaultValue={initialValues.withinSLA}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="exceededSLA-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Active deliveries exceeded E2E SLA
                        </Form.Label>
                        <Select
                          ref={exceededSLARef}
                          name="exceededSLA"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('exceededSLA', evt?.value);
                            setFieldValue('exceededSLA', evt?.value);
                          }}
                          defaultValue={initialValues.exceededSLA}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="fakeAttempt-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Deliveries with fake attempts
                        </Form.Label>
                        <Select
                          ref={fakeAttemptRef}
                          name="fakeAttempt"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('fakeAttempt', evt?.value);
                            setFieldValue('fakeAttempt', evt?.value);
                          }}
                          defaultValue={initialValues.fakeAttempt}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="onHold-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          On Hold
                        </Form.Label>
                        <Select
                          ref={onHoldRef}
                          name="onHold"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('onHold', evt?.value);
                            setFieldValue('onHold', evt?.value);
                          }}
                          defaultValue={initialValues.onHold}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="withinDeliverySLA-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Active deliveries within delivery promise SLA
                        </Form.Label>
                        <Select
                          ref={withinDeliverySLARef}
                          name="withinDeliverySLA"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('withinDeliverySLA', evt?.value);
                            setFieldValue('withinDeliverySLA', evt?.value);
                          }}
                          defaultValue={initialValues.withinDeliverySLA}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="exceededDeliverySLA-in-hub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Active deliveries exceeded delivery promise SLA
                        </Form.Label>
                        <Select
                          ref={exceededDeliverySLARef}
                          name="exceededDeliverySLA"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('exceededDeliverySLA', evt?.value);
                            setFieldValue('exceededDeliverySLA', evt?.value);
                          }}
                          defaultValue={initialValues.exceededDeliverySLA}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4} controlId="OFDByStar-control">
                        <Form.Label className={styles.formLabel}>
                          OFD By Star
                        </Form.Label>
                        <Select
                          ref={ofdByStarRef}
                          name="OFDByStar"
                          // value={values.starId}
                          options={
                            stars && stars.data
                              ? formatOptions(
                                  '_id',
                                  'profile.firstName,profile.lastName',
                                  stars.data
                                )
                              : []
                          }
                          onChange={(evt) => {
                            handleFormChange('OFDByStar', evt?.value);
                            setFieldValue('OFDByStar', evt?.value);
                          }}
                          defaultValue={initialValues.OFDByStar}
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="inProgress-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          In Progress
                        </Form.Label>
                        <Select
                          ref={inProgressRef}
                          name="inProgress"
                          // value={values.starId}
                          options={locatedInHubState}
                          onChange={(evt) => {
                            handleFormChange('inProgress', evt?.value);
                            setFieldValue('inProgress', evt?.value);
                          }}
                          defaultValue={initialValues.inProgress}
                          isClearable
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="assignedHub-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          Assigned Hub
                        </Form.Label>
                        <Select
                          ref={assignedHubRef}
                          name="assignedHub"
                          options={formatOptions('_id', 'name', hubs)}
                          onChange={(evt) => {
                            handleFormChange('assignedHub', evt?.value);
                            setFieldValue('assignedHub', evt?.value);
                          }}
                          defaultValue={initialValues?.assignedHub}
                          onBlur={handleBlur}
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>
                    {/* Note */}
                    <Form.Row className={styles.formRow}>
                      <Form.Group as={Col} sm={4} controlId="note-control">
                        <Form.Label className={styles.formLabel}>
                          Note
                        </Form.Label>
                        <Form.Control
                          value={values.notes || ''}
                          type="text"
                          onChange={(evt) => {
                            handleFormChange('notes', evt.currentTarget.value);
                            setFieldValue('notes', evt.currentTarget.value);
                          }}
                          onBlur={handleBlur}
                          name="note"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        controlId="callsNumber-control"
                      >
                        <Form.Label className={styles.formLabel}>
                          No. of Calls
                        </Form.Label>
                        <Form.Control
                          type="number"
                          value={values.callsNumber || ''}
                          onChange={(evt) => {
                            if (evt.currentTarget.value >= 0) {
                              handleFormChange(
                                'callsNumber',
                                evt.currentTarget.value
                              );
                              setFieldValue(
                                'callsNumber',
                                evt.currentTarget.value
                              );
                            }
                          }}
                          min="0"
                          onBlur={handleBlur}
                          name="callsNumber"
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4} controlId="smsNumber-control">
                        <Form.Label className={styles.formLabel}>
                          No. of SMSes
                        </Form.Label>
                        <Form.Control
                          type="number"
                          value={values.smsNumber || ''}
                          onChange={(evt) => {
                            if (evt.currentTarget.value >= 0) {
                              handleFormChange(
                                'smsNumber',
                                evt.currentTarget.value
                              );
                              setFieldValue(
                                'smsNumber',
                                evt.currentTarget.value
                              );
                            }
                          }}
                          min="0"
                          onBlur={handleBlur}
                          name="smsNumber"
                        />
                      </Form.Group>
                    </Form.Row>
                    {/* submit area */}
                    <div>
                      <Button
                        type="submit"
                        className="primaryButton"
                        disabled={formLoading}
                      >
                        Filter
                      </Button>
                      <Button
                        type="button"
                        className="secondaryButton"
                        onClick={() => {
                          handleResetForm();
                          resetForm(initialValues);
                          // setFieldValue("pickedUpAtStart", null)
                          // setFieldValue("pickedUpAtEnd", null)
                          // setFieldValue("deliveredAtStart", null)
                          // setFieldValue("deliveredAtEnd", null)
                          // setFieldValue("exceptionAtStart", null)
                          // setFieldValue("exceptionAtEnd", null)
                          // setFieldValue("canceledAtStart", null)
                          // setFieldValue("canceledAtEnd", null)
                          // setFieldValue("createdAtStart", null)
                          // setFieldValue("createdAtEnd", null)
                          clearCurrentControls();
                        }}
                        disabled={formLoading}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

/**
 * Cancel delivery reason and confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @returns {*}
 * @constructor
 */
const CancelDeliveryReasonModal = ({ show, cancel, confirm }) => {
  const [reason, setReason] = useState('');
  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Delivery Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please add reason for why to cancel this delivery</p>
          <input
            type="text"
            className="form-control"
            required
            onChange={(evt) => setReason(evt.currentTarget.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={cancel}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={reason === ''}
            onClick={() => {
              confirm(reason);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/**
 * Lost or Damaged change state modal with related data (users or hubs)
 * @param show
 * @param cancel
 * @param confirm
 * @param options
 * @returns {*}
 * @constructor
 */
const LostOrDamagedDeliveryModal = ({
  show,
  cancel,
  confirm,
  options = { stars: [], hubs: [] }
}) => {
  const [type, setType] = useState(null);
  const [takenBy, setTakenBy] = useState(null);
  const [data, setData] = useState(null);

  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Lost Or Damaged</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose Type</p>
          <Select
            className="mb-2"
            value={type}
            required
            onChange={(evt) => {
              setType(evt);
              setData(null);
            }}
            placeholder="Select a type"
            options={formatOptions(null, null, ['Lost', 'Damaged'])}
          />
          <Select
            className="mb-2"
            value={takenBy}
            required
            onChange={(evt) => {
              setTakenBy(evt);
              setData(null);
            }}
            placeholder="Action taken by"
            options={formatOptions(null, null, ['Hub', 'Star'])}
          />
          {takenBy && (
            <Select
              value={data}
              required
              onChange={(evt) => {
                setData(evt);
              }}
              placeholder={`Select ${takenBy.value}`}
              options={formatOptions(
                '_id',
                takenBy?.value === 'Star'
                  ? 'profile.firstName,profile.lastName'
                  : 'name',
                takenBy?.value === 'Star' ? options.stars : options.hubs
              )}
              isClearable
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={() => {
              cancel();
              setType(null);
              setTakenBy(null);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={!type || !data}
            onClick={() => {
              confirm(type.value, {
                starId: takenBy.value === 'Star' ? data.value : undefined,
                warehouseId: takenBy.value !== 'Star' ? data.value : undefined
              });
              setType(null);
              setTakenBy(null);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/**
 * general purpose confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @param title
 * @param body
 * @param disableConfirm
 * @param confirmText
 * @returns {*}
 * @constructor
 */
const ConfirmationModal = ({
  show,
  cancel,
  confirm,
  title,
  body,
  disableConfirm,
  confirmText = 'Confirm'
}) => (
  <>
    <Modal show={show} onHide={cancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="secondaryButton mt-0"
          onClick={cancel}
        >
          Close
        </Button>
        <Button
          variant="primary"
          className="primaryButton mt-0"
          onClick={() => {
            confirm();
          }}
          disabled={
            typeof disableConfirm !== 'undefined' ? !disableConfirm : false
          }
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

/**
 * add comment and confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @returns {*}
 * @constructor
 */
const AddCommentToDeliveryModal = ({ show, cancel, confirm }) => {
  const [comment, setComment] = useState('');
  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please add comment to this delivery</p>
          <input
            type="text"
            className="form-control"
            required
            onChange={(evt) => setComment(evt.currentTarget.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={cancel}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={comment === ''}
            onClick={() => {
              confirm(comment);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const IvestigationOrders = (props) => {
  const {
    fetchHubs,
    fetchDeliveries,
    deliveries,
    loading,
    history,
    printAirWayBill,
    clearBill,
    airWayBill,
    cancelDeliveryWithReason,
    addCommentToDelivery,
    rerequestDelivery,
    fetchStars,
    // fetchActiveRoutes,
    // activeRoutes,
    // returnDeliveriesToBusiness,
    // addToRoute,
    terminateDeliveries,
    resetDelivery,
    stars,
    hubs,
    intl
  } = props;
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  // eslint-disable-next-line no-unused-vars
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  // const [state, setState] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openCancelMultiModal, setOpenCancelMultiModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openExceptionModal, setOpenExceptionModal] = useState(false);
  const [openLostModal, setOpenLostModal] = useState(false);
  const [openRerequestModal, setOpenRerequestModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openRestartModal, setOpenRestartModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [openRedispatchModal, setOpenRedispatchModal] = useState(false);
  // const [openAssignRouteModal, setOpenAssignRouteModal] = useState(false);
  const [proposedCancelledDelivery, setProposedCancelledDelivery] =
    useState(null);
  const [proposedResetDelivery, setProposedResetDelivery] = useState(null);
  const [proposedRestartDelivery, setProposedRestartDelivery] = useState(null);
  const [proposedRedispatchDelivery, setProposedRedispatchDelivery] =
    useState(null);
  const [proposedTerminateDelivery, setProposedTerminateDelivery] =
    useState(null);
  const [proposedCommentedDelivery, setProposedCommentedDelivery] =
    useState(null);
  const [proposedExceptionDelivery, setProposedExceptionDelivery] =
    useState(null);
  const [proposedLostDelivery, setProposedLostDelivery] = useState(null);
  const [proposedRerequestedDelivery, setProposedRerequestedDelivery] =
    useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  // const [assignedRoute, setAssignedRoute] = useState(null);

  const getCurrentFilter = (values) => {
    const searchValues = values || formValues;
    return omitBy({ ...searchValues /* state */ }, (value) => {
      if (
        (typeof value === 'string' && value === '') ||
        value === null ||
        value === undefined
      ) {
        return true;
      }
      return Array.isArray(value) && value.length === 0;
    });
  };

  const formatCurrentFilterBeforeSearch = (
    selectedFieldsFilter,
    values = null
  ) => {
    // Handle Id
    if (selectedFieldsFilter.id) {
      if (selectedFieldsFilter.businessId) {
        selectedFieldsFilter.businessId.push(selectedFieldsFilter.id);
      } else {
        selectedFieldsFilter.businessId = [selectedFieldsFilter.id];
      }
      delete selectedFieldsFilter.id;
    } else if (selectedFieldsFilter.businessId) {
      selectedFieldsFilter.businessId = selectedFieldsFilter.businessId.filter(
        (item) => item !== formValues.id
      );

      if (values) {
        values.businessId = values.businessId.filter(
          (item) => item !== formValues.id
        );
      }
    }

    // handle state codes
    if (selectedFieldsFilter.stateCodes?.length) {
      const newStateCodes = [];
      selectedFieldsFilter.stateCodes.map((state) => {
        if (typeof state === 'string') {
          newStateCodes.push(state);
        } else {
          newStateCodes.push(...state);
        }
      });
      selectedFieldsFilter.stateCodes = newStateCodes;
    }

    for (const key in selectedFieldsFilter) {
      if (
        dayjs(selectedFieldsFilter[key]).isValid() &&
        DATE_PAYLOAD.includes(key)
      ) {
        selectedFieldsFilter[key] = FORMAT_DATE_TO_UTC(
          selectedFieldsFilter[key]
        );
      }
    }
    return selectedFieldsFilter;
  };

  const cancelDeliveryAsUncoveredZone = async (ids) => {
    try {
      await cancelDeliveryWithReason({
        ids: [...ids],
        reason: UNDERCOVERED_ZONE
      });
      const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
        getCurrentFilter()
      );
      await fetchDeliveries({
        ...selectedFieldsFilter,
        ...deliveries.pagination
      });
    } catch (e) {
      const {
        response: { data: message }
      } = e;
      notify(message.message);
      return null;
    }
  };
  const resetSearchForm = () => {
    setFormValues({
      ...initialFormValues
    });
    // setType(null);
    fetchDeliveries({ ...deliveries.pagination });
  };
  // const handleSearchFormChange = (name, value, isDateRange) => {
  //   if (!isDateRange) {
  //     setFormValues({
  //       ...formValues,
  //       [name]: value,
  //     });
  //   } else {
  //     setFormValues({
  //       ...formValues,
  //       [`${name}Start`]: value[0],
  //       [`${name}End`]: value[1],
  //     });
  //   }
  // };

  const handleSearchFormSubmit = (values) => {
    setFormValues({ ...values });
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter(values),
      values
    );

    if (deliveries && deliveries.pagination) {
      deliveries.pagination.pageNumber = 1;
    }

    setSelectedRowsIds([]);
    fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
    /**
     * handling submit
     */
  };

  const confirmCancelDelivery = (reason, ids) => {
    cancelDeliveryWithReason({ ids, reason });
    setSelectedRowsIds([]);

    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
  };
  const confirmAddCommentToDelivery = (comment) => {
    const { _id } = proposedCommentedDelivery;
    addCommentToDelivery({ id: _id, comment });
    setOpenCommentModal(false);
    setSelectedRowsIds([]);
    setProposedCommentedDelivery(null);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
  };

  const confirmRerequestDelivery = () => {
    const { _id } = proposedRerequestedDelivery;
    rerequestDelivery({ id: _id });
    setOpenRerequestModal(false);
    setSelectedRowsIds([]);
    setProposedRerequestedDelivery(null);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
  };

  const confirmResetDelivery = () => {
    const { _id } = proposedResetDelivery;
    resetDelivery({ id: _id });
    setOpenResetModal(false);
    setSelectedRowsIds([]);
    setProposedResetDelivery(null);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({
      ...selectedFieldsFilter,
      ...deliveries.pagination
    });
  };

  const confirmRestartDelivery = async () => {
    const { _id } = proposedRestartDelivery;
    await restartDeliveredDelivery({ id: _id });
    setOpenRestartModal(false);
    setProposedRestartDelivery(null);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({
      ...selectedFieldsFilter,
      ...deliveries.pagination
    });
  };

  const confirmReDispatchDelivery = async () => {
    const { _id } = proposedRedispatchDelivery;
    await redispatchRTODelivery({ id: _id });
    setOpenRedispatchModal(false);
    setProposedRedispatchDelivery(null);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({
      ...selectedFieldsFilter,
      ...deliveries.pagination
    });
  };
  // const confirmAssignToRoutes = () => {
  //   const { value } = assignedRoute;
  //   addToRoute({ routeId: value, ids: selectedRowsIds });
  //   setOpenAssignRouteModal(false);
  //   setSelectedRowsIds([]);
  //   const selectedFieldsFilter = getCurrentFilter();
  //   fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
  // };
  const confirmTerminate = async () => {
    if (selectedRowsIds.length > 0)
      await terminateDeliveries({ ids: selectedRowsIds });
    else if (proposedTerminateDelivery) {
      await terminateDeliveries({ ids: [proposedTerminateDelivery._id] });
    } else {
      return;
    }
    setOpenTerminateModal(false);
    setSelectedRowsIds([]);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    await fetchDeliveries({
      ...selectedFieldsFilter,
      ...deliveries.pagination
    });
  };
  const confirmLostOrDamaged = async (type, data) => {
    try {
      const { _id } = proposedLostDelivery;
      await markAsLostOrDamaged({
        deliveryId: _id,
        type,
        ...data
      });
      notify(`Delivery marked as "${type}" successfully.`, 'success');
    } catch (e) {
      notify(e.response?.data?.message || e.message);
    }
  };
  const rowSelection = {
    /**
     * on checkbox state change for each row
     */
    selectedRowKeys: selectedRowsIds,
    onChange: (selectedRowKeys) => {
      setSelectedRowsIds([...selectedRowKeys]);
    }
    /**
     * get check box related data row item, this hook can facilitate disabling rows checking
     */
    /* getCheckboxProps: record => {
      return {
      };
    } */
  };
  const handleTableChange = (pagingOptions) => {
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({
      ...deliveries.pagination,
      pageNumber: pagingOptions.current,
      pageLimit: pagingOptions.pageSize,
      ...selectedFieldsFilter
    });
  };

  const rowsSelectionRequired = (fn) => () => {
    const isSelected = selectedRowsIds.length > 0;
    if (!isSelected) {
      notify('No rows selected.');
      return;
    }
    fn();
  };

  /*  const filterRequired = fn => () => {
  const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    if (Object.entries(selectedFieldsFilter).length === 0) {
      notify("You should at least use one filter to export data.", "error");
      return;
    }
    fn(selectedFieldsFilter);
  }; */
  const handleDownloadXlsx = async (data) => {
    try {
      const res = await exportXlsx(data);
      notify(res.msg, 'success');
    } catch (e) {
      notify(e.message);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleExportXlsx = async () => {
    setDownloadLoading(true);
    if (selectedRowsIds.length === 0) {
      const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
        getCurrentFilter()
      );
      handleDownloadXlsx({ ...selectedFieldsFilter });
    } else {
      const trackingNumbers = deliveries.data
        .filter((el) => selectedRowsIds.includes(el._id))
        .map((el) => el.trackingNumber)
        .toString();
      handleDownloadXlsx({ trackingNumbers });
    }
  };

  const ActionsMenu = ({ options }) => {
    return (
      <Menu>
        <Menu.Item disabled={downloadLoading} onClick={handleExportXlsx}>
          Export Xlsx
        </Menu.Item>
        <Menu.Item
          disabled={loading}
          onClick={rowsSelectionRequired(() => {
            options.printAirWayBill({ ids: selectedRowsIds });
          })}
        >
          Print airway bill
        </Menu.Item>
        <Menu.Item
          onClick={rowsSelectionRequired(() => {
            cancelDeliveryAsUncoveredZone(selectedRowsIds);
          })}
        >
          Uncovered Zone
        </Menu.Item>
        <Menu.Item
          onClick={rowsSelectionRequired(() => {
            options.returnDeliveriesToBusiness(selectedRowsIds);
          })}
        >
          Return to business
        </Menu.Item>
        <Menu.Item
          onClick={rowsSelectionRequired(() => {
            options.resetDeliveries(selectedRowsIds);
          })}
        >
          Reset
        </Menu.Item>
        {/* <Menu.Item
          onClick={rowsSelectionRequired(() => {
            setOpenCancelMultiModal(true);
          })}
        >
          Cancel
        </Menu.Item> */}
        <Menu.Item
          onClick={rowsSelectionRequired(() => {
            setOpenTerminateModal(true);
          })}
        >
          Terminate
        </Menu.Item>
      </Menu>
    );
  };

  if (airWayBill) {
    showBill(airWayBill.data);
    clearBill();
  }

  useEffect(() => {
    fetchDeliveries({
      pageLimit: deliveries?.pagination?.pageLimit || tablePageSize,
      pageNumber: deliveries?.pagination?.pageNumber || 1
    });
  }, [fetchDeliveries]);
  useEffect(() => {
    fetchHubs();
  }, [fetchHubs]);
  useEffect(() => {
    fetchStars();
  }, [fetchStars]);
  /* useEffect(() => {
      fetchActiveRoutes();
    }, [fetchActiveRoutes]); */

  return (
    <div>
      <div className={`my-4 ${styles.actionsMenu}`}>
        Delivery Actions
        <Dropdown
          overlay={<ActionsMenu options={{ ...props }} />}
          trigger={['click']}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
      {/* <div className={styles.filtersSection}>
            <FilterStatus
              className={styles.filterBar}
              statusList={statusList}
              selected={state}
              onChange={(item) => {
                setState(item);
                fetchDeliveries(item ? { ...deliveries.pagination, state: item } : { ...deliveries.pagination });
              }}
            />
          </div> */}
      <div className="mx-1 mb-4">
        {deliveries.data.length > 0 && (
          <div className="mb-4">
            <b>Total Deliveries:</b>
            <span className="ml-2">{deliveries.count}</span>
          </div>
        )}
        <Table
          rowSelection={rowSelection}
          style={{ overflow: 'auto' }}
          columns={deliveriesColumnsMapped}
          dataSource={formatData(deliveries.data, {
            intl,
            history,
            printAirWayBill,
            setProposedCancelledDelivery,
            setProposedResetDelivery,
            setProposedRestartDelivery,
            setOpenCancelModal,
            setProposedCommentedDelivery,
            setOpenCommentModal,
            setOpenExceptionModal,
            setProposedExceptionDelivery,
            setOpenRerequestModal,
            setOpenResetModal,
            setOpenRestartModal,
            setProposedRerequestedDelivery,
            setProposedTerminateDelivery,
            setOpenTerminateModal,
            setProposedLostDelivery,
            setOpenLostModal,
            setOpenRedispatchModal,
            setProposedRedispatchDelivery,
            cancelDeliveryAsUncoveredZone
          })}
          pagination={{
            current: deliveries?.pagination?.pageNumber || 1,
            total: deliveries?.count,
            pageSize: deliveries?.pagination?.pageLimit || tablePageSize,
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100', '200']
          }}
          // onRow={record => ({
          //   onClick: evt => {
          //     if (
          //       evt.target.tagName === 'svg' ||
          //       evt.target.classList.contains('ant-dropdown-menu-item')
          //     ) {
          //       evt.stopPropagation();
          //       return;
          //     }
          //     history.push(`/deliveries/${record.id}/details`);
          //   },
          // })}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 3500, y: 600 }}
        />
        <CancelDeliveryReasonModal
          show={openCancelModal}
          cancel={() => {
            setOpenCancelModal(false);
          }}
          confirm={(reason) => {
            const { _id } = proposedCancelledDelivery;
            confirmCancelDelivery(reason, [_id]);
            setProposedCancelledDelivery(null);
            setOpenCancelModal(false);
          }}
        />
        <AddCommentToDeliveryModal
          show={openCommentModal}
          cancel={() => {
            setOpenCommentModal(false);
          }}
          confirm={confirmAddCommentToDelivery}
        />

        <ConfirmationModal
          show={openRerequestModal}
          cancel={() => {
            setOpenRerequestModal(false);
          }}
          confirm={confirmRerequestDelivery}
          title="Confirm Re-Request"
          body="You are going to re-request this delivery, Are you sure?"
        />
        <ConfirmationModal
          show={openResetModal}
          cancel={() => {
            setOpenResetModal(false);
          }}
          confirm={confirmResetDelivery}
          title="Confirm Reset"
          body="You are going to Reset this delivery, Are you sure?"
        />
        <ConfirmationModal
          show={openRestartModal}
          cancel={() => {
            setOpenRestartModal(false);
          }}
          confirm={confirmRestartDelivery}
          title="Confirm Restart"
          body="You are going to Restart this delivery, Are you sure?"
        />
        {/* <ConfirmationModal
              show={openAssignRouteModal}
              cancel={() => {
                setOpenAssignRouteModal(false);
              }}
              confirm={confirmAssignToRoutes}
              title="Add to route"
              disableConfirm={!!assignedRoute}
              body={
                <Select
                  required
                  value={assignedRoute}
                  onChange={evt => setAssignedRoute(evt)}
                  placeholder="Select a route"
                  options={formatOptions('_id', 'name', activeRoutes)}
                  isClearable
                />
              }
            /> */}
        <ConfirmationModal
          show={openRedispatchModal}
          cancel={() => {
            setOpenRedispatchModal(false);
          }}
          confirm={confirmReDispatchDelivery}
          title="Confirm Redispatch"
          body="You are going to Re-dispatch this delivery, Are you sure?"
        />
        <ConfirmationModal
          show={openTerminateModal}
          cancel={() => {
            setOpenTerminateModal(false);
          }}
          confirm={confirmTerminate}
          title="Terminate Deliveries"
          body="Are you sure you want to terminate these orders. You won't be able to revert this!"
          confirmText="Yes, terminate orders!"
        />
        <CancelDeliveryReasonModal
          show={openCancelMultiModal}
          cancel={() => {
            setOpenCancelMultiModal(false);
          }}
          confirm={(reason) => {
            confirmCancelDelivery(reason, selectedRowsIds);
            setOpenCancelMultiModal(false);
          }}
        />
        <LostOrDamagedDeliveryModal
          show={openLostModal}
          cancel={() => {
            setOpenLostModal(false);
          }}
          confirm={async (type, data) => {
            await confirmLostOrDamaged(type, data);
            setOpenLostModal(false);
          }}
          options={{ stars: stars.data, hubs }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveries: (data) =>
    dispatch(
      getAllDeliveries({
        ...data,
        stateCodes: ['102']
      })
    ),
  fetchHubs: () => dispatch(getAllHubs()),
  fetchCities: () => dispatch(getAllCities()),
  fetchZones: () => dispatch(getAllZones()),
  fetchDistricts: () => dispatch(getAllDistricts()),
  fetchStars: () => dispatch(getAllStars()),
  printAirWayBill: (data) => dispatch(printBill(data)),
  clearBill: () => dispatch(clearAirWayBill()),
  cancelDeliveryWithReason: (data) => dispatch(cancelDelivery(data)),
  addCommentToDelivery: (data) => dispatch(addDeliveryComment(data)),
  rerequestDelivery: (data) => dispatch(rerequestCancelledDelivery(data)),
  fetchActiveRoutes: () => dispatch(getAllActiveRoutes()),
  resetDeliveries: (ids) => dispatch(resetDeliveredDeliveries(ids)),
  returnDeliveriesToBusiness: (ids) => dispatch(returnToBusiness(ids)),
  addToRoute: (ids) => dispatch(addDeliveriesToRoute(ids)),
  terminateDeliveries: (ids) => dispatch(terminateDelivery(ids)),
  resetDelivery: (data) => dispatch(resetDeliveredDelivery(data))
});
const mapStateToProps = ({ deliveries }) => ({
  ...deliveries
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(IvestigationOrders)));
