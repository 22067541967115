import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getRTODeliveries,
  scanDeliveries,
  getHubShelves,
  getRoutes,
  assignToRoute
} from 'services/hubs';
import { setDeliveriesOnShelf } from 'services/deliveries';
import { searchDeliveries } from 'services/deliveries';

import {
  BUSINESS,
  RECEIVED_AT_WAREHOUSE_CODE,
  DEFAULT_DESTINATION_HUB_ID,
  DELIVERIES_SCAN_TYPES
} from 'constants/hubs';
import { ROUTES_STATE } from 'constants/route';
import {
  ALL_ORDER_FINAL_STATES,
  RECEIVED_AND_ON_HOLD_CODES
} from 'constants/shipments';
import { isReturnOrder } from 'utils/shipmentDetails';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { isAdminGroupUser } from 'utils/helpers';
import { HubsContext } from 'contexts/hub.context';

import DispatchRTOHeader from 'containers/Hub/DispatchRTO/DispatchRTOHeader';
import RTOTable from 'containers/Hub/DispatchRTO/RTOTable';
import RTOScan from 'containers/Hub/DispatchRTO/RTOScan';
import { notify } from 'components/Notify/Notify';
import Sounds from 'common/Sounds';

import './DispatchRTO.less';

function DispatchRTO({ intl }) {
  const [isLoading, setIsLoading] = useState(false);
  const [scannedDeliveries, setScannedDeliveries] = useState([]);
  const [scannedQuerySearch, setScannedQuerySearch] = useState('');
  const [scannedValue, setScannedValue] = useState('');
  const [hubs, setHubs] = useState(null);
  const [currentUserHubId, setCurrentUserHubId] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);
  const [rtoCount, setRTOCount] = useState(0);
  const [rtoList, setRTOList] = useState([]);
  const [rtoSearchResults, setRTOSearchResults] = useState([]);
  const [rtoListTrackingNumbers, setRTOListTrackingNumbers] = useState([]);
  const [assignee, setAssignee] = useState(null);
  const [selectedTrackingNumbers, setSelectedTrackingNumbers] = useState(null);
  const [shelves, setShelves] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [groupedBy, setGroupedBy] = useState({
    label: intl.formatMessage({
      id: `hubs.dispatch_rto.group_by.business`
    }),
    value: BUSINESS
  });

  const { scopedHubs } = useContext(HubsContext);

  const history = useHistory();

  const handleClearSelectedHub = () => {
    setSelectedHub(null);
    setShelves([]);
    setRoutes([]);
    setRTOCount(0);
    setRTOList([]);
    setRTOSearchResults([]);
    clearScannedTNsTable();
  };

  const setDefaultSelectedHub = (defaultHub) => {
    const { name, _id } = defaultHub || {};

    setSelectedHub({
      label: name,
      value: _id
    });
  };

  useEffect(() => {
    if (scopedHubs.length) {
      const currentUserHub = getCurrentUserHubInfo();
      setHubs(scopedHubs);

      if (isAdminGroupUser()) {
        if (scopedHubs.length === 1) {
          setDefaultSelectedHub(scopedHubs[0]);
        }
      } else {
        if (currentUserHub?._id) {
          const currentHub = scopedHubs.find(
            (hub) => hub._id === currentUserHub._id
          );
          setCurrentUserHubId(currentHub);
          setDefaultSelectedHub(currentHub);
        }
      }
    }
  }, [scopedHubs]);

  const fetchRTODeliveries = async (hubId, groupBy) => {
    setIsLoading(true);
    try {
      const payload = {
        groupBy
      };

      const {
        data: { list, count }
      } = await getRTODeliveries(hubId, payload);
      if (list) {
        setRTOCount(count);
        setRTOList(list);
        setRTOSearchResults(list);
        const trackingNumberList = [];
        list.forEach(({ deliveries }) =>
          deliveries?.forEach(({ trackingNumber }) =>
            trackingNumberList.push(trackingNumber)
          )
        );
        setSelectedTrackingNumbers(trackingNumberList);
        setRTOListTrackingNumbers(trackingNumberList);
      }
    } catch ({ message }) {
      notify(message);
    }
    setIsLoading(false);
  };

  const fetchRoutes = async () => {
    try {
      const { DRAFTED, UNASSIGNED, ASSIGNED, DISPATCHED } = ROUTES_STATE;
      const routesStates = [DRAFTED, UNASSIGNED, ASSIGNED, DISPATCHED];
      const selectedHubId = selectedHub?.value;

      const payload = {
        pageId: 0,
        states: routesStates,
        ...(selectedHubId && { warehouses: [selectedHubId] })
      };

      const { result } = await getRoutes(payload);

      if (result) {
        const routesList = result.map(({ name, _id }) => ({
          label: name,
          value: _id
        }));
        setRoutes(routesList);
      }
    } catch ({ message }) {
      notify(message);
    }
  };

  const assignDeliveriesToRoute = async (
    routeId,
    trackingNumbers,
    successMsg
  ) => {
    setIsLoading(true);

    const payload = {
      trackingNumbers
    };

    try {
      const res = await assignToRoute(routeId, payload);

      if (res?.message) {
        notify(successMsg || res.message, 'success');
      } else if (res?.failed) {
        notify(res?.reasons?.map(({ reason }) => reason).join(', '));
      }

      fetchRTODeliveries(selectedHub.value, groupedBy.value);
    } catch ({ message }) {
      notify(message);
    }

    setIsLoading(false);
  };

  const fetchShelves = async () => {
    try {
      const { warehouse } = await getHubShelves(selectedHub.value);
      const shelvesList = warehouse?.shelves?.map((shelf) => ({
        label: shelf,
        value: shelf
      }));

      if (shelvesList) {
        setShelves(shelvesList);
      } else {
        setShelves([]);
      }
    } catch ({ message }) {
      notify(message);
    }
  };

  const assignDeliveriesToShelf = async (
    shelfValue,
    deliveryIds,
    successMsg
  ) => {
    setIsLoading(true);

    const payload = {
      shelfValue,
      deliveryIds: deliveryIds.join(','),
      hubId: selectedHub.value
    };

    try {
      const res = await setDeliveriesOnShelf(payload);

      if (res?.msg) {
        notify(successMsg || res.msg, 'success');
      } else if (res?.failed) {
        notify(res?.reasons?.map(({ reason }) => reason).join(', '));
      }

      fetchRTODeliveries(selectedHub.value, groupedBy.value);
    } catch ({ message }) {
      notify(message);
    }

    setIsLoading(false);
  };

  const checkRTODeliveriesValidity = (deliveries) => {
    const validDeliveries = [];
    deliveries.forEach((delivery) => {
      const {
        state: { code, receivedAtWarehouse, value, lastExceptionCode }
      } = delivery;
      const isReceivedAtWarehouse = RECEIVED_AT_WAREHOUSE_CODE.includes(code);
      const isOnHold = RECEIVED_AND_ON_HOLD_CODES.includes(lastExceptionCode);
      if (isReceivedAtWarehouse && isOnHold) {
        Sounds.longBeep();
      } else if (ALL_ORDER_FINAL_STATES.includes(code)) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.dispatch_rto.scan.error_msgs.final_state_order'
            },
            {
              orderState: value
            }
          ),
          'error',
          true
        );
      } else if (isReturnOrder(delivery)) {
        if (isReceivedAtWarehouse) {
          if (receivedAtWarehouse?.warehouse?._id === selectedHub.value) {
            validDeliveries.push(delivery);
            Sounds.beep();
            setScannedValue('');
          } else {
            notify(
              intl.formatMessage(
                {
                  id: 'hubs.dispatch_rto.scan.error_msgs.not_in_this_hub'
                },
                {
                  hubName: receivedAtWarehouse?.warehouse?.name
                }
              ),
              'error',
              true
            );
          }
        } else {
          notify(
            intl.formatMessage(
              {
                id: 'hubs.dispatch_rto.scan.error_msgs.other_states'
              },
              {
                orderState: value
              }
            ),
            'error',
            true
          );
        }
      } else {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.dispatch_rto.scan.error_msgs.not_rto'
            },
            {
              orderState: value
            }
          ),
          'error',
          true
        );
      }

      setScannedDeliveries((prev) => [...prev, ...validDeliveries]);
    });
  };

  const searchScannedDeliveries = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const payload = {
        searchType: DELIVERIES_SCAN_TYPES.RTO_DELIVERY_SCAN,
        trackingNumbers
      };
      const { data: RTODeliveries } = await scanDeliveries(payload);

      checkRTODeliveriesValidity(RTODeliveries);
    } catch ({ message }) {
      notify(message, 'error', true);
    }
    setIsLoading(false);
  };

  const transferToSortingFacility = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const { deliveries } = await searchDeliveries({ trackingNumbers });
      if (deliveries?.length) {
        history.push({
          pathname: '/hubs/hub-operation/transfer-to-hub',
          state: {
            hub: selectedHub,
            destinationHubId: DEFAULT_DESTINATION_HUB_ID,
            deliveries
          }
        });
      }
    } catch ({ message }) {
      notify(message);
    }
    setIsLoading(false);
  };

  const clearScannedTNsTable = () => {
    setSelectedAssignee(null);
    setScannedDeliveries([]);
    setScannedValue('');
  };

  useEffect(() => {
    if (selectedHub) {
      clearScannedTNsTable();
      fetchShelves();
      fetchRoutes();
    }
  }, [selectedHub]);

  useEffect(() => {
    if (selectedHub) {
      fetchRTODeliveries(selectedHub.value, groupedBy.value);
    }
  }, [selectedHub, groupedBy]);

  useEffect(() => {
    if (scannedQuerySearch) {
      searchScannedDeliveries([scannedQuerySearch]);
    }
  }, [scannedQuerySearch]);

  useEffect(() => {
    setSelectedAssignee(null);
  }, [assignee]);

  return (
    <div className="br-dispatch-rto">
      <div className="br-dispatch-rto_side-container br-dispatch-rto_side-container_left">
        <DispatchRTOHeader
          hubs={hubs}
          rtoCount={rtoCount}
          selectedHub={selectedHub}
          currentUserHubId={currentUserHubId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedHub={setSelectedHub}
          rtoList={rtoList}
          setRTOSearchResults={setRTOSearchResults}
          selectedTrackingNumbers={selectedTrackingNumbers}
          groupedBy={groupedBy}
          setGroupedBy={setGroupedBy}
          handleClearSelectedHub={handleClearSelectedHub}
        />
        <RTOTable
          groupedBy={groupedBy}
          shelves={shelves}
          routes={routes}
          rtoList={rtoList}
          rtoSearchResults={rtoSearchResults}
          rtoListTrackingNumbers={rtoListTrackingNumbers}
          assignDeliveriesToRoute={assignDeliveriesToRoute}
          assignDeliveriesToShelf={assignDeliveriesToShelf}
          selectedHub={selectedHub}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedTrackingNumbers={setSelectedTrackingNumbers}
          rtoCount={rtoCount}
          transferToSortingFacility={transferToSortingFacility}
        />
      </div>
      <div className="br-dispatch-rto_side-container br-dispatch-rto_side-container_right">
        <RTOScan
          isLoading={isLoading}
          assignee={assignee}
          routes={routes}
          shelves={shelves}
          setAssignee={setAssignee}
          setScannedQuerySearch={setScannedQuerySearch}
          setScannedDeliveries={setScannedDeliveries}
          clearScannedTNsTable={clearScannedTNsTable}
          scannedDeliveries={scannedDeliveries}
          scannedValue={scannedValue}
          setScannedValue={setScannedValue}
          selectedHub={selectedHub}
          selectedAssignee={selectedAssignee}
          assignDeliveriesToRoute={assignDeliveriesToRoute}
          assignDeliveriesToShelf={assignDeliveriesToShelf}
          setSelectedAssignee={setSelectedAssignee}
          transferToSortingFacility={transferToSortingFacility}
          fetchRoutes={fetchRoutes}
        />
      </div>
    </div>
  );
}

export default injectIntl(DispatchRTO);
