import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  ACTION_NOTES,
  CANCEL_ORDER,
  CHANGE_COD,
  RESCHEDULED,
  RETURN_ISSUE_RESOLVED,
  STAR_INSTRUCTION,
  TRANSFER_TO_SORTING,
  UPDATED_DELIVERY_DETAILS,
  UPDATES_COLORS
} from 'constants/action-center';
import { getCurrency } from 'common/countries/countries-mapping';

import './OrderDetailsNote.less';

const OrderDetailsNote = ({
  orderDetails,
  orderAction,
  intl,
  customerAddress,
  ofdEditsPage
}) => {
  const { action, actionDetails } = orderAction;

  const formatActionNote = () => {
    if (action === RESCHEDULED) {
      return (
        <>
          {intl.formatMessage({
            id: 'action_center.order_details.reschedule_order_to'
          })}
          <span className="br-order-details__description-date">
            {dayjs(new Date(orderDetails?.scheduledAt)).format(
              'dddd, DD MMM YYYY'
            )}
          </span>
        </>
      );
    } else if (
      [UPDATED_DELIVERY_DETAILS, TRANSFER_TO_SORTING, CHANGE_COD].includes(
        action
      )
    ) {
      return (
        <>
          {actionDetails?.dropOffAddress && (
            <>
              {intl.formatMessage({
                id: 'action_center.order_details.address_updated_to'
              })}
              <span className="br-order-details__description-order-details">
                {customerAddress}
              </span>
            </>
          )}
          {actionDetails?.receiver?.phone && (
            <>
              {intl.formatMessage({
                id: 'action_center.order_details.phone_updated_to'
              })}
              <span className="br-order-details__description-order-details">
                {orderDetails?.receiver?.phone}
              </span>
            </>
          )}

          {actionDetails?.receiver?.secondPhone && (
            <>
              {intl.formatMessage({
                id: 'action_center.order_details.second_phone_updated_to'
              })}
              <span className="br-order-details__description-order-details">
                {orderDetails?.receiver?.secondPhone}
              </span>
            </>
          )}
          {actionDetails?.allowToOpenPackage && (
            <>
              {intl.formatMessage({
                id: 'action_center.order_details.open_package_updated_to'
              })}
              <span className="br-order-details__description-order-details">
                {orderDetails?.allowToOpenPackage
                  ? intl.formatMessage({
                      id: 'common.yes'
                    })
                  : intl.formatMessage({
                      id: 'common.no'
                    })}
              </span>
            </>
          )}
          {!isNaN(parseInt(actionDetails?.codAmount)) && (
            <>
              {intl.formatMessage(
                {
                  id: 'action_center.order_details.cod_was_updated_to'
                },
                {
                  amount: (
                    <span className="br-order-details__cod-amount body-medium">
                      {actionDetails?.codAmount} {getCurrency().type}
                    </span>
                  )
                }
              )}
            </>
          )}
        </>
      );
    } else if (action === CANCEL_ORDER) {
      return (
        <>
          {intl.formatMessage({
            id: 'action_center.order_details.order_cancelled'
          })}
        </>
      );
    } else if (action === RETURN_ISSUE_RESOLVED) {
      return (
        <>
          {intl.formatMessage({
            id: 'action_center.order_details.order_rescheduled_to'
          })}
          <span className="br-order-details__description-date">
            {dayjs(new Date(orderDetails?.scheduledAt)).format(
              'dddd, DD MMM YYYY'
            )}
          </span>
        </>
      );
    } else if (action === STAR_INSTRUCTION) {
      return (
        <>
          {intl.formatMessage({
            id: 'action_center.order_details.delivery_instructions'
          })}
          <div className="br-order-details__customer_notes body-medium">
            {
              orderDetails?.customerNotes?.find(
                (note) => note?._id === orderAction?.noteId
              )?.note
            }
          </div>
        </>
      );
    }
  };

  return (
    <div className={`br-order-details__notes ${UPDATES_COLORS[action]}`}>
      <div
        className={`br-order-details__description ${UPDATES_COLORS[action]}`}
      >
        {formatActionNote()}
      </div>
      {!ofdEditsPage && (
        <div className={`br-order-details__action ${UPDATES_COLORS[action]}`}>
          <h1 className="br-order-details__action-title">
            {intl.formatMessage({
              id: 'action_center.order_details.action_notes.title'
            })}
          </h1>
          <p className="br-order-details__action-description">
            {ACTION_NOTES[action]}
          </p>
        </div>
      )}
    </div>
  );
};

export default injectIntl(OrderDetailsNote);
