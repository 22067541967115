import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Form, Input, Select } from 'antd';
import { groupBy } from 'lodash';

import {
  getThreePLProviders,
  printIntegrationAwb,
  printShipoxAwb,
  sendToIntegration
} from 'services/3pl-integrations';
import { printAirwaybill } from 'services/shipments';
import { downloadAsPdf, downloadAsZip } from 'utils/download';
import { openInNewTab } from 'utils/helpers';
import { PROVIDERS, PROVIDERS_STRING } from 'constants/3pl-integrations';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTable from 'components/BRTable/BRTable';

import './ThreePLIntegrations.less';

const ThreePLIntegrations = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [errorsTableData, setErrorsTableData] = useState([]);

  const formRef = useRef('');

  useEffect(() => {
    fetchThreePLProviders();
  }, []);

  const handleFinish = async (values) => {
    setIsLoading(true);
    setErrorsTableData([]);

    const trackingNumbers = values.trackingNumbers
      ?.replace(/\s/g, '')
      ?.split(',');

    const payload = {
      provider: selectedProvider,
      trackingNumbers
    };

    try {
      if (trackingNumbers) {
        const data = await sendToIntegration(payload);

        if (data?.failedTn?.length) {
          handleFailedTrackingNumbers(data.failedTn);
        }

        if (data?.createdTn?.length) {
          notify(
            intl.formatMessage(
              { id: 'three_pl_integrations.sent_successfully' },
              {
                provider: PROVIDERS_STRING[selectedProvider] || selectedProvider
              }
            ),
            'success'
          );
        }
      } else {
        notify(
          intl.formatMessage({
            id: 'three_pl_integrations.invalid_tracking_number'
          })
        );
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintIntegrationAwb = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const data = await printIntegrationAwb({
        trackingNumbers,
        provider: selectedProvider
      });
      downloadAsZip(data, 'AWBs');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintShipoxAwb = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const data = await printShipoxAwb({ trackingNumbers });
      openInNewTab(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintSaeeAwb = async (trackingNumbers) => {
    setIsLoading(true);
    try {
      const { data } = await printAirwaybill(trackingNumbers?.toString(), {
        isTrackingNumbers: true
      });
      downloadAsPdf(data, trackingNumbers);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintTorodAwb = () => {};

  const fetchThreePLProviders = async () => {
    try {
      const data = await getThreePLProviders();
      setAllProviders(Object.values(data));
    } catch (error) {
      notify(error.message);
    }
  };

  const handleFailedTrackingNumbers = (failedTn) => {
    const grouped = groupBy(failedTn, 'errorMessage');

    const errorsData = Object.keys(grouped).map((key) => {
      return {
        errorMessage: key,
        trackingNumbers: grouped[key].map((tn) => tn.trackingNumber)?.join(', ')
      };
    });

    setErrorsTableData(errorsData);
  };

  const handlePrinting = () => {
    const trackingNumbers = formRef.current
      ?.getFieldsValue()
      ?.trackingNumbers?.replace(/\s/g, '')
      ?.split(',');

    if (trackingNumbers) {
      switch (selectedProvider) {
        case PROVIDERS.SHIPOX_PROVIDER:
          handlePrintShipoxAwb(trackingNumbers);
          return;
        case PROVIDERS.SAEE_PROVIDER:
          handlePrintSaeeAwb(trackingNumbers);
          return;
        default:
          handlePrintIntegrationAwb(trackingNumbers);
          return;
      }
    } else {
      notify(
        intl.formatMessage({
          id: 'three_pl_integrations.invalid_tracking_number'
        })
      );
    }
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'three_pl_integrations.tracking_numbers'
      }),
      dataIndex: 'trackingNumbers'
    },
    {
      title: intl.formatMessage({
        id: 'three_pl_integrations.error_message'
      }),
      dataIndex: 'errorMessage'
    }
  ];

  const getData = () => {
    return {
      list: errorsTableData,
      total: errorsTableData.length
    };
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({ id: 'three_pl_integrations.title' })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-three-pl-integrations__container">
            <Form
              ref={formRef}
              name="sendToIntegration"
              onFinish={handleFinish}
            >
              <Form.Item
                label={intl.formatMessage({
                  id: 'three_pl_integrations.provider'
                })}
                name="provider"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Select
                  placeholder={intl.formatMessage({
                    id: 'three_pl_integrations.select_provider'
                  })}
                  onChange={setSelectedProvider}
                >
                  {allProviders.map((provider) => (
                    <Select.Option key={provider} value={provider}>
                      {PROVIDERS_STRING[provider] || provider}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'three_pl_integrations.tracking_numbers'
                })}
                name="trackingNumbers"
              >
                <Input.TextArea
                  rows={4}
                  placeholder={intl.formatMessage({
                    id: 'three_pl_integrations.tracking_numbers_placeholder'
                  })}
                />
              </Form.Item>
              <div className="br-three-pl-integrations__actions display-flex justify-content-end">
                <Button onClick={handlePrinting}>
                  {selectedProvider
                    ? intl.formatMessage(
                        {
                          id: 'three_pl_integrations.print_provider_awb'
                        },
                        {
                          provider:
                            PROVIDERS_STRING[selectedProvider] ||
                            selectedProvider
                        }
                      )
                    : intl.formatMessage({ id: 'three_pl_integrations.print' })}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  form="sendToIntegration"
                  disabled={!selectedProvider}
                >
                  {selectedProvider
                    ? intl.formatMessage(
                        {
                          id: 'three_pl_integrations.send_to_provider'
                        },
                        {
                          provider:
                            PROVIDERS_STRING[selectedProvider] ||
                            selectedProvider
                        }
                      )
                    : intl.formatMessage({ id: 'three_pl_integrations.send' })}
                </Button>
              </div>
            </Form>
            {!!errorsTableData.length && (
              <div className="br-three-pl-integrations__errors-table-container">
                <div className="br-three-pl-integrations__errors-table-title">
                  {intl.formatMessage({
                    id: 'three_pl_integrations.failed_tracking_numbers_error'
                  })}
                </div>
                <BRTable
                  columns={columns}
                  onRowClick={() => {}}
                  listFunction={getData}
                  pageLimit={50}
                  emptySearchContent={{
                    title: intl.formatMessage({
                      id: 'cashier.empty_search_title'
                    }),
                    subtitle: intl.formatMessage({
                      id: 'cashier.empty_search_sub_title'
                    })
                  }}
                  showSearchFilter={false}
                  showFilter={false}
                />
              </div>
            )}
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(ThreePLIntegrations);
