import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import { openModal } from 'utils/modal';
import { SECURITY_TYPES } from 'constants/Security';
import { setupSecurityType, disable2FA } from 'services/Security';
import { logout } from 'utils';

import SecurityPINModal from 'components/NewSettings/components/Security/SecurityPINModal/SecurityPINModal';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import SecurityTypeCard from 'components/NewSettings/components/Security/SecurityTypeCard/SecurityTypeCard';
import { notify } from 'components/Notify/Notify';

import './Security.less';

function Security({ intl }) {
  const [isSecurityStepsCompeleted, setSecurityStepsCompeleted] =
    useState(false);

  const handleOpenPINModal = ({ twoFAType, response }) => {
    openModal(SecurityPINModal, {
      type: twoFAType,
      response
    });
  };

  const handleDisableTOTP = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const twoFAType = userInfo.twoFA?.twoFAType;
      const response = await disable2FA(twoFAType);
      notify(response.message, 'success');
      logout();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleSetupTOTP = async (twoFAType) => {
    try {
      const response = await setupSecurityType({ twoFAType });
      if (response.success) {
        handleOpenPINModal({ twoFAType, response });
      } else {
        notify(response.message);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const checkUserSecurityInfo = useCallback(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo.twoFA) {
      setSecurityStepsCompeleted(
        userInfo.twoFA?.isTOTPTwoFASetupCompleted ||
          userInfo.twoFA?.isEmailTwoFASetupCompleted ||
          userInfo.twoFA?.isSMSTwoFASetupCompleted
      );
    }
  }, []);

  useEffect(() => {
    checkUserSecurityInfo();
  }, [checkUserSecurityInfo]);

  return isSecurityStepsCompeleted ? (
    <>
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.security.title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.security.subtitle'
        })}
        isInternalComponent
      />
      <span className="br-security__action">
        <p>
          {intl.formatMessage({
            id: 'settings.security.enabled_status'
          })}
        </p>
        <Button type="primary" onClick={handleDisableTOTP}>
          {intl.formatMessage({
            id: 'settings.security.disable_2FA_button'
          })}
        </Button>
      </span>
    </>
  ) : (
    <>
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.security.authentication_type_question'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.security.authentication_type_note'
        })}
        isInternalComponent
      />
      <SecurityTypeCard
        title={intl.formatMessage({
          id: 'settings.security.authentication_types.app.title'
        })}
        subtitle={
          <span>
            {intl.formatMessage({
              id: 'settings.security.authentication_types.app.subtitle'
            })}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Authenticator
            </a>
            ,{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Duo Mobile
            </a>{' '}
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&utm_source=apkvariant.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Authy
            </a>
            .
          </span>
        }
        buttonText={intl.formatMessage({
          id: 'settings.security.authentication_types.app.action'
        })}
        handleOnClickButton={() => handleSetupTOTP(SECURITY_TYPES.APP)}
      />
      <SecurityTypeCard
        title={intl.formatMessage({
          id: 'settings.security.authentication_types.email.title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.security.authentication_types.email.subtitle'
        })}
        buttonText={intl.formatMessage({
          id: 'settings.security.authentication_types.email.action'
        })}
        handleOnClickButton={() => handleSetupTOTP(SECURITY_TYPES.EMAIL)}
      />
      <SecurityTypeCard
        title={intl.formatMessage({
          id: 'settings.security.authentication_types.sms.title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.security.authentication_types.sms.subtitle'
        })}
        buttonText={intl.formatMessage({
          id: 'settings.security.authentication_types.sms.action'
        })}
        handleOnClickButton={() => handleSetupTOTP(SECURITY_TYPES.SMS)}
      />
    </>
  );
}

export default injectIntl(Security);
