import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getBusinessProductVariances = (payload) => {
  return http.get(`/products/fulfillment/variances`, payload);
};

export const getFulfillmentProductsTree = (payload) => {
  return http.get(`/products/fulfillment/treeView`, payload);
};

export const getFulfillmentProductNames = (payload) => {
  return http.get(`/products/fulfillment/productNames`, payload);
};

export const exportFulfillmentProducts = (payload) => {
  return http.get(`/products/fulfillment/export`, payload, downloadHeader);
};

export const enableOrDisableFulfillmentForProducts = (payload) => {
  return http.put(`/products/fulfillment/enable`, payload);
};
