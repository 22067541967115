import React from 'react';
import { connect } from 'react-redux';
import Container from 'components/Container';
import { Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { notify } from 'components/Notify/Notify';
import { getHubs } from '../../actions';
import { getAllStars } from '../../actions/HubsActions';
import { formatOptions } from '../../utils';
import { markDeliveriesAsDelivered } from '../../actions/DeliveriesActions';
import HubManagmentContainer from './HubManagmentContainer';
import BRHeader from 'components/BRHeader/BRHeader';

class ManageDeliveries extends React.Component {
  state = { stars: { data: [] }, currentStar: null, packages: 0 };

  starSelectRef = React.createRef();

  deliveriesRef = React.createRef();

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const stars = await getAllStars();
    this.setState({ stars });
  };

  starsSelectChanged = (evt) => {
    const { stars } = this.state;
    const currentStar = stars.data.find((el) => el._id === evt?.value);
    this.setState({ currentStar });
  };

  clearSelected = () => {
    this.starSelectRef.current.select.clearValue();
    this.deliveriesRef.current.value = '';
  };

  submitDeliveries = async (e) => {
    e.preventDefault();
    const { currentStar } = this.state;
    const value = this.deliveriesRef.current.value.trim();
    if (!value) {
      notify('please enter tracking number(s).');
      return;
    }
    if (!currentStar) {
      notify('Please select star.');
      return;
    }
    const trackingNumbers = value
      .split(/[\s,\n\r]/g)
      .map((txt) => txt.trim())
      .filter((el) => el !== '');
    const payload = {
      trackingNumbers,
      starId: currentStar._id
    };
    try {
      const res = await markDeliveriesAsDelivered(payload);
      if (res && res.failedDeliveries.length > 0) {
        notify(
          `Deliveries with TNs: ${res.failedDeliveries.join(
            ', '
          )} failed to be delivered.`
        );
      } else if (res) {
        notify('Operation done successfully', 'success');
        this.clearSelected();
      }
    } catch (e) {
      notify(e.response?.data?.message || e.message);
      console.dir(e);
    }
  };

  calculateNumberOfPackages = (value) => {
    this.setState({
      packages: value
        .split(/[\s,\n\r]/g)
        .map((txt) => txt.trim())
        .filter((el) => el !== '')
    });
  };

  render() {
    const { stars, packages } = this.state;
    return (
      <>
        <Container
          className="br-tabs-container-content"
          hideWarningBanner
          header={<BRHeader title="Manage Deliveries" />}
          content={
            <>
              <HubManagmentContainer
                children={
                  <Form onSubmit={this.submitDeliveries}>
                    <Form.Row>
                      <Form.Group controlId="trackingNumbers" as={Col} sm={6}>
                        <Form.Label>Tracking Number(s)</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          ref={this.deliveriesRef}
                          onChange={(e) => {
                            this.calculateNumberOfPackages(e.target.value);
                          }}
                        />
                        {packages.length > 0 && (
                          <Form.Label className="pt-3">
                            <b>No. of packages:</b> {packages.length}
                          </Form.Label>
                        )}
                      </Form.Group>
                      <Form.Group controlId="stars" as={Col} sm={6}>
                        <Form.Label>Star</Form.Label>
                        <Select
                          ref={this.starSelectRef}
                          name="stars"
                          options={
                            stars && stars.data
                              ? formatOptions(
                                  '_id',
                                  'profile.firstName,profile.lastName',
                                  stars.data
                                )
                              : []
                          }
                          onChange={this.starsSelectChanged}
                          isClearable
                        />
                      </Form.Group>
                    </Form.Row>
                    <div className="display-flex justify-content-end">
                      <Button className="primaryButton" type="submit">
                        Mark added orders as delivered
                      </Button>
                    </div>
                  </Form>
                }
              />
            </>
          }
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs())
});
const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDeliveries);
