import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';

import { getPurchaseList, exportInventoryRequests } from 'services/fulfillment';
import {
  REQUESTS_QUICK_FILTERS,
  INVENTORY_REQUESTS_COLUMNS,
  FILTERS,
  INVENTORY_PAGE_SIZE_OPTIONS
} from 'constants/fulfillment';
import { downloadAsXlsx } from 'utils';
import { cleanEmptyString } from 'utils/helpers';
import { BusinessesContext } from 'contexts/fulfilment.context';

import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './InventoryRequests.less';

const InventoryRequests = ({ intl, history }) => {
  const refreshRequests = useRef();
  const { businesses, isPageLoading } = useContext(BusinessesContext);
  const [requestsData, setRequestsData] = useState({ data: [], count: 0 });
  const [businessId, setBusinessId] = useState(null);

  const acceptMethods = (refreshMethod) => {
    refreshRequests.current = refreshMethod;
  };

  useEffect(() => {
    if (history.location?.state?.bussinessId) {
      handleOnChangeBusiness(history.location?.state.bussinessId);
    }
  }, []);

  const fetchInventoryRequests = async ({
    page,
    limit,
    searchInputText,
    quickFiltersValue,
    recievedEnd,
    recievedStart,
    ...props
  }) => {
    if (!businessId) {
      return {
        list: [],
        total: 0
      };
    }
    try {
      const {
        data: { data, count }
      } = await getPurchaseList({
        page,
        limit,
        purchaseName: searchInputText?.trim(),
        requestState: quickFiltersValue?.length ? quickFiltersValue : undefined,
        recievedStartDate: recievedStart,
        recievedEndDate: recievedEnd,
        businessId,
        ...props
      });
      setRequestsData({ data, count });
      return {
        count,
        list: data,
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportFile = async (payload, filterValues) => {
    try {
      const exportedData = await exportInventoryRequests(
        cleanEmptyString({
          businessId,
          requestState:
            filterValues?.requestState || filterValues?.quickFiltersValue,
          exportedIds:
            (filterValues?.searchInputText || filterValues?.recievedStart) &&
            requestsData?.data?.length
              ? requestsData.data?.map(({ id }) => {
                  return String(id);
                })
              : payload?.selectedRowKeys?.length
              ? payload?.selectedRowKeys?.map((num) => {
                  return String(num);
                })
              : undefined
        })
      );
      const date = new Date();
      const exportedFileName = `Inventory_Requests_Export_${date.getDate()}_${
        date.getMonth() + 1
      }_${date.getFullYear()}`;
      downloadAsXlsx(exportedData, exportedFileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnChangeBusiness = useCallback(async (businessId) => {
    await setBusinessId(businessId);
    refreshRequests.current();
  }, []);

  return (
    <LoadingWrapper loading={isPageLoading}>
      <div className="br-inventory-requests">
        <div className="br-inventory__business-selection-container">
          <div className="br-inventory__business-header">
            {intl.formatMessage({
              id: 'fulfillment.inventory.business'
            })}
          </div>
          <Select
            className="br-inventory__business-selector"
            placeholder={intl.formatMessage({
              id: `orders.mass_upload.business_placeholder`
            })}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleOnChangeBusiness}
            value={businessId}
          >
            {businesses?.map(({ _id, name }) => (
              <Select.Option key={_id} value={_id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <BRTable
          allowSelection
          showSearch
          showExport
          title={intl.formatMessage(
            {
              id: `fulfillment.inventory_requests.title`
            },
            { count: requestsData.count }
          )}
          searchPlaceholder={intl.formatMessage({
            id: `fulfillment.inventory_requests.search_placeholder`
          })}
          rowKey="id"
          shareMethods={acceptMethods}
          listFunction={fetchInventoryRequests}
          pageSizeOptions={INVENTORY_PAGE_SIZE_OPTIONS}
          exportListFileFunction={handleExportFile}
          columns={INVENTORY_REQUESTS_COLUMNS({ businessId })}
          quickFilters={REQUESTS_QUICK_FILTERS}
          tableFilters={FILTERS}
          onRowClick={({ id, picking_type_id, name }) => {
            history.push({
              pathname: `/inventory/requests/${businessId}/${id}`,
              state: {
                warehouse: picking_type_id?.length && picking_type_id[1],
                name
              }
            });
          }}
        />
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(InventoryRequests));
