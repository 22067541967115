import http from 'utils/http';

export const getBusiness = (id) => {
  return http.get(`/businesses/${id}/profile`);
};

export const switchCountry = (countryCode) => {
  return http.get(`/users/switch-country/${countryCode}`);
};

export const getActiveSessions = () => {
  return http.get('/users/list-active-sessions');
};

export const expireUserSession = (payload) => {
  return http.post('/users/expire-user-sessions', payload);
};
