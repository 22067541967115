import { useState, useEffect, useMemo } from 'react';
import useMedia from 'use-media';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const setPageTitle = (title) => {
  document.title = title;
};

export const usePageTitle = (title) => {
  useEffect(() => {
    setPageTitle(`Bosta - ${title}`);
    return () => {
      setPageTitle('Bosta Business Dashboard');
    };
  }, [title]);
};

export const useOnScreen = (ref, threshold = 0.5, delay = 50) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
          root: document.querySelector('#scrollArea'),
          threshold,
          delay
        }
      ),
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const mediaHook = (Component) => {
  return function WrappedComponent(props) {
    const isTinylMobileScreen = useMedia({ maxWidth: '328px' });
    const isSmallMobileScreen = useMedia({ maxWidth: '576px' });
    const isMediumMobileScreen = useMedia({ maxWidth: '768px' });
    const isLargeMobileScreen = useMedia({ maxWidth: '992px' });
    const isXLargeMobileScreen = useMedia({ maxWidth: '1440px' });
    const isExtraLargeMobileScreen = useMedia({ maxWidth: '1870px' });
    const mobileScreenSizes = {
      isTinylMobileScreen,
      isSmallMobileScreen,
      isMediumMobileScreen,
      isLargeMobileScreen,
      isXLargeMobileScreen,
      isExtraLargeMobileScreen
    };
    return <Component {...props} mobileScreenSizes={mobileScreenSizes} />;
  };
};
