import React, { useEffect, useState } from 'react';
import { Modal, Form, Switch, Divider, Input } from 'antd';
import { useIntl } from 'react-intl';

import { bulkEditMaterials, getPackagingMaterials } from 'services/packaging';

import { notify } from 'components/Notify/Notify';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import {
  getCurrency,
  isPackagingFreeItemsAvilable,
  isPriceColumnInPackagingMaterialsVailable
} from 'common/countries/countries-mapping';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './MaterialBulkEditModal.less';

const MaterialBulkEditModal = ({
  material,
  close,
  setIsLoading,
  onSuccess,
  ...props
}) => {
  const [items, setItems] = useState([]);
  const [description, setDescription] = useState('');

  const intl = useIntl();

  const handleOnChange = (name, id, ...rest) => {
    setItems((prev) =>
      prev.map((item) => {
        if (item.id !== id) return item;
        return {
          ...item,
          [name]: rest[0][1]
        };
      })
    );
  };

  const onSwitchChange = (checked, id) => {
    setItems((prev) =>
      prev.map((item) => {
        if (item.id !== id) return item;
        item.avalible = checked ? 1 : 0;
        return item;
      })
    );
  };

  const handleOnDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleOnOk = async () => {
    setIsLoading(true);
    try {
      const payload = {
        updates: items
      };
      if (description) {
        payload.updates = items.map((item) => ({ ...item, description }));
      }
      await bulkEditMaterials(payload);
      close();
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getFlyersData = async () => {
    try {
      const { ItemsList } = await getPackagingMaterials({
        pageSize: 50,
        pageNumber: 0,
        payload: isPackagingFreeItemsAvilable() ? { free: 0 } : null
      });
      setItems(
        ItemsList.map((item) => ({
          title: item.title,
          id: item.id,
          price: item.price,
          avalible: item.avalible,
          total_balance: item.total_balance
        }))
      );
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    getFlyersData();
  }, []);

  return (
    <Modal
      title={`Bulk update packaging material`}
      onCancel={close}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      onOk={handleOnOk}
      className="br-material-bulk-edit-modal"
      {...props}
    >
      <LoadingWrapper loading={!!!items.length}>
        {items.map((item) => (
          <>
            <div className="br-edit-material-modal__content" key={item.id}>
              <div className="br-bulk-edit-modal__item-header">
                <span className="display-sm">{item.title}</span>
                <div>
                  <Switch
                    checked={item.avalible}
                    onChange={(checked) => onSwitchChange(checked, item.id)}
                    size="small"
                  />
                  <span className="body-medium"> Enable Item </span>
                </div>
              </div>
              <div className="br-edit-material-modal__inputs-container">
                <Form.Item
                  label={intl.formatMessage({
                    id: 'packaging.manage_materials.table.quantity'
                  })}
                >
                  <BRFormInputNumber
                    handleOnChangeForm={(...props) =>
                      handleOnChange('total_balance', item.id, props)
                    }
                    name="quantity"
                    value={item.total_balance}
                    acceptNegative={true}
                  />
                </Form.Item>
                {isPriceColumnInPackagingMaterialsVailable() &&
                  !material?.free && (
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'packaging.manage_materials.table.price'
                      })}
                    >
                      <BRFormInputNumber
                        handleOnChangeForm={(...props) =>
                          handleOnChange('price', item.id, props)
                        }
                        name="price"
                        value={item.price}
                        suffix={getCurrency().type}
                        acceptFloat
                      />
                    </Form.Item>
                  )}
              </div>
            </div>
            <Divider />
          </>
        ))}

        <Form.Item
          label={intl.formatMessage({
            id: 'packaging.manage_materials.description'
          })}
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'packaging.manage_materials.description_placeholder'
            })}
            autoSize={{ minRows: 3, maxRows: 3 }}
            onChange={handleOnDescriptionChange}
          />
        </Form.Item>
      </LoadingWrapper>
    </Modal>
  );
};

export default MaterialBulkEditModal;
