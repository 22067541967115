import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';

import { openModal } from 'utils/modal';
import { deleteHubDistrict, deleteHubZone } from 'services/zoning';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import CreateEditZoneModal from '../CreateEditZoneModal/CreateEditZoneModal';
import CreateEditDistrictModal from '../CreateEditDistrictModal/CreateEditDistrictModal';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';
import { ReactComponent as AddZoneIcon } from 'assets/imgRevamp/add-zone-icon.svg';
import { ReactComponent as Delete } from 'assets/bosta-icons/Delete.svg';

import './ZoningTable.less';

const ZoningTable = ({
  intl,
  zoningData,
  selectedCityId,
  cities,
  filterZoningData,
  updateZoningData,
  filteredDistrictsCount,
  currentSearchText,
  setCurrentSearchText,
  handleFilterClear,
  allHubs
}) => {
  const refreshTableRef = useRef('');

  const acceptMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const refreshTable = (clearSearch) => {
    refreshTableRef.current({ clearSearch });
  };

  useEffect(() => {
    refreshTable();
  }, [zoningData]);

  const handleEditZone = (zoneData) => {
    const {
      zoneId,
      name,
      nameAr,
      pickupAvailability,
      dropOffAvailability,
      hub,
      fmHub
    } = zoneData;

    openModal(CreateEditZoneModal, {
      name,
      nameAr,
      selectedCityId,
      pickupAvailability,
      dropOffAvailability,
      zoneId,
      hubId: hub?._id,
      fmHubId: fmHub?._id,
      cities,
      updateZoningData,
      allHubs,
      isEditMode: true
    });
  };

  const handleEditDistrict = (districtData) => {
    const {
      _id,
      name,
      nameAr,
      zone,
      pickupAvailability,
      dropOffAvailability,
      hub = {},
      fmHub = {}
    } = districtData;

    openModal(CreateEditDistrictModal, {
      name,
      nameAr,
      zone,
      cities,
      selectedCityId,
      pickupAvailability,
      dropOffAvailability,
      districtId: _id,
      hubId: hub._id,
      fmHubId: fmHub._id,
      updateZoningData,
      allHubs,
      zoningData,
      isEditMode: true
    });
  };

  const handleDelete = async (id, isZone) => {
    try {
      isZone ? await deleteHubZone(id) : await deleteHubDistrict(id);
      updateZoningData();
      notify(
        intl.formatMessage({
          id: `zoning.delete_${
            isZone ? 'zone' : 'district'
          }_confirmation.success_message`
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleDeleteDistrictOrZone = ({
    _id,
    isZone,
    districtsCount,
    zoneId
  }) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: `zoning.delete_${isZone ? 'zone' : 'district'}_confirmation.title`
      }),
      content:
        isZone && !!districtsCount
          ? intl.formatMessage({
              id: `zoning.delete_zone_confirmation.has_districts_confirmation_message`
            })
          : isZone
          ? intl.formatMessage({
              id: `zoning.delete_zone_confirmation.confirmation_message`
            })
          : intl.formatMessage({
              id: `zoning.delete_district_confirmation.confirmation_message`
            }),
      modalAction: () => handleDelete(isZone ? zoneId : _id, isZone)
    });
  };

  const actionsMenu = (record) => {
    return (
      <Dropdown
        overlay={menuItems(record)}
        overlayClassName="br-zoning-add-new__dropdown"
        trigger={['click']}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement
        }
      >
        <BRButton type="table-actions" suffixIcon={<Actions />} />
      </Dropdown>
    );
  };

  const menuItems = (record) => {
    const { isZone, _id, districtsCount, zoneId } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            isZone ? handleEditZone(record) : handleEditDistrict(record)
          }
        >
          <AddZoneIcon />
          {isZone
            ? intl.formatMessage({
                id: 'zoning.create_edit_zone_modal.edit_zone'
              })
            : intl.formatMessage({
                id: 'zoning.create_edit_district_modal.edit_district'
              })}
        </Menu.Item>
        <Menu.Item
          className="br-zoning__delete-action"
          onClick={() =>
            handleDeleteDistrictOrZone({ _id, isZone, districtsCount, zoneId })
          }
        >
          <Delete />
          {intl.formatMessage({ id: 'common.delete' })}
        </Menu.Item>
      </Menu>
    );
  };

  const expandable = {
    expandedRowKeys: [selectedCityId],
    expandIcon: () => null
  };

  const columns = [
    {
      dataIndex: 'city',
      key: 'city',
      width: 10
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.zone_name_en' }),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.zone_name_ar' }),
      dataIndex: 'nameAr',
      key: 'nameAr'
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.pickup' }),
      dataIndex: 'pickupAvailability',
      key: 'pickupAvailability',
      width: 120,
      render: (pickupAvailability, record) =>
        !record.isCity &&
        intl.formatMessage({
          id: `zoning.${pickupAvailability ? 'covered' : 'uncovered'}`
        })
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.delivery' }),
      dataIndex: 'dropOffAvailability',
      key: 'dropOffAvailability',
      width: 120,
      render: (dropOffAvailability, record) =>
        !record.isCity &&
        intl.formatMessage({
          id: `zoning.${dropOffAvailability ? 'covered' : 'uncovered'}`
        })
    },
    {
      title: intl.formatMessage({ id: 'zoning.hub' }),
      dataIndex: 'hub',
      key: 'hub',
      width: 120,
      render: (hub, record) =>
        !record.isCity &&
        (hub ? hub.name : intl.formatMessage({ id: 'common.empty_field' }))
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.fm_hub' }),
      dataIndex: 'fmHub',
      key: 'fmHub',
      width: 120,
      render: (fmHub, record) =>
        !record.isCity &&
        (fmHub ? fmHub.name : intl.formatMessage({ id: 'common.empty_field' }))
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.added_by' }),
      dataIndex: 'addedBy',
      key: 'addedBy',
      width: 160,
      render: (addedBy, record) =>
        !record?.isCity && (
          <div className="caption">
            <div>
              {addedBy?.userName ||
                intl.formatMessage({ id: 'zoning.system_admin' })}
            </div>
            <div className="br-zoning-table__time">
              {dayjs(record.createdAt).format('ddd, DD MMMM YYYY')}
            </div>
          </div>
        )
    },
    {
      title: intl.formatMessage({ id: 'zoning.table_columns.last_update' }),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 160,
      render: (updatedBy, record) =>
        !record?.isCity && (
          <div className="caption">
            <div>
              {updatedBy?.userName ||
                intl.formatMessage({ id: 'zoning.system_admin' })}
            </div>
            <div className="br-zoning-table__time">
              {dayjs(record.updatedAt).format('ddd, DD MMM YYYY')}
            </div>
          </div>
        )
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 20,
      render: (_, record) => !record?.isCity && actionsMenu(record)
    }
  ];

  const getZoningData = ({ searchInputText }) => {
    if (!selectedCityId) {
      return;
    }

    if (!searchInputText && currentSearchText) {
      setCurrentSearchText(null);
      return handleFilterClear();
    }

    if (searchInputText !== currentSearchText) {
      setCurrentSearchText(searchInputText);
      filterZoningData({ searchText: searchInputText?.trim() });
    }

    return {
      list: zoningData
    };
  };

  return (
    <div className="br-zoning-table__container">
      <BRTable
        className="br-zoning__table"
        title={intl.formatMessage(
          { id: 'zoning.table_title' },
          { count: filteredDistrictsCount }
        )}
        columns={columns}
        expandable={expandable}
        listFunction={getZoningData}
        rowClassName={(record) =>
          record?.isZone ? 'br-zoning-table__zone-row' : ''
        }
        searchPlaceholder={intl.formatMessage({
          id: 'zoning.search_placeholder'
        })}
        showSearch={true}
        showExport={false}
        showFilter={false}
        pagination={false}
        keepDataBetweenTabs
        shareMethods={acceptMethods}
      />
    </div>
  );
};

export default injectIntl(ZoningTable);
