import classNames from 'classnames';
import { Skeleton } from 'antd';
import { DELIVERIES_TYPE } from 'constants/sorting-facility';

import './SingleCardInfo.less';

const SingleInfoCard = ({ title, data, type, loading }) => {
  return (
    <div className="single-info__container">
      <h3 className="single-info__header body-medium">{title}</h3>
      <div className="single-info__data-container">
        {loading ? (
          <Skeleton active paragraph={{ rows: 0 }} />
        ) : (
          <h6
            className={classNames(
              {
                'single-info__pinding-orders': type === DELIVERIES_TYPE.PENDING
              },
              {
                'single-info__damaged-orders': type === DELIVERIES_TYPE.DAMAGED
              },
              'display-md'
            )}
          >
            {data}
          </h6>
        )}
      </div>
    </div>
  );
};
export default SingleInfoCard;
