import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import BRButton from 'components/BRButton/BRButton';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import BRTable from 'components/BRTable/BRTable';

import {
  INTERNATIONAL_DELIVERIES_COLUMNS,
  INTERNATIONAL_DELIVERY_UPDATE_TYPES
} from 'constants/international-orders';

import { ReactComponent as EmptyStateIcon } from 'assets/bosta-icons/Empty-International-Orders.svg';
import { ReactComponent as RocketIcon } from 'assets/bosta-icons/rocket.svg';

import './InternationalOrdersActionTable.less';

const InternationalOrdersActionTable = ({
  scannedDeliveries,
  handleTableActionClick,
  updateType
}) => {
  const intl = useIntl();

  const emptyState = () => (
    <BREmptyState customImageComponent={<EmptyStateIcon />} hideImage />
  );

  return (
    <div className="br-international-transfer-table__conatiner">
      {!!scannedDeliveries.length && (
        <div className="br-international-transfer-table__actions">
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: `international_orders.transfer.table.${updateType.toLowerCase()}`
            })}
            prefixIcon={
              updateType === INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER && (
                <Icon component={RocketIcon} className="ant-icon-md" />
              )
            }
            onClick={handleTableActionClick}
          />
        </div>
      )}
      <BRTable
        title={intl.formatMessage(
          {
            id: 'international_orders.transfer.table.title'
          },
          {
            count: scannedDeliveries.length
          }
        )}
        columns={INTERNATIONAL_DELIVERIES_COLUMNS({ isTransferPage: true })}
        listingData={scannedDeliveries}
        emptyState={emptyState}
        showFilter={false}
      />
    </div>
  );
};

export default InternationalOrdersActionTable;
