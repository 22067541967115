import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getPO, editPO } from 'services/fulfillment';
import { openModal } from 'utils/modal';
import {
  FULFILLMENT_INBOUND_MAIN_PATH,
  PO_STATE_IDS_CAN_EDITED_OR_CANCELED
} from 'constants/fulfillment';
import {
  getPORenderedTag,
  getSKU,
  renderPOName,
  unselectProduct
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import PurchaseOrder from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/PurchaseOrder';
import { notify } from 'components/Notify/Notify';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import QuantityInput from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/QuantityInput/QuantityInput';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

const EditPO = ({
  intl,
  history,
  match: {
    params: { id }
  }
}) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [products, setProducts] = useState([]);
  const [fetchedProductsIds, setFetchedProductsIds] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAnythingChanged, setIsAnythingChanged] = useState(false);

  const goToCurrentPOPage = () =>
    history.push(`${FULFILLMENT_INBOUND_MAIN_PATH}/po/${id}`);

  const productsTableColumns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.columns.original_quantity'
      }),
      render: ({ quantity, updatedQuantity, ...rest }) => (
        <QuantityInput
          initialValue={updatedQuantity || quantity}
          productSku={getSKU(rest)}
          setProducts={setProducts}
          quantityKey="updatedQuantity"
        />
      )
    },
    {
      width: 20,
      render: (product) => (
        <CloseIcon
          className="close-icon"
          onClick={() =>
            unselectProduct({ productSku: getSKU(product), setProducts })
          }
        />
      )
    }
  ];

  const handleEditPO = async () => {
    try {
      const deletedItems = [];
      const updatedItems = [];
      const productsList = [...products];

      fetchedProductsIds.forEach((productId) => {
        const index = productsList.findIndex(
          (product) => product.id === productId
        );
        if (index === -1) {
          deletedItems.push(productId);
        } else {
          const { updatedQuantity, quantity } = productsList[index];
          updatedItems.push({
            id: productId,
            quantity: updatedQuantity || quantity
          });
          productsList.splice(index, 1);
        }
      });

      const payload = {
        updatedItems,
        ...(deletedItems.length && {
          deletedItems
        }),
        ...(productsList.length && {
          createdItems: productsList.map(
            ({ quantity, updatedQuantity, ...rest }) => ({
              productSku: getSKU(rest),
              quantity: updatedQuantity || quantity
            })
          )
        })
      };

      await editPO(id, payload);

      notify(
        intl.formatMessage({
          id: 'fulfillment_inbound.edit_po.success_msg'
        }),
        'success'
      );

      goToCurrentPOPage();
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchPO = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await getPO(id);
      if (
        PO_STATE_IDS_CAN_EDITED_OR_CANCELED.includes(
          data.purchaseOrder?.state?.stateId
        )
      ) {
        setPurchaseOrder(data.purchaseOrder);
        setProducts(
          data.purchaseOrder.lines.map((line) => ({
            ...line,
            key: getSKU(line)
          }))
        );
        setFetchedProductsIds(
          data.purchaseOrder.lines.map((product) => product.id)
        );
      } else {
        notify(
          intl.formatMessage({
            id: 'fulfillment_inbound.edit_po.can_not_edit'
          })
        );
        goToCurrentPOPage();
      }
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const checkIfAnythingChanged = () => {
    let isChanged = false;

    if (purchaseOrder.lines && purchaseOrder.lines.length !== products.length) {
      isChanged = true;
    } else {
      for (let i = 0; i < products.length; i++) {
        const { quantity, updatedQuantity } = products[i];
        if (updatedQuantity !== undefined && quantity !== updatedQuantity) {
          isChanged = true;
          break;
        }
      }
    }

    setIsAnythingChanged(isChanged);
  };

  const discardChanges = () => {
    if (isAnythingChanged && products.length) {
      openModal(BRConfirmationModal, {
        wrapClassName: 'br-cancel-po__confirmation-modal',
        title: intl.formatMessage({
          id: 'fulfillment_inbound.edit_po.discard_changes_confirmation_modal.title'
        }),
        message: intl.formatMessage({
          id: 'fulfillment_inbound.edit_po.discard_changes_confirmation_modal.content'
        }),
        onConfirm: goToCurrentPOPage,
        confirmButtonText: intl.formatMessage({
          id: 'fulfillment_inbound.edit_po.discard_changes_confirmation_modal.confirm_btn'
        }),
        cancelButtonText: intl.formatMessage({
          id: 'fulfillment_inbound.edit_po.discard_changes_confirmation_modal.cancel_btn'
        })
      });
    } else {
      goToCurrentPOPage();
    }
  };

  useEffect(() => {
    fetchPO();
  }, []);

  useEffect(() => {
    checkIfAnythingChanged();
  }, [products]);

  return (
    <PurchaseOrder
      purchaseOrder={purchaseOrder}
      products={products}
      setProducts={setProducts}
      selectedBusiness={selectedBusiness}
      setSelectedBusiness={setSelectedBusiness}
      productsTableColumns={productsTableColumns}
      headerProps={{
        icon: CloseIcon,
        iconOnClick: discardChanges,
        title: renderPOName(id),
        renderTag: () =>
          getPORenderedTag({ stateId: purchaseOrder?.state?.stateId }),
        actions: [
          {
            label: intl.formatMessage({
              id: 'fulfillment_inbound.edit_po.actions.cancel'
            }),
            onClick: discardChanges
          },
          {
            label: intl.formatMessage({
              id: 'fulfillment_inbound.edit_po.actions.confirm'
            }),
            type: 'primary',
            disabled: isLoading || !products.length || !isAnythingChanged,
            onClick: handleEditPO
          }
        ]
      }}
      isBusinessEditDisabled
      isEditPOPage
    />
  );
};

export default injectIntl(withRouter(EditPO));
