import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Checkbox } from 'antd';

import { SllrProvider } from 'contexts/sllr.context';
import { getSkuForScan } from 'constants/fulfillment-sales-orders';
import {
  unselectProduct,
  getSKU
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import AddProductBtn from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/AddProductBtn/AddProductBtn';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import QuantityInput from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/QuantityInput/QuantityInput';

import './SelectProductsModal.less';

const SelectProductsModal = ({
  intl,
  selectedBusiness = {},
  products = [],
  onSuccess,
  close,
  columns,
  okText,
  getCounts = () => [],
  listKey,
  fetchData,
  formatData,
  renderEmptyState = () => {},
  showAddProductBtn = false,
  checkById = false,
  isEditPOPage = false,
  searchOptions,
  searchOptionsIds,
  defaultSearchOptionKey,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(products);
  const [selectedSearchKey, setSelectedSearchKey] = useState(
    defaultSearchOptionKey
  );
  const handleCancel = () => {
    close();
  };

  const getTableData = async ({ page, limit, searchInputText }) => {
    setIsLoading(true);
    let value = searchInputText?.trim();
    if (selectedSearchKey === searchOptionsIds.SKU.KEY) {
      value = getSkuForScan(value);
    }
    setSearchValue(value);

    try {
      const payload = {
        page,
        limit,
        ...(selectedBusiness._id && {
          businessId: selectedBusiness._id
        }),
        ...(value && {
          [searchOptionsIds[selectedSearchKey].API_KEY]: value
        })
      };

      const {
        data: { count, ...rest }
      } = await fetchData(payload);
      const list = rest[listKey];

      setFetchedProducts(list);
      setIsLoading(false);

      return {
        list: formatData(list),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const renderFooterCounts = () =>
    getCounts(selectedProducts).map((count, index) => (
      <span key={index}>{count}</span>
    ));

  const renderFooter = () => (
    <>
      <div className="br-po-select-products-modal__footer__counts">
        {!!fetchedProducts.length && renderFooterCounts(selectedProducts)}
      </div>
      <div className="br-po-select-products-modal__footer__actions">
        <BRButton
          label={intl.formatMessage({
            id: 'common.cancel'
          })}
          onClick={handleCancel}
        />
        <BRButton
          type="primary"
          label={okText}
          disabled={isLoading || !selectedProducts.length}
          onClick={onConfirm}
          loading={isLoading}
        />
      </div>
    </>
  );

  const handleSelectProducts = (record, isSelected) => {
    setSelectedProducts((prev) =>
      isSelected
        ? [...prev, record]
        : prev.filter((product) => {
            return checkById
              ? product.id !== record.id
              : getSKU(product) !== getSKU(record);
          })
    );
  };

  const renderSelectedProducts = () => {
    return (
      <div className="br-po-select-products-modal__selected-products">
        <span className="br-po-select-products-modal__label">
          {intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.select_products_modal.selected_products'
          })}
        </span>
        {selectedProducts.map(
          ({
            productName,
            productNameAr,
            quantity,
            updatedQuantity,
            id,
            productImage,
            ...rest
          }) => {
            const sku = getSKU(rest);

            return (
              <div
                key={id || sku}
                className="br-po-select-products-modal__selected-products__product"
              >
                <Checkbox
                  checked
                  onChange={() => {
                    unselectProduct({
                      checkById,
                      productId: id,
                      productSku: sku,
                      setProducts: setSelectedProducts
                    });
                  }}
                />
                <ProductImage src={productImage} />
                <span className="br-po-select-products-modal__product__name">
                  {productName || productNameAr}
                </span>
                {sku && (
                  <span className="br-po-select-products-modal__product__sku">
                    {sku}
                  </span>
                )}
                {quantity !== undefined && (
                  <QuantityInput
                    initialValue={updatedQuantity || quantity}
                    productSku={sku}
                    setProducts={setSelectedProducts}
                    {...(isEditPOPage && {
                      quantityKey: 'updatedQuantity'
                    })}
                  />
                )}
              </div>
            );
          }
        )}
        <span className="br-po-select-products-modal__label">
          {intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.select_products_modal.other_products'
          })}
        </span>
      </div>
    );
  };

  const onClearSearch = () => {
    setSearchValue('');
  };

  const onConfirm = async () => {
    setIsLoading(true);
    await onSuccess(selectedProducts);
    close();
  };

  return (
    <SllrProvider>
      <Modal
        {...restProps}
        wrapClassName="br-po-select-products-modal"
        title={intl.formatMessage({
          id: 'fulfillment_inbound.create_po.products.select_products_modal.title'
        })}
        footer={renderFooter()}
        width={null}
      >
        <LoadingWrapper
          loading={isLoading}
          className="br-po-select-products-modal__loading-wrapper"
        >
          <div className="br-po-select-products-modal__tables">
            {!!fetchedProducts.length && showAddProductBtn && (
              <AddProductBtn
                type="treitary-color"
                className="br-po-products-table__add-product-btn"
              />
            )}

            {!!selectedProducts.length && renderSelectedProducts()}

            <BRTable
              columns={columns}
              listFunction={getTableData}
              showExport={false}
              showFilter={false}
              showHeader={false}
              handleOnSelectRow={handleSelectProducts}
              selectedRowKeys={selectedProducts.map(({ key }) => key)}
              {...(!searchValue && { emptyState: renderEmptyState })}
              doAfterClearSearch={onClearSearch}
              allowSelection
              searchPlaceholder={intl.formatMessage(
                {
                  id: 'fulfillment_products.products_table.search_placeholder'
                },
                {
                  searchBy: searchOptionsIds?.[selectedSearchKey]?.LABEL
                }
              )}
              showSearch={fetchedProducts.length}
              searchOptions={searchOptions}
              selectedSearchOption={selectedSearchKey}
              setSelectedSearchOption={setSelectedSearchKey}
            />
          </div>
        </LoadingWrapper>
      </Modal>
    </SllrProvider>
  );
};
export default injectIntl(SelectProductsModal);
