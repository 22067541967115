import React, { useState } from 'react';
import { Modal, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { notify } from 'components/Notify/Notify';

import { updatePriorityWeight } from 'services/live-ops-priorities';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './EditWeightModal.less';

const EditWeightModal = (props) => {
  const { intl, id, currentWeight, tableRef, close, title } = props;

  const [weight, setWeight] = useState(currentWeight);

  const handleOnCancel = () => {
    close();
  };

  const handleWeightChange = (weight) => {
    if (weight >= 4) {
      setWeight(4);
    } else if (weight <= 1) {
      setWeight(1);
    } else {
      setWeight(weight || 1);
    }
  };

  const hanldePriorityEdit = async () => {
    try {
      const payload = { id, weight };
      await updatePriorityWeight(payload);
      tableRef.current();
      notify(
        intl.formatMessage({
          id: 'settings.live_ops_priorities.modal.success_message'
        }),
        'success'
      );
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      {...props}
      className="br-edit-weight-modal"
      title={null}
      // closeIcon={<Icon component={CloseIcon} onClick={handleOnCancel} />}
      okText={intl.formatMessage({
        id: 'settings.live_ops_priorities.modal.buttons.save'
      })}
      onOk={hanldePriorityEdit}
      onCancel={handleOnCancel}
    >
      <div className="br-edit-weight-modal__heading">
        <span>{title}</span>
      </div>
      <p className="select-weight">
        {intl.formatMessage({
          id: 'settings.live_ops_priorities.modal.weight'
        })}
      </p>
      <InputNumber
        value={weight}
        onChange={handleWeightChange}
        min={1}
        max={4}
      />
    </Modal>
  );
};
export default injectIntl(EditWeightModal);
