import { Modal, Form, Input } from 'antd';
import { useIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

const AddNoteModal = ({ close, onSuccess, ...props }) => {
  const intl = useIntl();

  const onFinish = (values) => {
    onSuccess(values);
    close();
  };

  return (
    <Modal
      onCancel={close}
      title={intl.formatMessage({ id: 'businesses.add_note_modal.title' })}
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({ id: 'common.ok' })}
            htmlType="submit"
            form="noteForm"
          />
        </>
      }
      {...props}
    >
      <Form onFinish={onFinish} name="noteForm">
        <Form.Item
          name="notes"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'businesses.add_note_modal.label'
          })}
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'businesses.add_note_modal.label'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNoteModal;
