import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import BRButton from 'components/BRButton/BRButton';

import './DatesFilter.less';

const DatesFilter = ({
  onClose,
  datesList = [],
  datesFilter,
  setDatesFilter
}) => {
  const [dates, setDates] = useState(datesFilter);

  const { formatMessage } = useIntl();

  const onDateChange = (key, date) => {
    const params = {
      from: date && dayjs.utc(date[0]).startOf('day').format(),
      to: date && dayjs.utc(date[1]).endOf('day').format()
    };

    setDates((prev) => {
      if (prev[key] && !date) {
        delete prev[key];
        return { ...prev };
      }

      return {
        ...prev,
        [key]: params
      };
    });
  };

  const onConfirm = () => {
    setDatesFilter(dates);
    onClose();
  };

  const renderFooter = () => (
    <BRButton
      type="primary"
      label={formatMessage({
        id: 'fulfillment_common.filter_by_date_drawer.apply_filters'
      })}
      disabled={isEmpty(dates)}
      onClick={onConfirm}
    />
  );

  return (
    <Drawer
      title={
        <>
          <span>
            {formatMessage({
              id: 'fulfillment_common.filter_by_date_drawer.title'
            })}
          </span>
        </>
      }
      footer={renderFooter()}
      className="br-dates-filter"
      placement="right"
      onClose={onClose}
      visible
    >
      {datesList.map(({ label, apiKey }, index) => (
        <Form.Item
          key={index}
          label={label}
          className="br-date-filter-form-item"
        >
          <DatePicker.RangePicker
            className="br-date-filter"
            onChange={(date) => onDateChange(apiKey, date)}
            {...(datesFilter[apiKey] && {
              defaultValue: [
                dayjs(datesFilter[apiKey].from),
                dayjs(datesFilter[apiKey].to)
              ]
            })}
          />
        </Form.Item>
      ))}
    </Drawer>
  );
};

export default DatesFilter;
