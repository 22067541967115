import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import EmptyTableView from 'assets/imgRevamp/EmptyTableView.png';
import EmptySearchView from 'assets/bosta-icons/Empty-Search-View.svg';

import './BREmptyState.less';

const BREmptyState = ({
  intl,
  title,
  subTitle,
  search,
  actions,
  className,
  emptyViewImage,
  customImageComponent,
  hideImage = false
}) => {
  return (
    <div className={classnames('br-empty-state', className)}>
      {!hideImage && (
        <img
          alt=""
          className="br-empty-state__image"
          src={
            emptyViewImage
              ? emptyViewImage
              : search
              ? EmptySearchView
              : EmptyTableView
          }
        />
      )}
      {customImageComponent && customImageComponent}
      <p className="br-empty-state__title heading">
        {title
          ? title
          : search
          ? intl.formatMessage({
              id: 'br_searchable_table.empty_view.no_search_title'
            })
          : intl.formatMessage({
              id: 'br_searchable_table.empty_view.title'
            })}
      </p>
      {subTitle && <p className="br-empty-state__sub-title body">{subTitle}</p>}
      {actions && <div className="br-empty-state__actions">{actions}</div>}
    </div>
  );
};

export default injectIntl(BREmptyState);
