import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import BRButton from 'components/BRButton/BRButton';

const EditWeightModal = ({ close, weight, handleUpdateWeight, ...props }) => {
  const formRef = useRef();

  const intl = useIntl();

  const onFinish = (values) => {
    handleUpdateWeight(Number(values.weight).toString());
    close();
  };

  const handleOnChangeForm = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.edit_weight'
      })}
      onCancel={close}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            form="updateWeight"
            htmlType="submit"
            type="primary"
            label={intl.formatMessage({ id: 'common.confirm' })}
          />
        </>
      }
      okText={intl.formatMessage({ id: 'common.confirm' })}
      {...props}
    >
      <Form
        ref={formRef}
        onFinish={onFinish}
        initialValues={{ weight }}
        name="updateWeight"
      >
        <Form.Item
          name="weight"
          label={intl.formatMessage({
            id: 'international_orders.deliveries.delivery_details.fields.weight'
          })}
        >
          <BRFormInputNumber
            filedName="weight"
            formRef={formRef}
            handleOnChangeForm={handleOnChangeForm}
            prefix={intl.formatMessage({ id: 'common.kg' })}
            acceptFloat
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditWeightModal;
