/* eslint-disable react/prop-types */
import React from 'react';
// import DatePicker from 'antd/es/date-picker';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
// import 'antd/es/date-picker/style/css';

// eslint-disable-next-line no-empty-pattern
const Calendar = ({ disabledDate, value, handleChange, ...rest }) => {
  return (
    <DatePicker.RangePicker
      size="small"
      defaultValue={['', '']}
      {...rest}
      ranges={{
        'Last 7 days': [dayjs().subtract(7, 'day'), dayjs()],
        'Last 14 days': [dayjs().subtract(14, 'day'), dayjs()],
        'Last 30 days': [dayjs().subtract(30, 'day'), dayjs()],
      }}
      onChange={(dates, dateStrings) => {
        handleChange(dates, dateStrings);
      }}
      disabledDate={disabledDate}
      allowClear
      value={value}
    />
  );
};

export default Calendar;
