import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_RETURNS_MAIN_PATH,
  FULFILLMENT_RETURNS_ROUTES
} from 'constants/fulfillment-return-orders';

const FulfillmentReturns = () => {
  return (
    <Switch>
      {FULFILLMENT_RETURNS_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_RETURNS_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentReturns);
