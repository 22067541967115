import http from 'utils/http';

export const getHubScores = (payload) => {
  return http.get(`/hubs/hubScores`, payload);
};

export const getAllHubsScores = (payload) => {
  return http.get(`/hubs/allHubsScores`, payload);
};

export const getPerformanceCard = (payload) => {
  return http.get(`hubs/performanceCard`, payload);
};

export const exportHubsPerformanceTable = (payload) => {
  return http.get(`hubs/exportAllHubsScores`, payload);
};

export const getStarPerformanceThirdParty = (payload) => {
  return http.get(`hubs/starPerformancethirdParty`, payload);
};

export const exportStarsPerformanceTable = (payload) => {
  return http.get(`hubs/starPerformancethirdParty/export`, payload);
};
