import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import {
  isDefaultBostaCourier,
  isDSPVendor,
  isUserAuthorized
} from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { isReceiveFromBinsAvailable } from 'common/countries/countries-mapping';

import BRTabs from 'components/BRTabs/BRTabs';
import ReceiveNewPickups from 'containers/Hub/ReceiveNewPickups/ReceiveNewPickups';
import ReceiveFromHubContainer from '../ReceiveFromHubContainer/ReceiveFromHubContainer';
import ReceiveFromBin from './components/ReceiveFromBin/ReceiveFromBin';

import './ReceiveContainer.less';

const ReceiveContainer = ({ intl, match: { path } }) => {
  const DEFAULT_TAB_PATH =
    isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
    isDSPVendor()
      ? 'receive-new-pickups'
      : isReceiveFromBinsAvailable()
      ? 'receive-from-bin'
      : 'receive-from-hub';

  const TABS = {
    receive_pickups: {
      label: intl.formatMessage({ id: 'hubs.receive.tabs.pickups' }),
      component: ReceiveNewPickups,
      path: '/hubs/hub-operation/receive/receive-new-pickups',
      isUserAuthorized:
        isUserAuthorized(aclMatrix.DISPATCH_RTO_TAB) &&
        (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
          isDSPVendor())
    },
    receive_from_sorting: {
      label: intl.formatMessage({ id: 'hubs.receive.tabs.sorting' }),
      component: ReceiveFromHubContainer,
      path: '/hubs/hub-operation/receive/receive-from-hub',
      exact: false,
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS)
    },
    receive_in_transit: {
      label: intl.formatMessage({ id: 'hubs.receive.tabs.in_transit' }),
      component: ReceiveFromBin,
      path: '/hubs/hub-operation/receive/receive-from-bin',
      isUserAuthorized:
        !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
        isReceiveFromBinsAvailable()
    }
  };

  return (
    <Switch>
      <Route
        path={Object.keys(TABS).map((tab) => TABS[tab].path)}
        render={() => (
          <div className="br-hub-receive__tabs">
            <BRTabs tabs={TABS} />
          </div>
        )}
      />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default withRouter(injectIntl(ReceiveContainer));
