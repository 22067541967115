import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

const difference = (newObject, oldObject, getArrayValuesDiff = true) => {
  const changes = (object, base) => {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        if (isArray(value) && !getArrayValuesDiff) {
          result[key] = value;
        } else {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      }
    });
  };

  if (!isObject(newObject) && !isArray(newObject)) {
    return !isEqual(newObject, oldObject) ? newObject : {};
  } else {
    return changes(newObject, oldObject);
  }
};
export default difference;
