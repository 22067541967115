import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Menu, Tooltip } from 'antd';
import dayjs from 'dayjs';
import classnames from 'classnames';

import {
  assignPickupToRoute,
  editPickup,
  exportPickups,
  fetchPickups,
  getPickupsCount,
  updatePickupState
} from 'services/pickups';
import { updateOrder } from 'services/shipments';
import { formatAddress } from 'utils';
import { mapCancelReason } from 'utils/pickups';
import {
  cleanEmptyString,
  isDefaultBostaCourier,
  isDSPVendor,
  isUserAuthorized
} from 'utils/helpers';
import {
  CAN_CANCEL_ROLE,
  CRP_SIGN_AND_RETURN_PICKUP_TYPES,
  PICKEDUP_DATE_FORMAT,
  PICKUPS_REQUESTS_COLUMNS,
  PICKUP_CANCEL_REASONS_OPTIONS,
  PICKUP_REQUESTS_CANCEL_REASON,
  PICKUP_TYPES
} from 'constants/pickups';
import {
  pickupStates,
  formatOptions,
  PACKAGE_TYPE_COLOR
} from 'constants/pickups';
import { ASSIGN_TO_HUB_UPDATE_TYPE } from 'constants/shipments';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { getHubs } from 'services/hubs';
import { openModal } from 'utils/modal';
import { ACL_MATRIX } from 'common/permissions';

import { notify } from 'components/Notify/Notify';
import BRHeader from 'components/BRHeader/BRHeader';
import BRButton from 'components/BRButton/BRButton';
import PickupsSearch from './components/PickupsSearch/PickupsSearch';
import Container from 'components/Container/index';
import PickupMapModal from 'components/Pickups/components/PickupMapModal/PickupMapModal';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import BRTable from 'components/BRTable/BRTable';
import BRTag from 'components/BRTag/BRTag';
import AssignToRouteModal from './components/AssignToRouteModal/AssignToRouteModal';

import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';
import { ReactComponent as NewOrder } from 'assets/bosta-icons/New order.svg';
import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as CircleClear } from 'assets/bosta-icons/Circle-clear.svg';
import { ReactComponent as DropdownIcon } from 'assets/bosta-icons/Dropdown.svg';
import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

import './PickupsRequests.less';

export const PAGE_SIZE = 50;

const PickupsRequests = () => {
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [searchValues, setSearchValues] = useState({});
  const [hubs, setHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pickupsCount, setPickupsCount] = useState(0);
  const [showPickupCount, setShowPickupCount] = useState(false);

  const refreshTable = useRef();

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    fetchHubs();
  }, []);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const searchPickups = async ({ page, filterValues }) => {
    setIsLoading(true);
    try {
      const payload = {
        pageId: page,
        pageLimit: PAGE_SIZE,
        ...(filterValues ? { ...filterValues } : { ...searchValues })
      };
      const { result } = await fetchPickups(payload);

      setIsLoading(false);
      return {
        list: formatData(result?.pickups || []),
        total: getTotal({ currentPage: page, pickups: result?.pickups || [] })
      };
    } catch (error) {
      setIsLoading(false);
      notify(error.message);
    }
  };

  const fetchHubs = async () => {
    try {
      const { result } = await getHubs();
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
  };

  const getCount = async () => {
    setIsCountLoading(true);
    try {
      const { count } = await getPickupsCount(cleanEmptyString(searchValues));
      setPickupsCount(count);
      setShowPickupCount(true);
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsCountLoading(false);
  };

  const handleCancelPickupClick = (pickupRequestId) => {
    openModal(BRDropdownModal, {
      title: intl.formatMessage({
        id: 'pickups.pickup_request.cancel_pickup_modal.title'
      }),
      dropdownPlaceholder: intl.formatMessage({
        id: 'pickups.pickup_request.cancel_pickup_modal.reason_label'
      }),
      confirmBtnText: intl.formatMessage({ id: 'common.confirm' }),
      dropdownData: PICKUP_CANCEL_REASONS_OPTIONS,
      onConfirm: (value) =>
        handleCancelPickup({ pickupRequestId, reason: value })
    });
  };

  const handleCancelPickup = async ({ pickupRequestId, reason }) => {
    setIsLoading(true);
    try {
      const payload = {
        pickupRequestIds: [pickupRequestId],
        state: pickupStates.Cancel,
        reason
      };

      await updatePickupState(payload);
      notify(
        intl.formatMessage({
          id: 'pickups.pickup_request.cancel_pickup_modal.success_message'
        }),
        'success'
      );
      refreshTable.current({ pageNumber: 1 });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDownloadXlsx = async (payload) => {
    try {
      const { message } = await exportPickups(payload);

      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportXlsx = async () => {
    setIsLoading(true);
    if (selectedRowsIds.length === 0) {
      handleDownloadXlsx({
        ...searchValues,
        pageId: undefined,
        pageLimit: undefined
      });
    } else {
      const pickupsIds = selectedRowsIds.join(',');
      handleDownloadXlsx({
        pickupRequestId: pickupsIds
      });
    }
  };

  const handleAddToRoute = () => {
    if (selectedRowsIds.length === 0) {
      notify(
        intl.formatMessage({
          id: 'pickups.pickup_request.error_msgs.no_pickup_selected'
        })
      );
    } else {
      handleAssignToRouteClick();
    }
  };

  const handleSubmit = (values) => {
    setSearchValues(values);
    setShowPickupCount(false);
    refreshTable.current({ pageNumber: 1, filterValues: values });
  };

  const handleAssignToHubClick = (pickupData) => {
    const { _id, type, business, deliveries } = pickupData;
    openModal(BRDropdownModal, {
      title: intl.formatMessage({
        id: 'pickups.pickup_request.assign_to_hub_modal.title'
      }),
      dropdownPlaceholder: intl.formatMessage({ id: 'common.select' }),
      dropdownData: formatOptions('_id', 'name', hubs),
      onConfirm: (warehouseId) =>
        CRP_SIGN_AND_RETURN_PICKUP_TYPES.includes(type)
          ? handlePickupOrdersManualAssigning({
              warehouseId,
              pickupDeliveries: deliveries
            })
          : handleManualAssigning({
              warehouseId,
              businessId: business._id,
              pickupRequestId: _id
            })
    });
  };

  const handlePickupOrdersManualAssigning = async ({
    warehouseId,
    pickupDeliveries
  }) => {
    setIsLoading(true);
    try {
      const payload = {
        warehouseId,
        updateType: ASSIGN_TO_HUB_UPDATE_TYPE
      };
      await updateOrder({ id: pickupDeliveries[0]._id, payload });
      refreshTable.current({ pageNumber: 1 });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleManualAssigning = async ({
    warehouseId,
    businessId,
    pickupRequestId
  }) => {
    setIsLoading(true);
    try {
      const payload = {
        businessId,
        warehouseId
      };
      await editPickup({ id: pickupRequestId, payload });
      notify(
        intl.formatMessage({
          id: 'pickups.pickup_request.assign_to_hub_modal.success_message'
        }),
        'success'
      );
      refreshTable.current({ pageNumber: 1 });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleAssignToRouteClick = (pickupRequestId) => {
    openModal(AssignToRouteModal, {
      onConfirm: (selectedRoute) =>
        pickupRequestId
          ? handleAssignToRoute({
              routeId: selectedRoute.value,
              pickupRequestId
            })
          : confirmAssignToRoute({ selectedRoute })
    });
  };

  const confirmAssignToRoute = ({ selectedRoute }) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({ id: 'common.confirm' }),
      confirmButtonLabel: intl.formatMessage({ id: 'common.yes' }),
      confirmButtonProps: {
        type: 'primary'
      },
      content: intl.formatMessage(
        {
          id: 'pickups.pickup_request.multi_pickups_route_confirm'
        },
        {
          routeName: selectedRoute.label
        }
      ),
      modalAction: () => handleAssignToRoute({ routeId: selectedRoute.value })
    });
  };

  const handleAssignToRoute = async ({ routeId, pickupRequestId }) => {
    setIsLoading(true);
    try {
      const payload = {
        routeId,
        pickupRequestIds: pickupRequestId ? [pickupRequestId] : selectedRowsIds
      };
      await assignPickupToRoute(payload);
      notify(
        intl.formatMessage({
          id: 'pickups.pickup_request.assign_to_route_modal.success_message'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleRowSelection = (selectedRowsIds) => {
    setSelectedRowsIds(selectedRowsIds);
  };

  const MenuItem = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleAssignToHubClick(item);
          }}
          disabled={
            item.state !== pickupStates.Requested ||
            !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])
          }
        >
          {intl.formatMessage({
            id: 'pickups.pickup_request.assign_to_hub_modal.title'
          })}
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleAssignToRouteClick(item._id);
          }}
          disabled={item.state !== pickupStates.Requested}
        >
          {intl.formatMessage({
            id: 'pickups.pickup_request.assign_to_route_modal.title'
          })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            history.push(`/pickups/edit/${item._id}`);
          }}
          disabled={
            !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
            item.state !== pickupStates.Requested
          }
        >
          {intl.formatMessage({ id: 'common.edit' })}
        </Menu.Item>
        <Menu.Item
          disabled={
            isUserAuthorized(CAN_CANCEL_ROLE, [
              ACL_MATRIX.PICKUP_REQUESTS_MARK_AS_CANCELLED
            ]) && item.state === pickupStates.Cancel
          }
          onClick={() => {
            handleCancelPickupClick(item._id);
          }}
        >
          {intl.formatMessage({ id: 'common.cancel' })}
        </Menu.Item>
      </Menu>
    );
  };

  const pickupActionsMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={handleExportXlsx} disabled={isLoading}>
          {intl.formatMessage({
            id: 'pickups.pickup_request.export_xlsx'
          })}
        </Menu.Item>
        <Menu.Item onClick={handleAddToRoute}>
          {intl.formatMessage({
            id: 'pickups.pickup_request.assign_to_route'
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const handleOpenMapModal = (pickupAddress) => {
    openModal(PickupMapModal, {
      pickupAddress
    });
  };

  const formatData = (data) =>
    data?.map((item) => {
      const isCRPOrSignAndReturnPickup =
        CRP_SIGN_AND_RETURN_PICKUP_TYPES.includes(item.type);

      return {
        key: item._id,
        _id: item._id,
        showAddress: isCRPOrSignAndReturnPickup || isDSPVendor(),
        pickupRequestID: (
          <div className="display-flex">
            <Link
              to={`/pickups/${item._id}`}
              style={{
                textDecoration: 'underline'
              }}
            >
              {item.puid}
            </Link>
            {item?.starInitiatedAction && (
              <Tooltip
                placement="bottom"
                title={intl.formatMessage({
                  id: 'pickups.created_by_star'
                })}
              >
                <div className="br-pickups-created-by-star__icon">
                  <NewOrder />
                </div>
              </Tooltip>
            )}
          </div>
        ),
        scheduledDate: dayjs(new Date(item.scheduledDate)).tz().format('LL'),
        pickupAddress: (
          <>
            {isCRPOrSignAndReturnPickup
              ? formatAddress(item.consigneeAddress)
              : formatAddress(item.business.address)}
            {item.isNewAddressPickupLocation && (
              <BRTag color="red">
                {intl.formatMessage({
                  id: 'pickups.new_pickup_location'
                })}
              </BRTag>
            )}
            <div
              className="br-pickups__view-map body-medium"
              onClick={() =>
                handleOpenMapModal(
                  isCRPOrSignAndReturnPickup
                    ? item.consigneeAddress
                    : item.business.address
                )
              }
            >
              View Map
            </div>
          </>
        ),
        city: (
          <>
            {isCRPOrSignAndReturnPickup
              ? item.consigneeAddress?.city?.name
              : item.business.address?.city?.name ||
                intl.formatMessage({ id: 'common.empty_field' })}
            {item.isNewCityPickupLocation && (
              <BRTag color="red">
                {intl.formatMessage({
                  id: 'pickups.new_pickup_location'
                })}
              </BRTag>
            )}
          </>
        ),
        area: (
          <>
            {isCRPOrSignAndReturnPickup
              ? `${item.consigneeAddress?.zone?.name?.concat('-') || ''}${
                  item.consigneeAddress?.district?.name ||
                  intl.formatMessage({ id: 'common.empty_field' })
                }`
              : `${item.business.address?.zone?.name?.concat('-') || ''}${
                  item.business.address?.district?.name ||
                  intl.formatMessage({ id: 'common.empty_field' })
                }` || 'NA'}
            {item.isNewAreaPickupLocation && (
              <BRTag color="red">
                {intl.formatMessage({
                  id: 'pickups.new_pickup_location'
                })}
              </BRTag>
            )}
          </>
        ),
        state: item.state,
        cancelReason:
          item.cancelReason || item.exceptionCode ? (
            <div>
              {item.exceptionCode
                ? PICKUP_REQUESTS_CANCEL_REASON[
                    item.type === PICKUP_TYPES.FIRST_MILE_PICKUP
                      ? PICKUP_TYPES.FIRST_MILE_PICKUP
                      : PICKUP_TYPES.CRP_PICKUP
                  ][item.exceptionCode]
                : intl.formatMessage({
                    id: `pickups.cancel_reasons.${
                      item.cancelReason.includes('_')
                        ? item.cancelReason
                        : mapCancelReason[item.cancelReason]
                    }`
                  })}
            </div>
          ) : (
            intl.formatMessage({ id: 'common.empty_field' })
          ),
        pickupRescheduledForId: item.pickupRescheduledForId || '-',
        business:
          isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
          item.business.phone ? (
            <span>
              {item.business.name}
              <span className="br-pickups__table-subtitle">
                {item.business.phone}
              </span>
              {item.newBusiness && (
                <BRTag color="red">
                  {intl.formatMessage({
                    id: 'pickups.new_pickup_location'
                  })}
                </BRTag>
              )}
            </span>
          ) : (
            <span>
              {item.business._id}
              {item.newBusiness && (
                <BRTag color="red">
                  {intl.formatMessage({
                    id: 'pickups.new_pickup_location'
                  })}
                </BRTag>
              )}
            </span>
          ),
        packageType: item.packageType ? (
          <span>
            <BRTag color={PACKAGE_TYPE_COLOR[item.packageType]}>
              {item.packageType}
            </BRTag>
          </span>
        ) : (
          ''
        ),
        hub: item.warehouse ? (
          <div>
            <div>{item.warehouse.name}</div>
            {item.isHubAutoAssigned && !item.isHubReassigned && (
              <BRTag color="blue">
                {intl.formatMessage({
                  id: 'pickups.auto_hub'
                })}
              </BRTag>
            )}
          </div>
        ) : (
          ''
        ),
        starInfo: item.star ? (
          <div>
            {item.star.name}
            <span className="br-pickups__table-subtitle caption">{item.star._id}</span>
            <span className="br-pickups__table-subtitle">
              {item.star.phone}
            </span>
          </div>
        ) : (
          ''
        ),
        pickedUpDate: item?.pickedupDate
          ? dayjs(item?.pickedupDate).format(PICKEDUP_DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' }),
        attemptDate: item?.attemptDate
          ? dayjs(item?.attemptDate).format(PICKEDUP_DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' }),
        assignedDate: item?.assignedAt
          ? dayjs(item.assignedAt).format(PICKEDUP_DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' }),
        isBusinessFirstPickup: item?.isBusinessFirstPickup ? (
          <CircleCheck className="br-pickups-check-icon" />
        ) : (
          <CircleClear className="br-pickups-clear-icon" />
        ),
        firstPickedUpDate: item?.firstPickedUpDate
          ? dayjs(item.firstPickedUpDate).format(PICKEDUP_DATE_FORMAT)
          : intl.formatMessage({ id: 'common.empty_field' }),
        numberOfPackages:
          item.noOfPackages || intl.formatMessage({ id: 'common.empty_field' }),
        numberOfParcels:
          item.numberOfParcels ||
          intl.formatMessage({ id: 'common.empty_field' }),
        type: item.type,
        repetition:
          item.repeatedData?.repeatedType ||
          intl.formatMessage({ id: 'common.empty_field' }),
        fmDistrictCode:
          item.business?.address?.district?.fmCode ||
          intl.formatMessage({ id: 'common.empty_field' }),
        rawData: item
      };
    });

  const renderTableRowActions = (item) => (
    <Dropdown overlay={MenuItem(item)} trigger={['click']}>
      <BRButton
        type="table-actions"
        className="button-sm"
        suffixIcon={<Actions />}
      />
    </Dropdown>
  );

  const getTotal = ({ currentPage, pickups }) => {
    if (showPickupCount) {
      return pickupsCount;
    }

    if (pickups.length === PAGE_SIZE) {
      return currentPage * PAGE_SIZE + 1;
    } else {
      return currentPage * PAGE_SIZE;
    }
  };

  const renderTableTitle = () => (
    <div className="br-pickups__table-count">
      {intl.formatMessage(
        {
          id: 'pickups.pickup_request.table_title'
        },
        {
          count: showPickupCount ? (
            pickupsCount
          ) : (
            <BRButton
              label={intl.formatMessage({
                id: 'pickups.pickup_request.show_count'
              })}
              loading={isCountLoading}
              prefixIcon={<ClosedEye />}
              onClick={getCount}
            />
          ),
          span: (children) => <span className="body-medium">{children}</span>
        }
      )}
    </div>
  );

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({ id: 'pickups.pickup_request.title' })}
        />
      }
      content={
        <div>
          <div>
            <PickupsSearch handleOnSubmit={handleSubmit} hubs={hubs} />
            <div className="br-pickups__table-header">
              {(isDefaultBostaCourier([
                ...aclMatrix.THREE_PL,
                VENDOR_MANAGER
              ]) ||
                isDSPVendor()) && (
                <Dropdown overlay={pickupActionsMenu()} trigger={['click']}>
                  <BRButton
                    label={intl.formatMessage({
                      id: 'pickups.pickup_request.pickup_actions'
                    })}
                    suffixIcon={<DropdownIcon />}
                  />
                </Dropdown>
              )}
            </div>
            <BRTable
              className={classnames({ 'br-pickups__table': !showPickupCount })}
              title={renderTableTitle()}
              columns={PICKUPS_REQUESTS_COLUMNS(renderTableRowActions)}
              listFunction={searchPickups}
              showFilter={false}
              allowSelection
              handleSelectedRows={handleRowSelection}
              shareMethods={acceptMethods}
            />
          </div>
        </div>
      }
    />
  );
};

export default PickupsRequests;
