import dayjs from 'dayjs';

const DateTime = ({ dateTime }) => {
  if (!dateTime) return 'N/A';
  return (
    <div className="br-hub-transfer-dispatch-update__time">
      {dayjs(dateTime).format('DD MMM YYYY')}{' '}
      <span>{dayjs(dateTime).format('h:mm A')} </span>
    </div>
  );
};

export default DateTime;
