import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Dropdown, Menu } from 'antd';
import { useIntl } from 'react-intl';

import { exportXlsx, excludeStarFromLiability, getStars } from 'services/stars';
import { downloadAsXlsx } from 'utils';
import {
  isUserAuthorized,
  isDefaultBostaCourier,
  isAdminGroupUser
} from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { openModal } from 'utils/modal';
import {
  STAR_AVAILABILITY_TAG_COLOR,
  STAR_STATUS_TAG_COLOR,
  TABLE_PAGE_SIZE_OPTIONS
} from 'constants/stars';
import { hasFawryId } from 'common/countries/countries-mapping';
import BRTable from 'components/BRTable/BRTable';

import BRTag from 'components/BRTag/BRTag';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import { notify } from 'components/Notify/Notify';
import StarsSearch from './components/StarsSearch/StarsSearch';
import BRButton from 'components/BRButton/BRButton';
import AddEditBonusesAndDeductions from 'components/Stars/components/BonusesAndDeductions/components/AddEditBonusesAndDeductions/AddEditBonusesAndDeductions';
import DateRangeModal from 'components/Stars/components/DateRangeModal/DateRangeModal';

import { ReactComponent as BonusAndDeduction } from 'assets/imgRevamp/wallet-add.svg';
import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

import './StarsListing.less';

const Stars = () => {
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValues, setSearchValues] = useState({
    isVerified: true
  });

  const refreshTable = useRef();

  const history = useHistory();
  const intl = useIntl();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleOnClickExcludeFromLiability = (starId) => {
    openModal(DateRangeModal, {
      onSubmit: (payload) => handleOnExcludeSubmitClick(payload, starId)
    });
  };

  const handleAddBonusAndDeduction = (row) => {
    openModal(AddEditBonusesAndDeductions, {
      values: row
    });
  };

  const handleOnExcludeSubmitClick = async (data, starId) => {
    setIsLoading(true);
    try {
      const payload = {
        excludeStarData: data
      };
      const result = await excludeStarFromLiability(starId, payload);
      notify(result.message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const starsTableColumns = [
    {
      key: 'starName',
      dataIndex: 'starName',
      width: 300,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.star_name'
      }),
      fixed: 'left'
    },
    {
      key: 'starId',
      dataIndex: 'starId',
      width: 220,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.star_id'
      })
    },
    {
      key: 'contractor',
      dataIndex: 'contractor',
      width: 150,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.contractor'
      })
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      width: 150,
      title: intl.formatMessage({
        id: 'stars.stars_listing.search_filters.phone'
      })
    },
    {
      key: 'email',
      dataIndex: 'email',
      width: 300,
      title: intl.formatMessage({
        id: 'stars.stars_listing.search_filters.email'
      })
    },
    ...(hasFawryId()
      ? [
          {
            key: 'fawryUserId',
            dataIndex: 'fawryUserId',
            width: 150,
            title: intl.formatMessage({
              id: 'stars.stars_listing.search_filters.fawry_id'
            })
          }
        ]
      : []),
    {
      key: 'hub',
      dataIndex: 'hub',
      width: 150,
      title: intl.formatMessage({
        id: 'stars.create_edit_star.form_labels.hub'
      })
    },
    {
      key: 'starWallet',
      dataIndex: 'starWallet',
      width: 120,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.star_wallet'
      })
    },
    {
      key: 'availabilityState',
      dataIndex: 'availabilityState',
      width: 150,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.availability_state'
      })
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: 150,
      title: intl.formatMessage({
        id: 'stars.stars_listing.table_columns.status'
      })
    },
    (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) && {
      title: '',
      fixed: 'right',
      width: 60,
      render: (row) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  handleOnClickExcludeFromLiability(row.key);
                }}
              >
                {intl.formatMessage({
                  id: `stars.star_details.actions.exclude_from_liability`
                })}
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  handleAddBonusAndDeduction(row);
                }}
              >
                <BonusAndDeduction />
                {/* <Icon component={BonusAndDeduction} /> */}
                {intl.formatMessage({
                  id: `bonuses_deductions.create_edit_modal.create_title`
                })}
              </Menu.Item>
            </Menu>
          }
        >
          <BRButton
            type="table-actions"
            className="button-sm"
            suffixIcon={<Actions />}
          />
        </Dropdown>
      )
    }) ||
      {}
  ];

  const formatStars = (stars) =>
    stars.map((itm) => ({
      key: itm?._id,
      starId: itm._id,
      starName: itm?.profile ? (
        <Link
          className="br-stars__star-name"
          to={`/stars/all/${itm?._id}`}
        >{`${itm?.profile?.firstName} ${itm.profile?.lastName}`}</Link>
      ) : (
        ''
      ),
      phone: itm?.profile && itm?.profile?.phone,
      email: itm?.emails && itm?.emails[0]?.address,
      availabilityState: (
        <BRTag
          color={
            STAR_AVAILABILITY_TAG_COLOR[itm?.starInfo?.availabilityState] ||
            STAR_AVAILABILITY_TAG_COLOR.OFFLINE
          }
        >
          {(itm?.starInfo && itm?.starInfo?.availabilityState) ||
            intl.formatMessage({ id: 'common.empty_field' })}
        </BRTag>
      ),
      starWallet: itm?.starWalletSum || 0,
      status: (
        <BRTag
          color={
            itm?.emails?.[0]?.verified
              ? STAR_STATUS_TAG_COLOR.VERIFIED
              : STAR_STATUS_TAG_COLOR.PENDING
          }
        >
          {intl.formatMessage({
            id: `stars.create_edit_star.star_status.${
              itm?.emails?.[0]?.verified ? 'verified' : 'pending'
            }`
          })}
        </BRTag>
      ),
      fawryUserId:
        itm?.starInfo?.fawryUserId ||
        intl.formatMessage({ id: 'common.empty_field' }),
      hub:
        itm?.warehouseInfo?.name ||
        intl.formatMessage({ id: 'common.empty_field' }),
      registrationToken: itm?.starInfo?.registrationToken,
      profile: itm?.profile,
      warehouseInfo: itm?.warehouseInfo,
      contractor:
        itm?.starInfo?.contractor?.contractorName ||
        intl.formatMessage({ id: 'common.empty_field' })
    }));

  const fetchStars = async ({ page, limit, filterValues }) => {
    setIsLoading(true);
    try {
      const payload = {
        page: page - 1,
        limit,
        ...(filterValues ? { ...filterValues } : { ...searchValues })
      };
      const { message } = await getStars(payload);

      setCount(message.count);
      setIsLoading(false);

      return {
        list: formatStars(message.result),
        total: message.count
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDownloadXlsx = async () => {
    setIsLoading(true);
    try {
      const data = await exportXlsx(
        selectedRowsIds.length ? { starId: selectedRowsIds } : searchValues
      );
      notify(
        intl.formatMessage({ id: 'stars.stars_listing.export_success' }),
        'success'
      );
      downloadAsXlsx(data, `stars.xlsx`);
    } catch (e) {
      notify(e.message);
    }
    setIsLoading(false);
  };

  const handleRowSelection = (selectedRowKeys) => {
    setSelectedRowsIds(selectedRowKeys);
  };

  const handleCreateStarClick = () => {
    history.push('/stars/create-star');
  };

  const handleOnSearchSubmit = (filterValues) => {
    setSearchValues(filterValues);
    refreshTable.current({ pageNumber: 1, filterValues });
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'stars.create_edit_star.titles.stars'
          })}
        />
      }
      content={
        <>
          <div className="br-stars__header">
            {((!isAdminGroupUser() &&
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])) ||
              (isAdminGroupUser() &&
                isUserAuthorized(aclMatrix.STARS_CREATE, [
                  ACL_MATRIX.STARS_CREATE
                ]))) && (
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'stars.stars_listing.create_star'
                })}
                onClick={handleCreateStarClick}
              />
            )}
            {((!isAdminGroupUser() &&
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])) ||
              (isAdminGroupUser() &&
                isUserAuthorized(aclMatrix.STARS_CREATE, [
                  ACL_MATRIX.STARS_EXPORT
                ]))) && (
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'stars.stars_listing.export'
                })}
                disabled={
                  isLoading ||
                  !isUserAuthorized(aclMatrix.STARS, [ACL_MATRIX.STARS_EXPORT])
                }
                onClick={handleDownloadXlsx}
              />
            )}
          </div>
          <StarsSearch handleOnSubmit={handleOnSearchSubmit} />
          <BRTable
            title={intl.formatMessage(
              { id: 'stars.stars_listing.table_title' },
              {
                count
              }
            )}
            columns={starsTableColumns}
            listFunction={fetchStars}
            showFilter={false}
            pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
            handleSelectedRows={handleRowSelection}
            shareMethods={acceptMethods}
            allowSelection
          />
        </>
      }
    />
  );
};

export default Stars;
