import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { COUNTRY_CURRENCY } from 'constants/helper';
import { getFlyersItemDetails } from 'services/packaging';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as ProductIcon } from 'assets/bosta-icons/Product.svg';

import './ItemsPopOver.less';

const ItemsPopOver = ({ id }) => {
  const [products, setProducts] = useState([]);

  const intl = useIntl();

  const fetchDraftOrderProducts = async () => {
    try {
      const {
        data: { itemsBreakDown }
      } = await getFlyersItemDetails(id);
      setProducts(
        itemsBreakDown.map((item) => ({
          variantDefaultImage: item.imageUrl,
          name: item.titleEn,
          variantPrice: item.price,
          quantity: item.quantity
        }))
      );
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    fetchDraftOrderProducts();
  }, []);

  return (
    <LoadingWrapper loading={!!!products.length}>
      {products?.map(
        ({ name, quantity, variantPrice, variantDefaultImage }, index) => {
          return (
            <div key={index}>
              <span className="br-draft-orders__products-popover__image">
                <span className="br-draft-orders__products-popover__image-count caption">
                  {quantity}
                </span>
                {variantDefaultImage ? (
                  <img src={variantDefaultImage} alt="" />
                ) : (
                  <ProductIcon />
                )}
              </span>
              <div className="br-draft-orders__products-popover__info">
                <div className="br-draft-orders__products-popover__title">
                  <span>{name}</span>
                </div>
                <span
                  className={classnames({
                    'br-draft-orders__products-popover__price': !variantPrice
                  })}
                >
                  {variantPrice
                    ? intl.formatMessage(
                        {
                          id: COUNTRY_CURRENCY
                        },
                        {
                          cod: variantPrice
                        }
                      )
                    : '-'}
                </span>
              </div>
            </div>
          );
        }
      )}
    </LoadingWrapper>
  );
};

export default ItemsPopOver;
