import { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { getInternationalOrder } from 'services/international-orders';
import {
  FLIGHT_DETAILS_DATE_FORMAT,
  INTERNATIONAL_DELIVERY_DATE_FORMAT,
  INTERNATIONAL_DELIVERY_STATES_LABEL,
  INTERNATIONAL_DELIVERY_TAG_COLORS
} from 'constants/international-orders';
import { checkSmartDate } from 'utils/helpers';
import {
  formatAddress,
  formatReturnOption,
  getE2EStateLabel
} from 'utils/international-order';
import { getCurrency } from 'common/countries/countries-mapping';
import { getDeliveryEvents } from 'services/shipments';

import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails';
import BRTag from 'components/BRTag/BRTag';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRTabs from 'components/BRTabs/BRTabs';
import DeliveryLogs from './components/DeliveryLogs/DeliveryLogs';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './InternationalDeliveryDetails.less';

const InternationalDeliveryDetails = ({
  open,
  onClose,
  _id: orderId,
  isE2E
}) => {
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [deliveryEvents, setDeliveryEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (orderId) {
      getDeliveryDetails();
    }
  }, [orderId]);

  const getDeliveryDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getInternationalOrder(orderId);
      const { data: deliveryEvents } = await getDeliveryEvents({
        id: data._id
      });

      setDeliveryDetails(formatDeliveryDetails(data));
      setDeliveryEvents(deliveryEvents);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const TABS = {
    details: {
      label: intl.formatMessage({
        id: `shipments.new_order.order_details.title`
      }),
      component: DeliveryDetails,
      componentProps: {
        deliveryDetails,
        setDeliveryDetails,
        setIsLoading,
        getDeliveryDetails,
        isE2E
      }
    },
    logs: {
      label: intl.formatMessage({
        id: 'international_orders.deliveries.delivery_details.tabs.logs'
      }),
      component: DeliveryLogs,
      componentProps: {
        deliveryEvents,
        deliveryDetails
      }
    }
  };

  const formatDeliveryDetails = (delivery) => ({
    ...delivery,
    receiver: {
      ...delivery.receiver,
      secondPhone: delivery.receiver?.secondPhone || undefined
    },
    cod: getCurrency(
      delivery.cod?.amount || 0,
      delivery.dropOffAddress.country.code
    ).localized,
    invoiceAmount: getCurrency(
      delivery.invoice?.amount || 0,
      delivery.dropOffAddress.country.code
    ).localized,
    weight: intl.formatMessage(
      { id: 'international_orders.deliveries.delivery_details.weight' },
      {
        weight: delivery.specs?.weight
      }
    ),
    isWorkAddress: intl.formatMessage({
      id: `common.${delivery.dropOffAddress?.isWorkAddress ? 'yes' : 'no'}`
    }),
    address: formatAddress(delivery.dropOffAddress),
    formattedPickupAddress: formatAddress(delivery.pickupAddress),
    collectedFromBusiness: delivery.collectedFromBusiness
      ? checkSmartDate(
          delivery.collectedFromBusiness,
          INTERNATIONAL_DELIVERY_DATE_FORMAT,
          true
        )
      : undefined,
    type: intl.formatMessage({ id: 'shipments.order_types.send' }),
    customsVatValueWithCurrency: getCurrency(
      delivery.customVAT?.value || 0,
      delivery.dropOffAddress.country.code
    ).localized,
    flightDetails: {
      ...delivery.flightDetails,
      ...(delivery.flightDetails?.flightDate && {
        flightDate: dayjs(delivery.flightDetails?.flightDate).format(
          FLIGHT_DETAILS_DATE_FORMAT
        )
      })
    },
    ...(delivery.pricing && {
      pricing: {
        ...delivery.pricing,
        priceAfterVat: `${delivery.pricing?.currency}  ${
          delivery.pricing.priceAfterVat.toFixed(2) || 0
        }`,
        priceBeforeVat: `${delivery.pricing?.currency} ${
          delivery.pricing.priceBeforeVat.toFixed(2) || 0
        }`
      }
    }),
    returnOption: formatReturnOption(delivery)
  });

  const renderTitle = () => (
    <div className="br-international-delivery-details__title">
      <div>
        <span className="display-xs">{deliveryDetails.trackingNumber}</span>
        <BRTag
          color={INTERNATIONAL_DELIVERY_TAG_COLORS[deliveryDetails.state?.code]}
        >
          {isE2E
            ? getE2EStateLabel(deliveryDetails)
            : INTERNATIONAL_DELIVERY_STATES_LABEL[deliveryDetails.state?.code]}
        </BRTag>
      </div>
      <Close
        className="br-international-delivery-details__close"
        onClick={onClose}
      />
    </div>
  );

  return (
    <Drawer
      className="br-international-delivery-details__drawer"
      title={renderTitle()}
      width={null}
      placement="right"
      onClose={onClose}
      visible={open}
      closeIcon={false}
      destroyOnClose
    >
      <LoadingWrapper loading={isLoading}>
        <BRTabs tabs={TABS} sameRouteTabs />
      </LoadingWrapper>
    </Drawer>
  );
};

export default InternationalDeliveryDetails;
