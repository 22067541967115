import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Input } from 'antd';

import {
  FULFILLMENT_LOCATION_LEVELS_KEYS,
  FULFILLMENT_LOCATION_LEVELS_OPTIONS,
  getLevelParentKey,
  getSelectedLevelsList
} from 'constants/fulfillment-locations';

import SelectLevelValue from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/CreateLocationModal/components/LocationForm/components/SelectLevelValue';
import LastLevelContent from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/CreateLocationModal/components/LocationForm/components/LastLevelContent/LastLevelContent';

import './LocationForm.less';

const LocationForm = ({
  intl,
  form,
  locationName,
  isLoading,
  setLocationName,
  setIsLoading,
  selectedZone,
  selectedAisle,
  selectedRack,
  selectedShelf,
  newLastLevelValue,
  setSelectedZone,
  setSelectedAisle,
  setSelectedRack,
  setSelectedShelf,
  setNewLastLevelValue,
  selectedLevel,
  setSelectedLevel
}) => {
  const { ZONE, AISLE, RACK, SHELF, TOTE } =
    FULFILLMENT_LOCATION_LEVELS_KEYS || {};

  const isZone = selectedLevel === ZONE;

  const levelsSelection = {
    [ZONE]: {
      setSelected: setSelectedZone,
      hasParent: false
    },
    [AISLE]: {
      setSelected: setSelectedAisle,
      parentLevelId: selectedZone?.id
    },
    [RACK]: {
      setSelected: setSelectedRack,
      parentLevelId: selectedAisle?.id
    },
    [SHELF]: {
      setSelected: setSelectedShelf,
      parentLevelId: selectedRack?.id
    },
    [TOTE]: {
      parentLevelId: selectedShelf?.id
    }
  };

  const renderLevelsDropdown = () => {
    return (
      <div className="br-fulfillment-location-form__levels-dropdowns">
        {getSelectedLevelsList(selectedLevel).map((level) => {
          const parentLevelName = getLevelParentKey(level);
          return (
            <SelectLevelValue
              key={level}
              level={level}
              setIsLoading={setIsLoading}
              parentLevelName={parentLevelName}
              {...levelsSelection[level]}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Form form={form} className="br-fulfillment-location-form">
      <Form.Item
        label={intl.formatMessage({
          id: 'fulfillment_locations.create_location_modal.location_level.label'
        })}
        name="locationLevel"
        initialValue={ZONE}
      >
        <Select
          options={FULFILLMENT_LOCATION_LEVELS_OPTIONS}
          onChange={setSelectedLevel}
        />
      </Form.Item>

      {!isZone && renderLevelsDropdown()}

      {levelsSelection[selectedLevel].parentLevelId && (
        <LastLevelContent
          level={selectedLevel}
          parentLevelName={getLevelParentKey(selectedLevel)}
          parentLevelId={levelsSelection[selectedLevel].parentLevelId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setNewLastLevelValue={setNewLastLevelValue}
        />
      )}
      <Form.Item
        className="br-fulfillment-location-form__location-name"
        label={intl.formatMessage({
          id: 'fulfillment_locations.create_location_modal.location_name.label'
        })}
        name="locationName"
      >
        {isZone ? (
          <Input
            placeholder={intl.formatMessage({
              id: 'fulfillment_locations.create_location_modal.location_name.placeholder'
            })}
            onChange={({ target: { value } }) => setLocationName(value)}
          />
        ) : (
          <div className="br-fulfillment-location-form__location-name__disabled">
            <span className="br-fulfillment-location-form__location-name__disabled__zone">
              {selectedZone?.label}
            </span>
            <span className="body-medium">
              {locationName}
              {locationName && newLastLevelValue && '-'}
              {newLastLevelValue}
            </span>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default injectIntl(LocationForm);
