import { fmt } from 'IntlWrapper/IntlWrapper';
import { URL_FLAGS } from './country-data';
import { CURRENCIES } from 'common/countries/constants/finance';
import {
  COUNTRIES_CODE_NAME,
  getCountryFullName
} from 'common/countries/constants/countries';

export const pageSize = 50;

export const PICKUP_LOCATION_OPTIONS = [
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.1`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    }),
    key: 1,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    })
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.2`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    }),
    key: 2,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    })
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    }),
    key: 3,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    })}`
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    }),
    key: 4,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    })}`
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    }),
    key: 5,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    })}`
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    }),
    key: 6,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    })}`
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    }),
    key: 7,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    })}`
  },
  {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.8`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.8`
    }),
    key: 8,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sectors.8`
    })
  }
];

export const BULKY = 'Bulky';

export const PRICING_FLAGS_OPTIONS = [
  {
    label: fmt({
      id: 'business_details.pricing_info.zero_cod_discount'
    }),
    value: 'zeroCodDiscount'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.extra_cod_fees'
    }),
    value: 'extraCodFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.insurance_fees'
    }),
    value: 'insuranceFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.next_day_cod_fees'
    }),
    value: 'expediteFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.cod_fees'
    }),
    value: 'codFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.pos_fees'
    }),
    value: 'posFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.bosta_material_fee'
    }),
    value: 'bostaMaterialFee'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.open_package_fees'
    }),
    value: 'openingPackageFee'
  }
];

export const PAYMENT_METHODS = [
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_methods.bank_transfer'
    }),
    value: 'Bank transfer'
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_methods.fawry'
    }),
    value: 'Fawry'
  }
];

export const PAYMENT_FREQUENCY_SCHEDULE = [
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.weekly_sun'
    }),
    paymentFrequency: 'Weekly',
    paymentSchedule: ['Sunday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.weekly_mon'
    }),
    paymentFrequency: 'Weekly',
    paymentSchedule: ['Monday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.weekly_wed'
    }),
    paymentFrequency: 'Weekly',
    paymentSchedule: ['Wednesday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.two_days'
    }),
    paymentFrequency: 'Weekly',
    paymentSchedule: ['Monday', 'Wednesday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.three_days'
    }),
    paymentFrequency: 'Weekly',
    paymentSchedule: ['Sunday', 'Thursday', 'Tuesday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.daily'
    }),
    paymentFrequency: 'Daily',
    paymentSchedule: ['Monday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday']
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.payment_frequency_schedule.next_day'
    }),
    paymentFrequency: 'Next Day',
    paymentSchedule: ['Monday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday']
  }
];

export const PRICING_RATES_OPTIONS = [
  {
    label: fmt({
      id: 'pricing.rates.dynamic'
    }),
    value: 'DYNAMIC_RATE_TYPE'
  },
  {
    label: fmt({
      id: 'pricing.rates.flat'
    }),
    value: 'FLAT_RATE_TYPE'
  }
];

export const WEIGHTING_OPTIONS = [
  {
    label: fmt({
      id: 'pricing.pricing_configurations.weighting.fixed_sizes'
    }),
    value: 'FIXED_SIZES'
  },
  {
    label: fmt({
      id: 'pricing.pricing_configurations.weighting.extra_weight'
    }),
    value: 'EXTRA_WEIGHT'
  }
];

export const BUSINESS_PRICING_FLAGS_CHECKERS = [
  {
    label: fmt({
      id: 'business_details.pricing_info.zero_cod_discount'
    }),
    value: 'isZeroCodDiscountApplied'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.extra_cod_fees'
    }),
    value: 'isExtraCodFeesApplied'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.insurance_fees'
    }),
    value: 'isInsuranceFeesApplied'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.next_day_cod_fees'
    }),
    value: 'isExpediteFeesApplied'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.cod_fees'
    }),
    value: 'isCodFeesApplied'
  },
  {
    label: fmt({
      id: 'business_details.pricing_info.pos_fees'
    }),
    value: 'isPosFeesApplied'
  }
];

export const TYPES_OPTIONS = [
  {
    value: 'SEND',
    label: 'Deliver'
  },
  {
    value: 'CASH_COLLECTION',
    label: 'Cash Collection'
  },
  {
    value: 'CUSTOMER_RETURN_PICKUP',
    label: 'CRP'
  },
  {
    value: 'EXCHANGE',
    label: 'Exchange'
  },
  {
    value: 'FXF_SEND',
    label: 'FXF Deliver'
  },
  {
    value: 'RTO',
    label: 'RTO'
  },
  {
    value: 'SIGN_AND_RETURN',
    label: 'Sign and return'
  }
];

export const COST_VALUE = 'cost';
export const COST_TYPE_VALUE = 'costType';

export const TYPE_MAPPING = {
  CASH_COLLECTION: 'Cash Collection',
  CUSTOMER_RETURN_PICKUP: 'CRP',
  EXCHANGE: 'Exchange',
  FXF_SEND: 'FXF Deliver',
  RTO: 'RTO',
  SEND: 'Deliver',
  SIGN_AND_RETURN: 'Sign and return',
  SIGN_AND_RETURN_OTHER_DAY: 'Sign and return other day'
};

export const INTERNATIONAL_SHIP_LOCATIONS = {
  FROM: [
    {
      value: 'UAE',
      label: (
        <div className="br-pricing__inter-location-option">
          <img
            className="br-international-pricing__country-flag"
            src={URL_FLAGS + 'ae' + '.svg'}
            alt="flag"
          />
          <p className="body">United Arab Emarates</p>
        </div>
      ),
      code: 'UAE'
    }
  ],
  TO: [
    {
      value: 'SA',
      label: (
        <div className="br-pricing__inter-location-option">
          <img
            className="br-international-pricing__country-flag"
            src={URL_FLAGS + 'sa' + '.svg'}
            alt="flag"
          />
          <p className="body">Saudi Arabia</p>
        </div>
      ),
      code: 'SA'
    }
  ]
};
export const DROPOFF_COUNTRIES = [COUNTRIES_CODE_NAME.SA];

export const renderShippingLocationOption = (code) => {
  return {
    value: code,
    label: (
      <div className="br-pricing__inter-location-option">
        <img
          className="br-international-pricing__country-flag"
          src={URL_FLAGS + code.toLowerCase() + '.svg'}
          alt="flag"
        />
        <p className="body br-pricing__location-option-country-name">
          {getCountryFullName(code)}
        </p>
      </div>
    ),
    code: 'UAE'
  };
};

export const INTERNATIONAL_EXCHANGE_SELECT = [
  {
    currency: CURRENCIES.SAR,
    currencyDetails: 'SAR - Saudi Reyal',
    code: 'sa',
    name: 'Saudi Arabia'
  },
  {
    currency: CURRENCIES.AED,
    currencyDetails: 'AED - UAE Dirham',
    code: 'ae',
    name: 'United Arab Emarates'
  }
];

export const INTERNATIONAL_WALLET_CELLS = [
  {
    key: ['internationalPricingTier', 'name'],
    label: fmt({
      id: 'business_details.international_wallet.pricing_plan'
    })
  },
  {
    // key: ['internationalPaymentInfo', 'paymentFrequency'],
    label: fmt({
      id: 'business_details.international_wallet.cashout_frequency'
    }),
    render: (businessInfo) => {
      return PAYMENT_FREQUENCY_SCHEDULE.find(
        (item) =>
          item.paymentSchedule.toString() ===
          businessInfo?.internationalPaymentInfo?.paymentSchedule?.toString()
      )?.label;
    }
  }
];
