// import React from 'react';
import axios from 'axios';
import { parseJwt } from '../utils';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import { Modal, Spin } from 'antd';
import { notify } from 'components/Notify/Notify';

export const API_KEY = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : '';

// export const REFRESH_TOKEN = localStorage.getItem('refreshToken')
//   ? localStorage.getItem('refreshToken')
//   : '';

const BASE_API_URL =
  window.env && window.env.ENVIRONMENT === 'production'
    ? `${window.env.BASE_API_URL}`
    : 'https://dev-app.bosta.co/api/v0';

const getAccessToken = () =>
  localStorage.getItem('token') ? localStorage.getItem('token') : '';

export const BASE = axios.create({
  baseURL:
    window.env && window.env.BASE_API_URL
      ? `${window.env.BASE_API_URL}`
      : BASE_API_URL,
  timeout: 800000,
  headers: {
    'Content-Type': 'application/json',
    ...(API_KEY ? { Authorization: API_KEY } : {})
  }
});

/* const getRefreshToken = () =>
  localStorage.getItem('refreshToken')
    ? localStorage.getItem('refreshToken')
    : '';

const refreshAuthLogic = failedRequest => {
  if (getRefreshToken() === '') {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.reload();
  } else {
    return axios
      .post(
        `${window.env.BASE_API_URL ||
          BASE_API_URL}/users/auth/token`,
        { refreshToken: getRefreshToken() },
      )
      .then(tokenRefreshResponse => {
        localStorage.setItem(
          'token',
          `Bearer ${tokenRefreshResponse.data.token}`,
        );
        localStorage.setItem(
          'refreshToken',
          `${tokenRefreshResponse.data.refreshToken}`,
        );
        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.token}`;
        Modal.warning({
          title: 'Notification',
          content: (
            <div className="display-block">
              <div className="fullWidth display-block word-break-all">
                Due to long time of inactivity or your credentials expired, your
                credentials has been refreshed and the page will reload after 5
                seconds.
              </div>
              <Spin size="large" className="mt-3 fullWidth display-block" />
            </div>
          ),
          centered: true,
          onOk: () => {
            window.location.reload();
          },
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return Promise.resolve();
      });
  }
};

createAuthRefreshInterceptor(BASE, refreshAuthLogic); */

BASE.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = getAccessToken();
    return config;
  },
  (error) => Promise.reject(error)
);

BASE.interceptors.response.use(
  (res) => res,
  /* error => {
    const originalRequest = error.config;
    console.log(error.response.status, originalRequest.url)

    if (error.response.status === 401 && originalRequest.url ===
      `${window.env.BASE_API_URL || BASE_API_URL}/users/auth/token`) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.reload();
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios.post(`${window.env.BASE_API_URL || BASE_API_URL}/users/auth/token`,
        {
          'refreshToken': getRefreshToken(),
        })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('token', `Bearer ${res.data.token}`);
            localStorage.setItem('refreshToken', `${res.data.refreshToken}`);
            error.response.config.headers.Authorization = `Bearer ${res.data.token}`;
            console.log(originalRequest.url);
            return axios(originalRequest);
          }
          return Promise.resolve();
        }, err => Promise.reject(err));
    }
    if (error.response.status === 401 && originalRequest._retry) {
      return axios(originalRequest);
    }
    return Promise.reject(error);
  } */
  (err) => {
    const { response } = err;
    if (response?.status === 401) {
      notify(response?.data?.message);
      // try {
      //   const token = parseJwt(API_KEY);
      //   const _expiresAt = token.exp * 1000;
      //   if (new Date().getTime() > _expiresAt) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      window.location.reload();
      return;
      //   }
      // } catch (e) {
      //   console.dir(e);
      // }
    }
    return Promise.reject(err);
  }
);
