import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getSortingFacilityData = (params) => {
  return http.get(`/hubs/sorting-facility-ops-room-report`, params);
};

export const exportTableData = (payload) => {
  return http.post('deliveries/exportxlsx?', payload, downloadHeader);
};
