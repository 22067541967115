import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { injectIntl } from 'react-intl';

import { getPrintedLocationsCode } from 'services/fulfillment-locations';
import { openModal } from 'utils/modal';
import {
  FULFILLMENT_LOCATION_LEVELS_EMPTY_STATE,
  FULFILLMENT_LOCATION_LEVELS_KEYS,
  fetchFulfillmentLocations,
  getCurrentLevel
} from 'constants/fulfillment-locations';
import { showBill } from 'utils';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import BRButton from 'components/BRButton/BRButton';
import LocationsListing from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/LocationsListing/LocationsListing';
import LocationEmptyState from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/LocationEmptyState/LocationEmptyState';
import CreateLocationModal from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/CreateLocationModal/CreateLocationModal';
import LocationBreadcrumb from 'components/NewFulfillment/FulfillmentLocations/components/LocationsList/components/LocationBreadcrumb/LocationBreadcrumb';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

import './LocationsList.less';

const LocationsList = ({ intl }) => {
  const { SHELF } = FULFILLMENT_LOCATION_LEVELS_KEYS || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [list, setList] = useState([]);
  const [locationLabelChain, setLocationLabelChain] = useState({});

  const params = useParams();
  const { id, level: currentLevel } = getCurrentLevel(params);

  const printLocations = async (locationId) => {
    setIsPrinting(true);

    try {
      const payload = {
        shelfId: locationId
      };
      const { data, message } = await getPrintedLocationsCode(payload);

      if (data) {
        showBill(data);
      } else {
        notify(message, 'success');
      }
    } catch (error) {
      notify(error.message);
    }

    setIsPrinting(false);
  };

  const fetchData = () =>
    fetchFulfillmentLocations({
      level: currentLevel,
      id,
      setIsLoading,
      setList,
      withSplit: true,
      callback: ({ newLocationLabelChain }) =>
        setLocationLabelChain(newLocationLabelChain)
    });

  const openCreateLocationModal = () => {
    openModal(CreateLocationModal, {
      currentLevel,
      printLocations,
      reloadData: fetchData
    });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Container
      className="br-fulfillment-locations-list"
      header={
        <BRHeader
          title={
            <LocationBreadcrumb locationLevelsParams={locationLabelChain} />
          }
        />
      }
      content={
        <LoadingWrapper
          loading={isLoading || isPrinting}
          className="br-fulfillment-locations-list__content"
        >
          <div className="br-fulfillment-locations-list__actions">
            {currentLevel === SHELF && (
              <BRButton
                label={intl.formatMessage({
                  id: 'fulfillment_locations.actions.print_labels'
                })}
                prefixIcon={<PrinterIcon />}
                onClick={() => printLocations(id)}
                disabled={!list.length}
              />
            )}
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'fulfillment_locations.actions.create_new_location'
              })}
              prefixIcon={<PlusIcon />}
              onClick={openCreateLocationModal}
            />
          </div>

          {!isLoading &&
            (!list.length ? (
              <LocationEmptyState
                {...FULFILLMENT_LOCATION_LEVELS_EMPTY_STATE[currentLevel]}
              />
            ) : (
              <LocationsListing
                currentLevel={currentLevel}
                setIsLoading={setIsLoading}
                list={list}
              />
            ))}
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(LocationsList);
