import { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select, Switch } from 'antd';

import { createHubDistrict, updateHubDistrict } from 'services/zoning';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

const CreateEditDistrictModal = ({
  intl,
  close,
  name,
  nameAr,
  zone,
  cities = [],
  selectedCityId,
  pickupAvailability = false,
  dropOffAvailability = false,
  districtId,
  hubId,
  fmHubId,
  allHubs,
  updateZoningData,
  zoningData,
  isEditMode,
  ...props
}) => {
  const [availableZones, setAvailableZones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef('');

  const handleOnFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        ...values
      };

      if (isEditMode) {
        delete payload.name;
        delete payload.nameAr;
      }

      isEditMode
        ? await updateHubDistrict(districtId, payload)
        : await createHubDistrict(payload);

      updateZoningData();
      notify(
        intl.formatMessage({
          id: `zoning.create_edit_district_modal.${
            isEditMode ? 'update' : 'create'
          }_success`
        }),
        'success'
      );
      close();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedCityId) {
      setAvailableZones(zoningData[0].children.filter((zone) => zone.isZone));
    }
  }, [selectedCityId]);

  return (
    <Modal
      title={intl.formatMessage({
        id: `zoning.create_edit_district_modal.${
          isEditMode ? 'edit_district' : 'title'
        }`
      })}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            type="primary"
            htmlType="submit"
            form="districtModalForm"
            disabled={isLoading}
            label={intl.formatMessage({
              id: `zoning.create_edit_district_modal.${
                isEditMode ? 'edit' : 'add'
              }_district`
            })}
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <Form
          ref={formRef}
          name="districtModalForm"
          initialValues={{
            name,
            nameAr,
            cityId: selectedCityId,
            zoneId: zone?._id,
            pickupAvailability,
            dropOffAvailability,
            hubId,
            fmHubId
          }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.create_edit_district_modal.district_name_en'
            })}
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              disabled={isEditMode}
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_district_modal.district_name_en_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.create_edit_district_modal.district_name_ar'
            })}
            name="nameAr"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              disabled={isEditMode}
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_district_modal.district_name_ar_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.city'
            })}
            name="cityId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              disabled
              showSearch
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_zone_modal.city_placeholder'
              })}
              optionFilterProp="children"
            >
              {cities.map((city) => (
                <Select.Option value={city._id} key={city._id}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.create_edit_district_modal.zone'
            })}
            name="zoneId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: 'zoning.create_edit_district_modal.zone_placeholder'
              })}
              optionFilterProp="children"
            >
              {availableZones.map((zone) => (
                <Select.Option value={zone.zoneId} key={zone.zoneId}>
                  {zone.zoneName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.hub'
            })}
            name="hubId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              showSearch
              options={allHubs}
              fieldNames={{ label: 'name', value: '_id' }}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'zoning.hub_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'zoning.table_columns.fm_hub'
            })}
            name="fmHubId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              showSearch
              options={allHubs}
              fieldNames={{ label: 'name', value: '_id' }}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'zoning.hub_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item
            className="br-zoning-availability__toggle"
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.pickup_coverage'
            })}
            name="pickupAvailability"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="br-zoning-availability__toggle"
            label={intl.formatMessage({
              id: 'zoning.create_edit_zone_modal.delivery_coverage'
            })}
            name="dropOffAvailability"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(CreateEditDistrictModal);
