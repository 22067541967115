import dayjs from 'dayjs';

import {
  ACTION_LEVEL,
  ACTION_TYPES,
  ADDRESS_UPDATES,
  DROP_OFF_ADDRESS,
  DROP_OFF_ADDRESS_WORK_ADDRESS,
  FINAL_STATE_CODES,
  OFD_CODES,
  RTO
} from 'constants/order-logs';
import { BUSINESS_ADMIN } from 'common/aclMatrix';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const beforeChangedToRto = (delivery, log) => {
  if (
    delivery?.changedToRTODate &&
    dayjs(log?.time) < dayjs(delivery?.changedToRTODate)
  ) {
    return true;
  }
};

export const deliveryAttemptsCount = (delivery) => delivery.attemptsCount || 0;

export const checkIfOrderRestarted = ({ logs = [], index, currentLog }) => {
  let orderRestarted = false;
  const followingLogs = [...logs];
  followingLogs.splice(0, index + 1);

  followingLogs.forEach((log) => {
    if (
      FINAL_STATE_CODES.includes(currentLog?.actionsList?.state_code?.after) &&
      FINAL_STATE_CODES.includes(log?.actionsList?.state_code?.before)
    ) {
      orderRestarted = true;
    }
    if (
      currentLog?.actionsList?.state_code?.after === 47 &&
      log?.actionsList?.state_code?.before === 47 &&
      OFD_CODES.includes(log?.actionsList?.state_code?.after) &&
      dayjs(currentLog?.time).format('DD-MM-YYYY') ===
        dayjs(log?.time).format('DD-MM-YYYY')
    ) {
      orderRestarted = true;
    }
    if (log?.actionsList?.type?.before === RTO) {
      orderRestarted = true;
    }
  });

  return orderRestarted;
};

export const orderIsCollected = ({ logs = [], index }) => {
  const previousLogs = logs.slice(0, index);

  return previousLogs.find(
    (log) => log?.actionsList?.collectedFromConsignee?.after
  );
};

export const getAddressUpdates = (log) => {
  const addressUpdateKeys = log.actionsList ? Object.keys(log.actionsList) : [];
  const addressUpdates = [];

  addressUpdateKeys.map((updateKey) => {
    if (updateKey.split('_')[0] === DROP_OFF_ADDRESS) {
      if (updateKey === DROP_OFF_ADDRESS_WORK_ADDRESS) {
        return addressUpdates.push(
          `${ADDRESS_UPDATES[updateKey]}: ${fmt({
            id: `common.${log?.actionsList[updateKey]?.after ? 'yes' : 'no'}`
          })}`
        );
      }
      ADDRESS_UPDATES[updateKey] &&
        addressUpdates.push(
          `${ADDRESS_UPDATES[updateKey]}: ${
            log?.actionsList[updateKey]?.after?.name ||
            log?.actionsList[updateKey]?.after
          }`
        );
    }
  });

  return log?.takenBy?.userRole === BUSINESS_ADMIN ||
    log?.actionType === ACTION_TYPES.WHATSAPP_ADDRESS_LOG ||
    log?.actionLevel === ACTION_LEVEL.TRACKING_SERVICE_ACTION_LEVEL
    ? fmt(
        {
          id: 'orders.states_logs.order_details_updates.order_address_updated_by_business'
        },
        {
          address: `[${addressUpdates.join(' , ')}]`
        }
      )
    : `${fmt({
        id: 'orders.states_logs.order_details_updates.order_address_updated'
      })} [${addressUpdates.join(', ')}] ${
        log?.actionsList?.linkedTickets
          ? `(${fmt({
              id: `orders.states_logs.rescheduled.ticket_ref_number`
            })} ${log?.actionsList?.linkedTickets?.after?.number})`
          : ''
      }`;
};
