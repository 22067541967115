import { BASE } from 'apis';
import { notify } from 'components/Notify/Notify';
import { downloadAsXlsx } from '../utils';

export const getBusinesses = async (page) => {
  const { data } = await BASE.get(`/businesses?pageId=${page - 1}`);
  return data;
};

export const searchBusinesses = async (values) => {
  try {
    const { data } = await BASE.get(`/businesses/search`, { params: values });
    return data;
  } catch (e) {
    notify(`${e.response?.data?.message || 'Error while searching business'}`);
    return null;
  }
};

export const getBusiness = async (id) => {
  const { data } = await BASE.get(`/businesses/${id}`);
  return data;
};

export const updateNoAttempts = async (params) => {
  const id = params.data ? params.data.id : params.id;
  const payload = {
    ...(params.data ? params.data : params),
    id: undefined
  };
  try {
    const { data } = await BASE.put(
      `/businesses/${id}/numberOfAttempts`,
      payload
    );
    notify(`businesses update successfully!`, 'success');
    return data;
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((e) => notify(e.message));
    } else {
      notify(err?.response?.data?.message);
    }
  }
};

export const updateBusiness = async (params) => {
  const id = params.data ? params.data.id : params.id;
  const payload = {
    ...(params.data ? params.data : params),
    id: undefined
  };
  try {
    const { data } = await BASE.put(`/businesses/${id}`, payload);
    notify(`businesses update successfully!`, 'success');
    return data;
  } catch (err) {
    if (Array.isArray(err.response.data)) {
      err.response.data.map((e) => notify(e.message));
    } else {
      notify(err?.response?.data?.message);
    }
  }
};

export const deleteBusiness = async (id) => {
  return BASE.delete(`/businesses/${id}`);
};

export const generateAPIForBusiness = async (id) => {
  const { data } = await BASE.post(`/apikey`, { businessId: id });
  return data;
};

export const getAllUserRoleSale = async () => {
  try {
    const response = await BASE.get(`/users/sales-users`);
    return { data: response.data.salesUsers };
  } catch (e) {
    notify(e.message);
    return null;
  }
};

export const assignAccountSalesNote = async (data) => {
  try {
    let payload = {};
    if (data.type === 'SALES') {
      payload = {
        salesManagerId: data.userId
      };
    }
    if (data.type === 'ACCOUNT') {
      payload = {
        accountManagerId: data.userId
      };
    }
    if (data.type === 'NOTE') {
      payload = {
        notes: data.note
      };
    }
    const response = await BASE.put(`/businesses/${data.id}`, payload);
    notify('Business updated successfully', 'success');
    return response;
  } catch (e) {
    notify(e?.response?.data?.message || e.message);
    return null;
  }
};
export const exportCSV = async (data) => {
  try {
    const queryStr = Object.entries(data).reduce(
      (acc, value) => `${acc}${value[0]}=${encodeURIComponent(value[1])}&`,
      ''
    );
    const header = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json'
      }
    };

    const res = await BASE.get(`/businesses/csv?${queryStr}`, header);
    if (res.headers['content-type'].includes('application/json')) {
      notify('Businesses exported to your mail successfully.', 'success');
    } else {
      notify(
        'Businesses loaded successfully, exporting in progress.',
        'success'
      );
      downloadAsXlsx(res.data, `businesses.xlsx`);
      return res.data;
    }
  } catch (e) {
    notify(e.message);
    return null;
  }
};

export const getBusinessPickupLocation = async (businessId) => {
  const { data } = await BASE.get(`/businesses/${businessId}/pickup-locations`);
  return data;
};
export const registerBusinessAtFawry = (payload) => {
  return BASE.post('/fawry/register', payload);
};

export const changeBusinessAdminEmail = async ({ id, newEmail }) => {
  const { data } = await BASE.put(`/businesses/${id}/change-email`, newEmail);
  return data;
};
