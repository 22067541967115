import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getFulfillmentProblematicJob } from 'services/fulfillment-problematic-jobs';
import {
  getViewProblematicJobContentForRendering,
  renderProblematicJobTag
} from 'constants/fulfillment-problematic-jobs';

import BusinessInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/BusinessInfoCard/BusinessInfoCard';
import OrderLogsCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/OrderLogsCard/OrderLogsCard';
import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import Products from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/Products';
import LinkedOrdersCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/LinkedOrdersCard/LinkedOrdersCard';

const ProblematicJob = ({
  match: {
    params: { id }
  }
}) => {
  const [problematicJob, setProblematicJob] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const { formatMessage } = useIntl();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await getFulfillmentProblematicJob(id);
      setProblematicJob(data.problematicJob);
      setProducts(data.problematicJob?.lines || []);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const { productsTableColumns, headerProps = {} } =
    getViewProblematicJobContentForRendering({
      problematicJob,
      isLoading,
      setIsLoading
    });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="br-sales-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader
            title={formatMessage(
              {
                id: 'fulfillment_common.pj_id'
              },
              {
                pjId: problematicJob.id
              }
            )}
            renderTag={() =>
              renderProblematicJobTag({
                stateId: problematicJob.state?.stateId,
                showProblematicTag: true
              })
            }
            {...headerProps}
          />
          <div className="br-po-content">
            <div className="start-side">
              <BusinessInfoCard
                businessInfo={{
                  businessName: problematicJob.businessName,
                  businessId: problematicJob.businessId
                }}
              />
              <LinkedOrdersCard
                soId={problematicJob.soId}
                roId={problematicJob.roId}
              />
              <OrderLogsCard orderLogs={problematicJob.history?.reverse()} />
            </div>
            <div className="end-side">
              <Products
                products={products}
                columns={productsTableColumns}
                hideSelectProductBtn
                hideOverlay
              />
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default ProblematicJob;
