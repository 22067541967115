import { useIntl } from 'react-intl';
import classnames from 'classnames';
import get from 'lodash/get';

import {
  INTERNATIONAL_PICKUP_DELIVERIES_COLUMNS,
  INTERNATIONAL_PICKUP_DETAILS_FIELDS
} from 'constants/international-pickups';
import { formatAddress } from 'utils/international-order';
import { openModal } from 'utils/modal';

import BRTable from 'components/BRTable/BRTable';
import BREllipsisCell from 'components/BREllipsisCell/BREllipsisCell';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';

import './PickupDetails.less';

const PickupDetails = ({ pickupDetails = {} }) => {
  const intl = useIntl();

  const formattedDeliveriesData = pickupDetails.deliveries?.map((delivery) => ({
    ...delivery,
    customerDetails: (
      <>
        <BREllipsisCell>
          {delivery.receiver.fullName
            ? delivery.receiver.fullName
            : `${delivery.receiver.firstName} ${delivery.receiver.lastName}`}
        </BREllipsisCell>
        <div className="br-international-delivery__column-subtext caption-lg">
          {delivery.receiver.phone}
        </div>
      </>
    ),
    dropOffAddress: formatAddress(delivery.dropOffAddress)
  }));

  const renderFields = (fields) => {
    return fields.map(({ title, dataIndex, fullWidth, isImage, imageAlt }) => {
      const fieldValue = get(
        pickupDetails,
        dataIndex,
        intl.formatMessage({ id: 'common.empty_field' })
      );

      return (
        <div
          className={classnames('br-international-pickup-details__field', {
            'full-width': fullWidth
          })}
        >
          <div>
            <div className="br-international-pickup-details__subtitle">
              {title}
            </div>
            <div className="br-international-pickup-details__field-value">
              {isImage &&
              fieldValue !==
                intl.formatMessage({ id: 'common.empty_field' }) ? (
                <img
                  onClick={() => {
                    openModal(BRPreviewImage, {
                      previewImage: fieldValue
                    });
                  }}
                  src={fieldValue}
                  alt={imageAlt}
                />
              ) : (
                fieldValue
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="br-international-pickup-details__header">
        <div>
          <span className="br-international-pickup-details__subtitle">
            {intl.formatMessage({
              id: 'deliveries.search_filters.business'
            })}
          </span>
          <span className="display-sm">{pickupDetails.business?.name}</span>
        </div>
      </div>
      <div className="br-international-pickup-details__content">
        {INTERNATIONAL_PICKUP_DETAILS_FIELDS.map(({ title, fields }, index) => (
          <div className="br-international-pickup-details__section" key={index}>
            <div className="br-international-pickup-details__section-title body-medium">
              {title}
            </div>
            <div className="br-international-pickup-details__section-fields">
              {renderFields(fields)}
            </div>
          </div>
        ))}
      </div>
      {!!pickupDetails.deliveries?.length && (
        <div className="br-international-pickups__deliveries-container">
          <BRTable
            columns={INTERNATIONAL_PICKUP_DELIVERIES_COLUMNS}
            listingData={formattedDeliveriesData}
            showFilter={false}
            scroll={{ x: 0 }}
          />
        </div>
      )}
    </div>
  );
};

export default PickupDetails;
