import { useState } from 'react';
import { Modal, Form, Select } from 'antd';
import { useIntl } from 'react-intl';

import { MAX_PENDING_BULKY_SEALS } from 'constants/hubs';
import { generateCounterOptions } from 'utils/hubs';
import { generateBulkySeals, printBulkySeal } from 'services/hubs';
import { showBill } from 'utils';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

const GenerateBulkySealsModal = ({
  close,
  hubs = [],
  allHubs = [],
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const [form] = Form.useForm();
  const sourceHubId = Form.useWatch('sourceHubId', form);

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const { bulkySealNumbers } = await generateBulkySeals(values);

      const { printed } = await printBulkySeal({
        sealNumbers: bulkySealNumbers.join(',')
      });
      showBill(printed);
      close();
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const getDestiantionHubOptions = () => {
    return allHubs.filter((hub) => hub._id !== sourceHubId);
  };

  return (
    <Modal
      onCancel={close}
      title={intl.formatMessage({
        id: 'hubs.hub_transfer.generate_bulky_seals_modal.title'
      })}
      footer={
        <>
          <BRButton
            disabled={isLoading}
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
          />
          <BRButton
            type="primary"
            htmlType="submit"
            form="generateBulkySealsForm"
            loading={isLoading}
            label={intl.formatMessage({
              id: 'hubs.hub_transfer.generate_bulky_seals_modal.generate_and_print'
            })}
          />
        </>
      }
      {...props}
    >
      <Form onFinish={onFinish} form={form} name="generateBulkySealsForm">
        <Form.Item
          name="sourceHubId"
          label={intl.formatMessage({
            id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.source_hub'
          })}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            options={hubs}
            fieldNames={{ label: 'name', value: '_id' }}
            placeholder={intl.formatMessage({ id: 'common.select' })}
            optionFilterProp="name"
          />
        </Form.Item>
        <Form.Item
          name="destinationHubId"
          label={intl.formatMessage({
            id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.destination_hub'
          })}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            options={getDestiantionHubOptions()}
            disabled={!sourceHubId}
            fieldNames={{ label: 'name', value: '_id' }}
            placeholder={intl.formatMessage({ id: 'common.select' })}
            optionFilterProp="name"
          />
        </Form.Item>
        <Form.Item
          name="numberOfSeals"
          label={intl.formatMessage({
            id: 'hubs.hub_transfer.generate_bulky_seals_modal.seals_number'
          })}
          rules={[{ required: true }]}
        >
          <Select
            options={generateCounterOptions(MAX_PENDING_BULKY_SEALS)}
            placeholder={intl.formatMessage({ id: 'common.select' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateBulkySealsModal;
