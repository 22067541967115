import React, { useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, DatePicker, Form, Row, Col, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { FRIDAY } from 'constants/shipment-details';
import { FORMAT_DATE_TO_UTC, isUserAuthorized } from 'utils/helpers';
import { reschduleDeliveryDate } from 'services/shipments';
import { getUserTimeSlots } from 'services/system';
import { CUSTOMER_SUPPORT } from 'common/aclMatrix';

import { notify } from 'components/Notify/Notify';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';

import './ReschduleDeliveryOptionModal.less';

const ReschduleDeliveryOptionModal = ({
  visible,
  close,
  delivery,
  onSuccess,
  intl
}) => {
  const formRef = useRef(null);
  const [userTimeSlots, setUserTimeSlots] = useState([]);

  const disabledDate = (current) => {
    // Can not select days before today and after slaDate and only show the next 5 days
    var weekDayName = dayjs(current).format('dddd');
    return (
      current &&
      (current > dayjs(delivery?.sla?.e2eSla?.e2eSlaTimestamp).endOf('day') ||
        current < dayjs().endOf('day') ||
        weekDayName === FRIDAY ||
        dayjs().add(7, 'days') < current)
    );
  };

  const getTimeSlots = useCallback(async () => {
    try {
      const list = await getUserTimeSlots();
      setUserTimeSlots(list || []);
    } catch (error) {
      notify(error.message);
    }
  }, []);

  useEffect(() => {
    getTimeSlots();
  }, []);

  const formatTimeSlot = (time) => {
    if (time >= 12) {
      return `${time % 12 || 12}:00 PM`;
    } else return `${time}:00 AM`;
  };

  const handleOnFinish = async (values) => {
    values['updateType'] = 'rescheduleDelivery';
    values.scheduledAt = FORMAT_DATE_TO_UTC(values.scheduledAt);
    if (values?.timeSlot) {
      values.timeSlot = JSON.parse(values.timeSlot);
    }
    try {
      await reschduleDeliveryDate(delivery._id, values);
      notify('Order has been scheduled successfully.', 'success');
      close();
      onSuccess();
    } catch (e) {
      notify(e.message);
    }
  };

  const handleOnTicketNumberChange = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
  };

  return (
    <Modal
      visible={visible}
      onCancel={close}
      className="br-reschedule-modal__container"
      okText="Reschedule Order"
      okType=""
      okButtonProps={{
        className: 'br-reschdule-modal__primary-button',
        htmlType: 'submit',
        form: 'rescudleDeliveryForm'
      }}
    >
      <div>
        <div className="br-reschedule-modal__header">
          <span>Reschedule Delivery</span>
          <CloseOutlined onClick={close} />
        </div>
        <Form
          scrollToFirstError
          ref={formRef}
          className="br-edit-delivery-modal__form-container"
          name="rescudleDeliveryForm"
          onFinish={handleOnFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="scheduledAt"
                label="Schedule Date"
                rules={[{ required: true }]}
              >
                <DatePicker disabledDate={disabledDate} suffixIcon={null} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="timeSlot" label="Schedule time slot">
            <Select placeholder="Select Time">
              {userTimeSlots?.map((timeSlot, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={JSON.stringify({
                      startTime: timeSlot.startTime,
                      endTime: timeSlot.endTime
                    })}
                  >
                    {`${formatTimeSlot(timeSlot.startTime)} - ${formatTimeSlot(
                      timeSlot.endTime
                    )}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {isUserAuthorized([CUSTOMER_SUPPORT]) ? (
            <Form.Item
              name="reschedulingTicketNumber"
              label="Ticket Number"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <BRFormInputNumber
                filedName="reschedulingTicketNumber"
                placeholder={'Ticket Number'}
                handleOnChangeForm={handleOnTicketNumberChange}
              />
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};

export default injectIntl(ReschduleDeliveryOptionModal);
