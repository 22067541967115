import XlsxPopulate from 'xlsx-populate';
import { omitBy } from 'lodash';
import * as yup from 'yup';
import dayjs from 'dayjs';

export const convertExcelToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet(0);
        const sheetPromotions = sheet.usedRange().value().slice(1);
        const promotions = sheetPromotions
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructPromotion);
        resolve(promotions);
      } catch (error) {
        reject(error);
      }
    });
  });
};

const formatExcelDate = (date) => {
  // excel stores dates as serial format "45578" is 10/13/2024
  return typeof date === 'string'
    ? date.replace(/[“'"”]+/g, '').trim()
    : dayjs('1900-01-01')
        .add(date - 2, 'day')
        .format('MM/DD/YYYY');
};

const constructPromotion = (promotion) => ({
  businessId: promotion[0]?.toString().trim(),
  percentage: promotion[1],
  activeAt: formatExcelDate(promotion[2]),
  inactiveAt: formatExcelDate(promotion[3])
});

const promotionSchema = () =>
  yup.array().of(
    yup.object().shape({
      businessId: yup
        .string()
        .label('Business ID')
        .required('Business ID is required!'),
      percentage: yup
        .number()
        .label('Percentage')
        .required('Percentage is required!')
        .min(0.01, 'Percentage shall be from 0.01-1')
        .max(1, 'Percentage shall be from 0.01-1'),
      activeAt: yup
        .string()
        .label('Active At')
        .test({
          name: 'Active at date validation',
          test: function (activeAt) {
            const { path, createError } = this;
            if (!activeAt) {
              return createError({
                path,
                message: 'Active At date is required!'
              });
            } else {
              if (
                !dayjs(activeAt, 'MM/DD/YYYY', true).isValid() &&
                !dayjs(activeAt, 'M/D/YYYY', true).isValid()
              ) {
                return createError({
                  path,
                  message: 'Active At date format shall be "MM/DD/YYYY"'
                });
              } else if (!(dayjs(activeAt) > dayjs())) {
                return createError({
                  path,
                  message:
                    'Active At date shall be in a future date starting from tomorrow'
                });
              }
            }
            return true;
          }
        }),
      inactiveAt: yup
        .string()
        .label('Inactive At')
        .test({
          name: 'inactive at date validation',
          test: function (inactiveAt) {
            const { path, parent, createError } = this;
            if (!inactiveAt) {
              return createError({
                path,
                message: 'Inactive At date is required!'
              });
            } else {
              if (
                !dayjs(inactiveAt, 'MM/DD/YYYY', true).isValid() &&
                !dayjs(inactiveAt, 'M/D/YYYY', true).isValid()
              ) {
                return createError({
                  path,
                  message: 'Inactive At date format shall be "MM/DD/YYYY"'
                });
              }
              const date = dayjs(inactiveAt);
              if (!(date > dayjs())) {
                return createError({
                  path,
                  message: 'Inactive At date shall be in a future date'
                });
              } else if (parent?.activeAt) {
                const activeAtStr = parent?.activeAt.slice(
                  1,
                  parent?.activeAt.length - 1
                );
                const activeAtDate = dayjs(activeAtStr);
                if (date <= activeAtDate) {
                  return createError({
                    path,
                    message:
                      'Inactive At date shall be later than active at date!'
                  });
                }
              }
            }
            return true;
          }
        })
    })
  );

const groupErrors = (errors) => {
  const errorObject = {
    'Business ID is required!': { fieldName: 'Business ID', rows: [] },
    'Percentage is required!': { fieldName: 'Percentage', rows: [] },
    'Percentage shall be from 0.01-1': { fieldName: 'Percentage', rows: [] },
    'Percentage shall be from 0.01-1': { fieldName: 'Percentage', rows: [] },
    'Active At date is required!': { fieldName: 'Active At', rows: [] },
    'Active At date format shall be "MM/DD/YYYY"': {
      fieldName: 'Active At',
      rows: []
    },
    'Active At date shall be in a future date starting from tomorrow': {
      fieldName: 'Active At',
      rows: []
    },
    'Inactive At date is required!': { fieldName: 'Inactive At', rows: [] },
    'Inactive At date format shall be "MM/DD/YYYY"': {
      fieldName: 'Inactive At',
      rows: []
    },
    'Inactive At date shall be in a future date': {
      fieldName: 'Inactive At',
      rows: []
    },
    'Inactive At date shall be later than active at date!': {
      fieldName: 'Inactive At',
      rows: []
    }
  };

  errors.forEach((error) => {
    const rowNumber = Number(error.path.split('.')[0].slice(1, -1)) + 2;
    errorObject[error.message].rows.push(rowNumber);
  });

  const errorArray = [];
  let keyIndex = 1;
  for (const [key, value] of Object.entries(errorObject)) {
    if (value.rows.length > 0) {
      errorArray.push({
        key: keyIndex++,
        name: value.fieldName,
        issue: key,
        rows: value.rows.join(', ')
      });
    }
  }

  return errorArray;
};

export const validatePromotions = (promotions) => {
  return new Promise(async (resolve, _) => {
    try {
      await promotionSchema().validate(promotions, {
        abortEarly: false
      });
      resolve({ isValid: true });
    } catch (error) {
      resolve({ isValid: false, errors: groupErrors(error.inner) });
    }
  });
};
