import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { SESSION_DATE_FORMAT } from 'constants/Security';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as MonitorIcon } from 'assets/imgRevamp/cashier-icon.svg';

import './SessionCard.less';

const SessionCard = ({ sessionInfo, intl, handleLogoutSession }) => {
  const handleLogoutClick = () => {
    handleLogoutSession([sessionInfo.sessionId]);
  };

  return (
    <div className="br-session-card__container">
      <div>
        <div className="br-session-card__icon">
          <MonitorIcon />
        </div>
        <div className="br-session-card__session-info">
          {sessionInfo?.sessionData?.device}
          <span>
            {sessionInfo?.sessionData?.ip ||
              intl.formatMessage({ id: 'common.empty_field' })}{' '}
            •{'  '}
            {dayjs(sessionInfo?.sessionData?.generatedDate).format(
              SESSION_DATE_FORMAT
            )}
          </span>
        </div>
      </div>
      {!sessionInfo?.isCurrentSession && (
        <div className="br-session-card__actions">
          <BRButton
            type="destructive-primary"
            onClick={handleLogoutClick}
            label={intl.formatMessage({
              id: 'settings.active_sessions.logout_this_session'
            })}
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(SessionCard);
