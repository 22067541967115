import { Image } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as NoImageIcon } from 'assets/imgRevamp/gallery-remove.svg';

const NoImage = ({ icon }) => <Icon component={icon} />;

const BRImage = ({
  src,
  alt,
  width,
  height,
  preview = false,
  noImageIcon = NoImageIcon,
  isThumbnail = false,
  ...restImageProps
}) => {
  return (
    <div className="br-image">
      {src ? (
        <Image
          src={src}
          width={width || (isThumbnail && 48)}
          height={height}
          alt={alt}
          preview={{
            mask: () => null,
            ...preview
          }}
          {...restImageProps}
        />
      ) : (
        <NoImage className="br-image__no-image" icon={noImageIcon} />
      )}
    </div>
  );
};

export default BRImage;
