import React from 'react';

import Container from '../../components/Container';
import BRHeader from 'components/BRHeader/BRHeader';

function RoutesContainer({ children, title }) {
  return (
    <Container
      header={<BRHeader title={title || 'Routes'} showBackArrow />}
      content={children}
    />
  );
}

export default RoutesContainer;
