import { List, Checkbox } from 'antd';

import './MobileCards.less';

const MobileCards = ({
  dataSource,
  columns,
  selectedCards,
  setSelectedCards,
  pagination,
  handleTableChange,
  isLoading
}) => {
  const onCardSelect = ({ checked, key }) => {
    const selectedItems = checked
      ? [...selectedCards, key]
      : selectedCards.filter((cardKey) => cardKey !== key);
    setSelectedCards(selectedItems);
  };
  return (
    <div className="br-mobile-cards">
      <List
        className="br-table-mobile-list"
        dataSource={dataSource}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        renderItem={(row) => (
          <div className="br-table-mobile-card">
            <Checkbox
              checked={selectedCards?.includes(row?.key)}
              onChange={({ target: { checked } }) =>
                onCardSelect({ checked, key: row.key })
              }
            />
            <div>
              {columns.map((item) =>
                item.render
                  ? item.render(row[item.dataIndex], row)
                  : row[item.dataIndex]
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default MobileCards;
