import BRBasicModal from 'components/BRBasicModal/BRBasicModal';
import { openModal } from './modal';
import { ReactComponent as CopyIcon } from 'assets/imgRevamp/CopyIcon.svg';
import { notify } from 'components/Notify/Notify';
import { useEffect, useState } from 'react';

const TnRow = ({ tn }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleOnCopyTn = (tn) => {
    navigator.clipboard.writeText(tn);
    setIsCopied(true);
  };
  useEffect(() => {
    let i;
    if (isCopied) {
      i = setTimeout(() => setIsCopied((prev) => !prev), 500);
    }
    return () => {
      clearTimeout(i);
    };
  }, [isCopied]);

  return (
    <div className="br-money-debrief__tracking-numbers-modal__tn-content">
      <p className="body-medium">{tn}</p>
      {isCopied ? (
        <span className="caption">copied </span>
      ) : (
        <CopyIcon onClick={() => handleOnCopyTn(tn)} />
      )}
    </div>
  );
};

const renderModalContent = (trackingNumbers) => {
  return trackingNumbers.map((tn) => <TnRow tn={tn} />);
};
export const handleOnShowAllTnClick = (trackingNumbers) => {
  openModal(BRBasicModal, {
    title: 'Tracking Numbers',
    footer: null,
    content: renderModalContent(trackingNumbers)
  });
};
