import React from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import InvestigationOrders from 'components/Quality/components/InvestigationOrders/InvestigationOrders';
import UndebriefedRoutes from 'components/Quality/components/UndebriefedRoutes/UndebriefedRoutes';
import RejectedReturns from 'components/Quality/components/RejectedReturns/RejectedReturns';

import './Quality.less';

const Quality = ({ intl }) => {
  const { TabPane } = Tabs;

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'quality.title'
          })}
        />
      }
      content={
        <Tabs className="br-international-shipping__tabs">
          <TabPane
            tab={intl.formatMessage({
              id: 'quality.investigation.title'
            })}
            key={1}
          >
            <InvestigationOrders />
          </TabPane>
          <TabPane
            tab={intl.formatMessage({
              id: 'quality.undebriefied_routes.title'
            })}
            key={2}
          >
            <UndebriefedRoutes />
          </TabPane>
          {isUserAuthorized(aclMatrix.REJECTED_RETURN_TAB_ACCESS) && (
            <TabPane
              tab={intl.formatMessage({
                id: 'quality.rejected_returns_tab.title'
              })}
              key={3}
            >
              <RejectedReturns />
            </TabPane>
          )}
        </Tabs>
      }
    />
  );
};

export default injectIntl(withRouter(Quality));
