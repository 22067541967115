import { useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';

import { RECEIVED_BY_MISTAKE } from 'constants/hubs';
import { receivePackagesAtHub } from 'services/hubs';

import BRButton from 'components/BRButton/BRButton';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import HubManagmentContainer from 'containers/HubManagment/HubManagmentContainer';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './ReceivedByMistake.less';

const ReceivedByMistake = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef('');

  const onFinish = async (values) => {
    setIsLoading(true);

    const payload = {
      updateType: RECEIVED_BY_MISTAKE,
      deliveries: values.trackingNumbers
        ? values.trackingNumbers
            .trim()
            .replace(/,+\s*$/, '')
            .split(/[, ]+/)
            .map((trackingNumber) => ({ _id: trackingNumber }))
        : []
    };

    try {
      if (payload.deliveries.length) {
        await receivePackagesAtHub(payload);
        formRef.current.resetFields();
        notify(
          intl.formatMessage({
            id: 'hub_management.received_by_mistake.success_message'
          }),
          'success'
        );
      } else {
        notify(
          intl.formatMessage({
            id: 'three_pl_integrations.invalid_tracking_number'
          })
        );
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Container
        className="br-tabs-container-content"
        hideWarningBanner
        header={
          <BRHeader
            title={intl.formatMessage({
              id: 'hub_management.received_by_mistake.title'
            })}
          />
        }
        content={
          <>
            <HubManagmentContainer
              children={
                <LoadingWrapper loading={isLoading}>
                  <div className="br-received-by-mistake__container">
                    <Form onFinish={onFinish} ref={formRef}>
                      <Form.Item
                        name="trackingNumbers"
                        label={intl.formatMessage({
                          id: 'hub_management.received_by_mistake.input_label'
                        })}
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder={intl.formatMessage({
                            id: 'hub_management.received_by_mistake.input_placeholder'
                          })}
                        />
                      </Form.Item>
                      <BRButton
                        disabled={isLoading}
                        type="primary"
                        htmlType="submit"
                        label={intl.formatMessage({
                          id: 'hub_management.received_by_mistake.action'
                        })}
                      />
                    </Form>
                  </div>
                </LoadingWrapper>
              }
            />
          </>
        }
      />
    </>
  );
};

export default injectIntl(ReceivedByMistake);
