import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getReturnOrdersStatistics = (payload) => {
  return http.get('/fulfillment-v2/return-orders/statistics', payload);
};

export const getReturnOrders = (payload) => {
  return http.get('/fulfillment-v2/return-orders', payload);
};

export const exportReturnOrders = (payload) => {
  return http.get(
    '/fulfillment-v2/return-orders/export',
    payload,
    downloadHeader
  );
};

export const getFulfillmentReturnOrder = (id, payload) => {
  return http.get(`/fulfillment-v2/return-orders/${id}`, payload);
};

export const getReturnOrderLineSerialNumbers = ({ lineId, payload }) => {
  return http.get(
    `/fulfillment-v2/return-orders/lines/${lineId}/serials`,
    payload
  );
};

export const changeReturnOrderOState = (id, payload) => {
  return http.post(`/fulfillment-v2/return-orders/${id}/state`, payload);
};
