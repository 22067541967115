import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { emailRule, phoneNumberRule } from 'utils/forms';
import {
  editTeamMemeberInfoVerification,
  confirmTeamMemeberInfoVerification
} from 'services/business';
import { openModal } from 'utils/modal';

import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';
import { notify } from 'components/Notify/Notify';

import './EditPhoneEmailModal.less';

const EditPhoneEmailModal = (props) => {
  const { intl, close, newEmail, newPhone, memeberId } = props;
  const [form] = Form.useForm();

  const handleEditTeamMemeberInfo = async (values) => {
    try {
      const response = await editTeamMemeberInfoVerification({
        _id: memeberId,
        contact: newEmail ? 'email' : 'phone',
        newValue: Object.values(values)[0]
      });
      return response;
    } catch (error) {
      notify(error.message);
      return error;
    }
  };

  const handleOnFinish = async (values) => {
    try {
      const response = await handleEditTeamMemeberInfo(values);
      if (response.success) {
        const { getTeamMembersInfo, getBusinessInfo } = props;
        close();
        openModal(BROTPCodeModal, {
          title: intl.formatMessage({
            id: `business_details.team_members.otp_screens.business_verification.edit_${
              newEmail ? 'email' : 'phone'
            }.title`
          }),
          subtitle: intl.formatMessage(
            {
              id: `business_details.team_members.otp_screens.business_verification.edit_${
                newEmail ? 'email' : 'phone'
              }.subtitle`
            },
            newEmail
              ? {
                  email: (
                    <span>
                      {values.email?.split('@')[0]?.slice(-4)}@
                      {values.email.split('@')[1]}
                    </span>
                  )
                }
              : {
                  phone: <span>{values.phone.slice(-3)}</span>
                }
          ),
          onSuccess: () => {
            notify(
              newEmail
                ? 'The email address is updated successfully.'
                : 'The phone number is updated successfully.',
              'success'
            );
            getBusinessInfo();
            getTeamMembersInfo();
          },
          confirmOTP: (newValueOtp) =>
            confirmTeamMemeberInfoVerification({
              _id: memeberId,
              contact: newEmail ? 'email' : 'phone',
              newValueOtp
            }),
          handleResendCode: handleEditTeamMemeberInfo
        });
      }
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <Modal
      {...props}
      footer={null}
      closeIcon={<CloseOutlined />}
      wrapClassName="br-edit-phone-email__container"
    >
      <Form
        form={form}
        onFinish={handleOnFinish}
        className="br-edit-phone-email-modal__form"
      >
        {newPhone && (
          <Form.Item
            name="phone"
            validateTrigger="onBlur"
            label={intl.formatMessage({
              id: 'contact_form_labels.phone'
            })}
            rules={[
              {
                label: intl.formatMessage({
                  id: 'contact_form_labels.phone'
                }),
                required: true
              },
              phoneNumberRule({
                message: intl.formatMessage({
                  id: 'form.phone_not_valid'
                }),
                internationlNumbers: true
              })
            ]}
          >
            <Input autoComplete={'' + Math.random()} />
          </Form.Item>
        )}
        {newEmail && (
          <Form.Item
            name="email"
            label={intl.formatMessage({
              id: 'contact_form_labels.email'
            })}
            rules={[
              {
                label: intl.formatMessage({
                  id: 'contact_form_labels.email'
                }),
                required: true
              },
              emailRule(
                intl.formatMessage({
                  id: 'form.email_not_valid'
                })
              )
            ]}
          >
            <Input
              type="email"
              maxLength={225}
              autoComplete={'' + Math.random()}
            />
          </Form.Item>
        )}
        <div className="br-edit-phone-email-modal__form__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.save'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(EditPhoneEmailModal);
