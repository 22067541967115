import http from 'utils/http';

export const getLiveOpsList = (payload) => {
  return http.get(`/deliveries/search`, payload);
};

export const lockUnlockDeliveryItem = (id, payload) => {
  return http.put(`/admin/deliveries/${id}`, payload);
};

export const callDeliveryItem = (id, payload) => {
  return http.put(`/admin/deliveries/${id}/live-ops-action`, payload);
};

export const getSummaryCard = (payload) => {
  return http.post(`/admin/deliveries/live-ops-summary-cards`, payload);
};

export const exportedLiveOpsFile = (payload) => {
  return http.post(`/deliveries/exportxlsx`, payload);
};

export const getCurrentUserHubInfo = () => {
  return http.get('/users/user-warehouse');
};
