import React, { useState } from 'react';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

import {
  getShippingOrders,
  exportItems,
  fetchRecapInfo,
  fetchInsightInfo,
  fetchBusinessItemsInfo
} from 'services/international-shipping-in';

import InsightsCards from 'components/InternationalShipping/components/Insights/InsightsCards/InsightsCards';
import InsightsExpand from 'components/InternationalShipping/components/Insights/InsightsExpand/InsightsExpand';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import { notify } from 'components/Notify/Notify';

import './Insights.less';

const Insights = ({ businesses }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState(businesses || []);
  const [selectedRows, setSelectedRows] = useState([]);
  const [count, setCount] = useState(0);

  const columns = () => {
    return [
      {
        title: 'Business',
        dataIndex: 'business_name'
      },
      {
        title: 'No of Items',
        dataIndex: 'items_count'
      },
      {
        title: 'No of Orders',
        dataIndex: 'orders_count',
        render: (text) => (text === 'N/A' ? 0 : text)
      }
    ];
  };

  const formatItems = (items) => {
    const formattedItems = [];
    items.map((item) => {
      formattedItems.push({
        key: item._id,
        _id: item._id,
        business_name: item.name,
        items_count: item.itemsCount,
        orders_count: item.ordersCount
      });
    });
    return formattedItems;
  };

  const getInsights = async ({ pageId: pageNumber, pageLimit: pageSize }) => {
    setIsLoading(true);
    try {
      const { data } = await fetchInsightInfo({
        pageNumber: pageNumber - 1,
        pageSize
      });

      setCount(data.length || 0);
      setIsLoading(false);
      return {
        list: formatItems(data),
        total: data.total || 0
      };
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  return (
    <div className="br-international-insights__container">
      <InsightsCards />
      <div className="br-international-insights__table-header">
        <b>{count} Total</b>
      </div>
      <BRSearchableTable
        columns={columns()}
        onRowClick={() => {}}
        showPagination
        pageLimit={50}
        listFunction={getInsights}
        withOutCheckBoxes
        expandable={{
          expandedRowRender: (record) => <InsightsExpand record={record} />,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
          expandIconColumnIndex: 4
        }}
      />
    </div>
  );
};

export default Insights;
