import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  VALIDATE_CALL,
  VALIDATE_EXCEPTION,
  VALIDATE_MESSAGE,
  ENTER_CONSIGNEE_FEEDBACK
} from 'constants/live-ops';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './LiveOpsValidation.less';

const LiveOpsValidation = ({
  intl,
  liveOps,
  whatsAppValidation,
  whatsappFakeAttempt,
  liveOpsActionId
}) => {
  const [liveOpsDetails, setLiveOpsDetails] = useState(null);
  const [reachOuts, setReachOuts] = useState(null);

  useEffect(() => {
    if (liveOps?.actions) {
      setLiveOpsDetails(
        liveOps?.actions?.filter(
          (item) => item.attemptNumber === liveOpsActionId
        )
      );

      setReachOuts(
        liveOps?.actions?.filter(
          (item) =>
            item.action === ENTER_CONSIGNEE_FEEDBACK &&
            item.attemptNumber === liveOpsActionId
        )
      );
    }
  }, [liveOps]);

  return (
    <div className="br-live-ops__container">
      <div className="br-live-ops__container__row">
        <div className="br-live-ops__container__row__details">
          {intl.formatMessage({
            id: 'live_ops.delivery_details.validate'
          })}
          <div className="br-live-ops__container__row__checkboxs">
            {liveOpsDetails?.filter(
              (item) => item?.action === VALIDATE_MESSAGE
            )[0]?.isValid === false ? (
              <div className="br-live-ops__container__cross-icon">
                <span>
                  <Icon component={CloseIcon} />
                </span>
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.sms'
                })}
              </div>
            ) : (
              <Checkbox
                checked={
                  liveOpsDetails?.filter(
                    (item) => item?.action === VALIDATE_MESSAGE
                  )[0]?.isValid
                }
                disabled={true}
              >
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.sms'
                })}
              </Checkbox>
            )}

            {liveOpsDetails?.filter((item) => item?.action === VALIDATE_CALL)[0]
              ?.isValid === false ? (
              <div className="br-live-ops__container__cross-icon">
                <span>
                  <Icon component={CloseIcon} />
                </span>
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.call'
                })}
              </div>
            ) : (
              <Checkbox
                checked={
                  liveOpsDetails?.filter(
                    (item) => item?.action === VALIDATE_CALL
                  )[0]?.isValid
                }
                disabled={true}
              >
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.call'
                })}
              </Checkbox>
            )}
            {whatsAppValidation ? (
              whatsappFakeAttempt ? (
                <div className="br-live-ops__container__cross-icon">
                  <span>
                    <Icon component={CloseIcon} />
                  </span>
                  {intl.formatMessage({
                    id: 'live_ops.delivery_details.whatsapp'
                  })}
                </div>
              ) : (
                <Checkbox checked disabled={true}>
                  {intl.formatMessage({
                    id: 'live_ops.delivery_details.whatsapp'
                  })}
                </Checkbox>
              )
            ) : (
              <Checkbox disabled={true}>
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.whatsapp'
                })}
              </Checkbox>
            )}
            {liveOpsDetails?.filter(
              (item) => item.action === VALIDATE_EXCEPTION
            )[0]?.isValid === false ? (
              <div className="br-live-ops__container__cross-icon">
                <span>
                  <Icon component={CloseIcon} />
                </span>
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.exception'
                })}
              </div>
            ) : (
              <Checkbox
                checked={
                  liveOpsDetails?.filter(
                    (item) => item.action === VALIDATE_EXCEPTION
                  )[0]?.isValid
                }
                disabled={true}
              >
                {intl.formatMessage({
                  id: 'live_ops.delivery_details.exception'
                })}
              </Checkbox>
            )}
          </div>
        </div>
        <div>
          {intl.formatMessage({
            id: 'live_ops.delivery_details.reach'
          })}
          <div>
            {reachOuts ? reachOuts[reachOuts.length - 1]?.reachesOut : 0}
          </div>
        </div>
        <div className="display-flex flex-dir-col">
          <span className="br-live-ops__container__row__title">
            {intl.formatMessage({
              id: 'live_ops.delivery_details.validated_by'
            })}
          </span>
          <span className="br-live-ops__container__row__sub-title">
            {
              liveOpsDetails?.filter(
                (item) => item?.action === VALIDATE_EXCEPTION
              )[0]?.takenBy?.name
            }
          </span>
          <span className="br-live-ops__container__row__sub-title">
            {liveOpsDetails?.filter(
              (item) => item?.action === VALIDATE_EXCEPTION
            )[0] &&
              dayjs(
                liveOpsDetails?.filter(
                  (item) => item?.action === VALIDATE_EXCEPTION
                )[0]?.time
              ).tz().format('YYYY-MM-DD h:mm A')}
          </span>
        </div>
      </div>
      <div className="br-live-ops__container__row flex-dir-col">
        <span className="br-live-ops__container__row__title">
          {intl.formatMessage({
            id: 'live_ops.delivery_details.exception_validation'
          })}
        </span>
        <span className="br-live-ops__container__row__sub-title">
          {
            liveOpsDetails?.filter(
              (item) => item?.action === VALIDATE_EXCEPTION
            )[0]?.exceptionReason
          }
        </span>
      </div>
      <div className="br-live-ops__container__row flex-dir-col">
        <span className="br-live-ops__container__row__title">
          {intl.formatMessage({
            id: 'live_ops.delivery_details.agent'
          })}
        </span>
        <span className="br-live-ops__container__row__sub-title">
          {
            liveOpsDetails?.filter(
              (item) => item?.action === VALIDATE_EXCEPTION
            )[0]?.exceptionNote
          }
        </span>
      </div>
      <div className="br-live-ops__container__row flex-dir-col">
        <span className="br-live-ops__container__row__title">
          {intl.formatMessage({
            id: 'live_ops.delivery_details.feedback'
          })}
        </span>
        <span className="br-live-ops__container__row__sub-title">
          {reachOuts ? reachOuts[reachOuts.length - 1]?.consigneeFeedback : ''}
        </span>
      </div>
    </div>
  );
};

export default injectIntl(LiveOpsValidation);
