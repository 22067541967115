import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { Button, Dropdown, Menu, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';

import { STAR_MAP_DEFAULT_DATE_FORMAT } from 'constants/stars';

import { ReactComponent as CalenderIcon } from 'assets/imgRevamp/calender-icon.svg';

import './HubPerformanceDateFilter.less';

const HubPerformanceDateFilter = ({
  intl,
  setDateFilter,
  defaultDate = [],
  setHistoryView = () => {},
  todayDefault = false,
  customDisabledDate = false
}) => {
  const [selectedDateString, setSelectedDateString] = useState(
    `${dayjs()
      .add(todayDefault ? 0 : -1, 'days')
      .format(STAR_MAP_DEFAULT_DATE_FORMAT)} - ${dayjs()
      .add(todayDefault ? 0 : -1, 'days')
      .format(STAR_MAP_DEFAULT_DATE_FORMAT)}`
  );
  const [openedMonth, setOpenedMonth] = useState(null);
  const [dateVisible, setDateVisible] = useState(false);
  const [dates, setDates] = useState([null, null]);

  useEffect(() => {
    if (defaultDate.length) {
      setSelectedDateString(
        `${defaultDate[0].format(
          STAR_MAP_DEFAULT_DATE_FORMAT
        )} - ${defaultDate[1].format(STAR_MAP_DEFAULT_DATE_FORMAT)}`
      );
    }
  }, [defaultDate]);

  const onChange = async (dates, dateString) => {
    await setHistoryView(false);
    if (dates) {
      setDateFilter({
        dateFrom: dates[0].format(STAR_MAP_DEFAULT_DATE_FORMAT),
        dateTo: dates[1].format(STAR_MAP_DEFAULT_DATE_FORMAT)
      });
      setSelectedDateString(
        `${dates[0].format(STAR_MAP_DEFAULT_DATE_FORMAT)} - ${dates[1].format(
          STAR_MAP_DEFAULT_DATE_FORMAT
        )}`
      );
      setDates(dates);
    } else {
      setDates([null, null]);
    }
  };

  const handleOnSelectMonth = async () => {
    setDateVisible(false);
    await setHistoryView(true);

    setDateFilter({
      dateFrom: dayjs().startOf('month').format(STAR_MAP_DEFAULT_DATE_FORMAT),
      dateTo: dayjs().add(-1, 'days').format(STAR_MAP_DEFAULT_DATE_FORMAT)
    });
    setSelectedDateString(
      `${dayjs()
        .startOf('month')
        .format(STAR_MAP_DEFAULT_DATE_FORMAT)} - ${dayjs()
        .add(-1, 'days')
        .format(STAR_MAP_DEFAULT_DATE_FORMAT)}`
    );
    setDates([dayjs().startOf('month'), dayjs().add(-1, 'days')]);
  };

  const disabledDate = (current) => {
    if (openedMonth) {
      return !openedMonth?.isSame(current, 'month') || current >= dayjs();
    }
    return (
      current && (current >= dayjs() || dayjs().month() - current.month() >= 3)
    );
  };

  const dateMenu = () => (
    <Menu className="br-star-map-filter__date-menu">
      <span onClick={(e) => e.stopPropagation()}>
        <DatePicker.RangePicker
          onChange={onChange}
          showToday={false}
          disabledDate={customDisabledDate || disabledDate}
          onOpenChange={(open) => (!open ? setOpenedMonth(null) : null)}
          onCalendarChange={(dates, dateStrings, info) => {
            if (info.range === 'start') {
              setOpenedMonth(dates[0]);
            }
          }}
          renderExtraFooter={() => (
            <div
              className="br-hub-performance__this-month-date-filter"
              onClick={handleOnSelectMonth}
            >
              {intl.formatMessage({
                id: 'bib.this_month'
              })}
            </div>
          )}
          value={dates}
          getPopupContainer={(trigger) =>
            trigger.parentElement.parentElement.parentElement.parentElement
          }
        />
      </span>
    </Menu>
  );

  return (
    <div className="br-star-logs-date-filter">
      <Dropdown
        overlay={dateMenu}
        trigger="click"
        visible={dateVisible}
        onVisibleChange={(currentVisibility) =>
          setDateVisible(currentVisibility)
        }
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
        }
      >
        <Button className="br-order-actions-filter__trigger">
          <CalenderIcon className="br-order-actions-filter__trigger-icon" />
          {selectedDateString}
        </Button>
      </Dropdown>
    </div>
  );
};

export default injectIntl(HubPerformanceDateFilter);
