import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import store from 'store/configureStore';
import IntlWrapper, { intl } from 'IntlWrapper/IntlWrapper';

export const openModal = (Component, componentProps) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const validateMessages = {
    required: intl.formatMessage({ id: 'form.required' }),
    string: {
      max: intl.formatMessage({ id: 'form.maxLen' })
    },
    whitespace: intl.formatMessage({ id: 'form.required' })
  };

  const modalProps = ({ visible, centered, close }) => ({
    visible,
    centered,
    close
  });

  const render = (props) => {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <IntlWrapper>
            <ConfigProvider
              // getPopupContainer={(trigger) =>
              //   trigger?.parentElement || document.body
              // }
              form={{ validateMessages }}
            >
              <Component {...props} />
            </ConfigProvider>
          </IntlWrapper>
        </BrowserRouter>
      </Provider>,
      div
    );
  };

  const destroy = () => {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  const close = () => {
    setTimeout(() => {
      destroy();
    }, 500);
    render({ ...modalProps({ visible: false, close }) });
  };

  render({
    ...componentProps,
    ...modalProps({ visible: true, centered: true, close })
  });
};

export const x = 5;
