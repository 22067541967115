import React, { useEffect, useState } from 'react';

import { fetchBusinessItemsInfo } from 'services/international-shipping-in';

import { notify } from 'components/Notify/Notify';

const InsightsExpand = ({ record }) => {
  const [itemsInfo, setItemsInfo] = useState([]);

  useEffect(() => {
    fetchBusinessOrderInfo();
  }, [record]);

  const STATUS_MAPPING = {
    arrived_at_us_office: 'Arrived at USA office',
    in_transit_to_cairo: 'In transit to Egypt',
    arrived_at_cairo_office: 'Arrived to Egypt',
    in_progress: 'In Progress',
    out_for_delivery: 'Out for Delivery',
    delivered: 'Delivered'
  };

  const fetchBusinessOrderInfo = async () => {
    try {
      const { data } = await fetchBusinessItemsInfo(record.key);
      setItemsInfo(data);
    } catch (error) {
      notify(error.message);
    }
  };

  return itemsInfo.length ? (
    <div className="br-insights-expand__container">
      <div className="br-insights-expand__header">
        <span>Status</span>
        <span>Items</span>
      </div>
      {itemsInfo.map((item) => (
        <div className="br-insights-expand__content-row">
          <span>
            <span className="br-insights__tag">
              {STATUS_MAPPING[item.status]}
            </span>
          </span>
          <span>{item.count}</span>
        </div>
      ))}
    </div>
  ) : (
    <div>
      <p>Loading...</p>
    </div>
  );
};

export default InsightsExpand;
