import React, { useEffect, useRef, useState } from 'react';
import { Popover, Dropdown, Menu, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Icon from '@ant-design/icons';

import { COUNTRY_CURRENCY } from 'constants/helper';
import { LOCALE } from 'constants/intl-wrapper';
import {
  cancelFlyersSalesOrder,
  confirmFlyersSalesOrder,
  getFlyersDraftOrder,
  getFlyersList
} from 'services/packaging';
import { openModal } from 'utils/modal';
import { dates } from 'utils/helpers';
import { exportPackages } from 'services/packaging';
import { getflyerSizePayload } from 'common/countries/countries-mapping';

import CancelFlyersOrderModal from '../CancelFlyersOrderModal/CancelFlyersOrderModal';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import ItemsPopOver from '../ItemsPopOver/ItemsPopOver';

import { ReactComponent as RefundIcon } from 'assets/bosta-icons/refund-icon.svg';
import { ReactComponent as CodIcon } from 'assets/bosta-icons/cash-on-delivery-icon.svg';
import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

import './DraftOrders.less';

const DraftOrders = ({ isCancelledFlyersTab = false }) => {
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleRowSelection, setToggleRowSelection] = useState(false);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  const refreshTable = useRef();

  const menuItem = (id) => {
    return (
      <Menu
        onClick={({ domEvent }) => domEvent.stopPropagation()}
        items={[
          {
            key: '1',
            label: (
              <span className="br-draft-orders__table-actions__delete">
                {intl.formatMessage({
                  id: 'packaging.orders_tab.draft_orders.table.delete'
                })}
              </span>
            ),
            onClick: () => {
              handleDeleteClick(id);
            }
          }
        ]}
      />
    );
  };

  const linkActionsDropdown = (id, creationSrc) => {
    return (
      <Dropdown
        trigger={['click']}
        placement={intl.locale === LOCALE.AR ? 'bottomLeft' : 'bottomRight'}
        overlay={menuItem(id, creationSrc)}
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement
        }
      >
        <BRButton
          type="table-actions"
          className="button-sm"
          prefixIcon={<Icon component={Actions} />}
        />
      </Dropdown>
    );
  };

  const MARKETPLACE_ORDERS_COLUMNS = [
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.order_id`
      }),
      dataIndex: 'title',
      width: 150
    },
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.customer_info`
      }),
      dataIndex: 'customerInfo',
      width: 240
    },
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.location`
      }),
      dataIndex: 'location',
      width: 150
    },
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.items`
      }),
      dataIndex: 'items',
      width: 90
    },
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.amount`
      }),
      dataIndex: 'cod',
      width: 140
    },
    {
      title: intl.formatMessage({
        id: `packaging.orders_tab.draft_orders.table.${
          isCancelledFlyersTab ? 'cancellation_date' : 'creation_date'
        }`
      }),
      dataIndex: 'createdAtColumn',
      width: 110
    },
    !isCancelledFlyersTab
      ? {
          dataIndex: 'id',
          render: (_id, { creationSrc, cancellationReason }) => (
            <div className="br-draft-orders__table-actions">
              <BRButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOrderConfirm(_id);
                }}
                label={intl.formatMessage({ id: 'common.confirm' })}
              />
              {linkActionsDropdown(_id, creationSrc)}
            </div>
          ),
          width: 160
        }
      : {
          title: intl.formatMessage({
            id: `packaging.orders_tab.draft_orders.table.cancellation_reason`
          }),
          dataIndex: 'id',
          render: (_id, { cancellationReason }) => cancellationReason,
          width: 160
        }
  ];

  const formatOrders = (orders) => {
    return orders?.map((order) => {
      const {
        _id,
        cod,
        receiver: { fullName, phone },
        dropOffAddress: { city, district, firstLine },
        productInfo,
        createdAt,
        creationSrc,
        cancellationReason,
        updatedAt
      } = order;
      return {
        id: _id,
        key: _id,
        title: (
          <span className="br-draft-orders__order-id-container">
            <span className="br-draft-orders__order-id">#{_id}</span>
          </span>
        ),
        cod: order.cod ? (
          <div className="br-amount-column__container">
            {Math.sign(order.cod) === -1 ? <RefundIcon /> : <CodIcon />}
            <div>
              <span className="body">
                {intl.formatMessage(
                  {
                    id: COUNTRY_CURRENCY
                  },
                  {
                    cod: order.cod
                  }
                )}
              </span>

              <span className="caption-lg">
                {intl.formatMessage({
                  id: `orders.shipment_details.shipment_details_summary.table_cells_labels.${
                    Math.sign(order.cod) === -1 ? 'refund' : 'cash_on_delivery'
                  }`
                })}
              </span>
            </div>
          </div>
        ) : (
          <div className="br-orders__no-cod-label">
            {intl.formatMessage({
              id: `common.no_cash_on_delivery`
            })}
          </div>
        ),
        items: (
          <div
            className="br-draft-orders__items"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!!productInfo.length ? (
              <Popover
                overlayClassName="br-draft-orders__products-popover"
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement.parentElement
                }
                content={<ItemsPopOver id={_id} />}
              >
                {intl.formatMessage(
                  { id: 'packaging.orders_tab.draft_orders.table.items_count' },
                  { count: getItemsQuantity(productInfo) }
                )}
              </Popover>
            ) : (
              '-'
            )}
          </div>
        ),
        customerInfo: (
          <div className="br-draft-orders__customer display-flex flex-dir-col">
            <span>{fullName}</span>
            <span className="br-how-to-shop-modal__subtitle">{phone}</span>
          </div>
        ),
        location: (
          <div className="br-draft-orders__order-location">
            <span>{firstLine ? firstLine : district?.name}</span>
            <span className="caption-lg">{city?.name}</span>
          </div>
        ),
        createdAtColumn: (
          <div className="br-draft-orders__order-date">
            <span>
              {dates(
                isCancelledFlyersTab ? updatedAt : createdAt,
                'D MMM, YYYY'
              )}
            </span>
            <span className="caption-lg">
              {dates(isCancelledFlyersTab ? updatedAt : createdAt, 'hh:mm A')}
            </span>
          </div>
        ),
        creationSrc,
        cancellationReason,
        rawData: { ...order }
      };
    });
  };

  const getItemsQuantity = (productInfo) => {
    let count = 0;
    productInfo?.map((product) => {
      count += product?.quantity;
    });
    return count;
  };

  const renderTableTitle = () =>
    selectedRows?.length ? (
      <div className="br-orders-table__print-selected-rows-container">
        <span className="br-orders-table__title-count">
          <Checkbox
            indeterminate
            onChange={() => {
              setToggleRowSelection(!toggleRowSelection);
            }}
          />
          {intl.formatMessage(
            { id: 'packaging.orders_tab.draft_orders.table.selected' },
            {
              count: selectedRows?.length
            }
          )}
        </span>
        <BRButton
          className="br-orders-table__print-selected-rows-btn"
          // type="destructive-treitary"
          onClick={(e) => handleOrderConfirm()}
          label={intl.formatMessage({ id: 'common.confirm' })}
        />

        <BRButton
          label={intl.formatMessage({ id: 'common.cancel' })}
          type="destructive-treitary"
          className="br-orders-table__title__delete-btn"
          onClick={(e) => handleDeleteClick()}
        />
      </div>
    ) : (
      intl.formatMessage(
        {
          id: `packaging.orders_tab.draft_orders.table.${
            isCancelledFlyersTab ? 'cancelled_count' : 'draft_count'
          }`
        },
        {
          count: totalOrdersCount
        }
      )
    );

  const handleSelectedRows = (rowKeys) => {
    setSelectedRows(rowKeys);
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const getTableData = async ({
    page,
    limit,
    searchInputText,
    ...filterData
  }) => {
    setIsTableLoading(true);
    setFilterValues(filterData);
    try {
      const payload = {
        ...filterData,
        cancelled: isCancelledFlyersTab,
        ...(searchInputText && { searchQuery: searchInputText })
      };
      const {
        data: { requests, totalRequestsCount }
      } = await getFlyersDraftOrder({
        page,
        limit,
        payload
      });
      setTotalOrdersCount(totalRequestsCount);
      setIsTableLoading(false);
      return {
        list: formatOrders(requests),
        total: totalRequestsCount
      };
    } catch (error) {
      notify(error.message);
    }
    setIsTableLoading(false);
  };

  const handleDeleteClick = (id) => {
    openModal(CancelFlyersOrderModal, {
      onConfirm: (values) => handleDeleteDraftOrder(id, values)
    });
  };

  const handleDeleteDraftOrder = async (id, data) => {
    setIsTableLoading(true);

    try {
      const { message } = await cancelFlyersSalesOrder({
        requestsData: id
          ? [
              {
                requestId: id,
                ...(data?.cancellationReason && {
                  cancellationReason: data?.cancellationReason
                })
              }
            ]
          : [
              ...selectedRows.map((requestId) => ({
                requestId,
                ...(data?.cancellationReason && {
                  cancellationReason: data?.cancellationReason
                })
              }))
            ]
      });
      if (!message.rejected.length) {
        notify(message.fulfilled.join(' , '), 'success');
      } else if (message.rejected.length && message.fulfilled.length) {
        notify(
          intl.formatMessage(
            {
              id: 'packaging.orders_tab.draft_orders.delete_not_all_success_message'
            },
            {
              backendMessage: message.rejected.join(' , ')
            }
          )
        );
      } else {
        notify(message.rejected.join(' , '));
      }
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsTableLoading(false);
  };

  const handleOrderConfirm = async (id) => {
    setIsTableLoading(true);
    try {
      const { message } = await confirmFlyersSalesOrder({
        requestIds: id ? [id] : selectedRows
      });
      if (!message.rejected.length) {
        notify(message.fulfilled.join(' , '), 'success');
      } else if (message.rejected.length && message.fulfilled.length) {
        notify(
          intl.formatMessage(
            {
              id: 'packaging.orders_tab.draft_orders.confirm_not_all_success_message'
            },
            {
              backendMessage: message.rejected.join(' , ')
            }
          )
        );
      } else {
        notify(message.rejected.join(' , '));
      }
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
    setIsTableLoading(false);
  };

  const getFlyersSizeList = async () => {
    try {
      let list = [];
      let payload = getflyerSizePayload() ? { free: '0' } : {};
      let data = await getFlyersList(payload);
      data?.ItemsList.forEach((element) => {
        list.push({
          label: intl.locale === LOCALE.EN ? element.title : element.title_ar,
          value: element.title
        });
      });
      setFilters([
        {
          label: intl.formatMessage({
            id: 'packaging.orders_tab.draft_orders.table.creation_date'
          }),
          menuType: 'date',
          key: 'createdAt'
        },
        {
          label: intl.formatMessage({
            id: isCancelledFlyersTab
              ? 'packaging.orders_tab.draft_orders.table.cancelation_date'
              : 'packaging.orders_tab.draft_orders.table.confirmation_date'
          }),
          menuType: 'date',
          key: isCancelledFlyersTab ? 'cancelledAt' : 'confirmedAt'
        },
        {
          label: 'Size',
          menuType: 'radio',
          menu: list,
          key: 'size'
        }
      ]);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnExportClick = async () => {
    try {
      const { message } = await exportPackages({
        ...filterValues,
        cancelled: isCancelledFlyersTab
      });
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    getFlyersSizeList();
  }, []);

  return (
    <div className="br-draft-order-page__container">
      <BRTable
        className="br-draft-orders__table"
        title={renderTableTitle()}
        isLoading={isTableLoading}
        columns={MARKETPLACE_ORDERS_COLUMNS}
        resetSelection={toggleRowSelection}
        pageSizeOptions={[20, 50]}
        pageLimit={20}
        rowKey={(record) => record.id}
        handleSelectedRows={handleSelectedRows}
        shareMethods={acceptMethods}
        allowSelection={!isCancelledFlyersTab}
        showFilter={true}
        showSearch
        searchPlaceholder={intl.formatMessage({
          id: 'packaging.orders_tab.draft_orders.table.search_placeholder'
        })}
        listFunction={getTableData}
        tableFilters={filters}
        exportListFileFunction={handleOnExportClick}
      />
    </div>
  );
};

export default DraftOrders;
