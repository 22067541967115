import http from 'utils/http';

export const createVendor = (payload) => {
  return http.post(`vendors`, payload);
};

export const editVendor = (vendorId, payload) => {
  return http.put(`vendors/${vendorId}`, payload);
};

export const searchVendor = (payload) => {
  return http.get(`/vendors/search`, payload);
};

export const getAllVenders = () => {
  return http.get(`/vendors`);
};

export const getVendor = (vendorId) => {
  return http.get(`/vendors/${vendorId}`);
};

export const deleteVendor = (vendorId) => {
  return http.delete(`/vendors/${vendorId}`);
};
