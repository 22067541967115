import http from 'utils/http';

export const getAllCashierContent = (payload) => {
  return http.get('cashier', payload);
};

export const applyAcctionOnRecord = (id, payload) => {
  return http.put(`cashier/${id}`, payload);
};

export const cashierUploadReceiptImage = (payload) => {
  return http.post(`cashier/bulk-deposit/image-upload`, payload);
};

export const cashierBulkDeposit = (payload) => {
  return http.put(`cashier/bulk-deposit`, payload);
};

export const accountantBulkApprove = (payload) => {
  return http.put(`cashier/bulk-accept-in-transit`, payload);
};

export const exportCashierPage = (payload) => {
  return http.get(`cashier/export`, payload);
};

export const accountantSettleHub = (payload) => {
  return http.put(`cashier/settle`, payload);
};
export const getCashierLogs = () => {
  return http.get(`cashier/logs`);
};

export const printCashierReceipt = (payload) => {
  return http.get(`/cashier/print-receipt`, payload);
};

export const getOrderBankTransferData = (id) => {
  return http.get(`/cashier/${id}/bank-transfer-data`);
};

export const getFinanceRecords = (payload) => {
  return http.get('/cashier/hub-page', payload);
};

export const exportFinancePage = (payload) => {
  return http.get(`cashier/finance-export`, payload);
};

export const uploadCashierSignature = (payload) => {
  return http.post(`cashier/signature-upload`, payload);
};
