import { useEffect, useRef, useState } from 'react';
import { Form, Collapse, Input, Select, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  SEARCH_VEHICLE_TYPES,
  STAR_SHORTAGE,
  STAR_STATE,
  STAR_STATUS
} from 'constants/stars';
import { getHubs } from 'services/hubs';
import { cleanEmptyString, trimObjectStrings } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './StarsSearch.less';

const StarsSearch = ({ handleOnSubmit }) => {
  const [hubs, setHubs] = useState([]);

  const formRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    fetchHubs();
  }, []);

  const fetchHubs = async () => {
    try {
      const { result } = await getHubs();
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = (values) => {
    if (values.date) {
      values.date = dayjs(values.date).format('YYYY-MM-DD');
    }

    handleOnSubmit(trimObjectStrings(cleanEmptyString(values)));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleOnSubmit({ isVerified: true, starShortage: false });
  };

  const disabledDate = (current) => {
    return current && current >= dayjs().startOf('day');
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <Icon
          component={DropDownIcon}
          rotate={isActive ? 0 : -90}
          className="ant-icon-xl"
        />
      )}
    >
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'stars.stars_listing.search_filters.title'
        })}
      >
        <Form
          onFinish={onFinish}
          ref={formRef}
          initialValues={{ isVerified: true }}
        >
          <div className="br-filters-search__row">
            <Form.Item
              name="starId"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.star_id'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'stars.stars_listing.search_filters.star_id'
                })}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.name'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'stars.stars_listing.search_filters.name'
                })}
              />
            </Form.Item>
            <Form.Item
              name="fawryUserId"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.fawry_id'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'stars.stars_listing.search_filters.fawry_id'
                })}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.email'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'stars.stars_listing.search_filters.email'
                })}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="phone"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.phone'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'stars.stars_listing.search_filters.phone'
                })}
              />
            </Form.Item>
            <Form.Item
              name="vehicle"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.vehicle'
              })}
            >
              <Select
                allowClear
                options={SEARCH_VEHICLE_TYPES}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
            <Form.Item
              name="state"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.state'
              })}
            >
              <Select
                allowClear
                options={STAR_STATE}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
            <Form.Item
              name="isVerified"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.status'
              })}
            >
              <Select
                allowClear
                options={STAR_STATUS}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="starShortage"
              label={intl.formatMessage({
                id: 'stars.stars_listing.search_filters.shortage'
              })}
            >
              <Select
                allowClear
                options={STAR_SHORTAGE}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
            <Form.Item
              name="warehouseInfo"
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.hub'
              })}
            >
              <Select
                allowClear
                showSearch
                options={hubs}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="date"
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.date'
              })}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </div>
          <div className="br-stars-search__actions">
            <div>
              <BRButton
                type="primary"
                htmlType="submit"
                label={intl.formatMessage({ id: 'common.search' })}
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleResetFilters}
              />
            </div>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default StarsSearch;
