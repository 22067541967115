import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_OUTBOUND_MAIN_PATH,
  FULFILLMENT_OUTBOUND_ROUTES
} from 'constants/fulfillment';

const FulfillmentOutbound = () => {
  return (
    <Switch>
      {FULFILLMENT_OUTBOUND_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_OUTBOUND_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentOutbound);
