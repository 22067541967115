import React from 'react';
import { connect } from 'react-redux';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import messages from 'messages';
import {
  flattenMessages,
  getLocaleFromLocalStorage,
  setLocaleInLocalStorage,
} from 'utils/intl-wrapper';
import { setLocale } from 'actions/app';

const initialLocale = 'en';
export const cache = createIntlCache();
/** You can use this variable in other files even after reassigning it. */
export let intl = createIntl(
  { locale: initialLocale, messages: flattenMessages(messages[initialLocale]) },
  cache,
);
export let fmt = intl.formatMessage;

class IntlWrapper extends React.Component {
  componentDidMount() {
    const locale = getLocaleFromLocalStorage();
    if (!locale) {
      setLocaleInLocalStorage('en');
    } else {
      const { setLocalization } = this.props;
      setLocalization(locale);
    }
  }

  render() {
    const { children, locale } = this.props;

    intl = createIntl(
      { locale, messages: flattenMessages(messages[locale]) },
      cache,
    );
    fmt = intl.formatMessage;
    document.documentElement.lang = locale;

    if (locale === 'ar') {
      document.dir = 'rtl';
    } else {
      document.dir = null;
    }

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

const mapDispatchToProps = dispatch => ({
  setLocalization: locale => dispatch(setLocale(locale)),
});

const mapStateToProps = ({ app: { locale } }) => {
  return { locale };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper);
