import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Image } from 'antd';
import dayjs from 'dayjs';

import { getOrderInfoDetails } from 'services/international-shipping-in';
import { COUNTRY_CURRENCY } from 'constants/helper';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import { notify } from 'components/Notify/Notify';

import './InternationalOrderDetails.less';

const InternationalOrderDetails = ({ intl, match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [items, setItems] = useState({});
  const [orderSummary, setOrderSummary] = useState({});
  useEffect(() => {
    const itemId = match?.params?.id;
    getItemInfo(itemId);
  }, []);
  const getItemInfo = async (itemId) => {
    setIsLoading(true);
    try {
      const { data } = await getOrderInfoDetails(itemId);
      setOrderInfo({
        providerOrderId: data.providerOrderId,
        subtotal: data?.breakdown?.subtotal
          ? `${data?.breakdown?.subtotal} ${data.currency}`
          : 'N/A',
        delivery: data?.breakdown?.delivery
          ? `${data?.breakdown?.delivery} ${data.currency}`
          : 'N/A',
        total: data?.amountInCents
          ? `${Math.round(data.amountInCents / 100)} ${data.currency}`
          : 'N/A',
        adjustments: data?.breakdown?.adjustments,
        promotion: data?.breakdown?.promotion,
        items: data.items.length,
        createdAt: data.createdAt
      });
      setShippingAddress({
        firstLine: data?.shippingAddress?.address?.firstLine || 'N/A',
        area: data?.shippingAddress?.address?.district?.name
          ? data?.shippingAddress?.address?.zone?.name
            ? `${data?.shippingAddress?.address?.district?.name} - ${data?.shippingAddress?.address?.zone?.name}`
            : data?.shippingAddress?.address?.district?.name
          : 'N/A',
        city: data?.shippingAddress?.address?.city?.name || 'N/A',
        secondLine: data?.shippingAddress?.address?.secondLine || 'N/A',
        floor: data?.shippingAddress?.address?.floor || 'N/A',
        building: data?.shippingAddress?.address?.building || 'N/A'
      });
      setItems(formatItems(data.items) || []);
      const { summary } = data;
      setOrderSummary(formatSummary(summary));
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };
  const STATUES_MAPPING = {
    in_progress: 'In Progress',
    out_for_delivery: 'Out for Delivery',
    delivered: 'Delivered'
  };
  const formatSummary = (summary) => {
    return Object.keys(summary).map((value) => ({
      state: STATUES_MAPPING[value],
      count: summary[value]
    }));
  };
  const formatItems = (items) => {
    const formattedItems = [];
    items.map((item) => {
      formattedItems.push({
        _id: item._id,
        product_image: item.extra.photo,
        item_id: item.providerItemId,
        package_id: item.providerPackageId,
        category: item.extra.categoryName,
        weight: item.extra.weight,
        price: item.providerPrice,
        status: STATUES_MAPPING[item.status],
        trackingNumber: item.lastMileTrackingNumber,
        trackingUrl: item.lastMileShipmentTrackingUrl
      });
    });
    return formattedItems;
  };

  const shippingAddressColumns = [
    {
      key: 'firstLine',
      label: 'Street name'
    },

    {
      key: 'area',
      label: 'Area'
    },
    {
      key: 'city',
      label: 'City'
    },

    {
      key: 'building',
      label: 'Building'
    },
    {
      key: 'floor',
      label: 'Floor'
    },
    {
      key: 'secondLine',
      label: 'Landmark'
    }
  ];

  const orderSummaryColumns = [
    {
      title: intl.formatMessage({
        id: 'international_shipping.summary.table_columns.status'
      }),
      dataIndex: 'state'
    },
    {
      title: intl.formatMessage({
        id: 'international_shipping.summary.table_columns.count'
      }),
      dataIndex: 'count'
    }
  ];

  const copyPackageId = (packageId) => {
    navigator.clipboard.writeText(packageId);
    notify('Package Id is copied successfully', 'success');
  };
  const columns = (intl) => {
    return [
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.product_image'
        }),
        dataIndex: 'product_image',
        render: (imageLink) => <Image src={imageLink} alt="" width={50} />,
        className: 'br-international__table-column-image'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.item_id'
        }),
        dataIndex: 'item_id'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.package_id'
        }),
        dataIndex: 'package_id',
        render: (packageId) => (
          <span
            className="br-international-shipping__package-id"
            onClick={() => copyPackageId(packageId)}
          >
            {packageId.slice(0, 6)} ...
          </span>
        )
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.category'
        }),
        dataIndex: 'category',
        render: (category) => (category ? category : 'N/A'),
        className: 'br-international__table-column'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.weight'
        }),
        dataIndex: 'weight',
        render: (weight) => (weight ? `${weight}KG` : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.price'
        }),
        dataIndex: 'price',
        render: (price) => (price ? `${price} EGP` : 'N/A'),
        className: 'br-international__table-column'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.status'
        }),
        dataIndex: 'status',
        render: (status) => (status ? status : 'N/A'),
        className: 'br-international__table-column'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.tracking_number'
        }),
        dataIndex: 'trackingNumber',
        render: (trackingNumber) => (trackingNumber ? trackingNumber : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.tracking_url'
        }),
        dataIndex: 'trackingUrl',
        render: (trackingUrl) =>
          trackingUrl ? (
            <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
              Click Here
            </a>
          ) : (
            'N/A'
          )
      }
    ];
  };

  const orderInfoColumns = [
    {
      key: 'providerOrderId',
      label: 'PayMob Order ID'
    },
    {
      key: 'shippingProviderOrderId',
      label: 'ExoHub Order ID'
    },
    {
      key: 'subtotal',
      label: 'Subtotal'
    },
    {
      key: 'delivery',
      label: 'Delivery'
    },
    {
      key: 'adjustments',
      label: intl.formatMessage({
        id: `international_shipping.summary.table_columns.international_${
          orderInfo.adjustments > 0 ? 'fees' : 'discount'
        }`
      }),
      render: () =>
        intl.formatMessage(
          {
            id: !orderInfo.adjustments ? 'common.empty_field' : COUNTRY_CURRENCY
          },
          {
            cod: orderInfo.adjustments
          }
        )
    },
    {
      key: 'promotion',
      label: intl.formatMessage({
        id: 'international_shipping.summary.table_columns.promotion_discount'
      }),
      render: () =>
        intl.formatMessage(
          {
            id: orderInfo.promotion ? COUNTRY_CURRENCY : 'common.empty_field'
          },
          {
            cod: orderInfo.promotion?.amount
          }
        )
    },
    {
      key: 'total',
      label: 'Total'
    },
    {
      key: 'items',
      label: 'Items count'
    },
    {
      key: 'createdAt',
      label: 'Created at',
      render: (date) =>
        date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
    }
  ];

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'international_shipping.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-international__address-container">
            <p className="br-iitem-details__header">
              Order : {orderInfo.providerOrderId}
            </p>

            <div className="br-completed-order-items__container">
              <span>Order Info</span>
              <BRInformationTable
                cells={orderInfoColumns}
                totalCol={3}
                dataSource={orderInfo}
              />
            </div>

            <div className="br-completed-order-items__container">
              <span>Address Details</span>
              <BRInformationTable
                cells={shippingAddressColumns}
                totalCol={3}
                dataSource={shippingAddress}
              />
            </div>

            <div className="br-completed-order-items__container">
              <span>Items Info</span>
              <BRSearchableTable
                className="br-completed-order-items__container__summary-table"
                listData={orderSummary}
                columns={orderSummaryColumns}
                onRowClick={() => {}}
                withOutCheckBoxes
                hidePagination
              />
            </div>
            <BRSearchableTable
              title={intl.formatMessage(
                {
                  id: 'international_shipping.item_count'
                },
                { count: items.length }
              )}
              listData={items}
              columns={columns(intl)}
              onRowClick={() => {}}
              withOutCheckBoxes
              hidePagination
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};
export default injectIntl(withRouter(InternationalOrderDetails));
