import http from 'utils/http.js';
import axios from 'axios';

export const fetchCyclesOrders = (payload, businessId) => {
  return http.get(`/wallet/cycles/${businessId}`, payload);
};

export const fetchCyclesCards = (payload, businessId) => {
  return http.get(`/wallet/cycles/${businessId}/cards`, payload);
};

export const exportCycles = (payload, businessId) => {
  return http.post(`/wallet/cycles/${businessId}/xlsx`, payload);
};

export const fetchBalanceTransactions = (businessId, payload) => {
  return http.get(`/wallet/transactions/${businessId}`, payload);
};

export const fetchBalanceCards = (businessId) => {
  return http.get(`wallet/transactions/${businessId}/cards`);
};

export const exportTransactions = (businessId, payload) => {
  return http.get(`/wallet/transactions/${businessId}/xlsx`, payload);
};

export const exportCompensation = (payload, businessId) => {
  return http.get(`/wallet/compensation/${businessId}/xlsx`, payload);
};

export const getBalanceReceipt = ({ transactionId, category, businessId }) => {
  return http.get(`/wallet/receipt/${businessId}/${transactionId}/${category}`);
};

export const generateOTP = (id) => {
  return http.post(`/wallet/generate-otp`);
};

export const confirmOTP = (payload) => {
  return http.post(`/wallet/confirm-otp`, payload);
};

export const checkOTP = () => {
  return http.get(`/wallet/check-otp`);
};

export const uploadAdjustments = (payload) => {
  return http.post(`/wallet/adjustment-transaction`, payload);
};

export const uploadBusinessConfig = (payload) => {
  return http.get(`/wallet/business-config`, payload);
};

export const uploadSheetToGoogleBucket = (url, file) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': `${file.type}`
    }
  });
};

export const updateUploadSuccess = (payload) => {
  return http.put(`/wallet/business-config?sheetName=${payload}`);
};

export const exportBusinessConfigSheet = (payload) => {
  return http.get(`/businesses/payment-config-xlsx`);
};

export const exportCashoutsSheet = (payload) => {
  return http.get(`/wallet/cashouts/export/xlsx`, payload);
};

export const exportBalancesSheet = (payload) => {
  return http.get(`/wallet/balances/export/xlsx`, payload);
};

export const createCompensationRecord = (payload) => {
  return http.post(`/wallet/compensation/request`, payload);
};

export const getCompensationsRequests = (payload) => {
  return http.get(`/wallet/compensation/requests`, payload);
};

export const approveCompensatinRequest = (id) => {
  return http.put(`/wallet/compensation/requests/approve/${id}`);
};

export const rejectCompensatinRequest = (id, payload) => {
  return http.put(`/wallet/compensation/requests/reject/${id}`, payload);
};

export const approveCompensationBulk = (payload) => {
  return http.put(`/wallet/compensation/requests/approve`, payload);
};

export const rejectCompensationBulk = (payload) => {
  return http.put(`/wallet/compensation/requests/reject`, payload);
};

export const getSearchCompensationRecord = (payload) => {
  return http.get(`/wallet/compensation/requests/search`, payload);
};

export const exportCompensationRecords = (payload) => {
  return http.get(`/wallet/compensation/requests/export/xlsx`, payload);
};

export const exportBalanceConfirmedOrder = (payload) => {
  return http.get(`/wallet/transactions/xlsx`, payload);
};

////////////////////////  International    ///////////////////////

export const fetchInternationalCyclesCards = ({ businessId, payload }) => {
  return http.get(`wallet/international/cycles/${businessId}/cards`, payload);
};

export const fetchInternationalCyclesOrders = ({ payload, businessId }) => {
  return http.get(`/wallet/international/cycles/${businessId}`, payload);
};

export const exportInternationalCycles = ({ payload, businessId }) => {
  return http.post(
    `/wallet/international/cycles/${businessId}/export`,
    payload
  );
};

export const fetchInternationalBalanceTransactions = ({
  businessId,
  payload
}) => {
  return http.get(`/wallet/international/transactions/${businessId}`, payload);
};

export const fetchInternationalBalanceCards = (businessId) => {
  return http.get(`wallet/international/transactions/${businessId}/cards`);
};

export const exportInternationalTransactions = ({ businessId, payload }) => {
  return http.post(
    `/wallet/international/transactions/${businessId}/export`,
    payload
  );
};

export const getInternationalBalanceReceipt = ({
  transactionId,
  businessId
}) => {
  return http.get(
    `/wallet/international/transactions/${businessId}/receipt/${transactionId}`
  );
};
