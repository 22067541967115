import { useEffect, useRef } from 'react';

import BRTable from 'components/BRTable/BRTable';

import './BRSwappingTables.less';

const SwapTable = ({ tableDataSource = [], tableProps }) => {
  const tableRef = useRef('');

  const acceptMethods = (refreshMethod) => {
    tableRef.current = refreshMethod;
  };

  const refreshTable = () => {
    tableRef.current();
  };

  useEffect(() => {
    refreshTable();
  }, [tableDataSource]);

  const getTableData = () => ({
    list: tableDataSource
  });

  return (
    <BRTable
      pagination={false}
      showFilter={false}
      scroll={false}
      listFunction={getTableData}
      shareMethods={acceptMethods}
      {...tableProps}
    />
  );
};

const BRSwappingTables = ({
  leftTableDataSource = [],
  rightTableDataSource = [],
  leftTableProps,
  rightTableProps
}) => {
  return (
    <div className="br-swapping-tables__container display-flex flex-equal-children">
      <div className="br-swapping-tables-left-table__container">
        <SwapTable
          tableDataSource={leftTableDataSource}
          tableProps={leftTableProps}
        />
      </div>
      <div className="br-swapping-tables-right-table__container">
        <SwapTable
          tableDataSource={rightTableDataSource}
          tableProps={rightTableProps}
        />
      </div>
    </div>
  );
};

export default BRSwappingTables;
