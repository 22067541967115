import { useContext, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'antd';
import dayjs from 'dayjs';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import {
  getInventorySerials,
  exportInventorySerials
} from 'services/fulfillment-inventory-serials';
import {
  SO_KEY,
  getSerialForScan,
  getSkuForScan
} from 'constants/fulfillment-sales-orders';
import {
  getSerialStateId,
  INVENTORY_SERIALS_SEARCH_OPTIONS,
  INVENTORY_SERIALS_SEARCH_OPTIONS_IDS,
  renderSerialStateTag,
  formatDatesForPayload,
  checkIfSerialHasIssue,
  SERIAL_STATE_IDS
} from 'constants/fulfillment-inventory-serials';
import { PO_KEY } from 'constants/fulfillment';
import { TABLE_PAGE_SIZE_OPTIONS } from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import {
  RETURN_ORDER_TYPES_NAMES,
  RO_KEY
} from 'constants/fulfillment-return-orders';
import { PJ_KEY } from 'constants/fulfillment-problematic-jobs';
import { RTV_KEY } from 'constants/fulfillment-return-to-vendor';
import { renderDate } from 'constants/fulfillment-common';
import { downloadAsXlsx } from 'utils';
import { SllrWrapper } from 'contexts/sllr.context';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import SerialLocationTooltip from 'components/NewFulfillment/FulfillmentInventorySerials/InventorySerialsList/components/SerialLocationTooltip/SerialLocationTooltip';

import { ReactComponent as ActionsIcon } from 'assets/bosta-icons/More-Actions.svg';

const InventorySerialsTable = ({
  intl,
  tableFilters,
  isFirstFetchDone,
  setIsFirstFetchDone,
  setSelectedRows,
  actionMenuItems
}) => {
  const { setIsLoading } = useContext(SllrWrapper);
  const [tableItemsCount, setTableItemsCount] = useState(0);
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    INVENTORY_SERIALS_SEARCH_OPTIONS_IDS.SERIAL.value
  );
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const refreshTable = useRef();

  const { IN_STOCK } = SERIAL_STATE_IDS;

  const columns = [
    {
      dataIndex: 'serialNumber',
      title: intl.formatMessage({
        id: 'fulfillment_common.serial'
      }),
      width: 130,
      fixed: 'left'
    },
    {
      dataIndex: 'businessName',
      title: intl.formatMessage({
        id: 'fulfillment_common.business_name'
      }),
      width: 150,
      ellipsis: true
    },
    {
      dataIndex: 'productSku',
      title: intl.formatMessage({
        id: 'fulfillment_common.sku'
      }),
      width: 100
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_common.product_name'
      }),
      width: 160,
      ellipsis: true,
      render: ({ productName, productNameAr }) => productName || productNameAr
    },
    {
      dataIndex: 'id',
      title: intl.formatMessage({
        id: 'fulfillment_common.location'
      }),
      width: 100,
      render: (id) => <SerialLocationTooltip serialId={id} />
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_common.status'
      }),
      width: 120,
      render: (serial) => renderSerialStateTag(getSerialStateId(serial))
    },
    {
      dataIndex: 'poId',
      title: intl.formatMessage({
        id: 'fulfillment_common.po'
      }),
      width: 100,
      render: (poId) =>
        poId &&
        intl.formatMessage(
          {
            id: 'fulfillment_common.po_id'
          },
          {
            poId
          }
        )
    },
    {
      dataIndex: 'soId',
      title: intl.formatMessage({
        id: 'fulfillment_common.so'
      }),
      width: 100,
      render: (soId) =>
        soId &&
        intl.formatMessage(
          {
            id: 'fulfillment_common.so_id'
          },
          {
            soId
          }
        )
    },
    {
      dataIndex: 'trackingNumber',
      title: `${intl.formatMessage({
        id: 'fulfillment_common.tracking_number'
      })}#`,
      width: 100
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_common.ro'
      }),
      width: 150,
      render: ({ roId, RoType }) =>
        roId && (
          <div>
            <span>
              {intl.formatMessage(
                {
                  id: 'fulfillment_common.ro_id'
                },
                {
                  roId
                }
              )}
            </span>
            <div className="text-gray">{RETURN_ORDER_TYPES_NAMES[RoType]}</div>
          </div>
        )
    },
    {
      dataIndex: 'pjId',
      title: intl.formatMessage({
        id: 'fulfillment_common.pj'
      }),
      width: 100,
      render: (pjId) =>
        pjId &&
        intl.formatMessage(
          {
            id: 'fulfillment_common.pj_id'
          },
          {
            pjId
          }
        )
    },
    {
      dataIndex: 'rtvId',
      title: intl.formatMessage({
        id: 'fulfillment_common.rtv'
      }),
      width: 100,
      render: (rtvId) =>
        rtvId &&
        intl.formatMessage(
          {
            id: 'fulfillment_common.rtv_id'
          },
          {
            rtvId
          }
        )
    },
    {
      dataIndex: 'vendorSerial',
      title: intl.formatMessage({
        id: 'fulfillment_common.vendor_serial'
      }),
      width: 150,
      ellipsis: true
    },
    {
      dataIndex: 'putAwayAt',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.received_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'SoCreationDate',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.so_created_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'transferredAt',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.transferred_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'RoCreationDate',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.ro_created_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'damagedAt',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.damaged_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'lostAt',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.lost_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      dataIndex: 'rtvAt',
      title: intl.formatMessage({
        id: 'fulfillment_common.dates.rtv_at'
      }),
      width: 150,
      render: renderDate
    },
    {
      width: 80,
      render: (serial) => {
        const menu = actionMenuItems({
          serials: [serial],
          hasIssue: checkIfSerialHasIssue({
            stateId: getSerialStateId(serial)
          }),
          isInStock: getSerialStateId(serial) === IN_STOCK
        });

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <ActionsIcon className={menu ? 'clickable' : 'dimmed'} />
          </Dropdown>
        );
      }
    }
  ];

  const handleGetTableData = async ({ page, limit, searchInputText }) => {
    setIsLoading(true);
    let value = searchInputText?.trim();
    const { SERIAL, SKU, PO, SO, RO, PROBLEMATIC, RTV } =
      INVENTORY_SERIALS_SEARCH_OPTIONS_IDS;

    switch (selectedSearchOption) {
      case SERIAL.value:
        value = getSerialForScan(value);
        break;

      case SKU.value:
        value = getSkuForScan(value);
        break;

      case PO.value:
        value = value?.replace(PO_KEY, '');
        break;

      case SO.value:
        value = value?.replace(SO_KEY, '');
        break;

      case RO.value:
        value = value?.replace(RO_KEY, '');
        break;

      case PROBLEMATIC.value:
        value = value?.replace(PJ_KEY, '');
        break;

      case RTV.value:
        value = value?.replace(RTV_KEY, '');
        break;

      default:
        break;
    }
    setSelectedRows([]);
    setSearchValue(value);
    const { datesFilter, ...restFilters } = tableFilters;

    const payload = omitBy(
      {
        page,
        limit,
        ...formatDatesForPayload(datesFilter),
        ...restFilters
      },
      isNull
    );

    if (value) {
      payload[
        INVENTORY_SERIALS_SEARCH_OPTIONS_IDS[selectedSearchOption].apiKey
      ] = value;
    }

    setSharedPayload(payload);
    setIsFirstFetchDone(true);

    try {
      const {
        data: { serials = [], count = 0 }
      } = await getInventorySerials(payload);
      setTableItemsCount(count);
      setIsLoading(false);

      return {
        list: serials,
        total: count
      };
    } catch (error) {
      notify(error.message);
      setTableItemsCount(0);
      setIsLoading(false);
    }
  };

  const handleExportData = async () => {
    setIsLoading(true);

    try {
      const data = await exportInventorySerials(sharedPayload);
      const fileName = `Inventory_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (isFirstFetchDone && refreshTable?.current) {
      updateTable();
    }
  }, [tableFilters]);

  return (
    <BRTable
      className="br-fulfillment-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_inventory_serials.table.title'
        },
        {
          count: tableItemsCount
        }
      )}
      columns={columns}
      listFunction={handleGetTableData}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage(
        {
          id: 'fulfillment_returns.return_orders_table.search_placeholder'
        },
        {
          searchBy:
            INVENTORY_SERIALS_SEARCH_OPTIONS_IDS[selectedSearchOption].label
        }
      )}
      exportListFileFunction={handleExportData}
      showFilter={false}
      showSearch
      searchOptions={INVENTORY_SERIALS_SEARCH_OPTIONS}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
      rowKey="id"
      allowSelection
      handleSelectedRowsData={setSelectedRows}
      allowUserUsedSearchOnErrors
    />
  );
};

export default injectIntl(InventorySerialsTable);
