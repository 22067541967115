import { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { getBusinesses } from 'services/fulfillment';
import { BusinessesContext } from 'contexts/fulfilment.context';

import { notify } from 'components/Notify/Notify';
import BRTabs from 'components/BRTabs/BRTabs';
import Header from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import Inventory from 'components/Fulfillment/Inventory/Inventory';
import InventoryRequests from 'components/Fulfillment/InventoryRequests/InventoryRequests';
import RequestDetails from 'components/Fulfillment/InventoryRequests/components/RequestDetails/RequestDetails';

import './FulfillmentContainer.less';

const DEFAULT_TAB_PATH = 'products';

const FulfillmentContainer = ({ intl, match: { path } }) => {
  const [businesses, setBusinesses] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const TABS = {
    draft: {
      label: intl.formatMessage({ id: 'fulfillment.tabs.products' }),
      component: Inventory,
      path: '/inventory/products'
    },
    tracking: {
      label: intl.formatMessage({ id: 'fulfillment.tabs.inventory_requests' }),
      component: InventoryRequests,
      path: '/inventory/requests'
    }
  };

  async function fetchBusinesses() {
    try {
      setIsPageLoading(true);
      const businesses = await getBusinesses();
      setBusinesses(businesses);
    } catch (error) {
      notify(error.message);
    }
    setIsPageLoading(false);
  }

  useEffect(() => {
    fetchBusinesses();
  }, []);

  return (
    <Switch>
      <Route
        path={['/inventory/products', '/inventory/requests']}
        exact
        render={() => (
          <Container
            header={
              <Header
                title={intl.formatMessage({
                  id: 'fulfillment.inventory.title'
                })}
              />
            }
            className="br-fulfillment-container__content"
            content={
              <BusinessesContext.Provider value={{ businesses, isPageLoading }}>
                <BRTabs tabs={TABS} />
              </BusinessesContext.Provider>
            }
          />
        )}
      />
      <Route
        path="/inventory/requests/:bussinessId/:id"
        exact
        render={() => <RequestDetails />}
      />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default withRouter(injectIntl(FulfillmentContainer));
