import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button, Select } from 'antd';

import 'containers/Route/RTOModal/RTOModal.less';

const { Option } = Select;

const AssigneeModal = ({
  intl,
  close,
  modalTitle,
  dropdownPlaceholder,
  dropdownData,
  onConfirm,
  isLoading,
  ...rest
}) => {
  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const renderedOptions = dropdownData?.map((option, index) => (
    <Option key={index} value={option.value || option}>
      {option.label}
    </Option>
  ));

  const handleSave = async () => {
    await onConfirm(selectedAssignee.value, selectedAssignee.label);
    handleCancel();
  };

  const handleCancel = () => {
    setSelectedAssignee(null);
    close();
  };

  return (
    <Modal
      {...rest}
      title={null}
      footer={null}
      onCancel={handleCancel}
      centered
    >
      <div className="br-rto-modal_title">{modalTitle}</div>
      <div className="br-rto-modal_subtitle">
        {intl.formatMessage({
          id: 'hubs.rto_modal.assignee_modal.subtitle'
        })}
      </div>
      <Select
        labelInValue
        showSearch
        size="large"
        className="br-rto-modal_assignee-dropdown"
        placeholder={dropdownPlaceholder}
        value={selectedAssignee}
        optionFilterProp="children"
        loading={isLoading}
        disabled={isLoading}
        onSelect={setSelectedAssignee}
        filterOption={(input, { children }) =>
          children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {renderedOptions}
      </Select>
      <div className="br-rto-modal_footer">
        <Button
          className="br-rto-modal_footer_btn br-rto-modal_rounded"
          onClick={handleCancel}
        >
          {intl.formatMessage({
            id: `hubs.rto_modal.assignee_modal.cancel_btn_text`
          })}
        </Button>
        <Button
          className="br-rto-modal_footer_btn br-rto-modal_rounded"
          type="primary"
          onClick={handleSave}
          disabled={!selectedAssignee}
          loading={isLoading}
        >
          {intl.formatMessage({
            id: `hubs.rto_modal.assignee_modal.confirm_btn_text`
          })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(AssigneeModal);
