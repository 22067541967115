import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';

const AddProductBtn = ({ intl, type = 'primary', className = '' }) => {
  const goToProducts = () => {
    window.open('/fulfillment/products', '_self');
  };

  return (
    <BRButton
      className={className}
      type={type}
      label={intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.select_products_modal.add_product'
      })}
      prefixIcon={<PlusIcon />}
      onClick={goToProducts}
    />
  );
};

export default injectIntl(AddProductBtn);
