import React, { useState, useRef } from 'react';
import { Button, Collapse, Form, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';

import { COUNTRIES_MAPPING, EG } from 'constants/international-shipping';
import {
  fetchInternationalFees,
  addCategory,
  deleteCategory,
  updateCategory
} from 'services/international-shipping-in';
import { openModal } from 'utils/modal';
import difference from 'utils/business';
import { isUserAuthorized } from 'utils/helpers';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import AddCategoryModal from './components/AddCategoryModal';
import { notify } from 'components/Notify/Notify';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';

import { ReactComponent as TrashIcon } from 'assets/imgRevamp/trash.svg';

import './InternationalShippingSettings.less';

const InternationalShippingSettings = ({ intl }) => {
  const { Panel } = Collapse;
  const canEditOrAdd = isUserAuthorized(['SUPER_ADMIN', 'OPERATIONS_MANAGER']);

  const [open, setOpen] = useState([]);
  const [routeId, setRouteId] = useState();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const refreshTable = useRef('');

  const isEditing = ({ name }) => name === editingKey;

  const edit = ({ name, fixedFees, feesPer100gm, deliverySLA }) => {
    form.setFieldsValue({
      fixedFees: fixedFees,
      feesPer100gm: feesPer100gm,
      deliverySLA: deliverySLA
    });
    setEditingKey(name);
  };

  const handleCollapseOpen = (index) => {
    const currentOpenPanel = [`${index}`];
    open[0] === currentOpenPanel[0] ? setOpen([]) : setOpen(currentOpenPanel);
    setEditingKey('');
  };

  const handleAddCategoryClick = () => {
    openModal(AddCategoryModal, {
      onSuccess: addInternationalCategory
    });
  };

  const handleEditCategory = async ({ name, ...restValues }) => {
    const newValues = form.getFieldsValue();
    const changes = difference(newValues, { ...restValues });
    if (!!Object.keys(changes).length) {
      try {
        const payload = {
          category: { name, ...newValues }
        };
        await updateCategory(payload, routeId);
        notify(
          intl.formatMessage(
            {
              id: 'settings.international_shipping.edit_category_success'
            },
            {
              name
            }
          ),
          'success'
        );
        refreshTable.current();
      } catch (err) {
        notify(err.message);
      }
    }
    setEditingKey('');
  };

  const handleDeleteCategoryClick = (record) => {
    openModal(BRConfirmationModal, {
      onConfirm: () => {
        handleDeleteCategory(record);
      },
      confirmButtonText: intl.formatMessage({
        id: 'common.delete'
      }),
      title: intl.formatMessage({
        id: 'settings.international_shipping.confirm_delete'
      })
    });
  };

  const handleDeleteCategory = async (record) => {
    try {
      const payload = {
        category: record
      };
      await deleteCategory(payload, routeId);
      refreshTable.current();
    } catch (err) {
      notify(err.message);
    }
    setEditingKey('');
  };

  const EditableCell = ({
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const editing = record ? isEditing(record) : false;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: intl.formatMessage(
                  {
                    id: 'settings.international_shipping.edit_category_error'
                  },
                  {
                    title
                  }
                )
              }
            ]}
          >
            <InputNumber />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'settings.international_shipping.table_columns.product_category'
      }),
      dataIndex: 'name'
    },
    {
      title: intl.formatMessage({
        id: 'settings.international_shipping.table_columns.fees'
      }),
      dataIndex: 'fixedFees',
      render: (fees) => `${fees}`,
      editable: true
    },
    {
      title: intl.formatMessage({
        id: 'settings.international_shipping.table_columns.cost'
      }),
      dataIndex: 'feesPer100gm',
      editable: true
    },
    {
      title: intl.formatMessage({
        id: 'settings.international_shipping.table_columns.delivery'
      }),
      dataIndex: 'deliverySLA',
      render: (deliverySLA) =>
        intl.formatMessage(
          {
            id: 'settings.international_shipping.table_columns.business_days'
          },
          {
            deliverySLA
          }
        ),
      editable: true
    },
    canEditOrAdd
      ? {
          title: intl.formatMessage({
            id: 'settings.international_shipping.table_columns.actions'
          }),
          dataIndex: 'actions',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <div className="display-flex">
                <Button
                  className="br-is-settings__table__save-button"
                  onClick={() => handleEditCategory(record)}
                >
                  {intl.formatMessage({
                    id: 'common.save'
                  })}
                </Button>
                <Button
                  className="br-is-settings__table__delete-button"
                  icon={<TrashIcon />}
                  onClick={() => {
                    handleDeleteCategoryClick(record);
                  }}
                ></Button>
              </div>
            ) : (
              <Button
                className="br-is-settings__table__edit-button"
                disabled={!!editingKey.length}
                onClick={() => edit(record)}
              >
                {intl.formatMessage({
                  id: 'common.edit'
                })}
              </Button>
            );
          }
        }
      : {}
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title
      })
    };
  });

  const getCountryCategories = async () => {
    try {
      const payload = {
        from: open[0],
        to: EG
      };
      const { data } = await fetchInternationalFees(payload);
      setRouteId(data[0]?._id);
      return {
        list: data[0]?.categories
      };
    } catch (err) {
      notify(err.message);
    }
  };

  const addInternationalCategory = async (payload) => {
    try {
      await addCategory(payload);
      notify(
        intl.formatMessage({
          id: 'settings.international_shipping.modal.add_category_success'
        }),
        'success'
      );
      !!open.length && refreshTable.current();
    } catch (err) {
      notify(err.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  return (
    <div className="br-is-settings">
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.international_shipping.title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.international_shipping.subtitle'
        })}
        isInternalComponent
      />
      {canEditOrAdd && (
        <div className="br-is-settings__card">
          <span>
            {intl.formatMessage({
              id: 'settings.international_shipping.card.manage_categories'
            })}
          </span>
          <Button onClick={handleAddCategoryClick}>
            {intl.formatMessage({
              id: 'settings.international_shipping.card.add_category'
            })}
          </Button>
        </div>
      )}
      {Object.values(COUNTRIES_MAPPING).map(({ label, value }) => (
        <Collapse
          className="br-is-settings__collapse"
          accordion
          activeKey={open}
          destroyInactivePanel
          key={value}
        >
          <Panel
            key={value}
            header={
              <div className="">
                <span>{label}</span>
                <Button
                  onClick={() => {
                    handleCollapseOpen(value);
                  }}
                >
                  {intl.formatMessage({
                    id: canEditOrAdd
                      ? 'common.edit'
                      : 'settings.international_shipping.view'
                  })}
                </Button>
              </div>
            }
          >
            <Form form={form} component={false}>
              <BRSearchableTable
                className="br-is-settings__table"
                customColumns={mergedColumns}
                components={{
                  body: {
                    cell: EditableCell
                  }
                }}
                listFunction={getCountryCategories}
                onRowClick={() => {}}
                rowClassName="editable-row"
                rowKey="name"
                withOutCheckBoxes
                hidePagination
                shareMethods={acceptMethods}
              />
            </Form>
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};

export default injectIntl(InternationalShippingSettings);
