import { put, takeLatest } from 'redux-saga/effects';
import actionTypes from '../actions/ActionTypes';
import {
  getStarsByAvailabilityState,
  getCashRecords,
} from '../actions/StarAction';

/**
 * fetch all online stars
 * @returns {?}
 */
function* fetchAllStarsFiltered({ data }) {
  let results = { data: [] };
  const res = yield getStarsByAvailabilityState(data);
  if (res) {
    results = { data: res };
  }
  yield put({
    type: actionTypes.GET_STARS_FILTERED_RECEIVED,
    payload: results,
  });
}
function* fetchWallet(pageData) {
  let results = [];
  const data = yield getCashRecords(pageData.data);
  if (data) {
    results = data;
  }
  yield put({ type: actionTypes.GET_WALLAT_RECEIVED, payload: results.data });
}

export default function* starWatchers() {
  yield takeLatest(actionTypes.GET_STARS_FILTERED, fetchAllStarsFiltered);
  yield takeLatest(actionTypes.GET_WALLAT, fetchWallet);
}
