import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Modal, Select, Form, Input, Tag, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { phoneNumberRule } from 'utils/forms';
import { isUserAuthorized, isVendor } from 'utils/helpers';
import { CUSTOMER_SUPPORT } from 'common/aclMatrix';
import { getAreasInfo } from 'services/cities';
import { editOrder } from 'services/shipments';
import { fetchCities } from 'services/cities';
import { FIRST_LINE_MIN_LENGTH } from 'constants/shipments';

import BRArea, { AVAILABILITY } from 'components/BRArea/BRArea';
import BRFormInputPhoneNumber from 'components/BRFormInputPhoneNumber/BRFormInputPhoneNumber';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import { notify } from 'components/Notify/Notify';

import './EditCustomerDetailsModal.less';

const EditCustomerDetailsModal = ({
  intl,
  visible,
  close,
  delivery,
  onSuccess,
  initialValues,
  customerAddressName
}) => {
  const { Option } = Select;
  const formRef = useRef(null);
  const [areasLoading, setAreasLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [cities, setCitites] = useState([]);
  const handleOnChangeForm = (name, value, parent) => {
    formRef.current.setFieldsValue({
      [parent]: { [name]: value }
    });
    // formRef.current.setFieldsValue({ [name]: value });
  };
  const resetDistrictField = () => {
    if (formRef) {
      formRef.current.setFieldsValue({
        [customerAddressName]: { districtId: null }
      });
    }
  };

  const handleTicketNumberChange = (name, value, parent) => {
    formRef.current.setFieldsValue({
      [name]: value
    });
  };
  const getCities = async () => {
    try {
      const cities = await fetchCities();
      setCitites(cities);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    if (delivery) {
      getCities();
      const customerAddress = [
        ORDER_TYPES_VALUE.CRP,
        ORDER_TYPES_VALUE.CASH_COLLECTION
      ].includes(delivery.type)
        ? 'pickupAddress'
        : 'dropOffAddress';
      if (delivery[customerAddress]?.city?._id) {
        handleCityChange(delivery[customerAddress]?.city?._id);
      }
    }
  }, [delivery]);

  const handleCityChange = async (cityId) => {
    try {
      setAreasLoading(true);
      const areas = await getAreasInfo(cityId);
      setAreas(areas);
    } catch (error) {
      notify(error.message);
    }
    setAreasLoading(false);
  };

  const handleOnFinish = async (values) => {
    try {
      await editOrder({
        id: delivery._id,
        updateData: values
      });
      notify('Your updates has been saved successfully.', 'success');
      close();
      onSuccess();
    } catch (e) {
      notify(e.message);
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={close}
      className="br-reschedule-modal__container"
      okText="Update Order"
      okType=""
      okButtonProps={{
        className: 'br-reschdule-modal__primary-button',
        htmlType: 'submit',
        form: 'editDeliveryModalForm'
      }}
    >
      <div>
        <div className="br-reschedule-modal__header">
          <span>Edit Customer Details</span>
          <CloseOutlined onClick={close} />
        </div>
        {initialValues && (
          <Form
            scrollToFirstError
            ref={formRef}
            initialValues={initialValues}
            className="br-edit-delivery-modal__form-container"
            name="editDeliveryModalForm"
            onFinish={handleOnFinish}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="updatingTicketNumber"
                  label={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.ticket_number'
                  })}
                >
                  <BRFormInputNumber
                    filedName="updatingTicketNumber"
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.ticket_number'
                    })}
                    handleOnChangeForm={handleTicketNumberChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name={['receiver', 'fullName']}
                  label="Customer Name"
                >
                  <Input
                    maxLength={50}
                    autoComplete={`${Math.random()}`}
                    disabled={isUserAuthorized([CUSTOMER_SUPPORT])}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name={['receiver', 'phone']}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.phone'
                  })}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      label: intl.formatMessage({
                        id: 'contact_form_labels.phone'
                      }),
                      required: true
                    },
                    phoneNumberRule({
                      message: intl.formatMessage({
                        id: 'form.phone_not_valid'
                      }),
                      internationlNumbers: true
                    })
                  ]}
                >
                  <BRFormInputPhoneNumber
                    placeholder={intl.formatMessage({
                      id: 'form.international_phone_placeholder'
                    })}
                    filedName="phone"
                    parent="receiver"
                    isWithCounter
                    handleOnChangeForm={handleOnChangeForm}
                    autoComplete={`${Math.random()}`}
                    disabled={isVendor()}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={['receiver', 'secondPhone']}
                  label="Second Phone"
                  validateTrigger="onBlur"
                  rules={[
                    phoneNumberRule({
                      message: intl.formatMessage({
                        id: 'form.phone_not_valid'
                      }),
                      landlineNumbers: true
                    })
                  ]}
                >
                  <BRFormInputPhoneNumber
                    placeholder={intl.formatMessage({
                      id: 'form.international_phone_placeholder'
                    })}
                    filedName="secondPhone"
                    parent="receiver"
                    handleOnChangeForm={handleOnChangeForm}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="br-reschedule-modal__header__customer-address">
              <span>Customer Address</span>
            </div>
            <Row>
              <Col span={24}>
                <div className="br-form-row br-form-row__city-area">
                  <Form.Item
                    name={[customerAddressName, 'cityCode']}
                    label={intl.formatMessage({
                      id: 'location_form_labels.city'
                    })}
                    rules={[{ required: true }]}
                  >
                    <Select
                      dropdownClassName="br-customer-details__city-dropdown"
                      dropdownStyle={{ minWidth: null }}
                      placeholder={intl.formatMessage({
                        id: `form.select_placeholder`
                      })}
                      showSearch
                      filterOption={(input, option) =>
                        option.children.props.children[0]
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        handleCityChange(value);
                        resetDistrictField();
                      }}
                      autoComplete={`${Math.random()}`}
                    >
                      {cities.map((city) => (
                        <Select.Option
                          disabled={!city?.dropOffAvailability}
                          key={city._id}
                          value={city._id}
                        >
                          <div
                            className={classnames(
                              'display-flex justify-space-between',
                              {
                                'br-uncovered-area': !city?.dropOffAvailability
                              }
                            )}
                          >
                            {city?.name}
                            {!city?.dropOffAvailability && (
                              <Tag className="ant-tag-light-gray">
                                {intl.formatMessage({
                                  id: 'form.uncovered_zone'
                                })}
                              </Tag>
                            )}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <BRArea
                    name={[customerAddressName, 'districtId']}
                    availability={AVAILABILITY.DROPOFF}
                    areas={areas}
                    loading={areasLoading}
                    disabled={
                      !formRef.current?.getFieldValue([
                        customerAddressName,
                        'cityCode'
                      ])
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={[customerAddressName, 'firstLine']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.street'
                  })}
                  rules={[
                    {
                      required: true,
                      label: intl.formatMessage({
                        id: 'location_form_labels.street'
                      })
                    },
                    {
                      min: FIRST_LINE_MIN_LENGTH,
                      message: intl.formatMessage(
                        {
                          id: 'form.field_min_length'
                        },
                        {
                          label: intl.formatMessage({
                            id: 'location_form_labels.address_details'
                          }),
                          count: FIRST_LINE_MIN_LENGTH
                        }
                      )
                    }
                  ]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.street_placeholder'
                    })}
                    maxLength={250}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[11, 0]}>
              <Col span={8}>
                <Form.Item
                  name={[customerAddressName, 'buildingNumber']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.building'
                  })}
                  rules={[{ max: 25 }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.building_placeholder'
                    })}
                    maxLength={25}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={[customerAddressName, 'floor']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.floor'
                  })}
                  rules={[
                    {
                      max: 25,
                      label: intl.formatMessage({
                        id: 'location_form_labels.floor'
                      })
                    }
                  ]}
                >
                  <Input
                    maxLength={25}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.floor_placeholder'
                    })}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={[customerAddressName, 'apartment']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.apartment'
                  })}
                  rules={[
                    {
                      max: 25,
                      label: intl.formatMessage({
                        id: 'location_form_labels.apartment'
                      })
                    }
                  ]}
                >
                  <Input
                    maxLength={25}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.apartment_placeholder'
                    })}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={[customerAddressName, 'secondLine']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.landmarks'
                  })}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.street_placeholder'
                    })}
                    maxLength={250}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={[customerAddressName, 'isWorkAddress']}
                  valuePropName="checked"
                  className="br-customer-details-modal__work-address"
                >
                  <Checkbox>Mark as working Address.</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(EditCustomerDetailsModal);
