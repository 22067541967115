import { memo } from 'react';
import { injectIntl } from 'react-intl';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import { STAR_MAP_DEFAULT_LOCATION } from 'constants/stars';
import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '60%'
};

const BRGoogleMap = ({ markerPosition, intl }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        center={markerPosition || STAR_MAP_DEFAULT_LOCATION}
        streetView={false}
        options={{
          streetViewControl: false,
          clickableIcons: false,
          mapTypeControl: false
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    );
  };

  return isLoaded ? (
    renderMap()
  ) : (
    <div>{intl.formatMessage({ id: 'common.map_failed_to_load' })}</div>
  );
};

export default injectIntl(memo(BRGoogleMap));
