import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { getBusiness } from 'services/business';
import { getDeliveryTickets } from 'services/support-tickets';

import {
  getOrderDetails,
  getProofOfReturn,
  getTicketLogs
} from 'services/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { mediaHook } from 'customHooks';

import {
  infoSectionContentMapping,
  infoSectionContentMappingCol8,
  isReturnOrder
} from 'utils/shipmentDetails';
import { CANCELLED, EXCEPTION } from 'constants/shipment-details';
import {
  PUDO_HUB_TYPE,
  SOHO,
  PUDO_PROVIDER,
  LOG_DATE_FORMAT,
  TICKET_LINK,
  TICKET_REQUESETER
} from 'constants/shipments';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import OrderInfoSection from 'components/Shipments/components/NewDeliveryDetails/components/OrderInfoSection/OrderInfoSection';
import OrderInfoHeader from 'components/Shipments/components/NewDeliveryDetails/components/OrderInfoHeader/OrderInfoHeader';
import DeliveryLogs from 'components/Shipments/components/NewDeliveryDetails/components/DeliveryLogs/DeliveryLogs';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Banner from 'components/Banner/Banner';
import { notify } from 'components/Notify/Notify';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';

import ExceptionStateIcon from 'assets/imgRevamp/ExceptionBannerIcon.svg';
import CancelledStateIcon from 'assets/imgRevamp/CancelBannerIcon.svg';

import './NewDeliveryDetails.less';

const NewDeliveryDetails = ({
  match,
  history,
  intl,
  mobileScreenSizes: { isMediumMobileScreen }
}) => {
  const [logs, setLogs] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState({});
  const [stateColor, setStateColor] = useState('');
  const [stateName, setStateName] = useState('');
  const [showBanner, setShowBanner] = useState(true);
  const [loadPage, setLoadPage] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState({});
  const [bannerLog, setBannerLog] = useState(null);
  const [proofOfReturnData, setProofOfReturnData] = useState(null);
  const [deliveryTickets, setDeliveryTickets] = useState([]);

  useEffect(() => {
    getDeliveryDetails();
  }, []);

  const fetchProofOfReturn = async (id) => {
    try {
      const { data } = await getProofOfReturn(id);
      setProofOfReturnData(data);
      return data;
    } catch ({ message }) {
      notify(message);
    }
    return null;
  };

  const getDeliveryDetails = async () => {
    setLoadPage(true);
    try {
      const [result, { data }, tickets] = await Promise.all([
        getOrderDetails(match.params.id),
        getTicketLogs(match.params.id),
        getDeliveryTickets(match.params.id, {
          getEscalationRelatedTickets: true
        })
      ]);
      setDeliveryTickets(tickets.data);
      const ticketLogs = formatTicketLogs(data);
      const delivery = result[0];
      if (delivery?.type === ORDER_TYPES_VALUE.FXF_SEND)
        delivery.type = ORDER_TYPES_VALUE.DELIVER;
      setCurrentDelivery(delivery);

      let porData;
      if (isReturnOrder(delivery)) {
        porData = await fetchProofOfReturn(delivery?._id);
      }
      const formattedLogs = deliveryStateFormatter
        .formatLog(delivery, porData)
        .reverse();
      setBannerLog(
        ...formattedLogs.filter((log) => {
          if (log.bannerColor) return log;
        })
      );

      setLogs(sortLogsByDate([...formattedLogs, ...ticketLogs]));

      setStateColor(deliveryStateFormatter.getStateColor(delivery));

      setStateName(deliveryStateFormatter.getStateName(delivery));

      if (
        isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
          ACL_MATRIX.BUSINESS_DETAILS
        ]) &&
        !delivery.internationalTrackingNumber
      ) {
        const businessInfo = await getBusiness(delivery.sender._id);
        setCurrentBusiness(businessInfo);
      }
    } catch (error) {
      notify(error.message);
    }
    setLoadPage(false);
  };

  const sortLogsByDate = (logs) => {
    if (!logs) {
      return [];
    }

    return logs?.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  };

  const formatTicketLogs = (logs = []) => {
    return logs.map((log) => ({
      action: intl.formatMessage(
        {
          id: 'deliveries.delivery_details.delivery_logs.ticket_log'
        },
        {
          ticketId: log.externalId,
          requester:
            TICKET_REQUESETER[log?.requesterType] || TICKET_REQUESETER.Business,
          Link: (children) => (
            <a
              href={`${TICKET_LINK}${log.externalId}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {children}
            </a>
          )
        }
      ),
      date: dayjs(log?.createdAt).format(LOG_DATE_FORMAT),
      takenBy: [
        log?.agentRole ||
          intl.formatMessage({
            id: 'deliveries.delivery_details.delivery_logs.agent_name'
          }),
        log?.agentName || log?.email
      ]
    }));
  };

  const onCloseBannerClick = () => {
    setShowBanner(false);
  };

  const formatContent = (content) => {
    if (content?.length) {
      return content.map((item) => item.filter((i) => i));
    }
  };

  return (
    <LoadingWrapper loading={loadPage}>
      <Container
        header={
          <BRHeader
            title="Deliveries"
            handleGoBackUrl={() => history.push(`/deliveries`)}
            showBackArrow
          />
        }
        content={
          <div className="br-new-delivery-detials__containter">
            {[EXCEPTION, CANCELLED].includes(stateName) &&
              showBanner &&
              bannerLog && (
                <Banner
                  title={stateName}
                  dismissIcon
                  icon={
                    bannerLog?.bannerColor === 'warning'
                      ? ExceptionStateIcon
                      : CancelledStateIcon
                  }
                  type={bannerLog?.bannerColor}
                  subText={bannerLog?.extraInfo}
                  onDismissIconClick={onCloseBannerClick}
                />
              )}

            <OrderInfoHeader
              stateColorGroupClassName={stateColor}
              deliveryTickets={deliveryTickets}
              stateName={stateName}
              delivery={currentDelivery}
              setIsLoading={setLoadPage}
              onSuccess={getDeliveryDetails}
              proofOfReturnData={proofOfReturnData}
            />
            <Row>
              <Col span={16}>
                {infoSectionContentMapping(
                  currentDelivery,
                  stateColor,
                  currentBusiness.numberOfAttempts,
                  getDeliveryDetails
                ).map(
                  (section, index) =>
                    section &&
                    section.access && (
                      <OrderInfoSection
                        title={section.title}
                        rowColumnMapping={section.rowColumnMapping}
                        logoIndex={section.id}
                        isEditable={
                          currentDelivery?.integrationInfo?.provider ===
                            PUDO_PROVIDER &&
                          (currentDelivery?.state?.receivedAtWarehouse
                            ?.warehouse?.type === PUDO_HUB_TYPE ||
                            currentDelivery?.state?.code === 41)
                            ? false
                            : section.isEditable
                        }
                        secondHeader={section.secondHeader || false}
                        secondRowColumnMapping={
                          section.secondRowColumnMapping || false
                        }
                        deliveryImage={
                          index === 0 &&
                          currentDelivery?.creationSrc?.includes(SOHO) &&
                          currentDelivery?.imageUrl
                        }
                        content={formatContent(section.content)}
                        secondSectionContent={section.secondSectionContent}
                        delivery={currentDelivery}
                        setIsLoading={setLoadPage}
                        onSuccess={getDeliveryDetails}
                        allowedToRefund={currentBusiness.allowedToRefund}
                        businessDetails={currentBusiness}
                      />
                    )
                )}
                {!isMediumMobileScreen && (
                  <DeliveryLogs
                    logs={logs}
                    liveOps={currentDelivery.liveOpsInfo}
                    setIsLoading={setLoadPage}
                    deliveryDetails={currentDelivery}
                  />
                )}
              </Col>
              <Col span={8}>
                {infoSectionContentMappingCol8(currentDelivery, stateColor).map(
                  (section) =>
                    section && (
                      <OrderInfoSection
                        title={section.title}
                        rowColumnMapping={section.rowColumnMapping}
                        logoIndex={section.id}
                        isEditable={
                          currentDelivery?.integrationInfo?.provider ===
                            PUDO_PROVIDER &&
                          (currentDelivery?.state?.receivedAtWarehouse
                            ?.warehouse?.type === PUDO_HUB_TYPE ||
                            currentDelivery?.state?.code === 41)
                            ? false
                            : section.isEditable
                        }
                        content={formatContent(section.content)}
                        isSmallCol={section.isSmallCol}
                        fixedHeight={section.fixedHeight}
                        delivery={currentDelivery}
                        notes={currentDelivery.customerNotes}
                        starNotes={currentDelivery.starNotes}
                        setIsLoading={setLoadPage}
                        onSuccess={getDeliveryDetails}
                        businessDetails={currentBusiness}
                        className={section.className}
                      />
                    )
                )}
              </Col>
            </Row>
            {isMediumMobileScreen && (
              <Row>
                <Col span={16}>
                  <DeliveryLogs
                    logs={logs}
                    liveOps={currentDelivery.liveOpsInfo}
                    setIsLoading={setLoadPage}
                    deliveryDetails={currentDelivery}
                  />
                </Col>
              </Row>
            )}
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default withRouter(injectIntl(mediaHook(NewDeliveryDetails)));
