import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Form } from 'antd';

import './BRFormItemSubTitle.less';

class BRFormItemSubTitle extends React.Component {
  render() {
    const { name, title, subtitle, rules, field, optional, intl } = this.props;

    return (
      <Form.Item
        className={classnames({ 'br-form-with-sub-title': subtitle })}
        name={name}
        label={
          <div className="br-form-item-label">
            <span className="br-form-item-label__title">
              {title}
              {optional && (
                <span className="br-form-optional-label">
                  {intl.formatMessage({
                    id: 'form.optional_label',
                  })}
                </span>
              )}
            </span>
            <span className="br-form-item-label__sub-title">{subtitle}</span>
          </div>
        }
        rules={rules}
      >
        {field}
      </Form.Item>
    );
  }
}

export default injectIntl(BRFormItemSubTitle);
