import {
  fetchHubDispatching,
  fetchHubDispatchingOverview,
  hubDispatchingExport
} from 'services/hubs';
import { isUserAuthorized } from 'utils/helpers';
import { DISPATCHING_ALL_HUBS_ACCESS, PAGES_TYPE } from 'constants/dispatching';
import { ACL_MATRIX } from 'common/permissions';

import DispatchingOverview from 'components/Dispatching/components/DispatchingOverview/DispatchingOverview';
import DispatchingHub from 'components/Dispatching/components/DispatchingHub/DispatchingHub';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

const { DISPATCHING } = PAGES_TYPE;

const DispatchingTab = ({
  allHubs,
  setIsLoading,
  isLoading,
  selectedHubId,
  setSelectedHubId
}) => {
  return (
    <LoadingWrapper loading={isLoading}>
      {isUserAuthorized(DISPATCHING_ALL_HUBS_ACCESS, [
        ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY
      ]) && !selectedHubId ? (
        <DispatchingOverview
          fetchData={fetchHubDispatchingOverview}
          setIsLoading={setIsLoading}
          setSelectedHubId={setSelectedHubId}
          pageType={DISPATCHING}
        />
      ) : (
        <DispatchingHub
          fetchHubData={fetchHubDispatching}
          exportHubData={hubDispatchingExport}
          setIsLoading={setIsLoading}
          selectedHubId={selectedHubId}
          setSelectedHubId={setSelectedHubId}
          pageType={DISPATCHING}
          allHubs={allHubs}
        />
      )}
    </LoadingWrapper>
  );
};

export default DispatchingTab;
