import dayjs from 'dayjs';

import './BRDateTime.less';

const BRDateTime = ({
  date,
  dateFormat = 'D MMMM, YYYY',
  timeFormat = 'hh:mm A'
}) => {
  return (
    <div className="br-date-time__container">
      <div>{dayjs(date).format(dateFormat)}</div>
      <div>{dayjs(date).format(timeFormat)}</div>
    </div>
  );
};

export default BRDateTime;
