import { Tag, Tooltip } from 'antd';
import sumBy from 'lodash/sumBy';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import {
  PO_STATE_IDS,
  FULFILLMENT_INBOUND_MAIN_PATH,
  FULFILLMENT_OUTBOUND_MAIN_PATH,
  PO_STATE_NAMES,
  PUT_AWAY_KEY,
  SALES_ORDER_STATE_NAMES,
  SALES_ORDER_STATE_IDS,
  PO_KEY
} from 'constants/fulfillment';
import {
  changePOState,
  getSerialsToPrint,
  getPOLineSerialNumbers
} from 'services/fulfillment';
import { openModal } from 'utils/modal';
import { getCurrency } from 'common/countries/countries-mapping';
import { seperateNumberDigits } from 'utils/helpers';
import { showBill } from 'utils';
import {
  SERIAL_NUMBERS_TYPES,
  renderSerialsCount,
  renderSerialsView
} from 'constants/fulfillment-return-orders';
import {
  SALES_ORDER_TYPES_IDS,
  SALES_ORDER_TYPES_NAMES
} from 'constants/fulfillment-sales-orders';

import { notify } from 'components/Notify/Notify';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import QuantityInput from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/QuantityInput/QuantityInput';

import FulfillmentBoxImg from 'assets/imgRevamp/FulfillmentBox.png';
import PrintingProcessGif from 'assets/imgRevamp/PrintingProcess.gif';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/bosta-icons/Info-Circle.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as DoubleCheckIcon } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as StaticLoadingIcon } from 'assets/bosta-icons/StaticLoadingIcon.svg';
import { ReactComponent as EditIcon } from 'assets/bosta-icons/Edit.svg';
import { ReactComponent as PickupsIcon } from 'assets/bosta-icons/Pickups.svg';

export const goToPOsList = () =>
  window.open(FULFILLMENT_INBOUND_MAIN_PATH, '_self');
export const goToSalesOrdersList = () =>
  window.open(FULFILLMENT_OUTBOUND_MAIN_PATH, '_self');
const goToNextStep = () => window.location.reload();
export const renderPOName = (poId) => `${PO_KEY}${poId}`;
export const isInPutawayState = ({ stateId, type }) =>
  type === PUT_AWAY_KEY ||
  [PO_STATE_IDS.READY_TO_PUTAWAY, PO_STATE_IDS.PARTIALLY_PUTAWAY].includes(
    stateId
  );
export const getSKU = ({ sku, bostaSku, productSku }) =>
  sku || bostaSku || productSku;

export const formatDateTime = (d) => {
  const dayjsDate = dayjs(d);
  const isToday = dayjsDate.isToday();
  const isYesterday = dayjsDate.isYesterday();
  const formattedTime = dayjsDate.format('h:mm A');
  const formattedDate = isToday
    ? fmt({
        id: 'date_picker.filter_option.today'
      })
    : isYesterday
    ? fmt({
        id: 'date_picker.filter_option.yesterday'
      })
    : dayjsDate.format('DD MMM YYYY');

  return {
    hours: dayjsDate.get('hour'),
    formattedDate,
    formattedTime,
    isToday,
    isYesterday
  };
};

export const formatPrice = (price = 0) =>
  getCurrency(seperateNumberDigits(Number(price))).localized;

const handleConfirmPO = async ({ poId, products = [] }) => {
  try {
    const payload = {
      state: PO_STATE_IDS.CONFIRMED,
      lines: products.map(({ id, updatedQuantity, quantity }) => ({
        id,
        quantity: updatedQuantity || quantity
      }))
    };
    await changePOState(poId, payload);

    openModal(SuccessModal, {
      title: fmt(
        {
          id: 'fulfillment_inbound.confirm_po.success_modal.title'
        },
        {
          poId: renderPOName(poId)
        }
      ),
      confirmButtonLabel: fmt({
        id: 'fulfillment_inbound.confirm_po.success_modal.confirm_btn'
      }),

      cancelButtonLabel: fmt({
        id: 'fulfillment_inbound.confirm_po.success_modal.cancel_btn'
      }),
      modalAction: goToNextStep,
      cancelAction: goToPOsList,
      onCancel: null
    });
  } catch (error) {
    notify(error.message);
  }
};

const confirmOnUserBeforeConfirmPO = ({ poId, products }) => {
  openModal(BRConfirmationModal, {
    wrapClassName: 'br-confirm-po__confirmation-modal',
    icon: <img src={FulfillmentBoxImg} alt="box" />,
    message: fmt(
      {
        id: 'fulfillment_inbound.confirm_po.confirmation_modal.title'
      },
      {
        span: (children) => (
          <div className="br-confirm-po__confirmation-modal__teal-color">{`${sumBy(
            products,
            ({ quantity, updatedQuantity }) => updatedQuantity || quantity
          )} ${children}`}</div>
        )
      }
    ),
    onConfirm: () => handleConfirmPO({ poId, products }),
    confirmButtonText: fmt({
      id: 'common.confirm'
    }),
    cancelButtonText: fmt({
      id: 'common.back'
    }),
    confirmButtonType: 'primary',
    width: 431
  });
};

const handleCheckPO = async ({ poId, products = [] }) => {
  try {
    const payload = {
      state: PO_STATE_IDS.QUALITY_CHECKED,
      lines: products.map(({ id, updatedQuantity, confirmedQuantity }) => ({
        id,
        quantity: updatedQuantity || confirmedQuantity
      }))
    };

    await changePOState(poId, payload);

    openModal(SuccessModal, {
      title: fmt(
        {
          id: 'fulfillment_inbound.quality_check.success_modal.title'
        },
        {
          poId: renderPOName(poId)
        }
      ),
      confirmButtonPrefixIcon: <PrinterIcon />,
      confirmButtonLabel: fmt({
        id: 'fulfillment_inbound.quality_check.success_modal.confirm_btn'
      }),
      cancelButtonLabel: fmt({
        id: 'fulfillment_inbound.quality_check.success_modal.cancel_btn'
      }),
      modalAction: goToNextStep,
      cancelAction: goToPOsList,
      onCancel: null
    });
  } catch (error) {
    notify(error.message);
  }
};

const confirmOnUserBeforeCheckPO = ({ poId, products }) => {
  openModal(BRConfirmationModal, {
    wrapClassName: 'br-confirm-po__confirmation-modal',
    title: fmt({
      id: 'fulfillment_inbound.quality_check.confirmation_modal.title'
    }),
    message: (
      <pre className="br-confirm-po__confirmation-modal__check-po-content">
        {fmt({
          id: 'fulfillment_inbound.quality_check.confirmation_modal.content'
        })}
      </pre>
    ),
    onConfirm: () => handleCheckPO({ poId, products }),
    confirmButtonText: fmt({
      id: 'fulfillment_inbound.quality_check.confirmation_modal.confirm_btn'
    }),
    cancelButtonText: fmt({
      id: 'common.back'
    }),
    confirmButtonType: 'primary',
    width: 431
  });
};

export const handlePrintLabels = async ({
  poId,
  allSerialsCount,
  setIsLoading
}) => {
  setIsLoading(true);
  try {
    if (allSerialsCount) {
      openModal(BRConfirmationModal, {
        wrapClassName: 'br-confirm-po__confirmation-modal',
        icon: <img src={PrintingProcessGif} alt="printing" />,
        message: fmt(
          {
            id: 'fulfillment_inbound.print_PO_labels.success_modal.title'
          },
          {
            count: allSerialsCount
          }
        ),
        confirmButtonText: fmt({
          id: 'common.done'
        }),
        confirmButtonType: 'basic',
        width: 431,
        hideCancel: true
      });
    }

    const payload = {
      poId
    };

    const { data, message } = await getSerialsToPrint(payload);
    if (data) {
      showBill(data);
    } else {
      notify(message, 'success');
    }
  } catch (error) {
    notify(error.message);
  }
  setIsLoading(false);
};

const confirmOnUserBeforePrintLabels = ({ poId, products, setIsLoading }) => {
  const allSerialsCount = sumBy(products, ({ serialsCount }) =>
    Number(serialsCount)
  );

  openModal(BRConfirmationModal, {
    wrapClassName: 'br-confirm-po__confirmation-modal',
    message: (
      <>
        <span className="display-xl">🖨️</span>
        <div className="br-confirm-po__confirmation-modal__po-id">
          {renderPOName(poId)}
        </div>
        <span>
          {fmt(
            {
              id: 'fulfillment_inbound.print_PO_labels.confirmation_modal.title'
            },
            {
              span: (children) => (
                <span className="br-confirm-po__confirmation-modal__teal-color">{`${allSerialsCount} ${children}`}</span>
              )
            }
          )}
        </span>
      </>
    ),
    onConfirm: () => handlePrintLabels({ poId, allSerialsCount, setIsLoading }),
    confirmButtonText: fmt({
      id: 'fulfillment_inbound.print_PO_labels.confirmation_modal.confirm_btn'
    }),
    cancelButtonText: fmt({
      id: 'common.back'
    }),
    confirmButtonType: 'primary',
    width: 431
  });
};

const handleCancelPO = async (poId) => {
  try {
    const payload = {
      state: PO_STATE_IDS.CANCELED
    };

    await changePOState(poId, payload);
    goToNextStep();
  } catch (error) {
    notify(error.message);
  }
};

export const confirmOnUserBeforeCancelPO = (poId) => {
  openModal(BRConfirmationModal, {
    wrapClassName: 'br-cancel-po__confirmation-modal',
    title: fmt({
      id: 'fulfillment_inbound.cancel_po_modal.title'
    }),
    message: fmt({
      id: 'fulfillment_inbound.cancel_po_modal.content'
    }),
    onConfirm: () => handleCancelPO(poId),
    confirmButtonText: fmt({
      id: 'fulfillment_inbound.cancel_po_modal.confirm_btn'
    }),
    cancelButtonText: fmt({
      id: 'fulfillment_inbound.cancel_po_modal.cancel_btn'
    }),
    maskClosable: false
  });
};

export const getPORenderedTag = ({ stateId }) => {
  const stateName = PO_STATE_NAMES[stateId];
  if (!stateName) {
    return null;
  }

  const stateInsideIcon = {
    [PO_STATE_IDS.COMPLETED]: <DoubleCheckIcon />
  };

  const stateOutsideIcon = {
    [PO_STATE_IDS.PARTIALLY_PUTAWAY]: (
      <Tooltip
        title={fmt({
          id: 'fulfillment_inbound.po_table.putaway_tooltip'
        })}
      >
        <Icon component={PickupsIcon} />
      </Tooltip>
    )
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_inbound.po_states.${stateName}`
        })}
        {stateInsideIcon[stateId]}
      </Tag>
      {stateOutsideIcon[stateId]}
    </div>
  );
};

export const getSalesOrderRenderedTag = ({ stateId, typeId }) => {
  const stateName = SALES_ORDER_STATE_NAMES[stateId];
  if (!stateName) {
    return null;
  }

  const stateInsideIcon = {
    [SALES_ORDER_STATE_IDS.TRANSFERRED]: <DoubleCheckIcon />
  };

  return (
    <div className="br-status-tag">
      <Tag className={stateName}>
        {fmt({
          id: `fulfillment_outbound.state_name.${stateName}`
        })}
        {stateInsideIcon[stateId]}
      </Tag>
      {typeId && typeId !== SALES_ORDER_TYPES_IDS.DELIVER && (
        <Tag className="br-status-tag__return-type">
          {SALES_ORDER_TYPES_NAMES[typeId]}
        </Tag>
      )}
    </div>
  );
};

export const unselectProduct = ({
  productSku,
  productId,
  setProducts,
  checkById = false
}) => {
  setProducts((prev) =>
    prev.filter((product) => {
      return checkById
        ? productId !== product.id
        : productSku !== getSKU(product);
    })
  );
};

export const getViewPOContentForRendering = ({
  poId,
  stateId,
  isLoading,
  products,
  setProducts,
  isSerialNumbersGenerated,
  clickedLineData,
  setClickedLineData,
  setIsLoading
}) => {
  const {
    CREATED,
    CONFIRMED,
    QUALITY_CHECKED,
    READY_TO_PUTAWAY,
    PARTIALLY_PUTAWAY,
    COMPLETED,
    CANCELED
  } = PO_STATE_IDS;

  const cancelBtnProps = {
    label: fmt({
      id: 'fulfillment_inbound.confirm_po.actions.cancel'
    }),
    type: 'destructive-treitary',
    prefixIcon: <CloseIcon />,
    onClick: () => confirmOnUserBeforeCancelPO(poId)
  };

  // const editBtnProps = {
  //   label: fmt({
  //     id: 'fulfillment_inbound.confirm_po.actions.edit'
  //   }),
  //   prefixIcon: <EditIcon />,
  //   onClick: () => window.open(`${window.location.pathname}/edit`, '_self')
  // };

  const viewSerials = ({ line = {}, serialType }) =>
    renderSerialsView({
      clickedLineId: clickedLineData?.id,
      setClickedLineData,
      line,
      fetchData: () => getPOLineSerialNumbers(line.id),
      formatTypedSerialsList: (allSerials) => [
        {
          type: serialType,
          title: fmt({
            id: `fulfillment_returns.serials_view.${serialType}.title`
          }),
          serials: allSerials
        }
      ],
      getSerialType: () => serialType,
      showExpandBtn: true,
      isViewBtnDisabled: !isSerialNumbersGenerated
    });

  const putawayStateProps = {
    productsTableColumns: [
      {
        dataIndex: 'qualityCheckedQuantity',
        title: fmt({
          id: 'fulfillment_inbound.create_po.products.columns.quantity'
        })
      },
      {
        title: fmt({
          id: 'fulfillment_inbound.create_po.products.columns.serial_numbers'
        }),
        render: ({ serialsCount }) =>
          !isSerialNumbersGenerated ? (
            <>
              <span className="br-confirmed-po__tooltip__generating-serials">
                <StaticLoadingIcon />
                <span>
                  {fmt({
                    id: 'fulfillment_inbound.print_PO_labels.generating_serials'
                  })}
                </span>
              </span>
            </>
          ) : (
            renderSerialsCount(serialsCount)
          )
      },
      {
        render: (line) =>
          viewSerials({
            line,
            serialType: SERIAL_NUMBERS_TYPES.GENERATED
          })
      }
    ],
    actions: [
      {
        label: fmt({
          id: 'fulfillment_inbound.print_PO_labels.actions.confirm'
        }),
        type: 'primary',
        prefixIcon: <PrinterIcon />,
        onClick: () =>
          confirmOnUserBeforePrintLabels({ poId, products, setIsLoading }),
        disabled: isLoading || !isSerialNumbersGenerated,
        ...(!isSerialNumbersGenerated && {
          renderTooltip: (children) => (
            <Tooltip
              title={fmt({
                id: 'fulfillment_inbound.print_PO_labels.tooltip_while_generating_serials'
              })}
              placement="bottomRight"
              visible
            >
              {children}
            </Tooltip>
          )
        })
      }
    ],
    renderTag: () =>
      getPORenderedTag({
        stateId
      })
  };

  const PO_VIEW = {
    [CREATED]: {
      productsTableColumns: [
        {
          dataIndex: 'quantity',
          title: fmt({
            id: 'fulfillment_inbound.create_po.products.columns.original_quantity'
          })
        },
        {
          title: (
            <span className="br-po__actual-quantity__header">
              {fmt({
                id: 'fulfillment_inbound.create_po.products.columns.actual_quantity'
              })}
              <Tooltip
                title={fmt({
                  id: 'fulfillment_inbound.confirm_po.actual_quantity_tooltip'
                })}
              >
                <InfoCircleIcon />
              </Tooltip>
            </span>
          ),
          className: 'br-po__actual-quantity__col',
          render: ({ quantity, ...rest }) => (
            <QuantityInput
              initialValue={quantity}
              productSku={getSKU(rest)}
              setProducts={setProducts}
              quantityKey="updatedQuantity"
            />
          )
        }
      ],
      actions: [
        cancelBtnProps,
        // editBtnProps,
        {
          label: fmt({
            id: 'fulfillment_inbound.confirm_po.actions.confirm'
          }),
          type: 'primary',
          prefixIcon: <CheckIcon />,
          onClick: () => confirmOnUserBeforeConfirmPO({ poId, products }),
          disabled: isLoading
        }
      ],
      renderTag: () =>
        getPORenderedTag({
          stateId: CREATED
        })
    },
    [CONFIRMED]: {
      productsTableColumns: [
        {
          dataIndex: 'confirmedQuantity',
          title: fmt({
            id: 'fulfillment_inbound.create_po.products.columns.confirmed_quantity'
          })
        },
        {
          title: (
            <span className="br-po__actual-quantity__header">
              {fmt({
                id: 'fulfillment_inbound.create_po.products.columns.qualified_quantity'
              })}
              <Tooltip
                title={fmt({
                  id: 'fulfillment_inbound.quality_check.qualified_quantity_tooltip'
                })}
              >
                <InfoCircleIcon />
              </Tooltip>
            </span>
          ),
          className: 'br-po__actual-quantity__col',
          render: ({ confirmedQuantity, ...rest }) => (
            <QuantityInput
              initialValue={confirmedQuantity}
              productSku={getSKU(rest)}
              setProducts={setProducts}
              quantityKey="updatedQuantity"
              max={confirmedQuantity}
            />
          )
        }
      ],
      actions: [
        cancelBtnProps,
        // editBtnProps,
        {
          label: fmt({
            id: 'fulfillment_inbound.quality_check.actions.confirm'
          }),
          type: 'primary',
          prefixIcon: <CheckIcon />,
          onClick: () => confirmOnUserBeforeCheckPO({ poId, products }),
          disabled: isLoading
        }
      ],
      renderTag: () =>
        getPORenderedTag({
          stateId: CONFIRMED
        })
    },
    [QUALITY_CHECKED]: putawayStateProps,
    [READY_TO_PUTAWAY]: putawayStateProps,
    [PARTIALLY_PUTAWAY]: putawayStateProps,
    [COMPLETED]: {
      productsTableColumns: [
        {
          title: fmt({
            id: 'fulfillment_inbound.create_po.products.columns.quantity'
          }),
          render: ({ qualityCheckedQuantity, confirmedQuantity, quantity }) =>
            qualityCheckedQuantity ?? confirmedQuantity ?? quantity
        },
        {
          render: (line) =>
            viewSerials({
              line,
              serialType: SERIAL_NUMBERS_TYPES.COMPLETED
            })
        }
      ],
      renderTag: () =>
        getPORenderedTag({
          stateId: COMPLETED
        })
    },
    [CANCELED]: {
      productsTableColumns: [
        {
          title: fmt({
            id: 'fulfillment_inbound.create_po.products.columns.quantity'
          }),
          render: ({ qualityCheckedQuantity, confirmedQuantity, quantity }) =>
            qualityCheckedQuantity ?? confirmedQuantity ?? quantity
        }
      ],
      renderTag: () =>
        getPORenderedTag({
          stateId: CANCELED
        })
    }
  };

  return PO_VIEW[stateId] || {};
};

export const getPutawayOptions = () => {
  const { READY_TO_PUTAWAY, PARTIALLY_PUTAWAY } = PO_STATE_IDS;

  return [
    {
      label: fmt({
        id: 'fulfillment_inbound.po_filters.putaway.options.all'
      }),
      value: PUT_AWAY_KEY
    },
    {
      label: fmt({
        id: 'fulfillment_inbound.po_filters.putaway.options.not_stated'
      }),
      value: READY_TO_PUTAWAY
    },
    {
      label: fmt({
        id: 'fulfillment_inbound.po_filters.putaway.options.in_progress'
      }),
      value: PARTIALLY_PUTAWAY
    }
  ];
};

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];
