import React, { useState, useRef } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { NEXT_ARROW_TYPE, PREV_ARROW_TYPE } from 'constants/images-carousel';

import './ImagesCarousel.less';

const CustomArrow = ({ className, onClick, type }) => {
  return (
    <div className={className} onClick={onClick}>
      {type === PREV_ARROW_TYPE && <LeftOutlined />}
      {type === NEXT_ARROW_TYPE && <RightOutlined />}
    </div>
  );
};

const ImagesCarousel = ({ imagesArray, intl }) => {
  const [current, setCurrent] = useState(0);
  const totalSlides = imagesArray.length;
  const carouselRef = useRef(null);

  const handleBeforeChange = (from, to) => {
    setCurrent(to);
  };
  const goToPrev = () => {
    carouselRef.current.prev();
  };

  const goToNext = () => {
    carouselRef.current.next();
  };
  return (
    <div className="carousel-container">
      <Carousel
        beforeChange={handleBeforeChange}
        arrows={false}
        dots={false}
        ref={carouselRef}
      >
        {imagesArray.map((img, index) => {
          return (
            <div>
              <img
                className="br-images-carousel__single-image"
                src={img}
                alt={`Slide ${index + 1}`}
              />
            </div>
          );
        })}
      </Carousel>
      <div className="br-images-carousel__footer-wrapper">
        <div className="carousel-wrapper">
          <CustomArrow
            type={PREV_ARROW_TYPE}
            className="custom-arrow custom-arrow-prev"
            onClick={goToPrev}
          />
          <div className="slide-info-container">
            {intl?.formatMessage(
              {
                id: 'hubs.packages_debrief.current_picture'
              },
              { current: current + 1, totalSlides }
            )}
          </div>
          <CustomArrow
            type={NEXT_ARROW_TYPE}
            className="custom-arrow custom-arrow-next"
            onClick={goToNext}
          />
        </div>
      </div>
    </div>
  );
};

export default ImagesCarousel;
